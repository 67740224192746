import { useState, useRef, useEffect } from "react";
import {
    ComponentContactSortIcon,
    StyledTable,
    ComponentEmptyData,
    StyledTableButtonTop,
    ComponentTableSearch,
    ComponentRowService,
    ComponentRowStore,
    ComponentRowEditInventoryQTY,
    ComponentRowEditInvProQTY,
} from "@components";
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    TouchSensor,
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    rectSortingStrategy,
    //@ts-ignore
} from "@dnd-kit/sortable";
import { Row, Col, Pagination, Button, Tabs } from "antd";
import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";
import {
    enumSortBy,
    enumContactSortFields,
    PAGINATION,
    PAGE_START,
    enumNewsStatus,
    INITIAL_LIST_PARAMS,
    PATH_PRODUCT_CREATE,
    PATH_STORE_CREATE,
    PATH_STOCK_CREATE,
} from "@configs";
import {
    IPaginationNext,
    INews,
    IDataexample,
    IStoreList,
    IConsumableExample,
    IProductitemIsAutoConsume,
} from "@interfaces";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth, selectContact, selectOrg, setLoading } from "@redux";
import { useNotify } from "@utils";
import { useHistory } from "react-router";
import axios from "axios";
import styled from "styled-components";
import { MoreHorizontal } from "react-feather";
import { productAPI, storeAPI } from "@api";
import { ComponentTableSearchStock } from "src/Components/TableSearchStock";
import { ComponentRowStock } from "src/Components/table/RowStock";
import { consumableAPI } from "src/API/consumable";
import { IStore, IUpdateStore } from "src/Interfaces/store";
import { ComponentTableEditInventoryQTY } from "src/Components/TableSearchEditInventoryQTY";

const INITAL_PAGE = {
    xpage: PAGE_START,
    xlimit: PAGINATION,
};

export const PageEditInventoryQTYList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const search_params = useSelector(selectContact).paramsContact;
    const [params, setParams] = useState(search_params);
    const org_id = useSelector(selectAuth).userInfo?.merchant?._id;
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const categories = useSelector(selectOrg).category;
    const [textSearch, setTextSearch] = useState<string>("");
    const [Refresh, setRefresh] = useState<boolean>(false);

    const [pagination, setPagination] = useState<IPaginationNext>(INITAL_PAGE);
    const [proPagination, setProPagination] = useState<IPaginationNext>(INITAL_PAGE);
    const [stores, setStores] = useState<IConsumableExample[]>([]);
    const [storeList, setStoreList] = useState<IUpdateStore[]>([]);
    const [selectedStore, setSelectedStore] = useState<string>();
    const [productList, setProductList] = useState<IProductitemIsAutoConsume[]>([]);
    const [total, setTotal] = useState<number>(0);
    const { success, error } = useNotify();
    const [tab, setTab] = useState<number>(1);
    const history = useHistory();
    const organization_id = useSelector(selectAuth).userInfo?.merchant?._id;

    useEffect(() => {
        if (tab === 1) {
            fetchNews();
        } else if (tab === 2) {
            getListProduct();
        }
    }, [textSearch, pagination, proPagination, selectedStore, tab, Refresh]);

    const fetchNews = async () => {
        dispatch(setLoading(true));
        try {
            setProPagination(INITAL_PAGE);
            // api call in here
            let nowStore = "";
            if (storeList?.length === 0) {
                const getStores = await storeAPI.getList({
                    organization_code: orgCode,
                    xpage: INITAL_PAGE.xpage,
                    xlimit: 40,
                });
                if (getStores.data.error === "0") {
                    setStoreList(getStores.data.result);
                    if (selectedStore === undefined) {
                        setSelectedStore(getStores.data.result[0]._id);
                        nowStore = getStores.data.result[0]._id;
                    }
                }
            }
            const getAllStocks = await consumableAPI.getConsumablesStoreId({
                organization_id: org_id,
                xpage: pagination.xpage,
                xlimit: pagination.xlimit,
                is_enable: true,
                is_auto_consumable: false,
                store_id: selectedStore === undefined ? nowStore : selectedStore,
                // consumable_category_id: "",
                text_search: textSearch,
            });

            setTotal(getAllStocks.data.total);
            setStores(getAllStocks.data.result);

            // Search Input
            // if (textSearch.length > 0) {
            //     let tempServices = getAllStocks.data.result;
            //     tempServices = tempServices.filter((s) => {
            //         return s.name.toLowerCase().match(textSearch.toLowerCase());
            //     });
            //     setStores(tempServices);
            // }
        } catch (err: any) {
            error(err.msg);
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const getListProduct = async () => {
        dispatch(setLoading(true));
        try {
            setPagination(INITAL_PAGE);
            const productRes = await productAPI.getListIsAutoConsumableStoreId({
                organization_id: organization_id || "",
                xpage: proPagination.xpage,
                xlimit: proPagination.xlimit,
                text_search: textSearch,
                store_id: selectedStore || "",
            });
            setProductList(productRes?.data?.result);

            setTotal(productRes.data.total);
        } catch (err: any) {
            error(err.msg);
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };
    const { TabPane } = Tabs;

    const handleSort = (sortField: enumContactSortFields, sortBy: enumSortBy) => {
        const newSortParams = { ...params, sortField, sortBy };
        setParams(newSortParams);
        // fetchContacts(newSortParams);
    };

    const callbackGetList = () => {
        fetchNews();
    };
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
        useSensor(TouchSensor)
    );
    const handlePagination = (page) => {
        if (tab === 1) {
            setPagination({ ...pagination, xpage: page });
        } else if (tab === 2) {
            setProPagination({ ...proPagination, xpage: page });
        }
    };

    const handleSearchCategory = (value: string) => {
        setSelectedStore(value);
    };

    return (
        <StyledTable>
            <div className="page-layout">
                <div className="page-header-layout">
                    <div className="page-header" style={{ padding: "1.5rem 0" }}>
                        <div>
                            <h3>{t("page.consumable_lists")}</h3>
                        </div>
                    </div>
                    <StockStyle>
                        <Tabs
                            defaultActiveKey="1"
                            onChange={(key) => {
                                if (key === "2") {
                                    setTab(2);
                                    // getListStockRunningOut();
                                    // getListProduct();
                                } else {
                                    setTab(1);
                                    // fetchNews();
                                }
                            }}
                        >
                            <TabPane tab={t("page.restock.ingredient")} key="1"></TabPane>
                            <TabPane tab={t("page.restock.product")} key="2"></TabPane>
                        </Tabs>
                    </StockStyle>
                    <div className="search-layout">
                        <ComponentTableEditInventoryQTY
                            setTextSearch={setTextSearch}
                            categories={categories}
                            total={total}
                            isRangePicker={true}
                            storeList={storeList}
                            handleSearchCategory={handleSearchCategory}
                            setSelectedStore={setSelectedStore}
                            selectedStore={selectedStore}
                        />
                    </div>
                </div>
                <div className="page-table-layout">
                    <div className="table">
                        <ScrollContainer vertical={false}>
                            <StockStyle>
                                <div className="table_ground">
                                    <div className="table-body">
                                        <div className="table-header">
                                            {tab === 1 && (
                                                <Row
                                                    gutter={24}
                                                    type="flex"
                                                    justify="space-between"
                                                    align="middle"
                                                    className="header-row"
                                                >
                                                    <Col className="col-item" span={8}>
                                                        <p>{t("page.consumable_name")}</p>
                                                        <div className="filter-button">
                                                            <ComponentContactSortIcon
                                                                handleSort={handleSort}
                                                                sortBy={enumSortBy.ASC}
                                                                sortField={
                                                                    enumContactSortFields.POINTS
                                                                }
                                                                searchParams={params}
                                                            />
                                                            <ComponentContactSortIcon
                                                                handleSort={handleSort}
                                                                sortBy={enumSortBy.DESC}
                                                                sortField={
                                                                    enumContactSortFields.POINTS
                                                                }
                                                                searchParams={params}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col
                                                        className="col-item"
                                                        span={selectedStore === "" ? 8 : 4}
                                                        style={{ placeContent: "center" }}
                                                    >
                                                        <p>{t("page.unit")}</p>
                                                        <div className="filter-button">
                                                            <ComponentContactSortIcon
                                                                handleSort={handleSort}
                                                                sortBy={enumSortBy.ASC}
                                                                sortField={
                                                                    enumContactSortFields.POINTS
                                                                }
                                                                searchParams={params}
                                                            />
                                                            <ComponentContactSortIcon
                                                                handleSort={handleSort}
                                                                sortBy={enumSortBy.DESC}
                                                                sortField={
                                                                    enumContactSortFields.POINTS
                                                                }
                                                                searchParams={params}
                                                            />
                                                        </div>
                                                    </Col>
                                                    {selectedStore !== "" && (
                                                        <Col
                                                            className="col-item"
                                                            span={4}
                                                            style={{ placeContent: "center" }}
                                                        >
                                                            <p>{t("page.qty_added_deducted")}</p>
                                                            <div className="filter-button">
                                                                <ComponentContactSortIcon
                                                                    handleSort={handleSort}
                                                                    sortBy={enumSortBy.ASC}
                                                                    sortField={
                                                                        enumContactSortFields.POINTS
                                                                    }
                                                                    searchParams={params}
                                                                />
                                                                <ComponentContactSortIcon
                                                                    handleSort={handleSort}
                                                                    sortBy={enumSortBy.DESC}
                                                                    sortField={
                                                                        enumContactSortFields.POINTS
                                                                    }
                                                                    searchParams={params}
                                                                />
                                                            </div>
                                                        </Col>
                                                    )}
                                                    {/* <Col
                                                        className="col-item"
                                                        span={selectedStore === "" ? 8 : 4}
                                                        style={{ placeContent: "end" }}
                                                    >
                                                        <p>{t("page.manage")}</p>
                                                    </Col> */}
                                                </Row>
                                            )}
                                            {tab === 2 && (
                                                <Row
                                                    gutter={24}
                                                    type="flex"
                                                    justify="space-between"
                                                    align="middle"
                                                    className="header-row"
                                                >
                                                    <Col className="col-item" span={6}>
                                                        <p>{t("page.product_name")}</p>
                                                        <div className="filter-button">
                                                            <ComponentContactSortIcon
                                                                handleSort={handleSort}
                                                                sortBy={enumSortBy.ASC}
                                                                sortField={
                                                                    enumContactSortFields.POINTS
                                                                }
                                                                searchParams={params}
                                                            />
                                                            <ComponentContactSortIcon
                                                                handleSort={handleSort}
                                                                sortBy={enumSortBy.DESC}
                                                                sortField={
                                                                    enumContactSortFields.POINTS
                                                                }
                                                                searchParams={params}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col
                                                        className="col-item"
                                                        span={4}
                                                        style={{ padding: "0" }}
                                                    >
                                                        <p>{t("page.product_variant")}</p>
                                                        <div className="filter-button">
                                                            <ComponentContactSortIcon
                                                                handleSort={handleSort}
                                                                sortBy={enumSortBy.ASC}
                                                                sortField={
                                                                    enumContactSortFields.POINTS
                                                                }
                                                                searchParams={params}
                                                            />
                                                            <ComponentContactSortIcon
                                                                handleSort={handleSort}
                                                                sortBy={enumSortBy.DESC}
                                                                sortField={
                                                                    enumContactSortFields.POINTS
                                                                }
                                                                searchParams={params}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col
                                                        className="col-item"
                                                        span={4}
                                                        style={{ padding: "0" }}
                                                    >
                                                        <p>{t("page.restock.product_category")}</p>
                                                        <div className="filter-button">
                                                            <ComponentContactSortIcon
                                                                handleSort={handleSort}
                                                                sortBy={enumSortBy.ASC}
                                                                sortField={
                                                                    enumContactSortFields.POINTS
                                                                }
                                                                searchParams={params}
                                                            />
                                                            <ComponentContactSortIcon
                                                                handleSort={handleSort}
                                                                sortBy={enumSortBy.DESC}
                                                                sortField={
                                                                    enumContactSortFields.POINTS
                                                                }
                                                                searchParams={params}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col
                                                        className="col-item"
                                                        span={3}
                                                        style={{ placeContent: "center" }}
                                                    >
                                                        <p>{t("page.quantity")}</p>
                                                        <div className="filter-button">
                                                            <ComponentContactSortIcon
                                                                handleSort={handleSort}
                                                                sortBy={enumSortBy.ASC}
                                                                sortField={
                                                                    enumContactSortFields.POINTS
                                                                }
                                                                searchParams={params}
                                                            />
                                                            <ComponentContactSortIcon
                                                                handleSort={handleSort}
                                                                sortBy={enumSortBy.DESC}
                                                                sortField={
                                                                    enumContactSortFields.POINTS
                                                                }
                                                                searchParams={params}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col className="col-item" span={4}>
                                                        <p>{t("page.qty_added_deducted")}</p>
                                                        <div className="filter-button">
                                                            <ComponentContactSortIcon
                                                                handleSort={handleSort}
                                                                sortBy={enumSortBy.ASC}
                                                                sortField={
                                                                    enumContactSortFields.POINTS
                                                                }
                                                                searchParams={params}
                                                            />
                                                            <ComponentContactSortIcon
                                                                handleSort={handleSort}
                                                                sortBy={enumSortBy.DESC}
                                                                sortField={
                                                                    enumContactSortFields.POINTS
                                                                }
                                                                searchParams={params}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col
                                                        className="col-item"
                                                        span={3}
                                                        style={{ placeContent: "end" }}
                                                    >
                                                        <p>{t("page.manage")}</p>
                                                    </Col>
                                                </Row>
                                            )}
                                        </div>
                                        {tab === 1 && (
                                            <DndContext
                                                sensors={sensors}
                                                collisionDetection={closestCenter}
                                            >
                                                <SortableContext
                                                    items={stores.map((item) =>
                                                        JSON.stringify(item)
                                                    )}
                                                    strategy={rectSortingStrategy}
                                                >
                                                    {stores.length !== 0 ? (
                                                        <div className="data-table">
                                                            {stores.map((item, index) => (
                                                                <ComponentRowEditInventoryQTY
                                                                    key={index}
                                                                    data={item}
                                                                    itemJson={JSON.stringify(item)}
                                                                    categories={categories}
                                                                    callbackGetList={
                                                                        callbackGetList
                                                                    }
                                                                    index={index}
                                                                    stores={stores}
                                                                    selectedStore={selectedStore}
                                                                    Refresh={Refresh}
                                                                    setRefresh={setRefresh}
                                                                />
                                                            ))}
                                                            <div
                                                                style={{
                                                                    width: "100%",
                                                                    height: 10,
                                                                }}
                                                            ></div>
                                                        </div>
                                                    ) : (
                                                        <ComponentEmptyData />
                                                    )}
                                                </SortableContext>
                                            </DndContext>
                                        )}
                                        {tab === 2 && (
                                            <DndContext
                                                sensors={sensors}
                                                collisionDetection={closestCenter}
                                            >
                                                <SortableContext
                                                    items={stores.map((item) =>
                                                        JSON.stringify(item)
                                                    )}
                                                    strategy={rectSortingStrategy}
                                                >
                                                    {productList.length !== 0 ? (
                                                        <div className="data-table">
                                                            {productList.map((item, index) => (
                                                                <ComponentRowEditInvProQTY
                                                                    key={index}
                                                                    data={item}
                                                                    itemJson={JSON.stringify(item)}
                                                                    categories={categories}
                                                                    callbackGetList={
                                                                        callbackGetList
                                                                    }
                                                                    index={index}
                                                                    stores={stores}
                                                                    selectedStore={selectedStore}
                                                                    Refresh={Refresh}
                                                                    setRefresh={setRefresh}
                                                                />
                                                            ))}
                                                            <div
                                                                style={{
                                                                    width: "100%",
                                                                    height: 10,
                                                                }}
                                                            ></div>
                                                        </div>
                                                    ) : (
                                                        <ComponentEmptyData />
                                                    )}
                                                </SortableContext>
                                            </DndContext>
                                        )}
                                    </div>
                                </div>
                            </StockStyle>
                        </ScrollContainer>
                    </div>
                </div>

                <div className="page-bottom">
                    {total > 0 && tab === 1 && (
                        <div className="pagination">
                            <Pagination
                                defaultCurrent={INITIAL_LIST_PARAMS.page || 1}
                                pageSize={PAGINATION}
                                total={total}
                                onChange={handlePagination}
                                current={pagination.xpage}
                            />
                        </div>
                    )}
                    {total > 0 && tab === 2 && (
                        <div className="pagination">
                            <Pagination
                                defaultCurrent={INITIAL_LIST_PARAMS.page || 1}
                                pageSize={PAGINATION}
                                total={total}
                                onChange={handlePagination}
                                current={proPagination.xpage}
                            />
                        </div>
                    )}
                </div>
            </div>
        </StyledTable>
    );
};

export const StockStyle = styled.div`
    .table-header {
        padding: 18px 40px 12px 45px !important;
        margin-left: 10px !important;
    }
    .ant-tabs-bar {
        border: none;
    }
    .ant-tabs-nav .ant-tabs-tab {
        padding: 8px 0;
        margin: 0 28px 0 0;
    }
    .ant-tabs-nav .ant-tabs-tab-active {
        color: #f4394f;
        font-weight: 600;
    }
    .ant-tabs-ink-bar {
        background-color: #f4394f;
    }
    .ant-tabs-nav .ant-tabs-tab:hover {
        color: #f4394f;
    }
    .grayBox {
        background: #f7f7f8;
        border-radius: 6px;
        padding: 16px;
        display: flex;
        flex-direction: column;
        place-items: center;
    }
    .grayBox .body1 {
        font-size: 16px;
        font-weight: 700;
    }
    .grayBox .header {
        font-size: 35px;
        font-weight: 700;
        color: #000;
        margin: 0;
    }
    .addQTY {
        margin-top: 24px;
    }
    .reasonDiv {
        margin-top: 2rem;
    }
    .reasonChoice {
        display: block;
        line-height: 30px;
    }
    .ant-radio-group {
        width: 100%;
    }
`;
