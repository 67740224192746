import { useTranslation } from "react-i18next";
import { SharedModal, StyledCancelButton, StyledSubmitButton } from "./shared";
import styled from "styled-components";
import { DeleteBigIcon } from "./icon/DeleteBigIcon";

interface IProps {
    showPopup: boolean;
    handleClose: () => void;
    handleConfirm?: () => void;
}

export const ComponentDeleteModal = (props: IProps) => {
    const { showPopup, handleClose, handleConfirm } = props;
    const { t } = useTranslation();

    return (
        <SharedModal showPopup={showPopup} handleClose={handleClose} width={400} closable={false}>
            <StyledModalContent>
                <div className="deleteBigImage">
                    <DeleteBigIcon />
                </div>
                <p className="title1">{t("message.do_you_want_to_delete")}</p>
                <p className="body2">{t("message.please_confirm_to_delete")}</p>
                <div className="btn-layout">
                    <StyledCancelButton
                        type="sub"
                        text={t("page.cancel")}
                        htmlType="button"
                        onClick={handleClose}
                    />
                    <StyledSubmitButton
                        type="default"
                        text={t("page.confirm")}
                        htmlType="button"
                        onClick={handleConfirm}
                    />
                </div>
            </StyledModalContent>
        </SharedModal>
    );
};

const StyledModalContent = styled.div`
    .title1 {
        font-weight: 700;
        font-size: 25px;
        line-height: 34px;
        text-align: center;
        margin-top: 15px;
        color: #000;
        margin-bottom: 0;
    }
    .body2 {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: #646464;
        margin-top: 5px;
        margin-bottom: 2rem;
    }
    .ant-modal-header {
        display: none;
    }
    .deleteBigImage {
        display: flex;
        place-content: center;
    }
    .btn-layout {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        button {
            height: 50px;
            width: 100%;
            margin: 0;
        }
    }
`;
