export const FoldersIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="102"
            height="77"
            viewBox="0 0 102 77"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 8.51231C0 3.81109 3.92966 0 8.77714 0H16.7569C19.0587 0 21.2684 0.876944 22.9099 2.4419L24.4158 3.87755C26.0573 5.4425 28.267 6.31945 30.5688 6.31945H49.2229C54.0703 6.31945 58 10.1305 58 14.8318V36.4877C58 41.1889 54.0703 45 49.2229 45H8.77714C3.92966 45 0 41.1889 0 36.4877V8.51231Z"
                fill="url(#paint0_linear_18601_49117)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M29 30.7823C29 24.8274 33.946 20 40.0471 20H50.0906C52.9877 20 55.7689 21.1108 57.8349 23.0931L59.7302 24.9116C61.7963 26.8938 64.5774 28.0046 67.4746 28.0046H90.9529C97.0541 28.0046 102 32.832 102 38.7869V66.2177C102 72.1726 97.0541 77 90.9529 77H40.0471C33.946 77 29 72.1726 29 66.2177V30.7823Z"
                fill="url(#paint1_linear_18601_49117)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_18601_49117"
                    x1="-55.6547"
                    y1="-9.1361"
                    x2="-32.0982"
                    y2="73.9546"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C1DCFF" />
                    <stop offset="1" stopColor="#2A87FF" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_18601_49117"
                    x1="-41.0481"
                    y1="8.42761"
                    x2="-11.0478"
                    y2="113.575"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C1DCFF" />
                    <stop offset="1" stopColor="#2A87FF" />
                </linearGradient>
            </defs>
        </svg>
    );
};
