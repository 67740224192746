export const ArrowLeftPermission = () => (
    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.263604 7.67216C-0.087868 7.30094 -0.087868 6.69906 0.263604 6.32784L5.99117 0.278417C6.34264 -0.0928057 6.91249 -0.0928057 7.26396 0.278417C7.61543 0.64964 7.61543 1.25151 7.26396 1.62273L3.07279 6.04942H17.1C17.5971 6.04942 18 6.47501 18 7C18 7.52499 17.5971 7.95058 17.1 7.95058H3.07279L7.26396 12.3773C7.61543 12.7485 7.61543 13.3504 7.26396 13.7216C6.91249 14.0928 6.34264 14.0928 5.99117 13.7216L0.263604 7.67216Z"
            fill="#6C7084"
        />
    </svg>
);
