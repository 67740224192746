import { useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
    getCategories,
    getInfo,
    selectAuth,
    setAuth,
    setFullName,
    setImage,
    setLogo,
    setMainColor,
} from "@redux";
import { PATH_HOME, PATH_PRODUCT, PATH_STORE_LIST } from "@configs";
import { merchantAPI } from "@api";

export const PageHome = () => {
    // page hook
    const { t } = useTranslation();
    const { token } = useParams<{ token: string }>();
    const dispatch = useDispatch();
    const history = useHistory();
    const getUserInfo = async () => {
        await dispatch(setAuth(token));
        await dispatch(getInfo());
    };
    const merchantId = useSelector(selectAuth)?.userInfo?.merchantId;
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const userPermissionType = useSelector(selectAuth).userInfo?.storeIds;

    const getMerchant = async () => {
        if (merchantId) {
            const response = await merchantAPI.getMerchant(merchantId);
            dispatch(setMainColor(response.data.themeColor || "#000000"));
            dispatch(setImage(response.data.logoUrl));
            dispatch(setLogo(response.data.logoSmallUrl));
            dispatch(setFullName(response.data.name || ""));
        }
    };

    useEffect(() => {
        const fetchTokenChange = async () => {
            dispatch(getCategories({ organization_code: orgCode }));
            if (token) {
                // console.log(token);
                await getUserInfo();
                await getMerchant();
                if (userPermissionType?.length !== 0) {
                    history.push(PATH_STORE_LIST);
                } else {
                    history.push(PATH_PRODUCT);
                }
            }
        };
        fetchTokenChange();

    }, [token]);

    return (
        <StyledContainer scrollable={true}>
            <div className="body-field">
                <div className="title">
                    <h4>{t("page.welcome_to_loyalty")}</h4>
                    <small>{t("page.deshboard_samll")}</small>
                </div>
                <div className="dashboard-layout">
                    <div className="services"></div>
                </div>
            </div>
        </StyledContainer>
    );
};

const StyledContainer = styled.div<{ scrollable: boolean }>`
    height: 86vh;
    overflow-y: ${(p) => (p.scrollable ? "hidden" : "auto")};
    padding-right: ${(p) => (p.scrollable ? "6px" : "0")};
    overflow-x: hidden;
    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        @media (max-width: ${(p) => p.theme.breakPoints.breakMobileMedium}) {
            display: none;
        }
    }

    ::-webkit-scrollbar-track {
        display: none;
    }

    ::-webkit-scrollbar-thumb {
        background: ${(p) => p.theme.colors.fadedText};
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        cursor: grab;
    }
    .marginX-8 {
        margin: 8px 0;
    }
    .top-field {
        background-color: white;
        padding: 5rem;
        h4 {
            color: ${(p) => p.theme.colors.black};
            font-weight: 600;
            font-size: 25px;
            padding: 14px 0;
        }
        .ant-divider-horizontal {
            margin: 2.5rem 0;
        }
        .swiper-button {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .search-input {
            display: flex;
            justify-content: flex-end;

            input {
                height: 49px;
                width: 331px;
                min-width: 300px;
                max-width: 340px;
                font-size: 16px;
                color: #646464;
            }
            @media (max-width: ${(p) => p.theme.breakPoints.breakOnlyMobile}) {
                margin-top: 20px;
                justify-content: flex-start;
            }
        }
        .user-info {
            display: flex;
            .avatar {
                margin-right: 26px;
            }
            .name-position {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
            .user-name {
                font-weight: 700;
                font-size: 35px;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .user-position {
                padding: 7px 23px;
                background-color: #e1e1e1;
                border-radius: 40px;
                width: fit-content;
                max-width: 300px;
                font-size: 16px;
                font-weight: 600;
                color: black;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
    .body-field {
        padding: 5rem;
        padding-top: 2.5rem;
        .title {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            h4 {
                font-style: normal;
                font-weight: 700;
                font-size: 35px;
                line-height: 48px;
                color: #000000;
                padding-right: 14px;
            }
            small {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                color: #a5a5a5;
            }
            h4,
            small {
                margin: 0;
            }
        }
    }
    .dashboard-layout {
        display: flex;
        column-gap: 16px;
        .benefit {
            flex: 1;
        }
        .services {
            flex: 3;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 16px;
        }
    }
    .point-layout {
        display: flex;
        column-gap: 16px;
        .progress-card {
            flex: 3;
        }
        .point-card {
            flex: 2;
        }
    }
`;
