import React, { useEffect, memo, useState, useRef, useContext } from "react";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Switch, Row, Col, Tooltip, Button, TimePicker, Tree, Avatar } from "antd";
import styled from "styled-components";
import { INews, INewsData, INewsCategory, INewsCategoryParams } from "@interfaces";
import {
    SharedCKEditor,
    StyledSubmitButton,
    StyledCancelButton,
    StyledCard,
    SharedInput,
    ComponentPeriod,
    InfoIcon,
    IconCamera,
} from "@components";
import { useYup } from "@validations";
import { getCategories, setLoading, selectAuth } from "@redux";
import { showErrorMessage, useNotify } from "@utils";
import { PATH_STORE_LIST } from "@configs";
import { newsAPI, orgApi, storeAPI } from "@api";
import { ComponentProductDrawer, ComponentProductPreview } from "src/Components/product";
import { DeleteOutlined } from "@ant-design/icons";
// import { ComponentSearchInput } from "./Search";
interface IProps {
    store: any | undefined;
    categories: INewsCategory[] | undefined;
    storeDetails?: any | undefined;
}

interface IKbank {
    is_kbank_pos_qr: boolean;
    kbank_consumer_id: string;
    kbank_consumer_secret: string;
    kbank_merchant_id: string;
    kbank_partner_id: string;
    kbank_partner_secret: string;
}

export const ModulePaymentSettingForm = memo((props: IProps) => {
    //hook
    const { YupNews } = useYup();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const { error, success } = useNotify();
    const [showModal, setShowModal] = useState<boolean>(false);
    const organization_id = useSelector(selectAuth).userInfo?.merchant?._id;
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const searchInputRef = useRef<any | null>(null);
    const [cat, setCat] = useState<string>("");
    const pathName = window.location.pathname;
    const createNews = Yup.object().shape(YupNews);
    const { store, categories, storeDetails } = props;
    //
    const [BankName, setBankName] = useState("");
    const [AccountNo, setAccountNo] = useState("");
    const [AccountName, setAccountName] = useState("");
    const [BankBranch, setBankBranch] = useState("");
    const [isActive, seIsActive] = useState<boolean>(false);
    const [isPaysolution, setIsPaysolution] = useState<boolean>(false);
    const [merchantID, setMerchantID] = useState<string>("");
    const [merchantName, setMerchantName] = useState<string>("");
    const [tokenPay, setTokenPay] = useState<string>("");
    const [apiKeyPay, setApiKeyPay] = useState<string>("");
    const [kbankDetail, setKbankDetail] = useState<IKbank>({
        is_kbank_pos_qr: false,
        kbank_consumer_id: "",
        kbank_consumer_secret: "",
        kbank_merchant_id: "",
        kbank_partner_id: "",
        kbank_partner_secret: "",
    });

    //
    useEffect(() => {
        setTimeout(() => {
            dispatch(setLoading(false));
        }, 500);
        if (store) {
            // console.log(store);
            if (store.prompt_pay_bank_transfer) {
                setPaymentQRCodeImage(store.prompt_pay_bank_transfer.qr_receive_money_image_url);
                setBankName(store.prompt_pay_bank_transfer.bank_name);
                setAccountNo(store.prompt_pay_bank_transfer.account_no);
                setAccountName(store.prompt_pay_bank_transfer.account_name);
                setBankBranch(store.prompt_pay_bank_transfer.bank_branch);
                seIsActive(store.prompt_pay_bank_transfer.is_active);
                setIsPaysolution(store.is_paysolution_pos_qr);
                setMerchantID(store.paysolution_merchant_id);
                setMerchantName(store.paysolution_merchant_name);
                setTokenPay(store.paysolution_merchant_token);
                setApiKeyPay(store.paysolution_apikey);
                setKbankDetail({
                    is_kbank_pos_qr: store.is_kbank_pos_qr,
                    kbank_consumer_id: store.kbank_consumer_id,
                    kbank_consumer_secret: store.kbank_consumer_secret,
                    kbank_merchant_id: store.kbank_merchant_id,
                    kbank_partner_id: store.kbank_partner_id,
                    kbank_partner_secret: store.kbank_partner_secret,
                });
            }
        }
    }, [store, categories]);
    const handleEnter = (e: string) => {
        if (e === "Enter") {
            searchInputRef.current.focus();
        }
    };

    const handleNewsSubmit = async () => {
        dispatch(setLoading(true));
        try {
            const updateStoreAPI = await storeAPI.updateStorePaymentGateway({
                organization_id: organization_id,
                store_id: storeDetails._id,
                payment_gateway: {
                    is_paysolution_pos_qr: isPaysolution,
                    paysolution_merchant_id: merchantID,
                    paysolution_merchant_name: merchantName,
                    paysolution_merchant_token: tokenPay,
                    prompt_pay_bank_transfer: {
                        is_active: isActive,
                        bank_name: BankName,
                        bank_branch: BankBranch,
                        account_no: AccountNo,
                        account_name: AccountName,
                        qr_receive_money_image_url: paymentQRCodeImage,
                    },
                    is_kbank_pos_qr: kbankDetail.is_kbank_pos_qr,
                    kbank_consumer_id: kbankDetail.kbank_consumer_id,
                    kbank_consumer_secret: kbankDetail.kbank_consumer_secret,
                    kbank_merchant_id: kbankDetail.kbank_merchant_id,
                    kbank_partner_id: kbankDetail.kbank_partner_id,
                    kbank_partner_secret: kbankDetail.kbank_partner_secret,
                    paysolution_apikey: apiKeyPay,
                },
            });
            if (updateStoreAPI.data.error === "0") {
                success(t("message.update.success"));
            }
        } catch (err: any) {
            if (err.response) {
                error(showErrorMessage(err.response));
            } else error(t("message.error"));
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };
    const textString = "390px × 270px Maximum 10MB";
    const [paymentQRCodeImage, setPaymentQRCodeImage] = useState<string>("");
    const handleRemoveImage = () => {
        // remove action from form value
        console.log("remove action");
    };
    const handleChangeImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
        try {
            //@ts-ignore
            const image = e.target.files[0];
            const formData = new FormData();
            formData.append("file", image);
            // const res = await merchantAPI.uploadImage(formData);
            const res = await orgApi.uploadImage(formData);
            setPaymentQRCodeImage(res.data.result.publicUrl);
            console.log(res.data.result.publicUrl);
            // await console.log("upload image api call here");
        } catch (err: any) {
            //
            // const data = error.response.data;
            if (err) {
                error(err.msg);
            } else {
                error(t("page.image_error"));
            }
        }
    };
    return (
        <div className="form-wrap">
            <div className="page-body">
                {/* <Button onClick={() => console.log(paymentQRCodeImage)}>Click</Button> */}
                {/* <div className="card-wrap">
                    <StyledCard style={{ padding: 0 }}>
                        <StyledCustom>
                            <div
                                className="title"
                                style={{
                                    fontSize: "25px",
                                    color: "#000",
                                    borderBottom: "0.5px solid #A5A5A5",
                                    marginBottom: 0,
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <p style={{ paddingBottom: "36px", marginBottom: 10 }}>
                                    {t("payment.static_qr_code_promptpay")}
                                </p>
                                <StyledSwitch className="switch-field">
                                    <p>{t("page.activate")}</p>
                                    <Switch
                                        checked={isActive}
                                        onChange={(value) => {
                                            seIsActive(value);
                                        }}
                                    />
                                </StyledSwitch>
                            </div>
                        </StyledCustom>
                        <Row>
                            <Col span={24} style={{ marginBottom: "20px", marginTop: 20 }}>
                                <StyledAvatar>
                                    <div className="avatar">
                                        <div className="box_title">
                                            <p style={{ color: "#000" }}>
                                                {t("page.image_qr_code")}
                                            </p>
                                            <Tooltip placement="topLeft" title={`${textString}`}>
                                                <Button>
                                                    <InfoIcon />
                                                </Button>
                                            </Tooltip>
                                        </div>
                                        <div className="avatar-layout">
                                            <Avatar
                                                size={110}
                                                src={paymentQRCodeImage}
                                                shape="square"
                                            />
                                            <div className="remove-icon-layout">
                                                <DeleteOutlined
                                                    style={{ color: "white", fontSize: 20 }}
                                                    onClick={handleRemoveImage}
                                                />
                                            </div>
                                        </div>
                                        <input
                                            id="file_update_img"
                                            type="file"
                                            hidden
                                            onChange={handleChangeImage}
                                        />
                                        <label className="camera" htmlFor="file_update_img">
                                            <IconCamera />
                                        </label>
                                    </div>
                                </StyledAvatar>
                            </Col>
                        </Row>
                        <Row className="select-layout" gutter={32}>
                            <Col md={12}>
                                <SharedInput
                                    label={t("payment.bank_name")}
                                    name="BankName"
                                    onChange={(value) => setBankName(value.target.value)}
                                    value={BankName}
                                    parentClassName="mb-6"
                                />
                            </Col>
                            <Col md={12}>
                                <SharedInput
                                    label={t("payment.account_no")}
                                    name="AccountNo"
                                    onChange={(value) => setAccountNo(value.target.value)}
                                    value={AccountNo}
                                    parentClassName="mb-6"
                                />
                            </Col>
                        </Row>
                        <Row className="select-layout" gutter={32}>
                            <Col md={12}>
                                <SharedInput
                                    label={t("payment.account_name")}
                                    name="AccountName"
                                    onChange={(value) => setAccountName(value.target.value)}
                                    value={AccountName}
                                    parentClassName="mb-6"
                                />
                            </Col>
                            <Col md={12}>
                                <SharedInput
                                    label={t("payment.branch")}
                                    name="BankBranch"
                                    onChange={(value) => setBankBranch(value.target.value)}
                                    value={BankBranch}
                                    parentClassName="mb-6"
                                />
                            </Col>
                        </Row>
                    </StyledCard>
                </div> */}
                <div className="card-wrap">
                    <StyledCard style={{ padding: 0, marginTop: 50 }}>
                        <StyledCustom>
                            <div className="title">
                                <p style={{ paddingBottom: "36px", marginBottom: 10 }}>
                                    {t("page.dynamic_qr_payment_prompt_pay")}
                                </p>
                                <StyledSwitch className="switch-field">
                                    <p>{t("page.activate")}</p>
                                    <Switch
                                        checked={isPaysolution}
                                        onChange={(value) => {
                                            setIsPaysolution(value);
                                        }}
                                    />
                                </StyledSwitch>
                            </div>
                        </StyledCustom>
                        <Row gutter={32}>
                            <Col md={12}>
                                <SharedInput
                                    label={t("page.merchant_id_pay_solution")}
                                    name="merchantID"
                                    onChange={(value) => setMerchantID(value.target.value)}
                                    value={merchantID}
                                    parentClassName="mb-6"
                                />
                            </Col>
                            <Col md={12}>
                                <SharedInput
                                    label={t("page.merchant_name_pay_solution")}
                                    name="merchantName"
                                    onChange={(value) => setMerchantName(value.target.value)}
                                    value={merchantName}
                                    parentClassName="mb-6"
                                />
                            </Col>
                        </Row>
                        <Row gutter={32}>
                            <Col md={12}>
                                <SharedInput
                                    label={t("page.token_pay_solution")}
                                    name="tokenPay"
                                    onChange={(value) => setTokenPay(value.target.value)}
                                    value={tokenPay}
                                    parentClassName="mb-6"
                                />
                            </Col>
                            <Col md={12}>
                                <SharedInput
                                    label={t("page.api_key_pay")}
                                    name="tokenPay"
                                    onChange={(value) => setApiKeyPay(value.target.value)}
                                    value={apiKeyPay}
                                    parentClassName="mb-6"
                                />
                            </Col>
                        </Row>
                    </StyledCard>
                </div>
                <div className="card-wrap">
                    <StyledCard style={{ padding: 0, marginTop: 50 }}>
                        <StyledCustom>
                            <div className="title">
                                <p style={{ paddingBottom: "36px", marginBottom: 10 }}>
                                    {t("page.dynamic_qr_payment_kbank")}
                                </p>
                                <StyledSwitch className="switch-field">
                                    <p>{t("page.activate")}</p>
                                    <Switch
                                        checked={kbankDetail?.is_kbank_pos_qr}
                                        onChange={(value) => {
                                            setKbankDetail({ ...kbankDetail, is_kbank_pos_qr: value });
                                        }}
                                    />
                                </StyledSwitch>
                            </div>
                        </StyledCustom>
                        <Row gutter={32}>
                            <Col md={12}>
                                <SharedInput
                                    label={t("payment.kbank_consumer_id")}
                                    name="merchantName"
                                    onChange={(value) => setKbankDetail({ ...kbankDetail, kbank_consumer_id: value.target.value })}
                                    value={kbankDetail.kbank_consumer_id}
                                    parentClassName="mb-6"
                                />
                            </Col>
                            <Col md={12}>
                                <SharedInput
                                    label={t("payment.kbank_consumer_secret")}
                                    name="merchantName"
                                    onChange={(value) => setKbankDetail({ ...kbankDetail, kbank_consumer_secret: value.target.value })}
                                    value={kbankDetail.kbank_consumer_secret}
                                    parentClassName="mb-6"
                                />
                            </Col>
                            <Col md={12}>
                                <SharedInput
                                    label={t("payment.kbank_merchant_id")}
                                    name="merchantName"
                                    onChange={(value) => setKbankDetail({ ...kbankDetail, kbank_merchant_id: value.target.value })}
                                    value={kbankDetail.kbank_merchant_id}
                                    parentClassName="mb-6"
                                />
                            </Col>
                            <Col md={12}>
                                <SharedInput
                                    label={t("payment.kbank_partner_id")}
                                    name="merchantName"
                                    onChange={(value) => setKbankDetail({ ...kbankDetail, kbank_partner_id: value.target.value })}
                                    value={kbankDetail.kbank_partner_id}
                                    parentClassName="mb-6"
                                />
                            </Col>
                            <Col md={12}>
                                <SharedInput
                                    label={t("payment.kbank_partner_secret")}
                                    name="merchantName"
                                    onChange={(value) => setKbankDetail({ ...kbankDetail, kbank_partner_secret: value.target.value })}
                                    value={kbankDetail.kbank_partner_secret}
                                    parentClassName="mb-6"
                                />
                            </Col>

                        </Row>
                    </StyledCard>
                </div>
                <div className="btn-layout">
                    <StyledSubmitButton
                        text={t("page.save")}
                        type={"default"}
                        onClick={handleNewsSubmit}
                    />
                    <StyledCancelButton
                        type="sub"
                        text={t("page.cancel")}
                        htmlType="button"
                        onClick={() => history.push(PATH_STORE_LIST)}
                    />
                </div>
            </div>
            {/* <ComponentProductPreview news={values} /> */}
            <ComponentProductDrawer
                showModal={showModal}
                handleClose={() => setShowModal(!showModal)}
            />
        </div>
    );
});

const StyledSwitch = styled.div`
    display: flex;
    align-items: center;
    p {
        margin-bottom: 0;
    }
    .weekDaysRow {
        display: flex;
        margin-top: 20px;
    }
    .ant-checkbox-wrapper + span,
    .ant-checkbox + span {
        display: flex;
        place-items: center;
        font-size: 16px;
        font-weight: 700;
        color: #000;
    }
    .ant-checkbox-wrapper {
        display: flex;
        place-items: center;
    }
    .ant-time-picker {
        margin-left: 8px;
        margin-right: 8px;
        width: 128px !important;
    }
    .daysOfTheWeek {
        width: 100px;
    }
`;

const StyledCustom = styled.div`
    .secondTitle {
        font-size: 20px;
    }
    .title {
        font-size: 25px !important;
        color: #000 !important;
        border-bottom: 0.5px solid #a5a5a5;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
    }
`;

const StyledAvatar = styled.div`
    .avatar {
        margin-bottom: 20px;
        position: relative;
        .box_title {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            p {
                margin: 0 4px 0 0;
            }
            button {
                padding: 0 4px;
                border: 0;
                background: transparent;
                height: auto;
            }
        }
    }
    .avatar .box_title {
        margin: 0 0 20px 0px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 1.5em;
    }
    .avatar-layout {
        position: relative;
        width: 110px;
        &:hover {
            .remove-icon-layout {
                visibility: visible;
            }
        }
    }
    .remove-icon-layout {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
        visibility: hidden;
        display: flex;
        justify-content: end;
        padding: 8px;
    }
    .avatar .box_title span {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 1.5em;
        position: relative;
        top: -1px;
        display: inline-block;
        margin-left: 4px;
    }
    .avatar .camera {
        width: 33px;
        height: 33px;
        background-color: #000000;
        border-radius: 50%;
        opacity: 0.29;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 110px;
        bottom: 0;
        transform: translate(-50%, 50%);
        cursor: pointer;
    }
`;
