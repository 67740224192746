import { IGetImportExcelLogs, IGetLastOneImportExcelLog } from "@interfaces";
import storeFrontAxiosClient from "./storeFrontAxiosClient";

export const importExcelAPI = {
    getImportExcelLogs: (params: IGetImportExcelLogs) => {
        const url = "import_excel/getImportExcelLogs";
        return storeFrontAxiosClient.get(url, { params });
    },
    importProductVariantConsumableSelling: (params: FormData) => {
        const url = "import_excel/importProductVariantConsumableSelling";
        return storeFrontAxiosClient.post(url, params);
    },
    importProductVariantConsumableNonSelling: (params: FormData) => {
        const url = "import_excel/importProductVariantConsumableNonSelling";
        return storeFrontAxiosClient.post(url, params);
    },
    importAssignNonSellingConsumableToVariant: (params: FormData) => {
        const url = "import_excel/importAssignNonSellingConsumableToVariant";
        return storeFrontAxiosClient.post(url, params);
    },
    importAssignBomConsumableToConsumable: (params: FormData) => {
        const url = "import_excel/importAssignBomConsumableToConsumable";
        return storeFrontAxiosClient.post(url, params);
    },
    importAssignAddonToProduct: (params: FormData) => {
        const url = "import_excel/importAssignAddonToProduct";
        return storeFrontAxiosClient.post(url, params);
    },
    getLastOneImportExcelLog: (params: IGetLastOneImportExcelLog) => {
        const url = "import_excel/getLastOneImportExcelLog";
        return storeFrontAxiosClient.get(url, { params });
    },
};
