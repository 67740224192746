export const AssignEditIcon = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.3764 17.0279L15.1628 5.66544C15.6403 5.0527 15.8101 4.3443 15.6509 3.62299C15.513 2.96726 15.1097 2.34377 14.5049 1.87078L13.0299 0.699064C11.7459 -0.322161 10.1541 -0.214663 9.24152 0.957057L8.25465 2.23735C8.12731 2.39752 8.15914 2.63401 8.31832 2.76301C8.31832 2.76301 10.812 4.76246 10.8651 4.80546C11.0349 4.96671 11.1622 5.1817 11.1941 5.43969C11.2471 5.94493 10.8969 6.41792 10.377 6.48242C10.1329 6.51467 9.89944 6.43942 9.72966 6.29967L7.1086 4.21422C6.98126 4.11855 6.79025 4.13898 6.68413 4.26797L0.455138 12.3303C0.0518984 12.8355 -0.086052 13.4912 0.0518984 14.1255L0.847767 17.5761C0.890213 17.7589 1.04939 17.8879 1.24039 17.8879L4.74222 17.8449C5.37891 17.8341 5.97316 17.5439 6.3764 17.0279ZM11.2797 15.9533H16.9898C17.5469 15.9533 18 16.4123 18 16.9766C18 17.5421 17.5469 18 16.9898 18H11.2797C10.7226 18 10.2695 17.5421 10.2695 16.9766C10.2695 16.4123 10.7226 15.9533 11.2797 15.9533Z"
            fill="black"
        />
    </svg>
);
