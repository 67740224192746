export const SmallEditIcon = () => {
    return (
        <svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.1264 17.5279L15.9128 6.16544C16.3903 5.5527 16.5601 4.8443 16.4009 4.12299C16.263 3.46726 15.8597 2.84377 15.2549 2.37078L13.7799 1.19906C12.4959 0.177839 10.9041 0.285337 9.99152 1.45706L9.00465 2.73735C8.87731 2.89752 8.90914 3.13401 9.06832 3.26301C9.06832 3.26301 11.562 5.26246 11.6151 5.30546C11.7849 5.46671 11.9122 5.6817 11.9441 5.93969C11.9971 6.44493 11.6469 6.91792 11.127 6.98242C10.8829 7.01467 10.6494 6.93942 10.4797 6.79967L7.8586 4.71422C7.73126 4.61855 7.54025 4.63898 7.43413 4.76797L1.20514 12.8303C0.801898 13.3355 0.663948 13.9912 0.801898 14.6255L1.59777 18.0761C1.64021 18.2589 1.79939 18.3879 1.99039 18.3879L5.49222 18.3449C6.12891 18.3341 6.72316 18.0439 7.1264 17.5279ZM12.0297 16.4533H17.7398C18.2969 16.4533 18.75 16.9123 18.75 17.4766C18.75 18.0421 18.2969 18.5 17.7398 18.5H12.0297C11.4726 18.5 11.0195 18.0421 11.0195 17.4766C11.0195 16.9123 11.4726 16.4533 12.0297 16.4533Z"
                fill="#C8C8C8"
            />
        </svg>
    );
};
