export const ApprovedIcon = () => (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="maps-and-flags 1" clipPath="url(#clip0_10800_494910)">
            <g id="Group">
                <g id="Group_2">
                    <path
                        id="Vector"
                        d="M8.5 0C3.80166 0 0 3.80206 0 8.5C0 13.1983 3.80206 17 8.5 17C13.1983 17 17 13.1979 17 8.5C17 3.80166 13.1979 0 8.5 0ZM8.5 15.8667C4.43803 15.8667 1.13332 12.562 1.13332 8.5C1.13332 4.438 4.43803 1.13332 8.5 1.13332C12.562 1.13332 15.8667 4.43803 15.8667 8.5C15.8667 12.562 12.562 15.8667 8.5 15.8667Z"
                        fill="#00B900"
                    />
                </g>
            </g>
            <g id="Group_3">
                <g id="Group_4">
                    <path
                        id="Vector_2"
                        d="M12.9995 5.37691C12.7782 5.15561 12.4193 5.15561 12.1981 5.37691L7.15328 10.4217L4.80184 8.07028C4.58054 7.84898 4.22171 7.84898 4.00045 8.07028C3.77915 8.29158 3.77915 8.65037 4.00045 8.87167L6.75255 11.6238C6.97382 11.845 7.33275 11.845 7.55394 11.6238L12.9995 6.17827C13.2208 5.95697 13.2208 5.59821 12.9995 5.37691Z"
                        fill="#00B900"
                    />
                </g>
            </g>
        </g>
        <defs>
            <clipPath id="clip0_10800_494910">
                <rect width="17" height="17" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
