import { useTranslation } from "react-i18next";
import {
    SharedComponentDrawer,
    SharedInput,
    SharedModal2,
    StyledCancelButton,
    StyledSubmitButton,
} from "../shared";
import styled from "styled-components";
import { enumValidation, IMAGE_TYPE } from "@configs";
import { useNotify } from "@utils";
import axios from "axios";
import { useEffect, useState } from "react";
import { Avatar, Button, Col, Divider, Row, Select, Switch, Table, Tag, Tooltip } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { InfoIcon, IconCamera } from "@components";
import { useFormik } from "formik";
import { INews, INewsCategory, INewsData } from "@interfaces";
import * as Yup from "yup";
import { useYup } from "@validations";
import React from "react";
import { BarcodeIcon } from "../icon/BarcodeIcon";
import { SharedModal } from "../shared/InvitePermissionModal";
import { BlueCheckedIcon } from "../icon/BlueCheckedIcon";
import { valueToPercent } from "@mui/material/node_modules/@mui/base";
import { permissionPosAPI } from "@api";
import { useSelector } from "react-redux";
import { selectAuth } from "@redux";

interface IProps {
    showPopup2: boolean;
    handleClose2: () => void;
    sendToParent: any;
    employeeID: string;
    employee: any;
    setEmployee: any;
}

export const ComponentPermissionEditModal = (props: IProps) => {
    const { showPopup2, handleClose2, employee, employeeID, setEmployee, sendToParent } = props;
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const { t } = useTranslation();
    const { success, error } = useNotify();
    const newDate = new Date();
    const [employeeCode, setEmployeeCode] = useState<string>("");

    const lastURL = window.location.href.split("/").pop();

    const handleKeypress = (e) => {
        if (e.key === "Enter") {
            handleSave();
        }
    };
    const handleSave = async () => {
        try {
            const res = await permissionPosAPI.updateEmployee({
                employee_id: employeeID,
                organization_code: orgCode,
                store_code: lastURL,
                email: employee.email,
                name: employee.name,
                is_enable: true,
                permission_type: employee.permission_type,
                pos_number: employee.pos_number,
                position_text: "",
            });
            if (res.data.error === "0") {
                // console.log(res);
                sendToParent();
                handleClose2();
            } else {
                console.log("Something is wrong!");
            }
        } catch (err) {
            console.log(err);
        }
    };
    const handleDelete = async () => {
        try {
            const res = await permissionPosAPI.deleteEmployee({
                organization_code: orgCode,
                employee_id: employeeID,
            });
            if (res.data.error === "0") {
                error("User deleted successfully");
                sendToParent();
                handleClose2();
            } else {
                console.log("Something is wrong!");
            }
        } catch (err) {
            console.log(err);
        }
    };

    const { Option } = Select;

    return (
        <SharedModal
            showPopup={showPopup2}
            handleClose={handleClose2}
            title={t("page.edit_member")}
            width={500}
        >
            <StyledModalContent>
                <div className="avatar">
                    <div
                        className="box_title"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                        }}
                    >
                        <Row style={{ width: "100%" }}>
                            <Col md={24}>
                                <SharedInput
                                    label={t("page.name_employee")}
                                    onChange={(value) => {
                                        setEmployee((state) => ({
                                            ...state,
                                            name: value.target.value,
                                        }));
                                    }}
                                    value={employee.name}
                                    parentClassName="mb-6"
                                    onKeyPress={handleKeypress}
                                />
                            </Col>
                        </Row>
                        <Row
                            style={{
                                width: "100%",
                            }}
                            gutter={20}
                        >
                            <Col md={18} style={{ paddingLeft: 0 }}>
                                <SharedInput
                                    label={t("page.employee_code")}
                                    value={employee.pos_number}
                                    disable
                                />
                            </Col>
                            <Col md={6} style={{ paddingRight: 0 }}>
                                <div className="title selectTitle" style={{ visibility: "hidden" }}>
                                    {t("page.description")}
                                </div>
                                <Button
                                    style={{
                                        height: "50px",
                                        border: "0.5px solid #646464",
                                        borderRadius: "4px",
                                        background: "#F7F7F8",
                                        color: "#646464",
                                        width: "100%",
                                        display: "flex",
                                        placeContent: "center",
                                        alignItems: "center",
                                    }}
                                    onClick={() => {
                                        setEmployee((state) => ({
                                            ...state,
                                            pos_number: Math.floor(
                                                100000 + Math.random() * 900000
                                            ).toString(),
                                        }));
                                    }}
                                >
                                    {t("page.reset")}
                                </Button>
                            </Col>
                            <Col md={24} style={{ padding: 0 }}>
                                <div className="title" style={{ marginBottom: 8, color: "#000" }}>
                                    {t("page.edit_permissions_granted")}
                                </div>
                                <Select
                                    showSearch
                                    filterOption={(input, option) =>
                                        //@ts-ignore
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    defaultValue={t("page.select_role")}
                                    style={{ width: "100%" }}
                                    onChange={(value) => {
                                        setEmployee((state) => ({
                                            ...state,
                                            permission_type: value,
                                        }));
                                    }}
                                    value={employee.permission_type}
                                >
                                    <Option value="cashier">Cashier</Option>
                                    <Option value="manager">Manager</Option>
                                </Select>
                            </Col>
                        </Row>
                    </div>
                </div>
            </StyledModalContent>
            <div
                className="btn-layout"
                style={{ display: "flex", justifyContent: "space-between", marginTop: 40 }}
            >
                <Button
                    style={{
                        height: "50px",
                        border: "unset",
                        borderRadius: "4px",
                        background: "#F22F46",
                        color: "#fff",
                        marginRight: "10px",
                        fontWeight: "700",
                    }}
                    onClick={() => {
                        handleDelete();
                    }}
                >
                    {t("page.delete_user")}
                </Button>
                <StyledSubmitButton
                    type="default"
                    text={t("page.save")}
                    htmlType="button"
                    onClick={handleSave}
                    style={{ marginRight: 0 }}
                />
            </div>
        </SharedModal>
    );
};

const StyledModalContent = styled.div`
    .ant-drawer-body {
        .add-sub-btn {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: #0263e0;
            margin: 0;
            cursor: pointer;
        }
        .title {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            color: #000000;
            margin-bottom: 20px;
        }
        .btn-layout {
            position: fixed;
            bottom: 16px;
        }
    }
    .avatar {
        margin-bottom: 0;
        position: relative;
        .box_title {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            p {
                margin: 0 4px 0 0;
                color: #000;
            }
            // button {
            //     padding: 0 4px;
            //     border: 0;
            //     background: transparent;
            //     height: auto;
            // }
        }
    }
    .avatar .box_title {
        margin: 30px auto 10px auto;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 1.5em;
    }
    .avatar-layout {
        position: relative;
        width: 110px;
        &:hover {
            .remove-icon-layout {
                visibility: visible;
            }
        }
    }
    .remove-icon-layout {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
        visibility: hidden;
        display: flex;
        justify-content: end;
        padding: 8px;
    }
    .avatar .box_title span {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 1.5em;
        position: relative;
        top: -1px;
        display: inline-block;
        margin-left: 4px;
    }
    .ant-table-tbody > tr > td {
        text-align: center;
    }
    .ant-table-thead > tr > th {
        align-items: center;
    }
    .ant-table-tbody > tr > td:first-child {
        text-align: left;
        color: #646464;
    }
    .avatar .box_title span {
        font-weight: 700;
        font-size: 16px;
    }
    .ant-select-arrow {
        display: inline-block !important;
        color: inherit !important;
        font-style: normal !important;
        position: absolute !important;
        top: 50% !important;
        font-size: 12px !important;
        line-height: 1 !important;
    }
    .selectTitle {
        margin-bottom: 7px;
    }
    .ant-input-affix-wrapper {
        margin-left: 0 !important;
    }
    .avatar .box_title button span {
        font-size: 14px;
    }
`;
