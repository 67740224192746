import { useState, useEffect } from "react";
import {
    StyledTable,
    ComponentEmptyData,
    StyledTableButtonTop,
    ComponentRowProductAddonList,
} from "@components";
import { Row, Col, Pagination } from "antd";
import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";
import { PAGINATION, PAGE_START, INITIAL_LIST_PARAMS, PATH_CREATE_ADDON } from "@configs";
import { IPaginationNext, IGetProductAddonsExample } from "@interfaces";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth, selectContact, selectOrg, setLoading } from "@redux";
import { useNotify } from "@utils";
import { useHistory } from "react-router";
import styled from "styled-components";
import { productAddonAPI } from "@api";
import { IUpdateStore } from "src/Interfaces/store";
import Search from "antd/lib/input/Search";

const INITAL_PAGE = {
    xpage: PAGE_START,
    xlimit: PAGINATION,
};

export const PageAddOnProductCategory = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const search_params = useSelector(selectContact).paramsContact;
    const org_id = useSelector(selectAuth).userInfo?.merchant?._id;
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const categories = useSelector(selectOrg).category;
    const [textSearch, setTextSearch] = useState<string>("");
    const userPermissionType = useSelector(selectAuth).userInfo?.storeIds;

    const [pagination, setPagination] = useState<IPaginationNext>(INITAL_PAGE);
    const [productAddons, setProductAddons] = useState<IGetProductAddonsExample[]>([]);
    const [selectedStore, setSelectedStore] = useState<string>();
    const [total, setTotal] = useState<number>(0);
    const { success, error } = useNotify();
    const history = useHistory();
    useEffect(() => {
        fetchNews();
    }, [textSearch, pagination]);

    const fetchNews = async () => {
        dispatch(setLoading(true));
        try {
            const get = await productAddonAPI.getProductAddons({
                organization_id: org_id,
                xlimit: pagination.xlimit,
                xpage: pagination.xpage,
                is_enable: true,
                // text_search: "",
            });
            // console.log(get.data.result);

            setTotal(get.data.total);
            setProductAddons(get.data.result);
        } catch (err: any) {
            error(err.msg);
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const callbackGetList = () => {
        fetchNews();
    };

    const handlePagination = (page) => {
        setPagination({ ...pagination, xpage: page });
    };

    return (
        <StyledTable>
            <div className="page-layout">
                <div className="page-header-layout">
                    <div className="page-header" style={{ padding: "1.5rem 0" }}>
                        <div>
                            <h3>{t("page.sidebar.add_on_product_category")}</h3>
                        </div>
                        <div className="flex-header">
                            {/* <Search
                                placeholder={t("page.search_product")}
                                onSearch={(value) => setTextSearch(value)}
                                style={{ width: 200, height: 42, border: "none", marginRight: 10 }}
                                onChange={(event) => {
                                    setTextSearch(event.target.value);
                                }}
                            /> */}
                            <StyledTableButtonTop
                                type="danger"
                                size="default"
                                text={t("page.create_option_addon")}
                                onClick={() => history.push(PATH_CREATE_ADDON)}
                                className="add-btn"
                                icon="plus"
                                style={{
                                    background: "#F22F46",
                                    borderRadius: "4px",
                                    width: "100%",
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="page-table-layout">
                    <StockStyle>
                        <div className="table">
                            <ScrollContainer vertical={false}>
                                <div className="table_ground">
                                    <div className="table-body">
                                        <div className="table-header">
                                            <Row
                                                gutter={24}
                                                type="flex"
                                                justify="space-between"
                                                align="middle"
                                                className="header-row"
                                            >
                                                <Col className="col-item" span={8}>
                                                    <p>{t("page.addon_name")}</p>
                                                </Col>
                                                <Col
                                                    className="col-item"
                                                    span={6}
                                                    style={{ placeContent: "center" }}
                                                >
                                                    <p>{t("page.amount_choices")}</p>
                                                </Col>
                                                <Col
                                                    className="col-item"
                                                    span={6}
                                                    style={{ placeContent: "center" }}
                                                >
                                                    <p>{t("page.status_request")}</p>
                                                </Col>
                                                <Col
                                                    className="col-item"
                                                    span={4}
                                                    style={{ placeContent: "center" }}
                                                >
                                                    <p>{t("page.manage")}</p>
                                                </Col>
                                            </Row>
                                        </div>
                                        {productAddons.length !== 0 ? (
                                            <div className="data-table">
                                                {productAddons.map((item, index) => (
                                                    <ComponentRowProductAddonList
                                                        key={index}
                                                        data={item}
                                                        itemJson={JSON.stringify(item)}
                                                        categories={categories}
                                                        callbackGetList={callbackGetList}
                                                        index={index}
                                                        productAddons={productAddons}
                                                        selectedStore={selectedStore}
                                                    />
                                                ))}
                                                <div style={{ width: "100%", height: 10 }}></div>
                                            </div>
                                        ) : (
                                            <ComponentEmptyData />
                                        )}
                                    </div>
                                </div>
                            </ScrollContainer>
                        </div>
                    </StockStyle>
                </div>

                <div className="page-bottom">
                    {total > 0 && (
                        <div className="pagination">
                            <Pagination
                                defaultCurrent={INITIAL_LIST_PARAMS.page || 1}
                                pageSize={PAGINATION}
                                total={total}
                                onChange={handlePagination}
                                current={pagination.xpage}
                            />
                        </div>
                    )}
                </div>
            </div>
        </StyledTable>
    );
};

export const StockStyle = styled.div`
    .table {
        width: 100%;
    }
    .table-header {
        padding: 18px 40px 12px 45px !important;
        margin-left: 10px !important;
    }
    .ant-tabs-bar {
        border: none;
    }
    .ant-tabs-nav .ant-tabs-tab {
        padding: 8px 0;
        margin: 0 28px 0 0;
    }
    .ant-tabs-nav .ant-tabs-tab-active {
        color: #f4394f;
        font-weight: 600;
    }
    .ant-tabs-ink-bar {
        background-color: #f4394f;
    }
    .ant-tabs-nav .ant-tabs-tab:hover {
        color: #f4394f;
    }
    .grayBox {
        background: #f7f7f8;
        border-radius: 6px;
        padding: 16px;
        display: flex;
        flex-direction: column;
        place-items: center;
    }
    .grayBox .body1 {
        font-size: 16px;
        font-weight: 700;
    }
    .grayBox .header {
        font-size: 35px;
        font-weight: 700;
        color: #000;
        margin: 0;
    }
    .addQTY {
        margin-top: 24px;
    }
    .reasonDiv {
        margin-top: 2rem;
    }
    .reasonChoice {
        display: block;
        line-height: 30px;
    }
    .ant-radio-group {
        width: 100%;
    }
`;
