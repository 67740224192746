export const EditQTYArrowRight = () => {
    return (
        <svg
            width="14"
            height="11"
            viewBox="0 0 14 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.1997 5.09865L8.0354 1.46012C7.83577 1.28569 7.81534 0.982461 7.98976 0.782832C8.16419 0.583203 8.46742 0.562773 8.66705 0.737199L13.787 5.21082C13.7884 5.21197 13.7897 5.21312 13.791 5.21428C13.8935 5.30231 13.9585 5.4329 13.9585 5.57865C13.9585 5.75719 13.861 5.91295 13.7164 5.99565L8.66756 10.4197C8.46818 10.5944 8.16492 10.5744 7.99021 10.375C7.8155 10.1756 7.8355 9.87235 8.03488 9.69765L12.1878 6.05865L0.841075 6.05865C0.575978 6.05865 0.361074 5.84375 0.361074 5.57865C0.361074 5.31356 0.575978 5.09865 0.841075 5.09865L12.1997 5.09865Z"
                fill="#919EAB"
            />
        </svg>
    );
};
