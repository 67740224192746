import axiosClient from "./axiosClient";
import {
    INewsCategoryParams,
    IOrganizationId,
    INewsCategoryDelete,
    IConplainCategoryParams,
    IConplainCategoryDelete,
    IDivisionParams,
    IDivisionUpdateParams,
    IDivisionDelete,
    IDivisionStatus,
    IConplainCategoryStatus,
    IGetProductCategory,
    IEnableProductCategory,
    IDeleteProductCategory,
    ICreateProductCategory,
    IUpdateProductCategory,
    IGetPaymentGateway,
    IUpdatePaymentGateway,
    IGetVatDetail,
    IUpdateVatDetail,
    IGetProductCategories,
    IGetServiceGroupByCategory,
    IGetPosPromotionCategorys,
    ICreatePosPromotionCategory,
    IUpdatePosPromotionCategory,
    IDeletePosPromotionCategory,
    IDeleteInvCate,
    ICreateInvCate,
    IUpdateInvCate,
    ICreateBrand,
    IUpdateBrand,
    IGetUnitList,
    IDeleteBrand,
    IUpdateUnit,
    ICreateUnit,
    IDeleteUOM,
    ICreateUnitGroup,
    IGetAmphur,
    IGetSubDistrict,
    IGetCheckStockReasonList,
    IGetPromotionGroups,
    IGetPromotionGroup,
    ICreatePromotionGroup,
    IUpdatePromotionGroup,
    IDeletePromotionGroup,
    IGetPartnerByOrganizationId,
} from "@interfaces";
import storeFrontAxiosClient from "./storeFrontAxiosClient";

export const orgApi = {
    getCat: (params: IGetProductCategory) => {
        const url = "/organization/getProductCategorys";
        return storeFrontAxiosClient.get(url, { params });
    },
    getInvCat: (params: any) => {
        const url = "/organization/getConsumableCategorys";
        return storeFrontAxiosClient.get(url, { params });
    },
    getBrand: (params: any) => {
        const url = "/organization/getBrands";
        return storeFrontAxiosClient.get(url, { params });
    },
    getUnitList: (params: IGetUnitList) => {
        const url = "/Uom/getUoms";
        return storeFrontAxiosClient.get(url, { params });
    },
    updateUnit: (params: IUpdateUnit) => {
        const url = "/uom/updateUom";
        return storeFrontAxiosClient.patch(url, params);
    },
    createUOMGroup: (params: ICreateUnitGroup) => {
        const url = "/bear_house/createBearHouseUomGroup";
        return storeFrontAxiosClient.post(url, params);
    },
    updateProductCategory: (params: IUpdateProductCategory) => {
        const url = "/organization/updateProductCategory";
        return storeFrontAxiosClient.patch(url, params);
    },
    enableProductCategory: (params: IEnableProductCategory) => {
        const url = "/organization/setEnableProductCategory";
        return storeFrontAxiosClient.patch(url, params);
    },
    createCat: (params: INewsCategoryParams) => {
        const url = "/organization/createNewsCategory";
        return axiosClient.post(url, params);
    },
    createProductCategory: (params: ICreateProductCategory) => {
        const url = "/organization/createProductCategory";
        return storeFrontAxiosClient.post(url, params);
    },
    createInvCate: (params: ICreateInvCate) => {
        const url = "/organization/createConsumableCategory";
        return storeFrontAxiosClient.post(url, params);
    },
    createBrand: (params: ICreateBrand) => {
        const url = "/organization/createBrand";
        return storeFrontAxiosClient.post(url, params);
    },
    createUOM: (params: ICreateUnit) => {
        const url = "/uom/createUom";
        return storeFrontAxiosClient.post(url, params);
    },
    updateInvCate: (params: IUpdateInvCate) => {
        const url = "/organization/updateConsumableCategory";
        return storeFrontAxiosClient.patch(url, params);
    },
    updateBrand: (params: IUpdateBrand) => {
        const url = "/organization/updateBrand";
        return storeFrontAxiosClient.patch(url, params);
    },

    deleteProductCategory: (params: IDeleteProductCategory) => {
        const url = "/organization/deleteProductCategory";
        return storeFrontAxiosClient.post(url, params);
    },
    deleteBrand: (params: IDeleteBrand) => {
        const url = "/organization/deleteBrand";
        return storeFrontAxiosClient.post(url, params);
    },
    deleteUOM: (params: IDeleteUOM) => {
        const url = "/uom/deleteUom";
        return storeFrontAxiosClient.post(url, params);
    },
    deleteInvCate: (params: IDeleteInvCate) => {
        const url = "/organization/deleteConsumableCategory";
        return storeFrontAxiosClient.post(url, params);
    },
    getServiceGroup: (params: IGetServiceGroupByCategory) => {
        const url = "/organization/getServiceGroupByCategory";
        return storeFrontAxiosClient.get(url, { params });
    },
    deleteCat: (params: INewsCategoryDelete) => {
        const url = "/organization/deleteNewsCategory";
        return axiosClient.post(url, params);
    },
    getConplainCat: (params: IOrganizationId) => {
        const url = "/organization/getComplainCategorys";
        return axiosClient.get(url, { params });
    },
    createConplainCat: (params: IConplainCategoryParams) => {
        const url = "/organization/createComplainCategory";
        return axiosClient.post(url, params);
    },
    deleteConplainCat: (params: IConplainCategoryDelete) => {
        const url = "/organization/deleteComplainCategory";
        return axiosClient.post(url, params);
    },
    setEnableComplainCategory: (params: IConplainCategoryStatus) => {
        const url = "/organization/setEnableComplainCategory";
        return axiosClient.patch(url, params);
    },
    getDivision: (params: IOrganizationId) => {
        const url = "/organization/getDivisions";
        return axiosClient.get(url, { params });
    },
    createDivision: (params: IDivisionParams) => {
        const url = "/organization/createDivision";
        return axiosClient.post(url, params);
    },
    updateDivision: (params: IDivisionUpdateParams) => {
        const url = "/organization/updateDivision";
        return axiosClient.post(url, params);
    },
    deleteDeivision: (params: IDivisionDelete) => {
        const url = "/organization/deleteDivision";
        return axiosClient.post(url, params);
    },
    setEnableDeivision: (params: IDivisionStatus) => {
        const url = "/organization/setEnableDivision";
        return axiosClient.patch(url, params);
    },
    getComplaintStatus: (params: IOrganizationId) => {
        const url = "/complain/getComplainStatus";
        return axiosClient.get(url, { params });
    },
    uploadImage: (params) => {
        const url = "/uploads/upload_file";
        return storeFrontAxiosClient.post(url, params);
    },
    getPaymentGateway: (params: IGetPaymentGateway) => {
        const url = "/organization/getPaymentGateway";
        return storeFrontAxiosClient.get(url, { params });
    },
    updatePaymentGateway: (params: IUpdatePaymentGateway) => {
        const url = "/organization/updatePaymentGateway";
        return storeFrontAxiosClient.patch(url, params);
    },
    getVatDetail: (params: IGetVatDetail) => {
        const url = "/organization/getVatDetail";
        return storeFrontAxiosClient.get(url, { params });
    },
    updateVatDetail: (params: IUpdateVatDetail) => {
        const url = "/organization/updateVatDetail";
        return storeFrontAxiosClient.patch(url, params);
    },
    getProductCategorys: (params: IGetProductCategories) => {
        const url = "organization/getProductCategorys";
        return storeFrontAxiosClient.get(url, { params });
    },
    getPosPromotionCategorys: (params: IGetPosPromotionCategorys) => {
        const url = "organization/getPosPromotionCategorys";
        return storeFrontAxiosClient.get(url, { params });
    },
    createPosPromotionCategory: (params: ICreatePosPromotionCategory) => {
        const url = "organization/createPosPromotionCategory";
        return storeFrontAxiosClient.post(url, params);
    },
    updatePosPromotionCategory: (params: IUpdatePosPromotionCategory) => {
        const url = "organization/updatePosPromotionCategory";
        return storeFrontAxiosClient.patch(url, params);
    },
    deletePosPromotionCategory: (params: IDeletePosPromotionCategory) => {
        const url = "organization/deletePosPromotionCategory";
        return storeFrontAxiosClient.post(url, params);
    },
    getProvince: () => {
        const url = "geos/getAllProvince";
        return storeFrontAxiosClient.post(url);
    },
    getAmphur: (params: IGetAmphur) => {
        const url = "geos/getAllAmphurByProvinceId";
        return storeFrontAxiosClient.post(url, params);
    },
    getSubDistrict: (params: IGetSubDistrict) => {
        const url = "geos/getAllDistrictByAmphurId";
        return storeFrontAxiosClient.post(url, params);
    },
    getCheckStockReasonList: (params: IGetCheckStockReasonList) => {
        const url = "organization/getCheckStockReasonList";
        return storeFrontAxiosClient.get(url, { params });
    },
    getWasteRecordReasonList: (params: IGetCheckStockReasonList) => {
        const url = "organization/getWasteRecordReasonList";
        return storeFrontAxiosClient.get(url, { params });
    },
    getPromotionGroups: (params: IGetPromotionGroups) => {
        const url = "organization/getPromotionGroups";
        return storeFrontAxiosClient.get(url, { params });
    },
    getPromotionGroup: (params: IGetPromotionGroup) => {
        const url = "organization/getPromotionGroup";
        return storeFrontAxiosClient.get(url, { params });
    },
    createPromotionGroup: (params: ICreatePromotionGroup) => {
        const url = "organization/createPromotionGroup";
        return storeFrontAxiosClient.post(url, params);
    },
    updatePromotionGroup: (params: IUpdatePromotionGroup) => {
        const url = "organization/updatePromotionGroup";
        return storeFrontAxiosClient.patch(url, params);
    },
    deletePromotionGroup: (params: IDeletePromotionGroup) => {
        const url = "organization/deletePromotionGroup";
        return storeFrontAxiosClient.post(url, params);
    },
    getPartnerByOrganizationId: (params: IGetPartnerByOrganizationId) => {
        const url = "organization/getPartnerByOrganizationId";
        return storeFrontAxiosClient.get(url, { params });
    },
};
