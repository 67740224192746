import { Layout } from "antd";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { MenuTheme } from "antd/lib/menu";

import { useRef, useEffect } from "react";
import { StyledSidebar } from "@components";
import { selectApp, useAppSelector, setMobileDrawer } from "@redux";
import {
    PATH_HOME,
    PATH_NEWS_ALL,
    PATH_NEWS_HIGHLIGHT,
    PATH_NEWS,
    PATH_CREATE_NEWS,
    PATH_STOREFRONT,
    PATH_SERVICES,
    PATH_SERVICES_CATEGORY,
    PATH_PRODUCT,
    PATH_PRODUCT_ORDERS,
    PATH_PRODUCT_SETTINGS,
    PATH_PROMOTION,
    PATH_STORE,
    PATH_STORE_ACCOUNT_SETTINGS,
    PATH_STORE_APPOINTMENT_SETTINGS,
    PATH_STORE_PAYMENT_SETTINGS,
    PATH_STAFF,
    PATH_STAFF_SCHEDULING,
    PATH_STAFF_TYPES,
    PATH_CUSTOMER_SERVICES,
    PATH_SERVICES_CREATE,
    PATH_PRODUCT_CREATE,
    PATH_STORE_LIST,
    PATH_STORE_CREATE,
    PATH_PERMISSION_POS,
    PATH_CATEGORY_LIST,
    enumDrawerPlacement,
    PATH_PAYMENT_SETTING,
    PATH_CALCULATION_BILL,
    PATH_STOCK_LIST,
    PATH_STOCK_CREATE,
    PATH_RECIPE_LIST,
    PATH_RECIPE_ASSIGN,
    PATH_EDIT_QTY_PRODUCT,
    PATH_INVENTORY_QTY,
    PATH_CHECK_STOCK,
    PATH_ADD_ON_PRODUCT_CATEGORY,
    PATH_CREATE_ADDON,
    PATH_PURCHASE_ORDER,
    PATH_GOOD_RECEIVE,
    PATH_SUPPLIER,
    PATH_PROMOTION_LIST,
    PATH_PROMOTION_CREATE,
    PATH_WHOLESALE_CHANNEL,
    PATH_WHOLESALE_EDIT,
    PATH_WASTE_RECORD,
    PATH_PURCHASE_REQUEST,
    PATH_PROMOTION_CATEGORY_LIST,
    PATH_INV_CATE,
    PATH_BRAND,
    PATH_UNIT,
    PATH_SETTING_CATEGORY_CHECKSTOCK,
    PATH_STOCK,
    PATH_WASTE,
    PATH_PROMOTION_GROUPS,
    PATH_BILL_PROMOTION,
    PATH_ROLES_PERMISSIONS,
    PATH_ROLES_PERMISSIONS_List,
    PATH_IMPORT_TABLE,
    PATH_RANKING,
    PATH_TRANSFER_REQUEST,
    PATH_EXPORT_TABLE,
} from "@configs";
import { ComponentStorefront } from "./Storefront";
import { ComponentNews } from "./news";
import { ComponentMobileSidebar } from "./MobileSidebar";
import { ComponentProfileSidebar } from "./ProfileSidebar";

const { Sider } = Layout;

interface IProps {
    sidebarTheme: MenuTheme;
    collapsed: boolean;
}

export const ComponentSidebar = ({ sidebarTheme, collapsed }: IProps) => {
    //hook
    const location = useLocation();

    const sidebarRef = useRef();
    //redux state
    const app = useAppSelector(selectApp);
    const pathname = location.pathname;

    useEffect(() => {
        if (sidebarRef.current) {
            //@ts-ignore
            sidebarRef.current.scrollIntoView({
                behavior: "auto",
                block: "center",
                inline: "nearest",
            });
        }
    }, [pathname]);

    const noSidebarRoute = () => location.pathname === PATH_HOME;

    const isStorefrontRoutes = (route: string) =>
        route === PATH_STOREFRONT ||
        route === PATH_SERVICES ||
        route === PATH_SERVICES_CREATE ||
        route === PATH_SERVICES_CATEGORY ||
        route === PATH_RANKING ||
        route === PATH_PRODUCT ||
        route === PATH_PRODUCT_CREATE ||
        route === PATH_PRODUCT_ORDERS ||
        route === PATH_PRODUCT_SETTINGS ||
        route === PATH_WHOLESALE_CHANNEL ||
        route.includes(PATH_WHOLESALE_EDIT) ||
        route === PATH_PROMOTION ||
        route === PATH_STORE ||
        route === PATH_STORE_ACCOUNT_SETTINGS ||
        route === PATH_STORE_APPOINTMENT_SETTINGS ||
        route === PATH_STORE_PAYMENT_SETTINGS ||
        route === PATH_STAFF ||
        route === PATH_STAFF_SCHEDULING ||
        route === PATH_STAFF_TYPES ||
        route === PATH_CUSTOMER_SERVICES ||
        route === PATH_STORE_LIST ||
        route === PATH_STORE_CREATE ||
        route === PATH_PERMISSION_POS ||
        route === `${PATH_PERMISSION_POS}/:store_code` ||
        route === PATH_CATEGORY_LIST ||
        route === PATH_INV_CATE ||
        route === PATH_BRAND ||
        route === PATH_UNIT ||
        route === PATH_PAYMENT_SETTING ||
        route === PATH_CALCULATION_BILL ||
        // route === PATH_REPORT_SETTING ||
        route === PATH_STOCK_LIST ||
        route === PATH_STOCK_CREATE ||
        route === PATH_RECIPE_ASSIGN ||
        route === PATH_EDIT_QTY_PRODUCT ||
        route === PATH_INVENTORY_QTY ||
        route === PATH_PROMOTION_LIST ||
        route === PATH_PROMOTION_CATEGORY_LIST ||
        route === PATH_PROMOTION_GROUPS ||
        route === PATH_SETTING_CATEGORY_CHECKSTOCK ||
        route === PATH_ROLES_PERMISSIONS ||
        route === PATH_ROLES_PERMISSIONS_List ||
        route === PATH_IMPORT_TABLE ||
        route === PATH_EXPORT_TABLE ||
        route.includes(PATH_PURCHASE_REQUEST) ||
        route.includes(PATH_TRANSFER_REQUEST) ||
        route.includes(PATH_ROLES_PERMISSIONS_List) ||
        route.includes(PATH_BILL_PROMOTION) ||
        route.includes(PATH_PURCHASE_ORDER) ||
        route.includes(PATH_GOOD_RECEIVE) ||
        route.includes(PATH_SUPPLIER) ||
        route.includes(PATH_PRODUCT) ||
        route.includes(PATH_PROMOTION_CREATE) ||
        route.includes(PATH_EDIT_QTY_PRODUCT) ||
        route.includes(PATH_STORE) ||
        route.includes(PATH_STOCK_LIST) ||
        route.includes(PATH_RECIPE_LIST) ||
        route.includes(PATH_RECIPE_ASSIGN) ||
        route.includes(PATH_ADD_ON_PRODUCT_CATEGORY) ||
        route.includes(PATH_CREATE_ADDON) ||
        route.includes(PATH_CHECK_STOCK) ||
        route.includes(PATH_WASTE_RECORD) ||
        route.includes(PATH_PROMOTION) ||
        route.includes(PATH_STOCK) ||
        route.includes(PATH_WASTE) ||
        route.includes(PATH_PERMISSION_POS);

    const isNewsRoutes = (route: string) =>
        route === PATH_NEWS ||
        route === PATH_NEWS_ALL ||
        route === PATH_NEWS_HIGHLIGHT ||
        route === PATH_CREATE_NEWS ||
        route.includes(PATH_NEWS);

    return (
        <>
            {!noSidebarRoute() && (
                <>
                    {!app.mobile && (
                        <div className="side-bar-menu">
                            <StyledSidebar>
                                <div className="sidebar-wrap">
                                    <Sider
                                        width={240}
                                        className={`bg-${sidebarTheme}`}
                                        theme={sidebarTheme}
                                        collapsed={collapsed}
                                    >
                                        {isNewsRoutes(pathname) && (
                                            <ComponentNews
                                                sidebarTheme={"light"}
                                                sidebarMode={"inline"}
                                                sidebarIcons={true}
                                                collapsed={false}
                                            />
                                        )}
                                        {isStorefrontRoutes(pathname) && (
                                            <ComponentStorefront
                                                sidebarTheme={"light"}
                                                sidebarMode={"inline"}
                                                sidebarIcons={true}
                                                collapsed={false}
                                            />
                                        )}
                                    </Sider>
                                </div>
                            </StyledSidebar>
                            <StyledVersion>
                                <p>Rocket Merchant Version</p>
                                <p>v.1.38.3</p>
                            </StyledVersion>
                        </div>
                    )}
                </>
            )}
            <ComponentMobileSidebar />
            <ComponentProfileSidebar />
        </>
    );
};
const StyledVersion = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 20px 39px 10px 39px;
    p {
        margin: 0;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #a5a5a5;
    }
`;
