import { Route, useHistory, useLocation } from "react-router";
import { useSelector } from "react-redux";
import { IRoute } from "@interfaces";
import { LayoutApp } from "@layouts";
import { PATH_IMPORT_TABLE, PATH_STORE, PATH_STORE_CREATE, PATH_STORE_LIST } from "@configs";
import { useTranslation } from "react-i18next";
import { useNotify } from "@utils";
import { selectAuth } from "@redux";
import { useEffect } from "react";
import { adminAPI } from "@api";

export const ComponentAppRoute = (props: IRoute) => {
    const { path, exact, permission }: any = props;
    const history = useHistory();
    const { t } = useTranslation();
    const { warning } = useNotify();
    const { pathname } = useLocation();
    const { error } = useNotify();
    const userPermissionStoreList: string[] = useSelector(selectAuth).userInfo?.storeIds || [];
    const { adminPermission } = useSelector(selectAuth);
    const Component = props.component;
    const Layout = props.layout || LayoutApp;

    useEffect(() => {
        if (userPermissionStoreList.length !== 0) {
            if (
                pathname !== PATH_STORE_LIST &&
                pathname !== PATH_STORE_CREATE &&
                !pathname.includes(PATH_STORE)
            ) {
                warning(t("message.not_permission"));
                history.push(PATH_STORE_LIST);
            }
        }
    }, []);

    const getNestedPermission = (permissions, permissionKey) => {
        const keys = permissionKey.split(".");
        let currentPermissions = permissions;
        for (const key of keys) {
            if (currentPermissions && key in currentPermissions) {
                currentPermissions = currentPermissions[key];
            } else {
                // Construct the nested key dynamically
                const nestedKey = Object.keys(currentPermissions).find(
                    (k) => currentPermissions[k] && currentPermissions[k][key] !== undefined
                );
                if (!nestedKey) {
                    return undefined; // No nested object found
                }
                currentPermissions = currentPermissions[nestedKey];
                const nestedPermission = currentPermissions[key];
                if (nestedPermission !== undefined && nestedPermission !== null) {
                    return nestedPermission;
                } else {
                    return undefined;
                }
            }
        }
        return currentPermissions;
    };

    useEffect(() => {
        if (adminPermission) {
            getUserData();
        }
    }, [adminPermission]);

    const getUserData = () => {
        try {
            if (adminPermission) {
                const userPermissions = adminPermission.pos_role;
                if (Object.keys(userPermissions).length !== 0) {
                    if (
                        getNestedPermission(userPermissions, permission) ||
                        path.includes("roles-permissions") ||
                        path.includes("store")
                    ) {
                        return;
                    } else {
                        warning(t("message.not_permission"));
                        history.push(PATH_STORE_LIST);
                    }
                } else {
                    return null;
                }
            }
        } catch (err: any) {
            error(err.message);
        }
    };

    return (
        <>
            <Route
                path={path}
                exact={exact}
                render={() => (
                    <Layout>
                        <Component />
                    </Layout>
                )}
            />
        </>
    );
};
