import { useEffect, memo, useState, useRef, ChangeEvent } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import xlsx from "json-as-xlsx";
import {
    Row,
    Col,
    Radio,
    Checkbox,
    Input,
    Table,
    Form,
    TimePicker,
    Switch,
    Divider,
    Button,
    DatePicker,
    Select,
} from "antd";
import styled from "styled-components";
import moment, { Moment } from "moment";
import {
    ICreatePosPromotion,
    ICreatePomotionProductList,
    IPromotionExample,
    IVariantFreeList,
    IGetPosPromotionCategorysExample,
    IPostCreateChannel,
    IGetPartnerByOrganizationIdExample,
} from "@interfaces";
import {
    StyledSubmitButton,
    StyledCancelButton,
    StyledCard,
    SharedInput,
    StyledTableButtonTop,
    BranchIcon,
} from "@components";
import { useYup } from "@validations";
import { setLoading, selectAuth } from "@redux";
import { showErrorMessage, theme, useNotify } from "@utils";
import {
    BLANK_IMAGE_URL,
    dateFormatYear,
    enumAddNews,
    PAGE_START,
    PAGINATION,
    PATH_PROMOTION_CREATE,
    PATH_PROMOTION_LIST,
} from "@configs";
import { orgApi, promotionAPI, storeAPI, wholesaleAPI } from "@api";
import { ColumnProps } from "antd/lib/table";
import { AssignDeleteIcon } from "src/Components/icon/AssignDeleteIcon";
import { SmallEditIcon } from "src/Components/icon/SmallEditIcon";
import {
    ComponentAddAddonModal,
    ComponentAddPromotionModal,
} from "src/Components/shared/promotion";
import { RadioChangeEvent } from "antd/lib/radio";
import { DeleteIcon } from "src/Components/icon/DeleteIcon";
import { ExportIcon } from "src/Components/icon/ExportIcon";
import * as XLSX from "xlsx";
import { ComponentPromotionCategoryDrawer, ComponentPromotionChannelIncludeModal } from "src/Components/promotion";
import { ComponentPromotionExcludeModal } from "src/Components/promotion/PromotionExcludeModal";
import { ModuleUploadImage } from "../form";
interface IProps {
    promotion?: IPromotionExample;
}

const initData: ICreatePosPromotion = {
    organization_id: "",
    pos_promotion_name: "",
    client_type: "",
    promotion_type: "",
    condition_type: "qty",
    promotion_hour_type: "all_hour",
    promotion_hours: {
        monday: {
            is_active: true,
            open_hour: "00:00",
            close_hour: "23:59",
        },
        tuesday: {
            is_active: true,
            open_hour: "00:00",
            close_hour: "23:59",
        },
        wednesday: {
            is_active: true,
            open_hour: "00:00",
            close_hour: "23:59",
        },
        thursday: {
            is_active: true,
            open_hour: "00:00",
            close_hour: "23:59",
        },
        friday: {
            is_active: true,
            open_hour: "00:00",
            close_hour: "23:59",
        },
        saturday: {
            is_active: true,
            open_hour: "00:00",
            close_hour: "23:59",
        },
        sunday: {
            is_active: true,
            open_hour: "00:00",
            close_hour: "23:59",
        },
    },
    item_type: "product",
    product_list: [],
    product_category_list: [],
    variant_free_list: [],
    has_free_variant: false,
    discount: 0,
    new_price: 0,
    remark: "",
    is_discount_step: false,
    discount_step_list: [],
    has_promo_code: false,
    promo_code_type: "",
    unique_promo_code_list: [],
    pos_promotion_category_id: "",
    sale_channel_id: "",
    is_all_sale_channel: false,
    tier_id: "",
    validity_store_list: [],
    sale_channel_id_list: [],
    is_validity_period: false,
    validity_date_from_string: moment().format(dateFormatYear).toString(),
    validity_date_to_string: moment().format(dateFormatYear).toString(),
    validity_time_from_string: "00:00",
    validity_time_to_string: "23:59",
    variant_list: [],
    promotion_group_list: [],
    variant_option: [],
    pos_promotion_image_url: "",
    is_not_deduct_basket: false,
};
const INITAL_PAGE = {
    xpage: PAGE_START,
    xlimit: PAGINATION,
};

export const ModulePromotionForm = memo((props: IProps) => {
    const { promotion } = props;
    const { YupPromotion } = useYup();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { error, success, warning } = useNotify();
    const organization_id: any = useSelector(selectAuth).userInfo?.merchant?._id;
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const createNews = Yup.object().shape(YupPromotion);
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [showAddonModal, setShowAddonModal] = useState<boolean>(false);
    const pathName = history.location.pathname;
    const [promotionCategories, setPromotionCategories] =
        useState<IGetPosPromotionCategorysExample[]>();
    const [promotionChannels, setPromotionChannels] = useState<IPostCreateChannel[]>([]);
    const [showDrawer, setShowDrawer] = useState<boolean>(false);
    const [excludeModal, setExcludeModal] = useState<boolean>(false);
    const [channelModal, setChannelModal] = useState<boolean>(false);
    const [allStore, setAllStore] = useState<any[]>([]);
    const [partners, setPartners] = useState<IGetPartnerByOrganizationIdExample[]>([]);
    const [tempRecord, setTempRecord] = useState<any>({});
    const inputFile = useRef(null);


    const MONTHlIST = [
        t("month.jan"),
        t("month.feb"),
        t("month.mar"),
        t("month.apr"),
        t("month.may"),
        t("month.jun"),
        t("month.jul"),
        t("month.aug"),
        t("month.sep"),
        t("month.oct"),
        t("month.nov"),
        t("month.dec"),
    ];

    function formatDate(date) {
        let d = new Date(date),
            month = '' + d.getMonth(),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return [day, MONTHlIST[parseInt(month)], year + 543].join(' ');
    };

    const fetchCategories = async () => {
        // get category list and channel list
        try {
            const get = await orgApi.getPosPromotionCategorys({
                organization_id: organization_id,
            });
            const getChannels = await wholesaleAPI.getList({
                organization_id: organization_id,
                xpage: 1,
                xlimit: 100,
            });
            setPromotionCategories(get.data.result);
            setPromotionChannels([
                {
                    _id: "629dc8c29708d1e778e0b23b",
                    sale_channel_name: "Dine in",
                    sale_channel_code: "",
                    sale_channel_id: "",
                },
                ...getChannels.data.result
            ]);
        } catch (err: any) {
            error(err.message);
        }
    };
    const fetchNews = async () => {
        dispatch(setLoading(true));
        try {
            const getStores = await storeAPI.getList({
                organization_code: orgCode,
                xpage: INITAL_PAGE.xpage,
                xlimit: 4 * INITAL_PAGE.xlimit,
            });
            if (getStores.data.error === "0") {
                setAllStore(getStores.data.result);
            }
            const get = await orgApi.getPartnerByOrganizationId({
                organization_id: organization_id,
            });
            if (get.data.error === "0") {
                setPartners(get.data.result);
            }
        } catch (err) {
            console.error(err);
        } finally {
            dispatch(setLoading(false));
        }
    };

    useEffect(() => {
        fetchCategories();
        fetchNews();
        if (promotion) {
            setFieldValue("validity_store_list", promotion.validity_store_list);
            setFieldValue("sale_channel_id_list", promotion.sale_channel_id_list);
            setFieldValue("pos_promotion_name", promotion.pos_promotion_name);
            setFieldValue("pos_promotion_image_url", promotion.pos_promotion_image_url);
            setFieldValue("client_type", promotion.client_type);
            setFieldValue("promotion_hour_type", promotion.promotion_hour_type);
            setFieldValue("promotion_hours", promotion.promotion_hours);
            setFieldValue("promotion_type", promotion.promotion_type);
            setFieldValue("condition_type", promotion.condition_type);
            setFieldValue("item_type", promotion.item_type);
            setFieldValue("product_list", promotion.product_list);
            setFieldValue("product_category_list", promotion.product_category_list);
            setFieldValue("variant_free_list", promotion.variant_free_list);
            setFieldValue("discount", promotion.discount);
            setFieldValue("new_price", promotion.new_price);
            setFieldValue("is_discount_step", promotion.is_discount_step);
            setFieldValue("discount_step_list", promotion.discount_step_list);
            setFieldValue("has_promo_code", promotion.is_promo_code);
            setFieldValue("promo_code_type", promotion.promo_code_type);
            setFieldValue("fixed_promo_code", promotion.fixed_promo_code);
            setFieldValue("unique_promo_code_list", promotion.unique_promo_code_list);
            setFieldValue("pos_promotion_category_id", promotion.pos_promotion_category_id);
            setFieldValue("tier_id", promotion.tier_id);
            setFieldValue("is_validity_period", promotion.is_validity_period);
            setFieldValue("validity_date_from_string", promotion.validity_date_from_string);
            setFieldValue("validity_date_to_string", promotion.validity_date_to_string);
            setFieldValue("validity_time_from_string", promotion.validity_time_from_string);
            setFieldValue("validity_time_to_string", promotion.validity_time_to_string);
            setFieldValue("variant_list", promotion.variant_list);
            setFieldValue("is_dynamic_freebie", promotion.is_dynamic_freebie);
            setFieldValue("dynamic_freebie_amount", promotion.dynamic_freebie_amount);
            setFieldValue("promotion_group_list", promotion.promotion_group_list);
            setFieldValue("pos_promotion_image_url", promotion.pos_promotion_image_url);
            setFieldValue("is_not_deduct_basket", promotion.is_not_deduct_basket);
            setFieldValue("is_exclusive", promotion.is_exclusive);
            setFieldValue("sale_channel_id", promotion.sale_channel_id);
            setFieldValue("is_all_sale_channel", promotion.is_all_sale_channel);
            if (promotion.variant_option) {
                setFieldValue(
                    "variant_option",
                    Array.isArray(promotion.variant_option)
                        ? promotion.variant_option
                        : [
                            {
                                product_id: (promotion.variant_option as any)?.product_id,
                                variant_id: (promotion.variant_option as any)?.variant_id,
                                addon_id: (promotion.variant_option as any)?.addon_id,
                                option_id: (promotion.variant_option as any)?.option_id,
                                need_amount: (promotion.variant_option as any)?.need_amount,
                                name:
                                    ((promotion.variant_option as any)?.variant_name || "") +
                                    ` (${(promotion.variant_option as any)?.option_name || ""})`,
                            },
                        ]
                );
            }
        }
    }, [promotion]);

    const handleAssignSubmit = async () => {
        dispatch(setLoading(true));
        try {
            let out_variant_option: any = values.variant_option;
            // values.item_type === "variant_option" ? values.variant_option : [],
            if (values.item_type === "variant_option") {
                if (values?.variant_option?.[0]) {
                    out_variant_option = values?.variant_option?.[0];
                } else {
                    error("please select variant + addon");
                    return;
                }
            }
            if (pathName === PATH_PROMOTION_CREATE) {
                const save = await promotionAPI.createPosPromotion({
                    organization_id: organization_id,
                    pos_promotion_name: values.pos_promotion_name,
                    client_type: values.client_type,
                    promotion_hour_type: values.promotion_hour_type,
                    promotion_hours: values.promotion_hours,
                    promotion_type: values.promotion_type,
                    condition_type: values.condition_type,
                    item_type: values.item_type,
                    product_list: values.item_type === "product" ? values.product_list : [],
                    product_category_list:
                        values.item_type === "category" ? values.product_category_list : [],
                    variant_free_list: values.variant_free_list,
                    discount: values.discount,
                    new_price: values.new_price,
                    remark: values.remark,
                    is_discount_step: values.is_discount_step,
                    discount_step_list: values.discount_step_list,
                    pos_promotion_category_id: values.pos_promotion_category_id,
                    sale_channel_id: values.sale_channel_id,
                    tier_id: values.tier_id,
                    is_all_sale_channel: values.is_all_sale_channel,
                    validity_store_list: values.validity_store_list,
                    sale_channel_id_list: values.sale_channel_id_list,
                    is_validity_period: values.is_validity_period,
                    validity_date_from_string: values.is_validity_period
                        ? values.validity_date_from_string
                        : "",
                    validity_date_to_string: values.is_validity_period
                        ? values.validity_date_to_string
                        : "",
                    validity_time_from_string: values.is_validity_period
                        ? values.validity_time_from_string
                        : "",
                    validity_time_to_string: values.is_validity_period
                        ? values.validity_time_to_string
                        : "",
                    variant_list: values.item_type === "variant" ? values.variant_list : [],
                    is_dynamic_freebie: values.is_dynamic_freebie,
                    dynamic_freebie_amount: values.dynamic_freebie_amount,
                    variant_option:
                        out_variant_option,
                    promotion_group_list:
                        values.item_type === "promotion_group" ? values.promotion_group_list : [],
                    pos_promotion_image_url: values.pos_promotion_image_url,
                    is_not_deduct_basket: values.is_not_deduct_basket,
                    is_exclusive: values.is_exclusive,
                });
                if (save.data.error === "0") {
                    success(t("message.create.success"));
                    history.push(PATH_PROMOTION_LIST);
                } else {
                    error(save.data.msg);
                }
            } else {
                const save = await promotionAPI.updatePosPromotion({
                    pos_promotion_id: promotion?._id,
                    organization_id: organization_id,
                    pos_promotion_name: values.pos_promotion_name,
                    client_type: values.client_type,
                    promotion_hour_type: values.promotion_hour_type,
                    promotion_hours: values.promotion_hours,
                    promotion_type: values.promotion_type,
                    condition_type: values.condition_type,
                    item_type: values.item_type,
                    product_list: values.item_type === "product" ? values.product_list : [],
                    product_category_list:
                        values.item_type === "category" ? values.product_category_list : [],
                    variant_free_list: values.variant_free_list,
                    discount: values.discount,
                    new_price: values.new_price,
                    remark: values.remark,
                    is_discount_step: values.is_discount_step,
                    discount_step_list: values.discount_step_list,
                    is_promo_code: values.has_promo_code,
                    promo_code_type: values.promo_code_type,
                    fixed_promo_code:
                        values.promo_code_type === "fixed_code" ? values.fixed_promo_code : "",
                    unique_promo_code_list: values.unique_promo_code_list,
                    pos_promotion_category_id: values.pos_promotion_category_id,
                    sale_channel_id: values.sale_channel_id,
                    is_all_sale_channel: values.is_all_sale_channel,
                    tier_id: values.tier_id,
                    validity_store_list: values.validity_store_list,
                    sale_channel_id_list: values.sale_channel_id_list,
                    is_validity_period: values.is_validity_period,
                    validity_date_from_string: values.is_validity_period
                        ? values.validity_date_from_string
                        : "",
                    validity_date_to_string: values.is_validity_period
                        ? values.validity_date_to_string
                        : "",
                    validity_time_from_string: values.is_validity_period
                        ? values.validity_time_from_string
                        : "",
                    validity_time_to_string: values.is_validity_period
                        ? values.validity_time_to_string
                        : "",
                    variant_list: values.item_type === "variant" ? values.variant_list : [],
                    is_dynamic_freebie: values.is_dynamic_freebie,
                    dynamic_freebie_amount: values.dynamic_freebie_amount,
                    variant_option:
                        out_variant_option,
                    promotion_group_list:
                        values.item_type === "promotion_group" ? values.promotion_group_list : [],
                    pos_promotion_image_url: values.pos_promotion_image_url,
                    is_not_deduct_basket: values.is_not_deduct_basket,
                    is_exclusive: values.is_exclusive,
                });
                if (save.data.error === "0") {
                    success(t("message.update.success"));
                    history.push(PATH_PROMOTION_LIST);
                } else {
                    error(save.data.msg);
                }
            }
        } catch (err: any) {
            if (err.response) {
                error(showErrorMessage(err.response));
            } else error(t("message.error"));
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleCancel = () => {
        history.push(PATH_PROMOTION_LIST);
    };
    const format = "HH:mm";
    const { values, handleChange, handleBlur, handleSubmit, errors, touched, setFieldValue } =
        useFormik({
            initialValues: initData,
            validationSchema: createNews,
            enableReinitialize: true,
            onSubmit: handleAssignSubmit,
        });
    const columns: ColumnProps<ICreatePomotionProductList>[] & any = [
        {
            dataIndex: "name",
            title:
                values.item_type === "product"
                    ? t("page.product")
                    : values.item_type === "variant" || values.item_type === "variant_option"
                        ? t("page.variant")
                        : values.item_type === "promotion_group"
                            ? t("page.product_collection")
                            : t("page.category"),
            key: "name",
            width: "50%",
            render: (text, record, index) => (
                <div
                    className="productName"
                    style={{
                        display: values.item_type === "variant_option" ? "flex" : "block",
                        placeItems: "center",
                        gap: 15,
                    }}
                >
                    {values.item_type === "product" && (
                        <img
                            src={
                                record.image_url_list ? record.image_url_list[0] : record.image_url
                            }
                            alt={text}
                        />
                    )}
                    {text}
                    {values.item_type === "variant_option" && (
                        <StyledTableButtonTop
                            type="danger"
                            size="default"
                            text={t("page.add_addon")}
                            onClick={() => {
                                handleOpenAddOnModal(record);
                            }}
                            className="add-btn"
                            icon="plus"
                        />
                    )}
                </div>
            ),
        },
        {
            dataIndex: "need_amount",
            title: values.condition_type === "qty" ? t("page.quantity") : t("page.amount"),
            key: "need_amount",
            render: (text, record, index) => (
                <StyledCustom>
                    <Input
                        type={"number"}
                        value={text}
                        minLength={0}
                        onChange={(value) => {
                            record.need_amount = parseInt(value.target.value);
                            setFieldValue("product_list", [...values.product_list]);
                        }}
                        suffix={values.condition_type === "sale_amount" ? "THB" : ""}
                    />
                </StyledCustom>
            ),
        },
        {
            dataIndex: values.item_type === "promotion_group" ? "promotion_group_id" : "product_id",
            key: values.item_type === "promotion_group" ? "promotion_group_id" : "product_id",
            title: () => {
                return <div style={{ textAlign: "center" }}>{t("page.delete")}</div>;
            },
            width: "15%",
            render: (text, record, index) => (
                <div
                    style={{
                        placeItems: "center",
                        display: "flex",
                        placeContent: "center",
                        cursor: "pointer",
                    }}
                    onClick={() => {
                        handleDelete(record);
                    }}
                >
                    <AssignDeleteIcon />
                </div>
            ),
        },
    ];
    const columns2: ColumnProps<IVariantFreeList>[] = [
        {
            dataIndex: "name",
            title: values.item_type === "product" ? t("page.product") : t("page.category"),
            key: "name",
            width: "50%",
            render: (text, record, index) => (
                <div className="productName">
                    <img src={record.image_url || BLANK_IMAGE_URL} alt={text} />
                    {text}
                </div>
            ),
        },
        {
            dataIndex: "free_qty",
            title: t("page.amount"),
            key: "free_qty",
            render: (text, record, index) => (
                <Input
                    type={"number"}
                    value={text}
                    minLength={0}
                    onChange={(value) => {
                        record.free_qty = parseInt(value.target.value);
                        setFieldValue("product_list", [...values.product_list]);
                    }}
                />
            ),
        },
        {
            dataIndex: "variant_id",
            key: "variant_id",
            title: () => {
                return <div style={{ textAlign: "center" }}>{t("page.delete")}</div>;
            },
            width: "15%",
            render: (text, record, index) => (
                <div
                    style={{
                        placeItems: "center",
                        display: "flex",
                        placeContent: "center",
                        cursor: "pointer",
                    }}
                    onClick={() => {
                        handleVariantDelete(text);
                    }}
                >
                    <AssignDeleteIcon />
                </div>
            ),
        },
    ];
    const handleDelete = (record) => {
        if (values.item_type === "variant_option") {
            if (values.variant_option) {
                setFieldValue(
                    "variant_option",
                    values.variant_option.filter((x) => x.variant_id !== record.variant_id)
                );
            }
        } else if (values.item_type === "variant") {
            if (values.variant_list) {
                setFieldValue(
                    "variant_list",
                    values.variant_list.filter((x) => x.variant_id !== record.variant_id)
                );
            }
        } else if (values.item_type === "category") {
            setFieldValue(
                "product_category_list",
                values.product_category_list.filter(
                    (x) => x.product_category_id !== record.product_category_id
                )
            );
        } else if (values.item_type === "promotion_group") {
            if (values.promotion_group_list) {
                setFieldValue(
                    "promotion_group_list",
                    values.promotion_group_list.filter(
                        (x) => x.promotion_group_id !== record.promotion_group_id
                    )
                );
            }
        } else {
            setFieldValue(
                "product_list",
                values.product_list.filter((x) => x.product_id !== record.product_id)
            );
        }
    };
    const handleVariantDelete = (id) => {
        setFieldValue(
            "variant_free_list",
            values.variant_free_list.filter((x) => x.variant_id !== id)
        );
    };
    const handlechangeBusinessHourType = (e: RadioChangeEvent) => {
        setFieldValue("promotion_hour_type", e.target.value);
    };
    const promotionByTypeHandler = (e: RadioChangeEvent) => {
        setFieldValue("item_type", e.target.value);
    };
    const overlapHandler = (e: RadioChangeEvent) => {
        setFieldValue("is_not_deduct_basket", e.target.value);
    };
    const clientTypeHandler = (e: RadioChangeEvent) => {
        setFieldValue("client_type", e.target.value);
    };
    const promotionTypeHandler = (e: RadioChangeEvent) => {
        setFieldValue("promotion_type", e.target.value);
    };
    const conditionTypeHandler = (e: RadioChangeEvent) => {
        setFieldValue("condition_type", e.target.value);
    };
    const isDynamicFreebieHandler = (e: RadioChangeEvent) => {
        setFieldValue("is_dynamic_freebie", e.target.value);
    };
    const handleCloseModal = () => {
        setShowPopup(!showPopup);
    };
    const handleCloseAddOnModal = () => {
        setShowAddonModal(false);
    };
    const handleOpenAddOnModal = (record) => {
        setShowAddonModal(true);
        setTempRecord(record);
    };
    const handleCloseDrawer = () => {
        setShowDrawer(!showDrawer);
    };
    const handleHasPromoCodeSwitch = (value) => {
        setFieldValue("has_promo_code", value);
    };
    const promoCodeTypeHandler = (e: RadioChangeEvent) => {
        setFieldValue("promo_code_type", e.target.value);
    };
    const validityStoreListHandler = (value) => {
        setFieldValue("validity_store_list", value);
    };
    const validityChannelListHandler = (value) => {
        setFieldValue("sale_channel_id_list", value);
    };
    const handleAddPromoSet = () => {
        let temp: any = values.unique_promo_code_list || [];
        temp.push({
            name: "",
            is_validity_period: false,
            generate_amount: 0,
            validity_date_from: {
                date: moment().format(dateFormatYear),
                time: "00:00",
            },
            validity_date_to: {
                date: moment().format(dateFormatYear),
                time: "00:00",
            },
            each_unique_promo_code: [],
        });
        setFieldValue("unique_promo_code_list", temp);
    };
    const autoGenerateFunc = () => {
        setFieldValue(
            "fixed_promo_code",
            Math.floor(1000000000000 + Math.random() * 9000000000000)
        );
    };

    const exportTemplate = async () => {
        await null;
        let arr: any[] = [
            { alienList: "exampleCode1" },
            { alienList: "exampleCode2" },
            { alienList: "exampleCode3" },
            { alienList: "exampleCode4" },
            { alienList: "exampleCode5" },
        ];
        const excelHeader = [
            { label: "alienList", value: "alienList" },
        ];
        const xlsxSettings = {
            fileName: "promo_code_template",
            extraLength: 3,
            writeMode: "writeFile",
            writeOptions: {},
            RTL: false,
        };
        let eData: any[] = [
            {
                sheet: "promo_code_template",
                columns: excelHeader,
                content: arr,
            },
        ];
        // @ts-ignore
        await xlsx(eData, xlsxSettings);
    };
    const clickFile = () => {

        //@ts-ignore
        inputFile.current.click();
    };

    const importFile = async (e: ChangeEvent<HTMLInputElement>) => {
        await null;
        //@ts-ignore
        if (e?.target?.files[0]) {
            try {
                const file = e.target.files[0];
                const formData = new FormData();
                //@ts-ignore
                formData.append("file", file);

                let res = await promotionAPI.uploadCouponExcel(formData);


                let codeList = res.data.alienCode.map((item) => {
                    return { code: item.alienList.toString() };
                });
                let index = Number(e.target.id);
                setFieldValue(`unique_promo_code_list[${index}].each_unique_promo_code`, codeList);
                setFieldValue(`unique_promo_code_list[${index}].generate_amount`, res.data.alienCode.length);

                let temp: any = values.unique_promo_code_list;
                temp[index].each_unique_promo_code = codeList;
                temp[index].generate_amount = res.data.alienCode.length;

                storeGeneratedCodes(temp[index]);




            } catch (err: any) {
                console.error(err);
                error(showErrorMessage(err.response));
            }
        }
    };


    const generateRandomCodesSet = (index: number) => {
        const data: any = [];

        let temp: any = values.unique_promo_code_list;
        const titleRow: any = [];
        temp.forEach((code) => {
            titleRow.push({ code: "code" });
        });
        data.push(titleRow);

        const promoList: any[] = [];

        for (let i = 0; i < temp[index].generate_amount; i++) {
            const row: any[] = [];
            temp.forEach((code) => {
                if (i < code.generate_amount) {
                    row.push({
                        code: Math.floor(1000000000000 + Math.random() * 9000000000000),
                    });
                } else {
                    row.push({ code: "" });
                }
            });

            const rowData: any = {};
            row.forEach((col, colIndex) => {
                const titleCol = titleRow[colIndex].code;
                rowData[titleCol] = col[titleCol];
            });

            promoList.push(rowData);
        }
        temp[index].each_unique_promo_code = promoList;
        setFieldValue(`unique_promo_code_list[${index}].each_unique_promo_code`, promoList);
        storeGeneratedCodes(temp[index]);
        return data;
    };

    const storeGeneratedCodes = async (unique_promo) => {
        try {
            const save = await promotionAPI.createPromoCodeToPosPromotion({
                organization_id: organization_id,
                pos_promotion_id: promotion?._id,
                is_promo_code: values.has_promo_code,
                promo_code_type: values.promo_code_type,
                fixed_promo_code: values.fixed_promo_code || "",
                unique_promo_code: unique_promo,
            });
            if (save.data.error === "0") {
                success(t("message.update.success"));
                setFieldValue("unique_promo_code_list", save.data.result.unique_promo_code_list);
            } else {
                error(save.data.msg);
            }
        } catch (err: any) {
            error(showErrorMessage(err.response));
        }
    };
    const deleteGeneratedCodes = async (promo_id) => {
        try {
            const del = await promotionAPI.deletePromoCodeToPosPromotion({
                organization_id: organization_id,
                pos_promotion_id: promotion?._id,
                unique_promo_code_id: promo_id,
            });
            if (del.data.error === "0") {
                success(t("message.update.success"));
            } else {
                error(del.data.msg);
            }
        } catch (err: any) {
            error(showErrorMessage(err.response));
        }
    };

    const generateRandomCodes = () => {
        const data: any = [];

        let temp: any = values.unique_promo_code_list;
        const titleRow: any = [];
        temp.forEach((code) => {
            titleRow.push(code.name);
            titleRow.push(`Validity`);
        });
        data.push(titleRow);

        const maxGenerateAmount = temp.reduce(
            (max, code) => Math.max(max, code.generate_amount),
            0
        );

        for (let i = 0; i < maxGenerateAmount; i++) {
            const row: any = [];
            temp.forEach((code) => {
                const uniqueCodeIndex = Math.min(i, code.each_unique_promo_code.length - 1);
                if (i < code.generate_amount) {
                    row.push(code.each_unique_promo_code[uniqueCodeIndex].code);
                    row.push(`${code.validity_date_from.date} - ${code.validity_date_to.date}`);
                } else {
                    row.push("");
                    row.push("");
                }
            });
            data.push(row);
        }

        return data;
    };

    const exportToExcel = (data) => {
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Promo Codes");

        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
        const excelData = new Blob([excelBuffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const excelUrl = URL.createObjectURL(excelData);

        const link = document.createElement("a");
        link.href = excelUrl;
        link.download = "promo_codes.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleButtonClick = () => {
        // const randomCodes = generateRandomCodes();
        // exportToExcel(randomCodes);
        handleExport();
    };
    const handleExport = async () => {
        await null;
        let temp: any = values.unique_promo_code_list;
        let eData: any[] = [];
        const excelHeader = [
            { label: "code", value: "code" },
            { label: "used", value: "used" },
        ];
        let eSheet: any[] = temp.map((item, index) => {
            return {
                sheet: ("Code" + (index + 1)),
                columns: excelHeader,
                content: item?.each_unique_promo_code?.map((cItem) => {
                    return {
                        code: cItem.code,
                        used: cItem?.used_date ? formatDate(cItem?.used_date) : "",
                    };
                }) || [],
            };
        });

        const xlsxSettings = {
            fileName: "Promotion Code",
            extraLength: 3,
            writeMode: "writeFile",
            writeOptions: {},
            RTL: false,
        };

        // @ts-ignore
        await xlsx(eSheet, xlsxSettings);
    };
    const { Option } = Select;

    const handleUploadImage = (images: string[]) => {
        setFieldValue("pos_promotion_image_url", images[0]);
    };

    return (
        <div className="form-wrap">
            <Form>
                <StyledCustom>
                    <div className="page-header">
                        <h3>{t("page.promotion")}</h3>
                    </div>
                    <div className="page-body">
                        <div className="card-wrap">
                            <StyledCard>
                                <div className="title" style={{ fontSize: "25px" }}>
                                    {t("page.promotion_setting")}
                                </div>
                                <Row>
                                    <Col span={24}>
                                        <SharedInput
                                            label={t("page.promotion_name")}
                                            name="pos_promotion_name"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.pos_promotion_name}
                                            errors={errors.pos_promotion_name}
                                            touched={touched.pos_promotion_name}
                                            parentClassName="mb-6"
                                        />
                                    </Col>
                                </Row>
                                <Row className="topRadioButtonRows" gutter={32}>
                                    <Col span={24}>
                                        <div>
                                            <div className="label">{t("page.type_customer")}*</div>
                                            <Radio.Group
                                                onChange={clientTypeHandler}
                                                value={values.client_type}
                                            >
                                                <Radio value={"all"}>
                                                    {t("page.all_customer")}
                                                </Radio>
                                                <Radio value={"member"}>
                                                    {t("page.member_only")}
                                                </Radio>
                                            </Radio.Group>
                                        </div>
                                    </Col>
                                </Row>
                                <Row
                                    gutter={32}
                                    style={{
                                        marginTop: 40,
                                        marginBottom: 40,
                                    }}
                                >
                                    <Col md={12}>
                                        <StyledSwitch
                                            className="switch-field"
                                            style={{
                                                gap: "10px",
                                                marginBottom: 0,
                                            }}
                                        >
                                            <Switch
                                                className="ant-switch"
                                                onChange={(value) => {
                                                    setFieldValue(`is_exclusive`, value);
                                                }}
                                                checked={values.is_exclusive}
                                            />
                                            <div
                                                className="label"
                                                style={{
                                                    marginBottom: 0,
                                                }}
                                            >
                                                {t("page.exclusive_promotion")}
                                            </div>
                                        </StyledSwitch>
                                    </Col>
                                </Row>
                                <Row className="topRadioButtonRows" gutter={32}>
                                    <Col span={8}>
                                        <div className="label">{t("page.category")}</div>
                                        <Select
                                            showSearch
                                            filterOption={(input, option) =>
                                                //@ts-ignore
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            style={{ width: "100%", height: 48 }}
                                            placeholder={t("page.category")}
                                            onChange={(value: string) => {
                                                if (value === enumAddNews.ADD_NEWS) {
                                                    setShowDrawer(true);
                                                } else {
                                                    setFieldValue(
                                                        "pos_promotion_category_id",
                                                        value
                                                    );
                                                }
                                            }}
                                            defaultActiveFirstOption={true}
                                            value={values.pos_promotion_category_id}
                                        >
                                            {promotionCategories &&
                                                promotionCategories.map((item, index) => {
                                                    return (
                                                        <Option key={index} value={item._id}>
                                                            {item.category}
                                                        </Option>
                                                    );
                                                })}
                                            <Option key={"add-new"} value={enumAddNews.ADD_NEWS}>
                                                {"+"} {t("page.add_category")}
                                            </Option>
                                        </Select>
                                    </Col>
                                    {values.client_type === "member" && (
                                        <Col span={8}>
                                            <div className="label">{t("page.tier")}</div>
                                            <Select
                                                showSearch
                                                filterOption={(input, option) =>
                                                    //@ts-ignore
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                style={{ width: "100%", height: 48 }}
                                                placeholder={t("page.tier")}
                                            >
                                                <Option key={"test"} value={"test"}>
                                                    test
                                                </Option>
                                            </Select>
                                        </Col>
                                    )}
                                </Row>

                                <Row className="topRadioButtonRows" gutter={32}>
                                    <Col md={12}>

                                    </Col>
                                </Row>
                                <Row className="topRadioButtonRows" gutter={32}>
                                    <Col md={12}>
                                        <div className="label">
                                            {t("page.include_promo_branch")}
                                        </div>
                                        <div className="label">
                                            {t("page.all_branch")}
                                        </div>
                                        <Switch
                                            onChange={(e) => {
                                                if (e) {
                                                    validityStoreListHandler([]);
                                                } else {
                                                    setExcludeModal(true);
                                                }

                                            }}
                                            checked={values?.validity_store_list?.length === 0}
                                        />
                                        <Button
                                            style={{
                                                height: "42px",
                                                border: "0.5px solid #fff",
                                                borderRadius: "4px",
                                                background: theme?.colors?.main,
                                                color: theme?.colors?.white,
                                                marginRight: "10px",
                                            }}
                                            onClick={() => setExcludeModal(true)}
                                        >
                                            <BranchIcon /> &nbsp;
                                            {t("page.select_branch")}
                                        </Button>
                                    </Col>
                                </Row>
                                <Row className="topRadioButtonRows" gutter={32}>
                                    <Col span={24}>
                                        <div>
                                            <div className="label">{t("page.type_promotion")}*</div>
                                            <Radio.Group
                                                className="radioGroupRow"
                                                onChange={promotionTypeHandler}
                                                value={values.promotion_type}
                                            >
                                                <Radio value={"freebie"}>
                                                    <p>{t("page.free")}</p>
                                                    <div
                                                        className={
                                                            values.promotion_type === "freebie"
                                                                ? "radioBox activeRadioBtn"
                                                                : "radioBox"
                                                        }
                                                    >
                                                        {t("page.free")}
                                                    </div>
                                                </Radio>
                                                <Radio value={"discount_baht"}>
                                                    <p>{t("page.discount_by_baht")}</p>
                                                    <div
                                                        className={
                                                            values.promotion_type ===
                                                                "discount_baht"
                                                                ? "radioBox withIcon activeRadioBtn"
                                                                : "radioBox withIcon"
                                                        }
                                                    >
                                                        -99.0
                                                        <div
                                                            className={"grayCircle"}
                                                            style={{
                                                                margin: 0,
                                                                fontSize: 16,
                                                                width: 25,
                                                                height: 25,
                                                            }}
                                                        >
                                                            ฿
                                                        </div>
                                                    </div>
                                                </Radio>
                                                <Radio value={"discount_percent"}>
                                                    <p>{t("page.discount_by_percent")}</p>
                                                    <div
                                                        className={
                                                            values.promotion_type ===
                                                                "discount_percent"
                                                                ? "radioBox withIcon activeRadioBtn"
                                                                : "radioBox withIcon"
                                                        }
                                                    >
                                                        -9
                                                        <div
                                                            className={"grayCircle"}
                                                            style={{
                                                                margin: 0,
                                                                fontSize: 16,
                                                                width: 25,
                                                                height: 25,
                                                            }}
                                                        >
                                                            %
                                                        </div>
                                                    </div>
                                                </Radio>
                                                <Radio value={"change_price"}>
                                                    <p>{t("page.new_price")}</p>
                                                    <div
                                                        className={
                                                            values.promotion_type === "change_price"
                                                                ? "radioBox withIcon activeRadioBtn"
                                                                : "radioBox withIcon"
                                                        }
                                                    >
                                                        99
                                                        <SmallEditIcon />
                                                    </div>
                                                </Radio>
                                            </Radio.Group>
                                        </div>
                                    </Col>
                                </Row>
                                <Row gutter={32}>
                                    <Col span={12}>
                                        <StyledSwitch
                                            className="switch-field"
                                            style={{
                                                gap: "10px",
                                                marginBottom: 20,
                                            }}
                                        >
                                            <Switch
                                                className="ant-switch"
                                                onChange={(value) => {
                                                    setFieldValue(`is_all_sale_channel`, value);
                                                }}
                                                checked={values.is_all_sale_channel}
                                            />
                                            <div
                                                className="label"
                                                style={{
                                                    marginBottom: 0,
                                                }}
                                            >
                                                {t("page.all_sale_channel")}
                                            </div>
                                        </StyledSwitch>
                                    </Col>
                                </Row>
                                {!values.is_all_sale_channel && (
                                    <Row gutter={32}>
                                        <Col span={12}>
                                            <div className="label">{t("page.channel")}</div>
                                            <Button
                                                style={{
                                                    height: "42px",
                                                    border: "0.5px solid #fff",
                                                    borderRadius: "4px",
                                                    background: theme?.colors?.main,
                                                    color: theme?.colors?.white,
                                                    marginRight: "10px",
                                                }}
                                                onClick={() => setChannelModal(true)}
                                            >
                                                <BranchIcon /> &nbsp;
                                                {t("page.select_channel")}
                                            </Button>
                                        </Col>
                                    </Row>
                                )}
                                {/* {!values.is_all_sale_channel && (
                                    <Row gutter={32}>
                                        <Col span={12}>
                                            <div className="label">{t("page.channel")}</div>
                                            <Select
                                                style={{ width: "100%", height: 48 }}
                                                placeholder={t("page.channel")}
                                                onChange={(value: string) => {
                                                    setFieldValue("sale_channel_id", value);
                                                }}
                                                value={
                                                    values.sale_channel_id
                                                        ? values.sale_channel_id
                                                        : ""
                                                }
                                            >
                                                <Option
                                                    key={"disabled"}
                                                    value={"disabled"}
                                                    disabled
                                                >
                                                    {t("page.select_channel")}
                                                </Option>
                                                <Option key={""} value={""}>
                                                    {t("page.dine_in")}
                                                </Option>
                                                {promotionChannels &&
                                                    promotionChannels.map((item, index) => {
                                                        return (
                                                            <Option key={index} value={item._id}>
                                                                {item.sale_channel_name}
                                                            </Option>
                                                        );
                                                    })}
                                            </Select>
                                        </Col>
                                    </Row>
                                )} */}
                                <Row
                                    className="topRadioButtonRows"
                                    gutter={32}
                                    style={{ marginTop: 20, marginBottom: 0 }}
                                >
                                    <Col span={24} style={{ marginBottom: "20px" }}>
                                        <div className="label">{t("page.promotion_set_image")}</div>
                                        <ModuleUploadImage
                                            images={
                                                values.pos_promotion_image_url
                                                    ? [values.pos_promotion_image_url]
                                                    : []
                                            }
                                            handleGetImage={handleUploadImage}
                                        />
                                    </Col>
                                </Row>
                            </StyledCard>
                        </div>
                        <div className="card-wrap">
                            <StyledCard>
                                <Row gutter={32}>
                                    <Col md={24}>
                                        <div
                                            className="title"
                                            style={{ fontSize: "25px", color: "#000" }}
                                        >
                                            {t("page.day_hour_limit")}
                                        </div>
                                        <div className="businessHourTypeRow">
                                            <Radio.Group
                                                onChange={handlechangeBusinessHourType}
                                                value={values.promotion_hour_type}
                                            >
                                                <Radio value={"all_hour"}>
                                                    {t("page.all_hours")}
                                                </Radio>
                                                <Radio value={"specific_hour"}>
                                                    {t("page.specific_hours")}
                                                </Radio>
                                            </Radio.Group>
                                        </div>
                                        <div>
                                            <StyledSwitch>
                                                <div className="weekDaysRow">
                                                    <Checkbox
                                                        checked={
                                                            values.promotion_hours.monday.is_active
                                                        }
                                                        onChange={(e) => {
                                                            setFieldValue(
                                                                "promotion_hours.monday",
                                                                {
                                                                    ...values.promotion_hours
                                                                        .monday,
                                                                    is_active: e.target.checked,
                                                                }
                                                            );
                                                        }}
                                                    >
                                                        <p className="daysOfTheWeek">
                                                            {t("day.mon")}
                                                        </p>
                                                        {values.promotion_hour_type ===
                                                            "specific_hour" && (
                                                                <>
                                                                    <TimePicker
                                                                        defaultValue={moment(
                                                                            "12:08",
                                                                            format
                                                                        )}
                                                                        value={moment(
                                                                            values.promotion_hours
                                                                                .monday.open_hour,
                                                                            format
                                                                        )}
                                                                        format={format}
                                                                        size="large"
                                                                        onChange={(value) => {
                                                                            setFieldValue(
                                                                                "promotion_hours.monday",
                                                                                {
                                                                                    ...values
                                                                                        .promotion_hours
                                                                                        .monday,
                                                                                    open_hour: value
                                                                                        .toDate()
                                                                                        .toTimeString()
                                                                                        .split(" ")[0]
                                                                                        .slice(0, -3),
                                                                                }
                                                                            );
                                                                        }}
                                                                    />
                                                                    -
                                                                    <TimePicker
                                                                        defaultValue={moment(
                                                                            "12:09",
                                                                            format
                                                                        )}
                                                                        value={moment(
                                                                            values.promotion_hours
                                                                                .monday.close_hour,
                                                                            format
                                                                        )}
                                                                        format={format}
                                                                        size="large"
                                                                        onChange={(value) => {
                                                                            setFieldValue(
                                                                                "promotion_hours.monday",
                                                                                {
                                                                                    ...values
                                                                                        .promotion_hours
                                                                                        .monday,
                                                                                    close_hour: value
                                                                                        .toDate()
                                                                                        .toTimeString()
                                                                                        .split(" ")[0]
                                                                                        .slice(0, -3),
                                                                                }
                                                                            );
                                                                        }}
                                                                    />
                                                                </>
                                                            )}
                                                    </Checkbox>
                                                </div>
                                            </StyledSwitch>
                                            <StyledSwitch>
                                                <div className="weekDaysRow">
                                                    <Checkbox
                                                        checked={
                                                            values.promotion_hours.tuesday.is_active
                                                        }
                                                        onChange={(e) => {
                                                            setFieldValue(
                                                                "promotion_hours.tuesday",
                                                                {
                                                                    ...values.promotion_hours
                                                                        .tuesday,
                                                                    is_active: e.target.checked,
                                                                }
                                                            );
                                                        }}
                                                    >
                                                        <p className="daysOfTheWeek">
                                                            {t("day.tue")}
                                                        </p>
                                                        {values.promotion_hour_type ===
                                                            "specific_hour" && (
                                                                <>
                                                                    <TimePicker
                                                                        defaultValue={moment(
                                                                            "12:08",
                                                                            format
                                                                        )}
                                                                        value={moment(
                                                                            values.promotion_hours
                                                                                .tuesday.open_hour,
                                                                            format
                                                                        )}
                                                                        format={format}
                                                                        size="large"
                                                                        onChange={(value) => {
                                                                            setFieldValue(
                                                                                "promotion_hours.tuesday",
                                                                                {
                                                                                    ...values
                                                                                        .promotion_hours
                                                                                        .tuesday,
                                                                                    open_hour: value
                                                                                        .toDate()
                                                                                        .toTimeString()
                                                                                        .split(" ")[0]
                                                                                        .slice(0, -3),
                                                                                }
                                                                            );
                                                                        }}
                                                                    />
                                                                    -
                                                                    <TimePicker
                                                                        defaultValue={moment(
                                                                            "12:08",
                                                                            format
                                                                        )}
                                                                        value={moment(
                                                                            values.promotion_hours
                                                                                .tuesday.close_hour,
                                                                            format
                                                                        )}
                                                                        format={format}
                                                                        size="large"
                                                                        onChange={(value) => {
                                                                            setFieldValue(
                                                                                "promotion_hours.tuesday",
                                                                                {
                                                                                    ...values
                                                                                        .promotion_hours
                                                                                        .tuesday,
                                                                                    close_hour: value
                                                                                        .toDate()
                                                                                        .toTimeString()
                                                                                        .split(" ")[0]
                                                                                        .slice(0, -3),
                                                                                }
                                                                            );
                                                                        }}
                                                                    />
                                                                </>
                                                            )}
                                                    </Checkbox>
                                                </div>
                                            </StyledSwitch>
                                            <StyledSwitch>
                                                <div className="weekDaysRow">
                                                    <Checkbox
                                                        checked={
                                                            values.promotion_hours.wednesday
                                                                .is_active
                                                        }
                                                        onChange={(e) => {
                                                            setFieldValue(
                                                                "promotion_hours.wednesday",
                                                                {
                                                                    ...values.promotion_hours
                                                                        .wednesday,
                                                                    is_active: e.target.checked,
                                                                }
                                                            );
                                                        }}
                                                    >
                                                        <p className="daysOfTheWeek">
                                                            {t("day.wed")}
                                                        </p>
                                                        {values.promotion_hour_type ===
                                                            "specific_hour" && (
                                                                <>
                                                                    <TimePicker
                                                                        defaultValue={moment(
                                                                            "12:08",
                                                                            format
                                                                        )}
                                                                        value={moment(
                                                                            values.promotion_hours
                                                                                .wednesday.open_hour,
                                                                            format
                                                                        )}
                                                                        format={format}
                                                                        size="large"
                                                                        onChange={(value) => {
                                                                            setFieldValue(
                                                                                "promotion_hours.wednesday",
                                                                                {
                                                                                    ...values
                                                                                        .promotion_hours
                                                                                        .wednesday,
                                                                                    open_hour: value
                                                                                        .toDate()
                                                                                        .toTimeString()
                                                                                        .split(" ")[0]
                                                                                        .slice(0, -3),
                                                                                }
                                                                            );
                                                                        }}
                                                                    />
                                                                    -
                                                                    <TimePicker
                                                                        defaultValue={moment(
                                                                            "12:08",
                                                                            format
                                                                        )}
                                                                        value={moment(
                                                                            values.promotion_hours
                                                                                .wednesday.close_hour,
                                                                            format
                                                                        )}
                                                                        format={format}
                                                                        size="large"
                                                                        onChange={(value) => {
                                                                            setFieldValue(
                                                                                "promotion_hours.wednesday",
                                                                                {
                                                                                    ...values
                                                                                        .promotion_hours
                                                                                        .wednesday,
                                                                                    close_hour: value
                                                                                        .toDate()
                                                                                        .toTimeString()
                                                                                        .split(" ")[0]
                                                                                        .slice(0, -3),
                                                                                }
                                                                            );
                                                                        }}
                                                                    />
                                                                </>
                                                            )}
                                                    </Checkbox>
                                                </div>
                                            </StyledSwitch>
                                            <StyledSwitch>
                                                <div className="weekDaysRow">
                                                    <Checkbox
                                                        checked={
                                                            values.promotion_hours.thursday
                                                                .is_active
                                                        }
                                                        onChange={(e) => {
                                                            setFieldValue(
                                                                "promotion_hours.thursday",
                                                                {
                                                                    ...values.promotion_hours
                                                                        .thursday,
                                                                    is_active: e.target.checked,
                                                                }
                                                            );
                                                        }}
                                                    >
                                                        <p className="daysOfTheWeek">
                                                            {t("day.thu")}
                                                        </p>
                                                        {values.promotion_hour_type ===
                                                            "specific_hour" && (
                                                                <>
                                                                    <TimePicker
                                                                        defaultValue={moment(
                                                                            "12:08",
                                                                            format
                                                                        )}
                                                                        value={moment(
                                                                            values.promotion_hours
                                                                                .thursday.open_hour,
                                                                            format
                                                                        )}
                                                                        format={format}
                                                                        size="large"
                                                                        onChange={(value) => {
                                                                            setFieldValue(
                                                                                "promotion_hours.thursday",
                                                                                {
                                                                                    ...values
                                                                                        .promotion_hours
                                                                                        .thursday,
                                                                                    open_hour: value
                                                                                        .toDate()
                                                                                        .toTimeString()
                                                                                        .split(" ")[0]
                                                                                        .slice(0, -3),
                                                                                }
                                                                            );
                                                                        }}
                                                                    />
                                                                    -
                                                                    <TimePicker
                                                                        defaultValue={moment(
                                                                            "12:08",
                                                                            format
                                                                        )}
                                                                        value={moment(
                                                                            values.promotion_hours
                                                                                .thursday.close_hour,
                                                                            format
                                                                        )}
                                                                        format={format}
                                                                        size="large"
                                                                        onChange={(value) => {
                                                                            setFieldValue(
                                                                                "promotion_hours.thursday",
                                                                                {
                                                                                    ...values
                                                                                        .promotion_hours
                                                                                        .thursday,
                                                                                    close_hour: value
                                                                                        .toDate()
                                                                                        .toTimeString()
                                                                                        .split(" ")[0]
                                                                                        .slice(0, -3),
                                                                                }
                                                                            );
                                                                        }}
                                                                    />
                                                                </>
                                                            )}
                                                    </Checkbox>
                                                </div>
                                            </StyledSwitch>
                                            <StyledSwitch>
                                                <div className="weekDaysRow">
                                                    <Checkbox
                                                        checked={
                                                            values.promotion_hours.friday.is_active
                                                        }
                                                        onChange={(e) => {
                                                            setFieldValue(
                                                                "promotion_hours.friday",
                                                                {
                                                                    ...values.promotion_hours
                                                                        .friday,
                                                                    is_active: e.target.checked,
                                                                }
                                                            );
                                                        }}
                                                    >
                                                        <p className="daysOfTheWeek">
                                                            {t("day.fri")}
                                                        </p>
                                                        {values.promotion_hour_type ===
                                                            "specific_hour" && (
                                                                <>
                                                                    <TimePicker
                                                                        defaultValue={moment(
                                                                            "12:08",
                                                                            format
                                                                        )}
                                                                        value={moment(
                                                                            values.promotion_hours
                                                                                .friday.open_hour,
                                                                            format
                                                                        )}
                                                                        format={format}
                                                                        size="large"
                                                                        onChange={(value) => {
                                                                            setFieldValue(
                                                                                "promotion_hours.friday",
                                                                                {
                                                                                    ...values
                                                                                        .promotion_hours
                                                                                        .friday,
                                                                                    open_hour: value
                                                                                        .toDate()
                                                                                        .toTimeString()
                                                                                        .split(" ")[0]
                                                                                        .slice(0, -3),
                                                                                }
                                                                            );
                                                                        }}
                                                                    />
                                                                    -
                                                                    <TimePicker
                                                                        defaultValue={moment(
                                                                            "12:08",
                                                                            format
                                                                        )}
                                                                        value={moment(
                                                                            values.promotion_hours
                                                                                .friday.close_hour,
                                                                            format
                                                                        )}
                                                                        format={format}
                                                                        size="large"
                                                                        onChange={(value) => {
                                                                            setFieldValue(
                                                                                "promotion_hours.friday",
                                                                                {
                                                                                    ...values
                                                                                        .promotion_hours
                                                                                        .friday,
                                                                                    close_hour: value
                                                                                        .toDate()
                                                                                        .toTimeString()
                                                                                        .split(" ")[0]
                                                                                        .slice(0, -3),
                                                                                }
                                                                            );
                                                                        }}
                                                                    />
                                                                </>
                                                            )}
                                                    </Checkbox>
                                                </div>
                                            </StyledSwitch>
                                            <StyledSwitch>
                                                <div className="weekDaysRow">
                                                    <Checkbox
                                                        checked={
                                                            values.promotion_hours.saturday
                                                                .is_active
                                                        }
                                                        onChange={(e) => {
                                                            setFieldValue(
                                                                "promotion_hours.saturday",
                                                                {
                                                                    ...values.promotion_hours
                                                                        .saturday,
                                                                    is_active: e.target.checked,
                                                                }
                                                            );
                                                        }}
                                                    >
                                                        <p className="daysOfTheWeek">
                                                            {t("day.sat")}
                                                        </p>
                                                        {values.promotion_hour_type ===
                                                            "specific_hour" && (
                                                                <>
                                                                    <TimePicker
                                                                        defaultValue={moment(
                                                                            "12:08",
                                                                            format
                                                                        )}
                                                                        value={moment(
                                                                            values.promotion_hours
                                                                                .saturday.open_hour,
                                                                            format
                                                                        )}
                                                                        format={format}
                                                                        size="large"
                                                                        onChange={(value) => {
                                                                            setFieldValue(
                                                                                "promotion_hours.saturday",
                                                                                {
                                                                                    ...values
                                                                                        .promotion_hours
                                                                                        .saturday,
                                                                                    open_hour: value
                                                                                        .toDate()
                                                                                        .toTimeString()
                                                                                        .split(" ")[0]
                                                                                        .slice(0, -3),
                                                                                }
                                                                            );
                                                                        }}
                                                                    />
                                                                    -
                                                                    <TimePicker
                                                                        defaultValue={moment(
                                                                            "12:08",
                                                                            format
                                                                        )}
                                                                        value={moment(
                                                                            values.promotion_hours
                                                                                .saturday.close_hour,
                                                                            format
                                                                        )}
                                                                        format={format}
                                                                        size="large"
                                                                        onChange={(value) => {
                                                                            setFieldValue(
                                                                                "promotion_hours.saturday",
                                                                                {
                                                                                    ...values
                                                                                        .promotion_hours
                                                                                        .saturday,
                                                                                    close_hour: value
                                                                                        .toDate()
                                                                                        .toTimeString()
                                                                                        .split(" ")[0]
                                                                                        .slice(0, -3),
                                                                                }
                                                                            );
                                                                        }}
                                                                    />
                                                                </>
                                                            )}
                                                    </Checkbox>
                                                </div>
                                            </StyledSwitch>
                                            <StyledSwitch>
                                                <div className="weekDaysRow">
                                                    <Checkbox
                                                        checked={
                                                            values.promotion_hours.sunday.is_active
                                                        }
                                                        onChange={(e) => {
                                                            setFieldValue(
                                                                "promotion_hours.sunday",
                                                                {
                                                                    ...values.promotion_hours
                                                                        .sunday,
                                                                    is_active: e.target.checked,
                                                                }
                                                            );
                                                        }}
                                                    >
                                                        <p className="daysOfTheWeek">
                                                            {t("day.sun")}
                                                        </p>
                                                        {values.promotion_hour_type ===
                                                            "specific_hour" && (
                                                                <>
                                                                    <TimePicker
                                                                        defaultValue={moment(
                                                                            "12:08",
                                                                            format
                                                                        )}
                                                                        value={moment(
                                                                            values.promotion_hours
                                                                                .sunday.open_hour,
                                                                            format
                                                                        )}
                                                                        format={format}
                                                                        size="large"
                                                                        onChange={(value) => {
                                                                            setFieldValue(
                                                                                "promotion_hours.sunday",
                                                                                {
                                                                                    ...values
                                                                                        .promotion_hours
                                                                                        .sunday,
                                                                                    open_hour: value
                                                                                        .toDate()
                                                                                        .toTimeString()
                                                                                        .split(" ")[0]
                                                                                        .slice(0, -3),
                                                                                }
                                                                            );
                                                                        }}
                                                                    />
                                                                    -
                                                                    <TimePicker
                                                                        defaultValue={moment(
                                                                            "12:08",
                                                                            format
                                                                        )}
                                                                        value={moment(
                                                                            values.promotion_hours
                                                                                .sunday.close_hour,
                                                                            format
                                                                        )}
                                                                        format={format}
                                                                        size="large"
                                                                        onChange={(value) => {
                                                                            setFieldValue(
                                                                                "promotion_hours.sunday",
                                                                                {
                                                                                    ...values
                                                                                        .promotion_hours
                                                                                        .sunday,
                                                                                    close_hour: value
                                                                                        .toDate()
                                                                                        .toTimeString()
                                                                                        .split(" ")[0]
                                                                                        .slice(0, -3),
                                                                                }
                                                                            );
                                                                        }}
                                                                    />
                                                                </>
                                                            )}
                                                    </Checkbox>
                                                </div>
                                            </StyledSwitch>
                                        </div>
                                    </Col>
                                </Row>
                                <Row
                                    gutter={32}
                                    style={{
                                        marginTop: 40,
                                        marginBottom: 20,
                                    }}
                                >
                                    <Col md={12}>
                                        <StyledSwitch
                                            className="switch-field"
                                            style={{
                                                gap: "10px",
                                                marginBottom: 0,
                                            }}
                                        >
                                            <Switch
                                                className="ant-switch"
                                                onChange={(value) => {
                                                    setFieldValue(`is_validity_period`, value);
                                                }}
                                                checked={values.is_validity_period}
                                            />
                                            <div
                                                className="label"
                                                style={{
                                                    marginBottom: 0,
                                                }}
                                            >
                                                {t("page.validity_period")}
                                            </div>
                                        </StyledSwitch>
                                    </Col>
                                </Row>
                                <Row
                                    gutter={32}
                                    style={{
                                        marginBottom: 20,
                                    }}
                                >
                                    <Col md={16}>
                                        <div className="selectDateRow">
                                            <div
                                                className="label"
                                                style={{
                                                    marginBottom: 0,
                                                }}
                                            >
                                                {t("page.from")}
                                            </div>
                                            <DatePicker
                                                size={"large"}
                                                format={dateFormatYear}
                                                defaultValue={moment()}
                                                value={
                                                    values.validity_date_from_string
                                                        ? (moment(
                                                            values.validity_date_from_string,
                                                            dateFormatYear
                                                        ) as Moment)
                                                        : undefined
                                                }
                                                onChange={(value, dateString) => {
                                                    setFieldValue(
                                                        `validity_date_from_string`,
                                                        dateString
                                                    );
                                                }}
                                            />
                                            <TimePicker
                                                defaultValue={moment("12:08", format)}
                                                value={
                                                    values.validity_time_from_string
                                                        ? (moment(
                                                            values.validity_time_from_string,
                                                            format
                                                        ) as Moment)
                                                        : undefined
                                                }
                                                format={format}
                                                size="large"
                                                onChange={(value) => {
                                                    const formattedValue = value
                                                        ? moment(value).format(format)
                                                        : undefined;
                                                    setFieldValue(
                                                        `validity_time_from_string`,
                                                        formattedValue
                                                    );
                                                }}
                                            />
                                        </div>
                                        <div className="selectDateRow">
                                            <div
                                                className="label"
                                                style={{
                                                    marginBottom: 0,
                                                }}
                                            >
                                                {t("page.till")}
                                            </div>
                                            <DatePicker
                                                size={"large"}
                                                format={dateFormatYear}
                                                defaultValue={moment()}
                                                value={
                                                    values.validity_date_to_string
                                                        ? moment(
                                                            values.validity_date_to_string,
                                                            dateFormatYear
                                                        )
                                                        : undefined
                                                }
                                                onChange={(value, dateString) => {
                                                    setFieldValue(
                                                        `validity_date_to_string`,
                                                        dateString
                                                    );
                                                }}
                                            />
                                            <TimePicker
                                                defaultValue={moment("12:09", format)}
                                                value={
                                                    values.validity_time_to_string
                                                        ? moment(
                                                            values.validity_time_to_string,
                                                            format
                                                        )
                                                        : undefined
                                                }
                                                format={format}
                                                size="large"
                                                onChange={(value) => {
                                                    const formattedValue = value
                                                        ? moment(value).format(format)
                                                        : undefined;
                                                    setFieldValue(
                                                        `validity_time_to_string`,
                                                        formattedValue
                                                    );
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </StyledCard>
                        </div>
                        <div className="card-wrap">
                            <StyledCard
                                style={
                                    (values.item_type === "product" &&
                                        values.product_list.length === 0) ||
                                        (values.item_type === "category" &&
                                            values.product_category_list.length === 0) ||
                                        values.item_type === "promotion_group" ||
                                        values.item_type === "variant" ||
                                        values.item_type === "variant_option"
                                        ? { border: "3px solid #f4394f", borderRadius: 8 }
                                        : { border: "none" }
                                }
                            >
                                <div className="title" style={{ fontSize: "25px" }}>
                                    {t("page.condition_promotion")}
                                </div>
                                <Row className="topRadioButtonRows" gutter={32}>
                                    <Col span={24}>
                                        <div>
                                            <div className="label">
                                                {t("page.select_confition_promotion")}*
                                            </div>
                                            <Radio.Group
                                                onChange={promotionByTypeHandler}
                                                value={values.item_type}
                                            >
                                                <Radio value={"variant"}>
                                                    {t("page.by_variant")}
                                                </Radio>
                                                <Radio value={"variant_option"}>
                                                    {t("page.by_variant_add_on")}
                                                </Radio>
                                                <Radio value={"product"}>
                                                    {t("page.by_product")}
                                                </Radio>
                                                <Radio value={"category"}>
                                                    {t("page.by_cate")}
                                                </Radio>
                                                <Radio value={"promotion_group"}>
                                                    {t("page.product_collection")}
                                                </Radio>
                                            </Radio.Group>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="topRadioButtonRows" gutter={32}>
                                    <Col span={24}>
                                        <div>
                                            <div className="label">
                                                {t("page.overlap_condition")}
                                            </div>
                                            <Radio.Group
                                                onChange={overlapHandler}
                                                value={values.is_not_deduct_basket}
                                            >
                                                <Radio value={false}>
                                                    {t("page.cannot_overlap")}
                                                </Radio>
                                                <Radio value={true}>{t("page.can_overlap")}</Radio>
                                            </Radio.Group>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="topRadioButtonRows" gutter={32}>
                                    <Col span={24}>
                                        <div>
                                            <div
                                                className="label"
                                                style={{ display: "flex", gap: 5 }}
                                            >
                                                {t("page.order_amount")}*
                                                <p className="sub-title">
                                                    ({t("page.order_amount_desc")})
                                                </p>
                                            </div>
                                            <Radio.Group
                                                onChange={conditionTypeHandler}
                                                value={values.condition_type}
                                            >
                                                <Radio value={"qty"}>{t("page.quantity")}</Radio>
                                                <Radio value={"sale_amount"}>
                                                    {t("page.sale_amount")}
                                                </Radio>
                                            </Radio.Group>
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 40 }}>
                                    <div
                                        className="aboveOfTableRow"
                                        style={{ marginBottom: 20, placeItems: "end" }}
                                    >
                                        <div className="title" style={{ color: "#000" }}>
                                            {values.item_type === "product"
                                                ? t("page.select_product")
                                                : values.item_type === "variant" ||
                                                    values.item_type === "variant_option"
                                                    ? t("page.select_variant")
                                                    : values.item_type === "promotion_group"
                                                        ? t("page.select_product_collection")
                                                        : t("page.select_category")}
                                            *
                                        </div>
                                        <StyledTableButtonTop
                                            type="danger"
                                            size="default"
                                            text={
                                                values.item_type === "product"
                                                    ? t("page.add_product")
                                                    : values.item_type === "category"
                                                        ? t("page.add_category")
                                                        : values.item_type === "promotion_group"
                                                            ? t("page.add_product_collection")
                                                            : t("page.add_variant")
                                            }
                                            onClick={() => {
                                                setFieldValue("has_free_variant", false);
                                                setShowPopup(true);
                                            }}
                                            className="add-btn"
                                            icon="plus"
                                        />
                                    </div>
                                    <Table
                                        columns={columns}
                                        dataSource={
                                            values.item_type === "product"
                                                ? values.product_list
                                                : values.item_type === "variant"
                                                    ? values.variant_list
                                                    : values.item_type === "promotion_group"
                                                        ? values.promotion_group_list
                                                        : values.item_type === "variant_option"
                                                            ? values.variant_option
                                                            : values.product_category_list
                                        }
                                        bordered
                                        rowKey={
                                            values.item_type === "product"
                                                ? "product_id"
                                                : values.item_type === "variant" ||
                                                    values.item_type === "variant_option"
                                                    ? "variant_id"
                                                    : values.item_type === "promotion_group"
                                                        ? "promotion_group_id"
                                                        : "product_category_id"
                                        }
                                    />
                                </Row>
                            </StyledCard>
                        </div>
                        {values.promotion_type === "freebie" && (
                            <div className="card-wrap">
                                <StyledCard
                                    style={
                                        (values.item_type === "product" &&
                                            values.product_list.length !== 0) ||
                                            (values.item_type === "category" &&
                                                values.product_category_list.length !== 0)
                                            ? { border: "3px solid #f4394f", borderRadius: 8 }
                                            : { border: "none" }
                                    }
                                >
                                    <div className="title" style={{ fontSize: "25px" }}>
                                        {t("page.promotion_result_free_promotion")}
                                    </div>
                                    <Row gutter={32}>
                                        <Col span={24}>
                                            <div
                                                className="label"
                                                style={{ display: "flex", gap: 5 }}
                                            >
                                                {t("page.type_free")}
                                            </div>
                                            <Radio.Group
                                                onChange={isDynamicFreebieHandler}
                                                value={values.is_dynamic_freebie}
                                            >
                                                <Radio value={false}>{t("page.all_product")}</Radio>
                                                <Radio value={true}>
                                                    {t("page.choice_within_list")}
                                                </Radio>
                                            </Radio.Group>
                                        </Col>
                                    </Row>
                                    <Row style={{ marginTop: 20 }}>
                                        <Col md={5}>
                                            <div
                                                className="label"
                                                style={{ display: "flex", gap: 5 }}
                                            >
                                                {t("page.quantity_free")}
                                            </div>
                                            <Input
                                                addonAfter={"ชิ้น"}
                                                name="dynamic_freebie_amount"
                                                value={values.dynamic_freebie_amount}
                                                onChange={handleChange}
                                                type="number"
                                                placeholder="1"
                                            />
                                        </Col>
                                    </Row>
                                    <Row style={{ marginTop: 40 }}>
                                        <div
                                            className="aboveOfTableRow"
                                            style={{ marginBottom: 20, placeItems: "end" }}
                                        >
                                            <div className="title" style={{ color: "#000" }}>
                                                {t("page.free_product")}*
                                            </div>
                                            <StyledTableButtonTop
                                                type="danger"
                                                size="default"
                                                text={t("page.add_product")}
                                                onClick={() => {
                                                    setFieldValue("has_free_variant", true);
                                                    setShowPopup(true);
                                                }}
                                                className="add-btn"
                                                icon="plus"
                                            />
                                        </div>
                                        <Table
                                            columns={columns2}
                                            dataSource={values.variant_free_list}
                                            bordered
                                            rowKey={"variant_id"}
                                        />
                                    </Row>
                                </StyledCard>
                            </div>
                        )}
                        {(values.promotion_type === "discount_baht" ||
                            values.promotion_type === "discount_percent") && (
                                <div className="card-wrap">
                                    <StyledCard
                                        style={
                                            (values.item_type === "product" &&
                                                values.product_list.length !== 0) ||
                                                (values.item_type === "category" &&
                                                    values.product_category_list.length !== 0)
                                                ? { border: "3px solid #f4394f", borderRadius: 8 }
                                                : { border: "none" }
                                        }
                                    >
                                        <div className="title" style={{ fontSize: "25px" }}>
                                            {values.promotion_type === "discount_baht"
                                                ? t("page.promotion_result_thb_discount_amount")
                                                : t("page.promotion_result_percentage_discount")}
                                        </div>
                                        <Row>
                                            <Col span={12}>
                                                <SharedInput
                                                    label={t("page.discount")}
                                                    type={"number"}
                                                    name={"discount"}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.discount}
                                                    errors={errors.discount}
                                                    touched={touched.discount}
                                                    parentClassName="mb-6"
                                                    suffixIcon={
                                                        values.promotion_type === "discount_baht"
                                                            ? "THB"
                                                            : "%"
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </StyledCard>
                                </div>
                            )}
                        {values.promotion_type === "change_price" && (
                            <div className="card-wrap">
                                <StyledCard
                                    style={
                                        (values.item_type === "product" &&
                                            values.product_list.length !== 0) ||
                                            (values.item_type === "category" &&
                                                values.product_category_list.length !== 0)
                                            ? { border: "3px solid #f4394f", borderRadius: 8 }
                                            : { border: "none" }
                                    }
                                >
                                    <div className="title" style={{ fontSize: "25px" }}>
                                        {t("page.promotion_result_set_new_pricing")}
                                    </div>
                                    <Row>
                                        <Col span={12}>
                                            <SharedInput
                                                label={t("page.new_price")}
                                                type={"number"}
                                                name="new_price"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.new_price}
                                                errors={errors.new_price}
                                                touched={touched.new_price}
                                                parentClassName="mb-6"
                                                suffixIcon={"THB"}
                                            />
                                        </Col>
                                    </Row>
                                </StyledCard>
                            </div>
                        )}
                        {promotion && (
                            <div className="card-wrap">
                                <StyledCard>
                                    <Row gutter={32}>
                                        <Col md={24}>
                                            <div
                                                className="title"
                                                style={{ fontSize: "25px", color: "#000" }}
                                            >
                                                {t("page.promo_code")}
                                            </div>
                                            <StyledSwitch
                                                className="switch-field"
                                                style={{ gap: "10px" }}
                                            >
                                                <Switch
                                                    className="ant-switch"
                                                    onChange={handleHasPromoCodeSwitch}
                                                    checked={values.has_promo_code}
                                                />
                                                <p
                                                    style={{
                                                        fontSize: "16px",
                                                        color: "#646464",
                                                        fontWeight: 400,
                                                    }}
                                                >
                                                    {t("page.enable")}
                                                </p>
                                            </StyledSwitch>
                                            <Divider />
                                        </Col>
                                    </Row>
                                    {values.has_promo_code && (
                                        <>
                                            <Row gutter={32} style={{ marginBottom: 20 }}>
                                                <Col md={24}>
                                                    <div className="label">
                                                        {t("page.promo_code_type")}
                                                    </div>
                                                    <Radio.Group
                                                        onChange={promoCodeTypeHandler}
                                                        value={values.promo_code_type}
                                                    >
                                                        <Radio value={"fixed_code"}>
                                                            {t("page.fixed_code")}
                                                        </Radio>
                                                        <Radio value={"unique_code"}>
                                                            {t("page.unique_code")}
                                                        </Radio>
                                                    </Radio.Group>
                                                </Col>
                                            </Row>
                                            {values.promo_code_type === "fixed_code" && (
                                                <StyledBarcode>
                                                    <Row gutter={32}>
                                                        <Col md={12}>
                                                            <div
                                                                className="box_title"
                                                                style={{
                                                                    display: "flex",
                                                                    justifyContent: "space-between",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                <div className="label">
                                                                    {t("page.promo_code")}
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                    }}
                                                                >
                                                                    <p
                                                                        style={{
                                                                            fontSize: "14px",
                                                                            margin: 0,
                                                                            marginRight: 10,
                                                                            fontWeight: 700,
                                                                        }}
                                                                    >
                                                                        {t("page.auto_generate")}
                                                                    </p>
                                                                    <Switch
                                                                        className="ant-switch"
                                                                        onChange={(value) => {
                                                                            if (value === true) {
                                                                                autoGenerateFunc();
                                                                            } else {
                                                                                setFieldValue(
                                                                                    "fixed_promo_code",
                                                                                    ""
                                                                                );
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row className="barcodeRow" gutter={32}>
                                                        <Col md={12}>
                                                            <SharedInput
                                                                onChange={(value) => {
                                                                    setFieldValue(
                                                                        "fixed_promo_code",
                                                                        value.target.value
                                                                    );
                                                                }}
                                                                value={values.fixed_promo_code}
                                                                parentClassName="mb-6"
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Divider />
                                                </StyledBarcode>
                                            )}
                                            {values.promo_code_type === "unique_code" && (
                                                <>
                                                    {values.unique_promo_code_list?.map(
                                                        (promo_item, i) => (
                                                            <Row
                                                                gutter={32}
                                                                style={{ marginBottom: 20 }}
                                                                key={i}
                                                            >
                                                                <Col md={24}>
                                                                    <div className="promoSetBoxRow">
                                                                        <div className="promoSetBox">
                                                                            <div className="titleRow">
                                                                                <div
                                                                                    className="title"
                                                                                    style={{
                                                                                        fontSize:
                                                                                            "25px",
                                                                                        color: "#000",
                                                                                        marginBottom: 0,
                                                                                    }}
                                                                                >
                                                                                    {t(
                                                                                        "page.promo_code_set"
                                                                                    )}{" "}
                                                                                    {i + 1}
                                                                                </div>
                                                                            </div>
                                                                            <Divider />
                                                                            <Row gutter={32}>
                                                                                <Col md={8}>
                                                                                    <SharedInput
                                                                                        label={t(
                                                                                            "page.name"
                                                                                        )}
                                                                                        descLabel={t(
                                                                                            "page.two_hundard_characters"
                                                                                        )}
                                                                                        name={
                                                                                            promo_item.name
                                                                                        }
                                                                                        disable={
                                                                                            promo_item.each_unique_promo_code &&
                                                                                                promo_item
                                                                                                    .each_unique_promo_code
                                                                                                    .length !==
                                                                                                0
                                                                                                ? true
                                                                                                : false
                                                                                        }
                                                                                        onBlur={
                                                                                            handleBlur
                                                                                        }
                                                                                        onChange={(
                                                                                            value
                                                                                        ) => {
                                                                                            setFieldValue(
                                                                                                `unique_promo_code_list[${i}].name`,
                                                                                                value
                                                                                                    .target
                                                                                                    .value
                                                                                            );
                                                                                        }}
                                                                                        value={
                                                                                            promo_item.name
                                                                                        }
                                                                                        errors={
                                                                                            promo_item.name
                                                                                        }
                                                                                        parentClassName="mb-6"
                                                                                    />
                                                                                </Col>
                                                                                <Col md={8}>
                                                                                    <SharedInput
                                                                                        label={t(
                                                                                            "page.generate_amount"
                                                                                        )}
                                                                                        type="number"
                                                                                        name={promo_item.generate_amount.toString()}
                                                                                        disable={
                                                                                            promo_item.each_unique_promo_code &&
                                                                                                promo_item
                                                                                                    .each_unique_promo_code
                                                                                                    .length !==
                                                                                                0
                                                                                                ? true
                                                                                                : false
                                                                                        }
                                                                                        onBlur={
                                                                                            handleBlur
                                                                                        }
                                                                                        onChange={(
                                                                                            value
                                                                                        ) => {
                                                                                            setFieldValue(
                                                                                                `unique_promo_code_list[${i}].generate_amount`,
                                                                                                value
                                                                                                    .target
                                                                                                    .value
                                                                                            );
                                                                                        }}
                                                                                        value={
                                                                                            promo_item.generate_amount
                                                                                        }
                                                                                        parentClassName="mb-6"
                                                                                    />
                                                                                </Col>
                                                                                <Col md={8}>
                                                                                    <div className="label">
                                                                                        {t(
                                                                                            "page.source"
                                                                                        )}
                                                                                    </div>
                                                                                    <Select
                                                                                        showSearch
                                                                                        filterOption={(input, option) =>
                                                                                            //@ts-ignore
                                                                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                                        }
                                                                                        style={{
                                                                                            width: "100%",
                                                                                            height: 48,
                                                                                        }}
                                                                                        disabled={
                                                                                            promo_item.each_unique_promo_code &&
                                                                                                promo_item
                                                                                                    .each_unique_promo_code
                                                                                                    .length !==
                                                                                                0
                                                                                                ? true
                                                                                                : false
                                                                                        }
                                                                                        placeholder={t(
                                                                                            "page.internal"
                                                                                        )}
                                                                                        defaultActiveFirstOption
                                                                                        onChange={(
                                                                                            value: string
                                                                                        ) => {
                                                                                            setFieldValue(
                                                                                                `unique_promo_code_list[${i}].partner_id`,
                                                                                                value
                                                                                            );
                                                                                        }}
                                                                                        value={
                                                                                            promo_item.partner_id
                                                                                        }
                                                                                    >
                                                                                        <Option
                                                                                            key={
                                                                                                "add-new"
                                                                                            }
                                                                                            value={
                                                                                                promo_item.partner_id
                                                                                            }
                                                                                        >
                                                                                            {t(
                                                                                                "page.internal"
                                                                                            )}
                                                                                        </Option>
                                                                                        {partners &&
                                                                                            partners.map(
                                                                                                (
                                                                                                    item,
                                                                                                    index
                                                                                                ) => {
                                                                                                    return (
                                                                                                        <Option
                                                                                                            key={
                                                                                                                index
                                                                                                            }
                                                                                                            value={
                                                                                                                item._id
                                                                                                            }
                                                                                                        >
                                                                                                            {
                                                                                                                item.partnerName
                                                                                                            }
                                                                                                        </Option>
                                                                                                    );
                                                                                                }
                                                                                            )}
                                                                                    </Select>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row gutter={32}>
                                                                                <Col md={12}>
                                                                                    <StyledSwitch
                                                                                        className="switch-field"
                                                                                        style={{
                                                                                            gap: "10px",
                                                                                            marginBottom: 20,
                                                                                        }}
                                                                                    >
                                                                                        <Switch
                                                                                            className="ant-switch"
                                                                                            onChange={(
                                                                                                value
                                                                                            ) => {
                                                                                                setFieldValue(
                                                                                                    `unique_promo_code_list[${i}].is_validity_period`,
                                                                                                    value
                                                                                                );
                                                                                            }}
                                                                                            checked={
                                                                                                promo_item.is_validity_period
                                                                                            }
                                                                                            disabled={
                                                                                                promo_item.each_unique_promo_code &&
                                                                                                    promo_item
                                                                                                        .each_unique_promo_code
                                                                                                        .length !==
                                                                                                    0
                                                                                                    ? true
                                                                                                    : false
                                                                                            }
                                                                                        />
                                                                                        <div
                                                                                            className="label"
                                                                                            style={{
                                                                                                marginBottom: 0,
                                                                                            }}
                                                                                        >
                                                                                            {t(
                                                                                                "page.validity_period"
                                                                                            )}
                                                                                        </div>
                                                                                    </StyledSwitch>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row
                                                                                gutter={32}
                                                                                style={{
                                                                                    marginBottom: 20,
                                                                                }}
                                                                            >
                                                                                <Col md={16}>
                                                                                    <div className="selectDateRow">
                                                                                        <div
                                                                                            className="label"
                                                                                            style={{
                                                                                                marginBottom: 0,
                                                                                            }}
                                                                                        >
                                                                                            {t(
                                                                                                "page.from"
                                                                                            )}
                                                                                        </div>
                                                                                        <DatePicker
                                                                                            size={
                                                                                                "large"
                                                                                            }
                                                                                            format={
                                                                                                dateFormatYear
                                                                                            }
                                                                                            defaultValue={moment()}
                                                                                            value={moment(
                                                                                                promo_item
                                                                                                    .validity_date_from
                                                                                                    .date
                                                                                            )}
                                                                                            disabled={
                                                                                                promo_item.each_unique_promo_code &&
                                                                                                    promo_item
                                                                                                        .each_unique_promo_code
                                                                                                        .length !==
                                                                                                    0
                                                                                                    ? true
                                                                                                    : false
                                                                                            }
                                                                                            onChange={(
                                                                                                value,
                                                                                                dateString
                                                                                            ) => {
                                                                                                setFieldValue(
                                                                                                    `unique_promo_code_list[${i}].validity_date_from.date`,
                                                                                                    dateString
                                                                                                );
                                                                                            }}
                                                                                        />
                                                                                        <TimePicker
                                                                                            defaultValue={moment(
                                                                                                "12:08",
                                                                                                format
                                                                                            )}
                                                                                            value={moment(
                                                                                                promo_item
                                                                                                    .validity_date_from
                                                                                                    .time,
                                                                                                format
                                                                                            )}
                                                                                            format={
                                                                                                format
                                                                                            }
                                                                                            size="large"
                                                                                            disabled={
                                                                                                promo_item.each_unique_promo_code &&
                                                                                                    promo_item
                                                                                                        .each_unique_promo_code
                                                                                                        .length !==
                                                                                                    0
                                                                                                    ? true
                                                                                                    : false
                                                                                            }
                                                                                            onChange={(
                                                                                                value
                                                                                            ) => {
                                                                                                setFieldValue(
                                                                                                    `unique_promo_code_list[${i}].validity_date_from.time`,
                                                                                                    moment(
                                                                                                        value
                                                                                                    ).format(
                                                                                                        format
                                                                                                    )
                                                                                                );
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="selectDateRow">
                                                                                        <div
                                                                                            className="label"
                                                                                            style={{
                                                                                                marginBottom: 0,
                                                                                            }}
                                                                                        >
                                                                                            {t(
                                                                                                "page.till"
                                                                                            )}
                                                                                        </div>
                                                                                        <DatePicker
                                                                                            size={
                                                                                                "large"
                                                                                            }
                                                                                            format={
                                                                                                dateFormatYear
                                                                                            }
                                                                                            defaultValue={moment()}
                                                                                            value={moment(
                                                                                                promo_item
                                                                                                    .validity_date_to
                                                                                                    .date
                                                                                            )}
                                                                                            onChange={(
                                                                                                value,
                                                                                                dateString
                                                                                            ) => {
                                                                                                setFieldValue(
                                                                                                    `unique_promo_code_list[${i}].validity_date_to.date`,
                                                                                                    dateString
                                                                                                );
                                                                                            }}
                                                                                            disabled={
                                                                                                promo_item.each_unique_promo_code &&
                                                                                                    promo_item
                                                                                                        .each_unique_promo_code
                                                                                                        .length !==
                                                                                                    0
                                                                                                    ? true
                                                                                                    : false
                                                                                            }
                                                                                        />
                                                                                        <TimePicker
                                                                                            defaultValue={moment(
                                                                                                "12:09",
                                                                                                format
                                                                                            )}
                                                                                            value={moment(
                                                                                                promo_item
                                                                                                    .validity_date_to
                                                                                                    .time,
                                                                                                format
                                                                                            )}
                                                                                            format={
                                                                                                format
                                                                                            }
                                                                                            size="large"
                                                                                            disabled={
                                                                                                promo_item.each_unique_promo_code &&
                                                                                                    promo_item
                                                                                                        .each_unique_promo_code
                                                                                                        .length !==
                                                                                                    0
                                                                                                    ? true
                                                                                                    : false
                                                                                            }
                                                                                            onChange={(
                                                                                                value
                                                                                            ) => {
                                                                                                setFieldValue(
                                                                                                    `unique_promo_code_list[${i}].validity_date_to.time`,
                                                                                                    moment(
                                                                                                        value
                                                                                                    ).format(
                                                                                                        format
                                                                                                    )
                                                                                                );
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row gutter={32}>
                                                                                <Col md={12}>
                                                                                    <div className="btn-layout">
                                                                                        <Button
                                                                                            className="antd-btn-custom generateBtn"
                                                                                            style={{
                                                                                                background:
                                                                                                    promo_item.each_unique_promo_code &&
                                                                                                        promo_item
                                                                                                            .each_unique_promo_code
                                                                                                            .length !==
                                                                                                        0
                                                                                                        ? "#d3d3d3"
                                                                                                        : "#f4394f",
                                                                                            }}
                                                                                            disabled={
                                                                                                promo_item.each_unique_promo_code &&
                                                                                                    promo_item
                                                                                                        .each_unique_promo_code
                                                                                                        .length !==
                                                                                                    0
                                                                                                    ? true
                                                                                                    : false
                                                                                            }
                                                                                            onClick={() => {
                                                                                                generateRandomCodesSet(
                                                                                                    i
                                                                                                );
                                                                                            }}
                                                                                        >
                                                                                            {t(
                                                                                                "page.generate"
                                                                                            )}
                                                                                        </Button>
                                                                                        {promo_item.each_unique_promo_code &&
                                                                                            promo_item
                                                                                                .each_unique_promo_code
                                                                                                .length ===
                                                                                            0
                                                                                            &&
                                                                                            <Button
                                                                                                className="antd-btn-custom generateBtn"
                                                                                                style={{
                                                                                                    background:
                                                                                                        promo_item.each_unique_promo_code &&
                                                                                                            promo_item
                                                                                                                .each_unique_promo_code
                                                                                                                .length !==
                                                                                                            0
                                                                                                            ? "#d3d3d3"
                                                                                                            : "#f4394f",
                                                                                                }}
                                                                                                disabled={
                                                                                                    promo_item.each_unique_promo_code &&
                                                                                                        promo_item
                                                                                                            .each_unique_promo_code
                                                                                                            .length !==
                                                                                                        0
                                                                                                        ? true
                                                                                                        : false
                                                                                                }
                                                                                                onClick={() => {
                                                                                                    if (promo_item.name === "") {
                                                                                                        warning(t("page.please_add_promo_code_set_name"));
                                                                                                    } else {
                                                                                                        clickFile();
                                                                                                    }
                                                                                                }}
                                                                                            >
                                                                                                {t(
                                                                                                    "page.import_file_excel"
                                                                                                )}
                                                                                            </Button>
                                                                                        }
                                                                                        <input
                                                                                            ref={inputFile}
                                                                                            style={{ display: "none" }}
                                                                                            onChange={importFile}
                                                                                            id={`${i}`}
                                                                                            className="input-import"
                                                                                            type="file"
                                                                                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xlsx, .xls, .csv"
                                                                                        />
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                            {promo_item.each_unique_promo_code &&
                                                                                promo_item
                                                                                    .each_unique_promo_code
                                                                                    .length ===
                                                                                0
                                                                                &&
                                                                                <p
                                                                                    className="load-template"
                                                                                    onClick={exportTemplate}
                                                                                >
                                                                                    {t("page.download_template")}
                                                                                </p>
                                                                            }
                                                                        </div>
                                                                        <div className="actions">
                                                                            <button
                                                                                className="deleteIcon"
                                                                                onClick={() => {
                                                                                    setFieldValue(
                                                                                        "unique_promo_code_list",
                                                                                        values.unique_promo_code_list?.filter(
                                                                                            (
                                                                                                x,
                                                                                                n
                                                                                            ) =>
                                                                                                n !==
                                                                                                i
                                                                                        )
                                                                                    );
                                                                                    deleteGeneratedCodes(
                                                                                        promo_item._id
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <DeleteIcon
                                                                                    size={20}
                                                                                    color={
                                                                                        "#6C7084"
                                                                                    }
                                                                                />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        )
                                                    )}
                                                    <Row gutter={32} style={{ marginBottom: 20 }}>
                                                        <Col md={24}>
                                                            <Button
                                                                className="antd-btn-custom"
                                                                style={{ marginLeft: 0 }}
                                                                onClick={handleAddPromoSet}
                                                            >
                                                                + {t("page.add_promo_codes")}
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                    <Row gutter={32}>
                                                        <Col md={24}>
                                                            <div className="generateExcelRow">
                                                                <Button
                                                                    className="antd-btn-custom"
                                                                    style={{ marginLeft: 0 }}
                                                                    onClick={() => {
                                                                        handleButtonClick();
                                                                    }}
                                                                >
                                                                    <ExportIcon />{" "}
                                                                    {t("page.export_excel")}
                                                                </Button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </>
                                            )}
                                        </>
                                    )}
                                </StyledCard>
                            </div>
                        )}
                    </div>
                    <div className="btn-layout">
                        <StyledSubmitButton
                            type="default"
                            text={t("page.save")}
                            htmlType="button"
                            onClick={() => {
                                handleAssignSubmit();
                            }}
                        />
                        <StyledCancelButton
                            type="sub"
                            text={t("page.cancel")}
                            htmlType="button"
                            onClick={() => {
                                handleCancel();
                            }}
                        />
                    </div>
                </StyledCustom>
            </Form>
            <ComponentAddPromotionModal
                showPopup={showPopup}
                handleClose={handleCloseModal}
                setTableData={setFieldValue}
                type={values.item_type}
                values={values}
            />
            <ComponentAddAddonModal
                showPopup={showAddonModal}
                handleClose={handleCloseAddOnModal}
                setTableData={setFieldValue}
                values={values}
                record={tempRecord}
            />
            <ComponentPromotionCategoryDrawer
                showDrawer={showDrawer}
                handleClose={handleCloseDrawer}
            />
            <ComponentPromotionExcludeModal
                showPopup={excludeModal}
                handleClose={() => {
                    setExcludeModal(false);
                }}
                allStore={allStore}
                selectStore={values.validity_store_list}
                setSelectStore={validityStoreListHandler}
            />
            <ComponentPromotionChannelIncludeModal
                showPopup={channelModal}
                handleClose={() => {
                    setChannelModal(false);
                }}
                allChannel={promotionChannels}
                selectChannel={values.sale_channel_id_list}
                setSelectChannel={validityChannelListHandler}
            />
        </div>
    );
});
const StyledBarcode = styled.div`
    .scanBtn {
        display: flex;
        place-content: center;
        gap: 5px;
        align-items: center;
        height: 48px;
        background: #0263e0;
        width: 100%;
        p {
            margin: 0;
        }
    }
    .printBtn {
        display: flex;
        place-content: center;
        border: 0.5px solid #646464;
        gap: 5px;
        align-items: center;
        height: 48px;
        background: #fff;
        width: 100%;
        p {
            margin: 0;
            color: #000;
        }
    }
    .barcodeRow {
        gap: 10px;
    }
`;
const StyledSwitch = styled.div`
    display: flex;
    align-items: center;
    p {
        margin-bottom: 0;
    }
    .weekDaysRow {
        display: flex;
        margin-top: 20px;
    }
    .ant-checkbox-wrapper + span,
    .ant-checkbox + span {
        display: flex;
        place-items: center;
        font-size: 16px;
        font-weight: 700;
        color: #000;
    }
    .ant-checkbox-wrapper {
        display: flex;
        place-items: center;
    }
    .ant-time-picker {
        margin-left: 8px;
        margin-right: 8px;
        width: 128px !important;
    }
    .daysOfTheWeek {
        width: 100px;
    }
`;
const StyledCustom = styled.div`
    .secondTitle {
        font-size: 20px;
    }
    .row {
        display: flex;
        align-items: center;
    }
    .step {
        display: flex;
        align-items: center;
        p {
            margin: 0;
            color: #0263e0;
            font-weight: 700;
            font-size: 16px;
        }
        .whiteText {
            color: #a5a5a5;
        }
    }
    .blueCircle {
        background: #0263e0;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: flex;
        place-content: center;
        align-items: center;
        color: #fff;
        font-size: 12px;
        font-weight: 700;
        margin-right: 10px;
    }
    .grayCircle {
        background: #a5a5a5;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: flex;
        place-content: center;
        align-items: center;
        color: #fff;
        font-size: 12px;
        font-weight: 700;
        margin-right: 10px;
    }
    .arrowRight {
        margin: auto 20px;
    }
    .ant-table-thead > tr > th {
        text-align: left !important;
    }
    .catCols {
        background: #f7f7f8;
        border-radius: 6px;
        display: flex;
        position: relative;
        padding: 20px;
        color: #646464;
        cursor: pointer;
        margin-bottom: 10px;
    }
    .catCols.active {
        background: #e1e1e1;
    }
    .colArrow {
        position: absolute;
        right: 20px;
        display: flex;
        gap: 10px;
        place-items: center;
        font-size: 12px;
    }
    .tableTitles {
        display: flex;
        justify-content: space-between;
        place-items: center;
        width: 100%;
        .results {
            color: #646464;
            font-size: 12px;
            font-weight: 300;
            margin: 0;
        }
    }
    .titleAndIcon {
        font-size: 16px;
        font-weight: 700;
        display: flex;
        gap: 10px;
        place-items: center;
    }
    .ant-table-thead > tr > th .ant-table-header-column {
        width: 100%;
    }
    .wholeTable {
        border: 1px solid #e1e1e1;
        position: relative;
        width: 100%;
        min-height: 30vh;
        border-radius: 6px;
    }
    .topTitleRow {
        background: #f7f7f8;
        display: flex;
        padding: 20px;
        border-top-left-radius: 6px;
        border-bottom: 1px solid #e1e1e1;
        justify-content: space-between;
        border-right: 1px solid #e1e1e1;
    }
    .topTitleRow.leftSide {
        width: 35%;
    }
    .topTitleRow.rightSide {
        width: 65%;
        right: 0;
        border-right: none;
        border-top-left-radius: 0;
        border-top-right-radius: 6px;
    }
    .wholeTopRow {
        display: flex;
    }
    .leftContent {
        padding: 10px;
        width: 35%;
        border-right: 1px solid #e1e1e1;
        overflow: scroll;
    }
    .rightContent {
        padding: 10px;
        width: 65%;
        height: 100%;
        overflow: scroll;
    }
    .wholeContent {
        display: flex;
        height: 50vh;
    }
    .topLongGrayBox {
        background: #f7f7f8;
        border-radius: 6px;
        padding: 20px;
        margin-bottom: 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
            margin: 0;
            color: #000;
            font-weight: 700;
        }
    }
    .customTransparentStockBtn {
        height: 42px;
        border: 0.5px solid #646464;
        border-radius: 4px;
        background: #fff;
        color: #646464;
        padding: 0 25px;
        display: flex;
        gap: 2px;
        align-items: center;
    }
    .customTransparentStockBtn:hover {
        border: 0.5px solid #646464 !important;
    }
    .aboveOfTableRow {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;
        .title {
            margin-bottom: 0 !important;
        }
    }
    .ant-input-group-addon {
        padding: 8px 16px !important;
    }
    .topRadioButtonRows {
        margin-bottom: 20px;
    }
    .radioBox {
        padding: 20px;
        background: #f5f5f5;
        border: 1px solid #e1e1e1;
        border-radius: 5px;
        color: #c8c8c8;
        position: absolute;
        left: 0;
        top: 30px;
        width: 100%;
        text-align: center;
        font-weight: 700;
        font-size: 32px;
        line-height: 44px;
    }
    .radioGroupRow {
        width: 100%;
        display: flex;
        place-content: space-between;
        position: relative;
        margin-bottom: 85px;
        .ant-radio-wrapper {
            width: 100%;
            display: flex;
            place-items: center;
            margin-bottom: 20px;
            p {
                margin: 0;
                font-weight: 700;
                font-size: 12px;
                line-height: 16px;
            }
        }
    }
    .activeRadioBtn {
        background: linear-gradient(0deg, rgba(2, 99, 224, 0.08), rgba(2, 99, 224, 0.08)), #ffffff;
        border-radius: 5px;
        border: 1px solid rgba(2, 99, 224, 0.36);
        color: #66a9ff;
    }
    .withIcon {
        display: flex;
        place-content: center;
        place-items: center;
        gap: 10px;
    }
    .label .sub-title {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #646464;
        margin: 0;
    }
    .increaseDecreaseSection {
        background: #f4f9ff;
        border-radius: 8px;
        width: 100%;
        height: 100px;
        margin-top: 20px;
        display: flex;
        place-content: center;
        place-items: center;
        gap: 30px;
        font-weight: 700;
        font-size: 25px;
        line-height: 34px;
        color: #000;
        .decreaseIcon {
            background: #e1e1e1;
            border-radius: 50%;
            display: flex;
            place-content: center;
            place-items: center;
            color: #fff;
            font-size: 40px;
            height: 35px;
            width: 35px;
            padding-bottom: 4px;
            cursor: pointer;
        }
        .increaseIcon {
            background: #0263e0;
            border-radius: 50%;
            display: flex;
            place-content: center;
            place-items: center;
            color: #fff;
            font-size: 30px;
            height: 35px;
            width: 35px;
            padding-bottom: 4px;
            cursor: pointer;
        }
    }
    .productName {
        img {
            height: auto;
            width: 50px;
            margin-right: 10px;
        }
    }
    .businessHourTypeRow {
        margin: 10px auto;
    }
    .customInputPromo {
        border: none;
        width: 100%;
        background: transparent;
        color: #66a9ff;
        cursor: pointer;
    }
    .customInputPromo::placeholder {
        color: #c8c8c8;
    }
    .customInputPromo::-webkit-outer-spin-button,
    .customInputPromo::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    .ant-input-affix-wrapper .ant-input:not(:last-child) {
        padding-right: 45px;
    }
    .promoSetBox {
        border-radius: 8px;
        background: #f7f7f8;
        padding: 24px;
        width: 100%;
    }
    .load-template {
        text-decoration: underline;
        cursor: pointer;
        margin-top: 10px;
    }
    .titleRow {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .promoSetBoxRow {
        display: flex;
        align-items: center;
        gap: 15px;
    }
    .deleteIcon {
        cursor: pointer;
        border: none;
        background: transparent;
    }
    .selectDateRow {
        display: flex;
        gap: 20px;
        align-items: center;
        margin-bottom: 20px;
        .label {
            width: 100px;
        }
        .ant-time-picker-large .ant-time-picker-input {
            height: 48px;
        }
    }
    .ant-calendar-picker-input {
        height: 48px;
    }
    .generateExcelRow {
        display: flex;
        align-items: center;
    }
    .generateBtn {
        margin-left: 0 !important;
        span {
            color: #fff !important;
        }
    }
`;
