import { useState, useRef, useEffect } from "react";
import {
    ComponentContactSortIcon,
    StyledTable,
    ComponentEmptyData,
    StyledTableButtonTop,
    ComponentTableSearch,
    ComponentRowService,
} from "@components";
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    TouchSensor,
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    rectSortingStrategy,
    //@ts-ignore
} from "@dnd-kit/sortable";
import { Row, Col, Pagination } from "antd";
import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";
import {
    enumSortBy,
    enumContactSortFields,
    PAGINATION,
    PAGE_START,
    enumNewsStatus,
    INITIAL_LIST_PARAMS,
    PATH_PRODUCT_CREATE,
} from "@configs";
import { IPaginationNext, INews, IDataexample } from "@interfaces";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth, selectContact, selectOrg, setLoading } from "@redux";
import { useNotify } from "@utils";
import { useHistory } from "react-router";

const INITAL_PAGE = {
    xpage: PAGE_START,
    xlimit: PAGINATION,
};
// example data

const data: IDataexample[] = [
    {
        name: "Shampoo",
        category: "HC284610",
        type: "Hare Care",
        sub_cat: "Shampoo",
        variant: 3,
        price: 440,
        raw_price: 440,
        is_enable: true,
        image_url_list: [],
        id: "1",
    },
    {
        name: "Shampoo",
        category: "HC284610",
        type: "Hare Care",
        sub_cat: "Shampoo",
        variant: 3,
        price: 440,
        raw_price: 440,
        is_enable: true,
        image_url_list: [],
        id: "2",
    },
    {
        name: "Shampoo",
        category: "HC284610",
        type: "Hare Care",
        sub_cat: "Shampoo",
        variant: 3,
        price: 440,
        raw_price: 440,
        is_enable: true,
        image_url_list: [],
        id: "3",
    },
];
export const PageServiceList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const search_params = useSelector(selectContact).paramsContact;
    const [params, setParams] = useState(search_params);
    const org_id = useSelector(selectAuth).userInfo?.merchant._id;
    const categories = useSelector(selectOrg).category;
    const [textSearch, setTextSearch] = useState<string>("");

    const [searchDate, setSearchDate] = useState<string[]>([]);
    const [catSearch, setCatSearch] = useState<string>("");
    const [status, setStatus] = useState<enumNewsStatus>(enumNewsStatus.ALL);
    const [filterStatus, setFilterStatus] = useState<boolean>(true);
    const [pagination, setPagination] = useState<IPaginationNext>(INITAL_PAGE);
    const [services, setServices] = useState<IDataexample[]>([]);
    const [total, setTotal] = useState<number>(0);
    const { success, error } = useNotify();
    const history = useHistory();
    useEffect(() => {
        fetchNews();
    }, []);

    const fetchNews = async () => {
        dispatch(setLoading(true));
        try {
            // api call in here
            await null;
            // const response = await newsAPI.getList();
            // setNews();
            // setTotal(response.data.total);
            setServices(data);
            success("api response");
        } catch (err: any) {
            error(err.msg);
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    // const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const value = e.target.value;
    //     if (typingTimeoutRef.current) {
    //         clearTimeout(typingTimeoutRef.current);
    //     }
    //     typingTimeoutRef.current = window.setTimeout(() => {
    //         const newSearchParams = { ...params, page: 1, search: value };
    //         setParams(newSearchParams);
    //     }, 300);
    // };

    const handleSort = (sortField: enumContactSortFields, sortBy: enumSortBy) => {
        const newSortParams = { ...params, sortField, sortBy };
        setParams(newSortParams);
        // fetchContacts(newSortParams);
    };
    const callbackGetList = () => {
        fetchNews();
    };
    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            const swapItem = (items: IDataexample[]) => {
                const oldIndex = items.findIndex((item) => JSON.stringify(item) === active.id);
                const newIndex = items.findIndex((item) => JSON.stringify(item) === over.id);
                return arrayMove(items, oldIndex, newIndex);
            };
            setServices(swapItem);
        }
    };
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
        useSensor(TouchSensor)
    );
    const handleSearchCategory = (value) => {
        setCatSearch(value);
    };
    const onChangeDateRange = (dates, dateStrings: [string, string]) => {
        setSearchDate(dateStrings);
    };
    const handlePagination = (page) => {
        setPagination(page);
    };
    const updateService = async (data) => {
        dispatch(setLoading(true));
        try {
            // api call in here
            await null;
            // const response = await newsAPI.getList();
            // setNews();
            // setTotal(response.data.total);
            success("api response");
        } catch (err: any) {
            error(err.msg);
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };
    return (
        <StyledTable>
            <div className="page-layout">
                <div className="page-header-layout">
                    <div className="page-header">
                        <div>
                            <h3>{t("page.product_lists")}</h3>
                        </div>
                        <div className="flex-header">
                            <StyledTableButtonTop
                                type="danger"
                                size="default"
                                text={t("page.add_product")}
                                onClick={() => history.push(PATH_PRODUCT_CREATE)}
                                className="add-btn"
                                icon="plus"
                            />
                        </div>
                    </div>
                    <div className="search-layout">
                        <ComponentTableSearch
                            setTextSearch={setTextSearch}
                            handleSearchCategory={handleSearchCategory}
                            categories={categories}
                            onChangeDateRange={onChangeDateRange}
                            total={total}
                            isRangePicker={true}
                        />
                    </div>
                </div>
                <div className="page-table-layout">
                    <div className="table">
                        <ScrollContainer vertical={false}>
                            <div className="table_ground">
                                <div className="table-body">
                                    <div className="table-header">
                                        <Row
                                            gutter={24}
                                            type="flex"
                                            justify="space-between"
                                            align="middle"
                                            className="header-row"
                                        >
                                            <Col className="col-item" span={5}>
                                                <p>{t("page.headlines")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.FULL_NAME}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.FULL_NAME}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={3}>
                                                <p>{t("page.news_type")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.TEL}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.TEL}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={3}>
                                                <p>{t("page.date_creation")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.POINTS}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.POINTS}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={3}>
                                                <p>{t("page.display_period")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.POINTS}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.POINTS}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={3}>
                                                <p>{t("page.active")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.CREATED_AT}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.CREATED_AT}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={3}>
                                                <p>{t("page.news_manage")}</p>
                                            </Col>
                                        </Row>
                                    </div>
                                    <DndContext
                                        sensors={sensors}
                                        collisionDetection={closestCenter}
                                        onDragEnd={handleDragEnd}
                                    >
                                        <SortableContext
                                            items={services.map((item) => JSON.stringify(item))}
                                            strategy={rectSortingStrategy}
                                        >
                                            {services && services.length > 0 ? (
                                                <div className="data-table">
                                                    {services.map((item, index) => (
                                                        <ComponentRowService
                                                            key={index}
                                                            data={item}
                                                            itemJson={JSON.stringify(item)}
                                                            categories={categories}
                                                            handleEdit={updateService}
                                                            callbackGetList={callbackGetList}
                                                            index={index}
                                                        />
                                                    ))}
                                                    <div
                                                        style={{ width: "100%", height: 10 }}
                                                    ></div>
                                                </div>
                                            ) : (
                                                <ComponentEmptyData />
                                            )}
                                        </SortableContext>
                                    </DndContext>
                                </div>
                            </div>
                        </ScrollContainer>
                    </div>
                </div>

                <div className="page-bottom">
                    {total > 0 && (
                        <div className="pagination">
                            <Pagination
                                defaultCurrent={INITIAL_LIST_PARAMS.page || 1}
                                pageSize={PAGINATION}
                                total={total}
                                onChange={handlePagination}
                                current={params.page}
                            />
                        </div>
                    )}
                </div>
            </div>
        </StyledTable>
    );
};
