import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { StyledNewDetail } from "@components";
import { PATH_SUPPLIER_CREATE } from "@configs";
import { ModuleShowPaper } from "@modules";
import { useNotify, showErrorMessage } from "@utils";
import { setLoading, selectOrg, selectAuth } from "@redux";
import { POAPI, supplierAPI } from "@api";
import { IGetPOPRExample } from "@interfaces";

interface IStoreDetail {
    store_name: string;
    address: string;
    remark: string;
    store_id: string;
    organization_id: string;
    organization_name: string;
}

export const PagePOShowPaper = () => {
    const { id } = useParams<{ id: string }>();
    const org_id = useSelector(selectAuth).userInfo?.merchant?._id;
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const categories = useSelector(selectOrg).category;
    const dispatch = useDispatch();
    const history = useHistory();
    const { warning, error } = useNotify();
    const { t } = useTranslation();
    const [poGr, setPoGr] = useState<IGetPOPRExample>();
    const [storeDetail, setStoreDetail] = useState<IStoreDetail>();
    const pathName = history.location.pathname;

    useEffect(() => {
        // fetchSupplier();
        fetchProduct();
    }, []);

    const fetchProduct = async () => {
        try {
            dispatch(setLoading(true));
            const res = await POAPI.getGRItem({
                organization_id: org_id || "",
                popr_id: id,
            });
            if (res.data.error === "0") {
                setPoGr(res.data.result);
                setStoreDetail(res.data.result2);
            } else {
                error(res.data.msg);
            }
        } catch (err: any) {
            if (err.response) {
                warning(showErrorMessage(err.response));
                dispatch(setLoading(false));
            } else warning(t("message.not_found", { name: t("object._reward") }));
        } finally {
            dispatch(setLoading(false));
        }
    };

    return (
        <StyledNewDetail>
            {<ModuleShowPaper type="PURCHASE ORDER" data={poGr} storeDetail={storeDetail} />}
        </StyledNewDetail>
    );
};
