import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { StyledNewDetail } from "@components";
import { IConsumableExample } from "@interfaces";
import { PAGE_START, PAGINATION, PATH_NEWS, PATH_STOCK_CREATE } from "@configs";
import { ModuleStockForm } from "@modules";
import { useNotify, showErrorMessage } from "@utils";
import { setLoading, selectAuth, selectOrg } from "@redux";
import { consumableAPI } from "src/API/consumable";

const INITAL_PAGE = {
    xpage: PAGE_START,
    xlimit: PAGINATION,
};

export const PageStockCreate = () => {
    const { id } = useParams<{ id: string }>();
    const org_id = useSelector(selectAuth).userInfo?.merchant?._id;
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const categories = useSelector(selectOrg).category;
    const dispatch = useDispatch();
    const history = useHistory();
    const { warning } = useNotify();
    const { t } = useTranslation();
    const [stock, setStock] = useState<IConsumableExample>();
    const [Refresh, setRefresh] = useState<boolean>(false);
    const pathName = history.location.pathname;

    useEffect(() => {
        fetchNews();
    }, [Refresh]);

    const fetchNews = async () => {
        dispatch(setLoading(true));
        if (pathName !== PATH_STOCK_CREATE) {
            try {
                const getStock = await consumableAPI.getConsumable({
                    organization_id: org_id,
                    consumable_id: id,
                });
                setStock(getStock.data.result);
                dispatch(setLoading(false));
            } catch (err: any) {
                if (err.response) {
                    warning(showErrorMessage(err.response));
                    dispatch(setLoading(false));
                } else warning(t("message.not_found", { name: t("object._reward") }));
                history.push(PATH_NEWS);
            } finally {
                dispatch(setLoading(false));
            }
        }
    };

    return (
        <StyledNewDetail>
            <ModuleStockForm
                stock={stock}
                Refresh={Refresh}
                setRefresh={setRefresh}
                categories={categories}
            />
        </StyledNewDetail>
    );
};
