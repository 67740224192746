import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { StyledNewDetail } from "@components";
import { PATH_NEWS, PATH_PURCHASE_REQUEST_CREATE } from "@configs";
import { ModulePRForm, ModuleTRForm } from "@modules";
import { useNotify, showErrorMessage } from "@utils";
import { setLoading, selectOrg, selectAuth } from "@redux";
import { productAPI } from "@api";

export const PageTRCreate = () => {
    const { id } = useParams<{ id: string }>();
    const org_id = useSelector(selectAuth).userInfo?.merchant._id;
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const categories = useSelector(selectOrg).category;
    const dispatch = useDispatch();
    const history = useHistory();
    const { warning } = useNotify();
    const { t } = useTranslation();
    const [product, setProduct] = useState<any>();
    const pathName = history.location.pathname;

    useEffect(() => {
        // fetchProduct();
    }, []);

    const fetchProduct = async () => {
        dispatch(setLoading(true));
        if (pathName !== PATH_PURCHASE_REQUEST_CREATE) {
            try {
                const getAllProduct = await productAPI.getProduct({
                    organization_code: orgCode,
                    product_code: id,
                });
                setProduct(getAllProduct.data.result);
                dispatch(setLoading(false));
            } catch (err: any) {
                if (err.response) {
                    warning(showErrorMessage(err.response));
                    dispatch(setLoading(false));
                } else warning(t("message.not_found", { name: t("object._reward") }));
                history.push(PATH_NEWS);
            } finally {
                dispatch(setLoading(false));
            }
        }
    };

    return (
        <StyledNewDetail>
            {<ModuleTRForm product={product} />}
        </StyledNewDetail>
    );
};
