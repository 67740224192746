import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";
import { IGetPOPRPRPendingList, IPOPRReceiveList } from "@interfaces";
import { useNotify } from "@utils";
import { dateFormat } from "@configs";
import moment from "moment";

interface IProps {
    data: IPOPRReceiveList & IGetPOPRPRPendingList;
}

export const ComponentRowPurchaseOrderInside = (props: IProps) => {
    const { t } = useTranslation();
    const { success, error } = useNotify();
    const { data } = props;

    return (
        <div className="table-element">
            <div className="table-row">
                <Row
                    style={{ height: "100%" }}
                    gutter={24}
                    type="flex"
                    align="middle"
                    justify="space-between"
                >
                    <Col className="col-item" span={7}>
                        <p className="news-title">{data.consumable_name || t("page.empty_text")}</p>
                    </Col>
                    <Col className="col-item visible-md" span={4}>
                        <p className="news-title">
                            {data.total_restock_qty
                                ? data.total_restock_qty + " " + data.restock_unit
                                : t("page.empty_text")}
                        </p>
                    </Col>
                    <Col className="col-item" span={3}>
                        <p className="news-title">
                            {data.restock_qty + " " + data.restock_unit || t("page.empty_text")}
                        </p>
                    </Col>
                    <Col className="col-item" span={6}>
                        <p className="news-title">
                            {data.unit_price + ` THB / ${data.restock_unit}` ||
                                t("page.empty_text")}
                        </p>
                    </Col>
                    <Col className="col-item" span={4}>
                        <p className="news-title">
                            {moment(data.expire_date).format(dateFormat) || t("page.no_expiry")}
                        </p>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
