import React, { useEffect, memo, useState, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Row, Col, Select, Input, Button, Switch } from "antd";
import styled from "styled-components";
import moment from "moment";
import {
    INewsData,
    INewsCategory,
    IConsumableExample,
    IBaseConvers,
    IBOMSelect,
} from "@interfaces";
import {
    StyledSubmitButton,
    StyledCancelButton,
    StyledCard,
    SharedInput,
    StyledTableButtonAddRow,
    ComponentSearchSupModal,
    ConversionIcon,
    ComponentConversModal,
    ComponentSearchIngredientModal,
    IconDelete,
    StyledSwitch,
} from "@components";
import { useYup } from "@validations";
import { setLoading, selectAuth, store, openToast } from "@redux";
import { showErrorMessage, useNotify } from "@utils";
import { dateFormat, PAGE_START, PAGINATION, PATH_STOCK_LIST } from "@configs";
import { orgApi, storeAPI } from "@api";
import Table, { ColumnProps, TableLocale } from "antd/lib/table";
import { consumableAPI } from "src/API/consumable";
import { ComponentEditStockDrawer } from "src/Components/stock";
import { ComponentStockModal } from "src/Components/stock/StockModal";
interface IProps {
    stock: IConsumableExample | undefined;
    categories: INewsCategory[] | undefined;
    setRefresh: any;
    Refresh: any;
}
const INITAL_PAGE = {
    xpage: PAGE_START,
    xlimit: PAGINATION,
};
// form inital data
const initData: any = {};
const { Option } = Select;

interface ISup {
    supplier_id: string;
    restock_unit_price: number;
    supplier_name: string;
    _id: string;
}

const initBaseConvers: IBaseConvers[] = [
    {
        delivery_unit_name: "Bag",
        to_restock_unit: 1,
        from_edit: false,
    },
];

export const ModuleStockForm = memo((props: IProps) => {
    //hook
    const { YupNews } = useYup();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const { error, success, warning } = useNotify();
    const { userInfo } = useSelector(selectAuth);
    const [showModal, setShowModal] = useState<boolean>(false);
    const organization_id = useSelector(selectAuth).userInfo?.merchant?._id;
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const searchInputRef = useRef<any | null>(null);
    const pathName = window.location.pathname;
    const createNews = Yup.object().shape(YupNews);
    const { stock, categories, setRefresh, Refresh } = props;
    //
    const [consumableName, setConsumableName] = useState<string>();
    const [Unit, setUnit] = useState<string>();
    const [Stores, setStores] = useState<any>([]);
    const [editItem, setEditItem] = useState<any>();
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [showSearch, setShowSearch] = useState<boolean>(false);
    const [showIngredient, setShowIngredient] = useState<boolean>(false);
    const [inventoryCode, setInventoryCode] = useState<string>("");
    const [cost, setCost] = useState<number>(0);
    const [unitName, setUnitName] = useState<string>("Bag");
    const [useUnit, setUseUnit] = useState<number>(1);
    const [restockUnit, setRestockUnit] = useState<string>("Bag");
    const [isUseUnit, setIsUseUnit] = useState<boolean>(false);
    const [showConvers, setShowConvers] = useState<boolean>(false);
    const [baseConvers, setBaseConvers] = useState<IBaseConvers[]>(initBaseConvers);
    const [supList, setSupList] = useState<any[]>([]);
    const [selectConsumeList, setSelectConsumeList] = useState<IBOMSelect[]>([]);
    const [invCateList, setInvCateList] = useState<any>([]);
    const [cateId, setCateId] = useState<string>("");
    const [inventoryCodePrefix, setInventoryCodePrefix] = useState<string>("");
    const [unitList, setUnitList] = useState<any[]>([]);
    const [enablePOPR, setEnablePOPR] = useState<boolean>(false);
    //


    useEffect(() => {
        setTimeout(() => {
            dispatch(setLoading(false));
        }, 500);
        if (stock) {
            setConsumableName(stock.name);
            setUnit(stock.unit_name);
            setCateId(stock?.consumable_category_id || "");
            // setInventoryCodePrefix(stock.consumable_code?.split("-")[0] || "");
            // setInventoryCode(stock.consumable_code?.split("-")[1] || "");
            setInventoryCode(stock.consumable_code || "");
            setCost(stock?.cost || 0);
            setUnitName(stock?.unit_name || "");
            if (stock?.formula && stock?.formula?.length > 0) {
                setUseUnit(stock?.formula[0]?.to_used_unit);
                setRestockUnit(stock?.formula[0]?.restock_unit_name);
            }
            setTableData(stock?.store_list);
            setIsUseUnit(stock?.is_restock_and_use_unit || true);
            setSupList(stock?.supplier_list || []);
            setEnablePOPR(stock?.is_enable_popr);
            let restockArr: IBaseConvers[] = [];
            stock?.restock_formula?.forEach((rItem, rIndex) => {
                let rObj = {
                    delivery_unit_name: rItem?.delivery_unit_name,
                    to_restock_unit: rItem?.to_restock_unit,
                    from_edit: true,
                };
                //@ts-ignore
                restockArr.push(rObj);
            });
            setBaseConvers([...restockArr]);
            let ingArr: IBOMSelect[] = [];
            stock?.bom_consumable_list?.forEach((bItem, bIndex) => {
                let bObj = {
                    consumable_id: bItem?.consumable_id,
                    qty: bItem?.qty,
                    name: bItem?.name,
                    unit: "unit",
                };
                //@ts-ignore
                ingArr.push(bObj);
            });
            setSelectConsumeList([...ingArr]);
        }
        fetchNews();
        fetchInvCate();
    }, [stock, categories]);

    useEffect(() => {
        fetchUOM();
    }, []);

    const fetchUOM = async () => {
        try {
            const resUOM = await orgApi.getUnitList({
                organization_id: organization_id || "",
                xpage: 1,
                xlimit: 10000,
            });
            setUnitList(resUOM?.data?.result);
        } catch (err: any) {
            error(err.msg);
        }
    };

    const handleEnter = (e: string) => {
        if (e === "Enter") {
            searchInputRef.current.focus();
        }
    };

    const fetchInvCate = async () => {
        try {
            const getAllStores = await orgApi.getInvCat({
                organization_id: userInfo?.merchant?._id || "",
            });
            setInvCateList(getAllStores.data.result);
        } catch (err: any) {
            error(err.msg);
            dispatch(setLoading(false));
        }
    };

    const fetchNews = async () => {
        dispatch(setLoading(true));
        try {
            const getStores = await storeAPI.getList({
                organization_code: orgCode,
                xpage: INITAL_PAGE.xpage,
                xlimit: INITAL_PAGE.xlimit,
            });
            let tempStore: any = [];
            for (let i = 0; i < getStores.data.result.length; i++) {
                let new_item = {
                    store_name: getStores.data.result[i].store_name,
                    store_id: getStores.data.result[i]._id,
                    qty: 0,
                    minimum_qty: 0,
                    key: getStores.data.result[i]._id,
                    // is_enable: false,
                };
                tempStore.push(new_item);
            }
            setStores(tempStore);
            setTableData2(tempStore);
        } catch (err: any) {
            if (err.response) {
                error(showErrorMessage(err.response));
            } else error(t("message.error"));
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };
    const handleNewsSubmit = async (values: INewsData) => {
        let supCount = supList.length;
        let arrList: any[] = [];
        supList.forEach((element) => {
            arrList.push({
                supplier_id: element?.supplier_id,
                restock_unit_price: parseInt(element?.restock_unit_price),
            });
            if (element?.restock_unit_price !== "") {
                supCount -= 1;
            }
        });
        if (isUseUnit === true && (restockUnit === "" || unitName === "")) {
            store.dispatch(
                openToast({
                    message: "please select Restock unit and Use unit",
                    type: "warning",
                    autoHideDuration: 3000,
                })
            );
        } else if (isUseUnit === false && unitName === "") {
            store.dispatch(
                openToast({
                    message: "please Use unit",
                    type: "warning",
                    autoHideDuration: 3000,
                })
            );
        } else if (supCount !== 0) {
            store.dispatch(
                openToast({
                    message: "please add price in supplier list",
                    type: "warning",
                    autoHideDuration: 3000,
                })
            );
        } else {
            try {
                dispatch(setLoading(true));
                let outCode = inventoryCode;
                if (inventoryCodePrefix !== "") {
                    outCode = inventoryCodePrefix + "-" + inventoryCode;
                }
                if (!stock) {
                    let tempStores: any = [];
                    for (let i = 0; i < tableData2.length; i++) {
                        if (tableData2[i].qty !== 0) {
                            tempStores.push(tableData2[i]);
                        }
                    }

                    const createStoreAPI = await consumableAPI.createConsumable({
                        organization_id: organization_id,
                        consumable_code: outCode,
                        name: consumableName,
                        cost: cost,
                        unit_name: unitName,
                        formula: [
                            {
                                to_used_unit: isUseUnit ? useUnit : 1,
                                restock_unit_name: restockUnit,
                            },
                        ],
                        is_restock_and_use_unit: isUseUnit,
                        store_list: tableData,
                        supplier_list: arrList,
                        restock_formula: baseConvers,
                        bom_consumable_list: selectConsumeList,
                        consumable_category_id: cateId,
                        is_enable_popr: enablePOPR,
                    });
                    if (createStoreAPI.data.error === "0") {
                        success(t("message.create.success"));
                    } else {
                        store.dispatch(
                            openToast({
                                message: createStoreAPI.data.msg,
                                type: "error",
                                autoHideDuration: 3000,
                            })
                        );
                    }
                } else {
                    const updateStoreAPI = await consumableAPI.updateSingleConsumable({
                        organization_id: organization_id,
                        consumable_id: stock._id,
                        consumable_code: outCode,
                        name: consumableName,
                        cost: cost,
                        unit_name: unitName,
                        formula: [
                            {
                                to_used_unit: isUseUnit ? useUnit : 1,
                                restock_unit_name: restockUnit,
                            },
                        ],
                        is_restock_and_use_unit: isUseUnit,
                        supplier_list: arrList,
                        consumable_category_id: cateId,
                        bom_consumable_list: selectConsumeList,
                        restock_formula: baseConvers,
                        store_list: tableData,
                        is_enable_popr: enablePOPR,
                    });

                    if (updateStoreAPI.data.error === "0") {
                        success(t("message.update.success"));
                    } else {
                        store.dispatch(
                            openToast({
                                message: updateStoreAPI.data.msg,
                                type: "error",
                                autoHideDuration: 3000,
                            })
                        );
                    }
                }
            } catch (err: any) {
                if (err.response) {
                    error(showErrorMessage(err.response));
                } else error(t("message.error"));
            } finally {
                dispatch(setLoading(false));
            }
        }
    };

    const handleCancel = () => {
        history.push("/");
    };

    const { values, handleChange, handleBlur, handleSubmit, errors, touched, setFieldValue } =
        useFormik({
            initialValues: initData,
            validationSchema: createNews,
            enableReinitialize: true,
            onSubmit: handleNewsSubmit,
        });

    const handleSelectType = (value: string) => {
        setUnit(value);
    };

    // Branch Table
    interface IMyTableData2 {
        store_id: string;
        minimun_qty: number;
        maximum_qty: number;
        recommend_qty: number;
        standard_monthly_qty: number;
    }
    const [tempBranch, setTempBranch]: any = useState<any>([]);
    const [tableData2, setTableData2] = useState<any>(tempBranch);
    const [tempTest, setTempTest] = useState<any>([]);
    const onChange = (key, index) => (checked: boolean) => {
        const newData = [...tableData2];
        newData[index][key] = Boolean(checked);
        setTableData2(newData);
    };
    const onInputChange = (key, index) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const newData = [...tableData];
        newData[index][key] = parseInt(e.target.value);
        setTableData(newData);
    };
    const columns2: ColumnProps<IMyTableData2>[] = [
        {
            dataIndex: "store_name",
            title: `${t("page.store")}`,
            key: "store_name",
            width: "25%",
            render: (text, record, index) => (
                <div style={{ placeItems: "center", display: "flex" }}>{text}</div>
            ),
        },
        {
            dataIndex: "minimum_qty",
            key: "minimum_qty",
            title: `${t("page.consume.qty_min")}`,
            render: (text, record, index) => (
                <Input value={text} onChange={onInputChange("minimum_qty", index)} />
            ),
        },
        {
            dataIndex: "maximum_qty",
            key: "maximum_qty",
            title: `${t("page.consume.qty_max")}`,
            render: (text, record, index) => (
                <Input value={text} onChange={onInputChange("maximum_qty", index)} />
            ),
        },
        {
            dataIndex: "recommend_qty",
            key: "recommend_qty",
            title: `${t("page.consume.qty_par")}`,
            render: (text, record, index) => (
                <Input value={text} onChange={onInputChange("recommend_qty", index)} />
            ),
        },
        {
            dataIndex: "standard_monthly_qty",
            key: "standard_monthly_qty",
            title: `${t("page.consume.qty_std")}`,
            render: (text, record, index) => (
                <Input value={text} onChange={onInputChange("standard_monthly_qty", index)} />
            ),
        },
    ];
    // Branch Table
    // Edit Stock Table
    interface IMyTableData {
        date: Date;
        activity: string;
        edited_qty: number;
        before_qty: number;
        new_qty: number;
        remark: string;
    }
    const [tableData, setTableData] = useState<any>();
    const columns: ColumnProps<IMyTableData>[] = [
        {
            dataIndex: "created_date",
            title: `${t("page.date")}`,
            key: "created_date",
            // width: "25%",
            render: (text, record, index) => (
                <div style={{ placeItems: "center", display: "flex" }}>
                    {moment(text).format(dateFormat)}
                </div>
            ),
        },
        {
            dataIndex: "edited_qty",
            key: "edited_qty",
            title: `${t("page.activity")}`,
            // width: "10%",
            render: (text, record, index) => (
                <div style={{ placeItems: "center", display: "flex" }}>
                    {String(text)[0] === "-"
                        ? t("page.deduct")
                        : String(text)[0] === "n"
                            ? "Fixed"
                            : t("page.add")}
                </div>
            ),
        },
        {
            dataIndex: "before_qty",
            key: "before_qty",
            title: `${t("page.current_quantity")}`,
            // width: "10%",
            render: (text, record, index) => (
                <div style={{ placeItems: "center", display: "flex" }}>{text}</div>
            ),
        },
        {
            dataIndex: "edited_qty",
            key: "edited_qty",
            title: `${t("page.edited_quantity")}`,
            // width: "10%",
            render: (text, record, index) => (
                <div style={{ placeItems: "center", display: "flex" }}>{text}</div>
            ),
        },
        {
            dataIndex: "new_qty",
            key: "new_qty",
            title: `${t("page.new_quantity")}`,
            // width: "10%",
            render: (text, record, index) => (
                <div style={{ placeItems: "center", display: "flex" }}>{text}</div>
            ),
        },
        {
            dataIndex: "remark",
            key: "remark",
            title: `${t("page.note")}`,
            // width: "10%",
            render: (text, record, index) => (
                <div style={{ placeItems: "center", display: "flex" }}>{text}</div>
            ),
        },
    ];
    // Edit Stock Table

    const addIng = (payload: IBOMSelect) => {
        let arr = [...selectConsumeList];
        const found = arr.some((obj: any) => obj.consumable_id === payload.consumable_id);
        if (found === false) {
            arr.push(payload);
            setSelectConsumeList([...arr]);
        } else {
            warning("Already selected");
        }
    };

    const changeBOMQty = (e, cIndex) => {
        let arr = [...selectConsumeList];
        if (!isNaN(+e.target.value)) {
            arr[cIndex].qty = e.target.value;
            setSelectConsumeList([...arr]);
        }
    };

    const deleteBom = (bIndex) => {
        let arr = [...selectConsumeList];
        arr = arr.filter((x, xIndex) => xIndex !== bIndex);
        setSelectConsumeList([...arr]);
    };

    const addsup = (payload: ISup) => {
        let arr = supList;
        const found = arr.some((obj: any) => obj.supplier_name === payload.supplier_name);
        if (found === false) {
            arr.push(payload);
            setSupList([...arr]);
        } else {
            store.dispatch(
                openToast({
                    message: "Aready selected",
                    type: "warning",
                    autoHideDuration: 3000,
                })
            );
        }
    };
    const changeSupPrice = (e: any, sIndex: number) => {
        let arr = supList;
        if (!isNaN(+e.target.value)) {
            arr[sIndex].restock_unit_price = e.target.value;
            setSupList([...arr]);
        }
    };
    let locale: TableLocale = {
        emptyText: t("page.please_create_inventory_first_to_set_quantity") + ".",
    };

    return (
        <div className="form-wrap">
            <form onSubmit={handleSubmit}>
                <div className="page-header">
                    <h3>
                        {pathName.includes(PATH_STOCK_LIST)
                            ? t("page.add_edit_consumable")
                            : t("page.add_consumable")}
                    </h3>
                    {/* <p className="sub-title">{t("page.specify_detail")}</p> */}
                </div>
                {/* form basic setting */}
                <div className="page-body">
                    <div className="card-wrap">
                        <StyledCard>
                            <StyledCustom>
                                <div
                                    className="title"
                                    style={{
                                        fontSize: "25px",
                                        color: "#000",
                                        borderBottom: "none",
                                        marginBottom: 32,
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <p>{t("page.consumable_setting")}</p>
                                </div>
                            </StyledCustom>
                            <Row className="select-layout" gutter={32}>
                                <Col md={12}>
                                    <SharedInput
                                        label={t("page.consumable_name")}
                                        // descLabel={t("page._2_100_characters")}
                                        name="consumableName"
                                        onBlur={handleBlur}
                                        onChange={(value) => setConsumableName(value.target.value)}
                                        value={consumableName}
                                        // errors={errors.product_code}
                                        // touched={touched.product_code}
                                        parentClassName="mb-6"
                                    />
                                </Col>
                            </Row>
                            <Row className="select-layout" gutter={32}>
                                <Col md={12}>
                                    <SharedInput
                                        label={t("page.consume.cost_restock")}
                                        name="cost"
                                        onBlur={handleBlur}
                                        onChange={(e: any) => {
                                            if (!isNaN(+e.target.value)) {
                                                setCost(e.target.value);
                                            }
                                        }}
                                        value={cost}
                                        parentClassName="mb-6"
                                    />
                                </Col>
                                <Col md={12}>
                                    <div className="title">{t("page.consume.cate")}</div>
                                    <Select
                                        showSearch
                                        filterOption={(input, option) =>
                                            //@ts-ignore
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        style={{ width: "100%", height: 48 }}
                                        placeholder={t("page.select_unit")}
                                        onChange={(value: string, key: any) => {
                                            setCateId(value);
                                            setInventoryCodePrefix(key.key);
                                        }}
                                        value={cateId}
                                    >
                                        {invCateList?.map((cItem) => {
                                            return (
                                                <Option key={cItem.code} value={cItem._id}>
                                                    {cItem?.category}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </Col>
                            </Row>
                            <StyledCustom>
                                <Row gutter={32}>
                                    <Col md={12}>
                                        <div className="inventoryCodeDiv">
                                            {inventoryCodePrefix !== "" && (
                                                <span className="prefixInventoryCode">
                                                    {inventoryCodePrefix + " - "}
                                                </span>
                                            )}
                                            <SharedInput
                                                label={t("page.inventory_code")}
                                                name="consumableName"
                                                onBlur={handleBlur}
                                                onChange={(value) => {
                                                    setInventoryCode(
                                                        value.target.value
                                                    );
                                                }}
                                                value={inventoryCode}
                                                parentClassName="mb-6"
                                                className={
                                                    inventoryCodePrefix !== ""
                                                        ? "inventoryCodeInput"
                                                        : ""
                                                }
                                                disable={stock ? true : false}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="select-layout" gutter={32}>
                                    <Col md={12}>
                                        <StyledSwitch
                                            className="switch-field"
                                            style={{
                                                gap: "10px",
                                                marginBottom: 0,
                                            }}
                                        >
                                            <Switch
                                                className="ant-switch"
                                                onChange={(value) => {
                                                    setEnablePOPR(value);
                                                }}
                                                checked={enablePOPR}
                                            />
                                            <div
                                                className="label"
                                                style={{
                                                    marginBottom: 0,
                                                }}
                                            >
                                                {t("page.enable_pr_po")}
                                            </div>
                                        </StyledSwitch>
                                    </Col>
                                </Row>
                            </StyledCustom>
                            <StyledCustom>
                                <Row className="select-layout" gutter={32}>
                                    <Col md={12}>
                                        <div className="title">
                                            {t("page.consume.restock_unit")}
                                        </div>
                                        <Row gutter={32}>
                                            <Col md={12}>
                                                <Select
                                                    showSearch
                                                    filterOption={(input, option) =>
                                                        //@ts-ignore
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    style={{ width: "100%", height: 48 }}
                                                    placeholder={t("page.select_unit")}
                                                    onChange={(value: string) => {
                                                        setRestockUnit(value);
                                                    }}
                                                    value={restockUnit}
                                                >
                                                    {unitList?.map((uItem, uIndex) => {
                                                        return (
                                                            <Option
                                                                key={uIndex}
                                                                value={uItem?.uom_name}
                                                            >
                                                                {uItem?.uom_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Col>
                                            <Col md={12}>
                                                <Button
                                                    className="conversion-btn"
                                                    onClick={() => setShowConvers(true)}
                                                >
                                                    <ConversionIcon /> &nbsp;
                                                    {t("page.consume.conversion")}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <div className="title">{t("page.consume.qty_setting")}</div>
                                <Row gutter={32}>
                                    <Col span={24}>
                                        <Table
                                            columns={columns2}
                                            dataSource={tableData}
                                            bordered
                                            size="middle"
                                            rowKey={"store_id"}
                                            locale={locale}
                                        />
                                    </Col>
                                </Row>
                            </StyledCustom>
                        </StyledCard>
                    </div>
                    <div className="card-wrap">
                        <StyledCard>
                            <div
                                className="title"
                                style={{
                                    fontSize: "25px",
                                    color: "#000",
                                    borderBottom: "none",
                                    marginBottom: 32,
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <p>{t("page.consume.BOM")}</p>
                            </div>
                            <div className="add-btn-section">
                                <StyledTableButtonAddRow
                                    type="danger"
                                    size="default"
                                    text={t("page.consume.add_ingredient")}
                                    onClick={() => setShowIngredient(true)}
                                    className="add-btn"
                                    icon="plus"
                                />
                            </div>
                            <StyledSup>
                                <Row className="table-head">
                                    <Col span={13}>{t("page.restock.ingredient")}</Col>
                                    <Col span={11}></Col>
                                </Row>
                                <div className="table-body">
                                    {selectConsumeList?.length > 0 ? (
                                        selectConsumeList?.map((sItem: any, sIndex: number) => {
                                            return (
                                                <Row
                                                    key={sIndex}
                                                    className={`table-item ${sIndex % 2 === 1 ? "odd" : ""
                                                        }`}
                                                >
                                                    <Col span={13}>
                                                        <p>{sItem?.name}</p>
                                                    </Col>
                                                    <Col span={7}>
                                                        <Input
                                                            value={sItem?.qty}
                                                            // addonAfter={t("page.unit_option." + sItem?.unit)}
                                                            addonAfter={sItem?.unit}
                                                            onChange={(e) => {
                                                                changeBOMQty(e, sIndex);
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col span={4}>
                                                        <div
                                                            className="delete-bom"
                                                            onClick={() => deleteBom(sIndex)}
                                                        >
                                                            <div className="del-icon">
                                                                <IconDelete />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            );
                                        })
                                    ) : (
                                        <div className="no-item">
                                            {t("page.consume.no_ingredient")}
                                        </div>
                                    )}
                                </div>
                            </StyledSup>
                        </StyledCard>
                    </div>
                    <div className="card-wrap">
                        <StyledCard>
                            <div
                                className="title"
                                style={{
                                    fontSize: "25px",
                                    color: "#000",
                                    borderBottom: "none",
                                    marginBottom: 32,
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <p>{t("page.consume.supplier")}</p>
                            </div>
                            <div className="add-btn-section">
                                <StyledTableButtonAddRow
                                    type="danger"
                                    size="default"
                                    text={t("page.consume.add_supplier")}
                                    onClick={() => setShowSearch(true)}
                                    className="add-btn"
                                    icon="plus"
                                />
                            </div>
                            <StyledSup>
                                <Row className="table-head">
                                    <Col span={13}>{t("page.consume.supplier")}</Col>
                                    <Col span={11}>{t("page.consume.restock_price")}</Col>
                                </Row>
                                <div className="table-body">
                                    {supList?.length > 0 ? (
                                        supList?.map((sItem: any, sIndex: number) => {
                                            return (
                                                <Row
                                                    className={`table-item ${sIndex % 2 === 1 ? "odd" : ""
                                                        }`}
                                                    key={sIndex}
                                                >
                                                    <Col span={13}>
                                                        <p>{sItem?.supplier_name}</p>
                                                    </Col>
                                                    <Col span={7}>
                                                        <Input
                                                            value={sItem?.restock_unit_price}
                                                            suffix="THB"
                                                            // addonAfter={t("page.unit_option." + restockUnit)}
                                                            addonAfter={restockUnit}
                                                            onChange={(e) => {
                                                                changeSupPrice(e, sIndex);
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            );
                                        })
                                    ) : (
                                        <div className="no-item">
                                            {t("page.consume.no_supplier")}
                                        </div>
                                    )}
                                </div>
                            </StyledSup>
                        </StyledCard>
                    </div>
                    <div className="btn-layout">
                        <StyledSubmitButton
                            type="default"
                            text={t("page.save")}
                            htmlType="submit"
                            // disable={isSubmitting}
                            onClick={handleNewsSubmit}
                        />
                        <StyledCancelButton
                            type="sub"
                            text={t("page.cancel")}
                            htmlType="button"
                            onClick={() => history.push(PATH_STOCK_LIST)}
                        />
                    </div>
                </div>
            </form>
            <ComponentSearchSupModal
                showPopup={showSearch}
                handleClose={() => setShowSearch(!showSearch)}
                addSup={addsup}
                price={cost}
            />
            <ComponentSearchIngredientModal
                showPopup={showIngredient}
                handleClose={() => setShowIngredient(false)}
                addIng={addIng}
            />
            <ComponentConversModal
                showPopup={showConvers}
                handleClose={() => setShowConvers(!showConvers)}
                isUseUnit={isUseUnit}
                setIsUseUnit={setIsUseUnit}
                baseConvers={baseConvers}
                setBaseConvers={setBaseConvers}
                restockUnit={restockUnit}
                useUnit={useUnit}
                setUseUnit={setUseUnit}
                unitName={unitName}
                setUnitName={setUnitName}
            />
            {/* <ComponentProductPreview news={values} /> */}
            <ComponentEditStockDrawer
                showModal={showModal}
                editItem={editItem}
                stock={stock}
                setRefresh={setRefresh}
                Refresh={Refresh}
                Unit={Unit}
                handleClose={() => setShowModal(!showModal)}
            />
            <ComponentStockModal
                showPopup={showPopup}
                handleClose={() => setShowPopup(!showPopup)}
                tableData={tableData}
                columns={columns}
            />
        </div>
    );
});

const StyledBtnStock = styled.div`
    .customTransparentStockBtn {
        height: 42px;
        border: 0.5px solid #646464;
        border-radius: 4px;
        background: transparent;
        color: #646464;
        padding: 0 25px;
    }
    .customTransparentStockBtn:hover {
        border: 0.5px solid #646464 !important;
    }
    .customBlueStockBtn {
        height: 42px;
        border: 0.5px solid transparent !important;
        border-radius: 4px;
        background: #0263e0;
        color: #fff;
        padding: 0 25px;
    }
`;

const StyledCustom = styled.div`
    .ant-table-row:nth-child(even) {
        background: #f7f7f8;
    }
    .secondTitle {
        font-size: 20px;
    }
    .conversion-btn {
        height: 48px;
        background: #0263e0;
        width: 100%;
        color: white;
    }
    .inventoryCodeDiv {
        position: relative;
        .prefixInventoryCode {
            position: absolute;
            bottom: 30px;
            left: 15px;
            z-index: 9;
            color: #979797;
        }
        .inventoryCodeInput input {
            padding-left: 50px;
        }
    }
`;

const StyledUnitSection = styled.div`
    background-color: #f7f7f8;
    padding: 25px;
    border-radius: 8px;
    margin-top: 25px;
    input {
        border-color: #d9d9d9 !important;
        box-shadow: none !important;
        height: 48px;
    }
    .equal {
        font-size: 30px;
    }
`;

const StyledSup = styled.div`
    input {
        border-color: #d9d9d9 !important;
        box-shadow: none !important;
        height: 38px;
    }
    .table-head {
        border: 1px solid #a5a5a5;
        border-radius: 6px 6px 0 0;
        padding: 10px 30px;
        font-weight: 700;
        font-size: 16px;
        margin-top: 40px;
    }
    .table-body {
        border: 1px solid #a5a5a5;
        border-top: none;
        border-radius: 0 0 6px 6px;

        p {
            font-size: 16px;
            margin: 0px;
            line-height: 40px;
        }
        .table-item {
            padding: 20px;
        }
        .odd {
            background-color: #f7f7f8;
        }
        .no-item {
            text-align: center;
            font-size: 20px;
            color: #adadad;
            padding: 30px;
        }
    }
    .delete-bom {
        display: flex;
        .del-icon {
            margin-left: 10px;
            margin-top: 8px;
            cursor: pointer;
        }
    }
`;
