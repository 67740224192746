import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Col, Row, Menu, Dropdown, Button } from "antd";
import { MoreHorizontal } from "react-feather";
import { IGetPosRolesExample } from "@interfaces";
import { PATH_ROLES_PERMISSIONS } from "@configs";
import { selectAuth } from "@redux";
import { useSelector } from "react-redux";
import { useNotify } from "@utils";
import styled from "styled-components";
import { rolePosAPI } from "@api";

interface IProps {
    data: IGetPosRolesExample;
    callbackGetList: () => void;
    index: number;
    posRoles: IGetPosRolesExample[];
}

export const ComponentRowRolePOS = (props: IProps) => {
    const { t } = useTranslation();
    const { success, error } = useNotify();
    const { userInfo } = useSelector(selectAuth);
    const { data, callbackGetList, posRoles, index } = props;

    const handleDelete = async (item: any) => {
        try {
            const res = await rolePosAPI.deletePosRole({
                organization_id: userInfo?.merchant?._id || "",
                pos_role_id: item,
            });
            if (res.data.error === "0") {
                callbackGetList();
                success(t("message.delete.success"));
            } else {
                error(res.data.msg);
            }
        } catch (err: any) {
            if (err) {
                error(err.msg);
            } else error(t("message.delete.fail"));
        }
    };

    const menu = (
        <Menu>
            <Menu.Item onClick={() => handleDelete(data._id)}>
                <div className="duplicate-btn">{t("page.delete")}</div>
            </Menu.Item>
        </Menu>
    );

    return (
        <StyledRolePos>
            <div className="table-element">
                <div className="table-row">
                    <Row
                        style={{ height: "100%" }}
                        gutter={24}
                        type="flex"
                        align="middle"
                        justify="space-between"
                    >
                        <Col className="col-item" span={8}>
                            <Link to={`${PATH_ROLES_PERMISSIONS}/${data._id}`}>
                                <p className="news-title">
                                    {posRoles[index].pos_role_name || t("page.empty_text")}
                                </p>
                            </Link>
                        </Col>
                        <Col className="col-item" span={8} style={{ placeContent: "center" }}>
                            <p>{posRoles[index].pos_role_detail || t("page.empty_text")}</p>
                        </Col>
                        <Col className="col-item" span={8} style={{ placeContent: "end" }}>
                            <Dropdown
                                //@ts-ignore
                                getPopupContainer={(trigger) => trigger.parentNode}
                                overlay={menu}
                                trigger={["click"]}
                                placement="bottomRight"
                            >
                                <Button style={{ border: "unset" }}>
                                    <MoreHorizontal />
                                </Button>
                            </Dropdown>
                        </Col>
                    </Row>
                </div>
            </div>
        </StyledRolePos>
    );
};
export const StyledRolePos = styled.div`
    .table-row {
        height: 60px;
    }
`;
