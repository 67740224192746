import { enumDrawerPlacement } from "@configs";
import { ReactNode } from "react";
import styled from "styled-components";
import { Drawer } from "antd";
interface IProps {
    title: string;
    placement: enumDrawerPlacement;
    onClose: () => void;
    visible: boolean;
    children: ReactNode;
    width: number;
}

export const SharedComponentDrawer = (props: IProps) => {
    const { title, placement, onClose, visible, children, width } = props;
    return (
        <StyledDrawer
            title={title}
            placement={placement}
            closable={false}
            onClose={onClose}
            visible={visible}
            width={width}
        >
            {children}
        </StyledDrawer>
    );
};
const StyledDrawer = styled(Drawer)`
    .ant-drawer-body {
        height: 100% !important;
        margin-bottom: 4rem;
        .add-sub-btn {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: #0263e0;
            margin: 0;
            cursor: pointer;
        }
        .title {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            color: #000000;
            margin-bottom: 20px;
        }
        .btn-layout {
            position: fixed;
            bottom: 16px;
        }
    }
    .avatar {
        margin-bottom: 42px;
        position: relative;
        .box_title {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            p {
                margin: 0 4px 0 0;
            }
            button {
                padding: 0 4px;
                border: 0;
                background: transparent;
                height: auto;
            }
        }
    }
    .avatar .box_title {
        margin: 0 0 20px 0px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 1.5em;
    }
    .avatar-layout {
        position: relative;
        width: 110px;
        &:hover {
            .remove-icon-layout {
                visibility: visible;
            }
        }
    }
    .remove-icon-layout {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
        visibility: hidden;
        display: flex;
        justify-content: end;
        padding: 8px;
    }
    .avatar .box_title span {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 1.5em;
        position: relative;
        top: -1px;
        display: inline-block;
        margin-left: 4px;
    }
    .avatar .camera {
        width: 33px;
        height: 33px;
        background-color: #000000;
        border-radius: 50%;
        opacity: 0.29;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 110px;
        bottom: 0;
        transform: translate(-50%, 50%);
        cursor: pointer;
    }
    .minimumQtyDiv {
        border-bottom: 0.5px solid #a5a5a5;
        padding-bottom: 2rem;
        margin-bottom: 1rem;
    }
`;
