import { Row, Col, Input, Select } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { StyledTableButtonTop } from "./styles";
const { Search } = Input;
const { Option } = Select;

interface Iprops {
    setTextSearch: (value: string) => void;
    handleSearchCategory?: (value: string) => void;
    storeList?: any[];
    handlePublish: () => void;
    isEmpty: boolean;
}
export const ComponentTableCheckStock = (props: Iprops) => {
    const { setTextSearch, handleSearchCategory, storeList, handlePublish, isEmpty } = props;
    const { t } = useTranslation();

    return (
        <StyledSearchLayout>
            <div className="filter">
                <Row
                    gutter={24}
                    type="flex"
                    justify="space-between"
                    align="middle"
                    className="search-row"
                >
                    <Col className="col-item" span={14}>
                        <div className="filter-section">
                            <div className="filter-element">
                                <p>{t("page.branch")}:</p>
                                <Select
                                    showSearch
                                    filterOption={(input, option) =>
                                        //@ts-ignore
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange={handleSearchCategory}
                                    placeholder={t("page.please_choose")}
                                >
                                    {storeList &&
                                        storeList.map((item, index) => (
                                            <Option value={item._id} key={index}>
                                                {item.store_name}
                                            </Option>
                                        ))}
                                </Select>
                            </div>
                            {/* <div className="filter-element">
                                <p>{t("page.search")}</p>
                                <Search
                                    placeholder={t("page.search_consumables")}
                                    onSearch={(value) => setTextSearch(value)}
                                    style={{ width: 200 }}
                                    onChange={(event) => {
                                        setTextSearch(event.target.value);
                                    }}
                                />
                            </div> */}
                        </div>
                    </Col>
                    {isEmpty && (
                        <Col className="col-item" span={10}>
                            <div className="filter-element result">
                                <StyledTableButtonTop
                                    type="danger"
                                    size="default"
                                    text={t("page.publish")}
                                    onClick={handlePublish}
                                    className="add-btn"
                                    icon={"caret-right"}
                                    style={{
                                        background: "#F22F46",
                                        borderRadius: "4px",
                                        width: "100%",
                                    }}
                                />
                            </div>
                        </Col>
                    )}
                </Row>
            </div>
        </StyledSearchLayout>
    );
};
const StyledSearchLayout = styled.div`
    @media (min-width: 992px) {
        .ant-select-selection {
            width: 160px;
        }
    }
    .filter-section {
        display: flex;
        grid-gap: 25px;
    }
    .filter {
        background: #fff;
        padding: 20px;
        border-radius: 4px;
        margin-bottom: 26px;

        .filter-element {
            display: flex;
            align-items: center;
            column-gap: 8px;
            p {
                margin: 0;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #646464;
            }
            .ant-input-search {
                height: 44px;
                width: 100%;
            }
            .ant-select {
                min-width: 200px;
                /* min-width: 160px; */
                width: 100%;
                .ant-select-selection--single {                    
                    min-width: 200px;
                    height: 44px;
                    .ant-select-selection__rendered {
                        height: 100%;
                        align-items: center;
                        display: flex;
                    }
                }
            }
            .ant-calendar-picker-input {
                height: 44px;
            }
            &.result {
                display: flex;
                justify-content: end;
                p {
                    display: flex;
                    column-gap: 10px;
                }
            }
        }
    }
`;
