import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Switch, Col, Row, Avatar, Menu, Dropdown, Button, Checkbox } from "antd";
import { MoreHorizontal } from "react-feather";

import { IDataexample, INewsCategory } from "@interfaces";
import { enumNewsType, PATH_PRODUCT_CREATE, PATH_SERVICES_CREATE } from "@configs";
import { selectAuth, store } from "@redux";
import { useSelector } from "react-redux";
import { IconDrag } from "@components";
import { useNotify } from "@utils";
import React, { useEffect, useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import styled from "styled-components";
import axios from "axios";
import { orgApi } from "@api";
interface IProps {
    data: IDataexample;
    itemJson: string;
    callbackGetList: () => void;
    index: number;
    newType?: enumNewsType;
    categories: INewsCategory[];
    stores: any[];
    setStores: any;
    editItem: (data: IDataexample) => void;
}

export const ComponentRowCategory = (props: IProps) => {
    //hooks
    const { t } = useTranslation();
    const { success, error } = useNotify();
    const organization_id = useSelector(selectAuth).userInfo?.merchant?._id;
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;

    //props
    const { data, callbackGetList, itemJson, newType, stores, index, setStores, editItem } = props;
    const [show, setShowModal] = useState(false);

    const { attributes, listeners, setNodeRef, transform } = useSortable({
        id: itemJson,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
    };

    const handleDelete = async () => {
        try {
            const res = await orgApi.deleteProductCategory({
                organization_code: orgCode,
                product_category_id: stores[index]._id,
            });
            if (res.data.error === "0") {
                await callbackGetList();
            }
            setShowModal(false);
            success(t("message.delete.success"));
        } catch (err: any) {
            if (err) {
                error(err.msg);
            } else error(t("message.delete.fail"));
        }
    };

    const showModal = () => {
        setShowModal(!show);
    };
    const menu = (
        <Menu>
            <Menu.Item key={1} onClick={handleDelete}>
                <div className="duplicate-btn">{t("page.delete")}</div>
            </Menu.Item>
        </Menu>
    );
    const handleCategoryStatus = async () => {
        const res = await orgApi.enableProductCategory({
            organization_id: organization_id,
            product_category_id: stores[index]._id,
            is_enable: !stores[index].is_enable,
        });
        console.log(res);
        if (res.data.error === "0") {
            await callbackGetList();
        }
    };
    const tempCategory = stores[index];

    // const [plainOptions, setPlainOptions] = useState(stores);
    return (
        <Styless>
            {/* {stores.length !== 0 ? (
                stores.map((item, i) => ( */}
            <div className="table-element" ref={setNodeRef} style={style}>
                <div className="drag-icon" {...attributes} {...listeners}>
                    <IconDrag />
                </div>
                <div className="table-row">
                    <Row
                        style={{ height: "100%" }}
                        gutter={24}
                        type="flex"
                        align="middle"
                        justify="space-between"
                    >
                        <Col className="col-item" span={12}>
                            <p
                                className="news-title"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    editItem(stores[index]);
                                    // setShowModal(!showModal);
                                }}
                            >
                                {stores[index].category || t("page.empty_text")}
                            </p>
                        </Col>
                        {/* <Col className="col-item" span={5}>
                            <p>{stores[index].sub_category.length || 0}</p>
                        </Col> */}
                        <Col className="col-item visible-md" span={6}>
                            {/* <p>{data.sub_cat || t("page.empty_text")}</p> */}
                            <Switch
                                checked={stores[index].is_enable}
                                onChange={(e) => {
                                    handleCategoryStatus();
                                }}
                            />
                            {/* <Button onClick={() => console.log(stores[index])}>Click</Button> */}
                        </Col>
                        <Col className="col-item" span={6} style={{ placeContent: "end" }}>
                            <Dropdown
                                //@ts-ignore
                                getPopupContainer={(trigger) => trigger.parentNode}
                                overlay={menu}
                                trigger={["click"]}
                                placement="bottomRight"
                            >
                                <Button style={{ border: "unset" }}>
                                    <MoreHorizontal />
                                </Button>
                            </Dropdown>
                        </Col>
                    </Row>
                </div>
            </div>
            {/* ))
            ) : (
                <p>Nothing to show!</p>
            )} */}
        </Styless>
    );
};
export const Styless = styled.div`
    .table-row {
        height: 60px;
    }
`;
