import React, { useEffect, memo, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Row, Col, Select, Radio, Input, Table, Button, Divider } from "antd";
import styled from "styled-components";
import {
    INewsData,
    INewsCategory,
    ICreatePR,
    IUpdatePR,
    IGetAutoGen,
    IConsumeListPayload,
    IGetAdminDataByTokenAdminDataExample,
    IGetStoresBindingDropdownExample,
} from "@interfaces";
import {
    StyledSubmitButton,
    StyledCancelButton,
    StyledCard,
    Step1Icon,
    Step2Icon,
    Step3Icon,
    Step4Icon,
    StyledTableButtonAddRow,
    IconMinusCircle,
    IconAddCircle,
    ComponentPOModal,
    IconDelete,
    BranchIcon,
    ComponentRowTrInv,
    SharedInput,
    ComponentTRModal,
} from "@components";
import { useYup } from "@validations";
import { setLoading, selectAuth } from "@redux";
import { showErrorMessage, theme, useNotify } from "@utils";
import {
    PAGE_START,
    PAGINATION,
    PATH_PRODUCT,
    PATH_PURCHASE_ORDER_CREATE,
    PATH_PURCHASE_REQUEST,
    PATH_TRANSFER_REQUEST,
    dateFormatYear,
} from "@configs";
import { POAPI, adminAPI, consumableAPI, orgApi, productAPI, storeAPI } from "@api";
import { ColumnProps } from "antd/lib/table";
import { PurchaseRequestIcon } from "src/Components/icon/purchaseRequest/PurchaseRequestIcon";
import { TransferRequestIcon } from "src/Components/icon/purchaseRequest/TransferRequestIcon";
import { EditIcon } from "src/Components/icon/purchaseRequest/EditIcon";
import moment from "moment";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";

interface IProps {
    product: any | undefined;
}

interface ICreateTRList {
    consumable_id: string;
    restock_qty: number;
    request_to_store_id: string;
}

interface ICateItem {
    category: string;
    cate_id: string;
    itemList: any[];
}



const newDate = new Date();

const initData: INewsData = {
    organization_id: "",
    title: "",
    detail: "",
    image_url: [],
    is_hot_news: false,
    news_category_id: "",
    date_from: new Date(),
    date_to: new Date(newDate.setFullYear(newDate.getFullYear() + 1)),
    is_enable: true,
    discount: "",
    product_code: "",
    price: "",
    discount_type: "",
    has_barcode: true,
    active_on_platform: [],
    point_deduction: false,
    maximum_deduction: false,
    point_deduction_value: "",
    variants: [
        {
            variant_name: "",
            qty: "",
            raw_price: "",
            net_price: "",
            barcode: "",
            status: true,
            store_list: [
                {
                    store_code: "",
                    qty: 0,
                },
            ],
        },
    ],
    hint_code: "",
    sku_earn_point: 0,
};
export const ModuleTRForm = memo((props: IProps) => {
    const { YupNews } = useYup();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const params = new URLSearchParams(window.location.search);
    const itemType = params.get("type");
    const req = params.get("req");
    const fromB = params.get("from");
    const { error, success, warning } = useNotify();
    const [step, setStep] = useState(1);
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [hasVariant, setHasVariant] = useState<boolean>(false);
    const organization_id = useSelector(selectAuth).userInfo?.merchant?._id;
    const pathName = window.location.pathname;
    const createNews = Yup.object().shape(YupNews);
    const [multipleImages, setMultipleImages] = useState<string[]>([]);
    const [focusStore, setFocusStore] = useState<string>("");
    const [fromStore, setFromStore] = useState<string>("");
    const [consumeList, setConsumeList] = useState<IConsumeListPayload[]>([]);
    const { product } = props;
    const [isActive, setIsActive] = useState<boolean>();
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const userInfo = useSelector(selectAuth).userInfo;
    const org_id = useSelector(selectAuth).userInfo?.merchant?._id;
    const [transferStep, setTransferStep] = useState<boolean>(false);
    const [cateList, setCateList] = useState<any>([]);
    const [focusCate, setFocusCate] = useState<number>(0);
    const [cateItemList, setCateItemList] = useState<ICateItem[]>([]);
    const [isFinish, setIsFinish] = useState<boolean>(false);
    const [checkInvList, setCheckInvList] = useState<any[]>([]);


    useEffect(() => {
        if (focusStore) {
            // fetchCateList();
        }
    }, [focusStore]);

    useEffect(() => {
        if (req && fromB) {
            setFocusStore(req);
            setFromStore(fromB);
        }
    }, [req, fromB]);

    const { Option } = Select;

    const changeCate = (cIndex: number) => {
        setFocusCate(cIndex);
    };

    const fetchCateList = async () => {
        try {
            dispatch(setLoading(true));
            let cateResult: any[] = [];
            if (itemType === "inventory") {
                // inv
                const getCateList = await orgApi.getInvCat({
                    organization_id: userInfo?.merchant?._id || "",
                });
                cateResult = getCateList.data.result;
            } else {
                // product
                const getAllProductCats = await orgApi.getProductCategorys({
                    organization_code: orgCode,
                });
                let outArr: any[] = [];
                getAllProductCats.data.result.forEach((cItem: any) => {
                    let obj = {
                        code: cItem?.code,
                        category: cItem?.category,
                        item_series: "",
                        item_group: "",
                        _id: cItem?._id,
                    };
                    outArr.push(obj);
                });
                cateResult = outArr;
            }

            let payload = [
                ...cateResult,
                {
                    code: "",
                    category: "No cat",
                    item_series: "",
                    item_group: "",
                    _id: "",
                },
            ];

            let arr: ICateItem[] = [];
            payload.forEach((pItem: any) => {
                let obj: ICateItem = {
                    category: pItem.category,
                    cate_id: pItem._id,
                    itemList: [],
                };
                arr.push(obj);
            });
            setFocusCate(0);

            const getAllItem = await consumableAPI.getConsumablesStoreIdForCreatePR({
                organization_id: org_id,
                xpage: 1,
                xlimit: 9999,
                is_enable: true,
                ...(itemType === "product" && { is_auto_consumable: true }),
                store_id: focusStore,
                is_enable_popr: true,
                // consumable_category_id: cateList?.[focusCate || 0]?._id,
            });
            const allList = getAllItem.data.result;
            let moreNoCat: any[] = [];
            let updateGroup: any[] = [];
            arr.forEach((groupItem) => {
                const filteredItems = allList.filter((item, index, arr) => {
                    const categoryId =
                        item.consumable_category_id
                        || item?.product_category_id
                        // || ""
                        ;
                    const isMatch = categoryId === groupItem.cate_id;
                    if (isMatch) {
                        arr.splice(index, 1);
                        return isMatch;
                    } else {
                        if (index < 5) {
                            moreNoCat.push({
                                consumable_id: item._id,
                                name: item.name,
                                code: item.consumable_code,
                                restock_price: item.cost,
                                on_stork: item.consumable_data.restock_qty,
                                add_qty: 0,
                                unit: item.formula[0].restock_unit_name,
                                delivery_qty: item?.restock_formula?.[0]?.to_restock_unit || 1,
                                index: (moreNoCat.length),
                            });
                        }
                    }
                    // return categoryId === groupItem.cate_id;
                });
                let itemArr: any = [];
                // moreNoCat = [...moreNoCat, ...allList];
                filteredItems.forEach((cItem, cIndex) => {
                    let outQty: any = 0;

                    if (id) {
                        // for edit pr
                        let checkArr = consumeList.find((fItem) => {
                            return fItem.consumable_id === cItem?._id;
                        });
                        if (checkArr) {
                            outQty = checkArr.add_qty;
                        }
                    }
                    if (id && outQty !== 0) {
                        itemArr.push({
                            consumable_id: cItem._id,
                            name: cItem.name,
                            restock_price: cItem.cost,
                            on_stork: cItem.consumable_data.restock_qty,
                            add_qty: outQty,
                            unit: cItem.formula[0].restock_unit_name,
                            delivery_qty: cItem?.restock_formula?.[0]?.to_restock_unit || 1,
                            index: (itemArr.length),
                        });
                    } else if (!id) {
                        itemArr.push({
                            consumable_id: cItem._id,
                            name: cItem.name,
                            code: cItem.consumable_code,
                            restock_price: cItem.cost,
                            on_stork: cItem.consumable_data.restock_qty,
                            add_qty: outQty,
                            unit: cItem.formula[0].restock_unit_name,
                            delivery_qty: cItem?.restock_formula?.[0]?.to_restock_unit || 1,
                            index: (itemArr.length),
                        });
                    }
                });
                if (itemArr.length > 0 || (!id)) {
                    updateGroup.push({
                        category: groupItem.category,
                        cate_id: groupItem.cate_id,
                        itemList: itemArr,
                    });
                } else {
                    payload = payload.filter((cItem) => cItem._id !== groupItem.cate_id);
                }
            });
            setCateList(payload);
            setCateItemList([...updateGroup]);
        } catch (err: any) {
            error(err.msg);
        } finally {
            dispatch(setLoading(false));
        }
    };


    const addConsume = (payload: IConsumeListPayload) => {
        let arr = consumeList;
        arr.push({
            ...payload,
            restock_qty: 0,
        });
        setConsumeList([...arr]);
    };



    const handleNewsSubmit = async (Values: INewsData) => {
        dispatch(setLoading(true));
        try {
            // setTableData(tempVariant);

            if (product) {
                if (hasVariant) {
                    // update with variant
                    const res = await productAPI.update({
                        ...product,
                        organization_code: orgCode,
                        product_code: values.product_code,
                        name: values.title,
                        standard_price: parseInt(values.price),
                        discount_in_baht: parseInt(values.discount),
                        category_id: values.news_category_id,
                        sub_category_id: "",
                        image_url_list: multipleImages,
                        is_variant: hasVariant,
                        variant_name_header: "",
                        description: values.detail,
                        condition: "",
                        text_block_list: [],
                        is_delete: false,
                        is_enable: isActive,
                        _id: product._id,
                        variant_list: tableData,
                        sku_earn_point: values.sku_earn_point || 0,
                        hint_code: values?.hint_code || "",
                        is_share_consumable: false,
                    });
                    if (res.data.error === "0") {
                        success(t("message.update.success"));
                        history.push(PATH_PRODUCT);
                    }
                } else {
                    // update without variant
                    const res = await productAPI.update({
                        ...product,
                        organization_code: orgCode,
                        product_code: values.product_code,
                        name: values.title,
                        standard_price: parseInt(values.price),
                        discount_in_baht: parseInt(values.discount),
                        category_id: values.news_category_id,
                        sub_category_id: "",
                        image_url_list: multipleImages,
                        is_variant: hasVariant,
                        variant_name_header: "",
                        description: values.detail,
                        condition: "",
                        text_block_list: [],
                        is_delete: false,
                        is_enable: isActive,
                        _id: product._id,
                        variant_list: [
                            {
                                variant_code: barcode,
                                variant_name: values.title,
                                is_enable: true,
                                store_list: tableData2,
                                raw_price: parseInt(values.price),
                                dimension_list: [],
                            },
                        ],
                        sku_earn_point: values.sku_earn_point || 0,
                        hint_code: values?.hint_code || "",
                        is_share_consumable: false,
                    });
                    if (res.data.error === "0") {
                        success(t("message.update.success"));
                        history.push(PATH_PRODUCT);
                    }
                }
            } else {
                if (hasVariant) {
                    // create with variant
                    const res = await productAPI.create({
                        ...product,
                        organization_id: org_id,
                        product_code: values.product_code,
                        name: values.title,
                        standard_price: parseInt(values.price),
                        discount_in_baht: parseInt(values.discount),
                        category_id: values.news_category_id,
                        image_url_list: multipleImages,
                        is_variant: hasVariant,
                        variant_name_header: "",
                        variant_list: tableData,
                        description: values.detail,
                        condition: "",
                        text_block_list: [],
                        is_enable: isActive,
                        //
                        discount_type: values.discount_type,
                        has_barcode: values.has_barcode,
                        active_on_platform: values.active_on_platform,
                        point_deduction: values.point_deduction,
                        maximum_deduction: values.maximum_deduction,
                        point_deduction_value: values.point_deduction_value,
                        hint_code: values?.hint_code || "",
                        sku_earn_point: values?.sku_earn_point || 0,
                        is_share_consumable: false,
                    });
                    if (res.data.error === "0") {
                        success(t("message.create.success"));
                        history.push(PATH_PRODUCT);
                        setTableData([]);
                        setTempVariant([]);
                        setMultipleImages([]);
                        setFieldValue("product_code", "");
                        setFieldValue("price", "");
                        setFieldValue("discount", "");
                        setFieldValue("news_category_id", "");
                        setFieldValue("detail", "");
                        setFieldValue("image_url", "");
                        setFieldValue("title", "");
                    }
                } else {
                    // create without variant
                    const res = await productAPI.create({
                        ...product,
                        organization_id: org_id,
                        product_code: values.product_code,
                        name: values.title,
                        standard_price: parseInt(values.price),
                        discount_in_baht: parseInt(values.discount),
                        category_id: values.news_category_id,
                        image_url_list: multipleImages,
                        is_variant: hasVariant,
                        variant_name_header: "",
                        variant_list: [
                            {
                                variant_code: barcode,
                                variant_name: values.title,
                                is_enable: true,
                                store_list: tableData2,
                                raw_price: parseInt(values.price),
                                dimension_list: [],
                            },
                        ],
                        description: values.detail,
                        condition: "",
                        text_block_list: [],
                        is_enable: isActive,
                        //
                        discount_type: values.discount_type,
                        has_barcode: values.has_barcode,
                        active_on_platform: values.active_on_platform,
                        point_deduction: values.point_deduction,
                        maximum_deduction: values.maximum_deduction,
                        point_deduction_value: values.point_deduction_value,
                        hint_code: values?.hint_code || "",
                        sku_earn_point: values?.sku_earn_point || 0,
                        is_share_consumable: false,
                    });

                    if (res.data.error === "0") {
                        success(t("message.create.success"));
                        history.push(PATH_PRODUCT);
                        setTableData([]);
                        setTempVariant([]);
                        setMultipleImages([]);
                        setFieldValue("product_code", "");
                        setFieldValue("price", "");
                        setFieldValue("discount", "");
                        setFieldValue("news_category_id", "");
                        setFieldValue("detail", "");
                        setFieldValue("image_url", "");
                        setFieldValue("title", "");
                    }
                }
            }
        } catch (err: any) {
            if (err.response) {
                error(showErrorMessage(err.response));
            } else error(t("message.error"));
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleCancel = () => {
        history.goBack();
    };

    const handleBack = () => {
        setTransferStep(false);
    };

    const { values, handleChange, handleBlur, handleSubmit, errors, touched, setFieldValue } =
        useFormik({
            initialValues: initData,
            validationSchema: createNews,
            enableReinitialize: true,
            onSubmit: handleNewsSubmit,
        });


    // Editable Variant Table
    interface IMyTableData {
        id: string;
        variant_name: string;
        quantity?: string;
        raw_price: number;
        net_price?: number;
        barcode: string;
        status?: boolean;
        is_enable: boolean;
        store_list: [
            {
                store_code: string;
                qty: number;
                is_enable: boolean;
            }
        ];
    }

    interface ITableInv {
        name: string;
        stock: string;
        orderNum: number;
        orderUnit: string;
    }

    const [tempVariant, setTempVariant]: any = useState<IMyTableData[]>([]);
    const [tempBranch, setTempBranch]: any = useState<any>([]);

    const [barcode, setBarcode] = useState<string>("");
    const [tableData, setTableData] = useState(tempVariant);
    const [tableData2, setTableData2] = useState<any>(tempBranch);


    const changeQty = (action: string, index) => {
        let arr = consumeList;
        if (action === "minus") {
            if (arr[index].restock_qty > 0) {
                let minusQty = arr[index].restock_qty - (arr[index]?.delivery_qty || 1);
                if (minusQty < 0) {
                    minusQty = 0;
                }
                arr[index].restock_qty = minusQty;
            }
        } else if (action === "add") {
            let addQty = arr[index].restock_qty + arr[index].delivery_qty;
            arr[index].restock_qty = addQty;
        }
        setConsumeList([...arr]);
        // let newData = checkInvList;
        // if (action === "minus") {
        //     if (newData[index].restock_qty > 0) {
        //         let minusQty = newData[index].restock_qty - newData[index].delivery_qty;
        //         if (minusQty < 0) {
        //             minusQty = 0;
        //         }
        //         newData[index].restock_qty = minusQty;
        //     }
        // } else if (action === "add") {
        //     let addQty = newData[index].restock_qty + newData[index].delivery_qty;
        //     newData[index].restock_qty = addQty;
        // }
        // setCheckInvList([...newData]);
    };

    const onInputChange2 = (key, index) => (e: React.ChangeEvent<HTMLInputElement>) => {
        let newData = consumeList;
        const re = /^[0-9\b]+$/;

        if (re.test(e.target.value)) {
            newData[index].restock_qty = Number(e.target.value);
        } else if (e.target.value === "") {
            newData[index].restock_qty = "";
        }
        setConsumeList([...newData]);
    };

    const inputBlur = (index) => (e: React.ChangeEvent<HTMLInputElement>) => {
        let newData = consumeList;
        let modNum = newData[index].delivery_qty || 1;
        if (
            Number(e.target.value) % modNum !== 0
        ) {
            warning("please check delivery quantity");
            newData[index].restock_qty = 0;
            setConsumeList([...newData]);
        }
    };


    const DATE_FORMAT = "YYYY-MM-DD";

    const deleteInv = (iIndex: number) => {
        let arr = consumeList;
        arr.splice(iIndex, 1);
        setConsumeList([...arr]);
    };

    const addCheckInv = async (inInv: any) => {
        await null;
        const arr: any[] = [...checkInvList];
        let checkIndex = checkInvList?.findIndex((x) => x?.consumable_id === inInv?.consumable_id);
        if (checkIndex > -1) {
            arr.splice(checkIndex, 1);
        } else {
            arr.push({
                consumable_id: inInv.consumable_id,
                restock_qty: 0,
                name: inInv.name,
                unit: inInv.unit,
                delivery_qty: inInv.delivery_qty,
            });
        }
        setCheckInvList([...arr]);

    };

    const transferStepHandler = () => {
        const arr: any[] = consumeList;
        // cateItemList.forEach((cItem: any) => {
        //     cItem?.itemList?.forEach((fItem: any) => {
        //         if (fItem?.add_qty > 0) {
        //             let obj = {
        //                 consumable_id: fItem?.consumable_id,
        //                 add_qty: fItem?.add_qty,
        //                 name: fItem?.name,
        //                 unit: fItem?.unit,
        //             };
        //             //@ts-ignore
        //             arr.push(obj);
        //         }
        //     });
        // });
        // setConsumeList(checkInvList);
        if (focusStore && arr.length !== 0) {
            if (transferStep === false) {
                setTransferStep(true);
            } else {
                confirmTransfer();
            }
        }
    };

    const confirmTransfer = async () => {
        try {
            await null;
            const arr: ICreateTRList[] = [];
            consumeList.forEach((cItem) => {
                if (cItem?.restock_qty !== 0) {
                    // let {
                    //     delivery_qty,
                    //     name,
                    //     unit,
                    //     ...cObj
                    // } = cItem;
                    arr.push({
                        consumable_id: cItem?.consumable_id,
                        restock_qty: cItem?.restock_qty,
                        request_to_store_id: focusStore
                    });
                }
            });
            let payload = {
                organization_id: org_id || "",
                store_id: fromStore,
                ttr_consumable_list: arr,
                remark: "",
            };
            const createTtr = await POAPI.createTtr(payload);
            if (createTtr.data.error === "0") {
                success(t("message.create.success"));
                history.push(PATH_TRANSFER_REQUEST);
            } else {
                error(createTtr.data.msg);
            }
        } catch (err: any) {
            error(err.message);
        }
    };

    return (
        <div className="form-wrap">
            <form onSubmit={handleSubmit}>
                <div className="page-header">
                    <h3>
                        {t("page.transfer_request.create_TR_title")}
                    </h3>
                </div>
                <div className="page-body" >
                    <div className="card-wrap">
                        <div className="card-wrap">
                            <StyledCustom>
                                <div className="row">
                                    <div className="step">
                                        <div className="blueCircle">1</div>
                                        <p className="title2">{t("page.transfer_request.select_inv")}</p>
                                    </div>
                                    <div className="arrowRight">
                                        <svg
                                            width="14"
                                            height="11"
                                            viewBox="0 0 14 11"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M12.1997 5.09865L8.0354 1.46012C7.83577 1.28569 7.81534 0.982461 7.98976 0.782832C8.16419 0.583203 8.46742 0.562773 8.66705 0.737199L13.787 5.21082C13.7884 5.21197 13.7897 5.21312 13.791 5.21428C13.8935 5.30231 13.9585 5.4329 13.9585 5.57865C13.9585 5.75719 13.861 5.91295 13.7164 5.99565L8.66756 10.4197C8.46818 10.5944 8.16492 10.5744 7.99021 10.375C7.8155 10.1756 7.8355 9.87235 8.03488 9.69765L12.1878 6.05865L0.841075 6.05865C0.575978 6.05865 0.361074 5.84375 0.361074 5.57865C0.361074 5.31356 0.575978 5.09865 0.841075 5.09865L12.1997 5.09865Z"
                                                fill="#919EAB"
                                            />
                                        </svg>
                                    </div>
                                    <div className="step">
                                        <div className={transferStep === true ? "blueCircle" : "grayCircle"}>
                                            2
                                        </div>
                                        <p className={transferStep === true ? "title2" : "title2 whiteText"}>
                                            {t("page.transfer_request.enter_qty")}
                                        </p>
                                    </div>
                                </div>
                            </StyledCustom>
                        </div>
                        {step === 1 && focusStore !== "" && transferStep !== true && (
                            <div className="card-wrap">
                                <CustomStyles>
                                    <div className="divide-section">
                                        <div className="main-section">
                                            <StyledCard maxWidth="100%">
                                                <div className="title" style={{ fontSize: "25px", fontWeight: "bold" }}>
                                                    {t("page.transfer_request.step_1_title")}
                                                </div>
                                                <div className="add-btn-section">
                                                    <StyledTableButtonAddRow
                                                        type="danger"
                                                        size="default"
                                                        text={t(
                                                            "page.purchase_order.add_inventory_btn"
                                                        )}
                                                        onClick={() => setShowPopup(true)}
                                                        className="add-btn"
                                                        icon="plus"
                                                    />
                                                </div>

                                                <Row>
                                                    <div className="branchListSection">
                                                        <Row>
                                                            <Col md={10}>
                                                                {t("page.inventory")}
                                                            </Col>
                                                            <Col md={6}>
                                                                {t("page.in_restock_stock")}
                                                            </Col>
                                                            <Col md={8}>
                                                                {/* {t("page.action")} */}
                                                            </Col>
                                                        </Row>
                                                        <Col md={24}>
                                                            {consumeList?.map((iItem, iIndex) => {
                                                                return (
                                                                    <ComponentRowTrInv
                                                                        key={iIndex}
                                                                        data={iItem}
                                                                        itemJson={JSON.stringify(iItem)}
                                                                        index={iIndex}
                                                                        checkInvList={checkInvList}
                                                                        // addCheckInv={addCheckInv}
                                                                        delInv={deleteInv}
                                                                    />
                                                                );
                                                            })}
                                                        </Col>
                                                    </div>
                                                </Row>
                                            </StyledCard>
                                        </div>
                                    </div>

                                </CustomStyles>
                            </div>
                        )}
                        {transferStep && (
                            <div className="card-wrap">
                                <StyledCard maxWidth="100%">
                                    <div className="title" style={{ fontSize: "25px" }}>
                                        {t("page.select_branch_request_transfer")}
                                    </div>
                                    <CustomStyles>
                                        <div className="selectBranchSection">
                                            {consumeList.map((cItem, cIndex) => (
                                                <React.Fragment key={cIndex}>
                                                    <Row gutter={32}>
                                                        <Col md={9}>
                                                            <div className="title">
                                                                {cItem?.name}
                                                            </div>
                                                        </Col>
                                                        <Col md={9}>
                                                            1 delivery unit = {cItem?.delivery_qty} restock unit
                                                        </Col>
                                                        <Col md={5}>
                                                            {/* <MinusCircleOutlined />
                                                            <SharedInput />
                                                            <PlusCircleOutlined /> */}
                                                            <div className="quantity-columns">
                                                                <span
                                                                    onClick={() => {
                                                                        changeQty("minus", cIndex);
                                                                    }}
                                                                >
                                                                    <IconMinusCircle
                                                                        color={`${cItem.restock_qty === 0 ||
                                                                            cItem.restock_qty === ""
                                                                            ? "#A5A5A5"
                                                                            : "black"
                                                                            }`}
                                                                    />
                                                                </span>
                                                                <Input
                                                                    value={cItem.restock_qty}
                                                                    onChange={onInputChange2("add_qty", cIndex)}
                                                                    onBlur={inputBlur(cIndex)}
                                                                />
                                                                <span
                                                                    onClick={() => {
                                                                        changeQty("add", cIndex);
                                                                    }}
                                                                >
                                                                    <IconAddCircle color="black" />
                                                                </span>
                                                                <p>{cItem.unit}&nbsp;</p>
                                                            </div>

                                                        </Col>
                                                    </Row>
                                                </React.Fragment>
                                            ))}
                                        </div>
                                    </CustomStyles>
                                </StyledCard>

                            </div>
                        )}

                    </div>
                </div>
                <div className="btn-layout">
                    <StyledCancelButton
                        type="sub"
                        text={t("page.back")}
                        htmlType="button"
                        onClick={
                            transferStep
                                ? handleBack
                                : handleCancel
                        }

                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <StyledSubmitButton
                        type="default"
                        text={
                            transferStep
                                ? t("page.confirm_request_transfer")
                                : t("page.next")
                        }
                        htmlType="submit"
                        onClick={transferStepHandler}
                    />
                </div>
            </form>
            <ComponentTRModal
                store_id={focusStore}
                showPopup={showPopup}
                handleClose={() => setShowPopup(!showPopup)}
                addConsume={addConsume}
                cate_id={cateItemList?.[focusCate]?.cate_id}
                type={itemType}
            />
        </div>
    );
});
const CustomStyles = styled.div`
    .divide-section {
        display: grid;
        /* grid-template-columns: 60% 30%;
        grid-gap: 30px; */
        .main-section {
            max-height: 45vh;
            overflow: auto;
            display: flex;
            flex-direction: column;
        }
        .side-section {
            border-radius: 6px;
            background-color: #fff;
            max-height: 45vh;
            overflow: auto;
            display: flex;
            flex-direction: column;
            .side-header {
                background-color: #646464;
                height: 48px;
                /* padding: 10px; */
                color: white;
                border-radius: 6px 6px 0 0;
                padding: 10px 15px;
            }
            .side-list {
                .check-item {
                    display: flex;
                    justify-content:space-between;
                    background-color: #F7F7F8;
                    padding: 10px;
                    height: 40px;
                    p {
                    margin: none;
                    }
                    border-radius: 4px;
                    margin: 5px 10px;
                    .del-item {
                    color: ${theme.colors.main};
                    cursor: pointer;
                    }
                }
            }
           
        }
    }
    .ant-table-row {
        font-size: 16px;
    }
    .tab-section {
        margin: 15px 0px;
        .tab-btn {
            background: none;
            border: none;
            font-weight: 700;
            font-size: 16px;
            padding: 0px;
            padding-bottom: 20px;
            margin-right: 30px;
        }
        .tab-btn:hover {
            color: #f4394f;
        }
        .active {
            color: #f4394f;
            border-bottom: 4px solid #f4394f !important;
        }
    }
    .branchListTitle {
        font-size: 16px;
        font-weight: 700;
        color: #000;
    }
    .branchListSection {
        margin-top: 20px;
        overflow-y: auto;
        max-height: 100%;
        /* flex-grow: 1; */
        td {
            padding: 0px;
            padding-top: 5px;
        }
    }
    .branchListSection .antd-btn-custom {
        margin-left: 0;
    }
    .selectBranchBox {
        position: absolute;
        background: #fff;
        margin-top: 10px;
        z-index: 999;
    }
    .ant-checkbox-wrapper {
        width: 100%;
    }
    .chooseTypeRow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 30px;
        align-items: flex-end;
        .dueDate {
            display: flex;
            align-items: center;
            label {
                width: 90px;
            }
        }
    }
    .choosePRTypeRow {
        display: flex;
        flex-direction: row;
        gap: 16px;
        .prTypeSelection {
            height: 60px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            svg {
                margin: auto auto auto 30px;
            }
        }
        .prTypeRadio {
            border-radius: 8px;
            border: 1px solid #e1e1e1;
            background: #fff;
            display: flex;
            flex-direction: row;
            position: relative;
            padding: 0 16px;
            place-items: center;
            p {
                margin: 0;
            }
            span {
                display: flex;
                flex-direction: row;
                padding-right: 0;
            }
        }
    }
    .selectBranchSection {
        max-height: 40vh;
        overflow-y: auto;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        .grayBtn {
            height: 70px;
            display: flex;
            flex-direction: row;
            border-radius: 6px;
            background: #f7f7f8;
            align-items: center;
            padding: 16px 20px;
            justify-content: space-between;
            p {
                color: #000;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin: 0;
            }
        }
        .branchTransferBox.active {
            background: linear-gradient(
                    0deg,
                    rgba(2, 99, 224, 0.08) 0%,
                    rgba(2, 99, 224, 0.08) 100%
                ),
                #fff;
        }
        .branchTransferBox {
            height: 70px;
            border-radius: 6px;
            border: 0.5px solid #a5a5a5;
            background: #fff;
            padding: 16px 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            p {
                color: #000;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin: 0;
            }
        }
    }
    .editIcon {
        cursor: pointer;
    }
`;

const StyledCustom = styled.div`
    background-color: #fff;
    width: 100%;
    padding: 20px;
    .secondTitle {
        font-size: 20px;
    }
    .row {
        display: flex;
        align-items: center;
    }
    .step {
        display: flex;
        align-items: center;
        p {
            margin: 0;
            color: ${theme.colors.main};
            font-weight: 700;
            font-size: 16px;
        }
        .whiteText {
            color: #a5a5a5;
        }
    }
    .blueCircle {
        background: ${theme.colors.main};
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: flex;
        place-content: center;
        align-items: center;
        color: #fff;
        font-size: 12px;
        font-weight: 700;
        margin-right: 10px;
    }
    .grayCircle {
        background: #a5a5a5;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: flex;
        place-content: center;
        align-items: center;
        color: #fff;
        font-size: 12px;
        font-weight: 700;
        margin-right: 10px;
    }
    .arrowRight {
        margin: auto 20px;
    }
`;