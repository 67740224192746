import { useTranslation } from "react-i18next";
import { Col, Row, Menu, Dropdown, Button } from "antd";
import { MoreHorizontal } from "react-feather";
import { IDataexample, IGetPosPromotionCategorysExample } from "@interfaces";
import { selectAuth } from "@redux";
import { useSelector } from "react-redux";
import { useNotify } from "@utils";
import styled from "styled-components";
import { orgApi } from "@api";

interface IProps {
    handleEdit: (status: boolean, data: IDataexample) => void;
    callbackGetList: () => void;
    index: number;
    categoryList: IGetPosPromotionCategorysExample[];
    editItem: (data: any) => void;
}

export const ComponentRowPromotionCategory = (props: IProps) => {
    //hooks
    const { t } = useTranslation();
    const { success, error } = useNotify();
    const org_id: any = useSelector(selectAuth).userInfo?.merchant._id;

    //props
    const { callbackGetList, categoryList, index, editItem } = props;

    const handleDelete = async () => {
        try {
            const res = await orgApi.deletePosPromotionCategory({
                organization_id: org_id,
                pos_promotion_category_id: categoryList[index]._id,
            });
            if (res.data.error === "0") {
                await callbackGetList();
                success(t("message.delete.success"));
            }
        } catch (err: any) {
            if (err) {
                error(err.msg);
            } else error(t("message.delete.fail"));
        }
    };

    const menu = (
        <Menu>
            <Menu.Item key={1} onClick={handleDelete}>
                <div className="duplicate-btn">{t("page.delete")}</div>
            </Menu.Item>
        </Menu>
    );
    return (
        <Styless>
            <div className="table-element">
                <div className="table-row">
                    <Row
                        style={{ height: "100%" }}
                        gutter={24}
                        type="flex"
                        align="middle"
                        justify="space-between"
                    >
                        <Col className="col-item" span={12}>
                            <p
                                className="news-title"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    editItem(categoryList[index]);
                                }}
                            >
                                {categoryList[index].category || t("page.empty_text")}
                            </p>
                        </Col>
                        <Col className="col-item visible-md" span={6}>
                            <p className="news-title">-</p>
                        </Col>
                        <Col className="col-item" span={6} style={{ placeContent: "end" }}>
                            <Dropdown
                                //@ts-ignore
                                getPopupContainer={(trigger) => trigger.parentNode}
                                overlay={menu}
                                trigger={["click"]}
                                placement="bottomRight"
                            >
                                <Button style={{ border: "unset" }}>
                                    <MoreHorizontal />
                                </Button>
                            </Dropdown>
                        </Col>
                    </Row>
                </div>
            </div>
        </Styless>
    );
};
export const Styless = styled.div`
    .table-row {
        height: 60px;
    }
`;
