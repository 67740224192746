export const ColumnRightArrow = () => (
    <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1 14.3333L7 7.99992L0.999999 1.66658"
            stroke="#646464"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
