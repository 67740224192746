import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Switch, Col, Row, Avatar, Menu, Dropdown, Button } from "antd";
import { MoreHorizontal } from "react-feather";

import { IDataexample, INewsCategory, IStoreSelect } from "@interfaces";
import { PATH_SUPPLIER } from "@configs";
import { selectAuth } from "@redux";
import { useSelector } from "react-redux";
import { IconDrag } from "@components";
import { useNotify } from "@utils";
import React, { useEffect, useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import axios from "axios";
import { productAPI } from "@api";
import { useHistory } from "react-router";

interface IProps {
    data: IDataexample | any;
    itemJson: string;
    index: number;
    storeList: IStoreSelect[];
}

export const ComponentRowSupplier = (props: IProps) => {
    //hooks
    const { t } = useTranslation();
    const { success, error } = useNotify();
    const history = useHistory();
    const organization_id = useSelector(selectAuth).auth?.organization_data?._id;
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    //props
    const { data, itemJson, storeList } = props;
    const { attributes, listeners, setNodeRef, transform } = useSortable({
        id: itemJson,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
    };

    const menu = (
        <Menu>
            <Menu.Item
                key={1}
                onClick={() => {
                    history.push(PATH_SUPPLIER + "/" + data?._id);
                }}
            >
                <div className="duplicate-btn" style={{ color: "#000" }}>
                    {t("page.edit")}
                </div>
            </Menu.Item>
            <Menu.Item
                key={1}
                onClick={() => {
                    // history.push(PATH_SUPPLIER + "/" + data?._id);
                }}
            >
                <div className="duplicate-btn" style={{ color: "#000" }}>
                    {t("page.delete")}
                </div>
            </Menu.Item>
        </Menu>
    );
    return (
        <div className="table-element" ref={setNodeRef} style={style}>
            <div className="drag-icon hide" {...attributes} {...listeners}>
                <IconDrag />
            </div>
            <div className="table-row">
                <Row
                    style={{ height: "100%" }}
                    gutter={24}
                    type="flex"
                    align="middle"
                    justify="space-between"
                >
                    <Col className="col-item" span={4}>
                        <Link to={`${PATH_SUPPLIER}/${data?._id}`}>
                            <p>{data?.supplier_name || t("page.empty_text")}</p>
                        </Link>
                    </Col>
                    <Col className="col-item" span={4}>
                        <p>{data?.supplier_code || t("page.empty_text")}</p>
                    </Col>
                    <Col className="col-item visible-md" span={6}>
                        <p>{data?.address || t("page.empty_text")}</p>
                    </Col>
                    <Col className="col-item price" span={3}>
                        <p>{data?.tax_id || t("page.empty_text")}</p>
                    </Col>
                    <Col className="col-item" span={3}>
                        <p>{data?.credit_term_in_day || "0"}</p>
                    </Col>
                    <Col className="col-item" span={2}>
                        <p>{data?.vat_type || t("page.empty_text")}</p>
                    </Col>
                    <Col className="col-item" span={2}>
                        <Dropdown
                            //@ts-ignore
                            getPopupContainer={(trigger) => trigger.parentNode}
                            overlay={menu}
                            trigger={["click"]}
                            placement="bottomRight"
                        >
                            <Button style={{ border: "unset" }}>
                                <MoreHorizontal />
                            </Button>
                        </Dropdown>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
