import { enumDrawerPlacement } from "@configs";
import { useTranslation } from "react-i18next";
import {
    SharedInput,
    StyledSubmitButton,
    StyledCancelButton,
    SharedComponentDrawer,
} from "@components";
import { useNotify } from "@utils";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth } from "@redux";
import { orgApi } from "@api";

interface IProps {
    showDrawer: boolean;
    handleClose: () => void;
    editItem?: any;
}

export const ComponentPromotionCategoryDrawer = (props: IProps) => {
    const history = useHistory();
    const { showDrawer, handleClose, editItem } = props;
    const { t } = useTranslation();
    const { success, error } = useNotify();
    const org_id: any = useSelector(selectAuth).userInfo?.merchant._id;
    const [CatName, setCatName] = useState<string>("");
    const dispatch = useDispatch();
    useEffect(() => {
        if (editItem) {
            setCatName(editItem.category);
        } else {
            setCatName("");
        }
    }, [editItem]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCatName(e.target.value);
    };

    const handleSave = async () => {
        try {
            if (editItem) {
                const updateCat = await orgApi.updatePosPromotionCategory({
                    organization_id: org_id,
                    category: CatName,
                    pos_promotion_category_id: editItem._id,
                });
                if (updateCat.data.error === "0") {
                    handleClose();
                    setCatName("");
                    success(t("message.update.success"));
                }
            } else {
                const res = await orgApi.createPosPromotionCategory({
                    organization_id: org_id,
                    category: CatName,
                });
                if (res.data.error === "0") {
                    handleClose();
                    setCatName("");
                    success(t("message.create.success"));
                }
            }
        } catch (err) {
            console.log(err);
        }
    };
    return (
        <SharedComponentDrawer
            title={t("page.add_promotion")}
            placement={enumDrawerPlacement.RIGHT}
            onClose={handleClose}
            visible={showDrawer}
            width={538}
        >
            <SharedInput
                label={t("page.promotion_category_name")}
                onChange={handleChange}
                value={CatName}
                parentClassName="mb-6"
            />
            <div className="btn-layout">
                <StyledSubmitButton
                    type="default"
                    text={t("page.save")}
                    htmlType="button"
                    onClick={handleSave}
                />
                <StyledCancelButton
                    type="sub"
                    text={t("page.cancel")}
                    htmlType="button"
                    onClick={handleClose}
                />
            </div>
        </SharedComponentDrawer>
    );
};
