export const PurchaseRequestIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="34"
            height="48"
            viewBox="0 0 34 48"
            fill="none"
        >
            <path
                d="M33.4766 0.937073H1.71427C1.42496 0.937073 1.19043 1.17173 1.19043 1.46119V3.56739C1.19043 3.85685 1.42496 4.09151 1.71427 4.09151H33.4766C33.7659 4.09151 34.0005 3.85685 34.0005 3.56739V1.46119C34.0005 1.17173 33.7659 0.937073 33.4766 0.937073Z"
                fill="white"
                fillOpacity="0.5"
            />
            <path
                d="M33.4302 2.6412C33.0083 2.6412 32.6288 2.81149 32.3558 3.08814C32.0793 3.36126 31.9091 3.74097 31.9091 4.16313V44.4985C31.9091 45.9147 30.7615 47.0629 29.346 47.0629H1.71582V5.97963C1.71582 4.13483 3.20866 2.6412 5.04897 2.6412H33.4302Z"
                fill="#F3F6FD"
            />
            <path
                d="M29.6982 47.0629C20.3888 47.0629 11.0794 47.0629 1.76979 47.0629C-0.589932 47.0629 -0.589932 43.5215 1.76979 43.5215H29.6982C27.3384 43.5215 27.3384 47.0627 29.6982 47.0629Z"
                fill="#BBCAEA"
            />
            <rect
                x="8.66406"
                y="9.28033"
                width="16.2891"
                height="1.98302"
                rx="0.99151"
                fill="#BBCAEA"
            />
            <path
                d="M21.6063 12.6306C21.6063 12.7628 21.4969 12.8723 21.3647 12.8723H12.1254C11.9906 12.8723 11.8838 12.7628 11.8838 12.6306C11.8838 12.4984 11.9906 12.3889 12.1254 12.3889H21.3647C21.4969 12.3889 21.6063 12.4984 21.6063 12.6306Z"
                fill="#BBCAEA"
            />
            <path
                d="M5.50474 16.663H4.76229C4.68538 16.663 4.62305 16.6007 4.62305 16.5237C4.62305 16.4467 4.68538 16.3844 4.76229 16.3844H5.50474C5.58166 16.3844 5.64399 16.4467 5.64399 16.5237C5.64399 16.6007 5.58166 16.663 5.50474 16.663Z"
                fill="#4A5258"
            />
            <path
                d="M26.7883 16.663H25.268C25.1911 16.663 25.1288 16.6006 25.1288 16.5237C25.1288 16.4467 25.1911 16.3843 25.268 16.3843H26.7883C26.8652 16.3843 26.9275 16.4467 26.9275 16.5237C26.9275 16.6006 26.8652 16.663 26.7883 16.663ZM23.7478 16.663H22.2275C22.1506 16.663 22.0882 16.6006 22.0882 16.5237C22.0882 16.4467 22.1506 16.3843 22.2275 16.3843H23.7478C23.8247 16.3843 23.887 16.4467 23.887 16.5237C23.887 16.6006 23.8247 16.663 23.7478 16.663ZM20.7072 16.663H19.187C19.1101 16.663 19.0478 16.6006 19.0478 16.5237C19.0478 16.4467 19.1101 16.3843 19.187 16.3843H20.7072C20.7842 16.3843 20.8465 16.4467 20.8465 16.5237C20.8465 16.6006 20.7842 16.663 20.7072 16.663ZM17.6667 16.663H16.1465C16.0696 16.663 16.0072 16.6006 16.0072 16.5237C16.0072 16.4467 16.0696 16.3843 16.1465 16.3843H17.6667C17.7437 16.3843 17.806 16.4467 17.806 16.5237C17.806 16.6006 17.7437 16.663 17.6667 16.663ZM14.6262 16.663H13.106C13.0291 16.663 12.9667 16.6006 12.9667 16.5237C12.9667 16.4467 13.0291 16.3843 13.106 16.3843H14.6262C14.7032 16.3843 14.7655 16.4467 14.7655 16.5237C14.7655 16.6006 14.7032 16.663 14.6262 16.663ZM11.5857 16.663H10.0655C9.98857 16.663 9.92624 16.6006 9.92624 16.5237C9.92624 16.4467 9.98857 16.3843 10.0655 16.3843H11.5857C11.6627 16.3843 11.725 16.4467 11.725 16.5237C11.725 16.6006 11.6627 16.663 11.5857 16.663ZM8.54524 16.663H7.02499C6.94807 16.663 6.88574 16.6006 6.88574 16.5237C6.88574 16.4467 6.94807 16.3843 7.02499 16.3843H8.54524C8.62216 16.3843 8.68449 16.4467 8.68449 16.5237C8.68449 16.6006 8.62216 16.663 8.54524 16.663Z"
                fill="#4A5258"
            />
            <path
                d="M29.0506 16.663H28.3082C28.2313 16.663 28.1689 16.6006 28.1689 16.5237C28.1689 16.4467 28.2313 16.3843 28.3082 16.3843H29.0506C29.1276 16.3843 29.1899 16.4467 29.1899 16.5237C29.1899 16.6006 29.1276 16.663 29.0506 16.663Z"
                fill="#4A5258"
            />
            <path
                d="M18.352 21.7107H4.91821C4.74279 21.7107 4.60059 21.8529 4.60059 22.0285V22.0366C4.60059 22.2121 4.74279 22.3544 4.91821 22.3544H18.352C18.5274 22.3544 18.6696 22.2121 18.6696 22.0366V22.0285C18.6696 21.8529 18.5274 21.7107 18.352 21.7107Z"
                fill="#D1DCF8"
            />
            <path
                d="M27.3477 21.7379H21.5803C21.4049 21.7379 21.2627 21.8801 21.2627 22.0556V22.0638C21.2627 22.2393 21.4049 22.3816 21.5803 22.3816H27.3477C27.5231 22.3816 27.6653 22.2393 27.6653 22.0638V22.0556C27.6653 21.8801 27.5231 21.7379 27.3477 21.7379Z"
                fill="#D1DCF8"
            />
            <path
                d="M18.352 24.5491H4.91821C4.74279 24.5491 4.60059 24.6914 4.60059 24.8669V24.8751C4.60059 25.0506 4.74279 25.1929 4.91821 25.1929H18.352C18.5274 25.1929 18.6696 25.0506 18.6696 24.8751V24.8669C18.6696 24.6914 18.5274 24.5491 18.352 24.5491Z"
                fill="#D1DCF8"
            />
            <path
                d="M27.3477 24.5763H21.5803C21.4049 24.5763 21.2627 24.7186 21.2627 24.8941V24.9023C21.2627 25.0778 21.4049 25.2201 21.5803 25.2201H27.3477C27.5231 25.2201 27.6653 25.0778 27.6653 24.9023V24.8941C27.6653 24.7186 27.5231 24.5763 27.3477 24.5763Z"
                fill="#D1DCF8"
            />
            <path
                d="M18.352 27.3875H4.91821C4.74279 27.3875 4.60059 27.5298 4.60059 27.7053V27.7135C4.60059 27.889 4.74279 28.0312 4.91821 28.0312H18.352C18.5274 28.0312 18.6696 27.889 18.6696 27.7135V27.7053C18.6696 27.5298 18.5274 27.3875 18.352 27.3875Z"
                fill="#D1DCF8"
            />
            <path
                d="M27.3477 27.4147H21.5803C21.4049 27.4147 21.2627 27.557 21.2627 27.7325V27.7406C21.2627 27.9162 21.4049 28.0584 21.5803 28.0584H27.3477C27.5231 28.0584 27.6653 27.9162 27.6653 27.7406V27.7325C27.6653 27.557 27.5231 27.4147 27.3477 27.4147Z"
                fill="#D1DCF8"
            />
            <path
                d="M18.352 30.2259H4.91821C4.74279 30.2259 4.60059 30.3682 4.60059 30.5437V30.5519C4.60059 30.7274 4.74279 30.8697 4.91821 30.8697H18.352C18.5274 30.8697 18.6696 30.7274 18.6696 30.5519V30.5437C18.6696 30.3682 18.5274 30.2259 18.352 30.2259Z"
                fill="#D1DCF8"
            />
            <path
                d="M27.3477 30.2531H21.5803C21.4049 30.2531 21.2627 30.3954 21.2627 30.5709V30.5791C21.2627 30.7546 21.4049 30.8969 21.5803 30.8969H27.3477C27.5231 30.8969 27.6653 30.7546 27.6653 30.5791V30.5709C27.6653 30.3954 27.5231 30.2531 27.3477 30.2531Z"
                fill="#D1DCF8"
            />
            <path d="M20.4206 34.2103H19.5957V39.8922H20.4206V34.2103Z" fill="#BBCAEA" />
            <path d="M27.0147 34.2103H26.7275V39.8922H27.0147V34.2103Z" fill="#BBCAEA" />
            <path d="M24.5338 34.2103H23.8184V39.8922H24.5338V34.2103Z" fill="#BBCAEA" />
            <path d="M23.2589 34.2103H22.8955V39.8922H23.2589V34.2103Z" fill="#BBCAEA" />
            <path d="M28.6691 34.2103H27.4541V39.8922H28.6691V34.2103Z" fill="#BBCAEA" />
            <path d="M26.2247 34.2103H25.8613V39.8922H26.2247V34.2103Z" fill="#BBCAEA" />
            <path d="M25.4005 34.2103H25.0371V39.8922H25.4005V34.2103Z" fill="#BBCAEA" />
            <path d="M22.3354 34.2103H20.9941V39.8922H22.3354V34.2103Z" fill="#BBCAEA" />
            <path
                d="M9.13803 40.7846C11.3144 40.7846 13.0788 39.0193 13.0788 36.8417C13.0788 34.6642 11.3144 32.8989 9.13803 32.8989C6.9616 32.8989 5.19727 34.6642 5.19727 36.8417C5.19727 39.0193 6.9616 40.7846 9.13803 40.7846Z"
                fill="#0263E0"
            />
            <path
                d="M10.7998 37.8199C10.7998 38.4627 10.3673 38.8149 9.50219 38.8764V39.4249C9.50219 39.475 9.47639 39.5 9.4248 39.5H8.98433C8.93274 39.5 8.90695 39.475 8.90695 39.4249V38.888H7.87719C7.8256 38.888 7.7998 38.863 7.7998 38.8129V35.1755C7.7998 35.1255 7.8256 35.1005 7.87719 35.1005H8.90695V34.5751C8.90695 34.525 8.93274 34.5 8.98433 34.5H9.4248C9.47639 34.5 9.50219 34.525 9.50219 34.5751V35.1178C9.8752 35.1524 10.151 35.2525 10.3296 35.418C10.5081 35.5835 10.5974 35.7991 10.5974 36.0647C10.5974 36.2725 10.5419 36.4477 10.4308 36.5901C10.3236 36.7286 10.1649 36.8287 9.95457 36.8903C10.2323 36.948 10.4427 37.0577 10.5855 37.2194C10.7284 37.3772 10.7998 37.5774 10.7998 37.8199ZM8.90695 35.6316H8.57361V36.694H8.90695V35.6316ZM9.87719 36.1628C9.87719 35.9126 9.75219 35.749 9.50219 35.6721V36.6536C9.62917 36.6189 9.72242 36.5593 9.78195 36.4746C9.84544 36.3899 9.87719 36.286 9.87719 36.1628ZM8.57361 37.1905V38.3568H8.90695V37.1905H8.57361ZM9.50219 38.3395C9.85933 38.2818 10.0379 38.0951 10.0379 37.7794C10.0379 37.4638 9.85933 37.2733 9.50219 37.2079V38.3395Z"
                fill="white"
            />
        </svg>
    );
};
