import { enumDrawerPlacement, PATH_PRODUCT, PATH_PRODUCT_CREATE } from "@configs";
import { Avatar, Button, Tabs, Tooltip, Radio, Input, InputNumber } from "antd";
import { useTranslation } from "react-i18next";
import { DeleteOutlined } from "@ant-design/icons";
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    TouchSensor,
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    rectSortingStrategy,
    //@ts-ignore
} from "@dnd-kit/sortable";
import {
    InfoIcon,
    IconCamera,
    SharedInput,
    StyledSubmitButton,
    StyledCancelButton,
    SharedComponentDrawer,
} from "@components";
import { useNotify } from "@utils";
import { IMAGE_TYPE, enumValidation } from "@configs";
import { useEffect, useState } from "react";
import { ComponentSortableItem } from "./SortableItem";
import axios from "axios";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getCategories, selectAuth } from "@redux";
import { orgApi, productAPI } from "@api";
import styled from "styled-components";
const textString = "390px × 270px Maximum 10MB";

interface IProps {
    showModal: boolean;
    handleClose: () => void;
    editItem?: any;
    product?: any;
    setRefresh?: any;
    Refresh?: any;
    selectedVariant?: any;
    setSelectedVariant?: any;
}

export const ProductEditQTYDrawer = (props: IProps) => {
    const history = useHistory();
    const {
        showModal,
        handleClose,
        editItem,
        product,
        setRefresh,
        Refresh,
        selectedVariant,
        setSelectedVariant,
    } = props;
    const { t } = useTranslation();
    const { success, error } = useNotify();
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const orgID = useSelector(selectAuth).userInfo?.merchant?._id;
    const [NewQTY, setNewQTY] = useState<number>(0);
    const [Tab, setTab] = useState<string>("1");
    const [reason, setReason] = useState<string>("");
    const dispatch = useDispatch();
    useEffect(() => {
        if (editItem) {
            setNewQTY(editItem.category);
        } else {
            setNewQTY(0);
        }
    }, [editItem]);

    const handleChange = (value) => {
        setNewQTY(value);
    };

    const handleSave = async () => {
        try {
            const res = await productAPI.updateVariantQuantityStoreId({
                organization_id: orgID,
                product_id: product._id,
                variant_id:
                    selectedVariant !== undefined
                        ? selectedVariant._id
                        : product.variant_list[0]._id,
                store_code: editItem.store_code,
                edited_qty: Tab === "1" ? NewQTY : -NewQTY,
                remark: reason,
            });
            if (res.data.error === "0") {
                handleClose();
                setNewQTY(0);
                setRefresh(!Refresh);
                setTab("1");
                setReason("");
                // await dispatch(getCategories({}));
                // history.push(PATH_PRODUCT);
                // history.push(PATH_PRODUCT_CREATE);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const { TabPane } = Tabs;
    const { TextArea } = Input;
    const radioStyle = {
        display: "block",
        height: "30px",
        lineHeight: "30px",
    };
    const onChange = (e) => {
        setReason(e.target.value);
    };
    return (
        <SharedComponentDrawer
            title={t("page.add_category")}
            placement={enumDrawerPlacement.RIGHT}
            onClose={handleClose}
            visible={showModal}
            width={538}
        >
            <DrawerStyle>
                <Tabs
                    defaultActiveKey="1"
                    onChange={(key) => {
                        if (key === "2") {
                            setTab("2");
                        } else {
                            setTab("1");
                        }
                    }}
                >
                    <TabPane tab={t("page.add_quantity")} key="1"></TabPane>
                    <TabPane tab={t("page.deduct_quantity")} key="2"></TabPane>
                </Tabs>
                <div className="grayBox">
                    <p>{t("page.current_quantity_pack")}</p>
                    <p className="number">{editItem && editItem.qty}</p>
                </div>
                <div className="qtyToAddInput">
                    <p className="title">
                        {Tab === "1"
                            ? t("page.quantity_to_add")
                            : t("page.enter_quantity_to_deduct")}
                    </p>
                    <InputNumber min={1} onChange={handleChange} size={"large"} value={NewQTY} />
                    <br />
                    <span>
                        {t("page.quantity")} {editItem && editItem.qty} {Tab === "1" ? "+" : "-"}{" "}
                        {NewQTY} ={" "}
                        {Tab === "1"
                            ? editItem && editItem.qty + NewQTY
                            : editItem && editItem.qty - NewQTY}{" "}
                        {Tab === "2" &&
                            `(${t("page.differenced")} = ${editItem && editItem.qty - NewQTY})`}
                    </span>
                </div>
                {Tab === "2" && (
                    <div className="reasonDiv">
                        <p className="reasonTitle">{t("page.reason_for_quantity_deduction")}</p>
                        <Radio.Group onChange={onChange} value={reason}>
                            <Radio style={radioStyle} value={"สินค้าชำรุด"}>
                                สินค้าชำรุด
                            </Radio>
                            <Radio style={radioStyle} value={"พนักงานทุจริต"}>
                                พนักงานทุจริต
                            </Radio>
                            <Radio style={radioStyle} value={"สินค้าหายไม่ทราบสาเหตุ"}>
                                สินค้าหายไม่ทราบสาเหตุ
                            </Radio>
                            <Radio style={radioStyle} value={"etc"}>
                                Etc.
                            </Radio>
                        </Radio.Group>
                        {reason === "etc" ? <TextArea rows={4} /> : null}
                    </div>
                )}
            </DrawerStyle>

            <div className="btn-layout">
                <StyledSubmitButton
                    type="default"
                    text={t("page.save")}
                    htmlType="button"
                    // disable={isSubmitting}
                    onClick={handleSave}
                />
                <StyledCancelButton
                    type="sub"
                    text={t("page.cancel")}
                    htmlType="button"
                    onClick={handleClose}
                />
            </div>
        </SharedComponentDrawer>
    );
};

export const DrawerStyle = styled.div`
    .ant-tabs-bar {
        border: none;
    }
    .ant-tabs-nav .ant-tabs-tab {
        padding: 8px 0;
        margin: 0 28px 0 0;
    }
    .ant-tabs-nav .ant-tabs-tab-active {
        color: #f4394f;
        font-weight: 600;
    }
    .ant-tabs-ink-bar {
        background-color: #f4394f;
    }
    .ant-tabs-nav .ant-tabs-tab:hover {
        color: #f4394f;
    }
    .grayBox {
        background: #f7f7f8;
        border-radius: 6px;
        width: 100%;
        place-content: center;
        place-items: center;
        padding: 16px;
        font-size: 16px;
        .number {
            font-size: 35px;
            color: #000;
        }
        p {
            font-weight: 700;
            margin: 0;
            text-align: center;
        }
    }
    .qtyToAddInput {
        margin-top: 24px;
        span {
            font-size: 12px;
            color: #6c7084;
            font-weight: 400;
        }
    }
    .reasonDiv {
        margin-top: 32px;
    }
    .reasonTitle {
        font-size: 16px;
        color: #000;
        font-weight: 700;
    }
    .ant-radio-inner {
        border-color: #646464;
    }
    span.ant-radio + * {
        color: #000;
    }
    .ant-input-number {
        width: 100%;
    }
`;
