export default {
    home: "หน้าหลัก",
    loyalty: "Loyalty",
    pos: "POS",
    communications: "Communications",
    analytics: "Analytics",
    marketing_automation: "Marketing Automation (Beta)",
    store_front: "Storefront",
    contact_list: "Contact list",
    LINE: "LINE",
    transaction: "รายการแลก",
    settings: "ตั้งค่า",
    dashboard: "Dashboard",
    workflow: "Workflow",
    help: "ช่วยเหลือ",
    deshboard_samll: "This is our main homepage",
    welcome_to_loyalty: "Welcome to Loyalty",
    product_lists: "คลังสินค้า",
    add_product: "เพิ่มสินค้า",
    search: "ค้นหา",
    search_product: "ค้นหาสินค้า",
    category: "หมวดหมู่สินค้า",
    please_choose: "เลือก",
    sub_Category: "Sub Category  :",
    state: "สถานนะ",
    result: "ผลลัพธ์",
    category_name: "ชื่อสินค้า",
    sub_category: "Sub Category",
    amount_variants: "ตัวเลือกสินค้า",
    price: "ราคา",
    storefront_display: "สถานะ",
    manage: "จัดการ",
    create_product: "เพิ่มสินค้า",
    edit_product: "แก้ไขสินค้า",
    product_settings: "ตั้งค่าสินค้า",
    images_product: "รูปภาพสินค้า",
    max_8_images: "(จำกัด 8 รูปภาพ)",
    add_images: "เพิ่มรูปภาพ",
    name_product: "ชื่อสินค้า",
    two_hundard_characters: "(2-100 ตัวอักษร)",
    headlines: "This field",
    product_code: "รหัสสินค้า",
    standerd_price: "ราคาขาย (แสดงบนเมนู)",
    discounts: "ส่วนลด",
    active_on_platform: "ช่องทางการขายบนแพลตฟอร์ม",
    exclude_pro_from_branch: "ยกเว้นสินค้าจากสาขา",
    include_promo_branch: "Include promotion to branch",
    point_deduction: "Point deduction",
    storefront_for_pickup: "Storefront for pickup",
    allow: "Allow",
    maximum_deduction: "Maximum deduction",
    variants: "ตัวเลือกสินค้า",
    this_product_has_options: "สินค้านี้มีตัวเลือก (เช่น ระดับ สี)",
    name_variants: "หัวข้อตัวเลือก",
    description: "รายละเอียดสินค้า",
    activate: "เปิดใช้งาน",
    save: "บันทึก",
    back: "ย้อนกลับ",
    next: "ถัดไป",
    finish: "สำเร็จ",
    cancel: "ยกเลิก",
    preview: "Preview",
    add_category: "เพิ่มหมวดหมู่สินค้า",
    yes: "มี",
    no: "ไม่มี",
    add_sub_category: "Add Sub Category",
    image_main_category: "รูปภาพหมวดหมู่",
    upload_image: "Upload Image",
    images: "Images",
    select_account: "Select Account",
    select_branch: "เลือกสาขา",
    user_guide: "คู่มือใช้งาน",
    purchase_request: {
        list_title: "สร้างใบขอซื้อ(PR)",
        add_PR: "สร้างการซื้อ(PR)",
        table_PR_number: "หมายเลข PR",
        table_status: "สถานะ",
        create_PR_title: "Create Purchase Request (PR)",
        edit_PR_title: "แก้ไขใบขอซื้อ(PR)",
    },
    transfer_request: {
        list_title: "Transfer Request (Tr)",
        add_TR: "Create Transfer Request (Tr)",
        create_TR_title: "Create Transfer Request",
        detail_all_TR_title: "All TR",
        table_TR_No: "TR No.",
        table_created_date: "Create Date",
        table_branch_requesting: "Branch Requesting",
        table_to_branch: "To Branch",
        table_qty_request: "Qty Request",
        table_state: "State",
        table_detail: "Detail",
        select_inv: "Select Inventory",
        enter_qty: "Enter Qty",
        branch_req: "สาขาที่ร้องขอ",
        from_branch: "สาขาที่จัดหา",
        choose_branch_modal: "Select branch create transfer request",
        step_1_title: "Please add inventory and enter quantity for request to transfer  other branch",
        inv_cate: "Inventory Category",
        tr_req_detail: "Transfer Request details",
        to_branch: "To branch",
        order_request: "Order Request",
        tr_no: "TR No.",
        qty_request_transfer: "QTY Request Transfer",
        cancel_tr: "Cancel TR",
    },
    purchase_order: {
        list_title: "สร้างใบสั่งซื้อ (PO)",
        add_PO: "สร้างใบสั่งซื้อ",
        detail_all_PO_title: "ทั้งหมด",
        detail_done_title: "เสร็จสิ้น",
        detail_pending_title: "รอดำเนินการ",
        create: "Create PO",
        detail_renew_title: "Create with Renew PO",
        detail_cancel_title: "ยกเลิก",
        detail_sheets: "รายการ",
        table_create_date: "วันที่สร้าง",
        table_PO_request: "เลขที่ใบสั่งซื้อ",
        table_supplier: "ผู้จัดจำหน่าย",
        table_due_date: "Due Date",
        table_store: "สาขาที่ขอซื้อ",
        create_PO_title: "สร้างใบสั่งซื้อ (PO)",
        edit_PO_title: "Edit Purchase Order (PO)",
        step: "ขั้นตอนที่",
        step_one_title: "กำหนดรายละเอียดการสั่งซื้อ",
        step_two_title: "เลือกผู้จัดจำหน่าย",
        step_three_title: "ระบุความต้องการถึงผู้จัดจำหน่าย",
        step_four_title: "พิมพ์ใบสั่งซื้อ",
        select_pr_title: "Select Purchase request",
        inventory_qr_title: "กำหนดจำนวนสินค้าคงคลังสำหรับการสั่งซื้อ",
        add_inventory_btn: "เพิ่มสินค้าคงคลัง",
        auto_gen_btn: "สร้างอัตโนมัติ",
        InvQ_row_name: "ชื่อสินค้า",
        InvQ_row_code: "รหัสสินค้า",
        InvQ_row_stock: "สต็อก (หน่วยรับ)",
        conversion: "Conversion",
        conversion_delivery: "Conversion (Delivery to Restock)",
        conversion_restock: "Conversion (Restock to Use)",
        InvQ_row_order: "จำนวน",
        modal_consume_title: "ค้นหาสินค้าคงคลัง",
        inventory_select_supplier: "เลือกผู้จัดจำหน่าย",
        inventory_specify_supplier: "ระบุความต้องการถึงผู้จัดจำหน่าย",
        inventory_PO_preview: "พิมพ์ใบสั่งสินค้า (PO)",
        step2_inventory: "สินค้าคงคลัง",
        step2_bath: "ราคา / หน่วยรับ",
        no_sup: "ไม่มีผู้จัดจำหน่าย",
        note: "หมายเหตุ",
        load_pdf: "เซฟ pdf",
        paper_title: "ใบสั่งซื้อ (PO)",
        modal_tab_ingredient: "วัตถุดิบ",
        modal_tab_product: "สินค้า",
        view_order: "ดูรายการ",
        delete_order: "ลบ order",
    },
    good_receive: {
        list_title: "ใบรับสินค้า (GR)",
        detail_all_PO_title: "ทั้งหมด",
        detail_waiting_title: "Waiting",
        detail_pending_title: "รอดำเนินการ",
        detail_done_title: "เสร็จสิ้น",
        detail_sheets: "Sheets",
        table_create_date: "วันที่สร้าง",
        table_purchase_request: "เลขที่ใบสั่งซื้อ",
        table_supplier: "ผู้จัดจำหน่าย",
        table_due_date: "Due Date",
        table_store: "ร้านค้าที่ขอซื้อ",
        table_status: "สถานะ",
        table_item_inventory: "ชื่อสินค้า",
        table_item_price: "จำนวน (หน่วยรับ)",
        table_item_baht: "ราคาต่อหน่วย",
        table_item_expiry: "หมดอายุ",
        receive_amount: "จำนวนสินค้า",
        detail_title: "ใบรับสินค้า",
        paper_title: "ใบรับสินค้า (GR)",
        load_paper: "บันทึกใบรับสินค้า .PDF",
        select_date: "เลือกวันที่",
        select_all: "เลือกทั้งหมด",
    },
    supplier: {
        list_title: "ผู้จัดจำหน่าย",
        add_supplier: "เพิ่มผู้จัดจำหน่าย",
        table_name_title: "ชื่อ",
        table_code_title: "รหัสผู้จัดจำหน่าย",
        table_address_title: "ที่อยู่",
        table_tax_title: "เลขประจำตัวผู้เสียภาษี",
        table_credit_title: "การให้สินเชื่อ",
        table_vat_title: "VAT",
        table_product_title: "สินค้า",
        create_sup_title: "สร้างผู้จัดจำหน่าย",
        sup_type: "ประเภท",
        edit_sup_title: "Edit Supplier",
        sup_setting: "สร้างผู้จัดจำหน่าย",
        sup_name: "ชื่อ",
        tax_id: "เลขประจำตัวผู้เสียภาษี",
        sup_code: "รหัสผู้จัดจำหน่าย",
        address: "ที่อยู่",
        vat: "ภาษีมูลค่าเพิ่ม",
        credit: "การให้สินเชื่อ",
        credit_day: "ระยะเวลาให้สินเชื่อ",
        include_vat: "VAT ใน",
        exclude_vat: "VAT นอก",
        internal: "internal",
        external: "external",
        yes: "ใช่",
        no: "ไม่ใข่",
        day: "วัน",
    },
    addon: {
        addon_title: "Add-On",
        table_addon_name: "Add-On Name",
        table_variant: "Variant",
        table_consume: "Inventory Assigned",
        radio_title: "ประเภทการจัดสูตรสินค้าคงคลัง",
        radio_product: "สินค้า",
        radio_addon: "Add-on",
        addon_option: "ตัวเลือก Add-On",
        add_addon_option: "เพิ่มตัวเลือก Add-On",
        select_addon: "เลือก Add-on",
        select_addon_desc: "Can Add Category Option on Add-On Product Category Menu",
    },
    exclude: {
        modal_title: "จำนวนสาขา",
        table_branch: "สาขา",
    },
    unit_option: {
        Bag: "ถุง",
        Bottle: "ขวด",
        Box: "กล่อง",
        Can: "กระป๋อง ",
        Cup: "ถ้วย",
        Gallon: "แกลลอน",
        Gram: "กรัม",
        Kilogram: "กิโลกรัม",
        Liter: "ลิตร",
        Milligram: "มิลลิกรัม",
        Milliliter: "มิลลิลิตร",
        Ounce: "ออนซ์",
        Pack: "แพ็ค ",
        Percent: "เปอร์เซ็นต์",
        Pieces: "ชิ้น",
        Pint: "ไพน์",
        Portion: "ส่วน",
        Pound: "ปอนด์",
        Quart: "ควอร์ต",
        Tablespoon: "ช้อนโต๊ะ",
        Teaspoon: "ช้อนชา",
    },
    sidebar: {
        services: "Services",
        services_list: "Services list",
        product_lists: "คลังสินค้า",
        purchase_request_lists: "ใบขอซื้อ(PR)",
        transfer_request_lists: "Transfer Require (TR)",
        purchase_order_lists: "ใบสั่งซื้อ (PO)",
        good_receive_lists: "ใบรับสินค้า (GR)",
        supplier_lists: "ผู้จัดจำหน่าย",
        catogory: "Catogory",
        in_store_product: "สินค้าหน้าร้าน",
        product_category: "ประเภทสินค้า",
        order_lists: "Order lists",
        settings_shipping_method: "Settings Shipping Method",
        promotions: "Promotions",
        staff_list: "Staff list",
        store_management: "จัดการร้านค้า",
        appointment_settings: "Appointment settings",
        payment_settings: "ตั้งค่าการชำระเงิน",
        staff: "Staff",
        staff_scheduling: "Staff scheduling",
        shift_types: "Shift types",
        store_list: "รายชื่อร้านค้า",
        permission_pos: "สิทธิการใช้งาน POS",
        format_payment_calculation: "ตั้งค่าองค์กร",
        contacts_list: "รายชื่อสมาชิก",
        segment: "Segment",
        merge_suggestions: "Merge suggestions",
        rewards: "ของรางวัล",
        member_benefits: "สิทธิพิเศษ",
        coupons: "คูปอง",
        merge_settings: "Merge settings",
        free_points: "Free points",
        loyalty_rules: "กฎเกณฑ์สมาชิก",
        user_sign_up_settings: "ตั้งค่าการสมัครสมาชิก",
        account_settings: "ตั้งค่าบัญชีร้านค้า",
        traffic_source: "Traffic source",
        features_settings: "ตั้งค่าฟีเจอร์",
        integrations: "การเชื่อมต่อ",
        manage_permissions: "ผู้ดูแลระบบ",
        settings: "ตั้งค่า",
        contacts: "สมาชิก",
        contacts_title: "รายชื่อ",
        loyalty: "Loyalty",
        current_plan: "Current plan",
        edit_points_member: "แก้ไข Points ของสมาชิก",
        accept_point: "ยืนยันการแลก",
        future_is_now: "The Future is now",
        transaction: "รายการแลก",
        points: "Points",
        user_info: "ข้อมูลของผู้ใช้งาน",
        noti_settings: "Notification Settings",
        edit_profile: "แก้ไขข้อมูลส่วนตัว",
        payments: "Payments",
        pricing_features: "แพลนที่กำลังใช้งานอยู่",
        set_theme: "ตั้งธีมร้าน",
        first_menu: "Menu",
        store_front: "Storefront",
        contact: "รายชื่อสมาชิก",
        loyalty_features: "Loyalty",
        gain_friends: "เพิ่มสมาชิก",
        user_guide: "คู่มือใช้งาน",
        pdpa_management: "นโยบายข้อมูล",
        ingredient: "Ingredient",
        recipe_list: "Recipe list",
        consumable_list: "สินค้าคงคลัง",
        assign_consumable: "จัดสูตรสินค้าคงคลัง",
        inventory: "สินค้าคงคลัง",
        inventory_list: "รายการสินค้าคงคลัง",
        edit_inventory_qty: "ปรับสต็อก",
        check_stock: "เช็คสต๊อก",
        add_on_product_category: "กลุ่มตัวเลือก",
        promotion_category: "ประเภทโปรโมชั่น",
        promotion_pos: "โปรโมชัน",
        wholesale_channel: "ช่องทางการสั่งซื้อ",
        waste_record: "บันทึกของเสีย",
        inv_cate: "ประเภทสินค้าคงคลัง",
        brand: "ยี่ห้อ",
        report_setting: "Report Setting",
        unit: "ตั้งค่าหน่วย",
        bill_promotion: "บิลโปรโมชั่น",
        supplier_list: "รายชื่อผู้จัดหาวัตถุดิบ",
        setting_category_check_stock_list: "รายการเช็คสต็อก",
        product_management: "จัดการสินค้า",
        create_manage_product: "เพิ่มและแก้ไขสินค้า",
        assign_inventory: "จ่ายสินค้าคงคลัง",
        addon: "ตัวเลือกเสริม",
        promotion: "โปรโมชั่น",
        inventory_management: "จัดการคลังสินค้า",
        store_channel: "สาขาและช่องทางการขาย",
        store_settings: "สาขา",
        category_management: "จัดการหมวดหมู่",
        inventory_units: "หน่วยสินค้าคงคลัง",
        check_stock_list: "Check stock list",
        organization_settings: "ตั้งค่าองค์กร",
        roles_permissions_settings: "รายการสิทธิ์ผู้ใช้งาน",
        roles_permissions: "รายการสิทธิ์ผู้ใช้งาน",
        promotion_groups: "คอลเลกชั่นสินค้า",
        import_table: "Import Table",
        export_table: "Export Table",
        import_export_table: "Import & Export",
        ranking: "ลำดับสินค้า",
        product_collections: "Product Collections",
    },
    manage_account: {
        accounts: "บัญชีของคุณ",
        select_account: "โปรดเลือกบัญชีที่คุณต้องการใช้งาน",
        account_name: "ชื่อบัญชี",
        our_customer: "จำนวนลูกค้า",
        add_account: "เพิ่มบัญชี",
        our_customer_m: "จำนวนลูกค้า :",
        role: "สิทธิการใช้งาน",
        plan: "Plan การใช้งาน",
        add_member: "สร้างบัญชีใหม่",
        enter_company_info: "กรอกข้อมูลบริษัท",
        confirm_info: "ยืนยันข้อมูล",
        complete: "เสร็จสิ้น",
        account_mame: "ชื่อบัญชี",
        account_owner: "เจ้าของบัญชี",
        appear_on_storefront: "ชื่อนี้จะเป็นชื่อร้านของคุณ",
        owner_account: "Owner account",
        digitalrocket_gmail: "Digitalrocket@gmail.com",
        logout: "Logout",
        business_info: "ข้อมูลธุรกิจของคุณ",
        company_name: "ชื่อบริษัทของคุณ",
        full_name: "ชื่อของคุณ",
        types_business_industries: "ประเภทธุรกิจ",
        david_dondonki: "David Dondonki",
        contact_business_account: "Contact for business account",
        telephone_number: "เบอร์โทรศัพท์",
        placeholder_number: "02-222-2222",
        how_many_people: "บริษัทคุณมีพนักงานจำนวนเท่าไหร่?",
        create_business_account: "Create business account",
        back: "ย้อนกลับ",
        next: "ถัดไป",
        create: "สร้าง",
        finish: "สำเร็จ",
        business_industries: "ประเภทธุรกิจ",
        fullname_contact: "ชื่อผู้ติดต่อ",
        people_your_company: "บริษัทคุณมีพนักงานจำนวนเท่าไหร่?",
        success_create_account: "คุณได้สร้างบัญชีใหม่สำเร็จแล้ว!",
        name_account: "Name Account",
        congratulations: "ยินดีด้วย!",
        your_program: "คุณได้สร้างบัญชีใหม่ของคุณสำเร็จ!",
        back_main_page: "กลับสู่หน้าเลือกบัญชี",
        faq_note:
            "FAQ : Note Verify Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,",
    },
    // Create Product Page
    The_product_have_a_barcode: "สินค้านี้มีบาร์โค้ดหรือไม่",
    price_by_variant: "Price by variant",
    add_variant: "เพิ่มตัวเลือก",
    variant_image: "รูปภาพตัวเลือกสินค้า",
    name_formula: "ชื่อตัวเลือกสินค้า*",
    enter_formula_name: "ระบุชื่อตัวเลือกสินค้า",
    enter_barcode: "ระบุบาร์โค้ด",
    view_barcode: "ดูบาร์โค้ด",
    barcode: "บาร์โค้ด",
    auto_generate: "สร้างบาร์โค้ดอัตโนมัติ",
    scan_barcode: "สแกนบาร์โค้ด",
    quantity_branch: "จำนวนสินค้าในแต่ละสาขา",
    branches_excluded_from_this_product: "สาขาที่ยกเว้นสินค้านี้",
    branches_include_from_this_promotion: "สาขาที่ร่วมโปรโมชั่นนี้",
    branch_lists: "Branch Lists",
    print_barcode: "พิมพ์บาร์โค้ด",
    delete: "ลบ",
    branch: "สาขา",
    branches_included_from_this_product: "สาขาที่เปิดขายสินค้านี้",
    product_name: "ชื่อสินค้า",
    enter_qty: "ระบุจำนวน",
    view_qty: "ดูจำนวน",
    quantity: "จำนวนสินค้า",
    variant: "ตัวเลือก",
    sale_amount: "ยอดขาย",
    standard_price: "ราคาสินค้า",
    net_price: "ราคาสุทธิ",
    print_barcode_all: "พิมพ์บาร์โค้ดทั้งหมด",
    add_variants: "เพิ่มตัวเลือก",
    add_edit_quantity: "Add / Edit Quantity",
    current_quantity_pack: "Current quantity (Pack)",
    differenced: "ส่วนต่าง",
    select_product_variant: "Select Product Variant",
    product_variant: "ตัวเลือกสินค้า",
    // Create Product Page
    // Category Page
    caregories_products: "หมวดหมู่สินค้า",
    category_12: "(12 หมวดหมู่)",
    // Category Page
    // Store List Page
    bulk_services_setting: "ตั้งค่าบริการจำนวนมาก",
    code: "รหัสร้านค้า",
    addon_code: "Add-on inventory code",
    store_name: "ชื่อร้านค้า",
    status: "สถานะร้านค้า",
    add_store: "เพิ่มร้านค้า",
    create_store: "สร้างร้านค้า",
    store_settings: "ตั้งค่าร้านค้า",
    name: "ชื่อร้านค้า",
    store_code: "รหัสร้านค้า",
    customer_service_tel: "เบอร์โทรศัพท์ (สำหรับลูกค้าติดต่อร้านค้า)",
    client_side_choose_by_staff: "ลูกค้าเลือกโดยพนักงาน",
    online_store: "Online store",
    is_client_order: "Turn on online store",
    store_on_client_order: "Store open on client order",
    turn_on_choose_by_staff_view: "เปิดเลือกตามมุมมองพนักงาน",
    business_hours: "เวลาทำการ",
    pos_settings: "ตั้งค่า POS",
    pos_code: "โค้ด POS",
    tax_included: "Tax Included",
    assign_services: "กำหนดบริการ",
    choose_the_services_that_will_be_assigned_to_the_store: "เลือกบริการที่จะกำหนดให้กับร้านค้า",
    store_lists: "รายชื่อร้านค้า",
    location_setting: "ตั้งค่าที่อยู่ร้านค้า",
    select_province: "เลือกจังหวัด",
    select_district: "เลือกอำเภอ",
    select_subdistrict: "เลือกตำบล",
    address: "ที่อยู่",
    permission_pos: "สิทธิการใช้งาน POS",
    select_store_for_add_new_account_members: "โปรดเลือกร้านค้าเพื่อเพิ่มผู้ใช้งาน",
    store: "ร้านค้า",
    total_employees: "จำนวนพนักงาน",
    search_store: "ค้นหาร้านค้า",
    back_to_main_page: "กลับไปหน้าหลัก",
    manage_permission: "จัดการสิทธิการใช้งาน",
    role: "สิทธิการใช้งาน",
    account_verification_status: "สถานะยืนยันตัวตนบัญชี",
    email: "อีเมล",
    add_member: "เพิ่มผู้ใช้งาน",
    name_employee: "ชื่อพนักงาน",
    email_employee: "อีเมลผู้ใช้งาน",
    invite: "เพิ่ม",
    edit_member: "แก้ไขผู้ใช้งาน",
    edit_permissions_granted: "แก้ไขการอนุญาติเข้าถึงสิทธิการใช้งาน",
    delete_user: "ลบผู้ใช้งาน",
    data_not_found: "Data not found",
    service_charge: "ค่าบริการ",
    add_view_permission: "เพิ่ม / ดู รายละเอียดสิทธิการใช้งาน",
    verified: "ยืนยันตัวตนแล้ว",
    waiting_verify: "รอยืนยันตัวตน",
    owner: "Owner",
    edit: "แก้ไข",
    resend_password: "รีเซ็ตรหัสผ่าน",
    // Store List Page
    // MenuHeader Modal
    feature_not_your_content: "ฟีเจอร์นี้ไม่ได้อยู่ในแพลนการใช้งานของคุณ",
    do_you_wish_use_feature:
        "คุณสนใจที่จะใช้งานฟีเจอร์นี้? กรุณาเลือกแพลนใหม่ถ้าต้องการใช้งานฟีเจอร์นี้",
    select_new_plan: "เลือกแพลนใหม่",
    // MenuHeader Modal
    // Stock Lists
    consumable_lists: "สินค้าคงคลัง",
    add_consumable: "เพิ่มสินค้าคงคลัง",
    all_consumables: "สินค้าคงคลังทั้งหมด",
    stock_running_out: "สต๊อกสินค้าคงคลังเหลือน้อย",
    search_consumables: "ค้นหาสินค้าคงคลัง",
    ingredients_name: "INGREDIENTS NAME",
    consumable_name: "ชื่อสินค้าคงคลัง",
    restock: {
        ingredient: "วัตถุดิบ",
        product: "สินค้า",
        product_category: "ประเภทสินค้า",
    },
    consume: {
        cost_restock: "ราคา(หน่วยเติมสต็อก)",
        type_unit: "ประเภทหน่วย",
        restock_diff: "หน่วยเติมสต็อกและหน่วยใช้ต่างกัน",
        restock_same: "หน่วยเติมสต็อกและหน่วยใช้เหมือนกัน",
        restock_unit: "หน่วยเติมสต๊อก",
        use_unit: "หน่วยใช้",
        re_use_unit: "Restock & use unit",
        many_to_one: "หลายชิ้นในหนึ่งหน่วย",
        one_to_many: "หน่วยของหนึ่งชิ้น",
        one_to_one: "One pieces of one unit",
        supplier: "ผู้จัดจำหน่าย",
        add_supplier: "ผู้จัดจำหน่าย",
        restock_price: "ราคาต่อหน่วยเติมสต็อก",
        search_supplier: "ค้นหาผู้จัดจำหน่าย",
        conversion: "การแปลง",
        base_conversion: "หน่วย Delivery -> หน่วยเติมสต๊อก",
        delivery_unit: "Delivery unit",
        add_convers: "เพิ่มการแปลง",
        usage_conversion: "หน่วยเต็มสต๊อก -> หน่วยใช้",
        BOM: "BOM",
        add_ingredient: "เพิ่มวัตถุดิบ",
        search_ingredient: "ค้นหาวัตถุดิบ",
        qty_setting: "การตั้งค่าปริมาณ (หน่วยเติมสต็อก)",
        qty_min: "ขั้นต่ำ",
        qty_max: "สูงสุด",
        qty_par: "Par",
        qty_std: "จำนวนใช้มาตรฐานต่อเดือน",
        convers_title: "การแปลง",
        no_ingredient: "ไม่มีวัตถุดิบ",
        no_supplier: "ไม่มีผู้จัดจำหน่าย",
        cate: "หมวดหมู่",
    },
    add_delete_quantity: "เพิ่ม/ลด จำนวน",
    unit: "หน่วย",
    consumable_setting: "ตั้งค่าสินค้าคงคลัง",
    ingredient_name: "Ingredient Name",
    select_unit: "Select Unit",
    assign_branch: "ระบุจำนวนรายสาขา",
    minimum: "กำหนดขึ้นต่ำ",
    add_edit_consumable: "Add / Edit consumable",
    history: "ประวัติ",
    edit_quantity: "แก้ไขจำนวน",
    quantity_piece: "จำนวน",
    view: "รายละเอียด",
    minimum_quantity: "กำหนดขึ้นต่ำ",
    add_quantity: "เพิ่มจำนวน",
    deduct_quantity: "ลดจำนวน",
    current_quantity: "จำนวนปัจจุบัน",
    quantity_to_add: "จำนวนที่ต้องการเพิ่ม",
    piece: "Piece",
    enter_quantity_to_deduct: "จำนวนที่ต้องการลด",
    reason_for_quantity_deduction: "เหตุผลที่ต้องการลด",
    history_edit_quantity: "ประวัติการแก้ไขจำนวน",
    date: "วันที่",
    activity: "กิจกรรม",
    edited_quantity: "จำนวนที่แก้ไข",
    new_quantity: "จำนวนใหม่",
    note: "บันทึก",
    deduct: "ลดจำนวน",
    add: "เพิ่มจำนวน",
    box: "กล่อง",
    bottle: "ขวด",
    grams: "กรัม",
    ounces: "ออนซ์",
    // Stock Lists
    // Assign Stock
    assign_consumable: "จัดสูตรรายการสินค้าคงคลัง",
    assign_stock_to_product: "สร้างรายการสินค้าใหม่",
    consumable_assigned: "สินค้าคงคลัง",
    select_product: "เลือกสินค้า",
    select_product_to_assign_consumables: "เลือกสินค้าเพื่อใส่ส่วนประกอบสินค้าคงคลัง",
    products: "สินค้า",
    categories: "หมวด",
    select: "เลือก",
    consumable_list: "รายการ",
    quantity_per_branch: "Quantity per branch",
    quantity_per_order: "จำนวนใช้",
    consumable: "ส่วนประกอบคงคลัง",
    select_consumables: "เลือกส่วนประกอบสินค้าคงคลัง",
    qty_added_deducted: "แก้ไขจำนวน",
    // Assign Stock
    // Store List
    permission_settings: "ตั้งค่าพนักงาน",
    payment_settings: "ตั้งค่าชำระเงิน",
    edit_store_details: "รายละเอียดร้าน",
    manage_permissions: "สิทธิ์พนักงาน",
    create_staff: "เพิ่มพนักงาน",
    edit_store: "ตั้งค่าร้านค้า",
    store_setting: "ตั้งค่ารายละเอียดร้านค้า",
    create_employee: "Create employee",
    employee_role: "บทบาท",
    select_role: "เลือกบทบาท",
    employee_code: "รหัสพนักงาน",
    done: "Done",
    reset: "รีเซ็ต",
    image_qr_code: "รูปคิวอาร์โค้ดพร้อมเพย์",
    dynamic_qr_payment_prompt_pay: "Payment gateway",
    dynamic_qr_payment_kbank: "KBank",
    merchant_id_pay_solution: "Merchant ID (Paysolution)",
    merchant_name_pay_solution: "Merchant Name (Paysolution)",
    token_pay_solution: "Token  (Pay Solution)",
    api_key_pay: "Api key",
    // Store List
    current_qty: "จำนวนในระบบ",
    new_balance: "จำนวนเช็คสต๊อก",
    empty_text: "-",
    price_unit: "ราคาต่อหน่วย",
    old_price: "ราคาเก่า",
    new_price: "ราคาใหม่",
    addon_name: "กลุ่มตัวเลือก",
    amount_choices: "จำนวนตัวเลือก",
    status_request: "จำเป็น",
    request_select: "จำเป็นต้องเลือก",
    confirm: "Confirm",
    create_add_on: "สร้างกลุ่มตัวเลือก",
    add_on_settings: "ตั้งค่ากลุ่มตัวเลือก",
    name_title_add_on: "ชื่อกลุ่มตัวเลือก",
    number_of_selectable_options: "ประเภทการเลือก*",
    add_on_options: "ตัวเลือก",
    option: "ตัวเลือก",
    option_name: "Option name",
    add_option: "เพิ่มตัวเลือก",
    single_option: "เลือกได้อย่างเดียว",
    multiple: "เลือกได้หลายอย่าง",
    create_option_addon: "สร้างกลุ่มตัวเลือก",
    variants_title: "หัวข้อตัวเลือกสินค้า",
    total_variants: "จำนวนตัวเลือกสินค้า",
    variant_name: "ชื่อตัวเลือกสินค้า",
    add_options: "เพิ่มตัวเลือก",
    _2_100_characters: "200 ตัวอักษร",
    promotion_setting: "การตั้งค่าโปรโมชัน",
    type_customer: "ประเภทลูกค้า",
    all_customer: "ลูกค้าทั้งหมด",
    member_only: "ลูกค้าสมาชิกเท่านั้น",
    product: "สินค้า",
    type_promotion: "ประเภทโปรโมชัน",
    free: "ฟรี",
    discount_by_baht: "ส่วนลดเป็นบาท",
    discount_by_percent: "ส่วนลดเป็นเปอร์เซ็นต์",
    condition_promotion: "เงื่อนไขโปรโมชัน",
    select_confition_promotion: "เลือกเงื่อนไขโปรโมชัน",
    order_amount: "ยอดสั่งซื้อ",
    order_amount_desc: "ลูกค้าที่สั่งซื้อตามเงื่อนไขนี้จะได้รับผลโปรโมชัน",
    promotion_result_free_promotion: "ผลโปรโมชัน (โปรโมชันฟรี)",

    required: "จำเป็นต้องเลือก",
    additional_fee: "เพิ่มราคา",
    language: {
        en: "English",
        th: "Thai",
        lang_en: "EN",
        lang_th: "TH",
    },
    select_all: "ทั้งหมด",
    select_product_promotion: "Select Product Promotion",
    selected: "Selected",
    promotion: "โปรโมชัน",
    bill_promotion: "Bill Promotion",
    add_promotion: "เพิ่มโปรโมชัน",
    promotion_name: "ชื่อโปรโมชัน",
    updated_date: "วันที่อัพเดท",
    promotion_type: "ประเภทของโปรโมชัน",
    active: "เปิดใช้งาน",
    day_hour_limit: "จำกัดวัน/ชั่วโมง",
    specific_hours: "ชั่วโมงที่เฉพาะเจาะจง",
    all_hours: "ทุกชั่วโมง",
    amount: "ยอดขาย",
    waste: "ของเสีย",
    chech_stock_amount: "จำนวนเช็คสต็อก",
    different_amount: "ความแตกต่าง",
    current_amount: "จำนวนปัจจุบัน",
    select_category: "เลือกหมวดหมู่สินค้า",
    free_product: "สินค้าฟรี",
    promotion_result_thb_discount_amount: "ผลลัพธ์โปรโมชัน(ส่วนลดเป็นบาท)",
    discount: "ส่วนลด",
    promotion_result_percentage_discount: "ผลลัพธ์โปรโมชัน(ส่วนลดเป็นเปอร์เซ็นต์)",
    promotion_result_set_new_pricing: "ผลลัพธ์โปรโมชัน(กำหนดราคาใหม่สำหรับสินค้านี้)",
    sale_amount_in_baht: "จำนวน(บาท)",
    has_empty_variant_title: "have empty variants title",
    has_empty_variant_name: "have empty variants name",
    has_produce_quantity: "สินค้านี้มีจำนวนหรือไม่?",
    hint_code: "Hint Code",
    points: "คะแนน",
    wholesale: {
        list_title: "ช่องทางการสั่งซื้อ",
        add_channel: "เพิ่มช่องทางการสั่งซื้อ",
        table_channel: "ช่องทางการสั่งซื้อ",
        table_menu: "เมนู",
        table_status: "สถานะ",
        channel_settings: "ตั้งค่าช่องทางการสั่งซื้อ",
        enter_price_product: "กำหนดราคาสินค้า",
        enter_price_addon: "กำหนดราคา Add-On",
        channel: "ช่องทางการสั่งซื้อ",
        select_menu: "Select Menu",
        menu: "Menu",
        type_service: "Type Service",
        wholesale: "ขายส่ง",
        delivery: "จัดส่ง",
        channel_img: "รูปภาพช่องทางการสั่งซื้อ",
        table_store_price: "ราคาหน้าร้าน",
        table_selling: "ราคาขาย",
        table_dif: "ส่วนต่าง",
        table_delete: "ลบ",
        table_addon: "Add-On",
    },
    start: "เริ่ม",
    publish: "เผยแพร่",
    check_store_id: "ตรวจสอบรหัสร้านค้า",
    search_stores: "ค้นหาร้านค้า",
    format: "รูปแบบ",
    date_time: "วันที่สร้าง",
    check_stock_round_no: "เช็คสต๊อกรอบที่",
    staff: "พนักงาน",
    affected_items: "รายการที่ได้รับผลกระทบ",
    download_pdf: "ดาวน์โหลด PDF",
    par_level: "Par level",
    current_base_qty: "จำนวนปัจจุบัน",
    difference: "ความแตกต่าง",
    waste_amount: "จำนวนของเสีย",
    new_restock_qty: "จำนวนสต็อกใหม่",
    promo_code: "รหัสโปรโมชัน",
    enable: "เปิดใช้งาน",
    promo_code_type: "ประเภทรหัสโปรโมชัน",
    fixed_code: "รหัสคงที่",
    unique_code: "รหัสเฉพาะ",
    generate_amount: "สร้างจำนวนเงิน",
    validity_period: "ระยะเวลาการใช้งาน",
    from: "จาก",
    till: "ถึง",
    add_promo_codes: "เพิ่มรหัสโปรโมชัน",
    export_excel: "ดาวน์โหลดรหัสโปรโมชัน",
    download_template: "ดาวน์โหลด template",
    generate: "สร้าง",
    variation: "ตัวเลือกสินค้าที่",
    nothing_to_show: "ไม่มีอะไรจะแสดง!",
    item: "รายการ",
    by_product: "สินค้า",
    by_cate: "หมวดหมู่สินค้า",
    promo_code_set: "รหัสโปรโมชันชุดที่",
    custom_date: "กำหนดวันที่",
    approved: "ได้รับการอนุมัติ",
    format_option: {
        day: "วัน",
    },
    status_option: {
        pending: "ดำเนินการ",
        finished: "สำเร็จ",
        canceled: "ยกเลิก",
    },
    print_paper: {
        title_po: "ใบสั่งซื้อ",
        title_gr: "ใบรับสินค้า",
        buyer: "ผู้ซื้อ/จัดส่งไปที่",
        detail: "รายละเอียด",
        issued_date: "วันที่ออก : ",
        credit_terms: "เงื่อนไขสินเชื่อ : ",
        tax_id: "หมายเลขประจำตัวผู้เสียภาษี : ",
        contact: "ติดต่อ : ",
        tel: "โทร : ",
        table_no: "เลขที่",
        table_code: "รหัส",
        table_ing: "วัตถุดิบ",
        table_qty: "จำนวน",
        table_unit: "หน่วย",
        table_vat: "ภาษีมูลค่าเพิ่ม",
        table_price: "ราคา",
        table_total: "รวม",
        remark: "หมายเหตุ : ",
        total: "รวม",
        vat: "ภาษีมูลค่าเพิ่ม",
        grand_total: "รวมทั้งสิ้น",
        autorized: "ได้รับอนุญาตจาก",
        cancelled: "ยกเลิกโดย",
        issued: "ออกโดย",
    },
    cashier: "แคชเชียร์",
    manager: "ผู้จัดการ",
    promotion_categories: "ประเภทโปรโมชั่น",
    promotions: "จำนวนโปรโมชั่น",
    promotion_category_name: "ชื่อประเภทโปรโมชั่น",
    channel: "Channel",
    tier: "Tier",
    inv_cate: {
        list_title: "ประเภทสินค้าคงคลัง",
        table_name: "ประเภทสินค้าคงคลัง",
        table_ingredients: "จำนวนสินค้าคงคลัง",
        table_total: "Total products",
        add: "เพิ่มประเภทสินค้าคงคลัง",
        name: "ชื่อประเภทสินค้าคงคลัง",
        code: "รหัส",
    },
    brand: {
        title: "ยี่ห้อ",
        table_name: "ยี่ห้อ",
        table_total: "จำนวนสินค้า",
        add_brand: "เพิ่มยี่ห้อ",
        brand_name: "ชื่อยี่ห้อ",
    },
    unit_setting: {
        title: "Unit Setting",
        add_unit: "เพิ่มหน่วย",
        table_unit: "Name",
        unit_name: "ชื่อหน่วย",
    },
    report_setting: {
        title: "Report delivery & notifications",
        noti_setting: "Notification settings",
        pos_report_setting: "POS report settings",
        select_recipients: "Select recipients",
    },
    receive_amount: "Receive Amount",
    setting_order_request_transfer: "เลือกสินค้าคงคลัง",
    select_store_to_request_transfer: "เลือกสาขาต้นทาง",
    select_branch_request_transfer: "Select Branch Request Transfer",
    inventory: "สินค้าคงคลัง",
    request_from_branch: "Request from branch",
    branch_request_transfer: "Branch Request Transfer",
    confirm_request_transfer: "Confirm Request Transfer",
    choose_branch_to_request: "Choose branch to request",
    title: "title",
    diff_from_par: "Diff from par",
    diff_from_max: "Diff from max",
    inventory_code: "รหัสสินค้าคงคลัง",
    channel_code: "Channel code",
    bp_code: "BP code",
    category_unit: "หน่วยรับ",
    type: "ประเภท",
    finished_goods: "Finished goods",
    ingredient: "Ingredient",
    purchase_request_tab: "Purchase Request",
    transfer_request_tab: "Transfer Request",
    branch_tax_id: "Branch tax id",
    province: "จังหวัด",
    district: "เขต/อำเภอ",
    subDistrict: "แขวง/ตำบล",
    item_group: "หมวดหมู่ประเภทสินค้าคงคลัง",
    display: "การแสดงรายการ",
    show_cost_on_po: "แสดงต้นทุนบน PO",
    auto_convert_pr_to_po: "Auto convert PR to PO",
    check_stock_reason: "เหตุผลการเช็คสต็อก",
    waste_record_reason: "เหตุผลการบันทึกของเสีย",
    set_reason: "ตั้งค่าเหตุผล",
    waste_reason: "Waste reason",
    close: "Close",
    due_date: "Due date",
    option_limit: "จำกัดตัวเลือก",
    option_qty_limit: "จำกัดปริมาณตัวเลือก",
    pos_tax_id: "POS tax id",
    store_tax_id: "Store tax id",
    setting_category_check_stock_list: "รายการเช็คสต็อก",
    amount_product: "Amount Product",
    daily: "รายวัน",
    weekly: "รายสัปดาห์",
    monthly: "รายเดือน",
    create_category_check_stock: "Create Category Check Stock",
    uncategorized: "Uncategorized",
    raw_mat: "Raw mat",
    please_create_inventory_first_to_set_quantity: "Please create inventory first to set quantity",
    adhoc: "เร่งด่วน",
    inventory_daily: "สินค้าคงคลังรายวัน",
    inventory_weekly: "สินค้าคงคลังรายสัปดาห์",
    inventory_monthly: "สินค้าคงคลังรายเดือน",
    product_daily: "สินค้ารายวัน",
    product_weekly: "สินค้ารายสัปดาห์",
    product_monthly: "สินค้ารายเดือน",
    inventory_name: "Inventory Name",
    po_amount: "PO Amount",
    received_amount: "Received Amount",
    expiry: "Expiry",
    no_expiry: "No expiry",
    lot_number: "Lot Number",
    pending: "Pending",
    remaining: "Remaining",
    received: "Received",
    check_stock: "Check Stock",
    waste_record: "Waste Record",
    add_promotion_group: "Add promotion group",
    promotion_category_group_name: "ชื่อคอลเลกชั่นสินค้า",
    promotion_groups: "Promotion Groups",
    promotion_group: "คอลเลกชั่นสินค้า",
    enable_pr_po: "อนุญาตให้เปิด PR/PO",
    max_option_total_qty: "Max option total qty",
    void_bill_witness_approve: "มีพยานในการยกเลิกใบเสร็จ",
    show_delivery_unit_on_check_stock: "แสดงหน่วยการส่งสินค้าบนรายการเช็คสต็อก",
    show_expiry_date_on_gr: "แสดงวันหมดอายุบน GR",
    overlap_condition: "Overlap condition",
    cannot_overlap: "ทับซ้อนไม่ได้",
    can_overlap: "ทับซ้อนได้",
    by_variant: "ตัวเลือกสินค้า",
    select_variant: "Select variant",
    select_promotion_group: "Select promotion group",
    by_variant_add_on: "ตัวเลือกสินค้าและตัวเลือกเสริม",
    add_addon: "Add-On",
    select_add_on: "Select Add-On",
    promotion_set_image: "รูปโปรโมชั่น",
    source: "Source",
    internal: "Internal",
    void_bill_staff_can_be_different_from_bill_owner:
        "พนักงานยกเลิกใบเสร็จสามารถเป็นคนละคนกับพนักงานเปิดใบเสร็จ",
    enable_order_queue: "เปิดคิว",
    exclusive_promotion: "โปรโมชั่นเดี่ยว",
    scan_only: "สแกนเท่านั้น",
    discount_percent: "ส่วนลด (%)",
    max_discount_value: "มูลค่าสูงสุดที่สามารถลดได้",
    promotion_period: "Promotion period",
    search_promotion: "Search promotion",
    roles_permissions_settings: "Roles & permissions settings",
    roles_permission_desc: "โปรดระบุรายละเอียดให้ครบถ้วน",
    role_name: "ชื่อตำแหน่ง",
    role_details: "รายละเอียดตำแหน่ง",
    features_permissions: "กำหนดสิทธิ์การเข้าถึงฟีเจอร์",
    product_management: "Product management",
    create_manage_product: "Create / manage product",
    assign_inventory: "Assign inventory",
    add_on: "Add-on",
    product_promotions: "Product promotions",
    bill_promotions: "Bill promotions",
    store_access: "Store access",
    all_stores: "All stores",
    specific_stores: "Specific stores",
    inventory_management: "Inventory management",
    inventory_list: "Inventory list",
    supplier_list: "Supplier list",
    edit_inventory_qty: "Edit inventory qty",
    inventory_request: "Inventory request",
    goods_received: "Goods received",
    purchaseOrder: "Purchase order",
    store_channel_management: "Store & channel management",
    permissions: "Permissions",
    payments: "Payments",
    store_details: "Store details",
    object_manager: "Object manager",
    inventory_category: "Inventory category",
    inventory_units: "Inventory units",
    promotion_category: "Promotion category",
    check_stock_list: "Check stock list",
    pos_organization_settings: "POS organization settings",
    brand_roles: "Brand",
    roles_permissions: "Roles & permissions",
    pos_reports: "POS reports",
    sales_summary: "Sales summary",
    sales_by_bill: "Sales by bill",
    sales_mix: "Sales mix",
    sale_by_product_summary: "Sale by product (summary)",
    sale_by_product_detail: "Sale by product (detail)",
    sale_by_category: "Sale by category",
    sale_by_channel: "Sale by channel",
    sales_by_payment_method: "Sales by payment method",
    sale_by_staff: "Sale by staff",
    sale_by_branch: "Sale by branch",
    add_on_report: "Add-on report",
    promotion_summary: "Promotion (summary)",
    promotion_by_record: "Promotion (by record)",
    inventory_movement: "Inventory movement",
    current_stock: "Current stock",
    movement_summary: "Movement (summary)",
    movement_by_record: "Movement (by record)",
    inventory_waste_record: "Inventory waste record",
    inventory_check_record: "Inventory check record",
    pr_po_gr: "PR/PO/GR",
    pr_report: "PR Report",
    pr_forecast_report: "PR Forecast Report",
    po_report: "PO Report",
    gr_report: "GR Report",
    product_form: {
        price_by_weight: "Price by weight?",
        price_desc: "This product is priced by weight",
        stock_control: "Stock Control",
        is_qty: "Does this product have quantity?",
        have_qty: "Have quantity",
        no_qty: "Not have quantity",
        base_inv: "Base inventory restock & use unit",
        restock_unit: "Restock Unit",
        use_unit: "Use unit",
        price_variants: "Price and Variants",
        selling_type: "Selling inventory type",
        separate: "Separate base inventory",
        shared: "Shared base inventory",
        table_base: "Base inventory qty",
    },
    export_table_page: {
        title: "Export Table",
        history: "History",
        data_to_export: "Data to be exported",
        select_data: "Select Data",
        export: "Export",
    },
    import_table: "Import Table",
    create_inventory: "Create Inventory",
    assign_inventory_to_product: "Assign inventory to product",
    bom_inventory: "BOM inventory",
    assign_addon_to_product: "Assign add-on to product",
    import_to: "Import to",
    template_table_file_csv: "Template table file .CSV",
    import_to_create_product_here: "Import to create product here",
    import_file_csv_or_excel: "Import File .CSV or Excel",
    import_file_excel: "Import File Excel",
    please_add_promo_code_set_name: "Please add promo code set name",
    please_select_file_or_drag_and_drop_file_for_upload:
        "Please select file or drag and drop file for upload.",
    import_your_table_now: "Import your table now",
    date_import: "Date Import",
    table_name: "Table Name",
    user_account: "User Account",
    file_name: "File Name",
    fail_reason: "Fail Reason",
    number_of_rows: "Number Of Rows",
    wifi_password: "Wifi password",
    bottom_title: "Bottom title",
    product_variant_selling_inventory: "Product/Variant/Selling inventory",
    non_selling_inventory: "Non-selling inventory",
    assign_non_selling_inventory_to_variant: "Assign non-selling inventory to variant",
    assing_bom_inventory_to_inventory: "Assign bom-inventory to inventory",
    assign_addon_option_to_product: "Assign addon/option to product",
    verification: "Verification",
    import: "Import",
    success: "Success",
    view_status: "View Status",
    select_import_option: "Select import option",
    view_history: "View History",
    quick_edit: "Quick Edit",
    import_pos_data: "Import POS data",
    product_collection: "Product Collection",
    type_free: "Type Free",
    choice_within_list: "Choice within list",
    all_product: "All product",
    quantity_free: "Quantity Free",
    select_product_collection: "Select product collection",
    loyalty_channel: "Loyalty channel",
    sale_channel: "Sale channel",
    all_sale_channel: "ทุกช่องทางการขาย",
    product_collections: "Product Colletions",
    add_product_collection: "Add product collection",
    dine_in: "Dine in",
    select_channel: "Select Channel",
    terminal_tax_id_15_digit: "Terminal tax id (15 digit)",
    all_branch: "All Branch",
    ranking: {
        title: "Product Ranking",
        ranking: "Ranking",
    },
    time_15_min: "15 นาที",
    time_30_min: "30 นาที",
    time_1_hour: "1 ชั่วโมง",
    set_step_time_slot: "Set step time slot",
    remark: "Remark",
    detail: "Detail",
    in_restock_stock: "In Stock (Restock QTY)",
    select_req_trans: "Select Request Transfer",
    qr_code: "QR Code",
    download: "Download",
    remove: "ลบออก",
};
