import { useState, useEffect } from "react";
import {
    ComponentContactSortIcon,
    StyledTable,
    ComponentEmptyData,
    StyledTableButtonTop,
    ComponentTableSearch,
    ComponentRowService,
    ComponentQuickEditModal,
    ComponentRowProductRanking,
    StyledCancelButton,
    StyledSubmitButton,
    IconAddCircle,
} from "@components";
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    TouchSensor,
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    rectSortingStrategy,
    //@ts-ignore
} from "@dnd-kit/sortable";
import { Row, Col, Pagination, Button } from "antd";
import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";
import moment, { Moment } from "moment";
import {
    enumSortBy,
    enumContactSortFields,
    PAGINATION,
    PAGE_START,
    enumNewsStatus,
    INITIAL_LIST_PARAMS,
    PATH_PRODUCT_CREATE,
    dateFormatYear,
} from "@configs";
import { IPaginationNext, IDataexample, IProduct, IProductRank, ICreatePosPromotion } from "@interfaces";
import { useDispatch, useSelector } from "react-redux";
import { getCategories, selectAuth, selectContact, selectOrg, setLoading } from "@redux";
import { useNotify } from "@utils";
import { useHistory } from "react-router";
import { productAPI } from "@api";
import styled from "styled-components";
import { ComponentAddProductRankModal } from "src/Components/shared/promotion";

const INITAL_PAGE = {
    xpage: PAGE_START,
    xlimit: PAGINATION,
};

const initData: ICreatePosPromotion = {
    organization_id: "",
    pos_promotion_name: "",
    client_type: "",
    promotion_type: "freebie",
    condition_type: "qty",
    promotion_hour_type: "all_hour",
    promotion_hours: {
        monday: {
            is_active: true,
            open_hour: "00:00",
            close_hour: "23:59",
        },
        tuesday: {
            is_active: true,
            open_hour: "00:00",
            close_hour: "23:59",
        },
        wednesday: {
            is_active: true,
            open_hour: "00:00",
            close_hour: "23:59",
        },
        thursday: {
            is_active: true,
            open_hour: "00:00",
            close_hour: "23:59",
        },
        friday: {
            is_active: true,
            open_hour: "00:00",
            close_hour: "23:59",
        },
        saturday: {
            is_active: true,
            open_hour: "00:00",
            close_hour: "23:59",
        },
        sunday: {
            is_active: true,
            open_hour: "00:00",
            close_hour: "23:59",
        },
    },
    item_type: "product",
    product_list: [],
    product_category_list: [],
    variant_free_list: [],
    has_free_variant: false,
    discount: 0,
    new_price: 0,
    remark: "",
    is_discount_step: false,
    discount_step_list: [],
    has_promo_code: false,
    promo_code_type: "",
    unique_promo_code_list: [],
    pos_promotion_category_id: "",
    sale_channel_id: "",
    is_all_sale_channel: false,
    tier_id: "",
    validity_store_list: [],
    sale_channel_id_list: [],
    is_validity_period: false,
    validity_date_from_string: moment().format(dateFormatYear).toString(),
    validity_date_to_string: moment().format(dateFormatYear).toString(),
    validity_time_from_string: "00:00",
    validity_time_to_string: "23:59",
    variant_list: [],
    promotion_group_list: [],
    variant_option: [],
    pos_promotion_image_url: "",
    is_not_deduct_basket: false,
};

export const PageRankingList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const search_params = useSelector(selectContact).paramsContact;
    const [params, setParams] = useState(search_params);
    const org_id = useSelector(selectAuth).userInfo?.merchant._id;
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const [textSearch, setTextSearch] = useState<string>("");
    const [searchDate, setSearchDate] = useState<string[]>([]);
    const [catSearch, setCatSearch] = useState<string>("");
    const [showModal, setShowModal] = useState<boolean>(false);
    const [tempCatID, setTempCatID] = useState<string>("");
    const [pagination, setPagination] = useState<IPaginationNext>(INITAL_PAGE);
    const [services, setServices] = useState<IDataexample[]>([]);
    const [productRank, setProductRank] = useState<IProductRank[]>([]);
    const { success, error } = useNotify();
    const [Refresh, setRefresh] = useState<any>(0);
    const [showPopup, setShowPopup] = useState<boolean>(false);
    useEffect(() => {
        fetchNews();
    }, [Refresh, textSearch, tempCatID, pagination]);

    const handleCloseModal = () => {
        setShowPopup(!showPopup);
    };
    const fetchNews = async () => {
        dispatch(setLoading(true));
        try {
            const res = await productAPI.getSortNoProducts({
                organization_id: org_id || "",
            });
            setProductRank(res.data.result);
        } catch (err: any) {
            error(err.msg);
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };



    const callbackGetList = () => {
        fetchNews();
    };
    const handleDragEnd = (event) => {
        const { active, over } = event;
        let active_id = active.data.current.sortable.index;
        let over_id = over.data.current.sortable.index;
        if (active_id !== over_id) {
            // const swapItem = () => {
            //     return arrayMove(productRank, active_id, over_id);
            // };
            // setProductRank(swapItem);

            const swapItem = (items: any[]) => {
                // const oldIndex = items.findIndex((item) => JSON.stringify(item) === active_id);
                // const newIndex = items.findIndex((item) => JSON.stringify(item) === over_id);
                return arrayMove(items, active_id, over_id);
            };
            setProductRank(swapItem);
        }
    };
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
        useSensor(TouchSensor)
    );

    const deleteProduct = (delete_index: number) => {
        let updateRank = [...productRank.slice(0, delete_index), ...productRank.slice(delete_index + 1)];
        setProductRank([...updateRank]);
    };

    const handleSave = async () => {
        let arr: string[] = [];
        productRank.forEach((pItem) => {
            arr.push(pItem?._id);
        });
        let res = await productAPI.setSortNoProduct({
            organization_id: org_id || "",
            product_id_sort_list: arr,
        });
        if (res.data.error === "0") {
            success(res.data.msg);
        } else {
            error(res.data.msg);
        }
    };

    const addProduct = (name: any, value: any) => {
        let check = false;
        productRank.forEach((pItem) => {
            if (pItem._id === value?._id) {
                check = true;
            }
        });
        if (check) {
            error("already select product");
        } else {

            productRank.push(value);
        }
    };
    const handleClose = () => {
        history.back();
    };

    return (
        <StyledTable>
            <div className="page-layout">
                <div className="page-header-layout">
                    <div className="page-header">
                        <div>
                            <h3>{t("page.ranking.title")}</h3>
                        </div>
                        {/* <div className="flex-header">
                            <StyledTableButtonTop
                                type="danger"
                                size="default"
                                text={t("page.add_product")}
                                onClick={() =>
                                    history.push({
                                        pathname: PATH_PRODUCT_CREATE,
                                    })
                                }
                                className="add-btn"
                                icon="plus"
                            />
                        </div> */}
                    </div>
                    {/* <div className="search-layout">
                        <ComponentTableSearch
                            setTextSearch={setTextSearch}
                            handleSearchCategory={handleSearchCategory}
                            categories={categories}
                            onChangeDateRange={onChangeDateRange}
                            total={total}
                            isRangePicker={true}
                        />
                    </div> */}
                </div>
                <div className="page-table-layout">
                    <div className="table">
                        <ScrollContainer vertical={false}>
                            <div className="table_ground">
                                <div className="table-body">

                                    <div className="table-header">

                                        <Row
                                            gutter={24}
                                            type="flex"
                                            justify="space-between"
                                            align="middle"
                                            className="header-row"
                                        >
                                            {/* <div style={{ minWidth: "150px" }} /> */}
                                            <Col className="col-item" span={1}>
                                                <p>{t("page.ranking.ranking")}</p>
                                            </Col>
                                            <Col className="col-item" span={8}>
                                                <p>{t("page.product_name")}</p>
                                            </Col>
                                            <Col className="col-item" span={8}>
                                                <p>{t("page.product_code")}</p>
                                            </Col>
                                        </Row>
                                    </div>
                                    <DndContext
                                        sensors={sensors}
                                        collisionDetection={closestCenter}
                                        onDragEnd={handleDragEnd}
                                    >
                                        <SortableContext
                                            items={productRank.map((item) => JSON.stringify(item))}
                                            strategy={rectSortingStrategy}
                                        >
                                            {productRank && productRank.length > 0 ? (
                                                <div className="data-table">
                                                    {productRank.map((item, index) => (
                                                        <ComponentRowProductRanking
                                                            key={index}
                                                            data={item}
                                                            itemJson={JSON.stringify(item)}
                                                            index={index}
                                                            deleteProduct={deleteProduct}
                                                        />
                                                    ))}
                                                    <div
                                                        style={{ width: "100%", height: 10 }}
                                                    ></div>
                                                </div>
                                            ) : (
                                                <ComponentEmptyData />
                                            )}
                                        </SortableContext>
                                    </DndContext>
                                </div>
                                <StyleAddProduct onClick={() => setShowPopup(true)}>
                                    <IconAddCircle color="#0263E0" />   Add Product
                                </StyleAddProduct>
                                <StyleBtnGroup>
                                    <StyledCancelButton
                                        type="sub"
                                        text={t("page.cancel")}
                                        htmlType="button"
                                        onClick={handleClose}
                                    />
                                    <StyledSubmitButton
                                        type="default"
                                        text={t("page.save")}
                                        htmlType="button"
                                        onClick={handleSave}
                                    />
                                </StyleBtnGroup>
                            </div>
                        </ScrollContainer>
                    </div>
                </div>
            </div>
            <ComponentAddProductRankModal
                showPopup={showPopup}
                handleClose={handleCloseModal}
                setTableData={addProduct}
                type={"product"}
                values={initData}
            />
        </StyledTable>
    );
};

const StyleBtnGroup = styled.div`
    display: flex;
    justify-content: end;
    gap: 10px;
    button {
        height: 50px;
        /* width: 100%; */
        margin: 0;
    }
`;

const StyleAddProduct = styled.div`
    border: 1px dashed #0263E0;
    color: #0263E0;
    padding: 25px;
    margin-bottom: 20px;
    border-radius: 8px;
    background-color: #0263E00A;
    text-align: center;
    cursor: pointer;
`;