import { useState, useEffect } from "react";
import {
    ComponentContactSortIcon,
    StyledTable,
    ComponentEmptyData,
    StyledTableButtonTop,
    ComponentRowStore,
    ComponentTableStoreFilter,
} from "@components";
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    TouchSensor,
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    rectSortingStrategy,
} from "@dnd-kit/sortable";
import { Row, Col, Pagination, Button } from "antd";
import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";
import {
    enumSortBy,
    enumContactSortFields,
    PAGINATION,
    PAGE_START,
    enumNewsStatus,
    INITIAL_LIST_PARAMS,
    PATH_STORE_CREATE,
} from "@configs";
import { IPaginationNext, IDataexample, IGetAdminDataByTokenAdminDataExample } from "@interfaces";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth, selectContact, selectOrg, setLoading } from "@redux";
import { useNotify } from "@utils";
import { useHistory } from "react-router";
import styled from "styled-components";
import { adminAPI, storeAPI } from "@api";

const INITAL_PAGE = {
    xpage: PAGE_START,
    xlimit: PAGINATION,
};

interface IFocusAddress {
    province_id: string;
    amphur_id: string;
    subDistrict_id: string;
    subDistrict_index: string;
}

export const PageStoreList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const search_params = useSelector(selectContact).paramsContact;
    const [params, setParams] = useState(search_params);
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const userPermissionStoreList: any = useSelector(selectAuth).userInfo?.storeIds;
    const categories = useSelector(selectOrg).category;
    const [pagination, setPagination] = useState<IPaginationNext>(INITAL_PAGE);
    const [services, setServices] = useState<IDataexample[]>([]);
    const [stores, setStores] = useState<any>([]);
    const [storeStatus, setStoreStatus] = useState<boolean>(false);
    const [total, setTotal] = useState<number>(0);
    const { success, error } = useNotify();
    const history = useHistory();
    const [focusAddress, setFocusAddresss] = useState<IFocusAddress>({
        province_id: "",
        amphur_id: "",
        subDistrict_id: "",
        subDistrict_index: "",
    });
    const { adminPermission } = useSelector(selectAuth);

    useEffect(() => {
        fetchNews();
    }, [storeStatus, orgCode, focusAddress, pagination]);

    const fetchNews = async () => {
        dispatch(setLoading(true));
        try {
            const getAllStores = await storeAPI.getList({
                organization_code: orgCode,
                xlimit: pagination.xlimit,
                xpage: pagination.xpage,
                province_id: focusAddress.province_id,
                amphur_id: focusAddress.amphur_id,
                district_id: focusAddress.subDistrict_id,
            });
            const stores = getAllStores.data.result;
            if (userPermissionStoreList.length !== 0) {
                let temp: any = [];
                for (let j = 0; j < userPermissionStoreList?.length; j++) {
                    for (let i = 0; i < stores.length; i++) {
                        if (userPermissionStoreList[j] === stores[i]._id) {
                            temp.push(
                                stores.find((x: any) => x._id === userPermissionStoreList[j])
                            );
                        }
                    }
                }
                setStores(temp);
            } else {
                setStores(getAllStores.data.result);
            }
            setTotal(getAllStores.data.total);
        } catch (err: any) {
            error(err.msg);
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const onChangeProvince = (value: any) => {
        setFocusAddresss({
            province_id: value,
            amphur_id: "",
            subDistrict_id: "",
            subDistrict_index: "",
        });
    };

    const onChangeAmphur = (value: any) => {
        setFocusAddresss({
            ...focusAddress,
            amphur_id: value,
            subDistrict_id: "",
            subDistrict_index: "",
        });
    };
    const onChangeSubDistrict = (value: any, id: string) => {
        setFocusAddresss({
            ...focusAddress,
            subDistrict_id: id.toString(),
            subDistrict_index: value,
        });
    };

    const handleSort = (sortField: enumContactSortFields, sortBy: enumSortBy) => {
        const newSortParams = { ...params, sortField, sortBy };
        setParams(newSortParams);
        // fetchContacts(newSortParams);
    };
    const callbackGetList = () => {
        fetchNews();
    };
    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            const swapItem = (items: IDataexample[]) => {
                const oldIndex = items.findIndex((item) => JSON.stringify(item) === active.id);
                const newIndex = items.findIndex((item) => JSON.stringify(item) === over.id);
                return arrayMove(items, oldIndex, newIndex);
            };
            setServices(swapItem);
        }
    };
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
        useSensor(TouchSensor)
    );
    const handlePagination = (page) => {
        setPagination({ ...pagination, xpage: page });
    };
    const updateService = async (data, item) => {
        dispatch(setLoading(true));
        try {
            // api call in here
            const updateStoreStatus = await storeAPI.updateStoreStatus({
                organization_code: orgCode,
                store_code: item.store_code,
                is_enable: !item.is_enable,
            });
            if (updateStoreStatus.data.error === "0") {
                setStoreStatus(!storeStatus);
            }
            // const response = await newsAPI.getList();
            // setNews();
            // setTotal(response.data.total);
            success("Status Changed");
        } catch (err: any) {
            error(err.msg);
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };
    return (
        <StyledTable>
            <div className="page-layout">
                <div className="page-header-layout">
                    <div className="page-header" style={{ padding: "1.5rem 0" }}>
                        <div>
                            <h3>{t("page.store_lists")}</h3>
                        </div>
                        <div className="flex-header">
                            <StyledTableButtonTop
                                type="danger"
                                size="default"
                                text={t("page.add_store")}
                                onClick={() => history.push(PATH_STORE_CREATE)}
                                className="add-btn"
                                icon="plus"
                                style={{ background: "#F22F46", borderRadius: "4px" }}
                            />
                        </div>
                    </div>
                    <div className="search-layout">
                        <ComponentTableStoreFilter
                            onChangeProvince={onChangeProvince}
                            onChangeAmphur={onChangeAmphur}
                            onChangeSubDistrict={onChangeSubDistrict}
                            total={total}
                            focusAddress={focusAddress}
                        />
                    </div>
                </div>
                <div className="page-table-layout">
                    <div className="table">
                        <ScrollContainer vertical={false}>
                            <Styles>
                                <div className="table_ground">
                                    <div className="table-body">
                                        <div className="table-header">
                                            <Row
                                                gutter={24}
                                                type="flex"
                                                justify="space-between"
                                                align="middle"
                                                className="header-row"
                                            >
                                                <Col className="col-item" span={8}>
                                                    <p>{t("page.store_name")}</p>
                                                    <div className="filter-button">
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.ASC}
                                                            sortField={enumContactSortFields.POINTS}
                                                            searchParams={params}
                                                        />
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.DESC}
                                                            sortField={enumContactSortFields.POINTS}
                                                            searchParams={params}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col className="col-item" span={4}>
                                                    <p>{t("page.code")}</p>
                                                    <div className="filter-button">
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.ASC}
                                                            sortField={enumContactSortFields.POINTS}
                                                            searchParams={params}
                                                        />
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.DESC}
                                                            sortField={enumContactSortFields.POINTS}
                                                            searchParams={params}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col className="col-item" span={4}>
                                                    <p>{t("page.pos_code")}</p>
                                                    <div className="filter-button">
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.ASC}
                                                            sortField={enumContactSortFields.POINTS}
                                                            searchParams={params}
                                                        />
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.DESC}
                                                            sortField={enumContactSortFields.POINTS}
                                                            searchParams={params}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col className="col-item" span={4}>
                                                    <p>{t("page.status")}</p>
                                                    <div className="filter-button">
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.ASC}
                                                            sortField={
                                                                enumContactSortFields.CREATED_AT
                                                            }
                                                            searchParams={params}
                                                        />
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.DESC}
                                                            sortField={
                                                                enumContactSortFields.CREATED_AT
                                                            }
                                                            searchParams={params}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col
                                                    className="col-item"
                                                    span={4}
                                                    style={{ placeContent: "end" }}
                                                >
                                                    <p>{t("page.manage")}</p>
                                                </Col>
                                            </Row>
                                        </div>
                                        <DndContext
                                            sensors={sensors}
                                            collisionDetection={closestCenter}
                                            onDragEnd={handleDragEnd}
                                        >
                                            <SortableContext
                                                items={stores.map((item) => JSON.stringify(item))}
                                                strategy={rectSortingStrategy}
                                            >
                                                {stores.length !== 0 ? (
                                                    <div className="data-table">
                                                        {adminPermission?.pos_role
                                                            .store_access_type === "all_store" ||
                                                            adminPermission?.pos_role
                                                                .store_access_type === undefined
                                                            ? stores.map((item, index) => (
                                                                <ComponentRowStore
                                                                    key={index}
                                                                    data={item}
                                                                    itemJson={JSON.stringify(
                                                                        item
                                                                    )}
                                                                    categories={categories}
                                                                    handleEdit={updateService}
                                                                    callbackGetList={
                                                                        callbackGetList
                                                                    }
                                                                    index={index}
                                                                    stores={stores}
                                                                />
                                                            ))
                                                            : adminPermission?.store_ids.map(
                                                                (item, index) => (
                                                                    <ComponentRowStore
                                                                        key={index}
                                                                        data={item}
                                                                        itemJson={JSON.stringify(
                                                                            item
                                                                        )}
                                                                        categories={categories}
                                                                        handleEdit={updateService}
                                                                        callbackGetList={
                                                                            callbackGetList
                                                                        }
                                                                        index={index}
                                                                        stores={stores}
                                                                    />
                                                                )
                                                            )}
                                                        <div
                                                            style={{ width: "100%", height: 10 }}
                                                        ></div>
                                                    </div>
                                                ) : (
                                                    <ComponentEmptyData />
                                                )}
                                            </SortableContext>
                                        </DndContext>
                                    </div>
                                </div>
                            </Styles>
                        </ScrollContainer>
                    </div>
                </div>

                <div className="page-bottom">
                    {total > 0 && (
                        <div className="pagination">
                            <Pagination
                                defaultCurrent={INITIAL_LIST_PARAMS.page || 1}
                                pageSize={PAGINATION}
                                total={total}
                                onChange={handlePagination}
                                current={pagination.xpage}
                            />
                        </div>
                    )}
                </div>
            </div>
        </StyledTable>
    );
};

export const Styles = styled.div`
    .table-header {
        padding: 18px 34px 12px 66px !important;
    }
`;
