export const AssignDeleteIcon = () => (
    <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.7871 3.24297C18.1761 3.24297 18.5 3.56596 18.5 3.97696V4.35696C18.5 4.75795 18.1761 5.09095 17.7871 5.09095H1.21385C0.82386 5.09095 0.5 4.75795 0.5 4.35696V3.97696C0.5 3.56596 0.82386 3.24297 1.21385 3.24297H4.12957C4.72185 3.24297 5.2373 2.82197 5.37054 2.22798L5.52323 1.54598C5.76054 0.616994 6.5415 0 7.43527 0H11.5647C12.4488 0 13.2385 0.616994 13.467 1.49699L13.6304 2.22698C13.7627 2.82197 14.2781 3.24297 14.8714 3.24297H17.7871ZM16.3058 17.134C16.6102 14.2971 17.1432 7.55712 17.1432 7.48913C17.1626 7.28313 17.0955 7.08813 16.9623 6.93113C16.8193 6.78413 16.6384 6.69713 16.4391 6.69713H2.56852C2.36818 6.69713 2.17756 6.78413 2.04529 6.93113C1.91108 7.08813 1.84494 7.28313 1.85467 7.48913C1.85646 7.50162 1.87558 7.73903 1.90755 8.13594C2.04958 9.89917 2.44517 14.8102 2.70079 17.134C2.88168 18.846 4.00498 19.922 5.63206 19.961C6.88763 19.99 8.18112 20 9.50379 20C10.7496 20 12.0149 19.99 13.3094 19.961C14.9929 19.932 16.1152 18.875 16.3058 17.134Z"
            fill="#6C7084"
        />
    </svg>
);
