import { Switch, useLocation } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { ComponentAppRoute, ComponentToast } from "@components";
import { routes } from "@configs";
import { IRoute } from "@interfaces";
import { useNotify, useWindowDimensions } from "@utils";
import { selectAuth, setAdminPermission, setMobile } from "@redux";
import { adminAPI } from "@api";

export const ModuleMain = () => {
    const { width } = useWindowDimensions();
    const { success, error } = useNotify();
    const dispatch = useDispatch();
    const { token } = useSelector(selectAuth);

    useEffect(() => {
        dispatch(setMobile(width < 992));
    }, [width]);

    useEffect(() => {
        if (token) {
            getAdminData();

        }
        // dispatch()
    }, [token]);
    const getAdminData = async () => {
        try {
            const res = await adminAPI.getAdminDataByToken();
            if (res.data.error === "0") {
                dispatch(setAdminPermission(res.data.result.admin_data));
            }
        } catch (err: any) {
            console.error(err);
        };
    };
    return (
        <BrowserRouter>
            <Switch>
                {routes.map((e: IRoute, key) => (
                    <ComponentAppRoute key={key} {...e} />
                ))}
            </Switch>
            <ComponentToast />
        </BrowserRouter>
    );
};
