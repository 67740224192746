import { useTranslation } from "react-i18next";
import {
    SharedComponentDrawer,
    SharedInput,
    SharedModal,
    StyledCancelButton,
    StyledSubmitButton,
} from "../shared";
import styled from "styled-components";
import { enumValidation, IMAGE_TYPE } from "@configs";
import { useNotify } from "@utils";
import axios from "axios";
import { useEffect, useState } from "react";
import { Avatar, Button, Input, Modal, Select, Tooltip } from "antd";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import { InfoIcon, IconCamera, GRItemIcon } from "@components";
import { useFormik } from "formik";
import { IGetConsumableStoreId, INews, INewsCategory, INewsData } from "@interfaces";
import * as Yup from "yup";
import { useYup } from "@validations";
import React from "react";
import { POAPI } from "@api";
import { useSelector } from "react-redux";
import { selectAuth } from "@redux";

interface IProps {
    showPopup: boolean;
    handleClose: () => void;
    confirmSave: (isClose: boolean) => void;
    isAll?: boolean;
}

interface IConsumePayload {
    consumable_id: string;
    name: string;
    restock_price: number;
    on_stork: number;
    add_qty: number;
    unit: string;
}

interface IConsume {
    _id: string;
    name: string;
}
const { Option } = Select;
export const ComponentGRItemModal = (props: IProps) => {
    const { YupNews } = useYup();
    const { showPopup, handleClose, confirmSave, isAll } = props;
    const { t } = useTranslation();
    const [itemName, setItemName] = useState<string>("");
    const [consumeList, setConsumeList] = useState<IConsume[]>([]);

    const { userInfo } = useSelector(selectAuth);

    // form inital data

    useEffect(() => {
        if (!showPopup) {
            setConsumeList([]);
        }
    }, [showPopup]);

    return (
        <Modal
            visible={showPopup}
            onCancel={handleClose}
            closable={false}
            // showPopup={showPopup}
            // handleClose={handleClose}
            // title={t("page.purchase_order.modal_consume_title")}
            footer={null}
            width={500}
        >
            {/* modify popup content & css here */}
            <StyledModalContent>
                <GRItemIcon />
                <p className="header">{isAll ? "ยืนยันการรับสินค้า" : "ยังรับสินค้าไม่ครบ ?"}</p>
                <p className="desc">
                    {isAll
                        ? "โปรดยืนยันการรับสินค้าเพื่อทำการปิดใบสั่ง"
                        : "รายการสินค้าที่สั่งยังไม่ครบตามจำนวนสินค้าที่สั่ง"}
                </p>

                {!isAll && <p className="desc">คุณยังต้องการคงใบสั่งซื้อไว้หรือไม่</p>}
                <br />
                <div className="btn-section">
                    {!isAll && (
                        <Button onClick={() => confirmSave(false)}>บันทึก และคงใบสั่ง</Button>
                    )}
                    <Button onClick={() => confirmSave(true)} className="close">
                        บันทึก และปิดใบสั่ง
                    </Button>
                </div>
            </StyledModalContent>
        </Modal>
    );
};

const StyledModalContent = styled.div`
    text-align: center;
    .header {
        font-weight: 700;
        font-size: 25px;
    }
    .desc {
        margin-bottom: 0;
    }
    .btn-section {
        button {
            height: 50px;
            font-weight: 700;
            font-size: 16px;
            margin: 10px;
        }
        .close {
            color: white;
            background-color: #0263e0;
        }
    }
`;
