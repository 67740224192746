import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Col, Progress, Row, Select, Tabs } from "antd";
import styled from "styled-components";
import {
    StyledCancelButton,
    StyledCard,
    ComponentPOPaper,
    ComponentGRPaper,
    TickIcon,
    SmallTickIcon,
    SharedButtonDefault,
    ComponentContactSortIcon,
    ComponentEmptyData,
    StyledTable,
    InsidePendingIcon,
} from "@components";
import { IGetPOPRExample } from "@interfaces";
import { theme } from "@utils";
import ScrollContainer from "react-indiana-drag-scroll";
import { enumContactSortFields, enumSortBy } from "@configs";
import { selectContact } from "@redux";
import { ComponentRowPurchaseOrderInside } from "src/Components/table/RowPurchaseOrderInside";
import printdiv from "src/Components/purchaseOrder/utils/PrintUtils";
interface IStoreDetail {
    store_name: string;
    address: string;
    remark: string;
    store_id: string;
    organization_id: string;
    organization_name: string;
}

interface IProps {
    data?: IGetPOPRExample;
    type: string;
    storeDetail?: IStoreDetail;
}

const { Option } = Select;
export const ModuleShowPaper = memo((props: IProps) => {
    const { t } = useTranslation();
    const { data, type, storeDetail } = props;
    const search_params = useSelector(selectContact).paramsContact;
    const [params, setParams] = useState(search_params);
    const { TabPane } = Tabs;
    const [currentTab, setCurrentTab] = useState<string>("1");

    const handleSort = () => {
        null;
    };

    return (
        <StyledTable>
            <div className="page-layout">
                <CustomStyles>
                    <div className="page-header-layout">
                        <div className="page-header">
                            {data?.pr_receive_list.length === data?.po_consumable_list.length &&
                            data?.pr_receive_list.length !== 0 ? (
                                <h3>
                                    <TickIcon /> {t("page.purchase_order.paper_title")} -{" "}
                                    {data?.po_no}
                                </h3>
                            ) : (
                                <h3>
                                    {t("page.purchase_order.paper_title")} - {data?.pr_no}
                                </h3>
                            )}
                        </div>
                        <div className="page-body">
                            <div className="card-wrap">
                                <div className="card-wrap">
                                    <StyledCard style={{ padding: 20, maxWidth: "100%" }}>
                                        <Row gutter={32} className="topStatusRow">
                                            <Col
                                                span={24}
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div
                                                    className="statusFrame"
                                                    style={
                                                        data?.pr_receive_list.length ===
                                                            data?.po_consumable_list.length &&
                                                        data?.pr_receive_list.length !== 0
                                                            ? { borderColor: "#6cd14e", width: 330 }
                                                            : { borderColor: "#FFA043", width: 330 }
                                                    }
                                                >
                                                    {data?.pr_receive_list.length ===
                                                        data?.po_consumable_list.length &&
                                                    data?.pr_receive_list.length !== 0 ? (
                                                        <>
                                                            <div className="leftSide">
                                                                <SmallTickIcon />{" "}
                                                                <span>{t("page.done")} :</span>
                                                            </div>
                                                            <Progress percent={100} size="small" />
                                                            <small>
                                                                {data?.pr_receive_list.length}/
                                                                {data?.po_consumable_list.length}
                                                            </small>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className="leftSide">
                                                                <InsidePendingIcon />{" "}
                                                                <span>{t("page.pending")} :</span>
                                                            </div>
                                                            <Progress
                                                                percent={50}
                                                                size="small"
                                                                strokeColor={"#FFA043"}
                                                            />
                                                            <small>
                                                                {data?.pr_receive_list.length}/
                                                                {data?.po_consumable_list.length}
                                                            </small>
                                                        </>
                                                    )}
                                                </div>
                                                <div>
                                                    <SharedButtonDefault
                                                        className="downloadBtn"
                                                        text="Download & Print PDF"
                                                        type="default"
                                                        onClick={() => printdiv(data)}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </StyledCard>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="page-table-layout">
                        <div className="table">
                            <ScrollContainer vertical={false}>
                                <Tabs
                                    defaultActiveKey="1"
                                    activeKey={currentTab}
                                    onChange={(key) => {
                                        setCurrentTab(key);
                                    }}
                                >
                                    <TabPane tab={t("page.remaining")} key="1"></TabPane>
                                    <TabPane tab={t("page.received")} key="2"></TabPane>
                                </Tabs>
                                <div className="table_ground">
                                    <div className="table-body">
                                        <div className="table-header">
                                            <Row
                                                gutter={24}
                                                type="flex"
                                                justify="space-between"
                                                align="middle"
                                                className="header-row"
                                            >
                                                <Col className="col-item" span={7}>
                                                    <p>{t("page.inventory_name")}</p>
                                                    <div className="filter-button">
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.ASC}
                                                            sortField={
                                                                enumContactSortFields.FULL_NAME
                                                            }
                                                            searchParams={params}
                                                        />
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.DESC}
                                                            sortField={
                                                                enumContactSortFields.FULL_NAME
                                                            }
                                                            searchParams={params}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col className="col-item" span={4}>
                                                    <p>{t("page.po_amount")}</p>
                                                    <div className="filter-button">
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.ASC}
                                                            sortField={
                                                                enumContactSortFields.CREATED_AT
                                                            }
                                                            searchParams={params}
                                                        />
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.DESC}
                                                            sortField={
                                                                enumContactSortFields.CREATED_AT
                                                            }
                                                            searchParams={params}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col className="col-item" span={3}>
                                                    <p>{t("page.received_amount")}</p>
                                                    <div className="filter-button">
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.ASC}
                                                            sortField={
                                                                enumContactSortFields.CREATED_AT
                                                            }
                                                            searchParams={params}
                                                        />
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.DESC}
                                                            sortField={
                                                                enumContactSortFields.CREATED_AT
                                                            }
                                                            searchParams={params}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col className="col-item" span={6}>
                                                    <p>{t("page.price")}</p>
                                                    <div className="filter-button">
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.ASC}
                                                            sortField={
                                                                enumContactSortFields.CREATED_AT
                                                            }
                                                            searchParams={params}
                                                        />
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.DESC}
                                                            sortField={
                                                                enumContactSortFields.CREATED_AT
                                                            }
                                                            searchParams={params}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col className="col-item" span={4}>
                                                    <p>{t("page.expiry")}</p>
                                                    <div className="filter-button">
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.ASC}
                                                            sortField={
                                                                enumContactSortFields.CREATED_AT
                                                            }
                                                            searchParams={params}
                                                        />
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.DESC}
                                                            sortField={
                                                                enumContactSortFields.CREATED_AT
                                                            }
                                                            searchParams={params}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        {currentTab === "1" && (
                                            <>
                                                {data?.pr_pending_list &&
                                                data?.pr_pending_list.length > 0 ? (
                                                    <div className="data-table">
                                                        {data?.pr_pending_list.map(
                                                            (item, index) => (
                                                                <ComponentRowPurchaseOrderInside
                                                                    key={index}
                                                                    //@ts-ignore
                                                                    data={item}
                                                                />
                                                            )
                                                        )}
                                                        <div
                                                            style={{ width: "100%", height: 10 }}
                                                        ></div>
                                                    </div>
                                                ) : (
                                                    <ComponentEmptyData />
                                                )}
                                            </>
                                        )}
                                        {currentTab === "2" && (
                                            <>
                                                {data?.pr_receive_list &&
                                                data?.pr_receive_list.length > 0 ? (
                                                    <div className="data-table">
                                                        {data?.pr_receive_list.map(
                                                            (item, index) => (
                                                                <ComponentRowPurchaseOrderInside
                                                                    key={index}
                                                                    data={item}
                                                                />
                                                            )
                                                        )}
                                                        <div
                                                            style={{ width: "100%", height: 10 }}
                                                        ></div>
                                                    </div>
                                                ) : (
                                                    <ComponentEmptyData />
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </ScrollContainer>
                        </div>
                    </div>
                    <div style={{ display: "none" }}>
                        {type === "PURCHASE ORDER" ? (
                            <ComponentPOPaper data={data} type={type} storeDetail={storeDetail} />
                        ) : (
                            <ComponentGRPaper data={data} type={type} storeDetail={storeDetail} />
                        )}
                    </div>
                </CustomStyles>
            </div>
        </StyledTable>
    );
});
const CustomStyles = styled.div`
    .table-header {
        padding: 0 34px 12px 34px !important;
    }
    .ant-tabs-bar {
        border: none;
        margin-top: 10px;
    }
    .ant-tabs-nav .ant-tabs-tab {
        padding: 8px 0;
        margin: 0 28px 0 0;
    }
    .ant-tabs-nav .ant-tabs-tab-active {
        color: #f4394f;
        font-weight: 600;
    }
    .ant-tabs-ink-bar {
        background-color: #f4394f;
        height: 3px !important;
    }
    .ant-tabs-nav .ant-tabs-tab:hover {
        color: #f4394f;
    }
    .page-table-layout {
        padding: 0 4.625rem 0 4.625rem !important;
    }
    .branchListTitle {
        font-size: 16px;
        font-weight: 700;
        color: #000;
    }
    .branchListSection {
        margin-top: 20px;
    }
    .branchListSection .antd-btn-custom {
        margin-left: 0;
    }
    .selectBranchBox {
        position: absolute;
        background: #fff;
        margin-top: 10px;
        z-index: 999;
    }
    .ant-checkbox-wrapper {
        width: 100%;
    }
    .input-box {
        margin-top: 30px;
        .input-title {
            font-weight: 700;
            font-size: 16px;
            margin-bottom: 5px;
            height: 25px;
        }
        input {
            border-color: #d9d9d9 !important;
            box-shadow: none !important;
            height: 48px;
        }
        .radio-item {
            margin-right: 30px;
        }
    }
    .page-header h3 svg {
        position: relative;
        top: -5px;
    }
    .ant-progress-text {
        display: none;
    }
    .topStatusRow {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .statusFrame {
            border-radius: 7px;
            border: 1px solid #6cd14e;
            padding: 20px 24px;
            width: 100%;
            display: flex;
            align-items: center;
            gap: 5px;
            .leftSide {
                display: flex;
                align-items: center;
                gap: 5px;
            }
            span {
                color: #202020;
                font-size: 20px;
                font-style: normal;
                font-weight: 800;
                line-height: normal;
                width: max-content;
            }
            small {
                color: #000;
                text-align: right;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
            .anticon-check-circle {
                display: none;
            }
            .ant-progress-show-info .ant-progress-outer {
                padding: 0;
            }
        }
        .btnCol {
            height: 100%;
            display: flex;
            justify-content: flex-end;
            .downloadBtn {
                height: 40px;
            }
            .downloadBtn:hover {
                color: ${theme.colors.main};
            }
        }
    }
`;
