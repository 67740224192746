export const ExportIcon = () => {
    return (
        <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask
                id="mask0_12839_31980"
                maskUnits="userSpaceOnUse"
                x="4"
                y="8"
                width="16"
                height="12"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4 8.93555H20V19.931H4V8.93555Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_12839_31980)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.4522 19.931H7.54807C5.59203 19.931 4 18.3397 4 16.3829V12.4828C4 10.5268 5.59203 8.93555 7.54807 8.93555H8.29449C8.62569 8.93555 8.8945 9.20435 8.8945 9.53556C8.8945 9.86677 8.62569 10.1356 8.29449 10.1356H7.54807C6.25285 10.1356 5.20002 11.1884 5.20002 12.4828V16.3829C5.20002 17.6781 6.25285 18.7309 7.54807 18.7309H16.4522C17.7467 18.7309 18.8003 17.6781 18.8003 16.3829V12.4756C18.8003 11.1852 17.7507 10.1356 16.4611 10.1356H15.7066C15.3754 10.1356 15.1066 9.86677 15.1066 9.53556C15.1066 9.20435 15.3754 8.93555 15.7066 8.93555H16.4611C18.4123 8.93555 20.0003 10.5236 20.0003 12.4756V16.3829C20.0003 18.3397 18.4083 19.931 16.4522 19.931Z"
                    fill="black"
                />
            </g>
            <mask
                id="mask1_12839_31980"
                maskUnits="userSpaceOnUse"
                x="11"
                y="3"
                width="2"
                height="12"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.3999 3.5H12.5999V14.3329H11.3999V3.5Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask1_12839_31980)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.9999 14.333C11.6687 14.333 11.3999 14.0642 11.3999 13.733V4.10001C11.3999 3.76881 11.6687 3.5 11.9999 3.5C12.3311 3.5 12.5999 3.76881 12.5999 4.10001V13.733C12.5999 14.0642 12.3311 14.333 11.9999 14.333Z"
                    fill="black"
                />
            </g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.66793 7.04279C9.51513 7.04279 9.36152 6.98439 9.24472 6.86759C9.01032 6.63399 9.00872 6.25478 9.24312 6.01957L11.5752 3.67713C11.8 3.45072 12.2 3.45072 12.4248 3.67713L14.7576 6.01957C14.9912 6.25478 14.9904 6.63399 14.756 6.86759C14.5208 7.1012 14.1416 7.1012 13.908 6.86599L12 4.95075L10.0927 6.86599C9.97594 6.98439 9.82153 7.04279 9.66793 7.04279Z"
                fill="black"
            />
        </svg>
    );
};
