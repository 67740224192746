import { ISvg } from "@interfaces";

export const IconOrganizationSettings = (props: ISvg) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.size || "50"}
            height={props.size || "48"}
            viewBox="0 0 50 48"
            fill="none"
        >
            <path
                d="M47.9167 38.5416H42.3687C41.4667 35.5395 38.7083 33.3333 35.4167 33.3333C32.1251 33.3333 29.3687 35.5395 28.4646 38.5416H2.0833C0.933301 38.5416 0 39.475 0 40.625C0 41.775 0.933301 42.7083 2.0833 42.7083H28.4646C29.3666 45.7103 32.125 47.9166 35.4166 47.9166C38.7082 47.9166 41.4646 45.7103 42.3687 42.7083H47.9166C49.0686 42.7083 49.9999 41.775 49.9999 40.625C49.9999 39.475 49.0687 38.5416 47.9167 38.5416ZM35.4167 43.75C33.6937 43.75 32.2917 42.3479 32.2917 40.625C32.2917 38.902 33.6937 37.5 35.4167 37.5C37.1396 37.5 38.5417 38.902 38.5417 40.625C38.5417 42.3479 37.1395 43.75 35.4167 43.75Z"
                fill={props.color || "black"}
            />
            <path
                d="M47.9167 5.2083H42.3687C41.4646 2.20625 38.7083 0 35.4167 0C32.1251 0 29.3687 2.20625 28.4646 5.2083H2.0833C0.933301 5.2083 0 6.1416 0 7.2916C0 8.4416 0.933301 9.3749 2.0833 9.3749H28.4646C29.3687 12.3771 32.125 14.5833 35.4167 14.5833C38.7084 14.5833 41.4646 12.3771 42.3687 9.375H47.9167C49.0687 9.375 50 8.4417 50 7.2917C50 6.1417 49.0687 5.2083 47.9167 5.2083ZM35.4167 10.4166C33.6937 10.4166 32.2917 9.01455 32.2917 7.2916C32.2917 5.56865 33.6937 4.1666 35.4167 4.1666C37.1396 4.1666 38.5417 5.56865 38.5417 7.2916C38.5417 9.01455 37.1395 10.4166 35.4167 10.4166Z"
                fill={props.color || "black"}
            />
            <path
                d="M47.9167 21.875H21.5354C20.6313 18.873 17.875 16.6667 14.5834 16.6667C11.2918 16.6667 8.53545 18.873 7.63135 21.875H2.0833C0.933301 21.875 0 22.8083 0 23.9583C0 25.1083 0.933301 26.0416 2.0833 26.0416H7.63125C8.53545 29.0437 11.2917 31.2499 14.5833 31.2499C17.8749 31.2499 20.6313 29.0437 21.5354 26.0416H47.9166C49.0686 26.0416 49.9999 25.1083 49.9999 23.9583C50 22.8083 49.0687 21.875 47.9167 21.875ZM14.5833 27.0833C12.8604 27.0833 11.4583 25.6813 11.4583 23.9583C11.4583 22.2354 12.8604 20.8333 14.5833 20.8333C16.3062 20.8333 17.7083 22.2354 17.7083 23.9583C17.7083 25.6813 16.3062 27.0833 14.5833 27.0833Z"
                fill={props.color || "black"}
            />
        </svg>
    );
};
