import { orgApi, storeAPI } from "@api";
import { IAmphur, IProvince, ISubDistrict } from "@interfaces";
import { Row, Col, Input, Select } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
const { Option } = Select;

interface IFocusAddress {
    province_id: string;
    amphur_id: string;
    subDistrict_id: string;
    subDistrict_index: string;
}
interface Iprops {
    // setTextSearch: (value: string) => void;
    onChangeProvince: (value: string) => void;
    onChangeAmphur: (value: string) => void;
    onChangeSubDistrict: (value: string, id: string) => void;
    total: number;
    focusAddress: IFocusAddress;
}

export const ComponentTableStoreFilter = (props: Iprops) => {
    const {
        onChangeProvince,
        onChangeAmphur,
        onChangeSubDistrict,
        total,
        focusAddress,
    } = props;
    const { t } = useTranslation();
    const [provinceList, setProvinceList] = useState<IProvince[]>([]);
    const [amphurList, setAmphurList] = useState<IAmphur[]>([]);
    const [subDistrictList, setSubDistrictList] = useState<ISubDistrict[]>([]);
    useEffect(() => {
        getAllProvince();
    }, []);
    const getAllProvince = async () => {
        const res = await orgApi.getProvince();
        setProvinceList(res.data.result);
    };
    const getAmphur = async (pId: number) => {
        let allAmphur = await orgApi.getAmphur({
            province_id: pId,
            xpage: 1,
            xlimit: 100,
        });
        setAmphurList(allAmphur.data.result);
    };
    const getSubDistrictByamphur = async (aId: number) => {
        let subRes = await orgApi.getSubDistrict({
            amphur_id: aId,
            xpage: 1,
            xlimit: 100
        });
        setSubDistrictList(subRes.data.result);
    };
    const handleChangeProvince = async (value: any) => {
        onChangeProvince(value);
        await getAmphur(value);
    };

    const handleChangeAmphur = async (value: any) => {
        onChangeAmphur(value);
        await getSubDistrictByamphur(value);
    };
    const handleChangeSubDistrict = (value: any) => {
        onChangeSubDistrict(value, subDistrictList[value]?.district_id);
    };

    return (
        <StyledSearchLayout>
            <div className="filter">
                <Row gutter={24} type="flex" justify="start" align="middle" className="search-row">
                    <Col className="col-item" span={6}>
                        <div className="filter-element">
                            <p>{t("page.province")} : </p>
                            <Select
                                showSearch
                                filterOption={(input, option) =>
                                    //@ts-ignore
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={handleChangeProvince}
                                value={focusAddress?.province_id}
                            >
                                <Option value={""}>all</Option>
                                {provinceList?.map((pItem: any, pIndex: number) => {
                                    return (
                                        <Option value={pItem?.province_id} key={pIndex}>
                                            {pItem?.province_name_tha}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </div>
                    </Col>
                    <Col className="col-item" span={6}>
                        <div className="filter-element">
                            <p>{t("page.district")} : </p>
                            <Select
                                showSearch
                                filterOption={(input, option) =>
                                    //@ts-ignore
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={handleChangeAmphur}
                                value={focusAddress?.amphur_id}
                            >
                                <Option value={""}>all</Option>
                                {amphurList?.length > 0 && amphurList?.map((aItem, aIndex) => {
                                    return (
                                        <Option value={aItem?.amphur_id} key={aIndex}>
                                            {aItem?.amphur_name_tha}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </div>
                    </Col>
                    <Col className="col-item" span={6}>
                        <div className="filter-element">
                            <p>{t("page.subDistrict")} : </p>
                            <Select
                                showSearch
                                filterOption={(input, option) =>
                                    //@ts-ignore
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={handleChangeSubDistrict}
                                value={focusAddress?.subDistrict_index}
                            >
                                <Option value={""}>all</Option>
                                {subDistrictList?.length > 0 && subDistrictList?.map((sItem, sIndex) => {
                                    return (
                                        <Option value={sIndex} key={sIndex}>
                                            {sItem?.district_name_tha}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </div>
                    </Col>
                    <Col className="col-item" span={6}>
                        <div className="filter-element result">
                            <p>
                                <span>{t("page.result")}</span>
                                <span>{":"}</span>
                                <span>{total}</span>
                            </p>
                        </div>
                    </Col>
                </Row>
            </div>
        </StyledSearchLayout>
    );
};
const StyledSearchLayout = styled.div`
    .filter {
        background: #fff;
        padding: 20px;
        border-radius: 4px;
        margin-bottom: 26px;

        .filter-element {
            display: flex;
            align-items: center;
            column-gap: 8px;
            p {
                margin: 0;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #646464;
                min-width: 90px;
            }
            .ant-input-search {
                height: 44px;
                width: 100%;
            }
            .ant-select {
                /* min-width: 160px; */
                width: 100%;
                .ant-select-selection--single {
                    height: 44px;
                    .ant-select-selection__rendered {
                        height: 100%;
                        align-items: center;
                        display: flex;
                    }
                }
            }
            .ant-calendar-picker-input {
                height: 44px;
            }
            &.result {
                display: flex;
                justify-content: end;
                p {
                    display: flex;
                    column-gap: 10px;
                }
            }
        }
    }
`;
