export default {
    invalid_type: {
        email: "Email must be a valid email",
    },
    correct_url: "This field must be a valid URL format",
    field_required: "This field is required!",
    email_invalid: "Email is invalid",
    required: "{{name}} is required",
    pass_not_confirm: "This password not confirm",
    max_length: "{{name}} should not exceed 255 characters",
    max_length_number: "{{name}} should not exceed {{number}} characters",
    password_must_match: "Passwords must match",
    min: "{{name}} is at least {{number}} characters ",
    max: "{{name}} must be shorter than {{number}} characters",
    max_number: "{{name}} must be less than or equal to {{number}}",
    min_number: "{{name}} must be greater than or equal to {{number}}",
    must_number: "{{name}} must be numbers",
    date_before_now: "{{name}} must be before today",
    date_now: "The date must be greater than the current date.",
    date_from: "Date must be greater than date from.",
    is_Number: "Must be a number",
    must_be_a_valid_data_URL: "Must be a valid data URL",
    please_enter_website: "Please enter website",
    image: {
        wrong_type: "The image must be in the format png, jpg, jpeg",
        empty: "No image found",
        max_size: "Image no more than 10MB",
        not_svg: "This image must be in the format svg or png",
    },
    file_max_size_10: "This file no more than 10MB",
    password_require: "Password is required",
    tier_value_not_same: "Tier values must not be same with other",
};
