import { useTranslation } from "react-i18next";
import { Col, Collapse, Divider, Icon, Row } from "antd";
import { ICheckConsumableList, ICheckProductList } from "@interfaces";
import styled from "styled-components";

interface IProps {
    consumable?: ICheckConsumableList;
    product?: ICheckProductList;
}

export const ComponentRowCheckStockInside = (props: IProps) => {
    const { t } = useTranslation();
    const { consumable, product } = props;
    const { Panel } = Collapse;

    const customPanelStyle = {
        background: "#f7f7f7",
        borderRadius: 4,
        // border: 0,
        overflow: "hidden",
    };

    return (
        <CustomStyle>
            <div className="table-element">
                <div className="table-row">
                    {consumable ? (
                        <Row
                            style={{ height: 45 }}
                            gutter={24}
                            type="flex"
                            align="middle"
                            justify="space-between"
                        >
                            <Col className="col-item" span={6}>
                                <p className="news-title">
                                    {(consumable && consumable?.consumable_name) ||
                                        t("page.empty_text")}
                                </p>
                            </Col>
                            <Col className="col-item" span={6}>
                                <p className="news-title">
                                    {(consumable && Number((consumable?.grand_total_restock_qty).toFixed(2))) ||
                                        t("page.empty_text")}
                                </p>
                            </Col>
                            <Col className="col-item" span={6}>
                                <p className="news-title">
                                    {(consumable && Number((consumable?.before_restock_qty).toFixed(2))) ||
                                        t("page.empty_text")}
                                </p>
                            </Col>
                            <Col className="col-item" span={6}>
                                <p className="news-title">
                                    {(consumable && Number((consumable?.restock_change_qty).toFixed(2))) ||
                                        t("page.empty_text")}
                                </p>
                            </Col>
                        </Row>
                    ) : product?.check_variant_list.length === 1 ? (
                        <Row
                            style={{ height: 45 }}
                            gutter={24}
                            type="flex"
                            align="middle"
                            justify="space-between"
                        >
                            <Col className="col-item" span={6}>
                                <p className="news-title">
                                    {(product &&
                                        product.check_variant_list &&
                                        product.check_variant_list[0].variant_name) ||
                                        t("page.empty_text")}
                                </p>
                            </Col>
                            <Col className="col-item" span={6}>
                                <p className="news-title">
                                    {(product &&
                                        product.check_variant_list &&
                                        product.check_variant_list[0]?.grand_total_restock_qty) ||
                                        t("page.empty_text")}
                                </p>
                            </Col>
                            <Col className="col-item" span={6}>
                                <p className="news-title">
                                    {(product &&
                                        product.check_variant_list &&
                                        product.check_variant_list[0]?.before_restock_qty) ||
                                        t("page.empty_text")}
                                </p>
                            </Col>
                            <Col className="col-item" span={6}>
                                <p className="news-title">
                                    {(product &&
                                        product.check_variant_list &&
                                        product.check_variant_list[0]?.restock_change_qty) ||
                                        t("page.empty_text")}
                                </p>
                            </Col>
                        </Row>
                    ) : (
                        <Row
                            style={{ height: "100%" }}
                            gutter={24}
                            type="flex"
                            align="middle"
                            justify="space-between"
                        >
                            <Collapse bordered={true}>
                                <Panel
                                    header={
                                        (product && product.product_name) || t("page.empty_text")
                                    }
                                    key="1"
                                    style={customPanelStyle}
                                    className="panelClass"
                                >
                                    {product &&
                                        product.check_variant_list &&
                                        product.check_variant_list.map((variant, i) => (
                                            <div>
                                                <Row className="innerRow">
                                                    <Col className="col-item" span={6}>
                                                        <p className="news-title" onClick={() => console.log(variant)}>
                                                            {variant.variant_name ||
                                                                t("page.empty_text")}
                                                        </p>
                                                    </Col>
                                                    <Col className="col-item" span={6}>
                                                        <p className="news-title">
                                                            {variant?.grand_total_restock_qty ||
                                                                t("page.empty_text")}
                                                        </p>
                                                    </Col>
                                                    <Col className="col-item" span={6}>
                                                        <p className="news-title">
                                                            {variant?.before_restock_qty ||
                                                                t("page.empty_text")}
                                                        </p>
                                                    </Col>
                                                    <Col className="col-item" span={6}>
                                                        <p className="news-title">
                                                            {variant.restock_change_qty ||
                                                                t("page.empty_text")}
                                                        </p>
                                                    </Col>
                                                </Row>
                                                {i !== product.check_variant_list.length - 1 && (
                                                    <Divider />
                                                )}
                                            </div>
                                        ))}
                                </Panel>
                            </Collapse>
                        </Row>
                    )}
                </div>
            </div>
        </CustomStyle>
    );
};

const CustomStyle = styled.div`
    .ant-collapse {
        width: 100%;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
        position: relative;
        top: -3px;
    }
    .table-row {
        height: auto;
        width: 100%;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        margin-bottom: 0;
        font-size: 16px;
        color: #6c7084;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
    }
`;
