import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { StyledNewDetail } from "@components";
import { PATH_PROMOTION_CREATE } from "@configs";
import { ModulePromotionForm } from "@modules";
import { useNotify, showErrorMessage } from "@utils";
import { setLoading, selectAuth } from "@redux";
import { promotionAPI } from "@api";
import { IPromotionExample } from "@interfaces";

export const PagePromotionCreate = () => {
    const { id } = useParams<{ id: string }>();
    const organization_id = useSelector(selectAuth).userInfo?.merchant?._id;
    const dispatch = useDispatch();
    const history = useHistory();
    const { warning } = useNotify();
    const { t } = useTranslation();
    const [promotion, setPromotion] = useState<IPromotionExample>();
    const pathName = history.location.pathname;

    useEffect(() => {
        fetchProduct();
    }, []);

    const fetchProduct = async () => {
        dispatch(setLoading(true));
        if (pathName !== PATH_PROMOTION_CREATE) {
            try {
                const get = await promotionAPI.getPosPromotion({
                    organization_id: organization_id,
                    pos_promotion_id: id,
                });
                if (get.data.error === "0") {
                    setPromotion(get.data.result);
                }
                dispatch(setLoading(false));
            } catch (err: any) {
                if (err.response) {
                    warning(showErrorMessage(err.response));
                    dispatch(setLoading(false));
                } else warning(t("message.not_found", { name: t("object._reward") }));
                history.push(PATH_PROMOTION_CREATE);
            } finally {
                dispatch(setLoading(false));
            }
        }
    };

    return <StyledNewDetail>{<ModulePromotionForm promotion={promotion} />}</StyledNewDetail>;
};
