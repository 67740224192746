export const EngIcon = () => (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="14" cy="14.142" r="12" fill="#C4C4C4" />
        <circle cx="14" cy="14.142" r="12.8" stroke="white" strokeOpacity="0.8" strokeWidth="1.6" />
        <path
            d="M14 26.142C20.6274 26.142 26 20.7694 26 14.142C26 7.51458 20.6274 2.142 14 2.142C7.37258 2.142 2 7.51458 2 14.142C2 20.7694 7.37258 26.142 14 26.142Z"
            fill="white"
        />
        <path
            d="M4.4805 6.8364C3.53789 8.06279 2.82713 9.4763 2.41327 11.0119H8.65599L4.4805 6.8364Z"
            fill="#0263E0"
        />
        <path
            d="M25.5865 11.0118C25.1726 9.47624 24.4618 8.06272 23.5193 6.83633L19.3439 11.0118H25.5865Z"
            fill="#0263E0"
        />
        <path
            d="M2.41327 17.2728C2.82718 18.8083 3.53794 20.2218 4.4805 21.4482L8.65585 17.2728H2.41327Z"
            fill="#0263E0"
        />
        <path
            d="M21.3057 4.62285C20.0793 3.68024 18.6658 2.96948 17.1302 2.55557V8.79824L21.3057 4.62285Z"
            fill="#0263E0"
        />
        <path
            d="M6.69409 23.6616C7.92048 24.6042 9.334 25.315 10.8695 25.7289V19.4862L6.69409 23.6616Z"
            fill="#0263E0"
        />
        <path
            d="M10.8695 2.55557C9.33395 2.96948 7.92044 3.68024 6.69409 4.62281L10.8695 8.7982V2.55557Z"
            fill="#0263E0"
        />
        <path
            d="M17.1302 25.7289C18.6658 25.315 20.0793 24.6042 21.3056 23.6616L17.1302 19.4862V25.7289Z"
            fill="#0263E0"
        />
        <path
            d="M19.3439 17.2728L23.5193 21.4482C24.4618 20.2219 25.1726 18.8083 25.5865 17.2728H19.3439Z"
            fill="#0263E0"
        />
        <path
            d="M25.8984 12.5768H15.5653H15.5653V2.24358C15.0529 2.17687 14.5305 2.142 14 2.142C13.4694 2.142 12.9471 2.17687 12.4348 2.24358V12.5767V12.5767H2.10158C2.03488 13.0891 2 13.6115 2 14.142C2 14.6726 2.03488 15.1949 2.10158 15.7072H12.4347H12.4348V26.0404C12.9471 26.1071 13.4694 26.142 14 26.142C14.5305 26.142 15.0529 26.1072 15.5652 26.0404V15.7073V15.7072H25.8984C25.9651 15.1949 26 14.6726 26 14.142C26 13.6115 25.9651 13.0891 25.8984 12.5768V12.5768Z"
            fill="#F22F46"
        />
        <path
            d="M17.1302 17.2724L22.4851 22.6272C22.7313 22.381 22.9663 22.1236 23.1904 21.8568L18.606 17.2724H17.1302V17.2724Z"
            fill="#F22F46"
        />
        <path
            d="M10.8694 17.2724H10.8693L5.51453 22.6271C5.76071 22.8734 6.0181 23.1084 6.28492 23.3325L10.8694 18.7479V17.2724Z"
            fill="#F22F46"
        />
        <path
            d="M10.8694 11.0116V11.0115L5.51458 5.65668C5.2683 5.90286 5.03336 6.16026 4.8092 6.42707L9.39372 11.0116H10.8694V11.0116Z"
            fill="#F22F46"
        />
        <path
            d="M17.1302 11.0118L22.4851 5.6569C22.2389 5.41062 21.9815 5.17568 21.7147 4.95157L17.1302 9.53608V11.0118Z"
            fill="#F22F46"
        />
    </svg>
);
