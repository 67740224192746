import React, { useEffect, memo, useState, useRef, useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import {
    Switch,
    Row,
    Col,
    Select,
    Radio,
    Checkbox,
    Input,
    Tag,
    Popconfirm,
    Table,
    Form,
    Tooltip,
    Button,
    TimePicker,
    Tree,
    Avatar,
} from "antd";
import styled from "styled-components";
import moment from "moment";
import { INews, INewsData, INewsCategory, INewsCategoryParams } from "@interfaces";
import {
    SharedCKEditor,
    StyledSubmitButton,
    StyledCancelButton,
    StyledCard,
    SharedInput,
    ComponentPeriod,
    InfoIcon,
    IconCamera,
} from "@components";
import { useYup } from "@validations";
import { getCategories, setLoading, selectAuth } from "@redux";
import { ModuleUploadImage } from "@modules";
import { showErrorMessage, useNotify } from "@utils";
import {
    enumAddNews,
    PATH_CREATE_NEWS,
    PATH_NEWS,
    PATH_PRODUCT,
    PATH_PRODUCT_CREATE,
    PATH_STORE,
    PATH_STORE_CREATE,
    PATH_STORE_LIST,
} from "@configs";
import { newsAPI, orgApi } from "@api";
import { ComponentProductDrawer, ComponentProductPreview } from "src/Components/product";
import { RadioChangeEvent } from "antd/lib/radio";
import { DescriptionIcon } from "src/Components/icon/DescriptionIcon";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { VariantGrayIcon } from "src/Components/icon/VariantGrayIcon";
import { ColumnProps } from "antd/lib/table";
import { PrintIcon } from "src/Components/icon/PrintIcon";
import { ComponentProductBarcodeModal } from "src/Components/product/ProductBarcodeModal";
import axios from "axios";
// import { ComponentMaps } from "./Map";
import { GoogleMap, Marker } from "react-google-maps";
import Geocode from "react-geocode";
import { DeleteOutlined } from "@ant-design/icons";
// import { ComponentSearchInput } from "./Search";
interface IProps {
    store: any | undefined;
    categories: INewsCategory[] | undefined;
}
const newDate = new Date();
// form inital data
const initData: INewsData = {
    organization_id: "",
    title: "",
    detail: "",
    image_url: [],
    is_hot_news: false,
    news_category_id: "",
    date_from: new Date(),
    date_to: new Date(newDate.setFullYear(newDate.getFullYear() + 1)),
    is_enable: true,
    discount: "",
    product_code: "",
    price: "",
    discount_type: "",
    has_barcode: true,
    active_on_platform: [],
    point_deduction: false,
    maximum_deduction: false,
    point_deduction_value: "",
    variants: [
        {
            variant_name: "",
            qty: "",
            raw_price: "",
            net_price: "",
            barcode: "",
            status: true,
        },
    ],
};
const { Option } = Select;

export const ModuleReportSettingForm = memo((props: IProps) => {
    //hook
    const { YupNews } = useYup();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const { error, success } = useNotify();
    const [showModal, setShowModal] = useState<boolean>(false);
    const organization_id = useSelector(selectAuth).userInfo?.merchant._id;
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const searchInputRef = useRef<any | null>(null);
    const [cat, setCat] = useState<string>("");
    const pathName = window.location.pathname;
    const createNews = Yup.object().shape(YupNews);
    const { store, categories } = props;
    //
    const [taxRateSelection, setTaxRateSelection] = useState("");
    const [roundingNumber, setRoundingNumber] = useState("");
    const [vatPercent, setVatPercent] = useState(0);
    const [AccountNo, setAccountNo] = useState("");
    const [AccountName, setAccountName] = useState("");
    const [BankBranch, setBankBranch] = useState("");
    const [isActive, seIsActive] = useState<boolean>();

    //
    useEffect(() => {
        setTimeout(() => {
            dispatch(setLoading(false));
        }, 500);
        if (store) {
            // console.log(store);
            setVatPercent(store.vat_percent);
            if (store.vat_type === "include") {
                setTaxRateSelection("Include VAT");
            }
            if (store.vat_type === "exclude") {
                setTaxRateSelection("Exclude VAT");
            }
            if (store.rounding_type === "up") {
                setRoundingNumber("Round Up");
            }
            if (store.rounding_type === "down") {
                setRoundingNumber("Round Down");
            }
            // setPaymentQRCodeImage(store.prompt_pay_bank_transfer[0].qr_receive_money_image_url);
        }
    }, [store, categories]);
    const handleEnter = (e: string) => {
        if (e === "Enter") {
            searchInputRef.current.focus();
        }
    };

    const handleNewsSubmit = async (values: INewsData) => {
        dispatch(setLoading(true));
        try {
            const updateStoreAPI = await orgApi.updateVatDetail({
                organization_id: organization_id,
                vat_type: taxRateSelection === "Exclude VAT" ? "exclude" : "include",
                vat_percent: 7,
                rounding_type: roundingNumber === "Round Up" ? "up" : "down",
            });
            // console.log(updateStoreAPI);
            if (updateStoreAPI.data.error === "0") {
                success(t("message.update.success"));
                // setStoreName("");
                // setStoreCode("");
                // setChooseByStaffView(false);
                // history.push(PATH_STORE_LIST);
            }
        } catch (err: any) {
            if (err.response) {
                error(showErrorMessage(err.response));
            } else error(t("message.error"));
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleCancel = () => {
        history.push("/");
    };

    const { values, handleChange, handleBlur, handleSubmit, errors, touched, setFieldValue } =
        useFormik({
            initialValues: initData,
            validationSchema: createNews,
            enableReinitialize: true,
            onSubmit: handleNewsSubmit,
        });

    const handleChangeStatus = (e: boolean) => {
        setFieldValue("is_hot_news", e);
    };
    const handlePointDeduction = (e: boolean) => {
        setFieldValue("point_deduction", e);
    };
    const handleMaximumDeduction = (e: boolean) => {
        setFieldValue("maximum_deduction", e);
    };
    const handlePointDeductionValue = (e: any) => {
        setVatPercent(e.target.value);
    };

    const handleChangeDateFrom = (date: Date | string) => {
        setFieldValue("date_from", moment(date).toISOString());
    };

    const handleChangeDateTo = (date: Date | string) => {
        setFieldValue("date_to", moment(date).toISOString());
    };

    const handleUploadImage = (images: string) => {
        console.log(images);
        setFieldValue("image_url", images);
    };

    const handleChangeGuideLine = (event: any, editors: any) => {
        const data = editors.getData();
        setFieldValue("detail", data);
    };
    const handleSelectType = (value: string) => {
        if (value === enumAddNews.ADD_NEWS) {
            setShowModal(true);
        } else {
            setFieldValue("news_category_id", value);
        }
    };
    // const handleModal = () => {
    //     setShowPopup(true);
    // };
    const textString = "390px × 270px Maximum 10MB";
    const [paymentQRCodeImage, setPaymentQRCodeImage] = useState<string>("");

    const taxRateChange = (e: RadioChangeEvent) => {
        // console.log(e.target.value);
        setTaxRateSelection(e.target.value);
    };
    const roundingNumberChange = (e: RadioChangeEvent) => {
        // console.log(e.target.value);
        setRoundingNumber(e.target.value);
    };
    return (
        <div className="form-wrap">
            <form onSubmit={handleSubmit}>
                <div className="page-header">
                    <h3>
                        {t("page.report_setting.title")}
                    </h3>
                </div>
                <StyledCustom>
                    <div className="page-body">
                        <div className="card-wrap">
                            <StyledCard>
                                <div className="title" style={{ fontSize: "25px", color: "#000" }}>
                                    {t("page.report_setting.noti_setting")}
                                </div>

                                <Button className="report-btn">
                                    {t("page.report_setting.select_recipients")}
                                </Button>

                            </StyledCard>
                        </div>
                        <div className="card-wrap">
                            <StyledCard>
                                <div className="title" style={{ fontSize: "25px", color: "#000" }}>
                                    {t("page.report_setting.pos_report_setting")}
                                </div>
                                <Button className="report-btn">
                                    {t("page.report_setting.select_recipients")}
                                </Button>
                            </StyledCard>
                        </div>
                    </div>
                </StyledCustom>
            </form>
            {/* <ComponentProductPreview news={values} /> */}
            <ComponentProductDrawer
                showModal={showModal}
                handleClose={() => setShowModal(!showModal)}
            />
        </div>
    );
});

const StyledSwitch = styled.div`
    display: flex;
    align-items: center;
    p {
        margin-bottom: 0;
    }
    .weekDaysRow {
        display: flex;
        margin-top: 20px;
    }
    .ant-checkbox-wrapper + span,
    .ant-checkbox + span {
        display: flex;
        place-items: center;
        font-size: 16px;
        font-weight: 700;
        color: #000;
    }
    .ant-checkbox-wrapper {
        display: flex;
        place-items: center;
    }
    .ant-time-picker {
        margin-left: 8px;
        margin-right: 8px;
        width: 128px !important;
    }
    .daysOfTheWeek {
        width: 100px;
    }
`;

const StyledCustom = styled.div`
    .report-btn{
        color: white;
        background-color: #f4394f;
        height: 48px;
    }
`;
