import { useState, useEffect } from "react";
import {
    ComponentContactSortIcon,
    StyledTable,
    ComponentEmptyData,
    StyledTableButtonTop,
} from "@components";
import { Row, Col, Pagination } from "antd";
import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";
import {
    enumSortBy,
    enumContactSortFields,
    PAGINATION,
    PAGE_START,
    INITIAL_LIST_PARAMS,
} from "@configs";
import { IGetPromotionGroupsExample, IPaginationNext } from "@interfaces";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth, selectContact, setLoading } from "@redux";
import { useNotify } from "@utils";
import styled from "styled-components";
import { orgApi } from "@api";
import { ComponentPromotionGroupsDrawer } from "src/Components/promotionGroups";
import { ComponentRowPromotionGroups } from "src/Components/table/RowPromotionGroups";

const INITAL_PAGE = {
    xpage: PAGE_START,
    xlimit: PAGINATION,
};

export const PagePromotionGroups = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const search_params = useSelector(selectContact).paramsContact;
    const [params, setParams] = useState(search_params);
    const org_id: any = useSelector(selectAuth).userInfo?.merchant._id;
    const [showDrawer, setShowDrawer] = useState<boolean>(false);
    const [promotionGroupList, setPromotionGroupList] = useState<IGetPromotionGroupsExample[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [editItem, setEditItem] = useState();
    const { success, error } = useNotify();
    const [pagination, setPagination] = useState<IPaginationNext>(INITAL_PAGE);

    const fetchNews = async () => {
        dispatch(setLoading(true));
        try {
            const getCategories = await orgApi.getPromotionGroups({
                organization_id: org_id,
            });
            setPromotionGroupList(getCategories.data.result);
            setTotal(getCategories.data.total);
        } catch (err: any) {
            error(err.msg);
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleSort = (sortField: enumContactSortFields, sortBy: enumSortBy) => {
        const newSortParams = { ...params, sortField, sortBy };
        setParams(newSortParams);
    };
    const callbackGetList = () => {
        fetchNews();
    };

    const handlePagination = (page) => {
        setPagination(page);
    };
    const updateService = async (data) => {
        dispatch(setLoading(true));
        try {
            await null;
            success("api response");
        } catch (err: any) {
            error(err.msg);
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };
    const handleEditItem = (editItem) => {
        setShowDrawer(true);
        setEditItem(editItem);
    };
    const handleCloseModal = () => {
        setShowDrawer(false);
        setEditItem(undefined);
    };

    useEffect(() => {
        fetchNews();
    }, [showDrawer]);

    return (
        <StyledTable>
            <div className="page-layout">
                <div className="page-header-layout">
                    <div className="page-header" style={{ padding: "1.5rem 0" }}>
                        <div>
                            <h3>{t("page.product_collections")}</h3>
                        </div>
                        <div className="flex-header">
                            <StyledTableButtonTop
                                type="danger"
                                size="default"
                                text={t("page.add_product_collection")}
                                onClick={() => setShowDrawer(!showDrawer)}
                                className="add-btn"
                                icon="plus"
                                style={{
                                    borderRadius: "4px",
                                }}
                            />
                        </div>
                    </div>
                </div>
                <StyledPageTableLayout>
                    <div className="page-table-layout">
                        <div className="table">
                            <ScrollContainer vertical={false}>
                                <Styless>
                                    <div className="table_ground">
                                        <div className="table-body">
                                            <div className="table-header">
                                                <Row
                                                    gutter={24}
                                                    type="flex"
                                                    justify="space-between"
                                                    align="middle"
                                                    className="header-row"
                                                >
                                                    <Col
                                                        className="col-item"
                                                        span={12}
                                                        style={{ padding: "0" }}
                                                    >
                                                        <p>{t("page.category_name")}</p>
                                                        <div className="filter-button">
                                                            <ComponentContactSortIcon
                                                                handleSort={handleSort}
                                                                sortBy={enumSortBy.ASC}
                                                                sortField={
                                                                    enumContactSortFields.POINTS
                                                                }
                                                                searchParams={params}
                                                            />
                                                            <ComponentContactSortIcon
                                                                handleSort={handleSort}
                                                                sortBy={enumSortBy.DESC}
                                                                sortField={
                                                                    enumContactSortFields.POINTS
                                                                }
                                                                searchParams={params}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col
                                                        className="col-item"
                                                        span={6}
                                                        style={{ padding: "0" }}
                                                    >
                                                        <p>{t("page.promotions")}</p>
                                                        <div className="filter-button">
                                                            <ComponentContactSortIcon
                                                                handleSort={handleSort}
                                                                sortBy={enumSortBy.ASC}
                                                                sortField={
                                                                    enumContactSortFields.CREATED_AT
                                                                }
                                                                searchParams={params}
                                                            />
                                                            <ComponentContactSortIcon
                                                                handleSort={handleSort}
                                                                sortBy={enumSortBy.DESC}
                                                                sortField={
                                                                    enumContactSortFields.CREATED_AT
                                                                }
                                                                searchParams={params}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col
                                                        className="col-item"
                                                        span={6}
                                                        style={{ placeContent: "end" }}
                                                    >
                                                        <p>{t("page.manage")}</p>
                                                    </Col>
                                                </Row>
                                            </div>
                                            {promotionGroupList.length !== 0 ? (
                                                <div className="data-table">
                                                    {promotionGroupList.map((item, index) => (
                                                        <ComponentRowPromotionGroups
                                                            key={index}
                                                            handleEdit={updateService}
                                                            callbackGetList={callbackGetList}
                                                            editItem={handleEditItem}
                                                            index={index}
                                                            promotionGroupList={promotionGroupList}
                                                        />
                                                    ))}
                                                    <div
                                                        style={{ width: "100%", height: 10 }}
                                                    ></div>
                                                </div>
                                            ) : (
                                                <ComponentEmptyData />
                                            )}
                                        </div>
                                    </div>
                                </Styless>
                            </ScrollContainer>
                        </div>
                    </div>
                </StyledPageTableLayout>

                <div className="page-bottom">
                    {total > 0 && (
                        <div className="pagination">
                            <Pagination
                                defaultCurrent={INITIAL_LIST_PARAMS.page || 1}
                                pageSize={PAGINATION}
                                total={total}
                                onChange={handlePagination}
                                current={params.page}
                            />
                        </div>
                    )}
                </div>
            </div>
            <ComponentPromotionGroupsDrawer
                showDrawer={showDrawer}
                editItem={editItem}
                handleClose={handleCloseModal}
            />
        </StyledTable>
    );
};

export const Styless = styled.div`
    .table-header {
        padding: 18px 34px 12px 37px !important;
    }
`;

export const StyledPageTableLayout = styled.div`
    .page-table-layout {
        padding: 0 4.625rem 0 4.625rem !important;
    }
`;
