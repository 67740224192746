import { AccountLayout, LayoutAuth, LayoutDefault, LayoutError } from "@layouts";
import { IRoute } from "@interfaces";
import {
    PageHome,
    PageError404,
    PageNotCompleted,
    PageAccountList,
    PageNewsCreate,
    PageNewsList,
    PageHotNewsList,
    PageConplaintList,
    PageConplaintCreate,
    PageComplaintInfo,
    PageDivisionList,
    PageCategoryList,
    PageActivityList,
    PageServiceCreate,
    PageProductCreate,
    PageProductList,
    PageServiceList,
    PageStoreList,
    PageStoreCreate,
    PagePermissionPos,
    PageManagePermission,
    PageStockList,
    PageStockCreate,
    PageAssignStockList,
    PageAssignStockCreate,
    EditQTYPage,
    PagePurchaseOrderList,
    PagePOCreate,
    PageGoodReceiveList,
    PageGoodReceiveDetailList,
    PageSupplierList,
    PageSupplierCreate,
    PagePOShowPaper,
    PageGRShowPaper,
    PagePromotionList,
    PagePromotionCreate,
    PageWholesaleChannelList,
    PageWholeSaleEdit,
    PagePurchaseRequestList,
    PagePRCreate,
    PageInvCategoryList,
    PageBrand,
    PageReportSetting,
    PageUnit,
    PageTransferRequestList,
    PageTRCreate,
    PageExportTable,
} from "@pages";
import { PagePaymentSetting } from "src/Pages/payment";
import { PageBillCalculation } from "src/Pages/bill_calculation";
import { enumPermission } from "./enum";
import { PageEditInventoryQTYList } from "src/Pages/editInventoryQTY";
import { PageCheckStock, PagePublishCheckStock } from "src/Pages/checkStock";
import { PageAddOnProductCategory, PageCreateProductAddon } from "src/Pages/addOnProductCategory";
import { PagePublishWasteRecord, PageWasteRecord } from "src/Pages/wasteRecord";
import { PagePromotionCategoryList } from "src/Pages/promotionCategory";
import { PageSettingCategoryCheckStockList } from "src/Pages/settingCategoryCheckStock";
import { ModuleInsideStock } from "src/Modules/stock/InsideStock";
import { PagePromotionGroups } from "src/Pages/promotionGroups";
import { PageBillPromotionCreate, PageBillPromotionList } from "src/Pages/billPromotion";
import { PageRolesPermissionsSetting } from "src/Pages/roles_permissions";
import { PageRolesPermissionsList } from "src/Pages/roles_permissions/List";
import { PageImportTable } from "src/Pages/importTable";
import { PageRankingList } from "src/Pages/ranking/List";
// new routes
export const PATH_HOME = "/";
export const PATH_HOME_LOYALTY_USER = "/loyalty-user";
export const PATH_NEWS = "/news";
export const PATH_CREATE_NEWS = "/news/create";
export const PATH_NEWS_ALL = "/news/all";
export const PATH_NEWS_HIGHLIGHT = "/news/highlight";
export const PATH_COMPLAINT = "/complaint";
export const PATH_COMPLAINT_CREATE = "/complaint/create";
export const PATH_COMPLAINT_CATEGORY = "/complaint-category";
export const PATH_COMPLAINT_DIVISIONS = "/complaint-divisions";
export const PATH_ACTIVITY = "/activity";
export const PATH_ACTIVITY_DETAIL = "/activity-detail";
// old routes
export const PATH_ACCOUNTS = "/accounts";
export const PATH_CREATE_ACCOUNTS = "/create-accounts";

export const PATH_CONTACTS = "/contacts";
export const PATH_PERMISSIONS = "/permissions";
export const PATH_MERGES_SETTING = "/merges-setting";
export const PATH_MERGES_SUGGESTION = "/merges-suggestion";
export const PATH_LOYALTY = "/loyalty/rules";
export const PATH_SEGMENTS = "/segments";
export const PATH_USER_PROFILE = "/user/profile";
export const PATH_NOT_COMPLETED = "/not-completed";
export const PATH_UPGRADE_PLAN = "/upgrade-plan";
export const PATH_EDIT_POINT = "/edit-point";
export const PATH_ACCEPT_REDEEM = "/accept-redeem";
// export const PATH_ACCEPT_REDEEM_LOYALTY = "https://uat-admin.rocket-tech.app/accept-redeem";

// storefront
export const PATH_STOREFRONT = "/storefront";
export const PATH_SERVICES = "/services";
export const PATH_SERVICES_CREATE = "/services-create";
export const PATH_SERVICES_CATEGORY = "/services-category";
export const PATH_PRODUCT = "/products";
export const PATH_RANKING = "/ranking";
export const PATH_WHOLESALE_CHANNEL = "/wholesale-channel";
export const PATH_WHOLESALE_EDIT = "/whole-sale-edit";
export const PATH_EDIT_QTY_PRODUCT = "/edit-qty";
export const PATH_PRODUCT_CREATE = "/products-create";
export const PATH_PRODUCT_CATEGORY = "/products-create-category";
export const PATH_PRODUCT_ORDERS = "/product-orders";
export const PATH_PRODUCT_SETTINGS = "/product-settings";
export const PATH_PROMOTION = "/promotion";
export const PATH_STORE = "/store";
export const PATH_PAYMENT_SETTING = "/payment-setting";
export const PATH_CALCULATION_BILL = "/org-setting";
export const PATH_REPORT_SETTING = "/report-setting";
export const PATH_CATEGORY_LIST = "/category-list";
export const PATH_INV_CATE = "/inv-cate";
export const PATH_BRAND = "/brand";
export const PATH_UNIT = "/unit-seting";
export const PATH_STORE_ACCOUNT_SETTINGS = "/store-account-settings";
export const PATH_STORE_APPOINTMENT_SETTINGS = "/store-appintment-settings";
export const PATH_STORE_PAYMENT_SETTINGS = "/store-payment-settings";
export const PATH_STAFF = "/staff";
export const PATH_STAFF_SCHEDULING = "/staff-scheduling";
export const PATH_STAFF_TYPES = "/staff-types";
export const PATH_CUSTOMER_SERVICES = "/customer-services";
export const PATH_STORE_LIST = "/store-list";
export const PATH_STORE_CREATE = "/stores-create";
export const PATH_PERMISSION_POS = "/permission-pos";
export const PATH_STOCK_LIST = "/stock-list";
export const PATH_STOCK_CREATE = "/stock-create";
export const PATH_RECIPE_LIST = "/recipe-list";
export const PATH_RECIPE_ASSIGN = "/recipe-assign";
export const PATH_INVENTORY_QTY = "/edit-inventory-qty";
export const PATH_CHECK_STOCK = "/check-stock";
export const PATH_STOCK = "/stock";
export const PATH_WASTE = "/waste";
export const PATH_WASTE_RECORD = "/waste-record";
export const PATH_ADD_ON_PRODUCT_CATEGORY = "/add-on-product-category";
export const PATH_CREATE_ADDON = "/create-addon";
export const PATH_PURCHASE_REQUEST = "/purchase-request";
export const PATH_PURCHASE_REQUEST_CREATE = "/purchase-request-create";
export const PATH_TRANSFER_REQUEST = "/transfer-request";
export const PATH_TRANSFER_REQUEST_CREATE = "/transfer-request-create";
export const PATH_PURCHASE_ORDER = "/purchase-order";
export const PATH_PURCHASE_ORDER_CREATE = "/purchase-order-create";
export const PATH_GOOD_RECEIVE = "/good-receive";
export const PATH_GOOD_RECEIVE_PAPER = "/good-receive-paper";
export const PATH_GOOD_RECEIVE_DETAIL = "/good-receive-detail";
export const PATH_SUPPLIER = "/supplier";
export const PATH_SUPPLIER_CREATE = "/supplier-create";
export const PATH_PROMOTION_LIST = "/promotion-list";
export const PATH_PROMOTION_CREATE = "/promotion-create";
export const PATH_PROMOTION_CATEGORY_LIST = "/promotion-category";
export const PATH_SETTING_CATEGORY_CHECKSTOCK = "/setting-category-checkstock";
export const PATH_PROMOTION_GROUPS = "/promotion-groups";
export const PATH_BILL_PROMOTION = "/bill-promotion";
export const PATH_BILL_PROMOTION_CREATE = "/bill-promotion-create";
export const PATH_ROLES_PERMISSIONS = "/roles-permissions-setting";
export const PATH_ROLES_PERMISSIONS_List = "/roles-permissions";
export const PATH_IMPORT_TABLE = "/import-table";
export const PATH_EXPORT_TABLE = "/export-table";

export const PATH_404 = "*";

export const routes: Array<IRoute> = [
    { path: PATH_HOME, component: PageHome, exact: true },
    { path: PATH_HOME + "auth/:token", component: PageHome, exact: true },
    { path: PATH_HOME_LOYALTY_USER + "/:token", component: PageHome, exact: true },
    // ---------------------- storefornt module ------------------------------------------
    { path: PATH_SERVICES, component: PageServiceList, exact: true },
    { path: PATH_SERVICES_CREATE, component: PageServiceCreate, exact: true },
    { path: PATH_SERVICES_CREATE + "/:id", component: PageServiceCreate, exact: true },
    {
        path: PATH_RANKING,
        component: PageRankingList,
        permission: "can_create_manage_product",
        exact: true,
    },
    {
        path: PATH_PRODUCT,
        component: PageProductList,
        permission: "can_create_manage_product",
        exact: true,
    },
    { path: PATH_EDIT_QTY_PRODUCT + "/:id", component: EditQTYPage, exact: true },
    {
        path: PATH_PRODUCT + "/:id",
        component: PageProductCreate,
        permission: "can_create_manage_product",
        exact: true,
    },
    {
        path: PATH_PRODUCT_CREATE,
        component: PageProductCreate,
        permission: "can_create_manage_product",
        exact: true,
    },
    {
        path: PATH_WHOLESALE_CHANNEL,
        component: PageWholesaleChannelList,
        permission: "can_channel",
        exact: true,
    },
    {
        path: PATH_WHOLESALE_EDIT,
        component: PageWholeSaleEdit,
        permission: "can_channel",
        exact: true,
    },
    {
        path: PATH_WHOLESALE_EDIT + "/:id",
        component: PageWholeSaleEdit,
        permission: "can_channel",
        exact: true,
    },
    {
        path: PATH_STOCK_LIST,
        component: PageStockList,
        permission: "can_inventory_list",
        exact: true,
    },
    {
        path: PATH_STOCK_CREATE,
        component: PageStockCreate,
        permission: "can_inventory_list",
        exact: true,
    },
    {
        path: PATH_STOCK_LIST + "/:id",
        component: PageStockCreate,
        permission: "can_inventory_list",
        exact: true,
    },
    {
        path: PATH_INVENTORY_QTY,
        component: PageEditInventoryQTYList,
        permission: "can_edit_inventory_qty",
        exact: true,
    },
    {
        path: PATH_CHECK_STOCK,
        component: PageCheckStock,
        permission: "can_check_stock",
        exact: true,
    },
    {
        path: PATH_STOCK + "/:id",
        component: ModuleInsideStock,
        permission: "can_check_stock",
        exact: true,
    },
    {
        path: PATH_CHECK_STOCK + "/publish",
        component: PagePublishCheckStock,
        permission: "can_check_stock",
        exact: true,
    },
    {
        path: PATH_WASTE_RECORD,
        component: PageWasteRecord,
        permission: "can_waste_record",
        exact: true,
    },
    {
        path: PATH_WASTE + "/:id",
        component: ModuleInsideStock,
        permission: "can_waste_record",
        exact: true,
    },
    {
        path: PATH_WASTE_RECORD + "/publish",
        component: PagePublishWasteRecord,
        permission: "can_waste_record",
        exact: true,
    },
    { path: PATH_RECIPE_LIST, component: PageAssignStockList, exact: true },
    {
        path: PATH_RECIPE_ASSIGN,
        component: PageAssignStockCreate,
        permission: "can_assign_invertory",
        exact: true,
    },
    { path: PATH_RECIPE_ASSIGN + "/:id", component: PageAssignStockCreate, exact: true },
    { path: PATH_ADD_ON_PRODUCT_CATEGORY, component: PageAddOnProductCategory, exact: true },
    {
        path: PATH_CREATE_ADDON,
        component: PageCreateProductAddon,
        permission: "can_addon",
        exact: true,
    },
    { path: PATH_CREATE_ADDON + "/:id", component: PageCreateProductAddon, exact: true },
    {
        path: PATH_PROMOTION_LIST,
        component: PagePromotionList,
        permission: "can_product_promotion",
        exact: true,
    },
    { path: PATH_PROMOTION_CREATE, component: PagePromotionCreate, exact: true },
    {
        path: PATH_BILL_PROMOTION,
        component: PageBillPromotionList,
        permission: "can_bill_promotion",
        exact: true,
    },
    {
        path: PATH_BILL_PROMOTION_CREATE,
        component: PageBillPromotionCreate,
        permission: "can_bill_promotion",
        exact: true,
    },
    {
        path: PATH_BILL_PROMOTION_CREATE + "/:id",
        component: PageBillPromotionCreate,
        permission: "can_bill_promotion",
        exact: true,
    },
    { path: PATH_PROMOTION + "/:id", component: PagePromotionCreate, exact: true },
    {
        path: PATH_SETTING_CATEGORY_CHECKSTOCK,
        component: PageSettingCategoryCheckStockList,
        permission: "can_check_stock_list",
        exact: true,
    },
    { path: PATH_ROLES_PERMISSIONS, component: PageRolesPermissionsSetting, exact: true },
    {
        path: PATH_ROLES_PERMISSIONS_List,
        component: PageRolesPermissionsList,
        permission: "free",
        exact: true,
    },
    { path: PATH_ROLES_PERMISSIONS + "/:id", component: PageRolesPermissionsSetting, exact: true },
    // ---------------------- promotion category & groups --------------------------------------------
    {
        path: PATH_PROMOTION_CATEGORY_LIST,
        component: PagePromotionCategoryList,
        permission: "can_promotion_category",
        exact: true,
    },
    {
        path: PATH_PROMOTION_GROUPS,
        component: PagePromotionGroups,
        permission: "can_promotion_group",
        exact: true,
    },
    // ---------------------- purchase require --------------------------------------------
    {
        path: PATH_PURCHASE_REQUEST,
        component: PagePurchaseRequestList,
        permission: "can_inventory_require",
        exact: true,
    },
    {
        path: PATH_PURCHASE_REQUEST + "/:id",
        component: PagePRCreate,
        permission: "can_inventory_require",
        exact: true,
    },
    {
        path: PATH_PURCHASE_REQUEST_CREATE,
        component: PagePRCreate,
        permission: "can_inventory_require",
        exact: true,
    },
    // --------------------- TR
    {
        path: PATH_TRANSFER_REQUEST,
        component: PageTransferRequestList,
        permission: "can_inventory_require",
        exact: true,
    },
    {
        path: PATH_TRANSFER_REQUEST + "/:id",
        component: PageTRCreate,
        permission: "can_inventory_require",
        exact: true,
    },
    {
        path: PATH_TRANSFER_REQUEST_CREATE,
        component: PageTRCreate,
        permission: "can_inventory_require",
        exact: true,
    },
    // ---------------------- purchase order --------------------------------------------
    {
        path: PATH_PURCHASE_ORDER,
        component: PagePurchaseOrderList,
        permission: "can_purchase_order",
        exact: true,
    },
    {
        path: PATH_PURCHASE_ORDER + "/:id",
        component: PagePOShowPaper,
        permission: "can_purchase_order",
        exact: true,
    },
    {
        path: PATH_PURCHASE_ORDER_CREATE,
        component: PagePOCreate,
        permission: "can_purchase_order",
        exact: true,
    },
    // ---------------------- Good Receive ----------------------------------------------
    {
        path: PATH_GOOD_RECEIVE,
        component: PageGoodReceiveList,
        permission: "can_goods_receive",
        exact: true,
    },
    {
        path: PATH_GOOD_RECEIVE + "/:id",
        component: PageGoodReceiveDetailList,
        permission: "can_goods_receive",
        exact: true,
    },
    {
        path: PATH_GOOD_RECEIVE_PAPER + "/:id",
        component: PageGRShowPaper,
        permission: "can_goods_receive",
        exact: true,
    },
    // ---------------------- Supplier --------------------------------------------------
    {
        path: PATH_SUPPLIER,
        component: PageSupplierList,
        permission: "can_supplier_list",
        exact: true,
    },
    {
        path: PATH_SUPPLIER_CREATE,
        component: PageSupplierCreate,
        permission: "can_supplier_list",
        exact: true,
    },
    {
        path: PATH_SUPPLIER + "/:id",
        component: PageSupplierCreate,
        permission: "can_supplier_list",
        exact: true,
    },
    // ---------------------- Store Management ------------------------------------------
    {
        path: PATH_STORE_CREATE,
        component: PageStoreCreate,
        permission: "store_permission",
        exact: true,
    },
    {
        path: PATH_STORE + "/:id",
        component: PageStoreCreate,
        permission: "store_permission",
        exact: true,
    },
    {
        path: PATH_STORE_LIST,
        component: PageStoreList,
        permission: "store_permission",
        exact: true,
    },
    { path: PATH_PERMISSION_POS, component: PagePermissionPos, exact: true },
    {
        path: PATH_PERMISSION_POS + "/:store_code",
        component: PageManagePermission,
        exact: true,
    },
    {
        path: PATH_PAYMENT_SETTING,
        component: PagePaymentSetting,
        exact: true,
    },
    {
        path: PATH_CALCULATION_BILL,
        component: PageBillCalculation,
        permission: "can_pos_organization_setting",
        exact: true,
    },
    // {
    //     path: PATH_REPORT_SETTING,
    //     component: PageReportSetting,
    //     exact: true,
    // },
    // ---------------------- Store Management ------------------------------------------
    {
        path: PATH_CATEGORY_LIST,
        component: PageCategoryList,
        permission: "can_product_category",
        exact: true,
    },
    {
        path: PATH_INV_CATE,
        component: PageInvCategoryList,
        permission: "can_inventory_category",
        exact: true,
    },
    { path: PATH_BRAND, component: PageBrand, permission: "can_brand", exact: true },
    { path: PATH_UNIT, component: PageUnit, permission: "can_inventory_unit", exact: true },
    {
        path: PATH_IMPORT_TABLE,
        component: PageImportTable,
        permission: "can_import_pos_data",
        exact: true,
    },
    {
        path: PATH_EXPORT_TABLE,
        component: PageExportTable,
        permission: "can_import_pos_data",
        exact: true,
    },

    // ---------------------- news module ------------------------------------------
    { path: PATH_NEWS, component: PageNewsList, exact: true },
    { path: PATH_CREATE_NEWS, component: PageNewsCreate, exact: true },
    { path: PATH_CREATE_NEWS + "/:id", component: PageNewsCreate, exact: true },
    { path: PATH_NEWS_HIGHLIGHT, component: PageHotNewsList, exact: true },
    // ---------------------- complaint module ------------------------------------------
    { path: PATH_COMPLAINT, component: PageConplaintList, exact: true },
    { path: PATH_COMPLAINT + "/:id", component: PageComplaintInfo, exact: true },
    { path: PATH_COMPLAINT_CREATE, component: PageConplaintCreate, exact: true },
    { path: PATH_COMPLAINT_CATEGORY, component: PageCategoryList, exact: true },
    { path: PATH_COMPLAINT_DIVISIONS, component: PageDivisionList, exact: true },
    // ---------------------- activity module ------------------------------------------
    { path: PATH_ACTIVITY, component: PageActivityList, exact: true },
    // ---------------------- login module ------------------------------------------
    { path: PATH_ACCOUNTS, component: PageAccountList, exact: true, layout: AccountLayout },
    // err
    { path: PATH_NOT_COMPLETED, component: PageNotCompleted, layout: LayoutDefault, exact: true },
    { component: PageError404, exact: false, layout: LayoutError },
];

export const normalRoutes = [
    PATH_HOME,
    PATH_NEWS,
    PATH_CREATE_NEWS,
    PATH_NEWS_HIGHLIGHT,
    PATH_COMPLAINT,
    PATH_COMPLAINT_CREATE,
    PATH_COMPLAINT_CATEGORY,
    PATH_COMPLAINT_DIVISIONS,
];

export const authRoutes = [PATH_ACCOUNTS, PATH_CREATE_ACCOUNTS];
