export default {
    payment_setting: "ตั้งค่าการชำระเงิน",
    qr_payment_promptpay: "การชำระเงินรูปแบบ QR Code (Prompt pay)",
    image_qr_code: "รูปภาพ QR Code",
    bank_name: "ธนาคาร",
    please_select_bank: "Please Select Bank",
    account_no: "เลขบัญชี",
    account_name: "ชื่อบัญชี",
    branch: "สาขา",
    set_as_default_checkout_qr_code_payment: "Set as default check out QR code payment",
    static_qr_code_promptpay: "Static QR Code Promptpay",
    kbank_consumer_id: "KBank consumer id",
    kbank_consumer_secret: "KBank consumer secret",
    kbank_merchant_id: "KBank merchant id",
    kbank_partner_id: "KBank partner id",
    kbank_partner_secret: "KBank partner secret",
};
