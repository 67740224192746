import { compose, withProps } from "recompose";

import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";

export const ComponentMaps = compose(
    withProps({
        googleMapURL:
            "https://maps.googleapis.com/maps/api/js?key=AIzaSyAVf2Ys7ZTPiSl54uaX8cC4v698oL0qoNY&v=3.exp&libraries=geometry,drawing,places",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `400px` }} />,
        mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap
)((props) => (
    <GoogleMap
        defaultZoom={13}
        // defaultCenter={{ lat: props.location_lat, lng: props.location_lon }}
        center={{ lat: props.location_lat, lng: props.location_lon }}
        // onBoundsChanged={(e)=> console.log("onBounds"+e)}
        onClick={(event) => props.onChangeLocation(event.latLng.lat(), event.latLng.lng(), "")}
    >
        <Marker position={{ lat: props.location_lat, lng: props.location_lon }} />
    </GoogleMap>
));
