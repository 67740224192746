export const BranchIcon = () => (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.0625 8.82715C1.0625 8.44765 1.3705 8.13965 1.75 8.13965C2.1295 8.13965 2.4375 8.44765 2.4375 8.82715V16.6188C2.4375 17.7582 3.36058 18.6813 4.5 18.6813H15.5C16.6394 18.6813 17.5625 17.7582 17.5625 16.6188V8.82715C17.5625 8.44765 17.8705 8.13965 18.25 8.13965C18.6295 8.13965 18.9375 8.44765 18.9375 8.82715V16.6188C18.9375 18.5172 17.3984 20.0563 15.5 20.0563H4.5C2.60158 20.0563 1.0625 18.5172 1.0625 16.6188V8.82715Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 11.8064C10.9121 11.8064 11.7857 12.1685 12.431 12.8129C13.0754 13.4582 13.4375 14.3318 13.4375 15.2439V19.3689C13.4375 19.7484 13.1295 20.0564 12.75 20.0564C12.3705 20.0564 12.0625 19.7484 12.0625 19.3689V15.2439C12.0625 14.6966 11.8452 14.1723 11.4584 13.7855C11.0716 13.3986 10.5473 13.1814 10 13.1814C9.45275 13.1814 8.92842 13.3986 8.54158 13.7855C8.15475 14.1723 7.9375 14.6966 7.9375 15.2439V19.3689C7.9375 19.7484 7.6295 20.0564 7.25 20.0564C6.8705 20.0564 6.5625 19.7484 6.5625 19.3689V15.2439C6.5625 14.3318 6.92458 13.4582 7.569 12.8129C8.21433 12.1685 9.08792 11.8064 10 11.8064Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.19843 1.63135V6.7876C7.19843 8.7236 5.62726 10.2938 3.69126 10.2938C2.59951 10.2938 1.56826 9.7906 0.896344 8.93077C0.224427 8.07002 -0.0129894 6.94893 0.251011 5.88926L0.979761 2.97518C1.27859 1.78076 2.35109 0.943848 3.58126 0.943848H6.51093C6.89043 0.943848 7.19843 1.25185 7.19843 1.63135ZM5.82343 2.31885H3.58126C2.98176 2.31885 2.45926 2.72676 2.31443 3.30793L1.58568 6.22293C1.42343 6.87101 1.56918 7.5576 1.97984 8.08468C2.39143 8.61085 3.02301 8.91885 3.69126 8.91885C4.86826 8.91885 5.82343 7.9646 5.82343 6.7876V2.31885Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.4888 0.943848H16.4184C17.6486 0.943848 18.7211 1.78076 19.0199 2.97518L19.7487 5.88926C20.0127 6.94893 19.7753 8.07002 19.1034 8.93077C18.4314 9.7906 17.4002 10.2938 16.3084 10.2938C14.3724 10.2938 12.8013 8.7236 12.8013 6.7876V1.63135C12.8013 1.25185 13.1093 0.943848 13.4888 0.943848ZM14.1763 2.31885V6.7876C14.1763 7.9646 15.1314 8.91885 16.3084 8.91885C16.9767 8.91885 17.6083 8.61085 18.0199 8.08468C18.4305 7.5576 18.5763 6.87101 18.414 6.22293L17.6853 3.30793C17.5404 2.72676 17.0179 2.31885 16.4184 2.31885H14.1763Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.50342 0.943848H13.4884C13.6708 0.943848 13.8459 1.01626 13.9752 1.14551C14.1044 1.27476 14.1759 1.44985 14.1759 1.63226L14.1713 6.61893C14.1695 8.64935 12.5232 10.2938 10.4937 10.2938H9.4945C7.46317 10.2938 5.81592 8.64751 5.81592 6.61618V1.63135C5.81592 1.25185 6.12392 0.943848 6.50342 0.943848ZM7.19092 2.31885V6.61618C7.19092 7.8876 8.22217 8.91885 9.4945 8.91885H10.4937C11.7651 8.91885 12.7954 7.88943 12.7963 6.61801L12.8009 2.31885H7.19092Z"
            fill="white"
        />
    </svg>
);
