export default {
    jan: "มกราคม",
    feb: "กุมภาพันธ์",
    mar: "มีนาคม",
    apr: "เมษายน",
    may: "พฤษภาคม",
    jun: "มิถุนายน",
    jul: "กรกฎาคม",
    aug: "สิงหาคม",
    sep: "กันยายน",
    oct: "ตุลาคม",
    nov: "พฤศจิกายน",
    dec: "ธันวาคม",
};
