import React, { useEffect, memo, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Row, Col, Select, Radio, Input, Table, Button } from "antd";
import styled from "styled-components";
import {
    INewsData,
    INewsCategory,
    IPostSupByConsumeList,
    IPostCreatePO,
    IGetAutoGen,
    IGetVatDetailExample,
    IGetAdminDataByTokenAdminDataExample,
    IGetStoresBindingDropdownExample,
} from "@interfaces";
import {
    StyledSubmitButton,
    StyledCancelButton,
    StyledCard,
    Step1Icon,
    Step2Icon,
    Step3Icon,
    Step4Icon,
    StyledTableButtonAddRow,
    IconMinusCircle,
    IconAddCircle,
    ComponentPOModal,
    ComponentPOPaper,
    IconDelete,
} from "@components";
import { useYup } from "@validations";
import { setLoading, selectAuth, store, openToast, selectApp } from "@redux";
import { showErrorMessage, useNotify } from "@utils";
import {
    PAGE_START,
    PAGINATION,
    PATH_PRODUCT,
    PATH_PURCHASE_ORDER,
    PATH_PURCHASE_ORDER_CREATE,
} from "@configs";
import { POAPI, adminAPI, consumableAPI, orgApi, productAPI, storeAPI } from "@api";
import { ColumnProps } from "antd/lib/table";
import TextArea from "antd/lib/input/TextArea";
import { Checkbox } from "@mui/material";

interface IProps {
    product: any | undefined;
}
interface IConsume {
    consumable_id: string;
    name: string;
    restock_price: number;
    on_stork: number;
    add_qty: number;
    unit: string;
}
interface ICateItem {
    category: string;
    cate_id: string;
    itemList: any[];
}
const INITAL_PAGE = {
    xpage: PAGE_START,
    xlimit: PAGINATION,
};

const initResult2 = {
    address: "",
    remark: "",
    store_code: "",
    store_name: "",
    _id: "",
};

const newDate = new Date();
// form inital data
const initTestStep4 = {
    organization_id: "629dc8c29708d1e778e0b23b",
    po_no: "PO66042000039",
    store_id: "61d7f8d8332169bdd44112f5",
    supplier_id: "643631af0cdf6c3d94f18a5f",
    supplier_name: "bigc",
    tax_id: "1234567890987",
    address: "123 moo2",
    vat_type: "include",
    vat_percent: 7,
    credit_term_in_day: 0,
    status: "pending",
    po_consumable_list: [
        {
            consumable_id: "643790f4cbf14323c03cf861",
            consumable_name: "coke",
            restock_qty: 4,
            unit_price: 8,
            total_price: 32,
        },
    ],
    po_vat: 2.0934579439252374,
    po_grand_total: 32,
    pr_pending_list: [
        {
            consumable_id: "643790f4cbf14323c03cf861",
            consumable_name: "coke",
            restock_qty: 4,
            unit_price: 8,
            total_price: 32,
        },
    ],
    pr_receive_list: [],
    pr_vat: 0,
    pr_grand_total: 0,
    remark: "yefdsdsf",
    created_by: "62afe4dac65af68dac458eb8",
    created_date: "2023-04-20T12:58:39.074Z",
    updated_by: "62afe4dac65af68dac458eb8",
    updated_date: "2023-04-20T12:58:39.074Z",
    po_grand_total_word_th: "สามสิบสองบาทถ้วน",
    po_grand_total_word_en: "Thirty Two",
    pr_no: "GR66042000039",
};
const initTest2Step4 = {
    store_name: "Siam",
    address: "254 moo3 xxxxxxx",
    remark: "",
    store_id: "61d7f8d8332169bdd44112f5",
    organization_id: "629dc8c29708d1e778e0b23b",
    organization_name: "Chaan",
};
const initData: INewsData = {
    organization_id: "",
    title: "",
    detail: "",
    image_url: [],
    is_hot_news: false,
    news_category_id: "",
    date_from: new Date(),
    date_to: new Date(newDate.setFullYear(newDate.getFullYear() + 1)),
    is_enable: true,
    discount: "",
    product_code: "",
    price: "",
    discount_type: "",
    has_barcode: true,
    active_on_platform: [],
    point_deduction: false,
    maximum_deduction: false,
    point_deduction_value: "",
    variants: [
        {
            variant_name: "",
            qty: "",
            raw_price: "",
            net_price: "",
            barcode: "",
            status: true,
            store_list: [
                {
                    store_code: "",
                    qty: 0,
                },
            ],
        },
    ],
    hint_code: "",
    sku_earn_point: 0,
};
export const ModulePOForm = memo((props: IProps) => {
    const { YupNews } = useYup();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { Option } = Select;
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const params = new URLSearchParams(window.location.search);
    const itemType = params.get("type");
    const { error, success, warning } = useNotify();
    const [step, setStep] = useState(1);
    const [showPR, setShowPR] = useState<boolean>(false);
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [hasVariant, setHasVariant] = useState<boolean>(false);
    const [Stores, setStores] = useState<IGetStoresBindingDropdownExample[]>([]);
    const organization_id = useSelector(selectAuth).userInfo?.merchant?._id;
    const [cat, setCat] = useState<string>("");
    const pathName = window.location.pathname;
    const createNews = Yup.object().shape(YupNews);
    const [multipleImages, setMultipleImages] = useState<string[]>([]);
    const [focusStore, setFocusStore] = useState<string>("");
    const [consumeList, setConsumeList] = useState<any>([]);
    const [tprList, setTprList] = useState<any>([]);
    const [selectPR, setSelectPR] = useState<any[]>([]);
    const { product } = props;
    const [isActive, setIsActive] = useState<boolean>();
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const userInfo = useSelector(selectAuth).userInfo;
    const [supplierList, setSupplierList] = useState<any[]>([]);
    const [selectSup, setSelectSup] = useState<any[]>([]);
    const [resultData, setResultData] = useState([]);
    const [result2Data, setResult2Data] = useState(initResult2);
    const [vatDetail, setVatDetail] = useState<IGetVatDetailExample>();
    const [cateList, setCateList] = useState<any>([]);
    const [focusCate, setFocusCate] = useState<number>(0);
    const [cateItemList, setCateItemList] = useState<ICateItem[]>([]);
    const [textFilter, setTextFilter] = useState<string>("");
    const { adminPermission } = useSelector(selectAuth);


    useEffect(() => {
        fetchStoreList();
    }, [product]);

    useEffect(() => {
        if (focusStore !== "") {
            fetchPRList();
        }
    }, [focusStore]);

    useEffect(() => {
        if (showPR) {
            fetchCateList();
        }
    }, [showPR]);

    const fetchCateList = async () => {
        try {
            dispatch(setLoading(true));
            let cateResult: any[] = [];
            if (itemType === "inventory") {
                const getCateList = await orgApi.getInvCat({
                    organization_id: userInfo?.merchant?._id || "",
                });
                cateResult = getCateList.data.result;
            } else {
                const getAllProductCats = await orgApi.getProductCategorys({
                    organization_code: orgCode,
                });
                let outArr: any[] = [];
                getAllProductCats.data.result.forEach((cItem: any) => {
                    let obj = {
                        code: cItem?.code,
                        category: cItem?.category,
                        item_series: "",
                        item_group: "",
                        _id: cItem?._id,
                    };
                    outArr.push(obj);
                });
                cateResult = outArr;
            }

            let payload = [
                ...cateResult,
                {
                    code: "",
                    category: "No cat",
                    item_series: "",
                    item_group: "",
                    _id: "",
                },
            ];
            setCateList(payload);
            let arr: ICateItem[] = [];
            payload.forEach((pItem: any) => {
                let obj: ICateItem = {
                    category: pItem.category,
                    cate_id: pItem._id,
                    itemList: [],
                };
                arr.push(obj);
            });
            // setCateItemList([...arr]);
            setFocusCate(0);

            const getAllItem = await consumableAPI.getConsumablesStoreIdForCreatePR({
                organization_id: userInfo?.merchant?._id,
                xpage: 1,
                xlimit: 9999,
                is_enable: true,
                ...(itemType === "product" && { is_auto_consumable: true }),
                // is_auto_consumable: itemType === "product" ? true : null,
                store_id: focusStore,
                is_enable_popr: true,
                // consumable_category_id: cateList?.[focusCate || 0]?._id,
            });
            const allList = getAllItem.data.result;
            let updateGroup = arr.map((groupItem) => {
                const filteredItems = allList.filter((item) => {
                    const categoryId =
                        item.consumable_category_id || item?.product_category_id || ""; // Replace with your actual property
                    return categoryId === groupItem.cate_id;
                });
                let itemArr = filteredItems.map((cItem, cIndex) => {
                    return {
                        consumable_id: cItem._id,
                        name: cItem.name,
                        code: cItem.consumable_code,
                        restock_price: cItem.cost,
                        on_stork: cItem.consumable_data.restock_qty,
                        add_qty: 0,
                        unit: cItem.formula[0].restock_unit_name,
                        delivery_qty: cItem?.restock_formula?.[0]?.to_restock_unit || 1,
                        index: cIndex,
                    };
                });
                return {
                    category: groupItem.category,
                    cate_id: groupItem.cate_id,
                    itemList: itemArr,
                };
            });
            setCateItemList(updateGroup);
        } catch (err: any) {
            error(err.msg);
        } finally {
            dispatch(setLoading(false));
        }
    };

    const fetchPRList = async () => {
        let res = await POAPI.getPRList({
            organization_id: organization_id || "",
            xpage: 1,
            xlimit: 40,
            store_id: focusStore,
            status: "pending",
        });
        if (res?.data?.result?.length > 0) {
            let outArr = res.data.result.filter((cItem) => {
                return cItem?.tpr_type === itemType;
            });
            setShowPR(true);
            setTprList(outArr);
            setSelectPR([]);
        } else {
            fetchCateList();
        }
    };

    const fetchStoreList = async () => {
        dispatch(setLoading(true));
        try {
            const getStores = await storeAPI.getStoresBindingDropdown({
                organization_id: organization_id,
            });
            const tempStore: any = [];
            for (let i = 0; i < getStores.data.result.length; i++) {
                const new_item = {
                    store_name: getStores.data.result[i].store_name,
                    store_code: getStores.data.result[i].store_code,
                    qty: 0,
                    is_enable: false,
                    _id: getStores.data.result[i]._id,
                };
                tempStore.push(new_item);
            }
            const getVatDetail = await orgApi.getVatDetail({ organization_id: organization_id });
            if (getVatDetail.data.error === "0") {
                setVatDetail(getVatDetail.data.result);
            }
            setStores(tempStore);
            if (!product) {
                if (hasVariant === false) {
                    setTableData2(tempStore);
                }
            }
        } catch (err) {
            console.error(err);
        } finally {
            dispatch(setLoading(false));
        }
    };

    const addConsume = (payload: IConsume) => {
        const arr = cateItemList;
        const found = arr[focusCate].itemList.some((obj: any) => obj.name === payload.name);
        if (found === false) {
            let lastIndex = arr[focusCate].itemList[arr[focusCate].itemList.length - 1].index;
            arr[focusCate].itemList.push({ ...payload, index: (lastIndex + 1) });
            setCateItemList(arr);
            // arr.push(payload);
            // setConsumeList(arr);
        } else {
            warning("Already selected");
        }
    };

    const clickAutoGen = async () => {
        let payload: IGetAutoGen = {
            organization_id: organization_id || "",
            store_id: focusStore,
        };
        dispatch(setLoading(true));
        let res = await POAPI.getAutoGen(payload);

        if (res?.data?.error === "0") {
            let arr = [];
            let autoGenList = res.data.result;
            let newCate = [...cateItemList];
            autoGenList.forEach((aItem, aIndex) => {
                let checkIndex: any = null;
                cateItemList.forEach((cItem, cIndex) => {
                    if (cItem.cate_id === aItem.consumable_category_id) {
                        checkIndex = cIndex;
                    }
                });
                if (checkIndex === null) {
                    checkIndex = newCate.length - 1;
                }
                if (checkIndex !== null) {
                    let out = "";
                    newCate?.[checkIndex]?.itemList?.forEach((fItem, fIndex) => {
                        if (fItem?.consumable_id === aItem._id) {
                            newCate[checkIndex].itemList[fIndex].add_qty = aItem?.store.need_add;
                        }
                    });
                }
            });
            setCateItemList([...newCate]);
        }
        dispatch(setLoading(false));
    };

    const clickPr = (payload) => {
        const index = selectPR.findIndex((x) => x._id === payload._id);
        if (index !== -1) {
            let temp = [...selectPR];
            temp.splice(index, 1);
            setSelectPR([...temp]);
        } else {
            setSelectPR([...selectPR, payload]);
        }
    };

    const handleNewsSubmit = async (Values: INewsData) => {
        dispatch(setLoading(true));
        try {
            // setTableData(tempVariant);

            if (product) {
                if (hasVariant) {
                    // update with variant
                    const res = await productAPI.update({
                        ...product,
                        organization_code: orgCode,
                        product_code: values.product_code,
                        name: values.title,
                        standard_price: parseInt(values.price),
                        discount_in_baht: parseInt(values.discount),
                        category_id: values.news_category_id,
                        sub_category_id: "",
                        image_url_list: multipleImages,
                        is_variant: hasVariant,
                        variant_name_header: "",
                        description: values.detail,
                        condition: "",
                        text_block_list: [],
                        is_delete: false,
                        is_enable: isActive,
                        _id: product._id,
                        variant_list: tableData,
                        sku_earn_point: values.sku_earn_point || 0,
                        hint_code: values?.hint_code || "",
                        is_share_consumable: false,
                    });
                    if (res.data.error === "0") {
                        success(t("message.update.success"));
                        history.push(PATH_PRODUCT);
                    }
                } else {
                    // update without variant
                    const res = await productAPI.update({
                        ...product,
                        organization_code: orgCode,
                        product_code: values.product_code,
                        name: values.title,
                        standard_price: parseInt(values.price),
                        discount_in_baht: parseInt(values.discount),
                        category_id: values.news_category_id,
                        sub_category_id: "",
                        image_url_list: multipleImages,
                        is_variant: hasVariant,
                        variant_name_header: "",
                        description: values.detail,
                        condition: "",
                        text_block_list: [],
                        is_delete: false,
                        is_enable: isActive,
                        _id: product._id,
                        variant_list: [
                            {
                                variant_code: barcode,
                                variant_name: values.title,
                                is_enable: true,
                                store_list: tableData2,
                                raw_price: parseInt(values.price),
                                dimension_list: [],
                            },
                        ],
                        sku_earn_point: values.sku_earn_point || 0,
                        hint_code: values?.hint_code || "",
                        is_share_consumable: false,
                    });
                    if (res.data.error === "0") {
                        success(t("message.update.success"));
                        history.push(PATH_PRODUCT);
                    }
                }
            } else {
                if (hasVariant) {
                    // create with variant
                    const res = await productAPI.create({
                        ...product,
                        organization_id: organization_id,
                        product_code: values.product_code,
                        name: values.title,
                        standard_price: parseInt(values.price),
                        discount_in_baht: parseInt(values.discount),
                        category_id: values.news_category_id,
                        image_url_list: multipleImages,
                        is_variant: hasVariant,
                        variant_name_header: "",
                        variant_list: tableData,
                        description: values.detail,
                        condition: "",
                        text_block_list: [],
                        is_enable: isActive,
                        //
                        discount_type: values.discount_type,
                        has_barcode: values.has_barcode,
                        active_on_platform: values.active_on_platform,
                        point_deduction: values.point_deduction,
                        maximum_deduction: values.maximum_deduction,
                        point_deduction_value: values.point_deduction_value,
                        hint_code: values.hint_code || "",
                        sku_earn_point: values.sku_earn_point || 0,
                        is_share_consumable: false,
                    });
                    if (res.data.error === "0") {
                        success(t("message.create.success"));
                        history.push(PATH_PRODUCT);
                        setTableData([]);
                        setTempVariant([]);
                        setMultipleImages([]);
                        setFieldValue("product_code", "");
                        setFieldValue("price", "");
                        setFieldValue("discount", "");
                        setFieldValue("news_category_id", "");
                        setFieldValue("detail", "");
                        setFieldValue("image_url", "");
                        setFieldValue("title", "");
                    }
                } else {
                    // create without variant
                    const res = await productAPI.create({
                        ...product,
                        organization_id: organization_id,
                        product_code: values.product_code,
                        name: values.title,
                        standard_price: parseInt(values.price),
                        discount_in_baht: parseInt(values.discount),
                        category_id: values.news_category_id,
                        image_url_list: multipleImages,
                        is_variant: hasVariant,
                        variant_name_header: "",
                        variant_list: [
                            {
                                variant_code: barcode,
                                variant_name: values.title,
                                is_enable: true,
                                store_list: tableData2,
                                raw_price: parseInt(values.price),
                                dimension_list: [],
                            },
                        ],
                        description: values.detail,
                        condition: "",
                        text_block_list: [],
                        is_enable: isActive,
                        //
                        discount_type: values.discount_type,
                        has_barcode: values.has_barcode,
                        active_on_platform: values.active_on_platform,
                        point_deduction: values.point_deduction,
                        maximum_deduction: values.maximum_deduction,
                        point_deduction_value: values.point_deduction_value,
                        hint_code: values.hint_code || "",
                        sku_earn_point: values?.sku_earn_point || 0,
                        is_share_consumable: false,
                    });

                    if (res.data.error === "0") {
                        success(t("message.create.success"));
                        history.push(PATH_PRODUCT);
                        setTableData([]);
                        setTempVariant([]);
                        setMultipleImages([]);
                        setFieldValue("product_code", "");
                        setFieldValue("price", "");
                        setFieldValue("discount", "");
                        setFieldValue("news_category_id", "");
                        setFieldValue("detail", "");
                        setFieldValue("image_url", "");
                        setFieldValue("title", "");
                    }
                }
            }
        } catch (err: any) {
            if (err.response) {
                error(showErrorMessage(err.response));
            } else error(t("message.error"));
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleCancel = () => {
        history.goBack();
    };

    const { values, handleChange, handleBlur, handleSubmit, errors, touched, setFieldValue } =
        useFormik({
            initialValues: initData,
            validationSchema: createNews,
            enableReinitialize: true,
            onSubmit: handleNewsSubmit,
        });

    // create new sgtate update on it state

    const changeFocusStore = (value: string) => {
        setFocusStore(value);
        setShowPR(false);
    };

    // Editable Variant Table
    interface IMyTableData {
        id: string;
        variant_name: string;
        quantity?: string;
        raw_price: number;
        net_price?: number;
        barcode: string;
        status?: boolean;
        is_enable: boolean;
        store_list: [
            {
                store_code: string;
                qty: number;
                is_enable: boolean;
            }
        ];
    }

    interface ITableInv {
        name: string;
        stock: string;
        orderNum: number;
        orderUnit: string;
        index: number;
    }

    const [tempVariant, setTempVariant]: any = useState<IMyTableData[]>([]);
    const [tempBranch, setTempBranch]: any = useState<any>([]);

    const [barcode, setBarcode] = useState<string>("");
    const [tableData, setTableData] = useState(tempVariant);
    const [tableData2, setTableData2] = useState<any>(tempBranch);

    // Branch Tables
    const columnsInvPR: ColumnProps<ITableInv>[] = [
        {
            dataIndex: "name",
            title: `${t("page.purchase_order.InvQ_row_name")}`,
            key: "name",
            width: "50%",
            render: (text, record, index) => (
                <>
                    <div style={{ placeItems: "center", display: "flex", fontSize: "14px" }}>
                        {text}
                    </div>
                </>
            ),
        },
        {
            dataIndex: "add_qty",
            width: "50%",
            key: "qty",
            title: `${t("page.purchase_order.InvQ_row_order")}`,
            align: "right",
            render: (text, record, index) => (
                <div className="quantity-columns">
                    <span
                        onClick={() => {
                            changeQty("minus", index);
                        }}
                    >
                        <IconMinusCircle
                            color={`${cateItemList?.[focusCate || 0]?.itemList?.[record?.index]?.[
                                "add_qty"
                            ] === 0 ||
                                cateItemList?.[focusCate || 0]?.itemList?.[record?.index]?.[
                                "add_qty"
                                ] === ""
                                ? "#A5A5A5"
                                : "black"
                                }`}
                        />
                    </span>

                    <Input
                        value={text}
                        onChange={onInputChange2("add_qty", record?.index)}
                        onBlur={inputBlur(record?.index)}
                    />
                    <span
                        onClick={() => {
                            changeQty("add", record?.index);
                        }}
                    >
                        <IconAddCircle color="black" />
                    </span>
                    <p>{cateItemList?.[focusCate || 0]?.itemList?.[record?.index]?.unit}&nbsp;</p>
                    {/* <span
                        onClick={() => {
                            let arr = consumeList.filter((x, xIndex) => xIndex !== index);
                            setConsumeList([...arr]);
                        }}
                    >
                        <IconDelete />
                    </span> */}
                </div>
            ),
        },
    ];

    const columnsInv: ColumnProps<ITableInv>[] = [
        {
            dataIndex: "name",
            title: `${t("page.purchase_order.InvQ_row_name")}`,
            key: "name",
            width: "30%",
            render: (text, record, index) => (
                <>
                    <div style={{ placeItems: "center", display: "flex", fontSize: "14px" }}>
                        {text}
                    </div>
                </>
            ),
        },
        {
            dataIndex: "code",
            title: `${t("page.purchase_order.InvQ_row_code")}`,
            key: "code",
            width: "20%",
            render: (text, record, index) => (
                <>
                    <div style={{ placeItems: "center", display: "flex", fontSize: "14px" }}>
                        {text}
                    </div>
                </>
            ),
        },
        {
            dataIndex: "on_stork",
            title: `${t("page.purchase_order.InvQ_row_stock")}`,
            key: "name",
            width: "30%",
            render: (text, record, index) => (
                <>
                    <div style={{ fontSize: "14px", textAlign: "center" }}>
                        {Number.isInteger(text) ? text.toString() : text.toFixed(2)}{" "}
                        {cateItemList?.[focusCate || 0]?.itemList?.[record?.index]?.unit}
                    </div>
                </>
            ),
        },
        {
            dataIndex: "delivery_qty",
            title: `${t("page.purchase_order.conversion_delivery")}`,
            key: "delivery_qty",
            width: "20%",
            render: (text, record, index) => (
                <>
                    <div style={{ fontSize: "14px", textAlign: "left" }}>
                        1 delivery unit = {text}{" "}
                        {cateItemList?.[focusCate || 0]?.itemList?.[record?.index]?.unit}
                    </div>
                </>
            ),
        },
        {
            dataIndex: "delivery_qty",
            title: `${t("page.purchase_order.conversion_restock")}`,
            key: "delivery_qty",
            width: "20%",
            render: (text, record, index) => (
                <>
                    <div style={{ fontSize: "14px", textAlign: "left" }}>
                        1 restock unit = {text}{" "}
                        {cateItemList?.[focusCate || 0]?.itemList?.[record?.index]?.unit}
                    </div>
                </>
            ),
        },
        {
            dataIndex: "add_qty",
            width: "40%",
            key: "qty",
            title: `${t("page.purchase_order.InvQ_row_order")}`,
            align: "right",
            render: (text, record, index) => (
                <div className="quantity-columns">
                    <span
                        onClick={() => {
                            changeQty("minus", record?.index);
                        }}
                    >
                        <IconMinusCircle
                            color={`${cateItemList?.[focusCate || 0]?.itemList?.[record?.index][
                                "add_qty"
                            ] === 0 ||
                                cateItemList?.[focusCate || 0]?.itemList?.[record?.index][
                                "add_qty"
                                ] === ""
                                ? "#A5A5A5"
                                : "black"
                                }`}
                        />
                    </span>

                    <Input
                        value={text}
                        onChange={onInputChange2("add_qty", record?.index)}
                        onBlur={inputBlur(record?.index)}
                    />
                    <span
                        onClick={() => {
                            changeQty("add", record?.index);
                        }}
                    >
                        <IconAddCircle color="black" />
                    </span>
                    <p>{cateItemList?.[focusCate || 0]?.itemList?.[record?.index].unit}&nbsp;</p>
                    {/* <span
                        onClick={() => {
                            let arr = consumeList.filter((x, xIndex) => xIndex !== index);
                            setConsumeList([...arr]);
                        }}
                    >
                        <IconDelete />
                    </span> */}
                </div>
            ),
        },
    ];

    const changeQty = (action: string, index) => {
        const newData = [...cateItemList];
        if (action === "minus") {
            if (newData[focusCate || 0]?.itemList[index].add_qty > 0) {
                let minusQty =
                    newData[focusCate || 0]?.itemList[index]?.add_qty -
                    newData[focusCate || 0]?.itemList[index]?.delivery_qty;
                if (minusQty < 0) {
                    minusQty = 0;
                }

                newData[focusCate || 0]["itemList"][index]["add_qty"] = minusQty;
            }
        } else if (action === "add") {
            let addQty =
                newData[focusCate || 0]?.itemList[index]?.add_qty +
                newData[focusCate || 0]?.itemList[index]?.delivery_qty;
            newData[focusCate || 0]["itemList"][index]["add_qty"] = parseInt(addQty);
        }
        setCateItemList(newData);
    };

    const onInputChange2 = (key, index) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const newData = [...cateItemList];
        const re = /^[0-9\b]+$/;

        if (re.test(e.target.value)) {
            newData[focusCate || 0]["itemList"][index]["add_qty"] = Number(e.target.value);
        } else if (e.target.value === "") {
            newData[focusCate || 0]["itemList"][index]["add_qty"] = "";
        }
        setCateItemList(newData);
    };

    const inputBlur = (index) => (e: React.ChangeEvent<HTMLInputElement>) => {
        let newData = [...cateItemList];
        if (Number(e.target.value) % newData[focusCate || 0].itemList[index].delivery_qty !== 0) {
            warning("please check delivery quantity");
            newData[focusCate || 0]["itemList"][index]["add_qty"] = 0;
            setCateItemList(newData);
        }
    };

    const step1next = async () => {
        if (showPR) {
            if (selectPR.length > 0) {
                let idArr = [];
                selectPR?.forEach((sItem) => {
                    //@ts-ignore
                    idArr.push(sItem?._id);
                });
                let res = await POAPI.getSumConsumeByTPR({
                    organization_id: organization_id || "",
                    tpr_id_list: idArr,
                });
                if (res.data.error === "0") {
                    let inPr = res.data.result;
                    let newCate = [...cateItemList];
                    inPr.forEach((aItem, aIndex) => {
                        let checkIndex: any = null;

                        cateItemList.forEach((cItem, cIndex) => {
                            let c_id: any = (cItem.cate_id === "" ? null : cItem.cate_id);
                            if (c_id === aItem.consumable_category_id) {
                                checkIndex = cIndex;
                            }
                            if (checkIndex === null) {
                                checkIndex = newCate.length - 1;
                            }
                            if (checkIndex !== null) {
                                newCate?.[checkIndex]?.itemList?.forEach((fItem, fIndex) => {
                                    if (fItem?.consumable_id === aItem.consumable_id) {
                                        newCate[checkIndex].itemList[fIndex].add_qty =
                                            aItem?.restock_qty;
                                    }
                                });
                            }
                        });
                    });
                    let filterCate: any[] = [];
                    let filterCateList: any[] = cateList;
                    newCate.forEach((cItem, cIndex) => {
                        if (cItem?.itemList?.length > 0) {
                            let filterItem: any[] = [];
                            cItem?.itemList?.forEach((aItem, aIndex) => {
                                const findIndex = inPr.findIndex(pItem => pItem?.consumable_id === aItem.consumable_id);
                                if (findIndex !== -1) {
                                    if (inPr[findIndex].restock_qty > 0) {
                                        filterItem.push({
                                            index: filterItem.length,
                                            add_qty: inPr[findIndex].restock_qty,
                                            consumable_id: aItem?.consumable_id,
                                            delivery_qty: aItem?.delivery_qty,
                                            name: aItem?.name,
                                            on_stork: aItem?.on_stork,
                                            restock_price: aItem?.restock_price,
                                            unit: aItem?.unit,
                                        });
                                    }
                                }
                                // if (aItem?.add_qty > 0) {
                                //     filterItem.push({ ...aItem, index: filterItem.length });
                                // }
                            });
                            if (filterItem.length === 0) {
                                filterCateList = filterCateList.filter((bItem) => bItem._id !== cItem.cate_id);
                            } else {
                                filterCate.push({ ...cItem, itemList: filterItem });
                            }
                        } else {
                            filterCateList = filterCateList.filter((bItem) => bItem._id !== cItem.cate_id);
                            // filterCate = filterCate.filter((bItem) => bItem.cate_id !== cItem.cate_id);
                        }
                    });
                    setCateItemList([...filterCate]);
                    setCateList(filterCateList);

                    setShowPR(false);
                }
            } else {
                setShowPR(false);
                setConsumeList([]);
            }
            // setShowPR(false);
            // setConsumeList([]);
        } else {
            let arr: any = [];
            let consume_arr: string[] = [];
            let new_consume: any[] = [];
            cateItemList.forEach((cItem: any) => {
                cItem?.itemList?.forEach((fItem: any) => {
                    if (fItem?.add_qty > 0) {
                        arr.push({
                            consumable_id: fItem?.consumable_id,
                            restock_qty: fItem?.add_qty,
                        });
                        new_consume.push(fItem);
                    }
                });
            });

            arr.forEach((element: any) => {
                consume_arr.push(element.consumable_id);
            });
            setConsumeList(arr);

            if (arr.length === 0) {
                warning("Please add to order");
            } else {
                const payload: IPostSupByConsumeList = {
                    organization_id: userInfo?.merchant?._id || "",
                    consumable_list: consume_arr,
                };
                try {
                    const res = await POAPI.getSupByConsumeList(payload);
                    if (res.data.error === "0") {
                        const array1 = new_consume;
                        const array2 = res.data.result;
                        const supArr: any[] = [];

                        const combinedArray = array1.reduce((acc: any[], obj1: any) => {
                            const obj2 = array2.find((obj2: any) => obj1.name === obj2.name);
                            if (obj2) {
                                acc.push({ ...obj1, ...obj2 });
                            } else {
                                acc.push(obj1);
                            }
                            return acc;
                        }, []);
                        setSupplierList(combinedArray);
                        new_consume.forEach((element: any, index: number) => {
                            supArr.push({
                                supplier_id: null,
                                remark: "",
                                supplier_name: "no supplier",
                                consumable_list: [
                                    {
                                        consumable_id: element?.consumable_id,
                                        restock_price: element?.restock_price,
                                        restock_qty: element?.add_qty,
                                    },
                                ],
                            });
                        });
                        setSelectSup(supArr);
                        setStep(2);
                    } else {
                        warning(res.data.msg);
                    }
                } catch (err) {
                    console.error(err);
                }
            }
        }
    };
    const step2next = () => {
        // setSupNoteList([]);
        const arr: any = {};
        selectSup.forEach((sItem) => {
            if (arr[sItem.supplier_id]) {
                arr[sItem.supplier_id].consumable_list.push(...sItem.consumable_list);
            } else {
                arr[sItem.supplier_id] = sItem;
            }
        });

        const mergeArray = Object.values(arr);

        setSelectSup(mergeArray);
        setStep(3);
    };
    const loading = useSelector(selectApp).loading;
    const step3next = async () => {
        try {
            dispatch(setLoading(true));

            let tprArr: string[] = [];
            selectPR.forEach((element) => {
                tprArr.push(element?._id);
            });
            const payload: IPostCreatePO = {
                organization_id: userInfo?.merchant?._id || "",
                api_type: "do",
                store_id: focusStore,
                supplier_list: selectSup,
                tpr_id_list: tprArr,
            };
            try {
                const res = await POAPI.postCreatePO(payload);
                if (res.data.error === "0") {
                    setResultData(res.data.result);
                    setResult2Data(res.data.result2);
                    //
                } else {
                    store.dispatch(
                        openToast({
                            message: res.data.msg,
                            type: "error",
                            autoHideDuration: 3000,
                        })
                    );
                }
            } catch (e) {
                console.error(e);
            }
            setStep(4);
        } catch (err: any) {
            console.error(err);
        } finally {
            dispatch(setLoading(false));
        }
    };
    const backStep1next = () => {
        if (tprList.length > 0 && !showPR) {
            setShowPR(true);
        } else {
            handleCancel();
        }
    };
    const backStep2next = () => {
        setStep(1);
    };
    const backStep3next = () => {
        setStep(2);
    };
    const savePO = () => {
        history.push(PATH_PURCHASE_ORDER);
    };
    const clickSup = (eItem: any, cIndex: number) => {
        const arr = selectSup;
        arr[cIndex].supplier_id = eItem?.supplier_id;
        arr[cIndex].consumable_list[0].restock_price = eItem.restock_unit_price;
        arr[cIndex].supplier_name = eItem.supplier_name;
        setSelectSup([...arr]);
    };
    const changeSupprice = (e: any, eItem: any, cIndex: number, eIndex: number) => {
        if (!isNaN(+e.target.value)) {
            const supArr = supplierList;
            const arr = selectSup;

            arr[cIndex].supplier_id = eItem?.supplier_id;
            if (e.target.value !== "") {
                supArr[cIndex].supplier_list[eIndex].restock_unit_price = parseFloat(
                    parseFloat(e.target.value).toFixed(2)
                );
                arr[cIndex].consumable_list[0].restock_price = parseFloat(
                    parseFloat(e.target.value).toFixed(2)
                );
            } else {
                supArr[cIndex].supplier_list[eIndex].restock_unit_price = e.target.value;
                arr[cIndex].consumable_list[0].restock_price = 0;
            }
            setSupplierList([...supArr]);
            setSelectSup([...arr]);
        }
    };
    const changeSupNote = (e: any, sItem: any, sIndex: number) => {
        const selectArr = selectSup;
        selectArr[sIndex].remark = e.target.value;
        setSelectSup([...selectArr]);
    };
    // Has Barcode Section
    return (
        <div className="form-wrap">
            <form onSubmit={handleSubmit}>
                <div className="page-header">
                    <h3>
                        {pathName === PATH_PURCHASE_ORDER_CREATE
                            ? t("page.purchase_order.create_PO_title")
                            : t("page.purchase_order.edit_PO_title")}
                    </h3>
                </div>
                <div className="page-body">
                    <div className="card-wrap">
                        {step === 1 && (
                            <div className="select-branch-section">
                                <p> {t("page.branch")} :</p>
                                <Select
                                    showSearch
                                    filterOption={(input, option) =>
                                        //@ts-ignore
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    style={{ width: "100%", height: 48 }}
                                    placeholder={t("page.store")}
                                    onChange={changeFocusStore}
                                    defaultActiveFirstOption={true}
                                    value={focusStore}
                                    disabled={consumeList.length > 0}
                                >
                                    {adminPermission?.pos_role.store_access_type === "all_store" ||
                                        adminPermission?.pos_role.store_access_type === undefined
                                        ? Stores?.length !== 0 &&
                                        Stores?.map((store: any, index: number) => {
                                            return (
                                                <Option key={index} value={store?._id}>
                                                    {store?.store_name}
                                                </Option>
                                            );
                                        })
                                        : adminPermission?.store_ids?.map(
                                            (store: any, index: number) => {
                                                return (
                                                    <Option key={index} value={store?._id}>
                                                        {store?.store_name}
                                                    </Option>
                                                );
                                            }
                                        )}
                                </Select>
                            </div>
                        )}
                        <div className="card-wrap">
                            <div className="step-section">
                                <Row>
                                    <Col
                                        span={6}
                                        className={`col-item start border-right ${step === 1 ? "active" : ""
                                            }`}
                                    >
                                        <Step1Icon color={step === 1 ? "#f4394f" : "#ff7f8e"} />
                                        <div className="step-text ">
                                            <p className="step-number">
                                                {" "}
                                                {t("page.purchase_order.step")} 1/4
                                            </p>
                                            <p className="step-title">
                                                {t("page.purchase_order.step_one_title")}
                                            </p>
                                        </div>
                                    </Col>
                                    <Col
                                        span={6}
                                        className={`col-item border-right ${step === 2 ? "active" : ""
                                            }`}
                                    >
                                        <Step2Icon color={step === 2 ? "#f4394f" : "#ff7f8e"} />
                                        <div className="step-text ">
                                            <p className="step-number">
                                                {" "}
                                                {t("page.purchase_order.step")} 2/4
                                            </p>
                                            <p className="step-title">
                                                {t("page.purchase_order.step_two_title")}
                                            </p>
                                        </div>
                                    </Col>
                                    <Col
                                        span={6}
                                        className={`col-item border-right ${step === 3 ? "active" : ""
                                            }`}
                                    >
                                        <Step3Icon color={step === 3 ? "#f4394f" : "#ff7f8e"} />
                                        <div className="step-text ">
                                            <p className="step-number">
                                                {" "}
                                                {t("page.purchase_order.step")} 3/4
                                            </p>
                                            <p className="step-title">
                                                {t("page.purchase_order.step_three_title")}
                                            </p>
                                        </div>
                                    </Col>
                                    <Col
                                        span={6}
                                        className={`col-item end ${step === 4 ? "active" : ""}`}
                                    >
                                        <Step4Icon color={step === 4 ? "#f4394f" : "#ff7f8e"} />
                                        <div className="step-text">
                                            <p className="step-number">
                                                {" "}
                                                {t("page.purchase_order.step")} 4/4
                                            </p>
                                            <p className="step-title">
                                                {t("page.purchase_order.step_four_title")}
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        {step === 1 && focusStore !== "" && (
                            <div className="card-wrap">
                                <CustomStyles>
                                    {showPR ? (
                                        <StyledCard maxWidth="100%">
                                            <div className="title" style={{ fontSize: "25px" }}>
                                                {t("page.purchase_order.select_pr_title")}
                                            </div>
                                            <div className="table-header">
                                                <Row
                                                    gutter={24}
                                                    type="flex"
                                                    justify="space-between"
                                                    align="middle"
                                                    className="header-row"
                                                >
                                                    <Col className="col-item" span={12}>
                                                        <p>
                                                            {t(
                                                                "page.purchase_request.table_PR_number"
                                                            )}
                                                        </p>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div className="table-body">
                                                {tprList?.map((pItem, pIndex) => {
                                                    return (
                                                        <div
                                                            className="table-item"
                                                            key={pIndex}
                                                            onClick={() => {
                                                                clickPr(pItem);
                                                            }}
                                                        >
                                                            <Checkbox
                                                                checked={selectPR.some(
                                                                    (x) => x._id === pItem?._id
                                                                )}
                                                            />
                                                            <p className="tpr-number">
                                                                {pItem?.tpr_no}
                                                            </p>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </StyledCard>
                                    ) : (
                                        <StyledCard maxWidth="100%">
                                            <div className="title" style={{ fontSize: "25px" }}>
                                                {t("page.purchase_order.inventory_qr_title")}
                                            </div>
                                            <CustomTabs>
                                                <div className="tab-section">
                                                    {cateList?.map((cItem: any, cIndex: number) => {
                                                        return (
                                                            <Button
                                                                className={`tab-btn ${cIndex === focusCate
                                                                    ? "active"
                                                                    : ""
                                                                    }`}
                                                                onClick={() => {
                                                                    setFocusCate(cIndex);
                                                                }}
                                                                key={cIndex}
                                                            >
                                                                {cItem?.category}
                                                            </Button>
                                                        );
                                                    })}
                                                    {/* {cateItemList?.map((cItem: any, cIndex: number) => {
                                                        if (cItem.itemList.length > 0) {
                                                            return (
                                                                <Button
                                                                    className={`tab-btn ${cIndex === focusCate
                                                                        ? "active"
                                                                        : ""
                                                                        }`}
                                                                    onClick={() => {
                                                                        setFocusCate(cIndex);
                                                                    }}
                                                                    key={cIndex}
                                                                >
                                                                    {cItem?.category}
                                                                </Button>
                                                            );
                                                        }

                                                    })} */}
                                                </div>
                                            </CustomTabs>

                                            {selectPR?.length === 0 && (
                                                <div className="add-btn-section">
                                                    <StyledTableButtonAddRow
                                                        type="danger"
                                                        size="default"
                                                        text={t("page.purchase_order.auto_gen_btn")}
                                                        onClick={clickAutoGen}
                                                        className="add-btn auto-gen-btn"
                                                        icon="sync"
                                                    />
                                                </div>
                                            )}
                                            {selectPR.length > 0 &&
                                                <div className="add-btn-section">
                                                    <StyledTableButtonAddRow
                                                        type="danger"
                                                        size="default"
                                                        text={t(
                                                            "page.purchase_order.add_inventory_btn"
                                                        )}
                                                        onClick={() => setShowPopup(true)}
                                                        className="add-btn"
                                                        icon="plus"
                                                    />
                                                </div>
                                            }
                                            <div className="pr-search">
                                                <p>{t("page.search")} :</p>
                                                {/* <Input onChange={(e) => handleFilter(e, focusCate)} /> */}
                                                <Input
                                                    value={textFilter}
                                                    onChange={(e) => setTextFilter(e.target.value)}
                                                />
                                            </div>
                                            <Row>
                                                <div className="branchListSection">
                                                    <Col md={24}>
                                                        <Table
                                                            rowKey="id"
                                                            columns={
                                                                selectPR?.length === 0
                                                                    ? columnsInv
                                                                    : columnsInvPR
                                                            }
                                                            dataSource={
                                                                cateItemList[focusCate || 0]
                                                                    ?.itemList
                                                            }
                                                            rowClassName={(record) => {
                                                                if (
                                                                    !record?.name
                                                                        .toLowerCase()
                                                                        .includes(
                                                                            textFilter.toLowerCase()
                                                                        )
                                                                    && !record?.code.toLowerCase()
                                                                        ?.includes(
                                                                            textFilter.toLowerCase()
                                                                        )
                                                                ) {
                                                                    return "hide-row";
                                                                } else {
                                                                    return "";
                                                                }
                                                            }}
                                                            pagination={false}
                                                        // pagination={{ defaultPageSize: 2 }}
                                                        // bordered
                                                        />
                                                    </Col>
                                                </div>
                                            </Row>
                                        </StyledCard>
                                    )}
                                </CustomStyles>
                                <div className="btn-layout">
                                    <StyledCancelButton
                                        type="sub"
                                        text={t("page.back")}
                                        htmlType="button"
                                        onClick={backStep1next}
                                    />
                                    <StyledSubmitButton
                                        type="default"
                                        text={t("page.next")}
                                        htmlType="submit"
                                        onClick={step1next}
                                    />
                                </div>
                            </div>
                        )}

                        {step === 2 && (
                            <div className="card-wrap">
                                <CustomStyles>
                                    <StyledCard maxWidth="100%">
                                        <div className="title" style={{ fontSize: "25px" }}>
                                            {t("page.purchase_order.inventory_select_supplier")}
                                        </div>
                                        <Row className="table-select-supplier">
                                            <Col span={6} className="table-head-1">
                                                {t("page.purchase_order.step2_inventory")}
                                            </Col>
                                            <Col span={18} className="table-head-2">
                                                {t("page.purchase_order.step2_bath")}
                                            </Col>
                                            {supplierList?.map((sItem: any, cIndex: number) => {
                                                return (
                                                    <Row className="row-sup" key={cIndex}>
                                                        <Col span={6}>
                                                            <p className="item-name">
                                                                {sItem?.name}: {sItem?.add_qty}{" "}
                                                                {sItem?.unit}
                                                            </p>
                                                        </Col>
                                                        <Col span={18}>
                                                            {sItem?.supplier_list?.map(
                                                                (eItem: any, eIndex: number) => {
                                                                    return (
                                                                        <div
                                                                            className="sup-card"
                                                                            onClick={() => {
                                                                                clickSup(
                                                                                    eItem,
                                                                                    cIndex
                                                                                );
                                                                            }}
                                                                            key={eIndex}
                                                                        >
                                                                            <p>
                                                                                <Radio
                                                                                    checked={
                                                                                        selectSup[
                                                                                            cIndex
                                                                                        ]
                                                                                            .supplier_id ===
                                                                                        eItem.supplier_id
                                                                                    }
                                                                                />
                                                                                {eItem.supplier_name ===
                                                                                    "no supplier"
                                                                                    ? t(
                                                                                        "page.purchase_order.no_sup"
                                                                                    )
                                                                                    : eItem.supplier_name}
                                                                            </p>
                                                                            {vatDetail?.is_show_cost_on_po && (
                                                                                <Input
                                                                                    className="price-input"
                                                                                    prefix="฿"
                                                                                    size="small"
                                                                                    value={
                                                                                        eItem.restock_unit_price
                                                                                    }
                                                                                    onChange={(
                                                                                        e
                                                                                    ) => {
                                                                                        changeSupprice(
                                                                                            e,
                                                                                            eItem,
                                                                                            cIndex,
                                                                                            eIndex
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    );
                                                                }
                                                            )}
                                                        </Col>
                                                    </Row>
                                                );
                                            })}
                                        </Row>
                                    </StyledCard>
                                </CustomStyles>
                                <div className="btn-layout">
                                    <StyledCancelButton
                                        type="sub"
                                        text={t("page.back")}
                                        htmlType="button"
                                        onClick={backStep2next}
                                    />
                                    <StyledSubmitButton
                                        type="default"
                                        text={t("page.next")}
                                        htmlType="submit"
                                        onClick={step2next}
                                    />
                                </div>
                            </div>
                        )}
                        {step === 3 && (
                            <div className="card-wrap">
                                <CustomStyles>
                                    <StyledCard maxWidth="100%">
                                        <div className="title" style={{ fontSize: "25px" }}>
                                            {t("page.purchase_order.inventory_specify_supplier")}
                                        </div>

                                        {selectSup?.map((sItem: any, sIndex: number) => {
                                            return (
                                                <div className="spec-sup-card" key={sIndex}>
                                                    <div className="name-section">
                                                        <p>{sItem.supplier_name}</p>
                                                    </div>
                                                    <div className="note-section">
                                                        <p>{t("page.purchase_order.note")}</p>
                                                        <TextArea
                                                            maxLength={100}
                                                            onChange={(e) => {
                                                                changeSupNote(e, sItem, sIndex);
                                                            }}
                                                            value={sItem.remark}
                                                            style={{ height: 120, resize: "none" }}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </StyledCard>
                                </CustomStyles>
                                <div className="btn-layout">
                                    <StyledCancelButton
                                        type="sub"
                                        text={t("page.back")}
                                        htmlType="button"
                                        onClick={backStep3next}
                                    />
                                    <StyledSubmitButton
                                        type="default"
                                        text={t("page.purchase_order.create")}
                                        htmlType="submit"
                                        onClick={step3next}
                                    />
                                </div>
                            </div>
                        )}
                        {step === 4 && (
                            <div className="card-wrap">
                                <CustomStyles>
                                    <StyledCard maxWidth="100%">
                                        <div className="title" style={{ fontSize: "25px" }}>
                                            {t("page.purchase_order.inventory_PO_preview")}
                                        </div>
                                        {/* <div>
                                            <div>
                                                <ComponentPOPaper
                                                    data={testStep4}
                                                    type={"PURCHASE ORDER"}
                                                    storeDetail={testStep4}
                                                />
                                            </div>
                                        </div> */}

                                        {resultData?.map((rItem: any, rIndex: number) => {
                                            return (
                                                <div
                                                    // className="PO-preview"
                                                    key={rIndex}
                                                >
                                                    <div>
                                                        <ComponentPOPaper
                                                            data={rItem}
                                                            type="PURCHASE ORDER"
                                                            storeDetail={result2Data}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </StyledCard>
                                </CustomStyles>
                                <div className="btn-layout">
                                    <StyledSubmitButton
                                        type="default"
                                        text={t("page.finish")}
                                        htmlType="submit"
                                        onClick={savePO}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </form >

            <ComponentPOModal
                store_id={focusStore}
                showPopup={showPopup}
                handleClose={() => setShowPopup(!showPopup)}
                addConsume={addConsume}
                cate_id={cateItemList?.[focusCate]?.cate_id}
                type={itemType}
            />
        </div >
    );
});
const CustomStyles = styled.div`
    .ant-table-row {
        font-size: 16px;
    }
    .branchListTitle {
        font-size: 16px;
        font-weight: 700;
        color: #000;
    }
    .branchListSection {
        margin-top: 20px;
        td {
            padding: 0px;
            padding-top: 5px;
        }
    }
    .branchListSection .antd-btn-custom {
        margin-left: 0;
    }
    .selectBranchBox {
        position: absolute;
        background: #fff;
        margin-top: 10px;
        z-index: 999;
    }
    .ant-checkbox-wrapper {
        width: 100%;
    }
    .table-header {
        text-transform: uppercase;
        /* color: #A5A5A5; */
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 0;
        margin-right: 10px;
    }
    .table-item {
        padding: 10px 18px;
        border-radius: 1px;
        width: 100%;
        min-width: 680px;

        height: 65px;
        transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
        border-radius: 6px;
        background-color: white;
        margin-bottom: 15px;
        border: 1px solid lightgray;
        display: flex;
        .tpr-number {
            font-size: 16px;
            font-weight: 600;
            padding-top: 8px;
            cursor: pointer;
        }
    }
    .table-item:hover {
        /* background: #0263e0; */
        box-shadow: 0 4px 12px rgba(2, 99, 224, 0.5) !important;
    }
`;

const CustomTabs = styled.div`
    .tab-section {
        margin: 15px 0px;
        .tab-btn {
            background: none;
            border: none;
            font-weight: 700;
            font-size: 16px;
            padding: 0px;
            padding-bottom: 20px;
            margin-right: 30px;
        }
        .tab-btn:hover {
            color: #f4394f;
        }
        .active {
            color: #f4394f;
            border-bottom: 4px solid #f4394f !important;
        }
    }
`;
