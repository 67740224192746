import { useTranslation } from "react-i18next";
import {
    SharedComponentDrawer,
    SharedInput,
    SharedModal,
    StyledCancelButton,
    StyledSubmitButton,
} from "../shared";
import styled from "styled-components";
import { enumValidation, IMAGE_TYPE } from "@configs";
import { useNotify } from "@utils";
import axios from "axios";
import { useEffect, useState } from "react";
import { Avatar, Button, Input, Select, Tooltip } from "antd";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import { InfoIcon, IconCamera } from "@components";
import { useFormik } from "formik";
import {
    IGetConsumableStoreId,
    IGetSupplierList,
    INews,
    INewsCategory,
    INewsData,
} from "@interfaces";
import * as Yup from "yup";
import { useYup } from "@validations";
import React from "react";
import { POAPI, supplierAPI } from "@api";
import { useSelector } from "react-redux";
import { selectAuth } from "@redux";

interface IProps {
    showPopup: boolean;
    handleClose: () => void;
    addSup: (payload: ISupPayload) => void;
    price: number;
}

interface ISupPayload {
    supplier_id: string;
    restock_unit_price: number;
    supplier_name: string;
    _id: string;
}

export const ComponentSearchSupModal = (props: IProps) => {
    const { showPopup, handleClose, price, addSup } = props;
    const { t } = useTranslation();
    const [itemName, setItemName] = useState<string>("");
    const [supList, setSupList] = useState<any>([]);

    const { userInfo } = useSelector(selectAuth);

    // form inital data

    useEffect(() => {
        if (!showPopup) {
            setSupList([]);
        }
    }, [showPopup]);
    const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setItemName(e.target.value);
    };
    const onSearch = async () => {
        const params: IGetSupplierList = {
            organization_id: userInfo?.merchant?._id || "",
            xpage: 1,
            xlimit: 40,
            text_search: itemName,
        };
        const res = await supplierAPI.getList(params);
        if (res.data.error === "0") {
            setSupList(res?.data?.result);
        }
    };
    const selectConsume = (cItem: any) => {
        const payload = {
            supplier_id: cItem?._id,
            restock_unit_price: price,
            supplier_name: cItem.supplier_name,
            _id: "",
        };
        addSup(payload);
        handleClose();
    };

    return (
        <SharedModal
            showPopup={showPopup}
            handleClose={handleClose}
            title={t("page.consume.search_supplier")}
            width={600}
        >
            <StyledModalContent>
                <Input
                    className="item-name"
                    suffix={<SearchOutlined onClick={onSearch} />}
                    onChange={handleChangeName}
                    onKeyPress={(e) => {
                        if (e.key === "Enter") {
                            onSearch();
                        }
                    }}
                    value={itemName}
                />
                {supList?.length > 0 && (
                    <div className="consume-list-section consume">
                        {supList?.map((cItem: any, cIndex: number) => {
                            return (
                                <div
                                    key={cIndex}
                                    className="consume-item"
                                    onClick={() => {
                                        selectConsume(cItem);
                                    }}
                                >
                                    {cItem?.supplier_name}
                                </div>
                            );
                        })}
                    </div>
                )}
            </StyledModalContent>
        </SharedModal>
    );
};

const StyledModalContent = styled.div`
    .item-name {
        height: 45px;
    }

    .consume {
        background-color: #f7f7f7;
        padding: 10px 15px;
        margin-top: 30px;
        border-radius: 8px;

        .consume-item {
            cursor: pointer;
            background-color: white;
            border: 1px solid #d7d7d7;
            border-radius: 8px;
            margin: 15px 0px;
            padding: 15px;
            color: black;
            font-size: 18px;
        }
    }
`;
