import { ISvg } from "@interfaces";

export const Step4Icon = (props: ISvg) => (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="56" height="56" rx="28" fill={props.color || "#0263E0"} />
        <path
            d="M38.7179 25.6749C38.339 25.4546 37.9986 25.174 37.7099 24.8442C37.7391 24.385 37.8485 23.9345 38.0331 23.513C38.3726 22.5552 38.7564 21.4702 38.1404 20.6267C37.5244 19.7832 36.3613 19.81 35.3404 19.8334C34.8893 19.8798 34.4337 19.849 33.9929 19.7424C33.7581 19.3603 33.5904 18.9408 33.4971 18.5022C33.2078 17.5164 32.8776 16.4022 31.8638 16.0685C30.8861 15.7535 29.9808 16.4465 29.1804 17.0555C28.8351 17.3709 28.4349 17.6204 27.9998 17.7917C27.56 17.6218 27.1554 17.3722 26.8063 17.0555C26.0083 16.45 25.1064 15.75 24.1241 16.0697C23.1126 16.3987 22.7824 17.5164 22.4908 18.5022C22.3976 18.9394 22.2315 19.3579 21.9996 19.74C21.558 19.8464 21.1018 19.8779 20.6498 19.8334C19.6254 19.8054 18.4716 19.775 17.8498 20.6267C17.2279 21.4784 17.6164 22.5552 17.9571 23.5119C18.1443 23.9327 18.2553 24.3834 18.2849 24.843C17.9968 25.1733 17.6568 25.4543 17.2781 25.6749C16.4241 26.2582 15.4546 26.922 15.4546 28C15.4546 29.078 16.4241 29.7395 17.2781 30.3252C17.6567 30.5454 17.9968 30.826 18.2849 31.1559C18.2584 31.6153 18.1506 32.0664 17.9664 32.4882C17.6281 33.4449 17.2454 34.5299 17.8603 35.3734C18.4751 36.2169 19.6348 36.19 20.6603 36.1667C21.1117 36.1202 21.5678 36.151 22.0089 36.2577C22.2427 36.6402 22.41 37.0595 22.5036 37.4979C22.7929 38.4837 23.1231 39.5979 24.1369 39.9315C24.2995 39.9836 24.4691 40.0104 24.6398 40.0109C25.4602 39.8931 26.2227 39.52 26.8191 38.9445C27.1644 38.6291 27.5646 38.3796 27.9998 38.2084C28.4395 38.3783 28.8441 38.6278 29.1933 38.9445C29.9924 39.5547 30.8978 40.2512 31.8766 39.9304C32.8881 39.6014 33.2183 38.4837 33.5099 37.499C33.6034 37.061 33.7707 36.642 34.0046 36.26C34.4445 36.1529 34.8993 36.1214 35.3498 36.1667C36.3741 36.1912 37.5279 36.225 38.1498 35.3734C38.7716 34.5217 38.3831 33.4449 38.0424 32.487C37.8565 32.0666 37.7456 31.6169 37.7146 31.1582C38.0028 30.8276 38.3433 30.5466 38.7226 30.3264C39.5766 29.743 40.5461 29.078 40.5461 28C40.5461 26.922 39.5731 26.2594 38.7179 25.6749Z"
            fill="white"
        />
        <path
            d="M26.833 31.2084C26.7181 31.2086 26.6043 31.186 26.4981 31.142C26.392 31.0979 26.2956 31.0332 26.2147 30.9517L23.8813 28.6184C23.7268 28.4525 23.6426 28.2331 23.6466 28.0064C23.6506 27.7798 23.7425 27.5635 23.9028 27.4032C24.0631 27.2428 24.2794 27.151 24.506 27.147C24.7327 27.143 24.9521 27.2272 25.118 27.3817L26.9147 29.1784L30.9747 26.1334C31.1603 25.9941 31.3937 25.9344 31.6234 25.9672C31.8531 26 32.0604 26.1227 32.1997 26.3084C32.3389 26.494 32.3987 26.7274 32.3659 26.9571C32.333 27.1869 32.2103 27.3941 32.0247 27.5334L27.358 31.0334C27.2065 31.1469 27.0223 31.2083 26.833 31.2084Z"
            fill={props.color || "#0263E0"}
        />
    </svg>
);
