import { memo, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Tooltip } from "antd";

import { SharedButtonSub, SharedInput, IconCopy, IConDown, StyledCard } from "../";
import { copyText, downloadImage } from "@utils";
import QRCode from "react-qr-code";
import { toPng } from "html-to-image";

interface IProps {
    qrCodeLink: string;
    isCopy: { status: boolean; value: number };
    reUpdateState: (value: number) => void;
}

export const ComponentQRCode = memo((props: IProps) => {
    //hook
    const { t } = useTranslation();
    const hostname = window.location.hostname;
    const prefix = hostname.split(".")[0] as string;
    const baseUrl = process.env.REACT_APP_CLIENT_URL;
    const ref = useRef<HTMLDivElement>(null);

    //props
    const { qrCodeLink, isCopy, reUpdateState } = props;

    const handleCopyText = useCallback(() => {
        const textCopy = qrCodeLink;
        copyText(textCopy, () => reUpdateState(2));
    }, [qrCodeLink]);

    const downLoadQR = useCallback(() => {
        downloadImage(qrCodeLink, "QR-code");
    }, [qrCodeLink]);

    const onLoadQr = useCallback(() => {
        if (ref.current === null) {
            return;
        }
        toPng(ref.current, { cacheBust: true })
            .then((dataUrl) => {
                const link = document.createElement("a");
                link.download = "my_qr_code.png";
                link.href = dataUrl;
                link.click();
            })
            .catch((err) => {
                console.log("err", err);
            });
    }, [ref]);
    return (
        <>
            <div className="title">{t("page.qr_code")}</div>
            <div className="ground_qrcode">
                <div ref={ref}>
                    <QRCode
                        id="qrcodeImg"
                        value={qrCodeLink}
                        size={256}
                    />
                </div>

                {/* <a href={qrCodeLink} download target="_blank"> */}
                <StyledDownloadButton
                    type="Gray"
                    size="small"
                    // onClick={downLoadQR}
                    onClick={onLoadQr}
                    text={
                        <>
                            <IConDown style={{ marginRight: "12px" }} />
                            {t("page.download")}
                        </>
                    }
                    htmlType="button"
                />
                {/* </a> */}
            </div>
            <div className="group_link">
                <SharedInput
                    styleParent={{ flex: 1 }}
                    value={qrCodeLink}
                    disable={true}
                />
                <Tooltip
                    title={
                        isCopy.status && isCopy.value === 2
                            ? t("message.copied")
                            : t("message.copy")
                    }
                >
                    <div className="copy" onClick={handleCopyText}>
                        <IconCopy />
                    </div>
                </Tooltip>
            </div>
        </>
    );
});

const StyledDownloadButton = styled(SharedButtonSub)`
    padding: 6px 12px;
    font-size: 16px;
    margin-left: 16px;
`;
