import { useState, useRef, useEffect } from "react";
import {
    ComponentContactSortIcon,
    StyledTable,
    ComponentEmptyData,
    StyledTableButtonTop,
    ComponentPromotionTableSearch,
} from "@components";
import { Row, Col, Pagination } from "antd";
import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";
import {
    enumSortBy,
    enumContactSortFields,
    PAGINATION,
    PAGE_START,
    INITIAL_LIST_PARAMS,
    PATH_BILL_PROMOTION_CREATE,
} from "@configs";
import { IGetBillPromotionExample, IPaginationNext } from "@interfaces";
import { useDispatch, useSelector } from "react-redux";
import { getCategories, selectAuth, selectContact, setLoading } from "@redux";
import { useNotify } from "@utils";
import { useHistory } from "react-router";
import styled from "styled-components";
import { billPromotionAPI } from "src/API/billPromotion";
import { ComponentBillRowPromotion } from "src/Components/table/RowBillPromotion";

const INITAL_PAGE = {
    xpage: PAGE_START,
    xlimit: PAGINATION,
};

export const PageBillPromotionList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const search_params = useSelector(selectContact).paramsContact;
    const [params, setParams] = useState(search_params);
    const organization_id = useSelector(selectAuth).userInfo?.merchant?._id;
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const [textSearch, setTextSearch] = useState<string>("");

    const [pagination, setPagination] = useState<IPaginationNext>(INITAL_PAGE);
    const [promotions, setPromotions] = useState<IGetBillPromotionExample[]>([]);
    const [total, setTotal] = useState<number>(0);
    const { success, error } = useNotify();
    const history = useHistory();
    const [tempRefresh, setTempRefresh] = useState(0);

    useEffect(() => {
        fetchNews();
    }, [textSearch, pagination]);

    useEffect(() => {
        handleSort;
    }, [tempRefresh]);

    const fetchNews = async () => {
        dispatch(setLoading(true));
        dispatch(getCategories({ organization_code: orgCode }));
        try {
            const res = await billPromotionAPI.getBillPromotions({
                organization_id: organization_id,
                xlimit: pagination.xlimit,
                xpage: pagination.xpage,
                text_search: textSearch,
            });
            setPromotions(res.data.result);
            setTotal(res.data.total);
        } catch (err: any) {
            error(err.msg);
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleSort = (sortField: enumContactSortFields, sortBy: enumSortBy) => {
        const newSortParams = { ...params, sortField, sortBy };
        setParams(newSortParams);
        setPromotions(promotions.reverse());
        setTempRefresh(2);
    };

    const callbackGetList = () => {
        fetchNews();
    };
    const handlePagination = (page) => {
        setPagination({ ...pagination, xpage: page });
    };
    const updateService = async (data, item: IGetBillPromotionExample) => {
        dispatch(setLoading(true));
        try {
            const updateStatus = await billPromotionAPI.setEnableBillPromotion({
                organization_id: organization_id,
                bill_promotion_id: item._id,
                is_enable: data,
            });
            if (updateStatus.data.error === "0") {
                success(t("message.update.success"));
                callbackGetList();
            }
        } catch (err: any) {
            error(err.msg);
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };
    return (
        <StyledTable>
            <div className="page-layout">
                <div className="page-header-layout">
                    <div className="page-header">
                        <div>
                            <h3>{t("page.bill_promotion")}</h3>
                        </div>
                        <div className="flex-header">
                            <StyledTableButtonTop
                                type="danger"
                                size="default"
                                text={t("page.add_promotion")}
                                onClick={() =>
                                    history.push({
                                        pathname: PATH_BILL_PROMOTION_CREATE,
                                    })
                                }
                                className="add-btn"
                                icon="plus"
                            />
                        </div>
                    </div>
                    <div className="search-layout">
                        <ComponentPromotionTableSearch
                            setTextSearch={setTextSearch}
                            total={total}
                        />
                    </div>
                </div>
                <div className="page-table-layout">
                    <div className="table">
                        <ScrollContainer vertical={false}>
                            <div className="table_ground">
                                <div className="table-body">
                                    <div className="table-header">
                                        <Row
                                            gutter={24}
                                            type="flex"
                                            justify="space-between"
                                            align="middle"
                                            className="header-row"
                                        >
                                            <Col className="col-item" span={5}>
                                                <p>{t("page.promotion_name")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.FULL_NAME}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.FULL_NAME}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={5}>
                                                <p>{t("page.updated_date")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.POINTS}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.POINTS}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={5}>
                                                <p>{t("page.promotion_type")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.CREATED_AT}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.CREATED_AT}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={5}>
                                                <p>{t("page.active")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.CREATED_AT}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.CREATED_AT}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={4}>
                                                <p>{t("page.manage")}</p>
                                            </Col>
                                        </Row>
                                    </div>
                                    {promotions && promotions.length > 0 ? (
                                        <CustomStyle>
                                            <div className="data-table">
                                                {promotions.map((item, index) => (
                                                    <ComponentBillRowPromotion
                                                        key={index}
                                                        data={item}
                                                        handleEdit={updateService}
                                                        callbackGetList={callbackGetList}
                                                    />
                                                ))}
                                                <div style={{ width: "100%", height: 10 }}></div>
                                            </div>
                                        </CustomStyle>
                                    ) : (
                                        <ComponentEmptyData />
                                    )}
                                </div>
                            </div>
                        </ScrollContainer>
                    </div>
                </div>

                <div className="page-bottom">
                    {total > 0 && (
                        <div className="pagination">
                            <Pagination
                                defaultCurrent={INITIAL_LIST_PARAMS.page || 1}
                                pageSize={PAGINATION}
                                total={total}
                                onChange={handlePagination}
                                current={pagination.xpage}
                            />
                        </div>
                    )}
                </div>
            </div>
        </StyledTable>
    );
};

const CustomStyle = styled.div`
    .data-table {
        padding: 0 0 0 34px !important;
    }
`;
