import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Col, Row, Menu, Dropdown, Button } from "antd";
import { MoreHorizontal } from "react-feather";
import { IDataexample, INewsCategory, IProduct } from "@interfaces";
import { enumNewsType, PATH_PRODUCT } from "@configs";
import { selectAuth } from "@redux";
import { useSelector } from "react-redux";
import { IconDrag } from "@components";
import { useNotify } from "@utils";
import React, { useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { productAPI } from "@api";
import { useHistory } from "react-router";

interface IProps {
    data: IDataexample | any;
    itemJson: string;
    handleEdit: (status: boolean, data: IDataexample) => void;
    callbackGetList: () => void;
    index: number;
    newType?: enumNewsType;
    categories: INewsCategory[];
    handleQuickEdit?: (item: IProduct) => void;
}

export const ComponentRowService = (props: IProps) => {
    const { t } = useTranslation();
    const { success, error } = useNotify();
    const history = useHistory();
    const organization_id = useSelector(selectAuth).auth?.organization_data?._id;
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const { data, handleEdit, callbackGetList, itemJson, newType, handleQuickEdit } = props;
    const [show, setShowModal] = useState(false);

    const { attributes, listeners, setNodeRef, transform } = useSortable({
        id: itemJson,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
    };

    const handleDelete = async (item: any) => {
        try {
            const res = await productAPI.delete({
                organization_code: orgCode,
                product_code: item,
            });
            callbackGetList();
            setShowModal(false);
            success(t("message.delete.success"));
        } catch (err: any) {
            if (err) {
                error(err.msg);
            } else error(t("message.delete.fail"));
        }
    };

    const menu = (
        <Menu>
            <Menu.Item
                key={1}
                onClick={() => {
                    if (handleQuickEdit) {
                        handleQuickEdit(data);
                    }
                }}
            >
                <div className="duplicate-btn" style={{ color: "#000" }}>
                    {t("page.quick_edit")}
                </div>
            </Menu.Item>
            <Menu.Item key={2} onClick={() => handleDelete(data.product_code)}>
                <div className="duplicate-btn">{t("page.delete")}</div>
            </Menu.Item>
        </Menu>
    );

    return (
        <div className="table-element" ref={setNodeRef} style={style}>
            <div className="drag-icon" {...attributes} {...listeners}>
                <IconDrag />
            </div>
            <div className="table-row">
                <Row
                    style={{ height: "100%" }}
                    gutter={24}
                    type="flex"
                    align="middle"
                    justify="space-between"
                >
                    <Col className="col-item" span={8}>
                        <Link to={`${PATH_PRODUCT}/${data.product_code}`}>
                            <p className="news-title">{data?.name}</p>
                        </Link>
                    </Col>
                    <Col className="col-item" span={6}>
                        <p>{data?.category || t("page.empty_text")}</p>
                    </Col>
                    <Col className="col-item visible-md" span={6}>
                        <p>{data?.variant || t("page.empty_text")}</p>
                    </Col>
                    <Col className="col-item" span={4}>
                        <Dropdown
                            //@ts-ignore
                            getPopupContainer={(trigger) => trigger.parentNode}
                            overlay={menu}
                            trigger={["click"]}
                            placement="bottomRight"
                        >
                            <Button style={{ border: "unset" }}>
                                <MoreHorizontal />
                            </Button>
                        </Dropdown>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
