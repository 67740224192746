import React, { useEffect, memo, useState, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Select, Input, Tabs } from "antd";
import styled from "styled-components";
import {
    INewsData,
    INewsCategory,
    INewsCategoryParams,
    IAddressData,
    IProvince,
    IAmphur,
    ISubDistrict,
    IGetAdminDataByTokenExample,
} from "@interfaces";
import { StyledTableButtonTop, ComponentEmptyData } from "@components";
import { useYup } from "@validations";
import { getCategories, setLoading, selectAuth, selectOrg } from "@redux";
import { formatMessage, showErrorMessage, useNotify } from "@utils";
import { PAGE_START, PAGINATION, PATH_STORE_CREATE, PATH_STORE_LIST } from "@configs";
import { adminAPI, orgApi, permissionPosAPI, storeAPI, wholesaleAPI } from "@api";
import Geocode from "react-geocode";
import { rectSortingStrategy, SortableContext } from "@dnd-kit/sortable";
import { ComponentRowPermissionEachStore } from "src/Components/table/RowPermissionEachStore";
import { StoreForm } from "./StoreForm";
import { ModulePaymentSettingForm } from "../payment/Form";
import { ComponentPermissionInviteModal } from "src/Components/store/PermissionInviteModal";

Geocode.setApiKey("AIzaSyAW-yZSy9ibuh4jq9QmiV5_cLqC9VKxdKQ");
Geocode.setLanguage("th");
Geocode.setRegion("th");
Geocode.setLocationType("ROOFTOP");
interface IProps {
    store: any | undefined;
    categories: INewsCategory[] | undefined;
}
const newDate = new Date();
const initData: INewsData = {
    organization_id: "",
    title: "",
    detail: "",
    image_url: [],
    is_hot_news: false,
    news_category_id: "",
    date_from: new Date(),
    date_to: new Date(newDate.setFullYear(newDate.getFullYear() + 1)),
    is_enable: true,
    discount: "",
    product_code: "",
    price: "",
    discount_type: "",
    has_barcode: true,
    active_on_platform: [],
    point_deduction: false,
    maximum_deduction: false,
    point_deduction_value: "",
    variants: [
        {
            variant_name: "",
            qty: "",
            raw_price: "",
            net_price: "",
            barcode: "",
            status: true,
        },
    ],
};

const INIT_ADDRESS: IAddressData = {
    address: "",
    district: "",
    province: "",
    subDistrict: "",
    postCode: "",
};
const { Option } = Select;

export const ModuleStoreForm = memo((props: IProps) => {
    const { YupNews } = useYup();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const { error, success } = useNotify();
    const org_id = useSelector(selectAuth).userInfo?.merchant?._id;
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const searchInputRef = useRef<any | null>(null);
    const [cat, setCat] = useState<string>("");
    const pathName = window.location.pathname;
    const createNews = Yup.object().shape(YupNews);
    const { store, categories } = props;
    //
    const [storeName, setStoreName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [storeCode, setStoreCode] = useState("");
    const [bookingDurationSlot, setBookingDurationSlot] = useState<string>("15 minutes");
    const [posTaxID, setPOSTaxID] = useState<string>("");
    const [storeTaxID, setStoreTaxID] = useState<string>("");
    const [terminalID, setTerminalID] = useState<string>("");
    const [customerServiceTel, setCustomerServiceTel] = useState("");
    const [chooseByStaffView, setChooseByStaffView] = useState<boolean>(false);
    const [services, setServices] = useState<any>([]);
    const [POSCode, setPOSCode] = useState("");
    const [serviceCharge, setServiceCharge] = useState("");
    const [isActive, seIsActive] = useState<boolean>(true);
    const [serviceList, setServiceList] = useState<any>([]);
    const [existServiceList, setExistServiceList] = useState<any>([]);
    // Permission Tab
    const [tabKey, setTabKey] = useState<string>("1");
    const [employees, setEmployees] = useState<any>([]);
    const categories2 = useSelector(selectOrg).category;
    const [showPopup, setShowPopup] = useState<boolean>(false);
    // Permission Tab
    // Payment Tab
    const [storePaymentGateWay, setStorePaymentGateWay] = useState<any>({});
    // Payment Tab
    const [addressData, setAddressData] = useState<IAddressData>(INIT_ADDRESS);
    const [provinceList, setProvinceList] = useState<IProvince[]>([]);
    const [amphurList, setAmphurList] = useState<IAmphur[]>([]);
    const [subDistrictList, setSubDistrictList] = useState<ISubDistrict[]>([]);
    const [storeAddress, setStoreAddress] = useState<string>("");
    const [wifiPassword, setWifiPassword] = useState<string>("");
    const [bottomTitle, setBottomTitle] = useState<string>("");
    const { adminPermission } = useSelector(selectAuth);


    const [monday, setMonday] = useState<any>({
        is_active: false,
        open_hour: "09:00",
        close_hour: "18:00",
    });
    const [tuesday, setTuesday] = useState<any>({
        is_active: false,
        open_hour: "09:00",
        close_hour: "18:00",
    });
    const [wednesday, setWednesday] = useState<any>({
        is_active: false,
        open_hour: "09:00",
        close_hour: "18:00",
    });
    const [thursday, setThursday] = useState<any>({
        is_active: false,
        open_hour: "09:00",
        close_hour: "18:00",
    });
    const [friday, setFriday] = useState<any>({
        is_active: false,
        open_hour: "09:00",
        close_hour: "18:00",
    });
    const [saturday, setSaturday] = useState<any>({
        is_active: false,
        open_hour: "09:00",
        close_hour: "18:00",
    });
    const [sunday, setSunday] = useState<any>({
        is_active: false,
        open_hour: "09:00",
        close_hour: "18:00",
    });
    const [userPermissions, setUserPermissions] = useState<IGetAdminDataByTokenExample>();
    const organization_id = useSelector(selectAuth).userInfo?.merchant?._id;
    const [isOldAdmin, setIsOldAdmin] = useState<boolean>(false);
    const [channelList, setChannelList] = useState<any[]>([]);
    const [focusChannel, setFocusChannel] = useState<string>("");
    const [isClientOrder, setIsClientOrder] = useState<boolean>(false);
    //
    useEffect(() => {
        fetchChannelList();
    }, []);
    useEffect(() => {
        if (adminPermission) {
            fetchNews();
            getAllProvince();
            setTimeout(() => {
                dispatch(setLoading(false));
            }, 500);
            if (store) {
                setFocusChannel(store?.loyalty_channel_id || "");
                setStoreName(store.store_name);
                setStoreCode(store.store_code);
                setIsClientOrder(store.is_client_order);
                setBookingDurationSlot(store.booking_duration_slot || "15 minutes");
                setPOSTaxID(store.bill_detail.tax5_id);
                setStoreTaxID(store.bill_detail.tax_id);
                setChooseByStaffView(store.is_choose_by_staff_view);
                setLat(store.location.latlon[1]);
                setLon(store.location.latlon[0]);
                setAddress(store.address);
                seIsActive(store.is_enable);
                setPOSCode(store.pos_number);
                setCustomerServiceTel(store.customer_service_tel);
                setServiceCharge(store.service_charge_percent);
                setServices(store.service_list);
                setMonday(store.business_hours.monday);
                setTuesday(store.business_hours.tuesday);
                setWednesday(store.business_hours.wednesday);
                setThursday(store.business_hours.thursday);
                setFriday(store.business_hours.friday);
                setSaturday(store.business_hours.saturday);
                setSunday(store.business_hours.sunday);
                setServiceList(store.service_list);
                setTerminalID(store.bill_detail.pos_id);
                setCompanyName(store.bill_detail.store_name);
                setBottomTitle(store.bill_detail.bottom_text);
                setWifiPassword(store.bill_detail.wifi_password);
                firstFetchAddress();
                getUserData();
            }
        }
    }, [store, categories, adminPermission]);

    const fetchChannelList = async () => {
        const getChannels = await storeAPI.getLoyatyChannelList();
        setChannelList(getChannels.data);
    };
    const getUserData = () => {
        try {
            if (adminPermission) {
                if (Object.keys(adminPermission.pos_role).length !== 0) {
                    setUserPermissions(adminPermission.pos_role);
                } else {
                    setIsOldAdmin(true);
                }
            }
        } catch (err: any) {
            error(err.message);
        }
    };

    const getAllProvince = async () => {
        const res = await orgApi.getProvince();
        setProvinceList(res.data.result);
    };

    const getAmphur = async (pId: number) => {
        let allAmphur = await orgApi.getAmphur({
            province_id: pId,
            xpage: 1,
            xlimit: 100,
        });
        setAmphurList(allAmphur.data.result);
        let newAdd = addressData;
        newAdd.province = pId.toString();
        newAdd.district = "";
        newAdd.subDistrict = "";
        newAdd.postCode = "";
        setAddressData(newAdd);
    };

    const getSubDistrictByamphur = async (aId: number) => {
        let subRes = await orgApi.getSubDistrict({
            amphur_id: aId,
            xpage: 1,
            xlimit: 100,
        });
        setSubDistrictList(subRes.data.result);
        let newAdd = addressData;
        newAdd.province = subRes.data.result[0].province_id;
        newAdd.district = aId.toString();
        newAdd.subDistrict = "";
        newAdd.postCode = "";
        setAddressData(newAdd);
        return subRes.data.result;
    };

    const onChangeProvince = async (value: any) => {
        setAddressData({
            ...addressData,
            province: value,
        });
        await getAmphur(value);
    };
    const onChangeAmphur = async (value: any) => {
        setAddressData({
            ...addressData,
            district: value,
        });
        await getSubDistrictByamphur(value);
    };
    const onChangeSubDistrict = (value: any) => {
        let outSub = subDistrictList[value];
        setAddressData({
            ...addressData,
            subDistrict: value,
            postCode: outSub?.zipcode,
        });
    };
    const fetchNews = async () => {
        if (store) {
            try {
                getAllEmployees();
                const temp: any = [];
                for (let i = 0; i < store.service_list.length; i++) {
                    for (let j = 0; j < store.service_list[i].service.length; j++) {
                        if (store.service_list[i].service[j].is_selected === true) {
                            temp.push(store.service_list[i].service[j].service_code);
                        }
                    }
                }
                setExistServiceList(temp);
            } catch (error) {
                console.error(error);
            }
        } else {
            try {
                const getServices = await orgApi.getServiceGroup({
                    organization_code: orgCode,
                });
                setServices(getServices.data.result);
            } catch (err) {
                console.error(err);
            }
        }
    };

    const firstFetchAddress = async () => {
        await getAmphur(store?.geo_location?.province_id);
        let subResult = await getSubDistrictByamphur(parseInt(store?.geo_location?.amphur_id));

        let disIndex = subResult.findIndex(
            (item) => item?.district_id === store?.geo_location?.district_id
        );

        let payload: IAddressData = {
            province: store?.geo_location?.province_id,
            district: store?.geo_location?.amphur_id,
            subDistrict: disIndex !== -1 ? disIndex?.toString() : "",
            address: "",
            postCode: "",
        };
        setAddressData(payload);
    };

    const handleEnter = (e: string) => {
        if (e === "Enter") {
            createCategory();
            searchInputRef.current.focus();
        }
    };
    const createCategory = async () => {
        dispatch(setLoading(true));
        // if (news?.organization_id === "") return;
        try {
            const payload: INewsCategoryParams = {
                category_name: cat,
                organization_id: org_id || "",
                is_enable: true,
            };
            const response = await orgApi.createCat(payload);
            success(response.data.msg);
            dispatch(getCategories({ organization_code: orgCode || "" }));
        } catch (err: any) {
            if (err) {
                error(err.msg);
            } else error(t("message.create.fail"));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleNewsSubmit = async (values: INewsData) => {
        dispatch(setLoading(true));
        try {
            let outSubdisIndex = 0;
            if (addressData?.subDistrict !== "") {
                outSubdisIndex = parseInt(addressData.subDistrict);
            }

            if (store) {
                const updateStoreAPI = await storeAPI.updateStore({
                    organization_code: orgCode,
                    loyalty_channel_id: focusChannel,
                    is_client_order: isClientOrder,
                    store_code: storeCode,
                    store_name: storeName,
                    booking_duration_slot: bookingDurationSlot,
                    address: address,
                    pos_number: POSCode,
                    service_charge_percent: serviceCharge,
                    remark: "",
                    // customer_service_tel: "024767624",
                    customer_service_tel: customerServiceTel,
                    is_choose_by_staff_view: chooseByStaffView,
                    location_lat: lat,
                    location_lon: lon,
                    is_enable: isActive,
                    bill_detail: {
                        tax_id: storeTaxID,
                        tax5_id: posTaxID,
                        store_name: companyName,
                        pos_id: terminalID,
                        wifi_password: wifiPassword,
                        bottom_text: bottomTitle,
                        address: address,
                    },
                    setting: {
                        is_override_setting: false,
                        cut_off_time: { value: 15, unit: "minutes" },
                        future_days: { value: 7, unit: "days" },
                        buffer_time_before: { value: 15, unit: "minutes" },
                        buffer_time_after: { value: 15, unit: "minutes" },
                    },
                    service_list: serviceList,
                    business_hours: {
                        monday: monday,
                        tuesday: tuesday,
                        wednesday: wednesday,
                        thursday: thursday,
                        friday: friday,
                        saturday: saturday,
                        sunday: sunday,
                    },
                    geo_location: {
                        province_id: addressData?.province,
                        amphur_id: addressData?.district,
                        district_id:
                            addressData?.subDistrict !== ""
                                ? subDistrictList[outSubdisIndex]?.district_id
                                : "",
                    },
                });
                if (updateStoreAPI.data.msg === "Store code or pos_number is dup. please check") {
                    error(formatMessage(updateStoreAPI));
                }
                if (updateStoreAPI.data.error === "0") {
                    setStoreName("");
                    setStoreCode("");
                    setChooseByStaffView(false);
                    history.push(PATH_STORE_LIST);
                } else {
                    error(formatMessage(updateStoreAPI));
                }
            } else {
                let payload: any = {
                    organization_code: orgCode,
                    loyalty_channel_id: focusChannel,
                    is_client_order: isClientOrder,
                    store_code: storeCode,
                    store_name: storeName,
                    booking_duration_slot: bookingDurationSlot,
                    pos_number: POSCode,
                    service_charge_percent: serviceCharge,
                    address: address,
                    remark: "remarkxx",
                    // customer_service_tel: "024767624",
                    customer_service_tel: customerServiceTel,
                    is_choose_by_staff_view: chooseByStaffView,
                    location_lat: lat,
                    location_lon: lon,
                    is_enable: isActive,
                    bill_detail: {
                        tax_id: storeTaxID,
                        tax5_id: posTaxID,
                        pos_id: terminalID,
                        store_name: companyName,
                    },
                    setting: {
                        is_override_setting: false,
                        cut_off_time: { value: 15, unit: "minutes" },
                        future_days: { value: 7, unit: "days" },
                        buffer_time_before: { value: 15, unit: "minutes" },
                        buffer_time_after: { value: 15, unit: "minutes" },
                    },
                    service_list: serviceList,
                    business_hours: {
                        monday: monday,
                        tuesday: tuesday,
                        wednesday: wednesday,
                        thursday: thursday,
                        friday: friday,
                        saturday: saturday,
                        sunday: sunday,
                    },
                    geo_location: {
                        province_id: addressData?.province,
                        amphur_id: addressData?.district,
                        district_id:
                            addressData?.subDistrict !== ""
                                ? subDistrictList[outSubdisIndex]?.district_id
                                : "",
                    },
                };
                const res = await storeAPI.createStore(payload);
                if (res.data.msg === "Store code or pos_number is dup. please check") {
                    error(formatMessage(res));
                }
                if (res.data.error === "0") {
                    setStoreCode("");
                    setStoreName("");
                    setAddress("");
                    setCustomerServiceTel("");
                    setChooseByStaffView(false);
                    setServiceCharge("");
                    setPOSCode("");
                    history.push(PATH_STORE_LIST);
                } else {
                    error(formatMessage(res));
                }
            }
        } catch (err: any) {
            if (err.response) {
                error(showErrorMessage(err.response));
            } else error(t("message.error"));
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const { values, handleChange, handleBlur, handleSubmit, errors, touched, setFieldValue } =
        useFormik({
            initialValues: initData,
            validationSchema: createNews,
            enableReinitialize: true,
            onSubmit: handleNewsSubmit,
        });

    const onCheck = (checkedKeys, info) => {
        let temp: any = [];
        for (let i = 0; i < services.length; i++) {
            for (let j = 0; j < checkedKeys.length; j++) {
                if (services[i].service.some((x) => x.service_code === checkedKeys[j])) {
                    setExistServiceList(checkedKeys);
                    const findService = services[i].service.find(
                        (x) => x.service_code === checkedKeys[j]
                    );
                    const newService = {
                        service: [
                            {
                                service_code: findService.service_code,
                                service_name: findService.service_name,
                                category: findService.category,
                                _id: findService.service_id,
                                is_selected: true,
                            },
                        ],
                    };
                    temp.push(newService);
                }
            }
        }
        setServiceList(temp);
        if (checkedKeys.length === 0) {
            setExistServiceList([]);
        }
        temp = [];
    };
    // Tree
    // Google Map Picker
    const [address, setAddress] = useState<string>("");
    const [lat, setLat] = useState<number>(0);
    const [lon, setLon] = useState<number>(0);
    const handleChangeLocation = (lat: number, lon: number, address: string) => {
        setLat(lat);
        setLon(lon);
        if (lat & lon) {
            formatAddress(lat, lon);
        }
    };

    const formatAddress = (lat, long) => {
        Geocode.fromLatLng(lat, long).then(
            (response) => {
                const res = response.results[0].formatted_address;
                if (res) {
                    setAddress(res);
                } else {
                    return "-";
                }
            },
            (error) => {
                console.error(error);
            }
        );
    };
    // Google Map Picker
    // Edit Store
    const { TabPane } = Tabs;
    const tabKeyHandler = (key) => {
        setTabKey(key);
        if (key === "2") {
            getStorePaymentGateWay();
        }
    };
    // Permission
    const INITAL_PAGE = {
        xpage: PAGE_START,
        xlimit: PAGINATION,
    };
    const getAllEmployees = async () => {
        try {
            const lastURL = window.location.href.split("/").pop();
            const getEpmloyees = await permissionPosAPI.getEmployees({
                organization_code: orgCode,
                xpage: 1,
                xlimit: 999,
                is_enable: true,
                store_code: lastURL,
            });
            setEmployees(getEpmloyees.data.result);
        } catch (err) {
            console.error(err);
        }
    };
    const callbackGetPermissonList = () => {
        fetchNews();
    };
    const updateService = async (data) => {
        await null;
    };
    const sendToParent = () => {
        fetchNews();
    };
    // Permission
    const getStorePaymentGateWay = async () => {
        try {
            const res = await storeAPI.getStorePaymentGateway({
                organization_id: org_id,
                store_id: store._id,
            });
            setStorePaymentGateWay(res.data.result);
        } catch (err) {
            console.error(err);
        }
    };
    // Edit Store

    return (
        <div className="form-wrap">
            <form onSubmit={handleSubmit}>
                <div className="page-header">
                    <h3>
                        {pathName.includes(PATH_STORE_CREATE)
                            ? t("page.create_store")
                            : t("page.edit_store")}
                    </h3>
                    {/* <p className="sub-title">{t("page.specify_detail")}</p> */}
                </div>
                {/* form basic setting */}
                {pathName.includes(PATH_STORE_CREATE) ? (
                    <StoreForm
                        store={store}
                        handleBlur={handleBlur}
                        setStoreName={setStoreName}
                        storeName={storeName}
                        setIsClientOrder={setIsClientOrder}
                        isClientOrder={isClientOrder}
                        setStoreCode={setStoreCode}
                        storeCode={storeCode}
                        bookingDurationSlot={bookingDurationSlot}
                        setBookingDurationSlot={setBookingDurationSlot}
                        setPOSTaxID={setPOSTaxID}
                        posTaxID={posTaxID}
                        setStoreTaxID={setStoreTaxID}
                        storeTaxID={storeTaxID}
                        setCustomerServiceTel={setCustomerServiceTel}
                        customerServiceTel={customerServiceTel}
                        chooseByStaffView={chooseByStaffView}
                        setChooseByStaffView={setChooseByStaffView}
                        setMonday={setMonday}
                        monday={monday}
                        setTuesday={setTuesday}
                        tuesday={tuesday}
                        setWednesday={setWednesday}
                        wednesday={wednesday}
                        setThursday={setThursday}
                        thursday={thursday}
                        setFriday={setFriday}
                        friday={friday}
                        setSaturday={setSaturday}
                        saturday={saturday}
                        setSunday={setSunday}
                        sunday={sunday}
                        setPOSCode={setPOSCode}
                        POSCode={POSCode}
                        setServiceCharge={setServiceCharge}
                        serviceCharge={serviceCharge}
                        existServiceList={existServiceList}
                        onCheck={onCheck}
                        services={services}
                        handleChange={handleChange}
                        address={address}
                        StyledCustom={StyledCustom}
                        setLat={setLat}
                        setLon={setLon}
                        setAddress={setAddress}
                        handleChangeLocation={handleChangeLocation}
                        lat={lat}
                        lon={lon}
                        isActive={isActive}
                        seIsActive={seIsActive}
                        handleNewsSubmit={handleNewsSubmit}
                        StyledSwitch={StyledSwitch}
                        addressData={addressData}
                        provinceList={provinceList}
                        amphurList={amphurList}
                        subDistrictList={subDistrictList}
                        onChangeProvince={onChangeProvince}
                        onChangeAmphur={onChangeAmphur}
                        onChangeSubDistrict={onChangeSubDistrict}
                        setTerminalID={setTerminalID}
                        setCompanyName={setCompanyName}
                        companyName={companyName}
                        terminalID={terminalID}
                        setStoreAddress={setStoreAddress}
                        storeAddress={storeAddress}
                        wifiPassword={wifiPassword}
                        setWifiPassword={setWifiPassword}
                        setBottomTitle={setBottomTitle}
                        bottomTitle={bottomTitle}
                        channelList={channelList}
                        focusChannel={focusChannel}
                        setFocusChannel={setFocusChannel}
                    />
                ) : (
                    <Styles>
                        <div className="page-layout">
                            <Tabs onChange={tabKeyHandler} type="card">
                                {((userPermissions &&
                                    userPermissions.store_permission &&
                                    userPermissions.store_permission.can_permission) ||
                                    isOldAdmin) && (
                                        <TabPane tab={t("page.permission_settings")} key="1">
                                            <div className="tab-title-row">
                                                <div className="ant-tabs-tab-title">
                                                    {t("page.manage_permissions")} - {storeName}
                                                </div>
                                                <StyledTableButtonTop
                                                    type="danger"
                                                    size="default"
                                                    text={t("page.create_staff")}
                                                    onClick={() => setShowPopup(true)}
                                                    className="add-btn"
                                                    icon="plus"
                                                    style={{
                                                        background: "#F22F46",
                                                        borderRadius: "4px",
                                                    }}
                                                />
                                            </div>
                                        </TabPane>
                                    )}
                                {((userPermissions &&
                                    userPermissions.store_permission &&
                                    userPermissions.store_permission.can_payment) ||
                                    isOldAdmin) && (
                                        <TabPane tab={t("page.payment_settings")} key="2">
                                            <ModulePaymentSettingForm
                                                store={storePaymentGateWay}
                                                categories={categories}
                                                storeDetails={store}
                                            />
                                        </TabPane>
                                    )}
                                {((userPermissions &&
                                    userPermissions.store_permission &&
                                    userPermissions.store_permission.can_store_detail) ||
                                    isOldAdmin) && (
                                        <TabPane tab={t("page.edit_store_details")} key="3">
                                            <div className="tab-title-row">
                                                <div className="ant-tabs-tab-title">
                                                    {t("page.store_setting")} - {storeName}
                                                </div>
                                            </div>
                                        </TabPane>
                                    )}
                            </Tabs>
                            {tabKey === "1" && (
                                <>
                                    <SortableContext
                                        items={employees.map((item) => JSON.stringify(item))}
                                        strategy={rectSortingStrategy}
                                    >
                                        {employees.length !== 0 ? (
                                            <div className="data-table">
                                                {employees.map((item, index) => (
                                                    <ComponentRowPermissionEachStore
                                                        key={index}
                                                        data={item}
                                                        itemJson={JSON.stringify(item)}
                                                        categories={categories2}
                                                        handleEdit={updateService}
                                                        callbackGetList={callbackGetPermissonList}
                                                        index={index}
                                                        stores={employees}
                                                        sendToParent={sendToParent}
                                                    />
                                                ))}
                                                <div style={{ width: "100%", height: 10 }}></div>
                                            </div>
                                        ) : (
                                            <ComponentEmptyData />
                                        )}
                                    </SortableContext>
                                    <ComponentPermissionInviteModal
                                        showPopup={showPopup}
                                        handleClose={() => {
                                            setShowPopup(!showPopup);
                                        }}
                                        sendToParent={sendToParent}
                                    />
                                </>
                            )}
                            {tabKey === "3" && (
                                <StoreForm
                                    store={store}
                                    handleBlur={handleBlur}
                                    setStoreName={setStoreName}
                                    storeName={storeName}
                                    setIsClientOrder={setIsClientOrder}
                                    isClientOrder={isClientOrder}
                                    setStoreCode={setStoreCode}
                                    storeCode={storeCode}
                                    bookingDurationSlot={bookingDurationSlot}
                                    setBookingDurationSlot={setBookingDurationSlot}
                                    setPOSTaxID={setPOSTaxID}
                                    posTaxID={posTaxID}
                                    setStoreTaxID={setStoreTaxID}
                                    storeTaxID={storeTaxID}
                                    setCustomerServiceTel={setCustomerServiceTel}
                                    customerServiceTel={customerServiceTel}
                                    chooseByStaffView={chooseByStaffView}
                                    setChooseByStaffView={setChooseByStaffView}
                                    setMonday={setMonday}
                                    monday={monday}
                                    setTuesday={setTuesday}
                                    tuesday={tuesday}
                                    setWednesday={setWednesday}
                                    wednesday={wednesday}
                                    setThursday={setThursday}
                                    thursday={thursday}
                                    setFriday={setFriday}
                                    friday={friday}
                                    setSaturday={setSaturday}
                                    saturday={saturday}
                                    setSunday={setSunday}
                                    sunday={sunday}
                                    setPOSCode={setPOSCode}
                                    POSCode={POSCode}
                                    setServiceCharge={setServiceCharge}
                                    serviceCharge={serviceCharge}
                                    existServiceList={existServiceList}
                                    onCheck={onCheck}
                                    services={services}
                                    handleChange={handleChange}
                                    address={address}
                                    StyledCustom={StyledCustom}
                                    setLat={setLat}
                                    setLon={setLon}
                                    setAddress={setAddress}
                                    handleChangeLocation={handleChangeLocation}
                                    lat={lat}
                                    lon={lon}
                                    isActive={isActive}
                                    seIsActive={seIsActive}
                                    handleNewsSubmit={handleNewsSubmit}
                                    StyledSwitch={StyledSwitch}
                                    addressData={addressData}
                                    provinceList={provinceList}
                                    amphurList={amphurList}
                                    subDistrictList={subDistrictList}
                                    onChangeProvince={onChangeProvince}
                                    onChangeAmphur={onChangeAmphur}
                                    onChangeSubDistrict={onChangeSubDistrict}
                                    setTerminalID={setTerminalID}
                                    setCompanyName={setCompanyName}
                                    companyName={companyName}
                                    terminalID={terminalID}
                                    setStoreAddress={setStoreAddress}
                                    storeAddress={storeAddress}
                                    wifiPassword={wifiPassword}
                                    setWifiPassword={setWifiPassword}
                                    setBottomTitle={setBottomTitle}
                                    bottomTitle={bottomTitle}
                                    channelList={channelList}
                                    focusChannel={focusChannel}
                                    setFocusChannel={setFocusChannel}
                                />
                            )}
                        </div>
                    </Styles>
                )}
            </form>
            {/* <ComponentProductPreview news={values} /> */}
            {/* <ComponentProductDrawer
                showModal={showModal}
                handleClose={() => setShowModal(!showModal)}
            /> */}
        </div>
    );
});

const StyledSwitch = styled.div`
    display: flex;
    align-items: center;
    p {
        margin-bottom: 0;
    }
    .weekDaysRow {
        display: flex;
        margin-top: 20px;
    }
    .ant-checkbox-wrapper + span,
    .ant-checkbox + span {
        display: flex;
        place-items: center;
        font-size: 16px;
        font-weight: 700;
        color: #000;
    }
    .ant-checkbox-wrapper {
        display: flex;
        place-items: center;
    }
    .ant-time-picker {
        margin-left: 8px;
        margin-right: 8px;
        width: 128px !important;
    }
    .daysOfTheWeek {
        width: 100px;
    }
`;

const StyledCustom = styled.div`
    .ant-input-search-enter-button input,
    .ant-input-search-button {
        height: 48px;
    }
    .ant-input-search .ant-btn-primary {
        background: #fff;
        color: #6c7084;
        border-color: #d9d9d9;
    }
    .mapComponent {
        margin-top: 20px;
    }
`;

const Styles = styled.div`
    .table-header {
        padding: 18px 34px 12px 66px !important;
    }
    .ant-tabs-content {
        padding-top: 16px;
        background: #fff;
        margin-bottom: 40px;
        border-radius: 6px;
    }
    .ant-tabs-bar {
        margin: 0;
    }
    .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
        padding: 0 25px;
        border: none;
        font-size: 16px;
        font-weight: 600;
        background: transparent;
        border-top: 3px solid transparent;
    }
    .ant-tabs-tabpane {
        padding: 36px 60px;
    }
    .ant-tabs-tab-title {
        font-size: 25px;
        font-weight: 700;
        color: #000;
    }
    .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
        color: #f4394f;
        border-top: 3px solid #f4394f;
        background: #fff;
    }
    .ant-tabs-nav .ant-tabs-tab:hover {
        color: #f4394f;
    }
    .tab-title-row {
        display: flex;
        justify-content: space-between;
        height: 55px;
    }
    .data-table {
        padding: 0 !important;
    }
    .table-row {
        background: #fff;
        transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
        border-radius: 6px;
        height: 97px;
        padding: 10px 18px;
        width: 100%;
        min-width: 680px;
    }
    .table-row p {
        margin: 0;
    }
    .table-element {
        align-items: center;
        display: flex;
        margin: 0 0 18px 0;
    }
    .table-row:hover {
        box-shadow: 0px 0px #f4394f, 0px 0 5px #f4394f;
    }
    .table-row .col-item {
        display: flex;
        align-items: center;
    }
    .news-title {
        font-size: 16px;
        font-weight: 700;
        color: #000;
    }
    .ant-btn-danger:focus {
        color: #fff;
    }
    .form-wrap {
        height: 100% !important;
    }
    .form-wrap form {
        padding: 0;
        overflow-x: hidden;
    }
`;
