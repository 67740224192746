import { ISvg } from "@interfaces";
export const IconInventoryManagement = (props: ISvg) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.size || "50"}
            height={props.size || "50"}
            viewBox="0 0 50 50"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.2206 3.84615C9.00667 3.84615 7.8968 4.53206 7.35397 5.61791L5.03468 10.2564H44.9653L42.646 5.61791C42.1032 4.53206 40.9933 3.84615 39.7794 3.84615H10.2206ZM46.1538 14.1026H3.84615V42.9487C3.84615 44.7188 5.28114 46.1538 7.05128 46.1538H42.9487C44.7188 46.1538 46.1538 44.7188 46.1538 42.9487V14.1026ZM3.91383 3.89785C5.10826 1.50899 7.54987 0 10.2206 0H39.7794C42.4501 0 44.8918 1.50899 46.0862 3.89786L49.7969 11.3195C49.9305 11.5865 50 11.8809 50 12.1795V42.9487C50 46.8431 46.8431 50 42.9487 50H7.05128C3.15696 50 0 46.8431 0 42.9487V12.1795C0 11.8809 0.0695128 11.5865 0.203026 11.3195L3.91383 3.89785Z"
                fill={props.color || "black"}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.6667 37.8205C16.6667 36.7585 17.5277 35.8975 18.5897 35.8975H31.4102C32.4723 35.8975 33.3333 36.7585 33.3333 37.8205C33.3333 38.8826 32.4723 39.7436 31.4102 39.7436H18.5897C17.5277 39.7436 16.6667 38.8826 16.6667 37.8205Z"
                fill={props.color || "black"}
            />
        </svg>
    );
};
