import { useState, useEffect } from "react";
import { ComponentContactSortIcon, StyledTable, ComponentEmptyData } from "@components";
import { Row, Col, Pagination, Tabs } from "antd";
import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";
import {
    enumSortBy,
    enumContactSortFields,
    PAGINATION,
    PAGE_START,
    INITIAL_LIST_PARAMS,
} from "@configs";
import {
    IGetSettingCheckStockConsumableListByStoreIdExample,
    IGetSettingCheckStockProductListByStoreIdExample,
    IPaginationNext,
    IUpdateSettingCheckStockType,
    IUpdateStore,
} from "@interfaces";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth, selectContact, selectTheme, setLoading } from "@redux";
import { theme, useNotify } from "@utils";
import { useHistory } from "react-router";
import styled from "styled-components";
import { checkStockDocAPI, storeAPI } from "@api";
import { ComponentSettingCategoryCheckStockSearch } from "src/Components/TableSettingCategoryCheckStockSearch";
import { ComponentRowSettingCategoryCheckStock } from "src/Components/table/RowSettingCategoryCheckStock";
const { TabPane } = Tabs;

const INITAL_PAGE = {
    xpage: PAGE_START,
    xlimit: PAGINATION,
};

export const PageSettingCategoryCheckStockList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const search_params = useSelector(selectContact).paramsContact;
    const { mainColor } = useSelector(selectTheme);
    const [params, setParams] = useState(search_params);
    const organization_id = useSelector(selectAuth).userInfo?.merchant?._id;
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const [textSearch, setTextSearch] = useState<string>("");
    const [pagination, setPagination] = useState<IPaginationNext>(INITAL_PAGE);
    const [products, setProducts] = useState<
        (IGetSettingCheckStockProductListByStoreIdExample &
            IGetSettingCheckStockConsumableListByStoreIdExample)[]
    >([]);
    const [storeList, setStoreList] = useState<IUpdateStore[]>([]);
    const [tab, setTab] = useState<string>("product");
    const [selectedStore, setSelectedStore] = useState<string>("");
    const [total, setTotal] = useState<number>(0);
    const { success, error } = useNotify();
    const history = useHistory();

    useEffect(() => {
        fetchNews();
    }, [textSearch, pagination, selectedStore, tab]);

    const fetchNews = async () => {
        dispatch(setLoading(true));
        try {
            if (storeList.length === 0) {
                const getStores = await storeAPI.getList({
                    organization_code: orgCode,
                    xpage: INITAL_PAGE.xpage,
                    xlimit: 40,
                });
                if (getStores.data.error === "0") {
                    setStoreList(getStores.data.result);
                    setSelectedStore(getStores.data.result[0]._id);
                }
            }
            if (tab === "product") {
                if (selectedStore !== "") {
                    const res = await checkStockDocAPI.getSettingCheckStockProductListByStoreId({
                        organization_id: organization_id,
                        xlimit: 100,
                        xpage: pagination.xpage,
                        text_search: textSearch,
                        store_id: selectedStore,
                    });
                    setProducts(res.data.result);
                    setTotal(res.data.total);
                }
            } else {
                if (selectedStore !== "") {
                    const res = await checkStockDocAPI.getSettingCheckStockConsumableListByStoreId({
                        organization_id: organization_id,
                        xlimit: 100,
                        xpage: pagination.xpage,
                        text_search: textSearch,
                        store_id: selectedStore,
                    });
                    setProducts(res.data.result);
                    setTotal(res.data.total);
                }
            }
        } catch (err: any) {
            error(err.msg);
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleSort = () => {
        null;
    };

    const callbackGetList = () => {
        fetchNews();
    };
    const handlePagination = (page) => {
        setPagination({ ...pagination, xpage: page });
    };
    const updateService = async (
        data: IUpdateSettingCheckStockType,
        item: IGetSettingCheckStockProductListByStoreIdExample &
            IGetSettingCheckStockConsumableListByStoreIdExample
    ) => {
        dispatch(setLoading(true));
        try {
            if (tab === "product") {
                const updateStatus = await checkStockDocAPI.updateSettingCheckStockProductByStoreId(
                    {
                        organization_id: organization_id,
                        product_id: item.product_id,
                        is_check_daily: data.is_check_daily,
                        is_check_weekly: data.is_check_weekly,
                        is_check_monthly: data.is_check_monthly,
                        // store_id: null,
                    }
                );
                if (updateStatus.data.error === "0") {
                    success(t("message.update.success"));
                    callbackGetList();
                }
            } else {
                const updateStatus =
                    await checkStockDocAPI.updateSettingCheckStockConsumableByStoreId({
                        organization_id: organization_id,
                        consumable_id: item.consumable_id,
                        is_check_daily: data.is_check_daily,
                        is_check_weekly: data.is_check_weekly,
                        is_check_monthly: data.is_check_monthly,
                        // store_id: null,
                    });
                if (updateStatus.data.error === "0") {
                    success(t("message.update.success"));
                    callbackGetList();
                }
            }
        } catch (err: any) {
            error(err.msg);
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleSelectStore = (value) => {
        setSelectedStore(value);
    };

    return (
        <StyledTable>
            <div className="page-layout">
                <div className="page-header-layout">
                    <div className="page-header">
                        <div>
                            <h3>{t("page.setting_category_check_stock_list")}</h3>
                        </div>
                    </div>
                    <div className="search-layout">
                        <StyledTab>
                            <Tabs
                                defaultActiveKey="product"
                                onChange={(key) => {
                                    setTab(key);
                                }}
                                className="tabsRow"
                            >
                                <TabPane tab={t("page.product")} key="product"></TabPane>
                                <TabPane tab={t("page.inventory")} key="inventory"></TabPane>
                            </Tabs>
                        </StyledTab>
                        <ComponentSettingCategoryCheckStockSearch
                            setTextSearch={setTextSearch}
                            total={total}
                            // storeList={storeList}
                            // handleSearchCategory={handleSelectStore}
                            // selectedStore={selectedStore}
                        />
                    </div>
                    {tab === "inventory" && (
                        <StyledBottomTab>
                            <Tabs
                                defaultActiveKey="finishedGoods"
                                onChange={(key) => {
                                    // setRequestType(key);
                                }}
                            >
                                <TabPane
                                    tab={t("page.finished_goods")}
                                    key="finishedGoods"
                                ></TabPane>
                                <TabPane
                                    tab={t("page.uncategorized")}
                                    key="uncategorized"
                                ></TabPane>
                                <TabPane tab={t("page.raw_mat")} key="rawMat"></TabPane>
                            </Tabs>
                        </StyledBottomTab>
                    )}
                </div>
                <div className="page-table-layout">
                    <div className="table">
                        <ScrollContainer vertical={false}>
                            <div className="table_ground">
                                <div className="table-body">
                                    <div className="table-header">
                                        <Row
                                            gutter={24}
                                            type="flex"
                                            justify="space-between"
                                            align="middle"
                                            className="header-row"
                                        >
                                            <Col className="col-item" span={9}>
                                                <p>{t("page.consumable_name")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.FULL_NAME}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.FULL_NAME}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={4}>
                                                <p>{t("page.daily")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.CREATED_AT}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.CREATED_AT}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={4}>
                                                <p>{t("page.weekly")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.CREATED_AT}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.CREATED_AT}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={4}>
                                                <p>{t("page.monthly")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.CREATED_AT}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.CREATED_AT}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={3}></Col>
                                        </Row>
                                    </div>
                                    {products && products.length > 0 ? (
                                        <CustomStyle>
                                            <div className="data-table">
                                                {products.map((item, index) => (
                                                    <ComponentRowSettingCategoryCheckStock
                                                        key={index}
                                                        data={item}
                                                        handleEdit={updateService}
                                                        callbackGetList={callbackGetList}
                                                    />
                                                ))}
                                                <div style={{ width: "100%", height: 10 }}></div>
                                            </div>
                                        </CustomStyle>
                                    ) : (
                                        <ComponentEmptyData />
                                    )}
                                </div>
                            </div>
                        </ScrollContainer>
                    </div>
                </div>

                <div className="page-bottom">
                    {total > 0 && (
                        <div className="pagination">
                            <Pagination
                                defaultCurrent={INITIAL_LIST_PARAMS.page || 1}
                                pageSize={100}
                                total={total}
                                onChange={handlePagination}
                                current={pagination.xpage}
                            />
                        </div>
                    )}
                </div>
            </div>
        </StyledTable>
    );
};

const CustomStyle = styled.div`
    .data-table {
        padding: 0 0 0 34px !important;
    }
`;
const StyledTab = styled.div`
    .ant-tabs-ink-bar {
        top: 1px;
        bottom: auto;
        background-color: #f22f46;
    }
    .ant-tabs-nav {
        color: #646464;
    }
    .ant-tabs-nav .ant-tabs-tab-active,
    .ant-tabs-nav .ant-tabs-tab:hover {
        color: #f22f46;
    }
    .ant-tabs-nav .ant-tabs-tab-active {
        background: #fff;
        border-radius: 4px 4px 0 0;
        font-weight: 700;
    }
    .ant-tabs-tab {
        margin: 0;
        padding: 12px 32px;
        font-size: 16px;
    }
    .ant-tabs-bar {
        margin: 0;
    }
`;
const StyledBottomTab = styled.div`
    .ant-tabs-ink-bar {
        background-color: ${theme.colors.main};
        height: 3px !important;
    }
    .ant-tabs-nav .ant-tabs-tab-active,
    .ant-tabs-nav .ant-tabs-tab:hover {
        color: ${theme.colors.main};
    }
    .ant-tabs-nav .ant-tabs-tab-active {
        font-weight: 700;
    }
    .ant-tabs-nav .ant-tabs-tab {
        padding: 12px 0;
    }
`;
