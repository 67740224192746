import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Switch, Col, Row, Avatar, Menu, Dropdown, Button } from "antd";
import { MoreHorizontal } from "react-feather";

import { PATH_RECIPE_ASSIGN } from "@configs";
import { selectAuth, store } from "@redux";
import { useSelector } from "react-redux";
import { IconDrag } from "@components";
import { useNotify } from "@utils";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import styled from "styled-components";
import { useHistory } from "react-router";

interface IProps {
    data: any;
    itemJson: string;
    index: number;
}

export const ComponentRowAssignAddon = (props: IProps) => {
    //hooks
    const { t } = useTranslation();
    const organization_id = useSelector(selectAuth).userInfo?.merchant?._id;
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    //props
    const { data, itemJson, index } = props;

    const history = useHistory();
    // const history = useHistory();

    const { attributes, listeners, setNodeRef, transform } = useSortable({
        id: itemJson,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
    };
    const handleDelete = async (item: any) => {
        // call delete
    };

    const menu = (
        <Menu>
            <Menu.Item
                key={1}
                onClick={() => {
                    // handleDelete(data.variant_id);
                }}
            >
                <div className="duplicate-btn">{t("page.delete")}</div>
            </Menu.Item>
        </Menu>
    );
    return (
        <Styles>
            <div className="table-element" ref={setNodeRef} style={style}>
                <div className="drag-icon hide" {...attributes} {...listeners}>
                    <IconDrag />
                </div>
                <div className="table-row">
                    <Row
                        style={{ height: "100%" }}
                        gutter={24}
                        type="flex"
                        align="middle"
                        justify="space-between"
                    >
                        <Col className="col-item" span={6}>
                            {/* <Link to={`${PATH_RECIPE_ASSIGN}/${data.variant_id}`}> */}
                            <p className="news-title">{data?.addon_name || t("page.empty_text")}</p>
                            {/* </Link> */}
                        </Col>
                        <Col className="col-item" span={6}>
                            <p className="news-title">{t("page.empty_text")}</p>
                        </Col>
                        <Col className="col-item" span={6}>
                            <p className="news-title">{t("page.empty_text")}</p>
                        </Col>
                        <Col className="col-item" span={6} style={{ placeContent: "end" }}>
                            <Button
                                style={{
                                    height: "42px",
                                    border: "0.5px solid #646464",
                                    borderRadius: "4px",
                                    background: "transparent",
                                    color: "#646464",
                                    marginRight: "10px",
                                }}
                                onClick={() => {
                                    // history.push(`${PATH_RECIPE_ASSIGN}/${data.variant_id}`);
                                }}
                            >
                                {t("page.edit")}
                            </Button>
                            <Dropdown
                                //@ts-ignore
                                getPopupContainer={(trigger) => trigger.parentNode}
                                overlay={menu}
                                trigger={["click"]}
                                placement="bottomRight"
                            >
                                <Button style={{ border: "unset" }}>
                                    <MoreHorizontal />
                                </Button>
                            </Dropdown>
                        </Col>
                    </Row>
                    {/* <Row
                        style={{ height: "100%" }}
                        gutter={24}
                        type="flex"
                        align="middle"
                        justify="space-between"
                    >
                        <Col className="col-item" span={6}>
                            <Link to={`${PATH_RECIPE_ASSIGN}/${data.variant_id}`}>
                                <p className="news-title">
                                    {stores[index].product_name || t("page.empty_text")}
                                </p>
                            </Link>
                        </Col>
                        <Col className="col-item" span={6}>
                            <p>{stores[index].variant_list.variant_name || t("page.empty_text")}</p>
                        </Col>
                        <Col className="col-item" span={6}>
                            <p>
                                {stores[index].variant_list.consumable_list.length !== 0
                                    ? stores[index].variant_list.consumable_list[0].used_qty +
                                      " " +
                                      stores[index].variant_list.consumable_list[0]
                                          .consumable_name +
                                      ", ..."
                                    : t("-")}
                            </p>
                        </Col>
                        <Col className="col-item" span={6} style={{ placeContent: "end" }}>
                            <Button
                                style={{
                                    height: "42px",
                                    border: "0.5px solid #646464",
                                    borderRadius: "4px",
                                    background: "transparent",
                                    color: "#646464",
                                    marginRight: "10px",
                                }}
                                onClick={() =>
                                    history.push(`${PATH_RECIPE_ASSIGN}/${data.variant_id}`)
                                }
                            >
                                {t("page.edit")}
                            </Button>
                            <Dropdown
                                //@ts-ignore
                                getPopupContainer={(trigger) => trigger.parentNode}
                                overlay={menu}
                                trigger={["click"]}
                                placement="bottomRight"
                            >
                                <Button style={{ border: "unset" }}>
                                    <MoreHorizontal />
                                </Button>
                            </Dropdown>
                        </Col>
                    </Row> */}
                </div>
            </div>
        </Styles>
    );
};
const Styles = styled.div`
    .table-row {
        height: 60px;
    }
`;
