import React, { useEffect, memo, useState, useRef } from "react";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Row, Col, Radio, Input, Table, Button } from "antd";
import styled from "styled-components";
import { IPaginationNext, IConsumableList } from "@interfaces";
import { StyledSubmitButton, StyledCancelButton, StyledCard } from "@components";
import { useYup } from "@validations";
import { setLoading, selectAuth } from "@redux";
import { showErrorMessage, useNotify } from "@utils";
import { PAGE_START, PAGINATION, PATH_STOCK_LIST } from "@configs";
import { orgApi, productAPI, productAddonAPI } from "@api";
import { ColumnProps } from "antd/lib/table";
import { consumableAPI } from "src/API/consumable";
import { AssignCategoryIcon } from "src/Components/icon/AssignCategoryIcon";
import { AssignProductIcon } from "src/Components/icon/AssignProductIcon";
import { AssignEditIcon } from "src/Components/icon/AssignEditIcon";
import { AssignDeleteIcon } from "src/Components/icon/AssignDeleteIcon";
import { ComponentAddConsumableModal } from "src/Components/stock/AddConsumableModal";

const INITAL_PAGE = {
    xpage: PAGE_START,
    xlimit: PAGINATION,
};

export const ModuleAssignStockForm = memo(() => {
    const { YupNews } = useYup();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const { error, success, warning } = useNotify();
    const organization_id = useSelector(selectAuth).userInfo?.merchant?._id;
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const searchInputRef = useRef<any | null>(null);
    const pathName = window.location.pathname;
    const createNews = Yup.object().shape(YupNews);
    const [pagination, setPagination] = useState<IPaginationNext>(INITAL_PAGE);
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [CategoryLength, setCategoryLength] = useState<number>();
    const [productCategories, setProductCategories] = useState<any>([]);
    const [variants, setVariants] = useState<any>([]);
    const [colStyle, setColStyle] = useState<any>();
    const [step, setStep] = useState<any>(1);
    const [consumables, setConsumables] = useState<any>([]);
    const [totalConsumables, setTotalConsumables] = useState<number>(0);
    const [tempConsumable, setTempConsumable] = useState<any[]>([]);
    const [variantID, setVariantID] = useState<string>();
    const [variant, setVariant] = useState<any>([]);
    const [type, setType] = useState<string>("product");
    const [addonList, setAddonList] = useState<any>([]);
    const [focusAddon, setFocusAddon] = useState<any>();
    const [aOptionList, setAOptionList] = useState<any>([]);
    const [selectAOption, setSelectAOption] = useState({
        name: "",
        _id: "",
    });
    const [textSearch, setTextSearch] = useState<string>("");

    useEffect(() => {
        fetchNews();
        fetchAddonList();
    }, []);

    useEffect(() => {
        getConsumables();
    }, [pagination, textSearch]);

    const handleEnter = (e: string) => {
        if (e === "Enter") {
            searchInputRef.current.focus();
        }
    };

    const fetchAddonList = async () => {
        dispatch(setLoading(true));
        try {
            const res = await productAddonAPI.getProductAddons({
                organization_id: organization_id,
                xpage: 1,
                xlimit: 999,
                is_enable: true,
            });
            if (res.data.error === "0") {
                setAddonList(res.data.result);
            } else {
                error(showErrorMessage(res.data.msg));
            }
        } catch (err: any) {
            if (err.response) {
                error(showErrorMessage(err.response));
            } else error(t("message.error"));
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };
    const fetchNews = async () => {
        dispatch(setLoading(true));
        try {
            const getAllProductCats = await orgApi.getProductCategorys({
                organization_code: orgCode,
            });
            setProductCategories(getAllProductCats.data.result);
            setCategoryLength(getAllProductCats.data.result.length);
        } catch (err: any) {
            if (err.response) {
                error(showErrorMessage(err.response));
            } else error(t("message.error"));
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };
    const getCat = async (item) => {
        dispatch(setLoading(true));
        try {
            const getAllVariants = await productAPI.getVariants({
                organization_id: organization_id,
                xpage: pagination.xpage,
                xlimit: 100,
                category_id: item,
            });
            setVariants(getAllVariants.data.result);
        } catch (err: any) {
            if (err.response) {
                error(showErrorMessage(err.response));
            } else error(t("message.error"));
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };
    const getVariant = async (item) => {
        dispatch(setLoading(true));
        try {
            const getVariant = await productAPI.getVariant({
                organization_id: organization_id,
                variant_id: item,
            });
            setTableData(getVariant.data.result.consumable_list);
            setTempConsumable(getVariant.data.result.consumable_list);
            setVariant(getVariant.data.result);
        } catch (err: any) {
            if (err.response) {
                error(showErrorMessage(err.response));
            } else error(t("message.error"));
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };
    const getConsumables = async () => {
        dispatch(setLoading(true));
        try {
            const getAllConsumables = await consumableAPI.getList({
                organization_id: organization_id,
                xpage: pagination.xpage,
                xlimit: pagination.xlimit,
                text_search: textSearch,
            });
            setConsumables(getAllConsumables.data.result);
            setTotalConsumables(getAllConsumables.data.total);
        } catch (err: any) {
            if (err.response) {
                error(showErrorMessage(err.response));
            } else error(t("message.error"));
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };
    const handleAssignSubmit = async () => {
        dispatch(setLoading(true));
        try {
            if (step === 2) {
                let count = tableData.length;
                let arr: IConsumableList[] = [];
                tableData.forEach((tItem, tIndex) => {
                    arr.push({
                        consumable_id: tItem?.consumable_id,
                        used_qty: tItem.used_qty,
                    });
                    if (!isNaN(+tItem.used_qty)) {
                        count -= 1;
                    }
                });
                if (count === 0) {
                    if (type === "product") {
                        const updateStoreAPI = await consumableAPI.assignConsumable({
                            organization_id: organization_id,
                            variant_id: variantID,
                            consumable_list: tableData,
                        });
                        if (updateStoreAPI.data.error === "0") {
                            success(t("message.update.success"));
                            resetAllData();
                        } else {
                            error(updateStoreAPI.data.msg);
                        }
                    } else if (type === "addon") {
                        let payload = {
                            organization_id: organization_id,
                            addon_option_id: selectAOption._id,
                            consumable_list: arr,
                        };
                        const res = await consumableAPI.updateConToAddon(payload);
                        if (res.data.error === "0") {
                            success(t("message.update.success"));
                            resetAllData();
                        } else {
                            error(res.data.msg);
                        }
                    }
                } else {
                    error("please add Quantity per order");
                }
            } else {
                error("please assign consumable");
            }
        } catch (err: any) {
            if (err.response) {
                error(showErrorMessage(err.response));
            } else error(t("message.error"));
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };
    const handlePagination = (page) => {
        setPagination({ ...pagination, xpage: page });
    };
    const handleCancel = () => {
        history.push("/");
    };
    // Edit Stock Table
    interface IMyTableData {
        consumable_name: string;
        consumable_unit_name: string;
        used_qty: number;
        consumable_id: string;
    }
    const [tableData, setTableData] = useState<any[]>([]);
    const columns: ColumnProps<IMyTableData>[] = [
        {
            dataIndex: "consumable_name",
            title: t("page.consumable"),
            key: "consumable_name",
            width: "50%",
            render: (text, record, index) => <div className="consumableName">{text}</div>,
        },
        {
            dataIndex: "used_qty",
            key: "used_qty",
            title: `${t("page.quantity_per_order")}`,
            width: "35%",
            render: (text, record, index) => (
                <Input
                    type={"number"}
                    style={{ placeItems: "center", display: "flex", position: "relative" }}
                    addonAfter={record.consumable_unit_name}
                    value={text}
                    minLength={0}
                    onChange={(value) => {
                        record.used_qty = parseFloat(value.target.value);
                        setTableData([...tableData]);
                    }}
                />
            ),
        },
        {
            dataIndex: "consumable_id",
            key: "consumable_id",
            title: () => {
                return <div style={{ textAlign: "center" }}>{t("page.delete")}</div>;
            },
            width: "15%",
            render: (text, record, index) => (
                <div
                    style={{
                        placeItems: "center",
                        display: "flex",
                        placeContent: "center",
                        cursor: "pointer",
                    }}
                    onClick={() => {
                        handleDelete(text);
                    }}
                >
                    <AssignDeleteIcon />
                </div>
            ),
        },
    ];

    const handleDelete = (stid) => {
        setTableData((data) => data.filter((item) => item.consumable_id !== stid));
    };
    // Edit Stock Table
    const resetAllData = () => {
        fetchNews();
        fetchAddonList();

        setTableData([]);
        setTempConsumable([]);
        setVariants([]);
        setColStyle(null);
        setStep(1);
        setConsumables([]);
        setVariantID("");
        setVariant([]);
        setType("product");
        setAOptionList(null);
        setSelectAOption({
            name: "",
            _id: "",
        });
    };
    const clickAddonDetail = (item, index) => {
        setSelectAOption({
            name: item?.option_name,
            _id: item?._id,
        });
        setTableData(item?.consumable_list);
        setTempConsumable(item?.consumable_list);
        setStep(2);
    };

    const clickAddonItem = async (item: any) => {
        dispatch(setLoading(true));
        try {
            const res = await productAddonAPI.getProductAddonID({
                organization_id: organization_id,
                product_addon_id: item?._id,
            });
            if (res.data.error === "0") {
                setAOptionList(res.data.result);
            } else {
                error(res.data.msg);
            }
        } catch (err: any) {
            if (err.response) {
                warning(showErrorMessage(err.response));
                dispatch(setLoading(false));
            } else warning(t("message.not_found", { name: t("object._reward") }));
        } finally {
            dispatch(setLoading(false));
        }
    };

    return (
        <div className="form-wrap">
            <form>
                <div className="page-header">
                    <h3>
                        {pathName.includes(PATH_STOCK_LIST)
                            ? t("page.add_edit_consumable")
                            : t("page.add_consumable")}
                    </h3>
                </div>
                <div className="page-body">
                    <div className="card-wrap">
                        <StyledCard style={{ padding: "1.5rem" }}>
                            <StyledCustom>
                                <div className="row">
                                    <div className="step">
                                        <div className="blueCircle">1</div>
                                        <p className="title2">{t("page.select_product")}</p>
                                    </div>
                                    <div className="arrowRight">
                                        <svg
                                            width="14"
                                            height="11"
                                            viewBox="0 0 14 11"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M12.1997 5.09865L8.0354 1.46012C7.83577 1.28569 7.81534 0.982461 7.98976 0.782832C8.16419 0.583203 8.46742 0.562773 8.66705 0.737199L13.787 5.21082C13.7884 5.21197 13.7897 5.21312 13.791 5.21428C13.8935 5.30231 13.9585 5.4329 13.9585 5.57865C13.9585 5.75719 13.861 5.91295 13.7164 5.99565L8.66756 10.4197C8.46818 10.5944 8.16492 10.5744 7.99021 10.375C7.8155 10.1756 7.8355 9.87235 8.03488 9.69765L12.1878 6.05865L0.841075 6.05865C0.575978 6.05865 0.361074 5.84375 0.361074 5.57865C0.361074 5.31356 0.575978 5.09865 0.841075 5.09865L12.1997 5.09865Z"
                                                fill="#919EAB"
                                            />
                                        </svg>
                                    </div>
                                    <div className="step">
                                        <div className={step === 2 ? "blueCircle" : "grayCircle"}>
                                            2
                                        </div>
                                        <p className={step === 2 ? "title2" : "title2 whiteText"}>
                                            {t("page.assign_consumable")}
                                        </p>
                                    </div>
                                </div>
                            </StyledCustom>
                        </StyledCard>
                    </div>
                </div>
                <div className="page-body">
                    <div className="card-wrap">
                        <StyledCard>
                            <StyledCustom>
                                <div
                                    className="title"
                                    style={{
                                        fontSize: "25px",
                                        color: "#000",
                                        borderBottom: "none",
                                        marginBottom: 32,
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    {step === 1 ? (
                                        <p>{t("page.select_product_to_assign_consumables")}</p>
                                    ) : (
                                        <p>{t("page.add_consumable")}</p>
                                    )}
                                </div>
                                {step === 1 && (
                                    <div className="radio-section">
                                        <p className="radio-title">{t("page.addon.radio_title")}</p>
                                        <span
                                            className="radio-item"
                                            onClick={() => {
                                                setType("product");
                                                setVariants([]);
                                                setColStyle(null);
                                            }}
                                        >
                                            <Radio checked={type === "product"} />
                                            {t("page.addon.radio_product")}
                                        </span>
                                        <span
                                            className="radio-item"
                                            onClick={() => {
                                                setType("addon");
                                                setVariants([]);
                                                setColStyle(null);
                                            }}
                                        >
                                            <Radio checked={type === "addon"} />
                                            {t("page.addon.radio_addon")}
                                        </span>
                                    </div>
                                )}

                                <br />
                                <Row className="select-layout" gutter={32}>
                                    <Col md={24}>
                                        <StyledBtnStock>
                                            {step === 1 ? (
                                                <div className="wholeTable">
                                                    <div className="wholeTopRow">
                                                        <div className="topTitleRow leftSide">
                                                            <div className="tableTitles">
                                                                <div className="titleAndIcon">
                                                                    <AssignCategoryIcon />
                                                                    {type === "product" &&
                                                                        t("page.category")}
                                                                    {type === "addon" &&
                                                                        t("page.addon.addon_title")}
                                                                </div>
                                                                <p className="results">
                                                                    {CategoryLength}{" "}
                                                                    {type === "product" &&
                                                                        t("page.categories")}
                                                                    {type === "addon" &&
                                                                        t("page.addon.addon_title")}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="topTitleRow rightSide">
                                                            <div className="tableTitles">
                                                                <div className="titleAndIcon">
                                                                    <AssignProductIcon />

                                                                    {type === "product" &&
                                                                        t("page.products")}
                                                                    {type === "addon" &&
                                                                        t(
                                                                            "page.addon.addon_option"
                                                                        )}
                                                                </div>
                                                                <p className="results">
                                                                    {variants && variants.length}{" "}
                                                                    {t("page.item")}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="wholeContent">
                                                        <div className="leftContent">
                                                            {type === "product" &&
                                                                productCategories &&
                                                                productCategories.map((item, i) => (
                                                                    <div
                                                                        className={
                                                                            colStyle === i
                                                                                ? "catCols active"
                                                                                : "catCols"
                                                                        }
                                                                        onClick={() => {
                                                                            getCat(item._id);
                                                                            setColStyle(i);
                                                                        }}
                                                                        key={i}
                                                                    >
                                                                        {item.category}
                                                                        <div className="colArrow">
                                                                            <svg
                                                                                width="8"
                                                                                height="16"
                                                                                viewBox="0 0 8 16"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M1 14.3333L7 7.99992L0.999999 1.66658"
                                                                                    stroke="#646464"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            {type === "addon" &&
                                                                addonList &&
                                                                addonList.map((item, i) => (
                                                                    <div
                                                                        className={
                                                                            colStyle === i
                                                                                ? "catCols active"
                                                                                : "catCols"
                                                                        }
                                                                        onClick={() => {
                                                                            // getCat(item?._id);
                                                                            clickAddonItem(item);
                                                                            setColStyle(i);
                                                                            setFocusAddon(i);
                                                                        }}
                                                                        key={i}
                                                                    >
                                                                        {item?.addon_name}
                                                                        <div className="colArrow">
                                                                            <svg
                                                                                width="8"
                                                                                height="16"
                                                                                viewBox="0 0 8 16"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M1 14.3333L7 7.99992L0.999999 1.66658"
                                                                                    stroke="#646464"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                        </div>
                                                        <div className="rightContent">
                                                            {type === "product" &&
                                                                variants &&
                                                                variants.map((item, i) => (
                                                                    <div
                                                                        className="catCols"
                                                                        onClick={() => {
                                                                            getVariant(
                                                                                item.variant_id
                                                                            );
                                                                            setStep(2);
                                                                            setVariantID(
                                                                                item.variant_id
                                                                            );
                                                                        }}
                                                                        key={i}
                                                                    >
                                                                        {
                                                                            item.variant_list
                                                                                .variant_name
                                                                        }

                                                                        <div className="colArrow">
                                                                            {t("page.select")}
                                                                            <svg
                                                                                width="8"
                                                                                height="16"
                                                                                viewBox="0 0 8 16"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M1 14.3333L7 7.99992L0.999999 1.66658"
                                                                                    stroke="#646464"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                ))}

                                                            {type === "addon" &&
                                                                aOptionList?.option_list &&
                                                                aOptionList?.option_list?.map(
                                                                    (item, i) => (
                                                                        <div
                                                                            className="catCols"
                                                                            onClick={() => {
                                                                                clickAddonDetail(
                                                                                    item,
                                                                                    i
                                                                                );
                                                                            }}
                                                                            key={i}
                                                                        >
                                                                            {item?.option_name}
                                                                            <div className="colArrow">
                                                                                {t("page.select")}
                                                                                <svg
                                                                                    width="8"
                                                                                    height="16"
                                                                                    viewBox="0 0 8 16"
                                                                                    fill="none"
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                >
                                                                                    <path
                                                                                        d="M1 14.3333L7 7.99992L0.999999 1.66658"
                                                                                        stroke="#646464"
                                                                                        strokeWidth="1.5"
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round"
                                                                                    />
                                                                                </svg>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                )}
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <>
                                                    {type === "product" && (
                                                        <>
                                                            <div className="topLongGrayBox">
                                                                <p>{variant.variant_name}</p>
                                                            </div>
                                                            <div className="aboveOfTableRow">
                                                                <div
                                                                    className="title"
                                                                    style={{ color: "#000" }}
                                                                >
                                                                    {t("page.consumable_list")}
                                                                </div>
                                                                <Button
                                                                    className="customTransparentStockBtn"
                                                                    onClick={() => {
                                                                        setShowPopup(true);
                                                                    }}
                                                                >
                                                                    <AssignEditIcon />
                                                                    &nbsp;
                                                                    <span
                                                                        style={{
                                                                            fontWeight: "700",
                                                                        }}
                                                                    >
                                                                        {t("page.add_consumable")}
                                                                    </span>
                                                                </Button>
                                                            </div>
                                                            <Table
                                                                columns={columns}
                                                                dataSource={tableData}
                                                                bordered
                                                                rowKey={"consumable_id"}
                                                            />
                                                        </>
                                                    )}
                                                    {type === "addon" && (
                                                        <>
                                                            <div className="topLongGrayBox">
                                                                <p>{selectAOption.name}</p>
                                                            </div>
                                                            <div className="aboveOfTableRow">
                                                                <div
                                                                    className="title"
                                                                    style={{ color: "#000" }}
                                                                >
                                                                    {t("page.consumable_list")}
                                                                </div>
                                                                <Button
                                                                    className="customTransparentStockBtn"
                                                                    onClick={() => {
                                                                        setShowPopup(true);
                                                                    }}
                                                                >
                                                                    <AssignEditIcon />
                                                                    &nbsp;
                                                                    <span
                                                                        style={{
                                                                            fontWeight: "700",
                                                                        }}
                                                                    >
                                                                        {t("page.add_consumable")}
                                                                    </span>
                                                                </Button>
                                                            </div>
                                                            <Table
                                                                columns={columns}
                                                                dataSource={tableData}
                                                                bordered
                                                            />
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </StyledBtnStock>
                                    </Col>
                                </Row>
                            </StyledCustom>
                        </StyledCard>
                    </div>
                    {step === 2 && (
                        <div className="btn-layout">
                            <StyledSubmitButton
                                type="default"
                                text={t("page.save")}
                                htmlType="button"
                                onClick={() => {
                                    handleAssignSubmit();
                                }}
                            />
                            <StyledCancelButton
                                type="sub"
                                text={t("page.cancel")}
                                htmlType="button"
                                onClick={() => {
                                    resetAllData();
                                }}
                            />
                        </div>
                    )}
                </div>
            </form>
            <ComponentAddConsumableModal
                showPopup={showPopup}
                handleClose={() => setShowPopup(!showPopup)}
                consumables={consumables}
                setConsumables={setConsumables}
                tempConsumable={tempConsumable}
                setTempConsumable={setTempConsumable}
                tableData={tableData}
                setTableData={setTableData}
                totalConsumables={totalConsumables}
                handlePagination={handlePagination}
                pagination={pagination}
                setTextSearch={setTextSearch}
            />
        </div>
    );
});

const StyledBtnStock = styled.div`
    .customTransparentStockBtn {
        height: 42px;
        border: 0.5px solid #646464;
        border-radius: 4px;
        background: transparent;
        color: #646464;
        padding: 0 25px;
    }
    .customTransparentStockBtn:hover {
        border: 0.5px solid #646464 !important;
    }
    .customBlueStockBtn {
        height: 42px;
        border: 0.5px solid transparent !important;
        border-radius: 4px;
        background: #0263e0;
        color: #fff;
        padding: 0 25px;
    }
`;

const StyledCustom = styled.div`
    .secondTitle {
        font-size: 20px;
    }
    .row {
        display: flex;
        align-items: center;
    }
    .step {
        display: flex;
        align-items: center;
        p {
            margin: 0;
            color: #0263e0;
            font-weight: 700;
            font-size: 16px;
        }
        .whiteText {
            color: #a5a5a5;
        }
    }
    .blueCircle {
        background: #0263e0;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: flex;
        place-content: center;
        align-items: center;
        color: #fff;
        font-size: 12px;
        font-weight: 700;
        margin-right: 10px;
    }
    .grayCircle {
        background: #a5a5a5;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: flex;
        place-content: center;
        align-items: center;
        color: #fff;
        font-size: 12px;
        font-weight: 700;
        margin-right: 10px;
    }
    .arrowRight {
        margin: auto 20px;
    }
    .ant-table-thead > tr > th {
        text-align: left !important;
    }
    .catCols {
        background: #f7f7f8;
        border-radius: 6px;
        display: flex;
        position: relative;
        padding: 20px;
        color: #646464;
        cursor: pointer;
        margin-bottom: 10px;
    }
    .catCols.active {
        background: #e1e1e1;
    }
    .colArrow {
        position: absolute;
        right: 20px;
        display: flex;
        gap: 10px;
        place-items: center;
        font-size: 12px;
    }
    .tableTitles {
        display: flex;
        justify-content: space-between;
        place-items: center;
        width: 100%;
        .results {
            color: #646464;
            font-size: 12px;
            font-weight: 300;
            margin: 0;
        }
    }
    .titleAndIcon {
        font-size: 16px;
        font-weight: 700;
        display: flex;
        gap: 10px;
        place-items: center;
    }
    .ant-table-thead > tr > th .ant-table-header-column {
        width: 100%;
    }
    .wholeTable {
        border: 1px solid #e1e1e1;
        position: relative;
        width: 100%;
        min-height: 30vh;
        border-radius: 6px;
    }
    .topTitleRow {
        background: #f7f7f8;
        display: flex;
        padding: 20px;
        border-top-left-radius: 6px;
        border-bottom: 1px solid #e1e1e1;
        justify-content: space-between;
        border-right: 1px solid #e1e1e1;
    }
    .topTitleRow.leftSide {
        width: 35%;
    }
    .topTitleRow.rightSide {
        width: 65%;
        right: 0;
        border-right: none;
        border-top-left-radius: 0;
        border-top-right-radius: 6px;
    }
    .wholeTopRow {
        display: flex;
    }
    .leftContent {
        padding: 10px;
        width: 35%;
        border-right: 1px solid #e1e1e1;
        overflow: scroll;
    }
    .rightContent {
        padding: 10px;
        width: 65%;
        height: 100%;
        overflow: scroll;
    }
    .wholeContent {
        display: flex;
        height: 50vh;
    }
    .topLongGrayBox {
        background: #f7f7f8;
        border-radius: 6px;
        padding: 20px;
        margin-bottom: 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
            margin: 0;
            color: #000;
            font-weight: 700;
        }
    }
    .customTransparentStockBtn {
        height: 42px;
        border: 0.5px solid #646464;
        border-radius: 4px;
        background: #fff;
        color: #646464;
        padding: 0 25px;
        display: flex;
        gap: 2px;
        align-items: center;
    }
    .customTransparentStockBtn:hover {
        border: 0.5px solid #646464 !important;
    }
    .aboveOfTableRow {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;
        .title {
            margin-bottom: 0 !important;
        }
    }
    .ant-input-group-addon {
        width: 70px;
    }
`;

const StyledAvatar = styled.div`
    .avatar {
        margin-bottom: 20px;
        position: relative;
        .box_title {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            p {
                margin: 0 4px 0 0;
            }
            button {
                padding: 0 4px;
                border: 0;
                background: transparent;
                height: auto;
            }
        }
    }
    .avatar .box_title {
        margin: 0 0 20px 0px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 1.5em;
    }
    .avatar-layout {
        position: relative;
        width: 110px;
        &:hover {
            .remove-icon-layout {
                visibility: visible;
            }
        }
    }
    .remove-icon-layout {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
        visibility: hidden;
        display: flex;
        justify-content: end;
        padding: 8px;
    }
    .avatar .box_title span {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 1.5em;
        position: relative;
        top: -1px;
        display: inline-block;
        margin-left: 4px;
    }
    .avatar .camera {
        width: 33px;
        height: 33px;
        background-color: #000000;
        border-radius: 50%;
        opacity: 0.29;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 110px;
        bottom: 0;
        transform: translate(-50%, 50%);
        cursor: pointer;
    }
`;
