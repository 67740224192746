import { useTranslation } from "react-i18next";
import { Switch, Col, Row, Menu, Dropdown, Button } from "antd";
import { MoreHorizontal } from "react-feather";
import {
    IGetSettingCheckStockConsumableListByStoreIdExample,
    IGetSettingCheckStockProductListByStoreIdExample,
    IUpdateSettingCheckStockType,
} from "@interfaces";
import { useNotify } from "@utils";

interface IProps {
    data: IGetSettingCheckStockProductListByStoreIdExample &
        IGetSettingCheckStockConsumableListByStoreIdExample;
    handleEdit: (
        status: IUpdateSettingCheckStockType,
        data: IGetSettingCheckStockProductListByStoreIdExample &
            IGetSettingCheckStockConsumableListByStoreIdExample
    ) => void;
    callbackGetList: () => void;
}

export const ComponentRowSettingCategoryCheckStock = (props: IProps) => {
    const { t } = useTranslation();
    const { success, error } = useNotify();
    const { data, handleEdit, callbackGetList } = props;

    const handleDelete = async (item: any) => {
        try {
            await console.log("API calling");
        } catch (err: any) {
            if (err) {
                error(err.msg);
            } else error(t("message.delete.fail"));
        }
    };

    const menu = (
        <Menu>
            <Menu.Item key={2} onClick={() => handleDelete(data.product_id)}>
                <div className="duplicate-btn">{t("page.delete")}</div>
            </Menu.Item>
        </Menu>
    );
    const handleUpdateStatus = (e: boolean, type) => {
        let temp: IUpdateSettingCheckStockType = {
            is_check_daily: type === "daily" ? e : data.is_check_daily,
            is_check_weekly: type === "weekly" ? e : data.is_check_weekly,
            is_check_monthly: type === "monthly" ? e : data.is_check_monthly,
        };
        handleEdit(temp, data);
    };

    return (
        <div className="table-element">
            <div className="table-row">
                <Row
                    style={{ height: "100%" }}
                    gutter={24}
                    type="flex"
                    align="middle"
                    justify="space-between"
                >
                    <Col className="col-item" span={9}>
                        <p className="news-title">{data.product_name || data.consumable_name}</p>
                    </Col>
                    <Col className="col-item visible-md" span={4}>
                        <Switch
                            onChange={(e) => handleUpdateStatus(e, "daily")}
                            checked={data.is_check_daily}
                        />
                    </Col>
                    <Col className="col-item price" span={4}>
                        <Switch
                            onChange={(e) => handleUpdateStatus(e, "weekly")}
                            checked={data.is_check_weekly}
                        />
                    </Col>
                    <Col className="col-item price" span={4}>
                        <Switch
                            onChange={(e) => handleUpdateStatus(e, "monthly")}
                            checked={data.is_check_monthly}
                        />
                    </Col>
                    <Col className="col-item" span={3} style={{ justifyContent: "end" }}>
                        <Dropdown
                            //@ts-ignore
                            getPopupContainer={(trigger) => trigger.parentNode}
                            overlay={menu}
                            trigger={["click"]}
                            placement="bottomRight"
                        >
                            <Button style={{ border: "unset" }}>
                                <MoreHorizontal />
                            </Button>
                        </Dropdown>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
