import {
    ICreateEmployee,
    IDeleteEmployee,
    IGetEmployee,
    IGetEmployees,
    IGetStoreEmployees,
    ISendMailEmployee,
    IUpdateEmployee,
} from "@interfaces";

import storeFrontAxiosClient from "./storeFrontAxiosClient";

export const permissionPosAPI = {
    getList: (params: IGetStoreEmployees) => {
        const url = "employee/getStoresWithEmployees";
        return storeFrontAxiosClient.get(url, { params });
    },
    getEmployee: (params: IGetEmployee) => {
        const url = "employee/getEmployee";
        return storeFrontAxiosClient.get(url, { params });
    },
    getEmployees: (params: IGetEmployees) => {
        const url = "employee/getEmployees";
        return storeFrontAxiosClient.get(url, { params });
    },
    updateEmployee: (params: IUpdateEmployee) => {
        const url = "employee/updateEmployee";
        return storeFrontAxiosClient.patch(url, params);
    },
    deleteEmployee: (params: IDeleteEmployee) => {
        const url = "employee/deleteEmployee";
        return storeFrontAxiosClient.post(url, params);
    },
    createEmployee: (params: ICreateEmployee) => {
        const url = "employee/createEmployee";
        return storeFrontAxiosClient.post(url, params);
    },
    sendMailEmployee: (params: ISendMailEmployee) => {
        const url = "employee/sendMailEmployeeData";
        return storeFrontAxiosClient.get(url, { params });
    },
};
