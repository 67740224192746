import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { StyledNewDetail } from "@components";
import { PATH_ROLES_PERMISSIONS, PATH_ROLES_PERMISSIONS_List } from "@configs";
import { useNotify, showErrorMessage } from "@utils";
import { setLoading, selectAuth } from "@redux";
import { rolePosAPI } from "@api";
import { ModuleRolesPermissionsForm } from "src/Modules/roles_permissions";
import { IGetPosRoleTemplateExample, IGetPosRolesExample } from "@interfaces";

export const PageRolesPermissionsSetting = () => {
    const { id } = useParams<{ id: string }>();
    const org_id = useSelector(selectAuth).userInfo?.merchant._id;
    const dispatch = useDispatch();
    const history = useHistory();
    const { warning } = useNotify();
    const { t } = useTranslation();
    const [posRole, setPosRole] = useState<IGetPosRolesExample>();
    const [posRoleTemplate, setPosRoleTemplate] = useState<IGetPosRoleTemplateExample>();
    const pathName = history.location.pathname;

    useEffect(() => {
        fetchNews();
    }, []);

    const fetchNews = async () => {
        dispatch(setLoading(true));
        if (pathName !== PATH_ROLES_PERMISSIONS) {
            try {
                const getStore = await rolePosAPI.getPosRole({
                    organization_id: org_id,
                    pos_role_id: id,
                });
                setPosRole(getStore.data.result);
                dispatch(setLoading(false));
            } catch (err: any) {
                if (err.response) {
                    warning(showErrorMessage(err.response));
                    dispatch(setLoading(false));
                } else warning(t("message.not_found", { name: t("object._reward") }));
                history.push(PATH_ROLES_PERMISSIONS_List);
            } finally {
                dispatch(setLoading(false));
            }
        } else {
            try {
                const getStore = await rolePosAPI.getPosRoleTemplate({
                    organization_id: org_id,
                });
                setPosRoleTemplate(getStore.data.result);
                dispatch(setLoading(false));
            } catch (err: any) {
                if (err.response) {
                    warning(showErrorMessage(err.response));
                    dispatch(setLoading(false));
                } else warning(t("message.not_found", { name: t("object._reward") }));
                history.push(PATH_ROLES_PERMISSIONS_List);
            } finally {
                dispatch(setLoading(false));
            }
        }
    };

    return (
        <StyledNewDetail>
            {<ModuleRolesPermissionsForm posRole={posRole} posRoleTemplate={posRoleTemplate} />}
        </StyledNewDetail>
    );
};
