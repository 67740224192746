import React, { useEffect, memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Row, Col, Radio, Checkbox, Input, Table, Avatar, Pagination, Typography } from "antd";
import styled from "styled-components";
import {
    IPaginationNext,
    ICreatePosPromotion,
    IProduct,
    IProductCategories,
    IGetVariantsExample,
    ITemplateAddon,
    ITemplateProduct,
    IOrderValue,
    IPostCreateChannel,
} from "@interfaces";
import {
    StyledSubmitButton,
    StyledCancelButton,
    StyledCard,
    IconCamera,
    ColumnRightArrow,
    SelectedProductBadge,
    SharedInput,
} from "@components";
import { setLoading, selectAuth } from "@redux";
import { showErrorMessage, useNotify } from "@utils";
import { BLANK_IMAGE_URL, PAGE_START, PAGINATION, PATH_WHOLESALE_CHANNEL, PATH_WHOLESALE_EDIT } from "@configs";
import { orgApi, productAPI, wholesaleAPI } from "@api";
import { AssignCategoryIcon } from "src/Components/icon/AssignCategoryIcon";
import { AssignProductIcon } from "src/Components/icon/AssignProductIcon";
import { DeleteIcon } from "src/Components/icon/DeleteIcon";
const { Text } = Typography;

const INITAL_PAGE = {
    xpage: PAGE_START,
    xlimit: 24,
};

const initProduct: ICreatePosPromotion = {
    organization_id: "",
    pos_promotion_name: "",
    pos_promotion_image_url: "",
    client_type: "",
    promotion_type: "",
    condition_type: "qty",
    promotion_hour_type: "all_hour",
    promotion_hours: {
        monday: {
            is_active: true,
            open_hour: "00:00",
            close_hour: "23:59",
        },
        tuesday: {
            is_active: true,
            open_hour: "00:00",
            close_hour: "23:59",
        },
        wednesday: {
            is_active: true,
            open_hour: "00:00",
            close_hour: "23:59",
        },
        thursday: {
            is_active: true,
            open_hour: "00:00",
            close_hour: "23:59",
        },
        friday: {
            is_active: true,
            open_hour: "00:00",
            close_hour: "23:59",
        },
        saturday: {
            is_active: true,
            open_hour: "00:00",
            close_hour: "23:59",
        },
        sunday: {
            is_active: true,
            open_hour: "00:00",
            close_hour: "23:59",
        },
    },
    item_type: "product",
    product_list: [],
    product_category_list: [],
    variant_free_list: [],
    has_free_variant: false,
    discount: 0,
    new_price: 0,
    remark: "",
    is_discount_step: false,
    discount_step_list: [],
    is_validity_period: false,
    validity_date_from_string: "",
    validity_date_to_string: "",
    validity_time_from_string: "",
    validity_time_to_string: "",
    is_not_deduct_basket: false,
};

export const ModuleWholesaleForm = memo(() => {
    //hook
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const { error, success, warning } = useNotify();
    const organization_id = useSelector(selectAuth).userInfo?.merchant?._id;
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const pathName = window.location.pathname;
    const [pagination, setPagination] = useState<IPaginationNext>(INITAL_PAGE);
    //
    const [productCategories, setProductCategories] = useState<any>([]);
    const [variants, setVariants] = useState<any>([]);
    const [colStyle, setColStyle] = useState<any>();
    const [step, setStep] = useState<any>(1);
    const [type, setType] = useState<string>("product");

    const [product, setProduct] = useState<ICreatePosPromotion>(initProduct);
    const [products, setProducts] = useState<IProduct[]>([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState<string>("");
    const [totalProducts, setTotalProducts] = useState<number>();
    const [templateAddon, setTemplateAddon] = useState<ITemplateAddon[]>([]);
    const [templateProduct, setTemplateProduct] = useState<ITemplateProduct[]>([]);
    const [startProduct, setStateProduct] = useState<ITemplateProduct[]>([]);
    const [orderValue, setOrderValue] = useState<IOrderValue>({
        sale_channel_name: "",
        sale_channel_code: "",
        bp_code: "",
        image_url: "",
        gb_percent: 30,
        service_type: "delivery",
        remark: "",
        hint_code: "",
    });
    const [isAll, setIsAll] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [configDetail, setConfigDetail] = useState<any>();

    useEffect(() => {
        if (id) {
            getChannelEdit();
            setIsEdit(true);
        }
    }, [id]);
    useEffect(() => {
        fetchNews();
        fetchORGSetting();
    }, []);
    useEffect(() => {
        getProducts(selectedCategoryId);
    }, [pagination]);

    const getChannelEdit = async () => {
        let res = await wholesaleAPI.getChannel({
            organization_id: organization_id || "",
            sale_channel_id: id,
        });
        let oObj = {
            sale_channel_name: res?.data?.result?.sale_channel_name,
            sale_channel_code: res?.data?.result?.sale_channel_code,
            bp_code: res?.data?.result?.bp_code,
            image_url: res?.data?.result?.image_url,
            gb_percent: res?.data?.result?.gb_percent,
            service_type: res?.data?.result?.service_type,
            remark: res?.data?.result?.remark,
            hint_code: res?.data?.result?.hint_code,
        };
        setOrderValue(oObj);
        let pList: any[] = [];
        let pArr: ITemplateProduct[] = [];
        res?.data?.result?.product_list?.forEach((pItem, pIndex) => {
            pList.push({
                _id: pItem?.product_id,
            });
            let pObj: ITemplateProduct = {
                image_url_list: [pItem?.image_url],
                is_variant: pItem?.variant_list.length > 1 ? true : false,
                name: pItem?.name,
                variant_list: [],
                _id: pItem?.product_id,
            };
            pItem?.variant_list?.forEach((vItem, vIndex) => {
                let vObj = {
                    // ...vItem,
                    index: vIndex,
                    variant_code: vItem?.variant_code,
                    variant_name: vItem?.variant_name,
                    _id: vItem?.variant_id,
                    product_index: pIndex,
                    price: vItem?.original_price,
                    channel_price: vItem?.channel_price,
                };
                //@ts-ignore
                pObj.variant_list.push(vObj);
            });
            pArr.push(pObj);
        });
        setTableData(pList);
        setStateProduct(pArr);
        if (pathName.includes(PATH_WHOLESALE_EDIT)) {
            let newList: ITemplateAddon[] = [];
            res?.data?.result?.product_addon_list?.forEach((pItem, pIndex) => {
                let pObj = {
                    product_addon_id: pItem?.product_addon_id,
                    product_addon_name: pItem?.name,
                    option_list: [],
                };
                pItem?.option_list?.forEach((oItem, oIndex) => {
                    let oObj = {
                        ...oItem,
                        index: oIndex,
                        product_addon_id: pItem?.product_addon_id,
                        product_addon_name: pItem?.name,
                        product_index: pIndex,
                        price: oItem?.original_price,
                    };
                    //@ts-ignore
                    pObj.option_list.push(oObj);
                });
                newList.push(pObj);
            });
            setTemplateAddon(newList);
        }
        // for skipp step 1 page
        // fetchAllTemplate();
        // setTemplateProduct(pArr);
        // setStep(2);
    };

    const fetchORGSetting = async () => {
        const getConfig = await orgApi.getVatDetail({
            organization_id: organization_id || "",
        });
        setConfigDetail(getConfig?.data?.result);
    };

    const clickAll = (e: any) => {
        setIsAll(e.target.checked);
        if (e.target.checked) {
            fetchAll();
        } else {
            setTableData([]);
        }
    };

    const fetchAll = async () => {
        dispatch(setLoading(true));
        try {
            const res = await productAPI.getList({
                organization_code: orgCode,
                category_id: "",
                text_search: "",
                xlimit: 999,
                xpage: 1,
            });

            let arr: any = [];
            res.data?.result?.forEach((cItem: any) => {
                arr.push({
                    _id: cItem?._id,
                });
            });
            setTableData([...arr]);
        } catch (err: any) {
            if (err.response) {
                error(showErrorMessage(err.response));
            } else error(t("message.error"));
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const getProducts = async (id?: string) => {
        dispatch(setLoading(true));
        try {
            const get = await productAPI.getList({
                organization_code: orgCode,
                category_id: id,
                text_search: "",
                xlimit: pagination.xlimit,
                xpage: pagination.xpage,
            });
            setProducts(get.data.result);
            setTotalProducts(get.data.total);
        } catch (err: any) {
            if (err.response) {
                error(showErrorMessage(err.response));
            } else error(t("message.error"));
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const getVariants = async (id?: string) => {
        dispatch(setLoading(true));
        try {
            const getAllVariants = await productAPI.getVariants({
                organization_id: organization_id,
                xpage: pagination.xpage,
                xlimit: pagination.xlimit,
                category_id: id,
            });
            setVariants(getAllVariants.data.result);
            setTotalProducts(getAllVariants.data.total);
        } catch (err: any) {
            if (err.response) {
                error(showErrorMessage(err.response));
            } else error(t("message.error"));
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const selectCategoryHandler = (item: IProductCategories) => {
        //
    };
    const selectProductHandler = (item: IProduct) => {
        setTableData([...tableData, { _id: item?._id }]);
        const index = tableData.findIndex((x) => x._id === item._id);
        if (index > -1) {
            let temp = [...tableData];
            temp.splice(index, 1);
            setTableData(temp);
        }
    };
    const handlePagination = (page) => {
        setPagination({ ...pagination, xpage: page });
    };
    const selectVariantHandler = (item: IGetVariantsExample) => {
        //
    };
    const scrollToTop = async () => {
        await window.scrollTo({
            top: 0,
            behavior: 'smooth' // smooth scrolling
        });
    };
    const step1next = () => {
        fetchAllTemplate();
        fetchProductSelect();
        setStep(2);
    };
    const step2next = async () => {
        try {
            dispatch(setLoading(true));

            let payload: IPostCreateChannel = {
                organization_id: organization_id || "",
                sale_channel_name: orderValue?.sale_channel_name,
                sale_channel_code: orderValue?.sale_channel_code,
                bp_code: orderValue?.bp_code,
                image_url: orderValue?.image_url,
                gb_percent: orderValue?.gb_percent,
                service_type: orderValue?.service_type,
                remark: orderValue?.remark,
                product_list: [],
                product_addon_list: [],
                hint_code: orderValue?.hint_code,
            };
            await templateProduct.forEach((pItem, pIndex) => {
                let pObj = {
                    product_id: pItem?._id,
                    variant_list: [],
                };
                pItem?.variant_list?.forEach((vItem, vIndex) => {
                    let vObj = {
                        variant_id: vItem?._id,
                        channel_price: Number(vItem?.channel_price),
                    };
                    //@ts-ignore
                    pObj.variant_list.push(vObj);
                });
                if (pObj?.variant_list?.length === 0) {
                    error("product variant list is empty");
                    return;
                } else {
                    //@ts-ignore
                    payload.product_list?.push(pObj);

                }
            });
            await templateAddon.forEach((aItem) => {
                let aObj = {
                    product_addon_id: aItem?.product_addon_id,
                    option_list: [],
                };
                aItem?.option_list.forEach((oItem) => {
                    let oObj = {
                        option_id: oItem?.option_id,
                        channel_price: Number(Math.abs(oItem?.channel_price)),
                    };
                    //@ts-ignore
                    aObj.option_list.push(oObj);
                });
                //@ts-ignore
                payload.product_addon_list.push(aObj);
            });
            if (isEdit) {
                let updateRes = await wholesaleAPI.updateOrder({
                    ...payload,
                    sale_channel_id: id,
                });
                if (updateRes?.data?.error === "0") {
                    success(t("message.update.success"));
                    history.push(PATH_WHOLESALE_CHANNEL);
                } else {
                    console.error(updateRes.data);
                    error(t("message.update.fail"));
                }
            } else {
                let res = await wholesaleAPI.createOrder(payload);
                if (res.data.error === "0") {
                    success(t("message.create.success"));
                    history.push(PATH_WHOLESALE_CHANNEL);
                } else {
                    console.error(res.data);
                    error(t("message.create.fail"));
                }
            }
        } catch (err: any) {
            console.error(err);
        } finally {
            dispatch(setLoading(false));
        }
    };



    const fetchProductSelect = async () => {
        dispatch(setLoading(true));
        let arr: string[] = [];
        let out2: ITemplateProduct[] = [];
        let oldArr: string[] = [];

        await tableData.forEach((tItem) => {
            arr.push(tItem._id);
        });
        if (isEdit) {
            out2 = startProduct.filter((obj1) => tableData.some((obj2) => obj1._id === obj2._id));
            let out3 = tableData.filter((obj1) => out2.some((obj2) => obj1._id === obj2._id));
            out3.forEach((tItem) => {
                oldArr.push(tItem._id);
            });
        }
        // if (!isEdit) {
        //     await tableData.forEach((tItem) => {
        //         arr.push(tItem._id);
        //     });
        // } else {
        //     out2 = startProduct.filter((obj1) => tableData.some((obj2) => obj1._id === obj2._id));
        //     let out3 = tableData.filter((obj1) => !out2.some((obj2) => obj1._id === obj2._id));
        //     out3.forEach((tItem) => {
        //         arr.push(tItem._id);
        //     });
        // }

        let payload = {
            organization_id: organization_id || "",
            product_id_list: arr,
        };
        let res = await wholesaleAPI.getProductSelect(payload);

        let newList: ITemplateProduct[] = [];
        res?.data?.result?.forEach((pItem, pIndex) => {
            let findIndex = startProduct.findIndex((obj) => obj._id === pItem._id);

            let pObj = {
                image_url_list: pItem.image_url_list,
                is_variant: pItem.is_variant,
                name: pItem.name,
                variant_list: [],
                _id: pItem?._id,
            };
            if (findIndex !== -1 && pItem?.variant_list?.length !== startProduct?.[findIndex]?.variant_list?.length) {
                let arr3 = pItem.variant_list.filter((item2) => !startProduct[findIndex]?.variant_list.some((item1) => item1._id === item2._id));

                //@ts-ignore
                pObj.variant_list = startProduct[findIndex].variant_list;
                arr3.forEach((vItem, vIndex) => {
                    let vObj = {
                        ...vItem,
                        index: pObj.variant_list.length,
                        variant_code: vItem?.variant_code,
                        variant_name: vItem?.variant_name,
                        _id: vItem?._id,
                        product_index: pIndex,
                        channel_price: vItem?.price,
                    };
                    //@ts-ignore
                    pObj.variant_list.push(vObj);
                });
                //@ts-ignore
                // pObj.variant_list = startProduct?.[findIndex]?.variant_list;
            } else if (findIndex === -1) {
                pItem?.variant_list?.forEach((vItem, vIndex) => {
                    let vObj = {
                        ...vItem,
                        index: vIndex,
                        variant_code: vItem?.variant_code,
                        variant_name: vItem?.variant_name,
                        _id: vItem?._id,
                        product_index: pIndex,
                        channel_price: vItem?.price,
                    };
                    //@ts-ignore
                    pObj.variant_list.push(vObj);
                });
            } else {
                //@ts-ignore
                pObj.variant_list = startProduct?.[findIndex]?.variant_list;
            }
            newList.push(pObj);
        });
        if (!isEdit) {
            setTemplateProduct(newList);
        } else {
            setTemplateProduct([
                // ...out2,
                ...newList]);
        }
        dispatch(setLoading(false));
    };

    const fetchAllTemplate = async () => {
        let res = await wholesaleAPI.getTemplate({
            organization_id: organization_id || "",
        });
        let newList: ITemplateAddon[] = [];
        res?.data?.result?.forEach((pItem, pIndex) => {
            let pObj = {
                product_addon_id: pItem?.product_addon_id,
                product_addon_name: pItem?.product_addon_name,
                option_list: [],
            };
            pItem?.option_list?.forEach((oItem, oIndex) => {
                let oObj = {
                    ...oItem,
                    index: oIndex,
                    product_addon_id: pItem?.product_addon_id,
                    product_addon_name: pItem?.product_addon_name,
                    product_index: pIndex,
                };
                //@ts-ignore
                pObj.option_list.push(oObj);
            });
            newList.push(pObj);
        });
        if (!isEdit) {
            setTemplateAddon(newList);
        }


    };

    const fetchNews = async () => {
        dispatch(setLoading(true));
        try {
            const getAllProductCats = await orgApi.getCat({
                organization_code: orgCode,
            });
            setProductCategories(getAllProductCats.data.result);
        } catch (err: any) {
            if (err.response) {
                error(showErrorMessage(err.response));
            } else error(t("message.error"));
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleCancel = () => {
        history.push(PATH_WHOLESALE_CHANNEL);
    };

    // Edit Stock Table
    interface IMyTableData {
        consumable_name: string;
        consumable_unit_name: string;
        used_qty: number;
        consumable_id: string;
    }
    const [tableData, setTableData] = useState<any[]>([]);

    const columnTemplateProduct: any = [
        {
            title: t("page.product"),
            dataIndex: "name",
            width: "30%",
            render: (text, record) => {
                return (
                    <div
                        style={{ maxWidth: "220px", wordWrap: "break-word", whiteSpace: "initial" }}
                    >
                        {text}
                    </div>
                );
            },
        },
        {
            title: t("page.wholesale.table_store_price"),
            width: "20%",
            render: (text, record) => {
                if (!record?.is_variant) {
                    return (
                        <>
                            <p style={{ color: "black", textAlign: "center" }}>
                                {record?.variant_list[0]?.price}
                            </p>
                        </>
                    );
                }
            },
        },
        {
            title: t("page.wholesale.table_selling"),
            width: "20%",
            render: (text, record) => {
                if (!record?.is_variant) {
                    return (
                        <div className="price-field">
                            <Input
                                className="price-input"
                                value={record?.variant_list[0]?.channel_price}
                                onChange={(e) => changePriceProduct(e, record)}
                            />
                        </div>
                    );
                }
            },
        },
        {
            title: t("page.wholesale.table_dif"),
            width: "15%",
            render: (text, record) => {
                if (!record?.is_variant) {
                    return (
                        <>
                            <Text style={{ color: "black" }}>
                                <span>
                                    {Math.abs(
                                        record?.variant_list[0].price -
                                        record?.variant_list[0].channel_price
                                    )}
                                </span>
                            </Text>
                        </>
                    );
                }
            },
        },
        {
            title: "GP" + orderValue?.gb_percent + "%",
            width: "15%",
            render: (text, record) => {
                if (!record?.is_variant) {
                    return (
                        <>
                            <Text style={{ color: "black" }}>
                                <span>
                                    {Math.abs(
                                        (record?.variant_list[0]?.channel_price *
                                            orderValue?.gb_percent) /
                                        100
                                    )}
                                </span>
                            </Text>
                        </>
                    );
                }
            },
        },
        {
            title: t("page.wholesale.table_delete"),
            width: "10%",
            render: (text, record) => {
                return (
                    <div style={{ cursor: "pointer" }} onClick={() => clickDelete(record)}>
                        <DeleteIcon size={14} color="#6C7084" />
                    </div>
                );
            },
        },
    ];
    const variantColumn: any = [
        {
            title: "variant name",
            dataIndex: "variant_name",
            width: "25%",
            render: (text, record) => {
                return (
                    <div
                        style={{ maxWidth: "180px", wordWrap: "break-word", whiteSpace: "initial" }}
                    >
                        {text}
                    </div>
                );
            },
        },
        {
            title: "store price",
            dataIndex: "price",
            width: "17%",
            render: (text, record) => {
                return (
                    <>
                        <p style={{ color: "black", textAlign: "center" }}>{record?.price}</p>
                    </>
                );
            },
        },
        {
            title: "selling price",
            dataIndex: "channel_price",
            width: "20%",
            render: (text, record) => {
                return (
                    <div className="price-field">
                        <Input
                            className="price-input"
                            value={record?.channel_price}
                            onChange={(e) => changePriceVariant(e, record)}
                        />
                    </div>
                );
            },
        },
        {
            title: "Difference",
            width: "15%",
            render: (text, record) => {
                return (
                    <>
                        <Text style={{ color: "black" }}>
                            <span>{Math.abs(record?.price - record?.channel_price)}</span>
                        </Text>
                    </>
                );
            },
        },
        {
            title: "GP" + orderValue?.gb_percent + "%",
            width: "15%",
            render: (text, record) => {
                return (
                    <>
                        <Text>
                            {Math.abs((record?.channel_price * orderValue?.gb_percent) / 100)}
                        </Text>
                    </>
                );
            },
        },
        {
            title: "Delete",
            width: "10%",
        },
    ];
    const columnTemplateAddon: any = [
        {
            title: t("page.wholesale.table_addon"),
            dataIndex: "product_addon_name",
            width: "20%",
            render: (text, record) => {
                return (
                    <div
                        style={{ maxWidth: "180px", wordWrap: "break-word", whiteSpace: "initial" }}
                    >
                        {text}
                    </div>
                );
            },
        },
        {
            title: t("page.wholesale.table_store_price"),
            width: "20%",
        },
        {
            title: t("page.wholesale.table_selling"),
            width: "20%",
        },
        {
            title: t("page.wholesale.table_dif"),
            width: "20%",
        },
        {
            title: "GP" + orderValue?.gb_percent + "%",
            width: "20%",
        },
    ];
    const optionColumn: any = [
        {
            title: "option name",
            dataIndex: "option_name",
            width: "20%",
            render: (text, record) => {
                return (
                    <div
                        style={{ maxWidth: "180px", wordWrap: "break-word", whiteSpace: "initial" }}
                    >
                        {text}
                    </div>
                );
            },
        },
        {
            title: "store price",
            dataIndex: "price",
            width: "20%",
            render: (text, record) => {
                return (
                    <div className="price-field">
                        <Text style={{ color: "black" }}>
                            <span>{record?.price}</span>
                        </Text>
                    </div>
                );
            },
        },
        {
            title: "selling price",
            dataIndex: "channel_price",
            width: "20%",
            render: (text, record) => {
                return (
                    <div className="price-field">
                        <Input
                            className="price-input"
                            value={record?.channel_price}
                            onChange={(e) => changePriceAddon(e, record)}
                        />
                    </div>
                );
            },
        },
        {
            title: "Difference",
            width: "20%",
            render: (text, record) => {
                return (
                    <>

                        <Text style={{ color: "black" }}>
                            <span>{Math.abs(record?.price - record?.channel_price)}</span>
                        </Text>
                    </>
                );
            },
        },
        {
            title: "GP" + orderValue?.gb_percent + "%",
            width: "20%",
            render: (text, record) => {
                return (
                    <>
                        <Text style={{ color: "black" }}>
                            {Math.abs((record?.channel_price * orderValue?.gb_percent) / 100)}
                        </Text>
                    </>
                );
            },
        },
    ];

    const changeName = (e) => {
        let arr = { ...orderValue };
        arr.sale_channel_name = e.target.value;
        setOrderValue({
            ...arr,
        });
    };

    const changeHint = (e) => {
        let arr = { ...orderValue };
        arr.hint_code = e.target.value;
        setOrderValue({
            ...arr,
        });
    };

    const changeGP = (e) => {
        let arr = { ...orderValue };
        arr.gb_percent = Number(e.target.value);
        setOrderValue({ ...arr });
    };

    const changeChannelCode = (e) => {
        let arr = { ...orderValue };
        arr.sale_channel_code = e.target.value;
        setOrderValue({
            ...arr,
        });
    };
    const changeBPCode = (e) => {
        let arr = { ...orderValue };
        arr.bp_code = e.target.value;
        setOrderValue({
            ...arr,
        });
    };

    const setTypeService = (text) => {
        let arr = { ...orderValue };
        arr.service_type = text;
        setOrderValue({ ...arr });
    };

    const changePriceAddon = (e, record) => {
        let arr = [...templateAddon];
        if (arr[record?.product_index].option_list[record?.index]) {
            arr[record?.product_index].option_list[record?.index].channel_price = e.target.value;
        }
        setTemplateAddon([...arr]);
    };

    const changePriceProduct = (e, record) => {
        let arr = [...templateProduct];
        arr[record?.variant_list[0]?.product_index].variant_list[0].channel_price = e.target.value;
        setTemplateProduct([...arr]);
    };

    const changePriceVariant = (e, record) => {
        let arr = [...templateProduct];
        arr[record?.product_index].variant_list[record?.index].channel_price = e.target.value;
        setTemplateProduct([...arr]);
    };

    const clickDelete = (record) => {
        let arr = [...templateProduct];
        let outArr = arr.filter((item, index) => index !== record?.variant_list[0].index);
        setTemplateProduct([...outArr]);
    };

    const handleChangeImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
        try {
            //@ts-ignore
            const image = e.target.files[0];
            if (!image) {
                //WHAT: image empty
                error(t("validation.image.empty"));
                return;
            }
            const formData = new FormData();
            formData.append("file", image);
            // const res = await merchantAPI.uploadImage(formData);
            const res = await orgApi.uploadImage(formData);
            setOrderValue(res.data.result.publicUrl);
            setOrderValue({
                ...orderValue,
                image_url: res.data.result.publicUrl,
            });
        } catch (err: any) {
            //
            // const data = error.response.data;
            if (err) {
                error(err.msg);
            } else {
                error(t("page.image_error"));
            }
        }
    };

    return (
        <div className="form-wrap">
            <form>
                <div className="page-header">
                    <h3>{t("page.wholesale.add_channel")}</h3>
                </div>
                <div className="page-body">
                    <div className="card-wrap">
                        <StyledCard style={{ padding: "1.5rem" }}>
                            <StyledCustom>
                                <div className="row">
                                    <div className="step">
                                        <div className="blueCircle">1</div>
                                        <p className="title2">
                                            {t("page.wholesale.channel_settings")}
                                        </p>
                                    </div>
                                    <div className="arrowRight">
                                        <svg
                                            width="14"
                                            height="11"
                                            viewBox="0 0 14 11"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M12.1997 5.09865L8.0354 1.46012C7.83577 1.28569 7.81534 0.982461 7.98976 0.782832C8.16419 0.583203 8.46742 0.562773 8.66705 0.737199L13.787 5.21082C13.7884 5.21197 13.7897 5.21312 13.791 5.21428C13.8935 5.30231 13.9585 5.4329 13.9585 5.57865C13.9585 5.75719 13.861 5.91295 13.7164 5.99565L8.66756 10.4197C8.46818 10.5944 8.16492 10.5744 7.99021 10.375C7.8155 10.1756 7.8355 9.87235 8.03488 9.69765L12.1878 6.05865L0.841075 6.05865C0.575978 6.05865 0.361074 5.84375 0.361074 5.57865C0.361074 5.31356 0.575978 5.09865 0.841075 5.09865L12.1997 5.09865Z"
                                                fill="#919EAB"
                                            />
                                        </svg>
                                    </div>
                                    <div className="step">
                                        <div className={step === 2 ? "blueCircle" : "grayCircle"}>
                                            2
                                        </div>
                                        <p className={step === 2 ? "title2" : "title2 whiteText"}>
                                            {t("page.wholesale.enter_price_product")}
                                        </p>
                                    </div>
                                </div>
                            </StyledCustom>
                        </StyledCard>
                    </div>
                </div>
                <div className="page-body">
                    <div className="card-wrap">
                        <StyledCard>
                            <div
                                className="title"
                                style={{
                                    fontSize: "25px",
                                    color: "#000",
                                    borderBottom: "none",
                                    marginBottom: 0,
                                    lineHeight: "25px",
                                }}
                            >
                                {step === 1
                                    ? t("page.wholesale.channel_settings")
                                    : t("page.wholesale.enter_price_product")}
                            </div>
                            {step === 1 && (
                                <StyleInputSection>
                                    <Row gutter={24}>
                                        <Col span={24}>
                                            <p className="input-title">
                                                {t("page.wholesale.channel")}
                                            </p>
                                        </Col>
                                        <Col span={24}>
                                            <Input
                                                value={orderValue?.sale_channel_name}
                                                onChange={changeName}
                                            />
                                        </Col>
                                    </Row>
                                    <Row gutter={24} style={{ marginTop: 40 }}>
                                        <Col span={12}>
                                            <SharedInput
                                                label={t("page.channel_code")}
                                                name="channel_code"
                                                onChange={changeChannelCode}
                                                value={orderValue?.sale_channel_code}
                                                parentClassName="mb-6"
                                                maxLength={8}
                                                disable={id ? true : false}
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <SharedInput
                                                label={t("page.bp_code")}
                                                name="bp_code"
                                                onChange={changeBPCode}
                                                value={orderValue?.bp_code}
                                                parentClassName="mb-6"
                                            />
                                        </Col>
                                    </Row>
                                    <Row gutter={24}>
                                        <Col span={24}>
                                            <p className="input-title">GP</p>
                                        </Col>
                                        <Col span={12}>
                                            <Input
                                                suffix="%"
                                                value={orderValue?.gb_percent}
                                                onChange={changeGP}
                                            />
                                        </Col>
                                    </Row>
                                    <Row gutter={24}>
                                        {configDetail?.hint_code_config?.is_sale_channel && (
                                            <Col md={12}>
                                                <br /> <br />
                                                <SharedInput
                                                    label={t("page.hint_code")}
                                                    name="hint_code"
                                                    onChange={changeHint}
                                                    value={orderValue?.hint_code}
                                                    parentClassName="mb-6"
                                                />
                                            </Col>
                                        )}
                                    </Row>
                                    <p className="input-title">
                                        {t("page.wholesale.type_service")}
                                    </p>
                                    <span
                                        className="radio-option"
                                        onClick={() => setTypeService("wholesale")}
                                    >
                                        <Radio
                                            className="radio"
                                            checked={orderValue?.service_type === "wholesale"}
                                            onClick={() => setTypeService("wholesale")}
                                        />
                                        {t("page.wholesale.wholesale")}
                                    </span>
                                    &nbsp; &nbsp;&nbsp;
                                    <span
                                        className="radio-option"
                                        onClick={() => setTypeService("delivery")}
                                    >
                                        <Radio
                                            className="radio"
                                            checked={orderValue?.service_type === "delivery"}
                                        />
                                        {t("page.wholesale.delivery")}
                                    </span>
                                    &nbsp;
                                    {/* <span className="radio-option" onClick={() => setTypeService("takeaway")}>
                                        <Radio
                                            className="radio"
                                            checked={orderValue?.service_type === "takeaway"}

                                        />
                                        Takeaway
                                    </span> */}
                                    &nbsp;
                                    <p className="input-title">{t("page.wholesale.channel_img")}</p>
                                    <StyledImgSelect>
                                        <div className="avatar">
                                            <div className="avatar-layout">
                                                <Avatar
                                                    size={110}
                                                    src={orderValue?.image_url}
                                                    shape="square"
                                                />
                                                <div className="remove-icon-layout">
                                                    {/* <DeleteOutlined
                                                    style={{ color: "white", fontSize: 20 }}
                                                    onClick={handleRemoveImage}
                                                /> */}
                                                </div>
                                            </div>
                                            <input
                                                id="file_update_img"
                                                type="file"
                                                accept="image/png, image/gif, image/jpeg"
                                                hidden
                                                onChange={handleChangeImage}
                                            />
                                            <label className="camera" htmlFor="file_update_img">
                                                <IconCamera />
                                            </label>
                                        </div>
                                    </StyledImgSelect>
                                </StyleInputSection>
                            )}
                            {step === 2 && (
                                <StyleStep2>
                                    <br />
                                    <div className="table-addon">
                                        <Table
                                            scroll={{ x: "max-content" }}
                                            columns={columnTemplateProduct}
                                            dataSource={templateProduct}
                                            pagination={false}
                                            rowClassName={(record) => {
                                                if (!record.is_variant) {
                                                    return "NotExpandible";
                                                } else {
                                                    return "";
                                                }
                                            }}
                                            expandedRowRender={(record) => {
                                                if (record.is_variant) {
                                                    return (
                                                        <Table
                                                            columns={variantColumn}
                                                            dataSource={record.variant_list}
                                                            pagination={false}
                                                            showHeader={false}
                                                        />
                                                    );
                                                }
                                            }}
                                        />
                                    </div>
                                    <div
                                        className="title"
                                        style={{
                                            fontSize: "25px",
                                            color: "#000",
                                            borderBottom: "none",
                                            margin: "30px 0px",
                                            lineHeight: "25px",
                                        }}
                                    >
                                        {t("page.wholesale.enter_price_addon")}
                                    </div>
                                    <div className="table-addon">
                                        <Table
                                            scroll={{ x: "max-content" }}
                                            columns={columnTemplateAddon}
                                            dataSource={templateAddon}
                                            pagination={false}
                                            expandedRowRender={(record) => (
                                                <Table
                                                    columns={optionColumn}
                                                    dataSource={record.option_list}
                                                    pagination={false}
                                                    showHeader={false}
                                                />
                                            )}
                                        />
                                    </div>
                                </StyleStep2>
                            )}
                        </StyledCard>
                        <br />
                        {step === 1 && (
                            <StyledCard>
                                <h3>{t("page.wholesale.select_menu")}</h3>
                                <StyledMenuSelect>
                                    <Row className="select-layout" gutter={32}>
                                        <Col md={24}>
                                            <StyledBtnStock>
                                                <div className="wholeTable">
                                                    <div className="wholeTopRow">
                                                        <div className="topTitleRow leftSide">
                                                            <div className="tableTitles">
                                                                <div className="titleAndIcon">
                                                                    <AssignCategoryIcon />
                                                                    {type === "product" &&
                                                                        t("page.category")}
                                                                    {type === "category" &&
                                                                        t("page.category")}
                                                                </div>
                                                                <p className="results">
                                                                    {productCategories.length}{" "}
                                                                    {t("page.categories")}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        {(type === "product" ||
                                                            product.has_free_variant === true) && (
                                                                <div className="topTitleRow rightSide">
                                                                    <div className="tableTitles">
                                                                        <div className="titleAndIcon">
                                                                            <AssignProductIcon />
                                                                            {product.has_free_variant ===
                                                                                true
                                                                                ? variants.length
                                                                                : products.length}{" "}
                                                                            {(type === "product" ||
                                                                                product.has_free_variant) &&
                                                                                t("page.products")}
                                                                        </div>
                                                                        <p className="results">
                                                                            <Checkbox checked={isAll} onChange={clickAll} /> All products
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            )}
                                                    </div>
                                                    <div className="wholeContent">
                                                        <div className="leftContent">
                                                            {(type === "product" ||
                                                                product.has_free_variant) ===
                                                                true && (
                                                                    <div
                                                                        className={
                                                                            colStyle === -1
                                                                                ? "catCols active"
                                                                                : "catCols"
                                                                        }
                                                                        onClick={() => {
                                                                            if (
                                                                                product.has_free_variant ===
                                                                                true
                                                                            ) {
                                                                                getVariants("");
                                                                            } else {
                                                                                getProducts("");
                                                                            }
                                                                            setColStyle(-1);
                                                                        }}
                                                                    >
                                                                        {t("page.select_all")}
                                                                        <div className="colArrow">
                                                                            <ColumnRightArrow />
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            {productCategories &&
                                                                productCategories.map((item, i) =>
                                                                    type === "product" ||
                                                                        product.has_free_variant ===
                                                                        true ? (
                                                                        <div
                                                                            className={
                                                                                colStyle === i
                                                                                    ? "catCols active"
                                                                                    : "catCols"
                                                                            }
                                                                            key={i}
                                                                            onClick={() => {
                                                                                if (
                                                                                    product.has_free_variant ===
                                                                                    true
                                                                                ) {
                                                                                    getVariants(
                                                                                        item._id
                                                                                    );
                                                                                    setSelectedCategoryId(
                                                                                        item._id
                                                                                    );
                                                                                } else {
                                                                                    getProducts(
                                                                                        item._id
                                                                                    );
                                                                                    setSelectedCategoryId(
                                                                                        item._id
                                                                                    );
                                                                                }

                                                                                setPagination({ xpage: 1, xlimit: 24 });
                                                                                setColStyle(i);
                                                                            }}
                                                                        >
                                                                            {item.category}
                                                                            {type === "product" && (
                                                                                <div className="colArrow">
                                                                                    <ColumnRightArrow />
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    ) : (
                                                                        <Checkbox
                                                                            className={
                                                                                colStyle === i
                                                                                    ? "catCols active"
                                                                                    : "catCols"
                                                                            }
                                                                            checked={product.product_category_list?.some(
                                                                                (x) =>
                                                                                    x.product_category_id ===
                                                                                    item._id
                                                                            )}
                                                                            key={i}
                                                                            onClick={() => {
                                                                                selectCategoryHandler(
                                                                                    item
                                                                                );
                                                                            }}
                                                                        >
                                                                            {item.category}
                                                                            {type === "product" && (
                                                                                <div className="colArrow">
                                                                                    <ColumnRightArrow />
                                                                                </div>
                                                                            )}
                                                                        </Checkbox>
                                                                    )
                                                                )}
                                                        </div>
                                                        {type === "product" &&
                                                            product.has_free_variant === false && (
                                                                <div className="rightContent">
                                                                    {products &&
                                                                        products.map((item, i) => (
                                                                            <div
                                                                                className="productBox"
                                                                                key={i}
                                                                                onClick={() => {
                                                                                    selectProductHandler(
                                                                                        item
                                                                                    );
                                                                                }}
                                                                            >
                                                                                {tableData?.some(
                                                                                    (x) =>
                                                                                        x._id ===
                                                                                        item._id
                                                                                ) && (
                                                                                        <div className="overlay">
                                                                                            <div className="selectedBadge">
                                                                                                <SelectedProductBadge />
                                                                                                <p>
                                                                                                    {t(
                                                                                                        "page.selected"
                                                                                                    )}
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    )}
                                                                                <div className="catCols rightColBoxes">
                                                                                    <img
                                                                                        src={
                                                                                            (item.image_url_list &&
                                                                                                item
                                                                                                    .image_url_list[0]) ||
                                                                                            BLANK_IMAGE_URL
                                                                                        }
                                                                                        alt={
                                                                                            item.name
                                                                                        }
                                                                                    />
                                                                                    <div className="namePrice">
                                                                                        <p>
                                                                                            {
                                                                                                item.name
                                                                                            }
                                                                                        </p>
                                                                                        <p>
                                                                                            {
                                                                                                item.standard_price
                                                                                            }
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    {products.length !==
                                                                        totalProducts && (
                                                                            <div className="loadMore">
                                                                                <Pagination
                                                                                    defaultCurrent={1}
                                                                                    pageSize={
                                                                                        INITAL_PAGE.xlimit
                                                                                    }
                                                                                    total={
                                                                                        totalProducts
                                                                                    }
                                                                                    onChange={
                                                                                        handlePagination
                                                                                    }
                                                                                    current={
                                                                                        pagination.xpage
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        )}
                                                                </div>
                                                            )}
                                                        {product.has_free_variant === true && (
                                                            <div className="rightContent">
                                                                {variants &&
                                                                    variants.map((item, i) => (
                                                                        <div
                                                                            className="productBox"
                                                                            key={i}
                                                                            onClick={() => {
                                                                                selectVariantHandler(
                                                                                    item
                                                                                );
                                                                            }}
                                                                        >
                                                                            {product.variant_free_list?.some(
                                                                                (x) =>
                                                                                    x.variant_id ===
                                                                                    item
                                                                                        .variant_list
                                                                                        ?._id
                                                                            ) && (
                                                                                    <div className="overlay">
                                                                                        <div className="selectedBadge">
                                                                                            <SelectedProductBadge />
                                                                                            <p>
                                                                                                {t(
                                                                                                    "page.selected"
                                                                                                )}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            <div className="catCols rightColBoxes">
                                                                                <img
                                                                                    src={
                                                                                        BLANK_IMAGE_URL
                                                                                    }
                                                                                    alt={
                                                                                        item
                                                                                            .variant_list
                                                                                            ?.variant_name
                                                                                    }
                                                                                />
                                                                                <div className="namePrice">
                                                                                    <p>
                                                                                        {
                                                                                            item
                                                                                                .variant_list
                                                                                                ?.variant_name
                                                                                        }
                                                                                    </p>
                                                                                    <p>
                                                                                        {
                                                                                            item
                                                                                                .variant_list
                                                                                                ?.price
                                                                                        }
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                {products.length !==
                                                                    totalProducts && (
                                                                        <div className="loadMore">
                                                                            <Pagination
                                                                                defaultCurrent={1}
                                                                                pageSize={
                                                                                    INITAL_PAGE.xlimit
                                                                                }
                                                                                total={totalProducts}
                                                                                onChange={
                                                                                    handlePagination
                                                                                }
                                                                                current={
                                                                                    pagination.xpage
                                                                                }
                                                                            />
                                                                        </div>
                                                                    )}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </StyledBtnStock>
                                        </Col>
                                    </Row>
                                </StyledMenuSelect>
                            </StyledCard>
                        )}

                        <br />
                    </div>
                    {step === 1 && (
                        <div className="btn-layout">
                            <StyledCancelButton
                                type="sub"
                                text={t("page.back")}
                                htmlType="button"
                                onClick={handleCancel}
                            />
                            <StyledSubmitButton
                                type="default"
                                text={t("page.next")}
                                htmlType="button"
                                onClick={step1next}
                            />
                        </div>
                    )}
                    {step === 2 && (
                        <div className="btn-layout">
                            <StyledCancelButton
                                type="sub"
                                text={t("page.back")}
                                htmlType="button"
                                onClick={handleCancel}
                            />
                            <StyledSubmitButton
                                type="default"
                                text={t("page.save")}
                                htmlType="button"
                                onClick={step2next}
                            />
                        </div>
                    )}
                </div>
            </form>
        </div>
    );
});

const StyledImgSelect = styled.div`
    .avatar {
        margin-bottom: 42px;
        position: relative;
        .box_title {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            p {
                margin: 0 4px 0 0;
            }
            button {
                padding: 0 4px;
                border: 0;
                background: transparent;
                height: auto;
            }
        }
    }
    .avatar .box_title {
        margin: 0 0 20px 0px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 1.5em;
    }
    .avatar-layout {
        position: relative;
        width: 110px;
        &:hover {
            .remove-icon-layout {
                visibility: visible;
            }
        }
    }
    .avatar .box_title span {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 1.5em;
        position: relative;
        top: -1px;
        display: inline-block;
        margin-left: 4px;
    }
    .avatar .camera {
        width: 33px;
        height: 33px;
        background-color: #000000;
        border-radius: 50%;
        opacity: 0.29;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 110px;
        bottom: 0;
        transform: translate(-50%, 50%);
        cursor: pointer;
    }
`;

const StyleStep2 = styled.div`
    .price-field {
        display: flex;
        justify-content: center;
    }
    .price-input {
        width: 80px;
    }
    .NotExpandible .ant-table-row-expand-icon-cell {
        visibility: hidden;
    }
`;

const StyledBtnStock = styled.div`
    .customTransparentStockBtn {
        height: 42px;
        border: 0.5px solid #646464;
        border-radius: 4px;
        background: transparent;
        color: #646464;
        padding: 0 25px;
    }
    .customTransparentStockBtn:hover {
        border: 0.5px solid #646464 !important;
    }
    .customBlueStockBtn {
        height: 42px;
        border: 0.5px solid transparent !important;
        border-radius: 4px;
        background: #0263e0;
        color: #fff;
        padding: 0 25px;
    }
`;

const StyledCustom = styled.div`
    .secondTitle {
        font-size: 20px;
    }
    .row {
        display: flex;
        align-items: center;
    }
    .step {
        display: flex;
        align-items: center;
        p {
            margin: 0;
            color: #0263e0;
            font-weight: 700;
            font-size: 16px;
        }
        .whiteText {
            color: #a5a5a5;
        }
    }
    .blueCircle {
        background: #0263e0;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: flex;
        place-content: center;
        align-items: center;
        color: #fff;
        font-size: 12px;
        font-weight: 700;
        margin-right: 10px;
    }
    .grayCircle {
        background: #a5a5a5;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: flex;
        place-content: center;
        align-items: center;
        color: #fff;
        font-size: 12px;
        font-weight: 700;
        margin-right: 10px;
    }
    .arrowRight {
        margin: auto 20px;
    }
    .ant-table-thead > tr > th {
        text-align: left !important;
    }
    .catCols {
        background: #f7f7f8;
        border-radius: 6px;
        display: flex;
        position: relative;
        padding: 20px;
        color: #646464;
        cursor: pointer;
        margin-bottom: 10px;
    }
    .catCols.active {
        background: #e1e1e1;
    }
    .colArrow {
        position: absolute;
        right: 20px;
        display: flex;
        gap: 10px;
        place-items: center;
        font-size: 12px;
    }
    .tableTitles {
        display: flex;
        justify-content: space-between;
        place-items: center;
        width: 100%;
        .results {
            color: #646464;
            font-size: 12px;
            font-weight: 300;
            margin: 0;
        }
    }
    .titleAndIcon {
        font-size: 16px;
        font-weight: 700;
        display: flex;
        gap: 10px;
        place-items: center;
    }
    .ant-table-thead > tr > th .ant-table-header-column {
        width: 100%;
    }
    .wholeTable {
        border: 1px solid #e1e1e1;
        position: relative;
        width: 100%;
        min-height: 30vh;
        border-radius: 6px;
    }
    .topTitleRow {
        background: #f7f7f8;
        display: flex;
        padding: 20px;
        border-top-left-radius: 6px;
        border-bottom: 1px solid #e1e1e1;
        justify-content: space-between;
        border-right: 1px solid #e1e1e1;
    }
    .topTitleRow.leftSide {
        width: 35%;
    }
    .topTitleRow.rightSide {
        width: 65%;
        right: 0;
        border-right: none;
        border-top-left-radius: 0;
        border-top-right-radius: 6px;
    }
    .wholeTopRow {
        display: flex;
    }
    .leftContent {
        padding: 10px;
        width: 35%;
        border-right: 1px solid #e1e1e1;
        overflow: scroll;
    }
    .rightContent {
        padding: 10px;
        width: 65%;
        height: 100%;
        overflow: scroll;
    }
    .wholeContent {
        display: flex;
        height: 50vh;
    }
    .topLongGrayBox {
        background: #f7f7f8;
        border-radius: 6px;
        padding: 20px;
        margin-bottom: 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
            margin: 0;
            color: #000;
            font-weight: 700;
        }
    }
    .customTransparentStockBtn {
        height: 42px;
        border: 0.5px solid #646464;
        border-radius: 4px;
        background: #fff;
        color: #646464;
        padding: 0 25px;
        display: flex;
        gap: 2px;
        align-items: center;
    }
    .customTransparentStockBtn:hover {
        border: 0.5px solid #646464 !important;
    }
    .aboveOfTableRow {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;
        .title {
            margin-bottom: 0 !important;
        }
    }
    .ant-input-group-addon {
        width: 70px;
    }
`;

const StyledAvatar = styled.div`
    .avatar {
        margin-bottom: 20px;
        position: relative;
        .box_title {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            p {
                margin: 0 4px 0 0;
            }
            button {
                padding: 0 4px;
                border: 0;
                background: transparent;
                height: auto;
            }
        }
    }
    .avatar .box_title {
        margin: 0 0 20px 0px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 1.5em;
    }
    .avatar-layout {
        position: relative;
        width: 110px;
        &:hover {
            .remove-icon-layout {
                visibility: visible;
            }
        }
    }
    .remove-icon-layout {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
        visibility: hidden;
        display: flex;
        justify-content: end;
        padding: 8px;
    }
    .avatar .box_title span {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 1.5em;
        position: relative;
        top: -1px;
        display: inline-block;
        margin-left: 4px;
    }
    .avatar .camera {
        width: 33px;
        height: 33px;
        background-color: #000000;
        border-radius: 50%;
        opacity: 0.29;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 110px;
        bottom: 0;
        transform: translate(-50%, 50%);
        cursor: pointer;
    }
`;

const StyledMenuSelect = styled.div`
    .ant-drawer-body {
        padding: 0 !important;
    }
    .secondTitle {
        font-size: 20px;
    }
    .row {
        display: flex;
        align-items: center;
    }
    .step {
        display: flex;
        align-items: center;
        p {
            margin: 0;
            color: #0263e0;
            font-weight: 700;
            font-size: 16px;
        }
        .whiteText {
            color: #a5a5a5;
        }
    }
    .blueCircle {
        background: #0263e0;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: flex;
        place-content: center;
        align-items: center;
        color: #fff;
        font-size: 12px;
        font-weight: 700;
        margin-right: 10px;
    }
    .grayCircle {
        background: #a5a5a5;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: flex;
        place-content: center;
        align-items: center;
        color: #fff;
        font-size: 12px;
        font-weight: 700;
        margin-right: 10px;
    }
    .arrowRight {
        margin: auto 20px;
    }
    .ant-table-thead > tr > th {
        text-align: left !important;
    }
    .catCols {
        background: #f7f7f8;
        border-radius: 6px;
        display: flex;
        position: relative;
        padding: 20px;
        color: #646464;
        cursor: pointer;
        margin-bottom: 10px;
        place-items: center;
    }
    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
        margin-left: 0;
    }
    .rightColBoxes {
        padding: 0;
        height: 140px;
        width: 125px;
        border-radius: 6px;
        margin: 0;
        display: flex;
        flex-direction: column;
        border: 1px solid #e1e1e1;
        background: #fff;
        overflow: hidden;
        p {
            margin: 0;
            font-weight: 700;
            font-size: 10px;
            line-height: 19px;
            color: #000;
        }
        img {
            width: 100%;
            height: 65%;
            object-fit: cover;
        }
        .namePrice {
            padding: 5px 12px;
        }
    }
    .catCols.active {
        background: #e1e1e1;
    }
    .colArrow {
        position: absolute;
        right: 20px;
        bottom: 0;
        top: 0;
        display: flex;
        gap: 10px;
        place-items: center;
        font-size: 12px;
    }
    .tableTitles {
        display: flex;
        justify-content: space-between;
        place-items: center;
        width: 100%;
        .results {
            color: #646464;
            font-size: 12px;
            font-weight: 300;
            margin: 0;
        }
    }
    .titleAndIcon {
        font-size: 16px;
        font-weight: 700;
        display: flex;
        gap: 10px;
        place-items: center;
    }
    .ant-table-thead > tr > th .ant-table-header-column {
        width: 100%;
    }
    .wholeTable {
        border: 1px solid #e1e1e1;
        position: relative;
        width: 100%;
        min-height: 30vh;
        border-radius: 6px;
    }
    .topTitleRow {
        background: #f7f7f8;
        display: flex;
        padding: 20px;
        border-top-left-radius: 6px;
        border-bottom: 1px solid #e1e1e1;
        justify-content: space-between;
        border-right: 1px solid #e1e1e1;
    }
    .topTitleRow.leftSide {
        width: 30%;
    }
    .topTitleRow.rightSide {
        width: 70%;
        right: 0;
        border-right: none;
        border-top-left-radius: 0;
        border-top-right-radius: 6px;
    }
    .wholeTopRow {
        display: flex;
    }
    .leftContent {
        padding: 10px;
        width: 30%;
        border-right: 1px solid #e1e1e1;
        overflow: scroll;
    }
    .rightContent {
        padding: 10px;
        width: 70%;
        height: 100%;
        overflow-x: scroll;
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        justify-content: space-between;
        position: relative;
    }
    .rightContent::after {
        content: "";
        flex: auto;
    }
    .wholeContent {
        display: flex;
        height: 50vh;
    }
    .topLongGrayBox {
        background: #f7f7f8;
        border-radius: 6px;
        padding: 20px;
        margin-bottom: 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
            margin: 0;
            color: #000;
            font-weight: 700;
        }
    }
    .customTransparentStockBtn {
        height: 42px;
        border: 0.5px solid #646464;
        border-radius: 4px;
        background: #fff;
        color: #646464;
        padding: 0 25px;
        display: flex;
        gap: 2px;
        align-items: center;
    }
    .customTransparentStockBtn:hover {
        border: 0.5px solid #646464 !important;
    }
    .aboveOfTableRow {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;
        .title {
            margin-bottom: 0 !important;
        }
    }
    .ant-input-group-addon {
        width: 70px;
    }
    .topRadioButtonRows {
        margin-bottom: 20px;
    }
    .radioBox {
        padding: 20px;
        background: #f5f5f5;
        border: 1px solid #e1e1e1;
        border-radius: 5px;
        color: #c8c8c8;
        position: absolute;
        left: 0;
        top: 30px;
        width: 100%;
        text-align: center;
        font-weight: 700;
        font-size: 32px;
        line-height: 44px;
    }
    .radioGroupRow {
        width: 100%;
        display: flex;
        place-content: space-between;
        position: relative;
        margin-bottom: 50px;
        .ant-radio-wrapper {
            width: 100%;
            display: flex;
            place-items: center;
            margin-bottom: 20px;
            p {
                margin: 0;
                font-weight: 700;
                font-size: 12px;
                line-height: 16px;
            }
        }
    }
    .activeRadioBtn {
        background: linear-gradient(0deg, rgba(2, 99, 224, 0.08), rgba(2, 99, 224, 0.08)), #ffffff;
        border-radius: 5px;
        border: 1px solid rgba(2, 99, 224, 0.36);
        color: #66a9ff;
    }
    .withIcon {
        display: flex;
        place-content: center;
        place-items: center;
        gap: 10px;
    }
    .label .sub-title {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #646464;
        margin: 0;
    }
    .increaseDecreaseSection {
        background: #f4f9ff;
        border-radius: 8px;
        width: 100%;
        height: 100px;
        margin-top: 20px;
        display: flex;
        place-content: center;
        place-items: center;
        gap: 30px;
        font-weight: 700;
        font-size: 25px;
        line-height: 34px;
        color: #000;
        .decreaseIcon {
            background: #e1e1e1;
            border-radius: 50%;
            display: flex;
            place-content: center;
            place-items: center;
            color: #fff;
            font-size: 40px;
            height: 35px;
            width: 35px;
            padding-bottom: 4px;
            cursor: pointer;
        }
        .increaseIcon {
            background: #0263e0;
            border-radius: 50%;
            display: flex;
            place-content: center;
            place-items: center;
            color: #fff;
            font-size: 30px;
            height: 35px;
            width: 35px;
            padding-bottom: 4px;
            cursor: pointer;
        }
    }
    .selectedBadge {
        position: absolute;
        width: 80px;
        height: 55px;
        background: rgba(255, 255, 255, 0.84);
        backdrop-filter: blur(2px);
        border-radius: 6px;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        display: flex;
        flex-direction: column;
        place-content: center;
        place-items: center;
        z-index: 9;
        cursor: pointer;
        p {
            margin: 0;
            color: #000;
            font-weight: 700;
            font-size: 14px;
            line-height: 19px;
        }
    }
    .overlay {
        background-color: rgba(0, 0, 0, 0.4);
        width: 100%;
        height: 100%;
        z-index: 9;
        position: absolute;
        border-radius: 6px;
        max-height: 140px;
    }
    .productBox {
        position: relative;
        cursor: pointer;
    }
    .loadMore {
        width: 100%;
        display: flex;
        place-content: center;
    }
    .loadMoreBtn {
        display: flex;
        place-content: center;
        place-items: center;
        align-items: center;
        color: #000;
        padding: 10px;
    }
`;

const StyleInputSection = styled.div`
    .input-title {
        margin-bottom: 5px;
        margin-top: 40px;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
        color: #000000;
    }
    .radio {
        margin-right: 10px;
    }
    .radio-option {
        margin-right: 50px;
        cursor: pointer;
    }
    input {
        height: 48px;
    }
`;
