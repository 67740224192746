import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Switch, Col, Row, Avatar, Menu, Dropdown, Button } from "antd";
import { MoreHorizontal } from "react-feather";

import { IDataexample, INewsCategory } from "@interfaces";
import {
    enumNewsType,
    PATH_EDIT_QTY_PRODUCT,
    PATH_PRODUCT,
    PATH_PRODUCT_CREATE,
    PATH_SERVICES_CREATE,
    PATH_WHOLESALE_EDIT,
} from "@configs";
import { selectAuth } from "@redux";
import { useSelector } from "react-redux";
import { IconDrag } from "@components";
import { useNotify } from "@utils";
import React, { useEffect, useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import axios from "axios";
import { productAPI, wholesaleAPI } from "@api";
import { useHistory } from "react-router";

interface IProps {
    data: IDataexample | any;
    itemJson: string;
    handleEdit: (status: boolean, data: IDataexample) => void;
    callbackGetList: () => void;
    index: number;
    newType?: enumNewsType;
    categories: INewsCategory[];
}

export const ComponentRowWholeSale = (props: IProps) => {
    //hooks
    const { t } = useTranslation();

    const { success, error } = useNotify();
    const history = useHistory();

    const organization_id = useSelector(selectAuth).userInfo?.merchant?._id;
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    //props
    const { data, handleEdit, callbackGetList, itemJson, newType } = props;
    const [show, setShowModal] = useState(false);

    const { attributes, listeners, setNodeRef, transform } = useSortable({
        id: itemJson,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
    };

    const handleDelete = async (item: any) => {
        try {
            await wholesaleAPI.deleteChannel({
                organization_id: organization_id || "",
                sale_channel_id: data?._id,
            });
            callbackGetList();
            success(t("message.delete.success"));
        } catch (err: any) {
            if (err) {
                error(err.msg);
            } else error(t("message.delete.fail"));
        }
    };

    const clickSwitch = async () => {
        try {
            await wholesaleAPI.setEnableChannel({
                organization_id: organization_id || "",
                sale_channel_id: data?._id,
                is_enable: !data?.is_enable,
            });
            success(t("message.update.success"));
            callbackGetList();
        } catch (err) {
            console.error(err);
            error(t("message.update.fail"));
        }
    };

    const menu = (
        <Menu>
            <Menu.Item
                key={1}
                onClick={() => {
                    history.push(`${PATH_WHOLESALE_EDIT}/${data._id}`);
                }}
            >
                <div className="duplicate-btn" style={{ color: "#000" }}>
                    {t("page.edit")}
                </div>
            </Menu.Item>
            <Menu.Item key={2} onClick={() => handleDelete(data.product_code)}>
                <div className="duplicate-btn">{t("page.delete")}</div>
            </Menu.Item>
        </Menu>
    );
    const handleUpdateStatus = (e: boolean) => {
        handleEdit(e, data);
    };

    return (
        <div className="table-element" ref={setNodeRef} style={style}>
            <div className="drag-icon hide" {...attributes} {...listeners}>
                <IconDrag />
            </div>
            <div className="table-row">
                <Row
                    style={{ height: "100%" }}
                    gutter={24}
                    type="flex"
                    align="middle"
                    justify="space-between"
                >
                    <Col className="col-item" span={7}>
                        <Avatar
                            shape="square"
                            src={data?.image_url || ""}
                            size={50}
                            style={{ minWidth: 50 }}
                        />
                        <Link to={`${PATH_WHOLESALE_EDIT}/${data._id}`}>
                            <p className="news-title">{data?.sale_channel_name}</p>
                        </Link>
                    </Col>
                    <Col className="col-item visible-md" span={7}>
                        <p>{data?.product_list?.length}</p>
                    </Col>
                    <Col className="col-item price" span={6}>
                        <div style={{ display: "flex" }}>
                            <Switch checked={data?.is_enable} onClick={clickSwitch} />
                        </div>
                    </Col>
                    <Col className="col-item" span={4}>
                        <Dropdown
                            //@ts-ignore
                            getPopupContainer={(trigger) => trigger.parentNode}
                            overlay={menu}
                            trigger={["click"]}
                            placement="bottomRight"
                        >
                            <Button style={{ border: "unset" }}>
                                <MoreHorizontal />
                            </Button>
                        </Dropdown>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
