import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { enumValidation } from "@configs";

export const YupComplaint = () => {
    //page hook
    const { t } = useTranslation();

    return {
        header: Yup.string()
            .required(
                t("validation.required", {
                    name: t("page.headlines"),
                })
            )
            .max(
                enumValidation.MAX_TITLE_INPUT,
                t("validation.max", {
                    name: t("page.headlines"),
                    number: enumValidation.MAX_TITLE_INPUT,
                })
            ),
        place_name: Yup.string()
            .required(
                t("validation.required", {
                    name: t("page.headlines"),
                })
            )
            .max(
                enumValidation.MAX_TITLE_INPUT,
                t("validation.max", {
                    name: t("page.headlines"),
                    number: enumValidation.MAX_TITLE_INPUT,
                })
            ),
        complain_category_id: Yup.string().required(
            t("validation.required", {
                name: t("page.benefit_name"),
            })
        ),
    };
};
