export const DeleteBigIcon = () => (
    <svg
        width="120"
        height="120"
        viewBox="0 0 120 120"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="60" cy="60" r="60" fill="#F7F7F7" />
        <path
            d="M103.854 50.4453L94.3762 110.404C93.5905 110.895 92.8048 111.337 92.0191 111.779C90.6441 112.565 89.22 113.253 87.7468 113.94C85.8317 114.824 83.8674 115.61 81.9032 116.297C80.5773 116.739 79.2023 117.181 77.8273 117.574C75.9613 118.065 73.997 118.507 72.0819 118.851C70.756 119.096 69.4301 119.293 68.1042 119.44C66.2382 119.636 64.4212 119.784 62.5061 119.833C61.8186 119.833 61.1802 119.882 60.4927 119.882C59.8543 119.882 59.216 119.882 58.5776 119.833C56.8588 119.784 55.1892 119.686 53.5196 119.489C52.1937 119.342 50.8678 119.145 49.542 118.949C47.5777 118.605 45.6625 118.212 43.7965 117.721C42.4215 117.378 41.0956 116.936 39.7206 116.494C37.7073 115.806 35.7921 115.07 33.877 114.235C32.4529 113.596 31.0288 112.909 29.6047 112.172C28.6717 111.681 27.7877 111.19 26.9038 110.65L17.0825 52.164L28.2297 50.4944H103.854V50.4453Z"
            fill="#E1E1E1"
        />
        <path
            d="M34.0097 114.341C32.5856 113.703 31.1615 113.016 29.7374 112.279L22.5187 58.2617C22.3714 57.1814 23.108 56.1993 24.1884 56.0519C25.2687 55.9046 26.2508 56.6412 26.3982 57.7216L34.0097 114.341Z"
            fill="black"
        />
        <path
            d="M43.8802 117.827C42.5052 117.484 41.1793 117.042 39.8043 116.6L34.3535 58.1628C34.2553 57.0825 35.041 56.1495 36.1213 56.0513C37.2017 55.953 38.1838 56.7387 38.2329 57.8191L43.8802 117.827Z"
            fill="black"
        />
        <path
            d="M53.6034 119.597C52.2775 119.449 50.9516 119.253 49.6257 119.057L46.6302 58.1153C46.5811 57.035 47.4159 56.1019 48.4963 56.0528C49.5766 56.0037 50.5097 56.8385 50.5588 57.9189L53.6034 119.597Z"
            fill="black"
        />
        <path
            d="M99.2724 58.2615L92.0538 111.886C90.6788 112.672 89.2547 113.359 87.7815 114.047L95.393 57.7704C95.5403 56.6901 96.5225 55.9535 97.6028 56.1008C98.6832 56.199 99.4197 57.1811 99.2724 58.2615Z"
            fill="black"
        />
        <path
            d="M87.3887 58.2136L81.987 116.405C80.6611 116.847 79.2861 117.289 77.9111 117.682L83.5093 57.8699C83.6075 56.7895 84.5405 56.0038 85.6209 56.102C86.7012 56.2003 87.536 57.1333 87.3887 58.2136Z"
            fill="black"
        />
        <path
            d="M75.1611 58.1153L72.1656 118.958C70.8397 119.204 69.5139 119.4 68.188 119.548L71.2326 57.9189C71.2817 56.8385 72.2147 56.0037 73.2951 56.0528C74.3754 56.1019 75.2102 57.035 75.1611 58.1153Z"
            fill="black"
        />
        <path
            d="M62.5897 58.017V119.94C61.9022 119.94 61.2638 119.989 60.5763 119.989C59.9379 119.989 59.2995 119.989 58.6611 119.94V58.017C58.6611 56.9367 59.5451 56.0527 60.6254 56.0527C61.7548 56.0527 62.5897 56.9367 62.5897 58.017Z"
            fill="black"
        />
        <path
            d="M102.7 55.9515L101.486 59.6875L112.927 63.4063L114.141 59.6703L102.7 55.9515Z"
            fill="#A5A5A5"
        />
        <path
            d="M18.4318 55.9609L6.99023 59.6797L8.20454 63.4157L19.6461 59.6969L18.4318 55.9609Z"
            fill="#A5A5A5"
        />
        <path
            d="M34.0097 114.341C32.5856 113.703 31.1615 113.016 29.7374 112.279L22.5187 58.2617C22.3714 57.1814 23.108 56.1993 24.1884 56.0519C25.2687 55.9046 26.2508 56.6412 26.3982 57.7216L34.0097 114.341Z"
            fill="#A5A5A5"
        />
        <path
            d="M43.8801 117.828C42.5051 117.484 41.1792 117.042 39.8043 116.6L34.3534 58.1635C34.2552 57.0831 35.0409 56.1501 36.1213 56.0519C37.2016 55.9537 38.1837 56.7394 38.2328 57.8197L43.8801 117.828Z"
            fill="#A5A5A5"
        />
        <path
            d="M53.6032 119.596C52.2773 119.448 50.9514 119.252 49.6256 119.056L46.6301 58.1143C46.581 57.034 47.4158 56.101 48.4961 56.0519C49.5765 56.0027 50.5095 56.8376 50.5586 57.9179L53.6032 119.596Z"
            fill="#A5A5A5"
        />
        <path
            d="M62.5897 58.0162V119.94C61.9022 119.94 61.2638 119.989 60.5763 119.989C59.9379 119.989 59.2995 119.989 58.6611 119.94V58.0162C58.6611 56.9358 59.545 56.0519 60.6254 56.0519C61.7548 56.0519 62.5897 56.9358 62.5897 58.0162Z"
            fill="#A5A5A5"
        />
        <path
            d="M75.1609 58.1143L72.1654 118.957C70.8396 119.203 69.5137 119.399 68.1878 119.547L71.2324 57.9179C71.2815 56.8376 72.2145 56.0027 73.2949 56.0519C74.3752 56.101 75.21 57.034 75.1609 58.1143Z"
            fill="#A5A5A5"
        />
        <path
            d="M87.3885 58.2126L81.9868 116.404C80.6609 116.846 79.2859 117.288 77.9109 117.681L83.5091 57.8689C83.6073 56.7885 84.5403 56.0028 85.6207 56.101C86.701 56.1993 87.5358 57.1323 87.3885 58.2126Z"
            fill="#A5A5A5"
        />
        <path
            d="M99.2723 58.2617L92.0536 111.886C90.6786 112.672 89.2546 113.359 87.7814 114.047L95.3929 57.7706C95.5402 56.6903 96.5223 55.9537 97.6027 56.101C98.683 56.1992 99.4196 57.1813 99.2723 58.2617Z"
            fill="#A5A5A5"
        />
        <path
            d="M104.085 52.2709H17.1662C16.2332 52.2709 15.4966 51.5343 15.4966 50.6013C15.4966 49.6682 16.2332 48.9316 17.1662 48.9316H104.085C105.018 48.9316 105.754 49.6682 105.754 50.6013C105.754 51.4852 105.018 52.2709 104.085 52.2709Z"
            fill="#A5A5A5"
        />
        <path
            d="M75.6448 40.9707C74.3109 40.9707 73.2315 39.8417 73.2315 38.4464L73.2315 30.8736C73.2315 29.4809 72.1497 28.3493 70.8182 28.3493H51.5117C50.1802 28.3493 49.0984 29.4809 49.0984 30.8736V38.4464C49.0984 39.8417 48.019 40.9707 46.685 40.9707C45.3511 40.9707 44.2717 39.8417 44.2717 38.4464V30.8736C44.2717 26.6978 47.5194 23.3008 51.5117 23.3008H70.8182C74.8105 23.3008 78.0581 26.6978 78.0581 30.8736L78.0581 38.4464C78.0581 39.8417 76.9787 40.9707 75.6448 40.9707Z"
            fill="#A5A5A5"
        />
        <path
            d="M20.9709 44.4473C20.9709 41.1336 23.6572 38.4473 26.9709 38.4473H94.1943C97.508 38.4473 100.194 41.1336 100.194 44.4473V50.0978H20.9709V44.4473Z"
            fill="#A5A5A5"
        />
    </svg>
);
