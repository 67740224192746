import { useEffect, memo, useState, useRef, useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Switch, Row, Col, Select, Radio, Checkbox, Input, Button, TimePicker } from "antd";
import { IGetVatDetailExample, IUpdateVatDetail } from "@interfaces";
import { StyledSubmitButton, StyledCancelButton, StyledCard } from "@components";
import { useYup } from "@validations";
import { setLoading, selectAuth } from "@redux";
import { showErrorMessage, useNotify } from "@utils";
import { PATH_PRODUCT, PATH_STORE_LIST } from "@configs";
import { orgApi } from "@api";
import { ComponentProductDrawer } from "src/Components/product";
import { RadioChangeEvent } from "antd/lib/radio";
import { SetReasonIcon } from "src/Components/icon/orgSetting";
import { SetReasonModal } from "src/Components/shared/orgSetting";
import moment from "moment";
import { ModuleUploadImage } from "../form";
import styled from "styled-components";
interface IProps {
    store: IGetVatDetailExample;
}
const initData: IUpdateVatDetail = {
    organization_id: "",
    vat_type: "",
    vat_percent: 0,
    rounding_type: "",
    is_require_employee_pin_checkbill: false,
    is_require_bank_note: false,
    bill_no_running_type: "",
    employee_code_digit: 0,
    is_record_revenue: false,
    hint_code_config: {
        is_product: false,
        is_addon: false,
        is_sale_channel: false,
    },
    is_show_cost_on_po: false,
    is_show_expiry_date_on_gr: false,
    is_show_delivery_unit_on_check_stock: false,
    is_auto_convert_tr_to_po: false,
    auto_convert_tr_to_po_hour_no: 0,
    is_enable_check_store_reason: false,
    check_stock_reason_list: [],
    is_enable_waste_record_reason: false,
    waste_record_reason_list: [],
    is_not_show_price_and_skip_payment: false,
    is_select_qty: false,
    is_display_category: false,
    resource_constraint_type: "",
    image_url_logo_on_receipt: "",
    store_resource_constraint: 0,
};
const { Option } = Select;

interface IBookingValue {
    is_display_category: boolean;
    is_select_qty: boolean;
    is_not_show_price_and_skip_payment: boolean;
    is_step_choose_service_first: boolean;
    is_check_period_service_rebooking: boolean;
    period_rebooking_type: string;
    booking_timepicker_style: string;
}

interface IResourceValue {
    resource_constraint_type: string;
    store_resource_constraint: string;
}

export const ModuleBillCalculationForm = memo((props: IProps) => {
    //hook
    const { YupNews } = useYup();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const { error, success } = useNotify();
    const [showModal, setShowModal] = useState<boolean>(false);
    const organization_id = useSelector(selectAuth).userInfo?.merchant._id;
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const searchInputRef = useRef<any | null>(null);
    const pathName = window.location.pathname;
    const createNews = Yup.object().shape(YupNews);
    const { store } = props;
    const [isCash, setIsCash] = useState<boolean>(true);
    const [isPIN, setIsPIN] = useState<boolean>(true);
    const [hintChannel, setHintChannel] = useState<boolean>(true);
    const [hintVariant, setHintVariant] = useState<boolean>(true);
    const [hintAddon, setHintAddon] = useState<boolean>(true);
    const [isDaily, setIsDaily] = useState<boolean>(true);
    const [bookingValue, setBookingValue] = useState<IBookingValue>({
        is_display_category: false,
        is_select_qty: false,
        is_not_show_price_and_skip_payment: false,
        is_step_choose_service_first: false,
        is_check_period_service_rebooking: false,
        period_rebooking_type: "weekly",
        booking_timepicker_style: "block",
    });
    const [resourceValue, setResourceValue] = useState<IResourceValue>({
        resource_constraint_type: "staff",
        store_resource_constraint: "1",
    });
    const [reasonModal, setReasonModal] = useState<boolean>(false);
    //
    useEffect(() => {
        setTimeout(() => {
            dispatch(setLoading(false));
        }, 500);
        if (store) {
            setFieldValue("vat_percent", store.vat_percent);
            setIsCash(store?.is_require_bank_note);
            setIsPIN(store?.is_require_employee_pin_checkbill);
            setHintChannel(store?.hint_code_config?.is_sale_channel);
            setHintVariant(store?.hint_code_config?.is_product);
            setHintAddon(store?.hint_code_config?.is_addon);
            setIsDaily(store?.bill_no_running_type === "per-day");
            let inBooking: IBookingValue = {
                is_select_qty: store?.is_select_qty,
                is_display_category: store?.is_display_category,
                is_not_show_price_and_skip_payment: store?.is_not_show_price_and_skip_payment,
                is_step_choose_service_first: store?.is_step_choose_service_first,
                is_check_period_service_rebooking: store?.is_check_period_service_rebooking,
                period_rebooking_type: store?.period_rebooking_type,
                booking_timepicker_style: store?.booking_timepicker_style,
            };
            let inResource: IResourceValue = {
                resource_constraint_type: store?.resource_constraint_type,
                store_resource_constraint: store?.store_resource_constraint.toString(),
            };
            setBookingValue(inBooking);
            setResourceValue(inResource);
            setFieldValue("is_show_cost_on_po", store.is_show_cost_on_po);
            setFieldValue("is_show_expiry_date_on_gr", store.is_show_expiry_date_on_gr);
            setFieldValue(
                "is_show_delivery_unit_on_check_stock",
                store.is_show_delivery_unit_on_check_stock
            );
            setFieldValue("is_auto_convert_tr_to_po", store.is_auto_convert_tr_to_po);
            setFieldValue("auto_convert_tr_to_po_hour_no", store.auto_convert_tr_to_po_hour_no);
            setFieldValue("is_enable_check_store_reason", store.is_enable_check_store_reason);
            setFieldValue("check_stock_reason_list", store.check_stock_reason_list);
            setFieldValue("is_enable_waste_record_reason", store.is_enable_waste_record_reason);
            setFieldValue("waste_record_reason_list", store.waste_record_reason_list);
            setFieldValue("image_url_logo_on_receipt", store.image_url_logo_on_receipt);
            setFieldValue("is_void_bill_need_witness", store.is_void_bill_need_witness);
            setFieldValue(
                "is_void_bill_can_be_different_bill_owner",
                store.is_void_bill_can_be_different_bill_owner
            );
            setFieldValue("is_use_queue_on_receipt", store.is_use_queue_on_receipt);
            setFieldValue("is_pos_scan_qr_only", store.is_pos_scan_qr_only);
            setFieldValue("rounding_type", store.rounding_type);
            setFieldValue("vat_type", store.vat_type);
            setFieldValue("receipt_qr_expired_day", store.receipt_qr_expired_day);
        }
    }, [store]);
    const timepickerList: {
        value: string;
        title: string;
    }[] = [
            { value: 'block', title: "wheel", },
            { value: 'list', title: "slot", }
        ];
    const rebookOptionList: {
        value: string;
        title: string;
    }[] = [
            { value: 'weekly', title: t("bill.rebook_op_weekly"), },
            { value: 'monthly', title: t("bill.rebook_op_monthly"), },
            { value: 'yearly', title: t("bill.rebook_op_yearly"), }
        ];
    const handleEnter = (e: string) => {
        if (e === "Enter") {
            searchInputRef.current.focus();
        }
    };

    const changeBooking = (title: string, value: boolean | string) => {
        let ans = { ...bookingValue };
        ans[title] = value;
        setBookingValue({ ...ans });
    };

    const changeResource = (title: string, value: string) => {
        let ans = { ...resourceValue };
        ans[title] = value;
        setResourceValue({ ...ans });
    };

    const handleNewsSubmit = async () => {
        dispatch(setLoading(true));
        try {
            const updateStoreAPI = await orgApi.updateVatDetail({
                organization_id: organization_id,
                vat_type: values.vat_type,
                vat_percent: 7,
                rounding_type: values.rounding_type,
                is_require_bank_note: isCash,
                is_require_employee_pin_checkbill: isPIN,
                hint_code_config: {
                    is_product: hintVariant,
                    is_addon: hintAddon,
                    is_sale_channel: hintChannel,
                },
                bill_no_running_type: isDaily ? "per-day" : "per-month",
                is_display_category: bookingValue?.is_display_category,
                is_select_qty: bookingValue?.is_select_qty,
                is_not_show_price_and_skip_payment:
                    bookingValue?.is_not_show_price_and_skip_payment,
                resource_constraint_type: resourceValue?.resource_constraint_type,
                store_resource_constraint: parseInt(resourceValue?.store_resource_constraint),
                is_show_cost_on_po: values.is_show_cost_on_po,
                is_show_expiry_date_on_gr: values.is_show_expiry_date_on_gr,
                is_show_delivery_unit_on_check_stock: values.is_show_delivery_unit_on_check_stock,
                is_auto_convert_tr_to_po: values.is_auto_convert_tr_to_po,
                auto_convert_tr_to_po_hour_no: values.auto_convert_tr_to_po_hour_no,
                is_enable_check_store_reason: values.is_enable_check_store_reason,
                check_stock_reason_list: values.check_stock_reason_list,
                is_enable_waste_record_reason: values.is_enable_waste_record_reason,
                waste_record_reason_list: values.waste_record_reason_list,
                image_url_logo_on_receipt: values.image_url_logo_on_receipt,
                is_void_bill_need_witness: values.is_void_bill_need_witness,
                is_void_bill_can_be_different_bill_owner:
                    values.is_void_bill_can_be_different_bill_owner,
                is_use_queue_on_receipt: values.is_use_queue_on_receipt,
                is_pos_scan_qr_only: values.is_pos_scan_qr_only,
                receipt_qr_expired_day: values.receipt_qr_expired_day || store.receipt_qr_expired_day,
                is_step_choose_service_first: bookingValue?.is_step_choose_service_first,
                is_check_period_service_rebooking: bookingValue?.is_check_period_service_rebooking,
                period_rebooking_type: bookingValue?.period_rebooking_type,
                booking_timepicker_style: bookingValue?.booking_timepicker_style,
            });
            if (updateStoreAPI.data.error === "0") {
                success(t("message.update.success"));
            }
        } catch (err: any) {
            if (err.response) {
                error(showErrorMessage(err.response));
            } else error(t("message.error"));
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const { values, handleChange, handleBlur, handleSubmit, errors, touched, setFieldValue } =
        useFormik({
            initialValues: initData,
            validationSchema: createNews,
            enableReinitialize: true,
            onSubmit: handleNewsSubmit,
        });

    const taxRateChange = (e: RadioChangeEvent) => {
        setFieldValue("vat_type", e.target.value);
    };
    const roundingNumberChange = (e: RadioChangeEvent) => {
        setFieldValue("rounding_type", e.target.value);
    };
    const setReasonModalHandler = () => {
        setReasonModal(false);
        setTimeout(() => {
            setFieldValue("is_check_stock_modal", false);
            setFieldValue("is_waste_record_modal", false);
        }, 500);
    };
    const format = "HH:mm";
    const disabledMinutes = () => {
        const disabledMinutes: number[] = [];
        for (let i = 0; i < 60; i++) {
            disabledMinutes.push(i);
        }
        return disabledMinutes;
    };

    const handleUploadImage = (images: string[]) => {
        setFieldValue("image_url_logo_on_receipt", images[0]);
    };

    return (
        <div className="form-wrap">
            <form onSubmit={handleSubmit}>
                <div className="page-header">
                    <h3>
                        {pathName.includes(PATH_PRODUCT)
                            ? t("bill.bill_calculation_setting")
                            : t("bill.bill_calculation_setting")}
                    </h3>
                </div>
                <div className="page-body">
                    <div className="card-wrap">
                        <StyledCard>
                            <div className="title" style={{ fontSize: "25px", color: "#000" }}>
                                {t("bill.vat_setting_organization")}
                            </div>
                            <Row>
                                <Col span={24}>
                                    <div className="label">
                                        <label>{t("bill.default_tax_rate_selection")}</label>
                                    </div>
                                    <Radio.Group onChange={taxRateChange} value={values.vat_type}>
                                        <Radio value={"exclude"}>{t("bill.exclude_vat")}</Radio>
                                        <Radio value={"include"}>{t("bill.include_vat")}</Radio>
                                    </Radio.Group>
                                </Col>
                            </Row>
                        </StyledCard>
                    </div>
                    <div className="card-wrap">
                        <StyledCard>
                            <Row>
                                <Col span={24}>
                                    <div className="label">
                                        <label>{t("bill.rounding_number")}</label>
                                    </div>
                                    <Radio.Group
                                        onChange={roundingNumberChange}
                                        value={values.rounding_type}
                                    >
                                        <Radio value={"up"}>{t("bill.round_up")}</Radio>
                                        <Radio value={"down"}>{t("bill.round_down")}</Radio>
                                    </Radio.Group>
                                </Col>
                            </Row>
                        </StyledCard>
                    </div>
                    <div className="card-wrap">
                        <StyledCard>
                            <div className="title" style={{ fontSize: "25px", color: "#000" }}>
                                {t("bill.sale_round_setting")}
                            </div>
                            <Row>
                                <Col span={24}>
                                    <div className="label">
                                        <label>{t("bill.sale_round_cash_record")}</label>
                                    </div>
                                    <Radio.Group
                                        onChange={(e) => setIsCash(e.target.value)}
                                        value={isCash}
                                    >
                                        <Radio value={false}>{t("bill.cash_amount")}</Radio>
                                        <Radio value={true}>{t("bill.cash_type")}</Radio>
                                    </Radio.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <div className="label">
                                        <br />
                                        <label>{t("bill.staff_pin_bill")}</label>
                                    </div>
                                    <Radio.Group
                                        onChange={(e) => setIsPIN(e.target.value)}
                                        value={isPIN}
                                    >
                                        <Radio value={false}>{t("bill.no")}</Radio>
                                        <Radio value={true}>{t("bill.yes")}</Radio>
                                    </Radio.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <div className="label">
                                        <br />
                                        <label>{t("page.void_bill_witness_approve")}</label>
                                    </div>
                                    <Radio.Group
                                        onChange={(e) =>
                                            setFieldValue(
                                                "is_void_bill_need_witness",
                                                e.target.value
                                            )
                                        }
                                        value={values.is_void_bill_need_witness}
                                    >
                                        <Radio value={false}>{t("bill.no")}</Radio>
                                        <Radio value={true}>{t("bill.yes")}</Radio>
                                    </Radio.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <div className="label">
                                        <br />
                                        <label>
                                            {t(
                                                "page.void_bill_staff_can_be_different_from_bill_owner"
                                            )}
                                        </label>
                                    </div>
                                    <Radio.Group
                                        onChange={(e) =>
                                            setFieldValue(
                                                "is_void_bill_can_be_different_bill_owner",
                                                e.target.value
                                            )
                                        }
                                        value={values.is_void_bill_can_be_different_bill_owner}
                                    >
                                        <Radio value={false}>{t("bill.no")}</Radio>
                                        <Radio value={true}>{t("bill.yes")}</Radio>
                                    </Radio.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <div className="label">
                                        <br />
                                        <label>{t("page.enable_order_queue")}</label>
                                    </div>
                                    <Radio.Group
                                        onChange={(e) =>
                                            setFieldValue("is_use_queue_on_receipt", e.target.value)
                                        }
                                        value={values.is_use_queue_on_receipt}
                                    >
                                        <Radio value={false}>{t("bill.no")}</Radio>
                                        <Radio value={true}>{t("bill.yes")}</Radio>
                                    </Radio.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <div className="label">
                                        <br />
                                        <label>{t("page.scan_only")}</label>
                                    </div>
                                    <Radio.Group
                                        onChange={(e) =>
                                            setFieldValue("is_pos_scan_qr_only", e.target.value)
                                        }
                                        value={values.is_pos_scan_qr_only}
                                    >
                                        <Radio value={false}>{t("bill.no")}</Radio>
                                        <Radio value={true}>{t("bill.yes")}</Radio>
                                    </Radio.Group>
                                </Col>
                            </Row>
                        </StyledCard>
                    </div>
                    <div className="card-wrap">
                        <StyledCard>
                            <div className="title" style={{ fontSize: "25px", color: "#000" }}>
                                {t("bill.code")}
                            </div>
                            <Row>
                                <Col span={24}>
                                    <div className="label">
                                        <label>{t("bill.hint_code")}</label>
                                    </div>
                                    <Checkbox
                                        checked={hintChannel}
                                        onClick={() => setHintChannel(!hintChannel)}
                                    />{" "}
                                    <span>{t("bill.channel")}</span>
                                    <Checkbox
                                        checked={hintVariant}
                                        onClick={() => setHintVariant(!hintVariant)}
                                    />{" "}
                                    <span>{t("bill.variant")}</span>
                                    <Checkbox
                                        checked={hintAddon}
                                        onClick={() => setHintAddon(!hintAddon)}
                                    />{" "}
                                    <span>{t("bill.addon")}</span>
                                </Col>
                                <Col span={24}>
                                    <div className="label">
                                        <br />
                                        <label>{t("bill.receipt_run_number")}</label>
                                    </div>
                                    <Radio.Group
                                        onChange={(e) => setIsDaily(e.target.value)}
                                        value={isDaily}
                                    >
                                        <Radio value={true}>{t("bill.daily")}</Radio>
                                        <Radio value={false}>{t("bill.monthly")}</Radio>
                                    </Radio.Group>
                                </Col>
                            </Row>
                        </StyledCard>
                    </div>
                    <div className="card-wrap">
                        <StyledCard>
                            <div className="title" style={{ fontSize: "25px", color: "#000" }}>
                                {t("page.inventory")}
                            </div>
                            <Row>
                                <Col span={12}>
                                    <div className="label">
                                        <br />
                                        <label>{t("page.display")}</label>
                                    </div>
                                    <Switch
                                        checked={values.is_show_cost_on_po}
                                        onChange={(value) =>
                                            setFieldValue("is_show_cost_on_po", value)
                                        }
                                    />
                                    <span className="switchText">
                                        &nbsp;{t("page.show_cost_on_po")}
                                    </span>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: 20 }}>
                                <Col span={12}>
                                    <Switch
                                        checked={values.is_show_expiry_date_on_gr}
                                        onChange={(value) =>
                                            setFieldValue("is_show_expiry_date_on_gr", value)
                                        }
                                    />
                                    <span className="switchText">
                                        &nbsp;{t("page.show_expiry_date_on_gr")}
                                    </span>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: 20 }}>
                                <Col span={12}>
                                    <Switch
                                        checked={values.is_show_delivery_unit_on_check_stock}
                                        onChange={(value) =>
                                            setFieldValue(
                                                "is_show_delivery_unit_on_check_stock",
                                                value
                                            )
                                        }
                                    />
                                    <span className="switchText">
                                        &nbsp;{t("page.show_delivery_unit_on_check_stock")}
                                    </span>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <div className="label">
                                        <br />
                                        <label>{t("page.auto_convert_pr_to_po")}</label>
                                    </div>
                                    <Switch
                                        checked={values.is_auto_convert_tr_to_po}
                                        onChange={(value) =>
                                            setFieldValue("is_auto_convert_tr_to_po", value)
                                        }
                                    />
                                    <span className="switchText">&nbsp;{t("page.enable")}</span>
                                    <TimePicker
                                        value={moment(values.auto_convert_tr_to_po_hour_no, format)}
                                        format={format}
                                        size="large"
                                        style={{ marginTop: 20 }}
                                        onChange={(value: any) => {
                                            setFieldValue(
                                                "auto_convert_tr_to_po_hour_no",
                                                moment(value, "HH").format("HH")
                                                // new Date(value).getHours()
                                            );
                                        }}
                                        disabledMinutes={disabledMinutes}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <div className="label">
                                        <br />
                                        <label>{t("page.check_stock_reason")}</label>
                                    </div>
                                    <Switch
                                        checked={values.is_enable_check_store_reason}
                                        onChange={(value) =>
                                            setFieldValue("is_enable_check_store_reason", value)
                                        }
                                    />
                                    <span className="switchText">&nbsp;{t("page.enable")}</span>
                                    <Button
                                        className="setReasonButton"
                                        onClick={() => {
                                            setReasonModal(true);
                                            setFieldValue("is_check_stock_modal", true);
                                        }}
                                    >
                                        <SetReasonIcon /> {t("page.set_reason")}
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <div className="label">
                                        <br />
                                        <label>{t("page.waste_record_reason")}</label>
                                    </div>
                                    <Switch
                                        checked={values.is_enable_waste_record_reason}
                                        onChange={(value) =>
                                            setFieldValue("is_enable_waste_record_reason", value)
                                        }
                                    />
                                    <span className="switchText">&nbsp;{t("page.enable")}</span>
                                    <Button
                                        className="setReasonButton"
                                        onClick={() => {
                                            setReasonModal(true);
                                            setFieldValue("is_waste_record_modal", true);
                                        }}
                                    >
                                        <SetReasonIcon /> {t("page.set_reason")}
                                    </Button>
                                </Col>
                            </Row>
                        </StyledCard>
                    </div>
                    <div className="card-wrap">
                        <StyledCard>
                            <div className="title" style={{ fontSize: "25px", color: "#000" }}>
                                {t("bill.service_booking")}
                            </div>
                            <Row>
                                <Col span={24}>
                                    <div className="label">
                                        <label>{t("bill.price_payment")}</label>
                                    </div>
                                    <Switch
                                        checked={bookingValue?.is_not_show_price_and_skip_payment}
                                        onChange={(value) =>
                                            changeBooking(
                                                "is_not_show_price_and_skip_payment",
                                                value
                                            )
                                        }
                                    />
                                    <span>&nbsp;{t("bill.price_desc")}</span>
                                </Col>
                                <Col span={24}>
                                    <div className="label">
                                        <br />
                                        <label>{t("bill.select_qty")}</label>
                                    </div>
                                    <Switch
                                        checked={bookingValue?.is_select_qty}
                                        onChange={(value) => changeBooking("is_select_qty", value)}
                                    />
                                    <span>&nbsp;{t("bill.enable")}</span>
                                </Col>
                                <Col span={24}>
                                    <div className="label">
                                        <br />
                                        <label>{t("bill.display")}</label>
                                    </div>
                                    <Radio.Group
                                        onChange={(e) =>
                                            changeBooking("is_display_category", e.target.value)
                                        }
                                        value={bookingValue?.is_display_category}
                                    >
                                        <Radio value={true}>{t("bill.with_cate")}</Radio>
                                        <Radio value={false}>{t("bill.with_no_cate")}</Radio>
                                    </Radio.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <br />
                                    <div className="label">
                                        <label>{t("bill.is_step_service_first")}</label>
                                    </div>
                                    <Switch
                                        checked={bookingValue?.is_step_choose_service_first}
                                        onChange={(value) =>
                                            changeBooking(
                                                "is_step_choose_service_first",
                                                value
                                            )
                                        }
                                    />
                                    <span>&nbsp;{t("bill.is_step_service_first")}</span>
                                </Col>
                                <Col span={12}>
                                    <br />
                                    <p className="label">{t("bill.timepicker_style")}</p>
                                    <Select
                                        showSearch
                                        filterOption={(input, option) =>
                                            //@ts-ignore
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        onChange={(value) => changeBooking(
                                            "booking_timepicker_style",
                                            value
                                        )}
                                        value={bookingValue.booking_timepicker_style}
                                        placeholder=""
                                        className="address-select"
                                    >
                                        {/* <Option value="" key={0}>
                                    Dine in
                                </Option> */}
                                        {timepickerList?.map((sItem, sIndex) => {
                                            return (
                                                <Option value={sItem.value} key={sIndex}>
                                                    {sItem?.title}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <br />
                                    <div className="label">
                                        <label>{t("bill.rebooking_amount")}</label>
                                    </div>
                                    <Switch
                                        checked={bookingValue?.is_check_period_service_rebooking}
                                        onChange={(value) =>
                                            changeBooking(
                                                "is_check_period_service_rebooking",
                                                value
                                            )
                                        }
                                    />
                                </Col>
                                <Col span={12}>
                                    <br />
                                    <Select
                                        showSearch
                                        filterOption={(input, option) =>
                                            //@ts-ignore
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        onChange={(value) => changeBooking(
                                            "period_rebooking_type",
                                            value
                                        )}
                                        value={bookingValue.period_rebooking_type}
                                        placeholder=""
                                        className="address-select"
                                    >
                                        {rebookOptionList?.map((sItem, sIndex) => {
                                            return (
                                                <Option value={sItem.value} key={sIndex}>
                                                    {sItem?.title}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </Col>
                            </Row>
                        </StyledCard>
                    </div>
                    <div className="card-wrap">
                        <StyledCard>
                            <div className="title" style={{ fontSize: "25px", color: "#000" }}>
                                {t("bill.resource")}
                            </div>
                            <Row>
                                <Col span={24}>
                                    <div className="label">
                                        <br />
                                        <label>{t("bill.resource_constraint")}</label>
                                    </div>
                                    <Radio.Group
                                        onChange={(e) =>
                                            changeResource(
                                                "resource_constraint_type",
                                                e.target.value
                                            )
                                        }
                                        value={resourceValue?.resource_constraint_type}
                                    >
                                        <Radio value="store">{t("bill.store")}</Radio>
                                        <Radio value="staff">{t("bill.staff")}</Radio>
                                    </Radio.Group>
                                </Col>
                                {resourceValue?.resource_constraint_type === "store" && (
                                    <Col span={12}>
                                        <div className="label">
                                            <br />
                                            <label>{t("bill.capacity")}</label>
                                        </div>
                                        <Input
                                            value={resourceValue?.store_resource_constraint}
                                            onChange={(e) => {
                                                if (!isNaN(+e.target.value)) {
                                                    if (e.target.value !== "") {
                                                        changeResource(
                                                            "store_resource_constraint",
                                                            parseInt(e.target.value).toString()
                                                        );
                                                    } else {
                                                        changeResource(
                                                            "store_resource_constraint",
                                                            e.target.value
                                                        );
                                                    }
                                                }
                                            }}
                                            style={{ height: "48px" }}
                                        />
                                    </Col>
                                )}
                            </Row>
                        </StyledCard>
                    </div>
                    <div className="card-wrap">
                        <StyledCard>
                            <UploadStyle>
                                <div className="title" style={{ fontSize: "25px", color: "#000" }}>
                                    {t("bill.receipt_settings")}
                                </div>
                                <Row className="uploadImage">
                                    <Col span={6}>
                                        <div className="label">
                                            <br />
                                            <label>{t("bill.receipt_logo")}</label>
                                        </div>
                                        <ModuleUploadImage
                                            images={
                                                values.image_url_logo_on_receipt
                                                    ? [values.image_url_logo_on_receipt]
                                                    : []
                                            }
                                            handleGetImage={handleUploadImage}
                                            multiple={false}
                                            showTitle={false}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={6}>
                                        <div className="label">
                                            <br />
                                            <label>{t("bill.qr_expired_day")}</label>
                                        </div>
                                        <Input
                                            value={values.receipt_qr_expired_day}
                                            onChange={(e) => {
                                                if (!isNaN(+e.target.value)) {
                                                    setFieldValue("receipt_qr_expired_day", e.target.value);
                                                }
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </UploadStyle>
                        </StyledCard>
                    </div>
                    <div className="btn-layout">
                        <StyledSubmitButton
                            type="default"
                            text={t("page.save")}
                            htmlType="submit"
                            // disable={isSubmitting}
                            onClick={handleNewsSubmit}
                        />
                        <StyledCancelButton
                            type="sub"
                            text={t("page.cancel")}
                            htmlType="button"
                            onClick={() => history.push(PATH_STORE_LIST)}
                        />
                    </div>
                </div>
            </form>
            <SetReasonModal
                showModal={reasonModal}
                title={
                    values.is_check_stock_modal
                        ? t("page.check_stock_reason")
                        : t("page.waste_reason")
                }
                handleClose={setReasonModalHandler}
                setFieldValue={setFieldValue}
                values={values}
            />
            <ComponentProductDrawer
                showModal={showModal}
                handleClose={() => setShowModal(!showModal)}
            />
        </div>
    );
});

const UploadStyle = styled.div`
    .preview-field {
        padding: 20px;
        display: flex;
        place-content: center;
        .img-wrap img {
            height: auto;
        }
        .button-upload-preview {
            right: auto;
            bottom: 0px;
            width: auto;
        }
    }
    .uploadImage .ant-col-md-6 {
        width: 100%;
    }
`;
