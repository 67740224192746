import {
    ICreatePosPromotion,
    ICreatePromoCodeToPosPromotion,
    IDeletePosPromotion,
    IDeletePromoCodeToPosPromotion,
    IGetPosPromotion,
    IGetPosPromotions,
    ISetEnablePosPromotion,
    IUpdatePosPromotion,
} from "@interfaces";

import storeFrontAxiosClient from "./storeFrontAxiosClient";
import axiosClient from "./axiosClient";

export const promotionAPI = {
    getPosPromotions: (params: IGetPosPromotions) => {
        const url = "pos_promotion/getPosPromotions";
        return storeFrontAxiosClient.get(url, { params });
    },
    getPosPromotion: (params: IGetPosPromotion) => {
        const url = "pos_promotion/getPosPromotion";
        return storeFrontAxiosClient.get(url, { params });
    },
    createPosPromotion: (params: ICreatePosPromotion) => {
        const url = "pos_promotion/createPosPromotion";
        return storeFrontAxiosClient.post(url, params);
    },
    updatePosPromotion: (params: IUpdatePosPromotion) => {
        const url = "pos_promotion/updatePosPromotion";
        return storeFrontAxiosClient.patch(url, params);
    },
    setEnablePosPromotion: (params: ISetEnablePosPromotion) => {
        const url = "pos_promotion/setEnablePosPromotion";
        return storeFrontAxiosClient.post(url, params);
    },
    deletePosPromotion: (params: IDeletePosPromotion) => {
        const url = "pos_promotion/deletePosPromotion";
        return storeFrontAxiosClient.post(url, params);
    },
    createPromoCodeToPosPromotion: (params: ICreatePromoCodeToPosPromotion) => {
        const url = "pos_promotion/createPromoCodeToPosPromotion";
        return storeFrontAxiosClient.patch(url, params);
    },
    deletePromoCodeToPosPromotion: (params: IDeletePromoCodeToPosPromotion) => {
        const url = "pos_promotion/deletePromoCodeToPosPromotion";
        return storeFrontAxiosClient.post(url, params);
    },
    uploadCouponExcel: (params: FormData) => {
        const url = "coupons/import-alien-coupon";
        return axiosClient.post(url, params);
    },
};
