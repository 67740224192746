import { useTranslation } from "react-i18next";
import { SharedModal, StyledSubmitButton } from "../../shared";
import styled from "styled-components";
import { showErrorMessage, useNotify } from "@utils";
import { Checkbox, Col, Input, Pagination, Row } from "antd";
import { AssignCategoryIcon } from "src/Components/icon/AssignCategoryIcon";
import { useEffect, useState } from "react";
import { AssignProductIcon } from "src/Components/icon/AssignProductIcon";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth, setLoading } from "@redux";
import { orgApi, productAPI } from "@api";
import { BLANK_IMAGE_URL, PAGE_START, PAGINATION } from "@configs";
import {
    ICreatePosPromotion,
    IPaginationNext,
    IProduct,
    IProductCategories,
    IGetVariantsExample,
    IGetPromotionGroupsExample,
} from "@interfaces";
import { ColumnRightArrow } from "src/Components/icon/ColumnRightArrow";
import { SelectedProductBadge } from "src/Components/icon/SelectedProductBadge";

interface IProps {
    showPopup: boolean;
    handleClose: () => void;
    setTableData?: any;
    type: string;
    values: ICreatePosPromotion;
}
const INITAL_PAGE = {
    xpage: PAGE_START,
    xlimit: 50,
};
export const ComponentAddProductRankModal = (props: IProps) => {
    const { showPopup, handleClose, setTableData, type, values } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { error } = useNotify();
    const [variants, setVariants] = useState<IGetVariantsExample[]>([]);
    const [products, setProducts] = useState<IProduct[]>([]);
    const [promotionGroups, setPromotionGroups] = useState<IGetPromotionGroupsExample[]>([]);
    const [totalProducts, setTotalProducts] = useState<number>();
    const [totalVariants, setTotalVariants] = useState<number>();
    const [totalPromotionGroups, settotalPromotionGroups] = useState<number>();
    const [productCategories, setProductCategories] = useState<IProductCategories[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<string>();
    const [colStyle, setColStyle] = useState<number>(-1);
    const organization_id = useSelector(selectAuth).userInfo?.merchant?._id;
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const [pagination, setPagination] = useState<IPaginationNext>(INITAL_PAGE);
    const [textSearch, setTextSearch] = useState<string>("");
    const [selectProduct, setSelectProduct] = useState<string>("");
    const [selectProDetail, setSelectProDetail] = useState<any>({
        first_image_url: "",
        name: "",
        product_code: "",
        _id: "",
    });
    useEffect(() => {
        fetchNews();
    }, []);
    useEffect(() => {
        if (showPopup) {
            setTextSearch("");
        }
    }, [showPopup]);

    useEffect(() => {
        if (values.has_free_variant === true || type === "variant" || type === "variant_option") {
            if (selectedCategory === undefined) {
                getVariants("");
            } else {
                getVariants(selectedCategory);
            }
        } else {
            if (selectedCategory === undefined) {
                getProducts("");
            } else {
                getProducts(selectedCategory);
            }
            if (type === "promotion_group") {
                getPromotionGroups();
            }
        }
    }, [pagination, values.has_free_variant, type, textSearch]);
    const fetchNews = async () => {
        dispatch(setLoading(true));
        try {
            const getAllProductCats = await orgApi.getProductCategorys({
                organization_code: orgCode,
            });
            setProductCategories(getAllProductCats.data.result);
        } catch (err: any) {
            if (err.response) {
                error(showErrorMessage(err.response));
            } else error(t("message.error"));
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleSubmit = () => {
        let payload = {
            first_image_url: selectProDetail.first_image_url,
            name: selectProDetail.name,
            product_code: selectProDetail.product_code,
            _id: selectProDetail._id,
        };
        setTableData('set', payload);
        handleClose();
    };

    const getVariants = async (id?: string) => {
        dispatch(setLoading(true));
        try {
            const getAllVariants = await productAPI.getVariants({
                organization_id: organization_id,
                xpage: pagination.xpage,
                xlimit: pagination.xlimit,
                category_id: id,
                text_search: textSearch,
            });
            setVariants(getAllVariants.data.result);
            setTotalProducts(getAllVariants.data.total);
            setTotalVariants(getAllVariants.data.total);
        } catch (err: any) {
            if (err.response) {
                error(showErrorMessage(err.response));
            } else error(t("message.error"));
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };
    const getProducts = async (id?: string) => {
        dispatch(setLoading(true));
        try {
            const get = await productAPI.getList({
                organization_code: orgCode,
                category_id: id,
                text_search: textSearch,
                xlimit: pagination.xlimit,
                xpage: pagination.xpage,

            });
            setProducts(get.data.result);
            setTotalProducts(get.data.total);
        } catch (err: any) {
            if (err.response) {
                error(showErrorMessage(err.response));
            } else error(t("message.error"));
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };
    const getPromotionGroups = async (id?: string) => {
        dispatch(setLoading(true));
        try {
            const get = await orgApi.getPromotionGroups({
                organization_id: organization_id,
            });
            setPromotionGroups(get.data.result);
            settotalPromotionGroups(get.data.total);
        } catch (err: any) {
            if (err.response) {
                error(showErrorMessage(err.response));
            } else error(t("message.error"));
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const selectProductHandler = (item: IProduct) => {
        setSelectProduct(item._id || "");
        let payload = {
            first_image_url: item.image_url_list?.[0] || "",
            name: item.name,
            product_code: item.product_code,
            _id: item._id,
        };
        setSelectProDetail(payload);
        // setTableData("product_list", [
        //     ...values.product_list,
        //     {
        //         name: item.name,
        //         image_url_list: item.image_url_list,
        //         product_id: item._id,
        //         need_amount: 1,
        //     },
        // ]);
        // const index = values.product_list.findIndex((x) => x.product_id === item._id);
        // if (index > -1) {
        //     let temp = [...values.product_list];
        //     temp.splice(index, 1);
        //     setTableData("product_list", temp);
        // }
    };
    const selectVariantListHandler = (item: IGetVariantsExample) => {
        if (values.variant_list) {
            setTableData("variant_list", [
                ...values.variant_list,
                {
                    variant_id: item.variant_id,
                    product_id: item.product_id,
                    need_amount: 1,
                    name: item.variant_list?.variant_name,
                },
            ]);
            const index = values.variant_list.findIndex((x) => x.variant_id === item.variant_id);
            if (index > -1) {
                let temp = [...values.variant_list];
                temp.splice(index, 1);
                setTableData("variant_list", temp);
            }
        }
    };
    const selectVariantOptionHandler = (item: IGetVariantsExample) => {
        if (values.variant_option) {
            setTableData("variant_option", [
                // ...values.variant_option,
                {
                    variant_id: item.variant_id,
                    product_id: item.product_id,
                    need_amount: 1,
                    name: item.variant_list?.variant_name,
                    addon_id: "",
                    option_id: "",
                },
            ]);
            const index = values.variant_option.findIndex((x) => x.variant_id === item.variant_id);
            if (index > -1) {
                let temp = [...values.variant_option];
                temp.splice(index, 1);
                setTableData("variant_list", temp);
            }
        }
        // setTableData("variant_option", {
        //     ...values.variant_option,
        //     product_id: item.product_id,
        //     variant_id: item.variant_id,
        //     addon_id: "",
        //     option_id: "",
        //     need_amount: 1,
        //     name: item.variant_list?.variant_name,
        // });
    };
    const selectPromotionGroupHandler = (item: IGetPromotionGroupsExample) => {
        if (values.promotion_group_list) {
            setTableData("promotion_group_list", [
                ...values.promotion_group_list,
                {
                    promotion_group_id: item._id,
                    need_amount: 1,
                    name: item.name,
                },
            ]);
            const index = values.promotion_group_list.findIndex(
                (x) => x.promotion_group_id === item._id
            );
            if (index > -1) {
                let temp = [...values.promotion_group_list];
                temp.splice(index, 1);
                setTableData("promotion_group_list", temp);
            }
        }
    };
    const selectVariantHandler = (item: IGetVariantsExample) => {
        setTableData("variant_free_list", [
            ...values.variant_free_list,
            {
                name: item.variant_list?.variant_name,
                variant_id: item.variant_list?._id,
                free_qty: 1,
            },
        ]);
        const index = values.variant_free_list.findIndex(
            (x) => x.variant_id === item.variant_list?._id
        );
        if (index > -1) {
            let temp = [...values.variant_free_list];
            temp.splice(index, 1);
            setTableData("variant_free_list", temp);
        }
    };
    const selectCategoryHandler = (item: IProductCategories) => {
        setTableData("product_category_list", [
            ...values.product_category_list,
            {
                name: item.category,
                product_category_id: item._id,
                need_amount: 1,
            },
        ]);
        const find = values.product_category_list.findIndex(
            (x) => x.product_category_id === item._id
        );
        if (find > -1) {
            let temp = [...values.product_category_list];
            temp.splice(find, 1);
            setTableData("product_category_list", temp);
        }
    };
    const handlePagination = (page) => {
        setPagination({ ...pagination, xpage: page });
    };

    return (
        <SharedModal
            showPopup={showPopup}
            handleClose={handleClose}
            title={t("page.select_product_promotion")}
            width={900}
        >
            <StyledModalContent>
                <Row className="select-layout" gutter={32}>
                    <Col md={24}>
                        <StyledBtnStock>
                            <div className="wholeTable">
                                <div className="wholeTopRow">
                                    {type !== "promotion_group" && (
                                        <div className="topTitleRow leftSide">
                                            <div className="tableTitles">
                                                <div className="titleAndIcon">
                                                    <AssignCategoryIcon />
                                                    {type === "product" && t("page.category")}
                                                    {type === "category" && t("page.category")}
                                                    {type === "variant" && t("page.category")}
                                                </div>
                                                <p className="results">
                                                    {productCategories.length}{" "}
                                                    {t("page.categories")}
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                    {(type === "product" ||
                                        type === "variant" ||
                                        type === "variant_option" ||
                                        values.has_free_variant === true) && (
                                            <div className="topTitleRow rightSide">
                                                <div className="tableTitles">
                                                    <div className="titleAndIcon">
                                                        <AssignProductIcon />
                                                        {(type === "product" ||
                                                            values.has_free_variant) &&
                                                            t("page.products")}
                                                        {type === "variant" ||
                                                            (type === "variant_option" &&
                                                                t("page.variants"))}
                                                        <Input value={textSearch} onChange={(e) => setTextSearch(e.target.value)} />
                                                    </div>
                                                    <p className="results">
                                                        {values.has_free_variant === true ||
                                                            type === "variant"
                                                            ? variants.length
                                                            : products.length}{" "}
                                                        {(type === "product" ||
                                                            values.has_free_variant) &&
                                                            t("page.products")}
                                                        {type === "variant" ||
                                                            (type === "variant_option" &&
                                                                t("page.variants"))}
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                </div>

                                <div className="wholeContent">
                                    {type !== "promotion_group" && (
                                        <div className="leftContent">
                                            {(type === "product" ||
                                                type === "variant" ||
                                                type === "variant_option" ||
                                                values.has_free_variant) === true && (
                                                    <div
                                                        className={
                                                            colStyle === -1
                                                                ? "catCols active"
                                                                : "catCols"
                                                        }
                                                        onClick={() => {
                                                            if (
                                                                values.has_free_variant === true ||
                                                                type === "variant" ||
                                                                type === "variant_option"
                                                            ) {
                                                                getVariants("");
                                                            } else {
                                                                getProducts("");
                                                            }
                                                            setSelectedCategory("");
                                                            setColStyle(-1);
                                                        }}
                                                    >
                                                        {t("page.select_all")}
                                                        <div className="colArrow">
                                                            <ColumnRightArrow />
                                                        </div>
                                                    </div>
                                                )}
                                            {productCategories &&
                                                productCategories.map((item, i) =>
                                                    type === "product" ||
                                                        type === "variant" ||
                                                        type === "variant_option" ||
                                                        values.has_free_variant === true ? (
                                                        <div
                                                            className={
                                                                colStyle === i
                                                                    ? "catCols active"
                                                                    : "catCols"
                                                            }
                                                            key={i}
                                                            onClick={() => {
                                                                if (
                                                                    values.has_free_variant ===
                                                                    true ||
                                                                    type === "variant"
                                                                ) {
                                                                    getVariants(item._id);
                                                                } else {
                                                                    getProducts(item._id);
                                                                }
                                                                setSelectedCategory(item._id);
                                                                setColStyle(i);
                                                            }}
                                                        >
                                                            {item.category}
                                                            {type === "product" && (
                                                                <div className="colArrow">
                                                                    <ColumnRightArrow />
                                                                </div>
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <Checkbox
                                                            className={
                                                                colStyle === i
                                                                    ? "catCols active"
                                                                    : "catCols"
                                                            }
                                                            checked={values.product_category_list.some(
                                                                (x) =>
                                                                    x.product_category_id ===
                                                                    item._id
                                                            )}
                                                            key={i}
                                                            onClick={() => {
                                                                selectCategoryHandler(item);
                                                            }}
                                                        >
                                                            {item.category}
                                                            {type === "product" && (
                                                                <div className="colArrow">
                                                                    <ColumnRightArrow />
                                                                </div>
                                                            )}
                                                        </Checkbox>
                                                    )
                                                )}
                                        </div>
                                    )}
                                    {type === "product" && values.has_free_variant === false && (
                                        <div className="rightContent">

                                            {products &&
                                                products.map((item, i) => (
                                                    <div
                                                        className="productBox"
                                                        key={i}
                                                        onClick={() => {
                                                            selectProductHandler(item);
                                                        }}
                                                    >
                                                        {selectProduct === item._id
                                                            && (
                                                                <div className="overlay">
                                                                    <div className="selectedBadge">
                                                                        <SelectedProductBadge />
                                                                        <p>{t("page.selected")}</p>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        <div className="catCols rightColBoxes">
                                                            <img
                                                                src={
                                                                    (item.image_url_list &&
                                                                        item.image_url_list[0]) ||
                                                                    BLANK_IMAGE_URL
                                                                }
                                                                alt={item.name}
                                                            />
                                                            <div className="namePrice">
                                                                <p>{item.name}</p>
                                                                <p>{item.standard_price}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            {products.length !== totalProducts && (
                                                <div className="loadMore">
                                                    <Pagination
                                                        defaultCurrent={1}
                                                        pageSize={PAGINATION}
                                                        total={totalProducts}
                                                        onChange={handlePagination}
                                                        current={pagination.xpage}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {(type === "variant" ||
                                        (type === "variant_option" &&
                                            values.has_free_variant === false)) && (
                                            <div className="rightContent">
                                                {variants &&
                                                    variants.map((item, i) => (
                                                        <div
                                                            className="productBox"
                                                            key={i}
                                                            onClick={() => {
                                                                if (type === "variant") {
                                                                    selectVariantListHandler(item);
                                                                } else {
                                                                    selectVariantOptionHandler(item);
                                                                }
                                                            }}
                                                        >
                                                            {type === "variant" &&
                                                                values.variant_list &&
                                                                values.variant_list.some(
                                                                    (x) =>
                                                                        x.variant_id === item.variant_id
                                                                ) && (
                                                                    <div className="overlay">
                                                                        <div className="selectedBadge">
                                                                            <SelectedProductBadge />
                                                                            <p>{t("page.selected")}</p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            {type === "variant_option" &&
                                                                values.variant_option &&
                                                                values.variant_option.some(
                                                                    (x) =>
                                                                        x.variant_id === item.variant_id
                                                                ) && (
                                                                    <div className="overlay">
                                                                        <div className="selectedBadge">
                                                                            <SelectedProductBadge />
                                                                            <p>{t("page.selected")}</p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            <div className="catCols rightColBoxes">
                                                                <div className="namePrice">
                                                                    <p>
                                                                        {
                                                                            item.variant_list
                                                                                ?.variant_name
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                {variants.length !== totalVariants && (
                                                    <div className="loadMore">
                                                        <Pagination
                                                            defaultCurrent={1}
                                                            pageSize={PAGINATION}
                                                            total={totalVariants}
                                                            onChange={handlePagination}
                                                            current={pagination.xpage}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    {type === "promotion_group" &&
                                        values.has_free_variant === false && (
                                            <div className="rightContent" style={{ width: "100%" }}>
                                                {promotionGroups &&
                                                    promotionGroups.map((item, i) => (
                                                        <div
                                                            className="productBox"
                                                            key={i}
                                                            onClick={() => {
                                                                selectPromotionGroupHandler(item);
                                                            }}
                                                        >
                                                            {values.promotion_group_list &&
                                                                values.promotion_group_list.some(
                                                                    (x) =>
                                                                        x.promotion_group_id ===
                                                                        item._id
                                                                ) && (
                                                                    <div className="overlay">
                                                                        <div className="selectedBadge">
                                                                            <SelectedProductBadge />
                                                                            <p>
                                                                                {t("page.selected")}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            <div className="catCols rightColBoxes">
                                                                <div className="namePrice">
                                                                    <p>{item.name}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                {promotionGroups.length !==
                                                    totalPromotionGroups && (
                                                        <div className="loadMore">
                                                            <Pagination
                                                                defaultCurrent={1}
                                                                pageSize={PAGINATION}
                                                                total={totalPromotionGroups}
                                                                onChange={handlePagination}
                                                                current={pagination.xpage}
                                                            />
                                                        </div>
                                                    )}
                                            </div>
                                        )}
                                    {values.has_free_variant === true && (
                                        <div className="rightContent">
                                            {variants &&
                                                variants.map((item, i) => (
                                                    <div
                                                        className="productBox"
                                                        key={i}
                                                        onClick={() => {
                                                            selectVariantHandler(item);
                                                        }}
                                                    >
                                                        {values.variant_free_list.some(
                                                            (x) =>
                                                                x.variant_id ===
                                                                item.variant_list?._id
                                                        ) && (
                                                                <div className="overlay">
                                                                    <div className="selectedBadge">
                                                                        <SelectedProductBadge />
                                                                        <p>{t("page.selected")}</p>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        <div className="catCols rightColBoxes">
                                                            <img
                                                                src={BLANK_IMAGE_URL}
                                                                alt={
                                                                    item.variant_list?.variant_name
                                                                }
                                                            />
                                                            <div className="namePrice">
                                                                <p>
                                                                    {
                                                                        item.variant_list
                                                                            ?.variant_name
                                                                    }
                                                                </p>
                                                                <p>{item.variant_list?.price}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            {products.length !== totalProducts && (
                                                <div className="loadMore">
                                                    <Pagination
                                                        defaultCurrent={1}
                                                        pageSize={50}
                                                        total={totalProducts}
                                                        onChange={handlePagination}
                                                        current={pagination.xpage}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </StyledBtnStock>
                    </Col>
                </Row>
            </StyledModalContent>
            <div className="btn-layout" style={{ marginTop: "2rem" }}>
                <StyledSubmitButton
                    type="default"
                    text={t("page.confirm")}
                    htmlType="submit"
                    onClick={handleSubmit}
                />
            </div>
        </SharedModal>
    );
};

const StyledModalContent = styled.div`
    .ant-drawer-body {
        padding: 0 !important;
    }
    .secondTitle {
        font-size: 20px;
    }
    .row {
        display: flex;
        align-items: center;
    }
    .step {
        display: flex;
        align-items: center;
        p {
            margin: 0;
            color: #0263e0;
            font-weight: 700;
            font-size: 16px;
        }
        .whiteText {
            color: #a5a5a5;
        }
    }
    .blueCircle {
        background: #0263e0;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: flex;
        place-content: center;
        align-items: center;
        color: #fff;
        font-size: 12px;
        font-weight: 700;
        margin-right: 10px;
    }
    .grayCircle {
        background: #a5a5a5;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: flex;
        place-content: center;
        align-items: center;
        color: #fff;
        font-size: 12px;
        font-weight: 700;
        margin-right: 10px;
    }
    .arrowRight {
        margin: auto 20px;
    }
    .ant-table-thead > tr > th {
        text-align: left !important;
    }
    .catCols {
        background: #f7f7f8;
        border-radius: 6px;
        display: flex;
        position: relative;
        padding: 20px;
        color: #646464;
        cursor: pointer;
        margin-bottom: 10px;
        place-items: center;
    }
    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
        margin-left: 0;
    }
    .rightColBoxes {
        padding: 0;
        height: 140px;
        width: 125px;
        border-radius: 6px;
        margin: 0;
        display: flex;
        flex-direction: column;
        border: 1px solid #e1e1e1;
        background: #fff;
        overflow: hidden;
        p {
            margin: 0;
            font-weight: 700;
            font-size: 10px;
            line-height: 19px;
            color: #000;
        }
        img {
            width: 100%;
            height: 65%;
            object-fit: cover;
        }
        .namePrice {
            padding: 5px 12px;
        }
    }
    .catCols.active {
        background: #e1e1e1;
    }
    .colArrow {
        position: absolute;
        right: 20px;
        bottom: 0;
        top: 0;
        display: flex;
        gap: 10px;
        place-items: center;
        font-size: 12px;
    }
    .tableTitles {
        display: flex;
        justify-content: space-between;
        place-items: center;
        width: 100%;
        .results {
            color: #646464;
            font-size: 12px;
            font-weight: 300;
            margin: 0;
        }
    }
    .titleAndIcon {
        font-size: 16px;
        font-weight: 700;
        display: flex;
        gap: 10px;
        place-items: center;
    }
    .ant-table-thead > tr > th .ant-table-header-column {
        width: 100%;
    }
    .wholeTable {
        border: 1px solid #e1e1e1;
        position: relative;
        width: 100%;
        min-height: 30vh;
        border-radius: 6px;
    }
    .topTitleRow {
        background: #f7f7f8;
        display: flex;
        padding: 20px;
        border-top-left-radius: 6px;
        border-bottom: 1px solid #e1e1e1;
        justify-content: space-between;
        border-right: 1px solid #e1e1e1;
    }
    .topTitleRow.leftSide {
        width: 30%;
    }
    .topTitleRow.rightSide {
        width: 70%;
        right: 0;
        border-right: none;
        border-top-left-radius: 0;
        border-top-right-radius: 6px;
    }
    .wholeTopRow {
        display: flex;
    }
    .leftContent {
        padding: 10px;
        width: 30%;
        border-right: 1px solid #e1e1e1;
        overflow: scroll;
    }
    .rightContent {
        padding: 10px;
        width: 70%;
        height: 100%;
        overflow-x: scroll;
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        justify-content: space-between;
        position: relative;
    }
    .rightContent::after {
        content: "";
        flex: auto;
    }
    .wholeContent {
        display: flex;
        height: 50vh;
    }
    .topLongGrayBox {
        background: #f7f7f8;
        border-radius: 6px;
        padding: 20px;
        margin-bottom: 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
            margin: 0;
            color: #000;
            font-weight: 700;
        }
    }
    .customTransparentStockBtn {
        height: 42px;
        border: 0.5px solid #646464;
        border-radius: 4px;
        background: #fff;
        color: #646464;
        padding: 0 25px;
        display: flex;
        gap: 2px;
        align-items: center;
    }
    .customTransparentStockBtn:hover {
        border: 0.5px solid #646464 !important;
    }
    .aboveOfTableRow {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;
        .title {
            margin-bottom: 0 !important;
        }
    }
    .ant-input-group-addon {
        width: 70px;
    }
    .topRadioButtonRows {
        margin-bottom: 20px;
    }
    .radioBox {
        padding: 20px;
        background: #f5f5f5;
        border: 1px solid #e1e1e1;
        border-radius: 5px;
        color: #c8c8c8;
        position: absolute;
        left: 0;
        top: 30px;
        width: 100%;
        text-align: center;
        font-weight: 700;
        font-size: 32px;
        line-height: 44px;
    }
    .radioGroupRow {
        width: 100%;
        display: flex;
        place-content: space-between;
        position: relative;
        margin-bottom: 50px;
        .ant-radio-wrapper {
            width: 100%;
            display: flex;
            place-items: center;
            margin-bottom: 20px;
            p {
                margin: 0;
                font-weight: 700;
                font-size: 12px;
                line-height: 16px;
            }
        }
    }
    .activeRadioBtn {
        background: linear-gradient(0deg, rgba(2, 99, 224, 0.08), rgba(2, 99, 224, 0.08)), #ffffff;
        border-radius: 5px;
        border: 1px solid rgba(2, 99, 224, 0.36);
        color: #66a9ff;
    }
    .withIcon {
        display: flex;
        place-content: center;
        place-items: center;
        gap: 10px;
    }
    .label .sub-title {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #646464;
        margin: 0;
    }
    .increaseDecreaseSection {
        background: #f4f9ff;
        border-radius: 8px;
        width: 100%;
        height: 100px;
        margin-top: 20px;
        display: flex;
        place-content: center;
        place-items: center;
        gap: 30px;
        font-weight: 700;
        font-size: 25px;
        line-height: 34px;
        color: #000;
        .decreaseIcon {
            background: #e1e1e1;
            border-radius: 50%;
            display: flex;
            place-content: center;
            place-items: center;
            color: #fff;
            font-size: 40px;
            height: 35px;
            width: 35px;
            padding-bottom: 4px;
            cursor: pointer;
        }
        .increaseIcon {
            background: #0263e0;
            border-radius: 50%;
            display: flex;
            place-content: center;
            place-items: center;
            color: #fff;
            font-size: 30px;
            height: 35px;
            width: 35px;
            padding-bottom: 4px;
            cursor: pointer;
        }
    }
    .selectedBadge {
        position: absolute;
        width: 80px;
        height: 55px;
        background: rgba(255, 255, 255, 0.84);
        backdrop-filter: blur(2px);
        border-radius: 6px;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        display: flex;
        flex-direction: column;
        place-content: center;
        place-items: center;
        z-index: 9;
        cursor: pointer;
        p {
            margin: 0;
            color: #000;
            font-weight: 700;
            font-size: 14px;
            line-height: 19px;
        }
    }
    .overlay {
        background-color: rgba(0, 0, 0, 0.4);
        width: 100%;
        height: 100%;
        z-index: 9;
        position: absolute;
        border-radius: 6px;
        max-height: 140px;
    }
    .productBox {
        position: relative;
        cursor: pointer;
    }
    .loadMore {
        width: 100%;
        display: flex;
        place-content: center;
    }
    .loadMoreBtn {
        display: flex;
        place-content: center;
        place-items: center;
        align-items: center;
        color: #000;
        padding: 10px;
    }
`;
const StyledBtnStock = styled.div`
    .customTransparentStockBtn {
        height: 42px;
        border: 0.5px solid #646464;
        border-radius: 4px;
        background: transparent;
        color: #646464;
        padding: 0 25px;
    }
    .customTransparentStockBtn:hover {
        border: 0.5px solid #646464 !important;
    }
    .customBlueStockBtn {
        height: 42px;
        border: 0.5px solid transparent !important;
        border-radius: 4px;
        background: #0263e0;
        color: #fff;
        padding: 0 25px;
    }
`;
