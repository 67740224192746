export const UploadFileIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="39"
            height="38"
            viewBox="0 0 39 38"
            fill="none"
        >
            <g opacity="0.64">
                <path
                    d="M18.2808 11.5434V5.19576C18.2808 4.52082 18.8192 3.95837 19.5 3.95837C20.1096 3.95837 20.626 4.43099 20.7079 5.02963L20.7192 5.19576V11.5434L28.2873 11.5438C32.0557 11.5438 35.1516 14.6297 35.3255 18.4782L35.3332 18.8197V26.7986C35.3332 30.674 32.3449 33.8552 28.6326 34.0338L28.3032 34.0417H10.6965C6.92817 34.0417 3.84715 30.9713 3.67416 27.1087L3.6665 26.7659L3.6665 18.8033C3.6665 14.9279 6.63986 11.7313 10.3513 11.5518L10.6807 11.5438L18.2808 11.5434L18.2807 21.681L15.7473 19.0649C15.2723 18.5744 14.4965 18.5744 14.0215 19.0649C13.784 19.3102 13.6732 19.6372 13.6732 19.9642C13.6732 20.2127 13.7441 20.4717 13.8941 20.6909L14.0215 20.8471L18.629 25.6214C18.8507 25.8666 19.1673 25.9974 19.4998 25.9974C19.7637 25.9974 20.0276 25.9066 20.2365 25.7344L20.3548 25.6214L24.9623 20.8471C25.4373 20.3566 25.4373 19.5554 24.9623 19.0649C24.5305 18.619 23.8501 18.5785 23.3731 18.9433L23.2365 19.0649L20.719 21.681L20.7192 11.5434H18.2808Z"
                    fill="#F22F46"
                />
            </g>
        </svg>
    );
};
