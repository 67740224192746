import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Switch, Col, Row, Avatar, Menu, Dropdown, Button } from "antd";
import { MoreHorizontal } from "react-feather";

import { IDataexample, INewsCategory } from "@interfaces";
import {
    enumNewsType,
    PATH_PERMISSION_POS,
    PATH_PRODUCT_CREATE,
    PATH_SERVICES_CREATE,
} from "@configs";
import { selectAuth, store } from "@redux";
import { useSelector } from "react-redux";
import { IconDrag } from "@components";
import { useNotify } from "@utils";
import React, { useEffect, useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import styled from "styled-components";
import { ComponentPermissionEditModal } from "../store/ComponentPermissionEditModal";
import axios from "axios";
import { permissionPosAPI } from "@api";

interface IProps {
    data: IDataexample;
    itemJson: string;
    handleEdit: (status: boolean, data: IDataexample) => void;
    callbackGetList: () => void;
    index: number;
    newType?: enumNewsType;
    categories: INewsCategory[];
    stores: any[];
    sendToParent: any;
}

export const ComponentRowPermissionEachStore = (props: IProps) => {
    //hooks
    const { t } = useTranslation();
    const { success, error } = useNotify();
    const organization_id = useSelector(selectAuth).auth?.organization_data?._id;
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    //props
    const { data, handleEdit, callbackGetList, itemJson, newType, stores, index, sendToParent } =
        props;
    const [show, setShowModal] = useState(false);
    const [showPopup2, setShowPopup2] = useState<boolean>(false);
    const [Refresh, setRefresh] = useState<number>(0);
    const [employeeID, setEmployeeID] = useState<string>("");
    const [employee, setEmployee] = useState<any>({});

    const { attributes, listeners, setNodeRef, transform } = useSortable({
        id: itemJson,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
    };

    const showModal = () => {
        setShowModal(!show);
    };

    const editHandle = async () => {
        try {
            setEmployeeID(stores[index]._id);
            const getEmployee = await permissionPosAPI.getEmployee({
                organization_code: orgCode,
                employee_id: stores[index]._id,
            });
            console.log(getEmployee.data.result);
            setEmployee(getEmployee.data.result);
            // console.log(employeeID);
            setShowPopup2(!showPopup2);
        } catch (err) {
            console.log(err);
        }
    };
    return (
        <Styles>
            <div className="table-element" ref={setNodeRef} style={style}>
                <div className="table-row">
                    <Row
                        style={{ height: "100%" }}
                        gutter={24}
                        type="flex"
                        align="middle"
                        justify="space-between"
                    >
                        <Col className="col-item" span={12}>
                            <p className="news-title" onClick={editHandle}>
                                {stores[index].name || t("page.empty_text")}
                            </p>
                        </Col>
                        <Col className="col-item" span={9}>
                            <p style={{ fontWeight: 700, fontSize: 16, color: "#000" }}>
                                {stores[index].permission_type ? t("page." + stores[index].permission_type) : "-"}
                            </p>
                        </Col>
                        <Col className="col-item" span={3} style={{ placeContent: "end" }}>
                            <Button
                                style={{
                                    height: "42px",
                                    border: "0.5px solid #646464",
                                    borderRadius: "4px",
                                    background: "#fff",
                                    color: "#646464",
                                    marginRight: "10px",
                                }}
                                onClick={editHandle}
                            >
                                {t("page.edit")}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
            <ComponentPermissionEditModal
                showPopup2={showPopup2}
                handleClose2={() => {
                    setShowPopup2(!showPopup2);
                }}
                sendToParent={sendToParent}
                employee={employee}
                employeeID={employeeID}
                setEmployee={setEmployee}
            />
        </Styles>
    );
};
export const Styles = styled.div`
    .table-row {
        height: 97px !important;
    }
    .news-title {
        cursor: pointer;
    }
`;
