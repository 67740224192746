export default {
    bill_calculation_setting: "ตั้งค่าองค์กร",
    vat_setting_organization: "ตั้งค่า ภาษีมูลค่าเพิ่ม",
    default_tax_rate_selection: "เลือกวิธีการเก็บภาษี",
    exclude_vat: "ไม่รวมภาษีมูลค่าเพิ่ม",
    include_vat: "รวมภาษีมูลค่าเพิ่ม",
    amount_exclude_vat: "จำนวน Exclude VAT ที่เรียกเก็บ",
    rounding_number_settings: "ตั้งค่ารอบการปัดเศษชำระเงิน",
    rounding_number: "รอบการปัดเศษ",
    round_up: "ปัดเศษขึ้น",
    round_down: "ปัดเศษลง",
    sale_round_setting: "ตั้งค่ารอบขาย",
    sale_round_cash_record: "การบันทึกเงินสด เปิด/ปิด",
    cash_amount: "จำนวนเดียว",
    cash_type: "แยกประเภท ธนบัตร/เหรียญ",
    staff_pin_bill: "PIN พนักงานหลังทุกออเดอร์",
    no: "ไม่ใช่",
    yes: "ใช่",
    code: "หมายเลข",
    hint_code: "Hint code",
    channel: "Channel",
    variant: "Variant",
    addon: "Add-on",
    receipt_run_number: "Running number ใบเสร็จ",
    daily: "รายวัน",
    monthly: "รายเดือน",
    service_booking: "การจอง",
    price_payment: "ราคาและการชำระเงิน",
    price_desc: "ไม่แสดงราคาและข้ามการชำระเงิน",
    select_qty: "Select Qty",
    enable: "เปิดใช้งาน",
    display: "แสดง",
    with_cate: "มีหมวดหมู่",
    with_no_cate: "ไม่มีหมวดหมู่",
    resource: "Resource",
    resource_constraint: "Resource Constraint",
    store: "สาขา",
    staff: "พนักงาน",
    capacity: "Capacity",
    receipt_settings: "ตั้งค่าใบเสร็จ",
    receipt_logo: "โลโก้ใบเสร็จ",
    qr_expired_day: "Receipt qr code expired day",
    is_step_service_first: "Is choose service before store",
    timepicker_style: "Time Picker style",
    rebooking_amount: "Rebooking amount",
    rebooking_desc: "This object has rebooking restrictions",
    rebook_op_weekly: "Weekly",
    rebook_op_monthly: "Monthly",
    rebook_op_yearly: "Yearly",
    rebook_op_all: "All time",
};
