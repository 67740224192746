import { StyledNewDetail } from "@components";
import { ModuleExportTableForm } from "@modules";

export const PageExportTable = () => {
    return (
        <StyledNewDetail>
            <ModuleExportTableForm />
        </StyledNewDetail>
    );
};
