import React, { useEffect, memo, useState, useRef, useContext } from "react";
import { FieldArray, Formik, useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import {
    Switch,
    Row,
    Col,
    Select,
    Radio,
    Checkbox,
    Input,
    Tag,
    Popconfirm,
    Table,
    Form,
    Tooltip,
    Button,
    Divider,
} from "antd";
import styled from "styled-components";
import moment from "moment";
import {
    INews,
    INewsData,
    INewsCategory,
    INewsCategoryParams,
    ICreateProduct,
    IProductEdit,
    IProduct,
    IVariantDimensionsInside,
    IVariantDimensions,
    IVariant,
    IAddonOptionList,
    IInnerAddonOptionList,
    IGetPromotionGroupsExample,
    ICreateProductParams,
} from "@interfaces";
import {
    SharedCKEditor,
    StyledSubmitButton,
    StyledCancelButton,
    StyledCard,
    SharedInput,
    ComponentPeriod,
    StyledTableButtonAddRow,
    IconTrash,
    BranchIcon,
    StyledTableButtonTop,
} from "@components";
import { useYup } from "@validations";
import { getCategories, setLoading, selectAuth } from "@redux";
import { ModuleUploadImage } from "@modules";
import { showErrorMessage, theme, useNotify } from "@utils";
import { enumAddNews, PAGE_START, PAGINATION, PATH_PRODUCT, PATH_PRODUCT_CREATE } from "@configs";
import { newsAPI, orgApi, productAPI, storeAPI } from "@api";
import {
    ComponentProductAddonModal,
    ComponentProductDrawer,
    ComponentProductExcludeModal,
} from "src/Components/product";
import { RadioChangeEvent } from "antd/lib/radio";
import { DescriptionIcon } from "src/Components/icon/DescriptionIcon";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { VariantGrayIcon } from "src/Components/icon/VariantGrayIcon";
import { ComponentProductBarcodeModal } from "src/Components/product/ProductBarcodeModal";
import { BarcodeIcon } from "src/Components/icon/BarcodeIcon";
import { PrintIconCreateProduct } from "src/Components/icon/PrintIconCreateProduct";
import { DeleteIcon } from "src/Components/icon/DeleteIcon";
import { DeleteFilled } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
interface IProps {
    product: IProduct;
    categories: INewsCategory[] | undefined;
}
const INITAL_PAGE = {
    xpage: PAGE_START,
    xlimit: PAGINATION,
};

const optionListInit: any = "";
// form inital data
const initData: IProduct = {
    organization_code: "",
    organization_id: "",
    product_code: "",
    name: "",
    standard_price: 0,
    discount_in_baht: 0,
    category_id: "",
    image_url_list: [],
    is_variant: false,
    is_auto_consumable: true,
    variant_dimensions: [
        {
            dimension_name: "",
            dimension_option_name: [""],
        },
    ],
    variant_name_header: "",
    variant_list: [
        {
            _id: uuidv4(),
            variant_code: "",
            variant_name: "",
            price: 0,
            is_enable: true,
            raw_price: 0,
            discount_in_percent: 0,
            is_auto_consumable: false,
            store_list: [],
            dimension_list: [
                {
                    dimension_name: "",
                    dimension_option_name: "",
                },
            ],
            consumable_list: [],
            consumable_used_qty: 1,
        },
    ],
    description: "",
    condition: "",
    text_block_list: [],
    is_enable: false,
    addon_id_list: [],
    product_addon_list: [],
    product_addon_list_master: [],
    addon_list: [],
    sku_earn_point: 0,
    hint_code: "",
    brand_id: "",
    brand_name: "",
    promotion_group_list: [],
    promotion_group_name: "",
};
interface IWeightDetail {
    is_weight: boolean;
    price: number;
    weight: number;
    unit_index: any;
    unit_name: string;
    is_qty: true;
    restock_unit: string;
    per_unit: number;
}
export const ModuleProductForm = memo((props: IProps) => {
    //hook
    const { YupProduct } = useYup();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const { error, success, warning } = useNotify();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showPopup2, setShowPopup2] = useState<boolean>(false);
    const [showAddonPopup, setShowAddonPopup] = useState<boolean>(false);
    const [TempBarcode, setTempBarcode] = useState<string>();
    const organization_id = useSelector(selectAuth).userInfo?.merchant?._id;
    const searchInputRef = useRef<any | null>(null);
    const pathName = window.location.pathname;
    const createNews = Yup.object().shape(YupProduct);
    const [currentChangeItem, setCurrentChageItem] = useState<IVariant>();
    const [autoGenSwitch, setAutoGenSwitch] = useState(false);
    const [multipleImages, setMultipleImages] = useState<string[]>([]);
    const org_id = useSelector(selectAuth).userInfo?.merchant._id;

    const { product, categories } = props;
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const [addonList, setAddonList] = useState<any[]>([]);
    const [firstVariantID, setFirstVariantID] = useState<string>();
    const [initValues, setInitValues] = useState<IProduct>(initData);
    const formRef = useRef<any>();
    const [excludeModal, setExcludeModal] = useState<boolean>(false);
    const [allStore, setAllStore] = useState<any[]>([]);
    const [oldDimension, setOldDimension] = useState<any[]>([]);
    const [focusText, setFocusText] = useState<string>("");
    const [selectStore, setSelectStore] = useState<string[]>([]);
    const [configDetail, setConfigDetail] = useState<any>();
    const [brandList, setBrandList] = useState<any[]>([]);
    const [promotionGroupList, setPromotionGroupList] = useState<IGetPromotionGroupsExample[]>([]);
    const [unitList, setUnitList] = useState<any[]>([]);
    const [weightDetail, setWeightDetail] = useState<IWeightDetail>({
        is_weight: false,
        price: 0,
        weight: 0,
        unit_index: 0,
        unit_name: "",
        is_qty: true,
        restock_unit: "",
        per_unit: 1,
    });
    const [isSeparate, setIsSeparate] = useState<boolean>(true);

    useEffect(() => {
        fetchNews();
        fetchORGSetting();
        fetchBrand();
        fetchUnitList();
    }, []);
    useEffect(() => {
        if (product) {
            if (product.is_share_consumable) {
                let oArr = [...oldColumn];
                let cArr = [...column];
                let oFind = oArr.find((cItem) => cItem?.dataIndex === "consumable_used_qty");
                if (oFind === undefined) {
                    oArr.push(INIT_TABLE_BASE);
                    setOldColumn([...oArr]);
                }
                let nFind = cArr.find((cItem) => cItem?.dataIndex === "consumable_used_qty");
                if (nFind === undefined) {
                    cArr.push(INIT_TABLE_BASE);
                    setColumn([...cArr]);
                }
            }
            setOldDimension(product.variant_dimensions);
            if (product?.excluded_store_id_list) {
                setSelectStore(product?.excluded_store_id_list);
            }
            setFirstVariantID(product?.variant_list?.[0]?._id);
            let tempPromotionGroupList: any = [];
            for (let i = 0; i < product?.promotion_group_list?.length; i++) {
                tempPromotionGroupList.push(product.promotion_group_list[i].promotion_group_id);
            }
            setInitValues({
                name: product.name,
                _id: product._id,
                organization_code: orgCode,
                organization_id: organization_id,
                product_code: product.product_code,
                standard_price: product.standard_price,
                discount_in_baht: product.discount_in_baht || 0,
                category_id: product.category_id,
                description: product.description,
                is_variant: product.is_variant,
                variant_list: product.variant_list,
                variant_dimensions: product.variant_dimensions,
                addon_id_list: product.addon_id_list,
                image_url_list: product.image_url_list,
                is_enable: true,
                is_auto_consumable: product.is_auto_consumable,
                sku_earn_point: product?.sku_earn_point,
                hint_code: product?.hint_code,
                brand_id: product?.brand_id,
                brand_name: product?.brand_name,
                product_addon_list: product.product_addon_list,
                promotion_group_list: tempPromotionGroupList,
                promotion_group_name: product.promotion_group_name,
                product_addon_list_master: product.product_addon_list_master,
            });
            let weightPayload = {
                is_weight: product?.is_weight,
                price: product?.weight_detail?.price_in_baht,
                weight: product?.weight_detail?.per_uom_qty,
                unit_index: "",
                unit_name: product?.uom_detail?.uom_name,
                is_qty: product?.is_auto_consumable,
                restock_unit: product.auto_consumable_formula?.restock_unit_uom_id,
                per_unit: product.auto_consumable_formula?.per_use_unit,
            };
            if (unitList?.length > 0) {
                let find = unitList?.findIndex((uItem) => {
                    return uItem._id === product?.uom_detail?.uom_id;
                });
                //@ts-ignore
                weightPayload.unit_index = find;
            }
            //@ts-ignore
            setWeightDetail({ ...weightPayload });
            setIsSeparate(!product?.is_share_consumable || false);
            if (
                !product.addon_option_list ||
                (product.product_addon_list_master &&
                    product.product_addon_list_master.length !== 0)
            ) {
                // Sorting Just like the product_addon_list
                let temp: any[] = [];
                for (let i = 0; i < product?.product_addon_list_master?.length; i++) {
                    temp.push({
                        addon_name: product?.product_addon_list_master[i].addon_name,
                        is_multi_choice: product?.product_addon_list_master[i].is_multi_choice,
                        is_require: product?.product_addon_list_master[i]?.is_require,
                        product_option_list: product?.product_addon_list_master[i]?.option_list,
                        product_addon_id: product?.product_addon_list_master[i]?._id,
                    });
                }
                temp.sort((a, b) => {
                    const indexA = product.product_addon_list.findIndex(
                        (addon) => addon.addon_id === a.product_addon_id
                    );

                    const indexB = product.product_addon_list.findIndex(
                        (addon) => addon.addon_id === b.product_addon_id
                    );

                    return indexA - indexB;
                });
                setAddonList(temp);
                // Sorting Just like the product_addon_list
            }
            setMultipleImages(product.image_url_list);
            setDiscountType("baht");
            if (product?.variant_list?.[0]?.variant_code === "") {
                setHasBarcode(false);
            } else {
                setHasBarcode(true);
                setBarcode(product?.variant_list?.[0]?.variant_code || "");
            }
            setTimeout(() => {
                refreshIt();
            }, 500);
        }
    }, [product, categories]);

    let INIT_TABLE_BASE: any = {
        dataIndex: "consumable_used_qty",
        title: `${t("page.product_form.table_base")}`,
        render: (text, record, index) => (
            <Input
                value={text}
                onChange={(e) => {
                    if (e.target.value === "") {
                        record.consumable_used_qty = "";
                        setRefresh(Refresh + 1);
                    } else if (!isNaN(+e.target.value)) {
                        record.consumable_used_qty = parseInt(e.target.value);
                        setRefresh(Refresh + 1);
                    }
                }}
                disabled={index === 0}
            />
        ),
    };

    const fetchORGSetting = async () => {
        const getConfig = await orgApi.getVatDetail({
            organization_id: org_id,
        });
        setConfigDetail(getConfig?.data?.result);
    };

    const fetchBrand = async () => {
        const brandRes = await orgApi.getBrand({
            organization_id: org_id,
        });
        setBrandList(brandRes.data.result);
    };

    const fetchUnitList = async () => {
        try {
            const resUOM = await orgApi.getUnitList({
                organization_id: org_id || "",
                xpage: 1,
                xlimit: 10000,
            });
            setUnitList(resUOM?.data?.result);
            if (resUOM?.data?.result?.length > 0) {
                let obj = { ...weightDetail };
                obj.unit_index = 0;
                obj.unit_name = resUOM?.data?.result[0]?.uom_name;

                if (weightDetail?.restock_unit === "") {
                    obj.restock_unit = resUOM?.data?.result[0]?._id;
                }
                setWeightDetail({ ...obj });
            }
        } catch (err: any) {
            error(err.msg);
        }
    };

    const { Option } = Select;
    const handleEnter = (e: string) => {
        if (e === "Enter") {
            createCategory();
            searchInputRef.current.focus();
        }
    };
    const createCategory = async () => {
        dispatch(setLoading(true));
        try {
            const payload: INewsCategoryParams = {
                category_name: "",
                organization_id: organization_id || "",
                is_enable: true,
            };
            const response = await orgApi.createCat(payload);
            success(response.data.msg);
            dispatch(getCategories({ organization_code: orgCode || "" }));
        } catch (err: any) {
            if (err) {
                error(err.msg);
            } else error(t("message.create.fail"));
        } finally {
            dispatch(setLoading(false));
        }
    };
    const fetchNews = async () => {
        dispatch(setLoading(true));
        try {
            const getStores = await storeAPI.getList({
                organization_code: orgCode,
                xpage: INITAL_PAGE.xpage,
                xlimit: 4 * INITAL_PAGE.xlimit,
            });
            const getPromotionGroups = await orgApi.getPromotionGroups({
                organization_id: org_id,
            });
            if (getPromotionGroups.data.error === "0") {
                setPromotionGroupList(getPromotionGroups.data.result);
            }
            if (getStores.data.error === "0") {
                setAllStore(getStores.data.result);
            }
        } catch (err) {
            console.error(err);
        } finally {
            dispatch(setLoading(false));
        }
    };

    const changeWeightDetail = (field: string, value: any) => {
        let obj = { ...weightDetail };
        obj[field] = value;
        setWeightDetail(obj);
    };

    const handleSaveProduct = async (values: ICreateProduct) => {
        let addonSelected: string[] = [];
        addonList.map((aItem, aIndex) => {
            addonSelected.push(aItem?.product_addon_id);
        });
        const variantDimentions = [
            {
                dimension_name: "selected",
                dimension_option_name: ["general"],
            },
        ];
        const noVariantList = [
            {
                variant_code: barcode,
                variant_name: formRef.current?.values.name,
                is_enable: true,
                store_list: [],
                dimension_list: [
                    {
                        dimension_name: "selected",
                        dimension_option_name: "general",
                    },
                ],
                raw_price: formRef.current?.values.standard_price,
                ...(product ? { _id: firstVariantID } : {}),
                consumable_used_qty: 1,
            },
        ];
        let tempPromotionGroupList: any = [];
        for (let i = 0; i < formRef?.current?.values?.promotion_group_list.length; i++) {
            tempPromotionGroupList.push({
                promotion_group_id: formRef?.current?.values?.promotion_group_list[i],
            });
        }
        try {
            dispatch(setLoading(true));
            if (product) {
                let vList = values.is_variant ? values.variant_list : noVariantList;
                if (weightDetail?.is_weight) {
                    if (vList?.[0]?.raw_price !== undefined) {
                        vList[0].raw_price =
                            Number(weightDetail.price) / Number(weightDetail.weight) || 0;
                    }
                }
                let updatePayload: IProductEdit = {
                    organization_id: organization_id,
                    product_id: formRef.current?.values._id,
                    product_code: formRef.current?.values.product_code,
                    name: formRef.current?.values.name,
                    standard_price: formRef.current?.values.standard_price,
                    discount_in_baht: formRef.current?.values.discount_in_baht,
                    category_id: formRef.current?.values.category_id,
                    is_auto_consumable: values.is_auto_consumable,
                    image_url_list: multipleImages,
                    is_variant: formRef.current?.values.is_variant,
                    variant_name_header: "",
                    variant_list: vList,
                    description: formRef.current?.values.description,
                    condition: "",
                    text_block_list: [],
                    is_delete: false,
                    is_enable: true,
                    _id: product._id,
                    addon_id_list: addonSelected,
                    product_addon_list: formRef.current?.values.product_addon_list,
                    variant_dimensions: formRef.current?.values.is_variant
                        ? formRef.current?.values.variant_dimensions
                        : variantDimentions,
                    excluded_store_id_list: selectStore,
                    sku_earn_point: formRef.current?.values.sku_earn_point || 0,
                    hint_code: formRef.current?.values?.hint_code || "",
                    brand_id: formRef.current?.values?.brand_id,
                    promotion_group_list: tempPromotionGroupList,
                    promotion_group_name: formRef?.current?.values?.promotion_group_name,
                    auto_consumable_formula: {
                        restock_unit_uom_id: weightDetail?.restock_unit,
                        per_use_unit: weightDetail?.per_unit,
                    },
                    is_weight: weightDetail?.is_weight,
                    weight_detail: {
                        price_in_baht: weightDetail?.price,
                        per_uom_qty: weightDetail?.weight,
                    },
                    uom_detail: {
                        uom_id: unitList?.[weightDetail?.unit_index]?._id || unitList?.[0]?._id,
                    },
                    is_share_consumable: !isSeparate,
                };
                const res = await productAPI.update(updatePayload);
                if (res.data.error === "0") {
                    success(t("message.update.success"));
                    history.push(PATH_PRODUCT);
                } else {
                    error(res.data.msg);
                }
            } else {
                let vList = formRef.current?.values.is_variant
                    ? formRef.current?.values.variant_list
                    : [{ ...noVariantList[0], variant_code: formRef.current?.values.product_code }];
                if (weightDetail?.is_weight) {
                    if (vList?.[0]?.raw_price !== undefined) {
                        vList[0].raw_price =
                            Number(weightDetail.price) / Number(weightDetail.weight) || 0;
                    }
                }
                let createPayload: ICreateProductParams = {
                    organization_id: organization_id,
                    product_code: formRef.current?.values.product_code,
                    name: formRef.current?.values.name,
                    standard_price: formRef.current?.values.standard_price,
                    discount_in_baht: formRef.current?.values.discount_in_baht,
                    category_id: formRef.current?.values.category_id,
                    is_auto_consumable: values.is_auto_consumable,
                    image_url_list: multipleImages,
                    is_variant: formRef.current?.values.is_variant,
                    variant_name_header: "",
                    variant_list: vList,
                    description: formRef.current?.values.description,
                    condition: "",
                    text_block_list: [],
                    is_enable: true,
                    variant_dimensions: values.is_variant
                        ? values.variant_dimensions
                        : variantDimentions,
                    addon_id_list: addonSelected,
                    product_addon_list: formRef.current?.values.product_addon_list,
                    excluded_store_id_list: selectStore,
                    sku_earn_point: formRef?.current?.values?.sku_earn_point,
                    hint_code: formRef?.current?.values?.hint_code,
                    brand_id: formRef?.current?.values?.brand_id,
                    promotion_group_name: formRef?.current?.values?.promotion_group_name,
                    promotion_group_list: tempPromotionGroupList,

                    auto_consumable_formula: {
                        restock_unit_uom_id: weightDetail?.restock_unit,
                        per_use_unit: weightDetail?.per_unit,
                    },
                    is_weight: weightDetail?.is_weight,
                    weight_detail: {
                        price_in_baht: weightDetail?.price,
                        per_uom_qty: weightDetail?.weight,
                    },
                    uom_detail: {
                        uom_id: unitList?.[weightDetail?.unit_index]._id,
                    },
                    is_share_consumable: !isSeparate,
                };
                const res = await productAPI.create(createPayload);
                if (res.data.error === "0") {
                    success(t("message.create.success"));
                    history.push(PATH_PRODUCT);
                } else {
                    error(res.data.msg);
                }
            }
        } catch (err: any) {
            if (err.response) {
                error(showErrorMessage(err.response));
            } else error(t("message.error"));
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleCancel = () => {
        history.goBack();
    };

    const handleUploadImage = (images: string[]) => {
        setMultipleImages(images);
    };

    const [hasBarcode, setHasBarcode] = useState(false);
    const [discountType, setDiscountType] = useState("");
    const hasBarcodeChange = (e: RadioChangeEvent) => {
        setHasBarcode(!hasBarcode);
    };
    const hasChangeConsumable = (isCheck: boolean, setFieldValue) => {
        if (product) {
            return;
        } else {
            setFieldValue("is_auto_consumable", !isCheck);
        }
    };

    // Editable Variant Table

    interface IMyTableData {
        id?: string;
        variant_name: string;
        raw_price: number;
        barcode: string;
        variant_code: string;
        store_list?: [];
        dimension_list?: IVariantDimensionsInside[];
    }

    const [tempVariant, setTempVariant] = useState<IMyTableData[] | any>([]);
    const [barcode, setBarcode] = useState<string>("");
    const [tableData, setTableData] = useState(tempVariant);
    const [Refresh, setRefresh] = useState<number>(0);

    // Fix re-rendering column titles
    const refreshIt = () => {
        setRefresh(Refresh + 1);
    };

    useEffect(() => {
        setTableData(formRef.current?.values.variant_list);
        let cArr = [
            {
                dataIndex: `dimension_name`,
                title: `${formRef.current?.values.variant_list &&
                    formRef.current?.values.variant_list[0]?.dimension_list &&
                    formRef.current?.values.variant_list[0]?.dimension_list[0]?.dimension_name
                    }`,
                render: (text, record, index) => (
                    <div style={{ width: "150px", placeItems: "center", display: "flex" }}>
                        {`${record.dimension_list[0] &&
                            record.dimension_list[0].dimension_option_name
                            }`}
                    </div>
                ),
            },
            {
                dataIndex: `dimension_list`,
                key: `dimension_list`,
                title: `${formRef.current?.values.variant_list &&
                    formRef.current?.values.variant_list[0].dimension_list &&
                    formRef.current?.values.variant_list[0].dimension_list[1] !== undefined
                    ? formRef.current?.values.variant_list[0].dimension_list[1].dimension_name
                    : ""
                    }`,
                render: (text, record, index) => (
                    <div
                        style={{
                            width: "150px",
                            placeItems: "center",
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                        }}
                    >
                        {`${record.dimension_list[1] &&
                            record.dimension_list[1].dimension_option_name
                            }`}
                    </div>
                ),
            },
            {
                dataIndex: "raw_price",
                title: `${t("page.price")}`,
                render: (text, record, index) => (
                    <Input
                        value={text}
                        onChange={(value) => {
                            record.raw_price = parseInt(value.target.value);
                            setRefresh(Refresh + 1);
                        }}
                        suffix={<Tooltip>฿</Tooltip>}
                    />
                ),
            },
            {
                dataIndex: "variant_code",
                title: `${t("page.barcode")}`,
                render: (text, record, index) => (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        {record.variant_code === "" ? (
                            <Button
                                type="primary"
                                className="ant-btn-custom"
                                onClick={() => {
                                    handleBarcodeModal(record);
                                }}
                                style={{
                                    background: "#0263e0",
                                }}
                            >
                                + {t("page.enter_barcode")}
                            </Button>
                        ) : (
                            <Button
                                type="default"
                                className="ant-btn-custom"
                                onClick={() => {
                                    handleBarcodeModal(record);
                                    setTempBarcode(record.variant_code);
                                }}
                            >
                                {t("page.view_barcode")}
                            </Button>
                        )}
                    </div>
                ),
            },
        ];
        let oArr = [
            {
                dataIndex: `dimension_name`,
                title: `${formRef.current?.values.variant_list &&
                    formRef.current?.values.variant_list[0]?.dimension_list &&
                    formRef.current?.values.variant_list[0]?.dimension_list[0]?.dimension_name
                    }`,
                render: (text, record, index) => (
                    <div
                        style={{
                            width: "200px",
                            placeItems: "center",
                            display: "flex",
                        }}
                    >
                        {`${record.dimension_list[0] &&
                            record.dimension_list[0].dimension_option_name
                            }`}
                    </div>
                ),
            },
            {
                dataIndex: "raw_price",
                title: `${t("page.price")}`,
                render: (text, record, index) => (
                    <Input
                        value={text}
                        onChange={(value) => {
                            record.raw_price = parseInt(value.target.value);
                            setRefresh(Refresh + 1);
                        }}
                        suffix={<Tooltip>฿</Tooltip>}
                    />
                ),
            },
            {
                dataIndex: "variant_code",
                title: `${t("page.barcode")}`,
                render: (text, record, index) => (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        {record.variant_code === "" ? (
                            <Button
                                type="primary"
                                className="ant-btn-custom"
                                onClick={() => {
                                    handleBarcodeModal(record);
                                }}
                                style={{
                                    background: "#0263e0",
                                }}
                            >
                                + {t("page.enter_barcode")}
                            </Button>
                        ) : (
                            <Button
                                type="default"
                                className="ant-btn-custom"
                                onClick={() => {
                                    handleBarcodeModal(record);
                                    setTempBarcode(record.variant_code);
                                }}
                            >
                                {t("page.view_barcode")}
                            </Button>
                        )}
                    </div>
                ),
            },
        ];
        if (configDetail?.hint_code_config?.is_product) {
            let addC = {
                dataIndex: "hint_code",
                title: `${t("page.hint_code")}`,
                render: (text, record, index) => (
                    <Input
                        value={text}
                        onChange={(value) => {
                            // record.raw_price = parseInt(value.target.value);
                            // setRefresh(Refresh + 1);
                            record.hint_code = value.target.value;
                            setRefresh(Refresh + 1);
                        }}
                    // suffix={<Tooltip>฿</Tooltip>}
                    />
                ),
            };
            cArr.splice(2, 0, addC);
            oArr.splice(1, 0, addC);
        }
        if (isSeparate) {
            let oFind = oArr.filter((cItem) => cItem?.dataIndex !== "consumable_used_qty");
            setOldColumn([...oFind]);
            let nFind = cArr.filter((cItem) => cItem?.dataIndex !== "consumable_used_qty");
            setColumn([...nFind]);
        } else {
            let oFind = oArr.find((cItem) => cItem?.dataIndex === "consumable_used_qty");
            if (oFind === undefined) {
                oArr.push(INIT_TABLE_BASE);
                setOldColumn([...oArr]);
            }
            let nFind = cArr.find((cItem) => cItem?.dataIndex === "consumable_used_qty");
            if (nFind === undefined) {
                cArr.push(INIT_TABLE_BASE);
                setColumn([...cArr]);
            }
        }
        setColumn([...cArr]);
        setOldColumn([...oArr]);
    }, [Refresh, product, configDetail]);
    // Fix re-rendering column titles
    const handleBarcodeModal = (itemName) => {
        setShowPopup2(true);
        setCurrentChageItem(itemName);
    };

    // Has Barcode Section
    const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setBarcode(e.target.value);
    };
    const autoGenBarcode = Math.floor(1000000000000 + Math.random() * 9000000000000).toString();
    const autoGenChange = () => {
        setAutoGenSwitch(!autoGenSwitch);
        if (autoGenSwitch === false) {
            setBarcode(autoGenBarcode);
        } else {
            setBarcode("");
        }
    };
    // Has Barcode Section
    const [state, setState] = useState<any>([]);
    const [column, setColumn] = useState<any>([]);
    const [oldColumn, setOldColumn] = useState<any>([]);

    // Ekasit Codes
    const refeashing = (values, setFieldValue, action?: string) => {
        // values is not when delete so please use formRef.current.values
        let new_variant_list: any = [];

        // -----------------------
        // action type list
        // 1. delete_variant_name
        // 2. delete_variants_title
        // ------------------------
        // if (action === "delete_variant_name") {
        //     return;
        // } else if (action === "delete_variants_title") {
        //     return;
        // }

        // if (formRef.current?.values.variant_dimensions.length < 1) {
        //     return false;
        // }

        for (let i = 0; i < formRef.current?.values.variant_dimensions.length; i++) {
            if (i === 0) {
                for (
                    let j = 0;
                    j < formRef.current?.values.variant_dimensions[i].dimension_option_name.length;
                    j++
                ) {
                    new_variant_list.push({
                        variant_code: "",
                        variant_name:
                            formRef.current?.values.name +
                            "(" +
                            formRef.current?.values.variant_dimensions[i].dimension_option_name[j] +
                            ")",
                        is_enable: true,
                        store_list: [],
                        dimension_list: [
                            {
                                dimension_name:
                                    formRef.current?.values.variant_dimensions[i].dimension_name,
                                dimension_option_name:
                                    formRef.current?.values.variant_dimensions[i]
                                        .dimension_option_name[j],
                            },
                        ],
                        raw_price: 0,
                        consumable_used_qty: 1,
                    });
                }
            } else {
                let temp: any = [];
                for (let a = 0; a < new_variant_list.length; a++) {
                    let new_a: any = {
                        variant_code: new_variant_list[a].variant_code,
                        variant_name: new_variant_list[a].variant_name,
                        is_enable: new_variant_list[a].is_enable,
                        store_list: new_variant_list[a].store_list,
                        dimension_list: [],
                        raw_price: new_variant_list[a].raw_price,
                        consumable_used_qty: new_variant_list[a].consumable_used_qty || 1,
                    };
                    for (let a1 = 0; a1 < new_variant_list[a].dimension_list.length; a1++) {
                        new_a.dimension_list.push(new_variant_list[a].dimension_list[a1]);
                    }

                    temp.push(new_a);
                }
                for (
                    let j = 0;
                    j < formRef.current?.values.variant_dimensions[i].dimension_option_name.length;
                    j++
                ) {
                    //3
                    if (j === 0) {
                        for (let a = 0; a < new_variant_list.length; a++) {
                            let index = new_variant_list[a].dimension_list.findIndex(function (
                                item,
                                ia
                            ) {
                                return (
                                    item.dimension_name.toString() ===
                                    formRef.current?.values.variant_dimensions[
                                        i
                                    ].dimension_name.toString()
                                );
                            });

                            if (index < 0) {
                                new_variant_list[a].dimension_list.push({
                                    dimension_name:
                                        formRef.current?.values.variant_dimensions[i]
                                            .dimension_name,
                                    dimension_option_name:
                                        formRef.current?.values.variant_dimensions[i]
                                            .dimension_option_name[j],
                                });
                            }
                            let option_name = "";
                            for (let a2 = 0; a2 < new_variant_list[a].dimension_list.length; a2++) {
                                option_name =
                                    option_name +
                                    new_variant_list[a].dimension_list[a2].dimension_option_name +
                                    "-";
                            }
                            new_variant_list[a].variant_name =
                                formRef.current?.values.name +
                                "(" +
                                option_name.substring(0, option_name.length - 1) +
                                ")";
                        }
                    } else {
                        let tempx: any = [];
                        for (let a = 0; a < temp.length; a++) {
                            let new_a: any = {
                                variant_code: temp[a].variant_code,
                                variant_name: temp[a].variant_name,
                                is_enable: temp[a].is_enable,
                                store_list: temp[a].store_list,
                                dimension_list: [],
                                raw_price: temp[a].raw_price,
                                consumable_used_qty: temp[a].consumable_used_qty || 1,
                            };
                            for (let a1 = 0; a1 < temp[a].dimension_list.length; a1++) {
                                new_a.dimension_list.push(temp[a].dimension_list[a1]);
                            }

                            tempx.push(new_a);
                        }

                        for (let z = 0; z < tempx.length; z++) {
                            let index = tempx[z].dimension_list.findIndex(function (item, ia) {
                                return (
                                    item.dimension_name.toString() ===
                                    formRef.current?.values.variant_dimensions[
                                        i
                                    ].dimension_name.toString()
                                );
                            });
                            if (index < 0) {
                                tempx[z].dimension_list.push({
                                    dimension_name:
                                        formRef.current?.values.variant_dimensions[i]
                                            .dimension_name,
                                    dimension_option_name:
                                        formRef.current?.values.variant_dimensions[i]
                                            .dimension_option_name[j],
                                });
                            }
                            let option_name = "";
                            for (let a2 = 0; a2 < tempx[z].dimension_list.length; a2++) {
                                option_name =
                                    option_name +
                                    tempx[z].dimension_list[a2].dimension_option_name +
                                    "-";
                            }
                            tempx[z].variant_name =
                                formRef.current?.values.name +
                                "(" +
                                option_name.substring(0, option_name.length - 1) +
                                ")";
                            new_variant_list.push(tempx[z]);
                        }
                    }
                }
            }
        }

        let sList = sortList(new_variant_list, formRef.current?.values.variant_dimensions);
        setFieldValue("variant_list", sList);
    };
    // Ekasit Codes

    const onDeleteVariantName = (values, iIndex: number, jIndex: number, setFieldValue) => {
        let arr = [...formRef.current?.values?.variant_dimensions];
        let vList = [...formRef.current?.values?.variant_list];
        let outTitle = values.variant_dimensions[iIndex]?.dimension_name;
        let outName = values.variant_dimensions[iIndex]?.dimension_option_name[jIndex];
        arr[iIndex].dimension_option_name.splice(jIndex, 1);
        let filterList = vList.filter((variant) => {
            return variant.dimension_list.every((dimension) => {
                return (
                    dimension.dimension_name !== outTitle ||
                    dimension.dimension_option_name !== outName
                );
            });
        });
        let sList = sortList(vList, arr);
        setFieldValue("variant_list", sList);
    };

    const addOption = (iIndex, setFieldValue) => {
        let arr = [...formRef.current.values.variant_dimensions];
        let vList = [...formRef.current?.values?.variant_list];

        let checkEmp = false;
        arr.forEach((aItem) => {
            if (aItem?.dimension_name === "") {
                warning(t("page.has_empty_variant_title"));
                checkEmp = true;
                return;
            } else {
                aItem?.dimension_option_name.forEach((dItem) => {
                    if (dItem === "") {
                        warning(t("page.has_empty_variant_name"));
                        checkEmp = true;
                        return;
                    }
                });
            }
        });
        if (checkEmp === false) {
            if (arr.length === 2) {
                arr.forEach((aItem, aIndex) => {
                    if (aIndex !== iIndex) {
                        aItem?.dimension_option_name?.map((dItem, dIndex) => {
                            let newitem: any = {
                                variant_code: "",
                                variant_name: "",
                                price: 0,
                                is_enable: true,
                                raw_price: 0,
                                discount_in_percent: 0,
                                is_auto_consumable: false,
                                store_list: [],
                                dimension_list: [],
                                consumable_list: [],
                                consumable_used_qty: 1,
                            };
                            if (aIndex === 1) {
                                newitem.dimension_list.push({
                                    dimension_name: arr[iIndex]?.dimension_name,
                                    dimension_option_name: "",
                                });
                                newitem.dimension_list.push({
                                    dimension_name: aItem?.dimension_name,
                                    dimension_option_name: dItem,
                                });
                            } else if (aIndex === 0) {
                                newitem.dimension_list.push({
                                    dimension_name: aItem?.dimension_name,
                                    dimension_option_name: dItem,
                                });
                                newitem.dimension_list.push({
                                    dimension_name: arr[iIndex]?.dimension_name,
                                    dimension_option_name: "",
                                });
                            }
                            let option_name = formRef.current?.values.name + "(";
                            option_name += newitem.dimension_list[0]?.dimension_option_name + "-";
                            option_name += newitem.dimension_list[1]?.dimension_option_name + ")";
                            newitem.variant_name = option_name;
                            vList.push(newitem);
                        });
                    }
                });
            } else if (arr.length === 1) {
                let newitem: any = {
                    variant_code: "",
                    variant_name: "",
                    price: 0,
                    is_enable: true,
                    raw_price: 0,
                    discount_in_percent: 0,
                    is_auto_consumable: false,
                    store_list: [],
                    dimension_list: [],
                    consumable_list: [],
                    consumable_used_qty: 1,
                };
                newitem.dimension_list.push({
                    dimension_name: arr[0]?.dimension_name,
                    dimension_option_name: "",
                });
                newitem.variant_name = formRef.current?.values.name + "()";
                vList.push(newitem);
            }

            arr[iIndex]?.dimension_option_name.push("");
            let sList = sortList(vList, arr);
            setFieldValue("variant_list", sList);
            setFieldValue("variant_dimensions", arr);
        }
    };

    const blurChangeName = (text, iIndex, jIndex, setFieldValue) => {
        let arr = [...formRef.current.values.variant_dimensions];
        let vList = [...formRef.current?.values?.variant_list];
        let proName = formRef.current?.values.name;
        let title = arr[iIndex]?.dimension_name;
        const updateList = vList.map((item) => {
            const updateDimenstionList = item?.dimension_list.map((dimension) => {
                if (
                    dimension.dimension_name === title &&
                    dimension.dimension_option_name === focusText
                ) {
                    return { ...dimension, dimension_option_name: text };
                }
                return dimension;
            });
            let updatedName = item?.variant_name;
            if (
                item.dimension_list.some(
                    (dimension) =>
                        dimension.dimension_name === title &&
                        dimension.dimension_option_name === focusText
                )
            ) {
                updatedName = proName;
                updatedName += "(" + updateDimenstionList[0].dimension_option_name;
                if (updateDimenstionList.length === 2) {
                    updatedName += "-" + updateDimenstionList[1].dimension_option_name;
                }
                updatedName += ")";
            }
            return { ...item, variant_name: updatedName, dimension_list: updateDimenstionList };
        });

        let sList = sortList(updateList, arr);
        setFieldValue("variant_list", sList);
    };

    const sortList = (vList, vDimension) => {
        let sortList: any[] = [];
        vDimension[0].dimension_option_name.map((dItem, dIndex) => {
            if (vDimension.length === 2) {
                vDimension[1].dimension_option_name.map((sItem, sIndex) => {
                    const item = vList.find(
                        (item) =>
                            item.dimension_list.some(
                                (dimension) =>
                                    dimension.dimension_name === vDimension[0].dimension_name &&
                                    dimension.dimension_option_name === dItem
                            ) &&
                            item.dimension_list.some(
                                (dimension) =>
                                    dimension.dimension_name === vDimension[1].dimension_name &&
                                    dimension.dimension_option_name === sItem
                            )
                    );
                    sortList.push(item);
                });
            } else {
                const item = vList.find((item) =>
                    item.dimension_list.some(
                        (dimension) =>
                            dimension.dimension_name === vDimension[0].dimension_name &&
                            dimension.dimension_option_name === dItem
                    )
                );
                sortList.push(item);
            }
        });
        return sortList;
    };

    return (
        <div className="form-wrap">
            <Formik
                initialValues={initValues}
                validationSchema={createNews}
                enableReinitialize={true}
                onSubmit={handleSaveProduct}
                innerRef={formRef}
            >
                {({
                    values,
                    handleChange,
                    handleBlur,
                    errors,
                    touched,
                    setFieldValue,
                    handleSubmit,
                    isSubmitting,
                }) => (
                    <Form>
                        <div className="page-header">
                            <h3>
                                {pathName === PATH_PRODUCT_CREATE
                                    ? t("page.create_product")
                                    : t("page.edit_product")}
                            </h3>
                        </div>
                        <div className="page-body">
                            <div className="card-wrap">
                                <StyledCard>
                                    <div className="title" style={{ fontSize: "25px" }}>
                                        {t("page.product_settings")}
                                    </div>
                                    <div>
                                        <Row>
                                            <Col span={24} style={{ marginBottom: "20px" }}>
                                                <ModuleUploadImage
                                                    images={multipleImages}
                                                    handleGetImage={handleUploadImage}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <SharedInput
                                                    label={t("page.name_product")}
                                                    descLabel={t("page.two_hundard_characters")}
                                                    name="name"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.name}
                                                    errors={errors.name}
                                                    touched={touched.name}
                                                    parentClassName="mb-6"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="select-layout">
                                            <Col md={24}>
                                                <SharedInput
                                                    label={t("page.product_code")}
                                                    name="product_code"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.product_code}
                                                    errors={errors.product_code}
                                                    touched={touched.product_code}
                                                    parentClassName="mb-6"
                                                    disable={product ? true : false}
                                                />
                                            </Col>
                                        </Row>
                                        {values.is_variant === false && (
                                            <>
                                                {/* <Row
                                                    className="select-layout"
                                                    style={{ marginBottom: "40px" }}
                                                >
                                                    <Col md={24}>
                                                        <div className="label">
                                                            {t("page.The_product_have_a_barcode")}
                                                        </div>
                                                        <Radio.Group
                                                            onChange={hasBarcodeChange}
                                                            value={hasBarcode}
                                                        >
                                                            <Radio value={true}>
                                                                {t("page.yes")}
                                                            </Radio>
                                                            <Radio value={false}>
                                                                {t("page.no")}
                                                            </Radio>
                                                        </Radio.Group>
                                                    </Col>
                                                </Row> */}
                                                <StyledBarcode>
                                                    <Row>
                                                        <div className="avatar">
                                                            <div
                                                                className="box_title"
                                                                style={{
                                                                    display: "flex",
                                                                    justifyContent:
                                                                        "space-between",
                                                                    alignItems: "center",
                                                                    width: 593,
                                                                }}
                                                            >
                                                                <p
                                                                    style={{
                                                                        fontWeight: 700,
                                                                        fontSize: 16,
                                                                        color: "#000",
                                                                    }}
                                                                >
                                                                    {t("page.barcode")}
                                                                </p>
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                    }}
                                                                >
                                                                    <p
                                                                        style={{
                                                                            fontSize: "14px",
                                                                            margin: 0,
                                                                            marginRight: 10,
                                                                            fontWeight: 700,
                                                                        }}
                                                                    >
                                                                        {t(
                                                                            "page.auto_generate"
                                                                        )}
                                                                    </p>
                                                                    <Switch
                                                                        className="ant-switch"
                                                                        onChange={autoGenChange}
                                                                        checked={autoGenSwitch}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                    <Row className="barcodeRow" gutter={10}>
                                                        <Col md={16}>
                                                            <SharedInput
                                                                onChange={handleChangeName}
                                                                value={barcode}
                                                                parentClassName="mb-6"
                                                            />
                                                        </Col>
                                                        <Col md={4}>
                                                            <Button
                                                                type="primary"
                                                                className="scanBtn"
                                                            >
                                                                <BarcodeIcon />
                                                                <p>{t("page.scan_barcode")}</p>
                                                            </Button>
                                                        </Col>
                                                        <Col md={4}>
                                                            <Button
                                                                type="primary"
                                                                className="printBtn"
                                                            >
                                                                <PrintIconCreateProduct />
                                                                <p>{t("page.print_barcode")}</p>
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </StyledBarcode>
                                                {/* {hasBarcode && (
                                                    
                                                )} */}
                                            </>
                                        )}
                                        <Row>
                                            <Col md={24}>
                                                <div className="label">
                                                    {t("page.product_form.price_by_weight")}
                                                </div>
                                                <StyledSwitch
                                                    className="switch-field"
                                                    style={{ gap: "10px" }}
                                                >
                                                    <Switch
                                                        checked={weightDetail.is_weight}
                                                        onChange={(e) => {
                                                            changeWeightDetail("is_weight", e);
                                                        }}
                                                        disabled={product !== undefined}
                                                    />
                                                    <p
                                                        style={{
                                                            fontSize: "16px",
                                                            color: "#646464",
                                                            fontWeight: 400,
                                                        }}
                                                    >
                                                        {t("page.product_form.price_desc")}
                                                    </p>
                                                </StyledSwitch>
                                            </Col>
                                            {weightDetail.is_weight ? (
                                                <>
                                                    <Col md={6}>
                                                        <Input
                                                            className="weight-uom"
                                                            value={weightDetail?.price}
                                                            onChange={(e) => {
                                                                if (!isNaN(+e.target.value)) {
                                                                    changeWeightDetail(
                                                                        "price",
                                                                        e.target.value
                                                                    );
                                                                }
                                                            }}
                                                            suffix="THB"
                                                        />
                                                    </Col>
                                                    <Col md={2}>
                                                        <p
                                                            style={{
                                                                fontSize: "16px",
                                                                color: "#646464",
                                                                fontWeight: 400,
                                                                textAlign: "center",
                                                                lineHeight: "48px",
                                                            }}
                                                        >
                                                            /
                                                        </p>
                                                    </Col>
                                                    <Col md={8}>
                                                        <Input
                                                            className="weight-uom"
                                                            value={weightDetail?.weight}
                                                            onChange={(e) => {
                                                                if (!isNaN(+e.target.value)) {
                                                                    changeWeightDetail(
                                                                        "weight",
                                                                        e.target.value
                                                                    );
                                                                }
                                                            }}
                                                            addonAfter={
                                                                <Select
                                                                    showSearch
                                                                    filterOption={(input, option) =>
                                                                        //@ts-ignore
                                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                    }
                                                                    style={{
                                                                        width: "100%",
                                                                        height: 48,
                                                                    }}
                                                                    placeholder={t(
                                                                        "page.select_unit"
                                                                    )}
                                                                    onChange={(value: string) => {
                                                                        // setUnitNameM(value);
                                                                        let obj = {
                                                                            ...weightDetail,
                                                                        };
                                                                        obj.unit_index = value;
                                                                        obj.unit_name =
                                                                            unitList[
                                                                                value
                                                                            ].uom_name;
                                                                        setWeightDetail({ ...obj });
                                                                    }}
                                                                    value={weightDetail?.unit_index}
                                                                >
                                                                    {unitList?.map(
                                                                        (uItem, uIndex) => {
                                                                            return (
                                                                                <Option
                                                                                    key={uIndex}
                                                                                    value={uIndex}
                                                                                >
                                                                                    {
                                                                                        uItem?.uom_name
                                                                                    }
                                                                                </Option>
                                                                            );
                                                                        }
                                                                    )}
                                                                </Select>
                                                            }
                                                        />
                                                    </Col>
                                                    <br />
                                                    <br />
                                                </>
                                            ) : (
                                                <>
                                                    <Col md={12}>
                                                        <Select
                                                            showSearch
                                                            filterOption={(input, option) =>
                                                                //@ts-ignore
                                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            style={{ width: "100%", height: 43 }}
                                                            placeholder={t("page.select_unit")}
                                                            onChange={(value: string) => {
                                                                // setUnitNameM(value);
                                                                let obj = { ...weightDetail };
                                                                obj.unit_index = value;
                                                                obj.unit_name =
                                                                    unitList[value].uom_name;
                                                                setWeightDetail({ ...obj });
                                                            }}
                                                            value={weightDetail?.unit_index}
                                                        >
                                                            {unitList?.map((uItem, uIndex) => {
                                                                return (
                                                                    <Option
                                                                        key={uIndex}
                                                                        value={uIndex}
                                                                    >
                                                                        {uItem?.uom_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        <br />
                                                        <br />
                                                    </Col>
                                                </>
                                            )}
                                        </Row>

                                        <Row
                                            className="select-layout"
                                            style={{ marginBottom: "40px" }}
                                        >
                                            <Col md={24}>
                                                <div className="label">
                                                    {t("page.has_produce_quantity")}
                                                </div>
                                                <Radio.Group
                                                    onChange={(value) => {
                                                        hasChangeConsumable(
                                                            values.is_auto_consumable,
                                                            setFieldValue
                                                        );
                                                    }}
                                                    value={values.is_auto_consumable}
                                                >
                                                    <Radio value={true}>{t("page.yes")}</Radio>
                                                    <Radio value={false}>{t("page.no")}</Radio>
                                                </Radio.Group>
                                            </Col>
                                        </Row>
                                        <Row gutter={32} style={{ marginBottom: "20px" }}>
                                            <Col md={12}>
                                                <div className="label">{t("page.brand.title")}</div>
                                                <div className="input-field">
                                                    <Select
                                                        showSearch
                                                        filterOption={(input, option) =>
                                                            //@ts-ignore
                                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        style={{ width: "100%", height: 48 }}
                                                        placeholder={t("page.category")}
                                                        onChange={(value: string) => {
                                                            setFieldValue("brand_id", value);
                                                        }}
                                                        defaultActiveFirstOption={true}
                                                        value={values.brand_id}
                                                    >
                                                        {brandList &&
                                                            brandList.map((item, index) => {
                                                                return (
                                                                    <Option
                                                                        key={index}
                                                                        value={item._id}
                                                                    >
                                                                        {item.name}
                                                                    </Option>
                                                                );
                                                            })}
                                                    </Select>
                                                </div>
                                            </Col>
                                            <Col md={12}>
                                                <div className="label">
                                                    {t("page.product_collection")}
                                                </div>
                                                <div className="input-field">
                                                    <CustomStyles>
                                                        <Select
                                                            showSearch
                                                            filterOption={(input, option) =>
                                                                //@ts-ignore
                                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            style={{ width: "100%", height: 48 }}
                                                            placeholder={t("page.category")}
                                                            mode="multiple"
                                                            onChange={(value: any) => {
                                                                setFieldValue(
                                                                    "promotion_group_list",
                                                                    value
                                                                );
                                                            }}
                                                            defaultActiveFirstOption={true}
                                                            value={values.promotion_group_list}
                                                        >
                                                            <Option key={""} value={""} disabled>
                                                                {t("page.select_category")}
                                                            </Option>
                                                            {promotionGroupList &&
                                                                promotionGroupList.map(
                                                                    (item, index) => {
                                                                        return (
                                                                            <Option
                                                                                key={index}
                                                                                value={item._id}
                                                                            >
                                                                                {item.name}
                                                                            </Option>
                                                                        );
                                                                    }
                                                                )}
                                                        </Select>
                                                    </CustomStyles>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row gutter={32} style={{ marginBottom: "20px" }}>
                                            <Col md={12}>
                                                <div className="label">{t("page.category")}</div>
                                                <div className="input-field">
                                                    <Select
                                                        showSearch
                                                        filterOption={(input, option) =>
                                                            //@ts-ignore
                                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        style={{ width: "100%", height: 48 }}
                                                        placeholder={t("page.category")}
                                                        onChange={(value: string) => {
                                                            if (value === enumAddNews.ADD_NEWS) {
                                                                setShowModal(true);
                                                            } else {
                                                                setFieldValue("category_id", value);
                                                            }
                                                        }}
                                                        defaultActiveFirstOption={true}
                                                        value={values.category_id}
                                                    >
                                                        <Option key={""} value={""} disabled>
                                                            {t("page.select_category")}
                                                        </Option>
                                                        {categories &&
                                                            categories.map((item, index) => {
                                                                return (
                                                                    <Option
                                                                        key={index}
                                                                        value={item._id}
                                                                    >
                                                                        {item.category_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        <Option
                                                            key={"add-new"}
                                                            value={enumAddNews.ADD_NEWS}
                                                        >
                                                            {"+"} {t("page.add_category")}
                                                        </Option>
                                                    </Select>
                                                </div>
                                            </Col>
                                            <Col md={12}>
                                                <SharedInput
                                                    label={t("page.standard_price")}
                                                    name="standard_price"
                                                    onBlur={handleBlur}
                                                    onChange={(e) => {
                                                        if (!isNaN(+e.target.value)) {
                                                            setFieldValue(
                                                                e.target.name,
                                                                e.target.value
                                                            );
                                                        }
                                                    }}
                                                    value={values.standard_price}
                                                    errors={errors.standard_price}
                                                    touched={touched.standard_price}
                                                    parentClassName="mb-6"
                                                />
                                            </Col>
                                            {configDetail?.hint_code_config?.is_product && (
                                                <Col md={12}>
                                                    <SharedInput
                                                        label={t("page.hint_code")}
                                                        name="hint_code"
                                                        onBlur={handleBlur}
                                                        onChange={(e) => {
                                                            // if (!isNaN(+e.target.value)) {
                                                            //     setFieldValue(
                                                            //         e.target.name,
                                                            //         e.target.value
                                                            //     );
                                                            // }
                                                            setFieldValue(
                                                                e.target.name,
                                                                e.target.value
                                                            );
                                                        }}
                                                        value={values.hint_code}
                                                        errors={errors.hint_code}
                                                        touched={touched.hint_code}
                                                        parentClassName="mb-6"
                                                    />
                                                </Col>
                                            )}

                                            <Col md={12}>
                                                <SharedInput
                                                    label={t("page.points")}
                                                    name="sku_earn_point"
                                                    onBlur={handleBlur}
                                                    onChange={(e) => {
                                                        if (!isNaN(+e.target.value)) {
                                                            setFieldValue(
                                                                e.target.name,
                                                                e.target.value
                                                            );
                                                        }
                                                    }}
                                                    value={values.sku_earn_point}
                                                    errors={errors.sku_earn_point}
                                                    touched={touched.sku_earn_point}
                                                    parentClassName="mb-6"
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <div
                                                    className="label"
                                                    style={{ visibility: "hidden" }}
                                                >
                                                    {"Discounts"}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row gutter={32}>
                                            <Col md={12}>
                                                <div className="label">
                                                    {t("page.exclude_pro_from_branch")}
                                                </div>
                                                <Button
                                                    style={{
                                                        height: "42px",
                                                        border: "0.5px solid #fff",
                                                        borderRadius: "4px",
                                                        background: theme?.colors?.main,
                                                        color: theme?.colors?.white,
                                                        marginRight: "10px",
                                                    }}
                                                    onClick={() => setExcludeModal(true)}
                                                >
                                                    <BranchIcon /> &nbsp;
                                                    {t("page.select_branch")}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </StyledCard>
                            </div>
                            <div className="card-wrap">
                                <CustomStyles>
                                    <StyledCard>
                                        <div className="title">
                                            {t("page.product_form.stock_control")}
                                        </div>
                                        <Col md={24}>
                                            <div className="label">
                                                {t("page.product_form.is_qty")}
                                            </div>
                                            <Radio.Group
                                                onChange={(value) => {
                                                    hasChangeConsumable(
                                                        values.is_auto_consumable,
                                                        setFieldValue
                                                    );
                                                }}
                                                value={values.is_auto_consumable}
                                            >
                                                <Radio value={true}>
                                                    {t("page.product_form.have_qty")}
                                                </Radio>
                                                <Radio value={false}>
                                                    {t("page.product_form.no_qty")}
                                                </Radio>
                                            </Radio.Group>
                                            <br />
                                            <br />
                                            <hr />
                                        </Col>

                                        <br />
                                        <br />
                                        <br />
                                        {values.is_auto_consumable ? (
                                            <>
                                                <Col md={24}>
                                                    <div className="stock-control">
                                                        <Row gutter={8}>
                                                            <Col md={12}>
                                                                <div className="label">
                                                                    {t(
                                                                        "page.product_form.restock_unit"
                                                                    )}
                                                                </div>
                                                            </Col>
                                                            <Col md={12}>
                                                                <div className="label">
                                                                    {t(
                                                                        "page.product_form.base_inv"
                                                                    )}
                                                                </div>
                                                            </Col>
                                                            <Col md={6}>
                                                                <SharedInput
                                                                    label=""
                                                                    value={1}
                                                                    parentClassName="mb-6"
                                                                />
                                                            </Col>
                                                            <Col md={5}>
                                                                <Select
                                                                    showSearch
                                                                    filterOption={(input, option) =>
                                                                        //@ts-ignore
                                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                    }
                                                                    style={{
                                                                        width: "100%",
                                                                        height: 48,
                                                                    }}
                                                                    placeholder={t(
                                                                        "page.select_unit"
                                                                    )}
                                                                    onChange={(value: string) => {
                                                                        // setUnitNameM(value);
                                                                        changeWeightDetail(
                                                                            "restock_unit",
                                                                            value
                                                                        );
                                                                    }}
                                                                    value={
                                                                        weightDetail?.restock_unit
                                                                    }
                                                                >
                                                                    {unitList?.map(
                                                                        (uItem, uIndex) => {
                                                                            return (
                                                                                <Option
                                                                                    key={uIndex}
                                                                                    value={
                                                                                        uItem._id
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        uItem?.uom_name
                                                                                    }
                                                                                </Option>
                                                                            );
                                                                        }
                                                                    )}
                                                                </Select>
                                                            </Col>
                                                            <Col md={1}>
                                                                <p
                                                                    style={{
                                                                        fontSize: "16px",
                                                                        color: "#646464",
                                                                        fontWeight: 400,
                                                                        textAlign: "center",
                                                                        lineHeight: "48px",
                                                                    }}
                                                                >
                                                                    =
                                                                </p>
                                                            </Col>
                                                            <Col md={6}>
                                                                <SharedInput
                                                                    label=""
                                                                    value={weightDetail?.per_unit}
                                                                    onChange={(e) => {
                                                                        if (
                                                                            !isNaN(+e.target.value)
                                                                        ) {
                                                                            changeWeightDetail(
                                                                                "per_unit",
                                                                                e.target.value
                                                                            );
                                                                        }
                                                                    }}
                                                                    parentClassName="mb-6"
                                                                />
                                                            </Col>
                                                            <Col md={6}>
                                                                <Select
                                                                    showSearch
                                                                    filterOption={(input, option) =>
                                                                        //@ts-ignore
                                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                    }
                                                                    style={{
                                                                        width: "100%",
                                                                        height: 48,
                                                                    }}
                                                                    placeholder={t(
                                                                        "page.select_unit"
                                                                    )}
                                                                    disabled
                                                                    value={weightDetail?.unit_index}
                                                                >
                                                                    {unitList?.map(
                                                                        (uItem, uIndex) => {
                                                                            return (
                                                                                <Option
                                                                                    key={uIndex}
                                                                                    value={uIndex}
                                                                                >
                                                                                    {
                                                                                        uItem?.uom_name
                                                                                    }
                                                                                </Option>
                                                                            );
                                                                        }
                                                                    )}
                                                                </Select>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>
                                                <br />
                                                <br />
                                                <br />
                                            </>
                                        ) : (
                                            <Col md={12}>
                                                <SharedInput
                                                    label={t("page.product_form.base_inv")}
                                                    value={weightDetail?.unit_name}
                                                    parentClassName="mb-6"
                                                    disable={true}
                                                />
                                            </Col>
                                        )}
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                    </StyledCard>
                                </CustomStyles>
                            </div>
                            <div className="card-wrap">
                                <CustomStyles>
                                    <StyledCard>
                                        <div className="title">
                                            {t("page.product_form.price_variants")}
                                        </div>
                                        <StyledSwitch
                                            className="switch-field"
                                            style={{ gap: "10px" }}
                                        >
                                            <Switch
                                                checked={values.is_variant}
                                                onChange={(e) => setFieldValue("is_variant", e)}
                                                disabled={!(!product)}
                                            />
                                            <p
                                                style={{
                                                    fontSize: "16px",
                                                    color: "#646464",
                                                    fontWeight: 400,
                                                }}
                                            >
                                                {t("page.this_product_has_options")}
                                            </p>
                                        </StyledSwitch>

                                        {values.is_variant && (
                                            <Row gutter={8}>
                                                <Col md={24}>
                                                    <div className="label">
                                                        {t("page.product_form.selling_type")}
                                                    </div>
                                                    <Radio.Group
                                                        onChange={() => {
                                                            if (
                                                                values.variant_dimensions.length ===
                                                                1
                                                            ) {
                                                                setIsSeparate(!isSeparate);
                                                            } else {
                                                                warning(
                                                                    "ไม่สามารถมี 2 varians ได้"
                                                                );
                                                            }
                                                        }}
                                                        value={isSeparate}
                                                        disabled={id ? true : false}
                                                    >
                                                        <Radio value={true}>
                                                            {t("page.product_form.separate")}
                                                        </Radio>
                                                        <Radio value={false}>
                                                            {t("page.product_form.shared")}
                                                        </Radio>
                                                    </Radio.Group>
                                                    <br />
                                                    <br />
                                                </Col>
                                                {values.variant_dimensions &&
                                                    values.variant_dimensions.map(
                                                        (item, i) => (
                                                            <Col
                                                                md={24}
                                                                className={"addVariantTitleArea"}
                                                                key={i}
                                                            >
                                                                <div className="addVariantTitleRow">
                                                                    <span>
                                                                        {t("page.variation")}{" "}
                                                                        {i + 1}
                                                                    </span>
                                                                    {pathName ===
                                                                        PATH_PRODUCT_CREATE && (
                                                                            <div
                                                                                className="delete-icon"
                                                                                onClick={() => {
                                                                                    setFieldValue(
                                                                                        "variant_dimensions",
                                                                                        values.variant_dimensions.splice(
                                                                                            -1
                                                                                        )
                                                                                    );
                                                                                    for (
                                                                                        let i = 0;
                                                                                        i <
                                                                                        values
                                                                                            .variant_list
                                                                                            .length;
                                                                                        i++
                                                                                    ) {
                                                                                        setFieldValue(
                                                                                            `variant_list[${i}].dimension_list`,
                                                                                            values.variant_list[
                                                                                                i
                                                                                            ].dimension_list.splice(
                                                                                                -1
                                                                                            )
                                                                                        );
                                                                                        refeashing(
                                                                                            values,
                                                                                            setFieldValue
                                                                                        );
                                                                                        setTimeout(
                                                                                            () => {
                                                                                                refeashing(
                                                                                                    values,
                                                                                                    setFieldValue
                                                                                                );
                                                                                            },
                                                                                            0
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <DeleteIcon size={16} />
                                                                                <span>
                                                                                    {t("page.delete")}
                                                                                </span>
                                                                            </div>
                                                                        )}
                                                                </div>
                                                                <div className="addVariantBody">
                                                                    <SharedInput
                                                                        label={t(
                                                                            "page.variants_title"
                                                                        )}
                                                                        name={`variant_dimensions[${i}].dimension_name`}
                                                                        onChange={(value) => {
                                                                            if (values.variant_dimensions?.length > 1) {
                                                                                let otherIndex = 0;
                                                                                if (i === 0) {
                                                                                    otherIndex = 1;
                                                                                }
                                                                                if (values.variant_dimensions[otherIndex].dimension_name === value.target.value) {
                                                                                    error('Variant title can not duplicate');
                                                                                    return;
                                                                                }
                                                                            }

                                                                            setFieldValue(
                                                                                `variant_dimensions[${i}].dimension_name`,
                                                                                value.target.value
                                                                            );
                                                                            for (
                                                                                let x = 0;
                                                                                x <
                                                                                values.variant_list
                                                                                    .length;
                                                                                x++
                                                                            ) {
                                                                                for (
                                                                                    let j = 0;
                                                                                    j <
                                                                                    values
                                                                                        .variant_list[
                                                                                        x
                                                                                    ].dimension_list
                                                                                        ?.length;
                                                                                    j++
                                                                                ) {
                                                                                    if (i === j) {
                                                                                        setFieldValue(
                                                                                            `variant_list[${x}].dimension_list[${j}].dimension_name`,
                                                                                            value
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }
                                                                                }
                                                                            }

                                                                            refeashing(
                                                                                values,
                                                                                setFieldValue
                                                                            );
                                                                            setTimeout(() => {
                                                                                refeashing(
                                                                                    values,
                                                                                    setFieldValue
                                                                                );
                                                                                refreshIt();
                                                                            }, 0);
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        isArray
                                                                        i={i}
                                                                        arrayListName={
                                                                            "variant_dimensions"
                                                                        }
                                                                        arrayFieldName={
                                                                            "dimension_name"
                                                                        }
                                                                        value={item.dimension_name}
                                                                        parentClassName="mb-6"
                                                                        disable={
                                                                            !oldDimension.every(
                                                                                (dItem) =>
                                                                                    dItem.dimension_name !==
                                                                                    item.dimension_name
                                                                            )
                                                                        }
                                                                    />
                                                                    <div className="totalVariantSection">
                                                                        <div className="title">
                                                                            {t(
                                                                                "page.total_variants"
                                                                            )}
                                                                        </div>
                                                                        <FieldArray
                                                                            name={`variant_dimensions[${i}].dimension_option_name`}
                                                                        >
                                                                            {({ remove, push }) => (
                                                                                <>
                                                                                    {item.dimension_option_name &&
                                                                                        item.dimension_option_name.map(
                                                                                            (
                                                                                                item2,
                                                                                                j
                                                                                            ) => (
                                                                                                <div
                                                                                                    className="cardOptionRow"
                                                                                                    key={
                                                                                                        j
                                                                                                    }
                                                                                                >
                                                                                                    <div className="variantOptions">
                                                                                                        <SharedInput
                                                                                                            label={t(
                                                                                                                "page.variant_name"
                                                                                                            )}
                                                                                                            name={`dimension_option_name${j}`}
                                                                                                            onBlur={(
                                                                                                                event
                                                                                                            ) => {
                                                                                                                blurChangeName(
                                                                                                                    event
                                                                                                                        .target
                                                                                                                        .value,
                                                                                                                    i,
                                                                                                                    j,
                                                                                                                    setFieldValue
                                                                                                                );
                                                                                                            }}
                                                                                                            onFocus={(
                                                                                                                event
                                                                                                            ) => {
                                                                                                                setFocusText(
                                                                                                                    event
                                                                                                                        .target
                                                                                                                        .value
                                                                                                                );
                                                                                                            }}
                                                                                                            // onBlur={handleBlur}
                                                                                                            onChange={(
                                                                                                                value
                                                                                                            ) => {
                                                                                                                setFieldValue(
                                                                                                                    `variant_dimensions[${i}].dimension_option_name[${j}]`,
                                                                                                                    value
                                                                                                                        .target
                                                                                                                        .value
                                                                                                                );
                                                                                                                // refeashing(values, setFieldValue);
                                                                                                                // setTimeout(() => {
                                                                                                                //     refeashing(values, setFieldValue);
                                                                                                                //     refreshIt();
                                                                                                                // }, 0);
                                                                                                            }}
                                                                                                            isArray
                                                                                                            i={
                                                                                                                i
                                                                                                            }
                                                                                                            arrayListName={
                                                                                                                ""
                                                                                                            }
                                                                                                            arrayFieldName={
                                                                                                                ""
                                                                                                            }
                                                                                                            value={
                                                                                                                item2
                                                                                                            }
                                                                                                            parentClassName="mb-6"
                                                                                                            disable={oldDimension[
                                                                                                                i
                                                                                                            ]?.dimension_option_name?.some(
                                                                                                                (
                                                                                                                    dimension
                                                                                                                ) => {
                                                                                                                    return (
                                                                                                                        dimension ===
                                                                                                                        item2 &&
                                                                                                                        item2 !==
                                                                                                                        ""
                                                                                                                    );
                                                                                                                }
                                                                                                            )}
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="actions">
                                                                                                        <button
                                                                                                            className="deleteIcon"
                                                                                                            type="button"
                                                                                                            onClick={() => {
                                                                                                                remove(
                                                                                                                    j
                                                                                                                );
                                                                                                                // refeashing(values, setFieldValue);
                                                                                                                onDeleteVariantName(
                                                                                                                    values,
                                                                                                                    i,
                                                                                                                    j,
                                                                                                                    setFieldValue
                                                                                                                );
                                                                                                                // setTimeout(
                                                                                                                //     () => {
                                                                                                                //         refeashing(values, setFieldValue, "delete_variant_name");
                                                                                                                //     }, 0);
                                                                                                            }}
                                                                                                        >
                                                                                                            <DeleteFilled />
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        )}
                                                                                    <Button
                                                                                        className="antd-btn-custom"
                                                                                        onClick={() => {
                                                                                            // push(optionListInit);
                                                                                            addOption(
                                                                                                i,
                                                                                                setFieldValue
                                                                                            );
                                                                                            // refeashing(values, setFieldValue);
                                                                                            // setTimeout(() => {
                                                                                            //     refeashing(values, setFieldValue);
                                                                                            //     refreshIt();
                                                                                            // }, 0);
                                                                                        }}
                                                                                    >
                                                                                        +{" "}
                                                                                        {t(
                                                                                            "page.add_options"
                                                                                        )}
                                                                                    </Button>
                                                                                </>
                                                                            )}
                                                                        </FieldArray>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        )
                                                        // ))
                                                    )}
                                                {pathName === PATH_PRODUCT_CREATE &&
                                                    values.variant_dimensions.length === 1 &&
                                                    isSeparate && (
                                                        <Button
                                                            className="antd-btn-custom"
                                                            onClick={() => {
                                                                setFieldValue(
                                                                    "variant_dimensions",
                                                                    [
                                                                        ...values.variant_dimensions,
                                                                        {
                                                                            dimension_name: "",
                                                                            dimension_option_name: [
                                                                                "",
                                                                            ],
                                                                        },
                                                                    ]
                                                                );
                                                                for (
                                                                    let i = 0;
                                                                    i < values.variant_list.length;
                                                                    i++
                                                                ) {
                                                                    for (
                                                                        let x = 0;
                                                                        x <
                                                                        values.variant_list[i]
                                                                            .dimension_list?.length;
                                                                        x++
                                                                    ) {
                                                                        setFieldValue(
                                                                            `variant_list[${i}].dimension_list`,
                                                                            [
                                                                                ...values
                                                                                    .variant_list[i]
                                                                                    .dimension_list,
                                                                                {
                                                                                    dimension_name:
                                                                                        "",
                                                                                    dimension_option_name:
                                                                                        "",
                                                                                },
                                                                            ]
                                                                        );
                                                                    }
                                                                }
                                                                // setRefresh(Refresh + 1);
                                                            }}
                                                            style={{ marginLeft: 0 }}
                                                        >
                                                            + {t("page.add_variants")}
                                                        </Button>
                                                    )}
                                                <Col md={24}>
                                                    {values.variant_dimensions.some(
                                                        (x) => x.dimension_name !== ""
                                                    ) ? (
                                                        <Table
                                                            rowKey={"_id"}
                                                            columns={
                                                                values.variant_dimensions.length ===
                                                                    1
                                                                    ? oldColumn
                                                                    : column
                                                            }
                                                            dataSource={values.variant_list}
                                                            pagination={false}
                                                            bordered
                                                        />
                                                    ) : (
                                                        <p style={{ marginTop: 20 }}>
                                                            Nothing to show!
                                                        </p>
                                                    )}
                                                </Col>
                                            </Row>
                                        )}
                                    </StyledCard>
                                </CustomStyles>
                            </div>
                            <div className="card-wrap">
                                <StyledCard>
                                    <div className="title">{t("page.addon.addon_title")}</div>
                                    <div className="add-btn-section">
                                        <StyledTableButtonAddRow
                                            type="danger"
                                            size="default"
                                            text={t("page.addon.add_addon_option")}
                                            onClick={() => setShowAddonPopup(true)}
                                            className="add-btn"
                                            icon="plus"
                                        />
                                    </div>
                                    <div className="product-addon-list">
                                        {addonList?.map((aItem: any, aIndex: number) => (
                                            <Row key={aIndex}>
                                                <Col span={12}>
                                                    <div className="addon-item">
                                                        <p className="title">
                                                            {aItem?.addon_name}
                                                            {aItem?.is_require && (
                                                                <span className="req-addon">
                                                                    Request Select
                                                                </span>
                                                            )}
                                                        </p>
                                                        {aItem?.product_option_list?.map(
                                                            (oItem: any, oIndex: number) => (
                                                                <div
                                                                    className="option"
                                                                    key={oIndex}
                                                                >
                                                                    <Checkbox
                                                                        checked={
                                                                            values.product_addon_list &&
                                                                                values
                                                                                    .product_addon_list
                                                                                    .length !== 0 &&
                                                                                values.product_addon_list.some(
                                                                                    (x) =>
                                                                                        x.product_option_list.some(
                                                                                            (x) =>
                                                                                                x.option_id ===
                                                                                                oItem._id
                                                                                        )
                                                                                )
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        onChange={(checked) => {
                                                                            oItem.is_selected =
                                                                                checked.target.checked;
                                                                            if (oItem.is_selected) {
                                                                                if (
                                                                                    values.product_addon_list &&
                                                                                    values
                                                                                        .product_addon_list
                                                                                        .length !==
                                                                                    0
                                                                                ) {
                                                                                    setFieldValue(
                                                                                        `product_addon_list[${aIndex}]`,
                                                                                        {
                                                                                            addon_id:
                                                                                                aItem.product_addon_id,
                                                                                            product_option_list:
                                                                                                [
                                                                                                    ...values
                                                                                                        .product_addon_list[
                                                                                                        aIndex
                                                                                                    ]
                                                                                                        .product_option_list,
                                                                                                    {
                                                                                                        option_id:
                                                                                                            oItem._id,
                                                                                                        option_name:
                                                                                                            oItem.option_name,
                                                                                                    },
                                                                                                ],
                                                                                        }
                                                                                    );
                                                                                } else {
                                                                                    setFieldValue(
                                                                                        `product_addon_list[${aIndex}]`,
                                                                                        {
                                                                                            addon_id:
                                                                                                aItem.product_addon_id,
                                                                                            product_option_list:
                                                                                                [
                                                                                                    {
                                                                                                        option_id:
                                                                                                            oItem._id,
                                                                                                        option_name:
                                                                                                            oItem.option_name,
                                                                                                    },
                                                                                                ],
                                                                                        }
                                                                                    );
                                                                                }
                                                                            } else {
                                                                                setFieldValue(
                                                                                    `product_addon_list[${aIndex}].product_option_list`,
                                                                                    values.product_addon_list[
                                                                                        aIndex
                                                                                    ].product_option_list.filter(
                                                                                        (x) =>
                                                                                            x.option_id !==
                                                                                            oItem._id
                                                                                    )
                                                                                );
                                                                            }
                                                                            setAddonList([
                                                                                ...addonList,
                                                                            ]);
                                                                        }}
                                                                    >
                                                                        {" "}
                                                                    </Checkbox>
                                                                    {oItem?.option_name}
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                </Col>
                                                <Col span={1}>
                                                    <div
                                                        className="delete-icon"
                                                        onClick={() => {
                                                            setAddonList((data) =>
                                                                data.filter(
                                                                    (item) =>
                                                                        item?.product_addon_id !==
                                                                        aItem.product_addon_id
                                                                )
                                                            );
                                                            setFieldValue(
                                                                "product_addon_list",
                                                                values.product_addon_list.filter(
                                                                    (x) =>
                                                                        x.addon_id !==
                                                                        aItem.product_addon_id
                                                                )
                                                            );
                                                        }}
                                                    >
                                                        <IconTrash size={21} />
                                                    </div>
                                                </Col>
                                            </Row>
                                        ))}
                                    </div>
                                </StyledCard>
                            </div>
                            <div className="card-wrap">
                                <StyledCard>
                                    <div className="title">
                                        {t("page.description")} <DescriptionIcon />
                                        <span>{t("page.max_8_images")}</span>
                                    </div>
                                    <SharedCKEditor
                                        editor={values.description || ""}
                                        handleChangeEditor={(e: any, editors: any) => {
                                            const data = editors.getData();
                                            setFieldValue("description", data);
                                        }}
                                        name="description"
                                    />
                                </StyledCard>
                            </div>
                            <div className="btn-layout">
                                <StyledSubmitButton
                                    type="default"
                                    text={t("page.save")}
                                    onClick={handleSubmit}
                                    htmlType="button"
                                    disable={isSubmitting}
                                />
                                <StyledCancelButton
                                    type="sub"
                                    text={t("page.cancel")}
                                    htmlType="button"
                                    onClick={handleCancel}
                                />
                            </div>
                        </div>
                        <ComponentProductBarcodeModal
                            showPopup2={showPopup2}
                            handleClose={() => {
                                setShowPopup2(!showPopup2);
                                setTempBarcode("");
                            }}
                            TempBarcode={TempBarcode}
                            setTempBarcode={setTempBarcode}
                            setFieldValue={setFieldValue}
                            values={values}
                            currentChangeItem={currentChangeItem}
                        />
                        <ComponentProductAddonModal
                            showPopup={showAddonPopup}
                            handleClose={() => {
                                setShowAddonPopup(!showAddonPopup);
                            }}
                            addonList={addonList}
                            setAddonList={setAddonList}
                            setFieldValue={setFieldValue}
                            values={values}
                        />
                    </Form>
                )}
            </Formik>
            <ComponentProductDrawer
                showModal={showModal}
                handleClose={() => setShowModal(!showModal)}
            />

            <ComponentProductExcludeModal
                showPopup={excludeModal}
                handleClose={() => {
                    setExcludeModal(false);
                }}
                allStore={allStore}
                product={product}
                selectStore={selectStore}
                setSelectStore={setSelectStore}
            />
        </div>
    );
});

const StyledBarcode = styled.div`
    .scanBtn {
        display: flex;
        place-content: center;
        gap: 5px;
        align-items: center;
        height: 48px;
        background: #0263e0;
        width: 100%;
        p {
            margin: 0;
        }
    }
    .printBtn {
        display: flex;
        place-content: center;
        border: 0.5px solid #646464;
        gap: 5px;
        align-items: center;
        height: 48px;
        background: #fff;
        width: 100%;
        p {
            margin: 0;
            color: #000;
        }
    }
    .barcodeRow {
        gap: 10px;
    }
`;
const StyledSwitch = styled.div`
    display: flex;
    align-items: center;
    p {
        margin-bottom: 0;
    }
`;
const CustomStyles = styled.div`
    .ant-checkbox-wrapper {
        width: 100%;
    }
    .addVariantTitleArea {
        background: #f6f8fd;
        margin-bottom: 20px;
    }
    .addVariantTitleRow {
        border-radius: 12px 12px 0 0;
        background: #0263e0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 32px;
        span {
            font-weight: 700;
            font-size: 25px;
            line-height: 34px;
            color: #fff;
        }
        .delete-icon {
            display: flex;
            cursor: pointer;
            place-items: center;
            align-items: center;
            span {
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                color: #fff;
                margin-left: 5px;
            }
        }
    }
    .addVariantBody {
        .title {
            color: #000 !important;
        }
        padding: 16px 32px;
        .totalVariantSection {
            margin-top: 20px;
            button {
                margin-left: 0;
                margin-top: 20px;
            }
        }
        .variantOptions {
            border-radius: 12px;
            background: #fff;
            padding: 12px 24px;
            width: 95%;
        }
    }
    .cardOptionRow {
        display: flex;
        justify-content: space-between;
        place-items: center;
        margin-bottom: 20px;
    }
    .cardOption {
        background: #f7f7f8;
        border-radius: 6px;
        padding: 25px;
        width: 100%;
        margin: auto 10px;
    }
    .deleteIcon {
        border: none;
        background: none;
        cursor: pointer;
        margin-top: 0 !important;
    }
    .ant-table-content {
        margin-top: 20px;
    }
    .hideDynamicCol {
        display: none;
    }
    .showDynamicCol {
        display: block;
    }
    .ant-select-selection--multiple .ant-select-selection__rendered {
        height: 100%;
    }
`;
