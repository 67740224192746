import { PATH_GOOD_RECEIVE_PAPER } from "@configs";
import { IConplaintCategory, IDivision, INewsCategory } from "@interfaces";
import { Row, Col, Input, Select, DatePicker, Pagination, Progress, Button } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import styled from "styled-components";
const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

interface Iprops {
    gRItem: any;
}
export const ComponentTableGRItemSearch = (props: Iprops) => {
    const { gRItem } = props;
    const [color, setColor] = useState<string>("#FFA043");
    const history = useHistory();
    useEffect(() => {
        if (gRItem?.status === "finished") {
            setColor("#6CD14E");
        } else if (gRItem?.pending_percent === 100) {
            setColor("#6CD14E");
        }
    }, []);
    const { t } = useTranslation();
    return (
        <StyledSearchLayout>
            <div className="filter">
                <Row gutter={24} type="flex" justify="start" align="middle" className="search-row">
                    <Col className="col-item" span={18}>
                        <div className="filter-element">
                            <p className="head">
                                {gRItem?.status === "pending" && "Pending :"}
                                {gRItem?.status === "finished" && "Done :"}
                            </p>
                            <Progress
                                className="progress-bar"
                                percent={gRItem?.pending_percent}
                                // percent={data.pending_percent}
                                showInfo={false}
                                strokeColor={color}
                            // strokeColor={gRItem.pending_percent !== 100 ? "#FFA043" : "#6CD14E"}
                            />
                            <p>
                                {gRItem?.pr_receive_list?.length}/
                                {gRItem?.po_consumable_list?.length} {t("page.item")}
                            </p>
                        </div>
                    </Col>
                    <Col span={6}>
                        <div style={{ textAlign: "right" }}>
                            {gRItem?.status === "finished" && (
                                <Button
                                    onClick={() => {
                                        history.push(PATH_GOOD_RECEIVE_PAPER + "/" + gRItem?._id);
                                    }}
                                >
                                    {t("page.good_receive.load_paper")}
                                </Button>
                            )}
                        </div>
                    </Col>
                </Row>
            </div>
        </StyledSearchLayout>
    );
};
const StyledSearchLayout = styled.div`
    .filter {
        background: #fff;
        padding: 20px;
        border-radius: 4px;
        margin-bottom: 26px;

        .filter-element {
            display: flex;
            /* align-items: center; */
            column-gap: 8px;
            .head {
                margin: 0;
                font-style: normal;
                font-weight: 800;
                font-size: 20px;
                line-height: 24px;
                color: #646464;
                min-width: 100px;
            }
            .progress-bar {
                width: 120px;
            }
        }
    }
`;
