export const SeperatorLine = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="2"
            viewBox="0 0 32 2"
            fill="none"
        >
            <path d="M0 1H32" stroke="#6C7084" />
        </svg>
    );
};
