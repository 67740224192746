export default {
    bill_calculation_setting: "Bill calculation settings",
    vat_setting_organization: "VAT Settings (Organization)",
    default_tax_rate_selection: "Default tax rate selection",
    exclude_vat: "Exclude VAT",
    include_vat: "Include VAT",
    amount_exclude_vat: "Amout Exclude Vat",
    rounding_number_settings: "Rounding Number Settings",
    rounding_number: "Rounding Number",
    round_up: "Round Up",
    round_down: "Round Down",
    sale_round_setting: "Sales Round Settings",
    sale_round_cash_record: "Sales round cash record",
    cash_amount: "Cash amount",
    cash_type: "Cash type",
    staff_pin_bill: "Staff PIN after each bill",
    no: "no",
    yes: "yes",
    code: "Code",
    hint_code: "Hint code",
    channel: "Channel",
    variant: "Variant",
    addon: "Add-on",
    receipt_run_number: "Receipt running number",
    daily: "Daily",
    monthly: "Monthly",
    service_booking: "Service booking",
    price_payment: "Price & Payment",
    price_desc: "Not show price & skip payment",
    select_qty: "Select Qty",
    enable: "Enable",
    display: "Display",
    with_cate: "With categories",
    with_no_cate: "With no categories",
    resource: "Resource",
    resource_constraint: "Resource Constraint",
    store: "Store",
    staff: "Staff",
    capacity: "Capacity",
    receipt_settings: "Receipt settings",
    receipt_logo: "Receipt logo",
    qr_expired_day: "Receipt qr code expired day",
    is_step_service_first: "Is choose service before store",
    timepicker_style: "Time Picker style",
    rebooking_amount: "Rebooking amount",
    rebooking_desc: "This object has rebooking restrictions",
    rebook_op_weekly: "Weekly",
    rebook_op_monthly: "Monthly",
    rebook_op_yearly: "Yearly",
    rebook_op_all: "All time",
};
