import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Switch, Col, Row, Avatar, Menu, Dropdown, Button } from "antd";
import { MoreHorizontal } from "react-feather";

import { IDataexample, INewsCategory } from "@interfaces";
import { enumNewsType, PATH_STOCK_LIST } from "@configs";
import { selectAuth } from "@redux";
import { useSelector } from "react-redux";
import { IconDrag } from "@components";
import { useNotify } from "@utils";
import React, { useEffect, useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import styled from "styled-components";
import { consumableAPI } from "@api";
import { useHistory } from "react-router";
interface IProps {
    data: any;
    itemJson: string;
    handleEdit: (status: boolean, data: IDataexample) => void;
    callbackGetList: () => void;
    index: number;
    newType?: enumNewsType;
    categories: INewsCategory[];
    stores: any[];
}

export const ComponentRowStock = (props: IProps) => {
    const history = useHistory();

    //hooks
    const { t } = useTranslation();
    const { success, error } = useNotify();

    const { userInfo } = useSelector(selectAuth);
    const organization_id = useSelector(selectAuth).auth?.organization_data?._id;
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    //props
    const { data, handleEdit, callbackGetList, itemJson, newType, stores, index } = props;
    const [showModal, setShowModal] = useState(false);
    // const history = useHistory();

    const { attributes, listeners, setNodeRef, transform } = useSortable({
        id: itemJson,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
    };
    const handleDelete = async (item: any) => {
        try {
            const res = await consumableAPI.deleteConsumable({
                organization_id: userInfo?.merchant?._id || "",
                consumable_id: item,
            });
            if (res.data.error === "0") {
                callbackGetList();
                setShowModal(false);
                success(t("message.delete.success"));
            } else {
                error(res.data.msg);
            }
        } catch (err: any) {
            if (err) {
                error(err.msg);
            } else error(t("message.delete.fail"));
        }
    };

    const menu = (
        <Menu>
            <Menu.Item key={0} onClick={() => history.push(`${PATH_STOCK_LIST}/${data._id}`)}>
                <div className="duplicate-btn" style={{ color: "#000" }}>
                    {t("page.add_delete_quantity")}
                </div>
            </Menu.Item>
            <Menu.Item key={1} onClick={() => handleDelete(data._id)}>
                <div className="duplicate-btn">{t("page.delete")}</div>
            </Menu.Item>
        </Menu>
    );

    return (
        <Styles>
            <div className="table-element" ref={setNodeRef} style={style}>
                <div className="drag-icon" {...attributes} {...listeners}>
                    <IconDrag />
                </div>
                <div className="table-row">
                    <Row
                        style={{ height: "100%" }}
                        gutter={24}
                        type="flex"
                        align="middle"
                        justify="space-between"
                    >
                        <Col className="col-item" span={8}>
                            <Link to={`${PATH_STOCK_LIST}/${data._id}`}>
                                <p className="news-title">
                                    {stores[index].name || t("page.empty_text")}
                                </p>
                            </Link>
                        </Col>
                        <Col className="col-item" span={8} style={{ placeContent: "center" }}>
                            <p>
                                {stores[index]?.formula[0]?.restock_unit_name ||
                                    t("page.empty_text")}
                            </p>
                        </Col>
                        <Col className="col-item" span={8} style={{ placeContent: "end" }}>
                            <Dropdown
                                //@ts-ignore
                                getPopupContainer={(trigger) => trigger.parentNode}
                                overlay={menu}
                                trigger={["click"]}
                                placement="bottomRight"
                            >
                                <Button style={{ border: "unset" }}>
                                    <MoreHorizontal />
                                </Button>
                            </Dropdown>
                        </Col>
                    </Row>
                </div>
            </div>
        </Styles>
    );
};
export const Styles = styled.div`
    .table-row {
        height: 60px;
    }
`;
