import { useTranslation } from "react-i18next";
import {
    SharedModal,
} from "../shared";
import styled from "styled-components";
import { Button, Col, Input, Radio, Row, Select } from "antd";

import {
    IBaseConvers,
} from "@interfaces";
import { useEffect, useState } from "react";
import { IconDelete } from "../icon";
import { useNotify } from "@utils";
import { orgApi } from "@api";
import { selectAuth } from "@redux";
import { useSelector } from "react-redux";

const { Option } = Select;

interface IProps {
    showPopup: boolean;
    handleClose: () => void;
    isUseUnit: boolean;
    setIsUseUnit: (status: boolean) => void;
    baseConvers: IBaseConvers[];
    setBaseConvers: (newList: IBaseConvers[]) => void;
    restockUnit: string;
    useUnit: number;
    setUseUnit: (newValue: number) => void;
    unitName: string;
    setUnitName: (newName: string) => void;
}

export const ComponentConversModal = (props: IProps) => {
    const {
        showPopup,
        handleClose,
        isUseUnit,
        setIsUseUnit,
        baseConvers,
        setBaseConvers,
        restockUnit,
        useUnit,
        setUseUnit,
        unitName,
        setUnitName,
    } = props;
    const { t } = useTranslation();
    const [isUseUnitM, setIsUseUnitM] = useState<boolean>(false);
    const [baseConversM, setBaseConversM] = useState<IBaseConvers[]>([]);
    const [useUnitM, setUseUnitM] = useState<number>(1);
    const [unitNameM, setUnitNameM] = useState<string>("Bag");
    const { error } = useNotify();
    const org_id = useSelector(selectAuth).userInfo?.merchant?._id;
    const [unitList, setUnitList] = useState<any[]>([]);
    const organization_id = useSelector(selectAuth).userInfo?.merchant?._id;

    useEffect(() => {
        if (showPopup) {
            setIsUseUnitM(isUseUnit);
            setBaseConversM(baseConvers);
            setUseUnitM(useUnit);
            setUnitNameM(unitName);
        }
    }, [showPopup]);

    useEffect(() => {
        fetchUOM();
    }, []);

    const fetchUOM = async () => {
        try {
            const resUOM = await orgApi.getUnitList({
                organization_id: org_id || "",
                xpage: 1,
                xlimit: 10000,
            });
            setUnitList(resUOM?.data?.result);
        } catch (err: any) {
            error(err.msg);
        }
    };
    // form inital data
    const addConvers = () => {
        let arr = [...baseConversM];
        arr.push({
            delivery_unit_name: "Bag",
            to_restock_unit: 1,
            from_edit: false,
        });
        setBaseConversM([...arr]);
    };

    const delConvers = (dIndex: number) => {
        let arr = [...baseConversM];
        arr = arr.filter((x, xIndex) => xIndex !== dIndex);
        setBaseConversM([...arr]);
    };

    const setDeliveryUnit = (value: string, index: number) => {
        let arr = [...baseConversM];
        arr[index].delivery_unit_name = value;
        setBaseConversM([...arr]);
    };

    const setRestockNumber = (e, index: number) => {
        let arr = [...baseConversM];
        arr[index].to_restock_unit = Number(e.target.value);
        setBaseConversM([...arr]);
    };

    const onSave = async () => {
        await orgApi.createUOMGroup({
            organization_id: organization_id || "",
            unit_name: unitNameM,
            restock_unit_name: restockUnit,
            to_used_unit: useUnitM,
        });

        setBaseConvers([...baseConversM]);
        setIsUseUnit(isUseUnitM);
        setUseUnit(useUnitM);
        setUnitName(unitNameM);
        handleClose();
    };

    return (
        <SharedModal
            showPopup={showPopup}
            handleClose={handleClose}
            title={t("page.consume.convers_title")}
            width={600}
        >
            <StyledModalContent>
                <div className="title">{t("page.consume.type_unit")}</div>
                <br />
                <div className="radio-section">
                    <span
                        className="radio-item"
                        onClick={() => {
                            setIsUseUnitM(false);
                        }}
                    >
                        <Radio checked={isUseUnitM === false} />
                        {t("page.consume.restock_same")}
                    </span>
                    <span
                        className="radio-item"
                        onClick={() => {
                            setIsUseUnitM(true);
                        }}
                    >
                        <Radio checked={isUseUnitM === true} />
                        {t("page.consume.restock_diff")}
                    </span>

                </div>

                <div className="title">{t("page.consume.base_conversion")}</div>
                <div className="base-section">
                    <StyledUnitSection>
                        {baseConversM?.map((bItem, bIndex) => {
                            return (
                                <div className="base-item" key={bIndex}>
                                    <Row gutter={12}>
                                        <Col span={12}>
                                            <div className="re-title">
                                                {t("page.consume.delivery_unit")}
                                            </div>
                                        </Col>
                                        <Col span={12}>
                                            <div className="re-title">
                                                {t("page.consume.restock_unit")}
                                            </div>
                                        </Col>
                                        <Col span={6}>
                                            <Input value={"1"} disabled />
                                        </Col>
                                        <Col span={5}>
                                            <Select
                                                showSearch
                                                filterOption={(input, option) =>
                                                    //@ts-ignore
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                style={{ width: "100%", height: 48 }}
                                                placeholder={t("page.select_unit")}
                                                onChange={(value: string) => {
                                                    setDeliveryUnit(value, bIndex);
                                                    // setRestockUnit(value);
                                                }}
                                                value={bItem?.delivery_unit_name}
                                                disabled={bItem?.from_edit}
                                            >
                                                {unitList?.map((uItem, uIndex) => {
                                                    return (
                                                        <Option key={uIndex} value={uItem?.uom_name}>
                                                            {uItem?.uom_name}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </Col>
                                        <Col span={1}>
                                            <div className="equal">=</div>
                                        </Col>
                                        <Col span={5}>
                                            <Input
                                                value={bItem?.to_restock_unit}
                                                disabled={bItem?.from_edit}
                                                onChange={(e: any) => {
                                                    setRestockNumber(e, bIndex);
                                                    // if (!isNaN(+e.target.value)) {
                                                    //     setUseUnit(e.target.value);
                                                    // }
                                                }}
                                            />
                                        </Col>
                                        <Col span={5}>
                                            <Input
                                                // value={t("page.unit_option." + restockUnit)}
                                                value={restockUnit}
                                                disabled
                                            />

                                        </Col>
                                        <Col span={1}>
                                            {bIndex !== 0 &&
                                                <div className="delete-convers">
                                                    {bItem?.from_edit === false &&
                                                        <div className="del-icon"
                                                            onClick={() => delConvers(bIndex)}
                                                        >
                                                            <IconDelete />
                                                        </div>
                                                    }

                                                </div>
                                            }
                                        </Col>
                                    </Row>

                                </div>
                            );
                        })}
                        <Button
                            className="add-convers"
                            icon="plus"
                            onClick={addConvers}
                        >
                            {t("page.consume.add_convers")}
                        </Button>
                    </StyledUnitSection>

                </div>

                {isUseUnitM &&
                    <div className="usage-section">
                        <div className="title">{t("page.consume.usage_conversion")}</div>

                        <StyledUnitSection>
                            <Row gutter={12}>
                                <Col span={12}>
                                    <div className="re-title">
                                        {t("page.consume.restock_unit")}
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div className="re-title">
                                        {t("page.consume.use_unit")}
                                    </div>
                                </Col>
                                <Col span={6}>
                                    <Input value={"1"} disabled />
                                </Col>
                                <Col span={5}>
                                    <Select
                                        showSearch
                                        filterOption={(input, option) =>
                                            //@ts-ignore
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        style={{ width: "100%", height: 48 }}
                                        placeholder={t("page.select_unit")}
                                        disabled
                                        value={restockUnit}
                                    >
                                        {unitList?.map((uItem, uIndex) => {
                                            return (
                                                <Option key={uIndex} value={uItem?.uom_name}>
                                                    {uItem?.uom_name}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </Col>
                                <Col span={1}>
                                    <div className="equal">=</div>
                                </Col>
                                <Col span={6}>
                                    <Input
                                        value={useUnitM}
                                        onChange={(e: any) => {
                                            if (!isNaN(+e.target.value)) {
                                                setUseUnitM(e.target.value);
                                            }
                                        }}
                                    />
                                </Col>
                                <Col span={5}>
                                    <Select
                                        showSearch
                                        filterOption={(input, option) =>
                                            //@ts-ignore
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        style={{ width: "100%", height: 48 }}
                                        placeholder={t("page.select_unit")}
                                        onChange={(value: string) => {
                                            setUnitNameM(value);
                                        }}
                                        value={unitNameM}
                                    >
                                        {unitList?.map((uItem, uIndex) => {
                                            return (
                                                <Option key={uIndex} value={uItem?.uom_name}>
                                                    {uItem?.uom_name}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </Col>
                            </Row>
                        </StyledUnitSection>
                    </div>
                }
                <div className="btn-section">
                    <Button className="save-btn" onClick={onSave}>
                        {t("page.save")}
                    </Button>
                    <Button onClick={handleClose}>
                        {t("page.cancel")}
                    </Button>

                </div>
            </StyledModalContent>
        </SharedModal>
    );
};

const StyledModalContent = styled.div`
    height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    .title {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
        color: #000000;
        margin: 0;
        margin-top: 25px;
    }
    .radio-item {
        margin-right: 30px;
    }
    .base-section {
        background-color: #F7F7F8;
        border-radius: 10px;
    }
    .add-convers {
        height: 48px;
    }
    .usage-section {
        margin-bottom: 15px;
    }
    .btn-section {
        margin-top: 20px;
        .ant-btn {
            height: 48px;
            width: 120px;
            margin-right: 20px;
            font-size: 16px;
            font-weight: 600;
        }
        .save-btn {
            background: #0263e0;
            color: #fff;
        }
    }
`;


const StyledUnitSection = styled.div`
    background-color: #f7f7f8;
    border-radius: 8px;
    padding: 20px;
    .re-title {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
        color: #000000;
        margin: 0;
    }
    .base-item {
        margin-bottom: 15px;
    }
    input {
        border-color: #d9d9d9 !important;
        box-shadow: none !important;
        height: 48px;
    }
    .equal {
        font-size: 30px;
        text-align: center;
    }
    .delete-convers {
        display: flex;
        .del-icon {
            margin-top: 10px;
            cursor: pointer;
        }
    }
`;