import { Layout, Menu } from "antd";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IconBookmark, StyledMenuHeader, User } from "@components";
import { IconSetting } from "@components";
import {
    PATH_ACCEPT_REDEEM,
    PATH_CONTACTS,
    PATH_LOYALTY,
    PATH_PERMISSIONS,
    PATH_USER_PROFILE,
    PATH_HOME,
    PATH_STOREFRONT,
    PATH_SERVICES,
    PATH_SERVICES_CATEGORY,
    PATH_PRODUCT,
    PATH_PRODUCT_ORDERS,
    PATH_PRODUCT_SETTINGS,
    PATH_PROMOTION,
    PATH_STORE,
    PATH_STORE_ACCOUNT_SETTINGS,
    PATH_STORE_APPOINTMENT_SETTINGS,
    PATH_STORE_PAYMENT_SETTINGS,
    PATH_STAFF,
    PATH_STAFF_SCHEDULING,
    PATH_STAFF_TYPES,
    PATH_CUSTOMER_SERVICES,
    PATH_SERVICES_CREATE,
    PATH_PRODUCT_CREATE,
    PATH_PERMISSION_POS,
    PATH_STORE_LIST,
    PATH_STORE_CREATE,
    PATH_PAYMENT_SETTING,
    PATH_CALCULATION_BILL,
    LOYALTY_URL,
    PATH_STOCK_LIST,
    PATH_STOCK_CREATE,
    PATH_RECIPE_LIST,
    PATH_RECIPE_ASSIGN,
    PATH_PROMOTION_LIST,
    PATH_PROMOTION_CREATE,
    PATH_WASTE_RECORD,
    PATH_PROMOTION_CATEGORY_LIST,
    PATH_STOCK,
    PATH_WASTE,
    PATH_PROMOTION_GROUPS,
    PATH_BILL_PROMOTION,
    PATH_ROLES_PERMISSIONS_List,
    PATH_IMPORT_TABLE,
    PATH_EXPORT_TABLE,
} from "@configs";
import { pathToFileURL } from "url";
import { useCallback, useEffect, useState } from "react";
import { ModuleUpgradePlan } from "@modules";
import { selectAuth } from "@redux";
import { useSelector } from "react-redux";
const { Header } = Layout;
const loyalty = "accept-redeem";
const communications = "communications";
const analytics = "customer-analytics";
const help = "help";
const contacts = "contacts";
const editProfile = "edit-profile";
const userGuide = "https://rocket.crunch.help/";
const marketingAuto = "marketing-automation";
const product = "/products";

export const ComponentMenuHeader = () => {
    //page hook
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const [visible, setVisible] = useState(false);
    const { userInfo } = useSelector(selectAuth);
    //redux state
    const pathname = location.pathname;
    const handleClickMenu = (e) => {
        if (e.key === loyalty) {
            window.open(`http://${userInfo?.merchant?.prefix}.${LOYALTY_URL}/${loyalty}`);
        } else if (e.key === communications) {
            setVisible(true);
        } else if (e.key === product) {
            history.push(e.key);
        }
        //  else if (e.key === analytics) {
        //     setVisible(true);
        // }
        else if (e.key === marketingAuto) {
            window.open(`https://d1vna94olhv4xb.cloudfront.net/workflow`);
        }
        // else if (e.key === help) {
        //     window.open(`${LOYALTY_URL}/${help}`);
        // }
        // else if (e.key === contacts) {
        //     window.open(`${LOYALTY_URL}/${contacts}`);
        // }
        // else if (e.key === editProfile) {
        //     window.open(`${LOYALTY_URL}/${editProfile}`);
        // }
        else if (e.key === userGuide) {
            window.open(userGuide);
        } else {
            // history.push(e.key);
            window.open(`http://${userInfo?.merchant?.prefix}.${LOYALTY_URL}/${e.key}`);
        }
    };
    const handleClose = useCallback(() => {
        setVisible(false);
    }, []);
    const gotoChoosePlan = useCallback(() => {
        // history.push(PATH_CHOOSE_PLAN);
    }, []);

    const isLoyaltyRoutes = (route: string) =>
        route === PATH_ACCEPT_REDEEM || route === PATH_LOYALTY;

    const isSettingRoutes = (route: string) =>
        route === PATH_PERMISSIONS || route === PATH_USER_PROFILE;

    const isContactRoute = (route: string) =>
        route === PATH_CONTACTS || route.includes(PATH_CONTACTS);
    const isStorefrontRoutes = (route: string) =>
        route === PATH_STOREFRONT ||
        route === PATH_SERVICES ||
        route === PATH_SERVICES_CREATE ||
        route === PATH_SERVICES_CATEGORY ||
        route === PATH_PRODUCT ||
        route === PATH_PRODUCT_CREATE ||
        route === PATH_PRODUCT_ORDERS ||
        route === PATH_PRODUCT_SETTINGS ||
        route === PATH_PROMOTION ||
        route === PATH_STORE ||
        route === PATH_STORE_ACCOUNT_SETTINGS ||
        route === PATH_STORE_APPOINTMENT_SETTINGS ||
        route === PATH_STORE_PAYMENT_SETTINGS ||
        route === PATH_STAFF ||
        route === PATH_STAFF_SCHEDULING ||
        route === PATH_STAFF_TYPES ||
        route === PATH_CUSTOMER_SERVICES ||
        route === PATH_STORE_LIST ||
        route === PATH_STORE_CREATE ||
        route === PATH_PERMISSION_POS ||
        route === PATH_PAYMENT_SETTING ||
        route === PATH_CALCULATION_BILL ||
        route === PATH_STOCK_LIST ||
        route === PATH_STOCK_CREATE ||
        route === PATH_RECIPE_LIST ||
        route === PATH_RECIPE_ASSIGN ||
        route === PATH_PROMOTION_LIST ||
        route === PATH_PROMOTION_CATEGORY_LIST ||
        route === PATH_PROMOTION_GROUPS ||
        route === PATH_ROLES_PERMISSIONS_List ||
        route === PATH_IMPORT_TABLE ||
        route === PATH_EXPORT_TABLE ||
        route.includes(PATH_PRODUCT) ||
        route.includes(PATH_ROLES_PERMISSIONS_List) ||
        route.includes(PATH_PROMOTION_CREATE) ||
        route.includes(PATH_BILL_PROMOTION) ||
        route.includes(PATH_STORE) ||
        route.includes(PATH_WASTE_RECORD) ||
        route.includes(PATH_PROMOTION) ||
        route.includes(PATH_STOCK) ||
        route.includes(PATH_WASTE) ||
        route.includes(PATH_PERMISSION_POS);

    return (
        <StyledMenuHeader>
            <Header>
                <Menu onClick={handleClickMenu} mode="horizontal">
                    <Menu.Item
                        className={
                            pathname === PATH_HOME
                                ? `ant-menu-item-selected menu-after`
                                : `menu-after`
                        }
                        key="/"
                    >
                        {t("page.home")}
                        <div className="menu-heightlight">
                            <div className="line"></div>
                        </div>
                    </Menu.Item>
                    <Menu.Item
                        className={
                            isLoyaltyRoutes(pathname)
                                ? `ant-menu-item-selected menu-after rewards`
                                : `menu-after rewards`
                        }
                        key={loyalty}
                    >
                        {t("page.loyalty")}
                        <div className="menu-heightlight">
                            <div className="line"></div>
                        </div>
                    </Menu.Item>
                    <Menu.Item
                        className={
                            isLoyaltyRoutes(pathname)
                                ? `ant-menu-item-selected menu-after rewards`
                                : `menu-after rewards`
                        }
                        key={analytics}
                    >
                        {t("page.analytics")}
                        <div className="menu-heightlight">
                            <div className="line"></div>
                        </div>
                    </Menu.Item>
                    <Menu.Item
                        className={
                            isStorefrontRoutes(pathname)
                                ? `ant-menu-item-selected menu-after rewards`
                                : `menu-after rewards`
                        }
                        key={product}
                    >
                        {t("page.pos")}
                        <div className="menu-heightlight">
                            <div className="line"></div>
                        </div>
                    </Menu.Item>
                    <Menu.Item
                        className={
                            isSettingRoutes(pathname)
                                ? "ant-menu-item-selected menu-after rewards"
                                : "menu-after rewards"
                        }
                        key={marketingAuto}
                    >
                        {t("page.marketing_automation")}
                    </Menu.Item>
                    {/* <Menu.Item
                        className={
                            isLoyaltyRoutes(pathname)
                                ? `ant-menu-item-selected menu-after rewards`
                                : `menu-after rewards`
                        }
                        key={communications}
                    >
                        {t("page.communications")}
                        <div className="menu-heightlight">
                            <div className="line"></div>
                        </div>
                    </Menu.Item> */}
                    {/* <Menu.Item
                        className={
                            isLoyaltyRoutes(pathname)
                                ? `ant-menu-item-selected menu-after rewards`
                                : `menu-after rewards`
                        }
                        key={help}
                    >
                        {t("page.help")}
                        <div className="menu-heightlight">
                            <div className="line"></div>
                        </div>
                    </Menu.Item> */}
                    {/* <Menu.Item
                        className={
                            pathname === "/store-front"
                                ? `ant-menu-item-selected menu-after store-front`
                                : `menu-after store-front`
                        }
                        key="/store-front"
                    >
                        {t("page.store_front")}
                    </Menu.Item> */}
                    {/* <Menu.Item
                        className={
                            pathname === "/line"
                                ? `ant-menu-item-selected menu-after line`
                                : `menu-after line`
                        }
                        key="/line"
                    >
                        {t("page.LINE")}
                    </Menu.Item> */}
                    {/* <Menu.Item
                        className={
                            pathname === "/workflow"
                                ? `ant-menu-item-selected menu-after workflow`
                                : `menu-after workflow`
                        }
                        key="/workflow"
                    >
                        {t("page.workflow")}
                    </Menu.Item> */}
                    {/* <Menu.Item
                        className={
                            pathname === "/help"
                                ? `ant-menu-item-selected menu-after help`
                                : `menu-after help`
                        }
                        key="/help"
                    >
                        {t("page.help")}
                    </Menu.Item> */}
                </Menu>
                <Menu onClick={handleClickMenu} mode="horizontal">
                    <Menu.Item
                        className={
                            pathname === "/user_guide"
                                ? `ant-menu-item-selected menu-after user_guide`
                                : `menu-after user_guide`
                        }
                        key={userGuide}
                    >
                        <span className="title">
                            <IconBookmark />
                            {t("page.user_guide")}
                        </span>
                        {/* <div className="menu-heightlight">
                            <div className="line"></div>
                        </div> */}
                    </Menu.Item>
                    <Menu.Item
                        className={
                            isContactRoute(pathname)
                                ? `ant-menu-item-selected menu-after contacts`
                                : `menu-after contacts`
                        }
                        key={contacts}
                    >
                        <span className="title">
                            <User />
                            {t("page.contact_list")}
                        </span>
                        <div className="menu-heightlight">
                            <div className="line"></div>
                        </div>
                    </Menu.Item>
                    <Menu.Item
                        className={
                            isSettingRoutes(pathname)
                                ? `ant-menu-item-selected menu-after edit-profile`
                                : `menu-after edit-profile`
                        }
                        key={editProfile}
                    >
                        <span className="title">
                            <IconSetting size={23} />
                            {t("page.settings")}
                        </span>
                        <div className="menu-heightlight">
                            <div className="line"></div>
                        </div>
                    </Menu.Item>
                </Menu>
            </Header>
            <ModuleUpgradePlan
                visible={visible}
                onClose={handleClose}
                handleUpgrade={gotoChoosePlan}
            />
        </StyledMenuHeader>
    );
};
