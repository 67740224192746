import { Modal } from "antd";
import { ReactNode } from "react";

interface IProps {
    showPopup3: boolean;
    handleClose?: () => void;
    children: ReactNode;
    title?: string;
    width: number;
    closable?: boolean;
}
export const SharedBranchModal = (props: IProps) => {
    const { showPopup3, handleClose, children, title, width, closable } = props;
    return (
        <Modal
            visible={showPopup3}
            onCancel={handleClose}
            title={title}
            footer={null}
            width={width}
            closable={closable}
        >
            {children}
        </Modal>
    );
};
