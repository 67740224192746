import page from "./page";
import format from "./format";
import error from "./error";
import message from "./message";
import validation from "./validation";
import object from "./object";
import bill from "./bill";
import payment from "./payment";
import month from "./month";
import day from "./day";

export const TRANSLATIONS_TH = {
    format,
    page,
    error,
    message,
    validation,
    object,
    payment,
    bill,
    month,
    day,
};
