import { useTranslation } from "react-i18next";
import { SharedModal } from "../shared";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { Button, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import {
    IConsumableListNew,
    IGetConsumableStoreId,
    IGetProductsWithComsumablesAutoExample,
} from "@interfaces";
import React from "react";
import { POAPI, productAPI } from "@api";
import { useSelector } from "react-redux";
import { selectAuth } from "@redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { PATH_CHECK_STOCK } from "@configs";

interface IProps {
    store_id: string;
    showPopup: boolean;
    handleClose: () => void;
    addConsume: (payload: IConsumePayload) => void;
    tab: number;
    setTab: any;
    selectedTab: number;
    setSelectedTab: any;
}

interface IConsumePayload {
    consumable_id: string;
    name: string;
    restock_price: number;
    on_stork: number;
    add_qty: number;
    unit: string;
}

interface IConsume {
    _id: string;
    name: string;
}
export const ComponentAddInventoryModal = (props: IProps) => {
    const {
        showPopup,
        handleClose,
        store_id,
        addConsume,
        tab,
        setTab,
        setSelectedTab,
        selectedTab,
    } = props;
    const { t } = useTranslation();
    const [itemName, setItemName] = useState<string>("");
    const [consumeList, setConsumeList] = useState<IConsume[]>([]);
    const [proName, setProName] = useState<string>("");
    const [proConList, setProConList] = useState<IGetProductsWithComsumablesAutoExample[]>([]);
    const [cPage, setCPage] = useState<number>(1);
    const [pPage, setPPage] = useState<number>(1);
    const [cTotal, setCTotal] = useState<number>(0);
    const [pTotal, setPTotal] = useState<number>(0);
    const pathName = window.location.pathname;

    const { userInfo } = useSelector(selectAuth);
    const onSearch = async () => {
        if (tab === 0) {
            const params: IGetConsumableStoreId = {
                organization_id: userInfo?.merchant?._id || "",
                xpage: 1,
                xlimit: 40,
                text_search: tab === 0 ? itemName : proName,
                store_id: store_id,
                is_auto_consumable: tab === 0 ? false : true,
            };

            const res = await POAPI.getConsumeByStore(params);
            if (res.data.error === "0") {
                setConsumeList(res?.data?.result);
                setCTotal(res.data.total);
            }
        } else {
            const res = await productAPI.getProductsWithComsumablesAuto({
                organization_id: userInfo?.merchant?._id,
                xpage: 1,
                xlimit: 20,
                store_id: store_id,
                text_search: tab === 0 ? itemName : proName,
            });
            if (res.data.error === "0") {
                setProConList(res.data.result);
                setPTotal(res.data.total);
            }
        }
    };
    const onLoadMore = async () => {
        const params: IGetConsumableStoreId = {
            organization_id: userInfo?.merchant?._id || "",
            xpage: cPage + 1,
            xlimit: 40,
            text_search: tab === 0 ? itemName : proName,
            store_id: store_id,
            is_auto_consumable: tab === 0 ? false : true,
        };
        const res = await POAPI.getConsumeByStore(params);
        if (res.data.error === "0") {
            setConsumeList([...consumeList, ...res?.data?.result]);
            setCPage(cPage + 1);
        }
    };
    const onLoadMoreProduct = async () => {
        try {
            const res = await productAPI.getProductsWithComsumablesAuto({
                organization_id: userInfo?.merchant?._id,
                xpage: pPage + 1,
                xlimit: 20,
                store_id: store_id,
                text_search: tab === 0 ? itemName : proName,
            });
            if (res.data.error === "0") {
                setProConList([...proConList, ...res?.data?.result]);
                setPPage(pPage + 1);
            }
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        if (showPopup) {
            onSearch();
        }
    }, [tab]);
    useEffect(() => {
        if (!showPopup) {
            setConsumeList([]);
        } else {
            onSearch();
            setCPage(1);
            setPPage(1);
        }
    }, [showPopup]);
    const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setItemName(e.target.value);
    };
    const handleChangeProName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setProName(e.target.value);
    };

    const selectConsume = (cItem: IConsumableListNew & IGetProductsWithComsumablesAutoExample) => {
        if (tab === 0) {
            const payload: any = {
                consumable_id: cItem._id,
                consumable_name: cItem.name,
                delivery_unit_name:
                    cItem.restock_formula && cItem.restock_formula.length !== 0
                        ? cItem.restock_formula[0].delivery_unit_name
                        : "",
                before_restock_qty: cItem.consumable_data.restock_qty,
                delivery_qty: 0,
                restock_qty: pathName.includes(PATH_CHECK_STOCK)
                    ? cItem.consumable_data.restock_qty
                    : 0 || 0,
                grand_total_restock_qty: pathName.includes(PATH_CHECK_STOCK)
                    ? cItem.consumable_data.restock_qty
                    : 0 || 0,
                restock_change_qty: 0,
                to_restock_unit:
                    cItem.restock_formula && cItem.restock_formula.length !== 0
                        ? cItem.restock_formula[0].to_restock_unit
                        : 0,
                restock_formula: cItem.restock_formula,
                formula: cItem.formula,
                unit_name: cItem.unit_name,
            };
            setSelectedTab(0);
            addConsume(payload);
            handleClose();
        } else {
            let temp: any = [];
            for (let i = 0; i < cItem.each_consumable.length; i++) {
                temp.push({
                    variant_id: cItem.each_consumable[i].variant_id,
                    variant_name: cItem.each_consumable[i].name,
                    consumable_id: cItem.each_consumable[i].store_list._id,
                    delivery_unit_name:
                        cItem.each_consumable[i].restock_formula &&
                            cItem.each_consumable[i].restock_formula.length !== 0
                            ? cItem.each_consumable[i].restock_formula[0].delivery_unit_name
                            : "",
                    before_restock_qty: cItem.each_consumable[i].store_list.restock_qty,
                    delivery_qty: 0,
                    restock_qty: pathName.includes(PATH_CHECK_STOCK)
                        ? cItem.each_consumable[i].store_list.restock_qty
                        : 0 || 0,
                    restock_formula:
                        cItem.each_consumable[i].restock_formula &&
                            cItem.each_consumable[i].restock_formula.length !== 0
                            ? cItem.each_consumable[i].restock_formula
                            : [],
                    formula: cItem.each_consumable[i].formula,
                    unit_name: cItem.each_consumable[i].unit_name,
                });
            }
            const payload: any = {
                product_id: cItem._id._id,
                product_name: cItem._id.product_name,
                check_variant_list: temp,
            };
            setSelectedTab(1);
            addConsume(payload);
            handleClose();
        }
    };

    return (
        <SharedModal
            showPopup={showPopup}
            handleClose={handleClose}
            title={t("page.purchase_order.modal_consume_title")}
            width={600}
        >
            <StyledModalContent>
                {store_id !== "" && (
                    <div className="tab-section">
                        <Button
                            className={`tab-btn ${tab === 0 ? "active" : ""}`}
                            onClick={() => {
                                setTab(0);
                            }}
                            disabled={selectedTab === 0 || selectedTab === -1 ? false : true}
                        >
                            {t("page.purchase_order.modal_tab_ingredient")}
                        </Button>
                        <Button
                            className={`tab-btn ${tab === 1 ? "active" : ""}`}
                            onClick={() => {
                                setTab(1);
                            }}
                            disabled={selectedTab === 1 || selectedTab === -1 ? false : true}
                        >
                            {t("page.purchase_order.modal_tab_product")}
                        </Button>
                    </div>
                )}
                {store_id !== "" && (
                    <>
                        {tab === 0 && (
                            <Input
                                className="item-name"
                                suffix={<SearchOutlined onClick={onSearch} />}
                                onChange={handleChangeName}
                                onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                        onSearch();
                                    }
                                }}
                                value={itemName}
                            />
                        )}
                        {tab === 1 && (
                            <Input
                                className="item-name"
                                suffix={<SearchOutlined onClick={onSearch} />}
                                onChange={handleChangeProName}
                                onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                        onSearch();
                                    }
                                }}
                                value={proName}
                            />
                        )}
                    </>
                )}
                {tab === 0 && consumeList.length > 0 && (
                    <InfiniteScroll
                        dataLength={consumeList.length}
                        next={onLoadMore}
                        hasMore={consumeList.length < cTotal}
                        loader={<p>loading...</p>}
                        height={400}
                    >
                        <div className="consume-list-section consume">
                            {consumeList?.map((cItem: any, cIndex: number) => {
                                return (
                                    <div
                                        key={cIndex}
                                        className="consume-item"
                                        onClick={() => {
                                            selectConsume(cItem);
                                        }}
                                    >
                                        {cItem?.name}
                                    </div>
                                );
                            })}
                        </div>
                    </InfiniteScroll>
                )}
                {tab === 1 && proConList.length > 0 && (
                    <InfiniteScroll
                        dataLength={proConList.length}
                        next={onLoadMoreProduct}
                        hasMore={proConList.length < pTotal}
                        loader={<p>loading...</p>}
                        height={400}
                    >
                        <div className="consume-list-section consume">
                            {proConList?.map((cItem: any, cIndex) => {
                                return (
                                    <div
                                        key={cIndex}
                                        className="consume-item"
                                        onClick={() => {
                                            selectConsume(cItem);
                                        }}
                                    >
                                        {cItem._id.product_name}
                                    </div>
                                );
                            })}
                        </div>
                    </InfiniteScroll>
                )}
            </StyledModalContent>
        </SharedModal>
    );
};

const StyledModalContent = styled.div`
    .item-name {
        height: 45px;
    }
    .tab-section {
        margin: 15px 0px;
        .tab-btn {
            background: none;
            border: none;
            font-weight: 700;
            font-size: 16px;
            padding: 0px;
            padding-bottom: 20px;
            margin-right: 30px;
        }
        .tab-btn:hover {
            color: #f4394f;
        }
        .active {
            color: #f4394f;
            border-bottom: 4px solid #f4394f !important;
        }
    }

    .consume {
        background-color: #f7f7f7;
        padding: 10px 15px;
        border-radius: 8px;
        /* max-height:250px;
        overflow:scroll; */
        .consume-item {
            cursor: pointer;
            background-color: white;
            border: 1px solid #d7d7d7;
            border-radius: 8px;
            margin: 15px 0px;
            padding: 15px;
            color: black;
            font-size: 18px;
        }
        .consume-item:hover {
            /* background: #0263e0; */
            box-shadow: 0px 4px 12px rgba(2, 99, 224, 0.2) !important;
        }
    }
    .infinite-scroll-component {
        margin-top: 30px;
    }
`;
