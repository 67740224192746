import { ISvg } from "@interfaces";

export const Step3Icon = (props: ISvg) => (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="56" height="56" rx="28" fill={props.color || "#0263E0"} />
        <g clipPath="url(#clip0_8462_5287)">
            <path
                d="M36.4375 36.9997C36.4375 37.0929 36.4593 37.1848 36.5008 37.2683L37.7009 39.6681C37.8024 39.8716 38.0103 39.9999 38.2375 39.9999C38.4649 39.9999 38.6728 39.8716 38.7743 39.6681L39.9742 37.2683C40.0159 37.1848 40.0375 37.0929 40.0375 36.9997V22H36.4375V36.9997Z"
                fill="white"
            />
            <path
                d="M38.2375 17.1999C37.2439 17.201 36.4386 18.0063 36.4375 18.9999V20.7999H40.0375V18.9999C40.0364 18.0063 39.2313 17.201 38.2375 17.1999Z"
                fill="white"
            />
            <path
                d="M33.4374 17.1999H31.0373V18.9999C31.0373 19.3313 30.7688 19.6 30.4375 19.6C30.1062 19.6 29.8374 19.3313 29.8374 18.9999V16.6001C29.8374 16.2687 29.5687 16 29.2374 16C28.906 16 28.6375 16.2687 28.6375 16.6001V17.1999H26.2375V18.9999C26.2375 19.3313 25.9687 19.6 25.6374 19.6C25.306 19.6 25.0375 19.3313 25.0375 18.9999V16.6001C25.0375 16.2687 24.7688 16 24.4375 16C24.1061 16 23.8374 16.2687 23.8374 16.6001V17.1999H21.4376V18.9999C21.4376 19.3313 21.1688 19.6 20.8375 19.6C20.5061 19.6 20.2374 19.3313 20.2374 18.9999V16.6001C20.2374 16.2687 19.9689 16 19.6376 16C19.3062 16 19.0375 16.2687 19.0375 16.6001V17.1999H16.6374C16.4784 17.1999 16.3257 17.263 16.2132 17.3757C16.1007 17.4882 16.0374 17.6409 16.0376 17.8V39.3998C16.0374 39.5589 16.1007 39.7116 16.2132 39.8241C16.3257 39.9368 16.4784 39.9999 16.6374 39.9999H33.4374C33.5965 39.9999 33.7492 39.9368 33.8617 39.8241C33.9744 39.7116 34.0375 39.5589 34.0375 39.3998V17.8C34.0375 17.6409 33.9744 17.4882 33.8617 17.3757C33.7492 17.263 33.5965 17.1999 33.4374 17.1999ZM29.8374 35.1998H20.2374C19.9061 35.1998 19.6376 34.9313 19.6376 34.5999C19.6376 34.2686 19.9061 33.9999 20.2374 33.9999H29.8374C30.1688 33.9999 30.4375 34.2686 30.4375 34.5999C30.4375 34.9313 30.1688 35.1998 29.8374 35.1998ZM29.8374 31.5998H20.2374C19.9061 31.5998 19.6376 31.3313 19.6376 31C19.6376 30.6686 19.9061 30.3999 20.2374 30.3999H29.8374C30.1688 30.3999 30.4375 30.6686 30.4375 31C30.4375 31.3313 30.1688 31.5998 29.8374 31.5998ZM29.8374 27.9998H20.2374C19.9061 27.9998 19.6376 27.7313 19.6376 27.4C19.6376 27.0686 19.9061 26.7999 20.2374 26.7999H29.8374C30.1688 26.7999 30.4375 27.0686 30.4375 27.4C30.4375 27.7313 30.1688 27.9998 29.8374 27.9998ZM29.8374 24.3999H20.2374C19.9061 24.3999 19.6376 24.1314 19.6376 23.8C19.6376 23.4687 19.9061 23.1999 20.2374 23.1999H29.8374C30.1688 23.1999 30.4375 23.4687 30.4375 23.8C30.4375 24.1314 30.1688 24.3999 29.8374 24.3999Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_8462_5287">
                <rect width="24" height="24" fill="white" transform="translate(16 16)" />
            </clipPath>
        </defs>
    </svg>
);
