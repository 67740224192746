export const TableSvg = () => {
    return (
        <svg
            width="229"
            height="181"
            viewBox="0 0 229 181"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M60.5 1V0.5H60H6C2.96243 0.5 0.5 2.96243 0.5 6V20V20.5H1H60H60.5V20V1Z"
                fill="#EBF3FD"
            />
            <path
                d="M60.5 1V0.5H60H6C2.96243 0.5 0.5 2.96243 0.5 6V20V20.5H1H60H60.5V20V1Z"
                fill="#EBF3FD"
            />
            <path
                d="M60.5 1V0.5H60H6C2.96243 0.5 0.5 2.96243 0.5 6V20V20.5H1H60H60.5V20V1Z"
                stroke="#66A9FF"
            />
            <rect x="9" y="5.75" width="43" height="9.5" rx="4.75" fill="#C1DCFF" />
            <rect x="0.5" y="20.5" width="60" height="20" fill="white" />
            <rect x="0.5" y="20.5" width="60" height="20" fill="white" />
            <rect x="0.5" y="20.5" width="60" height="20" stroke="#66A9FF" />
            <rect x="9" y="25.75" width="43" height="9.5" rx="4.75" fill="#E7E7E7" />
            <rect x="0.5" y="40.5" width="60" height="20" fill="white" />
            <rect x="0.5" y="40.5" width="60" height="20" fill="white" />
            <rect x="0.5" y="40.5" width="60" height="20" stroke="#66A9FF" />
            <rect x="9" y="45.75" width="43" height="9.5" rx="4.75" fill="#E7E7E7" />
            <rect x="0.5" y="60.5" width="60" height="20" fill="white" />
            <rect x="0.5" y="60.5" width="60" height="20" fill="white" />
            <rect x="0.5" y="60.5" width="60" height="20" stroke="#66A9FF" />
            <rect x="9" y="65.75" width="43" height="9.5" rx="4.75" fill="#E7E7E7" />
            <rect x="0.5" y="80.5" width="60" height="20" fill="white" />
            <rect x="0.5" y="80.5" width="60" height="20" fill="white" />
            <rect x="0.5" y="80.5" width="60" height="20" stroke="#66A9FF" />
            <rect x="9" y="85.75" width="43" height="9.5" rx="4.75" fill="#E7E7E7" />
            <rect x="0.5" y="100.5" width="60" height="20" fill="white" />
            <rect x="0.5" y="100.5" width="60" height="20" fill="white" />
            <rect x="0.5" y="100.5" width="60" height="20" stroke="#66A9FF" />
            <rect x="9" y="105.75" width="43" height="9.5" rx="4.75" fill="#E7E7E7" />
            <rect x="0.5" y="120.5" width="60" height="20" fill="white" />
            <rect x="0.5" y="120.5" width="60" height="20" fill="white" />
            <rect x="0.5" y="120.5" width="60" height="20" stroke="#66A9FF" />
            <rect x="9" y="125.75" width="43" height="9.5" rx="4.75" fill="#E7E7E7" />
            <rect x="0.5" y="140.5" width="60" height="20" fill="white" />
            <rect x="0.5" y="140.5" width="60" height="20" fill="white" />
            <rect x="0.5" y="140.5" width="60" height="20" stroke="#66A9FF" />
            <rect x="9" y="145.75" width="43" height="9.5" rx="4.75" fill="#E7E7E7" />
            <path
                d="M1 160.5H0.5V161V175C0.5 178.038 2.96243 180.5 6 180.5H60H60.5V180V161V160.5H60H1Z"
                fill="white"
            />
            <path
                d="M1 160.5H0.5V161V175C0.5 178.038 2.96243 180.5 6 180.5H60H60.5V180V161V160.5H60H1Z"
                fill="white"
            />
            <path
                d="M1 160.5H0.5V161V175C0.5 178.038 2.96243 180.5 6 180.5H60H60.5V180V161V160.5H60H1Z"
                stroke="#66A9FF"
            />
            <rect x="9" y="165.75" width="43" height="9.5" rx="4.75" fill="#E7E7E7" />
            <rect x="59.5" y="0.5" width="97" height="20" fill="#EBF3FD" />
            <rect x="59.5" y="0.5" width="97" height="20" fill="#EBF3FD" />
            <rect x="59.5" y="0.5" width="97" height="20" stroke="#66A9FF" />
            <rect x="73.2632" y="6" width="69.9661" height="8" rx="4" fill="#C1DCFF" />
            <rect x="59.5" y="20.5" width="97" height="20" fill="white" />
            <rect x="59.5" y="20.5" width="97" height="20" fill="white" />
            <rect x="59.5" y="20.5" width="97" height="20" stroke="#66A9FF" />
            <rect x="59.5" y="40.5" width="97" height="20" fill="white" />
            <rect x="59.5" y="40.5" width="97" height="20" fill="white" />
            <rect x="59.5" y="40.5" width="97" height="20" stroke="#66A9FF" />
            <rect x="59.5" y="60.5" width="97" height="20" fill="white" />
            <rect x="59.5" y="60.5" width="97" height="20" fill="white" />
            <rect x="59.5" y="60.5" width="97" height="20" stroke="#66A9FF" />
            <rect x="59.5" y="80.5" width="97" height="20" fill="white" />
            <rect x="59.5" y="80.5" width="97" height="20" fill="white" />
            <rect x="59.5" y="80.5" width="97" height="20" stroke="#66A9FF" />
            <rect x="59.5" y="100.5" width="97" height="20" fill="white" />
            <rect x="59.5" y="100.5" width="97" height="20" fill="white" />
            <rect x="59.5" y="100.5" width="97" height="20" stroke="#66A9FF" />
            <rect x="59.5" y="120.5" width="97" height="20" fill="white" />
            <rect x="59.5" y="120.5" width="97" height="20" fill="white" />
            <rect x="59.5" y="120.5" width="97" height="20" stroke="#66A9FF" />
            <rect x="59.5" y="140.5" width="97" height="20" fill="white" />
            <rect x="59.5" y="140.5" width="97" height="20" fill="white" />
            <rect x="59.5" y="140.5" width="97" height="20" stroke="#66A9FF" />
            <rect x="59.5" y="160.5" width="97" height="20" fill="white" />
            <rect x="59.5" y="160.5" width="97" height="20" fill="white" />
            <rect x="59.5" y="160.5" width="97" height="20" stroke="#66A9FF" />
            <rect x="155.5" y="0.5" width="37" height="20" fill="#EBF3FD" />
            <rect x="155.5" y="0.5" width="37" height="20" fill="#EBF3FD" />
            <rect x="155.5" y="0.5" width="37" height="20" stroke="#66A9FF" />
            <rect x="160.974" y="6" width="26.2373" height="8" rx="4" fill="#C1DCFF" />
            <rect x="155.5" y="20.5" width="37" height="20" fill="white" />
            <rect x="155.5" y="20.5" width="37" height="20" fill="white" />
            <rect x="155.5" y="20.5" width="37" height="20" stroke="#66A9FF" />
            <rect x="155.5" y="40.5" width="37" height="20" fill="white" />
            <rect x="155.5" y="40.5" width="37" height="20" fill="white" />
            <rect x="155.5" y="40.5" width="37" height="20" stroke="#66A9FF" />
            <rect x="155.5" y="60.5" width="37" height="20" fill="white" />
            <rect x="155.5" y="60.5" width="37" height="20" fill="white" />
            <rect x="155.5" y="60.5" width="37" height="20" stroke="#66A9FF" />
            <rect x="155.5" y="80.5" width="37" height="20" fill="white" />
            <rect x="155.5" y="80.5" width="37" height="20" fill="white" />
            <rect x="155.5" y="80.5" width="37" height="20" stroke="#66A9FF" />
            <rect x="155.5" y="100.5" width="37" height="20" fill="white" />
            <rect x="155.5" y="100.5" width="37" height="20" fill="white" />
            <rect x="155.5" y="100.5" width="37" height="20" stroke="#66A9FF" />
            <rect x="155.5" y="120.5" width="37" height="20" fill="white" />
            <rect x="155.5" y="120.5" width="37" height="20" fill="white" />
            <rect x="155.5" y="120.5" width="37" height="20" stroke="#66A9FF" />
            <rect x="155.5" y="140.5" width="37" height="20" fill="white" />
            <rect x="155.5" y="140.5" width="37" height="20" fill="white" />
            <rect x="155.5" y="140.5" width="37" height="20" stroke="#66A9FF" />
            <rect x="155.5" y="160.5" width="37" height="20" fill="white" />
            <rect x="155.5" y="160.5" width="37" height="20" fill="white" />
            <rect x="155.5" y="160.5" width="37" height="20" stroke="#66A9FF" />
            <path
                d="M192 0.5H191.5V1V20V20.5H192H228H228.5V20V6C228.5 2.96243 226.038 0.5 223 0.5H192Z"
                fill="#EBF3FD"
            />
            <path
                d="M192 0.5H191.5V1V20V20.5H192H228H228.5V20V6C228.5 2.96243 226.038 0.5 223 0.5H192Z"
                fill="#EBF3FD"
            />
            <path
                d="M192 0.5H191.5V1V20V20.5H192H228H228.5V20V6C228.5 2.96243 226.038 0.5 223 0.5H192Z"
                stroke="#66A9FF"
            />
            <rect x="196.974" y="6" width="26.2373" height="8" rx="4" fill="#C1DCFF" />
            <rect x="191.5" y="20.5" width="37" height="20" fill="white" />
            <rect x="191.5" y="20.5" width="37" height="20" fill="white" />
            <rect x="191.5" y="20.5" width="37" height="20" stroke="#66A9FF" />
            <rect x="191.5" y="40.5" width="37" height="20" fill="white" />
            <rect x="191.5" y="40.5" width="37" height="20" fill="white" />
            <rect x="191.5" y="40.5" width="37" height="20" stroke="#66A9FF" />
            <rect x="191.5" y="60.5" width="37" height="20" fill="white" />
            <rect x="191.5" y="60.5" width="37" height="20" fill="white" />
            <rect x="191.5" y="60.5" width="37" height="20" stroke="#66A9FF" />
            <rect x="191.5" y="80.5" width="37" height="20" fill="white" />
            <rect x="191.5" y="80.5" width="37" height="20" fill="white" />
            <rect x="191.5" y="80.5" width="37" height="20" stroke="#66A9FF" />
            <rect x="191.5" y="100.5" width="37" height="20" fill="white" />
            <rect x="191.5" y="100.5" width="37" height="20" fill="white" />
            <rect x="191.5" y="100.5" width="37" height="20" stroke="#66A9FF" />
            <rect x="191.5" y="120.5" width="37" height="20" fill="white" />
            <rect x="191.5" y="120.5" width="37" height="20" fill="white" />
            <rect x="191.5" y="120.5" width="37" height="20" stroke="#66A9FF" />
            <rect x="191.5" y="140.5" width="37" height="20" fill="white" />
            <rect x="191.5" y="140.5" width="37" height="20" fill="white" />
            <rect x="191.5" y="140.5" width="37" height="20" stroke="#66A9FF" />
            <path
                d="M192 160.5H191.5V161V180V180.5H192H223C226.038 180.5 228.5 178.038 228.5 175V161V160.5H228H192Z"
                fill="white"
            />
            <path
                d="M192 160.5H191.5V161V180V180.5H192H223C226.038 180.5 228.5 178.038 228.5 175V161V160.5H228H192Z"
                fill="white"
            />
            <path
                d="M192 160.5H191.5V161V180V180.5H192H223C226.038 180.5 228.5 178.038 228.5 175V161V160.5H228H192Z"
                stroke="#66A9FF"
            />
        </svg>
    );
};
