import { useState, useRef, useEffect } from "react";
import {
    ComponentContactSortIcon,
    StyledTable,
    ComponentEmptyData,
    StyledTableButtonTop,
    ComponentTableSearch,
    ComponentRowService,
    ComponentRowStore,
    ComponentRowCategory,
    ComponentProductDrawer,
    ComponentInvCateDrawer,
    ComponentRowInvCate,
} from "@components";
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    TouchSensor,
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    rectSortingStrategy,
    //@ts-ignore
} from "@dnd-kit/sortable";
import { Row, Col, Pagination, Button } from "antd";
import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";
import {
    enumSortBy,
    enumContactSortFields,
    PAGINATION,
    PAGE_START,
    enumNewsStatus,
    INITIAL_LIST_PARAMS,
    PATH_PRODUCT_CREATE,
    PATH_STORE_CREATE,
} from "@configs";
import { IPaginationNext, INews, IDataexample, IGetConsumableCategorysExample } from "@interfaces";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth, selectContact, selectOrg, setLoading } from "@redux";
import { useNotify } from "@utils";
import { useHistory } from "react-router";
import axios from "axios";
import styled from "styled-components";
import { MoreHorizontal } from "react-feather";
import { orgApi } from "@api";

const INITAL_PAGE = {
    xpage: PAGE_START,
    xlimit: PAGINATION,
};

export const PageInvCategoryList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const search_params = useSelector(selectContact).paramsContact;
    const [params, setParams] = useState(search_params);
    const org_id = useSelector(selectAuth).userInfo?.merchant._id;
    const categories = useSelector(selectOrg).category;
    const [showModal, setShowModal] = useState<boolean>(false);
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;

    const { userInfo } = useSelector(selectAuth);
    const [pagination, setPagination] = useState<IPaginationNext>(INITAL_PAGE);
    const [services, setServices] = useState<IDataexample[]>([]);
    const [stores, setStores] = useState<IGetConsumableCategorysExample[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [editItem, setEditItem] = useState();
    const { success, error } = useNotify();
    const history = useHistory();
    useEffect(() => {
        fetchNews();
    }, [categories]);

    const fetchNews = async () => {
        dispatch(setLoading(true));
        try {
            const getAllStores = await orgApi.getInvCat({
                organization_id: userInfo?.merchant?._id || "",
            });
            setStores(getAllStores.data.result);
        } catch (err: any) {
            error(err.msg);
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleSort = (sortField: enumContactSortFields, sortBy: enumSortBy) => {
        const newSortParams = { ...params, sortField, sortBy };
        setParams(newSortParams);
        // fetchContacts(newSortParams);
    };
    const callbackGetList = () => {
        fetchNews();
    };
    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            const swapItem = (items: IDataexample[]) => {
                const oldIndex = items.findIndex((item) => JSON.stringify(item) === active.id);
                const newIndex = items.findIndex((item) => JSON.stringify(item) === over.id);
                return arrayMove(items, oldIndex, newIndex);
            };
            setServices(swapItem);
        }
    };
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
        useSensor(TouchSensor)
    );
    const handlePagination = (page) => {
        setPagination(page);
    };
    const updateService = async (data) => {
        dispatch(setLoading(true));
        try {
            // api call in here
            await null;
            // const response = await newsAPI.getList();
            // setNews();
            // setTotal(response.data.total);
            success("api response");
        } catch (err: any) {
            error(err.msg);
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };
    const handleEditItem = (editItem) => {
        setShowModal(true);
        setEditItem(editItem);
    };
    const handleCloseModal = () => {
        setShowModal(false);
        setEditItem(undefined);
        fetchNews();
    };
    return (
        <StyledTable>
            <div className="page-layout">
                <div className="page-header-layout">
                    <div className="page-header" style={{ padding: "1.5rem 0" }}>
                        <div>
                            <h3>{t("page.inv_cate.list_title")}</h3>
                        </div>
                        <div className="flex-header">
                            <StyledTableButtonTop
                                type="danger"
                                size="default"
                                text={t("page.inv_cate.add")}
                                onClick={() => setShowModal(!showModal)}
                                className="add-btn"
                                icon="plus"
                                style={{
                                    //  background: "#F22F46",
                                    borderRadius: "4px",
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="page-table-layout">
                    <div className="table">
                        <ScrollContainer vertical={false}>
                            <Styless>
                                <div className="table_ground">
                                    <div className="table-body">
                                        <div className="table-header">
                                            <Row
                                                gutter={24}
                                                type="flex"
                                                justify="space-between"
                                                align="middle"
                                                className="header-row"
                                            >
                                                <Col
                                                    className="col-item"
                                                    span={12}
                                                    style={{ padding: "0" }}
                                                >
                                                    <p>{t("page.inv_cate.table_name")}</p>
                                                    <div className="filter-button">
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.ASC}
                                                            sortField={enumContactSortFields.POINTS}
                                                            searchParams={params}
                                                        />
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.DESC}
                                                            sortField={enumContactSortFields.POINTS}
                                                            searchParams={params}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col
                                                    className="col-item"
                                                    span={6}
                                                    style={{ padding: "0" }}
                                                >
                                                    <p>{t("page.inv_cate.table_ingredients")}</p>
                                                    <div className="filter-button">
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.ASC}
                                                            sortField={
                                                                enumContactSortFields.CREATED_AT
                                                            }
                                                            searchParams={params}
                                                        />
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.DESC}
                                                            sortField={
                                                                enumContactSortFields.CREATED_AT
                                                            }
                                                            searchParams={params}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col
                                                    className="col-item"
                                                    span={6}
                                                    style={{ placeContent: "end" }}
                                                >
                                                    <p>{t("page.manage")}</p>
                                                </Col>
                                            </Row>
                                        </div>
                                        <DndContext
                                            sensors={sensors}
                                            collisionDetection={closestCenter}
                                            onDragEnd={handleDragEnd}
                                        >
                                            <SortableContext
                                                items={stores.map((item) => JSON.stringify(item))}
                                                strategy={rectSortingStrategy}
                                            >
                                                {stores.length !== 0 ? (
                                                    <div className="data-table">
                                                        {stores.map((item, index) => (
                                                            <ComponentRowInvCate
                                                                key={index}
                                                                data={item}
                                                                itemJson={JSON.stringify(item)}
                                                                categories={categories}
                                                                handleEdit={updateService}
                                                                callbackGetList={callbackGetList}
                                                                editItem={handleEditItem}
                                                                index={index}
                                                                stores={stores}
                                                                setStores={setStores}
                                                            />
                                                        ))}
                                                        <div
                                                            style={{ width: "100%", height: 10 }}
                                                        ></div>
                                                    </div>
                                                ) : (
                                                    <ComponentEmptyData />
                                                )}
                                            </SortableContext>
                                        </DndContext>
                                    </div>
                                </div>
                            </Styless>
                        </ScrollContainer>
                    </div>
                </div>

                <div className="page-bottom">
                    {total > 0 && (
                        <div className="pagination">
                            <Pagination
                                defaultCurrent={INITIAL_LIST_PARAMS.page || 1}
                                pageSize={PAGINATION}
                                total={total}
                                onChange={handlePagination}
                                current={params.page}
                            />
                        </div>
                    )}
                </div>
            </div>
            <ComponentInvCateDrawer
                showModal={showModal}
                editItem={editItem}
                handleClose={handleCloseModal}
            />
        </StyledTable>
    );
};

const Styless = styled.div`
    .table-header {
        padding: 18px 34px 12px 66px !important;
    }
`;
