import {
    IProductListParams,
    IDeleteProductParams,
    ICreateProductParams,
    IUpdateProductParams,
    IDetailProductParams,
    IProductList,
    ISingleProduct,
    IProductEdit,
    IGetVariants,
    IGetVariant,
    IUpdateVariantQuantityStoreId,
    IGetHistoryChangeOfVariantStoreId,
    IGetProductListIsAutoConsumableStoreId,
    IGetProductsWithComsumablesAuto,
    IGetProductsWithComsumablesAll,
    IGetSortNoProducts,
    ISetSortNoProduct,
} from "@interfaces";

import axiosClient from "./axiosClient";
import storeFrontAxiosClient from "./storeFrontAxiosClient";

export const productAPI = {
    getList: (params: IProductList) => {
        const url = "product/getProducts";
        return storeFrontAxiosClient.get(url, { params });
    },
    getProduct: (params: ISingleProduct) => {
        const url = "product/getProduct";
        return storeFrontAxiosClient.get(url, { params });
    },
    delete: (params: IDeleteProductParams) => {
        const url = "product/deleteproduct";
        return storeFrontAxiosClient.post(url, params);
    },
    create: (params: ICreateProductParams) => {
        const url = "product/createProductVariantDimension";
        return storeFrontAxiosClient.post(url, params);
    },
    update: (params: IProductEdit) => {
        const url = "product/updateProductVariantDimension";
        return storeFrontAxiosClient.patch(url, params);
    },
    detail: (params: IDetailProductParams) => {
        const url = "porduct/getProduct";
        return axiosClient.get(url, { params });
    },
    getVariants: (params: IGetVariants) => {
        const url = "product/getVariants";
        return storeFrontAxiosClient.get(url, { params });
    },
    getVariant: (params: IGetVariant) => {
        const url = "product/getVariant";
        return storeFrontAxiosClient.get(url, { params });
    },
    updateVariantQuantityStoreId: (params: IUpdateVariantQuantityStoreId) => {
        const url = "product/updateVariantQuantityStoreId";
        return storeFrontAxiosClient.patch(url, params);
    },
    getHistoryChangeOfVariantStoreId: (params: IGetHistoryChangeOfVariantStoreId) => {
        const url = "product/getHistoryChangeOfVariantStoreId";
        return storeFrontAxiosClient.get(url, { params });
    },
    getListIsAutoConsumableStoreId: (params: IGetProductListIsAutoConsumableStoreId) => {
        const url = "product/getProductListIsAutoConsumableStoreId";
        return storeFrontAxiosClient.get(url, { params });
    },
    getProductsWithComsumablesAuto: (params: IGetProductsWithComsumablesAuto) => {
        const url = "product/getProductsWithComsumablesAuto";
        return storeFrontAxiosClient.get(url, { params });
    },
    getProductsForWaste: (params: IGetProductsWithComsumablesAuto) => {
        const url = "check_stock_doc/getProductsForWaste";
        return storeFrontAxiosClient.get(url, { params });
    },
    getProductwithConsumableAll: (params: IGetProductsWithComsumablesAll) => {
        const url = "product/getProductsWithComsumablesAll";
        return storeFrontAxiosClient.get(url, { params });
    },
    getSortNoProducts: (params: IGetSortNoProducts) => {
        const url = "product/getSortNoProducts";
        return storeFrontAxiosClient.get(url, { params });
    },
    setSortNoProduct: (params: ISetSortNoProduct) => {
        const url = "product/setSortNoProduct";
        return storeFrontAxiosClient.post(url, params);
    }
};
