import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Col, Row, Menu, Dropdown, Button } from "antd";
import { MoreHorizontal } from "react-feather";
import { ICancelPr, IDataexample, IGetStoresBindingDropdownExample } from "@interfaces";
import { PATH_PURCHASE_REQUEST, PATH_TRANSFER_REQUEST } from "@configs";
import { IconDrag } from "@components";
import { useNotify } from "@utils";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useHistory } from "react-router";
import { DoneIcon } from "../icon/DoneIcon";
import { POAPI } from "@api";
import { ArrowRightOutlined } from "@ant-design/icons";

interface IProps {
    data: IDataexample | any;
    itemJson: string;
    index: number;
    storeList: IGetStoresBindingDropdownExample[];
    requestType?: string;
    Refresh: number
    setRefresh: (value: number) => void;
    openDetailModal: (value: number) => void;
}

export const ComponentRowTransferRequest = (props: IProps) => {
    //hooks
    const { t } = useTranslation();
    const { success, error } = useNotify();
    const history = useHistory();
    //props
    const { data, itemJson, index, storeList, requestType, Refresh, setRefresh, openDetailModal } = props;
    const { attributes, listeners, setNodeRef, transform } = useSortable({
        id: itemJson,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
    };

    const MONTHlIST = [
        t("month.jan"),
        t("month.feb"),
        t("month.mar"),
        t("month.apr"),
        t("month.may"),
        t("month.jun"),
        t("month.jul"),
        t("month.aug"),
        t("month.sep"),
        t("month.oct"),
        t("month.nov"),
        t("month.dec"),
    ];

    function formatDate(date) {
        let d = new Date(date),
            month = "" + d.getMonth(),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        return [day, MONTHlIST[parseInt(month)], year + 543].join(" ");
    }
    const cancelPr = async () => {
        let payload: ICancelPr = {
            organization_id: data.organization_id,
            tpr_id: data._id,
        };
        const res = await POAPI.cancelPr(payload);
        if (res.data.error === "0") {
            success(res.data.msg);
            setRefresh(Refresh + 1);
        } else {
            error(res.data.msg);
        }

    };
    const menu = (
        <Menu>
            <Menu.Item
                key={1}
                onClick={() => {
                    history.push(`${PATH_PURCHASE_REQUEST}/${data._id}`);
                }}
            >
                <div className="duplicate-btn" style={{ color: "#000" }}>
                    {t("page.purchase_order.view_order")}
                </div>
            </Menu.Item>
            {data.status === "pending" &&
                <Menu.Item
                    key={1}
                    onClick={cancelPr}
                >
                    <div className="duplicate-btn" style={{ color: "#a40808" }}>
                        {t("page.purchase_order.delete_order")}
                    </div>
                </Menu.Item>
            }

        </Menu>
    );

    const renderStore = () => {
        const findStore = storeList.find((obj) => obj._id === data?.store_id);
        return <>{findStore?.store_name || "-"}</>;
    };
    return (
        <div className="table-element" ref={setNodeRef} style={style}>
            <div className="drag-icon hide" {...attributes} {...listeners}>
                <IconDrag />
            </div>
            <div className="table-row">
                <Row
                    style={{ height: "100%" }}
                    gutter={24}
                    type="flex"
                    align="middle"
                    justify="space-between"
                >
                    <Col className="col-item" span={4}>
                        <b onClick={() => openDetailModal(index)}>
                            <p>{data?.ttr_no || t("page.empty_text")}</p>
                        </b>
                    </Col>
                    <Col className="col-item" span={3}>
                        <p>{formatDate(data?.created_date) || t("page.empty_text")}</p>
                    </Col>
                    <Col className="col-item" span={4}>
                        <p>{data?.ttr_consumable_list?.[0]?.request_to_store_name || t("page.empty_text")}</p>
                    </Col>

                    <Col className="col-item price" span={5}>
                        <p>
                            <ArrowRightOutlined /> {renderStore()}
                        </p>
                    </Col>
                    <Col className="col-item visible-md" span={3}>
                        {data.status === "finished" && <DoneIcon />}
                        <p>
                            {data?.ttr_consumable_list?.length}
                        </p>
                    </Col>
                    <Col className="col-item visible-md" span={3}>
                        {data.status === "finished" && <DoneIcon />}
                        <p>
                            {data?.status
                                ? t("page.status_option." + data?.status)
                                : t("page.empty_text")}
                        </p>
                    </Col>
                    <Col className="col-item" span={2}>
                        <Button onClick={() => openDetailModal(index)}>
                            {t("page.detail")}
                        </Button>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
