import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Switch, Col, Row, Avatar, Menu, Dropdown, Button } from "antd";
import { MoreHorizontal } from "react-feather";

import { IDataexample, INewsCategory } from "@interfaces";
import {
    enumNewsType,
    PATH_PERMISSION_POS,
    PATH_PRODUCT_CREATE,
    PATH_SERVICES_CREATE,
} from "@configs";
import { selectAuth, store } from "@redux";
import { useSelector } from "react-redux";
import { IconDrag } from "@components";
import { useNotify } from "@utils";
import React, { useEffect, useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import styled from "styled-components";
interface IProps {
    data: IDataexample;
    itemJson: string;
    handleEdit: (status: boolean, data: IDataexample) => void;
    callbackGetList: () => void;
    index: number;
    newType?: enumNewsType;
    categories: INewsCategory[];
    stores: any[];
}

export const ComponentRowPermission = (props: IProps) => {
    //hooks
    const { t } = useTranslation();
    const { success, error } = useNotify();
    const organization_id = useSelector(selectAuth).auth?.organization_data?._id;
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    //props
    const { data, handleEdit, callbackGetList, itemJson, newType, stores, index } = props;
    const [show, setShowModal] = useState(false);

    const { attributes, listeners, setNodeRef, transform } = useSortable({
        id: itemJson,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
    };

    const handleDelete = async () => {
        try {
            await console.log("delete");
            // await newsAPI.delete({ organization_id: organization_id || "", news_id: data._id });
            callbackGetList();
            setShowModal(false);
            success(t("message.delete.success"));
        } catch (err: any) {
            if (err) {
                error(err.msg);
            } else error(t("message.delete.fail"));
        }
    };

    const showModal = () => {
        setShowModal(!show);
        // Modal.confirm({
        //     title: t("message.delete_title"),
        //     icon: <ExclamationCircleOutlined />,
        //     content: t("message.delete_confirm", {
        //         returnObjects: true,
        //         name: t("object._contact"),
        //     }),
        //     okText: t("page.delete"),
        //     cancelText: t("page.cancel"),
        //     centered: true,
        //     onOk: handleDelete,
        // });
    };

    const menu = (
        <Menu>
            <Menu.Item key={1} onClick={handleDelete}>
                <div className="duplicate-btn">{t("page.delete")}</div>
            </Menu.Item>
        </Menu>
    );
    const handleUpdateStatus = (e: boolean) => {
        handleEdit(e, data);
    };
    return (
        <Styles>
            {/* {stores.length !== 0 ? (
                stores.map((item, i) => ( */}
            <div className="table-element" ref={setNodeRef} style={style}>
                {/* <div className="drag-icon" {...attributes} {...listeners}>
                    <IconDrag />
                </div> */}
                <div className="table-row">
                    <Row
                        style={{ height: "100%" }}
                        gutter={24}
                        type="flex"
                        align="middle"
                        justify="space-between"
                    >
                        <Col className="col-item" span={9}>
                            <Link to={`${PATH_PERMISSION_POS}/${stores[index].store_code}`}>
                                <p className="news-title">
                                    {stores[index].store_name || t("page.empty_text")}
                                </p>
                            </Link>
                            {/* <Button onClick={() => console.log(stores[index].store_name)}>
                                Click
                            </Button> */}
                        </Col>
                        <Col className="col-item" span={5}>
                            <p>{stores[index].store_code || t("page.empty_text")}</p>
                        </Col>
                        <Col className="col-item visible-md" span={5}>
                            {/* <p>{data.sub_cat || t("page.empty_text")}</p> */}
                            <p className="news-title">{stores[index].employee_list.length || 0}</p>
                        </Col>
                        <Col className="col-item" span={5} style={{ placeContent: "end" }}>
                            {/* <Dropdown
                                //@ts-ignore
                                getPopupContainer={(trigger) => trigger.parentNode}
                                overlay={menu}
                                trigger={["click"]}
                                placement="bottomRight"
                            > */}
                            <Link to={`${PATH_PERMISSION_POS}/${stores[index].store_code}`}>
                                <Button
                                    style={{
                                        height: "42px",
                                        border: "unset",
                                        borderRadius: "4px",
                                        background: "#f4394f",
                                        color: "#fff",
                                        marginRight: "10px",
                                    }}
                                >
                                    {t("page.add_view_permission")}
                                </Button>
                            </Link>
                            {/* </Dropdown> */}
                        </Col>
                    </Row>
                </div>
            </div>
            {/* ))
            ) : (
                <p>Nothing to show!</p>
            )} */}
        </Styles>
    );
};
export const Styles = styled.div`
    .table-row {
        height: 97px !important;
    }
`;
