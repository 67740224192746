import { useHistory } from "react-router";
import { StyledNewDetail } from "@components";
import { ModuleWholesaleForm } from "@modules";

export const PageWholeSaleEdit = () => {
    return (
        <StyledNewDetail>
            <ModuleWholesaleForm />
        </StyledNewDetail>
    );
};
