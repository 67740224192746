export const Heart = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.85 2.50071C16.481 2.50071 17.111 2.58971 17.71 2.79071C21.401 3.99071 22.731 8.04071 21.62 11.5807C20.99 13.3897 19.96 15.0407 18.611 16.3897C16.68 18.2597 14.561 19.9197 12.28 21.3497L12.03 21.5007L11.77 21.3397C9.48102 19.9197 7.35002 18.2597 5.40102 16.3797C4.06102 15.0307 3.03002 13.3897 2.39002 11.5807C1.26002 8.04071 2.59002 3.99071 6.32102 2.76971C6.61102 2.66971 6.91002 2.59971 7.21002 2.56071H7.33002C7.61102 2.51971 7.89002 2.50071 8.17002 2.50071H8.28002C8.91002 2.51971 9.52002 2.62971 10.111 2.83071H10.17C10.21 2.84971 10.24 2.87071 10.26 2.88971C10.481 2.96071 10.69 3.04071 10.89 3.15071L11.27 3.32071C11.3618 3.36968 11.4649 3.44451 11.554 3.50918C11.6104 3.55015 11.6612 3.58705 11.7 3.61071C11.7163 3.62034 11.7329 3.63002 11.7496 3.63978C11.8354 3.68983 11.9247 3.74197 12 3.79971C13.111 2.95071 14.46 2.49071 15.85 2.50071ZM18.51 9.70071C18.92 9.68971 19.27 9.36071 19.3 8.93971V8.82071C19.33 7.41971 18.481 6.15071 17.19 5.66071C16.78 5.51971 16.33 5.74071 16.18 6.16071C16.04 6.58071 16.26 7.04071 16.68 7.18971C17.321 7.42971 17.75 8.06071 17.75 8.75971V8.79071C17.731 9.01971 17.8 9.24071 17.94 9.41071C18.08 9.58071 18.29 9.67971 18.51 9.70071Z"
            fill="#6C7084"
        />
    </svg>
);
