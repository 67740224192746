export const TickIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="44"
            height="43"
            viewBox="0 0 44 43"
            fill="none"
        >
            <path
                d="M40.7683 17.809C40.1059 17.4238 39.5106 16.9333 39.0059 16.3566C39.057 15.5538 39.2482 14.7661 39.571 14.0292C40.1645 12.3545 40.8356 10.4574 39.7586 8.98264C38.6816 7.50785 36.6479 7.55477 34.863 7.59556C34.0744 7.67671 33.2776 7.62291 32.507 7.43646C32.0965 6.76843 31.8033 6.03507 31.6401 5.26812C31.1342 3.54447 30.557 1.59644 28.7844 1.01305C27.075 0.462296 25.4921 1.67395 24.0928 2.73874C23.489 3.29014 22.7893 3.72637 22.0285 4.02587C21.2596 3.72881 20.5522 3.29247 19.9417 2.73874C18.5465 1.68007 16.9697 0.456177 15.2522 1.01509C13.4836 1.59032 12.9064 3.54447 12.3964 5.26812C12.2335 6.03258 11.9432 6.7642 11.5377 7.43238C10.7656 7.61832 9.96786 7.67348 9.17758 7.59556C7.38661 7.54661 5.36922 7.49357 4.282 8.98264C3.19477 10.4717 3.87403 12.3545 4.46966 14.0271C4.79693 14.7629 4.99101 15.551 5.04285 16.3546C4.53918 16.932 3.94456 17.4233 3.28248 17.809C1.78933 18.8289 0.0942383 19.9895 0.0942383 21.8743C0.0942383 23.7591 1.78933 24.9157 3.28248 25.9397C3.94441 26.3248 4.53902 26.8154 5.04285 27.3921C4.99646 28.1954 4.80791 28.9841 4.48598 29.7215C3.89443 31.3942 3.22537 33.2912 4.30036 34.766C5.37534 36.2408 7.40293 36.1939 9.19593 36.1531C9.98528 36.0719 10.7827 36.1257 11.554 36.3122C11.9627 36.9809 12.2551 37.7141 12.4189 38.4805C12.9247 40.2042 13.502 42.1522 15.2746 42.7356C15.5588 42.8267 15.8553 42.8735 16.1538 42.8743C17.5882 42.6685 18.9214 42.0162 19.9642 41.0099C20.568 40.4585 21.2676 40.0223 22.0285 39.7228C22.7973 40.0199 23.5047 40.4562 24.1152 41.0099C25.5125 42.0768 27.0954 43.2945 28.8068 42.7336C30.5753 42.1584 31.1526 40.2042 31.6626 38.4826C31.826 37.7167 32.1185 36.9842 32.5274 36.3163C33.2965 36.1291 34.0918 36.0739 34.8794 36.1531C36.6703 36.1959 38.6877 36.2551 39.7749 34.766C40.8622 33.277 40.1829 31.3942 39.5873 29.7195C39.2622 28.9844 39.0683 28.1981 39.0141 27.3961C39.518 26.8182 40.1134 26.3269 40.7765 25.9417C42.2696 24.9218 43.9647 23.7591 43.9647 21.8743C43.9647 19.9895 42.2635 18.8309 40.7683 17.809Z"
                fill="#6CD14E"
            />
            <path
                d="M19.9852 27.4839C19.7843 27.4843 19.5853 27.4448 19.3997 27.3678C19.2141 27.2908 19.0457 27.1777 18.9041 27.0351L14.8244 22.9555C14.5542 22.6655 14.4071 22.2819 14.4141 21.8856C14.421 21.4892 14.5816 21.1111 14.8619 20.8308C15.1422 20.5505 15.5204 20.3899 15.9167 20.3829C16.313 20.3759 16.6966 20.523 16.9866 20.7933L20.128 23.9346L27.2266 18.6107C27.5512 18.3672 27.9592 18.2627 28.3608 18.3201C28.7625 18.3775 29.1249 18.592 29.3684 18.9166C29.6118 19.2412 29.7163 19.6492 29.659 20.0509C29.6016 20.4526 29.387 20.815 29.0624 21.0585L20.9031 27.1779C20.6382 27.3764 20.3162 27.4838 19.9852 27.4839Z"
                fill="white"
            />
        </svg>
    );
};
