import {
    ICreateCheckStockDoc,
    IGetCheckStockDoc,
    IGetCheckStockDocs,
    IGetInitCheckStockConsumableListByStoreId,
    IGetSettingCheckStockConsumableListByStoreId,
    IGetSettingCheckStockProductListByStoreId,
    IUpdateSettingCheckStockConsumableByStoreId,
    IUpdateSettingCheckStockProductByStoreId,
} from "@interfaces";
import axiosClient from "./storeFrontAxiosClient";

export const checkStockDocAPI = {
    getCheckStockDocs: (params: IGetCheckStockDocs) => {
        const url = `/check_stock_doc/getCheckStockDocs`;
        return axiosClient.get(url, { params });
    },
    getCheckStockDoc: (params: IGetCheckStockDoc) => {
        const url = `/check_stock_doc/getCheckStockDoc`;
        return axiosClient.get(url, { params });
    },
    createCheckStockDoc: (params: ICreateCheckStockDoc) => {
        const url = `/check_stock_doc/createCheckStockDoc`;
        return axiosClient.post(url, params);
    },
    getSettingCheckStockProductListByStoreId: (
        params: IGetSettingCheckStockProductListByStoreId
    ) => {
        const url = `/check_stock_doc/getSettingCheckStockProductListByStoreId`;
        return axiosClient.get(url, { params });
    },
    updateSettingCheckStockProductByStoreId: (params: IUpdateSettingCheckStockProductByStoreId) => {
        const url = `/check_stock_doc/updateSettingCheckStockProductByStoreId`;
        return axiosClient.post(url, params);
    },
    getSettingCheckStockConsumableListByStoreId: (
        params: IGetSettingCheckStockConsumableListByStoreId
    ) => {
        const url = `/check_stock_doc/getSettingCheckStockConsumableListByStoreId`;
        return axiosClient.get(url, { params });
    },
    updateSettingCheckStockConsumableByStoreId: (
        params: IUpdateSettingCheckStockConsumableByStoreId
    ) => {
        const url = `/check_stock_doc/updateSettingCheckStockConsumableByStoreId`;
        return axiosClient.post(url, params);
    },
    getInitCheckStockConsumableListByStoreId: (
        params: IGetInitCheckStockConsumableListByStoreId
    ) => {
        const url = `/check_stock_doc/getInitCheckStockConsumableListByStoreId`;
        return axiosClient.get(url, { params });
    },
    getInitCheckStockProductListByStoreId: (params: IGetInitCheckStockConsumableListByStoreId) => {
        const url = `/check_stock_doc/getInitCheckStockProductListByStoreId`;
        return axiosClient.get(url, { params });
    },
};
