import { useTranslation } from "react-i18next";
import { StyledCancelButton, StyledSubmitButton } from "../shared";
import styled from "styled-components";
import { Col, Row, Switch } from "antd";
import { SharedBranchModal } from "../shared/Branch Modal";
import { useDispatch } from "react-redux";

interface IProps {
    showPopup: boolean;
    handleClose: () => void;
    allStore: any[];
    selectStore?: any[];
    setSelectStore: (arr: string[]) => void;
}

export const ComponentPromotionExcludeModal = (props: IProps) => {
    const dispatch = useDispatch();
    const { showPopup, handleClose, allStore, selectStore, setSelectStore } = props;
    const { t } = useTranslation();
    const toggleStore = (sId) => {
        if (selectStore) {
            let arr = [...selectStore];
            const index = arr.findIndex((x) => x.store_id === sId);
            if (index !== -1) {
                arr.splice(index, 1);
            } else {
                arr.push({ store_id: sId });
            }
            setSelectStore([...arr]);
        }
    };

    return (
        <SharedBranchModal
            showPopup3={showPopup}
            handleClose={handleClose}
            title={t("page.exclude.modal_title")}
            width={750}
        >
            <br />
            <StyledModalExclude>
                <Row className="table-head">
                    <Col span={12}>
                        <p>{t("page.exclude.table_branch")}</p>
                    </Col>
                    <Col span={12} className="toggle-side">
                        {t("page.branches_include_from_this_promotion")}
                    </Col>
                </Row>
                {allStore?.map((sItem, sIndex) => {
                    return (
                        <Row className="table-row" key={sIndex}>
                            <Col span={12}>
                                <p>{sItem?.store_name}</p>
                            </Col>
                            <Col span={12} className="toggle-side">
                                <Switch
                                    className="toggle-item"
                                    checked={
                                        selectStore &&
                                        selectStore.some((x) => x.store_id === sItem?._id)
                                    }
                                    onClick={() => toggleStore(sItem?._id)}
                                />
                            </Col>
                        </Row>
                    );
                })}
            </StyledModalExclude>
            <div className="btn-layout" style={{ display: "flex", gap: "20px" }}>
                <StyledCancelButton
                    type="sub"
                    text={t("page.cancel")}
                    htmlType="button"
                    onClick={handleClose}
                />
                <StyledSubmitButton
                    type="default"
                    text={t("page.save")}
                    htmlType="button"
                    onClick={handleClose}
                    style={{ marginRight: 0 }}
                />
            </div>
        </SharedBranchModal>
    );
};

const StyledModalExclude = styled.div`
    /* max-height: 500px; */
    height: 400px;
    max-height: 400px;
    overflow-y: auto;
    margin-bottom: 50px;
    .toggle-side {
        text-align: right;
    }
    .table-head {
        font-size: 18px;
        font-weight: 600;
        border: 1px solid #f7f7f8;
        padding: 10px;
        padding-bottom: 0px;
    }
    .table-row {
        border: 1px solid #f7f7f8;
        p {
            margin: 15px;
        }
        .toggle-item {
            margin-top: 15px;
            margin-right: 20px;
        }
    }
    .box {
        .checkbox {
            padding: 20px;
        }
        .addon-item {
            width: 100%;
            background: #f7f7f8;
            border-radius: 6px;
            padding: 20px;
            margin-bottom: 30px;
            .title {
                font-weight: 800;
                font-size: 20px;
                span {
                    background-color: #ffd9de;
                    color: #6d1923;
                    padding: 5px 10px;
                    margin-left: 15px;
                    font-weight: 800;
                    font-size: 12px;
                    border-radius: 50px;
                }
            }
        }
    }
`;
