import { useState, useRef, useEffect } from "react";
import {
    ComponentContactSortIcon,
    StyledTable,
    ComponentEmptyData,
    StyledTableButtonTop,
    ComponentTableSearch,
    ComponentRowService,
    ComponentRowStore,
} from "@components";
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    TouchSensor,
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    rectSortingStrategy,
    //@ts-ignore
} from "@dnd-kit/sortable";
import { Row, Col, Pagination, Button } from "antd";
import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";
import {
    enumSortBy,
    enumContactSortFields,
    PAGINATION,
    PAGE_START,
    enumNewsStatus,
    INITIAL_LIST_PARAMS,
    PATH_PRODUCT_CREATE,
    PATH_PERMISSION_POS,
} from "@configs";
import { IPaginationNext, INews, IDataexample } from "@interfaces";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth, selectContact, selectOrg, setLoading } from "@redux";
import { useNotify } from "@utils";
import { useHistory } from "react-router";
import axios from "axios";
import styled from "styled-components";
import { MoreHorizontal } from "react-feather";
import { ComponentRowPermission } from "src/Components/table/RowPermission";
import Search from "antd/lib/input/Search";
import { permissionPosAPI } from "@api";

const INITAL_PAGE = {
    xpage: PAGE_START,
    xlimit: PAGINATION,
};

export const PagePermissionPos = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const search_params = useSelector(selectContact).paramsContact;
    const [params, setParams] = useState(search_params);
    const org_id = useSelector(selectAuth).userInfo?.merchant._id;
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const categories = useSelector(selectOrg).category;
    const [textSearch, setTextSearch] = useState<string>("");

    const [searchDate, setSearchDate] = useState<string[]>([]);
    const [catSearch, setCatSearch] = useState<string>("");
    const [status, setStatus] = useState<enumNewsStatus>(enumNewsStatus.ALL);
    const [filterStatus, setFilterStatus] = useState<boolean>(true);
    const [pagination, setPagination] = useState<IPaginationNext>(INITAL_PAGE);
    const [services, setServices] = useState<IDataexample[]>([]);
    const [stores, setStores] = useState<any>([]);
    const [total, setTotal] = useState<number>(0);
    const { success, error } = useNotify();
    const history = useHistory();
    useEffect(() => {
        fetchNews();
    }, []);

    const fetchNews = async () => {
        dispatch(setLoading(true));
        try {

            const getAllStores = await permissionPosAPI.getList({
                organization_code: orgCode,
            });
            // for(let i=0; i <getAllStores.data.result.length; i++) {
            //     setStores([...stores, stores[i]])
            // }
            // console.log(getAllStores.data.result);
            setStores(getAllStores.data.result);
            // setServices(data);
            success("api response");
        } catch (err: any) {
            error(err.msg);
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    // const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const value = e.target.value;
    //     if (typingTimeoutRef.current) {
    //         clearTimeout(typingTimeoutRef.current);
    //     }
    //     typingTimeoutRef.current = window.setTimeout(() => {
    //         const newSearchParams = { ...params, page: 1, search: value };
    //         setParams(newSearchParams);
    //     }, 300);
    // };

    const handleSort = (sortField: enumContactSortFields, sortBy: enumSortBy) => {
        const newSortParams = { ...params, sortField, sortBy };
        setParams(newSortParams);
        // fetchContacts(newSortParams);
    };
    const callbackGetList = () => {
        fetchNews();
    };
    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            const swapItem = (items: IDataexample[]) => {
                const oldIndex = items.findIndex((item) => JSON.stringify(item) === active.id);
                const newIndex = items.findIndex((item) => JSON.stringify(item) === over.id);
                return arrayMove(items, oldIndex, newIndex);
            };
            setServices(swapItem);
        }
    };
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
        useSensor(TouchSensor)
    );
    const handleSearchCategory = (value) => {
        setCatSearch(value);
    };
    const onChangeDateRange = (dates, dateStrings: [string, string]) => {
        setSearchDate(dateStrings);
    };
    const handlePagination = (page) => {
        setPagination(page);
    };
    const updateService = async (data) => {
        dispatch(setLoading(true));
        try {
            // api call in here
            await null;
            // const response = await newsAPI.getList();
            // setNews();
            // setTotal(response.data.total);
            success("api response");
        } catch (err: any) {
            error(err.msg);
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };
    return (
        <StyledTable>
            <div className="page-layout">
                <div className="page-header-layout">
                    <div className="page-header" style={{ padding: "1.5rem 0" }}>
                        <div
                            className="title"
                            style={{ flexDirection: "column", alignItems: "baseline" }}
                        >
                            <h3>{t("page.permission_pos")}</h3>
                            <p style={{ fontWeight: 400 }}>
                                {t("page.select_store_for_add_new_account_members")}
                            </p>
                        </div>
                        <div className="flex-header">
                            <Search
                                placeholder={t("page.search_store")}
                                onSearch={(value) => setTextSearch(value)}
                                style={{ width: 200, height: 50, border: "none", paddingRight: 4 }}
                                onChange={(event) => {
                                    setTextSearch(event.target.value);
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="page-table-layout">
                    <div className="table">
                        <ScrollContainer vertical={false}>
                            <StylesPermission>
                                <div className="table_ground">
                                    <div className="table-body">
                                        <div className="table-header">
                                            <Row
                                                gutter={24}
                                                type="flex"
                                                justify="space-between"
                                                align="middle"
                                                className="header-row"
                                            >
                                                <Col className="col-item" span={9}>
                                                    <p>{t("page.store")}</p>
                                                    <div className="filter-button">
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.ASC}
                                                            sortField={enumContactSortFields.POINTS}
                                                            searchParams={params}
                                                        />
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.DESC}
                                                            sortField={enumContactSortFields.POINTS}
                                                            searchParams={params}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col className="col-item" span={5}>
                                                    <p>{t("page.code")}</p>
                                                    <div className="filter-button">
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.ASC}
                                                            sortField={enumContactSortFields.POINTS}
                                                            searchParams={params}
                                                        />
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.DESC}
                                                            sortField={enumContactSortFields.POINTS}
                                                            searchParams={params}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col className="col-item" span={5}>
                                                    <p>{t("page.total_employees")}</p>
                                                    <div className="filter-button">
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.ASC}
                                                            sortField={
                                                                enumContactSortFields.CREATED_AT
                                                            }
                                                            searchParams={params}
                                                        />
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.DESC}
                                                            sortField={
                                                                enumContactSortFields.CREATED_AT
                                                            }
                                                            searchParams={params}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col
                                                    className="col-item"
                                                    span={5}
                                                    style={{ placeContent: "end" }}
                                                >
                                                    <p>{t("page.manage")}</p>
                                                </Col>
                                            </Row>
                                        </div>
                                        <DndContext
                                            sensors={sensors}
                                            collisionDetection={closestCenter}
                                            onDragEnd={handleDragEnd}
                                        >
                                            <SortableContext
                                                items={stores.map((item) => JSON.stringify(item))}
                                                strategy={rectSortingStrategy}
                                            >
                                                {stores.length !== 0 ? (
                                                    <div className="data-table">
                                                        {stores.map((item, index) => (
                                                            <ComponentRowPermission
                                                                key={index}
                                                                data={item}
                                                                itemJson={JSON.stringify(item)}
                                                                categories={categories}
                                                                handleEdit={updateService}
                                                                callbackGetList={callbackGetList}
                                                                index={index}
                                                                stores={stores}
                                                            />
                                                        ))}
                                                        <div
                                                            style={{ width: "100%", height: 10 }}
                                                        ></div>
                                                    </div>
                                                ) : (
                                                    <ComponentEmptyData />
                                                )}
                                            </SortableContext>
                                        </DndContext>
                                    </div>
                                </div>
                            </StylesPermission>
                        </ScrollContainer>
                    </div>
                </div>

                <div className="page-bottom">
                    {total > 0 && (
                        <div className="pagination">
                            <Pagination
                                defaultCurrent={INITIAL_LIST_PARAMS.page || 1}
                                pageSize={PAGINATION}
                                total={total}
                                onChange={handlePagination}
                                current={params.page}
                            />
                        </div>
                    )}
                </div>
            </div>
        </StyledTable>
    );
};

export const StylesPermission = styled.div`
    .table-header {
        padding: 12px 34px 12px 66px !important;
    }
`;
