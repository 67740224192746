import { useState, useEffect } from "react";
import {
    ComponentContactSortIcon,
    StyledTable,
    ComponentEmptyData,
    StyledTableButtonTop,
    ComponentRowCategory,
    ComponentProductDrawer,
} from "@components";
import { Row, Col, Pagination } from "antd";
import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";
import {
    enumSortBy,
    enumContactSortFields,
    PAGINATION,
    PAGE_START,
    INITIAL_LIST_PARAMS,
} from "@configs";
import { IPaginationNext } from "@interfaces";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth, selectContact, selectOrg, setLoading } from "@redux";
import { useNotify } from "@utils";
import { useHistory } from "react-router";
import styled from "styled-components";
import { orgApi } from "@api";

const INITAL_PAGE = {
    xpage: PAGE_START,
    xlimit: PAGINATION,
};

export const PageCategoryList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const search_params = useSelector(selectContact).paramsContact;
    const [params, setParams] = useState(search_params);
    const org_id = useSelector(selectAuth).userInfo?.merchant._id;
    const categories = useSelector(selectOrg).category;
    const [showModal, setShowModal] = useState<boolean>(false);
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const [pagination, setPagination] = useState<IPaginationNext>(INITAL_PAGE);
    const [stores, setStores] = useState<any>([]);
    const [total, setTotal] = useState<number>(0);
    const [editItem, setEditItem] = useState();
    const { success, error } = useNotify();
    const history = useHistory();

    const fetchNews = async () => {
        dispatch(setLoading(true));
        try {
            const getAllStores = await orgApi.getCat({
                organization_code: orgCode,
            });
            setStores(getAllStores.data.result);
        } catch (err: any) {
            error(err.msg);
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleSort = (sortField: enumContactSortFields, sortBy: enumSortBy) => {
        const newSortParams = { ...params, sortField, sortBy };
        setParams(newSortParams);
    };
    const callbackGetList = () => {
        fetchNews();
    };
    const handlePagination = (page) => {
        setPagination(page);
    };
    const handleEditItem = (editItem) => {
        setShowModal(true);
        setEditItem(editItem);
    };
    const handleCloseModal = () => {
        setShowModal(false);
        setEditItem(undefined);
    };

    useEffect(() => {
        fetchNews();
    }, [categories, showModal]);

    return (
        <StyledTable>
            <div className="page-layout">
                <div className="page-header-layout">
                    <div className="page-header" style={{ padding: "1.5rem 0" }}>
                        <div>
                            <h3>
                                {t("page.caregories_products")}{" "}
                                <span style={{ fontSize: 14, color: "#A5A5A5" }}>
                                    {stores?.length} {t("page.category")}
                                </span>
                            </h3>
                        </div>
                        <div className="flex-header">
                            <StyledTableButtonTop
                                type="danger"
                                size="default"
                                text={t("page.add_category")}
                                onClick={() => setShowModal(!showModal)}
                                className="add-btn"
                                icon="plus"
                                style={{
                                    borderRadius: "4px",
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="page-table-layout">
                    <div className="table">
                        <ScrollContainer vertical={false}>
                            <Styless>
                                <div className="table_ground">
                                    <div className="table-body">
                                        <div className="table-header">
                                            <Row
                                                gutter={24}
                                                type="flex"
                                                justify="space-between"
                                                align="middle"
                                                className="header-row"
                                            >
                                                <Col
                                                    className="col-item"
                                                    span={12}
                                                    style={{ padding: "0" }}
                                                >
                                                    <p>{t("page.category_name")}</p>
                                                    <div className="filter-button">
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.ASC}
                                                            sortField={enumContactSortFields.POINTS}
                                                            searchParams={params}
                                                        />
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.DESC}
                                                            sortField={enumContactSortFields.POINTS}
                                                            searchParams={params}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col
                                                    className="col-item"
                                                    span={6}
                                                    style={{ padding: "0" }}
                                                >
                                                    <p>{t("page.storefront_display")}</p>
                                                    <div className="filter-button">
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.ASC}
                                                            sortField={
                                                                enumContactSortFields.CREATED_AT
                                                            }
                                                            searchParams={params}
                                                        />
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.DESC}
                                                            sortField={
                                                                enumContactSortFields.CREATED_AT
                                                            }
                                                            searchParams={params}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col
                                                    className="col-item"
                                                    span={6}
                                                    style={{ placeContent: "end" }}
                                                >
                                                    <p>{t("page.manage")}</p>
                                                </Col>
                                            </Row>
                                        </div>
                                        {stores.length !== 0 ? (
                                            <div className="data-table">
                                                {stores.map((item, index) => (
                                                    <ComponentRowCategory
                                                        key={index}
                                                        data={item}
                                                        itemJson={JSON.stringify(item)}
                                                        categories={categories}
                                                        callbackGetList={callbackGetList}
                                                        editItem={handleEditItem}
                                                        index={index}
                                                        stores={stores}
                                                        setStores={setStores}
                                                    />
                                                ))}
                                                <div style={{ width: "100%", height: 10 }}></div>
                                            </div>
                                        ) : (
                                            <ComponentEmptyData />
                                        )}
                                    </div>
                                </div>
                            </Styless>
                        </ScrollContainer>
                    </div>
                </div>

                <div className="page-bottom">
                    {total > 0 && (
                        <div className="pagination">
                            <Pagination
                                defaultCurrent={INITIAL_LIST_PARAMS.page || 1}
                                pageSize={PAGINATION}
                                total={total}
                                onChange={handlePagination}
                                current={params.page}
                            />
                        </div>
                    )}
                </div>
            </div>
            <ComponentProductDrawer
                showModal={showModal}
                editItem={editItem}
                handleClose={handleCloseModal}
            />
        </StyledTable>
    );
};

export const Styless = styled.div`
    .table-header {
        padding: 18px 34px 12px 66px !important;
    }
`;
