import { Drawer, Avatar, Divider, Menu } from "antd";

import { useDispatch, useSelector } from "react-redux";
import { MenuMode, MenuTheme } from "antd/lib/menu";

import {
    StyledSidebar,
    HomeOutline,
    SettingOutLine,
    GuideOutline,
    PricingFeature,
    LogoutOutline,
    SharedComponentDrawer,
} from "@components";

import {
    selectApp,
    setMobileDrawer,
    setProfileDrawer,
    useAppSelector,
    selectTheme,
    selectAuth,
    logout,
} from "@redux";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { enumDrawerPlacement } from "@configs";

export const ComponentProfileSidebar = () => {
    const dispatch = useDispatch();

    const app = useAppSelector(selectApp);
    const { userInfo } = useSelector(selectAuth);
    const { t } = useTranslation();
    const history = useHistory();
    const handleSignOut = () => {
        dispatch(logout());
        setTimeout(() => {
            history.push("/");
        }, 1000);
    };
    const handleChangeSelectAccount = () => {
        dispatch(setProfileDrawer(false));
    };
    const handleEditProfile = () => {
        history.push("/");
        dispatch(setProfileDrawer(false));
    };
    const handleSelectFeature = () => {
        history.push("/");
        dispatch(setProfileDrawer(false));
    };
    return (
        <SharedComponentDrawer
            title={""}
            placement={enumDrawerPlacement.RIGHT}
            onClose={() => dispatch(setProfileDrawer(!app.profileDrawer))}
            visible={app.profileDrawer}
            width={275}
        >
            <StyledSidebar>
                <div
                    style={{
                        overflow: "hidden",
                        flex: "1 1 auto",
                        flexDirection: "column",
                        display: "flex",
                        height: "100vh",
                    }}
                >
                    <div className="profile-header">
                        <div className="profile-image">
                            <Avatar
                                size={84}
                                className="avatar"
                                src={userInfo?.avatar || "/user-avatar.png"}
                            />
                        </div>
                        <div className="profile-info">
                            <p className="profile-name">{userInfo?.fullName}</p>
                            <p className="profile-role">{userInfo?.role?.name}</p>
                            <p className="profile-email">{userInfo?.email}</p>
                        </div>
                    </div>

                    <Divider className="profile-divider" type="horizontal" />
                    <div className="sidebar-layout">
                        <Avatar className="future-avatar" src={""} />
                        <div className="email-layout">
                            <h4>{"User Name"}</h4>
                            {/* <p>{userInfo?.email || ""}</p> */}
                        </div>
                    </div>
                    <Divider className="profile-divider" type="horizontal" />
                    <Menu className="profile-layout">
                        <Menu.Item
                            onClick={handleChangeSelectAccount}
                            style={{
                                fontWeight: 400,
                                fontSize: "16px",
                                cursor: "pointer",
                                color: "#000000",
                            }}
                        >
                            <div className="item-text">
                                <HomeOutline />
                                <span>{t("page.select_account")}</span>
                            </div>
                        </Menu.Item>
                        <Menu.Item
                            onClick={handleEditProfile}
                            style={{
                                fontWeight: 400,
                                fontSize: "16px",
                                cursor: "pointer",
                                color: "#000000",
                            }}
                        >
                            <div className="item-text">
                                <SettingOutLine />
                                <span>{t("page.sidebar.edit_profile")}</span>
                            </div>
                        </Menu.Item>
                        <Menu.Item
                            style={{
                                fontWeight: 400,
                                fontSize: "16px",
                                cursor: "pointer",
                                color: "#000000",
                            }}
                        >
                            <div className="item-text">
                                <GuideOutline />
                                <span>{t("page.user_guide")}</span>
                            </div>
                        </Menu.Item>
                        <Menu.Item
                            onClick={handleSelectFeature}
                            style={{
                                fontWeight: 400,
                                fontSize: "16px",
                                cursor: "pointer",
                                color: "#000000",
                            }}
                        >
                            <div className="item-text">
                                <PricingFeature />
                                <span>{t("page.sidebar.pricing_features")}</span>
                            </div>
                        </Menu.Item>
                        <Menu.Item
                            style={{
                                fontWeight: 400,
                                fontSize: "16px",
                                cursor: "pointer",
                                color: "#F22F46",
                            }}
                            onClick={handleSignOut}
                        >
                            <div className="item-text">
                                <LogoutOutline />
                                <span>{t("page.manage_account.logout")}</span>
                            </div>
                        </Menu.Item>
                    </Menu>
                </div>
            </StyledSidebar>
        </SharedComponentDrawer>
    );
};
