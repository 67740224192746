import { ISvg } from "@interfaces";
export const IconProductManagement = (props: ISvg) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.size || "50"}
            height={props.size || "62"}
            viewBox="0 0 50 62"
            fill="none"
        >
            <path
                d="M5 18.5714C4.60551 18.5714 4.28571 18.8912 4.28571 19.2857V56.4286C4.28571 56.8231 4.60551 57.1428 5 57.1428H45C45.3946 57.1428 45.7143 56.8231 45.7143 56.4286V19.2857C45.7143 18.8912 45.3946 18.5714 45 18.5714H5ZM0 19.2857C0 16.5243 2.23857 14.2857 5 14.2857H45C47.7614 14.2857 50 16.5243 50 19.2857V56.4286C50 59.19 47.7614 61.4286 45 61.4286H5C2.23857 61.4286 0 59.19 0 56.4286V19.2857Z"
                fill={props.color || "black"}
            />
            <path
                d="M25 4.28571C20.6606 4.28571 17.1429 7.80349 17.1429 12.1429V22.1429C17.1429 23.3263 16.1835 24.2857 15 24.2857C13.8165 24.2857 12.8571 23.3263 12.8571 22.1429V12.1429C12.8571 5.43654 18.2937 0 25 0C31.7063 0 37.1429 5.43654 37.1429 12.1429V22.1429C37.1429 23.3263 36.1834 24.2857 35 24.2857C33.8166 24.2857 32.8571 23.3263 32.8571 22.1429V12.1429C32.8571 7.80349 29.3394 4.28571 25 4.28571Z"
                fill={props.color || "black"}
            />
        </svg>
    );
};
