import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { enumValidation } from "@configs";

export const YupCreateAddOn = () => {
    //page hook
    const { t } = useTranslation();

    return {
        addon_name: Yup.string()
            .required(
                t("validation.required", {
                    name: t("page.name_title_add_on"),
                })
            )
            .max(
                enumValidation.LENGTH_100,
                t("validation.max", {
                    name: t("page.name_title_add_on"),
                    number: enumValidation.MAX_TITLE_INPUT,
                })
            ),
        option_list: Yup.array().of(
            Yup.object().shape({
                option_name: Yup.string()
                    .required(
                        t("validation.required", {
                            name: t("page.option_name"),
                        })
                    )
                    .max(
                        enumValidation.LENGTH_100,
                        t("validation.max", {
                            name: t("page.option_name"),
                            number: enumValidation.MAX_TITLE_INPUT,
                        })
                    ),
            })
        ),
    };
};
