import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Col, Row, Menu } from "antd";
import moment from "moment";

import { IConplaint, INews } from "@interfaces";
import { newsAPI } from "@api";
import { dateFormat, enumNewsType, enumProcessStatus, PATH_COMPLAINT } from "@configs";
import { selectAuth } from "@redux";
import { useSelector } from "react-redux";
import { ShareButton, IconDrag } from "@components";
import { showErrorMessage, useNotify } from "@utils";
import { useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
interface IProps {
    data: IConplaint;
    itemJson: string;
    handleEdit: (status: boolean, data: INews) => void;
    callbackGetList: () => void;
    index: number;
    newType?: enumNewsType;
    // categories: INewsCategory[];
}

export const ComponentRowConpliant = (props: IProps) => {
    //hooks
    const { t } = useTranslation();
    const { success, error } = useNotify();
    const history = useHistory();
    const organization_id = useSelector(selectAuth).auth?.organization_data?._id;
    //props
    const { data, handleEdit, callbackGetList, itemJson, newType } = props;
    const [show, setShowModal] = useState(false);

    const { attributes, listeners, setNodeRef, transform } = useSortable({
        id: itemJson,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
    };

    const catName = () => {
        // const item = props.categories.filter((item) => item._id === props.data.news_category_id);
        // if (item.length > 0) {
        //     return item[0].category_name;
        // }
        return "...";
    };

    const handleDelete = async () => {
        try {
            await newsAPI.delete({ organization_id: organization_id || "", news_id: data._id });
            callbackGetList();
            setShowModal(false);
            success(t("message.delete.success"));
        } catch (err: any) {
            if (err) {
                error(err.msg);
            } else error(t("message.delete.fail"));
        }
    };

    const handleRemove = () => {
        try {
            // const payload = {
            //     organization_id: data.organization_id,
            //     title: data.title,
            //     detail: data.detail,
            //     image_url: data.image_url,
            //     is_hot_news: false,
            //     news_category_id: data.news_category_id,
            //     date_from: data.date_from,
            //     date_to: data.date_to,
            //     is_enable: data.is_enable,
            //     news_id: data._id,
            // };
            // await newsAPI.update(payload);
            callbackGetList();
            success(t("message.duplicate.success"));
        } catch (err: any) {
            if (err.response) {
                error(showErrorMessage(err.response));
            } else error(t("message.duplicate.fail"));
        }
    };

    const showModal = () => {
        setShowModal(!show);
        // Modal.confirm({
        //     title: t("message.delete_title"),
        //     icon: <ExclamationCircleOutlined />,
        //     content: t("message.delete_confirm", {
        //         returnObjects: true,
        //         name: t("object._contact"),
        //     }),
        //     okText: t("page.delete"),
        //     cancelText: t("page.cancel"),
        //     centered: true,
        //     onOk: handleDelete,
        // });
    };

    const menu = (
        <Menu>
            {newType && newType === enumNewsType.HOT_NEWS && (
                <Menu.Item key={2} onClick={handleRemove}>
                    <div className="delete-btn">{t("page.remove")}</div>
                </Menu.Item>
            )}
            <Menu.Divider />
            <Menu.Item key={1} onClick={handleDelete}>
                <div className="duplicate-btn">{t("page.delete")}</div>
            </Menu.Item>
        </Menu>
    );
    const handleUpdateStatus = (e: boolean) => {
        // handleEdit(e, data);
    };
    const getProcessClass = (value: enumProcessStatus) => {
        if (value === enumProcessStatus.PENDING) return "status-button warning";
        if (value === enumProcessStatus.DOING) return "status-button doing";
        if (value === enumProcessStatus.COMPLETE) return "status-button complete";
        if (value === enumProcessStatus.CANCELED) return "status-button canceled";
    };
    return (
        <div className="table-row" ref={setNodeRef} style={style}>
            <div className="drag-icon" {...attributes} {...listeners}>
                <IconDrag />
            </div>
            <Row
                style={{ height: "100%" }}
                gutter={24}
                type="flex"
                align="middle"
                justify="space-between"
            >
                <Col className="col-item" span={5}>
                    <p>{data.complainer.name || t("page.empty_text")}</p>
                </Col>
                <Col className="col-item" span={3}>
                    <p>{data.header || t("page.empty_text")}</p>
                </Col>
                <Col className="col-item m-member-code" xs={10} sm={10} lg={3} md={3}>
                    <p className="visible-md">
                        {moment(data.created_date).format(dateFormat) || t("page.empty_text")}
                    </p>
                </Col>
                <Col className="col-item visible-md" span={3}>
                    <div className={getProcessClass(data.status)}>
                        {t(`page.${data.status}`) || t("page.empty_text")}
                    </div>
                </Col>
                <Col className="col-item visible-md" span={3}>
                    <p>{data.petitioner.name || t("page.empty_text")}</p>
                </Col>
                <Col className="col-item" span={3}>
                    <ShareButton
                        type="default"
                        size="large"
                        className="ant-btn-primary"
                        text={t("page.carry_out")}
                        onClick={() => history.push(PATH_COMPLAINT + "/" + data._id)}
                    />
                </Col>
            </Row>
        </div>
    );
};
