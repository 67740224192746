import { ISvg } from "@interfaces";

export const Step2Icon = (props: ISvg) => (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="56" height="56" rx="28" fill={props.color || "#0263E0"} />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.6931 15.0044C32.6074 14.9145 32.5038 14.8434 32.389 14.796C32.2741 14.7485 32.1506 14.7257 32.0264 14.7289H23.9731C23.8479 14.7281 23.7238 14.752 23.6078 14.7993C23.4919 14.8466 23.3865 14.9163 23.2975 15.0044C23.1225 15.1824 23.0237 15.4215 23.022 15.6711V24.2044H32.9775V15.6711C32.9725 15.4204 32.8706 15.1815 32.6931 15.0044ZM28.6753 18.56H27.3153C27.0801 18.5372 26.8617 18.4276 26.7029 18.2526C26.544 18.0776 26.456 17.8497 26.456 17.6133C26.456 17.377 26.544 17.1491 26.7029 16.9741C26.8617 16.7991 27.0801 16.6895 27.3153 16.6667H28.6753C28.9106 16.6895 29.1289 16.7991 29.2877 16.9741C29.4466 17.1491 29.5346 17.377 29.5346 17.6133C29.5346 17.8497 29.4466 18.0776 29.2877 18.2526C29.1289 18.4276 28.9106 18.5372 28.6753 18.56Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.0444 26.1067C17.9199 26.1055 17.7964 26.129 17.6811 26.1758C17.5657 26.2227 17.4608 26.2919 17.3724 26.3795C17.2839 26.4671 17.2137 26.5714 17.1658 26.6863C17.1179 26.8012 17.0933 26.9244 17.0933 27.0489V34.64H27.0488V26.1067H18.0444ZM22.7466 29.8933H21.3955C21.2635 29.9061 21.1302 29.8912 21.0043 29.8494C20.8784 29.8077 20.7626 29.7401 20.6644 29.6509C20.5662 29.5618 20.4877 29.4531 20.434 29.3318C20.3803 29.2105 20.3525 29.0793 20.3525 28.9467C20.3525 28.814 20.3803 28.6828 20.434 28.5616C20.4877 28.4403 20.5662 28.3316 20.6644 28.2424C20.7626 28.1533 20.8784 28.0856 21.0043 28.0439C21.1302 28.0021 21.2635 27.9872 21.3955 28H22.7466C22.8786 27.9872 23.0119 28.0021 23.1378 28.0439C23.2637 28.0856 23.3795 28.1533 23.4777 28.2424C23.5759 28.3316 23.6544 28.4403 23.7081 28.5616C23.7618 28.6828 23.7895 28.814 23.7895 28.9467C23.7895 29.0793 23.7618 29.2105 23.7081 29.3318C23.6544 29.4531 23.5759 29.5618 23.4777 29.6509C23.3795 29.7401 23.2637 29.8077 23.1378 29.8494C23.0119 29.8912 22.8786 29.9061 22.7466 29.8933Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M38.6667 26.3822C38.5792 26.2947 38.4752 26.2254 38.3608 26.1781C38.2464 26.1308 38.1238 26.1065 38.0001 26.1066H28.9512V34.64H38.9067V27.0489C38.9138 26.8043 38.828 26.5661 38.6667 26.3822ZM34.6401 29.8933H33.2445C33.1125 29.9061 32.9792 29.8912 32.8533 29.8494C32.7274 29.8077 32.6116 29.74 32.5134 29.6509C32.4152 29.5617 32.3367 29.453 32.283 29.3317C32.2293 29.2105 32.2016 29.0793 32.2016 28.9466C32.2016 28.814 32.2293 28.6828 32.283 28.5615C32.3367 28.4402 32.4152 28.3315 32.5134 28.2424C32.6116 28.1532 32.7274 28.0856 32.8533 28.0438C32.9792 28.0021 33.1125 27.9872 33.2445 28H34.5956C34.7276 27.9872 34.8609 28.0021 34.9868 28.0438C35.1127 28.0856 35.2285 28.1532 35.3267 28.2424C35.4249 28.3315 35.5034 28.4402 35.5571 28.5615C35.6108 28.6828 35.6386 28.814 35.6386 28.9466C35.6386 29.0793 35.6108 29.2105 35.5571 29.3317C35.5034 29.453 35.4249 29.5617 35.3267 29.6509C35.2285 29.74 35.1127 29.8077 34.9868 29.8494C34.8609 29.8912 34.7276 29.9061 34.5956 29.8933H34.6401Z"
            fill="white"
        />
        <path
            d="M41.2712 36.5333H14.729C14.597 36.5205 14.4637 36.5355 14.3378 36.5772C14.2119 36.6189 14.0961 36.6866 13.9979 36.7757C13.8997 36.8649 13.8212 36.9736 13.7675 37.0949C13.7138 37.2162 13.686 37.3473 13.686 37.48C13.686 37.6126 13.7138 37.7438 13.7675 37.8651C13.8212 37.9864 13.8997 38.0951 13.9979 38.1842C14.0961 38.2734 14.2119 38.341 14.3378 38.3828C14.4637 38.4245 14.597 38.4395 14.729 38.4266H17.5734V40.3289C17.5963 40.5641 17.7058 40.7824 17.8809 40.9413C18.0559 41.1001 18.2837 41.1881 18.5201 41.1881C18.7564 41.1881 18.9843 41.1001 19.1593 40.9413C19.3343 40.7824 19.4439 40.5641 19.4668 40.3289V38.4266H36.5334V40.3289C36.5563 40.5641 36.6658 40.7824 36.8409 40.9413C37.0159 41.1001 37.2437 41.1881 37.4801 41.1881C37.7164 41.1881 37.9443 41.1001 38.1193 40.9413C38.2943 40.7824 38.4039 40.5641 38.4268 40.3289V38.4266H41.2712C41.4032 38.4395 41.5365 38.4245 41.6624 38.3828C41.7883 38.341 41.9041 38.2734 42.0023 38.1842C42.1005 38.0951 42.179 37.9864 42.2327 37.8651C42.2864 37.7438 42.3142 37.6126 42.3142 37.48C42.3142 37.3473 42.2864 37.2162 42.2327 37.0949C42.179 36.9736 42.1005 36.8649 42.0023 36.7757C41.9041 36.6866 41.7883 36.6189 41.6624 36.5772C41.5365 36.5355 41.4032 36.5205 41.2712 36.5333Z"
            fill="white"
        />
    </svg>
);
