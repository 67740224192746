export const SelectedProductBadge = () => (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle opacity="0.64" cx="11" cy="10.5078" r="10.5" fill="white" />
        <circle opacity="0.9" cx="11" cy="10.5078" r="9" fill="white" />
        <path
            d="M14.875 8.32031L9.71875 13.4766L7.375 11.1328"
            stroke="#6CD14E"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
