import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { StyledNewDetail } from "@components";
import { PAGE_START, PAGINATION, PATH_NEWS, PATH_RECIPE_ASSIGN } from "@configs";
import { ModuleAssignStockForm } from "@modules";
import { useNotify, showErrorMessage } from "@utils";
import { setLoading, selectAuth, selectOrg } from "@redux";
import { productAPI } from "@api";

const INITAL_PAGE = {
    xpage: PAGE_START,
    xlimit: PAGINATION,
};

export const PageAssignStockCreate = () => {
    const { id } = useParams<{ id: string }>();
    const org_id = useSelector(selectAuth).userInfo?.merchant?._id;
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const categories = useSelector(selectOrg).category;
    const dispatch = useDispatch();
    const history = useHistory();
    const { warning, error } = useNotify();
    const { t } = useTranslation();
    // page state
    const [stock, setStock] = useState<any>();
    // variable
    const pathName = history.location.pathname;

    useEffect(() => {
        // fetchNews();
    }, []);

    const fetchNews = async () => {
        dispatch(setLoading(true));
        if (pathName !== PATH_RECIPE_ASSIGN) {
            try {
                const getVariant = await productAPI.getVariant({
                    organization_id: org_id,
                    variant_id: id,
                });
                setStock(getVariant.data.result);
                dispatch(setLoading(false));
            } catch (err: any) {
                if (err.response) {
                    warning(showErrorMessage(err.response));
                    dispatch(setLoading(false));
                } else warning(t("message.not_found", { name: t("object._reward") }));
                history.push(PATH_NEWS);
            } finally {
                dispatch(setLoading(false));
            }
        }
    };

    return (
        <StyledNewDetail>
            <ModuleAssignStockForm />
        </StyledNewDetail>
    );
};
