import { useState, useEffect } from "react";
import {
    ComponentContactSortIcon,
    StyledTable,
    ComponentEmptyData,
    StyledTableButtonTop,
    ComponentRowRolePOS,
} from "@components";
import { Row, Col, Pagination } from "antd";
import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";
import {
    enumSortBy,
    enumContactSortFields,
    PAGINATION,
    PAGE_START,
    INITIAL_LIST_PARAMS,
    PATH_ROLES_PERMISSIONS,
} from "@configs";
import { IPaginationNext, IGetPosRolesExample } from "@interfaces";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth, selectContact, setLoading } from "@redux";
import { useNotify } from "@utils";
import { useHistory } from "react-router";
import styled from "styled-components";
import { rolePosAPI } from "@api";
import { ComponentTableSearchRolesPermission } from "src/Components/TableSearchRolesPermission";

const INITAL_PAGE = {
    xpage: PAGE_START,
    xlimit: PAGINATION,
};

export const PageRolesPermissionsList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const search_params = useSelector(selectContact).paramsContact;
    const [params, setParams] = useState(search_params);
    const org_id = useSelector(selectAuth).userInfo?.merchant?._id;
    const [textSearch, setTextSearch] = useState<string>("");
    const [pagination, setPagination] = useState<IPaginationNext>(INITAL_PAGE);
    const [posRoles, setPosRoles] = useState<IGetPosRolesExample[]>([]);
    const [total, setTotal] = useState<number>(0);
    const { success, error } = useNotify();
    const history = useHistory();
    useEffect(() => {
        fetchNews();
    }, [pagination, textSearch]);

    const fetchNews = async () => {
        dispatch(setLoading(true));
        try {
            const getAllStocks = await rolePosAPI.getPosRoles({
                organization_id: org_id,
                xpage: pagination.xpage,
                xlimit: pagination.xlimit,
                textSearch: textSearch,
            });

            setTotal(getAllStocks.data.total);
            setPosRoles(getAllStocks.data.result);
        } catch (err: any) {
            error(err.msg);
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleSort = (sortField: enumContactSortFields, sortBy: enumSortBy) => {
        const newSortParams = { ...params, sortField, sortBy };
        setParams(newSortParams);
    };

    const callbackGetList = () => {
        fetchNews();
    };
    const handlePagination = (page) => {
        setPagination({ ...pagination, xpage: page });
    };

    return (
        <StyledTable>
            <div className="page-layout">
                <div className="page-header-layout">
                    <div className="page-header" style={{ padding: "1.5rem 0" }}>
                        <div>
                            <h3>{t("รายการสิทธิผู้ใช้งาน")}</h3>
                        </div>
                        <div className="flex-header">
                            <StyledTableButtonTop
                                type="danger"
                                size="default"
                                text={t("สร้างสิทธิผู้ใช้งาน")}
                                onClick={() => history.push(PATH_ROLES_PERMISSIONS)}
                                className="add-btn"
                                icon="plus"
                                style={{
                                    background: "#F22F46",
                                    borderRadius: "4px",
                                    width: "100%",
                                }}
                            />
                        </div>
                    </div>
                    <div className="search-layout">
                        <ComponentTableSearchRolesPermission
                            total={total}
                            setTextSearch={setTextSearch}
                        />
                    </div>
                </div>
                <div className="page-table-layout">
                    <div className="table">
                        <ScrollContainer vertical={false}>
                            <StockStyle>
                                <div className="table_ground">
                                    <div className="table-body">
                                        <div className="table-header">
                                            <Row
                                                gutter={24}
                                                type="flex"
                                                justify="space-between"
                                                align="middle"
                                                className="header-row"
                                            >
                                                <Col className="col-item" span={8}>
                                                    <p>{t("รายชื่อสิทธิการใช้งาน")}</p>
                                                    <div className="filter-button">
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.ASC}
                                                            sortField={enumContactSortFields.POINTS}
                                                            searchParams={params}
                                                        />
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.DESC}
                                                            sortField={enumContactSortFields.POINTS}
                                                            searchParams={params}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col
                                                    className="col-item"
                                                    span={8}
                                                    style={{ placeContent: "center" }}
                                                >
                                                    <p>{t("รายละเอียด")}</p>
                                                    <div className="filter-button">
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.ASC}
                                                            sortField={enumContactSortFields.POINTS}
                                                            searchParams={params}
                                                        />
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.DESC}
                                                            sortField={enumContactSortFields.POINTS}
                                                            searchParams={params}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col
                                                    className="col-item"
                                                    span={8}
                                                    style={{ placeContent: "end" }}
                                                >
                                                    <p>{t("page.manage")}</p>
                                                </Col>
                                            </Row>
                                        </div>

                                        {posRoles.length !== 0 ? (
                                            <div className="data-table">
                                                {posRoles.map((item, index) => (
                                                    <ComponentRowRolePOS
                                                        key={index}
                                                        data={item}
                                                        callbackGetList={callbackGetList}
                                                        index={index}
                                                        posRoles={posRoles}
                                                    />
                                                ))}
                                                <div style={{ width: "100%", height: 10 }}></div>
                                            </div>
                                        ) : (
                                            <ComponentEmptyData />
                                        )}
                                    </div>
                                </div>
                            </StockStyle>
                        </ScrollContainer>
                    </div>
                </div>

                <div className="page-bottom">
                    <div className="pagination">
                        <Pagination
                            defaultCurrent={INITIAL_LIST_PARAMS.page || 1}
                            pageSize={pagination.xlimit}
                            total={total}
                            onChange={handlePagination}
                            current={pagination.xpage}
                        />
                    </div>
                </div>
            </div>
        </StyledTable>
    );
};

export const StockStyle = styled.div`
    .data-table {
        padding: 0 0 0 34px !important;
    }
    .table-header {
        padding: 18px 40px 12px 45px !important;
        margin-left: 10px !important;
    }
    .ant-tabs-bar {
        border: none;
    }
    .ant-tabs-nav .ant-tabs-tab {
        padding: 8px 0;
        margin: 0 28px 0 0;
    }
    .ant-tabs-nav .ant-tabs-tab-active {
        color: #f4394f;
        font-weight: 600;
    }
    .ant-tabs-ink-bar {
        background-color: #f4394f;
    }
    .ant-tabs-nav .ant-tabs-tab:hover {
        color: #f4394f;
    }
    .grayBox {
        background: #f7f7f8;
        border-radius: 6px;
        padding: 16px;
        display: flex;
        flex-direction: column;
        place-items: center;
    }
    .grayBox .body1 {
        font-size: 16px;
        font-weight: 700;
    }
    .grayBox .header {
        font-size: 35px;
        font-weight: 700;
        color: #000;
        margin: 0;
    }
    .addQTY {
        margin-top: 24px;
    }
    .reasonDiv {
        margin-top: 2rem;
    }
    .reasonChoice {
        display: block;
        line-height: 30px;
    }
    .ant-radio-group {
        width: 100%;
    }
    .ant-select-selection__rendered {
        width: 100%;
    }
    .ant-input-group-addon .ant-select {
        height: 39px;
    }
`;
