export const InsidePendingIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.5004 2.64989C7.34474 2.64989 3.15044 6.84419 3.15044 11.9999C3.15044 17.1556 7.34474 21.3499 12.5004 21.3499C17.6561 21.3499 21.8504 17.1556 21.8504 11.9999C21.8504 6.84419 17.6561 2.64989 12.5004 2.64989ZM12.5 23C6.4346 23 1.5 18.0654 1.5 12C1.5 5.9346 6.4346 1 12.5 1C18.5654 1 23.5 5.9346 23.5 12C23.5 18.0654 18.5654 23 12.5 23Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.2742 16.0617C16.1301 16.0617 15.9849 16.0243 15.8518 15.9462L11.7048 13.4723C11.4562 13.3227 11.3022 13.0532 11.3022 12.7628V7.42998C11.3022 6.97458 11.6718 6.60498 12.1272 6.60498C12.5837 6.60498 12.9522 6.97458 12.9522 7.42998V12.2942L16.6977 14.5272C17.0882 14.7615 17.2169 15.2675 16.9837 15.6591C16.8286 15.9176 16.5547 16.0617 16.2742 16.0617Z"
                fill="black"
            />
        </svg>
    );
};
