import {
    ICreateBillPromotion,
    ICreatePromoCodeToBillPromotion,
    IDeleteBillPromotion,
    IDeletePromoCodeToBillPromotion,
    IGetBillPromotion,
    IGetBillPromotions,
    ISetEnableBillPromotion,
    IUpdateBillPromotion,
} from "@interfaces";
import axiosClient from "./axiosClient";
import storeFrontAxiosClient from "./storeFrontAxiosClient";

export const billPromotionAPI = {
    getBillPromotion: (params: IGetBillPromotion) => {
        const url = "bill_promotion/getBillPromotion";
        return storeFrontAxiosClient.get(url, { params });
    },
    getBillPromotions: (params: IGetBillPromotions) => {
        const url = "bill_promotion/getBillPromotions";
        return storeFrontAxiosClient.get(url, { params });
    },
    createBillPromotion: (params: ICreateBillPromotion) => {
        const url = "bill_promotion/createBillPromotion";
        return storeFrontAxiosClient.post(url, params);
    },
    updateBillPromotion: (params: IUpdateBillPromotion) => {
        const url = "bill_promotion/updateBillPromotion";
        return storeFrontAxiosClient.patch(url, params);
    },
    deleteBillPromotion: (params: IDeleteBillPromotion) => {
        const url = "bill_promotion/deleteBillPromotion";
        return storeFrontAxiosClient.post(url, params);
    },
    setEnableBillPromotion: (params: ISetEnableBillPromotion) => {
        const url = "bill_promotion/setEnableBillPromotion";
        return storeFrontAxiosClient.post(url, params);
    },
    createPromoCodeToBillPromotion: (params: ICreatePromoCodeToBillPromotion) => {
        const url = "bill_promotion/createPromoCodeToBillPromotion";
        return storeFrontAxiosClient.patch(url, params);
    },
    deletePromoCodeToBillPromotion: (params: IDeletePromoCodeToBillPromotion) => {
        const url = "bill_promotion/deletePromoCodeToBillPromotion";
        return storeFrontAxiosClient.post(url, params);
    },
};
