import { IUpdateStore } from "@interfaces";
import { Row, Col, Input, Select } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
const { Search } = Input;

interface Iprops {
    setTextSearch: (value: string) => void;
    total: number;
    // handleSearchCategory?: (value: string) => void;
    // storeList: IUpdateStore[];
    // selectedStore: string;
}
export const ComponentSettingCategoryCheckStockSearch = (props: Iprops) => {
    const { Option } = Select;
    const { setTextSearch, total } = props;
    const { t } = useTranslation();

    return (
        <StyledSearchLayout>
            <div className="filter">
                <Row
                    gutter={24}
                    type="flex"
                    justify="space-between"
                    align="middle"
                    className="search-row"
                >
                    <Col className="col-item" span={20}>
                        <div className="filter-section">
                            {/* <div className="filter-element">
                                <p>{t("page.branch")}:</p>
                                <Select
                                    onChange={handleSearchCategory}
                                    placeholder={t("page.please_choose")}
                                    value={selectedStore}
                                >
                                    {storeList.map((item, index) => (
                                        <Option value={item._id} key={index}>
                                            {item.store_name}
                                        </Option>
                                    ))}
                                </Select>
                            </div> */}
                            <div className="filter-element">
                                <p>{t("page.search")}</p>
                                <Search
                                    placeholder={t("page.search_product")}
                                    onSearch={(value) => setTextSearch(value)}
                                    style={{ width: 200 }}
                                    onChange={(event) => {
                                        setTextSearch(event.target.value);
                                    }}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col className="col-item" span={4}>
                        <div className="filter-element result">
                            <p>
                                <span>{t("page.result")}</span>
                                <span>{":"}</span>
                                <span>{total}</span>
                            </p>
                        </div>
                    </Col>
                </Row>
            </div>
        </StyledSearchLayout>
    );
};
const StyledSearchLayout = styled.div`
    @media (min-width: 992px) {
        .ant-select-selection {
            width: 160px;
        }
    }
    .filter {
        background: #fff;
        padding: 20px;
        border-radius: 4px;
        margin-bottom: 26px;
        .filter-section {
            display: flex;
            grid-gap: 25px;
        }
        .filter-element {
            display: flex;
            align-items: center;
            column-gap: 8px;
            p {
                margin: 0;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #646464;
            }
            .ant-input-search {
                height: 44px;
                width: 100%;
            }
            .ant-select {
                /* min-width: 160px; */
                width: 100%;
                .ant-select-selection--single {
                    height: 44px;
                    .ant-select-selection__rendered {
                        height: 100%;
                        align-items: center;
                        display: flex;
                    }
                }
            }
            .ant-calendar-picker-input {
                height: 44px;
            }
            &.result {
                display: flex;
                justify-content: end;
                p {
                    display: flex;
                    column-gap: 10px;
                }
            }
        }
    }
`;
