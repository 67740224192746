export const TransferRequestIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="82"
            height="46"
            viewBox="0 0 82 46"
            fill="none"
        >
            <path
                d="M68.7291 37.2764C68.7291 37.2764 67.3407 35.1321 66.719 34.1973C66.0973 33.2626 61.3206 30.7337 60.0487 30.2978C58.7768 29.8619 57.1893 30.0948 57.5816 30.9594C57.5816 30.9594 56.5989 30.2822 55.6693 30.6105C54.7409 30.94 56.1443 32.5759 56.1443 32.5759L55.8729 32.8005C55.8729 32.8005 55.4209 31.5783 54.4534 31.3206C53.4859 31.0629 52.745 31.9782 53.2697 33.7804L52.2025 33.8605C52.2025 33.8605 51.0193 33.1866 50.3011 33.2575C49.5818 33.3295 49.6847 35.0687 49.6847 35.0687L50.9448 36.8374L62.4661 41.3349L68.7303 37.2753L68.7291 37.2764Z"
                fill="#BBCAEA"
            />
            <g>
                <path
                    d="M68.7291 37.2764C68.7291 37.2764 67.3407 35.1321 66.719 34.1973C66.0973 33.2626 61.3206 30.7337 60.0487 30.2978C58.7768 29.8619 57.1893 30.0948 57.5816 30.9594C57.5816 30.9594 56.5989 30.2822 55.6693 30.6105C54.7409 30.94 56.1443 32.5759 56.1443 32.5759L55.8729 32.8005C55.8729 32.8005 55.4209 31.5783 54.4534 31.3206C53.4859 31.0629 52.745 31.9782 53.2697 33.7804L52.2025 33.8605C52.2025 33.8605 51.0193 33.1866 50.3011 33.2575C49.5818 33.3295 49.6847 35.0687 49.6847 35.0687L50.9448 36.8374L62.4661 41.3349L68.7303 37.2753L68.7291 37.2764Z"
                    fill="#BBCAEA"
                />
            </g>
            <g>
                <path
                    d="M55.8155 35.7524C55.8155 35.7524 54.6454 36.265 53.8984 36.0062"
                    stroke="#BBCAEA"
                    strokeWidth="0.99"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <g opacity="0.7">
                <path
                    d="M65.2814 35.6938C65.2814 35.6938 63.0161 36.7581 61.9892 37.9071L60.9623 39.056C60.9623 39.056 59.579 38.6964 58.3503 38.6215C57.1216 38.5466 53.6797 38.1528 53.5332 41.6029C53.5332 41.6029 61.3968 40.8755 61.3708 40.8738C61.3448 40.8738 65.2814 35.6954 65.2814 35.6954V35.6938Z"
                    fill="#4E5649"
                />
            </g>
            <path
                d="M81.9962 32.0158V20.2383C78.3183 23.1725 69.2829 30.434 69.2829 30.434C69.2829 30.434 68.92 30.5561 68.2365 30.7774C67.8525 30.9011 67.3642 31.0557 66.7849 31.2379C65.1689 31.7473 62.5243 31.5781 62.5243 31.5781L65.4553 32.5448L64.7132 36.0844C63.8051 36.8265 63.1021 37.549 62.5894 38.1398C61.8603 38.9811 61.5153 39.5572 61.5153 39.5572L60.6317 39.6468C59.6308 39.2936 58.0604 39.4873 58.0604 39.4873C56.5192 39.1943 55.4956 39.5947 55.4956 39.5947C52.3058 41.1244 53.956 42.4052 53.956 42.4052C54.3482 42.3645 55.8048 41.9365 57.2938 42.0293C58.7813 42.122 61.9303 43.1945 62.9995 43.2287C64.0687 43.2628 68.1698 41.0512 69.2992 40.1138C70.4303 39.1781 72.1146 37.6922 77.4737 34.5513C78.8635 33.7376 80.4323 32.8621 81.9962 32.0142V32.0158Z"
                fill="#BBCAEA"
            />
            <g>
                <path
                    d="M68.2356 38.1398V30.7758C67.8516 30.8994 67.3633 31.054 66.784 31.2363C65.168 31.7457 62.5234 31.5764 62.5234 31.5764L65.4544 32.5431L64.7123 36.0827C63.8042 36.8248 63.1012 37.5474 62.5885 38.1381H68.2356V38.1398Z"
                    fill="#BBCAEA"
                />
            </g>
            <g>
                <path
                    d="M66.8506 35.16C66.8506 35.16 65.5552 35.3488 64.7122 36.0843C62.5722 37.9526 61.5144 39.5572 61.5144 39.5572L60.6323 39.6484C59.6314 39.2936 58.0594 39.4873 58.0594 39.4873C56.5182 39.1959 55.4946 39.5947 55.4946 39.5947C52.3049 41.1261 53.9551 42.4052 53.9551 42.4052C54.3473 42.3645 55.8038 41.9365 57.2912 42.0293C58.7787 42.122 61.9277 43.1961 62.9985 43.2303"
                    stroke="#BBCAEA"
                    strokeWidth="0.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M63.5082 40.6216C63.5017 40.6476 62.7726 39.8225 61.5146 39.5573"
                    stroke="#BBCAEA"
                    strokeWidth="0.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M58.7301 40.1269C58.7301 40.1269 58.5478 39.8518 58.0596 39.4889"
                    stroke="#BBCAEA"
                    strokeWidth="0.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <path
                d="M35.3524 16.3781C35.1474 16.1714 34.9082 14.5131 32.9439 14.3975C32.7063 14.3829 32.441 14.391 32.1497 14.4284C30.4979 14.6351 27.7508 15.2145 25.8695 15.6344C24.6767 15.898 23.832 16.0982 23.832 16.0982L25.1535 18.5084L26.5319 21.0211C26.5319 21.0211 27.1047 21.2326 27.8647 21.423C28.2 21.5077 28.571 21.5874 28.9453 21.6427C29.9283 21.7876 31.8877 23.1888 33.8894 23.8999C34.3776 24.0757 34.8707 24.2092 35.3524 24.271C35.3524 24.271 35.3866 23.1009 35.4192 21.6623C35.4631 19.4181 35.4924 16.518 35.3524 16.3781Z"
                fill="#BBCAEA"
            />
            <g>
                <path
                    d="M35.3529 16.3781C35.1478 16.1714 34.9086 14.5131 32.9443 14.3975L33.8899 23.8999C34.3781 24.0757 34.8712 24.2092 35.3529 24.271C35.3529 24.271 35.3871 23.1009 35.4196 21.6623C35.4636 19.4181 35.4929 16.518 35.3529 16.3781Z"
                    fill="#BBCAEA"
                />
            </g>
            <g>
                <path
                    d="M35.3521 16.3781C35.1226 16.147 34.8492 14.0884 32.1477 14.4269C30.4959 14.6335 27.7489 15.2129 25.8676 15.6328C24.6747 15.8964 23.8301 16.0966 23.8301 16.0966L25.1515 18.5068C26.001 18.3001 27.0833 18.0771 27.8628 18.0771C29.207 18.0771 32.0875 20.5768 35.4172 21.6607C35.4611 19.4165 35.4904 16.5164 35.3505 16.3765L35.3521 16.3781Z"
                    fill="#BBCAEA"
                />
            </g>
            <g>
                <path
                    d="M27.8644 21.4214C27.3453 20.3327 26.3981 18.1292 25.8676 15.6327C24.6747 15.8964 23.8301 16.0965 23.8301 16.0965L26.5299 21.0194C26.5299 21.0194 27.1028 21.231 27.8628 21.4214H27.8644Z"
                    fill="#BBCAEA"
                />
            </g>
            <path
                d="M22.9143 22.3441C22.7369 22.4027 22.54 22.4564 22.3284 22.5069C20.7466 22.9007 18.3152 23.1774 16.2175 23.7486C13.8415 24.3963 11.4118 25.8545 9.25058 26.3232C7.08938 26.7918 5.52382 28.4469 2.66121 30.0125C2.01839 30.364 1.32186 30.6439 0.626953 30.8685V13.382C3.32845 13.19 9.74043 12.8775 12.7609 12.0297C13.6429 11.7807 14.6601 11.5479 15.6853 11.338C18.6895 10.7196 21.78 10.3013 21.78 10.3013C21.78 10.3013 23.1307 13.1102 24.4261 16.0738C24.7695 16.8598 25.1454 17.6117 25.51 18.2919C26.519 20.1716 27.4499 21.4979 27.4499 21.4979C25.829 21.8413 24.4261 21.8592 22.9126 22.3441H22.9143Z"
                fill="#E8EDF6"
            />
            <path
                d="M27.4501 21.498C27.4501 21.498 25.7218 19.0373 24.4263 16.0738C23.1309 13.1103 21.7802 10.3014 21.7802 10.3014C21.7802 10.3014 18.6914 10.7213 15.6855 11.3381C16.8182 13.6343 18.0388 16.1161 19.0787 18.2399C20.3871 20.9056 21.4921 22.048 22.327 22.507C22.5386 22.4565 22.7355 22.4028 22.9129 22.3442C24.4247 21.8576 25.8292 21.8414 27.4501 21.498Z"
                fill="#BECCE6"
            />
            <g opacity="0.5">
                <path
                    d="M18.4301 20.7232C17.2421 21.1545 16.378 21.2635 15.1363 21.7501C13.8945 22.2367 13.8945 22.074 11.652 23.0846C9.41104 24.0952 5.6322 24.8276 5.6322 24.8276C3.803 26.1946 2.10561 27.049 0.62793 27.5795V13.3804C3.32942 13.1883 9.7414 12.8759 12.7619 12.028C13.6439 11.779 14.661 11.5463 15.6863 11.3364C18.6905 10.7179 21.781 10.2997 21.781 10.2997C21.781 10.2997 23.1317 13.1086 24.4271 16.0721C24.7705 16.8582 25.1464 17.61 25.511 18.2903C23.1105 19.1072 19.2406 20.4287 18.4317 20.7232H18.4301Z"
                    fill="#0052BC"
                />
            </g>
            <path
                d="M66.9986 38.1284L66.1784 15.1364L65.9684 9.27448L65.5844 9.28913L49.0678 9.87825L41.087 10.163L34.0176 10.4153L35.0477 39.2692L66.9986 38.1284Z"
                fill="#E8EDF7"
            />
            <g>
                <path
                    d="M66.1791 15.1364L65.9708 9.27446L65.5867 9.28912C65.5867 9.38513 65.5949 9.49417 65.6144 9.61785C66.0408 12.4056 63.7966 12.0232 61.2269 13.6994C58.6573 15.374 60.0829 16.9542 60.0829 16.9542L61.6598 16.6792L66.1791 15.1348V15.1364Z"
                    fill="white"
                />
            </g>
            <path
                d="M53.3428 38.6114L48.7012 38.7775L48.4703 32.3274L53.1119 32.1612L53.3428 38.6114Z"
                fill="#3E92FF"
            />
            <path
                d="M52.5401 16.2076L47.8984 16.3737L47.6676 9.92356L52.3092 9.75741L52.5401 16.2076Z"
                fill="#3E92FF"
            />
            <path
                d="M64.5767 36.9663L55.5098 37.2909L55.3234 32.0833L64.3903 31.7587L64.5767 36.9663Z"
                fill="white"
            />
            <path
                d="M59.3717 36.4356L56.376 36.5428L56.3576 36.0305L59.3534 35.9233L59.3717 36.4356Z"
                fill="#0263E0"
            />
            <path
                d="M63.6133 34.9062L56.3271 35.167L56.3165 34.8694L63.6026 34.6086L63.6133 34.9062Z"
                fill="#E8EDF6"
            />
            <path
                d="M63.5879 34.2045L56.3018 34.4653L56.2911 34.1677L63.5772 33.9069L63.5879 34.2045Z"
                fill="#E8EDF6"
            />
            <path
                d="M63.5625 33.5028L56.2764 33.7636L56.2657 33.466L63.5518 33.2052L63.5625 33.5028Z"
                fill="#E8EDF6"
            />
            <path
                d="M63.542 32.8119L56.2559 33.0714L56.2453 32.7738L63.5314 32.5143L63.542 32.8119Z"
                fill="#E8EDF6"
            />
            <path
                d="M39.8444 36.5921L39.7483 33.9053L37.0615 34.0013L37.1575 36.6881L39.8444 36.5921ZM40.082 36.8134L36.9346 36.9257L36.8223 33.7783L39.9697 33.666L40.082 36.8134Z"
                fill="#0263E0"
            />
            <path
                d="M37.5449 34.4879C37.5465 34.5465 37.5498 34.6034 37.5514 34.6653C38.1357 34.6441 38.7199 34.623 39.3074 34.6018C39.3058 34.5449 39.3025 34.4863 39.3009 34.4244C38.715 34.4456 38.1308 34.4667 37.5449 34.4879Z"
                fill="#0263E0"
            />
            <path
                d="M38.8053 35.7686C38.7663 35.7686 38.7353 35.7719 38.6963 35.7719C38.7711 35.8907 38.8395 36.0013 38.9144 36.1201C38.9795 35.9981 39.0413 35.8825 39.1064 35.7589C39.069 35.7589 39.038 35.7589 39.0022 35.7589C38.9892 35.4106 38.9778 35.0623 38.9648 34.7124C38.8981 34.7141 38.8362 34.7173 38.7679 34.719C38.7809 35.0656 38.7923 35.4122 38.8053 35.7686Z"
                fill="#0263E0"
            />
            <path
                d="M37.942 35.7996C37.903 35.7996 37.8721 35.8028 37.833 35.8028C37.9062 35.9216 37.9762 36.0323 38.0511 36.1527C38.1162 36.0307 38.178 35.9135 38.2447 35.7882C38.2041 35.7882 38.1748 35.7914 38.139 35.7914C38.1259 35.4399 38.1145 35.0933 38.1015 34.7434C38.0348 34.745 37.973 34.7483 37.9062 34.7499C37.9193 35.0965 37.9307 35.4448 37.9437 35.7996H37.942Z"
                fill="#0263E0"
            />
            <path
                d="M41.8013 34.4504C41.7542 34.452 41.7086 34.4537 41.6646 34.4553C41.5784 34.4585 41.4938 34.4602 41.4075 34.4634C41.3473 34.4667 41.3034 34.4895 41.2741 34.5464C41.2171 34.6636 41.1536 34.7775 41.0967 34.8882C41.1569 34.88 41.2187 34.8687 41.2806 34.8654C41.458 34.8573 41.6354 34.8524 41.8144 34.8459C41.8095 34.7075 41.8046 34.5822 41.7997 34.4504H41.8013Z"
                fill="#0263E0"
            />
            <path
                d="M42.5465 35.4594C42.61 35.3406 42.6734 35.2267 42.7337 35.1079C42.7548 35.0639 42.7499 35.0184 42.7206 34.976C42.6474 34.867 42.5791 34.7547 42.5107 34.6489C42.4879 34.701 42.4684 34.7563 42.444 34.8084C42.3707 34.9565 42.2959 35.103 42.221 35.2511C42.2145 35.2657 42.208 35.2804 42.1982 35.2999C42.3138 35.3536 42.4277 35.4057 42.5465 35.4594Z"
                fill="#0263E0"
            />
            <path
                d="M41.6289 35.3878C41.5231 35.4627 41.4222 35.5343 41.3164 35.6107C41.3896 35.7263 41.4596 35.8418 41.5329 35.9541C41.554 35.9883 41.5898 36.0062 41.6338 36.0029C41.7639 35.9964 41.8941 35.9916 42.0243 35.985C42.0292 35.985 42.0357 35.9834 42.0341 35.9834C42.0015 35.9411 41.9657 35.8988 41.9348 35.8532C41.8421 35.7133 41.7509 35.5717 41.6582 35.4317C41.6484 35.4187 41.6386 35.4057 41.6272 35.3878H41.6289Z"
                fill="#0263E0"
            />
            <path
                d="M42.4362 35.7279C42.4638 35.7426 42.4882 35.7539 42.5127 35.7653C42.5143 35.7653 42.5175 35.7621 42.5192 35.7605C42.498 35.7247 42.4769 35.6905 42.4573 35.6547C42.4248 35.601 42.3939 35.5456 42.3597 35.4936C42.3515 35.4822 42.3353 35.4675 42.3239 35.4675C42.2148 35.4692 42.1058 35.474 41.9935 35.4887C42.0212 35.505 42.0472 35.5196 42.0781 35.5375C42.0407 35.6091 42.0065 35.6807 41.9659 35.7474C41.9414 35.7881 41.9479 35.8158 41.9691 35.8565C42.0261 35.9606 42.1139 35.9948 42.2246 35.9834C42.2767 35.9785 42.3174 35.9541 42.3418 35.9053C42.3711 35.8467 42.4036 35.7881 42.4362 35.7279Z"
                fill="#0263E0"
            />
            <path
                d="M41.547 35.0964C41.521 35.1111 41.4966 35.1257 41.4657 35.1436C41.425 35.0785 41.381 35.0167 41.3452 34.95C41.3192 34.8995 41.285 34.8881 41.2313 34.8898C41.1158 34.893 41.0507 34.9435 41.0002 35.0574C40.9807 35.1013 40.9856 35.1453 41.0116 35.186C41.0474 35.2429 41.0832 35.2999 41.119 35.3585C41.0946 35.3747 41.0718 35.3894 41.0507 35.404C41.0507 35.4089 41.0507 35.4138 41.0507 35.4187C41.1174 35.417 41.1841 35.4057 41.2492 35.4138C41.3599 35.4268 41.425 35.3878 41.4608 35.282C41.4819 35.2185 41.521 35.1615 41.5519 35.1013C41.5503 35.0981 41.547 35.0964 41.5454 35.0932L41.547 35.0964Z"
                fill="#0263E0"
            />
            <path
                d="M42.1064 34.9338C42.1064 34.9061 42.1048 34.8768 42.1031 34.841C42.1259 34.841 42.1487 34.841 42.1699 34.8394C42.2154 34.8394 42.2594 34.8394 42.3049 34.8345C42.3407 34.8312 42.3928 34.8345 42.4075 34.815C42.4498 34.758 42.4921 34.6945 42.4726 34.6164C42.4612 34.5725 42.44 34.5302 42.4156 34.4911C42.3879 34.4455 42.344 34.426 42.2887 34.4293C42.2236 34.4341 42.1568 34.4341 42.0869 34.4374C42.0869 34.4048 42.0852 34.3772 42.0836 34.3495C42.0803 34.3495 42.0771 34.3495 42.0738 34.3479C42.0315 34.4244 41.9941 34.5041 41.9453 34.5774C41.9095 34.6311 41.9144 34.6701 41.9518 34.7206C42.0039 34.7905 42.0478 34.867 42.095 34.9419C42.0982 34.9419 42.1015 34.9386 42.1048 34.937L42.1064 34.9338Z"
                fill="#0263E0"
            />
            <path
                d="M43.2623 36.47L43.1663 33.7832L40.4795 33.8792L40.5755 36.566L43.2623 36.47ZM43.4999 36.6914L40.3525 36.8036L40.2402 33.6562L43.3876 33.544L43.4999 36.6914Z"
                fill="#0263E0"
            />
            <path
                d="M45.1279 35.8988C45.0986 35.863 45.0742 35.8141 45.0465 35.7751C45.0303 35.7539 45.0172 35.7312 44.9977 35.7002L45.2076 35.6921C45.0953 35.5424 44.9879 35.3992 44.8789 35.2527C45.0595 35.0802 45.3964 35.046 45.6308 35.1762C45.8147 35.2787 45.9253 35.4415 46.0034 35.6303C46.0344 35.7051 46.0571 35.7832 46.0832 35.8646L45.1295 35.8988H45.1279Z"
                fill="#0263E0"
            />
            <path
                d="M44.667 34.234C44.6686 34.2926 44.6702 34.3479 44.6735 34.4081C44.8379 34.4016 44.9974 34.3967 45.1633 34.3902C45.1715 34.6083 45.178 34.8198 45.1861 35.0363C45.2512 35.0347 45.3098 35.0314 45.3749 35.0298C45.3668 34.8166 45.3603 34.605 45.3521 34.3853C45.5181 34.3788 45.6792 34.3739 45.8436 34.3674C45.842 34.3056 45.8387 34.2502 45.8371 34.19C45.4498 34.2047 45.0608 34.2177 44.667 34.2323V34.234Z"
                fill="#0263E0"
            />
            <path
                d="M45.0107 35.9037L44.5469 35.92C44.5843 35.6987 44.6543 35.4985 44.8007 35.3244C44.8707 35.4171 44.9358 35.505 45.0074 35.5994L44.8007 35.6075C44.8724 35.7084 44.9391 35.8044 45.0091 35.9037H45.0107Z"
                fill="#0263E0"
            />
            <path
                d="M46.6764 36.348L46.5804 33.6612L43.8935 33.7572L43.9895 36.444L46.6764 36.348ZM46.914 36.5693L43.7666 36.6816L43.6543 33.5342L46.8017 33.4219L46.914 36.5693Z"
                fill="#0263E0"
            />
            <g>
                <path
                    d="M49.0677 9.87824L41.0869 10.163L38.6816 15.4765L38.903 17.4847C38.903 17.4847 47.9497 21.205 47.8163 21.0715C47.7577 21.0146 48.8855 19.5304 47.8163 18.0267C46.7454 16.5213 45.1115 16.4139 43.131 16.0396C41.1504 15.6653 44.3906 12.2705 46.0489 11.587C47.4713 11.0012 48.6576 10.4104 49.0677 9.87986V9.87824Z"
                    fill="white"
                />
            </g>
            <path
                d="M48.7161 9.88957C48.7161 9.88957 48.5274 7.48426 47.7364 6.67707C46.9439 5.8715 45.144 6.87236 45.144 6.87236C45.144 6.87236 44.7827 5.65506 40.6784 6.24906C40.6784 6.24906 40.1023 5.22217 38.2666 5.04315C38.2666 5.04315 38.1234 4.82671 37.4024 4.53703C36.6831 4.24898 36.0712 4.53703 36.0712 4.53703C36.0712 4.53703 35.3877 3.6029 34.2355 3.52966C33.08 3.45806 30.5624 6.33857 27.8967 7.85205C27.8967 7.85205 26.8161 8.75039 24.9804 9.00426C24.7444 9.03681 24.4743 9.06936 24.183 9.10516C22.2024 9.34276 19.2227 9.61942 19.2227 9.61942L23.7924 17.4651C23.7924 17.4651 25.0423 17.4033 26.4174 17.3805C27.6331 17.3577 28.948 17.3642 29.5909 17.4651C29.5909 17.4651 30.3102 17.7174 30.9579 18.1845C31.6072 18.6531 33.7668 19.4457 35.2786 19.6979C36.7921 19.9502 37.7637 20.178 38.9517 20.2073C40.1397 20.2382 43.4889 20.8501 45.0366 21.2814C45.7526 21.4816 46.5631 21.7192 47.3149 21.628C48.0131 21.5434 48.3369 20.7053 48.2377 20.0657C48.1352 19.4034 47.6892 18.8338 47.1441 18.4448C46.5989 18.0559 45.9593 17.8232 45.3246 17.6132C44.7339 17.4179 44.2798 17.4993 43.6891 17.4391C43.1618 17.3854 42.6329 17.3333 42.1072 17.2536C41.5783 17.1722 40.9876 17.0778 40.571 16.71C39.9591 16.1681 40.6084 15.1607 41.1471 14.404C41.6874 13.6489 45.2156 10.4771 46.2229 9.94002L48.5534 9.89119H48.7161V9.88957Z"
                fill="#BBCAEA"
            />
            <g>
                <path
                    d="M26.4181 17.3806C26.1935 15.9419 25.5344 12.3372 24.182 9.10684C22.2015 9.34444 19.2217 9.6211 19.2217 9.6211L23.7914 17.4668C23.7914 17.4668 25.0413 17.405 26.4164 17.3822L26.4181 17.3806Z"
                    fill="#BBCAEA"
                />
            </g>
            <g>
                <path
                    d="M48.5567 9.89284C48.4363 9.6113 48.254 9.31185 48.0245 9.31185C47.5933 9.31348 46.2246 9.94003 46.2246 9.94003L48.5551 9.89284H48.5567Z"
                    fill="#BBCAEA"
                />
            </g>
            <g>
                <path
                    d="M42.1738 8.03278C42.1738 8.03278 43.0819 7.80656 43.9379 7.38507C44.8249 6.94892 45.1455 6.87569 45.1455 6.87569"
                    stroke="#BBCAEA"
                    strokeWidth="0.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M38.6836 6.48181C38.6836 6.48181 39.7284 6.44763 40.682 6.25072"
                    stroke="#BBCAEA"
                    strokeWidth="0.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M36.0725 4.54036C36.0725 4.54036 35.6314 4.65753 35.0098 4.65753"
                    stroke="#BBCAEA"
                    strokeWidth="0.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <path
                d="M25.5738 17.7321C24.3614 18.1292 19.9332 19.5873 18.2716 20.2074C18.1919 20.2383 18.1186 20.266 18.0519 20.292C16.3773 20.9397 15.5132 20.292 12.867 21.7501C10.2208 23.2083 7.08808 23.1546 5.90007 23.8023C4.71207 24.45 3.20021 25.0977 0.630533 26.3232C0.628906 26.3232 0.625651 26.3248 0.624023 26.3264V5.9757C2.74128 6.08149 4.96594 6.11566 6.92534 6.19703C8.67805 6.27027 9.64799 6.17913 10.5203 6.06684C11.4235 5.95455 12.2225 5.81785 13.6758 5.81785C16.5384 5.81785 18.213 5.11643 19.3457 5.81785C20.48 6.51926 22.9634 11.3803 24.3142 13.9353C25.2516 15.7092 25.6421 16.7996 25.7935 17.3008C25.8472 17.4815 25.7495 17.6702 25.5705 17.7305L25.5738 17.7321Z"
                fill="#E8EDF6"
            />
            <path
                d="M25.7966 17.3024C25.6453 16.8012 25.2547 15.7108 24.3173 13.937C22.9666 11.3819 20.4832 6.52088 19.3489 5.81946C18.2146 5.11805 16.54 5.81946 13.679 5.81946C12.2257 5.81946 11.4266 5.95617 10.5234 6.06846H10.5251C14.8214 11.1818 16.2177 17.1071 17.7833 19.5369C17.9493 19.794 18.1137 20.017 18.2731 20.209C19.9347 19.5889 24.3629 18.1308 25.5753 17.7337C25.7543 17.6735 25.852 17.4847 25.7983 17.3041L25.7966 17.3024Z"
                fill="#BECCE6"
            />
            <path
                d="M15.5946 6.25075C16.3549 6.25075 16.9713 6.83874 16.9713 7.56407C16.9713 8.28939 16.3549 8.87738 15.5946 8.87738C14.8342 8.87738 14.2178 8.28939 14.2178 7.56407C14.2178 6.83874 14.8342 6.25075 15.5946 6.25075Z"
                fill="#0052BC"
            />
            <path
                d="M81.9974 8.23777C80.4172 8.82689 78.7898 9.47296 77.784 9.97583C75.299 11.2175 73.3022 12.622 70.9245 13.7563C68.5501 14.8906 68.2247 14.998 66.2278 15.8622C64.2278 16.7263 63.9592 17.0502 62.2033 17.5091C60.7207 17.8964 59.6385 17.3203 60.1788 16.0233C60.7207 14.7279 62.7712 14.5114 63.3652 14.1339C63.9592 13.7563 65.4727 13.3771 65.5801 12.8921C65.6387 12.6269 65.7298 12.0768 65.6176 11.5853C65.5248 11.172 65.2888 10.8009 64.7713 10.6772C63.6354 10.4071 62.7712 10.246 61.4221 9.86843C60.0714 9.48924 58.9371 9.65198 57.3178 9.65198C55.6985 9.65198 52.1345 9.81472 52.1345 9.81472C52.1345 9.81472 51.7553 9.27443 53.4299 8.73413C53.5471 8.69507 53.6594 8.65926 53.7684 8.62021C55.2054 8.11083 55.9019 7.55589 57.2104 6.95374C58.6132 6.30441 60.0177 6.25233 60.9908 5.7104C61.9624 5.17011 63.2578 4.30758 64.5549 4.19854C65.8503 4.08951 66.013 4.52077 68.6055 4.84625C71.1979 5.17011 72.7651 5.1164 74.1142 4.5761C74.8986 4.26201 78.8386 1.94296 82.0006 0.053545V8.23777H81.9974Z"
                fill="#BBCAEA"
            />
            <g>
                <path
                    d="M65.6136 11.5854C65.6136 10.469 64.3703 10.0573 63.4476 9.72038C62.5248 9.38514 61.1985 8.7716 60.1146 8.7716C59.3986 8.7716 55.9989 8.70162 53.7645 8.61863C53.6555 8.65768 53.5432 8.69349 53.426 8.73255C51.7514 9.27447 52.1306 9.81314 52.1306 9.81314C52.1306 9.81314 55.6946 9.6504 57.3139 9.6504C58.9331 9.6504 60.0674 9.48766 61.4182 9.86685C62.7673 10.2444 63.6315 10.4071 64.7674 10.6757C65.2849 10.7994 65.5209 11.1704 65.6136 11.5838V11.5854Z"
                    fill="#BBCAEA"
                />
            </g>
            <g>
                <path
                    d="M65.0908 14.2169C65.0908 14.2169 65.5676 13.4813 65.6132 12.7018"
                    stroke="#BBCAEA"
                    strokeWidth="0.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <g>
                <path
                    d="M63.1455 14.9183C63.1455 14.9183 63.1878 14.3308 63.7476 13.9353"
                    stroke="#BBCAEA"
                    strokeWidth="0.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <g>
                <path
                    d="M57.8535 9.00435C57.8535 9.00435 58.5045 9.613 59.1229 9.61951"
                    stroke="#BBCAEA"
                    strokeWidth="0.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <g>
                <path
                    d="M60.7412 9.71875C60.7412 9.71875 62.1212 9.7871 62.5802 9.13939"
                    stroke="#BBCAEA"
                    strokeWidth="0.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <g>
                <path
                    d="M57.3848 6.87402C57.3848 6.87402 58.0976 6.68199 60.1221 6.73732C62.1466 6.79103 63.1458 6.41347 64.6039 6.49484"
                    stroke="#BBCAEA"
                    strokeWidth="0.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
};
