import { useEffect, memo, useState } from "react";
import { FieldArray, Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Switch, Row, Col, Radio, Checkbox, Input, Form } from "antd";
import styled from "styled-components";
import { IGetProductAddonsExample, ICreateProductAddon, IOptionList } from "@interfaces";
import {
    StyledSubmitButton,
    StyledCancelButton,
    StyledCard,
    SharedInput,
    IconDrag,
    StyledTableButtonTop,
} from "@components";
import { useYup } from "@validations";
import { setLoading, selectAuth } from "@redux";
import { showErrorMessage, useNotify } from "@utils";
import { PATH_ADD_ON_PRODUCT_CATEGORY } from "@configs";
import { orgApi, productAddonAPI } from "@api";
import { DeleteFilled } from "@ant-design/icons";

interface IProps {
    productAddon?: IGetProductAddonsExample;
}
const initData: ICreateProductAddon = {
    addon_name: "",
    is_require: false,
    is_multi_choice: false,
    option_list: [],
    hint_code: "",
    type: "",
};

const optionListInit: IOptionList = {
    option_name: "",
    option_qty_limit: 0,
    is_new: true,
};

export const ModuleCreateProductAddon = memo((props: IProps) => {
    const { YupCreateAddOn } = useYup();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { error, success } = useNotify();
    const organization_id = useSelector(selectAuth).userInfo?.merchant?._id;
    const createAddon = Yup.object().shape(YupCreateAddOn);
    const { productAddon } = props;
    const [initValues, setInitValues] = useState<ICreateProductAddon>(initData);
    const [configDetail, setConfigDetail] = useState<any>();

    useEffect(() => {
        setTimeout(() => {
            dispatch(setLoading(false));
        }, 500);
        if (productAddon) {
            setInitValues({
                addon_name: productAddon.addon_name,
                is_multi_choice: productAddon.is_multi_choice,
                is_require: productAddon.is_require,
                organization_id: productAddon.organization_id,
                option_list: productAddon.option_list,
                hint_code: productAddon.hint_code,
                type: productAddon.type,
                option_limit: productAddon.option_limit,
                max_option_limit_qty: productAddon.max_option_limit_qty,
            });
        }
    }, [productAddon]);

    useEffect(() => {
        fetchORGSetting();
    }, []);

    const fetchORGSetting = async () => {
        const getConfig = await orgApi.getVatDetail({
            organization_id: organization_id,
        });
        setConfigDetail(getConfig?.data?.result);
    };

    const handleNewsSubmit = async (values: ICreateProductAddon) => {
        dispatch(setLoading(true));
        try {
            if (productAddon) {
                const update = await productAddonAPI.updateProductAddon({
                    organization_id: productAddon.organization_id,
                    product_addon_id: productAddon._id,
                    addon_name: values.addon_name,
                    option_limit: values.option_limit,
                    max_option_limit_qty: values.max_option_limit_qty,
                    is_multi_choice: values.is_multi_choice,
                    is_require: values.is_require,
                    option_list: values.option_list,
                    type: values.type,
                });
                if (update.data.error === "0") {
                    success(t("message.update.success"));
                    history.push(PATH_ADD_ON_PRODUCT_CATEGORY);
                }
            } else {
                const res = await productAddonAPI.createProductAddon({
                    organization_id: organization_id,
                    addon_name: values.addon_name,
                    is_multi_choice: values.is_multi_choice,
                    is_require: values.is_require,
                    option_list: values.option_list,
                    type: values.type,
                    option_limit: values.option_limit,
                    max_option_limit_qty: values.max_option_limit_qty,
                });
                if (res.data.error === "0") {
                    success(t("message.create.success"));
                    history.push(PATH_ADD_ON_PRODUCT_CATEGORY);
                }
            }
        } catch (err: any) {
            if (err.response) {
                error(showErrorMessage(err.response));
            } else error(t("message.error"));
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const radioTypeHandler = (value, setFieldValue, values) => {
        setFieldValue("type", value.target.value);
        values.option_list.map((item, i) => {
            if (value.target.value === "finish-goods") {
                setFieldValue(
                    `option_list[${i}].code`,
                    item.code ? `FG-${item.code?.split("-")[1] || item.code}` : ""
                );
            } else if (value.target.value === "ingredient") {
                setFieldValue(
                    `option_list[${i}].code`,
                    item.code ? `AI-${item.code?.split("-")[1] || item.code}` : ""
                );
            }
        });
    };

    return (
        <div className="form-wrap">
            <Formik
                initialValues={initValues}
                validationSchema={createAddon}
                enableReinitialize={true}
                onSubmit={handleNewsSubmit}
            >
                {({
                    values,
                    handleChange,
                    handleBlur,
                    errors,
                    touched,
                    setFieldValue,
                    handleSubmit,
                }) => (
                    <Form>
                        <div className="page-header">
                            <h3>{t("page.create_add_on")}</h3>
                        </div>
                        <div className="page-body">
                            <div className="card-wrap">
                                <StyledCard>
                                    <StyledCustom>
                                        <div className="title topTitle">
                                            <p>{t("page.add_on_settings")}</p>
                                        </div>
                                        <Row gutter={32}>
                                            <Col md={24}>
                                                <SharedInput
                                                    label={t("page.name_title_add_on")}
                                                    descLabel={t("page._2_100_characters")}
                                                    name="addon_name"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.addon_name}
                                                    errors={errors.addon_name}
                                                    touched={touched.addon_name}
                                                    parentClassName="mb-6"
                                                />
                                            </Col>
                                        </Row>
                                        <Row gutter={32} style={{ marginBottom: 30 }}>
                                            <Col md={24}>
                                                <div className="label">
                                                    <label>{t("page.type")}</label>
                                                </div>
                                                <Radio.Group
                                                    onChange={(value) =>
                                                        radioTypeHandler(
                                                            value,
                                                            setFieldValue,
                                                            values
                                                        )
                                                    }
                                                    value={values.type}
                                                    className={"radioTypeRow"}
                                                >
                                                    <Radio value={"finish-goods"}>
                                                        {t("page.finished_goods")}
                                                    </Radio>
                                                    <Radio value={"ingredient"}>
                                                        {t("page.ingredient")}
                                                    </Radio>
                                                </Radio.Group>
                                            </Col>
                                        </Row>
                                        <Row gutter={32} style={{ marginBottom: 20 }}>
                                            <Col
                                                md={24}
                                                style={{
                                                    display: "flex",
                                                    placeItems: "center",
                                                    gap: 10,
                                                }}
                                            >
                                                <div
                                                    className="title"
                                                    style={{ margin: 0, color: "#000" }}
                                                >
                                                    {t("page.required")}
                                                </div>
                                                <Switch
                                                    onChange={(value) => {
                                                        setFieldValue("is_require", value);
                                                    }}
                                                    checked={values.is_require}
                                                />
                                            </Col>
                                        </Row>
                                        <Row gutter={32} style={{ marginBottom: 20 }}>
                                            <Col md={24}>
                                                <div className="label">
                                                    <label>
                                                        {t("page.number_of_selectable_options")}
                                                    </label>
                                                </div>
                                                <Radio.Group
                                                    onChange={(value) => {
                                                        setFieldValue(
                                                            "is_multi_choice",
                                                            value.target.value
                                                        );
                                                    }}
                                                    value={values.is_multi_choice}
                                                    className={"isMultipleRadioGroup"}
                                                >
                                                    <Radio value={false}>
                                                        {t("page.single_option")}
                                                    </Radio>
                                                    <Radio value={true}>{t("page.multiple")}</Radio>
                                                </Radio.Group>
                                            </Col>
                                        </Row>
                                        <Row gutter={32}>
                                            <Col md={12}>
                                                <SharedInput
                                                    label={t("page.option_limit")}
                                                    name="option_limit"
                                                    onBlur={handleBlur}
                                                    onChange={(e) => {
                                                        setFieldValue(
                                                            e.target.name,
                                                            e.target.value
                                                        );
                                                    }}
                                                    type="number"
                                                    value={values.option_limit}
                                                    errors={errors.option_limit}
                                                    touched={touched.option_limit}
                                                    parentClassName="mb-6"
                                                />
                                            </Col>
                                        </Row>
                                        <Row gutter={32}>
                                            <Col md={12}>
                                                <SharedInput
                                                    label={t("page.max_option_total_qty")}
                                                    name="max_option_limit_qty"
                                                    onBlur={handleBlur}
                                                    onChange={(e) => {
                                                        setFieldValue(
                                                            e.target.name,
                                                            e.target.value
                                                        );
                                                    }}
                                                    type="number"
                                                    value={values.max_option_limit_qty}
                                                    errors={errors.max_option_limit_qty}
                                                    touched={touched.max_option_limit_qty}
                                                    parentClassName="mb-6"
                                                />
                                            </Col>
                                        </Row>
                                    </StyledCustom>
                                </StyledCard>
                            </div>
                            <div className="card-wrap">
                                <StyledCard>
                                    <StyledCustom>
                                        <div className="title topTitle">
                                            <p>{t("page.add_on_options")}</p>
                                        </div>
                                        <FieldArray name="option_list">
                                            {({ remove, push }) => (
                                                <>
                                                    {values.option_list.length !== 0 &&
                                                        values.option_list.map((item, i) => (
                                                            <Row gutter={32} key={i}>
                                                                <div className="cardOptionRow">
                                                                    <div className="drag-icon">
                                                                        <IconDrag />
                                                                    </div>
                                                                    <div className="cardOption">
                                                                        <SharedInput
                                                                            label={
                                                                                t("page.option") +
                                                                                " " +
                                                                                (i + 1)
                                                                            }
                                                                            name={`option_list.${i}.option_name`}
                                                                            notErr
                                                                            isArray
                                                                            i={i}
                                                                            arrayListName={
                                                                                "option_list"
                                                                            }
                                                                            arrayFieldName={
                                                                                "option_name"
                                                                            }
                                                                            onBlur={handleBlur}
                                                                            onChange={handleChange}
                                                                            value={item.option_name}
                                                                            parentClassName="mb-6"
                                                                            suffixIcon={
                                                                                <span
                                                                                    style={{
                                                                                        color: "#A5A5A5",
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        style={{
                                                                                            color: "#646464",
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            item
                                                                                                .option_name
                                                                                                ?.length
                                                                                        }
                                                                                    </span>
                                                                                    /100
                                                                                </span>
                                                                            }
                                                                        />

                                                                        <div className="inventoryCodeDiv">
                                                                            {item.code !== "" && (
                                                                                <span className="prefixInventoryCode">
                                                                                    {values.type ===
                                                                                    "ingredient"
                                                                                        ? "AI - "
                                                                                        : values.type ===
                                                                                          "finish-goods"
                                                                                        ? "FG - "
                                                                                        : ""}
                                                                                </span>
                                                                            )}
                                                                            <SharedInput
                                                                                label={t(
                                                                                    "page.addon_code"
                                                                                )}
                                                                                name={`option_list.${i}.code`}
                                                                                onBlur={handleBlur}
                                                                                onChange={(
                                                                                    value
                                                                                ) => {
                                                                                    if (
                                                                                        values.type ===
                                                                                        "finish-goods"
                                                                                    ) {
                                                                                        setFieldValue(
                                                                                            `option_list.${i}.code`,
                                                                                            `FG-${value.target.value}`
                                                                                        );
                                                                                    } else if (
                                                                                        values.type ===
                                                                                        "ingredient"
                                                                                    ) {
                                                                                        setFieldValue(
                                                                                            `option_list.${i}.code`,
                                                                                            `AI-${value.target.value}`
                                                                                        );
                                                                                    } else {
                                                                                        setFieldValue(
                                                                                            `option_list.${i}.code`,
                                                                                            value
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }
                                                                                }}
                                                                                value={
                                                                                    (item.code &&
                                                                                        item.code.split(
                                                                                            "-"
                                                                                        )[1]) ||
                                                                                    item.code
                                                                                }
                                                                                parentClassName="mb-6"
                                                                                className={
                                                                                    values.type !==
                                                                                    ""
                                                                                        ? "inventoryCodeInput"
                                                                                        : ""
                                                                                }
                                                                                disable={
                                                                                    productAddon &&
                                                                                    item.is_new !==
                                                                                        true
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <SharedInput
                                                                            label={t(
                                                                                "page.hint_code"
                                                                            )}
                                                                            name={`option_list.${i}.hint_code`}
                                                                            onBlur={handleBlur}
                                                                            onChange={(e) => {
                                                                                setFieldValue(
                                                                                    `option_list.${i}.hint_code`,
                                                                                    e.target.value
                                                                                );
                                                                            }}
                                                                            value={item.hint_code}
                                                                            errors={item.hint_code}
                                                                            parentClassName="mb-6"
                                                                        />
                                                                        <div className="secondRow">
                                                                            <Checkbox
                                                                                checked={
                                                                                    values
                                                                                        .option_list[
                                                                                        i
                                                                                    ].price !==
                                                                                    undefined
                                                                                }
                                                                                onChange={(
                                                                                    value
                                                                                ) => {
                                                                                    if (
                                                                                        value.target
                                                                                            .checked ===
                                                                                        true
                                                                                    ) {
                                                                                        setFieldValue(
                                                                                            `option_list.${i}.price`,
                                                                                            0
                                                                                        );
                                                                                    } else {
                                                                                        setFieldValue(
                                                                                            `option_list.${i}.price`,
                                                                                            undefined
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            >
                                                                                {t(
                                                                                    "page.additional_fee"
                                                                                )}
                                                                            </Checkbox>
                                                                            {item.price !==
                                                                                undefined && (
                                                                                <Input
                                                                                    addonAfter={
                                                                                        "THB"
                                                                                    }
                                                                                    placeholder="+0"
                                                                                    type={"number"}
                                                                                    onChange={
                                                                                        handleChange
                                                                                    }
                                                                                    size="large"
                                                                                    value={
                                                                                        item.price
                                                                                    }
                                                                                    name={`option_list.${i}.price`}
                                                                                />
                                                                            )}
                                                                        </div>
                                                                        <div className="optionQtyLimit">
                                                                            <SharedInput
                                                                                label={t(
                                                                                    "page.option_qty_limit"
                                                                                )}
                                                                                name={`option_list[${i}].option_qty_limit`}
                                                                                onBlur={handleBlur}
                                                                                onChange={(e) => {
                                                                                    setFieldValue(
                                                                                        `option_list[${i}].option_qty_limit`,
                                                                                        e.target
                                                                                            .value
                                                                                    );
                                                                                }}
                                                                                type="number"
                                                                                value={
                                                                                    values
                                                                                        .option_list[
                                                                                        i
                                                                                    ]
                                                                                        .option_qty_limit
                                                                                }
                                                                                parentClassName="mb-6"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="actions">
                                                                        <button
                                                                            className="deleteIcon"
                                                                            type="button"
                                                                            onClick={() => {
                                                                                if (i !== 0) {
                                                                                    remove(i);
                                                                                }
                                                                            }}
                                                                        >
                                                                            <DeleteFilled />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </Row>
                                                        ))}
                                                    <StyledTableButtonTop
                                                        type="danger"
                                                        size="default"
                                                        text={t("page.add_option")}
                                                        className="add-btn"
                                                        icon="plus"
                                                        style={{
                                                            background: "#fff",
                                                            borderRadius: "4px",
                                                            color: "#000",
                                                            marginTop: 20,
                                                            marginLeft: 6,
                                                        }}
                                                        onClick={() => {
                                                            push(optionListInit);
                                                        }}
                                                    />
                                                </>
                                            )}
                                        </FieldArray>
                                    </StyledCustom>
                                </StyledCard>
                            </div>
                            <div className="btn-layout">
                                <StyledSubmitButton
                                    type="default"
                                    text={t("page.save")}
                                    htmlType="submit"
                                    onClick={handleSubmit}
                                />
                                <StyledCancelButton
                                    type="sub"
                                    text={t("page.cancel")}
                                    htmlType="button"
                                    onClick={() => history.push(PATH_ADD_ON_PRODUCT_CATEGORY)}
                                />
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
});

const StyledCustom = styled.div`
    .secondTitle {
        font-size: 20px;
    }
    .topTitle {
        p {
            margin: 0;
        }
        font-size: 25px !important;
        color: #000 !important;
        border-bottom: none !important;
        margin-bottom: 32px !important;
        display: flex !important;
        justify-content: space-between !important;
    }
    .isMultipleRadioGroup {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .cardOptionRow {
        display: flex;
        justify-content: space-between;
        place-items: center;
        margin-bottom: 20px;
    }
    .cardOption {
        background: #f7f7f8;
        border-radius: 6px;
        padding: 25px;
        width: 100%;
        margin: auto 10px;
    }
    .deleteIcon {
        border: none;
        background: none;
        cursor: pointer;
    }
    .secondRow {
        margin-top: 20px;
        display: flex;
        gap: 10px;
        place-items: center;
    }
    .ant-input-group {
        width: 150px;
    }
    .ant-checkbox-wrapper,
    .ant-input-group-wrapper {
        width: auto;
    }
    .radioTypeRow {
        display: flex;
        flex-direction: row;
        gap: 32px;
    }
    .inventoryCodeDiv {
        position: relative;
        margin-top: 30px;
        .prefixInventoryCode {
            position: absolute;
            bottom: 30px;
            left: 15px;
            z-index: 9;
            color: #979797;
        }
        .inventoryCodeInput input {
            padding-left: 50px;
        }
    }
    .optionQtyLimit {
        margin-top: 20px;
        width: 50%;
    }
`;
