export const IconRank = () => {
    return (
        <svg width="70" height="70" viewBox="0 0 84 74" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_20173_655" maskUnits="userSpaceOnUse" x="11" y="62" width="27" height="12">
                <path d="M11.9873 62.9891H37.0952V73.4365H11.9873V62.9891Z" fill="white" />
            </mask>
            <g mask="url(#mask0_20173_655)">
                <path d="M36.9375 73.2818L12.2109 65.0018L32.3306 62.9974" fill="#006AC1" />
            </g>
            <mask id="mask1_20173_655" maskUnits="userSpaceOnUse" x="7" y="0" width="77" height="66">
                <path d="M7.03711 0.563477H84.0004V65.1109H7.03711V0.563477Z" fill="white" />
            </mask>
            <g mask="url(#mask1_20173_655)">
                <path d="M47.7753 21.8784C47.7325 21.8355 47.6883 21.7913 47.6454 21.7485C48.1662 19.8298 47.4714 17.7826 45.9063 16.6485C46.0803 15.6898 45.9933 14.7311 45.6466 13.8153C45.1244 12.5085 44.0815 11.5056 42.8217 10.9393L42.0827 10.5911C41.5177 10.3287 40.8657 10.1546 40.2579 10.0676C39.1279 9.93776 37.9979 10.0676 36.955 10.4599L31.5663 12.5955H31.5234C31.5663 11.8109 31.6533 10.9393 31.7403 10.0676C31.8273 9.28299 31.8273 8.49837 31.6961 7.80216C31.6091 7.27861 31.4364 6.71225 31.2624 6.18871C30.0012 3.13864 27.0906 1.04586 23.8306 0.828979C23.7435 0.828979 23.6565 0.828979 23.5709 0.828979C22.2668 0.828979 21.0927 1.43817 20.3108 2.44104C19.529 3.48674 19.2679 4.7949 19.5718 6.01466L20.4849 9.41422C20.5719 9.80653 20.6147 10.1988 20.6147 10.5911C20.5719 11.0263 20.5277 11.3758 20.3978 11.6368C19.1809 14.383 18.877 18.7413 18.8341 19.2206L18.6159 23.0553C18.6159 23.2293 18.486 23.4034 18.312 23.4918C17.8768 23.6659 17.4859 23.9269 17.182 24.2322C16.7911 24.101 16.3131 24.014 15.878 24.014C15.3572 24.014 14.8793 24.101 14.4013 24.3192L9.49052 26.2808C7.40464 27.1082 6.3617 29.5063 7.18776 31.5977L12.0115 43.9748V65.1568L71.546 59.1851C78.4984 58.4875 83.8001 52.6043 83.8001 45.6311V18.2619L47.7753 21.8784Z" fill="#0081FE" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M41.7335 1.16727L42.7538 3.95555L45.5927 4.04112L43.3873 5.91972L44.1904 8.78488L41.8071 7.15787L39.4644 8.84293L40.1971 5.95891L37.946 4.13505L40.7822 3.97977L41.7335 1.16727Z" fill="#FEBE58" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M52.3651 5.83345L51.112 8.44837L52.9883 10.4911L50.1711 10.2308L48.7161 12.7463L48.2278 9.97068L45.4522 9.48245L47.9679 8.02762L47.7074 5.21017L49.7503 7.08672L52.3651 5.83345Z" fill="#FEBE58" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M53.9566 13.8269L52.6674 15.4574L53.6568 17.1822L51.7279 16.5552L50.3132 18.0843L50.4102 16.0664L48.5465 15.2866L50.5353 14.6666L50.7982 12.6554L51.9303 14.2902L53.9566 13.8269Z" fill="#FEBE58" />
            </g>
            <path d="M21.9443 42.9053L17.0335 44.8669C16.2959 45.1722 15.5127 44.7798 15.2088 44.0823L9.82001 30.484C9.51611 29.7436 9.90704 28.959 10.6033 28.6537L15.5127 26.6921C16.2517 26.3882 17.0335 26.7805 17.3388 27.4768L22.6834 41.075C22.9873 41.8154 22.6405 42.6442 21.9443 42.9053Z" fill="white" />
            <path d="M45.1945 20.9829L45.0204 21.5064C44.8035 22.1598 45.0204 22.8574 45.6282 23.2497C46.5841 23.8603 47.0193 24.993 46.7582 26.0829L46.2802 27.869C46.1504 28.3055 46.2802 28.7849 46.5413 29.1772L46.6712 29.3512C47.0193 29.7864 47.2361 30.3969 47.1491 30.9633C47.1491 31.0503 47.1063 31.1387 47.1063 31.2258C46.8894 32.4013 45.9763 34.1446 42.9345 35.3215L33.1572 39.2004C33.1572 39.2004 29.5062 40.5956 25.2474 41.075C24.8993 41.1178 24.5954 40.9009 24.4656 40.5956L19.0768 26.8675C18.947 26.4752 19.1197 26.0387 19.512 25.9088C20.6419 25.4723 21.4238 24.3824 21.5108 23.1627L21.7277 19.3266C21.7277 19.3266 22.0316 15.0568 23.0745 12.6587C23.3356 12.0495 23.4655 11.3519 23.5097 10.6985C23.5525 10.0438 23.4655 9.34618 23.2928 8.69279L22.3797 5.29323C22.1186 4.33455 22.9005 3.37588 23.9006 3.46291C26.2033 3.59414 28.1152 5.07635 28.9412 7.12355C29.0725 7.47304 29.2023 7.82114 29.2451 8.21345C29.3322 8.69279 29.3322 9.17213 29.2893 9.65146C29.0725 11.613 28.9412 13.4433 29.0283 15.0568C29.0283 15.7102 29.7231 16.1895 30.3323 15.9284L38.1108 12.8328C39.0667 12.4418 40.1538 12.4847 41.1098 12.877L42.0215 13.2693C43.2827 13.7928 43.8476 15.2737 43.2827 16.4948C42.9774 17.191 43.2827 18.0198 43.9775 18.3679L44.2386 18.4992C45.1074 18.9785 45.5412 20.0242 45.1945 20.9829Z" fill="white" />
            <mask id="mask2_20173_655" maskUnits="userSpaceOnUse" x="0" y="1" width="16" height="19">
                <path d="M0 1.45721H15.5239V19.8461H0V1.45721Z" fill="white" />
            </mask>
            <g mask="url(#mask2_20173_655)">
                <path d="M15.5128 19.502L4.95361 1.4585L0 7.64705L15.5128 19.502Z" fill="#0081FE" />
            </g>
            <path d="M13.2103 19.8502L5.43177 16.0583L3.04199 19.5021L13.2103 19.8502Z" fill="#0081FE" />
        </svg>
    );
};