import { ISvg } from "@interfaces";

export const IconTrash = (props: ISvg) => (
    <svg
        width={props.size}
        height={props.size}
        viewBox="0 0 21 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M19.626 3.7135H14.384V1.0925C14.384 0.918783 14.315 0.752181 14.1922 0.629345C14.0693 0.506509 13.9027 0.4375 13.729 0.4375H7.177C7.09099 0.4375 7.00581 0.454442 6.92634 0.487359C6.84687 0.520276 6.77467 0.568523 6.71384 0.629345C6.65302 0.690167 6.60477 0.762374 6.57186 0.841842C6.53894 0.921311 6.522 1.00648 6.522 1.0925V3.7135H1.28C1.10628 3.7135 0.939681 3.78251 0.816845 3.90534C0.694009 4.02818 0.625 4.19478 0.625 4.3685C0.625 4.54222 0.694009 4.70882 0.816845 4.83166C0.939681 4.95449 1.10628 5.0235 1.28 5.0235H19.626C19.7997 5.0235 19.9663 4.95449 20.0892 4.83166C20.212 4.70882 20.281 4.54222 20.281 4.3685C20.281 4.19478 20.212 4.02818 20.0892 3.90534C19.9663 3.78251 19.7997 3.7135 19.626 3.7135V3.7135ZM7.832 1.7475H13.074V3.7135H7.832V1.7475Z"
            fill={props.color || "#646464"}
        />
        <path
            d="M2.59106 6.33398V18.783C2.59106 19.4781 2.8672 20.1448 3.35873 20.6363C3.85027 21.1279 4.51693 21.404 5.21207 21.404H15.6951C16.3902 21.404 17.0569 21.1279 17.5484 20.6363C18.0399 20.1448 18.3161 19.4781 18.3161 18.783V6.33398H2.59106ZM14.0011 16.49L13.0751 17.417L10.4541 14.796L7.83306 17.417L6.90707 16.49L9.52806 13.869L6.90707 11.248L7.83306 10.322L10.4541 12.943L13.0751 10.322L14.0011 11.248L11.3801 13.869L14.0011 16.49Z"
            fill={props.color || "#646464"}
        />
    </svg>
);
