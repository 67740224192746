import { enumDrawerPlacement, PATH_PRODUCT, PATH_PRODUCT_CREATE } from "@configs";
import { Avatar, Button, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { DeleteOutlined } from "@ant-design/icons";
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    TouchSensor,
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    rectSortingStrategy,
    //@ts-ignore
} from "@dnd-kit/sortable";
import {
    InfoIcon,
    IconCamera,
    SharedInput,
    StyledSubmitButton,
    StyledCancelButton,
    SharedComponentDrawer,
} from "@components";
import { useNotify } from "@utils";
import { IMAGE_TYPE, enumValidation } from "@configs";
import { useEffect, useState } from "react";
import { ComponentSortableItem } from "./SortableItem";
import axios from "axios";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getCategories, selectAuth } from "@redux";
import { orgApi } from "@api";
const textString = "390px × 270px Maximum 10MB";

interface IProps {
    showModal: boolean;
    handleClose: () => void;
    editItem?: any;
}

export const ComponentUnitDrawer = (props: IProps) => {
    const history = useHistory();
    const { showModal, handleClose, editItem } = props;
    const { t } = useTranslation();
    const { success, error } = useNotify();
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const [CatName, setCatName] = useState<string>("");
    const dispatch = useDispatch();

    const { userInfo } = useSelector(selectAuth);
    useEffect(() => {
        if (editItem) {
            setCatName(editItem.uom_name);
        } else {
            setCatName("");
        }
    }, [editItem]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCatName(e.target.value);
    };
    const handleSave = async () => {
        try {
            if (editItem) {
                const updateUnit = await orgApi.updateUnit({

                    organization_id: userInfo?.merchant?._id || "",
                    uom_id: editItem?._id,
                    uom_name: CatName,
                    uom_code: CatName,
                    remark: "",
                });
                if (updateUnit.data.error === "0") {
                    handleClose();
                    setCatName("");
                }
            } else {
                const res = await orgApi.createUOM({
                    organization_id: userInfo?.merchant?._id || "",
                    uom_name: CatName,
                    uom_code: CatName,
                    remark: "",
                });
                if (res.data.error === "0") {
                    handleClose();
                    setCatName("");
                    // await dispatch(getCategories({}));
                    // history.push(PATH_PRODUCT);
                    // history.push(PATH_PRODUCT_CREATE);
                }
            }
        } catch (err) {
            console.log(err);
        }
    };
    return (
        <SharedComponentDrawer
            title={t("page.unit_setting.add_unit")}
            placement={enumDrawerPlacement.RIGHT}
            onClose={handleClose}
            visible={showModal}
            width={538}
        >
            <SharedInput
                label={t("page.unit_setting.unit_name")}
                onChange={handleChange}
                value={CatName}
                parentClassName="mb-6"
            />

            <div className="btn-layout">
                <StyledSubmitButton
                    type="default"
                    text={t("page.save")}
                    htmlType="button"
                    // disable={isSubmitting}
                    onClick={handleSave}
                />
                <StyledCancelButton
                    type="sub"
                    text={t("page.cancel")}
                    htmlType="button"
                    onClick={handleClose}
                />
            </div>
        </SharedComponentDrawer>
    );
};
