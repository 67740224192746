import { useState, useRef, useEffect } from "react";
import {
    ComponentContactSortIcon,
    StyledTable,
    ComponentEmptyData,
    StyledTableButtonTop,
    ComponentRowConpliant,
} from "@components";
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    TouchSensor,
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    rectSortingStrategy,
    //@ts-ignore
} from "@dnd-kit/sortable";
import { Row, Col, Pagination } from "antd";
import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";
import {
    enumSortBy,
    enumContactSortFields,
    PAGINATION,
    PAGE_START,
    enumNewsStatus,
    INITIAL_LIST_PARAMS,
    enumProcessStatus,
    enumLanuage,
} from "@configs";
import { IPaginationNext, INews, IConplaint } from "@interfaces";
import { useDispatch, useSelector } from "react-redux";
import {
    getComplaintType,
    getConplaintCategories,
    getDivisions,
    selectAuth,
    selectContact,
    selectOrg,
    setLoading,
} from "@redux";
import { useNotify } from "@utils";
import { useHistory } from "react-router";
import { conplaintAPI, newsAPI } from "@api";
import { ConpliantTableSearch } from "@modules";

const INITAL_PAGE = {
    xpage: PAGE_START,
    xlimit: PAGINATION,
};

export const PageConplaintList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const search_params = useSelector(selectContact).paramsContact;
    const [params, setParams] = useState(search_params);
    const typingTimeoutRef = useRef(0);
    const org_id = useSelector(selectAuth).userInfo?.merchant._id;
    const categories = useSelector(selectOrg).conplaintCategory;
    const divisions = useSelector(selectOrg).division;
    const complainTypes = useSelector(selectOrg).complainTypes;
    const [textSearch, setTextSearch] = useState<string>("");
    const [searchDate, setSearchDate] = useState<string>("");
    const [filterId, setFilterId] = useState<string | enumProcessStatus>("");
    const [catSearch, setCatSearch] = useState<string>("");
    const [pagination, setPagination] = useState<IPaginationNext>(INITAL_PAGE);
    const [news, setNews] = useState<INews[]>([]);
    const [conplaints, setConplaints] = useState<IConplaint[]>([]);
    const [total, setTotal] = useState<number>(0);
    const { success, error } = useNotify();
    const history = useHistory();
    const i18nextLng = window.localStorage.i18nextLng;
    useEffect(() => {
        fetchDivision();
        fetchConplaintCategory();
        fetchComplaintType();
        fetchConplaint(pagination, textSearch, searchDate, catSearch, filterId);
    }, [pagination, textSearch, searchDate, catSearch, filterId]);

    const fetchConplaintCategory = async () => {
        await dispatch(getConplaintCategories({ organization_id: org_id || "" }));
    };
    const fetchDivision = async () => {
        await dispatch(getDivisions({ organization_id: org_id || "" }));
    };
    const fetchComplaintType = async () => {
        await dispatch(getComplaintType({ organization_id: org_id || "" }));
    };
    const fetchConplaint = async (
        pagination: IPaginationNext,
        textSearch: string,
        searchDate: string,
        catSearch: string,
        filterId: string | enumProcessStatus
    ) => {
        dispatch(setLoading(true));
        try {
            const response = await conplaintAPI.getList({
                organization_id: org_id || "",
                ...pagination,
                ...(textSearch && { text_search: textSearch }),
                ...(searchDate.length > 1 &&
                    searchDate[0] !== "" && {
                    date_from: searchDate[0],
                }),
                ...(searchDate.length > 1 &&
                    searchDate[1] !== "" && {
                    date_to: searchDate[1],
                }),
                ...(catSearch && { new_category_id: catSearch }),
                ...(filterId !== "" && {
                    status: filterId,
                }),
            });
            setConplaints(response.data.result);
            setTotal(response.data.total);
            success(response.data.msg);
        } catch (err: any) {
            error(err.msg);
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    // const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const value = e.target.value;
    //     if (typingTimeoutRef.current) {
    //         clearTimeout(typingTimeoutRef.current);
    //     }
    //     typingTimeoutRef.current = window.setTimeout(() => {
    //         const newSearchParams = { ...params, page: 1, search: value };
    //         setParams(newSearchParams);
    //         // fetchContacts(newSearchParams);
    //     }, 300);
    // };

    const handleSort = (sortField: enumContactSortFields, sortBy: enumSortBy) => {
        // const newSortParams = { ...params, sortField, sortBy };
        // setParams(newSortParams);
        // fetchContacts(newSortParams);
    };
    const updateNews = async (isEnable: boolean, data: INews) => {
        dispatch(setLoading(true));
        try {
            const payload = {
                organization_id: data.organization_id,
                title: data.title,
                detail: data.detail,
                image_url: [data.image_url],
                is_hot_news: data.is_hot_news,
                news_category_id: data.news_category_id,
                date_from: data.date_from,
                date_to: data.date_to,
                is_enable: isEnable,
                news_id: data._id,
                discount: data.discount,
                product_code: data.product_code,
                price: data.price,
                discount_type: data.discount_type,
                has_barcode: data.has_barcode,
                active_on_platform: data.active_on_platform,
                point_deduction: data.point_deduction,
                maximum_deduction: data.maximum_deduction,
                point_deduction_value: data.point_deduction_value,
                variants: data.variants,
            };
            await newsAPI.update(payload);
            success(t("message.create.success"));
            callbackGetList();
        } catch (error) {
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };
    const callbackGetList = () => {
        fetchConplaint(pagination, textSearch, searchDate, catSearch, status);
    };
    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            const swapItem = (items: INews[]) => {
                const oldIndex = items.findIndex((item) => JSON.stringify(item) === active.id);
                const newIndex = items.findIndex((item) => JSON.stringify(item) === over.id);
                return arrayMove(items, oldIndex, newIndex);
            };
            setNews(swapItem);
        }
    };
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
        useSensor(TouchSensor)
    );
    const handleSearchCategory = (value) => {
        setCatSearch(value);
    };
    const onChangeDate = (dates, dateStrings: string) => {
        setSearchDate(dateStrings);
    };
    const handlePagination = (page) => {
        setPagination(page);
    };
    return (
        <StyledTable>
            <div className="page-layout">
                <div className="page-header">
                    <div>
                        <h3>{t("page.complaint_list")}</h3>
                    </div>
                    <div className="flex-header">
                        <StyledTableButtonTop
                            type="danger"
                            size="default"
                            text={t("page.add_a_complaint")}
                            onClick={() => history.push(`/complaint/create`)}
                            className="add-btn"
                            icon="plus"
                        />
                    </div>
                </div>
                <div className="search-layout">
                    <div className="status-filter">
                        {complainTypes.map((item, index) => (
                            <div
                                onClick={() => setFilterId(item.status)}
                                className={
                                    filterId === item.status ? "active filter-elem" : "filter-elem"
                                }
                                key={index}
                            >
                                {i18nextLng === enumLanuage.TH ? item.status_th : item.status}
                                <span>{item.count}</span>
                            </div>
                        ))}
                    </div>
                    <ConpliantTableSearch
                        setTextSearch={setTextSearch}
                        handleSearchCategory={handleSearchCategory}
                        divisions={divisions}
                        onChangeDate={onChangeDate}
                        total={total}
                    />
                </div>
                <div className="table">
                    <ScrollContainer vertical={false}>
                        <div className="table_ground">
                            <div className="table-body">
                                <div className="table-header">
                                    <Row
                                        gutter={24}
                                        type="flex"
                                        justify="space-between"
                                        align="middle"
                                        className="header-row"
                                    >
                                        <Col className="col-item" span={5}>
                                            <p>{t("page.conplaint")}</p>
                                            <div className="filter-button">
                                                <ComponentContactSortIcon
                                                    handleSort={handleSort}
                                                    sortBy={enumSortBy.ASC}
                                                    sortField={enumContactSortFields.FULL_NAME}
                                                    searchParams={params}
                                                />
                                                <ComponentContactSortIcon
                                                    handleSort={handleSort}
                                                    sortBy={enumSortBy.DESC}
                                                    sortField={enumContactSortFields.FULL_NAME}
                                                    searchParams={params}
                                                />
                                            </div>
                                        </Col>
                                        <Col className="col-item" span={3}>
                                            <p>{t("page.report_topic")}</p>
                                            <div className="filter-button">
                                                <ComponentContactSortIcon
                                                    handleSort={handleSort}
                                                    sortBy={enumSortBy.ASC}
                                                    sortField={enumContactSortFields.TEL}
                                                    searchParams={params}
                                                />
                                                <ComponentContactSortIcon
                                                    handleSort={handleSort}
                                                    sortBy={enumSortBy.DESC}
                                                    sortField={enumContactSortFields.TEL}
                                                    searchParams={params}
                                                />
                                            </div>
                                        </Col>
                                        <Col className="col-item" span={3}>
                                            <p>{t("page.report_date")}</p>
                                            <div className="filter-button">
                                                <ComponentContactSortIcon
                                                    handleSort={handleSort}
                                                    sortBy={enumSortBy.ASC}
                                                    sortField={enumContactSortFields.POINTS}
                                                    searchParams={params}
                                                />
                                                <ComponentContactSortIcon
                                                    handleSort={handleSort}
                                                    sortBy={enumSortBy.DESC}
                                                    sortField={enumContactSortFields.POINTS}
                                                    searchParams={params}
                                                />
                                            </div>
                                        </Col>
                                        <Col className="col-item" span={3}>
                                            <p>{t("page.status")}</p>
                                            <div className="filter-button">
                                                <ComponentContactSortIcon
                                                    handleSort={handleSort}
                                                    sortBy={enumSortBy.ASC}
                                                    sortField={enumContactSortFields.POINTS}
                                                    searchParams={params}
                                                />
                                                <ComponentContactSortIcon
                                                    handleSort={handleSort}
                                                    sortBy={enumSortBy.DESC}
                                                    sortField={enumContactSortFields.POINTS}
                                                    searchParams={params}
                                                />
                                            </div>
                                        </Col>
                                        <Col className="col-item" span={3}>
                                            <p>{t("page.recipient")}</p>
                                            <div className="filter-button">
                                                <ComponentContactSortIcon
                                                    handleSort={handleSort}
                                                    sortBy={enumSortBy.ASC}
                                                    sortField={enumContactSortFields.CREATED_AT}
                                                    searchParams={params}
                                                />
                                                <ComponentContactSortIcon
                                                    handleSort={handleSort}
                                                    sortBy={enumSortBy.DESC}
                                                    sortField={enumContactSortFields.CREATED_AT}
                                                    searchParams={params}
                                                />
                                            </div>
                                        </Col>
                                        <Col className="col-item" span={3}>
                                            <p>{t("page.manage")}</p>
                                        </Col>
                                    </Row>
                                </div>
                                <DndContext
                                    sensors={sensors}
                                    collisionDetection={closestCenter}
                                    onDragEnd={handleDragEnd}
                                >
                                    <SortableContext
                                        items={conplaints.map((item) => JSON.stringify(item))}
                                        strategy={rectSortingStrategy}
                                    >
                                        {conplaints && conplaints.length > 0 ? (
                                            <div className="data-table">
                                                {conplaints.map((item, index) => (
                                                    <ComponentRowConpliant
                                                        key={index}
                                                        data={item}
                                                        itemJson={JSON.stringify(item)}
                                                        // categories={categories}
                                                        handleEdit={updateNews}
                                                        callbackGetList={callbackGetList}
                                                        index={index}
                                                    />
                                                ))}
                                                <div style={{ width: "100%", height: 10 }}></div>
                                            </div>
                                        ) : (
                                            <ComponentEmptyData />
                                        )}
                                    </SortableContext>
                                </DndContext>
                            </div>
                        </div>
                    </ScrollContainer>
                </div>

                <div className="page-bottom">
                    {total > 0 && (
                        <div className="pagination">
                            <Pagination
                                defaultCurrent={INITIAL_LIST_PARAMS.page || 1}
                                pageSize={PAGINATION}
                                total={total}
                                onChange={handlePagination}
                                current={params.page}
                            />
                        </div>
                    )}
                </div>
            </div>
        </StyledTable>
    );
};
