import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Switch, Col, Row, Avatar, Menu, Dropdown, Button } from "antd";
import { MoreHorizontal } from "react-feather";

import { IDataexample, IGetProductAddonsExample, INewsCategory, IOptionList } from "@interfaces";
import {
    enumNewsType,
    PATH_CREATE_ADDON,
    PATH_PRODUCT_CREATE,
    PATH_SERVICES_CREATE,
    PATH_STOCK_LIST,
    PATH_STORE,
    PATH_STORE_CREATE,
} from "@configs";
import { selectAuth, store } from "@redux";
import { useSelector } from "react-redux";
import { IconDrag, StyledTableButtonTop, SharedInput, ComponentDeleteModal } from "@components";
import { useNotify } from "@utils";
import React, { useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import styled from "styled-components";
import { consumableAPI, productAddonAPI, storeAPI } from "@api";
import { useHistory } from "react-router";
import { IUpdateStore } from "src/Interfaces/store";

interface IProps {
    data: IGetProductAddonsExample;
    itemJson: string;
    handleEdit?: (status: boolean, data: IDataexample) => void;
    callbackGetList: () => void;
    index: number;
    newType?: enumNewsType;
    categories: INewsCategory[];
    productAddons: IGetProductAddonsExample[];
    selectedStore?: string;
}

export const ComponentRowProductAddonList = (props: IProps) => {
    const history = useHistory();

    //hooks
    const { t } = useTranslation();
    const { success, error } = useNotify();
    const organization_id = useSelector(selectAuth).userInfo?.merchant?._id;
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const userPermissionType = useSelector(selectAuth).userInfo?.storeIds;
    //props
    const {
        data,
        handleEdit,
        callbackGetList,
        itemJson,
        newType,
        productAddons,
        index,
        selectedStore,
    } = props;
    const [showInput, setShowInput] = useState<boolean>(false);
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [newBalance, setNewBalance] = useState<string>("");
    // const history = useHistory();

    const { attributes, listeners, setNodeRef, transform } = useSortable({
        id: itemJson,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
    };

    const handleClose = () => {
        setShowPopup(false);
    };

    const handleDelete = async () => {
        try {
            const res = await productAddonAPI.deleteProductAddon({
                organization_id: organization_id,
                product_addon_id: data._id,
            });
            if (res.data.error === "0") {
                handleClose();
                callbackGetList();
                success(t("message.delete.success"));
            } else {
                console.log(res);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const menu = (
        <Menu>
            <Menu.Item
                key={1}
                onClick={() => {
                    setShowPopup(true);
                }}
            >
                <div className="duplicate-btn">{t("page.delete")}</div>
            </Menu.Item>
        </Menu>
    );

    return (
        <StylesProductAddonList>
            <div className="table-element" ref={setNodeRef} style={style}>
                <div className="drag-icon" {...attributes} {...listeners}>
                    <IconDrag />
                </div>
                <div className="table-row">
                    <Row
                        style={{ height: "100%" }}
                        gutter={24}
                        type="flex"
                        align="middle"
                        justify="space-between"
                    >
                        <Col className="col-item" span={8}>
                            <Link to={`${PATH_CREATE_ADDON}/${data._id}`}>
                                <p className="news-title">
                                    {productAddons[index].addon_name || t("page.empty_text")}
                                </p>
                            </Link>
                        </Col>
                        <Col className="col-item" span={6} style={{ placeContent: "center" }}>
                            <p>
                                {productAddons[index].option_list?.length || t("page.empty_text")}
                            </p>
                        </Col>
                        <Col className="col-item" span={6} style={{ placeContent: "center" }}>
                            <p className="statusBadge">
                                {productAddons[index].is_require
                                    ? t("page.request_select")
                                    : t("page.empty_text")}
                            </p>
                        </Col>
                        <Col className="col-item" span={4} style={{ placeContent: "center" }}>
                            <Dropdown
                                //@ts-ignore
                                getPopupContainer={(trigger) => trigger.parentNode}
                                overlay={menu}
                                trigger={["click"]}
                                placement="bottomRight"
                            >
                                <Button style={{ border: "unset" }}>
                                    <MoreHorizontal />
                                </Button>
                            </Dropdown>
                        </Col>
                    </Row>
                </div>
            </div>
            <ComponentDeleteModal
                showPopup={showPopup}
                handleClose={handleClose}
                handleConfirm={handleDelete}
            />
        </StylesProductAddonList>
    );
};
export const StylesProductAddonList = styled.div`
    .table-row {
        height: 60px;
    }
    input {
        height: auto;
    }
    .statusBadge {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 8px 16px;
        gap: 10px;
        background: linear-gradient(0deg, rgba(2, 99, 224, 0.12), rgba(2, 99, 224, 0.12)), #ffffff;
        border-radius: 40px;
        font-weight: 700;
        font-size: 16px !important;
        line-height: 22px;
        color: #0263e0 !important;
    }
`;
