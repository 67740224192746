import { consumableAPI, storeAPI } from "@api";
import { IconMinusCircle, StyledSubmitButton } from "@components";
import { PATH_TRANSFER_REQUEST_CREATE } from "@configs";
import { IConsumableExample, IConsumableStores, IConsumeListPayload } from "@interfaces";
import { selectAuth } from "@redux";
import { theme, useNotify } from "@utils";
import { Button, Col, Input, Modal, Radio, Row, Select, Tabs } from "antd";
import Table, { ColumnProps } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { ArrowRight } from "react-feather";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";

const { Option } = Select;

interface IProps {
    showModal: boolean;
    handleClose: () => void;
}

export const TRBranchModal = (props: IProps) => {
    const { showModal, handleClose } = props;
    const { t } = useTranslation();
    const { TabPane } = Tabs;
    const { error } = useNotify();
    const org_id = useSelector(selectAuth).userInfo?.merchant?._id;
    const [stores, setStores] = useState<any[]>([]);
    const [reqStore, setReqStore] = useState<string>("");
    const [fromStore, setFromStore] = useState<string>("");

    const history = useHistory();
    const confirmStore = () => {
        if (reqStore !== "" && fromStore !== "") {
            history.push(
                PATH_TRANSFER_REQUEST_CREATE +
                "?req=" + reqStore +
                "&from=" + fromStore
            );
        }
    };

    const getStores = async () => {
        try {
            const getStores = await storeAPI.getStoresBindingDropdown({
                organization_id: org_id,
            });
            if (getStores.data.error === "0") {
                setStores(getStores.data.result);
            } else {
                error(getStores.data.msg);
            }
        } catch (err: any) {
            error(err.message);
        }
    };

    useEffect(() => {
        getStores();
    }, []);

    return (
        //@ts-ignore
        <StyledModal
            visible={showModal}
            onCancel={handleClose}
            title={t("page.transfer_request.choose_branch_modal")}
            footer={null}
            width={600}
            closable={true}
        >
            <div className="filter-element">
                <Row gutter={24} type="flex" justify="start" align="middle" className="search-row">
                    <Col className="col-item" span={11}>
                        <p>{t("page.transfer_request.branch_req")}:</p>
                        <Select
                            showSearch
                            filterOption={(input, option) =>
                                //@ts-ignore
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            // onChange={handleSelectStore}
                            onChange={(value: string) => setReqStore(value)}
                            placeholder={t("page.please_choose")}
                        >
                            {stores?.map((sItem, sIndex) => (
                                <Option value={sItem._id} key={sIndex}>
                                    {sItem.store_name}
                                </Option>
                            ))}
                        </Select>

                    </Col>
                    <Col span={2}>
                        <br />
                        <ArrowRight />
                    </Col>
                    <Col className="col-item" span={11}>
                        <p>{t("page.transfer_request.from_branch")}:</p>
                        <Select
                            showSearch
                            filterOption={(input, option) =>
                                //@ts-ignore
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            // onChange={handleSelectStore}
                            onChange={(value: string) => setFromStore(value)}
                            placeholder={t("page.please_choose")}
                        >
                            {stores?.map((sItem, sIndex) => (
                                <Option value={sItem._id} key={sIndex}>
                                    {sItem.store_name}
                                </Option>
                            ))}
                        </Select>
                    </Col>

                </Row>

            </div>
            <Button className="confirm-btn" onClick={confirmStore}>
                Confirm
            </Button>
        </StyledModal >
    );
};
const StyledModal = styled(Modal)`
    .ant-select {
        min-width: 100%;
    }
    .confirm-btn {
        width: 100%;
        margin-top: 20px;
        height: 50px;
        background-color: ${theme.colors.main};
        color: white;
        font-weight: bold;
        font-size: 18px;
    }
    .ant-modal-title {
        padding-bottom: 20px;
    }
    .ant-modal-body {
        padding: 36px 0 0 0 !important;
    }
    .ant-tabs-bar {
        border: none;
    }
    .ant-tabs-nav .ant-tabs-tab-active {
        color: ${theme.colors.main};
    }
    .ant-tabs-ink-bar {
        height: 3px !important;
        background: ${theme.colors.main};
    }
    .ant-tabs-nav .ant-tabs-tab {
        margin: 0 16px 0 0;
        padding: 8px 0;
        text-align: center;
    }
    .ant-tabs-nav .ant-tabs-tab:hover {
        color: ${theme.colors.main};
    }
    .ant-table-body {
        border-radius: 6px;
        border: 0.5px solid #a5a5a5;
        background: #fff;
    }
    .ant-table-thead > tr:first-child > th:last-child {
        border-top-right-radius: 6px;
    }
    .ant-table-thead > tr:first-child > th:first-child {
        border-top-left-radius: 6px;
    }
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }
    .ant-pagination {
        display: flex;
        width: 100%;
        place-content: center;
    }
    .btnRow {
        width: 100%;
        display: flex;
        flex-direction: row;
        place-content: center;
        margin-top: 20px;
        button {
            width: 200px;
            margin: 0;
        }
    }
`;
