import { IGetSup, IGetSupplierList, IPostCreateSupplier, IUpdateSupplier } from "@interfaces";
import storeFrontAxiosClient from "./storeFrontAxiosClient";

export const supplierAPI = {
    getList: (params: IGetSupplierList) => {
        const url = "supplier/getSuppliers";
        return storeFrontAxiosClient.get(url, { params });
    },
    postCreateSup: (params: IPostCreateSupplier) => {
        const url = "supplier/createSupplier";
        return storeFrontAxiosClient.post(url, params);
    },
    getSup: (params: IGetSup) => {
        const url = "supplier/getSupplier";
        return storeFrontAxiosClient.get(url, { params });
    },
    updateSup: (params: IUpdateSupplier) => {
        const url = "supplier/updateSupplier";
        return storeFrontAxiosClient.patch(url, params);
    },
};
