import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Switch, Col, Row, Menu, Dropdown, Button } from "antd";
import { MoreHorizontal } from "react-feather";

import { IGetBillPromotionExample } from "@interfaces";
import { dateFormat, PATH_BILL_PROMOTION_CREATE } from "@configs";
import { selectAuth } from "@redux";
import { useSelector } from "react-redux";
import { useNotify } from "@utils";
import moment from "moment";
import { billPromotionAPI } from "src/API/billPromotion";

interface IProps {
    data: IGetBillPromotionExample;
    handleEdit: (status: boolean, data: IGetBillPromotionExample) => void;
    callbackGetList: () => void;
}

export const ComponentBillRowPromotion = (props: IProps) => {
    const { t } = useTranslation();
    const { success, error } = useNotify();
    const organization_id = useSelector(selectAuth).userInfo?.merchant?._id;
    const { data, handleEdit, callbackGetList } = props;

    const handleDelete = async (item: any) => {
        try {
            const res = await billPromotionAPI.deleteBillPromotion({
                organization_id: organization_id,
                bill_promotion_id: item,
            });
            if (res.data.error === "0") {
                callbackGetList();
                success(t("message.delete.success"));
            }
        } catch (err: any) {
            if (err) {
                error(err.msg);
            } else error(t("message.delete.fail"));
        }
    };

    const menu = (
        <Menu>
            <Menu.Item key={2} onClick={() => handleDelete(data._id)}>
                <div className="duplicate-btn">{t("page.delete")}</div>
            </Menu.Item>
        </Menu>
    );
    const handleUpdateStatus = (e: boolean) => {
        handleEdit(e, data);
    };

    return (
        <div className="table-element">
            <div className="table-row">
                <Row
                    style={{ height: "100%" }}
                    gutter={24}
                    type="flex"
                    align="middle"
                    justify="space-between"
                >
                    <Col className="col-item" span={5}>
                        <Link to={`${PATH_BILL_PROMOTION_CREATE}/${data._id}`}>
                            <p className="news-title">{data.bill_promotion_name}</p>
                        </Link>
                    </Col>
                    <Col className="col-item" span={5}>
                        <p>
                            {moment(data.updated_date).format(dateFormat) || t("page.empty_text")}
                        </p>
                    </Col>
                    <Col className="col-item visible-md" span={5}>
                        <p>{t("page.empty_text")}</p>
                    </Col>
                    <Col className="col-item price" span={5}>
                        <Switch onChange={handleUpdateStatus} checked={data.is_enable} />
                    </Col>
                    <Col className="col-item" span={4}>
                        <Dropdown
                            //@ts-ignore
                            getPopupContainer={(trigger) => trigger.parentNode}
                            overlay={menu}
                            trigger={["click"]}
                            placement="bottomRight"
                        >
                            <Button style={{ border: "unset" }}>
                                <MoreHorizontal />
                            </Button>
                        </Dropdown>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
