import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";
import { IGetImportExcelLogsExample } from "@interfaces";
import { dateFormat, dateFormatHM } from "@configs";
import moment from "moment";

interface IProps {
    data: IGetImportExcelLogsExample;
}

export const ComponentRowExcelHistory = (props: IProps) => {
    const { t } = useTranslation();
    const { data } = props;

    return (
        <Row
            style={{ height: "100%" }}
            gutter={24}
            type="flex"
            align="middle"
            justify="space-between"
        >
            <Col className="col-item" span={3}>
                <p className="body3 news-title">
                    {moment(data.created_date).format(dateFormatHM) || t("page.empty_text")}
                </p>
            </Col>
            <Col className="body3 col-item" span={3}>
                <p>{data.table_name || t("page.empty_text")}</p>
            </Col>
            <Col className="body4 col-item visible-md" span={3}>
                <p>{t("page.empty_text")}</p>
            </Col>
            <Col className="body4 col-item visible-md" span={5} style={{ overflow: "hidden" }}>
                <p>{data.excel_file_original_name || t("page.empty_text")}</p>
            </Col>
            <Col className="body4 col-item visible-md" span={5}>
                <p style={{ overflowWrap: "break-word" }}>{data?.fail_description || t("page.empty_text")}</p>
            </Col>
            <Col className="col-item" span={2} style={{ display: "flex", placeContent: "center" }}>
                <p
                    className="status"
                    style={{
                        background:
                            data.status === "fail"
                                ? "rgba(242, 47, 70, 0.12)"
                                : data.status === "finished"
                                    ? "rgba(73, 197, 73, 0.12)"
                                    : "rgba(73, 197, 73, 0.12)",
                        color:
                            data.status === "fail"
                                ? "#F22F46"
                                : data.status === "finished"
                                    ? "#49C549"
                                    : "#49C549",
                    }}
                >
                    {data.status || t("page.empty_text")}
                </p>
            </Col>
        </Row>
    );
};
