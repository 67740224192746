import { useState, useRef, useEffect } from "react";
import {
    ComponentContactSortIcon,
    StyledTable,
    ComponentEmptyData,
    StyledTableButtonTop,
    ComponentTableSearch,
    ComponentRowService,
    ComponentRowPurchaseOrder,
    IconAllPO,
    IconDone,
    IconRenew,
    IconCancel,
    ComponentTablePOSearch,
    IconPending,
    ComponentRowSupplier,
} from "@components";
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    TouchSensor,
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    rectSortingStrategy,
    //@ts-ignore
} from "@dnd-kit/sortable";
import { Row, Col, Pagination, Button } from "antd";
import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";
import {
    enumSortBy,
    enumContactSortFields,
    PAGINATION,
    PAGE_START,
    enumNewsStatus,
    INITIAL_LIST_PARAMS,
    PATH_PRODUCT_CREATE,
    PATH_PURCHASE_ORDER_CREATE,
    PATH_SUPPLIER_CREATE,
} from "@configs";
import { IPaginationNext, INews, IDataexample, IStoreSelect, IDetailSummary } from "@interfaces";
import { useDispatch, useSelector } from "react-redux";
import { getCategories, selectAuth, selectContact, selectOrg, setLoading } from "@redux";
import { useNotify } from "@utils";
import { useHistory } from "react-router";
import axios from "axios";
import styled from "styled-components";
import { POAPI, orgApi, productAPI, storeAPI, supplierAPI } from "@api";

const INITAL_PAGE = {
    xpage: PAGE_START,
    xlimit: PAGINATION,
};

const initDetailSum: IDetailSummary = {
    all_count: 0,
    canceled_count: 0,
    finished_count: 0,
    pending_count: 0,
};
export const PageSupplierList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const search_params = useSelector(selectContact).paramsContact;
    const [params, setParams] = useState(search_params);
    const org_id = useSelector(selectAuth).userInfo?.merchant._id;
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;

    const categories = useSelector(selectOrg).category;
    const [textSearch, setTextSearch] = useState<string>("");
    const [category, setCategory] = useState<any>([]);
    const [subCategory, setSubCategory] = useState<any>([]);

    const [searchDate, setSearchDate] = useState<string[]>([]);
    const [catSearch, setCatSearch] = useState<string>("");
    const [tempCatID, setTempCatID] = useState<string>("");
    const [status, setStatus] = useState<enumNewsStatus>(enumNewsStatus.ALL);
    const [filterStatus, setFilterStatus] = useState<boolean>(true);
    const [pagination, setPagination] = useState<IPaginationNext>(INITAL_PAGE);
    const [services, setServices] = useState<IDataexample[]>([]);
    const [total, setTotal] = useState<number>(0);
    const { success, error } = useNotify();
    const history = useHistory();
    const [Refresh, setRefresh] = useState<any>(0);
    const [tempRefresh, setTempRefresh] = useState(0);
    const refreshPlease = 0;
    const userInfo = useSelector(selectAuth).userInfo;
    const [pOList, setPOList] = useState([]);
    const [storeList, setStoreList] = useState<IStoreSelect[]>([]);
    const [detailSum, setDetailSum] = useState<IDetailSummary>(initDetailSum);
    const [supList, setSupList] = useState([]);

    let tempServices: any = [];
    useEffect(() => {
        fetchSupplierList();
    }, [Refresh, textSearch, tempCatID, pagination]);

    useEffect(() => {
        handleSort;
    }, [tempRefresh]);

    const fetchSupplierList = async () => {
        dispatch(setLoading(true));
        try {
            // const res = await POAPI.getPOGRList({
            //     organization_id: userInfo?.merchant?._id || "",
            //     xpage: pagination.xpage,
            //     xlimit: pagination.xlimit,
            //     // store_id: "",
            //     // supplier_id: "",
            //     // status: "pending",
            //     // text_search: "",
            // });
            const res = await supplierAPI.getList({
                organization_id: userInfo?.merchant?._id || "",
                xpage: pagination.xpage,
                xlimit: pagination.xlimit,
            });
            if (res.data.error === "0") {
                setSupList(res.data.result);
                setTotal(res.data.total);
            }
        } catch (e) {
            console.log(e);
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleSort = (sortField: enumContactSortFields, sortBy: enumSortBy) => {
        const newSortParams = { ...params, sortField, sortBy };
        setParams(newSortParams);
        // fetchContacts(newSortParams);
        setServices(services.reverse());
        setTempRefresh(2);
    };

    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            const swapItem = (items: IDataexample[]) => {
                const oldIndex = items.findIndex((item) => JSON.stringify(item) === active.id);
                const newIndex = items.findIndex((item) => JSON.stringify(item) === over.id);
                return arrayMove(items, oldIndex, newIndex);
            };
            setServices(swapItem);
        }
    };
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
        useSensor(TouchSensor)
    );
    const handleSearchCategory = (value) => {
        setCatSearch(value);
        setRefresh(0);
    };
    const onChangeDateRange = (dates, dateStrings: [string, string]) => {
        setSearchDate(dateStrings);
    };
    const handlePagination = (page) => {
        setPagination({ ...pagination, xpage: page });
    };

    return (
        <StyledTable>
            <div className="page-layout">
                <div className="page-header-layout">
                    <div className="page-header">
                        <div>
                            <h3>{t("page.supplier.list_title")}</h3>
                            <span>
                                ({supList?.length} {t("page.supplier.list_title")})
                            </span>
                        </div>
                        <div className="flex-header">
                            <StyledTableButtonTop
                                type="danger"
                                size="default"
                                text={t("page.supplier.add_supplier")}
                                onClick={() =>
                                    history.push({
                                        pathname: PATH_SUPPLIER_CREATE,
                                    })
                                }
                                className="add-btn"
                                icon="plus"
                            />
                        </div>
                    </div>
                </div>
                <div className="page-table-layout">
                    <div className="table">
                        <ScrollContainer vertical={false}>
                            <div className="table_ground">
                                <div className="table-body">
                                    <div className="table-header">
                                        <Row
                                            gutter={24}
                                            type="flex"
                                            justify="space-between"
                                            align="middle"
                                            className="header-row"
                                        >
                                            <Col className="col-item" span={4}>
                                                <p>{t("page.supplier.table_name_title")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.FULL_NAME}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.FULL_NAME}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={4}>
                                                <p>{t("page.supplier.table_code_title")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.POINTS}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.POINTS}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={6}>
                                                <p>{t("page.supplier.table_address_title")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.CREATED_AT}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.CREATED_AT}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={3}>
                                                <p>{t("page.supplier.table_tax_title")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.CREATED_AT}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.CREATED_AT}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={3}>
                                                <p>{t("page.supplier.table_credit_title")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.CREATED_AT}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.CREATED_AT}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={2}>
                                                <p>{t("page.supplier.table_vat_title")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.CREATED_AT}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.CREATED_AT}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={2}>
                                                <p>{t("page.manage")}</p>
                                            </Col>
                                        </Row>
                                    </div>
                                    <DndContext
                                        sensors={sensors}
                                        collisionDetection={closestCenter}
                                        onDragEnd={handleDragEnd}
                                    >
                                        <SortableContext
                                            items={services.map((item) => JSON.stringify(item))}
                                            strategy={rectSortingStrategy}
                                        >
                                            {supList && supList?.length > 0 ? (
                                                <div className="data-table">
                                                    {supList.map((item, index) => (
                                                        <ComponentRowSupplier
                                                            key={index}
                                                            data={item}
                                                            itemJson={JSON.stringify(item)}
                                                            index={index}
                                                            storeList={storeList}
                                                        />
                                                    ))}
                                                    <div
                                                        style={{ width: "100%", height: 10 }}
                                                    ></div>
                                                </div>
                                            ) : (
                                                <ComponentEmptyData />
                                            )}
                                        </SortableContext>
                                    </DndContext>
                                </div>
                            </div>
                        </ScrollContainer>
                    </div>
                </div>

                <div className="page-bottom">
                    {total > 0 && (
                        <div className="pagination">
                            <Pagination
                                defaultCurrent={INITIAL_LIST_PARAMS.page || 1}
                                pageSize={PAGINATION}
                                total={total}
                                onChange={handlePagination}
                                current={pagination.xpage}
                            />
                        </div>
                    )}
                </div>
            </div>
        </StyledTable>
    );
};
