import React, { useEffect, memo, useState, useRef, useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Row, Col, Select, Radio, Input, Table } from "antd";
import styled from "styled-components";
import {
    INewsCategory,
    IPostCreateSupplier,
    IPostSupByConsumeList,
    IUpdateSupplier,
} from "@interfaces";
import { StyledSubmitButton, StyledCancelButton, StyledCard } from "@components";
import { useYup } from "@validations";
import { getCategories, setLoading, selectAuth, store, openToast } from "@redux";
import { showErrorMessage, useNotify } from "@utils";
import { PATH_SUPPLIER, PATH_SUPPLIER_CREATE } from "@configs";
import { POAPI, supplierAPI } from "@api";
import { ColumnProps } from "antd/lib/table";
import TextArea from "antd/lib/input/TextArea";

interface IProps {
    supplier: any;
}

const initSup: IPostCreateSupplier = {
    organization_id: "",
    supplier_code: "",
    supplier_name: "",
    tax_id: "",
    address: "",
    vat_type: "include",
    vat_percent: "",
    credit_term_in_day: 0,
    supplier_type: "internal",
};

const { Option } = Select;
export const ModuleSupplierForm = memo((props: IProps) => {
    //hook
    const { YupNews } = useYup();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const { error, success, warning } = useNotify();
    const [step, setStep] = useState(1);
    const pathName = window.location.pathname;
    const [focusStore, setFocusStore] = useState<string>("");
    const [consumeList, setConsumeList] = useState<any>([]);

    const { supplier } = props;
    const [isActive, setIsActive] = useState<boolean>();
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const org_id = useSelector(selectAuth).userInfo?.merchant?._id;
    const userInfo = useSelector(selectAuth).userInfo;
    const [supplierList, setSupplierList] = useState<any[]>([]);
    const [selectSup, setSelectSup] = useState<any[]>([]);
    const [isDefault, setIsDefault] = useState<boolean>(false);
    const [supValue, setSupValue] = useState<IPostCreateSupplier>(initSup);
    const [show, setShow] = useState({
        vat: true,
        credit: true,
    });
    useEffect(() => {
        // fetchStoreList();
        if (supplier) {
            setIsDefault(supplier.is_default);
            let value: IPostCreateSupplier = {
                organization_id: org_id || "",
                supplier_code: supplier?.supplier_code,
                supplier_name: supplier?.supplier_name,
                tax_id: supplier?.tax_id,
                address: supplier?.address,
                vat_type: supplier?.vat_type,
                vat_percent: supplier?.vat_percent.toString(),
                credit_term_in_day: supplier?.credit_term_in_day,
                supplier_type: supplier?.supplier_type,
            };
            setSupValue({
                ...value,
            });
        }
    }, [supplier]);

    const { Option } = Select;

    const handleCancel = () => {
        history.goBack();
    };

    // create new sgtate update on it state

    // Branch Table
    const handleSaveBtn = () => {
        if (pathName === PATH_SUPPLIER_CREATE) {
            onCreateSup();
        } else {
            onUpdateSup();
            // update
        }
    };
    const onUpdateSup = async () => {
        let payload: IUpdateSupplier = {
            organization_id: supValue?.organization_id,
            supplier_id: supplier?._id,
            supplier_code: supValue?.supplier_code,
            supplier_name: supValue?.supplier_name,
            tax_id: supValue?.tax_id,
            address: supValue?.address,
            vat_type: supValue?.vat_type,
            vat_percent: supValue?.vat_percent,
            credit_term_in_day: supValue?.credit_term_in_day,
            supplier_type: supValue?.supplier_type,
        };
        if (show.credit === false) {
            payload.credit_term_in_day = 0;
        }
        try {
            dispatch(setLoading(true));
            const res = await supplierAPI.updateSup(payload);
            if (res.data.error === "0") {
                success(res.data.msg);
                history.push(PATH_SUPPLIER);
            } else {
                error(res.data.msg);
            }
        } catch (err: any) {
            if (err.response) {
                warning(showErrorMessage(err.response));
                dispatch(setLoading(false));
            } else warning(t("message.not_found", { name: t("object._reward") }));
        } finally {
            dispatch(setLoading(false));
        }
    };
    const onCreateSup = async () => {
        let payload: IPostCreateSupplier = supValue;
        payload.organization_id = userInfo?.merchant?._id || "";
        if (show.credit === false) {
            payload.credit_term_in_day = 0;
        }
        try {
            dispatch(setLoading(true));
            const res = await supplierAPI.postCreateSup(payload);
            if (res.data.error === "0") {
                store.dispatch(
                    openToast({
                        message: res.data.msg,
                        type: "success",
                        autoHideDuration: 3000,
                    })
                );
                history.push(PATH_SUPPLIER);
            } else {
                store.dispatch(
                    openToast({
                        message: res.data.msg,
                        type: "error",
                        autoHideDuration: 3000,
                    })
                );
            }
        } catch (err: any) {
            if (err.response) {
                warning(showErrorMessage(err.response));
                dispatch(setLoading(false));
            } else warning(t("message.not_found", { name: t("object._reward") }));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const changeText = (e: any) => {
        let arr = supValue;
        arr[e.target.name] = e.target.value;
        setSupValue({ ...arr });
    };
    const changeNumber = (e: any) => {
        if (!isNaN(+e.target.value)) {
            let arr = supValue;
            arr[e.target.name] = e.target.value;
            setSupValue({ ...arr });
        }
    };
    const changeVatType = (value: string) => {
        let arr = supValue;
        arr.vat_type = value;
        setSupValue({ ...arr });
    };

    const changeType = (value: string) => {
        let arr = supValue;
        arr.supplier_type = value;
        setSupValue({ ...arr });
    };

    return (
        <div className="form-wrap">
            <form>
                <div className="page-header">
                    <h3>
                        {pathName === PATH_SUPPLIER_CREATE
                            ? t("page.supplier.create_sup_title")
                            : t("page.supplier.edit_sup_title")}
                    </h3>
                </div>
                <div className="page-body">
                    <div className="card-wrap">
                        <div className="card-wrap">
                            <CustomStyles>
                                <StyledCard>
                                    <div className="title" style={{ fontSize: "25px" }}>
                                        {t("page.supplier.sup_setting")}
                                    </div>
                                    <Row gutter={24}>
                                        <Col span={24}>
                                            <div className="input-box">
                                                <p className="input-title">
                                                    {t("page.supplier.sup_name")}
                                                </p>
                                                <Input
                                                    value={supValue.supplier_name}
                                                    name="supplier_name"
                                                    onChange={changeText}
                                                    placeholder={t("page.supplier.sup_name")}
                                                />
                                            </div>
                                        </Col>
                                        <Col span={12}>
                                            <div className="input-box">
                                                <p className="input-title">
                                                    {t("page.supplier.sup_type")}
                                                </p>
                                                <Select
                                                    showSearch
                                                    filterOption={(input, option) =>
                                                        //@ts-ignore
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    value={supValue.supplier_type}
                                                    placeholder={t("page.please_choose")}
                                                    onChange={changeType}
                                                    disabled={isDefault}
                                                >
                                                    <Option value="internal">
                                                        {t("page.supplier.internal")}
                                                    </Option>
                                                    <Option value="external">
                                                        {t("page.supplier.external")}
                                                    </Option>
                                                </Select>
                                            </div>
                                        </Col>
                                        <Col span={24}>
                                            <div className="input-box">
                                                <p className="input-title">
                                                    {t("page.supplier.tax_id")}
                                                </p>
                                                <Input
                                                    value={supValue.tax_id}
                                                    name="tax_id"
                                                    onChange={changeText}
                                                    placeholder={t("page.supplier.tax_id")}
                                                />
                                            </div>
                                        </Col>
                                        <Col span={24}>
                                            <div className="input-box">
                                                <p className="input-title">
                                                    {t("page.supplier.sup_code")}
                                                </p>
                                                <Input
                                                    value={supValue.supplier_code}
                                                    name="supplier_code"
                                                    onChange={changeText}
                                                    placeholder={t("page.supplier.sup_code")}
                                                />
                                            </div>
                                        </Col>
                                        <Col span={24}>
                                            <div className="input-box">
                                                <p className="input-title">
                                                    {t("page.supplier.address")}
                                                </p>
                                                <TextArea
                                                    maxLength={2000}
                                                    // onChange={(e) => {
                                                    //     changeSupNote(e, sItem, sIndex);
                                                    // }}
                                                    name="address"
                                                    onChange={changeText}
                                                    value={supValue.address}
                                                    style={{ height: 120, resize: "none" }}
                                                />
                                            </div>
                                        </Col>
                                        <Col span={12}>
                                            <div className="input-box">
                                                <p className="input-title">
                                                    {t("page.supplier.vat")}
                                                </p>
                                                {/* <Input value={supValue.vat_type} /> */}
                                                <Select
                                                    showSearch
                                                    filterOption={(input, option) =>
                                                        //@ts-ignore
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    // onChange={handleSearchCategory}
                                                    value={supValue.vat_type}
                                                    placeholder={t("page.please_choose")}
                                                    onChange={changeVatType}
                                                >
                                                    <Option value="include">
                                                        {t("page.supplier.include_vat")}
                                                    </Option>
                                                    <Option value="exclude">
                                                        {t("page.supplier.exclude_vat")}
                                                    </Option>
                                                </Select>
                                            </div>
                                        </Col>
                                        <Col span={6}>
                                            <div className="input-box">
                                                <p className="input-title">{` `}</p>
                                                <Input
                                                    value={supValue.vat_percent}
                                                    name="vat_percent"
                                                    onChange={changeNumber}
                                                    suffix="%"
                                                />
                                            </div>
                                        </Col>
                                        <Col span={24}>
                                            <div className="input-box">
                                                <p className="input-title">
                                                    {t("page.supplier.credit")}
                                                </p>
                                                <span className="radio-item">
                                                    <Radio
                                                        checked={show.credit === true}
                                                        onClick={() => {
                                                            setShow({
                                                                ...show,
                                                                credit: true,
                                                            });
                                                        }}
                                                    />
                                                    {t("page.supplier.yes")}
                                                </span>
                                                <span>
                                                    <Radio
                                                        checked={show.credit === false}
                                                        onClick={() => {
                                                            setShow({
                                                                ...show,
                                                                credit: false,
                                                            });
                                                        }}
                                                    />
                                                    {t("page.supplier.no")}
                                                </span>
                                            </div>
                                        </Col>
                                        {show.credit && (
                                            <Col span={6}>
                                                <div className="input-box">
                                                    <p className="input-title">
                                                        {t("page.supplier.credit_day")}
                                                    </p>
                                                    <Input
                                                        value={supValue.credit_term_in_day}
                                                        name="credit_term_in_day"
                                                        onChange={changeNumber}
                                                        suffix={t("page.supplier.day")}
                                                    />
                                                </div>
                                            </Col>
                                        )}
                                    </Row>
                                </StyledCard>
                            </CustomStyles>
                            <div className="btn-layout">
                                <StyledCancelButton
                                    type="sub"
                                    text={t("page.back")}
                                    htmlType="button"
                                    onClick={handleCancel}
                                />
                                <StyledSubmitButton
                                    type="default"
                                    text={t("page.save")}
                                    htmlType="button"
                                    onClick={handleSaveBtn}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
});
const CustomStyles = styled.div`
    .branchListTitle {
        font-size: 16px;
        font-weight: 700;
        color: #000;
    }
    .branchListSection {
        margin-top: 20px;
    }
    .branchListSection .antd-btn-custom {
        margin-left: 0;
    }
    .selectBranchBox {
        position: absolute;
        background: #fff;
        margin-top: 10px;
        z-index: 999;
    }
    .ant-checkbox-wrapper {
        width: 100%;
    }
    .input-box {
        margin-top: 30px;
        .input-title {
            font-weight: 700;
            font-size: 16px;
            margin-bottom: 5px;
            height: 25px;
        }
        input {
            border-color: #d9d9d9 !important;
            box-shadow: none !important;
            height: 48px;
        }
        .radio-item {
            margin-right: 30px;
        }
    }
`;
