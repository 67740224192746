export default {
    user: "User",
    contact: "Contact",
    loyalty_rules: "Loyalty rules",
    points: "Points",
    _points: "points",
    time_expiry: "Time expiry",
    image: "Image",
    initial_point: "Initial Points",
    need_one_rule: "You need at least one loyalty rule",
    require_rule: "need loyalty rule",
    membership: "Membership",
    baht_spent: "Baht spent",
    membership_name: "Membership name",
    benefits: "Benefits",
    name: "Name",
    phone_number: "Phone number",
    email: "Email",
    sales: "Sales",
    phoneNumber: "Phone number",
    date_of_birth: "Date of birth",
    benefit: "Benefit",
    role: "Role",
    _contact_name: "contact name",
    _contact: "contact",
    _user: "user",
    webhook: "Webhook",
    reward_code: "Reward code",
    quantity_limit: "Quantity limit",
    quantity_limit_per_user: "Quantity limit per user",
    description: "Description",
    this_field: "This field",
    _segment: "segment",
    _this: "this",
    _benefit: "benefit",
    _reward: "reward",
    access_token: "Access token",
    channel_ID: "Channel ID",
    channel_secret: "Channel secret",
    liff_Url: "LIFF URL",
    field_name: "Field name",
    property_name: "Property name",
    option_name: "Option name",
    tag: "Tag",
    _coupon: "coupon",
    _free_point: "free point",
    _traffic_source: "traffic source",
    point_threshold: "Point threshold",
};
