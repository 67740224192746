import { PAGE_START, PAGINATION, enumDrawerPlacement } from "@configs";
import { Avatar, Button, Row, Select, Switch, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { DeleteOutlined } from "@ant-design/icons";
import {
    InfoIcon,
    IconCamera,
    SharedInput,
    StyledSubmitButton,
    StyledCancelButton,
    SharedComponentDrawer,
    ComponentProductExcludeModal,
    BranchIcon,
} from "@components";
import { formatMessage, theme, useNotify } from "@utils";
import { IMAGE_TYPE, enumValidation } from "@configs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth } from "@redux";
import { orgApi, storeAPI } from "@api";
import { UomAPI } from "src/API/uom";
import { IGetUomsExample } from "@interfaces";
import styled from "styled-components";
const textString = "390px × 270px Maximum 10MB";

interface IProps {
    showModal: boolean;
    handleClose: () => void;
    editItem?: any;
}

const INITAL_PAGE = {
    xpage: PAGE_START,
    xlimit: PAGINATION,
};


export const ComponentProductDrawer = (props: IProps) => {
    const { showModal, handleClose, editItem } = props;
    const { t } = useTranslation();
    const { success, error } = useNotify();
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const orgID = useSelector(selectAuth).userInfo?.merchant._id;
    const [CatName, setCatName] = useState<string>("");
    const [code, setCode] = useState<string>("");
    const [CategoryUnitList, setCategoryUnitList] = useState<IGetUomsExample[]>([]);
    const [uomID, setUomID] = useState<string>("");
    const [categoryImage, setCategoryImage] = useState<string>("");
    const dispatch = useDispatch();
    const { Option } = Select;
    const [selectStoreList, setSelectStoreList] = useState<string[]>([]);
    const [showStorList, setShowStoreList] = useState<any[]>([]);
    const [excludeModal, setExcludeModal] = useState<boolean>(false);

    const getUoms = async () => {
        try {
            const get = await UomAPI.getUoms({
                organization_id: orgID,
                xpage: 1,
                xlimit: 100,
            });
            setCategoryUnitList(get.data.result);
        } catch (err: any) {
            error(err.message);
        }
    };

    useEffect(() => {
        fetchStoreList();
    }, []);

    useEffect(() => {
        getUoms();
        if (editItem) {
            setCatName(editItem.category);
            setUomID(editItem.uom_id);
            setCode(editItem.code);
            setSelectStoreList(editItem?.excluded_store_id_list || []);
        } else {
            setCatName("");
            setUomID("");
            setCode("");
            setSelectStoreList([]);
        }
    }, [editItem]);

    const fetchStoreList = async () => {
        const res = await storeAPI.getList({
            organization_code: orgCode,
            xpage: INITAL_PAGE.xpage,
            xlimit: 4 * INITAL_PAGE.xlimit,
        });
        if (res.data.error === "0") {
            setShowStoreList(res.data.result);
        }
    };
    const handleRemoveImage = () => {
        //
    };

    const handleChangeImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
        try {
            //@ts-ignore
            const image = e.target.files[0];
            if (!image) {
                error(t("validation.image.empty"));
                return;
            }
            if (!IMAGE_TYPE.some((imageType) => imageType === image.type)) {
                error(t("validation.image.wrong_type"));
                return;
            }
            if (image.size >= enumValidation.MAX_FILE_SIZE) {
                error(t("validation.image.max_size"));
                return;
            }
            const formData = new FormData();
            formData.append("file", image);
            const res = await orgApi.uploadImage(formData);
            setCategoryImage(res.data.result.publicUrl);
        } catch (err: any) {
            if (err) {
                error(err.msg);
            } else {
                error(t("page.image_error"));
            }
        }
    };
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCatName(e.target.value);
    };

    const handleSave = async () => {
        try {
            if (editItem) {
                const updateCat = await orgApi.updateProductCategory({
                    organization_code: orgCode,
                    product_category_id: editItem._id,
                    category: CatName,
                    is_enable: editItem.is_enable,
                    uom_id: uomID,
                    code: code,
                    excluded_store_id_list: selectStoreList,
                });
                if (updateCat.data.error === "0") {
                    handleClose();
                    success(t("message.update.success"));
                    setCatName("");
                }
            } else {
                const res = await orgApi.createProductCategory({
                    organization_code: orgCode,
                    category: CatName,
                    is_enable: true,
                    sub_category: [],
                    uom_id: uomID,
                    code: code,
                    excluded_store_id_list: selectStoreList,
                });
                if (res.data.error === "0") {
                    handleClose();
                    success(t("message.create.success"));
                    setCatName("");
                } else {
                    error(formatMessage(res));
                }
            }
        } catch (err: any) {
            error(err.message);
        }
    };
    return (
        <SharedComponentDrawer
            title={t("page.add_category")}
            placement={enumDrawerPlacement.RIGHT}
            onClose={handleClose}
            visible={showModal}
            width={538}
        >
            <StyledBody>
                <div className="avatar">
                    <div className="box_title">
                        <p>{t("page.image_main_category")}</p>
                        <Tooltip placement="topLeft" title={`${textString}`}>
                            <Button>
                                <InfoIcon />
                            </Button>
                        </Tooltip>
                    </div>
                    <div className="avatar-layout">
                        <Avatar size={110} src={categoryImage} shape="square" />
                        <div className="remove-icon-layout">
                            <DeleteOutlined
                                style={{ color: "white", fontSize: 20 }}
                                onClick={handleRemoveImage}
                            />
                        </div>
                    </div>
                    <input id="file_update_img" type="file" hidden onChange={handleChangeImage} />
                    <label className="camera" htmlFor="file_update_img">
                        <IconCamera />
                    </label>
                </div>
                <Row>
                    <SharedInput
                        label={t("page.category")}
                        onChange={handleChange}
                        value={CatName}
                        parentClassName="mb-6"
                    />
                </Row>
                <Row>
                    <SharedInput
                        label={t("page.code")}
                        onChange={(e) => setCode(e.target.value)}
                        value={code}
                        parentClassName="mb-6"
                    />
                </Row>
                <Row>
                    <label className="label">
                        <span className="label-name">{t("page.category_unit")}</span>
                    </label>
                    <Select
                        showSearch
                        filterOption={(input, option) =>
                            //@ts-ignore
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        style={{ width: "100%", height: 48 }}
                        placeholder={t("page.category_unit")}
                        onChange={(value: string) => {
                            setUomID(value);
                        }}
                        value={uomID}
                    >
                        {CategoryUnitList?.map((category, index) => {
                            return (
                                <Option key={index} value={category._id}>
                                    {category.uom_name}
                                </Option>
                            );
                        })}
                    </Select>
                </Row>
                <Row>
                    <label className="label">
                        <br />
                        <span className="label-name">Display on All Store</span>
                    </label>

                    <Switch
                        className="ant-switch"
                        onChange={(e) => {
                            if (e) {
                                setSelectStoreList([]);
                            } else {
                                setExcludeModal(true);
                            }

                        }}
                        checked={selectStoreList.length === 0}
                    />
                    <ComponentProductExcludeModal
                        showPopup={excludeModal}
                        handleClose={() => {
                            setExcludeModal(false);
                        }}
                        allStore={showStorList}
                        product={""}
                        selectStore={selectStoreList}
                        setSelectStore={setSelectStoreList}
                    />
                    <br />
                    {selectStoreList.length > 0 && <Button
                        style={{
                            height: "42px",
                            border: "0.5px solid #fff",
                            borderRadius: "4px",
                            background: theme?.colors?.main,
                            color: theme?.colors?.white,
                            marginRight: "10px",
                        }}
                        onClick={() => setExcludeModal(true)}
                    >
                        <BranchIcon /> &nbsp;
                        {t("page.select_branch")}
                    </Button>
                    }

                </Row>
                <div className="btn-layout">
                    <StyledSubmitButton
                        type="default"
                        text={t("page.save")}
                        htmlType="button"
                        // disable={isSubmitting}
                        onClick={handleSave}
                    />
                    <StyledCancelButton
                        type="sub"
                        text={t("page.cancel")}
                        htmlType="button"
                        onClick={handleClose}
                    />
                </div>
            </StyledBody>
        </SharedComponentDrawer>
    );
};

const StyledBody = styled.div`
    label {
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 21px;
        color: #000000;
        margin-bottom: 8px;
        display: inline-block;
    }
    .label-name {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
        color: #000000;
        display: inline-block;
        margin: 0;
    }
    .ant-switch {
        margin-left: 12px;
    }
`;
