export const IconAllPO = () => (
    <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_8366_461958)">
            <rect
                x="4"
                y="4"
                width="42"
                height="42"
                rx="12"
                fill="url(#paint0_linear_8366_461958)"
                shapeRendering="crispEdges"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.81 15H29.191C32.28 15 34 16.78 34 19.83V30.16C34 33.26 32.28 35 29.191 35H20.81C17.77 35 16 33.26 16 30.16V19.83C16 16.78 17.77 15 20.81 15ZM21.08 19.66V19.65H24.069C24.5 19.65 24.85 20 24.85 20.429C24.85 20.87 24.5 21.22 24.069 21.22H21.08C20.649 21.22 20.3 20.87 20.3 20.44C20.3 20.01 20.649 19.66 21.08 19.66ZM21.08 25.74H28.92C29.35 25.74 29.7 25.39 29.7 24.96C29.7 24.53 29.35 24.179 28.92 24.179H21.08C20.649 24.179 20.3 24.53 20.3 24.96C20.3 25.39 20.649 25.74 21.08 25.74ZM21.08 30.31H28.92C29.319 30.27 29.62 29.929 29.62 29.53C29.62 29.12 29.319 28.78 28.92 28.74H21.08C20.78 28.71 20.49 28.85 20.33 29.11C20.17 29.36 20.17 29.69 20.33 29.95C20.49 30.2 20.78 30.35 21.08 30.31Z"
                fill="white"
            />
        </g>
        <defs>
            <filter
                id="filter0_d_8366_461958"
                x="0"
                y="0"
                width="58"
                height="58"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dx="4" dy="4" />
                <feGaussianBlur stdDeviation="4" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.423529 0 0 0 0 0.439216 0 0 0 0 0.517647 0 0 0 0.2 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_8366_461958"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_8366_461958"
                    result="shape"
                />
            </filter>
            <linearGradient
                id="paint0_linear_8366_461958"
                x1="2.425"
                y1="-0.200001"
                x2="33.2014"
                y2="42.2787"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FBFBFB" />
                <stop offset="0.578368" stopColor="#A8ABB6" />
                <stop offset="1" stopColor="#6C7084" />
            </linearGradient>
        </defs>
    </svg>
);
