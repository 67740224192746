import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Col, Row, Button } from "antd";
import { IGetCheckStockDocsExample, IGetVatDetailExample } from "@interfaces";
import { dateFormatHM, PATH_STOCK, PATH_WASTE } from "@configs";
import styled from "styled-components";
import { useHistory } from "react-router";
import moment from "moment";
import { ApprovedIcon } from "../icon/checkStock";
import xlsx from "json-as-xlsx";
interface IProps {
    data: IGetCheckStockDocsExample;
    vatDetail?: IGetVatDetailExample;
}
interface ITableData {
    name: string;
    check_stock_amount: number;
    current_amount: number;
    different_amount: number;
}
interface IExcelFormat {
    sheet: string;
    columns: ILableHeader[];
    content: ITableData[];
}
export interface ILableHeader {
    label: string;
    value: string;
}
export const ComponentRowCheckStockStart = (props: IProps) => {
    const history = useHistory();
    const { t } = useTranslation();
    const { data, vatDetail } = props;
    const pathName = window.location.pathname;

    const excelHeader = [
        // { label: "Date Range", value: "" },
        // { label: range, value: "" },
        { label: "name", value: "name" },
        { label: "check stock amount", value: "check_stock_amount" },
        { label: "current amount", value: "current_amount" },
        { label: "different amount", value: "different_amount" },
    ];
    const xlsxSettings = {
        fileName: (pathName.includes(PATH_WASTE) ? "waste record" : "check stock"),
        extraLength: 3,
        writeMode: "writeFile",
        writeOptions: {},
        RTL: false,
    };
    const clickExport = async () => {
        await null;
        // consumable name
        // check stock amount
        // current amount
        // different amount
        let arr: ITableData[] = [];
        if (data?.check_consumable_list?.length !== 0) {
            data?.check_consumable_list?.forEach((cItem, cIndex) => {
                let item: ITableData = {
                    name: cItem?.consumable_name,
                    check_stock_amount: Number((cItem?.grand_total_restock_qty).toFixed(2)),
                    current_amount: Number((cItem?.before_restock_qty).toFixed(2)),
                    different_amount: Number((cItem?.restock_change_qty).toFixed(2)),
                };
                arr.push(item);
            });
        } else {
            data?.check_product_list?.forEach((cItem, cIndex) => {
                cItem?.check_variant_list?.forEach((vItem, vIndex) => {
                    let item: ITableData = {
                        name: vItem?.variant_name,
                        check_stock_amount: Number((vItem?.grand_total_restock_qty).toFixed(2)),
                        current_amount: Number((vItem?.before_restock_qty).toFixed(2)),
                        different_amount: Number((vItem?.restock_change_qty).toFixed(2)),
                    };
                    arr.push(item);
                });
                // let item: ITableData = {
                //     name: cItem?.product_name,
                //     check_stock_amount: Number((cItem?.grand_total_restock_qty).toFixed(2)),
                //     current_amount: Number((cItem?.before_restock_qty).toFixed(2)),
                //     different_amount: Number((cItem?.restock_change_qty).toFixed(2)),
                // };
                // arr.push(item);
            });
        }

        let eData: IExcelFormat[] = [
            {
                sheet: (pathName.includes(PATH_WASTE) ? "waste record" : "check stock"),
                columns: excelHeader,
                content: arr,
            },
        ];
        // @ts-ignore
        await xlsx(eData, xlsxSettings);
    };

    return (
        <StylesCheckStockStart>
            <div className="table-element">
                <div className="table-row">
                    <Row
                        style={{ height: "100%" }}
                        gutter={24}
                        type="flex"
                        align="middle"
                        justify="space-between"
                    >
                        <Col className="col-item" span={4}>
                            <Link
                                to={`${data.is_waste === false ? PATH_STOCK : PATH_WASTE}/${data._id
                                    }`}
                            >
                                <p className="news-title">
                                    {moment(data.created_date).format(dateFormatHM) ||
                                        t("page.empty_text")}
                                </p>
                            </Link>
                        </Col>
                        <Col
                            className="col-item"
                            span={
                                vatDetail &&
                                    (vatDetail.is_enable_check_store_reason ||
                                        vatDetail?.is_enable_waste_record_reason)
                                    ? 4
                                    : 5
                            }
                            style={{ placeContent: "center" }}
                        >
                            <p>{data.check_stock_code || t("page.empty_text")}</p>
                        </Col>
                        <Col className="col-item" span={2} style={{ placeContent: "end" }}>
                            <p>{data.store_name || t("page.empty_text")}</p>
                        </Col>
                        <Col
                            className="col-item"
                            span={
                                vatDetail &&
                                    (vatDetail.is_enable_check_store_reason ||
                                        vatDetail?.is_enable_waste_record_reason)
                                    ? 2
                                    : 3
                            }
                            style={{ placeContent: "center" }}
                        >
                            <p>
                                {(data.check_product_list ? data.check_product_list.length : 0) ||
                                    t("page.empty_text")}
                            </p>
                        </Col>
                        <Col
                            className="col-item"
                            span={
                                vatDetail &&
                                    (vatDetail.is_enable_check_store_reason ||
                                        vatDetail?.is_enable_waste_record_reason)
                                    ? 3
                                    : 4
                            }
                            style={{ placeContent: "end" }}
                        >
                            <p>
                                <ApprovedIcon />{" "}
                                {/* {data.status.charAt(0).toUpperCase() + data.status.substring(1) ||
                                    t("page.empty_text")} */}
                                {t("page." + data.status)}
                            </p>
                        </Col>
                        <Col
                            className="col-item"
                            span={
                                vatDetail &&
                                    (vatDetail.is_enable_check_store_reason ||
                                        vatDetail?.is_enable_waste_record_reason)
                                    ? 3
                                    : 4
                            }
                            style={{ placeContent: "center" }}
                        >
                            <StylesDownloadPDFButton>
                                <Button
                                    className="pdfBtn"
                                    onClick={clickExport}
                                >
                                    {t("page.download_pdf")}
                                </Button>
                            </StylesDownloadPDFButton>
                        </Col>
                        {vatDetail &&
                            (vatDetail.is_enable_check_store_reason ||
                                vatDetail?.is_enable_waste_record_reason) && (
                                <Col
                                    className="col-item"
                                    span={3}
                                    style={{ placeContent: "center" }}
                                >
                                    <p>
                                        {(data.check_consumable_list.length !== 0 &&
                                            data.check_consumable_list[0].reason_system &&
                                            data.check_consumable_list[0].reason_system) ||
                                            t("page.empty_text")}
                                    </p>
                                </Col>
                            )}
                    </Row>
                </div>
            </div>
        </StylesCheckStockStart>
    );
};
export const StylesCheckStockStart = styled.div`
    .table-row {
        height: 100px;
    }
    input {
        height: auto;
    }
`;

const StylesDownloadPDFButton = styled.div`
    .pdfBtn {
        border-radius: 4px;
        border: 0.5px solid #646464;
        background: #fff;
        color: #646464;
        font-size: 14px;
        font-weight: 600;
        height: 50px;
    }
`;
