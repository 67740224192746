import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { StyledNewDetail } from "@components";
import { INews } from "@interfaces";
import {
    PATH_CREATE_NEWS,
    PATH_NEWS,
    PATH_PRODUCT_CREATE,
    PATH_PURCHASE_ORDER_CREATE,
    PATH_SUPPLIER_CREATE,
} from "@configs";
import { ModulePOForm, ModuleProductForm, ModuleShowPaper, ModuleSupplierForm } from "@modules";
import { useNotify, showErrorMessage } from "@utils";
import { setLoading, selectOrg, selectAuth } from "@redux";
import axios from "axios";
import { POAPI, productAPI, supplierAPI } from "@api";

const initResult2 = {
    address: "",
    remark: "",
    store_code: "",
    store_name: "",
    _id: "",
};

export const PageGRShowPaper = () => {
    const { id } = useParams<{ id: string }>();
    const org_id = useSelector(selectAuth).userInfo?.merchant?._id;
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const categories = useSelector(selectOrg).category;
    const dispatch = useDispatch();
    const history = useHistory();
    const { warning, error } = useNotify();
    const { t } = useTranslation();
    const [poGr, setPoGr] = useState<any>();
    // page state
    const [sup, setSup] = useState<any>();
    const [storeDetail, setStoreDetail] = useState<any>(initResult2);
    // variable
    const pathName = history.location.pathname;

    useEffect(() => {
        // fetchSupplier();
        fetchProduct();
    }, []);

    const fetchProduct = async () => {
        try {
            dispatch(setLoading(true));
            const res = await POAPI.getGRItem({
                organization_id: org_id || "",
                popr_id: id,
            });
            if (res.data.error === "0") {
                setPoGr(res.data.result);
                setStoreDetail(res.data.result2);
            } else {
                error(res.data.msg);
            }
        } catch (err: any) {
            if (err.response) {
                warning(showErrorMessage(err.response));
                dispatch(setLoading(false));
            } else warning(t("message.not_found", { name: t("object._reward") }));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const fetchSupplier = async () => {
        if (pathName !== PATH_SUPPLIER_CREATE) {
            try {
                dispatch(setLoading(true));
                const resSup = await supplierAPI.getSup({
                    organization_id: org_id || "",
                    supplier_id: id,
                });
                // const getAllProduct = await productAPI.getProduct({
                //     organization_code: orgCode,
                //     product_code: id,
                // });
                if (resSup.data.error === "0") {
                    setSup(resSup.data.result);
                } else {
                    warning(resSup.data.msg);
                }
            } catch (err: any) {
                if (err.response) {
                    warning(showErrorMessage(err.response));
                    dispatch(setLoading(false));
                } else warning(t("message.not_found", { name: t("object._reward") }));
            } finally {
                dispatch(setLoading(false));
            }
        }
    };

    return (
        <StyledNewDetail>
            {/* {<ModuleSupplierForm supplier={sup} />} */}
            {<ModuleShowPaper type="GOOD RECEIVE" data={poGr} storeDetail={storeDetail} />}
            {/* {<ModuleProductForm product={product} categories={categories} />} */}
        </StyledNewDetail>
    );
};
