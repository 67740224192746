import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Col, Row, Menu, Dropdown, Button } from "antd";
import { MoreHorizontal } from "react-feather";
import { IDataexample, INewsCategory, IProduct, IProductRank } from "@interfaces";
import { enumNewsType, PATH_PRODUCT } from "@configs";
import { selectAuth } from "@redux";
import { useSelector } from "react-redux";
import { IconDelete, IconDrag, IconRank, IconTrash } from "@components";
import { useNotify } from "@utils";
import React, { useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { productAPI } from "@api";
import { useHistory } from "react-router";

interface IProps {
    data: IProductRank | any;
    itemJson: string;
    index: number;
    deleteProduct: (index: number) => void;
}

export const ComponentRowProductRanking = (props: IProps) => {
    const { t } = useTranslation();
    const { success, error } = useNotify();
    const history = useHistory();
    const organization_id = useSelector(selectAuth).auth?.organization_data?._id;
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const { data, itemJson, deleteProduct, index } = props;
    const [show, setShowModal] = useState(false);

    const { attributes, listeners, setNodeRef, transform } = useSortable({
        id: itemJson,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        maxHeight: "50px",
    };


    const handleDelete = () => {
        deleteProduct(index);
    };


    return (
        <div className="table-element" ref={setNodeRef} style={style}>
            <div className="drag-icon" {...attributes} {...listeners}>
                <IconDrag />
            </div>
            <div className="rank-number" style={{ textAlign: "center" }}>
                <IconRank />
                <p className="rank-index">{index + 1}</p>
            </div>
            <div className="table-row rank-row" style={{ height: "50px" }}>
                <Row
                    // gutter={24}
                    type="flex"
                    align="middle"
                    justify="space-between"
                >
                    <Col className="col-item" span={12}>
                        <img className="small-img" src={data?.first_image_url || "/images/grayimg.png"} />
                        <p className="news-title">{data?.name}</p>
                    </Col>
                    <Col className="col-item" span={9}>
                        <p>{data?.product_code || t("page.empty_text")}</p>
                    </Col>
                </Row>
            </div>
            <div className="trash-icon" onClick={handleDelete}>
                <IconTrash size={24} />
            </div>
        </div>
    );
};
