import { StyledSearchLayout } from "@components";
import { IDivision } from "@interfaces";
import { Row, Col, Input, Select, DatePicker } from "antd";
import { useTranslation } from "react-i18next";
const { Search } = Input;
const { Option } = Select;

interface Iprops {
    setTextSearch: (value: string) => void;
    handleSearchCategory: (value: string) => void;
    onChangeDate?: (dates, dateStrings: string) => void;
    total: number;
    divisions: IDivision[];
}
export const ConpliantTableSearch = (props: Iprops) => {
    const { setTextSearch, handleSearchCategory, onChangeDate, total, divisions } = props;
    const { t } = useTranslation();
    return (
        <StyledSearchLayout>
            <div className="filter">
                <Row
                    gutter={24}
                    type="flex"
                    justify="space-between"
                    align="middle"
                    className="search-row"
                >
                    <Col className="col-item" span={6}>
                        <div className="filter-element">
                            <p>{t("page.search_news")}</p>
                            <Search
                                placeholder="input search text"
                                onSearch={(value) => setTextSearch(value)}
                                style={{ width: 200 }}
                            />
                        </div>
                    </Col>
                    <Col className="col-item" span={6}>
                        <div className="filter-element">
                            <p>{t("page.news_type")}</p>

                            <Select
                                showSearch
                                filterOption={(input, option) =>
                                    //@ts-ignore
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={handleSearchCategory}
                                placeholder={t("page.all")}
                            >
                                {divisions.map((item, index) => (
                                    <Option value={item.division_name} key={index}>
                                        {item.division_name}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    </Col>
                    <Col className="col-item" span={6}>
                        <div className="filter-element">
                            <p>{t("page.date_creation")}</p>
                            <DatePicker format={"YYYY-MM-DD"} onChange={onChangeDate} />
                        </div>
                    </Col>
                    <Col className="col-item" span={6}>
                        <div className="filter-element result">
                            <p>
                                <span>{t("page.result")}</span>
                                <span>{":"}</span>
                                <span>{total}</span>
                            </p>
                        </div>
                    </Col>
                </Row>
            </div>
        </StyledSearchLayout>
    );
};
