import { useTranslation } from "react-i18next";
import { SharedModal, StyledSubmitButton } from "../../shared";
import styled from "styled-components";
import { showErrorMessage, useNotify } from "@utils";
import { Col, Row } from "antd";
import { AssignCategoryIcon } from "src/Components/icon/AssignCategoryIcon";
import { useEffect, useState } from "react";
import { AssignProductIcon } from "src/Components/icon/AssignProductIcon";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth, setLoading } from "@redux";
import { productAddonAPI } from "@api";
import { PAGE_START } from "@configs";
import {
    ICreatePosPromotion,
    IPaginationNext,
    IGetProductAddonsExample,
    IOptionList,
} from "@interfaces";
import { ColumnRightArrow } from "src/Components/icon/ColumnRightArrow";

interface IProps {
    showPopup: boolean;
    handleClose: () => void;
    setTableData?: any;
    values: ICreatePosPromotion;
    record: any;
}
const INITAL_PAGE = {
    xpage: PAGE_START,
    xlimit: 50,
};
export const ComponentAddAddonModal = (props: IProps) => {
    const { showPopup, handleClose, setTableData, values, record } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { error } = useNotify();
    const [productAddons, setProductAddons] = useState<IGetProductAddonsExample[]>([]);
    const [totalProductAddons, setTotalProductAddons] = useState<number>();
    const organization_id = useSelector(selectAuth).userInfo?.merchant?._id;
    const [pagination, setPagination] = useState<IPaginationNext>(INITAL_PAGE);
    const [selectedAddon, setSelectedAddon] = useState<IGetProductAddonsExample>();

    useEffect(() => {
        fetchNews();
    }, []);
    const fetchNews = async () => {
        dispatch(setLoading(true));
        try {
            const getProductAddons = await productAddonAPI.getProductAddons({
                organization_id: organization_id,
                is_enable: true,
                xlimit: pagination.xlimit,
                xpage: pagination.xpage,
            });
            setProductAddons(getProductAddons.data.result);
            setTotalProductAddons(getProductAddons.data.total);
        } catch (err: any) {
            if (err.response) {
                error(showErrorMessage(err.response));
            } else error(t("message.error"));
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleSubmit = () => {
        let temp: any = values.product_list;
        for (let i = 0; i < temp.length; i++) {
            temp[i].need_amount = 1;
        }
        setTableData("product_list", temp);
        handleClose();
    };

    const selectAddonHandler = (item: IGetProductAddonsExample) => {
        if (values.variant_option) {
            let updatedVariantOption = values.variant_option;
            const index = values.variant_option.findIndex(
                (x) => x.variant_id === record.variant_id
            );
            updatedVariantOption[index] = {
                ...updatedVariantOption[index],
                addon_id: item._id,
            };
            setTableData("variant_option", updatedVariantOption);
        }
    };
    const selectOptionHandler = (item: IOptionList) => {
        if (values.variant_option) {
            let updatedVariantOption = values.variant_option;
            const index = values.variant_option.findIndex(
                (x) => x.variant_id === record.variant_id
            );
            let updatedName: any = record.name;

            const regex = /\(([^)]+)\)/;
            const matches = regex.exec(updatedName);

            // if (matches) {
            //     updatedName = updatedName.replace(matches[0], `(${item.option_name})`);
            // } else {
            //     updatedName = `${updatedName} (${item.option_name})`;
            // }
            updatedName = `${updatedName} (${item.option_name})`;
            updatedVariantOption[index] = {
                ...updatedVariantOption[index],
                option_id: item._id,
                name: updatedName,
            };
            setTableData("variant_option", updatedVariantOption);
        }
    };

    return (
        <SharedModal
            showPopup={showPopup}
            handleClose={handleClose}
            title={t("page.select_add_on")}
            width={900}
        >
            <StyledModalContent>
                <Row className="select-layout" gutter={32}>
                    <Col md={24}>
                        <StyledBtnStock>
                            <div className="wholeTable">
                                <div className="wholeTopRow">
                                    <div className="topTitleRow leftSide">
                                        <div className="tableTitles">
                                            <div className="titleAndIcon">
                                                <AssignCategoryIcon />
                                                Add-On
                                            </div>
                                            <p className="results">{productAddons.length} addons</p>
                                        </div>
                                    </div>
                                    <div className="topTitleRow rightSide">
                                        <div className="tableTitles">
                                            <div className="titleAndIcon">
                                                <AssignProductIcon />
                                                addons
                                            </div>
                                            <p className="results">{totalProductAddons}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="wholeContent">
                                    <div className="leftContent">
                                        {productAddons &&
                                            productAddons.map((item, i) => (
                                                <div
                                                    className={"catCols"}
                                                    key={i}
                                                    onClick={() => {
                                                        selectAddonHandler(item);
                                                        setSelectedAddon(item);
                                                    }}
                                                >
                                                    {item.addon_name}

                                                    <div className="colArrow">
                                                        <ColumnRightArrow />
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                    <div className="rightContent">
                                        {selectedAddon &&
                                            selectedAddon.option_list.map((item, i) => (
                                                <div
                                                    className="productBox"
                                                    key={i}
                                                    onClick={() => {
                                                        selectOptionHandler(item);
                                                        handleSubmit();
                                                    }}
                                                >
                                                    <div className="catCols rightColBoxes">
                                                        <div className="namePrice">
                                                            <p>{item.option_name}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            </div>
                        </StyledBtnStock>
                    </Col>
                </Row>
            </StyledModalContent>
            <div className="btn-layout" style={{ marginTop: "2rem" }}>
                <StyledSubmitButton
                    type="default"
                    text={t("page.confirm")}
                    htmlType="submit"
                    onClick={handleSubmit}
                />
            </div>
        </SharedModal>
    );
};

const StyledModalContent = styled.div`
    .ant-drawer-body {
        padding: 0 !important;
    }
    .secondTitle {
        font-size: 20px;
    }
    .row {
        display: flex;
        align-items: center;
    }
    .step {
        display: flex;
        align-items: center;
        p {
            margin: 0;
            color: #0263e0;
            font-weight: 700;
            font-size: 16px;
        }
        .whiteText {
            color: #a5a5a5;
        }
    }
    .blueCircle {
        background: #0263e0;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: flex;
        place-content: center;
        align-items: center;
        color: #fff;
        font-size: 12px;
        font-weight: 700;
        margin-right: 10px;
    }
    .grayCircle {
        background: #a5a5a5;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: flex;
        place-content: center;
        align-items: center;
        color: #fff;
        font-size: 12px;
        font-weight: 700;
        margin-right: 10px;
    }
    .arrowRight {
        margin: auto 20px;
    }
    .ant-table-thead > tr > th {
        text-align: left !important;
    }
    .catCols {
        background: #f7f7f8;
        border-radius: 6px;
        display: flex;
        position: relative;
        padding: 20px;
        color: #646464;
        cursor: pointer;
        margin-bottom: 10px;
        place-items: center;
    }
    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
        margin-left: 0;
    }
    .rightColBoxes {
        padding: 0;
        height: 140px;
        width: 125px;
        border-radius: 6px;
        margin: 0;
        display: flex;
        flex-direction: column;
        border: 1px solid #e1e1e1;
        background: #fff;
        overflow: hidden;
        p {
            margin: 0;
            font-weight: 700;
            font-size: 10px;
            line-height: 19px;
            color: #000;
        }
        img {
            width: 100%;
            height: 65%;
            object-fit: cover;
        }
        .namePrice {
            padding: 5px 12px;
        }
    }
    .catCols.active {
        background: #e1e1e1;
    }
    .colArrow {
        position: absolute;
        right: 20px;
        bottom: 0;
        top: 0;
        display: flex;
        gap: 10px;
        place-items: center;
        font-size: 12px;
    }
    .tableTitles {
        display: flex;
        justify-content: space-between;
        place-items: center;
        width: 100%;
        .results {
            color: #646464;
            font-size: 12px;
            font-weight: 300;
            margin: 0;
        }
    }
    .titleAndIcon {
        font-size: 16px;
        font-weight: 700;
        display: flex;
        gap: 10px;
        place-items: center;
    }
    .ant-table-thead > tr > th .ant-table-header-column {
        width: 100%;
    }
    .wholeTable {
        border: 1px solid #e1e1e1;
        position: relative;
        width: 100%;
        min-height: 30vh;
        border-radius: 6px;
    }
    .topTitleRow {
        background: #f7f7f8;
        display: flex;
        padding: 20px;
        border-top-left-radius: 6px;
        border-bottom: 1px solid #e1e1e1;
        justify-content: space-between;
        border-right: 1px solid #e1e1e1;
    }
    .topTitleRow.leftSide {
        width: 30%;
    }
    .topTitleRow.rightSide {
        width: 70%;
        right: 0;
        border-right: none;
        border-top-left-radius: 0;
        border-top-right-radius: 6px;
    }
    .wholeTopRow {
        display: flex;
    }
    .leftContent {
        padding: 10px;
        width: 30%;
        border-right: 1px solid #e1e1e1;
        overflow: scroll;
    }
    .rightContent {
        padding: 10px;
        width: 70%;
        height: 100%;
        overflow-x: scroll;
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        justify-content: space-between;
        position: relative;
    }
    .rightContent::after {
        content: "";
        flex: auto;
    }
    .wholeContent {
        display: flex;
        height: 50vh;
    }
    .topLongGrayBox {
        background: #f7f7f8;
        border-radius: 6px;
        padding: 20px;
        margin-bottom: 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
            margin: 0;
            color: #000;
            font-weight: 700;
        }
    }
    .customTransparentStockBtn {
        height: 42px;
        border: 0.5px solid #646464;
        border-radius: 4px;
        background: #fff;
        color: #646464;
        padding: 0 25px;
        display: flex;
        gap: 2px;
        align-items: center;
    }
    .customTransparentStockBtn:hover {
        border: 0.5px solid #646464 !important;
    }
    .aboveOfTableRow {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;
        .title {
            margin-bottom: 0 !important;
        }
    }
    .ant-input-group-addon {
        width: 70px;
    }
    .topRadioButtonRows {
        margin-bottom: 20px;
    }
    .radioBox {
        padding: 20px;
        background: #f5f5f5;
        border: 1px solid #e1e1e1;
        border-radius: 5px;
        color: #c8c8c8;
        position: absolute;
        left: 0;
        top: 30px;
        width: 100%;
        text-align: center;
        font-weight: 700;
        font-size: 32px;
        line-height: 44px;
    }
    .radioGroupRow {
        width: 100%;
        display: flex;
        place-content: space-between;
        position: relative;
        margin-bottom: 50px;
        .ant-radio-wrapper {
            width: 100%;
            display: flex;
            place-items: center;
            margin-bottom: 20px;
            p {
                margin: 0;
                font-weight: 700;
                font-size: 12px;
                line-height: 16px;
            }
        }
    }
    .activeRadioBtn {
        background: linear-gradient(0deg, rgba(2, 99, 224, 0.08), rgba(2, 99, 224, 0.08)), #ffffff;
        border-radius: 5px;
        border: 1px solid rgba(2, 99, 224, 0.36);
        color: #66a9ff;
    }
    .withIcon {
        display: flex;
        place-content: center;
        place-items: center;
        gap: 10px;
    }
    .label .sub-title {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #646464;
        margin: 0;
    }
    .increaseDecreaseSection {
        background: #f4f9ff;
        border-radius: 8px;
        width: 100%;
        height: 100px;
        margin-top: 20px;
        display: flex;
        place-content: center;
        place-items: center;
        gap: 30px;
        font-weight: 700;
        font-size: 25px;
        line-height: 34px;
        color: #000;
        .decreaseIcon {
            background: #e1e1e1;
            border-radius: 50%;
            display: flex;
            place-content: center;
            place-items: center;
            color: #fff;
            font-size: 40px;
            height: 35px;
            width: 35px;
            padding-bottom: 4px;
            cursor: pointer;
        }
        .increaseIcon {
            background: #0263e0;
            border-radius: 50%;
            display: flex;
            place-content: center;
            place-items: center;
            color: #fff;
            font-size: 30px;
            height: 35px;
            width: 35px;
            padding-bottom: 4px;
            cursor: pointer;
        }
    }
    .selectedBadge {
        position: absolute;
        width: 80px;
        height: 55px;
        background: rgba(255, 255, 255, 0.84);
        backdrop-filter: blur(2px);
        border-radius: 6px;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        display: flex;
        flex-direction: column;
        place-content: center;
        place-items: center;
        z-index: 9;
        cursor: pointer;
        p {
            margin: 0;
            color: #000;
            font-weight: 700;
            font-size: 14px;
            line-height: 19px;
        }
    }
    .overlay {
        background-color: rgba(0, 0, 0, 0.4);
        width: 100%;
        height: 100%;
        z-index: 9;
        position: absolute;
        border-radius: 6px;
        max-height: 140px;
    }
    .productBox {
        position: relative;
        cursor: pointer;
    }
    .loadMore {
        width: 100%;
        display: flex;
        place-content: center;
    }
    .loadMoreBtn {
        display: flex;
        place-content: center;
        place-items: center;
        align-items: center;
        color: #000;
        padding: 10px;
    }
`;
const StyledBtnStock = styled.div`
    .customTransparentStockBtn {
        height: 42px;
        border: 0.5px solid #646464;
        border-radius: 4px;
        background: transparent;
        color: #646464;
        padding: 0 25px;
    }
    .customTransparentStockBtn:hover {
        border: 0.5px solid #646464 !important;
    }
    .customBlueStockBtn {
        height: 42px;
        border: 0.5px solid transparent !important;
        border-radius: 4px;
        background: #0263e0;
        color: #fff;
        padding: 0 25px;
    }
`;
