export default {
    home: "Home",
    loyalty: "Loyalty",
    pos: "POS",
    communications: "Communications",
    analytics: "Analytics",
    marketing_automation: "Marketing Automation (Beta)",
    store_front: "Storefront",
    contact_list: "Contact list",
    LINE: "LINE",
    settings: "Settings",
    transaction: "Transaction",
    dashboard: "Dashboard",
    workflow: "Workflow",
    help: "Help",
    deshboard_samll: "This is our main homepage",
    welcome_to_loyalty: "Welcome to Loyalty",
    product_lists: "Product lists",
    add_product: "Add product",
    search: "Search:",
    search_product: "Search Product...",
    category: "Category",
    please_choose: "Please choose",
    sub_Category: "Sub Category",
    state: "State:",
    result: "Result:",
    category_name: "Category Name",
    sub_category: "Sub Category",
    amount_variants: "Amount Variants",
    price: "Price",
    storefront_display: "Storefront Display",
    manage: "Manage",
    create_product: "Create Product",
    edit_product: "Edit Product",
    product_settings: "Product settings",
    images_product: "Images Product",
    max_8_images: "(max 8 images)",
    add_images: "Add images",
    name_product: "Name Product",
    two_hundard_characters: "(2-100 characters)",
    headlines: "This field",
    product_code: "Product Code",
    standerd_price: "Standerd price (display)",
    discounts: "Discounts",
    active_on_platform: "Active on platform",
    exclude_pro_from_branch: "Exclude product from branch",
    include_promo_branch: "Include promotion to branch",
    point_deduction: "Point deduction",
    storefront_for_pickup: "Storefront for pickup",
    allow: "Allow",
    maximum_deduction: "Maximum deduction",
    variants: "Variants",
    this_product_has_options: "This product has options (like level, color)",
    name_variants: "Name Variants",
    description: "Description",
    activate: "Activate",
    save: "Save",
    back: "Back",
    next: "Next",
    finish: "Finish",
    cancel: "Cancel",
    preview: "Preview",
    add_category: "Add Category",
    yes: "Yes",
    no: "No",
    add_sub_category: "Add Sub Category",
    image_main_category: "Image Main Category",
    upload_image: "Upload Image",
    images: "Images",
    select_account: "Select Account",
    select_branch: "Select branch",
    user_guide: "User Guide",
    purchase_request: {
        list_title: "Create Purchase Request (PR)",
        add_PR: "Create Purchase (PR)",
        table_PR_number: "PR number",
        table_status: "status",
        create_PR_title: "Create Purchase Request (PR)",
        edit_PR_title: "Edit Purchase Request (PR)",
    },
    transfer_request: {
        list_title: "Transfer Request (Tr)",
        add_TR: "Create Transfer Request (Tr)",
        create_TR_title: "Create Transfer Request",
        detail_all_TR_title: "All TR",
        table_TR_No: "TR No.",
        table_created_date: "Create Date",
        table_branch_requesting: "Branch Requesting",
        table_to_branch: "To Branch",
        table_qty_request: "Qty Request",
        table_state: "State",
        table_detail: "Detail",
        select_inv: "Select Inventory",
        enter_qty: "Enter Qty",
        branch_req: "Requesting Branch",
        from_branch: "Supplying Branch",
        choose_branch_modal: "Select branch create transfer request",
        step_1_title: "Please add inventory and enter quantity for request to transfer  other branch",
        inv_cate: "Inventory Category",
        tr_req_detail: "Transfer Request details",
        to_branch: "To branch",
        order_request: "Order Request",
        tr_no: "TR No.",
        qty_request_transfer: "QTY Request Transfer",
        cancel_tr: "Cancel TR",
    },
    purchase_order: {
        list_title: "Create Purchase Order (PO)",
        add_PO: "Create Purchase (PO)",
        detail_all_PO_title: "All PO",
        detail_done_title: "Done",
        detail_pending_title: "Pending",
        create: "Create PO",
        detail_renew_title: "Create with Renew PO",
        detail_cancel_title: "Cancel",
        detail_sheets: "Sheets",
        table_create_date: "Create Date",
        table_PO_request: "PO number",
        table_supplier: "Supplier",
        table_due_date: "Due Date",
        table_store: "Store",
        create_PO_title: "Create Purchase Order (PO)",
        edit_PO_title: "Edit Purchase Order (PO)",
        step: "Step",
        step_one_title: "Setting Purchase",
        step_two_title: "Select Supplier",
        step_three_title: "Specify to Supplier",
        step_four_title: "Summary PO",
        select_pr_title: "Select Purchase request",
        inventory_qr_title: "Enter inventory quantity for order",
        add_inventory_btn: "Add inventory",
        auto_gen_btn: "Auto Gennerate",
        InvQ_row_name: "Inventory Name",
        InvQ_row_code: "Inventory Code",
        InvQ_row_stock: "In stock (restock qty)",
        conversion: "Conversion",
        conversion_delivery: "Conversion (Delivery to Restock)",
        conversion_restock: "Conversion (Restock to Use)",
        InvQ_row_order: "Quantity Order",
        modal_consume_title: "Search inventory",
        inventory_select_supplier: "Select Supplier",
        inventory_specify_supplier: "Specify to Supplier",
        inventory_PO_preview: "Preview Purchase Order (PO)",
        step2_inventory: "Inventory",
        step2_bath: "Bath/Unit",
        no_sup: "no supplier",
        note: "Note",
        load_pdf: "load pdf",
        paper_title: "Purchase Order (PO)",
        modal_tab_ingredient: "Ingredient",
        modal_tab_product: "Product",
        view_order: "View order",
        delete_order: "Delete order",
    },
    good_receive: {
        list_title: "Create Good Receive (GR)",
        detail_all_PO_title: "All PO",
        detail_waiting_title: "Waiting",
        detail_pending_title: "Pending",
        detail_done_title: "Done",
        detail_sheets: "Sheets",
        table_create_date: "Create Date",
        table_purchase_request: "PO number",
        table_supplier: "Supplier",
        table_due_date: "Due Date",
        table_store: "Store",
        table_status: "Status",
        table_item_inventory: "Inventory Name",
        table_item_price: "Qty (restock unit)",
        receive_amount: "Receive Amount",
        table_item_baht: "Baht / Unit",
        table_item_expiry: "Expiry",
        detail_title: "Good Receive",
        paper_title: "Good Receive (GR)",
        load_paper: "save GRPaper .pdf",
        select_date: "เลือกวันที่",
        select_all: "Select All",
    },
    supplier: {
        list_title: "Supplier",
        add_supplier: "Add supplier",
        table_name_title: "Name Supplier",
        table_code_title: "Supplier Code",
        table_address_title: "Address",
        table_tax_title: "TAX ID",
        table_credit_title: "Credit Term",
        table_vat_title: "VAT",
        table_product_title: "Product",
        create_sup_title: "Create Supplier",
        sup_type: "Type",
        edit_sup_title: "Edit Supplier",
        sup_setting: "Supplier settings",
        sup_name: "Supplier Name",
        tax_id: "TAX ID",
        sup_code: "Supplier Code",
        address: "Address",
        vat: "VAT",
        credit: "Credit Term",
        credit_day: "Credit Tern",
        include_vat: "Inclusive VAT",
        exclude_vat: "Exclusive VAT",
        internal: "internal",
        external: "external",
        yes: "yes",
        no: "no",
        day: "Day",
    },
    addon: {
        addon_title: "Add-On",
        table_addon_name: "Add-On Name",
        table_variant: "Variant",
        table_consume: "Inventory Assigned",
        radio_title: "Type Assign Consumable",
        radio_product: "Product",
        radio_addon: "Add-on",
        addon_option: "Add-On options",
        add_addon_option: "Add Add-on Option",
        select_addon: "Select Add-on",
        select_addon_desc: "Can Add Category Option on Add-On Product Category Menu",
    },
    exclude: {
        modal_title: "Quantity Branch",
        table_branch: "Branch",
    },
    unit_option: {
        Bag: "Bag",
        Bottle: "Bottle",
        Box: "Box",
        Can: "Can",
        Cup: "Cup",
        Gallon: "Gallon",
        Gram: "Gram",
        Kilogram: "Kilogram",
        Liter: "Liter",
        Milligram: "Milligram",
        Milliliter: "Milliliter",
        Ounce: "Ounce",
        Pack: "Pack",
        Percent: "Percent",
        Pieces: "Pieces",
        Pint: "Pint",
        Portion: "Portion",
        Pound: "Pound",
        Quart: "Quart",
        Tablespoon: "Tablespoon",
        Teaspoon: "Teaspoon",
    },
    sidebar: {
        services: "Services",
        services_list: "Services list",
        product_lists: "Product lists",
        purchase_request_lists: "Purchase Require (PR)",
        transfer_request_lists: "Transfer Require (TR)",
        purchase_order_lists: "Purchase Order (PO)",
        good_receive_lists: "Goods Receive (GR)",
        supplier_list: "Supplier list",
        catogory: "Catogory",
        in_store_product: "In-store product",
        product_category: "Product Category",
        order_lists: "Order lists",
        settings_shipping_method: "Settings Shipping Method",
        promotions: "Promotions",
        staff_list: "Staff list",
        store_management: "Store management",
        appointment_settings: "Appointment settings",
        payment_settings: "Payment settings",
        staff: "Staff",
        staff_scheduling: "Staff scheduling",
        shift_types: "Shift types",
        store_list: "Store List",
        permission_pos: "Permission POS",
        format_payment_calculation: "Organization Settings",
        contacts_list: "Contacts list",
        segment: "Segment",
        merge_suggestions: "Merge suggestions",
        rewards: "Rewards",
        member_benefits: "Member benefits",
        coupons: "Coupons",
        merge_settings: "Merge settings",
        free_points: "Free points",
        loyalty_rules: "Loyalty rules",
        user_sign_up_settings: "User sign-up settings",
        account_settings: "Account settings",
        traffic_source: "Traffic source",
        features_settings: "Features settings",
        integrations: "Integrations",
        manage_permissions: "Manage permissions",
        settings: "Settings",
        contacts: "Contacts",
        contacts_title: "Contacts",
        loyalty: "Loyalty",
        current_plan: "Current plan",
        edit_points_member: "Edit Points Member",
        accept_point: "Accept Redeem",
        future_is_now: "The Future is now",
        transaction: "Transaction",
        points: "Points",
        user_info: "User Information",
        noti_settings: "Notification Settings",
        edit_profile: "Edit My Profile",
        payments: "Payments",
        pricing_features: "Current Plan",
        set_theme: "Set Theme",
        first_menu: "Menu",
        store_front: "Storefront",
        contact: "Contact list",
        loyalty_features: "Loyalty",
        gain_friends: "Gain member",
        user_guide: "User Guide",
        pdpa_management: "Data policy",
        ingredient: "Ingredient",
        recipe_list: "Recipe list",
        consumable_list: "Consumable list",
        assign_consumable: "Assign consumable",
        inventory: "Inventory",
        inventory_list: "Inventory list",
        edit_inventory_qty: "Edit inventory qty",
        check_stock: "Check stock",
        add_on_product_category: "Add-on Product Category",
        promotion_category: "Promotion Category",
        promotion_groups: "Promotion Groups",
        promotion_pos: "Promotion",
        wholesale_channel: "Order Channel",
        waste_record: "Waste Record",
        inv_cate: "Inventory Category",
        brand: "Brand",
        report_setting: "Report Setting",
        unit: "Unit Setting",
        setting_category_check_stock_list: "Setting Category Check Stock List",
        product_management: "Product management",
        create_manage_product: "Create / manage product",
        assign_inventory: "Assign inventory",
        addon: "Addon",
        promotion: "Promotion",
        bill_promotion: "Bill Promotion",
        inventory_management: "Inventory management",
        store_channel: "Store & channel",
        store_settings: "Store settings",
        category_management: "Category management",
        inventory_units: "Inventory units",
        check_stock_list: "Check stock list",
        organization_settings: "Organization settings",
        roles_permissions_settings: "Roles & permissions settings",
        roles_permissions: "Roles & permissions",
        product_collections: "Product Collections",
        import_export_table: "Import & Export",
        import_table: "Import Table",
        export_table: "Export Table",
        ranking: "Product Ranking",
    },
    manage_account: {
        accounts: "Accounts",
        select_account: "Please select the account you want to manage.",
        account_name: "Account name",
        our_customer: "Our Customer",
        add_account: "Add Account",
        our_customer_m: "Our Customer :",
        role: "Role",
        plan: "Plan",
        add_member: "Invite member",
        enter_company_info: "Enter Company Info",
        confirm_info: "Confirm Info",
        complete: "Complete",
        account_mame: "Account Name",
        account_owner: "Account Owner",
        appear_on_storefront: "This name will appear on the storefront.",
        owner_account: "Owner account",
        digitalrocket_gmail: "Digitalrocket@gmail.com",
        logout: "Logout",
        business_info: "Business info",
        company_name: "Company name",
        full_name: "Fullname",
        types_business_industries: "Types of business industries",
        david_dondonki: "David Dondonki",
        contact_business_account: "Contact for business account",
        telephone_number: "Telephone number",
        placeholder_number: "02-222-2222",
        how_many_people: "How many people of your company ?",
        create_business_account: "Create business account",
        back: "Back",
        next: "Next",
        create: "Create",
        finish: "Finish",
        business_industries: "Business industries",
        fullname_contact: "Fullname Contact",
        people_your_company: "People of your company",
        success_create_account: "Success Create Account",
        name_account: "Name Account",
        congratulations: "Congratulations, ",
        your_program: "you have created your loyalty program!",
        back_main_page: "Back to main page",
        faq_note:
            "FAQ : Note Verify Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,",
    },
    // Create Product Page
    The_product_have_a_barcode: "The product have a barcode",
    price_by_variant: "Price by variant",
    add_variant: "Add Variant",
    variant_image: "Variant Image",
    name_formula: "Formula Name*",
    enter_formula_name: "Enter formula name",
    enter_barcode: "Enter Barcode",
    view_barcode: "View Barcode",
    barcode: "Barcode",
    auto_generate: "Auto Generate",
    scan_barcode: "Scan Barcode",
    quantity_branch: "Quantity Branch",
    branches_excluded_from_this_product: "Branches excluded from this product",
    branches_include_from_this_promotion: "Branches inclued in this promotion",
    branch_lists: "Branch Lists",
    print_barcode: "Print Barcode",
    delete: "Delete",
    branch: "Branch",
    branches_included_from_this_product: "Branches Included from this product",
    product_name: "Product Name",
    enter_qty: "Enter QTY",
    view_qty: "View QTY",
    quantity: "Quantity",
    variant: "Variant",
    sale_amount: "Sale Amount",
    standard_price: "Standard Price",
    net_price: "Net Price",
    print_barcode_all: "Print Barcode All",
    add_variants: "Add Variants",
    add_edit_quantity: "Add / Edit Quantity",
    current_quantity_pack: "Current quantity (Pack)",
    differenced: "Differenced",
    select_product_variant: "Select Product Variant",
    product_variant: "Product Variant",
    // Create Product Page
    // Category Page
    caregories_products: "Caregories (products)",
    category_12: "(12 Category)",
    // Category Page
    // Store List Page
    bulk_services_setting: "Bulk services setting",
    code: "Code",
    addon_code: "Add-on inventory code",
    store_name: "Store Name",
    status: "Status",
    add_store: "Add Store",
    create_store: "Create Store",
    store_settings: "Store settings",
    name: "Name",
    store_code: "Store code",
    customer_service_tel: "Customer service tel",
    client_side_choose_by_staff: "Client-side choose by staff",
    online_store: "Online store",
    store_on_client_order: "open online store",
    is_client_order: "Turn on online store",
    turn_on_choose_by_staff_view: "Turn on choose by staff view",
    business_hours: "Business hours",
    pos_settings: "POS settings",
    pos_code: "POS code",
    tax_included: "Tax Included",
    assign_services: "Assign Services",
    choose_the_services_that_will_be_assigned_to_the_store:
        "Choose the services that will be assigned to the store",
    store_lists: "Store Lists",
    location_setting: "Location setting",
    select_province: "Select Province",
    select_district: "Select District",
    select_subdistrict: "Select Subdistrict",
    address: "Address",
    permission_pos: "Permission POS",
    select_store_for_add_new_account_members: "Select store for add new account members.",
    store: "Store",
    total_employees: "Total Employees",
    search_store: "Search Store...",
    back_to_main_page: "Back to main page",
    manage_permission: "Manage Permission",
    role: "Role",
    account_verification_status: "Account verification status",
    email: "Email",
    add_member: "Invite member",
    name_employee: "Employee Name",
    email_employee: "Email Admin",
    invite: "Invite User",
    edit_member: "Edit member",
    edit_permissions_granted: "Edit permissions granted to this account member.",
    delete_user: "Delete User",
    data_not_found: "Data not found",
    service_charge: "Service Charge",
    add_view_permission: "Add / View Permission",
    verified: "Verified",
    waiting_verify: "Waiting Verify",
    owner: "Owner",
    edit: "Edit",
    resend_password: "Resend Password",
    // Store List Page
    // MenuHeader Modal
    feature_not_your_content: "This feature is not on your current plan",
    do_you_wish_use_feature:
        "Do you wish to use this feature? Please select new plan if you would like to use this feature.",
    select_new_plan: "Select new plan",
    // MenuHeader Modal
    // Stock Lists
    consumable_lists: "Inventory lists",
    add_consumable: "Add consumable",
    all_consumables: "All Inventory",
    stock_running_out: "Stock running out",
    search_consumables: "Search Inventory...",
    ingredients_name: "INGREDIENTS NAME",
    consumable_name: "Consumable name",
    restock: {
        ingredient: "Ingredient",
        product: "Product",
        product_category: "Product Category",
    },
    consume: {
        cost_restock: "Cost (restock unit)",
        type_unit: "Type Unit",
        restock_diff: "Restock & use unit different",
        restock_same: "Restock & use unit same",
        restock_unit: "Restock unit",
        use_unit: "Use unit",
        re_use_unit: "Restock & use unit",
        many_to_one: "Many pieces in one unit",
        one_to_many: "One pieces of main unit",
        one_to_one: "One pieces of one unit",
        supplier: "Supplier",
        add_supplier: "Add supplier",
        restock_price: "Restock unit price",
        search_supplier: "Search Supplier",
        conversion: "Conversion",
        base_conversion: "Delivery -> Restock conversion",
        delivery_unit: "Delivery unit",
        add_convers: "Add Conversion",
        usage_conversion: "Restock -> Usage conversion",
        BOM: "BOM",
        add_ingredient: "Add Ingredient",
        search_ingredient: "Search Ingredient",
        qty_setting: "Quantity setting (restock unit)",
        qty_min: "Minimum",
        qty_max: "Maximum",
        qty_par: "Par",
        qty_std: "Standard monthly consumption",
        convers_title: "Conversion",
        no_ingredient: "no ingredient",
        no_supplier: "no supplier",
        cate: "Catetory",
    },
    language: {
        en: "English",
        th: "Thai",
        lang_en: "EN",
        lang_th: "TH",
    },
    add_delete_quantity: "Add / Delete Quantity",
    unit: "Unit",
    consumable_setting: "Consumable settings",
    ingredient_name: "Ingredient Name",
    select_unit: "Select Unit",
    assign_branch: "Assign branch",
    minimum: "Minimum",
    add_edit_consumable: "Add / Edit consumable",
    history: "History",
    edit_quantity: "Edit quantity",
    quantity_piece: "Quantity (Piece)",
    view: "View",
    minimum_quantity: "Minimum Quantity",
    add_quantity: "Add Quantity",
    deduct_quantity: "Deduct Quantity",
    current_quantity: "Current quantity",
    quantity_to_add: "Quantity to add",
    piece: "Piece",
    enter_quantity_to_deduct: "Enter quantity to deduct",
    reason_for_quantity_deduction: "Reason for quantity deduction",
    history_edit_quantity: "History Edit Quantity",
    date: "Date",
    activity: "Activity",
    edited_quantity: "Edited quantity",
    new_quantity: "New quantity",
    note: "Note",
    deduct: "Deduct",
    add: "Add",
    box: "Box",
    bottle: "Bottle",
    grams: "Grams",
    ounces: "Ounces",
    // Stock Lists
    // Assign Stock
    assign_consumable: "Assign Consumable",
    assign_stock_to_product: "Assign stock to product",
    consumable_assigned: "Inventory assigned",
    select_product: "Select product",
    select_product_to_assign_consumables: "Select product to assign Inventory",
    products: "Products",
    categories: "Categories",
    select: "Select",
    consumable_list: "Inventory list",
    quantity_per_branch: "Quantity per branch",
    quantity_per_order: "Use Amount",
    consumable: "Consumable",
    select_consumables: "Select Inventory",
    qty_added_deducted: "Qty added / deducted",
    // Assign Stock
    // Store List
    permission_settings: "Permission settings",
    payment_settings: "Payment settings",
    edit_store_details: "Edit store details",
    manage_permissions: "Manage permission",
    create_staff: "Create staff",
    edit_store: "Edit store",
    store_setting: "Store setting",
    create_employee: "Create employee",
    employee_role: "Employee role",
    select_role: "Select a role",
    employee_code: "Employee code",
    done: "Done",
    reset: "Reset",
    image_qr_code: "Image QR Code",
    dynamic_qr_payment_prompt_pay: "Payment gateway",
    dynamic_qr_payment_kbank: "KBank",
    merchant_id_pay_solution: "Merchant ID (Pay Solution)",
    merchant_name_pay_solution: "Merchant Name (Pay Solution)",
    token_pay_solution: "Token  (Pay Solution)",
    api_key_pay: "Api key",
    // Store List
    current_qty: "Current Qty",
    new_balance: "New balance",
    empty_text: "-",
    price_unit: "Price / Unit",
    old_price: "Old Price",
    new_price: "New Price",
    addon_name: "Addon Name",
    amount_choices: "Amount Choices",
    status_request: "Status Request",
    request_select: "Request Select",
    confirm: "Confirm",
    create_add_on: "Create Add-On",
    add_on_settings: "Add-On settings",
    name_title_add_on: "Name Title Add-On",
    number_of_selectable_options: "Number of selectable options*",
    add_on_options: "Add-on options",
    option: "Option",
    option_name: "Option name",
    add_option: "Add Option",
    single_option: "Single option",
    multiple: "Multiple",
    create_option_addon: "Create Option Add-On",
    _2_100_characters: "(2-100 characters)",
    required: "Required",
    additional_fee: "Additional fee",
    variant_name: "Variant Name",
    variants_title: "Variants Title",
    total_variants: "Total Variants",
    add_options: "Add Options",
    promotion_pos: "Promotion POS",
    add_channel_delivery: "Add Channel Delivery",
    promotion_settings: "Promotion Settings",
    details_product: "Details product",
    type_customer: "Type Customer",
    promotion_setting: "Promotion Setting",
    type_promotion: "Type Promotion",
    free: "Free",
    discount_by_baht: "Discount by baht",
    discount_by_percent: "Discount by percent",
    condition_promotion: "Condition Promotion",
    select_confition_promotion: "Select Condition Promotion",
    order_amount: "Order amount",
    order_amount_desc:
        "Customers that order according to these condition will get promotion result",
    all_customer: "All Customer",
    member_only: "Member Only",
    product: "Product",
    promotion_result_free_promotion: "Promotion Result (Free Promotion)",
    select_product_result: "Select Product Result",
    select_all: "Select All",
    select_product_promotion: "Select Product Promotion",
    selected: "Selected",
    promotion: "Promotion",
    bill_promotion: "Bill Promotion",
    add_promotion: "Add Promotion",
    promotion_name: "Promotion Name",
    updated_date: "Update date",
    promotion_type: "Promotion type",
    active: "Active",
    day_hour_limit: "Day / hour limit",
    specific_hours: "Specific hours",
    all_hours: "All hours",
    amount: "Amount",
    waste: "waste",
    chech_stock_amount: "Check stock amount",
    current_amount: "current amount",
    different_amount: "different amount",
    select_category: "Select category",
    free_product: "Free Product",
    promotion_result_thb_discount_amount: "Promotion Result (THB discount amount)",
    discount: "Discount",
    promotion_result_percentage_discount: "Promotion Result (Percentage discount)",
    promotion_result_set_new_pricing: "Promotion Result (set new pricing for this set of product)",
    sale_amount_in_baht: "Amount (THB)",
    has_empty_variant_title: "have empty variants title",
    has_empty_variant_name: "have empty variants name",
    has_produce_quantity: "Does this product have quantity?",
    hint_code: "Hint Code",
    points: "Points",
    wholesale: {
        list_title: "Order channel",
        add_channel: "Add Channel",
        table_channel: "Channel",
        table_menu: "Menu",
        table_status: "status",
        channel_settings: "Channel setting",
        enter_price_product: "Enter price product",
        enter_price_addon: "Enter price addon",
        channel: "Channel",
        select_menu: "Select Menu",
        menu: "Menu",
        type_service: "Type Service",
        wholesale: "Wholesale",
        delivery: "Delivery",
        channel_img: "Channel Image",
        table_store_price: "Store Price",
        table_selling: "Selling Price",
        table_dif: "Difference",
        table_delete: "Delete",
        table_addon: "Add-On",
    },
    start: "Start",
    publish: "Publish",
    check_store_id: "Check store id",
    search_stores: "Search stores...",
    format: "Format",
    date_time: "Date / time",
    check_stock_round_no: "Check stock round no.",
    staff: "Staff",
    affected_items: "Affected items",
    download_pdf: "Download PDF",
    par_level: "Par level",
    current_base_qty: "Current base qty",
    difference: "Difference",
    waste_amount: "Waste Amount",
    new_restock_qty: "NEW RESTOCK QTY",
    promo_code: "Promo code",
    enable: "Enable",
    promo_code_type: "Promo code type",
    fixed_code: "Fixed code",
    unique_code: "Unique code",
    generate_amount: "Generate amount",
    validity_period: "Validity period",
    from: "From",
    till: "Till",
    add_promo_codes: "Add promo codes",
    export_excel: "Export Excel",
    download_template: "Download template",
    generate: "Generate",
    variation: "Variation",
    nothing_to_show: "Nothing to show!",
    item: "Items",
    by_product: "By Product",
    by_cate: "Product Category",
    promo_code_set: "Promo code set",
    custom_date: "Custom Date",
    approved: "Approved",
    format_option: {
        day: "Day",
    },
    status_option: {
        pending: "Pending",
        finished: "Finished",
        canceled: "Canceled",
    },
    print_paper: {
        title_po: "PURCHASE ORDER",
        title_gr: "GOOD RECEIVE",
        buyer: "BUYER/SHIP TO",
        detail: "DETAIL",
        issued_date: "Issued date : ",
        credit_terms: "Credit terms : ",
        tax_id: "Tax id : ",
        contact: "Contact : ",
        tel: "Tel : ",
        table_no: "No.",
        table_code: "Code",
        table_ing: "Ingredient",
        table_qty: "Qty",
        table_unit: "Unit",
        table_vat: "VAT",
        table_price: "Price",
        table_total: "Total",
        remark: "Remark : ",
        total: "Total",
        vat: "VAT",
        grand_total: "Grand total",
        autorized: "Authorized by",
        cancelled: "Cancelled by",
        issued: "Issued by",
    },
    cashier: "Cashier",
    manager: "Manager",
    promotion_categories: "Promotion categories",
    promotions: "Promotions",
    promotion_category_name: "Promotion category name",
    channel: "Channel",
    tier: "Tier",
    inv_cate: {
        list_title: "Inventory Category",
        table_name: "Category Name",
        table_ingredients: "Ingredients",
        table_total: "Total products",
        add: "Add Category",
        name: "Inventory Category name",
        code: "Code prefix",
    },
    brand: {
        title: "Brands",
        table_name: "Brand",
        table_total: "Total Product",
        add_brand: "Add Brand",
        brand_name: "Brand Name",
    },
    unit_setting: {
        title: "Unit Setting",
        add_unit: "Add unit",
        table_unit: "Name",
        unit_name: "Unit name",
    },
    report_setting: {
        title: "Report delivery & notifications",
        noti_setting: "Notification settings",
        pos_report_setting: "POS report settings",
        select_recipients: "Select recipients",
    },
    receive_amount: "Receive Amount",
    setting_order_request_transfer: "Setting Order Request Transfer",
    select_store_to_request_transfer: "Select Store To Request Transfer",
    select_branch_request_transfer: "Select Branch Request Transfer",
    inventory: "Inventory",
    request_from_branch: "Request from branch",
    branch_request_transfer: "Branch Request Transfer",
    confirm_request_transfer: "Confirm Request Transfer",
    choose_branch_to_request: "Choose branch to request",
    title: "title",
    diff_from_par: "Diff from par",
    diff_from_max: "Diff from max",
    inventory_code: "Inventory code",
    channel_code: "Channel code",
    bp_code: "BP code",
    category_unit: "Category unit",
    type: "Type",
    finished_goods: "Finished goods",
    ingredient: "Ingredient",
    purchase_request_tab: "Purchase Request",
    transfer_request_tab: "Transfer Request",
    branch_tax_id: "Branch tax id",
    item_group: "Item group",
    province: "Province",
    district: "District",
    subDistrict: "Subdistrict",
    display: "Display",
    show_cost_on_po: "Show cost on PO",
    auto_convert_pr_to_po: "Auto convert PR to PO",
    check_stock_reason: "Check stock reason",
    waste_record_reason: "Waste record reason",
    set_reason: "Set reason",
    waste_reason: "Waste reason",
    close: "Close",
    due_date: "Due date",
    option_limit: "Option limit",
    option_qty_limit: "Option qty limit",
    pos_tax_id: "POS tax id",
    store_tax_id: "Store tax id",
    setting_category_check_stock_list: "Setting Category Check Stock List",
    amount_product: "Amount Product",
    daily: "Daily",
    weekly: "Weekly",
    monthly: "Monthly",
    create_category_check_stock: "Create Category Check Stock",
    uncategorized: "Uncategorized",
    raw_mat: "Raw mat",
    please_create_inventory_first_to_set_quantity: "Please create inventory first to set quantity",
    adhoc: "Adhoc",
    inventory_daily: "Inventory (daily)",
    inventory_weekly: "Inventory (weekly)",
    inventory_monthly: "Inventory (monthly)",
    product_daily: "Product (daily)",
    product_weekly: "Product (weekly)",
    product_monthly: "Product (monthly)",
    inventory_name: "Inventory Name",
    po_amount: "PO Amount",
    received_amount: "Received Amount",
    expiry: "Expiry",
    no_expiry: "No expiry",
    lot_number: "Lot Number",
    pending: "Pending",
    remaining: "Remaining",
    received: "Received",
    check_stock: "Check Stock",
    waste_record: "Waste Record",
    add_promotion_group: "Add promotion group",
    promotion_category_group_name: "Promotion category group name",
    promotion_groups: "Promotion Groups",
    promotion_group: "Promotion Group",
    enable_pr_po: "Enable PR/PO",
    max_option_total_qty: "Max option total qty",
    void_bill_witness_approve: "Void bill witness approve",
    show_delivery_unit_on_check_stock: "Show delivery unit on check stock",
    show_expiry_date_on_gr: "Show expiry date on GR",
    overlap_condition: "Overlap condition",
    cannot_overlap: "Cannot overlap",
    can_overlap: "Can overlap",
    by_variant: "By Variant",
    select_variant: "Select variant",
    select_promotion_group: "Select promotion group",
    by_variant_add_on: "By Variant + Add-on",
    add_addon: "Add-On",
    select_add_on: "Select Add-On",
    promotion_set_image: "Promotion set image",
    source: "Source",
    internal: "Internal",
    void_bill_staff_can_be_different_from_bill_owner:
        "Void bill staff can be different from bill owner",
    enable_order_queue: "Enable order queue",
    exclusive_promotion: "Exclusive promotion",
    scan_only: "Scan only",
    discount_percent: "Discount (%)",
    max_discount_value: "Max discount value",
    promotion_period: "Promotion period",
    search_promotion: "Search promotion",
    roles_permissions_settings: "Roles & permissions settings",
    roles_permission_desc: "โปรดระบุรายละเอียดให้ครบถ้วน",
    role_name: "Role name",
    role_details: "Role details",
    features_permissions: "Features permissions",
    product_management: "Product management",
    create_manage_product: "Create / manage product",
    assign_inventory: "Assign inventory",
    add_on: "Add-on",
    product_promotions: "Product promotions",
    bill_promotions: "Bill promotions",
    store_access: "Store access",
    all_stores: "All stores",
    specific_stores: "Specific stores",
    inventory_management: "Inventory management",
    inventory_list: "Inventory list",
    supplier_list: "Supplier list",
    edit_inventory_qty: "Edit inventory qty",
    inventory_request: "Inventory request",
    goods_received: "Goods received",
    purchaseOrder: "Purchase order",
    store_channel_management: "Store & channel management",
    permissions: "Permissions",
    payments: "Payments",
    store_details: "Store details",
    object_manager: "Object manager",
    inventory_category: "Inventory category",
    inventory_units: "Inventory units",
    promotion_category: "Promotion category",
    check_stock_list: "Check stock list",
    pos_organization_settings: "POS organization settings",
    brand_roles: "Brand",
    roles_permissions: "Roles & permissions",
    pos_reports: "POS reports",
    sales_summary: "Sales summary",
    sales_by_bill: "Sales by bill",
    sales_mix: "Sales mix",
    sale_by_product_summary: "Sale by product (summary)",
    sale_by_product_detail: "Sale by product (detail)",
    sale_by_category: "Sale by category",
    sale_by_channel: "Sale by channel",
    sales_by_payment_method: "Sales by payment method",
    sale_by_staff: "Sale by staff",
    sale_by_branch: "Sale by branch",
    add_on_report: "Add-on report",
    promotion_summary: "Promotion (summary)",
    promotion_by_record: "Promotion (by record)",
    inventory_movement: "Inventory movement",
    current_stock: "Current stock",
    movement_summary: "Movement (summary)",
    movement_by_record: "Movement (by record)",
    inventory_waste_record: "Inventory waste record",
    inventory_check_record: "Inventory check record",
    pr_po_gr: "PR/PO/GR",
    pr_report: "PR Report",
    pr_forecast_report: "PR Forecast Report",
    po_report: "PO Report",
    gr_report: "GR Report",
    minimum_order_value: "Minimum order value",
    select_channel: "Select Channel",
    manual_select: "Manual select",
    company_name_for_tax_inv: "Company name (for tax inv)",
    terminal_tax_id_15_digit: "Terminal tax id (15 digit)",
    dine_in: "Dine In",
    loyalty_channel: "Loyalty channel",
    sale_channel: "Sale channel",
    all_sale_channel: "All sale channels",
    product_collections: "Product Colletions",
    add_product_collection: "Add product collection",
    all: "All",
    product_category: "Product category",
    product_form: {
        price_by_weight: "Price by weight?",
        price_desc: "This product is priced by weight",
        stock_control: "Stock Control",
        is_qty: "Does this product have quantity?",
        have_qty: "Have quantity",
        no_qty: "Not have quantity",
        base_inv: "Base inventory restock & use unit",
        restock_unit: "Restock Unit",
        use_unit: "Use unit",
        price_variants: "Price and Variants",
        selling_type: "Selling inventory type",
        separate: "Separate base inventory",
        shared: "Shared base inventory",
        table_base: "Base inventory qty",
    },
    export_table_page: {
        title: "Export Table",
        history: "History",
        data_to_export: "Data to be exported",
        select_data: "Select Data",
        export: "Export",
    },
    import_table: "Import Table",
    create_inventory: "Create Inventory",
    assign_inventory_to_product: "Assign inventory to product",
    bom_inventory: "BOM inventory",
    assign_addon_to_product: "Assign add-on to product",
    import_to: "Import to",
    template_table_file_csv: "Template table file .CSV",
    import_to_create_product_here: "Import to create product here",
    import_file_csv_or_excel: "Import File .CSV or Excel",
    import_file_excel: "Import File Excel",
    please_add_promo_code_set_name: "Please add promo code set name",
    please_select_file_or_drag_and_drop_file_for_upload:
        "Please select file or drag and drop file for upload.",
    import_your_table_now: "Import your table now",
    date_import: "Date Import",
    table_name: "Table Name",
    user_account: "User Account",
    file_name: "File Name",
    fail_reason: "Fail Reason",
    number_of_rows: "Number Of Rows",
    wifi_password: "Wifi password",
    bottom_title: "Bottom title",
    product_variant_selling_inventory: "Product/Variant/Selling inventory",
    non_selling_inventory: "Non-selling inventory",
    assign_non_selling_inventory_to_variant: "Assign non-selling inventory to variant",
    assing_bom_inventory_to_inventory: "Assign bom-inventory to inventory",
    assign_addon_option_to_product: "Assign addon/option to product",
    verification: "Verification",
    import: "Import",
    success: "Success",
    view_status: "View Status",
    select_import_option: "Select import option",
    view_history: "View History",
    quick_edit: "Quick Edit",
    import_pos_data: "Import POS data",
    product_collection: "Product Collection",
    type_free: "Type Free",
    choice_within_list: "Choice within list",
    all_product: "All product",
    quantity_free: "Quantity Free",
    select_product_collection: "Select product collection",
    all_branch: "All Branch",
    ranking: {
        title: "Product Ranking",
        ranking: "Ranking",
    },
    time_15_min: "15 minutes",
    time_30_min: "30 minutes",
    time_1_hour: "1 hour",
    set_step_time_slot: "Set step time slot",
    remark: "Remark",
    detail: "Detail",
    in_restock_stock: "In Stock (Restock QTY)",
    select_req_trans: "Select Request Transfer",
    qr_code: "QR Code",
    download: "Download",
    remove: "Remove",
};
