import { ISvg } from "@interfaces";
export const IconStaff = (props: ISvg) => (
    <svg
        width={props.size || "24"}
        height={props.size || "24"}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <mask id="mask0_3102_215322" maskUnits="userSpaceOnUse" x="2" y="13" width="16" height="9">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 13.957H17.1838V21.0566H2V13.957Z"
                fill="white"
            />
        </mask>
        <g mask="url(#mask0_3102_215322)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.59176 15.457C6.81276 15.457 3.49976 15.814 3.49976 17.519C3.49976 18.871 5.54976 19.557 9.59176 19.557C13.6338 19.557 15.6838 18.864 15.6838 17.499C15.6838 16.144 13.6338 15.457 9.59176 15.457ZM9.59176 21.057C7.51576 21.057 1.99976 21.057 1.99976 17.519C1.99976 13.957 7.71376 13.957 9.59176 13.957C12.8508 13.957 17.1838 14.324 17.1838 17.499C17.1838 21.057 11.4698 21.057 9.59176 21.057Z"
                fill={props.color || "#0263E0"}
            />
        </g>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.59181 3.5C7.58681 3.5 5.95581 5.131 5.95581 7.135C5.95581 9.139 7.58681 10.77 9.59181 10.77H9.62281C10.5868 10.766 11.4948 10.387 12.1758 9.7C12.8578 9.015 13.2308 8.104 13.2268 7.138C13.2268 5.131 11.5958 3.5 9.59181 3.5ZM9.59181 12.27C6.75981 12.27 4.45581 9.966 4.45581 7.135C4.45581 4.304 6.75981 2 9.59181 2C12.4228 2 14.7268 4.304 14.7268 7.135C14.7328 8.499 14.2038 9.787 13.2398 10.757C12.2778 11.728 10.9928 12.265 9.62581 12.27H9.59181Z"
            fill={props.color || "#0263E0"}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.4824 11.1318C16.1144 11.1318 15.7934 10.8608 15.7404 10.4858C15.6834 10.0758 15.9684 9.6958 16.3784 9.6388C17.6264 9.4638 18.5684 8.3808 18.5704 7.1188C18.5704 5.8648 17.6724 4.8068 16.4374 4.6048C16.0284 4.5368 15.7514 4.1518 15.8184 3.7428C15.8854 3.3338 16.2724 3.0588 16.6794 3.1238C18.6444 3.4458 20.0704 5.1268 20.0704 7.1198C20.0664 9.1248 18.5694 10.8468 16.5874 11.1248C16.5524 11.1298 16.5174 11.1318 16.4824 11.1318Z"
            fill={props.color || "#0263E0"}
        />
        <mask id="mask1_3102_215322" maskUnits="userSpaceOnUse" x="17" y="13" width="5" height="6">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.8452 13.4834H21.979V18.4609H17.8452V13.4834Z"
                fill="white"
            />
        </mask>
        <g mask="url(#mask1_3102_215322)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.8955 18.4609C19.5925 18.4609 19.3075 18.2759 19.1945 17.9769C19.0475 17.5899 19.2425 17.1559 19.6295 17.0099C20.4795 16.6879 20.4795 16.2949 20.4795 16.1269C20.4795 15.5599 19.8085 15.1719 18.4855 14.9749C18.0755 14.9129 17.7925 14.5309 17.8535 14.1219C17.9155 13.7119 18.3045 13.4369 18.7065 13.4899C21.4125 13.8949 21.9795 15.1479 21.9795 16.1269C21.9795 16.8559 21.6645 17.8429 20.1615 18.4119C20.0745 18.4449 19.9845 18.4609 19.8955 18.4609Z"
                fill={props.color || "#0263E0"}
            />
        </g>
    </svg>
);
