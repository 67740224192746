export const FolderIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="33"
            viewBox="0 0 32 33"
            fill="none"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1 9.35074C1 6.94789 3.03258 5 5.5399 5H9.66736C10.858 5 12.0009 5.44822 12.85 6.24808L13.6289 6.98186C14.4779 7.78172 15.6209 8.22994 16.8115 8.22994H26.4601C28.9674 8.22994 31 10.1778 31 12.5807V23.6493C31 26.0521 28.9674 28 26.4601 28H5.5399C3.03258 28 1 26.0521 1 23.6493V9.35074Z"
                fill="url(#paint0_linear_18241_37523)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_18241_37523"
                    x1="-27.7869"
                    y1="0.330438"
                    x2="-15.8687"
                    y2="42.8736"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#8FC6FE" />
                    <stop offset="1" stopColor="#0080FF" />
                </linearGradient>
            </defs>
        </svg>
    );
};
