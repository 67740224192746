export const DoneIcon = () => {
    return (
        <svg
            width="24"
            height="23"
            viewBox="0 0 24 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_12159_501741)">
                <path
                    d="M11.8999 3C7.20156 3 3.3999 6.80206 3.3999 11.5C3.3999 16.1983 7.20196 20 11.8999 20C16.5982 20 20.3999 16.1979 20.3999 11.5C20.3999 6.80166 16.5978 3 11.8999 3ZM11.8999 18.8667C7.83793 18.8667 4.53322 15.562 4.53322 11.5C4.53322 7.438 7.83793 4.13332 11.8999 4.13332C15.9619 4.13332 19.2666 7.43803 19.2666 11.5C19.2666 15.562 15.9619 18.8667 11.8999 18.8667Z"
                    fill="#00B900"
                />
                <path
                    d="M16.3994 8.37655C16.1781 8.15525 15.8192 8.15525 15.598 8.37655L10.5532 13.4213L8.20174 11.0699C7.98044 10.8486 7.62161 10.8486 7.40035 11.0699C7.17905 11.2912 7.17905 11.65 7.40035 11.8713L10.1525 14.6234C10.3737 14.8447 10.7326 14.8446 10.9538 14.6234L16.3994 9.17791C16.6207 8.95661 16.6207 8.59785 16.3994 8.37655Z"
                    fill="#00B900"
                />
            </g>
            <defs>
                <clipPath id="clip0_12159_501741">
                    <rect width="17" height="17" fill="white" transform="translate(3.3999 3)" />
                </clipPath>
            </defs>
        </svg>
    );
};
