import { Drawer } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useFormik } from "formik";
import { useYup } from "@validations";
import { SharedInput, StyledSubmitButton, StyledCancelButton } from "@components";
import { IDivisionParams } from "@interfaces";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getDivisions, selectAuth, setLoading } from "@redux";
import { orgApi } from "@api";
import { useState } from "react";
import { useNotify } from "@utils";
interface Iprops {
    isVisible: boolean;
    onClose: () => void;
}

const initData: IDivisionParams = {
    organization_id: "",
    division_name: "",
    line_notify_token: "",
};

export const ModuleDrawerForm = (props: Iprops) => {
    const { isVisible, onClose } = props;
    const { t } = useTranslation();
    const { YupDivision } = useYup();
    const createDivision = Yup.object().shape(YupDivision);
    const organization_id = useSelector(selectAuth).auth?.organization_data?._id;
    const dispatch = useDispatch();
    const { success } = useNotify();

    const handleNewsSubmit = async (values) => {
        dispatch(setLoading(true));
        try {
            const { data } = await orgApi.createDivision({
                ...values,
                organization_id: organization_id || "",
                line_notify_token: "",
            });
            if (data.error === "0") {
                success(data.msg);
                dispatch(getDivisions({ organization_id: organization_id || "" }));
                dispatch(setLoading(false));
                onClose();
            } else {
                success(data.msg);
                dispatch(setLoading(false));
            }
        } catch (err: any) {
            dispatch(setLoading(false));
            onClose();
        }
    };
    const { values, handleChange, handleBlur, handleSubmit, errors, touched, setFieldValue } =
        useFormik({
            initialValues: initData,
            validationSchema: createDivision,
            enableReinitialize: true,
            onSubmit: handleNewsSubmit,
        });

    return (
        <StyledDrawer
            width={538}
            visible={isVisible}
            onClose={onClose}
            title={t("page.add_division")}
        >
            <form onSubmit={handleSubmit}>
                <SharedInput
                    label={t("page.division_name")}
                    // descLabel={t("page._2_100_characters")}
                    name="division_name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.division_name}
                    errors={errors.division_name}
                    touched={touched.division_name}
                    parentClassName="mb-6"
                />
                <div className="btn-layout">
                    <StyledSubmitButton
                        type="default"
                        text={t("page.save")}
                        htmlType="submit"
                        // disable={isSubmitting}
                    />
                    <StyledCancelButton
                        type="sub"
                        text={t("page.cancel")}
                        htmlType="button"
                        onClick={onClose}
                    />
                </div>
            </form>
        </StyledDrawer>
    );
};
const StyledDrawer = styled(Drawer)`
    .btn-layout {
        position: fixed;
        bottom: 14px;
    }
`;
