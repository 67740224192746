export default {
    payment_setting: "Payment Settings",
    qr_payment_promptpay: "QR Payment (Prompt pay)",
    image_qr_code: "Image QR Code",
    bank_name: "Bank Name",
    please_select_bank: "Please Select Bank",
    account_no: "Account No.",
    account_name: "Account Name",
    branch: "Branch",
    set_as_default_checkout_qr_code_payment: "Set as default check out QR code payment",
    static_qr_code_promptpay: "Static QR Code Promptpay",
    kbank_consumer_id: "KBank consumer id",
    kbank_consumer_secret: "KBank consumer secret",
    kbank_merchant_id: "KBank merchant id",
    kbank_partner_id: "KBank partner id",
    kbank_partner_secret: "KBank partner secret",
};
