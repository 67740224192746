export const AssignProductIcon = () => (
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.1 13.5H7.5C8.60457 13.5 9.5 14.3954 9.5 15.5V6.5C9.5 4.63623 9.5 3.70435 9.19552 2.96927C8.78954 1.98915 8.01085 1.21046 7.03073 0.804482C6.29565 0.5 5.36377 0.5 3.5 0.5C2.56812 0.5 2.10218 0.5 1.73463 0.652241C1.24458 0.855229 0.855228 1.24458 0.652241 1.73463C0.5 2.10218 0.5 2.56812 0.5 3.5V9.9C0.5 11.3999 0.5 12.1498 0.881966 12.6756C1.00533 12.8454 1.15464 12.9947 1.32443 13.118C1.85016 13.5 2.60011 13.5 4.1 13.5Z"
            fill="#6C7084"
        />
        <path
            d="M15.9 13.5H12.5C11.3954 13.5 10.5 14.3954 10.5 15.5V6.5C10.5 4.63623 10.5 3.70435 10.8045 2.96927C11.2105 1.98915 11.9892 1.21046 12.9693 0.804482C13.7044 0.5 14.6362 0.5 16.5 0.5C17.4319 0.5 17.8978 0.5 18.2654 0.652241C18.7554 0.855229 19.1448 1.24458 19.3478 1.73463C19.5 2.10218 19.5 2.56812 19.5 3.5V9.9C19.5 11.3999 19.5 12.1498 19.118 12.6756C18.9947 12.8454 18.8454 12.9947 18.6756 13.118C18.1498 13.5 17.3999 13.5 15.9 13.5Z"
            fill="#6C7084"
        />
    </svg>
);
