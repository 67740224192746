export default {
    delete: {
        success: "Delete successfully",
        fail: "Delete failed",
    },
    duplicate: {
        success: "Duplicate successfully",
        fail: "Duplicate failed",
    },
    create: {
        success: "Create successfully",
        fail: "Create failed",
    },
    update: {
        success: "Update successfully",
        fail: "Update failed",
    },
    form_validate: {
        warning: "Some fields are invalid. Please check!",
    },
    reset_pass: {
        fail: "Reset password not successfully!",
        success: "Reset password successfully!",
    },
    sign_up: {
        success: "Sign up successfully!",
        fail: "Sign up not successfully!",
    },
    upload_image: {
        success: "Upload image successfully!",
        fail: "Upload image  fail!",
    },
    merge: {
        success: "Merge successfully! ",
        fail: "Merge suggestions fail!",
    },
    export_segment_success: "Export segment successfully!",
    upload_image_big_size: "Image too big size",
    delete_confirm: "Are you sure delete this {{name}}?",
    exist_email: "Email already exist!",
    not_found: "This {{name}} is not found",
    delete_title: "Are you sure?",
    try_again_text: "Have an error. Please try again",
    invalid: "Invalid page",
    not_permission: "You don't have this permission",
    error: "Have an undefine error. Please try again",
    you_need_login: "You need login to do this action",
    email_send: "Email sent",
    forbidden: "You don't have this permission",
    copied: "Copied",
    export_file_fail: "Export file fail!",
    copy: "Copy",
    feature_not_available: "The feature is not available in this Plan",
    change_password_fail: "Change password fail!",
    reset_password_fail: "Reset password fail!",
    sign_up_fail: "Sign up fail!",
    create_account_fail: "Create account fail!",
    update_profile_fail: "Update profile fail!",
    phone_number_invalid: "Phone number is not available",
    admin_panel_notification_setting_is_turn_off: "Admin pane notification setting is turn off",
    export_list_contact_success: "Export list contact successfully!",
    download_file_success: "Download file successfully!",
    import_successfully: "Import successfully!",
    congrat_loyalty_program: "Congratultion!, your Loyalty Program has been Successfully!",
    fail_loyalty_program: "Update Loyalty Program fail",
    do_you_want_to_delete: "Do you want to delete ?",
    please_confirm_to_delete: "Please confirm to delete",
    customer_not_found: "Customer Not Found",
    user_not_found: "User not found",
    request_permission: "You will need to give a permission",
};
