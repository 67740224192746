import { useTranslation } from "react-i18next";
import { SharedInput, StyledSubmitButton } from "../shared";
import styled from "styled-components";
import { showErrorMessage, useNotify } from "@utils";
import { Col, Modal, Row, Select } from "antd";
import { INewsCategory, IProduct, IProductEdit } from "@interfaces";
import { useFormik } from "formik";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth, setLoading } from "@redux";
import { useHistory } from "react-router";
import { productAPI } from "@api";
import { useEffect } from "react";

interface IProps {
    showPopup: boolean;
    handleClose: () => void;
    categories: INewsCategory[] | undefined;
    selectedProduct: IProduct;
    setRefresh: any;
    Refresh: number;
}

const initData: IProduct = {
    organization_code: "",
    organization_id: "",
    product_code: "",
    name: "",
    standard_price: 0,
    discount_in_baht: 0,
    category_id: "",
    image_url_list: [],
    is_variant: false,
    is_auto_consumable: true,
    variant_dimensions: [
        {
            dimension_name: "",
            dimension_option_name: [""],
        },
    ],
    variant_name_header: "",
    variant_list: [
        {
            _id: uuidv4(),
            variant_code: "",
            variant_name: "",
            price: 0,
            is_enable: true,
            raw_price: 0,
            discount_in_percent: 0,
            is_auto_consumable: false,
            store_list: [],
            dimension_list: [
                {
                    dimension_name: "",
                    dimension_option_name: "",
                },
            ],
            consumable_list: [],
            consumable_used_qty: 1,
        },
    ],
    description: "",
    condition: "",
    text_block_list: [],
    is_enable: false,
    addon_id_list: [],
    product_addon_list: [],
    product_addon_list_master: [],
    addon_list: [],
    sku_earn_point: 0,
    hint_code: "",
    brand_id: "",
    brand_name: "",
    promotion_group_list: [],
    promotion_group_name: "",
};

export const ComponentQuickEditModal = (props: IProps) => {
    const { showPopup, handleClose, categories, selectedProduct, setRefresh, Refresh } = props;
    const { t } = useTranslation();
    const { success, error } = useNotify();
    const { Option } = Select;
    const dispatch = useDispatch();
    const organization_id = useSelector(selectAuth).userInfo?.merchant?._id;

    useEffect(() => {
        setFieldValue("name", selectedProduct.name);
        setFieldValue("category_id", selectedProduct.category_id);
    }, [selectedProduct]);

    const handleSaveProduct = async () => {
        try {
            dispatch(setLoading(true));
            if (selectedProduct) {
                let updatePayload: IProductEdit = {
                    organization_id: organization_id,
                    product_id: selectedProduct._id,
                    product_code: selectedProduct.product_code,
                    name: values.name,
                    standard_price: selectedProduct.standard_price,
                    discount_in_baht: selectedProduct.discount_in_baht,
                    category_id: values.category_id,
                    is_auto_consumable: values.is_auto_consumable,
                    image_url_list: selectedProduct.image_url_list,
                    is_variant: selectedProduct.is_variant,
                    variant_name_header: selectedProduct.variant_name_header,
                    variant_list: selectedProduct.variant_list,
                    description: selectedProduct.description,
                    condition: selectedProduct.condition,
                    text_block_list: selectedProduct.text_block_list,
                    is_delete: selectedProduct.is_delete,
                    is_enable: selectedProduct.is_enable,
                    _id: selectedProduct._id,
                    addon_id_list: selectedProduct.addon_id_list,
                    product_addon_list: selectedProduct.product_addon_list,
                    variant_dimensions: selectedProduct.variant_dimensions,
                    excluded_store_id_list: selectedProduct.excluded_store_id_list,
                    sku_earn_point: selectedProduct.sku_earn_point,
                    hint_code: selectedProduct.hint_code,
                    brand_id: selectedProduct.brand_id,
                    promotion_group_list: selectedProduct.promotion_group_list,
                    promotion_group_name: selectedProduct.promotion_group_name,
                    auto_consumable_formula: {
                        restock_unit_uom_id:
                            selectedProduct.auto_consumable_formula?.restock_unit_uom_id,
                        per_use_unit: selectedProduct.auto_consumable_formula?.per_use_unit,
                    },
                    is_weight: selectedProduct?.is_weight,
                    weight_detail: {
                        price_in_baht: selectedProduct.weight_detail?.price_in_baht,
                        per_uom_qty: selectedProduct.weight_detail?.per_uom_qty,
                    },
                    uom_detail: {
                        uom_id: selectedProduct.uom_detail?.uom_id,
                        uom_name: selectedProduct.uom_detail?.uom_name,
                    },
                    is_share_consumable: selectedProduct.is_share_consumable,
                };
                const res = await productAPI.update(updatePayload);
                if (res.data.error === "0") {
                    success(t("message.update.success"));
                    handleClose();
                    setRefresh(Refresh + 1);
                } else {
                    error(res.data.msg);
                }
            }
        } catch (err: any) {
            if (err.response) {
                error(showErrorMessage(err.response));
            } else error(t("message.error"));
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const { values, handleChange, setFieldValue } = useFormik({
        initialValues: initData,
        enableReinitialize: true,
        onSubmit: handleSaveProduct,
    });

    return (
        //@ts-ignore
        <StyledModalContent
            visible={showPopup}
            onCancel={handleClose}
            title={t("page.quick_edit")}
            width={440}
            footer={false}
        >
            <Row>
                <Col span={24}>
                    <SharedInput
                        label={t("page.name")}
                        onChange={handleChange}
                        name="name"
                        value={values.name}
                        parentClassName="mb-6"
                    />
                </Col>
            </Row>
            <Row className="selectionBox">
                <Col span={24}>
                    <p className="body1">{t("page.category")}</p>
                    <Select
                        showSearch
                        filterOption={(input, option) =>
                            //@ts-ignore
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        style={{ width: "100%", height: 48 }}
                        placeholder={t("page.category")}
                        onChange={(value: string) => {
                            setFieldValue("category_id", value);
                        }}
                        defaultActiveFirstOption={true}
                        value={values.category_id}
                    >
                        {categories &&
                            categories.map((item, index) => {
                                return (
                                    <Option key={index} value={item._id}>
                                        {item.category_name}
                                    </Option>
                                );
                            })}
                    </Select>
                </Col>
            </Row>
            <div className="btn-layout">
                <StyledSubmitButton
                    type="default"
                    text={t("page.save")}
                    htmlType="button"
                    onClick={handleSaveProduct}
                    style={{ marginRight: 0 }}
                />
            </div>
        </StyledModalContent>
    );
};

const StyledModalContent = styled(Modal)`
    .ant-modal-content {
        padding: 24px !important;
    }
    .ant-modal-header {
        padding: 0 24px 16px 0 !important;
        border: none;
    }
    .ant-modal-close-x {
        position: absolute;
        top: -25px;
        right: -10px;
    }
    .selectionBox .body1 {
        margin: 0;
        color: #000;
        font-weight: 700;
        margin-bottom: 8px;
        cursor: default;
    }
    .btn-layout {
        display: flex;
        justify-content: end;
        margin-top: 20px;
    }
`;
