export const IconDone = () => (
    <svg width="59" height="58" viewBox="0 0 59 58" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_8366_462572)">
            <rect
                x="4.5"
                y="4"
                width="42"
                height="42"
                rx="12"
                fill="url(#paint0_linear_8366_462572)"
                shapeRendering="crispEdges"
            />
            <path
                d="M34.6871 23.007C34.3623 22.8182 34.0705 22.5777 33.8231 22.295C33.8481 21.9014 33.9418 21.5152 34.1001 21.154C34.3911 20.333 34.7201 19.403 34.1921 18.68C33.6641 17.957 32.6671 17.98 31.7921 18C31.4054 18.0398 31.0148 18.0134 30.6371 17.922C30.4358 17.5945 30.2921 17.235 30.2121 16.859C29.9641 16.014 29.6811 15.059 28.8121 14.773C27.9741 14.503 27.1981 15.097 26.5121 15.619C26.2161 15.8893 25.8731 16.1032 25.5001 16.25C25.1232 16.1044 24.7764 15.8905 24.4771 15.619C23.7931 15.1 23.0201 14.5 22.1781 14.774C21.3111 15.056 21.0281 16.014 20.7781 16.859C20.6982 17.2338 20.5559 17.5924 20.3571 17.92C19.9786 18.0112 19.5875 18.0382 19.2001 18C18.3221 17.976 17.3331 17.95 16.8001 18.68C16.2671 19.41 16.6001 20.333 16.8921 21.153C17.0525 21.5137 17.1477 21.9 17.1731 22.294C16.9261 22.5771 16.6346 22.8179 16.3101 23.007C15.5781 23.507 14.7471 24.076 14.7471 25C14.7471 25.924 15.5781 26.491 16.3101 26.993C16.6346 27.1818 16.9261 27.4223 17.1731 27.705C17.1503 28.0988 17.0579 28.4855 16.9001 28.847C16.6101 29.667 16.2821 30.597 16.8091 31.32C17.3361 32.043 18.3301 32.02 19.2091 32C19.596 31.9602 19.987 31.9866 20.3651 32.078C20.5655 32.4058 20.7088 32.7653 20.7891 33.141C21.0371 33.986 21.3201 34.941 22.1891 35.227C22.3284 35.2717 22.4738 35.2946 22.6201 35.295C23.3233 35.1941 23.9769 34.8743 24.4881 34.381C24.7841 34.1107 25.1271 33.8968 25.5001 33.75C25.877 33.8956 26.2238 34.1095 26.5231 34.381C27.2081 34.904 27.9841 35.501 28.8231 35.226C29.6901 34.944 29.9731 33.986 30.2231 33.142C30.3032 32.7665 30.4466 32.4074 30.6471 32.08C31.0241 31.9882 31.414 31.9612 31.8001 32C32.6781 32.021 33.6671 32.05 34.2001 31.32C34.7331 30.59 34.4001 29.667 34.1081 28.846C33.9487 28.4856 33.8536 28.1001 33.8271 27.707C34.0741 27.4237 34.366 27.1828 34.6911 26.994C35.4231 26.494 36.2541 25.924 36.2541 25C36.2541 24.076 35.4201 23.508 34.6871 23.007Z"
                fill="white"
            />
            <path
                d="M24.5001 27.75C24.4016 27.7502 24.304 27.7308 24.2131 27.6931C24.1221 27.6553 24.0395 27.5999 23.9701 27.53L21.9701 25.53C21.8376 25.3878 21.7655 25.1998 21.7689 25.0055C21.7723 24.8112 21.8511 24.6258 21.9885 24.4884C22.1259 24.351 22.3113 24.2723 22.5056 24.2688C22.6999 24.2654 22.8879 24.3375 23.0301 24.47L24.5701 26.01L28.0501 23.4C28.2092 23.2807 28.4092 23.2294 28.6062 23.2575C28.8031 23.2857 28.9807 23.3909 29.1001 23.55C29.2194 23.7091 29.2707 23.9092 29.2426 24.1061C29.2144 24.303 29.1092 24.4807 28.9501 24.6L24.9501 27.6C24.8202 27.6973 24.6624 27.7499 24.5001 27.75Z"
                fill="#4ED9A8"
            />
        </g>
        <defs>
            <filter
                id="filter0_d_8366_462572"
                x="0.5"
                y="0"
                width="58"
                height="58"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dx="4" dy="4" />
                <feGaussianBlur stdDeviation="4" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.2 0 0 0 0 0.764706 0 0 0 0 0.564706 0 0 0 0.16 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_8366_462572"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_8366_462572"
                    result="shape"
                />
            </filter>
            <linearGradient
                id="paint0_linear_8366_462572"
                x1="2.925"
                y1="-0.200001"
                x2="33.7014"
                y2="42.2787"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#6AF0C1" />
                <stop offset="0.699186" stopColor="#44D19F" />
                <stop offset="1" stopColor="#33C390" />
            </linearGradient>
        </defs>
    </svg>
);
