import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { StyledNewDetail } from "@components";
import { PATH_NEWS } from "@configs";
import { useNotify, showErrorMessage } from "@utils";
import { setLoading, selectAuth } from "@redux";
import { ModuleBillCalculationForm } from "src/Modules/bill_calculation";
import { orgApi } from "@api";

export const PageBillCalculation = () => {
    const { id } = useParams<{ id: string }>();
    const org_id = useSelector(selectAuth).userInfo?.merchant._id;
    const dispatch = useDispatch();
    const history = useHistory();
    const { warning } = useNotify();
    const { t } = useTranslation();
    const [store, setStore] = useState<any>();

    useEffect(() => {
        fetchNews();
    }, []);

    const fetchNews = async () => {
        dispatch(setLoading(true));
        try {
            const getStore = await orgApi.getVatDetail({
                organization_id: org_id,
            });
            setStore(getStore.data.result);
            dispatch(setLoading(false));
        } catch (err: any) {
            if (err.response) {
                warning(showErrorMessage(err.response));
                dispatch(setLoading(false));
            } else warning(t("message.not_found", { name: t("object._reward") }));
            history.push(PATH_NEWS);
        } finally {
            dispatch(setLoading(false));
        }
    };

    return <StyledNewDetail>{<ModuleBillCalculationForm store={store} />}</StyledNewDetail>;
};
