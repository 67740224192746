import { IDeleteChannel, IGetChannel, IGetProdcutSelect, IGetWholesaleList, IGetWholesaleTemplate, IPostCreateChannel, ISetEnableChannel } from "@interfaces";
import storeFrontAxiosClient from "./storeFrontAxiosClient";


export const wholesaleAPI = {
    getList: (params: IGetWholesaleList) => {
        const url = "sale_channel/getSaleChannels";
        return storeFrontAxiosClient.get(url, { params });
    },
    getChannel: (params: IGetChannel) => {
        const url = "sale_channel/getSaleChannel";
        return storeFrontAxiosClient.get(url, { params });
    },
    getTemplate: (params: IGetWholesaleTemplate) => {
        const url = "sale_channel/getAllProductAddonTemplate";
        return storeFrontAxiosClient.get(url, { params });
    },
    getProductSelect: (params: IGetProdcutSelect) => {
        const url = "product/getProductsByProductIdList";
        return storeFrontAxiosClient.post(url, params);
    },
    createOrder: (params: IPostCreateChannel) => {
        const url = "sale_channel/createSaleChannel";
        return storeFrontAxiosClient.post(url, params);
    },
    updateOrder: (params: IPostCreateChannel) => {
        const url = "sale_channel/updateSaleChannel";
        return storeFrontAxiosClient.patch(url, params);
    },
    setEnableChannel: (params: ISetEnableChannel) => {
        const url = "sale_channel/setEnableSaleChannel";
        return storeFrontAxiosClient.patch(url, params);
    },
    deleteChannel: (params: IDeleteChannel) => {
        const url = "sale_channel/deleteSaleChannel";
        return storeFrontAxiosClient.post(url, params);
    },
};