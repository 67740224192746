export const ImportFailedIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 0C4.481 0 0 4.481 0 10C0 15.519 4.481 20 10 20C15.519 20 20 15.519 20 10C20 4.481 15.519 0 10 0ZM8.585 10L5.757 7.171C5.367 6.781 5.367 6.147 5.757 5.757C6.147 5.367 6.781 5.367 7.171 5.757L10 8.586L12.828 5.757C13.218 5.367 13.852 5.367 14.242 5.757C14.633 6.147 14.633 6.781 14.242 7.171L11.414 10L14.242 12.828C14.633 13.218 14.633 13.852 14.242 14.242C13.852 14.633 13.218 14.633 12.828 14.242L10 11.414L7.171 14.242C6.781 14.633 6.147 14.633 5.757 14.242C5.367 13.852 5.367 13.218 5.757 12.828L8.585 10Z"
                fill="#F22F46"
            />
        </svg>
    );
};
