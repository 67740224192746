import { useTranslation } from "react-i18next";
import {
    SharedComponentDrawer,
    SharedInput,
    SharedModal,
    StyledCancelButton,
    StyledSubmitButton,
} from "../shared";
import styled from "styled-components";
import { enumValidation, IMAGE_TYPE } from "@configs";
import { useNotify } from "@utils";
import axios from "axios";
import { useEffect, useState } from "react";
import { Avatar, Button, Tooltip } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { InfoIcon, IconCamera } from "@components";
import { useFormik } from "formik";
import { INews, INewsCategory, INewsData } from "@interfaces";
import * as Yup from "yup";
import { useYup } from "@validations";
import React from "react";
import { ColumnProps } from "antd/lib/table";
import { Table } from "antd";

interface IProps {
    showPopup: boolean;
    handleClose: () => void;
    historyChange?: any;
    tableData?: any;
    columns?: any;
}

export const ComponentStockModal = (props: IProps) => {
    const { YupNews } = useYup();
    const { showPopup, handleClose, historyChange, tableData, columns } = props;
    const { t } = useTranslation();
    const { success, error } = useNotify();
    const [variantImage, setVariantImage] = useState<string>("");
    const [variantName, setVariantName] = useState<any>("");
    const textString = "390px × 270px Maximum 10MB";
    const createNews = Yup.object().shape(YupNews);

    const newDate = new Date();
    // form inital data
    const initData: INewsData = {
        organization_id: "",
        title: "",
        detail: "",
        image_url: [],
        is_hot_news: false,
        news_category_id: "",
        date_from: new Date(),
        date_to: new Date(newDate.setFullYear(newDate.getFullYear() + 1)),
        is_enable: true,
        discount: "",
        product_code: "",
        price: "",
        discount_type: "",
        has_barcode: true,
        active_on_platform: [],
        point_deduction: false,
        maximum_deduction: false,
        point_deduction_value: "",
        variants: [
            {
                variant_name: "",
                qty: "",
                raw_price: "",
                net_price: "",
                barcode: "",
                status: true,
            },
        ],
    };

    const handleRemoveImage = () => {
        // remove action from form value
        console.log("remove action");
    };
    const handleChangeImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
        try {
            //@ts-ignore
            const image = e.target.files[0];
            if (!image) {
                //WHAT: image empty
                error(t("validation.image.empty"));
                return;
            }
            if (!IMAGE_TYPE.some((imageType) => imageType === image.type)) {
                error(t("validation.image.wrong_type"));
                return;
            }
            if (image.size >= enumValidation.MAX_FILE_SIZE) {
                error(t("validation.image.max_size"));
                return;
            }
            const formData = new FormData();
            formData.append("file", image);
            // const res = await merchantAPI.uploadImage(formData);
            const headers = {
                "access-token":
                    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2MmFmZTRkYWM2NWFmNjhkYWM0NThlYjgiLCJtZXJjaGFudElkIjoiNjI5ZGM4YzI5NzA4ZDFlNzc4ZTBiMjNiIiwiZW1haWwiOiIiLCJ1c2VyVHlwZSI6Ik1FUkNIQU5UIiwicGVybWlzc2lvbnMiOlsibWVyY2hhbnRfbWFuYWdlX2NvbnRhY3RfYW5kX2VkaXRfcG9pbnQiLCJtZXJjaGFudF9jcmVhdGVfbG95YWx0eSIsIm1lcmNoYW50X2NoYW5nZV9hY2NvdW50X3NldHRpbmciLCJtZXJjaGFudF9tYW5hZ2Vfcm9sZSJdLCJpYXQiOjE2Njg3NDY4MDQsImV4cCI6MTY3MTMzODgwNCwiYXVkIjoicmV3YXJkaW5nUGxhdGZvcm06YXV0aCIsImlzcyI6InJld2FyZGluZ1BsYXRmb3JtIn0.cna-C93EFwZoSqmvFURNTq7Pw00oGcXH8mEUvvFEfXs",
            };
            const res = await axios.post(
                "https://api-store-admin.rocket-tech.app/1.0.0/uploads/upload_file",
                formData,
                { headers: headers }
            );
            setVariantImage(res.data.result.publicUrl);
            console.log(res.data.result.publicUrl);
            // await console.log("upload image api call here");
        } catch (err: any) {
            //
            // const data = error.response.data;
            if (err) {
                error(err.msg);
            } else {
                error(t("page.image_error"));
            }
        }
    };
    const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setVariantName(e.target.value);
        // values.variants.shift();
    };

    return (
        <SharedModal
            showPopup={showPopup}
            handleClose={handleClose}
            title={t("page.history_edit_quantity")}
            width={900}
        >
            {/* modify popup content & css here */}
            <StyledModalContent>
                <Table columns={columns} dataSource={tableData} bordered />
            </StyledModalContent>
        </SharedModal>
    );
};

const StyledModalContent = styled.div`
    .ant-drawer-body {
        padding: 0 !important;
    }
`;
