import { useEffect, memo, useState, useRef, ChangeEvent } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import xlsx from "json-as-xlsx";
import {
    Row,
    Col,
    Form,
    TimePicker,
    Switch,
    Divider,
    Button,
    DatePicker,
    Select,
    Radio,
} from "antd";
import styled from "styled-components";
import moment from "moment";
import {
    ICreateBillPromotion,
    IGetBillPromotionExample,
    IGetWholesaleListExample,
    IPostCreateChannel,
} from "@interfaces";
import { StyledSubmitButton, StyledCancelButton, StyledCard, SharedInput, BranchIcon, ComponentPromotionChannelIncludeModal } from "@components";
import { useYup } from "@validations";
import { setLoading, selectAuth } from "@redux";
import { showErrorMessage, theme, useNotify } from "@utils";
import {
    dateFormatYear,
    PAGE_START,
    PAGINATION,
    PATH_BILL_PROMOTION,
    PATH_BILL_PROMOTION_CREATE,
} from "@configs";
import { orgApi, promotionAPI, wholesaleAPI } from "@api";
import { DeleteIcon } from "src/Components/icon/DeleteIcon";
import { ExportIcon } from "src/Components/icon/ExportIcon";
import * as XLSX from "xlsx";
import { ModuleUploadImage } from "../form";
import { billPromotionAPI } from "src/API/billPromotion";
import { RadioChangeEvent } from "antd/lib/radio";
import Column from "antd/lib/table/Column";
interface IProps {
    promotion?: IGetBillPromotionExample;
}

const initData: ICreateBillPromotion = {
    organization_id: "",
    bill_promotion_image_url: "",
    bill_promotion_name: "",
    discount_percent: 0,
    is_enable: true,
    is_validity_period: false,
    max_discount_value: 0,
    remark: "",
    validity_date_from_string: moment().format(dateFormatYear).toString(),
    validity_date_to_string: moment().format(dateFormatYear).toString(),
    validity_time_from_string: "00:00",
    validity_time_to_string: "23:59",
    unique_promo_code_list: [],
    bill_promotion_type: "",
    discount_baht: 0,
    is_apply_unique_code: false,
    sale_channel_id: "",
    min_order_value: 0,
    is_all_sale_channel: false,
    bill_promotion_category_id: "",
    sale_channel_id_list: [],
};
const INITAL_PAGE = {
    xpage: PAGE_START,
    xlimit: PAGINATION,
};

export const ModuleBillPromotionForm = memo((props: IProps) => {
    const { promotion } = props;
    const { YupPromotion } = useYup();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { error, success, warning } = useNotify();
    const organization_id: any = useSelector(selectAuth).userInfo?.merchant?._id;
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const createNews = Yup.object().shape(YupPromotion);
    const pathName = history.location.pathname;
    const [channels, setChannels] = useState<IGetWholesaleListExample[]>([]);
    const [categoryList, setCategoryList] = useState<any>([]);
    const [channelModal, setChannelModal] = useState<boolean>(false);
    const [promotionChannels, setPromotionChannels] = useState<IPostCreateChannel[]>([]);
    const inputFile = useRef(null);


    const MONTHlIST = [
        t("month.jan"),
        t("month.feb"),
        t("month.mar"),
        t("month.apr"),
        t("month.may"),
        t("month.jun"),
        t("month.jul"),
        t("month.aug"),
        t("month.sep"),
        t("month.oct"),
        t("month.nov"),
        t("month.dec"),
    ];

    function formatDate(date) {
        let d = new Date(date),
            month = '' + d.getMonth(),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return [day, MONTHlIST[parseInt(month)], year + 543].join(' ');
    };

    const fetchNews = async () => {
        dispatch(setLoading(true));
        try {
            const getChannels = await wholesaleAPI.getList({
                organization_id: organization_id,
                xlimit: 1000,
                xpage: 1,
            });
            if (getChannels.data.error === "0") {
                setChannels(getChannels.data.result);
            }
        } catch (err) {
            console.error(err);
        } finally {
            dispatch(setLoading(false));
        }
    };

    useEffect(() => {
        fetchNews();
        fetchCategories();
        if (promotion) {
            setFieldValue("sale_channel_id_list", promotion?.sale_channel_id_list);
            setFieldValue("bill_promotion_name", promotion.bill_promotion_name);
            setFieldValue("bill_promotion_image_url", promotion.bill_promotion_image_url);
            setFieldValue("discount_percent", promotion.discount_percent);
            setFieldValue("is_enable", promotion.is_enable);
            setFieldValue("is_validity_period", promotion.is_validity_period);
            setFieldValue("max_discount_value", promotion.max_discount_value);
            setFieldValue("validity_date_from_string", promotion.validity_date_from_string);
            setFieldValue("validity_date_to_string", promotion.validity_date_to_string);
            setFieldValue("validity_time_from_string", promotion.validity_time_from_string);
            setFieldValue("validity_time_to_string", promotion.validity_time_to_string);
            setFieldValue("unique_promo_code_list", promotion.unique_promo_code_list);
            setFieldValue("bill_promotion_type", promotion.bill_promotion_type);
            setFieldValue("discount_baht", promotion.discount_baht);
            setFieldValue("is_apply_unique_code", promotion.is_apply_unique_code);
            setFieldValue("sale_channel_id", promotion.sale_channel_id);
            setFieldValue("is_all_sale_channel", promotion.is_all_sale_channel);
            setFieldValue("min_order_value", promotion.min_order_value);
            setFieldValue("bill_promotion_category_id", promotion.bill_promotion_category_id);
        }
    }, [promotion]);

    const fetchCategories = async () => {
        // get category list and channel list
        dispatch(setLoading(true));
        try {
            const getCategories = await orgApi.getPosPromotionCategorys({
                organization_id: organization_id,
            });
            const getChannels = await wholesaleAPI.getList({
                organization_id: organization_id,
                xpage: 1,
                xlimit: 100,
            });
            setCategoryList(getCategories.data.result);
            setPromotionChannels([
                {
                    _id: "629dc8c29708d1e778e0b23b",
                    sale_channel_name: "Dine in",
                    sale_channel_code: "",
                    sale_channel_id: "",
                },
                ...getChannels.data.result
            ]);
        } catch (err: any) {
            error(err.msg);
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleAssignSubmit = async () => {
        dispatch(setLoading(true));
        try {
            if (pathName === PATH_BILL_PROMOTION_CREATE) {
                const save = await billPromotionAPI.createBillPromotion({
                    organization_id: organization_id,
                    bill_promotion_name: values.bill_promotion_name,
                    bill_promotion_image_url: values.bill_promotion_image_url,
                    discount_percent: values.discount_percent,
                    max_discount_value: values.max_discount_value,
                    remark: "",
                    is_enable: true,
                    is_validity_period: values.is_validity_period,
                    validity_date_from_string: values.validity_date_from_string,
                    validity_date_to_string: values.validity_date_to_string,
                    validity_time_from_string: values.validity_time_from_string,
                    validity_time_to_string: values.validity_time_to_string,
                    bill_promotion_type: values.bill_promotion_type,
                    discount_baht: values.discount_baht,
                    is_apply_unique_code: values.is_apply_unique_code,
                    sale_channel_id: values.sale_channel_id === "" ? null : values.sale_channel_id,
                    min_order_value: values.min_order_value,
                    variant_exchange_list: [],
                    is_all_sale_channel: values.is_all_sale_channel,
                    bill_promotion_category_id: values.bill_promotion_category_id,
                });
                if (save.data.error === "0") {
                    success(t("message.create.success"));
                    history.push(PATH_BILL_PROMOTION);
                } else {
                    error(save.data.msg);
                }
            } else {
                const save = await billPromotionAPI.updateBillPromotion({
                    bill_promotion_id: promotion?._id,
                    organization_id: organization_id,
                    bill_promotion_name: values.bill_promotion_name,
                    bill_promotion_image_url: values.bill_promotion_image_url,
                    discount_percent: values.discount_percent,
                    max_discount_value: values.max_discount_value,
                    remark: "",
                    is_enable: true,
                    is_validity_period: values.is_validity_period,
                    validity_date_from_string: values.validity_date_from_string,
                    validity_date_to_string: values.validity_date_to_string,
                    validity_time_from_string: values.validity_time_from_string,
                    validity_time_to_string: values.validity_time_to_string,
                    bill_promotion_type: values.bill_promotion_type,
                    discount_baht: values.discount_baht,
                    is_apply_unique_code: values.is_apply_unique_code,
                    sale_channel_id: values.sale_channel_id === "" ? null : values.sale_channel_id,
                    min_order_value: values.min_order_value,
                    variant_exchange_list: [],
                    is_all_sale_channel: values.is_all_sale_channel,
                    bill_promotion_category_id: values.bill_promotion_category_id,
                });
                if (save.data.error === "0") {
                    success(t("message.update.success"));
                    history.push(PATH_BILL_PROMOTION);
                } else {
                    error(save.data.msg);
                }
            }
        } catch (err: any) {
            if (err.response) {
                error(showErrorMessage(err.response));
            } else error(t("message.error"));
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleCancel = () => {
        history.push(PATH_BILL_PROMOTION);
    };
    const format = "HH:mm";
    const { values, handleChange, handleBlur, handleSubmit, errors, touched, setFieldValue } =
        useFormik({
            initialValues: initData,
            validationSchema: createNews,
            enableReinitialize: true,
            onSubmit: handleAssignSubmit,
        });
    const handleAddPromoSet = () => {
        let temp: any = values.unique_promo_code_list || [];
        temp.push({
            name: "",
            is_validity_period: false,
            generate_amount: "",
            validity_date_from: {
                date: moment().format(dateFormatYear),
                time: "00:00",
            },
            validity_date_to: {
                date: moment().format(dateFormatYear),
                time: "00:00",
            },
            each_unique_promo_code: [],
        });
        setFieldValue("unique_promo_code_list", temp);
    };

    const generateRandomCodesSet = (index: number) => {
        const data: any = [];

        let temp: any = values.unique_promo_code_list;
        const titleRow: any = [];
        temp.forEach((code) => {
            titleRow.push({ code: "code" });
        });
        data.push(titleRow);

        const promoList: any[] = [];

        for (let i = 0; i < temp[index].generate_amount; i++) {
            const row: any[] = [];
            temp.forEach((code) => {
                if (i < code.generate_amount) {
                    row.push({
                        code: Math.floor(1000000000000 + Math.random() * 9000000000000),
                    });
                } else {
                    row.push({ code: "" });
                }
            });

            const rowData: any = {};
            row.forEach((col, colIndex) => {
                const titleCol = titleRow[colIndex].code;
                rowData[titleCol] = col[titleCol];
            });

            promoList.push(rowData);
        }
        temp[index].each_unique_promo_code = promoList;
        setFieldValue(`unique_promo_code_list[${index}].each_unique_promo_code`, promoList);
        storeGeneratedCodes(temp[index]);
        return data;
    };

    const storeGeneratedCodes = async (unique_promo) => {
        try {
            const save = await billPromotionAPI.createPromoCodeToBillPromotion({
                organization_id: organization_id,
                bill_promotion_id: promotion?._id,
                unique_promo_code: unique_promo,
            });
            if (save.data.error === "0") {
                success(t("message.update.success"));
                setFieldValue("unique_promo_code_list", save.data.result.unique_promo_code_list);
            } else {
                error(save.data.msg);
            }
        } catch (err: any) {
            error(showErrorMessage(err.response));
        }
    };
    const deleteGeneratedCodes = async (promo_id) => {
        try {
            const del = await billPromotionAPI.deletePromoCodeToBillPromotion({
                organization_id: organization_id,
                bill_promotion_id: promotion?._id,
                unique_promo_code_id: promo_id,
            });
            if (del.data.error === "0") {
                success(t("message.update.success"));
            } else {
                error(del.data.msg);
            }
        } catch (err: any) {
            error(showErrorMessage(err.response));
        }
    };
    const clickFile = () => {

        //@ts-ignore
        inputFile.current.click();
    };
    const importFile = async (e: ChangeEvent<HTMLInputElement>) => {
        await null;
        //@ts-ignore
        if (e?.target?.files[0]) {
            try {
                const file = e.target.files[0];
                const formData = new FormData();
                //@ts-ignore
                formData.append("file", file);

                let res = await promotionAPI.uploadCouponExcel(formData);


                let codeList = res.data.alienCode.map((item) => {
                    return { code: item.alienList.toString() };
                });
                let index = Number(e.target.id);
                setFieldValue(`unique_promo_code_list[${index}].each_unique_promo_code`, codeList);
                setFieldValue(`unique_promo_code_list[${index}].generate_amount`, res.data.alienCode.length);

                let temp: any = values.unique_promo_code_list;
                temp[index].each_unique_promo_code = codeList;
                temp[index].generate_amount = res.data.alienCode.length;

                storeGeneratedCodes(temp[index]);




            } catch (err: any) {
                console.error(err);
                error(showErrorMessage(err.response));
            }
        }
    };
    const exportTemplate = async () => {
        await null;
        let arr: any[] = [
            { alienList: "exampleCode1" },
            { alienList: "exampleCode2" },
            { alienList: "exampleCode3" },
            { alienList: "exampleCode4" },
            { alienList: "exampleCode5" },
        ];
        const excelHeader = [
            { label: "alienList", value: "alienList" },
        ];
        const xlsxSettings = {
            fileName: "promo_code_template",
            extraLength: 3,
            writeMode: "writeFile",
            writeOptions: {},
            RTL: false,
        };
        let eData: any[] = [
            {
                sheet: "promo_code_template",
                columns: excelHeader,
                content: arr,
            },
        ];
        // @ts-ignore
        await xlsx(eData, xlsxSettings);
    };
    const validityChannelListHandler = (value) => {
        setFieldValue("sale_channel_id_list", value);
    };
    const generateRandomCodes = () => {
        const data: any = [];

        let temp: any = values.unique_promo_code_list;
        const titleRow: any = [];
        temp.forEach((code) => {
            titleRow.push(code.name);
        });
        data.push(titleRow);

        const maxGenerateAmount = temp.reduce(
            (max, code) => Math.max(max, code.generate_amount),
            0
        );

        for (let i = 0; i < maxGenerateAmount; i++) {
            const row: any = [];
            temp.forEach((code, cIndex) => {
                const uniqueCodeIndex = Math.min(i, code.each_unique_promo_code.length - 1);
                if (i < code.generate_amount) {
                    row.push(code.each_unique_promo_code[uniqueCodeIndex].code);
                } else {
                    row.push("");
                }
            });
            data.push(row);
        }

        return data;
    };

    const exportToExcel = (data) => {
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Promo Codes");

        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
        const excelData = new Blob([excelBuffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const excelUrl = URL.createObjectURL(excelData);

        const link = document.createElement("a");
        link.href = excelUrl;
        link.download = "promo_codes.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleButtonClick = () => {
        // const randomCodes = generateRandomCodes();
        // exportToExcel(randomCodes);
        handleExport();
    };

    const handleExport = async () => {
        await null;
        let temp: any = values.unique_promo_code_list;
        let eData: any[] = [];
        const excelHeader = [
            { label: "code", value: "code" },
            { label: "used", value: "used" },
        ];
        let eSheet: any[] = temp.map((item, index) => {
            return {
                sheet: ("Code" + (index + 1)),
                columns: excelHeader,
                content: item?.each_unique_promo_code?.map((cItem) => {
                    return {
                        code: cItem.code,
                        used: cItem?.used_date ? formatDate(cItem?.used_date) : "",
                    };
                }) || [],
            };
        });

        const xlsxSettings = {
            fileName: "Bill Promotion Code",
            extraLength: 3,
            writeMode: "writeFile",
            writeOptions: {},
            RTL: false,
        };

        // @ts-ignore
        await xlsx(eSheet, xlsxSettings);
    };

    const { Option } = Select;
    const handleUploadImage = (images: string[]) => {
        setFieldValue("bill_promotion_image_url", images[0]);
    };

    const promotionTypeHandler = (e: RadioChangeEvent) => {
        setFieldValue("bill_promotion_type", e.target.value);
    };
    const promoCodeTypeHandler = (e: RadioChangeEvent) => {
        setFieldValue("is_apply_unique_code", e.target.value);
    };

    return (
        <div className="form-wrap">
            <Form>
                <StyledCustom>
                    <div className="page-header">
                        <h3>{t("page.bill_promotion")}</h3>
                    </div>
                    <div className="page-body">
                        <div className="card-wrap">
                            <StyledCard>
                                <div className="title" style={{ fontSize: "25px", color: "#000" }}>
                                    {t("page.promotion_setting")}
                                </div>
                                <Row>
                                    <Col span={24}>
                                        <SharedInput
                                            label={t("page.promotion_name")}
                                            name="bill_promotion_name"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.bill_promotion_name}
                                            errors={errors.bill_promotion_name}
                                            touched={touched.bill_promotion_name}
                                            parentClassName="mb-6"
                                        />
                                    </Col>
                                </Row>
                                <StyledCustom>
                                    <Row className="topRadioButtonRows" gutter={32}>
                                        <Col span={24}>
                                            <div>
                                                <div className="label">
                                                    {t("page.type_promotion")}*
                                                </div>
                                                <Radio.Group
                                                    className="radioGroupRow"
                                                    onChange={promotionTypeHandler}
                                                    value={values.bill_promotion_type}
                                                >
                                                    {/* <Radio value={"freebie"}>
                                                        <p>{t("page.free")}</p>
                                                        <div
                                                            className={
                                                                values.bill_promotion_type ===
                                                                    "freebie"
                                                                    ? "radioBox activeRadioBtn"
                                                                    : "radioBox"
                                                            }
                                                        >
                                                            {t("page.free")}
                                                        </div>
                                                    </Radio> */}
                                                    <Radio value={"discount_baht"}>
                                                        <p>{t("page.discount_by_baht")}</p>
                                                        <div
                                                            className={
                                                                values.bill_promotion_type ===
                                                                    "discount_baht"
                                                                    ? "radioBox withIcon activeRadioBtn"
                                                                    : "radioBox withIcon"
                                                            }
                                                        >
                                                            -99.0
                                                            <div
                                                                className={"grayCircle"}
                                                                style={{
                                                                    margin: 0,
                                                                    fontSize: 16,
                                                                    width: 25,
                                                                    height: 25,
                                                                }}
                                                            >
                                                                ฿
                                                            </div>
                                                        </div>
                                                    </Radio>
                                                    <Radio value={"discount_percent"}>
                                                        <p>{t("page.discount_by_percent")}</p>
                                                        <div
                                                            className={
                                                                values.bill_promotion_type ===
                                                                    "discount_percent"
                                                                    ? "radioBox withIcon activeRadioBtn"
                                                                    : "radioBox withIcon"
                                                            }
                                                        >
                                                            -9
                                                            <div
                                                                className={"grayCircle"}
                                                                style={{
                                                                    margin: 0,
                                                                    fontSize: 16,
                                                                    width: 25,
                                                                    height: 25,
                                                                }}
                                                            >
                                                                %
                                                            </div>
                                                        </div>
                                                    </Radio>
                                                </Radio.Group>
                                            </div>
                                        </Col>
                                    </Row>
                                    {(values.bill_promotion_type === "discount_percent" ||
                                        values.bill_promotion_type === "discount_baht") && (
                                            <Row gutter={32}>
                                                <Col span={12}>
                                                    <SharedInput
                                                        label={t("page.discount")}
                                                        name={
                                                            values.bill_promotion_type ===
                                                                "discount_percent"
                                                                ? "discount_percent"
                                                                : "discount_baht"
                                                        }
                                                        onBlur={handleBlur}
                                                        type="number"
                                                        onChange={handleChange}
                                                        value={
                                                            values.bill_promotion_type ===
                                                                "discount_percent"
                                                                ? values.discount_percent
                                                                : values.discount_baht
                                                        }
                                                        parentClassName="mb-6"
                                                        suffixIcon={
                                                            values.bill_promotion_type ===
                                                                "discount_percent"
                                                                ? "%"
                                                                : "THB"
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        )}
                                </StyledCustom>
                                <Row gutter={32}>
                                    {values.bill_promotion_type === "discount_percent" && (
                                        <Col span={12}>
                                            <SharedInput
                                                label={t("page.max_discount_value")}
                                                name="max_discount_value"
                                                onBlur={handleBlur}
                                                type="number"
                                                onChange={handleChange}
                                                value={
                                                    values.max_discount_value !== 0
                                                        ? values.max_discount_value
                                                        : ""
                                                }
                                                errors={errors.max_discount_value}
                                                touched={touched.max_discount_value}
                                                parentClassName="mb-6"
                                            />
                                        </Col>
                                    )}
                                    <Col span={12}>
                                        <SharedInput
                                            label={t("page.minimum_order_value")}
                                            name="min_order_value"
                                            onBlur={handleBlur}
                                            type="number"
                                            onChange={handleChange}
                                            value={
                                                values.min_order_value !== 0
                                                    ? values.min_order_value
                                                    : ""
                                            }
                                            errors={errors.min_order_value}
                                            touched={touched.min_order_value}
                                            parentClassName="mb-6"
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={32}>
                                    <Col span={12}>
                                        <StyledSwitch
                                            className="switch-field"
                                            style={{
                                                gap: "10px",
                                                marginBottom: 20,
                                            }}
                                        >
                                            <Switch
                                                className="ant-switch"
                                                onChange={(value) => {
                                                    setFieldValue(`is_all_sale_channel`, value);
                                                }}
                                                checked={values.is_all_sale_channel}
                                            />
                                            <div
                                                className="label"
                                                style={{
                                                    marginBottom: 0,
                                                }}
                                            >
                                                {t("page.all_sale_channel")}
                                            </div>
                                        </StyledSwitch>
                                    </Col>
                                </Row>
                                {!values.is_all_sale_channel && (
                                    <Row gutter={32}>
                                        <Col span={12}>
                                            <div className="label">{t("page.channel")}</div>
                                            <Button
                                                style={{
                                                    height: "42px",
                                                    border: "0.5px solid #fff",
                                                    borderRadius: "4px",
                                                    background: theme?.colors?.main,
                                                    color: theme?.colors?.white,
                                                    marginRight: "10px",
                                                }}
                                                onClick={() => setChannelModal(true)}
                                            >
                                                <BranchIcon /> &nbsp;
                                                {t("page.select_channel")}
                                            </Button>
                                        </Col>
                                    </Row>
                                )}
                                {/* {!values.is_all_sale_channel && (
                                    <Row gutter={32}>
                                        <Col span={12}>
                                            <div className="label">{t("page.channel")}</div>
                                            <Select
                                                showSearch
                                                filterOption={(input, option) =>
                                                    //@ts-ignore
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                style={{ width: "100%", height: 48 }}
                                                placeholder={t("page.channel")}
                                                onChange={(value: string) => {
                                                    setFieldValue("sale_channel_id", value);
                                                }}
                                                value={
                                                    values.sale_channel_id
                                                        ? values.sale_channel_id
                                                        : ""
                                                }
                                            >
                                                <Option
                                                    key={"disabled"}
                                                    value={"disabled"}
                                                    disabled
                                                >
                                                    {t("page.select_channel")}
                                                </Option>
                                                <Option key={""} value={""}>
                                                    {t("page.dine_in")}
                                                </Option>
                                                {channels &&
                                                    channels.map((item, index) => {
                                                        return (
                                                            <Option key={index} value={item._id}>
                                                                {item.sale_channel_name}
                                                            </Option>
                                                        );
                                                    })}
                                            </Select>
                                        </Col>
                                    </Row>
                                )} */}
                                <Row gutter={32}>
                                    <Col span={12}>
                                        <div className="label">{t("page.category")}</div>
                                        <Select
                                            showSearch
                                            filterOption={(input, option) =>
                                                //@ts-ignore
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            style={{ width: "100%", height: 48 }}
                                            placeholder={t("page.category")}
                                            onChange={(value: string) => {
                                                setFieldValue("bill_promotion_category_id", value);
                                            }}
                                            value={
                                                values.bill_promotion_category_id || ""
                                            }
                                        >
                                            <Option
                                                key={"disabled"}
                                                value={"disabled"}
                                                disabled
                                            >
                                                {t("page.select_category")}
                                            </Option>
                                            {categoryList &&
                                                categoryList.map((item, index) => {
                                                    return (
                                                        <Option key={index} value={item._id}>
                                                            {item.category}
                                                        </Option>
                                                    );
                                                })}
                                        </Select>
                                    </Col>
                                </Row>

                                <Row
                                    className="topRadioButtonRows"
                                    gutter={32}
                                    style={{ marginTop: 10, marginBottom: 0 }}
                                >
                                    <Col span={24} style={{ marginBottom: "20px" }}>
                                        <div className="label">{t("page.promotion_set_image")}</div>
                                        <ModuleUploadImage
                                            images={
                                                values.bill_promotion_image_url
                                                    ? [values.bill_promotion_image_url]
                                                    : []
                                            }
                                            handleGetImage={handleUploadImage}
                                        />
                                    </Col>
                                </Row>
                                <Row className="topRadioButtonRows" gutter={32}>
                                    <Col span={24}>
                                        <div>
                                            <div className="label">{t("page.promotion_type")}</div>
                                            <Radio.Group
                                                onChange={promoCodeTypeHandler}
                                                value={values.is_apply_unique_code}
                                            >
                                                <Radio value={true}>{t("page.unique_code")}</Radio>
                                                <Radio value={false}>
                                                    {t("page.manual_select")}
                                                </Radio>
                                            </Radio.Group>
                                        </div>
                                    </Col>
                                </Row>
                            </StyledCard>
                        </div>
                        <div className="card-wrap">
                            <StyledCard>
                                <div className="title" style={{ fontSize: "25px", color: "#000" }}>
                                    {t("page.promotion_period")}
                                </div>
                                <Row
                                    gutter={32}
                                    style={{
                                        marginTop: 40,
                                        marginBottom: 20,
                                    }}
                                >
                                    <Col md={12}>
                                        <StyledSwitch
                                            className="switch-field"
                                            style={{
                                                gap: "10px",
                                                marginBottom: 0,
                                            }}
                                        >
                                            <Switch
                                                className="ant-switch"
                                                onChange={(value) => {
                                                    setFieldValue(`is_validity_period`, value);
                                                }}
                                                checked={values.is_validity_period}
                                            />
                                            <div
                                                className="label"
                                                style={{
                                                    marginBottom: 0,
                                                }}
                                            >
                                                {t("page.validity_period")}
                                            </div>
                                        </StyledSwitch>
                                    </Col>
                                </Row>
                                <Row
                                    gutter={32}
                                    style={{
                                        marginBottom: 20,
                                    }}
                                >
                                    <Col md={16}>
                                        <div className="selectDateRow">
                                            <div
                                                className="label"
                                                style={{
                                                    marginBottom: 0,
                                                }}
                                            >
                                                {t("page.from")}
                                            </div>
                                            <DatePicker
                                                size={"large"}
                                                format={dateFormatYear}
                                                defaultValue={moment()}
                                                value={moment(values.validity_date_from_string)}
                                                onChange={(value, dateString) => {
                                                    setFieldValue(
                                                        `validity_date_from_string`,
                                                        dateString
                                                    );
                                                }}
                                            />
                                            <TimePicker
                                                defaultValue={moment("12:08", format)}
                                                value={moment(
                                                    values.validity_time_from_string,
                                                    format
                                                )}
                                                format={format}
                                                size="large"
                                                onChange={(value) => {
                                                    setFieldValue(
                                                        `validity_time_from_string`,
                                                        moment(value).format(format)
                                                    );
                                                }}
                                            />
                                        </div>
                                        <div className="selectDateRow">
                                            <div
                                                className="label"
                                                style={{
                                                    marginBottom: 0,
                                                }}
                                            >
                                                {t("page.till")}
                                            </div>
                                            <DatePicker
                                                size={"large"}
                                                format={dateFormatYear}
                                                defaultValue={moment()}
                                                value={moment(values.validity_date_to_string)}
                                                onChange={(value, dateString) => {
                                                    setFieldValue(
                                                        `validity_date_to_string`,
                                                        dateString
                                                    );
                                                }}
                                            />
                                            <TimePicker
                                                defaultValue={moment("12:09", format)}
                                                value={moment(
                                                    values.validity_time_to_string,
                                                    format
                                                )}
                                                format={format}
                                                size="large"
                                                onChange={(value) => {
                                                    setFieldValue(
                                                        `validity_time_to_string`,
                                                        moment(value).format(format)
                                                    );
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </StyledCard>
                        </div>
                        {values.is_apply_unique_code && (
                            <div className="card-wrap">
                                <StyledCard>
                                    {values.unique_promo_code_list?.map((promo_item, i) => (
                                        <Row gutter={32} style={{ marginBottom: 20 }} key={i}>
                                            <Col md={24}>
                                                <div className="promoSetBoxRow">
                                                    <div className="promoSetBox">
                                                        <div className="titleRow">
                                                            <div
                                                                className="title"
                                                                style={{
                                                                    fontSize: "25px",
                                                                    color: "#000",
                                                                    marginBottom: 0,
                                                                }}
                                                            >
                                                                {t("page.promo_code_set")} {i + 1}
                                                            </div>
                                                        </div>
                                                        <Divider />
                                                        <Row gutter={32}>
                                                            <Col md={8}>
                                                                <SharedInput
                                                                    label={t("page.name")}
                                                                    descLabel={t(
                                                                        "page.two_hundard_characters"
                                                                    )}
                                                                    name={promo_item.name}
                                                                    disable={
                                                                        promo_item.each_unique_promo_code &&
                                                                            promo_item
                                                                                .each_unique_promo_code
                                                                                .length !== 0
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    onBlur={handleBlur}
                                                                    onChange={(value) => {
                                                                        setFieldValue(
                                                                            `unique_promo_code_list[${i}].name`,
                                                                            value.target.value
                                                                        );
                                                                    }}
                                                                    value={promo_item.name}
                                                                    errors={promo_item.name}
                                                                    parentClassName="mb-6"
                                                                />
                                                            </Col>
                                                            <Col md={8}>
                                                                <SharedInput
                                                                    label={t(
                                                                        "page.generate_amount"
                                                                    )}
                                                                    name={promo_item.generate_amount.toString()}
                                                                    disable={
                                                                        promo_item.each_unique_promo_code &&
                                                                            promo_item
                                                                                .each_unique_promo_code
                                                                                .length !== 0
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    onBlur={handleBlur}
                                                                    onChange={(value) => {
                                                                        setFieldValue(
                                                                            `unique_promo_code_list[${i}].generate_amount`,
                                                                            value.target.value
                                                                        );
                                                                    }}
                                                                    value={
                                                                        promo_item.generate_amount
                                                                    }
                                                                    parentClassName="mb-6"
                                                                />
                                                            </Col>
                                                            <Col md={8}>
                                                                <div className="label">
                                                                    {t("page.source")}
                                                                </div>
                                                                <Select
                                                                    showSearch
                                                                    filterOption={(input, option) =>
                                                                        //@ts-ignore
                                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                    }
                                                                    style={{
                                                                        width: "100%",
                                                                        height: 48,
                                                                    }}
                                                                    disabled={true}
                                                                    defaultValue={""}
                                                                    placeholder={t("page.internal")}
                                                                    value={t("page.internal")}
                                                                >
                                                                    <Option
                                                                        key={"add-new"}
                                                                        value={""}
                                                                    >
                                                                        {t("page.internal")}
                                                                    </Option>
                                                                </Select>
                                                            </Col>
                                                        </Row>
                                                        <Row gutter={32}>
                                                            <Col md={12}>
                                                                <div className="btn-layout">
                                                                    <Button
                                                                        className="antd-btn-custom generateBtn"
                                                                        style={{
                                                                            background:
                                                                                promo_item.each_unique_promo_code &&
                                                                                    promo_item
                                                                                        .each_unique_promo_code
                                                                                        .length !== 0
                                                                                    ? "#d3d3d3"
                                                                                    : "#f4394f",
                                                                        }}
                                                                        disabled={
                                                                            promo_item.each_unique_promo_code &&
                                                                                promo_item
                                                                                    .each_unique_promo_code
                                                                                    .length !== 0
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        onClick={() => {
                                                                            generateRandomCodesSet(i);
                                                                        }}
                                                                    >
                                                                        {t("page.generate")}
                                                                    </Button>
                                                                    {
                                                                        promo_item.each_unique_promo_code &&
                                                                        promo_item
                                                                            .each_unique_promo_code
                                                                            .length === 0
                                                                        &&
                                                                        <Button
                                                                            className="antd-btn-custom generateBtn"
                                                                            style={{
                                                                                background:
                                                                                    promo_item.each_unique_promo_code &&
                                                                                        promo_item
                                                                                            .each_unique_promo_code
                                                                                            .length !==
                                                                                        0
                                                                                        ? "#d3d3d3"
                                                                                        : "#f4394f",
                                                                            }}
                                                                            disabled={
                                                                                promo_item.each_unique_promo_code &&
                                                                                    promo_item
                                                                                        .each_unique_promo_code
                                                                                        .length !==
                                                                                    0
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            onClick={() => {
                                                                                if (promo_item.name === "") {
                                                                                    warning(t("page.please_add_promo_code_set_name"));
                                                                                } else {
                                                                                    clickFile();
                                                                                }
                                                                            }}
                                                                        >
                                                                            {t(
                                                                                "page.import_file_excel"
                                                                            )}
                                                                        </Button>
                                                                    }
                                                                    <input
                                                                        ref={inputFile}
                                                                        style={{ display: "none" }}
                                                                        onChange={importFile}
                                                                        id={`${i}`}
                                                                        className="input-import"
                                                                        type="file"
                                                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xlsx, .xls, .csv"
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        {
                                                            promo_item.each_unique_promo_code &&
                                                            promo_item
                                                                .each_unique_promo_code
                                                                .length === 0
                                                            &&
                                                            <p
                                                                className="load-template"
                                                                onClick={exportTemplate}
                                                            >
                                                                {t("page.download_template")}
                                                            </p>
                                                        }

                                                    </div>
                                                    <div className="actions">
                                                        <button
                                                            className="deleteIcon"
                                                            onClick={() => {
                                                                setFieldValue(
                                                                    "unique_promo_code_list",
                                                                    values.unique_promo_code_list?.filter(
                                                                        (x, n) => n !== i
                                                                    )
                                                                );
                                                                deleteGeneratedCodes(
                                                                    promo_item._id
                                                                );
                                                            }}
                                                        >
                                                            <DeleteIcon
                                                                size={20}
                                                                color={"#6C7084"}
                                                            />
                                                        </button>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    ))}
                                    <Row gutter={32} style={{ marginBottom: 20 }}>
                                        <Col md={24}>
                                            <Button
                                                className="antd-btn-custom"
                                                style={{ marginLeft: 0 }}
                                                onClick={handleAddPromoSet}
                                            >
                                                + {t("page.add_promo_codes")}
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row gutter={32}>
                                        <Col md={24}>
                                            <div className="generateExcelRow">
                                                <Button
                                                    className="antd-btn-custom"
                                                    style={{ marginLeft: 0 }}
                                                    onClick={() => {
                                                        handleButtonClick();
                                                    }}
                                                >
                                                    <ExportIcon /> {t("page.export_excel")}
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </StyledCard>
                            </div>
                        )}
                    </div>
                    <div className="btn-layout">
                        <StyledSubmitButton
                            type="default"
                            text={t("page.save")}
                            htmlType="button"
                            onClick={() => {
                                handleAssignSubmit();
                            }}
                        />
                        <StyledCancelButton
                            type="sub"
                            text={t("page.cancel")}
                            htmlType="button"
                            onClick={() => {
                                handleCancel();
                            }}
                        />
                    </div>
                </StyledCustom>
            </Form>
            <ComponentPromotionChannelIncludeModal
                showPopup={channelModal}
                handleClose={() => {
                    setChannelModal(false);
                }}
                allChannel={promotionChannels}
                selectChannel={values.sale_channel_id_list}
                setSelectChannel={validityChannelListHandler}
            />
        </div>
    );
});
const StyledBarcode = styled.div`
    .scanBtn {
        display: flex;
        place-content: center;
        gap: 5px;
        align-items: center;
        height: 48px;
        background: #0263e0;
        width: 100%;
        p {
            margin: 0;
        }
    }
    .printBtn {
        display: flex;
        place-content: center;
        border: 0.5px solid #646464;
        gap: 5px;
        align-items: center;
        height: 48px;
        background: #fff;
        width: 100%;
        p {
            margin: 0;
            color: #000;
        }
    }
    .barcodeRow {
        gap: 10px;
    }
`;
const StyledSwitch = styled.div`
    display: flex;
    align-items: center;
    p {
        margin-bottom: 0;
    }
    .weekDaysRow {
        display: flex;
        margin-top: 20px;
    }
    .ant-checkbox-wrapper + span,
    .ant-checkbox + span {
        display: flex;
        place-items: center;
        font-size: 16px;
        font-weight: 700;
        color: #000;
    }
    .ant-checkbox-wrapper {
        display: flex;
        place-items: center;
    }
    .ant-time-picker {
        margin-left: 8px;
        margin-right: 8px;
        width: 128px !important;
    }
    .daysOfTheWeek {
        width: 100px;
    }
`;
const StyledCustom = styled.div`
    .secondTitle {
        font-size: 20px;
    }
    .row {
        display: flex;
        align-items: center;
    }
    .step {
        display: flex;
        align-items: center;
        p {
            margin: 0;
            color: #0263e0;
            font-weight: 700;
            font-size: 16px;
        }
        .whiteText {
            color: #a5a5a5;
        }
    }
    .blueCircle {
        background: #0263e0;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: flex;
        place-content: center;
        align-items: center;
        color: #fff;
        font-size: 12px;
        font-weight: 700;
        margin-right: 10px;
    }
    .grayCircle {
        background: #a5a5a5;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: flex;
        place-content: center;
        align-items: center;
        color: #fff;
        font-size: 12px;
        font-weight: 700;
        margin-right: 10px;
    }
    .arrowRight {
        margin: auto 20px;
    }
    .ant-table-thead > tr > th {
        text-align: left !important;
    }
    .catCols {
        background: #f7f7f8;
        border-radius: 6px;
        display: flex;
        position: relative;
        padding: 20px;
        color: #646464;
        cursor: pointer;
        margin-bottom: 10px;
    }
    .catCols.active {
        background: #e1e1e1;
    }
    .colArrow {
        position: absolute;
        right: 20px;
        display: flex;
        gap: 10px;
        place-items: center;
        font-size: 12px;
    }
    .tableTitles {
        display: flex;
        justify-content: space-between;
        place-items: center;
        width: 100%;
        .results {
            color: #646464;
            font-size: 12px;
            font-weight: 300;
            margin: 0;
        }
    }
    .titleAndIcon {
        font-size: 16px;
        font-weight: 700;
        display: flex;
        gap: 10px;
        place-items: center;
    }
    .ant-table-thead > tr > th .ant-table-header-column {
        width: 100%;
    }
    .wholeTable {
        border: 1px solid #e1e1e1;
        position: relative;
        width: 100%;
        min-height: 30vh;
        border-radius: 6px;
    }
    .topTitleRow {
        background: #f7f7f8;
        display: flex;
        padding: 20px;
        border-top-left-radius: 6px;
        border-bottom: 1px solid #e1e1e1;
        justify-content: space-between;
        border-right: 1px solid #e1e1e1;
    }
    .topTitleRow.leftSide {
        width: 35%;
    }
    .topTitleRow.rightSide {
        width: 65%;
        right: 0;
        border-right: none;
        border-top-left-radius: 0;
        border-top-right-radius: 6px;
    }
    .wholeTopRow {
        display: flex;
    }
    .leftContent {
        padding: 10px;
        width: 35%;
        border-right: 1px solid #e1e1e1;
        overflow: scroll;
    }
    .rightContent {
        padding: 10px;
        width: 65%;
        height: 100%;
        overflow: scroll;
    }
    .wholeContent {
        display: flex;
        height: 50vh;
    }
    .topLongGrayBox {
        background: #f7f7f8;
        border-radius: 6px;
        padding: 20px;
        margin-bottom: 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
            margin: 0;
            color: #000;
            font-weight: 700;
        }
    }
    .customTransparentStockBtn {
        height: 42px;
        border: 0.5px solid #646464;
        border-radius: 4px;
        background: #fff;
        color: #646464;
        padding: 0 25px;
        display: flex;
        gap: 2px;
        align-items: center;
    }
    .customTransparentStockBtn:hover {
        border: 0.5px solid #646464 !important;
    }
    .aboveOfTableRow {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;
        .title {
            margin-bottom: 0 !important;
        }
    }
    .ant-input-group-addon {
        width: 70px;
    }
    .topRadioButtonRows {
        margin-bottom: 20px;
    }
    .radioBox {
        padding: 20px;
        background: #f5f5f5;
        border: 1px solid #e1e1e1;
        border-radius: 5px;
        color: #c8c8c8;
        position: absolute;
        left: 0;
        top: 30px;
        width: 100%;
        text-align: center;
        font-weight: 700;
        font-size: 32px;
        line-height: 44px;
    }
    .radioGroupRow {
        width: 100%;
        display: flex;
        place-content: space-between;
        position: relative;
        margin-bottom: 80px;
        .ant-radio-wrapper {
            width: 100%;
            display: flex;
            place-items: center;
            margin-bottom: 20px;
            p {
                margin: 0;
                font-weight: 700;
                font-size: 12px;
                line-height: 16px;
            }
        }
    }
    .activeRadioBtn {
        background: linear-gradient(0deg, rgba(2, 99, 224, 0.08), rgba(2, 99, 224, 0.08)), #ffffff;
        border-radius: 5px;
        border: 1px solid rgba(2, 99, 224, 0.36);
        color: #66a9ff;
    }
    .withIcon {
        display: flex;
        place-content: center;
        place-items: center;
        gap: 10px;
    }
    .label .sub-title {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #646464;
        margin: 0;
    }
    .increaseDecreaseSection {
        background: #f4f9ff;
        border-radius: 8px;
        width: 100%;
        height: 100px;
        margin-top: 20px;
        display: flex;
        place-content: center;
        place-items: center;
        gap: 30px;
        font-weight: 700;
        font-size: 25px;
        line-height: 34px;
        color: #000;
        .decreaseIcon {
            background: #e1e1e1;
            border-radius: 50%;
            display: flex;
            place-content: center;
            place-items: center;
            color: #fff;
            font-size: 40px;
            height: 35px;
            width: 35px;
            padding-bottom: 4px;
            cursor: pointer;
        }
        .increaseIcon {
            background: #0263e0;
            border-radius: 50%;
            display: flex;
            place-content: center;
            place-items: center;
            color: #fff;
            font-size: 30px;
            height: 35px;
            width: 35px;
            padding-bottom: 4px;
            cursor: pointer;
        }
    }
    .productName {
        img {
            height: auto;
            width: 50px;
            margin-right: 10px;
        }
    }
    .businessHourTypeRow {
        margin: 10px auto;
    }
    .customInputPromo {
        border: none;
        width: 100%;
        background: transparent;
        color: #66a9ff;
        cursor: pointer;
    }
    .customInputPromo::placeholder {
        color: #c8c8c8;
    }
    .customInputPromo::-webkit-outer-spin-button,
    .customInputPromo::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    .ant-input-affix-wrapper .ant-input:not(:last-child) {
        padding-right: 45px;
    }
    .promoSetBox {
        border-radius: 8px;
        background: #f7f7f8;
        padding: 24px;
        width: 100%;
    }
    .load-template {
        text-decoration: underline;
        cursor: pointer;
        margin-top: 10px;
    }
    .titleRow {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .promoSetBoxRow {
        display: flex;
        align-items: center;
        gap: 15px;
    }
    .deleteIcon {
        cursor: pointer;
        border: none;
        background: transparent;
    }
    .selectDateRow {
        display: flex;
        gap: 20px;
        align-items: center;
        margin-bottom: 20px;
        .label {
            width: 100px;
        }
        .ant-time-picker-large .ant-time-picker-input {
            height: 48px;
        }
    }
    .ant-calendar-picker-input {
        height: 48px;
    }
    .generateExcelRow {
        display: flex;
        align-items: center;
    }
    .generateBtn {
        margin-left: 0 !important;
        span {
            color: #fff !important;
        }
    }
    .topRadioButtonRows {
        margin-bottom: 20px;
    }
`;
