export const EyeIcon = () => {
    return (
        <svg
            width="21"
            height="17"
            viewBox="0 0 21 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.3329 6.14062C9.00287 6.14062 7.92188 7.22262 7.92188 8.55262C7.92188 9.88163 9.00287 10.9626 10.3329 10.9626C11.6629 10.9626 12.7449 9.88163 12.7449 8.55262C12.7449 7.22262 11.6629 6.14062 10.3329 6.14062ZM10.3329 12.4626C8.17587 12.4626 6.42188 10.7086 6.42188 8.55262C6.42188 6.39562 8.17587 4.64062 10.3329 4.64062C12.4899 4.64062 14.2449 6.39562 14.2449 8.55262C14.2449 10.7086 12.4899 12.4626 10.3329 12.4626Z"
                fill="black"
            />
            <mask
                id="mask0_7746_323812"
                // style={"mask-type:luminance"}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="21"
                height="17"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.333496 0.5H20.3333V16.605H0.333496V0.5Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_7746_323812)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.90325 8.55275C3.76325 12.6617 6.89625 15.1047 10.3332 15.1057C13.7702 15.1047 16.9032 12.6617 18.7632 8.55275C16.9032 4.44475 13.7702 2.00175 10.3332 2.00075C6.89725 2.00175 3.76325 4.44475 1.90325 8.55275ZM10.3352 16.6057H10.3312H10.3302C6.19425 16.6027 2.48025 13.7038 0.394246 8.84875C0.313246 8.65975 0.313246 8.44575 0.394246 8.25675C2.48025 3.40275 6.19525 0.50375 10.3302 0.50075C10.3322 0.49975 10.3322 0.49975 10.3332 0.50075C10.3352 0.49975 10.3352 0.49975 10.3362 0.50075C14.4722 0.50375 18.1862 3.40275 20.2722 8.25675C20.3542 8.44575 20.3542 8.65975 20.2722 8.84875C18.1872 13.7038 14.4722 16.6027 10.3362 16.6057H10.3352Z"
                    fill="black"
                />
            </g>
        </svg>
    );
};
