import { wholesaleAPI } from "@api";
import { selectAuth } from "@redux";
import { Row, Col, Input, Select } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";
const { Search } = Input;
const { Option } = Select;

interface Iprops {
    setTextSearch: (value: string) => void;
    total: number;
    channelList?: any[];
    setFocusChannel?: (value: string) => void;
    storeList?: any[];
    setFocusStore?: (value: string) => void;
}
export const ComponentPromotionTableSearch = (props: Iprops) => {
    const { setTextSearch, total, channelList, setFocusChannel, storeList, setFocusStore } = props;
    const { t } = useTranslation();

    const handleChangeChannel = (value) => {
        //
    };

    return (
        <StyledSearchLayout>
            <div className="filter">
                <Row gutter={24} type="flex" justify="start" align="middle" className="search-row">
                    <Col className="col-item" span={18}>
                        <div className="filter-section">
                            <div className="filter-element">
                                <p>{t("page.search")}</p>
                                <Search
                                    placeholder={t("page.search_promotion")}
                                    onSearch={(value) => setTextSearch(value)}
                                    style={{ width: 200 }}
                                    onChange={(event) => {
                                        setTextSearch(event.target.value);
                                    }}
                                />
                            </div>
                            {(channelList && setFocusChannel) &&
                                <div className="filter-element">
                                    <p>{t("page.category")}:</p>
                                    <Select
                                        showSearch
                                        filterOption={(input, option) =>
                                            //@ts-ignore
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        onChange={setFocusChannel}
                                        placeholder={t("page.please_choose")}
                                    >
                                        {channelList.map((item, index) => (
                                            <Option value={item?._id} key={index}>
                                                {item?.sale_channel_name}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                            }
                            {(storeList && setFocusStore) &&
                                <div className="filter-element">
                                    <p>{t("page.store")}:</p>
                                    <Select
                                        showSearch
                                        filterOption={(input, option) =>
                                            //@ts-ignore
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        onChange={setFocusStore}
                                        placeholder={t("page.please_choose")}
                                    >
                                        <Option value="">
                                            All Store
                                        </Option>
                                        {storeList.map((item, index) => (
                                            <Option value={item?._id} key={index}>
                                                {item?.store_name}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                            }

                        </div>
                    </Col>
                    <Col className="col-item" span={6}>
                        <div className="filter-element result">
                            <p>
                                <span>{t("page.result")}</span>
                                <span>{":"}</span>
                                <span>{total}</span>
                            </p>
                        </div>
                    </Col>
                </Row>
            </div>
        </StyledSearchLayout>
    );
};
const StyledSearchLayout = styled.div`
    .filter {
        background: #fff;
        padding: 20px;
        border-radius: 4px;
        margin-bottom: 26px;
        .filter-section {
            display: flex;
            grid-gap: 25px;
        }
        .filter-element {
            display: flex;
            align-items: center;
            column-gap: 8px;
            p {
                margin: 0;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #646464;
            }
            .ant-input-search {
                height: 44px;
                width: 100%;
            }
            .ant-select {
                min-width: 160px;
                width: 100%;
                .ant-select-selection--single {
                    height: 44px;
                    .ant-select-selection__rendered {
                        height: 100%;
                        align-items: center;
                        display: flex;
                    }
                }
            }
            .ant-calendar-picker-input {
                height: 44px;
            }
            &.result {
                display: flex;
                justify-content: end;
                p {
                    display: flex;
                    column-gap: 10px;
                }
            }
        }
    }
`;
