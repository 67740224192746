import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Switch, Col, Row, Avatar, Menu, Dropdown, Button, Spin, Icon } from "antd";
import { MoreHorizontal } from "react-feather";

import { IDataexample, INewsCategory, IPatchStock } from "@interfaces";
import {
    enumNewsType,
    PATH_PRODUCT_CREATE,
    PATH_SERVICES_CREATE,
    PATH_STOCK_LIST,
    PATH_STORE,
    PATH_STORE_CREATE,
} from "@configs";
import { selectAuth, setLoading, store } from "@redux";
import { useDispatch, useSelector } from "react-redux";
import { IconDrag, StyledTableButtonTop, SharedInput } from "@components";
import { showErrorMessage, useNotify } from "@utils";
import React, { useEffect, useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import styled from "styled-components";
import { consumableAPI, storeAPI } from "@api";
import { useHistory } from "react-router";
import { IUpdateStore } from "src/Interfaces/store";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";

interface IProps {
    data: any;
    itemJson: string;
    handleEdit?: (status: boolean, data: IDataexample) => void;
    callbackGetList: () => void;
    index: number;
    newType?: enumNewsType;
    categories: INewsCategory[];
    stores: any[];
    selectedStore?: string;
    Refresh?: boolean;
    setRefresh?: (change: boolean) => void;
}

export const ComponentRowCheckStockInvPro = (props: IProps) => {
    const history = useHistory();

    //hooks
    const { t } = useTranslation();
    const { success, error, warning } = useNotify();
    const dispatch = useDispatch();
    const organization_id = useSelector(selectAuth).userInfo?.merchant?._id;
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const userPermissionType = useSelector(selectAuth).userInfo?.storeIds;
    //props
    const {
        data,
        handleEdit,
        callbackGetList,
        itemJson,
        newType,
        stores,
        index,
        selectedStore,
        Refresh,
        setRefresh,
    } = props;
    const [showInput, setShowInput] = useState<boolean>(false);
    const [newBalance, setNewBalance] = useState<string>("");
    const [balanceList, setBalanceList] = useState<string[]>([]);
    const [oldBalanceList, setOldBalanceList] = useState<string[]>([]);
    const [q2deci, setQ2deci] = useState<string>("-");
    const [isSpin, setIsSpin] = useState<boolean[]>([]);
    const [editArr, setEditArr] = useState<number[]>([]);
    const [isShow, setIsShow] = useState<boolean>(true);
    // const history = useHistory();

    useEffect(() => {
        if (data) {
            let newBArr: string[] = [];
            let oldBArr: string[] = [];
            let spinArr: boolean[] = [];
            for (let i = 0; i < data?.variant_list?.length; i++) {
                newBArr.push("0");
                oldBArr.push(data?.variant_list[i]?.auto_consumable_qty);
                spinArr.push(false);
            }
            setBalanceList(newBArr);
            setOldBalanceList(oldBArr);
            setIsSpin(spinArr);
            // for (let i = 0; i < getAllStocks.data.result.length; i++) {
            //     getAllStocks.data.result[i].editStep = false;
            // }
        }
    }, [data]);

    const { attributes, listeners, setNodeRef, transform } = useSortable({
        id: itemJson,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
    };
    const handleDelete = async (item: any) => {
        try {
            const res = await consumableAPI.deleteConsumable({
                organization_id: organization_id,
                consumable_id: item,
            });
            if (res.data.error === "0") {
                callbackGetList();
                success(t("message.delete.success"));
            } else {
                error(res.data.msg);
            }
        } catch (err: any) {
            if (err) {
                error(err.msg);
            } else error(t("message.delete.fail"));
        }
    };

    const changeQty = (e: any, vIndex: number) => {
        if (!isNaN(+e.target.value)) {
            let arr = [...balanceList];
            arr[vIndex] = e.target.value;
            setBalanceList([...arr]);
        }
    };

    const onClickEdit = (index) => {
        let arr = [...editArr];
        if (!arr.includes(index)) {
            arr.push(index);
        }
        setEditArr([...arr]);
    };
    const handleSpin = (status: boolean, vIndex: number) => {
        let arr = [...isSpin];
        arr[vIndex] = status;
        setIsSpin([...arr]);
    };
    const onSaveItem = async (vIndex) => {
        if (balanceList[vIndex] !== "") {
            let outQty = parseFloat(parseFloat(balanceList[vIndex]).toFixed(2));
            const payload: IPatchStock = {
                organization_id: organization_id || "",
                consumable_id: data?.variant_list[vIndex].consumable_id,
                store_id: selectedStore || "",
                new_qty: outQty,
            };
            try {
                // setIsSpin(true);
                handleSpin(true, vIndex);

                const res = await consumableAPI.updateStock(payload);
                if (res.data.error === "0") {
                    success(res.data.msg);
                    let arr = [...oldBalanceList];
                    arr[vIndex] = outQty.toString();
                    setOldBalanceList([...arr]);
                } else {
                    error(res.data.msg);
                }
            } catch (err: any) {
                if (err.response) {
                    warning(showErrorMessage(err.response));
                    dispatch(setLoading(false));
                } else warning(t("message.not_found", { name: t("object._reward") }));
            } finally {
                // setIsSpin(false);
                handleSpin(false, vIndex);
            }
        }
    };

    return (
        <StylesCheckStock>
            <div className="table-element" ref={setNodeRef} style={style}>
                <div className="drag-icon hide" {...attributes} {...listeners}>
                    <IconDrag />
                </div>
                <div
                    className={
                        "table-row " +
                        (data?.variant_list?.length === 1 ? " one-row " : "") +
                        (!isShow && " one-row ")
                    }
                >
                    <Row
                        style={{ height: "100%" }}
                        gutter={24}
                        type="flex"
                        align="middle"
                        justify="space-between"
                    >
                        <Col className="col-item" span={6}>
                            <p className="news-title">
                                {data?.variant_list?.length > 1 ? (
                                    <>
                                        {isShow ? (
                                            <CaretUpOutlined onClick={() => setIsShow(false)} />
                                        ) : (
                                            <CaretDownOutlined onClick={() => setIsShow(true)} />
                                        )}
                                    </>
                                ) : (
                                    <span className="no-arrow"> </span>
                                )}
                            </p>

                            <p className="news-title">{data?.name}</p>
                        </Col>
                        <Col className="col-item" span={4}>
                            <p className="news-title">
                                {data?.variant_list.length === 1 &&
                                    data?.variant_list[0]?.variant_name}
                            </p>
                        </Col>
                        <Col className="col-item" span={4}>
                            <p className="news-title">{data?.category_name}</p>
                        </Col>
                        {selectedStore !== undefined ? (
                            <>
                                <Col
                                    className="col-item"
                                    span={3}
                                    style={{ placeContent: "center" }}
                                >
                                    <p>
                                        {/* {data?.variant_list?.length === 1 &&
                                            data?.variant_list[0]?.auto_consumable_qty} */}
                                        {data?.variant_list?.length === 1 && oldBalanceList[0]}
                                    </p>
                                </Col>
                                {editArr.includes(0) ? (
                                    <Col
                                        className="col-item"
                                        span={4}
                                        style={{ placeContent: "center" }}
                                    >
                                        {data?.variant_list?.length === 1 && (
                                            <SharedInput
                                                parentClassName="mb-6"
                                                onChange={(e) => changeQty(e, 0)}
                                                value={balanceList[0]}
                                                style={{ textAlign: "center" }}
                                                notErr
                                            />
                                        )}
                                    </Col>
                                ) : (
                                    <Col
                                        className="col-item"
                                        span={4}
                                        style={{ placeContent: "center" }}
                                    >
                                        <p>{t("page.empty_text")}</p>
                                    </Col>
                                )}
                                <Col
                                    className="col-item"
                                    span={3}
                                    style={{ placeContent: "center" }}
                                >
                                    {data?.variant_list?.length === 1 && (
                                        <StylesSaveBtn>
                                            <Button
                                                className="save-btn"
                                                onClick={() => {
                                                    if (editArr.includes(0)) {
                                                        // click save
                                                        // handleSave();
                                                        onSaveItem(0);
                                                    } else {
                                                        // on click Edit
                                                        onClickEdit(0);
                                                        // setShowInput(!showInput);
                                                        // stores[index].editStep = true;
                                                        // setNewBalance("");
                                                    }
                                                }}
                                            >
                                                {isSpin[0] ? (
                                                    <Icon
                                                        type="loading"
                                                        className="load-icon"
                                                        style={{ fontSize: 18, color: "white" }}
                                                    />
                                                ) : editArr.includes(0) ? (
                                                    t("page.save")
                                                ) : (
                                                    t("page.edit")
                                                )}
                                            </Button>
                                        </StylesSaveBtn>
                                    )}
                                </Col>
                            </>
                        ) : (
                            <Col
                                className="col-item"
                                span={10}
                                style={{ placeContent: "center" }}
                            ></Col>
                        )}
                    </Row>
                </div>
            </div>
            {data?.variant_list?.length > 1 && isShow && (
                <div className="sub-table-element" ref={setNodeRef} style={style}>
                    {data?.variant_list?.map((vItem: any, vIndex: number) => {
                        return (
                            <div className="table-row sub-table-row">
                                <Row
                                    style={{ height: "100%" }}
                                    gutter={24}
                                    type="flex"
                                    align="middle"
                                    justify="space-between"
                                >
                                    <Col className="col-item" span={6}>
                                        <p className="news-title sub-title">
                                            {vItem?.variant_name}
                                        </p>
                                    </Col>
                                    <Col className="col-item" span={4}>
                                        <p className="news-title">{vItem?.variant_name}</p>
                                    </Col>
                                    <Col className="col-item" span={4}>
                                        <p className="news-title">{data?.category_name}</p>
                                    </Col>
                                    {selectedStore !== undefined ? (
                                        <>
                                            <Col
                                                className="col-item"
                                                span={3}
                                                style={{ placeContent: "center" }}
                                            >
                                                <p>{oldBalanceList[vIndex]}</p>
                                            </Col>
                                            {editArr.includes(vIndex) ? (
                                                <Col
                                                    className="col-item"
                                                    span={4}
                                                    style={{ placeContent: "center" }}
                                                >
                                                    <SharedInput
                                                        parentClassName="mb-6"
                                                        onChange={(e) => changeQty(e, vIndex)}
                                                        value={balanceList[vIndex]}
                                                        style={{ textAlign: "center" }}
                                                        notErr
                                                    />
                                                </Col>
                                            ) : (
                                                <Col
                                                    className="col-item"
                                                    span={4}
                                                    style={{ placeContent: "center" }}
                                                >
                                                    <p>{t("page.empty_text")}</p>
                                                </Col>
                                            )}
                                            <Col
                                                className="col-item"
                                                span={3}
                                                style={{ placeContent: "center" }}
                                            >
                                                <StylesSaveBtn>
                                                    <Button
                                                        className="save-btn"
                                                        onClick={() => {
                                                            if (editArr.includes(vIndex)) {
                                                                // click save
                                                                // handleSave();
                                                                onSaveItem(vIndex);
                                                            } else {
                                                                // on click Edit
                                                                onClickEdit(vIndex);
                                                                // setShowInput(!showInput);
                                                                // stores[index].editStep = true;
                                                                // setNewBalance("");
                                                            }
                                                        }}
                                                    >
                                                        {isSpin[vIndex] ? (
                                                            <Icon
                                                                type="loading"
                                                                className="load-icon"
                                                                style={{
                                                                    fontSize: 18,
                                                                    color: "white",
                                                                }}
                                                            />
                                                        ) : editArr.includes(vIndex) ? (
                                                            t("page.save")
                                                        ) : (
                                                            t("page.edit")
                                                        )}
                                                    </Button>
                                                </StylesSaveBtn>
                                            </Col>
                                        </>
                                    ) : (
                                        <Col
                                            className="col-item"
                                            span={10}
                                            style={{ placeContent: "center" }}
                                        ></Col>
                                    )}
                                </Row>
                            </div>
                        );
                    })}
                </div>
            )}
        </StylesCheckStock>
    );
};
const StylesCheckStock = styled.div`
    .table-row {
        height: 60px;
    }
    input {
        height: auto;
    }
    .one-row {
        margin-bottom: 22px;
    }
    .no-arrow {
        margin-left: 16px;
    }
    .table-element {
        margin: 0px;
    }
    .sub-table-row {
        color: pink;
        /* border-radius: 0px 0px 6px 6px; */
        border-radius: 0px;
    }
    .sub-title {
        padding-left: 25px;
    }
`;

const StylesSaveBtn = styled.div`
    .save-btn {
        color: white;
        background-color: #f4394f;
        height: 42px;
        padding: 4px 12px;
        font-size: 16px;
        font-weight: 700;
    }
    .load-icon {
        padding: 0px 6px;
    }
`;
