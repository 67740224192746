const printdiv = (data) => {
    const element_id = "container" + data?.po_no;
    const divToPrint = document.getElementById(element_id);
    if (divToPrint) {
        const html =
            "<html>" + //
            "<head>" +
            "<title>PO Paper</title>" +
            '<link href="/paper.scss" rel="stylesheet" type="text/css">' +
            "</head>" +
            // '<body onload="window.print(); window.close();">' +
            '<body onload="window.print(); ">' +
            divToPrint?.innerHTML +
            "</body>" +
            "</html>";
        const popupWin = window.open();
        if (popupWin) {
            popupWin.document.open();
            popupWin.document.write(html);
            popupWin.document.close();
        }
    }
};

export default printdiv;
