import { ISvg } from "@interfaces";

export const CopyIcon = (props: ISvg) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_7313_414020)">
            <path
                d="M16.75 1.75H1.75V16.75H16.75V1.75Z"
                stroke="#646464"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.5168 7.21704H22.2498V22.25H7.2168V19.517"
                stroke="#646464"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_7313_414020">
                <rect width="21.5" height="21.5" fill="white" transform="translate(1.25 1.25)" />
            </clipPath>
        </defs>
    </svg>
);
