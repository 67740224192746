import { useTranslation } from "react-i18next";
import {
    SharedComponentDrawer,
    SharedInput,
    SharedModal2,
    StyledCancelButton,
    StyledSubmitButton,
} from "../shared";
import styled from "styled-components";
import { useNotify } from "@utils";
import axios from "axios";
import { useMemo, useState } from "react";
import {
    Avatar,
    Button,
    Col,
    Divider,
    Icon,
    Row,
    Select,
    Spin,
    Switch,
    Table,
    Tag,
    Tooltip,
} from "antd";
import { useYup } from "@validations";
import React from "react";
import { SharedModal } from "../shared/InvitePermissionModal";
import { BlueCheckedIcon } from "../icon/BlueCheckedIcon";
import { permissionPosAPI } from "@api";
import { useSelector } from "react-redux";
import { selectAuth } from "@redux";
import { CopyIcon } from "../icon/storeList/CopyIcon";

interface IProps {
    showPopup: boolean;
    handleClose: () => void;
    sendToParent: any;
}

export const ComponentPermissionInviteModal = (props: IProps) => {
    const { YupNews } = useYup();
    const { showPopup, handleClose, sendToParent } = props;
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const { t } = useTranslation();
    const { success, error, warning } = useNotify();
    const [employeeName, setEmployeeName] = useState<string>("");
    const [permissionType, setPermissionType] = useState<any>("");
    const [successfull, setSuccessfull] = useState<boolean>(false);
    const [spinning, setSpinning] = useState<boolean>(false);
    const [tooltip, setTooltip] = useState<boolean>(false);

    const newDate = new Date();

    let lastURL = window.location.href.split("/").pop();

    const handleKeypress = (e) => {
        if (e.key === "Enter") {
            handleSave();
        }
    };
    const employeeCodeMemo = useMemo(
        () => Math.floor(100000 + Math.random() * 900000).toString(),
        []
    );
    const handleSave = async () => {
        try {
            if (permissionType === "cashier" || permissionType === "manager") {
                if (employeeName !== "") {
                    const res = await permissionPosAPI.createEmployee({
                        organization_code: orgCode,
                        store_code: lastURL,
                        name: employeeName,
                        email: "",
                        is_enable: true,
                        permission_type: permissionType,
                        pos_number: employeeCodeMemo,
                        position_text: "",
                    });
                    if (res.data.error === "0") {
                        success("User created successfully");
                        sendToParent();
                        // handleClose();
                        setSuccessfull(true);
                        setSpinning(true);
                        setTimeout(() => {
                            setSpinning(false);
                        }, 1500);
                        // setEmployeeName("");
                        // setPermissionType("");
                    } else {
                        error(res.data.msg);
                    }
                } else {
                    warning(t("error.input_employee_name"));
                }
            } else {
                warning(t("error.select_role"));
            }
        } catch (err) {
            console.log(err);
        }
    };

    const columns = [
        {
            title: "",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Cashier",
            dataIndex: "cashier",
            key: "cashier",
        },
        {
            title: "Manager",
            dataIndex: "manager",
            key: "manager",
        },
    ];

    const data = [
        {
            key: "1",
            name: "Create / view orders",
            cashier: <BlueCheckedIcon />,
            manager: <BlueCheckedIcon />,
        },
        {
            key: "2",
            name: "Manage sales round",
            cashier: "",
            manager: <BlueCheckedIcon />,
        },
        {
            key: "3",
            name: "Create Product",
            cashier: "",
            manager: <BlueCheckedIcon />,
        },
        {
            key: "4",
            name: "Store settings",
            cashier: "",
            manager: <BlueCheckedIcon />,
        },
        {
            key: "5",
            name: "View dashboard",
            cashier: "",
            manager: <BlueCheckedIcon />,
        },
    ];
    const { Option } = Select;

    return (
        <SharedModal
            showPopup={showPopup}
            handleClose={handleClose}
            title={t("page.add_member")}
            width={500}
        >
            <StyledModalContent>
                <div className="avatar">
                    <div
                        className="box_title"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                        }}
                    >
                        {!successfull ? (
                            <>
                                <Row style={{ width: "100%" }}>
                                    <Table
                                        columns={columns}
                                        dataSource={data}
                                        pagination={false}
                                        style={{ width: "100%", marginBottom: "30px" }}
                                    />
                                </Row>
                                <Row style={{ width: "100%" }}>
                                    <Col md={24}>
                                        <SharedInput
                                            label={t("page.name_employee")}
                                            onChange={(value) =>
                                                setEmployeeName(value.target.value)
                                            }
                                            value={employeeName}
                                            parentClassName="mb-6"
                                        />
                                    </Col>
                                </Row>
                                <Row
                                    style={{
                                        width: "100%",
                                    }}
                                >
                                    <Col md={24} style={{ paddingRight: 0 }}>
                                        <div className="title selectTitle">
                                            {t("page.employee_role")}
                                        </div>
                                        <Select
                                            showSearch
                                            filterOption={(input, option) =>
                                                //@ts-ignore
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            defaultValue={t("page.select_role")}
                                            style={{ width: "100%" }}
                                            onChange={(value) => setPermissionType(value)}
                                        >
                                            <Option value="cashier">{t("page.cashier")}</Option>
                                            <Option value="manager">{t("page.manager")}</Option>
                                        </Select>
                                    </Col>
                                </Row>
                            </>
                        ) : (
                            <Spin spinning={spinning}>
                                <Row style={{ width: "100%" }}>
                                    <Col md={24}>
                                        <SharedInput
                                            label={t("page.name_employee")}
                                            value={employeeName}
                                            parentClassName="mb-6"
                                            disable
                                        />
                                    </Col>
                                </Row>
                                <Row style={{ width: "100%" }}>
                                    <Col md={24}>
                                        <SharedInput
                                            label={t("page.employee_role")}
                                            value={permissionType}
                                            parentClassName="mb-6"
                                            disable
                                        />
                                    </Col>
                                </Row>
                                <Row style={{ width: "100%" }}>
                                    <Col md={24}>
                                        <SharedInput
                                            label={t("page.employee_role")}
                                            value={employeeCodeMemo}
                                            parentClassName="mb-6"
                                            disable
                                            suffixIcon={
                                                <div
                                                    onClick={() => {
                                                        setTooltip(true);
                                                        navigator.clipboard.writeText(
                                                            employeeCodeMemo
                                                        );
                                                        setTimeout(() => {
                                                            setTooltip(false);
                                                        }, 1000);
                                                    }}
                                                >
                                                    <Tooltip title={"Copied!"} visible={tooltip}>
                                                        <CopyIcon width={10} />
                                                    </Tooltip>
                                                </div>
                                            }
                                        />
                                    </Col>
                                </Row>
                            </Spin>
                        )}
                    </div>
                </div>
            </StyledModalContent>
            <div className="btn-layout" style={{ display: "flex", justifyContent: "center" }}>
                {!successfull ? (
                    <StyledSubmitButton
                        type="default"
                        text={t("page.create_employee")}
                        htmlType="button"
                        // disable={isSubmitting}
                        onClick={handleSave}
                        style={{ marginRight: 0 }}
                    />
                ) : (
                    <StyledSubmitButton
                        type="default"
                        text={t("page.done")}
                        htmlType="button"
                        onClick={() => {
                            handleClose();
                            setEmployeeName("");
                            setPermissionType("");
                        }}
                        style={{ marginRight: 0 }}
                    />
                )}
            </div>
        </SharedModal>
    );
};

const StyledModalContent = styled.div`
    .ant-drawer-body {
        .add-sub-btn {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: #0263e0;
            margin: 0;
            cursor: pointer;
        }
        .title {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            color: #000000;
            margin-bottom: 20px;
        }
        .btn-layout {
            position: fixed;
            bottom: 16px;
        }
    }
    .avatar {
        margin-bottom: 20px;
        position: relative;
        .box_title {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            p {
                margin: 0 4px 0 0;
                color: #000;
            }
            // button {
            //     padding: 0 4px;
            //     border: 0;
            //     background: transparent;
            //     height: auto;
            // }
        }
    }
    .avatar .box_title {
        margin: 30px auto 10px auto;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 1.5em;
    }
    .avatar-layout {
        position: relative;
        width: 110px;
        &:hover {
            .remove-icon-layout {
                visibility: visible;
            }
        }
    }
    .remove-icon-layout {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
        visibility: hidden;
        display: flex;
        justify-content: end;
        padding: 8px;
    }
    .avatar .box_title span {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 1.5em;
        position: relative;
        top: -1px;
        display: inline-block;
        margin-left: 4px;
    }
    .ant-table-tbody > tr > td {
        text-align: center;
    }
    .ant-table-thead > tr > th {
        align-items: center;
    }
    .ant-table-tbody > tr > td:first-child {
        text-align: left;
        color: #646464;
    }
    .avatar .box_title span {
        font-weight: 700;
        font-size: 16px;
    }
    .ant-select-arrow {
        display: inline-block !important;
        color: inherit !important;
        font-style: normal !important;
        position: absolute !important;
        top: 50% !important;
        font-size: 12px !important;
        line-height: 1 !important;
    }
    .selectTitle {
        margin-bottom: 7px;
        color: #000;
    }
    .ant-input-affix-wrapper {
        margin-left: 0 !important;
    }
    .ant-input-affix-wrapper .ant-input-suffix {
        position: absolute !important;
        top: 50% !important;
        line-height: 0 !important;
        cursor: pointer;
    }
    .ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
        position: absolute !important;
        top: 50% !important;
        left: 50% !important;
        right: 50% !important;
        margin: -10px;
    }
`;
