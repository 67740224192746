import {
    IAssignConsumable,
    ICreateConsumable,
    IDeleteConsumable,
    IGetConsumable,
    IGetConsumables,
    IGetConsumablesStoreId,
    IPatchStock,
    IUpdateConToAddon,
    IUpdateConsumable,
    IUpdateConsumableStoreID,
    IUpdateSingleConsumable,
} from "@interfaces";

import storeFrontAxiosClient from "./storeFrontAxiosClient";

export const consumableAPI = {
    getList: (params: IGetConsumables) => {
        const url = "consumable/getConsumables";
        return storeFrontAxiosClient.get(url, { params });
    },
    getConsumablesStoreId: (params: IGetConsumablesStoreId) => {
        const url = "consumable/getConsumablesStoreId";
        return storeFrontAxiosClient.get(url, { params });
    },
    getConsumablesStoreIdForCreatePR: (params: IGetConsumablesStoreId) => {
        const url = "consumable/getConsumablesStoreIdForCreatePR";
        return storeFrontAxiosClient.get(url, { params });
    },
    getItemGroupConsumableCategory: () => {
        const url = "consumable/getItemGroupConsumableCategory";
        return storeFrontAxiosClient.get(url);
    },
    getListStoreRunningOut: (params: IGetConsumables) => {
        const url = "consumable/getConsumablesStoreRunningOut";
        return storeFrontAxiosClient.get(url, { params });
    },
    getConsumable: (params: IGetConsumable) => {
        const url = "consumable/getConsumable";
        return storeFrontAxiosClient.get(url, { params });
    },
    createConsumable: (params: ICreateConsumable) => {
        const url = "consumable/createConsumable";
        return storeFrontAxiosClient.post(url, params);
    },
    updateConsumableStoreID: (params: IUpdateConsumableStoreID) => {
        const url = "consumable/updateConsumableStoreId";
        return storeFrontAxiosClient.patch(url, params);
    },
    updateSingleConsumable: (params: IUpdateSingleConsumable) => {
        const url = "consumable/updateConsumable";
        return storeFrontAxiosClient.patch(url, params);
    },
    assignConsumable: (params: IAssignConsumable) => {
        const url = "consumable/assignConsumableToVariant";
        return storeFrontAxiosClient.post(url, params);
    },
    updateConToAddon: (params: IUpdateConToAddon) => {
        const url = "product_addon/updateConsumableOnOptionOfAddon";
        return storeFrontAxiosClient.patch(url, params);
    },
    deleteConsumable: (params: IDeleteConsumable) => {
        const url = "consumable/deleteConsumable";
        return storeFrontAxiosClient.post(url, params);
    },
    updateStock: (params: IPatchStock) => {
        const url = "consumable/checkStockConsumable";
        return storeFrontAxiosClient.patch(url, params);
        //
    },
};
