import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Col, Row, Menu, Dropdown, Button } from "antd";
import { MoreHorizontal } from "react-feather";
import { IDataexample, IGetStoresBindingDropdownExample } from "@interfaces";
import { PATH_PURCHASE_ORDER } from "@configs";
import { selectAuth } from "@redux";
import { useSelector } from "react-redux";
import { IconDrag } from "@components";
import { useNotify } from "@utils";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useHistory } from "react-router";

interface IProps {
    data: IDataexample | any;
    itemJson: string;
    index: number;
    storeList: IGetStoresBindingDropdownExample[];
}

export const ComponentRowPurchaseOrder = (props: IProps) => {
    const { t } = useTranslation();
    const { success, error } = useNotify();
    const history = useHistory();
    const { data, itemJson, storeList } = props;
    const { attributes, listeners, setNodeRef, transform } = useSortable({
        id: itemJson,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
    };

    const MONTHlIST = [
        t("month.jan"),
        t("month.feb"),
        t("month.mar"),
        t("month.apr"),
        t("month.may"),
        t("month.jun"),
        t("month.jul"),
        t("month.aug"),
        t("month.sep"),
        t("month.oct"),
        t("month.nov"),
        t("month.dec"),
    ];

    function formatDate(date) {
        let d = new Date(date),
            month = "" + d.getMonth(),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        return [day, MONTHlIST[parseInt(month)], year + 543].join(" ");
    }

    const menu = (
        <Menu>
            <Menu.Item
                key={1}
                onClick={() => {
                    history.push(`${PATH_PURCHASE_ORDER}/${data._id}`);
                }}
            >
                <div className="duplicate-btn" style={{ color: "#000" }}>
                    {t("page.purchase_order.view_order")}
                </div>
            </Menu.Item>
        </Menu>
    );
    const renderStore = () => {
        const findStore = storeList.find((obj) => obj._id === data?.store_id);
        return <>{findStore?.store_name || "-"}</>;
    };
    return (
        <div className="table-element" ref={setNodeRef} style={style}>
            <div className="drag-icon hide" {...attributes} {...listeners}>
                <IconDrag />
            </div>
            <div className="table-row">
                <Row
                    style={{ height: "100%" }}
                    gutter={24}
                    type="flex"
                    align="middle"
                    justify="space-between"
                >
                    <Col className="col-item" span={5}>
                        {/* <Link to={`${PATH_PRODUCT}/${data.product_code}`}>
                            <p className="news-title">{data.name || t("page.empty_text")}</p>
                        </Link> */}
                        <p>{formatDate(data?.created_date) || t("page.empty_text")}</p>
                    </Col>
                    <Col className="col-item" span={5}>
                        <Link to={`${PATH_PURCHASE_ORDER}/${data._id}`}>
                            <b>
                                <p>{data.po_no || t("page.empty_text")}</p>
                            </b>
                        </Link>
                    </Col>
                    <Col className="col-item visible-md" span={5}>
                        <p>{data.supplier_name || t("page.empty_text")}</p>
                    </Col>
                    <Col className="col-item price" span={5}>
                        {renderStore()}
                    </Col>
                    {/* <Col className="col-item visible-md" span={4}>
                        <Switch onChange={handleUpdateStatus} checked={data.is_enable} />
                    </Col> */}
                    <Col className="col-item" span={3}>
                        <Dropdown
                            //@ts-ignore
                            getPopupContainer={(trigger) => trigger.parentNode}
                            overlay={menu}
                            trigger={["click"]}
                            placement="bottomRight"
                        >
                            <Button style={{ border: "unset" }}>
                                <MoreHorizontal />
                            </Button>
                        </Dropdown>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
