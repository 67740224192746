import { Badge, Layout, Menu } from "antd";

import { useLocation, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { MenuMode, MenuTheme } from "antd/lib/menu";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useState, useRef } from "react";
import { capitalize } from "@utils";
import { NewsIcon, HotNewsIcon } from "@components";
import { selectApp, setMobileDrawer, useAppSelector, selectAuth } from "@redux";
import { enumPermission, PATH_NEWS, PATH_NEWS_HIGHLIGHT } from "@configs";
interface IProps {
    sidebarTheme: MenuTheme;
    sidebarMode: MenuMode;
    sidebarIcons: boolean;
    collapsed: boolean;
}
interface ISideBarRoute {
    path?: string;
    name: string;
    icon: JSX.Element;
    permission?: string;
    children?: Array<{
        path: string;
        name: string;
        permission?: string;
        badge?: {
            value: string;
        };
    }>;
    badge?: {
        value: string;
    };
}

const { SubMenu } = Menu;
const { Sider } = Layout;
const getKey = (name: string, index: number) => {
    const string = `${name}-${index}`;
    const key = string.replace(" ", "-");
    return key.charAt(0).toLowerCase() + key.slice(1);
};

const newsRoutes: ISideBarRoute[] = [
    {
        path: PATH_NEWS,
        name: "all_news",
        permission: enumPermission.MANAGE_NEWS,
        icon: <NewsIcon />,
    },
    {
        path: PATH_NEWS_HIGHLIGHT,
        name: "hightlight",
        icon: <HotNewsIcon />,
        permission: enumPermission.MANAGE_NEWS,
    },
];

export const ComponentNews = ({ sidebarTheme, sidebarMode, sidebarIcons, collapsed }: IProps) => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const sidebarRef = useRef();
    const [openKeys, setOpenKeys] = useState<string[]>([]);
    //redux state
    const app = useAppSelector(selectApp);

    const pathname = location.pathname;
    const handleOpenKey = (e) => {
        setOpenKeys([...openKeys, e.key]);
    };
    const switchRoute = (path: string, permission?: string, index?: string) => {
        if (app.mobile) dispatch(setMobileDrawer(!app.mobileDrawer));
        if (permission) {
            history.push(path);
        } else {
            history.push(path);
        }
    };
    const badgeTemplate = (badge: { value: string }) => <Badge count={badge.value} />;
    return (
        <Menu
            theme={sidebarTheme}
            className="border-0 scroll-y "
            style={{ flex: 1 }}
            mode={sidebarMode}
            defaultOpenKeys={["0", "1"]}
            onClick={handleOpenKey}
        >
            {newsRoutes.map((route, index) => {
                return (
                    <SubMenu
                        className={`menu-after ${route.name}`}
                        key={index}
                        onTitleClick={() => {
                            if (route.path && route.permission) {
                                switchRoute(route.path, route.permission || "", index.toString());
                            }
                        }}
                        title={
                            <span>
                                {sidebarIcons && (
                                    <span className="anticon" style={{ marginBottom: 5 }}>
                                        {route.icon}
                                    </span>
                                )}
                                <span
                                    className="menu-item-text"
                                    style={{
                                        color: "black",
                                        fontWeight: 700,
                                        fontSize: 16,
                                    }}
                                >
                                    {capitalize(t("page.sidebar." + route.name))}
                                </span>
                                {route.badge && badgeTemplate(route.badge)}
                            </span>
                        }
                    ></SubMenu>
                );
            })}
        </Menu>
    );
};
