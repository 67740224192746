export const DescriptionIcon = () => (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.36249 11.6849H3.30036C1.32634 11.6849 0 10.2994 0 8.23765V3.4681C0 1.4063 1.32634 0.020874 3.30036 0.020874H5.44534C5.68284 0.020874 5.8756 0.213629 5.8756 0.451132C5.8756 0.688634 5.68284 0.881389 5.44534 0.881389H3.30036C1.81798 0.881389 0.860515 1.8968 0.860515 3.4681V8.23765C0.860515 9.80895 1.81798 10.8244 3.30036 10.8244H8.36249C9.84487 10.8244 10.8029 9.80895 10.8029 8.23765V5.92688C10.8029 5.68937 10.9957 5.49662 11.2332 5.49662C11.4707 5.49662 11.6634 5.68937 11.6634 5.92688V8.23765C11.6634 10.2994 10.3365 11.6849 8.36249 11.6849Z"
            fill="black"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.93993 7.70333H5.64777C5.86577 7.70333 6.07 7.619 6.22431 7.46468L10.5321 3.15694C10.7087 2.98025 10.8063 2.74504 10.8063 2.49492C10.8063 2.24422 10.7087 2.00844 10.5321 1.83175L9.83389 1.13359C9.46846 0.769301 8.87413 0.769301 8.50812 1.13359L4.22104 5.42067C4.07245 5.56925 3.98812 5.7666 3.98296 5.97599L3.93993 7.70333ZM5.64774 8.56385H3.49875C3.38287 8.56385 3.27157 8.51681 3.19068 8.43362C3.1098 8.35101 3.06562 8.23915 3.06849 8.12269L3.12242 5.95476C3.13332 5.52336 3.30714 5.11777 3.61234 4.812H3.61291L7.9 0.524914C8.60103 -0.174971 9.74092 -0.174971 10.442 0.524914L11.1401 1.22308C11.4803 1.5627 11.6673 2.01418 11.6668 2.49492C11.6668 2.97566 11.4797 3.42657 11.1401 3.76561L6.83238 8.07335C6.51629 8.38945 6.09521 8.56385 5.64774 8.56385Z"
            fill="black"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.1721 4.54139C10.0619 4.54139 9.95179 4.49951 9.86803 4.41518L7.24862 1.79577C7.08054 1.62768 7.08054 1.35519 7.24862 1.1871C7.41671 1.01901 7.68863 1.01901 7.85672 1.1871L10.4761 3.80708C10.6442 3.97517 10.6442 4.24709 10.4761 4.41518C10.3924 4.49951 10.2822 4.54139 10.1721 4.54139Z"
            fill="black"
        />
    </svg>
);
