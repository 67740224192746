import { useEffect, memo, useState, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Switch, Row, Col, Select, Modal, Input, Drawer, Avatar, Button, Tooltip } from "antd";
import styled from "styled-components";
import moment from "moment";
import { INews, INewsData, INewsCategory, INewsCategoryParams } from "@interfaces";
import {
    SharedCKEditor,
    StyledSubmitButton,
    StyledCancelButton,
    StyledCard,
    SharedInput,
    ComponentPeriod,
    ShareButton,
    InfoIcon,
    IconCamera,
    ComponentServicePreview,
} from "@components";
import { DeleteOutlined } from "@ant-design/icons";
import { useYup } from "@validations";
import { getCategories, setLoading, selectAuth } from "@redux";
import { ModuleUploadImage } from "@modules";
import { showErrorMessage, useNotify } from "@utils";
import {
    enumAddNews,
    enumDrawerPlacement,
    PATH_CREATE_NEWS,
    PATH_NEWS,
    PATH_PRODUCT,
    IMAGE_TYPE,
    enumValidation,
} from "@configs";
import { newsAPI, orgApi } from "@api";

const textString = "390px × 270px Maximum 10MB";
interface IProps {
    news: INews | undefined;
    categories: INewsCategory[] | undefined;
}
const newDate = new Date();
// form inital data
const initData: INewsData = {
    organization_id: "",
    title: "",
    detail: "",
    image_url: [],
    is_hot_news: false,
    news_category_id: "",
    date_from: new Date(),
    date_to: new Date(newDate.setFullYear(newDate.getFullYear() + 1)),
    is_enable: true,
    discount: "",
    product_code: "",
    price: "",
    discount_type: "",
    has_barcode: true,
    active_on_platform: [],
    point_deduction: false,
    maximum_deduction: false,
    point_deduction_value: "",
    variants: [
        {
            variant_name: "",
            qty: "",
            raw_price: "",
            net_price: "",
            barcode: "",
            status: false,
        },
    ],
};
const { Option } = Select;
export const ModuleServiceForm = memo((props: IProps) => {
    //hook
    const { YupNews } = useYup();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const { error, success } = useNotify();
    const [showModal, setShowModal] = useState<boolean>(false);
    const organization_id = useSelector(selectAuth).auth?.admin_data?.organization_id;
    const searchInputRef = useRef<any | null>(null);
    const [cat, setCat] = useState<string>("");
    const pathName = window.location.pathname;
    const createNews = Yup.object().shape(YupNews);
    const { news, categories } = props;
    useEffect(() => {
        if (news) {
            setFieldValue("title", news.title);
            setFieldValue("detail", news.detail);
            setFieldValue("date_from", news.date_from);
            setFieldValue("date_to", news.date_to);
            setFieldValue("news_category_id", news.news_category_id);
            setFieldValue("is_hot_news", news.is_hot_news);
        }
    }, [news, categories]);
    const handleEnter = (e: string) => {
        if (e === "Enter") {
            createCategory();
            searchInputRef.current.focus();
        }
    };
    const createCategory = async () => {
        dispatch(setLoading(true));
        // if (news?.organization_id === "") return;
        try {
            const payload: INewsCategoryParams = {
                category_name: cat,
                organization_id: organization_id || "",
                is_enable: true,
            };
            const response = await orgApi.createCat(payload);
            success(response.data.msg);
            // dispatch(getCategories({ organization_code: organization_id || "" }));
        } catch (err: any) {
            if (err) {
                error(err.msg);
            } else error(t("message.create.fail"));
        } finally {
            dispatch(setLoading(false));
        }
    };
    const handleNewsSubmit = async (values: INewsData) => {
        dispatch(setLoading(true));
        try {
            if (pathName === PATH_CREATE_NEWS) {
                const payload = {
                    organization_id: organization_id || "",
                    title: values.title,
                    detail: values.detail,
                    image_url: values.image_url,
                    is_hot_news: values.is_hot_news,
                    news_category_id: values.news_category_id,
                    date_from: values.date_from,
                    date_to: values.date_to,
                    is_enable: values.is_enable,
                    discount: values.discount,
                    product_code: values.product_code,
                    price: values.price,
                    discount_type: values.discount_type,
                    has_barcode: values.has_barcode,
                    active_on_platform: values.active_on_platform,
                    point_deduction: values.point_deduction,
                    maximum_deduction: values.maximum_deduction,
                    point_deduction_value: values.point_deduction_value,
                    variants: values.variants,
                };
                await newsAPI.create(payload);
                success(t("message.create.success"));
                history.push(PATH_NEWS);
            } else {
                await newsAPI.update(values);
                success(t("message.create.success"));
                history.push(PATH_NEWS);
            }
        } catch (err: any) {
            if (err.response) {
                error(showErrorMessage(err.response));
            } else error(t("message.error"));
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleCancel = () => {
        history.push("/");
    };

    const { values, handleChange, handleBlur, handleSubmit, errors, touched, setFieldValue } =
        useFormik({
            initialValues: initData,
            validationSchema: createNews,
            enableReinitialize: true,
            onSubmit: handleNewsSubmit,
        });

    const handleChangeStatus = (e: boolean) => {
        setFieldValue("is_hot_news", e);
    };

    const handleChangeDateFrom = (date: Date | string) => {
        setFieldValue("date_from", moment(date).toISOString());
    };

    const handleChangeDateTo = (date: Date | string) => {
        setFieldValue("date_to", moment(date).toISOString());
    };

    const handleUploadImage = (images: string[]) => {
        console.log(images);
        setFieldValue("image_url", images);
    };

    const handleChangeGuideLine = (event: any, editors: any) => {
        const data = editors.getData();
        setFieldValue("detail", data);
    };
    const handleSelectType = (value: string) => {
        if (value === enumAddNews.ADD_NEWS) {
            setShowModal(true);
        } else {
            setFieldValue("news_category_id", value);
        }
    };
    const handleSearchInput = (value: string) => {
        setCat(value);
    };
    const handleRemoveCategory = async (value: string) => {
        const removeItem = categories?.find((item) => item.category_name === value);
        console.log(removeItem);
        if (removeItem) {
            const payload = {
                organization_id: organization_id || "",
                news_category_id: removeItem._id,
            };
            await orgApi.deleteCat(payload);
            // dispatch(getCategories({ organization_id: organization_id || "" }));
        }
    };
    const handleRemoveImageLogo = () => {
        // remove action from form value
    };
    const handleRemoveImage = () => {
        // remove action from form value
    };
    const handleChangeImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
        try {
            //@ts-ignore
            const image = e.target.files[0];
            if (!image) {
                //WHAT: image empty
                error(t("validation.image.empty"));
                return;
            }
            if (!IMAGE_TYPE.some((imageType) => imageType === image.type)) {
                error(t("validation.image.wrong_type"));
                return;
            }
            if (image.size >= enumValidation.MAX_FILE_SIZE) {
                error(t("validation.image.max_size"));
                return;
            }
            const formData = new FormData();
            formData.append("file", image);
            // const res = await merchantAPI.uploadImage(formData);
            await console.log("upload image api call here");
        } catch (error: any) {
            //
            // const data = error.response.data;
            if (error.response) {
                error(showErrorMessage(error.response));
            } else {
                error(t("page.image_error"));
            }
        }
    };
    return (
        <div className="form-wrap">
            <form onSubmit={handleSubmit}>
                <div className="page-header">
                    <h3>
                        {pathName.includes(PATH_PRODUCT)
                            ? t("page.create_product")
                            : t("page.create_product")}
                    </h3>
                    <p className="sub-title">{t("page.specify_detail")}</p>
                </div>
                {/* form basic setting */}
                <div className="page-body">
                    <div className="card-wrap">
                        <StyledCard>
                            <div className="title">{t("page.product_settings")}</div>
                            <div>
                                <Row>
                                    <Col span={24}>
                                        <ModuleUploadImage
                                            images={values.image_url}
                                            handleGetImage={handleUploadImage}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <SharedInput
                                            label={t("page.images_product")}
                                            // descLabel={t("page._2_100_characters")}
                                            name="title"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.title}
                                            errors={errors.title}
                                            touched={touched.title}
                                            parentClassName="mb-6"
                                        />
                                    </Col>
                                </Row>
                                <Row className="select-layout">
                                    <Col md={24}>
                                        <div className="label">{t("page.product_code")}</div>
                                        <div className="input-field">
                                            <Select
                                                showSearch
                                                filterOption={(input, option) =>
                                                    //@ts-ignore
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                style={{ width: "100%", height: 48 }}
                                                placeholder={t("page.product_code")}
                                                onChange={handleSelectType}
                                                defaultActiveFirstOption={true}
                                                value={values.news_category_id}
                                            >
                                                {categories &&
                                                    categories.map((item, index) => {
                                                        return (
                                                            <Option key={index} value={item._id}>
                                                                {item.category_name}
                                                            </Option>
                                                        );
                                                    })}
                                                <Option
                                                    key={"add-new"}
                                                    value={enumAddNews.ADD_NEWS}
                                                >
                                                    {"+"} {t("page.specify_news_type")}
                                                </Option>
                                            </Select>
                                        </div>
                                    </Col>
                                </Row>
                                <Row gutter={32}>
                                    <Col md={12}>
                                        <SharedInput
                                            label={t("page.category")}
                                            // descLabel={t("page._2_100_characters")}
                                            name="title"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.title}
                                            errors={errors.title}
                                            touched={touched.title}
                                            parentClassName="mb-6"
                                        />
                                    </Col>
                                    <Col md={12}>
                                        <SharedInput
                                            label={t("page.sub_category")}
                                            // descLabel={t("page._2_100_characters")}
                                            name="title"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.title}
                                            errors={errors.title}
                                            touched={touched.title}
                                            parentClassName="mb-6"
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={32}>
                                    <Col md={12}>
                                        <div className="label">{t("page.standerd_price")}</div>
                                        <div className="input-field">
                                            <Select
                                                showSearch
                                                filterOption={(input, option) =>
                                                    //@ts-ignore
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                style={{ width: "100%", height: 48 }}
                                                placeholder={t("page.standerd_price")}
                                                onChange={handleSelectType}
                                                defaultActiveFirstOption={true}
                                                value={values.news_category_id}
                                            >
                                                {categories &&
                                                    categories.map((item, index) => {
                                                        return (
                                                            <Option key={index} value={item._id}>
                                                                {item.category_name}
                                                            </Option>
                                                        );
                                                    })}
                                                <Option
                                                    key={"add-new"}
                                                    value={enumAddNews.ADD_NEWS}
                                                >
                                                    {"+"} {t("page.add_category")}
                                                </Option>
                                            </Select>
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div className="label">{t("page.discounts")}</div>
                                        <div className="input-field">
                                            <Select
                                                showSearch
                                                filterOption={(input, option) =>
                                                    //@ts-ignore
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                style={{ width: "100%", height: 48 }}
                                                placeholder={t("page.discounts")}
                                                onChange={handleSelectType}
                                                defaultActiveFirstOption={true}
                                                value={values.news_category_id}
                                            >
                                                {categories &&
                                                    categories.map((item, index) => {
                                                        return (
                                                            <Option key={index} value={item._id}>
                                                                {item.category_name}
                                                            </Option>
                                                        );
                                                    })}
                                                <Option
                                                    key={"add-new"}
                                                    value={enumAddNews.ADD_NEWS}
                                                >
                                                    {"+"} {t("page.add_category")}
                                                </Option>
                                            </Select>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </StyledCard>
                    </div>
                    <div className="card-wrap">
                        <StyledCard>
                            <div className="title">
                                {t("page.variants")}
                                {/* <span>{t("page._2_2000_characters")}</span> */}
                            </div>
                            <StyledSwitch className="switch-field">
                                <Switch
                                    checked={values.is_hot_news}
                                    onChange={handleChangeStatus}
                                />
                                <p>{t("page.this_product_has_options")}</p>
                            </StyledSwitch>
                            <ComponentPeriod
                                dateFrom={values.date_from}
                                dateTo={values.date_to}
                                limitDate={{ minDate: new Date() }}
                                handleDateFrom={handleChangeDateFrom}
                                handleDateTo={handleChangeDateTo}
                            />
                        </StyledCard>
                    </div>
                    <div className="card-wrap">
                        <StyledCard>
                            <div className="title">
                                {t("page.descriptions")}
                                {/* <span>{t("page._2_2000_characters")}</span> */}
                            </div>
                            <SharedCKEditor
                                editor={values.detail || ""}
                                handleChangeEditor={handleChangeGuideLine}
                                name="description"
                            />
                        </StyledCard>
                    </div>

                    <StyledSwitch className="switch-field">
                        <p>{t("page.activate")}</p>
                        <Switch checked={values.is_hot_news} onChange={handleChangeStatus} />
                    </StyledSwitch>
                    <div className="btn-layout">
                        <StyledSubmitButton
                            type="default"
                            text={t("page.save")}
                            htmlType="submit"
                        // disable={isSubmitting}
                        />
                        <StyledCancelButton
                            type="sub"
                            text={t("page.cancel")}
                            htmlType="button"
                            onClick={handleCancel}
                        />
                    </div>
                </div>
            </form>
            <ComponentServicePreview news={values} />
            <StyledDrawer
                title={t("page.add_category")}
                placement={enumDrawerPlacement.RIGHT}
                closable={false}
                onClose={() => setShowModal(false)}
                visible={showModal}
                width={538}
            >
                <div className="avatar">
                    <div className="box_title">
                        <p>{t("page.image_main_category")}</p>
                        <Tooltip placement="topLeft" title={`${textString}`}>
                            <Button>
                                <InfoIcon />
                            </Button>
                        </Tooltip>
                    </div>
                    <div className="avatar-layout">
                        <Avatar size={110} src={""} shape="square" />
                        <div className="remove-icon-layout">
                            <DeleteOutlined
                                style={{ color: "white", fontSize: 20 }}
                                onClick={handleRemoveImage}
                            />
                        </div>
                    </div>
                    <input id="file_update_img" type="file" hidden onChange={handleChangeImage} />
                    <label className="camera" htmlFor="file_update_img">
                        <IconCamera />
                    </label>
                </div>
                <SharedInput
                    label={t("page.category")}
                    // descLabel={t("page._2_100_characters")}
                    name="title"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.title}
                    errors={errors.title}
                    touched={touched.title}
                    parentClassName="mb-6"
                />
                <SharedInput
                    label={t("page.category")}
                    // descLabel={t("page._2_100_characters")}
                    name="title"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.title}
                    errors={errors.title}
                    touched={touched.title}
                    parentClassName="mb-6"
                />
                <div className="btn-layout">
                    <StyledSubmitButton
                        type="default"
                        text={t("page.save")}
                        htmlType="button"
                    // disable={isSubmitting}
                    />
                    <StyledCancelButton
                        type="sub"
                        text={t("page.cancel")}
                        htmlType="button"
                        onClick={handleCancel}
                    />
                </div>
            </StyledDrawer>
        </div>
    );
});
const StyledDrawer = styled(Drawer)`
    .avatar {
        margin-bottom: 42px;
        position: relative;
        .box_title {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            p {
                margin: 0 4px 0 0;
            }
            button {
                padding: 0 4px;
                border: 0;
                background: transparent;
                height: auto;
            }
        }
    }
    .avatar .box_title {
        margin: 27px 0 20px 0px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 1.5em;
    }
    .avatar-layout {
        position: relative;
        width: 110px;
        &:hover {
            .remove-icon-layout {
                visibility: visible;
            }
        }
    }
    .remove-icon-layout {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
        visibility: hidden;
        display: flex;
        justify-content: end;
        padding: 8px;
    }
    .avatar .box_title span {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 1.5em;
        position: relative;
        top: -1px;
        display: inline-block;
        margin-left: 4px;
    }
    .avatar .camera {
        width: 33px;
        height: 33px;
        background-color: #000000;
        border-radius: 50%;
        opacity: 0.29;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 110px;
        bottom: 0;
        transform: translate(-50%, 50%);
        cursor: pointer;
    }
`;
const StyledSwitch = styled.div`
    display: flex;
    align-items: center;
    p {
        margin-bottom: 0;
    }
`;
