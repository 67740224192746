export const IconCancel = () => (
    <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_8366_460794)">
            <rect
                x="4"
                y="4"
                width="42"
                height="42"
                rx="12"
                fill="url(#paint0_linear_8366_460794)"
                shapeRendering="crispEdges"
            />
            <path
                d="M24.9999 14.2749C23.5913 14.2744 22.1965 14.5515 20.895 15.0903C19.5936 15.6291 18.4111 16.4191 17.4151 17.4151C16.4191 18.4111 15.6291 19.5936 15.0903 20.895C14.5515 22.1965 14.2744 23.5913 14.2749 24.9999C14.2744 26.4085 14.5515 27.8033 15.0903 29.1048C15.6291 30.4062 16.4191 31.5887 17.4151 32.5847C18.4111 33.5807 19.5936 34.3707 20.895 34.9095C22.1965 35.4483 23.5913 35.7254 24.9999 35.7249C26.4085 35.7254 27.8033 35.4483 29.1048 34.9095C30.4062 34.3707 31.5887 33.5807 32.5847 32.5847C33.5807 31.5887 34.3707 30.4062 34.9095 29.1048C35.4483 27.8033 35.7254 26.4085 35.7249 24.9999C35.7254 23.5913 35.4483 22.1965 34.9095 20.895C34.3707 19.5936 33.5807 18.4111 32.5847 17.4151C31.5887 16.4191 30.4062 15.6291 29.1048 15.0903C27.8033 14.5515 26.4085 14.2744 24.9999 14.2749ZM24.9999 32.7249C24.6792 32.7256 24.3655 32.6312 24.0985 32.4534C23.8315 32.2757 23.6233 32.0228 23.5002 31.7266C23.3771 31.4304 23.3447 31.1044 23.4071 30.7898C23.4695 30.4752 23.6239 30.1862 23.8506 29.9594C24.0774 29.7326 24.3664 29.5782 24.681 29.5159C24.9956 29.4535 25.3217 29.4859 25.6178 29.6089C25.914 29.732 26.167 29.9402 26.3447 30.2072C26.5224 30.4742 26.6169 30.7879 26.6162 31.1087C26.6152 31.537 26.4446 31.9475 26.1417 32.2504C25.8388 32.5533 25.4283 32.7239 24.9999 32.7249ZM26.9612 19.3749L26.3499 27.6249C26.3461 27.6819 26.3207 27.7354 26.2789 27.7744C26.2371 27.8134 26.1821 27.835 26.1249 27.8349H23.8749C23.8177 27.835 23.7627 27.8134 23.7209 27.7744C23.6791 27.7354 23.6537 27.6819 23.6499 27.6249L23.0387 19.3749C23.0221 19.1071 23.0605 18.8388 23.1516 18.5865C23.2427 18.3342 23.3844 18.1031 23.5682 17.9077C23.7519 17.7122 23.9737 17.5564 24.22 17.45C24.4662 17.3435 24.7316 17.2886 24.9999 17.2886C25.2682 17.2886 25.5336 17.3435 25.7798 17.45C26.0261 17.5564 26.2479 17.7122 26.4316 17.9077C26.6154 18.1031 26.7571 18.3342 26.8482 18.5865C26.9393 18.8388 26.9777 19.1071 26.9612 19.3749Z"
                fill="white"
            />
        </g>
        <defs>
            <filter
                id="filter0_d_8366_460794"
                x="0"
                y="0"
                width="58"
                height="58"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dx="4" dy="4" />
                <feGaussianBlur stdDeviation="4" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.403906 0 0 0 0 0.878431 0 0 0 0.16 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_8366_460794"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_8366_460794"
                    result="shape"
                />
            </filter>
            <linearGradient
                id="paint0_linear_8366_460794"
                x1="2.425"
                y1="-0.200001"
                x2="33.2014"
                y2="42.2787"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FF7A79" />
                <stop offset="1" stopColor="#FA3E3D" />
            </linearGradient>
        </defs>
    </svg>
);
