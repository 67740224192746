import {
    IDeleteStore,
    IGetSingleStore,
    IGetStorePaymentGateway,
    IGetStoresBindingDropdown,
    INewStoreList,
    IUpdateStore,
    IUpdateStorePaymentGateway,
    IUpdateStoreStatus,
} from "src/Interfaces/store";

import storeFrontAxiosClient from "./storeFrontAxiosClient";
import axiosClient from "./axiosClient";

export const storeAPI = {
    getList: (params: INewStoreList) => {
        const url = "store/getStores";
        return storeFrontAxiosClient.get(url, { params });
    },
    getStoresBindingDropdown: (params: IGetStoresBindingDropdown) => {
        const url = "store/getStoresBindingDropdown";
        return storeFrontAxiosClient.get(url, { params });
    },
    getSingleStore: (params: IGetSingleStore) => {
        const url = "store/getStore";
        return storeFrontAxiosClient.get(url, { params });
    },
    updateStoreStatus: (params: IUpdateStoreStatus) => {
        const url = "store/updateStoreStatus";
        return storeFrontAxiosClient.patch(url, params);
    },
    deleteStore: (params: IDeleteStore) => {
        const url = "store/deleteStore";
        return storeFrontAxiosClient.post(url, params);
    },
    updateStore: (params: IUpdateStore) => {
        const url = "store/updateStore";
        return storeFrontAxiosClient.patch(url, params);
    },
    createStore: (params: IUpdateStore) => {
        const url = "store/createStore";
        return storeFrontAxiosClient.post(url, params);
    },
    getLoyatyChannelList: () => {
        const url = "channel/getAllChannelForPos";
        return axiosClient.get(url);
    },
    getStorePaymentGateway: (params: IGetStorePaymentGateway) => {
        const url = "store/getStorePaymentGateway";
        return storeFrontAxiosClient.get(url, { params });
    },
    updateStorePaymentGateway: (params: IUpdateStorePaymentGateway) => {
        const url = "store/updateStorePaymentGateway";
        return storeFrontAxiosClient.patch(url, params);
    },
};
