import { StyledNewDetail } from "@components";
import { ModuleImportTableForm } from "@modules";

export const PageImportTable = () => {
    return (
        <StyledNewDetail>
            <ModuleImportTableForm />
        </StyledNewDetail>
    );
};
