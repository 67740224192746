import { useState, useEffect } from "react";
import {
    ComponentContactSortIcon,
    StyledTable,
    ComponentEmptyData,
    StyledTableButtonTop,
    ComponentRowAcitivity,
    ComponentStatusFilter,
} from "@components";
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    TouchSensor,
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    rectSortingStrategy,
    //@ts-ignore
} from "@dnd-kit/sortable";
import { Row, Col, Pagination } from "antd";
import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";
import {
    enumSortBy,
    enumContactSortFields,
    PAGINATION,
    PAGE_START,
    INITIAL_LIST_PARAMS,
    enumNewsStatus,
} from "@configs";
import { IPaginationNext, INews, IActivity } from "@interfaces";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth, selectContact, setLoading } from "@redux";
import { useNotify } from "@utils";
import { useHistory } from "react-router";
import { activityApi } from "@api";
const INITAL_PAGE = {
    xpage: PAGE_START,
    xlimit: PAGINATION,
};

export const PageActivityList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const search_params = useSelector(selectContact).paramsContact;
    const [params, setParams] = useState(search_params);
    const org_id = useSelector(selectAuth).userInfo?.merchant._id;
    const [searchDate, setSearchDate] = useState<string>("");
    const [pagination, setPagination] = useState<IPaginationNext>(INITAL_PAGE);
    const [activities, setActivities] = useState<IActivity[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [isFull, setIsFull] = useState<boolean>(false);
    const [filterStatus, setFilterStatus] = useState<boolean>(true);
    const [status, setStatus] = useState<enumNewsStatus>(enumNewsStatus.ALL);
    const { success, error } = useNotify();
    const history = useHistory();
    useEffect(() => {
        fetchConplaint(pagination, isFull, searchDate);
    }, [pagination, isFull, searchDate]);

    const getActiveCount = () => {
        return activities.filter((item) => item.is_enable === true).length;
    };
    const getInactiveCount = () => {
        return activities.filter((item) => item.is_enable === false).length;
    };
    const fetchConplaint = async (
        pagination: IPaginationNext,
        isFull: boolean,
        searchDate: string
    ) => {
        dispatch(setLoading(true));
        try {
            const response = await activityApi.getActivities({
                organization_id: org_id || "",
                ...pagination,
                ...(isFull && { is_full: isFull }),
                ...(searchDate.length > 1 &&
                    searchDate[0] !== "" && {
                    date_from: searchDate[0],
                }),
                ...(searchDate.length > 1 &&
                    searchDate[1] !== "" && {
                    date_to: searchDate[1],
                }),
            });
            setActivities(response.data.result);
            setTotal(response.data.total);
            success(response.data.msg);
        } catch (err: any) {
            error(err.msg);
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleSort = (sortField: enumContactSortFields, sortBy: enumSortBy) => {
        const newSortParams = { ...params, sortField, sortBy };
        setParams(newSortParams);
    };
    const callbackGetList = async () => {
        await fetchConplaint(pagination, isFull, searchDate);
    };
    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            const swapItem = (items: INews[]) => {
                const oldIndex = items.findIndex((item) => JSON.stringify(item) === active.id);
                const newIndex = items.findIndex((item) => JSON.stringify(item) === over.id);
                return arrayMove(items, oldIndex, newIndex);
            };
            // setNews(swapItem);
        }
    };
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
        useSensor(TouchSensor)
    );
    const handlePagination = (page) => {
        setPagination(page);
    };
    return (
        <StyledTable>
            <div className="page-layout">
                <div className="page-header">
                    <div>
                        <h3>{t("page.activity_list")}</h3>
                    </div>
                    <div className="flex-header">
                        <StyledTableButtonTop
                            type="danger"
                            size="default"
                            text={t("page.create_activity")}
                            onClick={() => history.push(`/activity/create`)}
                            className="add-btn"
                            icon="plus"
                        />
                    </div>
                </div>
                <div className="search-layout">
                    <ComponentStatusFilter
                        setStatus={setStatus}
                        setFilterStatus={setFilterStatus}
                        activeCount={getActiveCount()}
                        inactiveCount={getInactiveCount()}
                    />
                </div>
                <div className="table">
                    <ScrollContainer vertical={false}>
                        <div className="table_ground">
                            <div className="table-body">
                                <div className="table-header">
                                    <Row
                                        gutter={24}
                                        type="flex"
                                        justify="space-between"
                                        align="middle"
                                        className="header-row"
                                    >
                                        <Col className="col-item" span={4}>
                                            <p>{t("page.activity")}</p>
                                            <div className="filter-button">
                                                <ComponentContactSortIcon
                                                    handleSort={handleSort}
                                                    sortBy={enumSortBy.ASC}
                                                    sortField={enumContactSortFields.FULL_NAME}
                                                    searchParams={params}
                                                />
                                                <ComponentContactSortIcon
                                                    handleSort={handleSort}
                                                    sortBy={enumSortBy.DESC}
                                                    sortField={enumContactSortFields.FULL_NAME}
                                                    searchParams={params}
                                                />
                                            </div>
                                        </Col>
                                        <Col className="col-item" span={3}>
                                            <p>{t("page.activity_type")}</p>
                                            <div className="filter-button">
                                                <ComponentContactSortIcon
                                                    handleSort={handleSort}
                                                    sortBy={enumSortBy.ASC}
                                                    sortField={enumContactSortFields.TEL}
                                                    searchParams={params}
                                                />
                                                <ComponentContactSortIcon
                                                    handleSort={handleSort}
                                                    sortBy={enumSortBy.DESC}
                                                    sortField={enumContactSortFields.TEL}
                                                    searchParams={params}
                                                />
                                            </div>
                                        </Col>
                                        <Col className="col-item" span={3}>
                                            <p>{t("page.participant_number")}</p>
                                            <div className="filter-button">
                                                <ComponentContactSortIcon
                                                    handleSort={handleSort}
                                                    sortBy={enumSortBy.ASC}
                                                    sortField={enumContactSortFields.POINTS}
                                                    searchParams={params}
                                                />
                                                <ComponentContactSortIcon
                                                    handleSort={handleSort}
                                                    sortBy={enumSortBy.DESC}
                                                    sortField={enumContactSortFields.POINTS}
                                                    searchParams={params}
                                                />
                                            </div>
                                        </Col>
                                        <Col className="col-item" span={3}>
                                            <p>{t("page.fully_booked")}</p>
                                            <div className="filter-button">
                                                <ComponentContactSortIcon
                                                    handleSort={handleSort}
                                                    sortBy={enumSortBy.ASC}
                                                    sortField={enumContactSortFields.POINTS}
                                                    searchParams={params}
                                                />
                                                <ComponentContactSortIcon
                                                    handleSort={handleSort}
                                                    sortBy={enumSortBy.DESC}
                                                    sortField={enumContactSortFields.POINTS}
                                                    searchParams={params}
                                                />
                                            </div>
                                        </Col>
                                        <Col className="col-item" span={3}>
                                            <p>{t("page.activity_period")}</p>
                                            <div className="filter-button">
                                                <ComponentContactSortIcon
                                                    handleSort={handleSort}
                                                    sortBy={enumSortBy.ASC}
                                                    sortField={enumContactSortFields.CREATED_AT}
                                                    searchParams={params}
                                                />
                                                <ComponentContactSortIcon
                                                    handleSort={handleSort}
                                                    sortBy={enumSortBy.DESC}
                                                    sortField={enumContactSortFields.CREATED_AT}
                                                    searchParams={params}
                                                />
                                            </div>
                                        </Col>
                                        <Col className="col-item" span={3}>
                                            <p>{t("page.date_of_creation")}</p>
                                            <div className="filter-button">
                                                <ComponentContactSortIcon
                                                    handleSort={handleSort}
                                                    sortBy={enumSortBy.ASC}
                                                    sortField={enumContactSortFields.CREATED_AT}
                                                    searchParams={params}
                                                />
                                                <ComponentContactSortIcon
                                                    handleSort={handleSort}
                                                    sortBy={enumSortBy.DESC}
                                                    sortField={enumContactSortFields.CREATED_AT}
                                                    searchParams={params}
                                                />
                                            </div>
                                        </Col>
                                        <Col className="col-item" span={3}>
                                            <p>{t("page.status")}</p>
                                            <div className="filter-button">
                                                <ComponentContactSortIcon
                                                    handleSort={handleSort}
                                                    sortBy={enumSortBy.ASC}
                                                    sortField={enumContactSortFields.CREATED_AT}
                                                    searchParams={params}
                                                />
                                                <ComponentContactSortIcon
                                                    handleSort={handleSort}
                                                    sortBy={enumSortBy.DESC}
                                                    sortField={enumContactSortFields.CREATED_AT}
                                                    searchParams={params}
                                                />
                                            </div>
                                        </Col>
                                        <Col className="col-item" span={2}>
                                            <p>{t("page.manage")}</p>
                                        </Col>
                                    </Row>
                                </div>
                                <DndContext
                                    sensors={sensors}
                                    collisionDetection={closestCenter}
                                    onDragEnd={handleDragEnd}
                                >
                                    <SortableContext
                                        items={activities.map((item) => JSON.stringify(item))}
                                        strategy={rectSortingStrategy}
                                    >
                                        {activities && activities.length > 0 ? (
                                            <div className="data-table">
                                                {activities.map((item, index) => (
                                                    <ComponentRowAcitivity
                                                        key={index}
                                                        item={item}
                                                        itemJson={JSON.stringify(item)}
                                                        callbackGetList={callbackGetList}
                                                        index={index}
                                                    />
                                                ))}
                                                <div style={{ width: "100%", height: 10 }}></div>
                                            </div>
                                        ) : (
                                            <ComponentEmptyData />
                                        )}
                                    </SortableContext>
                                </DndContext>
                            </div>
                        </div>
                    </ScrollContainer>
                </div>

                <div className="page-bottom">
                    {total > 0 && (
                        <div className="pagination">
                            <Pagination
                                defaultCurrent={INITIAL_LIST_PARAMS.page || 1}
                                pageSize={PAGINATION}
                                total={total}
                                onChange={handlePagination}
                                current={params.page}
                            />
                        </div>
                    )}
                </div>
            </div>
        </StyledTable>
    );
};
