import axiosClient from "./axiosClient";
import {
    IActivitiesParams,
    IActivityParams,
    IEnableFullActivity,
    IActivityCreate,
    IActivityDelete,
} from "src/Interfaces/activity";

export const activityApi = {
    getActivities: (params: IActivitiesParams) => {
        const url = "activity/getActivities";
        return axiosClient.get(url, { params });
    },
    getActivity: (params: IActivityParams) => {
        const url = "activity/getActivity";
        return axiosClient.get(url, { params });
    },
    createActivity: (params: IActivityCreate) => {
        const url = "activity/createActivity";
        return axiosClient.post(url, params);
    },
    updateActivity: (params: IActivityCreate) => {
        const url = "activity/updateActivity";
        return axiosClient.post(url, params);
    },
    delete: (params: IActivityDelete) => {
        const url = `activity/deleteActivity`;
        return axiosClient.post(url, params);
    },
    setEnableFullActivity: (params: IEnableFullActivity) => {
        const url = "activity/setEnableFullActivity";
        return axiosClient.patch(url, params);
    },
};
