import { useState, useRef, useEffect } from "react";
import {
    ComponentContactSortIcon,
    StyledTable,
    ComponentEmptyData,
    StyledTableButtonTop,
    ComponentTableSearch,
    ComponentRowService,
    ComponentRowPurchaseOrder,
    IconAllPO,
    IconDone,
    IconRenew,
    IconCancel,
    ComponentTablePOSearch,
    ComponentRowGoodReceive,
    ComponentTableGRItemSearch,
    ComponentRowGoodReceiveItem,
    StyledSubmitButton,
    StyledCancelButton,
    ComponentGRItemModal,
} from "@components";
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    TouchSensor,
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    rectSortingStrategy,
    //@ts-ignore
} from "@dnd-kit/sortable";
import { Row, Col, Pagination, Button } from "antd";
import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";
import {
    enumSortBy,
    enumContactSortFields,
    PAGINATION,
    PAGE_START,
    enumNewsStatus,
    INITIAL_LIST_PARAMS,
    PATH_PRODUCT_CREATE,
    PATH_PURCHASE_ORDER_CREATE,
    PATH_GOOD_RECEIVE,
} from "@configs";
import { IPaginationNext, INews, IDataexample, IPostDoRG } from "@interfaces";
import { useDispatch, useSelector } from "react-redux";
import {
    getCategories,
    openToast,
    selectAuth,
    selectContact,
    selectOrg,
    setLoading,
    store,
} from "@redux";
import { useNotify } from "@utils";
import { useHistory, useParams } from "react-router";
import axios from "axios";
import styled from "styled-components";
import { POAPI, orgApi, productAPI } from "@api";
import { Checkbox } from "@mui/material";

const INITAL_PAGE = {
    xpage: PAGE_START,
    xlimit: PAGINATION,
};

export const PageGoodReceiveDetailList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string }>();
    const search_params = useSelector(selectContact).paramsContact;
    const [params, setParams] = useState(search_params);
    const org_id = useSelector(selectAuth).userInfo?.merchant._id;
    const { userInfo } = useSelector(selectAuth);
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [pagination, setPagination] = useState<IPaginationNext>(INITAL_PAGE);
    const [services, setServices] = useState<IDataexample[]>([]);
    const [total, setTotal] = useState<number>(0);
    const { success, error } = useNotify();
    const history = useHistory();
    const [Refresh, setRefresh] = useState<any>(0);
    const [tempRefresh, setTempRefresh] = useState(0);
    const [tab, setTab] = useState(0);
    const [gRItem, setGRItem] = useState<any>(null);
    const [selectList, setSelectList] = useState<string[]>([]);
    const [PRPendingList, setPRPendingList] = useState<any[]>([]);
    const [configDetail, setConfigDetail] = useState<any>();
    useEffect(() => {
        getGRItem();
        fetchORGSetting();
    }, []);

    useEffect(() => {
        handleSort;
    }, [tempRefresh]);

    const fetchORGSetting = async () => {
        const getConfig = await orgApi.getVatDetail({
            organization_id: org_id,
        });
        setConfigDetail(getConfig?.data?.result);
    };

    const getGRItem = async () => {
        dispatch(setLoading(true));
        try {
            const res = await POAPI.getGRItem({
                organization_id: userInfo?.merchant?._id || "",
                popr_id: id || "",
            });
            if (res.data.error === "0") {
                setGRItem(res.data.result);
                let arr = [];
                let selectArr: string[] = [];
                res.data.result?.pr_pending_list?.forEach((element) => {
                    let obj = {
                        consumable_name: element?.consumable_name,
                        restock_qty: element?.restock_qty,
                        receive_amount: element?.restock_qty,
                        restock_unit: element?.restock_unit,
                        unit_price: element?.unit_price,
                        _id: element?._id,
                        expire_date: null,
                    };
                    //@ts-ignore
                    arr.push(obj);
                    selectArr.push(element?._id);
                });
                setPRPendingList([...arr]);
                setSelectList(selectArr);
            }
        } catch (err: any) {
            error(err.msg);
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const changeDate = (dateString: string, index: number) => {
        let arr = [...PRPendingList];
        arr[index].expire_date = dateString;
        setPRPendingList([...arr]);
    };
    const receiveAmountHandler = (amount: number, index: number) => {
        let arr = [...PRPendingList];
        arr[index].receive_amount = amount;
        setPRPendingList([...arr]);
    };

    const handleSort = (sortField: enumContactSortFields, sortBy: enumSortBy) => {
        const newSortParams = { ...params, sortField, sortBy };
        setParams(newSortParams);
        // fetchContacts(newSortParams);
        setServices(services.reverse());
        setTempRefresh(2);
    };

    const handlePagination = (page) => {
        setPagination({ ...pagination, xpage: page });
    };
    const clickCheck = (isCheck: boolean, gId: string) => {
        let arr = selectList;
        if (isCheck === false) {
            if (arr.includes(gId)) {
                const index = arr.indexOf(gId);
                arr.splice(index, 1);
            }
        } else {
            arr.push(gId);
        }
        setSelectList([...arr]);
    };
    const changeTab = (index: number) => {
        setTab(index);
        // setSelectList([]);
    };
    const clickSelectAll = () => {
        if (selectList.length === 0) {
            //
            let arr: string[] = [];
            PRPendingList.forEach((pItem) => {
                arr.push(pItem._id);
            });
            setSelectList(arr);
        } else {
            setSelectList([]);
        }
    };
    const saveGRItem = () => {
        setShowPopup(true);
    };

    const confirmSave = async (isClose: boolean) => {
        // setShowPopup(false);
        let arr = [];
        PRPendingList.forEach((element) => {
            let check = selectList.some((x) => x === element._id);
            if (check) {
                let obj = {
                    move_id: element?._id,
                    expire_date: element?.expire_date,
                    receive_amount: element?.receive_amount,
                };
                //@ts-ignore
                arr.push(obj);
            }
        });
        let payload: IPostDoRG = {
            organization_id: userInfo?.merchant?._id || "",
            popr_id: id,
            move_pr_pending_list: arr,
            is_close_pr: isClose,
        };
        try {
            const res = await POAPI.postDoGR(payload);
            if (res.data.error === "0") {
                history.push(PATH_GOOD_RECEIVE);
                store.dispatch(
                    openToast({
                        message: "update success",
                        type: "success",
                        autoHideDuration: 3000,
                    })
                );
            } else {
                store.dispatch(
                    openToast({
                        message: res.data.msg,
                        type: "error",
                        autoHideDuration: 3000,
                    })
                );
            }
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <StyledTable>
            <div className="page-layout">
                <div className="page-header-layout">
                    <div className="page-header">
                        <div>
                            {/* <h3>{t("page.good_receive.list_title")}</h3> */}
                            <h3>
                                {t("page.good_receive.detail_title")} {gRItem?.pr_no}{" "}
                            </h3>
                        </div>
                    </div>
                    <div className="search-layout">
                        {gRItem && <ComponentTableGRItemSearch gRItem={gRItem} />}
                    </div>
                </div>

                <div className="page-table-layout">
                    <div className="tab-section">
                        <Button
                            className={`tab-btn ${tab === 0 ? "active" : ""}`}
                            onClick={() => changeTab(0)}
                        >
                            ยังไม่ได้รับสินค้า
                            {/* ({gRList?.length}) */}
                        </Button>
                        <Button
                            className={`tab-btn ${tab === 1 ? "active" : ""}`}
                            onClick={() => changeTab(1)}
                        >
                            รับสินค้าแล้ว
                            {/* ({doneGRList?.length}) */}
                        </Button>
                    </div>
                    {tab === 0 &&
                        <div className="select-all-section">
                            <Checkbox
                                checked={selectList.length === PRPendingList.length}
                                onClick={clickSelectAll}
                                className="check-box-all"
                            />
                            <p>
                                {t("page.good_receive.select_all")}
                            </p>
                        </div>
                    }

                    <div className="table">
                        <ScrollContainer vertical={false}>
                            <div className="table_ground">
                                <div className="table-body">
                                    <div className="table-header">
                                        <Row
                                            gutter={24}
                                            type="flex"
                                            justify="space-between"
                                            align="middle"
                                            className="header-row"
                                        >
                                            <Col className="col-item" span={7}>
                                                <p>{t("page.good_receive.table_item_inventory")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.FULL_NAME}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.FULL_NAME}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={4}>
                                                <p>{t("page.good_receive.table_item_price")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.POINTS}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.POINTS}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={4}>
                                                <p>{t("page.good_receive.receive_amount")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.POINTS}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.POINTS}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={4}>
                                                <p>{t("page.good_receive.table_item_baht")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.CREATED_AT}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.CREATED_AT}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            {configDetail?.is_show_expiry_date_on_gr &&
                                                <Col className="col-item" span={5}>
                                                    <p>{t("page.good_receive.table_item_expiry")}</p>
                                                    <div className="filter-button">
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.ASC}
                                                            sortField={enumContactSortFields.CREATED_AT}
                                                            searchParams={params}
                                                        />
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.DESC}
                                                            sortField={enumContactSortFields.CREATED_AT}
                                                            searchParams={params}
                                                        />
                                                    </div>
                                                </Col>
                                            }

                                        </Row>
                                    </div>
                                    {tab === 0 && (
                                        <>
                                            {PRPendingList.length > 0 ? (
                                                <div className="data-table">
                                                    {PRPendingList.map(
                                                        (item: any, index: number) => {
                                                            return (
                                                                <ComponentRowGoodReceiveItem
                                                                    key={index}
                                                                    data={item}
                                                                    selectList={selectList}
                                                                    itemJson={JSON.stringify(item)}
                                                                    index={index}
                                                                    changeDate={changeDate}
                                                                    receiveAmountHandler={
                                                                        receiveAmountHandler
                                                                    }
                                                                    clickCheck={clickCheck}
                                                                    showCheck={
                                                                        gRItem?.status ===
                                                                            "finished"
                                                                            ? false
                                                                            : true
                                                                    }
                                                                    showExpiry={configDetail?.is_show_expiry_date_on_gr}
                                                                />
                                                            );
                                                        }
                                                    )}
                                                </div>
                                            ) : (
                                                <ComponentEmptyData />
                                            )}
                                        </>
                                    )}
                                    {tab === 1 && (
                                        <>
                                            {gRItem?.pr_receive_list &&
                                                gRItem?.pr_receive_list.length > 0 ? (
                                                <div className="data-table">
                                                    {gRItem?.pr_receive_list.map(
                                                        (item: any, index: number) => (
                                                            <ComponentRowGoodReceiveItem
                                                                key={index}
                                                                data={item}
                                                                selectList={selectList}
                                                                changeDate={changeDate}
                                                                itemJson={JSON.stringify(item)}
                                                                index={index}
                                                                clickCheck={clickCheck}
                                                                showCheck={false}
                                                                showExpiry={configDetail?.is_show_expiry_date_on_gr}
                                                            />
                                                        )
                                                    )}
                                                </div>
                                            ) : (
                                                <ComponentEmptyData />
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        </ScrollContainer>
                    </div>
                </div>

                <div className="page-bottom">
                    {total > 0 && (
                        <div className="pagination">
                            <Pagination
                                defaultCurrent={INITIAL_LIST_PARAMS.page || 1}
                                pageSize={PAGINATION}
                                total={total}
                                onChange={handlePagination}
                                current={pagination.xpage}
                            />
                        </div>
                    )}
                </div>
                {gRItem?.status === "pending" && (
                    <div className="btn-section">
                        <StyledCancelButton
                            type="sub"
                            text={t("page.cancel")}
                            htmlType="button"
                            onClick={() => history.goBack()}
                        />
                        <StyledSubmitButton
                            type="default"
                            text={t("page.save")}
                            htmlType="button"
                            onClick={saveGRItem}
                        />
                    </div>
                )}

                <ComponentGRItemModal
                    showPopup={showPopup}
                    handleClose={() => setShowPopup(!showPopup)}
                    confirmSave={confirmSave}
                    isAll={gRItem?.pr_pending_list.length === selectList?.length}
                />
            </div>
        </StyledTable>
    );
};
