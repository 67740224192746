import { Modal } from "antd";
import { ReactNode } from "react";

interface IProps {
    showPopup2: boolean;
    handleClose: () => void;
    children: ReactNode;
    title: string;
    width: number;
}
export const SharedModal2 = (props: IProps) => {
    const { showPopup2, handleClose, children, title, width } = props;
    return (
        <Modal
            visible={showPopup2}
            onCancel={handleClose}
            title={title}
            footer={null}
            width={width}
        >
            {children}
        </Modal>
    );
};
