import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { StyledNewDetail } from "@components";
import { INews } from "@interfaces";
import { PATH_CREATE_NEWS, PATH_NEWS } from "@configs";
import { ModuleNewsForm } from "@modules";
import { useNotify, showErrorMessage } from "@utils";
import { newsAPI } from "@api";
import { setLoading, selectAuth, selectOrg, getCategories } from "@redux";

export const PageNewsCreate = () => {
    const { id } = useParams<{ id: string }>();
    const org_id = useSelector(selectAuth).userInfo?.merchant._id;
    const categories = useSelector(selectOrg).category;
    const dispatch = useDispatch();
    const history = useHistory();
    const { warning } = useNotify();
    const { t } = useTranslation();
    // page state
    const [news, setNews] = useState<INews>();
    // variable
    const pathName = history.location.pathname;

    useEffect(() => {
        // fetchNewsCategory();
        fetchNews();
    }, []);
    // const fetchNewsCategory = async () => {
    //     await dispatch(getCategories({ organization_id: org_id || "" }));
    // };
    const fetchNews = async () => {
        dispatch(setLoading(true));
        if (pathName !== PATH_CREATE_NEWS) {
            try {
                const response = await newsAPI.detail({
                    organization_id: org_id || "",
                    news_id: id,
                });
                const data: INews = response.data.result;
                setNews(data);
                dispatch(setLoading(false));
            } catch (err: any) {
                if (err.response) {
                    warning(showErrorMessage(err.response));
                    dispatch(setLoading(false));
                } else warning(t("message.not_found", { name: t("object._reward") }));
                history.push(PATH_NEWS);
            } finally {
                dispatch(setLoading(false));
            }
        }
    };

    return (
        <StyledNewDetail>{<ModuleNewsForm news={news} categories={categories} />}</StyledNewDetail>
    );
};
