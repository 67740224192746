import {
    ICreatePosRole,
    IDeletePosRole,
    IGetPosRole,
    IGetPosRoleTemplate,
    IGetPosRoles,
    IUpdatePosRole,
} from "@interfaces";
import storeFrontAxiosClient from "./storeFrontAxiosClient";

export const rolePosAPI = {
    getPosRole: (params: IGetPosRole) => {
        const url = "pos_role/getPosRole";
        return storeFrontAxiosClient.get(url, { params });
    },
    getPosRoles: (params: IGetPosRoles) => {
        const url = "pos_role/getPosRoles";
        return storeFrontAxiosClient.get(url, { params });
    },
    deletePosRole: (params: IDeletePosRole) => {
        const url = "pos_role/deletePosRole";
        return storeFrontAxiosClient.post(url, params);
    },
    createPosRole: (params: ICreatePosRole) => {
        const url = "pos_role/createPosRole";
        return storeFrontAxiosClient.post(url, params);
    },
    updatePosRole: (params: IUpdatePosRole) => {
        const url = "pos_role/updatePosRole";
        return storeFrontAxiosClient.patch(url, params);
    },
    getPosRoleTemplate: (params: IGetPosRoleTemplate) => {
        const url = "pos_role/getPosRoleTemplate";
        return storeFrontAxiosClient.get(url, { params });
    },
};
