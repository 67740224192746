import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Col, Modal, Row } from "antd";
import {
    ArrowRightLine,
    EmptySuccessCheckBox,
    FoldersIcon2,
    ImportCheckedIcon,
    ImportFailedIcon,
    SeperatorLine,
    TableSvg,
    VerificationFailedCheckbox,
    VerificationPendingGreenIcon,
} from "../icon";
import { IGetLastOneImportExcelLogExample } from "@interfaces";
import { StyledCancelButton } from "../shared";

interface IProps {
    showPopup: boolean;
    handleClose: () => void;
    status: IGetLastOneImportExcelLogExample;
}

export const ComponentImportTableStatusModal = (props: IProps) => {
    const { showPopup, handleClose, status } = props;
    const { t } = useTranslation();

    function nextLineFormat(inputText) {
        const formattedText = inputText.replace(/, /g, "\n");
        return formattedText;
    }

    return (
        //@ts-ignore
        <StyledImportTableModal
            visible={showPopup}
            onCancel={handleClose}
            width={550}
            footer={false}
        >
            <Row className="table-head">
                <Col span={24}>
                    <p className="title2">{t("page.verification")}</p>
                    <p className="body4">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    </p>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <div className="statusRow">
                        <div className="statusCol">
                            <ImportCheckedIcon />
                            <p className="body3">{t("page.import")}</p>
                        </div>
                        <SeperatorLine />
                        <div className="statusCol">
                            {status.status === "finished" ? (
                                <ImportCheckedIcon />
                            ) : status.status === "doing" ? (
                                <VerificationFailedCheckbox />
                            ) : (
                                <VerificationPendingGreenIcon />
                            )}
                            <p className="body3">{t("page.verification")}</p>
                        </div>
                        <SeperatorLine />
                        <div className="statusCol">
                            {status.status === "finished" ? (
                                <ImportCheckedIcon />
                            ) : (
                                <EmptySuccessCheckBox />
                            )}
                            <p className="body3">{t("page.success")}</p>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <div className="svgMotionRow">
                        <FoldersIcon2 />
                        <ArrowRightLine />
                        <TableSvg />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <div
                        className="statusReportRow"
                        style={{
                            background:
                                status.status === "doing" ? "rgba(242, 47, 70, 0.08)" : "#f7f7f8",
                        }}
                    >
                        {status.status === "finished" ? (
                            <ImportCheckedIcon />
                        ) : (
                            <ImportFailedIcon />
                        )}
                        {status.status === "finished" ? (
                            <p
                                className={status.status === "finished" ? "body3" : "body4"}
                                style={{
                                    color: status.status === "finished" ? "#6CD14E" : "#000",
                                    whiteSpace: "pre-line",
                                }}
                            >
                                {nextLineFormat(status.success_description)}
                            </p>
                        ) : (
                            <p className="body4">Importing failed</p>
                        )}
                    </div>
                </Col>
            </Row>
            <Row className="btnRow">
                <Col span={24}>
                    <StyledCancelButton
                        type="default"
                        text={t("page.view_history")}
                        htmlType="submit"
                        onClick={handleClose}
                    />
                </Col>
            </Row>
        </StyledImportTableModal>
    );
};

const StyledImportTableModal = styled(Modal)`
    .ant-modal-close-x {
        position: absolute;
        top: -30px;
        right: -15px;
    }
    overflow-y: auto;
    .table-head {
        margin-bottom: 20px;
        p {
            color: #000;
            text-align: center;
            margin: 0;
        }
    }
    .statusRow {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        .statusCol {
            display: flex;
            flex-direction: row;
            gap: 8px;
            align-items: center;
        }
        p {
            margin: 0;
        }
    }
    .svgMotionRow {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 16px;
        justify-content: space-between;
        padding: 16px;
        margin-bottom: 20px;
    }
    .statusReportRow {
        display: flex;
        align-items: center;
        width: 100%;
        gap: 8px;
        border-radius: 8px;
        padding: 16px 14px;
        p {
            margin: 0;
        }
    }
    button[type="submit"] {
        width: 100%;
        background: #fff !important;
        margin-top: 20px;
        margin-bottom: 0;
        color: #000;
        border: 0.5px solid #646464;
        border-radius: 4px;
    }
    button[type="submit"]:hover {
        box-shadow: 0px 4px 12px rgb(0 0 0 / 20%);
    }
`;
