import React, { useEffect, memo, useState, useRef, useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import {
    Switch,
    Row,
    Col,
    Select,
    Radio,
    Checkbox,
    Input,
    Tag,
    Popconfirm,
    Table,
    Form,
    Tooltip,
    Button,
    TimePicker,
    Tree,
    Avatar,
} from "antd";
import styled from "styled-components";
import moment from "moment";
import { INews, INewsData, INewsCategory, INewsCategoryParams, IPaginationNext } from "@interfaces";
import {
    SharedCKEditor,
    StyledSubmitButton,
    StyledCancelButton,
    StyledCard,
    SharedInput,
    ComponentPeriod,
    InfoIcon,
    IconCamera,
    StyledTableButtonTop,
} from "@components";
import { useYup } from "@validations";
import { getCategories, setLoading, selectAuth } from "@redux";
import { ModuleUploadImage } from "@modules";
import { showErrorMessage, useNotify } from "@utils";
import {
    dateFormat,
    enumAddNews,
    PAGE_START,
    PAGINATION,
    PATH_CREATE_NEWS,
    PATH_NEWS,
    PATH_PRODUCT,
    PATH_PRODUCT_CREATE,
    PATH_RECIPE_LIST,
    PATH_STOCK_LIST,
    PATH_STORE,
    PATH_STORE_CREATE,
    PATH_STORE_LIST,
} from "@configs";
import { newsAPI, orgApi, productAPI, storeAPI } from "@api";
import { ComponentProductDrawer, ComponentProductPreview } from "src/Components/product";
import { RadioChangeEvent } from "antd/lib/radio";
import { DescriptionIcon } from "src/Components/icon/DescriptionIcon";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { VariantGrayIcon } from "src/Components/icon/VariantGrayIcon";
import { ColumnProps } from "antd/lib/table";
import { PrintIcon } from "src/Components/icon/PrintIcon";
import { ComponentProductBarcodeModal } from "src/Components/product/ProductBarcodeModal";
import axios from "axios";
// import { ComponentMaps } from "./Map";
import { GoogleMap, Marker } from "react-google-maps";
import Geocode from "react-geocode";
import { DeleteOutlined } from "@ant-design/icons";
import { consumableAPI } from "src/API/consumable";
import { ViewIcon } from "src/Components/icon/ViewIcon";
import { StockEditIcon } from "src/Components/icon/StockEditIcon";
import { ComponentEditStockDrawer } from "src/Components/stock";
import { ComponentStockModal } from "src/Components/stock/StockModal";
import { AssignCategoryIcon } from "src/Components/icon/AssignCategoryIcon";
import { AssignProductIcon } from "src/Components/icon/AssignProductIcon";
import { AssignEditIcon } from "src/Components/icon/AssignEditIcon";
import { AssignDeleteIcon } from "src/Components/icon/AssignDeleteIcon";
import { ComponentAddConsumableModal } from "src/Components/stock/AddConsumableModal";
import { EyeIcon } from "src/Components/icon/EyeIcon";
import { ProductEditQTYDrawer } from "src/Components/product/ProductEditQTYDrawer";
import { ProductQTYHistoryModal } from "src/Components/product/ProductQTYHistoryModal";
import { EditQTYArrowRight } from "src/Components/icon/EditQTYArrowRight";
// import { ComponentSearchInput } from "./Search";
interface IProps {
    product: any | undefined;
    categories: INewsCategory[] | undefined;
    setRefresh?: any;
    Refresh?: any;
}
const newDate = new Date();
const INITAL_PAGE = {
    xpage: PAGE_START,
    xlimit: PAGINATION,
};
// form inital data
const initData: INewsData = {
    organization_id: "",
    title: "",
    detail: "",
    image_url: [],
    is_hot_news: false,
    news_category_id: "",
    date_from: new Date(),
    date_to: new Date(newDate.setFullYear(newDate.getFullYear() + 1)),
    is_enable: true,
    discount: "",
    product_code: "",
    price: "",
    discount_type: "",
    has_barcode: true,
    active_on_platform: [],
    point_deduction: false,
    maximum_deduction: false,
    point_deduction_value: "",
    variants: [
        {
            variant_name: "",
            qty: "",
            raw_price: "",
            net_price: "",
            barcode: "",
            status: true,
        },
    ],
};
const { Option } = Select;

interface IGetHistory {
    is_deduct_by_pos: boolean;
    before_qty: number;
    edited_qty: number;
    new_qty: number;
    created_date: Date;
    created_by: Date;
    remark: string;
}

export const ModuleEditQTYForm = memo((props: IProps) => {
    //hook
    const { YupNews } = useYup();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const { error, success } = useNotify();
    const [showModal, setShowModal] = useState<boolean>(false);
    const organization_id = useSelector(selectAuth).userInfo?.merchant?._id;
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const { product, categories, setRefresh, Refresh } = props;
    const [pagination, setPagination] = useState<IPaginationNext>(INITAL_PAGE);
    //
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [step, setStep] = useState<any>(1);
    const [tableData, setTableData] = useState<any>();
    const [selectedVariant, setSelectedVariant] = useState<any>();
    const [selectedStore, setSelectedStore] = useState<any>();
    const [historyChange, setHistoryChange] = useState<IGetHistory[]>([]);
    const [variants, setVariants] = useState<string[]>([]);
    const [tableData2, setTableData2] = useState<string[]>([]);
    //

    useEffect(() => {
        setTimeout(() => {
            dispatch(setLoading(false));
        }, 500);
        if (product) {
            // console.log(product);
            if (product.variant_list.length > 1) {
                setTableData2(product.variant_list);
                if (selectedVariant) {
                    if (product.variant_list.some((x) => x._id === selectedVariant._id)) {
                        setTableData(
                            product.variant_list.find((x) => x._id === selectedVariant._id)
                                .store_list
                        );
                    }
                }
            }
            if (selectedVariant === undefined) {
                setTableData(product.variant_list[0].store_list);
            }
        }
    }, [product]);

    const getHistory = async (item) => {
        try {
            const res = await productAPI.getHistoryChangeOfVariantStoreId({
                organization_id: organization_id,
                store_code: item.store_code,
                product_id: product._id,
                variant_id: product.variant_list[0]._id,
            });
            // console.log(res);
            if (res.data.error === "0") {
                setHistoryChange(res.data.result);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleClose = () => {
        setShowModal(false);
    };

    interface ITableData {
        _id: string;
        store_code: string;
        qty: number;
        is_enable: boolean;
        store_name: string;
    }

    const columns: ColumnProps<ITableData>[] = [
        {
            dataIndex: "store_name",
            title: `${t("page.branch")}`,
            render: (text, record, index) => (
                <div style={{ placeItems: "center", display: "flex" }}>{text}</div>
            ),
        },
        {
            dataIndex: "qty",
            title: `${t("page.quantity")}`,
            render: (text, record, index) => (
                <div style={{ placeItems: "center", display: "flex", placeContent: "center" }}>
                    {text}
                </div>
            ),
        },
        {
            dataIndex: "history",
            title: `${t("page.history")}`,
            render: (text, record, index) => (
                <div style={{ placeItems: "center", display: "flex", placeContent: "center" }}>
                    <Button
                        className="antd-btn-custom"
                        onClick={() => {
                            setHistoryChange([]);
                            getHistory(record);
                            setShowPopup(true);
                        }}
                        style={{ margin: 0 }}
                    >
                        <EyeIcon /> {t("page.view")}
                    </Button>
                </div>
            ),
        },
        {
            dataIndex: "quantity",
            title: `${t("page.edit_quantity")}`,
            render: (text, record, index) => (
                <div style={{ placeItems: "center", display: "flex", placeContent: "center" }}>
                    <StyledTableButtonTop
                        type="danger"
                        size="default"
                        text={t("page.edit_quantity")}
                        onClick={() => {
                            setSelectedStore(record);
                            setShowModal(true);
                        }}
                        className="add-btn"
                        icon="plus"
                    />
                </div>
            ),
        },
    ];

    const columns2 = [
        {
            dataIndex: "variant_name",
            title: `${t("page.variant")}`,
            width: "70%",
            render: (text, record, index) => (
                <div style={{ placeItems: "center", display: "flex" }}>{text}</div>
            ),
        },
        {
            dataIndex: "add_edit_qty",
            title: `${t("page.add_edit_quantity")}`,
            render: (text, record, index) => (
                <div style={{ placeItems: "center", display: "flex" }}>
                    <StyledTableButtonTop
                        type="danger"
                        size="default"
                        text={t("page.add_edit_quantity")}
                        onClick={() => {
                            setTableData(record.store_list);
                            setSelectedVariant(record);
                            setStep(2);
                        }}
                        className="add-btn"
                        icon="plus"
                    />
                </div>
            ),
        },
    ];

    return (
        <div className="form-wrap">
            <form>
                <div className="page-header">
                    <h3>{t("page.add_edit_quantity")}</h3>
                </div>
                <div className="page-body">
                    {product && product.variant_list.length !== 1 && (
                        <div className="card-wrap">
                            <StyledCard style={{ padding: "1.5rem" }}>
                                <StyledCustom>
                                    <div className="row">
                                        <div className="step">
                                            <div className="blueCircle">1</div>
                                            <p
                                                className="title2"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    setStep(1);
                                                }}
                                            >
                                                {t("page.select_product_variant")}
                                            </p>
                                        </div>
                                        <div className="arrowRight">
                                            <EditQTYArrowRight />
                                        </div>
                                        <div className="step">
                                            <div
                                                className={step === 2 ? "blueCircle" : "grayCircle"}
                                            >
                                                2
                                            </div>
                                            <p
                                                className={
                                                    step === 2 ? "title2" : "title2 whiteText"
                                                }
                                            >
                                                {t("page.add_edit_quantity")}
                                            </p>
                                        </div>
                                    </div>
                                </StyledCustom>
                            </StyledCard>
                        </div>
                    )}
                    {product && product.variant_list.length !== 1 && step === 1 && (
                        <div className="card-wrap">
                            <StyledCard>
                                <StyledCustom>
                                    <div
                                        className="title"
                                        style={{
                                            fontSize: "25px",
                                            color: "#000",
                                            borderBottom: "none",
                                            marginBottom: 0,
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        {step === 1 ? (
                                            <p>{t("page.product_variant")}</p>
                                        ) : (
                                            <p>{t("page.add_consumable")}</p>
                                        )}
                                    </div>
                                    <StyledCustom>
                                        <Table
                                            rowKey="_id"
                                            columns={columns2}
                                            dataSource={tableData2 && tableData2}
                                            pagination={false}
                                            bordered
                                            className="table"
                                        />
                                    </StyledCustom>
                                </StyledCustom>
                            </StyledCard>
                        </div>
                    )}
                    {(step === 2 || (product && product.variant_list.length === 1)) && (
                        <>
                            <div className="card-wrap">
                                <StyledCard>
                                    <StyledCustom>
                                        <div
                                            className="title"
                                            style={{
                                                fontSize: "25px",
                                                color: "#000",
                                                borderBottom: "none",
                                                marginBottom: 0,
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <p>{t("page.edit_quantity")}</p>
                                        </div>
                                        <StyledCustom>
                                            <div className="longGrayBox">
                                                <img
                                                    src={product && product.image_url_list[0]}
                                                    alt="Product Image"
                                                    className="productImage"
                                                />
                                                <p>
                                                    {product && product.name}
                                                    {selectedVariant &&
                                                        ` (${selectedVariant.variant_name})`}
                                                </p>
                                            </div>
                                        </StyledCustom>
                                    </StyledCustom>
                                </StyledCard>
                            </div>
                            <div className="card-wrap">
                                <StyledCard>
                                    <StyledCustom>
                                        <Table
                                            rowKey="store_code"
                                            columns={columns}
                                            dataSource={tableData && tableData}
                                            pagination={false}
                                            bordered
                                            className="table"
                                        />
                                    </StyledCustom>
                                </StyledCard>
                            </div>
                        </>
                    )}
                    {/* <div className="btn-layout">
                            <StyledSubmitButton
                                type="default"
                                text={t("page.save")}
                                htmlType="button"
                                onClick={() => handleAssignSubmit()}
                            />
                            <StyledCancelButton
                                type="sub"
                                text={t("page.cancel")}
                                htmlType="button"
                                onClick={() => history.push(PATH_RECIPE_LIST)}
                            />
                        </div> */}
                </div>
            </form>
            <ProductQTYHistoryModal
                showPopup={showPopup}
                handleClose={() => setShowPopup(!showPopup)}
                tableData={historyChange}
            />
            <ProductEditQTYDrawer
                showModal={showModal}
                handleClose={handleClose}
                editItem={selectedStore}
                product={product}
                setRefresh={setRefresh}
                Refresh={Refresh}
                selectedVariant={selectedVariant}
                setSelectedVariant={setSelectedVariant}
            />
        </div>
    );
});

const StyledCustom = styled.div`
    .topLongGrayBox {
        background: #f7f7f8;
        border-radius: 6px;
        padding: 20px;
        margin-bottom: 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
            margin: 0;
            color: #000;
            font-weight: 700;
        }
    }
    .customTransparentStockBtn {
        height: 42px;
        border: 0.5px solid #646464;
        border-radius: 4px;
        background: #fff;
        color: #646464;
        padding: 0 25px;
        display: flex;
        gap: 2px;
        align-items: center;
    }
    .customTransparentStockBtn:hover {
        border: 0.5px solid #646464 !important;
    }
    .aboveOfTableRow {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;
        .title {
            margin-bottom: 0 !important;
        }
    }
    .ant-input-group-addon {
        width: 70px;
    }
    .longGrayBox {
        background: #f7f7f8;
        border-radius: 6px;
        padding: 20px;
        p {
            margin: 0;
            font-size: 20px;
            font-weight: 800;
            color: #000;
        }
        margin-bottom: 20px;
        display: flex;
        place-items: center;
    }
    .productImage {
        width: 110px;
        height: 85px;
        border-radius: 6px;
        margin-right: 20px;
    }
    .row {
        display: flex;
        align-items: center;
    }
    .step {
        display: flex;
        align-items: center;
        p {
            margin: 0;
            color: #0263e0;
            font-weight: 700;
            font-size: 16px;
        }
        .whiteText {
            color: #a5a5a5;
        }
    }
    .blueCircle {
        background: #0263e0;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: flex;
        place-content: center;
        align-items: center;
        color: #fff;
        font-size: 12px;
        font-weight: 700;
        margin-right: 10px;
    }
    .grayCircle {
        background: #a5a5a5;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: flex;
        place-content: center;
        align-items: center;
        color: #fff;
        font-size: 12px;
        font-weight: 700;
        margin-right: 10px;
    }
    .arrowRight {
        margin: auto 20px;
    }
`;
