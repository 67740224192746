export const ImportCheckedIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
        >
            <g clipPath="url(#clip0_18601_49100)">
                <path
                    d="M16.9557 16.958C20.7985 13.1152 20.7985 6.88478 16.9557 3.04196C13.1128 -0.800861 6.8824 -0.800859 3.03958 3.04196C-0.803237 6.88478 -0.803237 13.1152 3.03958 16.958C6.8824 20.8009 13.1128 20.8009 16.9557 16.958Z"
                    fill="#6CD14E"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.17722 9.52713L5.95824 8.74591C6.07901 8.62504 6.23463 8.57212 6.40441 8.59463C6.57391 8.61684 6.71056 8.70815 6.79609 8.85638L8.22315 11.3282L13.5743 5.97679C13.6852 5.86593 13.8279 5.81751 13.9834 5.83795C14.139 5.85868 14.2641 5.94221 14.3424 6.07791L14.9151 7.06976C15.0577 7.31688 15.0179 7.6184 14.8164 7.81984L8.83934 13.797C8.57201 14.0642 8.22876 14.1809 7.85412 14.1316C7.47937 14.082 7.17809 13.8808 6.98894 13.5535L5.08195 10.2503C4.94434 10.0121 4.98263 9.72159 5.17726 9.52716L5.17722 9.52713Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_18601_49100">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
