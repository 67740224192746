import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { StyledNewDetail } from "@components";
import { PATH_CREATE_ADDON } from "@configs";
import { useNotify, showErrorMessage } from "@utils";
import { setLoading, selectAuth } from "@redux";
import { ModuleCreateProductAddon } from "src/Modules/productAddon";
import { IGetProductAddonsExample } from "@interfaces";
import { productAddonAPI } from "@api";

export const PageCreateProductAddon = () => {
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch();
    const history = useHistory();
    const { warning } = useNotify();
    const { t } = useTranslation();

    const [productAddon, setProductAddon] = useState<IGetProductAddonsExample>();
    const organization_id = useSelector(selectAuth).userInfo?.merchant?._id;
    const pathName = history.location.pathname;

    useEffect(() => {
        fetchNews();
    }, []);

    const fetchNews = async () => {
        dispatch(setLoading(true));
        if (pathName !== PATH_CREATE_ADDON) {
            try {
                const get = await productAddonAPI.getProductAddon({
                    organization_id: organization_id || "",
                    product_addon_id: id,
                });
                if (get.data.error === "0") {
                    setProductAddon(get.data.result);
                }
                dispatch(setLoading(false));
            } catch (err: any) {
                if (err.response) {
                    warning(showErrorMessage(err.response));
                    dispatch(setLoading(false));
                } else warning(t("message.not_found", { name: t("object._reward") }));
            } finally {
                dispatch(setLoading(false));
            }
        }
    };

    return (
        <StyledNewDetail>
            {<ModuleCreateProductAddon productAddon={productAddon} />}
        </StyledNewDetail>
    );
};
