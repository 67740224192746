import { ISvg } from "@interfaces";

export const ConversionIcon = (props: ISvg) => (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.823 22.25H18.6355C21.4063 22.25 22.698 21.01 22.698 18.35V15.65C22.698 12.99 21.4063 11.75 18.6355 11.75H15.823C13.0522 11.75 11.7605 12.99 11.7605 15.65V18.35C11.7605 21.01 13.0522 22.25 15.823 22.25ZM18.6355 13.25C20.5522 13.25 21.1355 13.81 21.1355 15.65V18.35C21.1355 20.19 20.5522 20.75 18.6355 20.75H15.823C13.9063 20.75 13.323 20.19 13.323 18.35V15.65C13.323 13.81 13.9063 13.25 15.823 13.25H18.6355Z" fill="white" />
        <path d="M8.37496 22.25C8.65621 22.25 8.91663 22.1 9.05205 21.87C9.18746 21.64 9.18746 21.35 9.04163 21.12L7.94788 19.37C7.72913 19.01 7.24996 18.9 6.87496 19.11C6.49996 19.32 6.38538 19.78 6.60413 20.14L6.88538 20.59C4.01038 19.94 1.85413 17.46 1.85413 14.5C1.85413 14.09 1.49996 13.75 1.07288 13.75C0.645797 13.75 0.29163 14.09 0.29163 14.5C0.302047 18.77 3.92705 22.25 8.37496 22.25Z" fill="white" />
        <path d="M21.9167 9.25C22.3438 9.25 22.698 8.91 22.698 8.5C22.698 4.23 19.073 0.75 14.6251 0.75C14.3438 0.75 14.0834 0.9 13.948 1.13C13.8126 1.36 13.8126 1.65 13.9584 1.88L15.0522 3.64C15.2709 3.99 15.7501 4.11 16.1251 3.89C16.4897 3.68 16.6147 3.22 16.3855 2.86L16.1042 2.41C18.9897 3.06 21.1355 5.54 21.1355 8.5C21.1355 8.91 21.4897 9.25 21.9167 9.25Z" fill="white" />
        <path d="M5.771 11.25C8.78141 11.25 11.2397 8.89 11.2397 6C11.2397 3.11 8.78141 0.75 5.771 0.75C2.76058 0.75 0.302246 3.11 0.302246 6C0.302246 8.89 2.76058 11.25 5.771 11.25ZM5.771 2.25C7.92725 2.25 9.67725 3.93 9.67725 6C9.67725 8.07 7.92725 9.75 5.771 9.75C3.61475 9.75 1.86475 8.07 1.86475 6C1.86475 3.93 3.61475 2.25 5.771 2.25Z" fill="white" />
    </svg>

);
