import { useTranslation } from "react-i18next";
import { StyledCancelButton, StyledSubmitButton } from "../shared";
import styled from "styled-components";
import { useNotify } from "@utils";
import { useEffect, useState } from "react";
import { Checkbox, Col, Row } from "antd";
import { IProduct } from "@interfaces";
import { useYup } from "@validations";
import { SharedBranchModal } from "../shared/Branch Modal";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth, setLoading } from "@redux";
import { productAddonAPI } from "@api";

interface IProps {
    showPopup: boolean;
    handleClose: () => void;
    addonList: any[];
    setAddonList: (list: any[]) => void;
    setFieldValue: any;
    values: IProduct;
}

export const ComponentProductAddonModal = (props: IProps) => {
    const { YupNews } = useYup();
    const dispatch = useDispatch();
    const { showPopup, handleClose, addonList, setAddonList, setFieldValue, values } = props;
    const { t } = useTranslation();
    const { success, error } = useNotify();
    const [allAddon, setAllAddon] = useState<any[]>([]);
    const [selectedList, setSelectedList] = useState<any[]>([]);

    const organization_id = useSelector(selectAuth).userInfo?.merchant?._id;
    useEffect(() => {
        if (showPopup) {
            fetchAddon();
            setSelectedList([...addonList]);
        }
    }, [showPopup]);

    // useEffect(() => {
    //     console.log(addonList);
    // }, [values.product_addon_list]);

    const fetchAddon = async () => {
        try {
            dispatch(setLoading(true));
            const res = await productAddonAPI.getProductAddons({
                organization_id: organization_id,
                xpage: 1,
                xlimit: 999,
                is_enable: true,
            });
            if (res.data.error === "0") {
                setAllAddon(res.data.result);
            } else {
                error(res.data.msg);
            }
        } catch (err: any) {
            if (err) {
                error(err.msg);
            } else error(t("message.create.fail"));
        } finally {
            dispatch(setLoading(false));
        }
        //
    };
    const handleSave = () => {
        setAddonList(selectedList);
        handleClose();
    };
    return (
        <SharedBranchModal
            showPopup3={showPopup}
            handleClose={handleClose}
            title={t("page.addon.select_addon")}
            width={750}
        >
            <br />
            <StyledModalAddon>
                {allAddon?.map((aItem: any, aIndex) => (
                    <div className="box" key={aIndex}>
                        <Row>
                            <Col span={2}>
                                <div className="checkbox">
                                    <Checkbox
                                        checked={
                                            selectedList?.some((x) =>
                                                x?.product_addon_id === aItem._id ? true : false
                                            ) || addonList.some((x) => x.addon_id === aItem._id)
                                        }
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                let arr = selectedList;
                                                arr.push({
                                                    addon_name: aItem.addon_name,
                                                    is_multi_choice: aItem.is_multi_choice,
                                                    is_require: aItem?.is_require,
                                                    is_selected: aItem?.is_selected,
                                                    product_option_list: aItem?.option_list,
                                                    product_addon_id: aItem?._id,
                                                });
                                                let temp: any = {};
                                                temp = {
                                                    addon_id: aItem._id,
                                                    product_option_list: [],
                                                };
                                                setFieldValue("product_addon_list", [
                                                    ...values.product_addon_list,
                                                    temp,
                                                ]);
                                                setSelectedList([...arr]);
                                            } else {
                                                setSelectedList((data) =>
                                                    data.filter(
                                                        (item) =>
                                                            item?.product_addon_id !== aItem._id
                                                    )
                                                );
                                                setFieldValue(
                                                    "product_addon_list",
                                                    values.product_addon_list.filter(
                                                        (x) => x.addon_id !== aItem._id
                                                    )
                                                );
                                                setAddonList(
                                                    addonList.filter(
                                                        (x) => x.addon_id !== aItem._id
                                                    )
                                                );
                                            }
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col span={22}>
                                <div className="addon-item">
                                    <p className="title">
                                        {aItem?.addon_name}
                                        {aItem?.is_require && <span>Request Select</span>}
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                ))}
            </StyledModalAddon>
            <div className="btn-layout" style={{ display: "flex", gap: "20px" }}>
                <StyledCancelButton
                    type="sub"
                    text={t("page.cancel")}
                    htmlType="button"
                    onClick={handleClose}
                />
                <StyledSubmitButton
                    type="default"
                    text={t("page.save")}
                    htmlType="button"
                    // disable={isSubmitting}
                    onClick={handleSave}
                    style={{ marginRight: 0 }}
                />
            </div>
        </SharedBranchModal>
    );
};

const StyledModalAddon = styled.div`
    /* max-height: 500px; */
    height: 400px;
    max-height: 400px;
    overflow-y: auto;
    margin-bottom: 50px;
    .box {
        .checkbox {
            padding: 20px;
        }
        .addon-item {
            width: 100%;
            background: #f7f7f8;
            border-radius: 6px;
            padding: 20px;
            margin-bottom: 30px;
            .title {
                font-weight: 800;
                font-size: 20px;
                margin: 0;
                span {
                    background-color: #ffd9de;
                    color: #6d1923;
                    padding: 5px 10px;
                    margin-left: 15px;
                    font-weight: 800;
                    font-size: 12px;
                    border-radius: 50px;
                }
            }
        }
    }
`;
