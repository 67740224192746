import { INavigationBox } from "@interfaces";
import { enumLanuage, navBox } from "@configs";
import {
    ContactIcon,
    GiftIcon,
    ManageIcon,
    PointIcon,
    SettingIcon,
    SuccessIcon,
} from "src/Components/icon/dashboard";
import styled from "styled-components";
interface IProps {
    data: INavigationBox;
}
const ActivityCard = (props: IProps) => {
    const { data } = props;
    const i18nextLng = window.localStorage.i18nextLng;
    const title = i18nextLng === enumLanuage.TH ? data.title_th : data.title;
    const text = i18nextLng === enumLanuage.TH ? data.text_th : data.text;

    return (
        <StyledCard onClick={() => window.open(data.link, "_self")}>
            <div className="image">
                {data.iconUrl === "manage" && <ManageIcon />}
                {data.iconUrl === "point" && <PointIcon />}
                {data.iconUrl === "success" && <SuccessIcon />}
                {data.iconUrl === "gift" && <GiftIcon />}
                {data.iconUrl === "contact" && <ContactIcon />}
                {data.iconUrl === "setting" && <SettingIcon />}
            </div>
            <h5>{title}</h5>
            <p className="visible-md">{text}</p>
        </StyledCard>
    );
};
const StyledCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    gap: 16px;
    /* height: 258px; */
    background: #ffffff;
    border-radius: 8px;
    &:hover {
        cursor: pointer;
    }
    .image {
        width: 100%;
        display: flex;
        justify-content: center;
        background: #f7f7f8;
        border-radius: 8px;
        padding: 16px;
        img {
            width: 108.5px;
            height: 114.72px;
        }
    }
    h5,
    p {
        margin: 0;
    }
    h5 {
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 27px;
        text-align: center;
        color: #000000;
    }
    p {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: #a5a5a5;
    }
    .visible-md {
        display: block;
    }
    @media screen and (max-width: ${(p) => p.theme.breakPoints.breakOnlyMobile}) {
        .visible-md {
            display: none;
        }
        text-align: center;
        .image {
            svg {
                height: 58px;
            }
        }
        h5 {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            color: #000000;
        }
        p {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            text-align: center;
            color: #a5a5a5;
        }
        .image {
            img {
                width: 82px;
                height: 57px;
            }
        }
    }
`;
