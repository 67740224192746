import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Switch, Col, Row, Menu, Dropdown, Button } from "antd";
import { MoreHorizontal } from "react-feather";
import { IDataexample, INewsCategory } from "@interfaces";
import { enumNewsType, PATH_STORE } from "@configs";
import { selectAuth } from "@redux";
import { useSelector } from "react-redux";
import { IconDrag } from "@components";
import { useNotify } from "@utils";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import styled from "styled-components";
import { storeAPI } from "@api";
interface IProps {
    data: any;
    itemJson: string;
    handleEdit: (status: boolean, data: IDataexample) => void;
    callbackGetList: () => void;
    index: number;
    newType?: enumNewsType;
    categories: INewsCategory[];
    stores: any[];
}

export const ComponentRowStore = (props: IProps) => {
    const { t } = useTranslation();
    const { success, error } = useNotify();
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const { data, handleEdit, callbackGetList, itemJson, newType, stores, index } = props;

    const { attributes, listeners, setNodeRef, transform } = useSortable({
        id: itemJson,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
    };

    const handleDelete = async (item: any) => {
        try {
            const res = await storeAPI.deleteStore({
                organization_code: orgCode,
                store_code: item,
            });
            if (res.data.error === "0") {
                callbackGetList();
                success(t("message.delete.success"));
            } else {
                console.log(res);
            }
        } catch (err: any) {
            if (err) {
                error(err.msg);
            } else error(t("message.delete.fail"));
        }
    };

    const menu = (
        <Menu>
            <Menu.Item key={1} onClick={() => handleDelete(data.store_code)}>
                <div className="duplicate-btn">{t("page.delete")}</div>
            </Menu.Item>
        </Menu>
    );
    const handleUpdateStatus = (e: boolean) => {
        handleEdit(e, data);
    };
    return (
        <Styles>
            <div className="table-element" ref={setNodeRef} style={style}>
                <div className="drag-icon" {...attributes} {...listeners}>
                    <IconDrag />
                </div>
                <div className="table-row">
                    <Row
                        style={{ height: "100%" }}
                        gutter={24}
                        type="flex"
                        align="middle"
                        justify="space-between"
                    >
                        <Col className="col-item" span={8}>
                            <Link to={`${PATH_STORE}/${data.store_code}`}>
                                <p className="news-title">
                                    {stores[index].store_name || t("page.empty_text")}
                                </p>
                            </Link>
                        </Col>
                        <Col className="col-item" span={4}>
                            <p>{stores[index].store_code || t("page.empty_text")}</p>
                        </Col>
                        <Col className="col-item" span={4}>
                            <p>{stores[index].pos_number || t("page.empty_text")}</p>
                        </Col>
                        <Col className="col-item visible-md" span={4}>
                            <Switch
                                checked={stores[index].is_enable}
                                onChange={handleUpdateStatus}
                            />
                        </Col>
                        <Col className="col-item" span={4} style={{ placeContent: "end" }}>
                            <Dropdown
                                //@ts-ignore
                                getPopupContainer={(trigger) => trigger.parentNode}
                                overlay={menu}
                                trigger={["click"]}
                                placement="bottomRight"
                            >
                                <Button style={{ border: "unset" }}>
                                    <MoreHorizontal />
                                </Button>
                            </Dropdown>
                        </Col>
                    </Row>
                </div>
            </div>
        </Styles>
    );
};
export const Styles = styled.div`
    .table-row {
        height: 60px;
    }
`;
