import { consumableAPI } from "@api";
import { IconMinusCircle, StyledSubmitButton } from "@components";
import { IConsumableExample, IConsumableStores, IConsumeListPayload } from "@interfaces";
import { selectAuth } from "@redux";
import { theme, useNotify } from "@utils";
import { Button, Input, Modal, Radio, Tabs } from "antd";
import Table, { ColumnProps } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";

interface IProps {
    showModal: boolean;
    handleClose: () => void;
    title: string;
    consumable: IConsumeListPayload;
    setConsumeList: any;
    consumeList: IConsumeListPayload[];
    consumeIndex: number;
}

export const PRTransferModal = (props: IProps) => {
    const { showModal, handleClose, title, consumable, setConsumeList, consumeList, consumeIndex } = props;
    const { t } = useTranslation();
    const { TabPane } = Tabs;
    const { error } = useNotify();
    const org_id = useSelector(selectAuth).userInfo?.merchant?._id;
    const [stores, setStores] = useState<IConsumableExample>();

    const columns: ColumnProps<IConsumableStores>[] = [
        {
            dataIndex: "store_name",
            title: `${t("page.branch")}`,
            key: "store_id",
            width: "30%",
            render: (text, record, index) => (
                <div style={{ placeItems: "center", display: "flex", fontSize: "18px" }}>
                    <Radio
                        checked={consumeList?.[consumeIndex].request_to_store_id === record.store_id ? true : false}
                        onChange={() => {
                            let arr = [...consumeList];
                            arr[consumeIndex]["request_to_store_id"] = record.store_id;
                            arr[consumeIndex]["store_name"] = record.store_name;

                            // const updatedConsumeList = consumeList.map((item) => {
                            //     if (consumable.consumable_id === item.consumable_id) {
                            //         return {
                            //             ...item,
                            //             request_to_store_id: record.store_id,
                            //             store_name: record.store_name,
                            //         };
                            //     }
                            //     return item;
                            // });
                            setConsumeList(arr);
                        }}
                    >
                        {text}
                    </Radio>
                </div>
            ),
        },
        {
            dataIndex: "diffFromPar",
            key: "diffFromPar",
            title: `${t("page.diff_from_par")}`,
            align: "right",
            render: (text, record, index) => <div className="quantity-columns">{"-"}</div>,
        },
        {
            dataIndex: "diffFromMax",
            key: "diffFromMax",
            title: `${t("page.diff_from_max")}`,
            align: "right",
            render: (text, record, index) => <div className="quantity-columns">{"-"}</div>,
        },
        {
            dataIndex: "restock_qty",
            key: "restock_qty",
            title: `${t("page.current_qty")}`,
            align: "right",
            render: (text, record, index) => <div className="quantity-columns">{text}</div>,
        },
    ];

    const getStores = async () => {
        try {
            const get = await consumableAPI.getConsumable({
                consumable_id: consumable.consumable_id,
                organization_id: org_id,
            });
            if (get.data.error === "0") {
                setStores(get.data.result);
            } else {
                error(get.data.msg);
            }
        } catch (err: any) {
            error(err.message);
        }
    };

    useEffect(() => {
        getStores();
    }, []);

    return (
        //@ts-ignore
        <StyledModal
            visible={showModal}
            onCancel={handleClose}
            title={title}
            footer={null}
            width={700}
            closable={true}
        >
            <Tabs defaultActiveKey="1">
                <TabPane tab={`All (${stores?.store_list?.length})`} key="1">
                    <Table
                        rowKey="id"
                        columns={columns}
                        dataSource={stores?.store_list}
                        key={"_id"}
                    />
                    <div className="btnRow">
                        <StyledSubmitButton
                            type="default"
                            text={t("page.save")}
                            onClick={handleClose}
                        />
                    </div>
                </TabPane>
                {/* <TabPane tab={"Above max"} key="2">
                    Content of Tab Pane 2
                </TabPane>
                <TabPane tab={"Above par"} key="3">
                    Content of Tab Pane 3
                </TabPane> */}
            </Tabs>
        </StyledModal>
    );
};
export const StyledModal = styled(Modal)`
    .ant-modal-title {
        padding-bottom: 20px;
    }
    .ant-modal-body {
        padding: 36px 0 0 0 !important;
    }
    .ant-tabs-bar {
        border: none;
    }
    .ant-tabs-nav .ant-tabs-tab-active {
        color: ${theme.colors.main};
    }
    .ant-tabs-ink-bar {
        height: 3px !important;
        background: ${theme.colors.main};
    }
    .ant-tabs-nav .ant-tabs-tab {
        margin: 0 16px 0 0;
        padding: 8px 0;
        text-align: center;
    }
    .ant-tabs-nav .ant-tabs-tab:hover {
        color: ${theme.colors.main};
    }
    .ant-table-body {
        border-radius: 6px;
        border: 0.5px solid #a5a5a5;
        background: #fff;
    }
    .ant-table-thead > tr:first-child > th:last-child {
        border-top-right-radius: 6px;
    }
    .ant-table-thead > tr:first-child > th:first-child {
        border-top-left-radius: 6px;
    }
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }
    .ant-pagination {
        display: flex;
        width: 100%;
        place-content: center;
    }
    .btnRow {
        width: 100%;
        display: flex;
        flex-direction: row;
        place-content: center;
        margin-top: 20px;
        button {
            width: 200px;
            margin: 0;
        }
    }
`;
