import { useState, useRef, useEffect } from "react";
import { StyledNewDetail } from "@components";
import { Row, Col, Pagination, Button } from "antd";
import { useTranslation } from "react-i18next";
import { PAGINATION, PAGE_START } from "@configs";
import { IPaginationNext, INews, IDataexample } from "@interfaces";
import { useDispatch, useSelector } from "react-redux";
import { getCategories, selectAuth, selectContact, selectOrg, setLoading } from "@redux";
import { useNotify } from "@utils";
import { useHistory, useParams } from "react-router";
import axios from "axios";
import styled from "styled-components";
import { orgApi, productAPI } from "@api";
import { ModuleEditQTYForm } from "@modules";
import { ColumnProps } from "antd/lib/table";

const INITAL_PAGE = {
    xpage: PAGE_START,
    xlimit: PAGINATION,
};

export const EditQTYPage = () => {
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const categories = useSelector(selectOrg).category;
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const [product, setProduct] = useState<any>();
    const [Refresh, setRefresh] = useState<boolean>();

    const getProduct = async () => {
        try {
            const res = await productAPI.getProduct({
                product_code: id,
                organization_code: orgCode,
            });
            setProduct(res.data.result);
            // console.log(res.data.result);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        getProduct();
    }, [Refresh]);

    return (
        <StyledNewDetail>
            {
                <ModuleEditQTYForm
                    product={product}
                    categories={categories}
                    setRefresh={setRefresh}
                    Refresh={Refresh}
                />
            }
        </StyledNewDetail>
    );
};
