export const IconPending = () => (
    <svg width="59" height="58" viewBox="0 0 59 58" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_8366_460244)">
            <rect
                x="4.5"
                y="4"
                width="42"
                height="42"
                rx="12"
                fill="url(#paint0_linear_8366_460244)"
                shapeRendering="crispEdges"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.5 35C19.98 35 15.5 30.53 15.5 25C15.5 19.48 19.98 15 25.5 15C31.03 15 35.5 19.48 35.5 25C35.5 30.53 31.03 35 25.5 35ZM28.69 28.71C28.81 28.78 28.94 28.82 29.08 28.82C29.33 28.82 29.58 28.69 29.72 28.45C29.93 28.1 29.82 27.64 29.46 27.42L25.9 25.3V20.68C25.9 20.26 25.56 19.93 25.15 19.93C24.74 19.93 24.4 20.26 24.4 20.68V25.73C24.4 25.99 24.54 26.23 24.77 26.37L28.69 28.71Z"
                fill="white"
            />
        </g>
        <defs>
            <filter
                id="filter0_d_8366_460244"
                x="0.5"
                y="0"
                width="58"
                height="58"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dx="4" dy="4" />
                <feGaussianBlur stdDeviation="4" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 1 0 0 0 0 0.752941 0 0 0 0 0.258824 0 0 0 0.16 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_8366_460244"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_8366_460244"
                    result="shape"
                />
            </filter>
            <linearGradient
                id="paint0_linear_8366_460244"
                x1="2.925"
                y1="-0.200001"
                x2="33.7014"
                y2="42.2787"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFE7B8" />
                <stop offset="1" stopColor="#FFC042" />
            </linearGradient>
        </defs>
    </svg>
);
