import { Badge, Layout, Menu } from "antd";

import { useLocation, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { MenuMode, MenuTheme } from "antd/lib/menu";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useState, useRef, useEffect } from "react";
import { capitalize, useNotify } from "@utils";
import {
    MessageIcon,
    IconService,
    IconBasket,
    IconCustomerService,
    IconHeartUser,
    IconPromotion,
    IconStaff,
    IconStore,
} from "@components";
import { selectApp, setMobileDrawer, useAppSelector, selectAuth } from "@redux";
import {
    enumPermission,
    PATH_PRODUCT,
    PATH_STORE_LIST,
    PATH_CATEGORY_LIST,
    PATH_CALCULATION_BILL,
    PATH_STOCK_LIST,
    PATH_RECIPE_LIST,
    PATH_INVENTORY_QTY,
    PATH_CHECK_STOCK,
    PATH_ADD_ON_PRODUCT_CATEGORY,
    PATH_PURCHASE_ORDER,
    PATH_GOOD_RECEIVE,
    PATH_SUPPLIER,
    PATH_RECIPE_ASSIGN,
    PATH_PROMOTION_LIST,
    PATH_WHOLESALE_CHANNEL,
    PATH_WASTE_RECORD,
    PATH_PURCHASE_REQUEST,
    PATH_PROMOTION_CATEGORY_LIST,
    PATH_INV_CATE,
    PATH_BRAND,
    PATH_REPORT_SETTING,
    PATH_UNIT,
    PATH_SETTING_CATEGORY_CHECKSTOCK,
    PATH_PROMOTION_GROUPS,
    PATH_BILL_PROMOTION,
    PATH_ROLES_PERMISSIONS,
    PATH_ROLES_PERMISSIONS_List,
    PATH_IMPORT_TABLE,
    PATH_RANKING,
    PATH_TRANSFER_REQUEST,
    PATH_EXPORT_TABLE,
} from "@configs";
import { IconProductManagement } from "../icon/storefront/menuIcons/IconProductManagement";
import { IconInventoryManagement } from "../icon/storefront/menuIcons/IconInventoryManagement";
import { IconStoreChannel } from "../icon/storefront/menuIcons/IconStoreChannel";
import { IconCategoryManagement } from "../icon/storefront/menuIcons/IconCategoryManagement";
import { IconOrganizationSettings } from "../icon/storefront/menuIcons/IconOrganizationSettings";
import { adminAPI } from "@api";
import { IGetAdminDataByTokenExample } from "@interfaces";
import { IconImportTable } from "../icon/storefront/menuIcons/IconImportTable";
interface IProps {
    sidebarTheme: MenuTheme;
    sidebarMode: MenuMode;
    sidebarIcons: boolean;
    collapsed: boolean;
}
interface ISideBarRoute {
    path?: string;
    name: string;
    icon: JSX.Element;
    permission?: string;
    children?: Array<{
        path: string;
        name: string;
        permission?: string;
        badge?: {
            value: string;
        };
    }>;
    badge?: {
        value: string;
    };
}

const { SubMenu } = Menu;
const { Sider } = Layout;
const getKey = (name: string, index: number) => {
    const string = `${name}-${index}`;
    const key = string.replace(" ", "-");
    return key.charAt(0).toLowerCase() + key.slice(1);
};

export const ComponentStorefront = ({
    sidebarTheme,
    sidebarMode,
    sidebarIcons,
    collapsed,
}: IProps) => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const userPermissionType = useSelector(selectAuth).userInfo?.storeIds;
    const { error } = useNotify();
    const [filteredRoutes, setFilteredRoutes] = useState<any[]>([]);
    const token: any = useSelector(selectAuth).token;
    const { adminPermission } = useSelector(selectAuth);

    const getUserData = () => {
        try {
            if (adminPermission) {
                const posRole = adminPermission.pos_role;
                if (Object.keys(posRole).length !== 0) {
                    const filteredRoutes = filterRoutes(storeFrontRoutes, posRole);
                    setFilteredRoutes(filteredRoutes);
                } else {
                    setFilteredRoutes(storeFrontRoutes);
                }
            }
        } catch (err: any) {
            error(err.message);
        }
    };

    const filterRoutes = (routes, userPermissions) => {
        return routes
            .map((route) => {
                if (route.children) {
                    const filteredChildren = filterRoutes(route.children, userPermissions);
                    if (filteredChildren.length > 0) {
                        return { ...route, children: filteredChildren };
                    } else {
                        return null;
                    }
                } else {
                    const permissionKey = route.permission;
                    // Add this line to check routePermission
                    const routePermission = getNestedPermission(userPermissions, permissionKey);
                    // Modify this condition to include the routePermission check
                    if (
                        (routePermission !== null && routePermission !== false) ||
                        checkStorePermission(userPermissions, route)
                    ) {
                        return route;
                    } else {
                        return null;
                    }
                }
            })
            .filter(Boolean);
    };

    // Add this function to check store_permission
    const checkStorePermission = (userPermissions, route) => {
        const storePermissionKey = route.permission;
        const storePermission = getNestedPermission(userPermissions, storePermissionKey);
        return storePermission && Object.values(storePermission).some((value) => value === true);
    };

    const getNestedPermission = (permissions, permissionKey) => {
        const keys = permissionKey.split(".");
        let currentPermissions = permissions;
        for (const key of keys) {
            if (currentPermissions && key in currentPermissions) {
                currentPermissions = currentPermissions[key];
            } else {
                // Construct the nested key dynamically
                const nestedKey = Object.keys(currentPermissions).find(
                    (k) => currentPermissions[k] && currentPermissions[k][key] !== undefined
                );
                if (!nestedKey) {
                    return undefined; // No nested object found
                }
                currentPermissions = currentPermissions[nestedKey];
                const nestedPermission = currentPermissions[key];
                if (nestedPermission !== undefined && nestedPermission !== null) {
                    return nestedPermission;
                } else {
                    return undefined;
                }
            }
        }
        return currentPermissions;
    };

    useEffect(() => {
        if (adminPermission) {
            getUserData();
        }
    }, []);

    const storeFrontRoutes: ISideBarRoute[] =
        userPermissionType?.length === 0
            ? [
                {
                    name: "product_management",
                    icon: <IconProductManagement size={24} color={"#F22F46"} />,
                    children: [
                        {
                            path: PATH_PRODUCT,
                            name: "create_manage_product",
                            permission: "can_create_manage_product",
                        },
                        {
                            path: PATH_RECIPE_ASSIGN,
                            name: "assign_inventory",
                            permission: "can_assign_invertory",
                        },
                        {
                            path: PATH_ADD_ON_PRODUCT_CATEGORY,
                            name: "addon",
                            permission: "can_addon",
                        },
                        {
                            path: PATH_PROMOTION_LIST,
                            name: "promotion",
                            permission: "can_product_promotion",
                        },
                        {
                            path: PATH_BILL_PROMOTION,
                            name: "bill_promotion",
                            permission: "can_bill_promotion",
                        },
                    ],
                },
                {
                    name: "inventory_management",
                    icon: <IconInventoryManagement size={24} color={"#F22F46"} />,
                    children: [
                        {
                            path: PATH_STOCK_LIST,
                            name: "inventory_list",
                            permission: "can_inventory_list",
                        },
                        {
                            path: PATH_SUPPLIER,
                            name: "supplier_list",
                            permission: "can_supplier_list",
                        },
                        {
                            path: PATH_INVENTORY_QTY,
                            name: "edit_inventory_qty",
                            permission: "can_edit_inventory_qty",
                        },
                        {
                            path: PATH_CHECK_STOCK,
                            name: "check_stock",
                            permission: "can_check_stock",
                        },
                        {
                            path: PATH_WASTE_RECORD,
                            name: "waste_record",
                            permission: "can_waste_record",
                        },
                        {
                            path: PATH_PURCHASE_REQUEST,
                            name: "purchase_request_lists",
                            permission: "can_inventory_require",
                        },
                        {
                            path: PATH_TRANSFER_REQUEST,
                            name: "transfer_request_lists",
                            permission: "can_inventory_require",
                        },
                        {
                            path: PATH_PURCHASE_ORDER,
                            name: "purchase_order_lists",
                            permission: "can_purchase_order",
                        },
                        {
                            path: PATH_GOOD_RECEIVE,
                            name: "good_receive_lists",
                            permission: "can_goods_receive",
                        },
                    ],
                },
                {
                    name: "store_channel",
                    icon: <IconStoreChannel size={24} color={"#F22F46"} />,
                    children: [
                        {
                            path: PATH_STORE_LIST,
                            name: "store_settings",
                            permission: "store_permission",
                        },
                        {
                            path: PATH_WHOLESALE_CHANNEL,
                            name: "wholesale_channel",
                            permission: "can_channel",
                        },
                    ],
                },
                {
                    name: "category_management",
                    icon: <IconCategoryManagement size={24} color={"#F22F46"} />,
                    children: [
                        {
                            path: PATH_CATEGORY_LIST,
                            name: "product_category",
                            permission: "can_product_category",
                        },
                        {
                            path: PATH_INV_CATE,
                            name: "inv_cate",
                            permission: "can_inventory_category",
                        },
                        {
                            path: PATH_UNIT,
                            name: "inventory_units",
                            permission: "can_inventory_unit",
                        },
                        {
                            path: PATH_PROMOTION_CATEGORY_LIST,
                            name: "promotion_category",
                            permission: "can_promotion_category",
                        },
                        {
                            path: PATH_PROMOTION_GROUPS,
                            name: "product_collections",
                            permission: "can_promotion_group",
                        },
                        {
                            path: PATH_BRAND,
                            name: "brand",
                            permission: "can_brand",
                        },
                        {
                            path: PATH_SETTING_CATEGORY_CHECKSTOCK,
                            name: "check_stock_list",
                            permission: "can_check_stock_list",
                        },
                    ],
                },
                {
                    name: "organization_settings",
                    icon: <IconOrganizationSettings size={24} color={"#F22F46"} />,
                    path: PATH_CALCULATION_BILL,
                    children: [
                        {
                            path: PATH_ROLES_PERMISSIONS_List,
                            name: "roles_permissions",
                            permission: "can_role_permission",
                        },
                        {
                            path: PATH_CALCULATION_BILL,
                            name: "organization_settings",
                            permission: "can_pos_organization_setting",
                        },
                        {
                            path: PATH_RANKING,
                            name: "ranking",
                            permission: "can_create_manage_product",
                        },
                    ],
                },
                {
                    name: "import_export_table",
                    icon: <IconImportTable size={24} color={"#F22F46"} />,
                    children: [
                        {
                            path: PATH_IMPORT_TABLE,
                            name: "import_table",
                            permission: "can_import_pos_data",
                        },
                        {
                            path: PATH_EXPORT_TABLE,
                            name: "export_table",
                            permission: "can_import_pos_data",
                        },
                    ],
                },
            ]
            : [
                {
                    name: "store_management",
                    icon: <IconStaff size={24} color={"#F22F46"} />,
                    children: [
                        {
                            path: PATH_STORE_LIST,
                            name: "store_list",
                            permission: "store_permission",
                        },
                    ],
                },
            ];

    const sidebarRef = useRef();
    const [openKeys, setOpenKeys] = useState<string[]>([]);
    //redux state
    const app = useAppSelector(selectApp);

    const pathname = location.pathname;
    const handleOpenKey = (e) => {
        setOpenKeys([...openKeys, e.key]);
    };
    const switchRoute = (path: string, permission?: string, index?: string) => {
        if (app.mobile) dispatch(setMobileDrawer(!app.mobileDrawer));
        if (permission) {
            history.push(path);
        } else {
            history.push(path);
        }
    };
    const badgeTemplate = (badge: { value: string }) => <Badge count={badge.value} />;
    return (
        <Menu
            theme={sidebarTheme}
            className="border-0 scroll-y "
            style={{ flex: 1 }}
            mode={sidebarMode}
            defaultOpenKeys={["0", "1", "2", "3", "4", "5"]}
            onClick={handleOpenKey}
        >
            {filteredRoutes.map((route, index) => {
                if (!route.children) {
                    return (
                        <Menu.Item
                            key={index}
                            className={
                                pathname === route.path
                                    ? `ant-menu-item-selected menu-after ${route.path}`
                                    : `menu-after ${route.path}`
                            }
                            onClick={() => switchRoute(route.path || "/", "", index.toString())}
                        >
                            <div>
                                {sidebarIcons && (
                                    <span style={{ marginBottom: 5 }} className="anticon">
                                        {route.icon}
                                    </span>
                                )}
                                <span
                                    className="mr-2 menu-item-text"
                                    style={{
                                        color: "black",
                                        fontWeight: 700,
                                        fontSize: 16,
                                    }}
                                >
                                    {capitalize(t("page.sidebar." + route.name))}
                                </span>
                                {route.badge && badgeTemplate(route.badge)}
                            </div>
                        </Menu.Item>
                    );
                } else {
                    return (
                        <SubMenu
                            className={`menu-after ${route.name}`}
                            key={index}
                            onTitleClick={() => {
                                if (route.path && route.permission) {
                                    switchRoute(
                                        route.path,
                                        route.permission || "",
                                        index.toString()
                                    );
                                }
                            }}
                            title={
                                <span>
                                    {sidebarIcons && (
                                        <span className="anticon" style={{ marginBottom: 5 }}>
                                            {route.icon}
                                        </span>
                                    )}
                                    <span
                                        className="menu-item-text"
                                        style={{
                                            color: "black",
                                            fontWeight: 700,
                                            fontSize: 16,
                                        }}
                                    >
                                        {capitalize(t("page.sidebar." + route.name))}
                                    </span>
                                    {route.badge && badgeTemplate(route.badge)}
                                </span>
                            }
                        >
                            {route.children.map((subitem, secondIndex) => (
                                <Menu.Item
                                    key={getKey(subitem.name, secondIndex)}
                                    style={{ paddingLeft: 60 }}
                                    className={
                                        pathname === subitem.path
                                            ? `ant-menu-item-selected sub-item-text`
                                            : ` sub-item-text`
                                    }
                                    onClick={() =>
                                        switchRoute(
                                            subitem.path,
                                            subitem.permission || "",
                                            index.toString()
                                        )
                                    }
                                >
                                    <div
                                        className={subitem.path}
                                        //@ts-ignore
                                        ref={pathname.includes(subitem.path) ? sidebarRef : null}
                                    >
                                        <span
                                            className="mr-auto"
                                            style={
                                                subitem.path && pathname.includes(subitem.path)
                                                    ? {
                                                        color: "black",
                                                        fontSize: 16,
                                                        fontWeight: 700,
                                                    }
                                                    : { color: "#646464", fontSize: 16 }
                                            }
                                        >
                                            {capitalize(t("page.sidebar." + subitem.name))}
                                        </span>
                                        {subitem.badge && badgeTemplate(subitem.badge)}
                                    </div>
                                </Menu.Item>
                            ))}
                        </SubMenu>
                    );
                }
            })}
        </Menu>
    );
};
