import { Layout } from "antd";
import { selectApp, useAppSelector, setMobileDrawer } from "@redux";
import {
    PATH_STOREFRONT,
    PATH_SERVICES,
    PATH_SERVICES_CATEGORY,
    PATH_PRODUCT,
    PATH_PRODUCT_ORDERS,
    PATH_PRODUCT_SETTINGS,
    PATH_PROMOTION,
    PATH_STORE,
    PATH_STORE_ACCOUNT_SETTINGS,
    PATH_STORE_APPOINTMENT_SETTINGS,
    PATH_STORE_PAYMENT_SETTINGS,
    PATH_STAFF,
    PATH_STAFF_SCHEDULING,
    PATH_STAFF_TYPES,
    PATH_CUSTOMER_SERVICES,
    PATH_SERVICES_CREATE,
    PATH_PRODUCT_CREATE,
    enumDrawerPlacement,
} from "@configs";
import styled from "styled-components";
import { SharedComponentDrawer, StyledSidebar } from "@components";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { ComponentStorefront } from "./Storefront";
const { Sider } = Layout;
export const ComponentMobileSidebar = () => {
    const app = useAppSelector(selectApp);
    const dispatch = useDispatch();
    const location = useLocation();
    const pathname = location.pathname;
    const isStorefrontRoutes = (route: string) =>
        route === PATH_STOREFRONT ||
        route === PATH_SERVICES ||
        route === PATH_SERVICES_CREATE ||
        route === PATH_SERVICES_CATEGORY ||
        route === PATH_PRODUCT ||
        route === PATH_PRODUCT_CREATE ||
        route === PATH_PRODUCT_ORDERS ||
        route === PATH_PRODUCT_SETTINGS ||
        route === PATH_PROMOTION ||
        route === PATH_STORE ||
        route === PATH_STORE_ACCOUNT_SETTINGS ||
        route === PATH_STORE_APPOINTMENT_SETTINGS ||
        route === PATH_STORE_PAYMENT_SETTINGS ||
        route === PATH_STAFF ||
        route === PATH_STAFF_SCHEDULING ||
        route === PATH_STAFF_TYPES ||
        route === PATH_CUSTOMER_SERVICES ||
        route.includes(PATH_STOREFRONT);

    return (
        <SharedComponentDrawer
            title={""}
            placement={enumDrawerPlacement.LEFT}
            onClose={() => dispatch(setMobileDrawer(!app.mobileDrawer))}
            visible={app.mobileDrawer}
            width={275}
            // visible={true}
            // className="sidebar-drawer"
        >
            <StyledSidebar>
                <div className="side-bar-menu">
                    <StyledSidebar>
                        <Sider width={240} className={`bg-light`} theme={"light"} collapsed={false}>
                            {isStorefrontRoutes(pathname) && (
                                <ComponentStorefront
                                    sidebarTheme={"light"}
                                    sidebarMode={"inline"}
                                    sidebarIcons={true}
                                    collapsed={false}
                                />
                            )}
                        </Sider>
                    </StyledSidebar>
                    <StyledVersion>
                        <p>Rocket Merchant Version</p>
                        <p>v.1.38.3</p>
                    </StyledVersion>
                </div>
            </StyledSidebar>
        </SharedComponentDrawer>
    );
};
const StyledVersion = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 20px 39px 10px 39px;
    p {
        margin: 0;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #a5a5a5;
    }
`;
