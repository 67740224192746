export const PrintIconCreateProduct = () => (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M18.5 13.5H19C19.9428 13.5 20.4142 13.5 20.7071 13.2071C21 12.9142 21 12.4428 21 11.5V10.5C21 8.61438 21 7.67157 20.4142 7.08579C19.8284 6.5 18.8856 6.5 17 6.5H8C6.11438 6.5 5.17157 6.5 4.58579 7.08579C4 7.67157 4 8.61438 4 10.5V12.5C4 12.9714 4 13.2071 4.14645 13.3536C4.29289 13.5 4.5286 13.5 5 13.5H6.5"
            stroke="black"
            strokeWidth="1.3"
        />
        <path
            d="M7 19.8063L7 11.5C7 10.5572 7 10.0858 7.29289 9.79289C7.58579 9.5 8.05719 9.5 9 9.5L16 9.5C16.9428 9.5 17.4142 9.5 17.7071 9.79289C18 10.0858 18 10.5572 18 11.5L18 19.8063C18 20.1228 18 20.2811 17.8962 20.356C17.7924 20.4308 17.6422 20.3807 17.3419 20.2806L15.1738 19.5579C15.0878 19.5293 15.0448 19.5149 15.0005 19.5162C14.9561 19.5175 14.9141 19.5344 14.8299 19.568L12.6857 20.4257C12.594 20.4624 12.5481 20.4807 12.5 20.4807C12.4519 20.4807 12.406 20.4624 12.3143 20.4257L10.1701 19.568C10.0859 19.5344 10.0439 19.5175 9.99952 19.5162C9.95519 19.5149 9.91221 19.5293 9.82625 19.5579L7.65811 20.2806C7.3578 20.3807 7.20764 20.4308 7.10382 20.356C7 20.2811 7 20.1228 7 19.8063Z"
            stroke="black"
            strokeWidth="1.3"
        />
        <path d="M10 13.5L14 13.5" stroke="black" strokeWidth="1.3" strokeLinecap="round" />
        <path d="M10 16.5L15 16.5" stroke="black" strokeWidth="1.3" strokeLinecap="round" />
        <path
            d="M18 6.5V6.1C18 4.40294 18 3.55442 17.4728 3.02721C16.9456 2.5 16.0971 2.5 14.4 2.5H10.6C8.90294 2.5 8.05442 2.5 7.52721 3.02721C7 3.55442 7 4.40294 7 6.1V6.5"
            stroke="black"
            strokeWidth="1.3"
        />
    </svg>
);
