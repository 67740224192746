export const EmptySuccessCheckBox = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
        >
            <g clip-path="url(#clip0_18601_49113)">
                <path
                    d="M16.6021 3.39551C20.2497 7.04307 20.2497 12.9569 16.6021 16.6045C12.9546 20.252 7.04069 20.252 3.39314 16.6045C-0.254421 12.9569 -0.254421 7.04307 3.39314 3.39551C7.04069 -0.252044 12.9546 -0.252045 16.6021 3.39551Z"
                    stroke="#E1E1E1"
                />
            </g>
            <defs>
                <clipPath id="clip0_18601_49113">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
