import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Switch, Col, Row, Avatar, Menu, Dropdown, Button } from "antd";
import { MoreHorizontal } from "react-feather";

import { IConsumableExample, IDataexample, INewsCategory } from "@interfaces";
import {
    enumNewsType,
    PATH_PRODUCT_CREATE,
    PATH_SERVICES_CREATE,
    PATH_STOCK_LIST,
    PATH_STORE,
    PATH_STORE_CREATE,
} from "@configs";
import { selectAuth, store } from "@redux";
import { useSelector } from "react-redux";
import { IconDrag, StyledTableButtonTop } from "@components";
import { useNotify } from "@utils";
import React, { useEffect, useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import styled from "styled-components";
import axios from "axios";
import { consumableAPI, storeAPI } from "@api";
import { useHistory } from "react-router";
import { ProductEditQTYDrawer } from "../product/ProductEditQTYDrawer";
import { ComponentEditProInvDrawer, ComponentEditStockDrawer } from "../stock";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";

interface IProps {
    data: any;
    itemJson: string;
    handleEdit?: (status: boolean, data: IDataexample) => void;
    callbackGetList: () => void;
    index: number;
    newType?: enumNewsType;
    categories: INewsCategory[];
    stores: IConsumableExample[];
    selectedStore?: string;
    Refresh?: boolean;
    setRefresh?: (change: boolean) => void;
}

const INITEDIT = {
    oldQty: 0,
    unit_name: "",
    store_id: "",
    consumable_id: "",
    price: 0,
};

export const ComponentRowEditInvProQTY = (props: IProps) => {
    const history = useHistory();

    //hooks
    const { t } = useTranslation();
    const { success, error } = useNotify();
    const organization_id = useSelector(selectAuth).auth?.organization_data?._id;
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const userPermissionType = useSelector(selectAuth).userInfo?.storeIds;
    //props
    const {
        data,
        handleEdit,
        callbackGetList,
        itemJson,
        newType,
        stores,
        index,
        selectedStore,
        Refresh,
        setRefresh,
        categories,
    } = props;
    const [showModal, setShowModal] = useState(false);
    const [edtiValue, setEditValue] = useState<any>(INITEDIT);
    const [focusId, setFocusId] = useState<string>("");
    const [isShow, setIsShow] = useState<boolean>(true);
    // const history = useHistory();

    const { attributes, listeners, setNodeRef, transform } = useSortable({
        id: itemJson,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
    };
    const handleDelete = async (item: any) => {
        try {
            const res = await consumableAPI.deleteConsumable({
                organization_id: organization_id,
                consumable_id: item,
            });
            if (res.data.error === "0") {
                callbackGetList();
                success(t("message.delete.success"));
            } else {
                error(res.data.msg);
            }
        } catch (err: any) {
            if (err) {
                error(err.msg);
            } else error(t("message.delete.fail"));
        }
    };

    const menu = (
        <Menu>
            <Menu.Item key={1} onClick={() => handleDelete(focusId)}>
                <div className="duplicate-btn">{t("page.delete")}</div>
            </Menu.Item>
        </Menu>
    );
    const clickEdit = (index: number) => {
        const payload = {
            oldQty: data?.variant_list[index]?.auto_consumable_qty,
            unit_name: data?.variant_list[index]?.unit_name,
            store_id: selectedStore,
            consumable_id: data?.variant_list[index]?.consumable_id,
            price: data?.variant_list[index]?.consumable_cost,
        };
        setEditValue(payload);
        setShowModal(true);
    };

    return (
        <StylesEditInventoryQTY>
            <div className="table-element first" ref={setNodeRef} style={style}>
                <div className="drag-icon hide" {...attributes} {...listeners}>
                    <IconDrag />
                </div>
                <div
                    className={
                        "table-row " +
                        (data?.variant_list?.length === 1 ? " one-row " : "") +
                        (!isShow && " one-row ")
                    }
                >
                    <Row
                        style={{ height: "100%" }}
                        gutter={24}
                        type="flex"
                        align="middle"
                        justify="space-between"
                    >
                        <Col className="col-item" span={6}>
                            <p className="news-title">
                                {data?.variant_list?.length > 1 ? (
                                    <>
                                        {isShow ? (
                                            <CaretUpOutlined onClick={() => setIsShow(false)} />

                                        ) : (
                                            <CaretDownOutlined onClick={() => setIsShow(true)} />
                                        )}
                                    </>
                                ) : (
                                    <span className="no-arrow"> </span>
                                )}

                            </p>
                            <p className="news-title">{data?.name}</p>
                        </Col>
                        <Col className="col-item" span={4} style={{ placeContent: "center" }}>
                            <p></p>
                        </Col>
                        <Col className="col-item" span={4} style={{ placeContent: "start" }}>
                            <p>{data?.category_name}</p>
                        </Col>
                        <Col className="col-item" span={3} style={{ placeContent: "center" }}>
                            <p>
                                {data?.variant_list?.length === 1 &&
                                    data?.variant_list[0]?.auto_consumable_qty}
                            </p>
                        </Col>
                        <Col className="col-item" span={4} style={{ placeContent: "center" }}>
                            {data?.variant_list?.length === 1 && (
                                <StyledTableButtonTop
                                    type="danger"
                                    size="default"
                                    text={t("page.edit")}
                                    onClick={() => {
                                        // setShowModal(true);
                                        clickEdit(0);
                                    }}
                                    className="add-btn"
                                />
                            )}
                        </Col>
                        <Col className="col-item" span={3} style={{ placeContent: "end" }}>
                            <Dropdown
                                //@ts-ignore
                                getPopupContainer={(trigger) => trigger.parentNode}
                                overlay={menu}
                                trigger={["click"]}
                                placement="bottomRight"
                            >
                                <Button
                                    style={{ border: "unset" }}
                                    onClick={() => setFocusId(data?.variant_list[0]?.consumable_id)}
                                >
                                    <MoreHorizontal />
                                </Button>
                            </Dropdown>
                        </Col>
                    </Row>
                </div>
            </div>
            {data?.variant_list?.length > 1 && isShow && (
                <div className="sub-table-element" ref={setNodeRef} style={style}>
                    {data?.variant_list?.map((vItem: any, vIndex: number) => {
                        return (
                            <div className="table-row sub-table-row">
                                <Row
                                    style={{ height: "100%" }}
                                    gutter={24}
                                    type="flex"
                                    align="middle"
                                    justify="space-between"
                                >
                                    <Col className="col-item" span={6}>
                                        <p className="news-title sub-title">
                                            {vItem?.variant_name}
                                        </p>
                                    </Col>
                                    <Col
                                        className="col-item"
                                        span={4}
                                        style={{ placeContent: "left" }}
                                    >
                                        <p>{vItem?.variant_name}</p>
                                    </Col>
                                    <Col
                                        className="col-item"
                                        span={4}
                                        style={{ placeContent: "start" }}
                                    >
                                        <p>{data?.category_name}</p>
                                    </Col>
                                    <Col
                                        className="col-item"
                                        span={3}
                                        style={{ placeContent: "center" }}
                                    >
                                        <p>{vItem?.auto_consumable_qty}</p>
                                    </Col>
                                    <Col
                                        className="col-item"
                                        span={4}
                                        style={{ placeContent: "center" }}
                                    >
                                        <StyledTableButtonTop
                                            type="danger"
                                            size="default"
                                            text={t("page.edit")}
                                            onClick={() => {
                                                clickEdit(vIndex);
                                                // setShowModal(true);
                                            }}
                                            className="add-btn"
                                        />
                                    </Col>
                                    <Col
                                        className="col-item"
                                        span={3}
                                        style={{ placeContent: "end" }}
                                    >
                                        <Dropdown
                                            //@ts-ignore
                                            getPopupContainer={(trigger) => trigger.parentNode}
                                            overlay={menu}
                                            trigger={["click"]}
                                            placement="bottomRight"
                                        >
                                            <Button
                                                style={{ border: "unset" }}
                                                onClick={() =>
                                                    setFocusId(
                                                        data?.variant_list[vIndex]?.consumable_id
                                                    )
                                                }
                                            >
                                                <MoreHorizontal />
                                            </Button>
                                        </Dropdown>
                                    </Col>
                                </Row>
                            </div>
                        );
                    })}
                </div>
            )}

            <ComponentEditProInvDrawer
                showModal={showModal}
                editItem={stores[index]?.consumable_data}
                stock={stores[index]}
                setRefresh={setRefresh}
                Refresh={Refresh}
                Unit={stores[index]?.unit_name}
                handleClose={() => setShowModal(!showModal)}
                itemDefault={edtiValue}
            />
        </StylesEditInventoryQTY>
    );
};
const StylesEditInventoryQTY = styled.div`
    .table-element {
        margin: 0px;
    }
    .table-row {
        height: 60px;
    }
    .one-row {
        margin-bottom: 22px;
    }
    .no-arrow {
        margin-left: 16px;
    }
    .sub-table-row {
        /* border-radius: 0px 0px 6px 6px; */
        border-radius: 0px;
    }
    .sub-title {
        padding-left: 25px;
    }
`;
