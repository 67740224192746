import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { StyledNewDetail } from "@components";
import { INews } from "@interfaces";
import {
    PATH_CREATE_NEWS,
    PATH_NEWS,
    PATH_PRODUCT_CREATE,
    PATH_PURCHASE_ORDER_CREATE,
    PATH_SUPPLIER_CREATE,
} from "@configs";
import { ModulePOForm, ModuleProductForm, ModuleSupplierForm } from "@modules";
import { useNotify, showErrorMessage } from "@utils";
import { setLoading, selectOrg, selectAuth } from "@redux";
import axios from "axios";
import { productAPI, supplierAPI } from "@api";

export const PageSupplierCreate = () => {
    const { id } = useParams<{ id: string }>();
    const org_id = useSelector(selectAuth).userInfo?.merchant?._id;
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const categories = useSelector(selectOrg).category;
    const dispatch = useDispatch();
    const history = useHistory();
    const { warning } = useNotify();
    const { t } = useTranslation();
    // page state
    const [sup, setSup] = useState<any>();
    // variable
    const pathName = history.location.pathname;

    useEffect(() => {
        fetchSupplier();
    }, []);

    const fetchSupplier = async () => {
        if (pathName !== PATH_SUPPLIER_CREATE) {
            try {
                dispatch(setLoading(true));
                const resSup = await supplierAPI.getSup({
                    organization_id: org_id || "",
                    supplier_id: id,
                });
                // const getAllProduct = await productAPI.getProduct({
                //     organization_code: orgCode,
                //     product_code: id,
                // });
                if (resSup.data.error === "0") {
                    setSup(resSup.data.result);
                } else {
                    warning(resSup.data.msg);
                }
            } catch (err: any) {
                if (err.response) {
                    warning(showErrorMessage(err.response));
                    dispatch(setLoading(false));
                } else warning(t("message.not_found", { name: t("object._reward") }));
            } finally {
                dispatch(setLoading(false));
            }
        }
    };

    return (
        <StyledNewDetail>
            {<ModuleSupplierForm supplier={sup} />}
            {/* {<ModuleProductForm product={product} categories={categories} />} */}
        </StyledNewDetail>
    );
};
