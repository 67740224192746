import {
    IOrganizationId,
    INewsCategory,
    IConplaintCategory,
    IDivision,
    IComplaint,
    IGetProductCategory,
} from "@interfaces";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState, selectAuth } from ".";
import { orgApi } from "@api";
import axios from "axios";
import { useSelector } from "react-redux";

interface IInitialState {
    category: INewsCategory[];
    isLoading: boolean;
    conplaintCategory: IConplaintCategory[];
    division: IDivision[];
    complainTypes: IComplaint[];
}

const initialState: IInitialState = {
    category: [],
    conplaintCategory: [],
    isLoading: false,
    division: [],
    complainTypes: [],
};

export const getCategories = createAsyncThunk<INewsCategory[], IGetProductCategory>(
    "org/category",
    async (values: IGetProductCategory, { rejectWithValue }) => {
        try {
            const res = await orgApi.getCat(values);
            let tempCat: any = [];
            tempCat = res.data.result;
            for (let i = 0; i < tempCat.length; i++) {
                tempCat[i].category_name = res.data.result[i].category;
                delete res.data.result[i].category;
            }

            return res.data.result as INewsCategory[];
        } catch (err: any) {
            return rejectWithValue(err);
        }
    }
);
export const getConplaintCategories = createAsyncThunk<IConplaintCategory[], IOrganizationId>(
    "org/conplaintCategory",
    async (values: IOrganizationId, { rejectWithValue }) => {
        try {
            const res = await orgApi.getConplainCat(values);
            return res.data.result as IConplaintCategory[];
        } catch (err: any) {
            return rejectWithValue(err);
        }
    }
);
export const getDivisions = createAsyncThunk<IDivision[], IOrganizationId>(
    "org/division",
    async (values: IOrganizationId, { rejectWithValue }) => {
        try {
            const res = await orgApi.getDivision(values);
            return res.data.result as IDivision[];
        } catch (err: any) {
            return rejectWithValue(err);
        }
    }
);
export const getComplaintType = createAsyncThunk<IComplaint[], IOrganizationId>(
    "org/complaintType",
    async (values: IOrganizationId, { rejectWithValue }) => {
        try {
            const res = await orgApi.getComplaintStatus(values);
            return res.data.result as IComplaint[];
        } catch (err: any) {
            return rejectWithValue(err);
        }
    }
);
const organization = createSlice({
    name: "organization",
    initialState,
    reducers: {
        addCat: (state, action: PayloadAction<number>) => {
            state.isLoading = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCategories.fulfilled, (state, action: { payload: INewsCategory[] }) => {
            state.category = action.payload;
            state.isLoading = false;
        });

        builder.addCase(getCategories.pending, (state) => {
            state.isLoading = true;
        });

        builder.addCase(getCategories.rejected, (state) => {
            state.category = [];
            state.isLoading = false;
        });
        builder.addCase(
            getConplaintCategories.fulfilled,
            (state, action: { payload: IConplaintCategory[] }) => {
                state.conplaintCategory = action.payload;
                state.isLoading = false;
            }
        );

        builder.addCase(getConplaintCategories.pending, (state) => {
            state.isLoading = true;
        });

        builder.addCase(getConplaintCategories.rejected, (state) => {
            state.conplaintCategory = [];
            state.isLoading = false;
        });
        builder.addCase(getDivisions.fulfilled, (state, action: { payload: IDivision[] }) => {
            state.division = action.payload;
            state.isLoading = false;
        });

        builder.addCase(getDivisions.pending, (state) => {
            state.isLoading = true;
        });

        builder.addCase(getDivisions.rejected, (state) => {
            state.division = [];
            state.isLoading = false;
        });
        builder.addCase(getComplaintType.fulfilled, (state, action: { payload: IComplaint[] }) => {
            state.complainTypes = action.payload;
            state.isLoading = false;
        });

        builder.addCase(getComplaintType.pending, (state) => {
            state.isLoading = true;
        });

        builder.addCase(getComplaintType.rejected, (state) => {
            state.complainTypes = [];
            state.isLoading = false;
        });
    },
});

export const selectOrg = (state: RootState) => state.org;

export const { addCat } = organization.actions;

export default organization.reducer;
