import { useState, useRef, useEffect } from "react";
import {
    ComponentContactSortIcon,
    StyledTable,
    ComponentEmptyData,
    StyledTableButtonTop,
    ComponentTableSearch,
    ComponentRowService,
    ComponentRowStore,
    ComponentRowAssignAddon,
} from "@components";
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    TouchSensor,
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    rectSortingStrategy,
    //@ts-ignore
} from "@dnd-kit/sortable";
import { Row, Col, Pagination, Button, Tabs } from "antd";
import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";
import {
    enumSortBy,
    enumContactSortFields,
    PAGINATION,
    PAGE_START,
    enumNewsStatus,
    INITIAL_LIST_PARAMS,
    PATH_PRODUCT_CREATE,
    PATH_STORE_CREATE,
    PATH_STOCK_CREATE,
    PATH_RECIPE_ASSIGN,
} from "@configs";
import { IPaginationNext, INews, IDataexample } from "@interfaces";
import { useDispatch, useSelector } from "react-redux";
import { openToast, selectAuth, selectContact, selectOrg, setLoading, store } from "@redux";
import { useNotify } from "@utils";
import { useHistory } from "react-router";
import axios from "axios";
import styled from "styled-components";
import { MoreHorizontal } from "react-feather";
import { productAPI, productAddonAPI, storeAPI } from "@api";
import { ComponentTableSearchStock } from "src/Components/TableSearchStock";
import { ComponentRowStock } from "src/Components/table/RowStock";
import { consumableAPI } from "src/API/consumable";
import { ComponentRowAssignStock } from "src/Components/table/RowAssignStock";
import Search from "antd/lib/input/Search";

const INITAL_PAGE = {
    xpage: PAGE_START,
    xlimit: PAGINATION,
};

export const PageAssignStockList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const search_params = useSelector(selectContact).paramsContact;
    const [params, setParams] = useState(search_params);
    const org_id = useSelector(selectAuth).userInfo?.merchant?._id;
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const categories = useSelector(selectOrg).category;
    const [textSearch, setTextSearch] = useState<string>("");

    const [searchDate, setSearchDate] = useState<string[]>([]);
    const [catSearch, setCatSearch] = useState<string>("");
    const [status, setStatus] = useState<enumNewsStatus>(enumNewsStatus.ALL);
    const [filterStatus, setFilterStatus] = useState<boolean>(true);
    const [pagination, setPagination] = useState<IPaginationNext>(INITAL_PAGE);
    const [services, setServices] = useState<IDataexample[]>([]);
    let [stores, setStores] = useState<any>([]);
    const [storeStatus, setStoreStatus] = useState<boolean>(false);
    const [total, setTotal] = useState<number>(0);
    const [Refresh, setRefresh] = useState<any>(0);
    const { success, error } = useNotify();
    const [tab, setTab] = useState<number>(0);
    const [paginationAddOn, setPaginationAddOn] = useState<IPaginationNext>(INITAL_PAGE);
    const [totalAddOn, setTotalAddOn] = useState<number>(0);
    const [addOnList, setAddOnList] = useState<any>([]);
    const history = useHistory();
    useEffect(() => {
        // for product
        if (tab === 0) {
            fetchNews();
        }
    }, [storeStatus, textSearch, pagination, tab]);
    useEffect(() => {
        // for add on
        if (tab === 1) {
            fetchAddon();
        }
    }, [storeStatus, textSearch, paginationAddOn, tab]);

    const fetchAddon = async () => {
        dispatch(setLoading(true));
        try {
            const res = await productAddonAPI.getProductAddons({
                organization_id: org_id,
                xlimit: paginationAddOn.xlimit,
                xpage: paginationAddOn.xpage,
                is_enable: true,
            });
            if (res.data.error === "0") {
                //
                setTotalAddOn(res.data.total);
                setAddOnList(res.data.result);
            } else {
                store.dispatch(
                    openToast({
                        message: res.data.msg,
                        type: "warning",
                        autoHideDuration: 3000,
                    })
                );
            }
        } catch (err: any) {
            error(err.msg);
        } finally {
            dispatch(setLoading(false));
        }
    };
    const fetchNews = async () => {
        dispatch(setLoading(true));
        try {
            // api call in here
            // await console.log("fetch api in here");

            const getAllVariants = await productAPI.getVariants({
                organization_id: org_id,
                xpage: pagination.xpage,
                xlimit: pagination.xlimit,
                has_consumable: true,
                // category_id: "true",
                // text_search: "",
            });
            // console.log(getAllVariants.data.result);

            setTotal(getAllVariants.data.total);
            const stores = getAllVariants.data.result;
            setStores(getAllVariants.data.result);

            // Search Input
            if (textSearch.length > 0) {
                let tempServices = getAllVariants.data.result;
                tempServices = tempServices.filter((s) => {
                    return s.product_name.toLowerCase().match(textSearch.toLowerCase());
                });
                setStores(tempServices);
            }
            // Search Input
            // setServices(data);
            // success("api response");
        } catch (err: any) {
            error(err.msg);
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };
    const { TabPane } = Tabs;
    // const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const value = e.target.value;
    //     if (typingTimeoutRef.current) {
    //         clearTimeout(typingTimeoutRef.current);
    //     }
    //     typingTimeoutRef.current = window.setTimeout(() => {
    //         const newSearchParams = { ...params, page: 1, search: value };
    //         setParams(newSearchParams);
    //     }, 300);
    // };

    const handleSort = (sortField: enumContactSortFields, sortBy: enumSortBy) => {
        const newSortParams = { ...params, sortField, sortBy };
        setParams(newSortParams);
        // fetchContacts(newSortParams);
    };

    const callbackGetList = () => {
        fetchNews();
    };
    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            const swapItem = (items: IDataexample[]) => {
                const oldIndex = items.findIndex((item) => JSON.stringify(item) === active.id);
                const newIndex = items.findIndex((item) => JSON.stringify(item) === over.id);
                return arrayMove(items, oldIndex, newIndex);
            };
            setServices(swapItem);
        }
    };
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
        useSensor(TouchSensor)
    );
    const handleSearchCategory = (value) => {
        setCatSearch(value);
    };
    const onChangeDateRange = (dates, dateStrings: [string, string]) => {
        setSearchDate(dateStrings);
    };
    const handlePagination = (page) => {
        setPagination({ ...pagination, xpage: page });
    };
    const handlePaginationAddOn = (page) => {
        setPaginationAddOn({ ...paginationAddOn, xpage: page });
    };
    const updateService = async (data, item) => {
        dispatch(setLoading(true));
        try {
            // api call in here
            const updateStoreStatus = await storeAPI.updateStoreStatus({
                organization_code: orgCode,
                store_code: item.store_code,
                is_enable: !item.is_enable,
            });
            if (updateStoreStatus.data.error === "0") {
                setStoreStatus(!storeStatus);
            }
            // console.log(updateStoreStatus);
            // const response = await newsAPI.getList();
            // setNews();
            // setTotal(response.data.total);
            success("Status Changed");
        } catch (err: any) {
            error(err.msg);
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };
    return (
        <StyledTable>
            <div className="page-layout">
                <div className="page-header-layout">
                    <div className="page-header" style={{ padding: "1.5rem 0" }}>
                        <div>
                            <h3>{t("page.assign_consumable")}</h3>
                        </div>
                        <div className="flex-header">
                            <Search
                                placeholder={t("page.search_product")}
                                onSearch={(value) => setTextSearch(value)}
                                style={{ width: 200, height: 42, border: "none", marginRight: 10 }}
                                onChange={(event) => {
                                    setTextSearch(event.target.value);
                                }}
                            />
                            <StyledTableButtonTop
                                type="danger"
                                size="default"
                                text={t("page.assign_stock_to_product")}
                                onClick={() => history.push(PATH_RECIPE_ASSIGN)}
                                className="add-btn"
                                icon="plus"
                                style={{
                                    background: "#F22F46",
                                    borderRadius: "4px",
                                    width: "100%",
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="page-table-layout ">
                    <div className="tab-section">
                        <Button
                            className={`tab-btn ${tab === 0 ? "active" : ""}`}
                            onClick={() => setTab(0)}
                        >
                            Product
                        </Button>
                        <Button
                            className={`tab-btn ${tab === 1 ? "active" : ""}`}
                            onClick={() => setTab(1)}
                        >
                            Add-On
                        </Button>
                    </div>
                </div>
                <div className="page-table-layout">
                    <div className="table">
                        <ScrollContainer vertical={false}>
                            <AssignStockStyle>
                                <div className="table_ground">
                                    {tab === 0 && (
                                        <div className="table-body">
                                            <div className="table-header">
                                                <Row
                                                    gutter={24}
                                                    type="flex"
                                                    justify="space-between"
                                                    align="middle"
                                                    className="header-row"
                                                >
                                                    <Col className="col-item" span={6}>
                                                        <p style={{ marginLeft: 16 }}>
                                                            {t("page.product_name")}
                                                        </p>
                                                        <div className="filter-button">
                                                            <ComponentContactSortIcon
                                                                handleSort={handleSort}
                                                                sortBy={enumSortBy.ASC}
                                                                sortField={
                                                                    enumContactSortFields.POINTS
                                                                }
                                                                searchParams={params}
                                                            />
                                                            <ComponentContactSortIcon
                                                                handleSort={handleSort}
                                                                sortBy={enumSortBy.DESC}
                                                                sortField={
                                                                    enumContactSortFields.POINTS
                                                                }
                                                                searchParams={params}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col className="col-item" span={6}>
                                                        <p>{t("page.variant")}</p>
                                                        <div className="filter-button">
                                                            <ComponentContactSortIcon
                                                                handleSort={handleSort}
                                                                sortBy={enumSortBy.ASC}
                                                                sortField={
                                                                    enumContactSortFields.POINTS
                                                                }
                                                                searchParams={params}
                                                            />
                                                            <ComponentContactSortIcon
                                                                handleSort={handleSort}
                                                                sortBy={enumSortBy.DESC}
                                                                sortField={
                                                                    enumContactSortFields.POINTS
                                                                }
                                                                searchParams={params}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col className="col-item" span={6}>
                                                        <p>{t("page.consumable_assigned")}</p>
                                                        <div className="filter-button">
                                                            <ComponentContactSortIcon
                                                                handleSort={handleSort}
                                                                sortBy={enumSortBy.ASC}
                                                                sortField={
                                                                    enumContactSortFields.POINTS
                                                                }
                                                                searchParams={params}
                                                            />
                                                            <ComponentContactSortIcon
                                                                handleSort={handleSort}
                                                                sortBy={enumSortBy.DESC}
                                                                sortField={
                                                                    enumContactSortFields.POINTS
                                                                }
                                                                searchParams={params}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col
                                                        className="col-item"
                                                        span={6}
                                                        style={{ placeContent: "end" }}
                                                    >
                                                        <p>{t("page.manage")}</p>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <DndContext
                                                sensors={sensors}
                                                collisionDetection={closestCenter}
                                                onDragEnd={handleDragEnd}
                                            >
                                                <SortableContext
                                                    items={stores.map((item) =>
                                                        JSON.stringify(item)
                                                    )}
                                                    strategy={rectSortingStrategy}
                                                >
                                                    {stores.length !== 0 ? (
                                                        <div className="data-table">
                                                            {stores.map((item, index) => (
                                                                <ComponentRowAssignStock
                                                                    key={index}
                                                                    data={item}
                                                                    itemJson={JSON.stringify(item)}
                                                                    categories={categories}
                                                                    handleEdit={updateService}
                                                                    callbackGetList={
                                                                        callbackGetList
                                                                    }
                                                                    index={index}
                                                                    stores={stores}
                                                                />
                                                            ))}
                                                            <div
                                                                style={{
                                                                    width: "100%",
                                                                    height: 10,
                                                                }}
                                                            ></div>
                                                        </div>
                                                    ) : (
                                                        <ComponentEmptyData />
                                                    )}
                                                </SortableContext>
                                            </DndContext>
                                        </div>
                                    )}

                                    {tab === 1 && (
                                        <div className="table-body">
                                            <div className="table-header">
                                                <Row
                                                    gutter={24}
                                                    type="flex"
                                                    justify="space-between"
                                                    align="middle"
                                                    className="header-row"
                                                >
                                                    <Col className="col-item" span={6}>
                                                        <p style={{ marginLeft: 16 }}>
                                                            {t("page.addon.table_addon_name")}
                                                        </p>
                                                        <div className="filter-button">
                                                            <ComponentContactSortIcon
                                                                handleSort={handleSort}
                                                                sortBy={enumSortBy.ASC}
                                                                sortField={
                                                                    enumContactSortFields.POINTS
                                                                }
                                                                searchParams={params}
                                                            />
                                                            <ComponentContactSortIcon
                                                                handleSort={handleSort}
                                                                sortBy={enumSortBy.DESC}
                                                                sortField={
                                                                    enumContactSortFields.POINTS
                                                                }
                                                                searchParams={params}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col className="col-item" span={6}>
                                                        <p>{t("page.addon.table_variant")}</p>
                                                        <div className="filter-button">
                                                            <ComponentContactSortIcon
                                                                handleSort={handleSort}
                                                                sortBy={enumSortBy.ASC}
                                                                sortField={
                                                                    enumContactSortFields.POINTS
                                                                }
                                                                searchParams={params}
                                                            />
                                                            <ComponentContactSortIcon
                                                                handleSort={handleSort}
                                                                sortBy={enumSortBy.DESC}
                                                                sortField={
                                                                    enumContactSortFields.POINTS
                                                                }
                                                                searchParams={params}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col className="col-item" span={6}>
                                                        <p>{t("page.addon.table_consume")}</p>
                                                        <div className="filter-button">
                                                            <ComponentContactSortIcon
                                                                handleSort={handleSort}
                                                                sortBy={enumSortBy.ASC}
                                                                sortField={
                                                                    enumContactSortFields.POINTS
                                                                }
                                                                searchParams={params}
                                                            />
                                                            <ComponentContactSortIcon
                                                                handleSort={handleSort}
                                                                sortBy={enumSortBy.DESC}
                                                                sortField={
                                                                    enumContactSortFields.POINTS
                                                                }
                                                                searchParams={params}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col
                                                        className="col-item"
                                                        span={6}
                                                        style={{ placeContent: "end" }}
                                                    >
                                                        <p>{t("page.manage")}</p>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <DndContext
                                                sensors={sensors}
                                                collisionDetection={closestCenter}
                                                onDragEnd={handleDragEnd}
                                            >
                                                <SortableContext
                                                    items={stores.map((item) =>
                                                        JSON.stringify(item)
                                                    )}
                                                    strategy={rectSortingStrategy}
                                                >
                                                    {addOnList.length !== 0 ? (
                                                        <div className="data-table">
                                                            {addOnList.map((item, index) => (
                                                                <ComponentRowAssignAddon
                                                                    key={index}
                                                                    data={item}
                                                                    itemJson={JSON.stringify(item)}
                                                                    index={index}
                                                                />
                                                            ))}
                                                            <div
                                                                style={{
                                                                    width: "100%",
                                                                    height: 10,
                                                                }}
                                                            ></div>
                                                        </div>
                                                    ) : (
                                                        <ComponentEmptyData />
                                                    )}
                                                </SortableContext>
                                            </DndContext>
                                        </div>
                                    )}
                                </div>
                            </AssignStockStyle>
                        </ScrollContainer>
                    </div>
                </div>

                <div className="page-bottom">
                    {tab === 0 && total > 0 && (
                        <div className="pagination">
                            <Pagination
                                defaultCurrent={INITIAL_LIST_PARAMS.page || 1}
                                pageSize={PAGINATION}
                                total={total}
                                onChange={handlePagination}
                                current={pagination.xpage}
                            />
                        </div>
                    )}
                    {tab === 1 && totalAddOn > 0 && (
                        <div className="pagination">
                            <Pagination
                                defaultCurrent={paginationAddOn.xpage || 1}
                                pageSize={paginationAddOn.xlimit}
                                total={totalAddOn}
                                onChange={handlePaginationAddOn}
                                current={paginationAddOn.xpage}
                            />
                        </div>
                    )}
                </div>
            </div>
        </StyledTable>
    );
};

export const AssignStockStyle = styled.div`
    .table-header {
        padding: 18px 40px 12px 39px !important;
    }
    .ant-tabs-bar {
        border: none;
    }
    .ant-tabs-nav .ant-tabs-tab {
        padding: 8px 0;
        margin: 0 28px 0 0;
    }
    .ant-tabs-nav .ant-tabs-tab-active {
        color: #f4394f;
        font-weight: 600;
    }
    .ant-tabs-ink-bar {
        background-color: #f4394f;
    }
    .ant-tabs-nav .ant-tabs-tab:hover {
        color: #f4394f;
    }
    .grayBox {
        background: #f7f7f8;
        border-radius: 6px;
        padding: 16px;
        display: flex;
        flex-direction: column;
        place-items: center;
    }
    .grayBox .body1 {
        font-size: 16px;
        font-weight: 700;
    }
    .grayBox .header {
        font-size: 35px;
        font-weight: 700;
        color: #000;
        margin: 0;
    }
    .addQTY {
        margin-top: 24px;
    }
    .reasonDiv {
        margin-top: 2rem;
    }
    .reasonChoice {
        display: block;
        line-height: 30px;
    }
    .ant-radio-group {
        width: 100%;
    }
`;
