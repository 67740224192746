import { useTranslation } from "react-i18next";
import { SharedModal } from "../shared";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { Button, Input, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { IGetConsumableStoreId } from "@interfaces";
import { useYup } from "@validations";
import React from "react";
import { POAPI } from "@api";
import { useSelector } from "react-redux";
import { selectAuth } from "@redux";
import InfiniteScroll from "react-infinite-scroll-component";

interface IProps {
    store_id: string;
    showPopup: boolean;
    handleClose: () => void;
    addConsume: (payload: any) => void;
    cate_id?: string;
    type?: string | null;
}

interface IConsumePayload {
    consumable_id: string;
    name: string;
    restock_price: number;
    on_stork: number;
    add_qty: number;
    unit: string;
    delivery_qty?: number;
}

interface IConsume {
    _id: string;
    name: string;
}
const { Option } = Select;
export const ComponentTRModal = (props: IProps) => {
    const { YupNews } = useYup();
    const { showPopup, handleClose, store_id, addConsume, cate_id, type } = props;
    const { t } = useTranslation();
    const [itemName, setItemName] = useState<string>("");
    const [consumeList, setConsumeList] = useState<IConsume[]>([]);

    const [proName, setProName] = useState<string>("");
    const [proConList, setProConList] = useState<IConsume[]>([]);
    const [tab, setTab] = useState<number>(0);
    const [cPage, setCPage] = useState<number>(1);
    const [pPage, setPPage] = useState<number>(1);
    const [cTotal, setCTotal] = useState<number>(0);
    const [pTotal, setPTotal] = useState<number>(0);

    const { userInfo } = useSelector(selectAuth);
    const onSearch = async () => {
        let checkTab = tab;
        if (type === "product") {
            checkTab = 1;
        } else if (type === "inv") {
            checkTab = 0;
        }
        const params: IGetConsumableStoreId = {
            organization_id: userInfo?.merchant?._id || "",
            xpage: 1,
            xlimit: 40,
            text_search: tab === 0 ? itemName : proName,
            store_id: store_id,
            is_auto_consumable: tab === 0 ? false : true,
            is_enable_popr: true,
            // ...(cate_id && { product_category_id: cate_id }),
            // product_category_id: cate_id || "",
        };
        const res = await POAPI.getConsumeByStore(params);
        if (res.data.error === "0") {
            if (tab === 0) {
                setConsumeList(res?.data?.result);
                setCTotal(res.data.total);
            } else {
                setProConList(res?.data?.result);
                setPTotal(res?.data.total);
            }
        }
    };
    const onLoadMore = async () => {
        const params: IGetConsumableStoreId = {
            organization_id: userInfo?.merchant?._id || "",
            xpage: tab === 0 ? cPage + 1 : pPage + 1,
            xlimit: 40,
            text_search: tab === 0 ? itemName : proName,
            store_id: store_id,
            is_auto_consumable: tab === 0 ? false : true,
        };
        const res = await POAPI.getConsumeByStore(params);
        if (res.data.error === "0") {
            if (tab === 0) {
                setConsumeList([...consumeList, ...res?.data?.result]);
                setCPage(cPage + 1);
            } else {
                setProConList([...proConList, ...res?.data?.result]);
                setPPage(pPage + 1);
            }
        }
    };
    // form inital data
    useEffect(() => {
        if (showPopup) {
            onSearch();
        }
    }, [tab]);
    useEffect(() => {
        if (!showPopup) {
            setConsumeList([]);
        } else {
            // if (type === "product") {
            //     setTab(1);
            // } else if (type === "inv") {
            //     setTab(0);
            // }
            onSearch();
            setCPage(1);
            setPPage(1);
        }
    }, [showPopup]);
    const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setItemName(e.target.value);
    };
    const handleChangeProName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setProName(e.target.value);
    };

    const selectConsume = (cItem: any) => {
        const payload: IConsumePayload = {
            consumable_id: cItem._id,
            name: cItem.name,
            restock_price: cItem.cost,
            on_stork: cItem.consumable_data.restock_qty,
            add_qty: 0,
            unit: cItem.formula[0].restock_unit_name,
            delivery_qty: cItem?.restock_formula?.[0]?.to_restock_unit || 1,
        };
        addConsume(payload);
        handleClose();
    };
    const changeTab = (index) => {
        if (!type) {
            setTab(index);
        }
    };

    return (
        <SharedModal
            showPopup={showPopup}
            handleClose={handleClose}
            title={t("page.purchase_order.modal_consume_title")}
            width={600}
        >
            {/* modify popup content & css here */}
            <StyledModalContent>
                {store_id !== "" && (
                    <div className="tab-section">
                        <Button
                            className={`tab-btn ${tab === 0 ? "active" : ""}`}
                            onClick={() => changeTab(0)}
                        >
                            {t("page.purchase_order.modal_tab_ingredient")}
                        </Button>
                        <Button
                            className={`tab-btn ${tab === 1 ? "active" : ""}`}
                            onClick={() => changeTab(1)}
                        >
                            {t("page.purchase_order.modal_tab_product")}
                        </Button>
                    </div>
                )}
                {store_id !== "" && (
                    <>
                        {tab === 0 && (
                            <Input
                                className="item-name"
                                suffix={<SearchOutlined onClick={onSearch} />}
                                onChange={handleChangeName}
                                onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                        onSearch();
                                    }
                                }}
                                value={itemName}
                            />
                        )}
                        {tab === 1 && (
                            <Input
                                className="item-name"
                                suffix={<SearchOutlined onClick={onSearch} />}
                                onChange={handleChangeProName}
                                onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                        onSearch();
                                    }
                                }}
                                value={proName}
                            />
                        )}
                    </>
                )}
                {tab === 0 && consumeList.length > 0 && (
                    <InfiniteScroll
                        dataLength={consumeList.length}
                        next={onLoadMore}
                        hasMore={consumeList.length < cTotal}
                        loader={<p>loading...</p>}
                        height={400}
                    >
                        <div className="consume-list-section consume">
                            {consumeList?.map((cItem: any, cIndex: number) => {
                                return (
                                    <div
                                        key={cIndex}
                                        className="consume-item"
                                        onClick={() => {
                                            selectConsume(cItem);
                                        }}
                                    >
                                        <p> {cItem?.name}</p>
                                        <p>
                                            {Number(
                                                (cItem?.consumable_data?.restock_qty).toFixed(2)
                                            )}{" "}
                                            {cItem?.formula[0]?.restock_unit_name}
                                        </p>
                                    </div>
                                );
                            })}
                        </div>
                    </InfiniteScroll>
                )}
                {tab === 1 && proConList.length > 0 && (
                    <InfiniteScroll
                        dataLength={proConList.length}
                        next={onLoadMore}
                        hasMore={proConList.length < pTotal}
                        loader={<p>loading...</p>}
                        height={400}
                    >
                        <div className="consume-list-section consume">
                            {proConList?.map((cItem: any, cIndex: number) => {
                                return (
                                    <div
                                        key={cIndex}
                                        className="consume-item"
                                        onClick={() => {
                                            selectConsume(cItem);
                                        }}
                                    >
                                        <p> {cItem?.name}</p>
                                        <p>
                                            {Number((cItem?.consumable_data?.qty).toFixed(2))}{" "}
                                            {cItem?.formula[0]?.restock_unit_name}
                                        </p>
                                    </div>
                                );
                            })}
                        </div>
                    </InfiniteScroll>
                )}
            </StyledModalContent>
        </SharedModal>
    );
};

const StyledModalContent = styled.div`
    .item-name {
        height: 45px;
    }
    .tab-section {
        margin: 15px 0px;
        .tab-btn {
            background: none;
            border: none;
            font-weight: 700;
            font-size: 16px;
            padding: 0px;
            padding-bottom: 20px;
            margin-right: 30px;
        }
        .tab-btn:hover {
            color: #f4394f;
        }
        .active {
            color: #f4394f;
            border-bottom: 4px solid #f4394f !important;
        }
    }

    .consume {
        background-color: #f7f7f7;
        padding: 10px 15px;
        border-radius: 8px;
        /* max-height:250px;
        overflow:scroll; */
        .consume-item {
            cursor: pointer;
            background-color: white;
            border: 1px solid #d7d7d7;
            border-radius: 8px;
            margin: 15px 0px;
            padding: 15px;
            color: black;
            font-size: 18px;
            display: flex;
            justify-content: space-between;
            p {
                margin-bottom: 0;
            }
        }
        .consume-item:hover {
            /* background: #0263e0; */
            box-shadow: 0px 4px 12px rgba(2, 99, 224, 0.2) !important;
        }
    }
    .infinite-scroll-component {
        margin-top: 30px;
    }
`;
