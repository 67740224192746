import { useTranslation } from "react-i18next";
import { SharedModal, StyledCancelButton, StyledSubmitButton } from "../shared";
import styled from "styled-components";
import { useNotify } from "@utils";
import { Checkbox, Input } from "antd";
import { ColumnProps } from "antd/lib/table";
import { Table } from "antd";
import { PAGINATION } from "@configs";
import { IPaginationNext } from "@interfaces";

interface IProps {
    showPopup: boolean;
    handleClose: () => void;
    consumables?: any;
    setConsumables?: any;
    tempConsumable?: any;
    setTempConsumable?: any;
    tableData?: any;
    setTableData?: any;
    totalConsumables?: number;
    handlePagination: (page: number) => void;
    pagination: IPaginationNext;
    setTextSearch?: any;
}

export const ComponentAddConsumableModal = (props: IProps) => {
    const {
        showPopup,
        handleClose,
        consumables,
        tempConsumable,
        setTempConsumable,
        tableData,
        setTableData,
        totalConsumables,
        handlePagination,
        pagination,
        setTextSearch,
    } = props;
    const { t } = useTranslation();
    const { Search } = Input;
    // Edit Stock Table
    interface IMyTableData {
        name: string;
        used_qty: number;
        _id: string;
        unit_name: string;
    }

    const columns: ColumnProps<IMyTableData>[] = [
        {
            dataIndex: "name",
            title: () => <span style={{ fontWeight: 700 }}>{t("page.consumable_list")}</span>,
            key: "name",
            width: "50%",
            render: (text, record, index) => (
                <div className="consumableName">
                    <Checkbox
                        checked={
                            tempConsumable?.some((x) => x?.consumable_name === text) ? true : false
                        }
                        // value={}
                        onChange={(e) => {
                            if (e.target.checked) {
                                let arr = tempConsumable;
                                arr.push({
                                    consumable_name: record.name,
                                    consumable_unit_name: record.unit_name,
                                    consumable_id: record._id,
                                    used_qty: 0,
                                });
                                setTempConsumable([...arr]);
                            } else {
                                setTempConsumable((data) =>
                                    data.filter((item) => item?.consumable_name !== text)
                                );
                            }
                        }}
                    >
                        {text}
                    </Checkbox>
                </div>
            ),
        },
    ];
    // Edit Stock Table
    const handleSubmit = () => {
        setTableData([...tempConsumable]);
        handleClose();
    };

    const paginationConfig = {
        pageSize: PAGINATION,
        current: pagination.xpage,
        total: totalConsumables,
        onChange: (page, pageSize) => {
            handlePagination(page);
        },
    };

    return (
        <SharedModal
            showPopup={showPopup}
            handleClose={handleClose}
            title={t("page.select_consumables")}
            width={900}
        >
            <StyledModalContent>
                <div className="searchRow">
                    <p className="body2">{t("page.search")}</p>
                    <Search
                        placeholder={t("page.search_consumables")}
                        onSearch={(value) => setTextSearch(value)}
                        style={{ width: 300, height: 45 }}
                        onChange={(event) => {
                            setTextSearch(event.target.value);
                        }}
                    />
                </div>
                <Table
                    columns={columns}
                    dataSource={consumables}
                    bordered
                    pagination={paginationConfig}
                    rowKey={"_id"}
                />
            </StyledModalContent>
            <div className="btn-layout" style={{ marginTop: "2rem" }}>
                <StyledSubmitButton
                    type="default"
                    text={t("page.save")}
                    htmlType="submit"
                    onClick={handleSubmit}
                />
                <StyledCancelButton
                    type="sub"
                    text={t("page.cancel")}
                    htmlType="button"
                    onClick={() => handleClose()}
                />
            </div>
        </SharedModal>
    );
};

const StyledModalContent = styled.div`
    .ant-drawer-body {
        padding: 0 !important;
    }
    .searchRow {
        display: flex;
        gap: 10px;
        place-items: center;
        margin: 20px auto;
        p {
            margin: 0;
            font-weight: 600;
        }
    }
`;
