import { useState, useEffect } from "react";
import {
    ComponentContactSortIcon,
    StyledTable,
    ComponentTableCheckStock,
    ComponentRowCheckStock,
    StyledTableButtonAddRow,
} from "@components";
import { Row, Col, Empty, Input } from "antd";
import { useTranslation } from "react-i18next";
import {
    enumSortBy,
    enumContactSortFields,
    PAGINATION,
    PAGE_START,
    PATH_CHECK_STOCK,
} from "@configs";
import {
    IPaginationNext,
    ICheckConsumableList,
    ICheckProductListCreate,
    ICheckStockReasonList,
    IGetVatDetailExample,
    IGetAdminDataByTokenAdminDataExample,
} from "@interfaces";
import { useDispatch, useSelector } from "react-redux";
import { openToast, selectAuth, selectContact, setLoading, store } from "@redux";
import { useNotify } from "@utils";
import styled from "styled-components";
import { adminAPI, checkStockDocAPI, orgApi, storeAPI } from "@api";
import { IGetStoresBindingDropdownExample } from "src/Interfaces/store";
import { useHistory, useLocation } from "react-router";
import { ComponentAddInventoryModal, ComponentAddInventoryWasteModal } from "src/Components/checkStock";

const INITAL_PAGE = {
    xpage: PAGE_START,
    xlimit: PAGINATION,
};

export const PagePublishCheckStock = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location: any = useLocation();
    const search_params = useSelector(selectContact).paramsContact;
    const [params, setParams] = useState(search_params);
    const org_id = useSelector(selectAuth).userInfo?.merchant?._id;
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const [textSearch, setTextSearch] = useState<string>("");
    const [innerTextSearch, setInnerTextSearch] = useState<string>("");
    const [pagination, setPagination] = useState<IPaginationNext>(INITAL_PAGE);
    const [storeList, setStoreList] = useState<IGetStoresBindingDropdownExample[]>([]);
    const [checkStockReasonList, setCheckStockReasonList] = useState<ICheckStockReasonList[]>([]);
    const [selectedStore, setSelectedStore] = useState<string>("");
    const { success, error } = useNotify();
    const [tab, setTab] = useState<number>(0);
    const [remark, setRemark] = useState<string>("");
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [consumeList, setConsumeList] = useState<
        (ICheckConsumableList & ICheckProductListCreate)[]
    >([]);
    const history = useHistory();
    const [vatDetail, setVatDetail] = useState<IGetVatDetailExample>();
    const [selectedTab, setSelectedTab] = useState<number>(-1);
    const [configDetail, setConfigDetail] = useState<any>();
    const { Search } = Input;
    const { adminPermission } = useSelector(selectAuth);

    useEffect(() => {
        fetchNews();
        if (location.state.checkType && location.state.checkType !== "adhoc") {
            getCheckStockList();
        }
    }, [selectedStore, textSearch, pagination, location.state]);

    useEffect(() => {
        fetchORGSetting();
    }, []);
    const fetchORGSetting = async () => {
        const getConfig = await orgApi.getVatDetail({
            organization_id: org_id,
        });
        setConfigDetail(getConfig?.data?.result);
    };

    const fetchNews = async () => {
        dispatch(setLoading(true));
        try {
            if (storeList?.length === 0) {
                const getStores = await storeAPI.getStoresBindingDropdown({
                    organization_id: org_id,
                });
                if (getStores.data.error === "0") {
                    setStoreList(getStores.data.result);
                }
            }
            const getVatDetail = await orgApi.getVatDetail({ organization_id: org_id });
            if (getVatDetail.data.error === "0") {
                setVatDetail(getVatDetail.data.result);
            }
            const getCheckStockReasonList = await orgApi.getCheckStockReasonList({
                organization_id: org_id,
            });
            if (getCheckStockReasonList.data.error === "0") {
                setCheckStockReasonList(getCheckStockReasonList.data.result);
            }
        } catch (err: any) {
            error(err.msg);
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const getCheckStockList = async () => {
        try {
            if (location.state.checkType.split("_")[0] === "inventory") {
                setTab(0);
                setSelectedTab(0);
                const get = await checkStockDocAPI.getInitCheckStockConsumableListByStoreId({
                    organization_id: org_id,
                    store_id: selectedStore,
                    check_type: location.state.checkType.split("_")[1],
                });
                if (get.data.error === "0") {
                    let temp: any = {};
                    let arr: any[] = [];
                    get.data.result.map((item) => {
                        temp = {
                            consumable_id: item._id,
                            consumable_name: item.name,
                            delivery_unit_name:
                                item.restock_formula && item.restock_formula.length !== 0
                                    ? item.restock_formula[0].delivery_unit_name
                                    : "",
                            before_restock_qty: item.consumable_data.restock_qty,
                            delivery_qty: 0,
                            restock_qty: item.consumable_data.restock_qty || 0,
                            grand_total_restock_qty: item.consumable_data.restock_qty || 0,
                            restock_change_qty: 0,
                            to_restock_unit:
                                item.restock_formula && item.restock_formula.length !== 0
                                    ? item.restock_formula[0].to_restock_unit
                                    : 0,
                            restock_formula: item.restock_formula,
                            formula: item.formula,
                            used_qty: 0,
                            unit_name: item.unit_name,
                        };
                        // addConsume(temp);
                        arr.push(temp);
                    });
                    setConsumeList(arr);

                }
            } else {
                setTab(1);
                setSelectedTab(1);
                const get = await checkStockDocAPI.getInitCheckStockProductListByStoreId({
                    organization_id: org_id,
                    store_id: selectedStore,
                    check_type: location.state.checkType.split("_")[1],
                });
                if (get.data.error === "0") {
                    let arr: any[] = [];
                    get.data.result.map((item, i) => {
                        let temp: any = [];
                        item.each_consumable.map((each) => {
                            temp.push({
                                variant_id: each.variant_id,
                                variant_name: each.name,
                                consumable_id: each._id,
                                delivery_unit_name:
                                    each.restock_formula && each.restock_formula.length !== 0
                                        ? each.restock_formula[0].delivery_unit_name
                                        : "",
                                before_restock_qty: each.store_list.restock_qty,
                                delivery_qty: 0,
                                restock_qty: each.store_list.restock_qty || 0,
                                restock_formula:
                                    each.restock_formula && each.restock_formula.length !== 0
                                        ? each.restock_formula
                                        : [],
                                formula: each?.formula,
                                used_qty: 0,
                                unit_name: each.unit_name,
                            });
                        });
                        const payload: any = {
                            product_id: item._id._id,
                            product_name: item._id.product_name,
                            check_variant_list: temp,
                        };
                        arr.push(payload);
                    });
                    setConsumeList(arr);
                }
            }
        } catch (err: any) {
            error(err.message);
        }
    };

    const handleKeyDown = (event: any, currentIndex: number, inputName: string, nextVariant?: number) => {
        if (event.key === "Tab" || event.key === "Enter") {
            if (currentIndex < consumeList.length - 1 || nextVariant !== 0) {
                let nextName = `${inputName}${nextVariant ? (currentIndex + "." + nextVariant) : currentIndex + 1}`;
                const nextInput = document.querySelector(`input[name="${nextName}"]`) as HTMLInputElement;
                if (nextInput) {
                    nextInput.focus();
                    event.preventDefault(); // Prevent default Tab behavior
                }
            }
        }
    };

    const handleSort = (sortField: enumContactSortFields, sortBy: enumSortBy) => {
        const newSortParams = { ...params, sortField, sortBy };
        setParams(newSortParams);
    };

    const handlePagination = (page) => {
        setPagination({ ...pagination, xpage: page });
    };

    const handleSelectStore = (value) => {
        setSelectedStore(value);
    };

    const addConsume = (payload: any) => {
        if (tab === 0) {
            let arr = consumeList;
            const found = arr.some((obj) => obj.consumable_id === payload.consumable_id);
            if (found === false) {
                // arr.push(payload);
                arr = [payload, ...consumeList];
                setConsumeList(arr);
            } else {
                store.dispatch(
                    openToast({
                        message: "Already selected",
                        type: "warning",
                        autoHideDuration: 3000,
                    })
                );
            }
        } else {
            let arr = consumeList;
            const found = arr.some(
                (obj: ICheckProductListCreate) => obj.product_id === payload.product_id
            );
            const foundVariant = arr.some((obj: ICheckProductListCreate) => {
                if (obj.product_id === payload.product_id) {
                    const variantExists = obj.check_variant_list.some(
                        (variant) => variant.variant_id === payload.check_variant_list[0].variant_id
                    );
                    if (variantExists) {
                        return true;
                    }
                }
                return false;
            });
            if (found === false) {
                // arr.push(payload);
                arr = [payload, ...consumeList];
                setConsumeList(arr);
            } else {
                if (foundVariant === true) {
                    store.dispatch(
                        openToast({
                            message: "Already selected",
                            type: "warning",
                            autoHideDuration: 3000,
                        })
                    );
                } else {
                    mergeObjects(consumeList, payload);
                }
            }
        }
    };
    const mergeObjects = (data, newData) => {
        const mergedData = [...data];
        const existingItemIndex = mergedData.findIndex(
            (item) => item.product_id === newData.product_id
        );
        if (existingItemIndex !== -1) {
            mergedData[existingItemIndex].check_variant_list = [
                ...mergedData[existingItemIndex].check_variant_list,
                ...newData.check_variant_list,
            ];
        }
        return mergedData;
    };

    const handlePublish = async () => {
        try {
            const send = await checkStockDocAPI.createCheckStockDoc({
                organization_id: org_id,
                remark: remark,
                store_id: selectedStore,
                check_doc_type: tab === 0 ? "ingredient" : "product",
                check_stock_code: "",
                check_consumable_list: tab === 0 ? consumeList : [],
                check_product_list: tab === 1 ? consumeList : [],
                is_waste: false,
            });
            if (send.data.error === "0") {
                success(t("message.create.success"));
                history.push(PATH_CHECK_STOCK);
            } else {
                error(send.data.msg);
            }
        } catch (error: any) {
            if (error) {
                error(error.msg);
            } else error(t("message.create.fail"));
        }
    };

    return (
        <StyledTable>
            <PublishStockStyle>
                <div className="page-layout">
                    <div className="page-header-layout">
                        <div className="page-header" style={{ padding: "1.5rem 0" }}>
                            <div>
                                <h3>{t("page.sidebar.check_stock")}</h3>
                            </div>
                            {selectedStore !== "" && (
                                <StyledTableButtonAddRow
                                    type="danger"
                                    size="default"
                                    text={t("page.purchase_order.add_inventory_btn")}
                                    onClick={() => setShowPopup(true)}
                                    className="add-btn"
                                    icon="plus"
                                />
                            )}
                        </div>
                        <div className="search-layout">
                            <ComponentTableCheckStock
                                setTextSearch={setTextSearch}
                                storeList={
                                    adminPermission?.pos_role.store_access_type === "all_store" ||
                                        adminPermission?.pos_role.store_access_type === undefined
                                        ? storeList
                                        : adminPermission?.store_ids
                                }
                                handleSearchCategory={handleSelectStore}
                                handlePublish={handlePublish}
                                isEmpty={consumeList.length !== 0 ? true : false}
                            />

                        </div>
                    </div>
                    {selectedStore !== "" && (
                        <div className="page-table-layout">
                            <div className="table">
                                <div className="table_ground">
                                    <div className="table-body">
                                        <div className="table-header">
                                            <Row gutter={32} style={{ marginBottom: 20 }}>
                                                <Col span={12} className="innerSearchRow">
                                                    <p>{t("page.search")}</p>
                                                    <Search
                                                        placeholder={t("page.search_consumables")}
                                                        onSearch={(value) =>
                                                            setInnerTextSearch(value)
                                                        }
                                                        style={{ width: 200, height: 45 }}
                                                        onChange={(value) => {
                                                            setInnerTextSearch(value.target.value);
                                                        }}
                                                    />
                                                </Col>
                                                <Col span={12} className="innerSearchRow">
                                                    <p>{t("page.remark")} : </p>
                                                    <Input
                                                        placeholder={t("page.remark")}
                                                        style={{ width: 200, height: 45 }}
                                                        onChange={(value) => {
                                                            setRemark(value.target.value);
                                                        }}
                                                        value={remark}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row
                                                gutter={24}
                                                type="flex"
                                                justify="space-between"
                                                align="middle"
                                                className="header-row"
                                            >
                                                <Col className="col-item" span={4}>
                                                    <p>{t("page.consumable_name")}</p>
                                                    <div className="filter-button">
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.ASC}
                                                            sortField={enumContactSortFields.POINTS}
                                                            searchParams={params}
                                                        />
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.DESC}
                                                            sortField={enumContactSortFields.POINTS}
                                                            searchParams={params}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col
                                                    className="col-item"
                                                    span={
                                                        vatDetail &&
                                                            vatDetail.is_enable_check_store_reason
                                                            ? 3
                                                            : 4
                                                    }
                                                    style={{ placeContent: "baseline" }}
                                                >
                                                    <p>{t("page.current_base_qty")}</p>
                                                    <div className="filter-button">
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.ASC}
                                                            sortField={enumContactSortFields.POINTS}
                                                            searchParams={params}
                                                        />
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.DESC}
                                                            sortField={enumContactSortFields.POINTS}
                                                            searchParams={params}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col
                                                    className="col-item"
                                                    span={11}
                                                    style={{ placeContent: "baseline" }}
                                                >
                                                    <p>{t("page.new_balance")}</p>
                                                    <div className="filter-button">
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.ASC}
                                                            sortField={enumContactSortFields.POINTS}
                                                            searchParams={params}
                                                        />
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.DESC}
                                                            sortField={enumContactSortFields.POINTS}
                                                            searchParams={params}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col
                                                    className="col-item"
                                                    span={
                                                        vatDetail &&
                                                            vatDetail.is_enable_check_store_reason
                                                            ? 3
                                                            : 4
                                                    }
                                                    style={{ placeContent: "center" }}
                                                >
                                                    <p>{t("page.difference")}</p>
                                                </Col>
                                                {vatDetail &&
                                                    vatDetail.is_enable_check_store_reason && (
                                                        <Col
                                                            className="col-item"
                                                            span={3}
                                                            style={{ placeContent: "baseline" }}
                                                        >
                                                            <p>{t("page.check_stock_reason")}</p>
                                                        </Col>
                                                    )}
                                            </Row>
                                        </div>

                                        {consumeList.length !== 0 ? (
                                            <div className="data-table">
                                                {consumeList.map(
                                                    (item, index) =>
                                                        (
                                                            item?.consumable_name ||
                                                            item?.product_name
                                                        )
                                                            .toLowerCase()
                                                            .includes(
                                                                innerTextSearch.toLowerCase()
                                                            ) && (
                                                            <ComponentRowCheckStock
                                                                key={index}
                                                                data={item}
                                                                index={index}
                                                                stores={consumeList}
                                                                setConsumeList={setConsumeList}
                                                                tab={tab}
                                                                checkStockReasonList={
                                                                    checkStockReasonList
                                                                }
                                                                handleKeyDown={handleKeyDown}
                                                                vatDetail={vatDetail}
                                                                isDelivery={
                                                                    configDetail?.is_show_delivery_unit_on_check_stock
                                                                }
                                                            />
                                                        )
                                                )}
                                                <div style={{ width: "100%", height: 10 }}></div>
                                            </div>
                                        ) : (
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {/* <ComponentAddInventoryModal */}
                    <ComponentAddInventoryWasteModal
                        store_id={selectedStore}
                        showPopup={showPopup}
                        handleClose={() => setShowPopup(!showPopup)}
                        addConsume={addConsume}
                        tab={tab}
                        setTab={setTab}
                        setSelectedTab={setSelectedTab}
                        selectedTab={selectedTab}
                    />
                </div>
            </PublishStockStyle>
        </StyledTable>
    );
};

export const PublishStockStyle = styled.div`
    .table {
        width: 100%;
    }
    .table_ground {
        overflow-x: hidden;
    }
    .page-table-layout {
        padding: 0 4.625rem 0 2.125rem !important;
    }
    .page-header-layout {
        padding: 3.75rem 4.625rem 0 2.125rem !important;
    }
    .table-header {
        padding: 18px 40px 12px 12px !important;
        margin-left: 0;
    }
    .ant-tabs-bar {
        border: none;
    }
    .ant-tabs-nav .ant-tabs-tab {
        padding: 8px 0;
        margin: 0 28px 0 0;
    }
    .ant-tabs-nav .ant-tabs-tab-active {
        color: #f4394f;
        font-weight: 600;
    }
    .ant-tabs-ink-bar {
        background-color: #f4394f;
    }
    .ant-tabs-nav .ant-tabs-tab:hover {
        color: #f4394f;
    }
    .grayBox {
        background: #f7f7f8;
        border-radius: 6px;
        padding: 16px;
        display: flex;
        flex-direction: column;
        place-items: center;
    }
    .grayBox .body1 {
        font-size: 16px;
        font-weight: 700;
    }
    .grayBox .header {
        font-size: 35px;
        font-weight: 700;
        color: #000;
        margin: 0;
    }
    .addQTY {
        margin-top: 24px;
    }
    .reasonDiv {
        margin-top: 2rem;
    }
    .reasonChoice {
        display: block;
        line-height: 30px;
    }
    .ant-radio-group {
        width: 100%;
    }
    .innerSearchRow {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        p {
            margin: 0;
        }
    }
`;
