import { useEffect, memo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Row, Col, Checkbox, Radio } from "antd";
import { IGetPosRoleTemplateExample, IGetPosRolesExample, IUpdatePosRole } from "@interfaces";
import { StyledSubmitButton, StyledCancelButton, StyledCard, SharedInput } from "@components";
import { useYup } from "@validations";
import { setLoading, selectAuth } from "@redux";
import { showErrorMessage, useNotify } from "@utils";
import { PATH_ROLES_PERMISSIONS, PATH_ROLES_PERMISSIONS_List } from "@configs";
import { rolePosAPI } from "@api";
import styled from "styled-components";
interface IProps {
    posRole?: IGetPosRolesExample;
    posRoleTemplate?: IGetPosRoleTemplateExample;
}
const initData: IUpdatePosRole = {
    pos_role_id: "",
    organization_id: "",
    pos_role_name: "",
    pos_role_detail: "",
    can_create_manage_product: false,
    can_assign_invertory: false,
    can_addon: false,
    can_product_promotion: false,
    can_bill_promotion: false,
    store_access_type: "all_store", // can be all_store/specifit_store
    can_inventory_list: false,
    can_supplier_list: false,
    can_edit_inventory_qty: false,
    can_check_stock: false,
    can_waste_record: false,
    can_inventory_require: false,
    can_purchase_order: false,
    can_goods_receive: false,
    can_all_store_permission: false,
    store_permission: {
        can_permission: false,
        can_payment: false,
        can_store_detail: false,
    },
    can_channel: false,
    can_all_object_manager: false,
    object_manager: {
        can_product_category: false,
        can_inventory_category: false,
        can_inventory_unit: false,
        can_promotion_category: false,
        can_promotion_group: false,
        can_brand: false,
        can_check_stock_list: false,
    },
    can_pos_organization_setting: false,
    can_role_permission: false,
    can_import_pos_data: false,
    can_all_sale_summary: false,
    sale_summary: {
        can_sale_summary: false,
        can_sale_by_bill: false,
    },
    can_all_sale_mix: false,
    sale_mix: {
        can_sale_by_product_summary: false,
        can_sale_by_product_detail: false,
        can_sale_by_category: false,
        can_sale_by_channel: false,
        can_sale_by_payment_method: false,
        can_sale_by_staff: false,
        can_sale_by_branch: false,
        can_addon_report: false,
    },
    can_all_promotion: false,
    promotion: {
        can_promotion_by_record: false,
        can_promotion_by_promotion: false,
    },
    can_all_inventory_movement: false,
    inventory_movement: {
        can_current_stock: false,
        can_inventory_movement_by_record: false,
        can_inventory_waste_record: false,
        can_inventory_check_record: false,
        can_inventory_movement_summary: false,
    },
    can_all_po_pr_gr: false,
    po_pr_gr: {
        can_pr_report: false,
        can_pr_forecast_report: false,
        can_po_report: false,
        can_gr_report: false,
    },
};

export const ModuleRolesPermissionsForm = memo((props: IProps) => {
    const { YupNews } = useYup();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { error, success } = useNotify();
    const organization_id = useSelector(selectAuth).userInfo?.merchant._id;
    const pathName = window.location.pathname;
    const createNews = Yup.object().shape(YupNews);
    const { posRole, posRoleTemplate } = props;
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        setTimeout(() => {
            dispatch(setLoading(false));
        }, 500);
        if (posRole) {
            setFieldValue("pos_role_name", posRole?.pos_role_name);
            setFieldValue("pos_role_detail", posRole?.pos_role_detail);
            setFieldValue("can_create_manage_product", posRole?.can_create_manage_product);
            setFieldValue("can_assign_invertory", posRole?.can_assign_invertory);
            setFieldValue("can_addon", posRole?.can_addon);
            setFieldValue("can_product_promotion", posRole?.can_product_promotion);
            setFieldValue("can_bill_promotion", posRole?.can_bill_promotion);
            setFieldValue("store_access_type", posRole?.store_access_type);
            setFieldValue("can_inventory_list", posRole?.can_inventory_list);
            setFieldValue("can_supplier_list", posRole?.can_supplier_list);
            setFieldValue("can_edit_inventory_qty", posRole?.can_edit_inventory_qty);
            setFieldValue("can_check_stock", posRole?.can_check_stock);
            setFieldValue("can_waste_record", posRole?.can_waste_record);
            setFieldValue("can_inventory_require", posRole?.can_inventory_require);
            setFieldValue("can_purchase_order", posRole?.can_purchase_order);
            setFieldValue("can_goods_receive", posRole?.can_goods_receive);
            setFieldValue("can_all_store_permission", posRole?.can_all_store_permission);
            if (posRole?.store_permission) {
                setFieldValue(
                    "store_permission.can_permission",
                    posRole?.store_permission.can_permission
                );
                setFieldValue(
                    "store_permission.can_payment",
                    posRole?.store_permission.can_payment
                );
                setFieldValue(
                    "store_permission.can_store_detail",
                    posRole?.store_permission.can_store_detail
                );
            }
            setFieldValue("can_channel", posRole?.can_channel);
            setFieldValue("can_all_object_manager", posRole?.can_all_object_manager);
            setFieldValue("object_manager.can_brand", posRole?.object_manager.can_brand);
            setFieldValue(
                "object_manager.can_check_stock_list",
                posRole?.object_manager.can_check_stock_list
            );
            setFieldValue(
                "object_manager.can_inventory_category",
                posRole?.object_manager.can_inventory_category
            );
            setFieldValue(
                "object_manager.can_inventory_unit",
                posRole?.object_manager.can_inventory_unit
            );
            setFieldValue(
                "object_manager.can_product_category",
                posRole?.object_manager.can_product_category
            );
            setFieldValue(
                "object_manager.can_promotion_category",
                posRole?.object_manager.can_promotion_category
            );
            setFieldValue(
                "object_manager.can_promotion_group",
                posRole?.object_manager.can_promotion_group
            );
            setFieldValue("can_pos_organization_setting", posRole?.can_pos_organization_setting);
            setFieldValue("can_role_permission", posRole?.can_role_permission);
            setFieldValue("can_import_pos_data", posRole?.can_import_pos_data);
            setFieldValue("can_all_sale_summary", posRole?.can_all_sale_summary);
            setFieldValue("sale_summary.can_sale_by_bill", posRole?.sale_summary.can_sale_by_bill);
            setFieldValue("sale_summary.can_sale_summary", posRole?.sale_summary.can_sale_summary);
            setFieldValue("can_all_sale_mix", posRole?.can_all_sale_mix);
            setFieldValue(
                "sale_mix.can_sale_by_product_summary",
                posRole?.sale_mix.can_sale_by_product_summary
            );
            setFieldValue(
                "sale_mix.can_sale_by_product_detail",
                posRole?.sale_mix.can_sale_by_product_detail
            );
            setFieldValue("sale_mix.can_sale_by_category", posRole?.sale_mix.can_sale_by_category);
            setFieldValue("sale_mix.can_sale_by_channel", posRole?.sale_mix.can_sale_by_channel);
            setFieldValue(
                "sale_mix.can_sale_by_payment_method",
                posRole?.sale_mix.can_sale_by_payment_method
            );
            setFieldValue("sale_mix.can_sale_by_staff", posRole?.sale_mix.can_sale_by_staff);
            setFieldValue("sale_mix.can_sale_by_branch", posRole?.sale_mix.can_sale_by_branch);
            setFieldValue("sale_mix.can_addon_report", posRole?.sale_mix.can_addon_report);
            setFieldValue("can_all_promotion", posRole?.can_all_promotion);
            setFieldValue(
                "promotion.can_promotion_by_promotion",
                posRole?.promotion.can_promotion_by_promotion
            );
            setFieldValue(
                "promotion.can_promotion_by_record",
                posRole?.promotion.can_promotion_by_record
            );
            setFieldValue("can_all_inventory_movement", posRole?.can_all_inventory_movement);
            setFieldValue(
                "inventory_movement.can_current_stock",
                posRole?.inventory_movement.can_current_stock
            );
            setFieldValue(
                "inventory_movement.can_inventory_movement_by_record",
                posRole?.inventory_movement.can_inventory_movement_by_record
            );
            setFieldValue(
                "inventory_movement.can_inventory_waste_record",
                posRole?.inventory_movement.can_inventory_waste_record
            );
            setFieldValue(
                "inventory_movement.can_inventory_check_record",
                posRole?.inventory_movement.can_inventory_check_record
            );
            setFieldValue(
                "inventory_movement.can_inventory_movement_summary",
                posRole?.inventory_movement.can_inventory_movement_summary
            );
            setFieldValue("can_all_po_pr_gr", posRole?.can_all_po_pr_gr);
            setFieldValue("po_pr_gr.can_gr_report", posRole?.po_pr_gr.can_gr_report);
            setFieldValue(
                "po_pr_gr.can_pr_forecast_report",
                posRole?.po_pr_gr.can_pr_forecast_report
            );
            setFieldValue("po_pr_gr.can_po_report", posRole?.po_pr_gr.can_po_report);
            setFieldValue("po_pr_gr.can_pr_report", posRole?.po_pr_gr.can_pr_report);
        } else {
            if (posRoleTemplate) {
                templateInit();
            }
        }
    }, [posRole, posRoleTemplate]);

    const templateInit = () => {
        setFieldValue("can_create_manage_product", posRoleTemplate?.can_create_manage_product);
        setFieldValue("can_assign_invertory", posRoleTemplate?.can_assign_invertory);
        setFieldValue("can_addon", posRoleTemplate?.can_addon);
        setFieldValue("can_product_promotion", posRoleTemplate?.can_product_promotion);
        setFieldValue("can_bill_promotion", posRoleTemplate?.can_bill_promotion);
        setFieldValue("store_access_type", posRoleTemplate?.store_access_type);
        setFieldValue("can_inventory_list", posRoleTemplate?.can_inventory_list);
        setFieldValue("can_supplier_list", posRoleTemplate?.can_supplier_list);
        setFieldValue("can_edit_inventory_qty", posRoleTemplate?.can_edit_inventory_qty);
        setFieldValue("can_check_stock", posRoleTemplate?.can_check_stock);
        setFieldValue("can_waste_record", posRoleTemplate?.can_waste_record);
        setFieldValue("can_inventory_require", posRoleTemplate?.can_inventory_require);
        setFieldValue("can_purchase_order", posRoleTemplate?.can_purchase_order);
        setFieldValue("can_goods_receive", posRoleTemplate?.can_goods_receive);
        setFieldValue("can_all_store_permission", posRoleTemplate?.can_all_store_permission);
        setFieldValue(
            "store_permission.can_permission",
            posRoleTemplate?.store_permission.can_permission
        );
        setFieldValue(
            "store_permission.can_payment",
            posRoleTemplate?.store_permission.can_payment
        );
        setFieldValue(
            "store_permission.can_store_detail",
            posRoleTemplate?.store_permission.can_store_detail
        );
        setFieldValue("can_channel", posRoleTemplate?.can_channel);
        setFieldValue("can_all_object_manager", posRoleTemplate?.can_all_object_manager);
        setFieldValue("object_manager.can_brand", posRoleTemplate?.object_manager.can_brand);
        setFieldValue(
            "object_manager.can_check_stock_list",
            posRoleTemplate?.object_manager.can_check_stock_list
        );
        setFieldValue(
            "object_manager.can_inventory_category",
            posRoleTemplate?.object_manager.can_inventory_category
        );
        setFieldValue(
            "object_manager.can_inventory_unit",
            posRoleTemplate?.object_manager.can_inventory_unit
        );
        setFieldValue(
            "object_manager.can_product_category",
            posRoleTemplate?.object_manager.can_product_category
        );
        setFieldValue(
            "object_manager.can_promotion_category",
            posRoleTemplate?.object_manager.can_promotion_category
        );
        setFieldValue(
            "object_manager.can_promotion_group",
            posRoleTemplate?.object_manager.can_promotion_group
        );
        setFieldValue(
            "can_pos_organization_setting",
            posRoleTemplate?.can_pos_organization_setting
        );
        setFieldValue("can_role_permission", posRoleTemplate?.can_role_permission);
        setFieldValue("can_import_pos_data", posRoleTemplate?.can_import_pos_data);
        setFieldValue("can_all_sale_summary", posRoleTemplate?.can_all_sale_summary);
        setFieldValue(
            "sale_summary.can_sale_by_bill",
            posRoleTemplate?.sale_summary.can_sale_by_bill
        );
        setFieldValue(
            "sale_summary.can_sale_summary",
            posRoleTemplate?.sale_summary.can_sale_summary
        );
        setFieldValue("can_all_sale_mix", posRoleTemplate?.can_all_sale_mix);
        setFieldValue(
            "sale_mix.can_sale_by_product_summary",
            posRoleTemplate?.sale_mix.can_sale_by_product_summary
        );
        setFieldValue(
            "sale_mix.can_sale_by_product_detail",
            posRoleTemplate?.sale_mix.can_sale_by_product_detail
        );
        setFieldValue(
            "sale_mix.can_sale_by_category",
            posRoleTemplate?.sale_mix.can_sale_by_category
        );
        setFieldValue(
            "sale_mix.can_sale_by_channel",
            posRoleTemplate?.sale_mix.can_sale_by_channel
        );
        setFieldValue(
            "sale_mix.can_sale_by_payment_method",
            posRoleTemplate?.sale_mix.can_sale_by_payment_method
        );
        setFieldValue("sale_mix.can_sale_by_staff", posRoleTemplate?.sale_mix.can_sale_by_staff);
        setFieldValue("sale_mix.can_sale_by_branch", posRoleTemplate?.sale_mix.can_sale_by_branch);
        setFieldValue("sale_mix.can_addon_report", posRoleTemplate?.sale_mix.can_addon_report);
        setFieldValue("can_all_promotion", posRoleTemplate?.can_all_promotion);
        setFieldValue(
            "promotion.can_promotion_by_promotion",
            posRoleTemplate?.promotion.can_promotion_by_promotion
        );
        setFieldValue(
            "promotion.can_promotion_by_record",
            posRoleTemplate?.promotion.can_promotion_by_record
        );
        setFieldValue("can_all_inventory_movement", posRoleTemplate?.can_all_inventory_movement);
        setFieldValue(
            "inventory_movement.can_current_stock",
            posRoleTemplate?.inventory_movement.can_current_stock
        );
        setFieldValue(
            "inventory_movement.can_inventory_movement_by_record",
            posRoleTemplate?.inventory_movement.can_inventory_movement_by_record
        );
        setFieldValue(
            "inventory_movement.can_inventory_waste_record",
            posRoleTemplate?.inventory_movement.can_inventory_waste_record
        );
        setFieldValue(
            "inventory_movement.can_inventory_check_record",
            posRoleTemplate?.inventory_movement.can_inventory_check_record
        );
        setFieldValue(
            "inventory_movement.can_inventory_movement_summary",
            posRoleTemplate?.inventory_movement.can_inventory_movement_summary
        );
        setFieldValue("can_all_po_pr_gr", posRoleTemplate?.can_all_po_pr_gr);
        setFieldValue("po_pr_gr.can_gr_report", posRoleTemplate?.po_pr_gr.can_gr_report);
        setFieldValue(
            "po_pr_gr.can_pr_forecast_report",
            posRoleTemplate?.po_pr_gr.can_pr_forecast_report
        );
        setFieldValue("po_pr_gr.can_po_report", posRoleTemplate?.po_pr_gr.can_po_report);
        setFieldValue("po_pr_gr.can_pr_report", posRoleTemplate?.po_pr_gr.can_pr_report);
    };

    const handleNewsSubmit = async () => {
        dispatch(setLoading(true));
        if (pathName === PATH_ROLES_PERMISSIONS) {
            try {
                const create = await rolePosAPI.createPosRole({
                    organization_id: organization_id,
                    pos_role_name: values.pos_role_name,
                    pos_role_detail: values.pos_role_detail,
                    can_create_manage_product: values.can_create_manage_product,
                    can_assign_invertory: values.can_assign_invertory,
                    can_addon: values.can_addon,
                    can_product_promotion: values.can_product_promotion,
                    can_bill_promotion: values.can_bill_promotion,
                    store_access_type: values.store_access_type,
                    can_inventory_list: values.can_inventory_list,
                    can_supplier_list: values.can_supplier_list,
                    can_edit_inventory_qty: values.can_edit_inventory_qty,
                    can_check_stock: values.can_check_stock,
                    can_waste_record: values.can_waste_record,
                    can_inventory_require: values.can_inventory_require,
                    can_purchase_order: values.can_purchase_order,
                    can_goods_receive: values.can_goods_receive,
                    can_all_store_permission: values.can_all_store_permission,
                    store_permission: {
                        can_permission: values.store_permission.can_permission,
                        can_payment: values.store_permission.can_payment,

                        can_store_detail: values.store_permission.can_store_detail,
                    },
                    can_channel: values.can_channel,
                    can_all_object_manager: values.can_all_object_manager,
                    object_manager: {
                        can_brand: values.object_manager.can_brand,

                        can_check_stock_list: values.object_manager.can_check_stock_list,
                        can_inventory_category: values.object_manager.can_inventory_category,
                        can_inventory_unit: values.object_manager.can_inventory_unit,
                        can_product_category: values.object_manager.can_product_category,
                        can_promotion_category: values.object_manager.can_promotion_category,
                        can_promotion_group: values.object_manager.can_promotion_group,
                    },
                    can_pos_organization_setting: values.can_pos_organization_setting,
                    can_role_permission: values.can_role_permission,
                    can_import_pos_data: values.can_import_pos_data,
                    can_all_sale_summary: values.can_all_sale_summary,
                    sale_summary: {
                        can_sale_by_bill: values.sale_summary.can_sale_by_bill,
                        can_sale_summary: values.sale_summary.can_sale_summary,
                    },
                    can_all_sale_mix: values.can_all_sale_mix,
                    sale_mix: {
                        can_sale_by_product_summary: values.sale_mix.can_sale_by_product_summary,
                        can_sale_by_product_detail: values.sale_mix.can_sale_by_product_detail,
                        can_sale_by_category: values.sale_mix.can_sale_by_category,
                        can_sale_by_channel: values.sale_mix.can_sale_by_channel,

                        can_sale_by_payment_method: values.sale_mix.can_sale_by_payment_method,
                        can_sale_by_staff: values.sale_mix.can_sale_by_staff,
                        can_sale_by_branch: values.sale_mix.can_sale_by_branch,
                        can_addon_report: values.sale_mix.can_addon_report,
                    },
                    can_all_promotion: values.can_all_promotion,
                    promotion: {
                        can_promotion_by_promotion: values.promotion.can_promotion_by_promotion,
                        can_promotion_by_record: values.promotion.can_promotion_by_record,
                    },
                    can_all_inventory_movement: values.can_all_inventory_movement,
                    inventory_movement: {
                        can_current_stock: values.inventory_movement.can_current_stock,

                        can_inventory_movement_by_record:
                            values.inventory_movement.can_inventory_movement_by_record,
                        can_inventory_waste_record:
                            values.inventory_movement.can_inventory_waste_record,
                        can_inventory_check_record:
                            values.inventory_movement.can_inventory_check_record,

                        can_inventory_movement_summary:
                            values.inventory_movement.can_inventory_movement_summary,
                    },
                    can_all_po_pr_gr: values.can_all_po_pr_gr,
                    po_pr_gr: {
                        can_gr_report: values.po_pr_gr.can_gr_report,

                        can_pr_forecast_report: values.po_pr_gr.can_pr_forecast_report,
                        can_po_report: values.po_pr_gr.can_po_report,
                        can_pr_report: values.po_pr_gr.can_pr_report,
                    },
                });
                if (create.data.error === "0") {
                    success(t("message.create.success"));
                    history.push(PATH_ROLES_PERMISSIONS_List);
                }
            } catch (err: any) {
                if (err.response) {
                    error(showErrorMessage(err.response));
                } else error(t("message.error"));
                dispatch(setLoading(false));
            } finally {
                dispatch(setLoading(false));
            }
        } else {
            try {
                const update = await rolePosAPI.updatePosRole({
                    organization_id: organization_id,
                    pos_role_id: id,
                    pos_role_name: values.pos_role_name,
                    pos_role_detail: values.pos_role_detail,
                    can_create_manage_product: values.can_create_manage_product,
                    can_assign_invertory: values.can_assign_invertory,
                    can_addon: values.can_addon,
                    can_product_promotion: values.can_product_promotion,
                    can_bill_promotion: values.can_bill_promotion,
                    store_access_type: values.store_access_type,
                    can_inventory_list: values.can_inventory_list,
                    can_supplier_list: values.can_supplier_list,
                    can_edit_inventory_qty: values.can_edit_inventory_qty,
                    can_check_stock: values.can_check_stock,
                    can_waste_record: values.can_waste_record,
                    can_inventory_require: values.can_inventory_require,
                    can_purchase_order: values.can_purchase_order,
                    can_goods_receive: values.can_goods_receive,
                    can_all_store_permission: values.can_all_store_permission,
                    store_permission: {
                        can_permission: values.store_permission.can_permission,
                        can_payment: values.store_permission.can_payment,

                        can_store_detail: values.store_permission.can_store_detail,
                    },
                    can_channel: values.can_channel,
                    can_all_object_manager: values.can_all_object_manager,
                    object_manager: {
                        can_brand: values.object_manager.can_brand,

                        can_check_stock_list: values.object_manager.can_check_stock_list,
                        can_inventory_category: values.object_manager.can_inventory_category,
                        can_inventory_unit: values.object_manager.can_inventory_unit,
                        can_product_category: values.object_manager.can_product_category,
                        can_promotion_category: values.object_manager.can_promotion_category,
                        can_promotion_group: values.object_manager.can_promotion_group,
                    },
                    can_pos_organization_setting: values.can_pos_organization_setting,
                    can_role_permission: values.can_role_permission,
                    can_import_pos_data: values.can_import_pos_data,
                    can_all_sale_summary: values.can_all_sale_summary,
                    sale_summary: {
                        can_sale_by_bill: values.sale_summary.can_sale_by_bill,
                        can_sale_summary: values.sale_summary.can_sale_summary,
                    },
                    can_all_sale_mix: values.can_all_sale_mix,
                    sale_mix: {
                        can_sale_by_product_summary: values.sale_mix.can_sale_by_product_summary,
                        can_sale_by_product_detail: values.sale_mix.can_sale_by_product_detail,
                        can_sale_by_category: values.sale_mix.can_sale_by_category,
                        can_sale_by_channel: values.sale_mix.can_sale_by_channel,

                        can_sale_by_payment_method: values.sale_mix.can_sale_by_payment_method,
                        can_sale_by_staff: values.sale_mix.can_sale_by_staff,
                        can_sale_by_branch: values.sale_mix.can_sale_by_branch,
                        can_addon_report: values.sale_mix.can_addon_report,
                    },
                    can_all_promotion: values.can_all_promotion,
                    promotion: {
                        can_promotion_by_promotion: values.promotion.can_promotion_by_promotion,
                        can_promotion_by_record: values.promotion.can_promotion_by_record,
                    },
                    can_all_inventory_movement: values.can_all_inventory_movement,
                    inventory_movement: {
                        can_current_stock: values.inventory_movement.can_current_stock,

                        can_inventory_movement_by_record:
                            values.inventory_movement.can_inventory_movement_by_record,
                        can_inventory_waste_record:
                            values.inventory_movement.can_inventory_waste_record,
                        can_inventory_check_record:
                            values.inventory_movement.can_inventory_check_record,

                        can_inventory_movement_summary:
                            values.inventory_movement.can_inventory_movement_summary,
                    },
                    can_all_po_pr_gr: values.can_all_po_pr_gr,
                    po_pr_gr: {
                        can_gr_report: values.po_pr_gr.can_gr_report,

                        can_pr_forecast_report: values.po_pr_gr.can_pr_forecast_report,
                        can_po_report: values.po_pr_gr.can_po_report,
                        can_pr_report: values.po_pr_gr.can_pr_report,
                    },
                });
                if (update.data.error === "0") {
                    success(t("message.update.success"));
                    history.push(PATH_ROLES_PERMISSIONS_List);
                }
            } catch (err: any) {
                if (err.response) {
                    error(showErrorMessage(err.response));
                } else error(t("message.error"));
                dispatch(setLoading(false));
            } finally {
                dispatch(setLoading(false));
            }
        }
    };

    const { values, handleChange, handleBlur, handleSubmit, errors, touched, setFieldValue } =
        useFormik({
            initialValues: initData,
            validationSchema: createNews,
            enableReinitialize: true,
            onSubmit: handleNewsSubmit,
        });

    return (
        <div className="form-wrap">
            <form onSubmit={handleSubmit}>
                <StyledRolesPermissions>
                    <div className="page-header">
                        <h3>{t("page.roles_permissions_settings")}</h3>
                        <p className="body2" style={{ margin: 0, color: "#A5A5A5" }}>
                            {t("page.roles_permission_desc")}
                        </p>
                    </div>
                    <div className="page-body">
                        <div className="card-wrap">
                            <StyledCard>
                                <div className="title" style={{ fontSize: "25px", color: "#000" }}>
                                    {t("page.role")}
                                </div>
                                <Row gutter={32}>
                                    <Col span={12}>
                                        <div className="label">
                                            <label>{t("page.role_name")}*</label>
                                        </div>
                                        <SharedInput
                                            name="pos_role_name"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.pos_role_name}
                                            errors={values.pos_role_name}
                                            parentClassName="mb-6"
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <div className="label">
                                            <label>{t("page.role_details")}*</label>
                                        </div>
                                        <SharedInput
                                            name="pos_role_detail"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.pos_role_detail}
                                            errors={values.pos_role_detail}
                                            parentClassName="mb-6"
                                        />
                                    </Col>
                                </Row>
                            </StyledCard>
                        </div>
                        <div className="card-wrap">
                            <StyledCard>
                                <Row>
                                    <Col span={24}>
                                        <div className="topFeaturesRow">
                                            <div
                                                className="title"
                                                style={{ fontSize: "25px", color: "#000" }}
                                            >
                                                {t("page.features_permissions")}
                                            </div>
                                            <span onClick={templateInit}>ล้างทั้งหมด</span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <div className="sectionSeparator">
                                            <p className="body">{t("page.product_management")}</p>
                                        </div>
                                        <div className="checkBoxes">
                                            <Checkbox
                                                checked={values.can_create_manage_product}
                                                onChange={(value) =>
                                                    setFieldValue(
                                                        "can_create_manage_product",
                                                        value.target.checked
                                                    )
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.create_manage_product")}</span>
                                            </Checkbox>
                                            <Checkbox
                                                checked={values.can_assign_invertory}
                                                onChange={(value) =>
                                                    setFieldValue(
                                                        "can_assign_invertory",
                                                        value.target.checked
                                                    )
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.assign_inventory")}</span>
                                            </Checkbox>
                                            <Checkbox
                                                checked={values.can_addon}
                                                onChange={(value) =>
                                                    setFieldValue("can_addon", value.target.checked)
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.add_on")}</span>
                                            </Checkbox>
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 20 }}>
                                    <Col span={24}>
                                        <div className="sectionSeparator">
                                            <p className="body">{t("page.promotions")}</p>
                                        </div>
                                        <div className="checkBoxes">
                                            <Checkbox
                                                checked={values.can_product_promotion}
                                                onChange={(value) =>
                                                    setFieldValue(
                                                        "can_product_promotion",
                                                        value.target.checked
                                                    )
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.product_promotions")}</span>
                                            </Checkbox>
                                            <Checkbox
                                                checked={values.can_bill_promotion}
                                                onChange={(value) =>
                                                    setFieldValue(
                                                        "can_bill_promotion",
                                                        value.target.checked
                                                    )
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.bill_promotions")}</span>
                                            </Checkbox>
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 20 }}>
                                    <Col span={24}>
                                        <div className="sectionSeparator">
                                            <p className="body">{t("page.store_access")}</p>
                                        </div>
                                        <div className="checkBoxRow">
                                            <Radio.Group
                                                onChange={(value) => {
                                                    setFieldValue(
                                                        "store_access_type",
                                                        value.target.value
                                                    );
                                                }}
                                                value={values.store_access_type}
                                                className={"isMultipleRadioGroup"}
                                            >
                                                <Radio value={"all_store"}>
                                                    {t("page.all_stores")}
                                                </Radio>
                                                <Radio value={"specifit_store"}>
                                                    {t("page.specific_stores")}
                                                </Radio>
                                            </Radio.Group>
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 20 }}>
                                    <Col span={24}>
                                        <div className="sectionSeparator">
                                            <p className="body">{t("page.inventory_management")}</p>
                                        </div>
                                        <div className="checkBoxes">
                                            <Checkbox
                                                checked={values.can_inventory_list}
                                                onChange={(value) =>
                                                    setFieldValue(
                                                        "can_inventory_list",
                                                        value.target.checked
                                                    )
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.inventory_list")}</span>
                                            </Checkbox>
                                            <Checkbox
                                                checked={values.can_supplier_list}
                                                onChange={(value) =>
                                                    setFieldValue(
                                                        "can_supplier_list",
                                                        value.target.checked
                                                    )
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.supplier_list")}</span>
                                            </Checkbox>
                                            <Checkbox
                                                checked={values.can_edit_inventory_qty}
                                                onChange={(value) =>
                                                    setFieldValue(
                                                        "can_edit_inventory_qty",
                                                        value.target.checked
                                                    )
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.edit_inventory_qty")}</span>
                                            </Checkbox>
                                            <Checkbox
                                                checked={values.can_check_stock}
                                                onChange={(value) =>
                                                    setFieldValue(
                                                        "can_check_stock",
                                                        value.target.checked
                                                    )
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.check_stock")}</span>
                                            </Checkbox>
                                            <Checkbox
                                                checked={values.can_waste_record}
                                                onChange={(value) =>
                                                    setFieldValue(
                                                        "can_waste_record",
                                                        value.target.checked
                                                    )
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.waste_record")}</span>
                                            </Checkbox>
                                            <Checkbox
                                                checked={values.can_inventory_require}
                                                onChange={(value) =>
                                                    setFieldValue(
                                                        "can_inventory_require",
                                                        value.target.checked
                                                    )
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.inventory_request")}</span>
                                            </Checkbox>
                                            <Checkbox
                                                checked={values.can_purchase_order}
                                                onChange={(value) =>
                                                    setFieldValue(
                                                        "can_purchase_order",
                                                        value.target.checked
                                                    )
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.purchaseOrder")}</span>
                                            </Checkbox>
                                            <Checkbox
                                                checked={values.can_goods_receive}
                                                onChange={(value) =>
                                                    setFieldValue(
                                                        "can_goods_receive",
                                                        value.target.checked
                                                    )
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.goods_received")}</span>
                                            </Checkbox>
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 20 }}>
                                    <Col span={24}>
                                        <div className="sectionSeparator">
                                            <p className="body">
                                                {t("page.store_channel_management")}
                                            </p>
                                        </div>
                                        <div className="checkBoxes" style={{ paddingLeft: 8 }}>
                                            <Checkbox
                                                checked={
                                                    values.store_permission.can_permission ||
                                                    values.store_permission.can_payment ||
                                                    values.store_permission.can_store_detail
                                                        ? true
                                                        : false
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.store_settings")}</span>
                                            </Checkbox>
                                        </div>
                                        <div
                                            className="checkBoxRow"
                                            style={{ marginTop: 20, paddingLeft: 20 }}
                                        >
                                            <Checkbox
                                                checked={values.store_permission.can_permission}
                                                onChange={(value) =>
                                                    setFieldValue(
                                                        "store_permission.can_permission",
                                                        value.target.checked
                                                    )
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.permissions")}</span>
                                            </Checkbox>
                                            <Checkbox
                                                checked={values.store_permission.can_payment}
                                                onChange={(value) =>
                                                    setFieldValue(
                                                        "store_permission.can_payment",
                                                        value.target.checked
                                                    )
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.payments")}</span>
                                            </Checkbox>
                                            <Checkbox
                                                checked={values.store_permission.can_store_detail}
                                                onChange={(value) =>
                                                    setFieldValue(
                                                        "store_permission.can_store_detail",
                                                        value.target.checked
                                                    )
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.store_details")}</span>
                                            </Checkbox>
                                        </div>
                                        <div
                                            className="checkBoxes"
                                            style={{ marginTop: 20, paddingLeft: 8 }}
                                        >
                                            <Checkbox
                                                checked={values.can_channel}
                                                onChange={(value) =>
                                                    setFieldValue(
                                                        "can_channel",
                                                        value.target.checked
                                                    )
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.channel")}</span>
                                            </Checkbox>
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 20 }}>
                                    <Col span={24}>
                                        <div className="sectionSeparator">
                                            <p className="body">{t("page.settings")}</p>
                                        </div>
                                        <div className="checkBoxes" style={{ paddingLeft: 8 }}>
                                            <Checkbox
                                                checked={
                                                    values.object_manager.can_brand ||
                                                    values.object_manager.can_check_stock_list ||
                                                    values.object_manager.can_inventory_category ||
                                                    values.object_manager.can_inventory_unit ||
                                                    values.object_manager.can_product_category ||
                                                    values.object_manager.can_promotion_category ||
                                                    values.object_manager.can_promotion_group
                                                        ? true
                                                        : false
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.object_manager")}</span>
                                            </Checkbox>
                                        </div>
                                        <div
                                            className="checkBoxRow"
                                            style={{ marginTop: 20, paddingLeft: 20 }}
                                        >
                                            <Checkbox
                                                checked={values.object_manager.can_product_category}
                                                onChange={(value) =>
                                                    setFieldValue(
                                                        "object_manager.can_product_category",
                                                        value.target.checked
                                                    )
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.product_category")}</span>
                                            </Checkbox>
                                            <Checkbox
                                                checked={
                                                    values.object_manager.can_inventory_category
                                                }
                                                onChange={(value) =>
                                                    setFieldValue(
                                                        "object_manager.can_inventory_category",
                                                        value.target.checked
                                                    )
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.inventory_category")}</span>
                                            </Checkbox>
                                            <Checkbox
                                                checked={values.object_manager.can_inventory_unit}
                                                onChange={(value) =>
                                                    setFieldValue(
                                                        "object_manager.can_inventory_unit",
                                                        value.target.checked
                                                    )
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.inventory_units")}</span>
                                            </Checkbox>
                                        </div>
                                        <div
                                            className="checkBoxRow"
                                            style={{ marginTop: 20, paddingLeft: 20 }}
                                        >
                                            <Checkbox
                                                checked={
                                                    values.object_manager.can_promotion_category
                                                }
                                                onChange={(value) =>
                                                    setFieldValue(
                                                        "object_manager.can_promotion_category",
                                                        value.target.checked
                                                    )
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.promotion_category")}</span>
                                            </Checkbox>
                                            <Checkbox
                                                checked={values.object_manager.can_promotion_group}
                                                onChange={(value) =>
                                                    setFieldValue(
                                                        "object_manager.can_promotion_group",
                                                        value.target.checked
                                                    )
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.promotion_group")}</span>
                                            </Checkbox>
                                            <Checkbox
                                                checked={values.object_manager.can_brand}
                                                onChange={(value) =>
                                                    setFieldValue(
                                                        "object_manager.can_brand",
                                                        value.target.checked
                                                    )
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.brand_roles")}</span>
                                            </Checkbox>
                                        </div>
                                        <div
                                            className="checkBoxRow"
                                            style={{ marginTop: 20, paddingLeft: 20 }}
                                        >
                                            <Checkbox
                                                checked={values.object_manager.can_check_stock_list}
                                                onChange={(value) =>
                                                    setFieldValue(
                                                        "object_manager.can_check_stock_list",
                                                        value.target.checked
                                                    )
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.check_stock_list")}</span>
                                            </Checkbox>
                                        </div>
                                        <div
                                            className="checkBoxes"
                                            style={{ marginTop: 20, paddingLeft: 8 }}
                                        >
                                            <Checkbox
                                                checked={values.can_pos_organization_setting}
                                                onChange={(value) =>
                                                    setFieldValue(
                                                        "can_pos_organization_setting",
                                                        value.target.checked
                                                    )
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.pos_organization_settings")}</span>
                                            </Checkbox>
                                        </div>
                                        <div
                                            className="checkBoxes"
                                            style={{ marginTop: 20, paddingLeft: 8 }}
                                        >
                                            <Checkbox
                                                checked={values.can_role_permission}
                                                onChange={(value) =>
                                                    setFieldValue(
                                                        "can_role_permission",
                                                        value.target.checked
                                                    )
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.roles_permissions")}</span>
                                            </Checkbox>
                                        </div>
                                        <div
                                            className="checkBoxes"
                                            style={{ marginTop: 20, paddingLeft: 8 }}
                                        >
                                            <Checkbox
                                                checked={values.can_import_pos_data}
                                                onChange={(value) =>
                                                    setFieldValue(
                                                        "can_import_pos_data",
                                                        value.target.checked
                                                    )
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.import_pos_data")}</span>
                                            </Checkbox>
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 20 }}>
                                    <Col span={24}>
                                        <div className="sectionSeparator">
                                            <p className="body">{t("page.pos_reports")}</p>
                                        </div>
                                        <div className="checkBoxes" style={{ paddingLeft: 8 }}>
                                            <Checkbox
                                                checked={
                                                    values.sale_summary.can_sale_summary ||
                                                    values.sale_summary.can_sale_by_bill
                                                        ? true
                                                        : false
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.sales_summary")}</span>
                                            </Checkbox>
                                        </div>
                                        <div
                                            className="checkBoxRow"
                                            style={{ marginTop: 20, paddingLeft: 20 }}
                                        >
                                            <Checkbox
                                                checked={values.sale_summary.can_sale_summary}
                                                onChange={(value) =>
                                                    setFieldValue(
                                                        "sale_summary.can_sale_summary",
                                                        value.target.checked
                                                    )
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.sales_summary")}</span>
                                            </Checkbox>
                                            <Checkbox
                                                checked={values.sale_summary.can_sale_by_bill}
                                                onChange={(value) =>
                                                    setFieldValue(
                                                        "sale_summary.can_sale_by_bill",
                                                        value.target.checked
                                                    )
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.sales_by_bill")}</span>
                                            </Checkbox>
                                        </div>
                                        <div
                                            className="checkBoxes"
                                            style={{ marginTop: 20, paddingLeft: 8 }}
                                        >
                                            <Checkbox
                                                checked={
                                                    values.sale_mix.can_addon_report ||
                                                    values.sale_mix.can_sale_by_branch ||
                                                    values.sale_mix.can_sale_by_category ||
                                                    values.sale_mix.can_sale_by_channel ||
                                                    values.sale_mix.can_sale_by_payment_method ||
                                                    values.sale_mix.can_sale_by_product_detail ||
                                                    values.sale_mix.can_sale_by_product_summary ||
                                                    values.sale_mix.can_sale_by_staff
                                                        ? true
                                                        : false
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.sales_mix")}</span>
                                            </Checkbox>
                                        </div>
                                        <div
                                            className="checkBoxRow"
                                            style={{ marginTop: 20, paddingLeft: 20 }}
                                        >
                                            <Checkbox
                                                checked={
                                                    values.sale_mix.can_sale_by_product_summary
                                                }
                                                onChange={(value) =>
                                                    setFieldValue(
                                                        "sale_mix.can_sale_by_product_summary",
                                                        value.target.checked
                                                    )
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.sale_by_product_summary")}</span>
                                            </Checkbox>
                                            <Checkbox
                                                checked={values.sale_mix.can_sale_by_product_detail}
                                                onChange={(value) =>
                                                    setFieldValue(
                                                        "sale_mix.can_sale_by_product_detail",
                                                        value.target.checked
                                                    )
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.sale_by_product_detail")}</span>
                                            </Checkbox>
                                            <Checkbox
                                                checked={values.sale_mix.can_sale_by_category}
                                                onChange={(value) =>
                                                    setFieldValue(
                                                        "sale_mix.can_sale_by_category",
                                                        value.target.checked
                                                    )
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.sale_by_category")}</span>
                                            </Checkbox>
                                        </div>
                                        <div
                                            className="checkBoxRow"
                                            style={{ marginTop: 20, paddingLeft: 20 }}
                                        >
                                            <Checkbox
                                                checked={values.sale_mix.can_sale_by_channel}
                                                onChange={(value) =>
                                                    setFieldValue(
                                                        "sale_mix.can_sale_by_channel",
                                                        value.target.checked
                                                    )
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.sale_by_channel")}</span>
                                            </Checkbox>
                                            <Checkbox
                                                checked={values.sale_mix.can_sale_by_payment_method}
                                                onChange={(value) =>
                                                    setFieldValue(
                                                        "sale_mix.can_sale_by_payment_method",
                                                        value.target.checked
                                                    )
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.sales_by_payment_method")}</span>
                                            </Checkbox>
                                            <Checkbox
                                                checked={values.sale_mix.can_sale_by_staff}
                                                onChange={(value) =>
                                                    setFieldValue(
                                                        "sale_mix.can_sale_by_staff",
                                                        value.target.checked
                                                    )
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.sale_by_staff")}</span>
                                            </Checkbox>
                                        </div>
                                        <div
                                            className="checkBoxRow"
                                            style={{ marginTop: 20, paddingLeft: 20 }}
                                        >
                                            <Checkbox
                                                checked={values.sale_mix.can_sale_by_branch}
                                                onChange={(value) =>
                                                    setFieldValue(
                                                        "sale_mix.can_sale_by_branch",
                                                        value.target.checked
                                                    )
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.sale_by_branch")}</span>
                                            </Checkbox>
                                            <Checkbox
                                                checked={values.sale_mix.can_addon_report}
                                                onChange={(value) =>
                                                    setFieldValue(
                                                        "sale_mix.can_addon_report",
                                                        value.target.checked
                                                    )
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.add_on_report")}</span>
                                            </Checkbox>
                                        </div>
                                        <div
                                            className="checkBoxes"
                                            style={{ marginTop: 20, paddingLeft: 8 }}
                                        >
                                            <Checkbox
                                                checked={
                                                    values.promotion.can_promotion_by_promotion ||
                                                    values.promotion.can_promotion_by_record
                                                        ? true
                                                        : false
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.promotion")}</span>
                                            </Checkbox>
                                        </div>
                                        <div
                                            className="checkBoxRow"
                                            style={{ marginTop: 20, paddingLeft: 20 }}
                                        >
                                            <Checkbox
                                                checked={
                                                    values.promotion.can_promotion_by_promotion
                                                }
                                                onChange={(value) =>
                                                    setFieldValue(
                                                        "promotion.can_promotion_by_promotion",
                                                        value.target.checked
                                                    )
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.promotion_summary")}</span>
                                            </Checkbox>
                                            <Checkbox
                                                checked={values.promotion.can_promotion_by_record}
                                                onChange={(value) =>
                                                    setFieldValue(
                                                        "promotion.can_promotion_by_record",
                                                        value.target.checked
                                                    )
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.promotion_by_record")}</span>
                                            </Checkbox>
                                        </div>
                                        <div
                                            className="checkBoxes"
                                            style={{ marginTop: 20, paddingLeft: 8 }}
                                        >
                                            <Checkbox
                                                checked={
                                                    values.inventory_movement.can_current_stock ||
                                                    values.inventory_movement
                                                        .can_inventory_check_record ||
                                                    values.inventory_movement
                                                        .can_inventory_movement_by_record ||
                                                    values.inventory_movement
                                                        .can_inventory_movement_summary ||
                                                    values.inventory_movement
                                                        .can_inventory_waste_record
                                                        ? true
                                                        : false
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.inventory_movement")}</span>
                                            </Checkbox>
                                        </div>
                                        <div
                                            className="checkBoxRow"
                                            style={{ marginTop: 20, paddingLeft: 20 }}
                                        >
                                            <Checkbox
                                                checked={
                                                    values.inventory_movement.can_current_stock
                                                }
                                                onChange={(value) =>
                                                    setFieldValue(
                                                        "inventory_movement.can_current_stock",
                                                        value.target.checked
                                                    )
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.current_stock")}</span>
                                            </Checkbox>
                                            <Checkbox
                                                checked={
                                                    values.inventory_movement
                                                        .can_inventory_movement_summary
                                                }
                                                onChange={(value) =>
                                                    setFieldValue(
                                                        "inventory_movement.can_inventory_movement_summary",
                                                        value.target.checked
                                                    )
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.movement_summary")}</span>
                                            </Checkbox>
                                            <Checkbox
                                                checked={
                                                    values.inventory_movement
                                                        .can_inventory_movement_by_record
                                                }
                                                onChange={(value) =>
                                                    setFieldValue(
                                                        "inventory_movement.can_inventory_movement_by_record",
                                                        value.target.checked
                                                    )
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.movement_by_record")}</span>
                                            </Checkbox>
                                        </div>
                                        <div
                                            className="checkBoxRow"
                                            style={{ marginTop: 20, paddingLeft: 20 }}
                                        >
                                            <Checkbox
                                                checked={
                                                    values.inventory_movement
                                                        .can_inventory_waste_record
                                                }
                                                onChange={(value) =>
                                                    setFieldValue(
                                                        "inventory_movement.can_inventory_waste_record",
                                                        value.target.checked
                                                    )
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.inventory_waste_record")}</span>
                                            </Checkbox>
                                            <Checkbox
                                                checked={
                                                    values.inventory_movement
                                                        .can_inventory_check_record
                                                }
                                                onChange={(value) =>
                                                    setFieldValue(
                                                        "inventory_movement.can_inventory_check_record",
                                                        value.target.checked
                                                    )
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.inventory_check_record")}</span>
                                            </Checkbox>
                                        </div>
                                        <div
                                            className="checkBoxes"
                                            style={{ marginTop: 20, paddingLeft: 8 }}
                                        >
                                            <Checkbox
                                                checked={
                                                    values.po_pr_gr.can_gr_report ||
                                                    values.po_pr_gr.can_po_report ||
                                                    values.po_pr_gr.can_pr_forecast_report ||
                                                    values.po_pr_gr.can_pr_report
                                                        ? true
                                                        : false
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.pr_po_gr")}</span>
                                            </Checkbox>
                                        </div>
                                        <div
                                            className="checkBoxRow"
                                            style={{ marginTop: 20, paddingLeft: 20 }}
                                        >
                                            <Checkbox
                                                checked={values.po_pr_gr.can_pr_report}
                                                onChange={(value) =>
                                                    setFieldValue(
                                                        "po_pr_gr.can_pr_report",
                                                        value.target.checked
                                                    )
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.pr_report")}</span>
                                            </Checkbox>
                                            <Checkbox
                                                checked={values.po_pr_gr.can_pr_forecast_report}
                                                onChange={(value) =>
                                                    setFieldValue(
                                                        "po_pr_gr.can_pr_forecast_report",
                                                        value.target.checked
                                                    )
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.pr_forecast_report")}</span>
                                            </Checkbox>
                                            <Checkbox
                                                checked={values.po_pr_gr.can_po_report}
                                                onChange={(value) =>
                                                    setFieldValue(
                                                        "po_pr_gr.can_po_report",
                                                        value.target.checked
                                                    )
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.po_report")}</span>
                                            </Checkbox>
                                        </div>
                                        <div
                                            className="checkBoxRow"
                                            style={{ marginTop: 20, paddingLeft: 20 }}
                                        >
                                            <Checkbox
                                                checked={values.po_pr_gr.can_gr_report}
                                                onChange={(value) =>
                                                    setFieldValue(
                                                        "po_pr_gr.can_gr_report",
                                                        value.target.checked
                                                    )
                                                }
                                            >
                                                {" "}
                                                <span>{t("page.gr_report")}</span>
                                            </Checkbox>
                                        </div>
                                    </Col>
                                </Row>
                            </StyledCard>
                        </div>
                        <div className="btn-layout">
                            <StyledSubmitButton
                                type="default"
                                text={t("page.save")}
                                htmlType="submit"
                                onClick={handleNewsSubmit}
                            />
                            <StyledCancelButton
                                type="sub"
                                text={t("page.cancel")}
                                htmlType="button"
                                onClick={() => history.push(PATH_ROLES_PERMISSIONS_List)}
                            />
                        </div>
                    </div>
                </StyledRolesPermissions>
            </form>
        </div>
    );
});

const StyledRolesPermissions = styled.div`
    .topFeaturesRow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        span {
            cursor: pointer;
            color: #0d6efd;
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            text-decoration-line: underline;
        }
    }
    .sectionSeparator {
        display: flex;
        padding: 12px 20px;
        background: #f7f9fc;
        border-radius: 8px;
        margin-bottom: 20px;
        .body {
            margin: 0;
            color: #29304d;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }
    .checkBoxes {
        display: flex;
        flex-direction: column;
        place-items: baseline;
        gap: 20px;
        .body2 {
            color: #29304d;
        }
    }
    .checkBoxRow {
        display: flex;
        flex-direction: row;
        gap: 30px;
        padding-left: 8px;
    }
`;

const UploadStyle = styled.div`
    .preview-field {
        padding: 20px;
        display: flex;
        place-content: center;
        .img-wrap img {
            height: auto;
        }
        .button-upload-preview {
            right: auto;
            bottom: 0px;
            width: auto;
        }
    }
    .uploadImage .ant-col-md-6 {
        width: 100%;
    }
`;
