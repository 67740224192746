export const SetReasonIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
        >
            <path
                d="M16.8229 23.25H19.6354C22.4062 23.25 23.6979 22.01 23.6979 19.35V16.65C23.6979 13.99 22.4062 12.75 19.6354 12.75H16.8229C14.052 12.75 12.7604 13.99 12.7604 16.65V19.35C12.7604 22.01 14.052 23.25 16.8229 23.25ZM19.6354 14.25C21.552 14.25 22.1354 14.81 22.1354 16.65V19.35C22.1354 21.19 21.552 21.75 19.6354 21.75H16.8229C14.9062 21.75 14.3229 21.19 14.3229 19.35V16.65C14.3229 14.81 14.9062 14.25 16.8229 14.25H19.6354Z"
                fill="white"
            />
            <path
                d="M9.37496 23.25C9.65621 23.25 9.91663 23.1 10.052 22.87C10.1875 22.64 10.1875 22.35 10.0416 22.12L8.94788 20.37C8.72913 20.01 8.24996 19.9 7.87496 20.11C7.49996 20.32 7.38538 20.78 7.60413 21.14L7.88538 21.59C5.01038 20.94 2.85413 18.46 2.85413 15.5C2.85413 15.09 2.49996 14.75 2.07288 14.75C1.6458 14.75 1.29163 15.09 1.29163 15.5C1.30205 19.77 4.92705 23.25 9.37496 23.25Z"
                fill="white"
            />
            <path
                d="M22.9166 10.25C23.3437 10.25 23.6979 9.91 23.6979 9.5C23.6979 5.23 20.0729 1.75 15.625 1.75C15.3437 1.75 15.0833 1.9 14.9479 2.13C14.8125 2.36 14.8125 2.65 14.9583 2.88L16.052 4.64C16.2708 4.99 16.75 5.11 17.125 4.89C17.4895 4.68 17.6145 4.22 17.3854 3.86L17.1041 3.41C19.9895 4.06 22.1354 6.54 22.1354 9.5C22.1354 9.91 22.4895 10.25 22.9166 10.25Z"
                fill="white"
            />
            <path
                d="M6.77075 12.25C9.78117 12.25 12.2395 9.89 12.2395 7C12.2395 4.11 9.78117 1.75 6.77075 1.75C3.76034 1.75 1.302 4.11 1.302 7C1.302 9.89 3.76034 12.25 6.77075 12.25ZM6.77075 3.25C8.927 3.25 10.677 4.93 10.677 7C10.677 9.07 8.927 10.75 6.77075 10.75C4.6145 10.75 2.8645 9.07 2.8645 7C2.8645 4.93 4.6145 3.25 6.77075 3.25Z"
                fill="white"
            />
        </svg>
    );
};
