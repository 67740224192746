import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Col, Collapse, Row, Select } from "antd";
import {
    ICheckConsumableList,
    ICheckProductListCreate,
    ICheckStockReasonList,
    IGetVatDetailExample,
    IWasteRecordReasonList,
} from "@interfaces";
import { PATH_CHECK_STOCK, PATH_STOCK_LIST, PATH_WASTE_RECORD } from "@configs";
import { SharedInput } from "@components";
import styled from "styled-components";

interface IProps {
    data: any;
    index: number;
    stores: (ICheckConsumableList & ICheckProductListCreate)[];
    setConsumeList?: any;
    tab: number;
    checkStockReasonList?: ICheckStockReasonList[];
    wasteRecordReasonList?: IWasteRecordReasonList[];
    vatDetail?: IGetVatDetailExample;
    isDelivery?: boolean;
    handleKeyDown: (event: any, currentIndex: number, inputName: string, nextVariant?: number) => void;
}

export const ComponentRowCheckStock = (props: IProps) => {
    const { t } = useTranslation();
    const {
        data,
        stores,
        index,
        setConsumeList,
        tab,
        checkStockReasonList,
        wasteRecordReasonList,
        vatDetail,
        isDelivery = true,
        handleKeyDown,
    } = props;
    const pathName = window.location.pathname;
    const changeUsedQty = (e: any, index: number, storeIndex?: number) => {
        const inputValue = e.target.value;
        const numberValue = inputValue.replace(/^0+/, "");
        if (!isNaN(+e.target.value) || e.target.value !== "") {
            if (tab === 0) {
                const newState = stores.map((obj, i) => {
                    const restockUnit =
                        obj.restock_formula.find(
                            (x) => x.delivery_unit_name === obj.delivery_unit_name
                        )?.to_restock_unit || 0;
                    const usedUnit = obj.formula?.[0].to_used_unit || 1;
                    if (i === index) {
                        if (pathName.includes(PATH_WASTE_RECORD)) {
                            return {
                                ...obj,
                                temp_used_qty: parseInt(numberValue) || 0,
                                waste_used_qty: ((obj?.temp_restock_qty || 0) * usedUnit) + (parseInt(numberValue) || 0),
                            };
                        } else {
                            return {
                                ...obj,
                                used_qty: parseInt(numberValue) || 0,
                                grand_total_restock_qty: ((obj.delivery_qty * restockUnit) + obj.restock_qty + (numberValue / usedUnit)),
                                restock_change_qty: pathName.includes(PATH_WASTE_RECORD) ?
                                    obj.before_restock_qty -
                                    ((obj.delivery_qty * restockUnit) + obj.restock_qty + (numberValue / usedUnit))
                                    :
                                    ((obj.delivery_qty * restockUnit) + obj.restock_qty + (numberValue / usedUnit)) -
                                    obj.before_restock_qty
                                ,
                            };
                        }

                    }
                    return obj;
                });
                setConsumeList(newState);
            } else if (!pathName.includes(PATH_WASTE_RECORD)) {

                const newState = stores.map((obj: ICheckProductListCreate, i) => {
                    if (i === storeIndex) {
                        const updatedCheckVariantList = obj.check_variant_list.map((variant, j) => {
                            const restockUnit =
                                variant.restock_formula.find(
                                    (x) => x.delivery_unit_name === variant.delivery_unit_name
                                )?.to_restock_unit || 0;
                            const usedUnit = variant.formula?.[0]?.to_used_unit;
                            if (j === index) {
                                return {
                                    ...variant,
                                    used_qty: parseInt(numberValue) || 0,
                                    grand_total_restock_qty:
                                        (variant.delivery_qty || 0) * restockUnit +
                                        (variant.restock_qty) +
                                        (numberValue / usedUnit)
                                    ,
                                    restock_change_qty:
                                        pathName.includes(PATH_WASTE_RECORD) ?
                                            variant.before_restock_qty -
                                            ((variant.delivery_qty * restockUnit) + variant.restock_qty + (numberValue / usedUnit))
                                            :
                                            ((variant.delivery_qty * restockUnit) + variant.restock_qty + (numberValue / usedUnit)) -
                                            variant.before_restock_qty
                                };
                            }

                            return variant;
                        });

                        return {
                            ...obj,
                            check_variant_list: updatedCheckVariantList,
                        };
                    }

                    return obj;
                });
                setConsumeList(newState);
            } else {
                const newState = stores.map((obj: ICheckProductListCreate, i) => {
                    if (i === storeIndex) {
                        const updatedCheckVariantList = obj.check_variant_list.map((variant, j) => {
                            const usedUnit = variant.formula?.[0]?.to_used_unit || 1;
                            if (j === index) {
                                return {
                                    ...variant,
                                    temp_used_qty: parseInt(numberValue) || 0,
                                    waste_used_qty: ((variant?.temp_restock_qty || 0) * usedUnit) + (parseInt(numberValue) || 0),
                                };
                            }
                            return variant;
                        });
                        return {
                            ...obj,
                            check_variant_list: updatedCheckVariantList,
                        };
                    }

                    return obj;
                });
                setConsumeList(newState);
            }
        }
    };
    const changeRestockQty = (e: any, index: number, storeIndex?: number) => {
        const inputValue = e.target.value;
        const numberValue = inputValue.replace(/^0+/, "");
        if (!isNaN(+e.target.value) || e.target.value !== "") {
            if (tab === 0) {
                const newState = stores.map((obj, i) => {
                    const restockUnit =
                        obj.restock_formula.find(
                            (x) => x.delivery_unit_name === obj.delivery_unit_name
                        )?.to_restock_unit || 0;
                    const usedUnit = obj.formula?.[0].to_used_unit;
                    const usedValue = obj.used_qty / usedUnit;
                    if (i === index) {
                        if (pathName.includes(PATH_WASTE_RECORD)) {
                            return {
                                ...obj,
                                temp_restock_qty: parseInt(numberValue) || 0,
                                waste_used_qty: (parseInt(numberValue) * usedUnit) + (obj?.temp_used_qty || 0)
                            };
                        } else {
                            return {
                                ...obj,
                                restock_qty: parseInt(numberValue) || 0,
                                grand_total_restock_qty:
                                    obj.delivery_qty * restockUnit + parseInt(numberValue) + usedValue ||
                                    obj.delivery_qty * restockUnit + usedValue ||
                                    0,
                                restock_change_qty: pathName.includes(PATH_WASTE_RECORD)
                                    ? obj.before_restock_qty -
                                    (obj.delivery_qty * restockUnit + parseInt(numberValue) + usedValue) ||
                                    obj.before_restock_qty - (obj.delivery_qty * restockUnit + 0 + usedValue) ||
                                    0
                                    : obj.delivery_qty * restockUnit +
                                    parseInt(numberValue) + usedValue -
                                    obj.before_restock_qty ||
                                    obj.delivery_qty * restockUnit + 0 + usedValue - obj.before_restock_qty ||
                                    0,
                            };
                        }

                    }
                    return obj;
                });
                setConsumeList(newState);
            } else if (!pathName.includes(PATH_WASTE_RECORD)) {
                const newState = stores.map((obj: ICheckProductListCreate, i) => {
                    if (i === storeIndex) {
                        const updatedCheckVariantList = obj.check_variant_list.map((variant, j) => {
                            const restockUnit =
                                variant.restock_formula.find(
                                    (x) => x.delivery_unit_name === variant.delivery_unit_name
                                )?.to_restock_unit || 0;
                            const usedUnit = variant.formula?.[0]?.to_used_unit;
                            const usedValue = variant.used_qty / usedUnit;
                            if (j === index) {
                                return {
                                    ...variant,
                                    restock_qty: parseInt(numberValue) || 0,
                                    grand_total_restock_qty:
                                        (variant.delivery_qty || 0) * restockUnit +
                                        (parseInt(numberValue) || 0) + usedValue,
                                    restock_change_qty:
                                        (variant.delivery_qty || 0) * restockUnit +
                                        (parseInt(numberValue) || 0) + usedValue -
                                        variant.before_restock_qty,
                                };
                            }

                            return variant;
                        });

                        return {
                            ...obj,
                            check_variant_list: updatedCheckVariantList,
                        };
                    }

                    return obj;
                });
                setConsumeList(newState);
            } else {
                const newState = stores.map((obj: ICheckProductListCreate, i) => {
                    if (i === storeIndex) {
                        const updatedCheckVariantList = obj.check_variant_list.map((variant, j) => {
                            const usedUnit = variant.formula?.[0]?.to_used_unit || 1;
                            if (j === index) {
                                return {
                                    ...variant,
                                    temp_restock_qty: parseInt(numberValue) || 0,
                                    waste_used_qty: (parseInt(numberValue) * usedUnit) + (variant.temp_used_qty || 0)
                                };
                            }
                            return variant;
                        });
                        return {
                            ...obj,
                            check_variant_list: updatedCheckVariantList,
                        };
                    }

                    return obj;
                });
                setConsumeList(newState);
            }
        }
    };
    const changeDeliveryQty = (e: any, index: number, storeIndex?: number) => {
        const inputValue = e.target.value;
        const numberValue = inputValue.replace(/^0+/, "");
        if (!isNaN(+e.target.value) || e.target.value !== "") {
            if (tab === 0) {
                const newState = stores.map((obj: ICheckConsumableList, i) => {
                    const usedUnit = obj.formula?.[0].to_used_unit || 1;
                    const usedValue = obj.used_qty / usedUnit;
                    if (obj.restock_formula.length !== 0) {
                        const restockUnit =
                            obj.restock_formula.find(
                                (x) => x.delivery_unit_name === obj.delivery_unit_name
                            )?.to_restock_unit || 0;
                        if (i === index) {
                            return {
                                ...obj,
                                delivery_qty: parseInt(numberValue) || 0,
                                grand_total_restock_qty:
                                    parseInt(numberValue) * restockUnit + obj.restock_qty + usedValue ||
                                    parseInt(numberValue) * restockUnit + usedValue ||
                                    0,
                                restock_change_qty: pathName.includes(PATH_WASTE_RECORD)
                                    ? obj.before_restock_qty -
                                    (parseInt(numberValue) * restockUnit + obj.restock_qty + usedValue) ||
                                    0
                                    : parseInt(numberValue) * restockUnit +
                                    obj.restock_qty + usedValue -
                                    obj.before_restock_qty || 0,
                            };
                        }
                        return obj;
                    } else {
                        if (i === index) {
                            return {
                                ...obj,
                                delivery_qty: parseInt(numberValue) || 0,
                                grand_total_restock_qty:
                                    parseInt(numberValue) * 0 + obj.restock_qty + usedValue ||
                                    parseInt(numberValue) * 0 + usedValue ||
                                    0,
                                restock_change_qty: pathName.includes(PATH_WASTE_RECORD)
                                    ? obj.before_restock_qty -
                                    (parseInt(numberValue) * 0 + obj.restock_qty) + usedValue || 0
                                    : parseInt(numberValue) * 0 +
                                    obj.restock_qty -
                                    obj.before_restock_qty + usedValue || 0,
                            };
                        }
                        return obj;
                    }
                });
                setConsumeList(newState);
            } else {
                const newState = stores.map((obj: ICheckProductListCreate, i) => {
                    if (i === storeIndex) {
                        if (obj.check_variant_list.length !== 0) {
                            const updatedCheckVariantList = obj.check_variant_list.map(
                                (variant, j) => {
                                    const restockUnit =
                                        variant.restock_formula.find(
                                            (x) =>
                                                x.delivery_unit_name === variant.delivery_unit_name
                                        )?.to_restock_unit || 0;
                                    const usedUnit = variant.formula?.[0]?.to_used_unit;
                                    const usedValue = variant.used_qty / usedUnit;

                                    if (j === index) {
                                        return {
                                            ...variant,
                                            delivery_qty: parseInt(numberValue) || 0,
                                            grand_total_restock_qty:
                                                (parseInt(numberValue) || 0) * restockUnit +
                                                variant.restock_qty + usedValue ||
                                                (parseInt(numberValue) || 0) * restockUnit + usedValue ||
                                                0,
                                            restock_change_qty:
                                                (parseInt(numberValue) || 0) * restockUnit +
                                                variant.restock_qty + usedValue -
                                                variant.before_restock_qty || 0,
                                        };
                                    }

                                    return variant;
                                }
                            );

                            return {
                                ...obj,
                                check_variant_list: updatedCheckVariantList,
                            };
                        } else {
                            const updatedCheckVariantList = obj.check_variant_list.map(
                                (variant, j) => {
                                    const restockUnit =
                                        variant.restock_formula.find(
                                            (x) =>
                                                x.delivery_unit_name === variant.delivery_unit_name
                                        )?.to_restock_unit || 0;
                                    const usedUnit = variant.formula?.[0]?.to_used_unit;
                                    const usedValue = variant.used_qty / usedUnit;
                                    if (j === index) {
                                        return {
                                            ...variant,
                                            delivery_qty: parseInt(numberValue) || 0,
                                            grand_total_restock_qty:
                                                (parseInt(numberValue) || 0) * restockUnit +
                                                variant.restock_qty + usedValue ||
                                                (parseInt(numberValue) || 0) * restockUnit + usedValue ||
                                                0,
                                            restock_change_qty:
                                                (parseInt(numberValue) || 0) * restockUnit +
                                                variant.restock_qty + usedValue -
                                                variant.before_restock_qty || 0,
                                        };
                                    }

                                    return variant;
                                }
                            );

                            return {
                                ...obj,
                                check_variant_list: updatedCheckVariantList,
                            };
                        }
                    }

                    return obj;
                });

                setConsumeList(newState);
            }
        }
    };

    const { Option } = Select;
    const handleChange = (value) => {
        const newState = stores.map((obj, i) => {
            const restockUnit =
                obj.restock_formula.find((x) => x.delivery_unit_name === value)?.to_restock_unit ||
                0;
            if (i === index) {
                return {
                    ...obj,
                    delivery_unit_name: value,
                    delivery_qty: obj.delivery_qty || 0,
                    grand_total_restock_qty:
                        obj.delivery_qty * restockUnit + obj.restock_qty ||
                        obj.delivery_qty * restockUnit ||
                        0,
                    restock_change_qty:
                        obj.delivery_qty * restockUnit + obj.restock_qty - obj.before_restock_qty ||
                        0,
                };
            }
            return obj;
        });
        setConsumeList(newState);
    };
    const handleReasonChange = (value, Index?: number) => {
        if (tab === 0) {
            const newState = stores.map((obj, i) => {
                if (i === index) {
                    return {
                        ...obj,
                        reason_system: value,
                    };
                }
                return obj;
            });
            setConsumeList(newState);
        } else {
            const newState = stores.map((obj: ICheckProductListCreate, i) => {
                const updatedCheckVariantList = obj.check_variant_list.map((variant, j) => {
                    if (j === Index) {
                        return {
                            ...variant,
                            reason_system: value,
                        };
                    }
                    return variant;
                });
                return {
                    ...obj,
                    check_variant_list: updatedCheckVariantList,
                };
            });
            setConsumeList(newState);
        }
    };
    const { Panel } = Collapse;
    const EllipsisSpan = ({ text, suffixText }) => {
        const truncatedText = text?.length > 6 ? text.slice(0, 6) + "..." : text;

        return (
            <div style={{ display: "flex", gap: 5 }}>
                <span>{truncatedText}</span>
                <span>{suffixText}</span>
            </div>
        );
    };

    return (
        <StylesCheckStock>
            <div className="table-element">
                {tab === 1 ? (
                    stores[index].check_variant_list.length > 1 ? (
                        <Collapse bordered>
                            <Panel header={stores[index].product_name} key="1">
                                {stores[index].check_variant_list.length > 0 &&
                                    stores[index].check_variant_list.map((variant, i) => (
                                        <div className="table-row" key={i}>
                                            <Row
                                                style={{ height: "100%" }}
                                                gutter={24}
                                                type="flex"
                                                align="middle"
                                                justify="space-between"
                                            >
                                                <Col className="col-item" span={4}>
                                                    <Link to={`${PATH_STOCK_LIST}/${data._id}`}>
                                                        <p className="news-title">
                                                            {variant.variant_name ||
                                                                t("page.empty_text")}
                                                        </p>
                                                    </Link>
                                                </Col>
                                                <Col
                                                    className="col-item"
                                                    span={
                                                        vatDetail &&
                                                            (vatDetail.is_enable_check_store_reason ||
                                                                vatDetail?.is_enable_waste_record_reason)
                                                            ? 3
                                                            : 4
                                                    }
                                                    style={{ placeContent: "baseline" }}
                                                >
                                                    <p>{t("page.empty_text")}</p>
                                                </Col>
                                                <Col
                                                    className="col-item inputSelectCol"
                                                    span={11}
                                                    style={{ placeContent: "baseline" }}
                                                >
                                                    {!pathName.includes(PATH_WASTE_RECORD) && (
                                                        <>
                                                            <SharedInput
                                                                parentClassName="mb-6"
                                                                onChange={(value) => {
                                                                    changeDeliveryQty(
                                                                        value,
                                                                        i,
                                                                        index
                                                                    );
                                                                }}
                                                                name={`deli${index}${i !== 0 ? "." + i : ""}`}
                                                                onKeyDown={(e) => handleKeyDown(e, index, "deli",
                                                                    (stores[index].check_variant_list.length > i + 1 ? i + 1 : 0)
                                                                )}
                                                                value={variant.delivery_qty}
                                                                defaultValue={0}
                                                                style={{
                                                                    textAlign: "left",
                                                                    width: 150,
                                                                }}
                                                                notErr
                                                                disable={
                                                                    variant.restock_formula
                                                                        .length === 0
                                                                }
                                                                suffixIcon={
                                                                    <Select
                                                                        disabled={
                                                                            variant.restock_formula
                                                                                .length === 0
                                                                        }
                                                                        defaultValue={
                                                                            variant.restock_formula
                                                                                .length !== 0
                                                                                ? variant
                                                                                    .restock_formula[0]
                                                                                    .delivery_unit_name
                                                                                : ""
                                                                        }
                                                                        onChange={handleChange}
                                                                    >
                                                                        {variant.restock_formula.map(
                                                                            (restockFormula, i) => (
                                                                                <Option
                                                                                    value={
                                                                                        restockFormula.delivery_unit_name
                                                                                    }
                                                                                    key={i}
                                                                                >
                                                                                    {
                                                                                        restockFormula.delivery_unit_name
                                                                                    }
                                                                                </Option>
                                                                            )
                                                                        )}
                                                                    </Select>
                                                                }
                                                            />
                                                            {" + "}
                                                        </>
                                                    )}
                                                    {/* 111 */}
                                                    <SharedInput
                                                        parentClassName="mb-6"
                                                        onChange={(value) =>
                                                            changeRestockQty(value, i, index)
                                                        }
                                                        value={
                                                            !pathName.includes(PATH_WASTE_RECORD)
                                                                ? variant.restock_qty
                                                                : (variant?.temp_restock_qty || 0)
                                                        }
                                                        name={`re${index}${i !== 0 ? "." + i : ""}`}
                                                        onKeyDown={(e) => handleKeyDown(e, index, "re",
                                                            (stores[index].check_variant_list.length > i + 1 ? i + 1 : 0)
                                                        )}
                                                        style={{ textAlign: "left", width: 130 }}
                                                        notErr
                                                        suffixIcon={
                                                            pathName.includes(PATH_CHECK_STOCK)
                                                                ? stores[index]?.formula?.[0]
                                                                    ?.restock_unit_name
                                                                : variant.formula?.[0]?.restock_unit_name
                                                        }
                                                    />
                                                    {" + "}
                                                    <SharedInput
                                                        parentClassName="mb-6"
                                                        onChange={(value) =>
                                                            changeUsedQty(value, i, index)
                                                        }
                                                        name={`used${index}${i !== 0 ? "." + i : ""}`}
                                                        onKeyDown={(e) => handleKeyDown(e, index, "used",
                                                            (stores[index].check_variant_list.length > i + 1 ? i + 1 : 0)
                                                        )}
                                                        value={
                                                            !pathName.includes(PATH_WASTE_RECORD)
                                                                ? variant.used_qty
                                                                : (variant.temp_used_qty || 0)
                                                        }
                                                        style={{ textAlign: "left", width: 120 }}
                                                        notErr
                                                        suffixIcon={stores[index]?.unit_name || variant.unit_name}
                                                    />
                                                    {!pathName.includes(PATH_WASTE_RECORD)
                                                        ? " = "
                                                        : ""}
                                                    {!pathName.includes(PATH_WASTE_RECORD) && (
                                                        <EllipsisSpan
                                                            text={
                                                                (variant?.grand_total_restock_qty ||
                                                                    variant.delivery_qty * 0 +
                                                                    variant.restock_qty
                                                                )?.toString()
                                                            }
                                                            suffixText={
                                                                pathName.includes(
                                                                    PATH_CHECK_STOCK
                                                                ) &&
                                                                stores[index]?.formula?.[0]
                                                                    ?.restock_unit_name
                                                            }
                                                        />
                                                    )}
                                                </Col>
                                                <Col
                                                    className="col-item"
                                                    span={
                                                        vatDetail &&
                                                            (vatDetail.is_enable_check_store_reason ||
                                                                vatDetail?.is_enable_waste_record_reason)
                                                            ? 3
                                                            : 4
                                                    }
                                                    style={{ placeContent: "center" }}
                                                >
                                                    <p>{pathName.includes(PATH_WASTE_RECORD) ?
                                                        (variant?.waste_used_qty) :
                                                        (variant?.grand_total_restock_qty || variant?.before_restock_qty) - variant?.before_restock_qty
                                                    }</p>
                                                </Col>
                                                {pathName.includes(PATH_CHECK_STOCK) &&
                                                    vatDetail &&
                                                    vatDetail.is_enable_check_store_reason && (
                                                        <Col
                                                            className="col-item"
                                                            span={3}
                                                            style={{ placeContent: "baseline" }}
                                                        >
                                                            <Select
                                                                style={{
                                                                    width: "100%",
                                                                    height: "auto",
                                                                }}
                                                                onChange={(value) =>
                                                                    handleReasonChange(value, i)
                                                                }
                                                            >
                                                                {pathName.includes(
                                                                    PATH_CHECK_STOCK
                                                                ) &&
                                                                    checkStockReasonList &&
                                                                    checkStockReasonList.map(
                                                                        (reason, i) => (
                                                                            <Option
                                                                                value={
                                                                                    reason.reason
                                                                                }
                                                                                key={i}
                                                                            >
                                                                                {reason.reason}
                                                                            </Option>
                                                                        )
                                                                    )}
                                                            </Select>
                                                        </Col>
                                                    )}
                                                {!pathName.includes(PATH_CHECK_STOCK) &&
                                                    vatDetail &&
                                                    vatDetail?.is_enable_waste_record_reason && (
                                                        <Col
                                                            className="col-item"
                                                            span={3}
                                                            style={{ placeContent: "baseline" }}
                                                        >
                                                            <Select
                                                                style={{
                                                                    width: "100%",
                                                                    height: "auto",
                                                                }}
                                                                onChange={(value) =>
                                                                    handleReasonChange(value, i)
                                                                }
                                                            >
                                                                {!pathName.includes(
                                                                    PATH_CHECK_STOCK
                                                                ) &&
                                                                    wasteRecordReasonList &&
                                                                    wasteRecordReasonList?.map(
                                                                        (reason, i) => (
                                                                            <Option
                                                                                value={
                                                                                    reason.reason
                                                                                }
                                                                                key={i}
                                                                            >
                                                                                {reason.reason}
                                                                            </Option>
                                                                        )
                                                                    )}
                                                            </Select>
                                                        </Col>
                                                    )}
                                            </Row>
                                        </div>
                                    ))}
                            </Panel>
                        </Collapse>
                    ) : (
                        stores[index].check_variant_list.length > 0 &&
                        stores[index].check_variant_list.map((variant, i) => (
                            <div className="table-row" key={i}>
                                <Row
                                    style={{ height: "100%" }}
                                    gutter={24}
                                    type="flex"
                                    align="middle"
                                    justify="space-between"
                                >
                                    <Col className="col-item" span={4}>
                                        <Link to={`${PATH_STOCK_LIST}/${data._id}`}>
                                            <p className="news-title">
                                                {variant.variant_name || t("page.empty_text")}
                                            </p>
                                        </Link>
                                    </Col>
                                    <Col
                                        className="col-item"
                                        span={
                                            vatDetail &&
                                                (vatDetail.is_enable_check_store_reason ||
                                                    vatDetail?.is_enable_waste_record_reason)
                                                ? 3
                                                : 4
                                        }
                                        style={{ placeContent: "baseline" }}
                                    >
                                        <p>{variant.before_restock_qty}</p>
                                    </Col>
                                    <Col
                                        className="col-item inputSelectCol"
                                        span={11}
                                        style={{ placeContent: "baseline" }}
                                    >
                                        {!pathName.includes(PATH_WASTE_RECORD) && (
                                            <>
                                                <SharedInput
                                                    parentClassName="mb-6"
                                                    onChange={(value) => {
                                                        changeDeliveryQty(value, i, index);
                                                    }}
                                                    value={variant.delivery_qty}
                                                    name={`deli${index}${i !== 0 ? "." + i : ""}`}
                                                    onKeyDown={(e) => handleKeyDown(e, index, "deli",
                                                        (stores[index].check_variant_list.length > i + 1 ? i + 1 : 0)
                                                    )}
                                                    defaultValue={0}
                                                    style={{ textAlign: "left", width: 150 }}
                                                    notErr
                                                    disable={variant.restock_formula.length === 0}
                                                    suffixIcon={
                                                        <Select
                                                            disabled={
                                                                variant.restock_formula.length === 0
                                                            }
                                                            defaultValue={
                                                                variant.restock_formula.length !== 0
                                                                    ? variant.restock_formula[0]
                                                                        .delivery_unit_name
                                                                    : ""
                                                            }
                                                            onChange={handleChange}
                                                        >
                                                            {variant.restock_formula.map(
                                                                (restockFormula, i) => (
                                                                    <Option
                                                                        value={
                                                                            restockFormula.delivery_unit_name
                                                                        }
                                                                        key={i}
                                                                    >
                                                                        {
                                                                            restockFormula.delivery_unit_name
                                                                        }
                                                                    </Option>
                                                                )
                                                            )}
                                                        </Select>
                                                    }
                                                />
                                                {" + "}
                                            </>
                                        )}
                                        {/* 222 */}
                                        <SharedInput
                                            parentClassName="mb-6"
                                            onChange={(value) => changeRestockQty(value, i, index)}
                                            value={
                                                !pathName.includes(PATH_WASTE_RECORD)
                                                    ? variant.restock_qty
                                                    : (variant.temp_restock_qty || 0)
                                            }
                                            style={{ textAlign: "left", width: 130 }}
                                            notErr
                                            suffixIcon={
                                                pathName.includes(PATH_CHECK_STOCK)
                                                    ? stores[index]?.formula?.[0]?.restock_unit_name
                                                    : variant?.formula?.[0]?.restock_unit_name
                                            }
                                            name={`re${index}${i !== 0 ? "." + i : ""}`}
                                            onKeyDown={(e) => handleKeyDown(e, index, "re",
                                                (stores[index].check_variant_list.length > i + 1 ? i + 1 : 0)
                                            )}
                                        />
                                        {" + "}
                                        <SharedInput
                                            parentClassName="mb-6"
                                            onChange={(value) => changeUsedQty(value, i, index)}
                                            value={
                                                !pathName.includes(PATH_WASTE_RECORD)
                                                    ? variant.used_qty
                                                    : (variant.temp_used_qty || 0)
                                            }
                                            name={`used${index}${i !== 0 ? "." + i : ""}`}
                                            onKeyDown={(e) => handleKeyDown(e, index, "used",
                                                (stores[index].check_variant_list.length > i + 1 ? i + 1 : 0)
                                            )}
                                            style={{ textAlign: "left", width: 120 }}
                                            notErr
                                            suffixIcon={stores[index]?.unit_name || variant.unit_name}
                                        />
                                        {!pathName.includes(PATH_WASTE_RECORD) ? " = " : ""}
                                        {!pathName.includes(PATH_WASTE_RECORD) && (
                                            <EllipsisSpan
                                                text={
                                                    (variant?.grand_total_restock_qty ||
                                                        variant.delivery_qty * 0 +
                                                        variant.restock_qty
                                                    )?.toString()
                                                }
                                                suffixText={
                                                    pathName.includes(
                                                        PATH_CHECK_STOCK
                                                    ) &&
                                                    stores[index]?.formula?.[0]
                                                        ?.restock_unit_name
                                                }
                                            />
                                        )}
                                    </Col>
                                    <Col
                                        className="col-item"
                                        span={
                                            vatDetail &&
                                                (vatDetail.is_enable_check_store_reason ||
                                                    vatDetail?.is_enable_waste_record_reason)
                                                ? 3
                                                : 4
                                        }
                                        style={{ placeContent: "center" }}
                                    >
                                        <p>{
                                            pathName.includes(PATH_WASTE_RECORD) ?
                                                variant?.waste_used_qty :
                                                t("page.empty_text")
                                        }
                                        </p>
                                    </Col>
                                    {pathName.includes(PATH_CHECK_STOCK) &&
                                        vatDetail &&
                                        vatDetail.is_enable_check_store_reason && (
                                            <Col
                                                className="col-item"
                                                span={3}
                                                style={{ placeContent: "baseline" }}
                                            >
                                                <Select
                                                    style={{ width: "100%", height: "auto" }}
                                                    onChange={(value) =>
                                                        handleReasonChange(value, i)
                                                    }
                                                >
                                                    {pathName.includes(PATH_CHECK_STOCK) &&
                                                        checkStockReasonList &&
                                                        checkStockReasonList.map((reason, i) => (
                                                            <Option value={reason.reason} key={i}>
                                                                {reason.reason}
                                                            </Option>
                                                        ))}
                                                </Select>
                                            </Col>
                                        )}
                                    {!pathName.includes(PATH_CHECK_STOCK) &&
                                        vatDetail &&
                                        vatDetail.is_enable_waste_record_reason && (
                                            <Col
                                                className="col-item"
                                                span={3}
                                                style={{ placeContent: "baseline" }}
                                            >
                                                <Select
                                                    style={{ width: "100%", height: "auto" }}
                                                    onChange={(value) =>
                                                        handleReasonChange(value, i)
                                                    }
                                                >
                                                    {!pathName.includes(PATH_CHECK_STOCK) &&
                                                        wasteRecordReasonList &&
                                                        wasteRecordReasonList?.map((reason, i) => (
                                                            <Option value={reason.reason} key={i}>
                                                                {reason.reason}
                                                            </Option>
                                                        ))}
                                                </Select>
                                            </Col>
                                        )}
                                </Row>
                            </div>
                        ))
                    )
                ) : (
                    <div className="table-row">
                        <Row
                            style={{ height: "100%" }}
                            gutter={24}
                            type="flex"
                            align="middle"
                            justify="space-between"
                        >
                            <Col className="col-item" span={4}>
                                <p className="news-title">
                                    {stores[index].consumable_name || t("page.empty_text")}
                                </p>
                            </Col>
                            <Col
                                className="col-item"
                                span={
                                    vatDetail &&
                                        (vatDetail.is_enable_check_store_reason ||
                                            vatDetail?.is_enable_waste_record_reason)
                                        ? 3
                                        : 4
                                }
                                style={{ placeContent: "baseline" }}
                            >
                                <p>{(stores[index]?.before_restock_qty).toFixed(2)}</p>
                            </Col>
                            <Col
                                className="col-item inputSelectCol"
                                span={11}
                                style={{ placeContent: "baseline" }}
                            >
                                {pathName.includes(PATH_CHECK_STOCK) && isDelivery && (
                                    <>
                                        <SharedInput
                                            parentClassName="mb-6"
                                            onChange={(value) => {
                                                changeDeliveryQty(value, index);
                                            }}
                                            name={`deli${index}`}
                                            onKeyDown={(e) => handleKeyDown(e, index, "deli")}
                                            value={stores[index].delivery_qty}
                                            defaultValue={0}
                                            style={{ textAlign: "left", width: 150 }}
                                            notErr
                                            disable={
                                                stores[index].restock_formula &&
                                                stores[index].restock_formula.length === 0
                                            }
                                            suffixIcon={
                                                <Select
                                                    disabled={
                                                        stores[index].restock_formula &&
                                                        stores[index].restock_formula.length === 0
                                                    }
                                                    defaultValue={
                                                        stores[index].restock_formula &&
                                                            stores[index].restock_formula.length !== 0
                                                            ? stores[index].restock_formula[0]
                                                                .delivery_unit_name
                                                            : ""
                                                    }
                                                    onChange={handleChange}
                                                >
                                                    {stores[index].restock_formula &&
                                                        stores[index].restock_formula.map(
                                                            (restockFormula, i) => (
                                                                <Option
                                                                    value={
                                                                        restockFormula.delivery_unit_name
                                                                    }
                                                                    key={i}
                                                                >
                                                                    {
                                                                        restockFormula.delivery_unit_name
                                                                    }
                                                                </Option>
                                                            )
                                                        )}
                                                </Select>
                                            }
                                        />
                                        {" + "}
                                    </>
                                )}
                                {/* 333 */}
                                <SharedInput
                                    parentClassName="mb-6"
                                    onChange={(value) => changeRestockQty(value, index)}
                                    value={
                                        !pathName.includes(PATH_WASTE_RECORD) ?
                                            stores[index].restock_qty :
                                            (stores[index]?.temp_restock_qty || 0)
                                    }
                                    style={{ textAlign: "left", width: 130 }}
                                    notErr
                                    name={`re${index}`}
                                    onKeyDown={(e) => handleKeyDown(e, index, "re")}
                                    suffixIcon={
                                        stores[index].formula
                                            ? stores[index].formula[0].restock_unit_name
                                            : ""
                                    }
                                />
                                {" + "}
                                <SharedInput
                                    parentClassName="mb-6"
                                    onChange={(value) => changeUsedQty(value, index)}
                                    value={
                                        !pathName.includes(PATH_WASTE_RECORD) ?
                                            stores[index].used_qty :
                                            (stores[index]?.temp_used_qty || 0)
                                    }
                                    style={{ textAlign: "left", width: 120 }}
                                    notErr
                                    name={`used${index}`}
                                    onKeyDown={(e) => handleKeyDown(e, index, "used")}
                                    suffixIcon={stores[index]?.unit_name || ""}
                                />
                                {pathName.includes(PATH_CHECK_STOCK) && isDelivery && (
                                    <>
                                        {" "}
                                        {" = "}{" "}
                                        <EllipsisSpan
                                            text={stores[index].grand_total_restock_qty.toString()}
                                            suffixText={
                                                pathName.includes(PATH_CHECK_STOCK) &&
                                                stores[index]?.formula?.[0]?.restock_unit_name
                                            }
                                        />
                                    </>
                                )}
                            </Col>
                            <Col
                                className="col-item"
                                span={
                                    vatDetail &&
                                        (vatDetail.is_enable_check_store_reason ||
                                            vatDetail?.is_enable_waste_record_reason)
                                        ? 3
                                        : 4
                                }
                                style={{ placeContent: "center" }}
                            >
                                <EllipsisSpan
                                    text={
                                        pathName.includes(PATH_CHECK_STOCK) ?
                                            stores[index].restock_change_qty.toString() :
                                            stores[index].waste_used_qty
                                    }
                                    suffixText={""}
                                />
                            </Col>
                            {pathName.includes(PATH_CHECK_STOCK) &&
                                vatDetail &&
                                vatDetail.is_enable_check_store_reason && (
                                    <Col
                                        className="col-item"
                                        span={3}
                                        style={{ placeContent: "baseline" }}
                                    >
                                        <Select
                                            style={{ width: "100%", height: "auto" }}
                                            onChange={(value) => handleReasonChange(value)}
                                        >
                                            {pathName.includes(PATH_CHECK_STOCK) &&
                                                checkStockReasonList &&
                                                checkStockReasonList.map((reason, i) => (
                                                    <Option value={reason.reason} key={i}>
                                                        {reason.reason}
                                                    </Option>
                                                ))}
                                        </Select>
                                    </Col>
                                )}
                            {!pathName.includes(PATH_CHECK_STOCK) &&
                                vatDetail &&
                                vatDetail?.is_enable_waste_record_reason && (
                                    <Col
                                        className="col-item"
                                        span={3}
                                        style={{ placeContent: "baseline" }}
                                    >
                                        <Select
                                            style={{ width: "100%", height: "auto" }}
                                            onChange={(value) => handleReasonChange(value)}
                                        >

                                            {!pathName.includes(PATH_CHECK_STOCK) &&
                                                wasteRecordReasonList &&
                                                wasteRecordReasonList?.map((reason, i) => (
                                                    <Option value={reason.reason} key={i}>
                                                        {reason.reason}
                                                    </Option>
                                                ))}
                                        </Select>
                                    </Col>
                                )}
                        </Row>
                    </div>
                )}
            </div>
        </StylesCheckStock>
    );
};
export const StylesCheckStock = styled.div`
    .page-table-layout {
        padding: 0 4.625rem 0 2.125rem !important;
    }
    .table-row {
        height: 65px;
        border-radius: 0;
        border-bottom: 1px solid #e8e8e8;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        height: 65px;
        display: flex;
        place-items: center;
    }
    .ant-collapse {
        width: 100%;
    }
    .ant-collapse-content > .ant-collapse-content-box {
        padding: 0;
        display: flex;
        flex-direction: column;
    }
    .table-element {
        flex-direction: column;
        gap: 5px;
        margin: 0 0 12px 0;
    }
    .inputSelectCol {
        gap: 15px;
        span {
            color: #a5a5a5;
            font-size: 14px;
            font-weight: 700;
        }
    }
    .inputSelectCol .ant-select-selection {
        height: 25px !important;
        position: absolute;
        right: -8px;
        bottom: 0;
        top: 0;
        margin: auto;
    }
    input {
        height: auto;
    }
    .table-row:hover {
        background: #e6f7ff;
        box-shadow: none !important;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
        margin-top: 0;
    }
`;

const StylesSaveBtn = styled.div`
    .save-btn {
        color: white;
        background-color: #f4394f;
        height: 42px;
        padding: 4px 12px;
        font-size: 16px;
        font-weight: 700;
    }
    .load-icon {
        padding: 0px 6px;
    }
`;
