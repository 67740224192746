import { useState, useEffect } from "react";
import {
    ComponentContactSortIcon,
    StyledTable,
    StyledTableButtonTop,
    IconAllPO,
    IconDone,
    IconCancel,
    ComponentTablePOSearch,
    IconPending,
    ComponentRowPurchaseRequest,
} from "@components";
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    TouchSensor,
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    rectSortingStrategy,
    //@ts-ignore
} from "@dnd-kit/sortable";
import { Row, Col, Pagination, Tabs, Dropdown, Menu } from "antd";
import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";
import {
    enumSortBy,
    enumContactSortFields,
    PAGINATION,
    PAGE_START,
    INITIAL_LIST_PARAMS,
    PATH_PURCHASE_REQUEST_CREATE,
} from "@configs";
import {
    IPaginationNext,
    IDataexample,
    IDetailSummary,
    IGetTtrsExample,
    IGetAdminDataByTokenAdminDataExample,
    IGetStoresBindingDropdownExample,
} from "@interfaces";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth, selectContact, setLoading } from "@redux";
import { theme, useNotify } from "@utils";
import { useHistory } from "react-router";
import { POAPI, adminAPI, storeAPI } from "@api";
import styled from "styled-components";

const INITAL_PAGE = {
    xpage: PAGE_START,
    xlimit: PAGINATION,
};

const initDetailSum: IDetailSummary = {
    all_count: 0,
    canceled_count: 0,
    finished_count: 0,
    pending_count: 0,
};
export const PagePurchaseRequestList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const search_params = useSelector(selectContact).paramsContact;
    const [params, setParams] = useState(search_params);
    const org_id = useSelector(selectAuth).userInfo?.merchant._id;
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const [textSearch, setTextSearch] = useState<string>("");
    const [tempCatID, setTempCatID] = useState<string>("");
    const [pagination, setPagination] = useState<IPaginationNext>(INITAL_PAGE);
    const [services, setServices] = useState<IDataexample[]>([]);
    const [total, setTotal] = useState<number>(0);
    const { success, error } = useNotify();
    const history = useHistory();
    const [Refresh, setRefresh] = useState<any>(0);
    const [tempRefresh, setTempRefresh] = useState<number>(0);
    const userInfo = useSelector(selectAuth).userInfo;
    const [pOList, setPOList] = useState([]);
    const [TRList, setTRList] = useState<IGetTtrsExample[]>([]);
    const [storeList, setStoreList] = useState<IGetStoresBindingDropdownExample[]>([]);
    const [detailSum, setDetailSum] = useState<IDetailSummary>(initDetailSum);
    const [selectStore, setSelectStore] = useState<string>("");
    const [requestType, setRequestType] = useState<string>("PR");
    const { TabPane } = Tabs;
    const { adminPermission } = useSelector(selectAuth);

    useEffect(() => {
        if (requestType === "PR") {
            fetchPOList();
            setPagination(INITAL_PAGE);
        } else {
            getTtrs();
            setPagination(INITAL_PAGE);
        }
    }, [Refresh, textSearch, tempCatID, pagination, selectStore, requestType]);
    useEffect(() => {
        fetchStoreList();
        fetchDetailSum();
    }, []);

    useEffect(() => {
        handleSort;
    }, [tempRefresh]);

    const fetchStoreList = async () => {
        dispatch(setLoading(true));
        try {
            const res = await storeAPI.getStoresBindingDropdown({
                organization_id: org_id,
            });
            if (res.data.error === "0") {
                setStoreList(res.data.result);
            }
        } catch (err: any) {
            error(err.msg);
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };
    const fetchDetailSum = async () => {
        dispatch(setLoading(true));
        try {
            const res = await POAPI.getPOSummary({
                organization_id: userInfo?.merchant?._id || "",
            });
            if (res.data.error === "0") {
                setDetailSum(res.data.result);
            }
        } catch (e) {
            console.error(e);
        } finally {
            dispatch(setLoading(false));
        }
    };

    const getTtrs = async () => {
        try {
            const get = await POAPI.getTtrs({
                organization_id: org_id,
                store_id: selectStore,
                xpage: pagination.xpage,
                xlimit: pagination.xlimit,
                text_search: textSearch,
            });
            if (get.data.error === "0") {
                setTRList(get.data.result);
                setTotal(get.data.total);
            } else {
                error(get.data.msg);
            }
        } catch (err: any) {
            error(err.message);
        }
    };
    const fetchPOList = async () => {
        dispatch(setLoading(true));
        try {
            const res = await POAPI.getPRList({
                organization_id: userInfo?.merchant?._id || "",
                xpage: pagination.xpage,
                xlimit: pagination.xlimit,
                ...(selectStore !== "" && { store_id: selectStore }),
                text_search: textSearch,
            });
            if (res.data.error === "0") {
                setPOList(res.data.result);
                setTotal(res.data.total);
            }
        } catch (e) {
            console.error(e);
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleSort = (sortField: enumContactSortFields, sortBy: enumSortBy) => {
        const newSortParams = { ...params, sortField, sortBy };
        setParams(newSortParams);
        // fetchContacts(newSortParams);
        setServices(services.reverse());
        setTempRefresh(2);
    };

    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            const swapItem = (items: IDataexample[]) => {
                const oldIndex = items.findIndex((item) => JSON.stringify(item) === active.id);
                const newIndex = items.findIndex((item) => JSON.stringify(item) === over.id);
                return arrayMove(items, oldIndex, newIndex);
            };
            setServices(swapItem);
        }
    };
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
        useSensor(TouchSensor)
    );

    const handleSelectStore = (value) => {
        setSelectStore(value);
    };

    const handlePagination = (page) => {
        setPagination({ ...pagination, xpage: page });
    };

    const handleMenuClick = (e) => {
        history.push(`${PATH_PURCHASE_REQUEST_CREATE}?type=${e.key}`, { type: e.key });
    };
    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="product">{t("page.product")}</Menu.Item>
            <Menu.Item key="inventory">{t("page.inventory")}</Menu.Item>
        </Menu>
    );
    return (
        <StyledTable>
            <div className="page-layout">
                <div className="page-header-layout">
                    <div className="page-header">
                        <div>
                            <h3>{t("page.purchase_request.list_title")}</h3>
                        </div>
                        <div className="flex-header">
                            <Dropdown overlay={menu} trigger={["click"]}>
                                <StyledTableButtonTop
                                    type="danger"
                                    size="default"
                                    text={t("page.purchase_request.add_PR")}
                                    className="add-btn"
                                    icon="plus"
                                    style={{
                                        background: "#F22F46",
                                        borderRadius: "4px",
                                        width: "100%",
                                    }}
                                />
                            </Dropdown>
                        </div>
                    </div>
                    <div className="detail-box">
                        <Row
                            gutter={12}
                            type="flex"
                            align="middle"
                            className="detail-box-row"
                            justify="space-between"
                        >
                            <Col className="col-item" span={6}>
                                <div className="item-bg">
                                    <div className="icon-section">
                                        <IconAllPO />
                                        <p>{t("page.purchase_order.detail_all_PO_title")}</p>
                                    </div>
                                    <div className="number-section">
                                        <p className="number">{detailSum?.all_count}</p>
                                        <p className="word">
                                            {t("page.purchase_order.detail_sheets")}
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            <Col className="col-item" span={6}>
                                <div className="item-bg">
                                    <div className="icon-section">
                                        <IconPending />
                                        <p>{t("page.purchase_order.detail_pending_title")}</p>
                                    </div>
                                    <div className="number-section">
                                        <p className="number">{detailSum?.pending_count}</p>
                                        <p className="word">
                                            {t("page.purchase_order.detail_sheets")}
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            <Col className="col-item" span={6}>
                                <div className="item-bg">
                                    <div className="icon-section">
                                        <IconCancel />
                                        <p>{t("page.purchase_order.detail_cancel_title")}</p>
                                    </div>
                                    <div className="number-section">
                                        <p className="number">{detailSum?.canceled_count}</p>
                                        <p className="word">
                                            {t("page.purchase_order.detail_sheets")}
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            <Col className="col-item" span={6}>
                                <div className="item-bg">
                                    <div className="icon-section">
                                        <IconDone />
                                        <p>{t("page.purchase_order.detail_done_title")}</p>
                                    </div>
                                    <div className="number-section">
                                        <p className="number">{detailSum?.finished_count}</p>
                                        <p className="word">
                                            {t("page.purchase_order.detail_sheets")}
                                        </p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="search-layout">
                        <ComponentTablePOSearch
                            setTextSearch={setTextSearch}
                            handleSelectStore={handleSelectStore}
                            total={total}
                            isRangePicker={true}
                            storeList={
                                adminPermission?.pos_role.store_access_type === "all_store" ||
                                    adminPermission?.pos_role.store_access_type === undefined
                                    ? storeList
                                    : adminPermission?.store_ids
                            }
                        />
                    </div>
                    <StyledTab>
                        <Tabs
                            defaultActiveKey="1"
                            // activeKey={currentTab}
                            onChange={(key) => {
                                setRequestType(key);
                            }}
                        >
                            <TabPane tab={t("page.purchase_request_tab")} key="PR"></TabPane>
                            <TabPane tab={t("page.transfer_request_tab")} key="TR"></TabPane>
                        </Tabs>
                    </StyledTab>
                </div>
                <div className="page-table-layout">
                    <div className="table">
                        <ScrollContainer vertical={false}>
                            <div className="table_ground">
                                <div className="table-body">
                                    <div className="table-header">
                                        <Row
                                            gutter={24}
                                            type="flex"
                                            justify="space-between"
                                            align="middle"
                                            className="header-row"
                                        >
                                            <Col className="col-item" span={5}>
                                                <p>{t("page.purchase_order.table_create_date")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.FULL_NAME}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.FULL_NAME}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={5}>
                                                <p>{t("page.purchase_request.table_PR_number")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.POINTS}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.POINTS}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={5}>
                                                <p>{t("page.purchase_order.table_store")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.CREATED_AT}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.CREATED_AT}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={5}>
                                                <p>{t("page.purchase_request.table_status")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.CREATED_AT}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.CREATED_AT}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={3}>
                                                {/* <p>{t("page.manage")}</p> */}
                                            </Col>
                                        </Row>
                                    </div>
                                    <DndContext
                                        sensors={sensors}
                                        collisionDetection={closestCenter}
                                        onDragEnd={handleDragEnd}
                                    >
                                        <SortableContext
                                            items={services.map((item) => JSON.stringify(item))}
                                            strategy={rectSortingStrategy}
                                        >
                                            {requestType === "PR" && pOList?.length > 0 ? (
                                                <div className="data-table">
                                                    {pOList.map((item, index) => (
                                                        <ComponentRowPurchaseRequest
                                                            key={index}
                                                            data={item}
                                                            itemJson={JSON.stringify(item)}
                                                            index={index}
                                                            storeList={storeList}
                                                            requestType={requestType}
                                                            Refresh={Refresh}
                                                            setRefresh={setRefresh}
                                                        />
                                                    ))}
                                                    <div
                                                        style={{ width: "100%", height: 10 }}
                                                    ></div>
                                                </div>
                                            ) : (
                                                <div className="data-table">
                                                    {TRList.map((item, index) => (
                                                        <ComponentRowPurchaseRequest
                                                            key={index}
                                                            data={item}
                                                            itemJson={JSON.stringify(item)}
                                                            index={index}
                                                            storeList={storeList}
                                                            requestType={requestType}
                                                            Refresh={Refresh}
                                                            setRefresh={setRefresh}
                                                        />
                                                    ))}
                                                    <div
                                                        style={{ width: "100%", height: 10 }}
                                                    ></div>
                                                </div>
                                            )}
                                        </SortableContext>
                                    </DndContext>
                                </div>
                            </div>
                        </ScrollContainer>
                    </div>
                </div>

                <div className="page-bottom">
                    {total && total > 0 && (
                        <div className="pagination">
                            <Pagination
                                defaultCurrent={INITIAL_LIST_PARAMS.page || 1}
                                pageSize={PAGINATION}
                                total={total}
                                onChange={handlePagination}
                                current={pagination.xpage}
                            />
                        </div>
                    )}
                </div>
            </div>
        </StyledTable>
    );
};
const StyledTab = styled.div`
    .ant-tabs-ink-bar {
        background-color: ${theme.colors.main};
        height: 3px !important;
    }
    .ant-tabs-nav .ant-tabs-tab-active,
    .ant-tabs-nav .ant-tabs-tab:hover {
        color: ${theme.colors.main};
    }
`;
