import { ComponentQRCode, SharedInput, StyledCancelButton, StyledCard, StyledSubmitButton } from "@components";
import { PATH_STORE_CREATE, PATH_STORE_LIST } from "@configs";
import { Checkbox, Col, Input, Row, Select, Switch, TimePicker, Tree } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { ComponentMaps } from "./Map";
import { ComponentSearchInput } from "./Search";
import { IAddressData, IAmphur, IProvince, ISubDistrict } from "@interfaces";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectAuth } from "@redux";
const { Option } = Select;

interface IProps {
    store: any | undefined;
    handleBlur: any;
    setStoreName: any;
    storeName: any;
    setIsClientOrder: (value: boolean) => void;
    isClientOrder: boolean;
    setStoreCode: any;
    setPOSTaxID: any;
    bookingDurationSlot: string;
    setBookingDurationSlot: (value: string) => void;
    posTaxID: string;
    setStoreTaxID: any;
    storeTaxID: string;
    storeCode: any;
    setCustomerServiceTel: any;
    customerServiceTel: any;
    chooseByStaffView: any;
    setChooseByStaffView: any;
    setMonday: any;
    monday: any;
    setTuesday: any;
    tuesday: any;
    setWednesday: any;
    wednesday: any;
    setThursday: any;
    thursday: any;
    setFriday: any;
    friday: any;
    setSaturday: any;
    saturday: any;
    setSunday: any;
    sunday: any;
    setPOSCode: any;
    POSCode: any;
    setServiceCharge: any;
    serviceCharge: any;
    existServiceList: any;
    onCheck: any;
    services: any;
    handleChange: any;
    address: any;
    StyledCustom: any;
    setLat: any;
    setLon: any;
    setAddress: any;
    handleChangeLocation: any;
    lat: any;
    lon: any;
    isActive: any;
    seIsActive: any;
    handleNewsSubmit: any;
    StyledSwitch: any;
    addressData: IAddressData;
    provinceList: IProvince[];
    amphurList: IAmphur[];
    subDistrictList: ISubDistrict[];
    onChangeProvince: (value: any) => void;
    onChangeAmphur: (value: any) => void;
    onChangeSubDistrict: (value: any) => void;
    setTerminalID: any;
    setCompanyName: any;
    companyName: string;
    terminalID: string;
    setStoreAddress: any;
    storeAddress: string;
    wifiPassword: string;
    setWifiPassword: any;
    setBottomTitle: any;
    bottomTitle: string;
    channelList: any[];
    focusChannel: string;
    setFocusChannel: (value: string) => void;

}

export const StoreForm = (props: IProps) => {
    const {
        store,
        handleBlur,
        setStoreName,
        storeName,
        setIsClientOrder,
        isClientOrder,
        setStoreCode,
        storeCode,
        bookingDurationSlot,
        setBookingDurationSlot,
        setCustomerServiceTel,
        customerServiceTel,
        chooseByStaffView,
        setChooseByStaffView,
        setMonday,
        monday,
        setTuesday,
        tuesday,
        setWednesday,
        wednesday,
        setThursday,
        thursday,
        setFriday,
        friday,
        setSaturday,
        saturday,
        setSunday,
        sunday,
        setPOSCode,
        POSCode,
        setServiceCharge,
        serviceCharge,
        existServiceList,
        onCheck,
        services,
        handleChange,
        address,
        StyledCustom,
        setLat,
        setLon,
        setAddress,
        handleChangeLocation,
        lat,
        lon,
        isActive,
        seIsActive,
        handleNewsSubmit,
        StyledSwitch,
        setPOSTaxID,
        posTaxID,
        setStoreTaxID,
        storeTaxID,
        addressData,
        provinceList,
        amphurList,
        subDistrictList,
        onChangeProvince,
        onChangeAmphur,
        onChangeSubDistrict,
        setTerminalID,
        setCompanyName,
        terminalID,
        companyName,
        setStoreAddress,
        storeAddress,
        wifiPassword,
        setWifiPassword,
        setBottomTitle,
        bottomTitle,
        channelList,
        focusChannel,
        setFocusChannel,
    } = props;
    const format = "HH:mm";
    const { TreeNode } = Tree;
    const history = useHistory();
    const { t } = useTranslation();
    const { TextArea } = Input;
    const [isAllChannel, setIsAllChannel] = useState<boolean>(false);
    const location = useLocation();
    const pathname = location.pathname;
    const CRMURL = process.env.REACT_APP_CRM_URL;
    const [isCopy, setIsCopy] = useState<{ status: boolean; value: number }>({
        status: false,
        value: 0,
    });

    const { userInfo } = useSelector(selectAuth);
    const showTooltipCopy = (value: number) => {
        setIsCopy({ status: true, value: value });
        setTimeout(() => setIsCopy({ status: false, value: 0 }), 5000);
    };
    const bookingDurationList = [
        { value: "15 minutes", title: t("page.time_15_min") },
        { value: "30 minutes", title: t("page.time_30_min") },
        { value: "1 hour", title: t("page.time_1_hour") },
    ];

    return (
        <div className="page-body">
            <div className="card-wrap">
                <StyledCard>
                    <div className="title" style={{ fontSize: "25px", color: "#000" }}>
                        {t("page.store_settings")}
                    </div>
                    <Row gutter={32}>
                        <Col span={12}>
                            <SharedInput
                                label={t("page.name")}
                                descLabel={t("page.two_hundard_characters")}
                                name="storeName"
                                onBlur={handleBlur}
                                onChange={(value) => setStoreName(value.target.value)}
                                value={storeName}
                                parentClassName="mb-6"
                                placeholder="The future is now"
                            />
                        </Col>
                        <Col span={12}>
                            <SharedInput
                                label={t("page.company_name_for_tax_inv")}
                                descLabel={t("page.two_hundard_characters")}
                                name="companyName"
                                onBlur={handleBlur}
                                onChange={(value) => setCompanyName(value.target.value)}
                                value={companyName}
                                parentClassName="mb-6"
                                placeholder="The future is now"
                            />
                        </Col>
                    </Row>
                    <Row className="select-layout" gutter={32}>
                        <Col md={12}>
                            <SharedInput
                                label={t("page.store_code")}
                                name="storeCode"
                                onBlur={handleBlur}
                                onChange={(value) => setStoreCode(value.target.value)}
                                value={storeCode}
                                parentClassName="mb-6"
                                maxLength={5}
                                disable={store ? true : false}
                            />
                        </Col>
                        <Col md={12}>
                            <SharedInput
                                label={t("page.customer_service_tel")}
                                name="customerServiceTel"
                                onBlur={handleBlur}
                                onChange={(value) => setCustomerServiceTel(value.target.value)}
                                value={customerServiceTel}
                                parentClassName="mb-6"
                            />
                        </Col>
                    </Row>
                    <Row className="select-layout" gutter={32}>
                        <Col md={8}>
                            <SharedInput
                                label={t("page.store_tax_id") + " (13 digits)"}
                                name="storeTaxID"
                                onBlur={handleBlur}
                                onChange={(value) => setStoreTaxID(value.target.value)}
                                value={storeTaxID}
                                parentClassName="mb-6"
                                maxLength={13}
                            />
                        </Col>
                        <Col md={8}>
                            <SharedInput
                                label={t("page.terminal_tax_id_15_digit")}
                                name="terminalID"
                                onBlur={handleBlur}
                                onChange={(value) => setTerminalID(value.target.value)}
                                value={terminalID}
                                parentClassName="mb-6"
                                maxLength={15}
                            />
                        </Col>
                        <Col md={8}>
                            <SharedInput
                                label={t("page.pos_tax_id") + " (5 digits)"}
                                name="posTaxID"
                                onBlur={handleBlur}
                                onChange={(value) => setPOSTaxID(value.target.value)}
                                value={posTaxID}
                                parentClassName="mb-6"
                                maxLength={5}
                            />
                        </Col>
                    </Row>
                    <Row className="select-layout" gutter={32}>
                        {/* <Col md={8}>
                            <label htmlFor={"address"} className="label">
                                <span className="label-name">{t("page.address")}</span>
                            </label>
                            <TextArea
                                id="address"
                                autosize={{ minRows: 2, maxRows: 6 }}
                                className="mb-6"
                                value={storeAddress}
                                style={{ marginTop: 20 }}
                                onChange={(value) => setStoreAddress(value.target.value)}
                            />
                        </Col> */}
                        <Col md={8}>
                            <SharedInput
                                label={t("page.wifi_password")}
                                name="wifiPassword"
                                onBlur={handleBlur}
                                onChange={(value) => setWifiPassword(value.target.value)}
                                value={wifiPassword}
                                parentClassName="mb-6"
                                maxLength={15}
                            />
                        </Col>
                        <Col md={8}>
                            <SharedInput
                                label={t("page.bottom_title")}
                                name="bottomTitle"
                                onBlur={handleBlur}
                                onChange={(value) => setBottomTitle(value.target.value)}
                                value={bottomTitle}
                                parentClassName="mb-6"
                                maxLength={5}
                            />
                        </Col>
                    </Row>
                    <Row className="select-layout" style={{ marginBottom: "20" }}>
                        <Col span={12}>
                            <p className="label">{t("page.loyalty_channel")}</p>
                            <Select
                                showSearch
                                filterOption={(input, option) =>
                                    //@ts-ignore
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={setFocusChannel}
                                value={focusChannel}
                                placeholder="แขวง/ตำบล"
                                className="address-select"
                            >
                                {/* <Option value="" key={0}>
                                    Dine in
                                </Option> */}
                                {channelList?.length > 0 &&
                                    channelList?.map((sItem, sIndex) => {
                                        return (
                                            <Option value={sItem.loyalty_channel_id} key={sIndex}>
                                                {sItem?.loyalty_channel_name}
                                            </Option>
                                        );
                                    })}
                            </Select>
                        </Col>
                    </Row>
                    <Row className="select-layout" style={{ marginBottom: "20" }}>
                        <Col span={12}>
                            <p className="label">{t("page.set_step_time_slot")}</p>
                            <Select
                                showSearch
                                filterOption={(input, option) =>
                                    //@ts-ignore
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={setBookingDurationSlot}
                                value={bookingDurationSlot}
                                placeholder=""
                                className="address-select"
                            >
                                {/* <Option value="" key={0}>
                                    Dine in
                                </Option> */}
                                {bookingDurationList?.map((sItem, sIndex) => {
                                    return (
                                        <Option value={sItem.value} key={sIndex}>
                                            {sItem?.title}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Col>
                    </Row>
                    <Row className="select-layout" style={{ marginBottom: "0" }}>
                        <Col md={24}>
                            <div className="label">{t("page.client_side_choose_by_staff")}</div>
                            <StyledSwitch
                                className="switch-field"
                                style={{ gap: "10px", marginBottom: "0" }}
                            >
                                <Switch
                                    checked={chooseByStaffView}
                                    onChange={(e) => setChooseByStaffView(e)}
                                />
                                <p style={{ color: "#646464", fontWeight: 400 }}>
                                    {t("page.turn_on_choose_by_staff_view")}
                                </p>
                            </StyledSwitch>
                        </Col>
                    </Row>
                </StyledCard>
            </div>
            <div className="card-wrap">
                <StyledCard>
                    <Row gutter={32}>
                        <Col md={24}>
                            <div className="title" style={{ fontSize: "25px", color: "#000" }}>
                                {t("page.business_hours")}
                            </div>
                            <StyledSwitch>
                                <div className="weekDaysRow">
                                    <Checkbox
                                        onChange={(e) => {
                                            setMonday((state) => ({
                                                ...state,
                                                is_active: e.target.checked,
                                            }));
                                        }}
                                        checked={monday.is_active}
                                    >
                                        <p className="daysOfTheWeek">{t("day.mon")}</p>
                                        <TimePicker
                                            defaultValue={
                                                monday.open_hour
                                                    ? moment(monday.open_hour, format)
                                                    : moment("12:08", format)
                                            }
                                            format={format}
                                            size="large"
                                            onChange={(value) => {
                                                setMonday((state) => ({
                                                    ...state,
                                                    open_hour: value
                                                        .toDate()
                                                        .toTimeString()
                                                        .split(" ")[0]
                                                        .slice(0, -3),
                                                }));
                                            }}
                                        />
                                        -
                                        <TimePicker
                                            defaultValue={
                                                monday.close_hour
                                                    ? moment(monday.close_hour, format)
                                                    : moment("12:09", format)
                                            }
                                            format={format}
                                            size="large"
                                            onChange={(value) => {
                                                setMonday((state) => ({
                                                    ...state,
                                                    close_hour: value
                                                        .toDate()
                                                        .toTimeString()
                                                        .split(" ")[0]
                                                        .slice(0, -3),
                                                }));
                                            }}
                                        />
                                    </Checkbox>
                                </div>
                            </StyledSwitch>
                            <StyledSwitch>
                                <div className="weekDaysRow">
                                    <Checkbox
                                        onChange={(e) => {
                                            setTuesday((state) => ({
                                                ...state,
                                                is_active: e.target.checked,
                                            }));
                                        }}
                                        checked={tuesday.is_active}
                                    >
                                        <p className="daysOfTheWeek"> {t("day.tue")}</p>
                                        <TimePicker
                                            defaultValue={
                                                tuesday.open_hour
                                                    ? moment(tuesday.open_hour, format)
                                                    : moment("12:08", format)
                                            }
                                            format={format}
                                            size="large"
                                            onChange={(value) => {
                                                setTuesday((state) => ({
                                                    ...state,
                                                    open_hour: value
                                                        .toDate()
                                                        .toTimeString()
                                                        .split(" ")[0]
                                                        .slice(0, -3),
                                                }));
                                            }}
                                        />
                                        -
                                        <TimePicker
                                            defaultValue={
                                                tuesday.close_hour
                                                    ? moment(tuesday.close_hour, format)
                                                    : moment("12:09", format)
                                            }
                                            format={format}
                                            size="large"
                                            onChange={(value) => {
                                                setTuesday((state) => ({
                                                    ...state,
                                                    close_hour: value
                                                        .toDate()
                                                        .toTimeString()
                                                        .split(" ")[0]
                                                        .slice(0, -3),
                                                }));
                                            }}
                                        />
                                    </Checkbox>
                                </div>
                            </StyledSwitch>
                            <StyledSwitch>
                                <div className="weekDaysRow">
                                    <Checkbox
                                        onChange={(e) => {
                                            setWednesday((state) => ({
                                                ...state,
                                                is_active: e.target.checked,
                                            }));
                                        }}
                                        checked={wednesday.is_active}
                                    >
                                        <p className="daysOfTheWeek">{t("day.wed")}</p>
                                        <TimePicker
                                            defaultValue={
                                                wednesday.open_hour
                                                    ? moment(wednesday.open_hour, format)
                                                    : moment("12:08", format)
                                            }
                                            format={format}
                                            size="large"
                                            onChange={(value) => {
                                                setWednesday((state) => ({
                                                    ...state,
                                                    open_hour: value
                                                        .toDate()
                                                        .toTimeString()
                                                        .split(" ")[0]
                                                        .slice(0, -3),
                                                }));
                                            }}
                                        />
                                        -
                                        <TimePicker
                                            defaultValue={
                                                wednesday.close_hour
                                                    ? moment(wednesday.close_hour, format)
                                                    : moment("12:09", format)
                                            }
                                            format={format}
                                            size="large"
                                            onChange={(value) => {
                                                setWednesday((state) => ({
                                                    ...state,
                                                    close_hour: value
                                                        .toDate()
                                                        .toTimeString()
                                                        .split(" ")[0]
                                                        .slice(0, -3),
                                                }));
                                            }}
                                        />
                                    </Checkbox>
                                </div>
                            </StyledSwitch>
                            <StyledSwitch>
                                <div className="weekDaysRow">
                                    <Checkbox
                                        onChange={(e) => {
                                            setThursday((state) => ({
                                                ...state,
                                                is_active: e.target.checked,
                                            }));
                                        }}
                                        checked={thursday.is_active}
                                    >
                                        <p className="daysOfTheWeek">{t("day.thu")}</p>
                                        <TimePicker
                                            defaultValue={
                                                thursday.open_hour
                                                    ? moment(thursday.open_hour, format)
                                                    : moment("12:08", format)
                                            }
                                            format={format}
                                            size="large"
                                            onChange={(value) => {
                                                setThursday((state) => ({
                                                    ...state,
                                                    open_hour: value
                                                        .toDate()
                                                        .toTimeString()
                                                        .split(" ")[0]
                                                        .slice(0, -3),
                                                }));
                                            }}
                                        />
                                        -
                                        <TimePicker
                                            defaultValue={
                                                thursday.close_hour
                                                    ? moment(thursday.close_hour, format)
                                                    : moment("12:09", format)
                                            }
                                            format={format}
                                            size="large"
                                            onChange={(value) => {
                                                setThursday((state) => ({
                                                    ...state,
                                                    close_hour: value
                                                        .toDate()
                                                        .toTimeString()
                                                        .split(" ")[0]
                                                        .slice(0, -3),
                                                }));
                                            }}
                                        />
                                    </Checkbox>
                                </div>
                            </StyledSwitch>
                            <StyledSwitch>
                                <div className="weekDaysRow">
                                    <Checkbox
                                        onChange={(e) => {
                                            setFriday((state) => ({
                                                ...state,
                                                is_active: e.target.checked,
                                            }));
                                        }}
                                        checked={friday.is_active}
                                    >
                                        <p className="daysOfTheWeek">{t("day.fri")}</p>
                                        <TimePicker
                                            defaultValue={
                                                friday.open_hour
                                                    ? moment(friday.open_hour, format)
                                                    : moment("12:08", format)
                                            }
                                            format={format}
                                            size="large"
                                            onChange={(value) => {
                                                setFriday((state) => ({
                                                    ...state,
                                                    open_hour: value
                                                        .toDate()
                                                        .toTimeString()
                                                        .split(" ")[0]
                                                        .slice(0, -3),
                                                }));
                                            }}
                                        />
                                        -
                                        <TimePicker
                                            defaultValue={
                                                friday.close_hour
                                                    ? moment(friday.close_hour, format)
                                                    : moment("12:09", format)
                                            }
                                            format={format}
                                            size="large"
                                            onChange={(value) => {
                                                setFriday((state) => ({
                                                    ...state,
                                                    close_hour: value
                                                        .toDate()
                                                        .toTimeString()
                                                        .split(" ")[0]
                                                        .slice(0, -3),
                                                }));
                                            }}
                                        />
                                    </Checkbox>
                                </div>
                            </StyledSwitch>
                            <StyledSwitch>
                                <div className="weekDaysRow">
                                    <Checkbox
                                        onChange={(e) => {
                                            setSaturday((state) => ({
                                                ...state,
                                                is_active: e.target.checked,
                                            }));
                                        }}
                                        checked={saturday.is_active}
                                    >
                                        <p className="daysOfTheWeek">{t("day.sat")}</p>
                                        <TimePicker
                                            defaultValue={
                                                saturday.open_hour
                                                    ? moment(saturday.open_hour, format)
                                                    : moment("12:08", format)
                                            }
                                            format={format}
                                            size="large"
                                            onChange={(value) => {
                                                setSaturday((state) => ({
                                                    ...state,
                                                    open_hour: value
                                                        .toDate()
                                                        .toTimeString()
                                                        .split(" ")[0]
                                                        .slice(0, -3),
                                                }));
                                            }}
                                        />
                                        -
                                        <TimePicker
                                            defaultValue={
                                                saturday.close_hour
                                                    ? moment(saturday.close_hour, format)
                                                    : moment("12:09", format)
                                            }
                                            format={format}
                                            size="large"
                                            onChange={(value) => {
                                                setSaturday((state) => ({
                                                    ...state,
                                                    close_hour: value
                                                        .toDate()
                                                        .toTimeString()
                                                        .split(" ")[0]
                                                        .slice(0, -3),
                                                }));
                                            }}
                                        />
                                    </Checkbox>
                                </div>
                            </StyledSwitch>
                            <StyledSwitch>
                                <div className="weekDaysRow">
                                    <Checkbox
                                        onChange={(e) => {
                                            setSunday((state) => ({
                                                ...state,
                                                is_active: e.target.checked,
                                            }));
                                        }}
                                        checked={sunday.is_active}
                                    >
                                        <p className="daysOfTheWeek">{t("day.sun")}</p>
                                        <TimePicker
                                            defaultValue={
                                                sunday.open_hour
                                                    ? moment(sunday.open_hour, format)
                                                    : moment("12:08", format)
                                            }
                                            format={format}
                                            size="large"
                                            onChange={(value) => {
                                                setSunday((state) => ({
                                                    ...state,
                                                    open_hour: value
                                                        .toDate()
                                                        .toTimeString()
                                                        .split(" ")[0]
                                                        .slice(0, -3),
                                                }));
                                            }}
                                        />
                                        -
                                        <TimePicker
                                            defaultValue={
                                                sunday.close_hour
                                                    ? moment(sunday.close_hour, format)
                                                    : moment("12:09", format)
                                            }
                                            format={format}
                                            size="large"
                                            onChange={(value) => {
                                                setSunday((state) => ({
                                                    ...state,
                                                    close_hour: value
                                                        .toDate()
                                                        .toTimeString()
                                                        .split(" ")[0]
                                                        .slice(0, -3),
                                                }));
                                            }}
                                        />
                                    </Checkbox>
                                </div>
                            </StyledSwitch>
                        </Col>
                    </Row>
                </StyledCard>
            </div>
            <div className="card-wrap">
                <StyledCard>
                    <Row gutter={32}>
                        <Col md={12}>
                            <div className="title" style={{ fontSize: "25px", color: "#000" }}>
                                {t("page.pos_settings")}
                            </div>
                            <SharedInput
                                label={t("page.pos_code")}
                                // descLabel={t("page._2_100_characters")}
                                name="posCode"
                                onBlur={handleBlur}
                                onChange={(e: any) => {
                                    setPOSCode(e.target.value.replace(/\D/g, ""));
                                }}
                                // type={"numbers"}
                                value={POSCode}
                                errors={POSCode}
                                // touched={touched.posCode}
                                parentClassName="mb-6"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={5}>
                            <div className="title" style={{ fontSize: "16px", color: "#000" }}>
                                {t("page.service_charge")}
                            </div>
                            <Input
                                addonAfter="%"
                                placeholder="10"
                                onChange={(value) => {
                                    setServiceCharge(value.target.value);
                                }}
                                size="large"
                                value={serviceCharge}
                            />
                        </Col>
                    </Row>
                </StyledCard>
            </div>
            <div className="card-wrap">
                <StyledCard>
                    <Row>
                        <Col md={24}>
                            <div className="title" style={{ fontSize: "25px", color: "#000" }}>
                                {t("page.assign_services")}
                            </div>
                            <p className="sub-title">
                                {t("page.choose_the_services_that_will_be_assigned_to_the_store")}
                            </p>
                            <Tree
                                checkable
                                defaultExpandParent={true}
                                defaultExpandAll={true}
                                checkedKeys={existServiceList}
                                onCheck={onCheck}
                            >
                                <TreeNode title={t("page.select_all")} key="all">
                                    {services &&
                                        services.map((item, i) => (
                                            <TreeNode title={item.category} key={item.category}>
                                                {item.service &&
                                                    item.service.map((item2, j) => (
                                                        <TreeNode
                                                            title={item2.service_name}
                                                            key={item2.service_code}
                                                        />
                                                    ))}
                                            </TreeNode>
                                        ))}
                                </TreeNode>
                            </Tree>
                        </Col>
                    </Row>
                </StyledCard>
            </div>


            <div className="card-wrap">
                <StyledCard>
                    <div>
                        <Row className="select-layout" style={{ marginBottom: "0" }}>
                            <div className="title" style={{ fontSize: "25px", color: "#000" }}>
                                {t("page.online_store")}
                            </div>
                            <Col md={24}>
                                <div className="label">{t("page.store_on_client_order")}</div>
                                <StyledSwitch
                                    className="switch-field"
                                    style={{ gap: "10px", marginBottom: "0" }}
                                >
                                    <Switch
                                        checked={isClientOrder}
                                        onChange={(e) => setIsClientOrder(e)}
                                    />
                                    <p style={{ color: "#646464", fontWeight: 400 }}>
                                        {t("page.is_client_order")}
                                    </p>
                                </StyledSwitch>
                            </Col>
                        </Row>
                    </div>
                    <br />
                    {pathname !== PATH_STORE_CREATE && isClientOrder &&
                        <ComponentQRCode
                            qrCodeLink={`https://${userInfo?.merchant?.prefix}.${CRMURL}/?app=userorder&store_id=${store?._id}`}
                            // qrCodeLink="https://channadecafe.rocket-crm.app/coupon/87838cfb-bfcc-4ada-a929-e8e35c7e0969"
                            isCopy={isCopy}
                            reUpdateState={showTooltipCopy}
                        />
                    }
                </StyledCard>
            </div>

            <div className="card-wrap">
                <StyledCard>
                    <div className="title" style={{ fontSize: "25px", color: "#000" }}>
                        {t("page.location_setting")}
                    </div>
                    <Row gutter={16} style={{ marginBottom: 20 }}>
                        <Col span={12}>
                            <p className="input-title">{t("page.province")}</p>
                            <Select
                                showSearch
                                filterOption={(input, option) =>
                                    //@ts-ignore
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                value={addressData?.province}
                                placeholder={t("page.select_province")}
                                onChange={onChangeProvince}
                            >
                                <Option value={""} disabled>
                                    {t("page.select_province")}
                                </Option>
                                {provinceList?.map((pItem: any, pIndex: number) => {
                                    return (
                                        <Option value={pItem?.province_id} key={pIndex}>
                                            {pItem?.province_name_tha}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Col>
                        <Col span={12}>
                            <p className="input-title">{t("page.district")}</p>
                            <Select
                                showSearch
                                filterOption={(input, option) =>
                                    //@ts-ignore
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                value={addressData?.district}
                                placeholder={t("page.select_district")}
                                onChange={onChangeAmphur}
                            >
                                <Option value={""} disabled>
                                    {t("page.select_district")}
                                </Option>
                                {amphurList?.length > 0 &&
                                    amphurList?.map((aItem, aIndex) => {
                                        return (
                                            <Option value={aItem?.amphur_id} key={aIndex}>
                                                {aItem?.amphur_name_tha}
                                            </Option>
                                        );
                                    })}
                            </Select>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <p className="input-title">{t("page.subDistrict")}</p>
                            <Select
                                showSearch
                                filterOption={(input, option) =>
                                    //@ts-ignore
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={onChangeSubDistrict}
                                value={
                                    addressData.subDistrict === ""
                                        ? undefined
                                        : parseInt(addressData.subDistrict)
                                }
                                placeholder={t("page.select_subdistrict")}
                                className="address-select"
                            >
                                <Option value={""} disabled>
                                    {t("page.select_subdistrict")}
                                </Option>
                                {subDistrictList?.length > 0 &&
                                    subDistrictList?.map((sItem, sIndex) => {
                                        return (
                                            <Option value={sIndex} key={sIndex}>
                                                {sItem?.district_name_tha}
                                            </Option>
                                        );
                                    })}
                            </Select>
                        </Col>
                        <Col span={12}>
                            <SharedInput
                                label={t("page.address")}
                                name="address"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                labelStyle={{ marginBottom: 15 }}
                                value={address}
                                parentClassName="mb-6"
                                placeholder="Mobicom 3, Sathorn, Bangkok"
                                disable
                            />
                        </Col>
                    </Row>
                    <Row>
                        <StyledCustom>
                            <Col md={12}>
                                <ComponentSearchInput
                                    onChangeLocation={(lat, lon, address) => {
                                        setLat(lat);
                                        setLon(lon);
                                        setAddress(address);
                                    }}
                                />
                            </Col>
                        </StyledCustom>
                    </Row>
                    <Row>
                        <StyledCustom>
                            <Col md={24} className="mapComponent">
                                <ComponentMaps
                                    onChangeLocation={handleChangeLocation}
                                    location_lat={lat}
                                    location_lon={lon}
                                />
                            </Col>
                        </StyledCustom>
                    </Row>
                </StyledCard>
            </div>
            <StyledSwitch className="switch-field">
                <p>{t("page.activate")}</p>
                <Switch
                    checked={isActive}
                    onChange={(value) => {
                        seIsActive(value);
                    }}
                />
            </StyledSwitch>
            <div className="btn-layout">
                <StyledSubmitButton
                    type="default"
                    text={t("page.save")}
                    htmlType="submit"
                    // disable={isSubmitting}
                    onClick={handleNewsSubmit}
                />
                <StyledCancelButton
                    type="sub"
                    text={t("page.cancel")}
                    htmlType="button"
                    onClick={() => history.push(PATH_STORE_LIST)}
                />
            </div>
        </div>
    );
};
