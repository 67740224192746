import { useTranslation } from "react-i18next";
import { StyledSubmitButton } from "../shared";
import styled from "styled-components";
import { Col, Row } from "antd";
import { SharedBranchModal } from "../shared/Branch Modal";
import {
    ArrowRightLine,
    EmptySuccessCheckBox,
    FoldersIcon,
    ImportCheckedIcon,
    SeperatorLine,
    TableSvg,
    VerificationCheckedPendingIcon,
} from "../icon";

interface IProps {
    showPopup: boolean;
    handleStatus: () => void;
}

export const ComponentImportTableModal = (props: IProps) => {
    const { showPopup, handleStatus } = props;
    const { t } = useTranslation();

    return (
        <SharedBranchModal showPopup3={showPopup} width={470} closable={false}>
            <StyledImportTableModal>
                <Row className="table-head">
                    <Col span={24}>
                        <p className="title2">{t("page.import_to_create_product_here")}</p>
                        <div className="statusRow">
                            <div className="statusCol">
                                <ImportCheckedIcon />
                                <p className="body3">{t("page.import")}</p>
                            </div>
                            <SeperatorLine />
                            <div className="statusCol">
                                <VerificationCheckedPendingIcon />
                                <p className="body3">{t("page.verification")}</p>
                            </div>
                            <SeperatorLine />
                            <div className="statusCol">
                                <EmptySuccessCheckBox />
                                <p className="body3">{t("page.success")}</p>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <div className="svgMotionRow">
                            <FoldersIcon />
                            <ArrowRightLine />
                            <TableSvg />
                        </div>
                    </Col>
                </Row>
                <Row className="btnRow">
                    <Col span={24}>
                        <StyledSubmitButton
                            type="default"
                            text={t("page.view_status")}
                            htmlType="submit"
                            onClick={handleStatus}
                        />
                    </Col>
                </Row>
            </StyledImportTableModal>
        </SharedBranchModal>
    );
};

const StyledImportTableModal = styled.div`
    overflow-y: auto;
    .table-head {
        margin-bottom: 20px;
        p {
            color: #000;
            text-align: center;
        }
    }
    .statusRow {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        .statusCol {
            display: flex;
            flex-direction: row;
            gap: 8px;
            align-items: center;
        }
        p {
            margin: 0;
        }
    }
    .svgMotionRow {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 16px;
        justify-content: space-between;
        padding: 16px;
        margin-bottom: 20px;
    }
    button[type="submit"] {
        width: 100%;
        background: #f22f46 !important;
    }
    button[type="submit"]:hover {
        box-shadow: 0px 4px 12px rgb(224 2 2 / 50%);
    }
`;
