import { Modal } from "antd";
import { ReactNode } from "react";

interface IProps {
    showPopup: boolean;
    handleClose: () => void;
    children: ReactNode;
    title: string;
    width: number;
}
export const SharedModal = (props: IProps) => {
    const { showPopup, handleClose, children, title, width } = props;
    return (
        <Modal visible={showPopup} onCancel={handleClose} title={title} footer={null} width={width}>
            {children}
        </Modal>
    );
};
