import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Col, Row, Button, Progress } from "antd";
import { IDataexample, IGetStoresBindingDropdownExample } from "@interfaces";
import { PATH_GOOD_RECEIVE } from "@configs";
import { selectAuth } from "@redux";
import { useSelector } from "react-redux";
import { IconDrag } from "@components";
import { useNotify } from "@utils";
import { useEffect, useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useHistory } from "react-router";

interface IProps {
    data: IDataexample | any;
    itemJson: string;
    index: number;
    storeList: IGetStoresBindingDropdownExample[];
}

export const ComponentRowGoodReceive = (props: IProps) => {
    //hooks
    const { t } = useTranslation();
    const { success, error } = useNotify();
    const history = useHistory();
    const organization_id = useSelector(selectAuth).auth?.organization_data?._id;
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const [color, setColor] = useState("#FFA043");
    //props
    const { data, itemJson, storeList } = props;

    const { attributes, listeners, setNodeRef, transform } = useSortable({
        id: itemJson,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
    };
    const MONTHlIST = [
        t("month.jan"),
        t("month.feb"),
        t("month.mar"),
        t("month.apr"),
        t("month.may"),
        t("month.jun"),
        t("month.jul"),
        t("month.aug"),
        t("month.sep"),
        t("month.oct"),
        t("month.nov"),
        t("month.dec"),
    ];

    const renderStore = () => {
        const findStore = storeList.find((obj) => obj._id === data?.store_id);
        return <>{findStore?.store_name || "-"}</>;
    };

    useEffect(() => {
        if (data?.status === "finished") {
            setColor("#6CD14E");
        } else if (data?.pending_percent === 100) {
            setColor("#6CD14E");
        }
    }, []);

    function formatDate(date) {
        let d = new Date(date),
            month = "" + d.getMonth(),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        return [day, MONTHlIST[parseInt(month)], year + 543].join(" ");
    }
    return (
        <div className="table-element" ref={setNodeRef} style={style}>
            <div className="drag-icon hide" {...attributes} {...listeners}>
                <IconDrag />
            </div>
            <div className="table-row">
                <Row
                    style={{ height: "100%" }}
                    gutter={24}
                    type="flex"
                    align="middle"
                    justify="space-between"
                >
                    <Col className="col-item" span={3}>
                        <p>{formatDate(data?.created_date) || t("page.empty_text")}</p>
                    </Col>
                    <Col className="col-item" span={6}>
                        <Link to={`${PATH_GOOD_RECEIVE}/${data._id}`}>
                            <p className="news-title">{data.pr_no || t("page.empty_text")}</p>
                        </Link>
                    </Col>
                    <Col className="col-item visible-md" span={4}>
                        <p>{data.supplier_name || t("page.empty_text")}</p>
                    </Col>

                    <Col className="col-item visible-md" span={4}>
                        <p>{renderStore()}</p>
                    </Col>
                    <Col className="col-item progress" span={4}>
                        <Progress
                            className="progress-bar"
                            percent={data.pending_percent}
                            showInfo={false}
                            // strokeColor={data.pending_percent !== 100 ? "#FFA043" : "#6CD14E"}
                            strokeColor={color}
                        />
                        <span>
                            {data.pr_receive_list.length}/{data.po_consumable_list.length}{" "}
                            {t("page.item")}
                        </span>
                    </Col>
                    <Col className="col-item" span={3}>
                        <Button
                            className="btn-in-table"
                            onClick={() => {
                                history.push(`${PATH_GOOD_RECEIVE}/${data._id}`);
                            }}
                        >
                            {data?.status === "pending" ? "รับสินค้า" : "ดูรายละเอียด"}
                        </Button>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
