import React, { useEffect, memo, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Row, Col, Select, Radio, Input, Table, Button, Divider, DatePicker } from "antd";
import styled from "styled-components";
import {
    INewsData,
    INewsCategory,
    ICreatePR,
    IUpdatePR,
    IGetAutoGen,
    IConsumeListPayload,
    IGetAdminDataByTokenAdminDataExample,
    IGetStoresBindingDropdownExample,
} from "@interfaces";
import {
    StyledSubmitButton,
    StyledCancelButton,
    StyledCard,
    Step1Icon,
    Step2Icon,
    Step3Icon,
    Step4Icon,
    StyledTableButtonAddRow,
    IconMinusCircle,
    IconAddCircle,
    ComponentPOModal,
    IconDelete,
    BranchIcon,
} from "@components";
import { useYup } from "@validations";
import { setLoading, selectAuth } from "@redux";
import { showErrorMessage, theme, useNotify } from "@utils";
import {
    PAGE_START,
    PAGINATION,
    PATH_PRODUCT,
    PATH_PURCHASE_ORDER_CREATE,
    PATH_PURCHASE_REQUEST,
    dateFormatYear,
} from "@configs";
import { POAPI, adminAPI, consumableAPI, orgApi, productAPI, storeAPI } from "@api";
import { ColumnProps } from "antd/lib/table";
import { PurchaseRequestIcon } from "src/Components/icon/purchaseRequest/PurchaseRequestIcon";
import { TransferRequestIcon } from "src/Components/icon/purchaseRequest/TransferRequestIcon";
import { EditIcon } from "src/Components/icon/purchaseRequest/EditIcon";
import { PRTransferModal } from "./PRTransferModal";
import moment from "moment";

interface IProps {
    product: any | undefined;
}
interface IConsume {
    consumable_id: string;
    name: string;
    restock_price: number;
    on_stork: number;
    add_qty: number;
    unit: string;
}

interface ITPRConsume {
    consumable_id: string;
    restock_qty: number;
}
interface ICreateTRList {
    consumable_id: string;
    restock_qty: number;
    request_to_store_id: string;
}

interface ICateItem {
    category: string;
    cate_id: string;
    itemList: any[];
}

const INITAL_PAGE = {
    xpage: PAGE_START,
    xlimit: PAGINATION,
};

const newDate = new Date();

const initData: INewsData = {
    organization_id: "",
    title: "",
    detail: "",
    image_url: [],
    is_hot_news: false,
    news_category_id: "",
    date_from: new Date(),
    date_to: new Date(newDate.setFullYear(newDate.getFullYear() + 1)),
    is_enable: true,
    discount: "",
    product_code: "",
    price: "",
    discount_type: "",
    has_barcode: true,
    active_on_platform: [],
    point_deduction: false,
    maximum_deduction: false,
    point_deduction_value: "",
    variants: [
        {
            variant_name: "",
            qty: "",
            raw_price: "",
            net_price: "",
            barcode: "",
            status: true,
            store_list: [
                {
                    store_code: "",
                    qty: 0,
                },
            ],
        },
    ],
    hint_code: "",
    sku_earn_point: 0,
};
export const ModulePRForm = memo((props: IProps) => {
    const { YupNews } = useYup();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const params = new URLSearchParams(window.location.search);
    const itemType = params.get("type");
    const { error, success, warning } = useNotify();
    const [step, setStep] = useState(1);
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [hasVariant, setHasVariant] = useState<boolean>(false);
    const [Stores, setStores] = useState<IGetStoresBindingDropdownExample[]>([]);
    const organization_id = useSelector(selectAuth).userInfo?.merchant?._id;
    const [cat, setCat] = useState<string>("");
    const pathName = window.location.pathname;
    const createNews = Yup.object().shape(YupNews);
    const [multipleImages, setMultipleImages] = useState<string[]>([]);
    const [focusStore, setFocusStore] = useState<string>("");
    const [consumeList, setConsumeList] = useState<IConsumeListPayload[]>([]);
    const { product } = props;
    const [isActive, setIsActive] = useState<boolean>();
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const userInfo = useSelector(selectAuth).userInfo;
    const org_id = useSelector(selectAuth).userInfo?.merchant?._id;
    const [prType, setPrType] = useState<string>("PR");
    const [transferStep, setTransferStep] = useState<boolean>(false);
    const [transferBranchModal, setTransferBranchModal] = useState<boolean>(false);
    const [consumeIndex, setConsumeIndex] = useState<number>(0);
    const [dueDate, setDueDate] = useState<string>(new Date().toString());
    const [cateList, setCateList] = useState<any>([]);
    const [focusCate, setFocusCate] = useState<number>(0);
    const [cateItemList, setCateItemList] = useState<ICateItem[]>([]);
    const [isFinish, setIsFinish] = useState<boolean>(false);
    const [textFilter, setTextFilter] = useState<string>("");
    const [isAll, setIsAll] = useState<boolean>(false);
    const { adminPermission } = useSelector(selectAuth);

    useEffect(() => {
        fetchStoreList();
    }, [product]);

    useEffect(() => {
        if (focusStore) {
            fetchCateList();
        }
    }, [focusStore]);

    useEffect(() => {
        if (id) {
            fetchPR();
        }
    }, [id]);

    const { Option } = Select;

    const changeCate = (cIndex: number) => {
        setFocusCate(cIndex);
        setIsAll(false);
    };

    const fetchPR = async () => {
        let res = await POAPI.getPR({
            organization_id: org_id || "",
            tpr_id: id,
        });
        if (res.data.error === "0") {
            let arr = [];
            res?.data?.result?.tpr_consumable_list?.forEach((element) => {
                let cObj = {
                    add_qty: element?.restock_qty || 0,
                    consumable_id: element?.consumable_id,
                    name: element?.consumable_name,
                    unit: element?.restock_unit || "restock unit",
                };
                //@ts-ignore
                arr.push(cObj);
            });
            setConsumeList([...arr]);
            setFocusStore(res?.data?.result?.store_id);
            setDueDate(res.data.result.due_date_str);
            if (res.data.result?.status !== "pending") setIsFinish(true);
        }
    };

    const fetchCateList = async () => {
        try {
            dispatch(setLoading(true));
            let cateResult: any[] = [];
            if (itemType === "inventory") {
                // inv
                const getCateList = await orgApi.getInvCat({
                    organization_id: userInfo?.merchant?._id || "",
                });
                cateResult = getCateList.data.result;
            } else {
                // product
                const getAllProductCats = await orgApi.getProductCategorys({
                    organization_code: orgCode,
                });
                let outArr: any[] = [];
                getAllProductCats.data.result.forEach((cItem: any) => {
                    let obj = {
                        code: cItem?.code,
                        category: cItem?.category,
                        item_series: "",
                        item_group: "",
                        _id: cItem?._id,
                    };
                    outArr.push(obj);
                });
                cateResult = outArr;
            }

            let payload = [
                ...cateResult,
                {
                    code: "",
                    category: "No cat",
                    item_series: "",
                    item_group: "",
                    _id: "",
                },
            ];

            let arr: ICateItem[] = [];
            payload.forEach((pItem: any) => {
                let obj: ICateItem = {
                    category: pItem.category,
                    cate_id: pItem._id,
                    itemList: [],
                };
                arr.push(obj);
            });
            setFocusCate(0);

            const getAllItem = await consumableAPI.getConsumablesStoreIdForCreatePR({
                organization_id: org_id,
                xpage: 1,
                xlimit: 9999,
                is_enable: true,
                is_auto_consumable: itemType === "product" ? true : false,
                store_id: focusStore,
                is_enable_popr: true,
                // consumable_category_id: cateList?.[focusCate || 0]?._id,
            });
            const allList = getAllItem.data.result;
            let cateArr: any[] = cateList;
            let updateGroup: any[] = [];
            arr.forEach((groupItem) => {
                const filteredItems = allList.filter((item) => {
                    const categoryId =
                        item.consumable_category_id || item?.product_category_id || ""; // Replace with your actual property
                    return categoryId === groupItem.cate_id;
                });
                let itemArr: any = [];
                filteredItems.forEach((cItem, cIndex) => {
                    let outQty: any = 0;

                    if (id) {
                        // for edit pr
                        let checkArr = consumeList.find((fItem) => {
                            return fItem.consumable_id === cItem?._id;
                        });
                        if (checkArr) {
                            outQty = checkArr.add_qty;
                        }
                    }
                    if (id && outQty !== 0) {
                        itemArr.push({
                            consumable_id: cItem._id,
                            name: cItem.name,
                            restock_price: cItem.cost,
                            on_stork: cItem.consumable_data.restock_qty,
                            add_qty: outQty,
                            unit: cItem.formula[0].restock_unit_name,
                            delivery_qty: cItem?.restock_formula?.[0]?.to_restock_unit || 1,
                            index: (itemArr.length),
                        });
                    } else if (!id) {
                        itemArr.push({
                            consumable_id: cItem._id,
                            name: cItem.name,
                            code: cItem.consumable_code,
                            restock_price: cItem.cost,
                            on_stork: cItem.consumable_data.restock_qty,
                            add_qty: outQty,
                            unit: cItem.formula[0].restock_unit_name,
                            delivery_qty: cItem?.restock_formula?.[0]?.to_restock_unit || 1,
                            index: (itemArr.length),
                        });
                    }
                });
                if (itemArr.length > 0 || (!id)) {
                    updateGroup.push({
                        category: groupItem.category,
                        cate_id: groupItem.cate_id,
                        itemList: itemArr,
                    });
                } else {
                    payload = payload.filter((cItem) => cItem._id !== groupItem.cate_id);
                }
            });
            setCateList(payload);
            setCateItemList([...updateGroup]);
        } catch (err: any) {
            error(err.msg);
        } finally {
            dispatch(setLoading(false));
        }
    };

    const fetchStoreList = async () => {
        dispatch(setLoading(true));
        try {
            const getStores = await storeAPI.getStoresBindingDropdown({
                organization_id: org_id,
            });
            const tempStore: any = [];
            for (let i = 0; i < getStores.data.result.length; i++) {
                const new_item = {
                    store_name: getStores.data.result[i].store_name,
                    store_code: getStores.data.result[i].store_code,
                    qty: 0,
                    is_enable: false,
                    _id: getStores.data.result[i]._id,
                };
                tempStore.push(new_item);
            }
            setStores(tempStore);
            if (!product) {
                if (hasVariant === false) {
                    setTableData2(tempStore);
                }
            }
        } catch (err) {
            console.error(err);
        } finally {
            dispatch(setLoading(false));
        }
    };

    const addConsume = (payload: IConsumeListPayload) => {
        const arr = cateItemList;
        const found = arr[focusCate].itemList.some((obj: any) => obj.name === payload.name);
        if (found === false) {
            let lastIndex = arr[focusCate].itemList[arr[focusCate].itemList.length - 1].index;
            arr[focusCate].itemList.push({ ...payload, index: (lastIndex + 1) });
            setCateItemList(arr);

            // arr.push(payload);
            // setConsumeList(arr);
        } else {
            warning("Already selected");
        }
    };

    const clickAutoGen = async () => {
        let payload: IGetAutoGen = {
            organization_id: organization_id || "",
            store_id: focusStore,
        };
        dispatch(setLoading(true));
        let res = await POAPI.getAutoGen(payload);

        if (res?.data?.error === "0") {
            let arr = [];
            let autoGenList = res.data.result;
            let newCate = [...cateItemList];
            autoGenList.forEach((aItem, aIndex) => {
                let checkIndex: any = null;
                cateItemList.forEach((cItem, cIndex) => {
                    if (cItem.cate_id === aItem.consumable_category_id) {
                        checkIndex = cIndex;
                    }
                });
                if (checkIndex === null) {
                    checkIndex = newCate.length - 1;
                }
                if (checkIndex !== null) {
                    newCate?.[checkIndex]?.itemList?.forEach((fItem, fIndex) => {
                        if (fItem?.consumable_id === aItem._id) {
                            newCate[checkIndex].itemList[fIndex].add_qty = aItem?.store.need_add;
                        }
                    });
                }
            });
            setCateItemList([...newCate]);
        }
        dispatch(setLoading(false));
    };

    const handleNewsSubmit = async (Values: INewsData) => {
        dispatch(setLoading(true));
        try {
            // setTableData(tempVariant);

            if (product) {
                if (hasVariant) {
                    // update with variant
                    const res = await productAPI.update({
                        ...product,
                        organization_code: orgCode,
                        product_code: values.product_code,
                        name: values.title,
                        standard_price: parseInt(values.price),
                        discount_in_baht: parseInt(values.discount),
                        category_id: values.news_category_id,
                        sub_category_id: "",
                        image_url_list: multipleImages,
                        is_variant: hasVariant,
                        variant_name_header: "",
                        description: values.detail,
                        condition: "",
                        text_block_list: [],
                        is_delete: false,
                        is_enable: isActive,
                        _id: product._id,
                        variant_list: tableData,
                        sku_earn_point: values.sku_earn_point || 0,
                        hint_code: values?.hint_code || "",
                        is_share_consumable: false,
                    });
                    if (res.data.error === "0") {
                        success(t("message.update.success"));
                        history.push(PATH_PRODUCT);
                    }
                } else {
                    // update without variant
                    const res = await productAPI.update({
                        ...product,
                        organization_code: orgCode,
                        product_code: values.product_code,
                        name: values.title,
                        standard_price: parseInt(values.price),
                        discount_in_baht: parseInt(values.discount),
                        category_id: values.news_category_id,
                        sub_category_id: "",
                        image_url_list: multipleImages,
                        is_variant: hasVariant,
                        variant_name_header: "",
                        description: values.detail,
                        condition: "",
                        text_block_list: [],
                        is_delete: false,
                        is_enable: isActive,
                        _id: product._id,
                        variant_list: [
                            {
                                variant_code: barcode,
                                variant_name: values.title,
                                is_enable: true,
                                store_list: tableData2,
                                raw_price: parseInt(values.price),
                                dimension_list: [],
                            },
                        ],
                        sku_earn_point: values.sku_earn_point || 0,
                        hint_code: values?.hint_code || "",
                        is_share_consumable: false,
                    });
                    if (res.data.error === "0") {
                        success(t("message.update.success"));
                        history.push(PATH_PRODUCT);
                    }
                }
            } else {
                if (hasVariant) {
                    // create with variant
                    const res = await productAPI.create({
                        ...product,
                        organization_id: org_id,
                        product_code: values.product_code,
                        name: values.title,
                        standard_price: parseInt(values.price),
                        discount_in_baht: parseInt(values.discount),
                        category_id: values.news_category_id,
                        image_url_list: multipleImages,
                        is_variant: hasVariant,
                        variant_name_header: "",
                        variant_list: tableData,
                        description: values.detail,
                        condition: "",
                        text_block_list: [],
                        is_enable: isActive,
                        //
                        discount_type: values.discount_type,
                        has_barcode: values.has_barcode,
                        active_on_platform: values.active_on_platform,
                        point_deduction: values.point_deduction,
                        maximum_deduction: values.maximum_deduction,
                        point_deduction_value: values.point_deduction_value,
                        hint_code: values?.hint_code || "",
                        sku_earn_point: values?.sku_earn_point || 0,
                        is_share_consumable: false,
                    });
                    if (res.data.error === "0") {
                        success(t("message.create.success"));
                        history.push(PATH_PRODUCT);
                        setTableData([]);
                        setTempVariant([]);
                        setMultipleImages([]);
                        setFieldValue("product_code", "");
                        setFieldValue("price", "");
                        setFieldValue("discount", "");
                        setFieldValue("news_category_id", "");
                        setFieldValue("detail", "");
                        setFieldValue("image_url", "");
                        setFieldValue("title", "");
                    }
                } else {
                    // create without variant
                    const res = await productAPI.create({
                        ...product,
                        organization_id: org_id,
                        product_code: values.product_code,
                        name: values.title,
                        standard_price: parseInt(values.price),
                        discount_in_baht: parseInt(values.discount),
                        category_id: values.news_category_id,
                        image_url_list: multipleImages,
                        is_variant: hasVariant,
                        variant_name_header: "",
                        variant_list: [
                            {
                                variant_code: barcode,
                                variant_name: values.title,
                                is_enable: true,
                                store_list: tableData2,
                                raw_price: parseInt(values.price),
                                dimension_list: [],
                            },
                        ],
                        description: values.detail,
                        condition: "",
                        text_block_list: [],
                        is_enable: isActive,
                        //
                        discount_type: values.discount_type,
                        has_barcode: values.has_barcode,
                        active_on_platform: values.active_on_platform,
                        point_deduction: values.point_deduction,
                        maximum_deduction: values.maximum_deduction,
                        point_deduction_value: values.point_deduction_value,
                        hint_code: values?.hint_code || "",
                        sku_earn_point: values?.sku_earn_point || 0,
                        is_share_consumable: false,
                    });

                    if (res.data.error === "0") {
                        success(t("message.create.success"));
                        history.push(PATH_PRODUCT);
                        setTableData([]);
                        setTempVariant([]);
                        setMultipleImages([]);
                        setFieldValue("product_code", "");
                        setFieldValue("price", "");
                        setFieldValue("discount", "");
                        setFieldValue("news_category_id", "");
                        setFieldValue("detail", "");
                        setFieldValue("image_url", "");
                        setFieldValue("title", "");
                    }
                }
            }
        } catch (err: any) {
            if (err.response) {
                error(showErrorMessage(err.response));
            } else error(t("message.error"));
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleCancel = () => {
        history.goBack();
    };

    const { values, handleChange, handleBlur, handleSubmit, errors, touched, setFieldValue } =
        useFormik({
            initialValues: initData,
            validationSchema: createNews,
            enableReinitialize: true,
            onSubmit: handleNewsSubmit,
        });

    // create new sgtate update on it state

    const changeFocusStore = (value: string) => {
        setFocusStore(value);
    };

    // Editable Variant Table
    interface IMyTableData {
        id: string;
        variant_name: string;
        quantity?: string;
        raw_price: number;
        net_price?: number;
        barcode: string;
        status?: boolean;
        is_enable: boolean;
        store_list: [
            {
                store_code: string;
                qty: number;
                is_enable: boolean;
            }
        ];
    }

    interface ITableInv {
        name: string;
        stock: string;
        orderNum: number;
        orderUnit: string;
    }

    const [tempVariant, setTempVariant]: any = useState<IMyTableData[]>([]);
    const [tempBranch, setTempBranch]: any = useState<any>([]);

    const [barcode, setBarcode] = useState<string>("");
    const [tableData, setTableData] = useState(tempVariant);
    const [tableData2, setTableData2] = useState<any>(tempBranch);

    const renderTableData = () => {
        if (isAll) {
            return [];
        } else {
            return cateItemList[focusCate || 0]?.itemList || [];
        }
    };
    // Branch Table
    const columnsInvPR: ColumnProps<IConsumeListPayload>[] = [
        {
            dataIndex: "name",
            title: `${t("page.purchase_order.InvQ_row_name")}`,
            key: "name",
            width: "50%",
            render: (text, record, index) => (
                <>
                    <div style={{ placeItems: "center", display: "flex", fontSize: "14px" }}>
                        {text}
                    </div>
                </>
            ),
        },
        {
            dataIndex: "add_qty",
            width: "50%",
            key: "qty",
            title: `${t("page.purchase_order.InvQ_row_order")}`,
            align: "right",
            render: (text, record, index) => (
                <div className="quantity-columns">
                    <span
                        onClick={() => {
                            if (!isFinish) changeQty("minus", record?.index);
                        }}
                    >
                        <IconMinusCircle
                            color={`${cateItemList?.[focusCate || 0]?.itemList?.[record?.index]?.[
                                "add_qty"
                            ] === 0 ||
                                cateItemList?.[focusCate || 0]?.itemList?.[record?.index]?.[
                                "add_qty"
                                ] === ""
                                ? "#A5A5A5"
                                : "black"
                                }`}
                        />
                    </span>

                    <Input
                        value={text}
                        onChange={onInputChange2("add_qty", record?.index)}
                        onBlur={inputBlur(record?.index)}
                        disabled={isFinish}
                    />
                    <span
                        onClick={() => {
                            if (!isFinish) changeQty("add", record?.index);
                        }}
                    >
                        <IconAddCircle color="black" />
                    </span>
                    <p>{cateItemList?.[focusCate || 0]?.itemList?.[record?.index]?.unit}&nbsp;</p>
                </div>
            ),
        },
    ];
    const columnsInv: ColumnProps<IConsumeListPayload>[] = [
        {
            dataIndex: "name",
            title: `${t("page.purchase_order.InvQ_row_name")}`,
            key: "name",
            width: "30%",
            render: (text, record, index) => (
                <>
                    <div style={{ placeItems: "center", display: "flex", fontSize: "14px" }}>
                        {text}
                    </div>
                </>
            ),
        },
        {
            dataIndex: "code",
            title: `${t("page.purchase_order.InvQ_row_code")}`,
            key: "code",
            width: "30%",
            render: (text, record, index) => (
                <>
                    <div style={{ placeItems: "center", display: "flex", fontSize: "14px" }}>
                        {text}
                    </div>
                </>
            ),
        },
        {
            dataIndex: "on_stork",
            title: `${t("page.purchase_order.InvQ_row_stock")}`,
            key: "on_stock",
            width: "25%",
            render: (text, record, index) => (
                <>
                    <div style={{ fontSize: "14px", textAlign: "center" }}>
                        {Number.isInteger(text) ? text.toString() : text.toFixed(2)}{" "}
                        {cateItemList?.[focusCate || 0]?.itemList?.[record?.index]?.unit}
                    </div>
                </>
            ),
        },
        {
            dataIndex: "delivery_qty",
            title: `${t("page.purchase_order.conversion_delivery")}`,
            key: "delivery_qty",
            width: "20%",
            render: (text, record, index) => (
                <>
                    <div style={{ fontSize: "14px", textAlign: "left" }}>
                        1 delivery unit = {text}{" "}
                        {cateItemList?.[focusCate || 0]?.itemList?.[record?.index]?.unit}
                    </div>
                </>
            ),
        },
        {
            dataIndex: "delivery_qty",
            title: `${t("page.purchase_order.conversion_restock")}`,
            key: "converse",
            width: "20%",
            render: (text, record, index) => (
                <>
                    <div style={{ fontSize: "14px", textAlign: "left" }}>
                        1 {cateItemList?.[focusCate || 0]?.itemList?.[record?.index]?.unit} = {text}{" "}
                        {cateItemList?.[focusCate || 0]?.itemList?.[record?.index]?.unit}
                    </div>
                </>
            ),
        },
        {
            dataIndex: "add_qty",
            width: "40%",
            key: "qty",
            title: `${t("page.purchase_order.InvQ_row_order")}`,
            align: "right",
            render: (text, record, index) => (
                <div className="quantity-columns">
                    <span
                        onClick={() => {
                            if (!isFinish) changeQty("minus", record?.index);
                        }}
                    >
                        <IconMinusCircle
                            color={`${cateItemList?.[focusCate || 0]?.itemList?.[record?.index]?.[
                                "add_qty"
                            ] === 0 ||
                                cateItemList?.[focusCate || 0]?.itemList?.[record?.index]?.[
                                "add_qty"
                                ] === ""
                                ? "#A5A5A5"
                                : "black"
                                }`}
                        />
                    </span>

                    <Input
                        value={text}
                        onChange={onInputChange2("add_qty", record?.index)}
                        onBlur={inputBlur(record?.index)}
                        disabled={isFinish}
                    />
                    <span
                        onClick={() => {
                            if (!isFinish) changeQty("add", record?.index);
                        }}
                    >
                        <IconAddCircle color="black" />
                    </span>
                    <p>{cateItemList?.[focusCate || 0]?.itemList?.[record?.index]?.unit}&nbsp;</p>
                </div>
            ),
        },
    ];

    const changeQty = (action: string, index) => {
        const newData = [...cateItemList];
        if (action === "minus") {
            if (newData[focusCate || 0]?.itemList[index].add_qty > 0) {
                let minusQty =
                    newData[focusCate || 0]?.itemList[index]?.add_qty -
                    newData[focusCate || 0]?.itemList[index]?.delivery_qty;
                if (minusQty < 0) {
                    minusQty = 0;
                }

                newData[focusCate || 0]["itemList"][index]["add_qty"] = minusQty;
            }
        } else if (action === "add") {
            let addQty =
                newData[focusCate || 0]?.itemList[index]?.add_qty +
                newData[focusCate || 0]?.itemList[index]?.delivery_qty;
            newData[focusCate || 0]["itemList"][index]["add_qty"] = parseInt(addQty);
        }
        setCateItemList(newData);
    };

    const onInputChange2 = (key, index) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const newData = [...cateItemList];
        const re = /^[0-9\b]+$/;

        if (re.test(e.target.value)) {
            newData[focusCate || 0]["itemList"][index]["add_qty"] = Number(e.target.value);
        } else if (e.target.value === "") {
            newData[focusCate || 0]["itemList"][index]["add_qty"] = "";
        }
        setCateItemList(newData);
    };

    const inputBlur = (index) => (e: React.ChangeEvent<HTMLInputElement>) => {
        let newData = [...cateItemList];
        if (Number(e.target.value) % newData[focusCate || 0].itemList[index].delivery_qty !== 0) {
            warning("please check delivery quantity");
            newData[focusCate || 0]["itemList"][index]["add_qty"] = 0;
            setCateItemList(newData);
        }
    };

    const DATE_FORMAT = "YYYY-MM-DD";

    const step1next = async () => {
        const arr: ITPRConsume[] = [];
        cateItemList.forEach((cItem: any) => {
            cItem?.itemList?.forEach((fItem: any) => {
                if (fItem?.add_qty > 0) {
                    arr.push({
                        consumable_id: fItem?.consumable_id,
                        restock_qty: fItem?.add_qty,
                    });
                }
            });
        });
        if (arr.length === 0) {
            warning("Please add quantity to order");
        } else {
            if (id) {
                let payload: IUpdatePR = {
                    organization_id: org_id || "",
                    tpr_id: id,
                    due_date_str: moment(dueDate).format(DATE_FORMAT),
                    tpr_consumable_list: arr,
                    remark: "",
                };
                const res = await POAPI.updatePR(payload);
                if (res.data.error === "0") {
                    success(res.data.msg);
                    history.push(PATH_PURCHASE_REQUEST);
                } else {
                    error(res.data.msg);
                }
            } else {
                let payload: ICreatePR = {
                    organization_id: org_id || "",
                    store_id: focusStore,
                    due_date_str: moment(dueDate).format(DATE_FORMAT),
                    tpr_consumable_list: arr,
                    remark: "",
                    tpr_type: itemType === "product" ? "product" : "inventory",
                };
                const res = await POAPI.createPR(payload);
                if (res.data.error === "0") {
                    success(res.data.msg);
                    history.push(PATH_PURCHASE_REQUEST);
                }
            }
        }

        // const full = consumeList.length;
        // let count = 0;
        // const arr: ITPRConsume[] = [];
        // consumeList.forEach((element: any) => {
        //     arr.push({
        //         consumable_id: element.consumable_id,
        //         restock_qty: element?.add_qty,
        //     });
        //     if (parseInt(element.add_qty) > 0) {
        //         count = count + 1;
        //     }
        // });
        // if (count < full) {
        //     warning("Please add all quantity order");
        // } else {
        //     if (id) {
        //         let payload: IUpdatePR = {
        //             organization_id: org_id || "",
        //             tpr_id: id,
        //             due_date_str: moment(dueDate).format(DATE_FORMAT),
        //             tpr_consumable_list: arr,
        //             remark: "",
        //         };
        //         const res = await POAPI.updatePR(payload);
        //         if (res.data.error === "0") {
        //             success(res.data.msg);
        //             history.push(PATH_PURCHASE_REQUEST);
        //         } else {
        //             error(res.data.msg);
        //         }
        //     } else {
        //         let payload: ICreatePR = {
        //             organization_id: org_id || "",
        //             store_id: focusStore,
        //             due_date_str: moment(dueDate).format(DATE_FORMAT),
        //             tpr_consumable_list: arr,
        //             remark: "",
        //         };
        //         const res = await POAPI.createPR(payload);
        //         if (res.data.error === "0") {
        //             success(res.data.msg);
        //             history.push(PATH_PURCHASE_REQUEST);
        //         }
        //     }
        // }
    };

    const transferStepHandler = () => {
        const arr: IConsumeListPayload[] = [];
        cateItemList.forEach((cItem: any) => {
            cItem?.itemList?.forEach((fItem: any) => {
                if (fItem?.add_qty > 0) {
                    let obj = {
                        consumable_id: fItem?.consumable_id,
                        add_qty: fItem?.add_qty,
                        name: fItem?.name,
                        unit: fItem?.unit,
                    };
                    //@ts-ignore
                    arr.push(obj);
                }
            });
        });
        setConsumeList(arr);
        if (focusStore && arr.length !== 0) {
            if (transferStep === false) {
                setTransferStep(true);
            } else {
                confirmTransfer();
            }
        }
    };

    const handleTransferBranchModal = () => {
        setTransferBranchModal(false);
    };

    const confirmTransfer = async () => {
        try {
            const arr: ICreateTRList[] = [];
            consumeList.forEach((element: IConsumeListPayload) => {
                if (element.request_to_store_id) {
                    arr.push({
                        consumable_id: element.consumable_id,
                        restock_qty: element?.add_qty,
                        request_to_store_id: element?.request_to_store_id,
                    });
                }
            });
            const createTtr = await POAPI.createTtr({
                organization_id: org_id || "",
                store_id: focusStore,
                ttr_consumable_list: arr,
                remark: "",
            });
            if (createTtr.data.error === "0") {
                success(t("message.create.success"));
                history.push(PATH_PURCHASE_REQUEST);
            } else {
                error(createTtr.data.msg);
            }
        } catch (err: any) {
            error(err.message);
        }
    };

    return (
        <div className="form-wrap">
            <form onSubmit={handleSubmit}>
                <div className="page-header">
                    <h3>
                        {pathName === PATH_PURCHASE_ORDER_CREATE
                            ? t("page.purchase_request.create_PR_title")
                            : t("page.purchase_request.edit_PR_title")}
                    </h3>
                </div>
                <div className="page-body">
                    <div className="card-wrap">
                        <CustomStyles>
                            {step === 1 && (
                                <>
                                    <div className="select-branch-section">
                                        <p> {t("page.branch")} :</p>
                                        <Select
                                            showSearch
                                            filterOption={(input, option) =>
                                                //@ts-ignore
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            style={{ width: "100%", height: 48 }}
                                            placeholder={t("page.store")}
                                            onChange={changeFocusStore}
                                            defaultActiveFirstOption={true}
                                            value={focusStore}
                                            disabled={consumeList.length > 0}
                                        >
                                            {adminPermission?.pos_role.store_access_type ===
                                                "all_store" ||
                                                adminPermission?.pos_role.store_access_type ===
                                                undefined
                                                ? Stores?.length !== 0 &&
                                                Stores?.map((store: any, index: number) => {
                                                    return (
                                                        <Option key={index} value={store?._id}>
                                                            {store?.store_name}
                                                        </Option>
                                                    );
                                                })
                                                : adminPermission?.store_ids?.map(
                                                    (store: any, index: number) => {
                                                        return (
                                                            <Option
                                                                key={index}
                                                                value={store?._id}
                                                            >
                                                                {store?.store_name}
                                                            </Option>
                                                        );
                                                    }
                                                )}
                                        </Select>
                                    </div>
                                    <div className="chooseTypeRow">
                                        <div className="choosePRTypeRow">
                                            <Radio
                                                className="prTypeRadio"
                                                onChange={() => setPrType("PR")}
                                                checked={prType === "PR" ? true : false}
                                            >
                                                <div className="prTypeSelection">
                                                    <p>Purchase Request</p>
                                                    <PurchaseRequestIcon />
                                                </div>
                                            </Radio>
                                            <Radio
                                                className="prTypeRadio"
                                                onChange={() => setPrType("TR")}
                                                checked={prType === "TR" ? true : false}
                                            >
                                                <div className="prTypeSelection">
                                                    <p>Transfer request</p>
                                                    <TransferRequestIcon />
                                                </div>
                                            </Radio>
                                        </div>
                                        {prType === "PR" && (
                                            <div className="dueDate">
                                                <label>{t("page.due_date")}:</label>
                                                <DatePicker
                                                    size={"large"}
                                                    format={dateFormatYear}
                                                    defaultValue={moment()}
                                                    value={moment(dueDate)}
                                                    onChange={(value, dateString) => {
                                                        setDueDate(dateString);
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}
                        </CustomStyles>
                        <div className="card-wrap">
                            <div className="step-section">
                                {prType === "TR" ? (
                                    <Row>
                                        <Col
                                            span={12}
                                            className={`col-item start border-right ${step === 1 ? "active" : ""
                                                }`}
                                        >
                                            <Step1Icon color={step === 1 ? "#f4394f" : "#ff7f8e"} />
                                            <div className="step-text ">
                                                <p className="step-number">
                                                    {" "}
                                                    {t("page.purchase_order.step")} 1/2
                                                </p>
                                                <p className="step-title">
                                                    {t("page.setting_order_request_transfer")}
                                                </p>
                                            </div>
                                        </Col>
                                        <Col
                                            span={12}
                                            className={`col-item border-right ${transferStep ? "active" : ""
                                                }`}
                                        >
                                            <Step2Icon
                                                color={transferStep ? "#f4394f" : "#ff7f8e"}
                                            />
                                            <div className="step-text ">
                                                <p className="step-number">
                                                    {" "}
                                                    {t("page.purchase_order.step")} 2/2
                                                </p>
                                                <p className="step-title">
                                                    {t("page.select_store_to_request_transfer")}
                                                </p>
                                            </div>
                                        </Col>
                                    </Row>
                                ) : (
                                    <Row>
                                        <Col
                                            span={6}
                                            className={`col-item start border-right ${step === 1 ? "active" : ""
                                                }`}
                                        >
                                            <Step1Icon color={step === 1 ? "#f4394f" : "#ff7f8e"} />
                                            <div className="step-text ">
                                                <p className="step-number">
                                                    {" "}
                                                    {t("page.purchase_order.step")} 1/4
                                                </p>
                                                <p className="step-title">
                                                    {t("page.purchase_order.step_one_title")}
                                                </p>
                                            </div>
                                        </Col>
                                        <Col
                                            span={6}
                                            className={`col-item border-right ${step === 2 ? "active" : ""
                                                }`}
                                        >
                                            <Step2Icon color={step === 2 ? "#f4394f" : "#ff7f8e"} />
                                            <div className="step-text ">
                                                <p className="step-number">
                                                    {" "}
                                                    {t("page.purchase_order.step")} 2/4
                                                </p>
                                                <p className="step-title">
                                                    {t("page.purchase_order.step_two_title")}
                                                </p>
                                            </div>
                                        </Col>
                                        <Col
                                            span={6}
                                            className={`col-item border-right ${step === 3 ? "active" : ""
                                                }`}
                                        >
                                            <Step3Icon color={step === 3 ? "#f4394f" : "#ff7f8e"} />
                                            <div className="step-text ">
                                                <p className="step-number">
                                                    {" "}
                                                    {t("page.purchase_order.step")} 3/4
                                                </p>
                                                <p className="step-title">
                                                    {t("page.purchase_order.step_three_title")}
                                                </p>
                                            </div>
                                        </Col>
                                        <Col
                                            span={6}
                                            className={`col-item end ${step === 4 ? "active" : ""}`}
                                        >
                                            <Step4Icon color={step === 4 ? "#f4394f" : "#ff7f8e"} />
                                            <div className="step-text">
                                                <p className="step-number">
                                                    {" "}
                                                    {t("page.purchase_order.step")} 4/4
                                                </p>
                                                <p className="step-title">
                                                    {t("page.purchase_order.step_four_title")}
                                                </p>
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                            </div>
                        </div>
                        {step === 1 && focusStore !== "" && transferStep !== true && (
                            <div className="card-wrap">
                                <CustomStyles>
                                    <StyledCard maxWidth="100%">
                                        <div className="title" style={{ fontSize: "25px" }}>
                                            {t("page.purchase_order.inventory_qr_title")}
                                        </div>
                                        {!isFinish && (
                                            <>
                                                <div className="tab-section">
                                                    {/* {id && <Button
                                                        className={`tab-btn ${(-1) === focusCate
                                                            ? "active"
                                                            : ""
                                                            }`}
                                                        onClick={() => {
                                                            changeCate(-1);
                                                            setIsAll(true);
                                                        }}
                                                        key={-1}
                                                    >
                                                        All
                                                    </Button>} */}
                                                    {cateList?.map((cItem: any, cIndex: number) => {
                                                        return (
                                                            <Button
                                                                className={`tab-btn ${cIndex === focusCate
                                                                    ? "active"
                                                                    : ""
                                                                    }`}
                                                                onClick={() => {
                                                                    changeCate(cIndex);
                                                                }}
                                                                key={cIndex}
                                                            >
                                                                {cItem?.category}
                                                            </Button>
                                                        );
                                                    })}
                                                </div>
                                                <div className="add-btn-section">
                                                    <StyledTableButtonAddRow
                                                        type="danger"
                                                        size="default"
                                                        text={t("page.purchase_order.auto_gen_btn")}
                                                        onClick={clickAutoGen}
                                                        className="add-btn auto-gen-btn"
                                                        icon="sync"
                                                    />
                                                    {id &&
                                                        <StyledTableButtonAddRow
                                                            type="danger"
                                                            size="default"
                                                            text={t("page.purchase_order.add_inventory_btn")}
                                                            onClick={() => setShowPopup(true)}
                                                            className="add-btn"
                                                            icon="plus"
                                                        />
                                                    }

                                                </div>
                                            </>
                                        )}
                                        <div className="pr-search">
                                            <p>{t("page.search")} :</p>
                                            {/* <Input onChange={(e) => handleFilter(e, focusCate)} /> */}
                                            <Input
                                                value={textFilter}
                                                onChange={(e) => setTextFilter(e.target.value)}
                                            />
                                        </div>

                                        <Row>
                                            <div className="branchListSection">
                                                <Col md={24}>
                                                    {focusCate &&

                                                        <Table
                                                            rowKey="name"
                                                            columns={!id ? columnsInv : columnsInvPR}
                                                            // dataSource={consumeList}
                                                            dataSource={
                                                                isFinish
                                                                    ? consumeList
                                                                    : renderTableData()
                                                            }
                                                            pagination={false}
                                                            // pagination={{ defaultPageSize: 2 }}
                                                            // bordered
                                                            rowClassName={(record) => {
                                                                if (
                                                                    !record?.name
                                                                        .toLowerCase()
                                                                        .includes(
                                                                            textFilter.toLowerCase()
                                                                        )
                                                                    && !record?.code?.toLowerCase()?.includes(
                                                                        textFilter.toLowerCase()
                                                                    )
                                                                ) {
                                                                    return "hide-row";
                                                                } else {
                                                                    return "";
                                                                }
                                                            }}
                                                        // pagination={{ defaultPageSize: 2 }}
                                                        // bordered
                                                        />
                                                    }
                                                </Col>
                                            </div>
                                        </Row>
                                    </StyledCard>
                                </CustomStyles>
                            </div>
                        )}
                        {transferStep && (
                            <div className="card-wrap">
                                <CustomStyles>
                                    <StyledCard maxWidth="100%">
                                        <div className="title" style={{ fontSize: "25px" }}>
                                            {t("page.select_branch_request_transfer")}
                                        </div>
                                        <div className="selectBranchSection">
                                            {consumeList.map((consumable, i) => (
                                                <React.Fragment key={i}>
                                                    <Row gutter={32}>
                                                        <Col md={10}>
                                                            <div className="title">
                                                                {t("page.inventory")}
                                                            </div>
                                                            <div className="grayBtn">
                                                                <p>{consumable.name}</p>
                                                                <div
                                                                    className="editIcon"
                                                                    onClick={() =>
                                                                        setTransferStep(false)
                                                                    }
                                                                >
                                                                    <EditIcon />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={14}>
                                                            <div className="title">
                                                                {t("page.branch_request_transfer")}
                                                            </div>
                                                            <div
                                                                className={`branchTransferBox ${consumable.store_name
                                                                    ? "active"
                                                                    : ""
                                                                    }`}
                                                            >
                                                                <p>
                                                                    {consumable.store_name
                                                                        ? consumable.store_name
                                                                        : "-"}
                                                                </p>
                                                                <Button
                                                                    style={{
                                                                        height: "auto",
                                                                        border: "0.5px solid #fff",
                                                                        borderRadius: "4px",
                                                                        background:
                                                                            theme?.colors?.main,
                                                                        color: theme?.colors?.white,
                                                                        padding: "10px",
                                                                    }}
                                                                    onClick={() => {
                                                                        setConsumeIndex(i);
                                                                        setTransferBranchModal(
                                                                            true
                                                                        );
                                                                    }}
                                                                >
                                                                    <BranchIcon /> &nbsp;
                                                                    {t("page.request_from_branch")}
                                                                </Button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Divider />
                                                    <PRTransferModal
                                                        showModal={transferBranchModal}
                                                        handleClose={handleTransferBranchModal}
                                                        title={t("page.choose_branch_to_request")}
                                                        consumable={consumable}
                                                        setConsumeList={setConsumeList}
                                                        consumeList={consumeList}
                                                        consumeIndex={consumeIndex}
                                                    />
                                                </React.Fragment>
                                            ))}
                                        </div>
                                    </StyledCard>
                                </CustomStyles>
                            </div>
                        )}
                        <div className="btn-layout">
                            <StyledCancelButton
                                type="sub"
                                text={t("page.back")}
                                htmlType="button"
                                onClick={handleCancel}
                            />
                            <StyledSubmitButton
                                type="default"
                                text={
                                    transferStep
                                        ? t("page.confirm_request_transfer")
                                        : t("page.next")
                                }
                                htmlType="submit"
                                onClick={prType === "PR" ? step1next : transferStepHandler}
                            />
                        </div>
                    </div>
                </div>
            </form>
            <ComponentPOModal
                store_id={focusStore}
                showPopup={showPopup}
                handleClose={() => setShowPopup(!showPopup)}
                addConsume={addConsume}
                cate_id={cateItemList?.[focusCate]?.cate_id}
                type={itemType}
            />
        </div>
    );
});
const CustomStyles = styled.div`
    .ant-table-row {
        font-size: 16px;
    }
    .tab-section {
        margin: 15px 0px;
        .tab-btn {
            background: none;
            border: none;
            font-weight: 700;
            font-size: 16px;
            padding: 0px;
            padding-bottom: 20px;
            margin-right: 30px;
        }
        .tab-btn:hover {
            color: #f4394f;
        }
        .active {
            color: #f4394f;
            border-bottom: 4px solid #f4394f !important;
        }
    }
    .branchListTitle {
        font-size: 16px;
        font-weight: 700;
        color: #000;
    }
    .branchListSection {
        margin-top: 20px;
        td {
            padding: 0px;
            padding-top: 5px;
        }
    }
    .branchListSection .antd-btn-custom {
        margin-left: 0;
    }
    .selectBranchBox {
        position: absolute;
        background: #fff;
        margin-top: 10px;
        z-index: 999;
    }
    .ant-checkbox-wrapper {
        width: 100%;
    }
    .chooseTypeRow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 30px;
        align-items: flex-end;
        .dueDate {
            display: flex;
            align-items: center;
            label {
                width: 90px;
            }
        }
    }
    .choosePRTypeRow {
        display: flex;
        flex-direction: row;
        gap: 16px;
        .prTypeSelection {
            height: 60px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            svg {
                margin: auto auto auto 30px;
            }
        }
        .prTypeRadio {
            border-radius: 8px;
            border: 1px solid #e1e1e1;
            background: #fff;
            display: flex;
            flex-direction: row;
            position: relative;
            padding: 0 16px;
            place-items: center;
            p {
                margin: 0;
            }
            span {
                display: flex;
                flex-direction: row;
                padding-right: 0;
            }
        }
    }
    .selectBranchSection {
        .grayBtn {
            height: 70px;
            display: flex;
            flex-direction: row;
            border-radius: 6px;
            background: #f7f7f8;
            align-items: center;
            padding: 16px 20px;
            justify-content: space-between;
            p {
                color: #000;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin: 0;
            }
        }
        .branchTransferBox.active {
            background: linear-gradient(
                    0deg,
                    rgba(2, 99, 224, 0.08) 0%,
                    rgba(2, 99, 224, 0.08) 100%
                ),
                #fff;
        }
        .branchTransferBox {
            height: 70px;
            border-radius: 6px;
            border: 0.5px solid #a5a5a5;
            background: #fff;
            padding: 16px 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            p {
                color: #000;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin: 0;
            }
        }
    }
    .editIcon {
        cursor: pointer;
    }
`;
