export const VerificationFailedCheckbox = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
        >
            <g clipPath="url(#clip0_18601_49768)">
                <path
                    d="M17.4559 16.958C21.2987 13.1152 21.2987 6.88478 17.4559 3.04196C13.6131 -0.800861 7.38265 -0.800859 3.53983 3.04196C-0.302992 6.88478 -0.302992 13.1152 3.53983 16.958C7.38265 20.8009 13.6131 20.8009 17.4559 16.958Z"
                    fill="#F22F46"
                    fillOpacity="0.16"
                />
                <circle cx="10.5" cy="10" r="5" fill="#F22F46" />
            </g>
            <defs>
                <clipPath id="clip0_18601_49768">
                    <rect width="20" height="20" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
};
