import {
    ICancelPr,
    ICancelTr,
    ICreatePR,
    ICreateTtr,
    IGetAutoGen,
    IGetConsumableStoreId,
    IGetConsumeList,
    IGetGRItem,
    IGetPOGRList,
    IGetPOList,
    IGetPR,
    IGetPoSummary,
    IGetProductWithComsumable,
    IGetSumConsumeByPR,
    IGetTtrs,
    IPostCreatePO,
    IPostDoRG,
    IPostSupByConsumeList,
    IUpdatePR,
} from "@interfaces";
import storeFrontAxiosClient from "./storeFrontAxiosClient";

export const POAPI = {
    getList: (params: IGetPOList) => {
        const url = "store/getStores";
        return storeFrontAxiosClient.get(url, { params });
    },
    getConsumeByStore: (params: IGetConsumableStoreId) => {
        const url = "consumable/getConsumablesStoreId";
        return storeFrontAxiosClient.get(url, { params });
    },
    getProductWithConsumable: (params: IGetProductWithComsumable) => {
        const url = "product/getProductWithComsumable";
        return storeFrontAxiosClient.get(url, { params });
    },
    getSupByConsumeList: (params: IPostSupByConsumeList) => {
        const url = "consumable/getSupplierByConsumablelist";
        return storeFrontAxiosClient.post(url, params);
    },
    getPOGRList: (params: IGetPOGRList) => {
        const url = "popr/getPoprs";
        return storeFrontAxiosClient.get(url, { params });
    },
    getGRItem: (params: IGetGRItem) => {
        const url = "popr/getPopr";
        return storeFrontAxiosClient.get(url, { params });
    },
    postDoGR: (params: IPostDoRG) => {
        const url = "popr/doReceiveGoods";
        return storeFrontAxiosClient.post(url, params);
    },
    getPOSummary: (params: IGetPoSummary) => {
        const url = "popr/getPoprSummary";
        return storeFrontAxiosClient.get(url, { params });
    },
    postCreatePO: (params: IPostCreatePO) => {
        const url = "popr/createPopr";
        return storeFrontAxiosClient.post(url, params);
    },
    getConsumeList: (params: IGetConsumeList) => {
        const url = "consumable/getConsumables";
        return storeFrontAxiosClient.get(url, { params });
    },
    getPRList: (params: IGetPOGRList) => {
        const url = "tpr/getTprs";
        return storeFrontAxiosClient.get(url, { params });
    },
    getPR: (params: IGetPR) => {
        const url = "tpr/getTpr";
        return storeFrontAxiosClient.get(url, { params });
    },
    createPR: (params: ICreatePR) => {
        const url = "tpr/createTpr";
        return storeFrontAxiosClient.post(url, params);
    },
    updatePR: (params: IUpdatePR) => {
        const url = "tpr/updateTpr";
        return storeFrontAxiosClient.patch(url, params);
    },
    cancelPr: (params: ICancelPr) => {
        const url = "tpr/cancelTpr";
        return storeFrontAxiosClient.post(url, params);
    },
    cancelTr: (params: ICancelTr) => {
        const url = "ttr/cancelTtr";
        return storeFrontAxiosClient.post(url, params);
    },
    getSumConsumeByTPR: (params: IGetSumConsumeByPR) => {
        const url = "tpr/getSumConsumablesByTprIdList";
        return storeFrontAxiosClient.post(url, params);
    },
    getAutoGen: (params: IGetAutoGen) => {
        const url = "tpr/getAutoGenerateTprPo";
        return storeFrontAxiosClient.get(url, { params });
    },
    createTtr: (params: ICreateTtr) => {
        const url = "ttr/createTtr";
        return storeFrontAxiosClient.post(url, params);
    },
    getTtrs: (params: IGetTtrs) => {
        const url = "ttr/getTtrs";
        return storeFrontAxiosClient.get(url, { params });
    },
};
