import { useState, useEffect } from "react";
import {
    ComponentContactSortIcon,
    StyledTable,
    ComponentEmptyData,
    IconAllPO,
    IconDone,
    IconCancel,
    ComponentRowGoodReceive,
    ComponentTableGRSearch,
    IconPending,
} from "@components";
import { Row, Col, Pagination, Button } from "antd";
import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";
import {
    enumSortBy,
    enumContactSortFields,
    PAGINATION,
    PAGE_START,
    INITIAL_LIST_PARAMS,
} from "@configs";
import {
    IPaginationNext,
    IDetailSummary,
    IGetAdminDataByTokenAdminDataExample,
    IGetStoresBindingDropdownExample,
} from "@interfaces";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth, selectContact, setLoading } from "@redux";
import { useNotify } from "@utils";
import { POAPI, adminAPI, storeAPI } from "@api";

const INITAL_PAGE = {
    xpage: PAGE_START,
    xlimit: PAGINATION,
};

const initDetailSum: IDetailSummary = {
    all_count: 0,
    canceled_count: 0,
    finished_count: 0,
    pending_count: 0,
};
export const PageGoodReceiveList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const search_params = useSelector(selectContact).paramsContact;
    const [params, setParams] = useState(search_params);
    const userInfo = useSelector(selectAuth).userInfo;
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const org_id = useSelector(selectAuth).userInfo?.merchant?._id;
    const [textSearch, setTextSearch] = useState<string>("");
    const [pagination, setPagination] = useState<IPaginationNext>(INITAL_PAGE);
    const [donePagination, setDonePagination] = useState<IPaginationNext>(INITAL_PAGE);
    const [total, setTotal] = useState<number>(0);
    const [doneTotal, setDoneTotal] = useState<number>(0);
    const { success, error } = useNotify();
    const [Refresh, setRefresh] = useState<any>(0);
    const [tempRefresh, setTempRefresh] = useState(0);
    const [tab, setTab] = useState(0);
    const [gRList, setGRList] = useState([]);
    const [doneGRList, setDoneGRList] = useState([]);
    const [storeList, setStoreList] = useState<IGetStoresBindingDropdownExample[]>([]);
    const [selectStore, setSelectStore] = useState<string>("");
    const [detailSum, setDetailSum] = useState<IDetailSummary>(initDetailSum);
    const { adminPermission } = useSelector(selectAuth);

    useEffect(() => {
        fetchStoreList();
        fetchDetailSum();
    }, []);

    useEffect(() => {
        if (tab === 0) fetchGRList();
        else if (tab === 1) fetchDoneGRList();
    }, [Refresh, textSearch, pagination, selectStore, tab, donePagination]);

    useEffect(() => {
        handleSort;
    }, [tempRefresh]);

    const fetchStoreList = async () => {
        dispatch(setLoading(true));
        try {
            const res = await storeAPI.getStoresBindingDropdown({
                organization_id: org_id,
            });
            if (res.data.error === "0") {
                setStoreList(res.data.result);
            }
        } catch (err: any) {
            error(err.msg);
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };
    const fetchDetailSum = async () => {
        dispatch(setLoading(true));
        try {
            const res = await POAPI.getPOSummary({
                organization_id: userInfo?.merchant?._id || "",
            });
            if (res.data.error === "0") {
                setDetailSum(res.data.result);
            }
        } catch (e) {
            console.error(e);
        } finally {
            dispatch(setLoading(false));
        }
    };
    const fetchDoneGRList = async () => {
        dispatch(setLoading(true));
        try {
            const res = await POAPI.getPOGRList({
                organization_id: userInfo?.merchant?._id || "",
                xpage: donePagination.xpage,
                xlimit: donePagination.xlimit,
                ...(selectStore !== "" && { store_id: selectStore }),
                status: "finished",
                text_search: textSearch,
            });
            if (res.data.error === "0") {
                setDoneGRList(res.data.result);
                setDoneTotal(res.data.total);
            }
        } catch (e) {
            console.error(e);
        } finally {
            dispatch(setLoading(false));
        }
    };

    const fetchGRList = async () => {
        dispatch(setLoading(true));
        try {
            const res = await POAPI.getPOGRList({
                organization_id: userInfo?.merchant?._id || "",
                xpage: pagination.xpage,
                xlimit: pagination.xlimit,
                ...(selectStore !== "" && { store_id: selectStore }),
                status: "pending",
                text_search: textSearch,
            });
            if (res.data.error === "0") {
                setGRList(res.data.result);
                setTotal(res.data.total);
            }
        } catch (e) {
            console.error(e);
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleSort = (sortField: enumContactSortFields, sortBy: enumSortBy) => {
        const newSortParams = { ...params, sortField, sortBy };
        setParams(newSortParams);
        setTempRefresh(2);
    };
    const handleSelectStore = (value) => {
        setSelectStore(value);
    };

    const handlePagination = (page) => {
        setPagination({ ...pagination, xpage: page });
        setDonePagination(INITAL_PAGE);
    };

    const handleDonePagination = (page) => {
        setDonePagination({ ...donePagination, xpage: page });
        setPagination(INITAL_PAGE);
    };
    return (
        <StyledTable>
            <div className="page-layout">
                <div className="page-header-layout">
                    <div className="page-header">
                        <div>
                            <h3>{t("page.good_receive.list_title")}</h3>
                        </div>
                    </div>
                    <div className="detail-box">
                        <Row
                            gutter={12}
                            type="flex"
                            align="middle"
                            className="detail-box-row"
                            justify="space-between"
                        >
                            <Col className="col-item" span={6}>
                                <div className="item-bg">
                                    <div className="icon-section">
                                        <IconAllPO />
                                        <p>{t("page.purchase_order.detail_all_PO_title")}</p>
                                    </div>
                                    <div className="number-section">
                                        <p className="number">{detailSum?.all_count}</p>
                                        <p className="word">
                                            {t("page.purchase_order.detail_sheets")}
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            <Col className="col-item" span={6}>
                                <div className="item-bg">
                                    <div className="icon-section">
                                        <IconPending />
                                        <p>{t("page.purchase_order.detail_pending_title")}</p>
                                    </div>
                                    <div className="number-section">
                                        <p className="number">{detailSum?.pending_count}</p>
                                        <p className="word">
                                            {t("page.purchase_order.detail_sheets")}
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            <Col className="col-item" span={6}>
                                <div className="item-bg">
                                    <div className="icon-section">
                                        <IconCancel />
                                        <p>{t("page.purchase_order.detail_cancel_title")}</p>
                                    </div>
                                    <div className="number-section">
                                        <p className="number">{detailSum?.canceled_count}</p>
                                        <p className="word">
                                            {t("page.purchase_order.detail_sheets")}
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            <Col className="col-item" span={6}>
                                <div className="item-bg">
                                    <div className="icon-section">
                                        <IconDone />
                                        <p>{t("page.purchase_order.detail_done_title")}</p>
                                    </div>
                                    <div className="number-section">
                                        <p className="number">{detailSum?.finished_count}</p>
                                        <p className="word">
                                            {t("page.purchase_order.detail_sheets")}
                                        </p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="search-layout">
                        <ComponentTableGRSearch
                            setTextSearch={setTextSearch}
                            handleSelectStore={handleSelectStore}
                            total={tab === 0 ? total : doneTotal}
                            storeList={
                                adminPermission?.pos_role.store_access_type === "all_store" ||
                                    adminPermission?.pos_role.store_access_type === undefined
                                    ? storeList
                                    : adminPermission?.store_ids
                            }
                        />
                    </div>
                </div>

                <div className="page-table-layout">
                    <div className="tab-section">
                        <Button
                            className={`tab-btn ${tab === 0 ? "active" : ""}`}
                            onClick={() => setTab(0)}
                        >
                            ยังไม่ได้รับสินค้า ({detailSum?.pending_count})
                        </Button>
                        <Button
                            className={`tab-btn ${tab === 1 ? "active" : ""}`}
                            onClick={() => setTab(1)}
                        >
                            รับสินค้าแล้ว ({detailSum?.finished_count})
                        </Button>
                    </div>
                    <div className="table">
                        <ScrollContainer vertical={false}>
                            <div className="table_ground">
                                <div className="table-body">
                                    <div className="table-header">
                                        <Row
                                            gutter={24}
                                            type="flex"
                                            justify="space-between"
                                            align="middle"
                                            className="header-row"
                                        >
                                            <Col className="col-item" span={3}>
                                                <p>{t("page.good_receive.table_create_date")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.FULL_NAME}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.FULL_NAME}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={6}>
                                                <p>
                                                    {t("page.good_receive.table_purchase_request")}
                                                </p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.POINTS}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.POINTS}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={4}>
                                                <p>{t("page.good_receive.table_supplier")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.CREATED_AT}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.CREATED_AT}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>

                                            <Col className="col-item" span={4}>
                                                <p>{t("page.good_receive.table_store")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.CREATED_AT}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.CREATED_AT}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={4}>
                                                <p>{t("page.good_receive.table_status")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.CREATED_AT}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.CREATED_AT}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={3}>
                                                <p>{t("page.manage")}</p>
                                            </Col>
                                        </Row>
                                    </div>
                                    {tab === 0 && (
                                        <>
                                            {gRList && gRList.length > 0 ? (
                                                <div className="data-table">
                                                    {gRList.map((item, index) => (
                                                        <ComponentRowGoodReceive
                                                            key={index}
                                                            data={item}
                                                            itemJson={JSON.stringify(item)}
                                                            index={index}
                                                            storeList={storeList}
                                                        />
                                                    ))}
                                                    <div
                                                        style={{ width: "100%", height: 10 }}
                                                    ></div>
                                                </div>
                                            ) : (
                                                <ComponentEmptyData />
                                            )}
                                        </>
                                    )}
                                    {tab === 1 && (
                                        <>
                                            {doneGRList && doneGRList.length > 0 ? (
                                                <div className="data-table">
                                                    {doneGRList.map((item, index) => (
                                                        <ComponentRowGoodReceive
                                                            key={index}
                                                            data={item}
                                                            itemJson={JSON.stringify(item)}
                                                            index={index}
                                                            storeList={storeList}
                                                        />
                                                    ))}
                                                    <div
                                                        style={{ width: "100%", height: 10 }}
                                                    ></div>
                                                </div>
                                            ) : (
                                                <ComponentEmptyData />
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        </ScrollContainer>
                    </div>
                </div>
                {tab === 0 && (
                    <div className="page-bottom">
                        {total > 0 && (
                            <div className="pagination">
                                <Pagination
                                    defaultCurrent={INITIAL_LIST_PARAMS.page || 1}
                                    pageSize={PAGINATION}
                                    total={total}
                                    onChange={handlePagination}
                                    current={pagination.xpage}
                                />
                            </div>
                        )}
                    </div>
                )}
                {tab === 1 && (
                    <div className="page-bottom">
                        {total > 0 && (
                            <div className="pagination">
                                <Pagination
                                    defaultCurrent={INITIAL_LIST_PARAMS.page || 1}
                                    pageSize={PAGINATION}
                                    total={doneTotal}
                                    onChange={handleDonePagination}
                                    current={donePagination.xpage}
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
        </StyledTable>
    );
};
