import { enumDrawerPlacement } from "@configs";
import { useTranslation } from "react-i18next";
import {
    SharedInput,
    StyledSubmitButton,
    StyledCancelButton,
    SharedComponentDrawer,
} from "@components";
import { useNotify } from "@utils";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth } from "@redux";
import { consumableAPI, orgApi } from "@api";
import { Select } from "antd";
import styled from "styled-components";
import { IGetConsumableCategorysExample, IItemGroupExample } from "@interfaces";

interface IProps {
    showModal: boolean;
    handleClose: () => void;
    editItem?: IGetConsumableCategorysExample;
}

export const ComponentInvCateDrawer = (props: IProps) => {
    const history = useHistory();
    const { showModal, handleClose, editItem } = props;
    const { t } = useTranslation();
    const { success, error } = useNotify();
    const [CatName, setCatName] = useState<string>("");
    const [CatCode, setCatCode] = useState<string>("");
    const [itemGroups, setItemGroups] = useState<IItemGroupExample[]>([]);
    const [itemGroupValue, setItemGroupValue] = useState<IItemGroupExample>({
        item_group: "",
        item_series: "",
    });
    const dispatch = useDispatch();
    const { Option } = Select;

    const { userInfo } = useSelector(selectAuth);

    useEffect(() => {
        if (editItem) {
            setCatName(editItem.category);
            setCatCode(editItem.code);
            setItemGroupValue({
                item_group: editItem.item_group,
                item_series: editItem.item_series,
            });
        } else {
            setCatName("");
            setCatCode("");
            setItemGroupValue({ item_group: "", item_series: "" });
        }
        getItemGroup();
    }, [editItem]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCatName(e.target.value);
    };
    const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCatCode(e.target.value);
    };
    const handleSave = async () => {
        try {
            if (editItem) {
                const updateCat = await orgApi.updateInvCate({
                    organization_id: userInfo?.merchant?._id || "",
                    consumable_category_id: editItem._id,
                    category: CatName,
                    code: CatCode,
                    item_series: itemGroupValue.item_series,
                    item_group: itemGroupValue.item_group,
                });
                if (updateCat.data.error === "0") {
                    handleClose();
                    success(t("message.update.success"));
                    setCatName("");
                    setItemGroupValue({ item_group: "", item_series: "" });
                } else {
                    error(updateCat.data.msg);
                }
            } else {
                const res = await orgApi.createInvCate({
                    organization_id: userInfo?.merchant?._id || "",
                    category: CatName,
                    code: CatCode,
                    item_series: itemGroupValue.item_series,
                    item_group: itemGroupValue.item_group,
                });
                if (res.data.error === "0") {
                    handleClose();
                    success(t("message.create.success"));
                    setCatName("");
                    setItemGroupValue({ item_group: "", item_series: "" });
                } else {
                    error(res.data.msg);
                }
            }
        } catch (err) {
            console.log(err);
        }
    };

    const getItemGroup = async () => {
        try {
            const get = await consumableAPI.getItemGroupConsumableCategory();
            if (get.data.error === "0") {
                setItemGroups(get.data.result);
            } else {
                error(get.data.msg);
            }
        } catch (err: any) {
            error(err.message);
        }
    };

    const handleSelect = (value, key) => {
        setItemGroupValue({ item_group: value, item_series: key.key });
    };

    return (
        <SharedComponentDrawer
            title={t("page.inv_cate.add")}
            placement={enumDrawerPlacement.RIGHT}
            onClose={handleClose}
            visible={showModal}
            width={538}
        >
            <StyledInvCateDrawer>
                <SharedInput
                    label={t("page.inv_cate.name")}
                    onChange={handleChange}
                    value={CatName}
                    parentClassName="mb-6"
                />
                <SharedInput
                    label={t("page.inv_cate.code")}
                    onChange={handleCodeChange}
                    value={CatCode}
                    parentClassName="mb-6"
                />
                <div className="itemGroup">
                    <label className="label">
                        <p className="label-name">{t("page.item_group")}</p>
                    </label>
                    <Select
                        showSearch
                        filterOption={(input, option) =>
                            //@ts-ignore
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={handleSelect}
                        placeholder={t("page.please_choose")}
                        value={itemGroupValue.item_group}
                    >
                        {itemGroups.map((item, i) => (
                            <Option value={item.item_group} key={item.item_series}>
                                {item.item_group} ({item.item_series})
                            </Option>
                        ))}
                    </Select>
                </div>
                <div className="btn-layout">
                    <StyledSubmitButton
                        type="default"
                        text={t("page.save")}
                        htmlType="button"
                        onClick={handleSave}
                    />
                    <StyledCancelButton
                        type="sub"
                        text={t("page.cancel")}
                        htmlType="button"
                        onClick={handleClose}
                    />
                </div>
            </StyledInvCateDrawer>
        </SharedComponentDrawer>
    );
};

const StyledInvCateDrawer = styled.div`
    .itemGroup {
        display: flex;
        flex-direction: column;
        gap: 8px;
        .label-name {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 21px;
            color: #000000;
            display: inline-block;
            margin: 0;
        }
    }
`;
