export const VerificationCheckedPendingIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
        >
            <g clipPath="url(#clip0_18601_49107)">
                <path
                    d="M16.9557 16.958C20.7985 13.1152 20.7985 6.88478 16.9557 3.04196C13.1128 -0.800861 6.8824 -0.800859 3.03958 3.04196C-0.803237 6.88478 -0.803237 13.1152 3.03958 16.958C6.8824 20.8009 13.1128 20.8009 16.9557 16.958Z"
                    fill="#6C7084"
                    fillOpacity="0.16"
                />
                <circle cx="10" cy="10" r="5" fill="#6C7084" />
            </g>
            <defs>
                <clipPath id="clip0_18601_49107">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
