import { Button, Col, Row } from "antd";
import { useEffect, useRef } from "react";
import styled from "styled-components";
import jsPDF from "jspdf";
import { useTranslation } from "react-i18next";

interface IProps {
    data: any;
    type: string;
    storeDetail: any;
}

export const ComponentPOPaper = (props: IProps) => {
    const { data, type, storeDetail } = props;
    const paperRef = useRef(null);
    const { t } = useTranslation();

    const roundUp = () => {
        const roundedNumber = (Math.ceil(data?.po_vat * 100) / 100).toFixed(2);
        return roundedNumber;
    };

    const doc = new jsPDF({
        orientation: "landscape",
        unit: "in",
        format: [4, 2],
    });
    const loadPDF = () => {
        const doc = new jsPDF({
            format: "a4",
            unit: "px",
        });

        // @ts-ignore
        doc.html(paperRef.current, {
            async callback(doc) {
                await doc.save("PO_Paper");
            },
        });
    };

    const paperStyle = {
        po_paper: {
            backgroundColor: "white",
            border: "1px solid #a5a5a5",
            width: "800px",
            marginTop: "30px",
        },
        body_detail: {
            padding: "15px",
        },
        header: {
            "text-align": "center",
            backgroundColor: "#fbaf5d",
        },
    };

    const printdiv = () => {
        const element_id = "container" + data?.po_no;
        const divToPrint = document.getElementById(element_id);
        if (divToPrint) {
            const html =
                "<html>" + //
                "<head>" +
                "<title>PO Paper</title>" +
                '<link href="/paper.scss" rel="stylesheet" type="text/css">' +
                "</head>" +
                // '<body onload="window.print(); window.close();">' +
                '<body onload="window.print();">' +
                divToPrint?.innerHTML +
                "</body>" +
                "</html>";
            const popupWin = window.open();
            if (popupWin) {
                popupWin.document.open();
                popupWin.document.write(html);
                popupWin.document.close();
            }
        }
    };

    const MONTHlIST = [
        t("month.jan"),
        t("month.feb"),
        t("month.mar"),
        t("month.apr"),
        t("month.may"),
        t("month.jun"),
        t("month.jul"),
        t("month.aug"),
        t("month.sep"),
        t("month.oct"),
        t("month.nov"),
        t("month.dec"),
    ];

    function formatDate(date) {
        let d = new Date(date),
            month = "" + d.getMonth(),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        return [day, MONTHlIST[parseInt(month)], year + 543].join(" ");
    }
    return (
        <StyledPOPaperLayout>
            <div
                // className="po-paper"
                ref={paperRef}
                id={`container${data?.po_no}`}
            >
                <div className="po-paper">
                    <div className="header">
                        <span>{type === "PURCHASE ORDER" && t("page.print_paper.title_po")}</span>
                    </div>
                    <div className="body-detail">
                        <div className="section1">
                            <Row>
                                <Col span={18}>
                                    <div className="logo-name">
                                        <div>
                                            <p className="store-name">
                                                {storeDetail?.organization_name} -{" "}
                                                {storeDetail?.store_name}
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                                <Col span={6}>
                                    <p className="po-no">{data?.po_no}</p>
                                </Col>
                            </Row>
                        </div>
                        <div className="section2">
                            <Row>
                                <Col span={16}>
                                    <div className="ship-to">
                                        <p className="sec2-header">{t("page.print_paper.buyer")}</p>
                                        <p>
                                            {storeDetail?.organization_name} -{" "}
                                            {storeDetail?.store_name}
                                        </p>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div className="detail">
                                        <p className="sec2-header">
                                            {t("page.print_paper.detail")}
                                        </p>
                                        <Row>
                                            <Col span={12}>{t("page.print_paper.issued_date")}</Col>
                                            <Col span={12} className="desc">
                                                {formatDate(data?.created_date)}
                                            </Col>
                                            <Col span={12}>
                                                {t("page.print_paper.credit_terms")}{" "}
                                            </Col>
                                            <Col span={12} className="desc">
                                                -
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="section3">
                            <p className="tax-header">
                                {t("page.print_paper.tax_id")} {data?.tax_id || "-"}
                            </p>
                            <p className="contact">
                                {" "}
                                {t("page.print_paper.contact")} : {storeDetail?.supplier_name}
                            </p>
                            <p>
                                {t("page.print_paper.tel")}
                                { }
                            </p>
                        </div>
                        <div className="section4">
                            <Row className="table-header">
                                <Col span={1}>{t("page.print_paper.table_no")}</Col>
                                <Col span={3}>{t("page.print_paper.table_code")}</Col>
                                <Col span={8}>{t("page.print_paper.table_ing")}</Col>
                                <Col span={1}>{t("page.print_paper.table_qty")}</Col>
                                <Col span={2}>{t("page.print_paper.table_unit")}</Col>
                                <Col span={3}>{t("page.print_paper.table_vat")}</Col>
                                <Col span={3}>{t("page.print_paper.table_price")}</Col>
                                <Col span={3}>{t("page.print_paper.table_total")}</Col>
                            </Row>
                            {data?.po_consumable_list?.length > 0 &&
                                data?.po_consumable_list?.map((item: any, index: number) => {
                                    return (
                                        <Row className="table-body">
                                            <Col span={1}>{index + 1}</Col>
                                            <Col span={3}>{item?.consumable_code}</Col>
                                            <Col span={8} className="name">
                                                {item?.consumable_name}
                                            </Col>
                                            <Col span={1}>{item?.restock_qty}</Col>
                                            <Col span={2}>{item?.unit || "-"}</Col>
                                            <Col span={3}>{item?.vat || "N"}</Col>
                                            <Col span={3}>{item?.unit_price || 0}</Col>
                                            <Col span={3} className="last">
                                                {item?.total_price || 0}
                                            </Col>
                                        </Row>
                                    );
                                })}
                            {data?.po_consumable_list?.length < 15 &&
                                Array(15 - data?.po_consumable_list?.length)
                                    .fill(null)
                                    .map((_, index) => (
                                        <Row className="table-body">
                                            <Col span={1} />
                                            <Col span={3} />
                                            <Col span={8} className="name" />
                                            <Col span={1} />
                                            <Col span={2} />
                                            <Col span={3} />
                                            <Col span={3} />
                                            <Col span={3} className="last" />
                                        </Row>
                                    ))}
                        </div>
                        <div className="section5">
                            <Row>
                                <Col span={12}>
                                    <div className="sec5-header">
                                        {t("page.print_paper.remark")}
                                    </div>
                                    <div className="remark">{data?.remark}</div>
                                </Col>
                                <Col span={6} />
                                <Col span={6} className="sum-total">
                                    <Row>
                                        <Col span={12}>{t("page.print_paper.total")}</Col>
                                        <Col span={12}>{data?.po_grand_total}</Col>
                                        <Col span={12}>
                                            {t("page.print_paper.vat")} ({data?.vat_percent}%)
                                        </Col>
                                        {/* <Col span={12}>{data?.po_vat}</Col> */}
                                        <Col span={12}>{roundUp()}</Col>
                                    </Row>
                                    <div className="full-line" />
                                    <Row>
                                        <Col span={12} className="grand">
                                            {t("page.print_paper.grand_total")}
                                        </Col>
                                        <Col span={12} className="grand">
                                            {data?.po_grand_total}
                                        </Col>
                                    </Row>
                                    <div className="word">({data?.po_grand_total_word_th})</div>
                                </Col>
                            </Row>
                        </div>
                        <div className="section6">
                            <Row>
                                <Col span={8}>
                                    <div className="sign-line" />
                                    <p>{t("page.print_paper.autorized")}</p>
                                </Col>
                                <Col span={8}>
                                    <div className="sign-line" />
                                    <p>{t("page.print_paper.cancelled")}</p>
                                </Col>
                                <Col span={8}>
                                    <div className="sign-line" />
                                    <p>
                                        {t("page.print_paper.issued")}{" "}
                                        {storeDetail?.organization_name}
                                    </p>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <Button onClick={printdiv}>{t("page.purchase_order.load_pdf")}</Button>
            <br />
        </StyledPOPaperLayout>
    );
};

const StyledPOPaperLayout = styled.div`
    .po-paper {
        background-color: white;
        border: 1px solid #a5a5a5;
        width: 800px;
        margin-top: 30px;
    }
    .body-detail {
        padding: 15px;
    }
    .header {
        text-align: center;
        // background-color: #fbaf5d;
        padding: 5px;
        color: white;
        position: relative;
    }
    .header span {
        position: relative;
        z-index: 1;
        top: 10px;
        font-size: 15px;
    }
    .header:after {
        content: "";
        width: 100%;
        height: 10px;
        background-color: #fbaf5d;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
    }
    .header:before {
        content: "";
        width: 200px;
        height: 50px;
        background-color: #fbaf5d;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 0;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
    }
    .section1 {
        margin-top: 15px;
        .store-name {
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 0px;
        }
        .po-no {
            font-size: 16px;
            font-weight: 600;
        }
    }
    .section2 {
        margin-top: 30px;
        .sec2-header {
            border: 1px solid #a6a6a6;
            color: #a6a6a6;
            width: fit-content;
            padding: 5px;
            font-size: 14px;
        }
        .detail {
            .desc {
                text-align: right;
            }
        }
    }
    .section3 {
        margin-top: 15px;
        .contact {
            margin: 0;
        }
    }
    .section4 {
        text-align: center;

        border: 1px solid #a6a6a6;
        border-right: none;
        border-collapse: collapse;
        .table-header {
            .ant-col {
                border-right: 1px solid #a6a6a6;
                padding: 10px 0px;
                font-size: 12px;
                border-collapse: collapse;
            }
            border-bottom: 1px solid #a6a6a6;
        }

        .table-body {
            .ant-col {
                border-right: 1px solid #a6a6a6;
                padding: 10px 0px;
                font-size: 12px;
                border-collapse: collapse;
            }
        }
    }
    .section5 {
        margin-top: 20px;
        .sec5-header {
            font-size: 16px;
            font-weight: 700;
        }
        .remark {
            border: 1px solid #a6a6a6;
            padding: 15px;
            margin-top: 10px;
            height: 110px;
            overflow-wrap: break-word;
        }

        .sum-total {
            text-align: right;
            .full-line {
                border-bottom: 1px solid #a6a6a6;
                margin: 15px 0px;
            }
            .grand {
                font-weight: 700;
            }
            .word {
                font-size: 12px;
                color: gray;
            }
        }
    }
    .section6 {
        text-align: center;
        margin-top: 50px;
        .sign-line {
            border-bottom: 1px solid #a6a6a6;
            width: 50%;
            margin-left: auto;
            margin-right: auto;
        }
    }
`;
