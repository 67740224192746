export const ArrowRightLine = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="83"
            height="9"
            viewBox="0 0 83 9"
            fill="none"
        >
            <path
                d="M0.669873 4.5L5 8.83013L9.33013 4.5L5 0.169873L0.669873 4.5ZM83 4.5L75.5 0.169873V8.83013L83 4.5ZM5 5.25H5.975V3.75H5V5.25ZM7.925 5.25H9.875V3.75H7.925V5.25ZM11.825 5.25H13.775V3.75H11.825V5.25ZM15.725 5.25H17.675V3.75H15.725V5.25ZM19.625 5.25H21.575V3.75H19.625V5.25ZM23.525 5.25H25.475V3.75H23.525V5.25ZM27.425 5.25H29.375V3.75H27.425V5.25ZM31.325 5.25H33.275V3.75H31.325V5.25ZM35.225 5.25H37.175V3.75H35.225V5.25ZM39.125 5.25H41.075V3.75H39.125V5.25ZM43.025 5.25H44.975V3.75H43.025V5.25ZM46.925 5.25H48.875V3.75H46.925V5.25ZM50.825 5.25H52.775V3.75H50.825V5.25ZM54.725 5.25H56.675V3.75H54.725V5.25ZM58.625 5.25H60.575V3.75H58.625V5.25ZM62.525 5.25H64.475V3.75H62.525V5.25ZM66.425 5.25H68.375V3.75H66.425V5.25ZM70.325 5.25H72.275V3.75H70.325V5.25ZM74.225 5.25H76.175V3.75H74.225V5.25ZM78.125 5.25H80.075V3.75H78.125V5.25Z"
                fill="#66A9FF"
            />
        </svg>
    );
};
