import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Switch, Col, Row, Avatar, Menu, Dropdown, Button } from "antd";
import { MoreHorizontal } from "react-feather";
import moment from "moment";

import { INews, INewsCategory } from "@interfaces";
import { newsAPI } from "@api";
import { dateFormat, dateFormatHM, PATH_CREATE_NEWS, enumNewsType } from "@configs";
import { selectAuth } from "@redux";
import { useSelector } from "react-redux";
import { IconDrag } from "@components";
import { showErrorMessage, useNotify } from "@utils";
import { useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
interface IProps {
    data: INews;
    itemJson: string;
    handleEdit: (status: boolean, data: INews) => void;
    callbackGetList: () => void;
    index: number;
    newType?: enumNewsType;
    categories: INewsCategory[];
}

export const ComponentRowNews = (props: IProps) => {
    //hooks
    const { t } = useTranslation();
    const { success, error } = useNotify();
    const organization_id = useSelector(selectAuth).auth?.organization_data?._id;
    //props
    const { data, handleEdit, callbackGetList, itemJson, newType } = props;
    const [show, setShowModal] = useState(false);

    const { attributes, listeners, setNodeRef, transform } = useSortable({
        id: itemJson,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
    };

    const catName = () => {
        const item = props.categories.filter((item) => item._id === props.data.news_category_id);
        if (item.length > 0) {
            return item[0].category_name;
        }
        return "...";
    };

    const handleDelete = async () => {
        try {
            await newsAPI.delete({ organization_id: organization_id || "", news_id: data._id });
            callbackGetList();
            setShowModal(false);
            success(t("message.delete.success"));
        } catch (err: any) {
            if (err) {
                error(err.msg);
            } else error(t("message.delete.fail"));
        }
    };

    const handleRemove = async () => {
        try {
            const payload = {
                organization_id: data.organization_id,
                title: data.title,
                detail: data.detail,
                image_url: [data.image_url],
                is_hot_news: false,
                news_category_id: data.news_category_id,
                date_from: data.date_from,
                date_to: data.date_to,
                is_enable: data.is_enable,
                news_id: data._id,
                discount: data.discount,
                product_code: data.product_code,
                price: data.price,
                discount_type: data.discount_type,
                has_barcode: data.has_barcode,
                active_on_platform: data.active_on_platform,
                point_deduction: data.point_deduction,
                maximum_deduction: data.maximum_deduction,
                point_deduction_value: data.point_deduction_value,
                variants: data.variants,
            };
            await newsAPI.update(payload);
            callbackGetList();
            success(t("message.duplicate.success"));
        } catch (err: any) {
            if (err.response) {
                error(showErrorMessage(err.response));
            } else error(t("message.duplicate.fail"));
        }
    };

    const showModal = () => {
        setShowModal(!show);
        // Modal.confirm({
        //     title: t("message.delete_title"),
        //     icon: <ExclamationCircleOutlined />,
        //     content: t("message.delete_confirm", {
        //         returnObjects: true,
        //         name: t("object._contact"),
        //     }),
        //     okText: t("page.delete"),
        //     cancelText: t("page.cancel"),
        //     centered: true,
        //     onOk: handleDelete,
        // });
    };

    const menu = (
        <Menu>
            {newType && newType === enumNewsType.HOT_NEWS && (
                <Menu.Item key={2} onClick={handleRemove}>
                    <div className="delete-btn">{t("page.remove")}</div>
                </Menu.Item>
            )}
            <Menu.Divider />
            <Menu.Item key={1} onClick={handleDelete}>
                <div className="duplicate-btn">{t("page.delete")}</div>
            </Menu.Item>
        </Menu>
    );
    const handleUpdateStatus = (e: boolean) => {
        handleEdit(e, data);
    };
    return (
        <div className="table-row" ref={setNodeRef} style={style}>
            <div className="drag-icon" {...attributes} {...listeners}>
                <IconDrag />
            </div>
            <Row
                style={{ height: "100%" }}
                gutter={24}
                type="flex"
                align="middle"
                justify="space-between"
            >
                <Col className="col-item" lg={5} md={5} xs={14} sm={14}>
                    <Avatar
                        shape="square"
                        src={data?.image_url}
                        size={84}
                        style={{ minWidth: 84 }}
                    />
                    <Link to={`${PATH_CREATE_NEWS}/${data._id}`}>
                        <p className="news-title">{data.title || t("page.empty_text")}</p>
                    </Link>
                </Col>
                <Col className="col-item" span={3}>
                    <p>{catName() || t("page.empty_text")}</p>
                </Col>
                <Col className="col-item m-member-code" xs={10} sm={10} lg={3} md={3}>
                    <p className="visible-md">
                        {moment(data.created_date).format(dateFormat) || t("page.empty_text")}
                    </p>
                </Col>
                <Col className="col-item visible-md" span={3}>
                    <p>
                        {moment(data.date_from).format(dateFormatHM)}
                        <br />
                        {moment(data.date_to).format(dateFormatHM)}
                    </p>
                </Col>
                <Col className="col-item visible-md" span={3}>
                    <Switch onChange={handleUpdateStatus} checked={data.is_enable} />
                </Col>
                <Col className="col-item" span={3}>
                    <Dropdown
                        //@ts-ignore
                        getPopupContainer={(trigger) => trigger.parentNode}
                        overlay={menu}
                        trigger={["click"]}
                        placement="bottomRight"
                    >
                        <Button style={{ border: "unset" }}>
                            <MoreHorizontal />
                        </Button>
                    </Dropdown>
                </Col>
            </Row>
        </div>
    );
};
