import { ISvg } from "@interfaces";

export const IconImportTable = (props: ISvg) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.size || "20"}
            height={props.size || "19"}
            viewBox="0 0 20 19"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.001 13.5411C9.58698 13.5411 9.25098 13.2051 9.25098 12.7911V0.750122C9.25098 0.336122 9.58698 0.00012207 10.001 0.00012207C10.415 0.00012207 10.751 0.336122 10.751 0.750122V12.7911C10.751 13.2051 10.415 13.5411 10.001 13.5411Z"
                fill={props.color || "#F22F46"}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.0008 13.5411C9.80183 13.5411 9.60983 13.4621 9.46983 13.3201L6.55383 10.3931C6.26183 10.0991 6.26283 9.62409 6.55583 9.33209C6.84983 9.04009 7.32383 9.04009 7.61583 9.33409L10.0008 11.7291L12.3858 9.33409C12.6778 9.04009 13.1518 9.04009 13.4458 9.33209C13.7388 9.62409 13.7398 10.0991 13.4478 10.3931L10.5318 13.3201C10.3918 13.4621 10.1998 13.5411 10.0008 13.5411Z"
                fill={props.color || "#F22F46"}
            />
            <mask id="mask0_18314_76" maskUnits="userSpaceOnUse" x="0" y="4" width="20" height="15">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 4.7326H19.9998V18.4766H0V4.7326Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_18314_76)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.575 18.4766H4.435C1.99 18.4766 0 16.4876 0 14.0416V9.1566C0 6.7166 1.985 4.7326 4.426 4.7326H5.367C5.781 4.7326 6.117 5.0686 6.117 5.4826C6.117 5.89661 5.781 6.2326 5.367 6.2326H4.426C2.812 6.2326 1.5 7.5436 1.5 9.1566V14.0416C1.5 15.6606 2.816 16.9766 4.435 16.9766H15.575C17.187 16.9766 18.5 15.6636 18.5 14.0516V9.1676C18.5 7.5486 17.183 6.2326 15.566 6.2326H14.634C14.22 6.2326 13.884 5.89661 13.884 5.4826C13.884 5.0686 14.22 4.7326 14.634 4.7326H15.566C18.011 4.7326 20 6.7226 20 9.1676V14.0516C20 16.4916 18.014 18.4766 15.575 18.4766Z"
                    fill={props.color || "#F22F46"}
                />
            </g>
        </svg>
    );
};
