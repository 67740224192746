import styled from "styled-components";
const { compose, withProps, lifecycle } = require("recompose");
const { withScriptjs } = require("react-google-maps");
const {
    StandaloneSearchBox,
} = require("react-google-maps/lib/components/places/StandaloneSearchBox");

export const ComponentSearchInput = compose(
    withProps({
        googleMapURL: `https://maps.googleapis.com/maps/api/js?key=AIzaSyAVf2Ys7ZTPiSl54uaX8cC4v698oL0qoNY&v=3.exp&libraries=geometry,drawing,places`,
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `400px` }} />,
    }),
    lifecycle({
        componentWillMount() {
            const refs = {};

            this.setState({
                places: [],
                onSearchBoxMounted: (ref) => {
                    // @ts-ignore
                    refs.searchBox = ref;
                },
                onPlacesChanged: () => {
                    // @ts-ignore
                    const places = refs.searchBox.getPlaces();
                    // console.log(places);
                    this.props.onChangeLocation(
                        places[0].geometry.location.lat(),
                        places[0].geometry.location.lng(),
                        places[0].formatted_address
                    );
                    this.setState({
                        places,
                    });
                },
            });
        },
    }),
    withScriptjs
)((props) => (
    <div data-standalone-searchbox="">
        <StandaloneSearchBox
            ref={props.onSearchBoxMounted}
            bounds={props.bounds}
            onPlacesChanged={props.onPlacesChanged}
        >
            <StyledInput type="text" placeholder="Search a place" />
        </StandaloneSearchBox>
        {/* <StyledOrderList>
            {props.places.map(({ place_id, formatted_address, geometry: { location } }) => (
                <li
                    key={place_id}
                    onClick={() => props.onChangeLocation(location.lat(), location.lng())}
                >
                    {formatted_address}
                </li>
            ))}
        </StyledOrderList> */}
    </div>
));
const StyledOrderList = styled.ol`
    padding: 16px 0;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
`;
const StyledInput = styled.input`
    box-sizing: border-box;
    height: 44px;
    width: 100%;
    border-radius: 3px;
    font-size: 14px;
    outline: none;
    text-overflow: ellipses;
    padding: 10px 20px;
    background: #ffffff;
    border: 1px solid #f3f3f3;
    border-radius: 4px;
    margin-top: 20px;
`;
