import { PATH_CHECK_STOCK, PATH_WASTE_RECORD } from "@configs";
import { IConplaintCategory, IDivision, INewsCategory } from "@interfaces";
import { Row, Col, Input, Select, DatePicker, Pagination } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { IUpdateStore } from "src/Interfaces/store";
import styled from "styled-components";
import { StyledTableButtonTop } from "./styles";
const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

interface Iprops {
    setTextSearch: (value: string) => void;
    handleSearchCategory?: (value: string) => void;
    categories: INewsCategory[] | IConplaintCategory[];
    onChangeDateRange?: (dates, dateStrings: [string, string]) => void;
    onChangeDate?: (dates, dateStrings: string) => void;
    total: number;
    divisions?: IDivision[];
    isRangePicker: boolean;
    storeList?: any[];
}
export const ComponentTableWasteRecordStart = (props: Iprops) => {
    const {
        setTextSearch,
        handleSearchCategory,
        categories,
        isRangePicker,
        onChangeDateRange,
        onChangeDate,
        total,
        divisions,
        storeList,
    } = props;
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <StyledSearchLayout>
            <div className="filter">
                <Row
                    gutter={24}
                    type="flex"
                    justify="space-between"
                    align="middle"
                    className="search-row"
                >
                    <Col className="col-item" span={20}>
                        <div className="filter-section">
                            <div className="filter-element">
                                <p>{t("page.branch")}:</p>
                                <Select
                                    showSearch
                                    filterOption={(input, option) =>
                                        //@ts-ignore
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange={handleSearchCategory}
                                    placeholder={t("page.please_choose")}
                                >
                                    {storeList &&
                                        storeList.map((item, index) => (
                                            <Option value={item._id} key={index}>
                                                {item.store_name}
                                            </Option>
                                        ))}
                                </Select>
                            </div>
                            <div className="filter-element">
                                <p>{t("page.check_store_id")}</p>
                                <Search
                                    placeholder={t("page.search_stores")}
                                    onSearch={(value) => setTextSearch(value)}
                                    style={{ width: 200 }}
                                    onChange={(event) => {
                                        setTextSearch(event.target.value);
                                    }}
                                />
                            </div>
                        </div>
                    </Col>

                    <Col className="col-item" span={4}>
                        <div className="filter-element result">
                            <StyledTableButtonTop
                                type="danger"
                                size="default"
                                text={t("page.start")}
                                onClick={() => history.push(PATH_WASTE_RECORD + "/publish")}
                                className="add-btn"
                                icon={"caret-right"}
                                style={{
                                    background: "#F22F46",
                                    borderRadius: "4px",
                                    width: "100%",
                                }}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
        </StyledSearchLayout>
    );
};
const StyledSearchLayout = styled.div`
    @media (min-width: 992px) {
        .ant-select-selection {
            width: 160px;
        }
    }
    .filter-section {
        display: flex;
        grid-gap: 25px;
    }
    .filter {
        background: #fff;
        padding: 20px;
        border-radius: 4px;
        margin-bottom: 26px;

        .filter-element {
            display: flex;
            align-items: center;
            column-gap: 8px;
            p {
                margin: 0;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #646464;
            }
            .ant-input-search {
                height: 44px;
                width: 100%;
            }
            .ant-select {
                /* min-width: 160px; */
                min-width: 200px;
                width: 100%;
                .ant-select-selection--single {
                    height: 44px;
                    .ant-select-selection__rendered {
                        height: 100%;
                        align-items: center;
                        display: flex;
                        min-width: 200px;
                    }
                }
                .ant-select-selection {
                    min-width: 200px;
                }
            }
            .ant-calendar-picker-input {
                height: 44px;
            }
            &.result {
                display: flex;
                justify-content: end;
                p {
                    display: flex;
                    column-gap: 10px;
                }
            }
        }
    }
`;
