import { useState, useEffect } from "react";
import {
    ComponentContactSortIcon,
    StyledTable,
    ComponentEmptyData,
    ComponentRowCheckStockStart,
    ComponentTableWasteRecordStart,
} from "@components";
import { Row, Col, Pagination, Select, DatePicker } from "antd";
import { useTranslation } from "react-i18next";
import {
    enumSortBy,
    enumContactSortFields,
    PAGINATION,
    PAGE_START,
    INITIAL_LIST_PARAMS,
} from "@configs";
import {
    IPaginationNext,
    IGetCheckStockDocsExample,
    IGetVatDetailExample,
    IGetAdminDataByTokenAdminDataExample,
} from "@interfaces";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth, selectContact, selectOrg, setLoading } from "@redux";
import { useNotify } from "@utils";
import styled from "styled-components";
import { adminAPI, checkStockDocAPI, orgApi, storeAPI } from "@api";
import { consumableAPI } from "src/API/consumable";
import { IGetStoresBindingDropdownExample } from "src/Interfaces/store";
import moment from "moment";

const INITAL_PAGE = {
    xpage: PAGE_START,
    xlimit: PAGINATION,
};

export const PageWasteRecord = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const search_params = useSelector(selectContact).paramsContact;
    const [params, setParams] = useState(search_params);
    const org_id = useSelector(selectAuth).userInfo?.merchant?._id;
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const categories = useSelector(selectOrg).category;
    const [textSearch, setTextSearch] = useState<string>("");
    const [pagination, setPagination] = useState<IPaginationNext>(INITAL_PAGE);
    const [proPagination, setProPagination] = useState<IPaginationNext>(INITAL_PAGE);
    const [storeList, setStoreList] = useState<IGetStoresBindingDropdownExample[]>([]);
    const [selectedStore, setSelectedStore] = useState<string>();
    const [total, setTotal] = useState<number>(0);
    const { success, error } = useNotify();
    const [tab, setTab] = useState<number>(1);
    const [checkStockDocs, setCheckStockDocs] = useState<IGetCheckStockDocsExample[]>([]);
    const organization_id = useSelector(selectAuth).userInfo?.merchant?._id;
    const [vatDetail, setVatDetail] = useState<IGetVatDetailExample>();
    const { adminPermission } = useSelector(selectAuth);

    useEffect(() => {
        fetchNews();
        getListProduct();
    }, [textSearch, pagination, selectedStore]);

    const fetchNews = async () => {
        dispatch(setLoading(true));
        try {
            if (storeList?.length === 0) {
                const getStores = await storeAPI.getStoresBindingDropdown({
                    organization_id: org_id,
                });
                if (getStores.data.error === "0") {
                    setStoreList(getStores.data.result);
                }
            }
            const getVatDetail = await orgApi.getVatDetail({ organization_id: org_id });
            if (getVatDetail.data.error === "0") {
                setVatDetail(getVatDetail.data.result);
            }
            const getAllStocks = await consumableAPI.getList({
                organization_id: org_id,
                xlimit: INITAL_PAGE.xlimit,
                xpage: INITAL_PAGE.xpage,
                is_enable: true,
                is_auto_consumable: false,
            });
            for (let i = 0; i < getAllStocks.data.result.length; i++) {
                getAllStocks.data.result[i].editStep = false;
            }


            // Search Input
            if (textSearch.length > 0) {
                let tempServices = getAllStocks.data.result;
                tempServices = tempServices.filter((s) => {
                    return s.name.toLowerCase().match(textSearch.toLowerCase());
                });
                setStoreList(tempServices);
            }
            // Search Input
        } catch (err: any) {
            error(err.msg);
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const getListProduct = async () => {
        dispatch(setLoading(true));
        try {
            const productRes = await checkStockDocAPI.getCheckStockDocs({
                organization_id: organization_id || "",
                xpage: proPagination.xpage,
                xlimit: proPagination.xlimit,
                text_search: textSearch,
                store_id: selectedStore || "",
                is_waste: true,
            });
            setCheckStockDocs(productRes?.data?.result);
            setTotal(productRes.data.total);
        } catch (err: any) {
            error(err.msg);
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleSort = (sortField: enumContactSortFields, sortBy: enumSortBy) => {
        const newSortParams = { ...params, sortField, sortBy };
        setParams(newSortParams);
    };
    const handlePagination = (page) => {
        if (tab === 1) {
            setPagination({ ...pagination, xpage: page });
        } else if (tab === 2) {
            setProPagination({ ...pagination, xpage: page });
        }
    };

    const handleSelectStore = (value) => {
        setSelectedStore(value);
    };
    const { Option } = Select;
    const { RangePicker } = DatePicker;
    const handleChange = (value) => {
        //
    };
    const onChange = (date, dateString) => {
        //
    };
    const dateFormat = "Do MMMM YYYY";
    const today = moment();

    return (
        <StyledTable>
            <div className="page-layout">
                <StockStyle>
                    <div className="page-header-layout">
                        <div className="page-header" style={{ padding: "1.5rem 0" }}>
                            <div>
                                <h3>{t("page.sidebar.waste_record")}</h3>
                            </div>
                            {/* <div className="flex-header">
                                <div className="flexHeaderElements">
                                    <p>{t("page.format")}:</p>
                                    <Select
                                        showSearch
                                        filterOption={(input, option) =>
                                            //@ts-ignore
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        defaultValue="day"
                                        style={{ width: 120 }}
                                        onChange={handleChange}
                                    >
                                        <Option value="day">{t("page.format_option.day")}</Option>
                                    </Select>
                                </div>
                                <div className="flexHeaderElements">
                                    <p>{t("page.date")}:</p>
                                    <Select
                                        defaultValue="customDate"
                                        style={{ width: 150 }}
                                        onChange={handleChange}
                                    >
                                        <Option value="customDate">{t("page.custom_date")}</Option>
                                    </Select>
                                </div>
                                <div className="flexHeaderElements">
                                    <RangePicker
                                        defaultValue={[
                                            moment(today, dateFormat),
                                            moment(today, dateFormat),
                                        ]}
                                        format={dateFormat}
                                        separator={"-"}
                                        onChange={onChange}
                                    />
                                </div>
                            </div> */}
                        </div>
                        <div className="search-layout">
                            <ComponentTableWasteRecordStart
                                setTextSearch={setTextSearch}
                                categories={categories}
                                total={total}
                                isRangePicker={true}
                                storeList={
                                    adminPermission?.pos_role.store_access_type === "all_store" ||
                                        adminPermission?.pos_role.store_access_type === undefined
                                        ? storeList
                                        : adminPermission?.store_ids
                                }
                                handleSearchCategory={handleSelectStore}
                            />
                        </div>
                    </div>
                </StockStyle>
                <div className="page-table-layout">
                    <StockStyle>
                        <div className="table">
                            <div className="table_ground">
                                <div className="table-body">
                                    <div className="table-header">
                                        <Row
                                            gutter={24}
                                            type="flex"
                                            justify="space-between"
                                            align="middle"
                                            className="header-row"
                                        >
                                            <Col className="col-item" span={4}>
                                                <p>{t("page.date_time")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.POINTS}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.POINTS}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col
                                                className="col-item"
                                                span={4}
                                                style={{ placeContent: "center" }}
                                            >
                                                <p>{t("page.check_stock_round_no")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.POINTS}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.POINTS}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col
                                                className="col-item"
                                                span={2}
                                                style={{ placeContent: "end" }}
                                            >
                                                <p>{t("page.store")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.POINTS}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.POINTS}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col
                                                className="col-item"
                                                span={
                                                    vatDetail &&
                                                        vatDetail?.is_enable_waste_record_reason
                                                        ? 2
                                                        : 3
                                                }
                                                style={{ placeContent: "end" }}
                                            >
                                                <p>{t("page.affected_items")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.POINTS}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.POINTS}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col
                                                className="col-item"
                                                span={3}
                                                style={{ placeContent: "end" }}
                                            >
                                                <p>{t("page.status")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.POINTS}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.POINTS}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col
                                                className="col-item"
                                                span={
                                                    vatDetail &&
                                                        vatDetail.is_enable_waste_record_reason
                                                        ? 3
                                                        : 4
                                                }
                                                style={{ placeContent: "center" }}
                                            >
                                                <p>{t("page.manage")}</p>
                                            </Col>
                                            {vatDetail && vatDetail.is_enable_waste_record_reason && (
                                                <Col
                                                    className="col-item"
                                                    span={3}
                                                    style={{ placeContent: "center" }}
                                                >
                                                    <p>{t("page.waste_reason")}</p>
                                                </Col>
                                            )}
                                        </Row>
                                    </div>
                                    {checkStockDocs.length !== 0 ? (
                                        <div className="data-table">
                                            {checkStockDocs?.map((item, index) => (
                                                <ComponentRowCheckStockStart
                                                    data={item}
                                                    key={index}
                                                    vatDetail={vatDetail}
                                                />
                                            ))}
                                            <div style={{ width: "100%", height: 10 }}></div>
                                        </div>
                                    ) : (
                                        <ComponentEmptyData />
                                    )}
                                </div>
                            </div>
                        </div>
                    </StockStyle>
                </div>

                <div className="page-bottom">
                    <div className="pagination">
                        <Pagination
                            defaultCurrent={INITIAL_LIST_PARAMS.page || 1}
                            pageSize={PAGINATION}
                            total={total}
                            onChange={handlePagination}
                            current={pagination.xpage}
                        />
                    </div>
                </div>
            </div>
        </StyledTable>
    );
};

export const StockStyle = styled.div`
    .page-header-layout {
        padding: 3.75rem 4.625rem 0 2.125rem !important;
    }
    .table {
        width: 100%;
    }
    .table-header {
        padding: 18px 40px 12px 16px !important;
        margin-left: 10px !important;
    }
    .ant-tabs-bar {
        border: none;
    }
    .ant-tabs-nav .ant-tabs-tab {
        padding: 8px 0;
        margin: 0 28px 0 0;
    }
    .ant-tabs-nav .ant-tabs-tab-active {
        color: #f4394f;
        font-weight: 600;
    }
    .ant-tabs-ink-bar {
        background-color: #f4394f;
    }
    .ant-tabs-nav .ant-tabs-tab:hover {
        color: #f4394f;
    }
    .grayBox {
        background: #f7f7f8;
        border-radius: 6px;
        padding: 16px;
        display: flex;
        flex-direction: column;
        place-items: center;
    }
    .grayBox .body1 {
        font-size: 16px;
        font-weight: 700;
    }
    .grayBox .header {
        font-size: 35px;
        font-weight: 700;
        color: #000;
        margin: 0;
    }
    .addQTY {
        margin-top: 24px;
    }
    .reasonDiv {
        margin-top: 2rem;
    }
    .reasonChoice {
        display: block;
        line-height: 30px;
    }
    .ant-radio-group {
        width: 100%;
    }
    .flexHeaderElements {
        display: flex;
        gap: 16px;
        align-items: center;
    }
    .page-header p {
        margin-bottom: 0;
        color: #000 !important;
        font-weight: 400 !important;
    }
    .ant-select-selection,
    .ant-calendar-picker-input {
        border-radius: 8px;
        border: 0.5px solid #e1e1e1;
        background: #fff;
    }
    .ant-select {
        height: 40px;
    }
    .flex-header {
        gap: 20px;
    }
    .ant-input {
        height: 40px;
    }
    .ant-calendar-range-picker-separator {
        vertical-align: unset;
    }
`;
