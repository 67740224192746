import { useState, useEffect } from "react";
import {
    ComponentContactSortIcon,
    StyledTable,
    ComponentEmptyData,
    StyledTableButtonTop,
} from "@components";
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    TouchSensor,
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    rectSortingStrategy,
    //@ts-ignore
} from "@dnd-kit/sortable";
import { Row, Col, Pagination, Button, Tabs } from "antd";
import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";
import {
    enumSortBy,
    enumContactSortFields,
    PAGINATION,
    PAGE_START,
    INITIAL_LIST_PARAMS,
    PATH_STOCK_CREATE,
} from "@configs";
import { IPaginationNext, IDataexample, IConsumableExample } from "@interfaces";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth, selectContact, selectOrg, setLoading } from "@redux";
import { useNotify } from "@utils";
import { useHistory } from "react-router";
import styled from "styled-components";
import { storeAPI } from "@api";
import { ComponentTableSearchStock } from "src/Components/TableSearchStock";
import { ComponentRowStock } from "src/Components/table/RowStock";
import { consumableAPI } from "src/API/consumable";
import { IUpdateStore } from "src/Interfaces/store";

const INITAL_PAGE = {
    xpage: PAGE_START,
    xlimit: PAGINATION,
};

export const PageStockList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const search_params = useSelector(selectContact).paramsContact;
    const [params, setParams] = useState(search_params);
    const org_id = useSelector(selectAuth).userInfo?.merchant?._id;
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const categories = useSelector(selectOrg).category;
    const [textSearch, setTextSearch] = useState<string>("");
    const [Refresh, setRefresh] = useState<boolean>(false);
    const [pagination, setPagination] = useState<IPaginationNext>(INITAL_PAGE);
    const [rPagination, setRPaginagion] = useState<IPaginationNext>(INITAL_PAGE);
    const [services, setServices] = useState<IDataexample[]>([]);
    let [stores, setStores] = useState<IConsumableExample[]>([]);
    const [storeStatus, setStoreStatus] = useState<boolean>(false);
    const [total, setTotal] = useState<number>(0);
    const [rTotal, setRTotal] = useState<number>(0);
    const { success, error } = useNotify();
    const [currentTab, setCurrentTab] = useState<string>("1");
    const history = useHistory();
    useEffect(() => {
        if (currentTab === "1") {
            fetchNews(pagination);
        }
    }, [storeStatus, pagination, textSearch]);
    useEffect(() => {
        if (currentTab === "2") {
            getListStockRunningOut(rPagination);
        }
    }, [storeStatus, rPagination, textSearch]);

    const fetchNews = async (nPage: IPaginationNext) => {
        dispatch(setLoading(true));
        try {
            const getAllStocks = await consumableAPI.getList({
                organization_id: org_id,
                xlimit: nPage.xlimit,
                xpage: nPage.xpage,
                is_enable: true,
                text_search: textSearch,
            });

            setTotal(getAllStocks.data.total);
            const stores = getAllStocks.data.result;
            setStores(getAllStocks.data.result);
            setRPaginagion(INITAL_PAGE);
        } catch (err: any) {
            error(err.msg);
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };
    const getListStockRunningOut = async (nPage: IPaginationNext) => {
        try {
            const getList = await consumableAPI.getListStoreRunningOut({
                organization_id: org_id,
                xpage: nPage.xpage,
                xlimit: nPage.xlimit,
                // category_id: "true",
                text_search: textSearch,
            });
            setRTotal(getList.data.total);
            setStores(getList.data.result);
            setPagination(INITAL_PAGE);
        } catch (err) {
            console.log(err);
        }
    };
    const { TabPane } = Tabs;

    const handleSort = (sortField: enumContactSortFields, sortBy: enumSortBy) => {
        const newSortParams = { ...params, sortField, sortBy };
        setParams(newSortParams);
        // fetchContacts(newSortParams);
    };

    const callbackGetList = () => {
        fetchNews(pagination);
    };
    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            const swapItem = (items: IDataexample[]) => {
                const oldIndex = items.findIndex((item) => JSON.stringify(item) === active.id);
                const newIndex = items.findIndex((item) => JSON.stringify(item) === over.id);
                return arrayMove(items, oldIndex, newIndex);
            };
            setServices(swapItem);
        }
    };
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
        useSensor(TouchSensor)
    );
    const handlePagination = (page) => {
        setPagination({ ...pagination, xpage: page });
    };
    const handleRPagination = (page) => {
        setRPaginagion({ ...rPagination, xpage: page });
    };
    const updateService = async (data, item) => {
        dispatch(setLoading(true));
        try {
            // api call in here
            const updateStoreStatus = await storeAPI.updateStoreStatus({
                organization_code: orgCode,
                store_code: item.store_code,
                is_enable: !item.is_enable,
            });
            if (updateStoreStatus.data.error === "0") {
                setStoreStatus(!storeStatus);
            }
            success("Status Changed");
        } catch (err: any) {
            error(err.msg);
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleSearchCategory = (value: string) => {
        // setSelectedStore(value);
        setTextSearch(value);
        setPagination({
            ...pagination,
            xpage: 1,
        });
    };

    return (
        <StyledTable>
            <div className="page-layout">
                <div className="page-header-layout">
                    <div className="page-header" style={{ padding: "1.5rem 0" }}>
                        <div>
                            <h3>{t("page.consumable_lists")}</h3>
                        </div>
                        <div className="flex-header">
                            <StyledTableButtonTop
                                type="danger"
                                size="default"
                                text={t("page.add_consumable")}
                                onClick={() => history.push(PATH_STOCK_CREATE)}
                                className="add-btn"
                                icon="plus"
                                style={{
                                    background: "#F22F46",
                                    borderRadius: "4px",
                                    width: "100%",
                                }}
                            />
                        </div>
                    </div>
                    <StockStyle>
                        <Tabs
                            defaultActiveKey="1"
                            activeKey={currentTab}
                            onChange={(key) => {
                                if (key === "2") {
                                    setCurrentTab("2");
                                    setRPaginagion(INITAL_PAGE);
                                    getListStockRunningOut(INITAL_PAGE);
                                } else {
                                    setCurrentTab("1");
                                    setPagination(INITAL_PAGE);
                                    fetchNews(INITAL_PAGE);
                                }
                            }}
                        >
                            <TabPane tab={t("page.all_consumables")} key="1"></TabPane>
                            <TabPane tab={t("page.stock_running_out")} key="2"></TabPane>
                        </Tabs>
                    </StockStyle>
                    <div className="search-layout">
                        <ComponentTableSearchStock
                            total={total}
                            handleSearchCategory={handleSearchCategory}
                        />
                    </div>
                </div>
                <div className="page-table-layout">
                    <div className="table">
                        <ScrollContainer vertical={false}>
                            <StockStyle>
                                <div className="table_ground">
                                    <div className="table-body">
                                        <div className="table-header">
                                            <Row
                                                gutter={24}
                                                type="flex"
                                                justify="space-between"
                                                align="middle"
                                                className="header-row"
                                            >
                                                <Col className="col-item" span={8}>
                                                    <p>{t("page.consumable_name")}</p>
                                                    <div className="filter-button">
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.ASC}
                                                            sortField={enumContactSortFields.POINTS}
                                                            searchParams={params}
                                                        />
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.DESC}
                                                            sortField={enumContactSortFields.POINTS}
                                                            searchParams={params}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col
                                                    className="col-item"
                                                    span={8}
                                                    style={{ placeContent: "center" }}
                                                >
                                                    <p>{t("page.unit")}</p>
                                                    <div className="filter-button">
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.ASC}
                                                            sortField={enumContactSortFields.POINTS}
                                                            searchParams={params}
                                                        />
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.DESC}
                                                            sortField={enumContactSortFields.POINTS}
                                                            searchParams={params}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col
                                                    className="col-item"
                                                    span={8}
                                                    style={{ placeContent: "end" }}
                                                >
                                                    <p>{t("page.manage")}</p>
                                                </Col>
                                            </Row>
                                        </div>
                                        <DndContext
                                            sensors={sensors}
                                            collisionDetection={closestCenter}
                                            onDragEnd={handleDragEnd}
                                        >
                                            <SortableContext
                                                items={stores.map((item) => JSON.stringify(item))}
                                                strategy={rectSortingStrategy}
                                            >
                                                {stores.length !== 0 ? (
                                                    <div className="data-table">
                                                        {stores.map((item, index) => (
                                                            <ComponentRowStock
                                                                key={index}
                                                                data={item}
                                                                itemJson={JSON.stringify(item)}
                                                                categories={categories}
                                                                handleEdit={updateService}
                                                                callbackGetList={callbackGetList}
                                                                index={index}
                                                                stores={stores}
                                                            />
                                                        ))}
                                                        <div
                                                            style={{ width: "100%", height: 10 }}
                                                        ></div>
                                                    </div>
                                                ) : (
                                                    <ComponentEmptyData />
                                                )}
                                            </SortableContext>
                                        </DndContext>
                                    </div>
                                </div>
                            </StockStyle>
                        </ScrollContainer>
                    </div>
                </div>

                <div className="page-bottom">
                    {currentTab === "1" && (
                        <div className="pagination">
                            <Pagination
                                defaultCurrent={INITIAL_LIST_PARAMS.page || 1}
                                pageSize={pagination.xlimit}
                                total={total}
                                onChange={handlePagination}
                                current={pagination.xpage}
                            />
                        </div>
                    )}
                    {currentTab === "2" && (
                        <div className="pagination">
                            <Pagination
                                defaultCurrent={INITIAL_LIST_PARAMS.page || 1}
                                pageSize={rPagination.xlimit}
                                total={rTotal}
                                onChange={handleRPagination}
                                current={rPagination.xpage}
                            />
                        </div>
                    )}
                </div>
            </div>
        </StyledTable>
    );
};

export const StockStyle = styled.div`
    .table-header {
        padding: 18px 40px 12px 45px !important;
        margin-left: 10px !important;
    }
    .ant-tabs-bar {
        border: none;
    }
    .ant-tabs-nav .ant-tabs-tab {
        padding: 8px 0;
        margin: 0 28px 0 0;
    }
    .ant-tabs-nav .ant-tabs-tab-active {
        color: #f4394f;
        font-weight: 600;
    }
    .ant-tabs-ink-bar {
        background-color: #f4394f;
    }
    .ant-tabs-nav .ant-tabs-tab:hover {
        color: #f4394f;
    }
    .grayBox {
        background: #f7f7f8;
        border-radius: 6px;
        padding: 16px;
        display: flex;
        flex-direction: column;
        place-items: center;
    }
    .grayBox .body1 {
        font-size: 16px;
        font-weight: 700;
    }
    .grayBox .header {
        font-size: 35px;
        font-weight: 700;
        color: #000;
        margin: 0;
    }
    .addQTY {
        margin-top: 24px;
    }
    .reasonDiv {
        margin-top: 2rem;
    }
    .reasonChoice {
        display: block;
        line-height: 30px;
    }
    .ant-radio-group {
        width: 100%;
    }
    .ant-select-selection__rendered {
        width: 100%;
    }
    .ant-input-group-addon .ant-select {
        height: 39px;
    }
`;
