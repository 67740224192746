import { PATH_CHECK_STOCK } from "@configs";
import { Row, Col, Input, Select, Menu, Dropdown } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { IGetStoresBindingDropdownExample } from "src/Interfaces/store";
import styled from "styled-components";
import { StyledTableButtonTop } from "./styles";
const { Search } = Input;
const { Option } = Select;

interface Iprops {
    setTextSearch: (value: string) => void;
    handleSearchCategory?: (value: string) => void;
    storeList?: any[];
}
export const ComponentTableCheckStockStart = (props: Iprops) => {
    const { setTextSearch, handleSearchCategory, storeList } = props;
    const { t } = useTranslation();
    const history = useHistory();

    const handleMenuClick = (e) => {
        history.push(`${PATH_CHECK_STOCK}/publish`, { checkType: e.key });
    };

    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="adhoc">{t("page.adhoc")}</Menu.Item>
            <Menu.Item key="inventory_daily">{t("page.inventory_daily")}</Menu.Item>
            <Menu.Item key="inventory_weekly">{t("page.inventory_weekly")}</Menu.Item>
            <Menu.Item key="inventory_monthly">{t("page.inventory_monthly")}</Menu.Item>
            <Menu.Item key="product_daily">{t("page.product_daily")}</Menu.Item>
            <Menu.Item key="product_weekly">{t("page.product_weekly")}</Menu.Item>
            <Menu.Item key="product_monthly">{t("page.product_monthly")}</Menu.Item>
        </Menu>
    );

    return (
        <StyledSearchLayout>
            <div className="filter">
                <Row
                    gutter={24}
                    type="flex"
                    justify="space-between"
                    align="middle"
                    className="search-row"
                >
                    <Col className="col-item" span={20}>
                        <div className="filter-section">
                            <div className="filter-element">
                                <p>{t("page.branch")}:</p>
                                <Select
                                    showSearch
                                    filterOption={(input, option) =>
                                        //@ts-ignore
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange={handleSearchCategory}
                                    placeholder={t("page.please_choose")}
                                >
                                    {storeList &&
                                        storeList.map((item, index) => (
                                            <Option value={item._id} key={index}>
                                                {item.store_name}
                                            </Option>
                                        ))}
                                </Select>
                            </div>
                            <div className="filter-element">
                                <p>{t("page.check_store_id")}</p>
                                <Search
                                    placeholder={t("page.search_stores")}
                                    onSearch={(value) => setTextSearch(value)}
                                    style={{ width: 200 }}
                                    onChange={(event) => {
                                        setTextSearch(event.target.value);
                                    }}
                                />
                            </div>
                        </div>
                    </Col>

                    <Col className="col-item" span={4}>
                        <div className="filter-element result">
                            <Dropdown overlay={menu} trigger={["click"]}>
                                <StyledTableButtonTop
                                    type="danger"
                                    size="default"
                                    text={t("page.start")}
                                    // onClick={() => history.push(PATH_CHECK_STOCK + "/publish")}
                                    className="add-btn"
                                    icon={"caret-right"}
                                    style={{
                                        background: "#F22F46",
                                        borderRadius: "4px",
                                        width: "100%",
                                    }}
                                />
                            </Dropdown>
                        </div>
                    </Col>
                </Row>
            </div>
        </StyledSearchLayout>
    );
};
const StyledSearchLayout = styled.div`
    @media (min-width: 992px) {
        .ant-select-selection {
            width: 160px;
        }
    }
    .ant-btn:focus,
    .ant-btn:active,
    .ant-btn.active {
        color: #fff !important;
    }
    .filter-section {
        display: flex;
        grid-gap: 25px;
    }
    .filter {
        background: #fff;
        padding: 20px;
        border-radius: 4px;
        margin-bottom: 26px;

        .filter-element {
            display: flex;
            align-items: center;
            column-gap: 8px;
            p {
                margin: 0;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #646464;
            }
            .ant-input-search {
                height: 44px;
                width: 100%;
            }
            .ant-select {
                min-width: 200px;
                width: 100%;
                .ant-select-selection--single {
                    height: 44px;
                    .ant-select-selection__rendered {
                        height: 100%;
                        align-items: center;
                        display: flex;
                        min-width: 200px;
                    }
                }
                .ant-select-selection {
                    min-width: 200px;
                }
            }
            .ant-calendar-picker-input {
                height: 44px;
            }
            &.result {
                display: flex;
                justify-content: end;
                p {
                    display: flex;
                    column-gap: 10px;
                }
            }
        }
    }
`;
