import { enumDrawerPlacement, PATH_PRODUCT, PATH_PRODUCT_CREATE, PATH_STOCK_LIST } from "@configs";
import { Avatar, Button, Input, Radio, Select, Tabs, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { DeleteOutlined } from "@ant-design/icons";
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    TouchSensor,
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    rectSortingStrategy,
    //@ts-ignore
} from "@dnd-kit/sortable";
import {
    InfoIcon,
    IconCamera,
    SharedInput,
    StyledSubmitButton,
    StyledCancelButton,
    SharedComponentDrawer,
} from "@components";
import { useNotify } from "@utils";
import { IMAGE_TYPE, enumValidation } from "@configs";
import { useEffect, useState } from "react";
// import { ComponentSortableItem } from "./SortableItem";
import axios from "axios";
import { useHistory, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getCategories, selectAuth } from "@redux";
import { orgApi } from "@api";
import { StockStyle } from "@pages";
import { consumableAPI } from "src/API/consumable";
import { IConsumableExample, IConsumableStores } from "@interfaces";

interface IProps {
    showModal: boolean;
    handleClose: () => void;
    editItem?: IConsumableStores;
    stock?: IConsumableExample;
    setRefresh?: any;
    Refresh?: any;
    Unit?: string | undefined;
    itemDefault?: IDefault;
}

interface IDefault {
    oldQty: number;
    unit_name: string;
    store_id: string;
    consumable_id: string;
    price: number;
}

export const ComponentEditProInvDrawer = (props: IProps) => {
    const history = useHistory();
    const { pathname } = useLocation();
    const { showModal, handleClose, editItem, stock, setRefresh, Refresh, Unit, itemDefault } =
        props;
    const { t } = useTranslation();
    const { success, error } = useNotify();
    const [subCategory, setSubCategory] = useState<string[]>(["sub category"]);
    const org_id = useSelector(selectAuth).userInfo?.merchant?._id;
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const [UnitName, setUnitName] = useState<string>();
    const [categoryImage, setCategoryImage] = useState<string>("");
    const [unitPrice, setUnitPrice] = useState<number>(0);
    const [restockPrice, setRestockPrice] = useState<number>();
    const [QTY, setQTY] = useState<number>(0);
    const [newQTY, setNewQTY] = useState<number>(0);
    const [reason, setReason] = useState<string>("");
    const [reasonTextArea, setReasonTextArea] = useState<string>("");
    const [Deduct, setDeduct] = useState<boolean>(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (editItem) {
            setRestockPrice(stock?.formula[0].to_used_unit);
            // setQTY(editItem.qty);
            // setUnitName(stock?.unit_name);
        }
    }, [editItem]);
    useEffect(() => {
        if (itemDefault) {
            setQTY(itemDefault?.oldQty);
            setUnitName(itemDefault?.unit_name);
            setUnitPrice(itemDefault?.price);
        }
    }, [itemDefault]);

    const hasReasonChange = (e) => {
        setReason(e.target.value);
        if (reason === "Etc.") {
            setReason(reasonTextArea);
        }
    };

    // const handleSave = async () => {
    //     try {
    //         const updateCat = await consumableAPI.updateConsumableStoreID({
    //             organization_id: org_id,
    //             consumable_id: stock?._id,
    //             store_id: editItem?.store_id,
    //             edited_qty: Deduct === true ? -newQTY : newQTY,
    //             minimum_qty: editItem?.minimum_qty,
    //             remark: reason,
    //             unit_name: UnitName,
    //             unit_price: Deduct === true ? 0 : unitPrice,
    //         });
    //         if (updateCat.data.error === "0") {
    //             handleClose();
    //             setRefresh(!Refresh);
    //             success(t("message.update.success"));
    //             setNewQTY(0);
    //         }
    //     } catch (err) {
    //         console.log(err);
    //     }
    // };

    const handleSave = async () => {
        try {
            let payload = {
                organization_id: org_id,
                consumable_id: itemDefault?.consumable_id,
                store_id: itemDefault?.store_id,
                edited_qty: Deduct === true ? -newQTY : newQTY,
                minimum_qty: 0,
                remark: reason,
                unit_name: itemDefault?.unit_name,
                unit_price: Deduct === true ? 0 : unitPrice,
            };
            const updateCat = await consumableAPI.updateConsumableStoreID(payload);
            if (updateCat?.data?.error === "0") {
                handleClose();
                setRefresh(!Refresh);
                success(t("message.update.success"));
                setNewQTY(0);
            }
        } catch (err) {
            console.log(err);
        }
    };
    const { TabPane } = Tabs;
    const { TextArea } = Input;
    const { Option } = Select;

    const handleSelect = (value: string) => {
        setUnitName(value);
    };

    return (
        <SharedComponentDrawer
            title={t("page.add_quantity")}
            placement={enumDrawerPlacement.RIGHT}
            onClose={handleClose}
            visible={showModal}
            width={538}
        >
            <StockStyle>
                {/* <Button onClick={() => console.log(stock)}>Click</Button> */}
                <Tabs
                    defaultActiveKey="1"
                    onChange={(value) => {
                        if (value === "2") {
                            setDeduct(true);
                        } else {
                            setDeduct(false);
                        }
                    }}
                >
                    <TabPane tab={t("page.add_quantity")} key="1">
                        <div className="grayBox">
                            <p className="body1">{t("page.current_quantity")}</p>
                            <p className="header">{QTY}</p>
                        </div>
                        <div className="addQTY">
                            <div className="title" style={{ fontSize: "16px", color: "#000" }}>
                                {t("page.quantity_to_add")}
                            </div>
                            <Input
                                // addonAfter={
                                //     <Select
                                //         style={{ width: "auto" }}
                                //         placeholder={t("page.select_unit")}
                                //         onChange={handleSelect}
                                //         value={UnitName}
                                //     >
                                //         <Option key={"unit_name"} value={stock?.unit_name}>
                                //             {stock?.unit_name}
                                //         </Option>
                                //         {stock &&
                                //             stock.formula.map((item, i) => (
                                //                 <Option
                                //                     key={item._id}
                                //                     value={item.restock_unit_name}
                                //                 >
                                //                     {item.restock_unit_name}
                                //                 </Option>
                                //             ))}
                                //     </Select>
                                // }
                                addonAfter={itemDefault?.unit_name}
                                placeholder="10"
                                type={"number"}
                                onChange={(value) => {
                                    setNewQTY(parseInt(value.target.value));
                                }}
                                size="large"
                                value={newQTY}
                            />
                            <small>
                                {t("page.quantity")} {QTY} + {newQTY} = {QTY + newQTY} {Unit}
                            </small>
                        </div>
                        <div className="addQTY">
                            <div className="title" style={{ fontSize: "16px", color: "#000" }}>
                                {t("page.price_unit")}
                            </div>
                            <Input
                                addonAfter={"THB"}
                                placeholder="10"
                                type={"number"}
                                onChange={(value) => {
                                    setUnitPrice(Number(value.target.value));
                                }}
                                size="large"
                                value={unitPrice}
                            />
                            <small>
                                {t("page.old_price")} = {QTY * unitPrice} THB ,{" "}
                                {t("page.new_price")} = {QTY * unitPrice} THB (
                                {t("page.differenced")} = {QTY * unitPrice - QTY * unitPrice} THB)
                            </small>
                        </div>
                    </TabPane>
                    <TabPane tab={t("page.deduct_quantity")} key="2">
                        <div className="grayBox">
                            <p className="body1">{t("page.current_quantity")}</p>
                            <p className="header">{QTY}</p>
                        </div>
                        <div className="addQTY">
                            <div className="title" style={{ fontSize: "16px", color: "#000" }}>
                                {t("page.enter_quantity_to_deduct")}
                            </div>
                            <Input
                                // addonAfter={
                                //     <Select
                                //         style={{ width: "auto" }}
                                //         placeholder={t("page.select_unit")}
                                //         onChange={handleSelect}
                                //         value={UnitName}
                                //     >
                                //         <Option key={"unit_name"} value={stock?.unit_name}>
                                //             {stock?.unit_name}
                                //         </Option>
                                //         {stock &&
                                //             stock.formula.map((item, i) => (
                                //                 <Option
                                //                     key={item._id}
                                //                     value={item.restock_unit_name}
                                //                 >
                                //                     {item.restock_unit_name}
                                //                 </Option>
                                //             ))}
                                //     </Select>
                                // }
                                addonAfter={itemDefault?.unit_name}
                                placeholder="10"
                                type={"number"}
                                onChange={(value) => {
                                    setNewQTY(parseInt(value.target.value));
                                }}
                                size="large"
                                value={newQTY}
                            />
                            <small>
                                {t("page.quantity")} {QTY} - {newQTY} = {QTY - newQTY} {Unit}
                            </small>
                        </div>
                        <div className="reasonDiv">
                            <div className="title">{t("page.reason_for_quantity_deduction")}</div>
                            <Radio.Group
                                onChange={hasReasonChange}
                                // value={hasBarcode}
                            >
                                <Radio className="reasonChoice" value={"สินค้าชำรุด"}>
                                    สินค้าชำรุด
                                </Radio>
                                <Radio className="reasonChoice" value={"ไม่ได้สร้างออเดอร์ในระบบ"}>
                                    ไม่ได้สร้างออเดอร์ในระบบ
                                </Radio>
                                <Radio className="reasonChoice" value={"พนักงานทุจริต"}>
                                    พนักงานทุจริต
                                </Radio>
                                <Radio className="reasonChoice" value={"Etc."}>
                                    Etc.
                                </Radio>
                                <TextArea
                                    value={reasonTextArea}
                                    style={{ width: "100%" }}
                                    rows={4}
                                    onChange={(value) => setReasonTextArea(value.target.value)}
                                    disabled={reason === "Etc." ? false : true}
                                />
                            </Radio.Group>
                        </div>
                    </TabPane>
                </Tabs>
            </StockStyle>
            <div className="btn-layout">
                <StyledSubmitButton
                    type="default"
                    text={t("page.save")}
                    htmlType="button"
                    // disable={isSubmitting}
                    onClick={handleSave}
                />
                <StyledCancelButton
                    type="sub"
                    text={t("page.cancel")}
                    htmlType="button"
                    onClick={handleClose}
                />
            </div>
        </SharedComponentDrawer>
    );
};
