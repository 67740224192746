import { Input, Modal } from "antd";
import { AssignDeleteIcon } from "src/Components/icon/AssignDeleteIcon";
import styled from "styled-components";
import { StyledCancelButton, StyledSubmitButton } from "../Button";
import { useTranslation } from "react-i18next";
import { PlusIcon } from "src/Components/icon/orgSetting/PlusIcon";
import { ICheckStockReasonList, IUpdateVatDetail, IWasteRecordReasonList } from "@interfaces";
import { useEffect, useState } from "react";

interface IProps {
    showModal: boolean;
    handleClose: () => void;
    title: string;
    setFieldValue: any;
    values: IUpdateVatDetail;
}
export const SetReasonModal = (props: IProps) => {
    const { showModal, handleClose, title, setFieldValue, values } = props;
    const { t } = useTranslation();

    useEffect(() => {
        if (values.is_check_stock_modal && values.check_stock_reason_list?.length === 0) {
            setFieldValue("check_stock_reason_list", [
                ...values.check_stock_reason_list,
                { reason: "" },
            ]);
        } else if (values.is_waste_record_modal && values.waste_record_reason_list?.length === 0) {
            setFieldValue("waste_record_reason_list", [
                ...values.waste_record_reason_list,
                { reason: "" },
            ]);
        }
    }, [values]);

    return (
        //@ts-ignore
        <StyledSetReasonModal
            visible={showModal}
            onCancel={handleClose}
            title={title}
            footer={null}
        >
            <div className="inputRowsBody">
                {values.is_check_stock_modal && values.check_stock_reason_list
                    ? values.check_stock_reason_list?.map((item, i) => (
                          <div className="inputRow" key={i}>
                              <Input
                                  value={item.reason}
                                  onChange={(value) => {
                                      setFieldValue(
                                          `check_stock_reason_list[${i}].reason`,
                                          value.target.value
                                      );
                                  }}
                              />
                              <div
                                  className="deleteIcon"
                                  onClick={() => {
                                      if (values.is_check_stock_modal) {
                                          setFieldValue(
                                              "check_stock_reason_list",
                                              values.check_stock_reason_list?.filter(
                                                  (reason, index) => i !== index
                                              )
                                          );
                                      }
                                  }}
                              >
                                  <AssignDeleteIcon />
                              </div>
                          </div>
                      ))
                    : values.is_waste_record_modal &&
                      values.waste_record_reason_list &&
                      values.waste_record_reason_list?.map((item, i) => (
                          <div className="inputRow" key={i}>
                              <Input
                                  value={item.reason}
                                  onChange={(value) => {
                                      if (values.is_check_stock_modal) {
                                          setFieldValue(
                                              `check_stock_reason_list[${i}].reason`,
                                              value.target.value
                                          );
                                      } else {
                                          setFieldValue(
                                              `waste_record_reason_list[${i}].reason`,
                                              value.target.value
                                          );
                                      }
                                  }}
                              />
                              <div
                                  className="deleteIcon"
                                  onClick={() => {
                                      if (values.is_check_stock_modal) {
                                          setFieldValue(
                                              "check_stock_reason_list",
                                              values.check_stock_reason_list?.filter(
                                                  (reason, index) => i !== index
                                              )
                                          );
                                      } else {
                                          setFieldValue(
                                              "waste_record_reason_list",
                                              values.waste_record_reason_list?.filter(
                                                  (reason, index) => i !== index
                                              )
                                          );
                                      }
                                  }}
                              >
                                  <AssignDeleteIcon />
                              </div>
                          </div>
                      ))}
                <div
                    className="plusIcon"
                    onClick={() => {
                        if (values.waste_record_reason_list && !values.is_check_stock_modal) {
                            setFieldValue("waste_record_reason_list", [
                                ...values.waste_record_reason_list,
                                { reason: "" },
                            ]);
                        } else if (values.is_check_stock_modal && values.check_stock_reason_list) {
                            setFieldValue("check_stock_reason_list", [
                                ...values.check_stock_reason_list,
                                { reason: "" },
                            ]);
                        }
                    }}
                >
                    <PlusIcon />
                </div>
            </div>
            <div className="btn-layout" style={{ marginTop: "2rem" }}>
                <StyledSubmitButton
                    type="default"
                    text={t("page.close")}
                    onClick={() => handleClose()}
                />
            </div>
        </StyledSetReasonModal>
    );
};

const StyledSetReasonModal = styled(Modal)`
    .inputRow {
        display: flex;
        flex-direction: row;
        gap: 16px;
        place-items: center;
        input {
            height: 48px;
        }
    }
    .ant-modal-body {
        padding-top: 20px !important;
    }
    .plusIcon {
        margin-top: 10px;
        cursor: pointer;
    }
    .inputRowsBody {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .deleteIcon {
        cursor: pointer;
    }
`;
