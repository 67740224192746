import { useTranslation } from "react-i18next";
import {
    SharedComponentDrawer,
    SharedInput,
    SharedModal2,
    StyledCancelButton,
    StyledSubmitButton,
} from "../shared";
import styled from "styled-components";
import { enumValidation, IMAGE_TYPE } from "@configs";
import { useNotify } from "@utils";
import axios from "axios";
import { useEffect, useState } from "react";
import { Avatar, Button, Col, Divider, Row, Switch, Tooltip } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { InfoIcon, IconCamera } from "@components";
import { useFormik } from "formik";
import { INews, INewsCategory, INewsData } from "@interfaces";
import * as Yup from "yup";
import { useYup } from "@validations";
import React from "react";
import { BarcodeIcon } from "../icon/BarcodeIcon";

interface IProps {
    showPopup2: boolean;
    handleClose: () => void;
    TempBarcode?: string;
    setFieldValue?: any;
    values?: any;
    currentChangeItem?: any;
    setTempBarcode?: any;
}

export const ComponentProductBarcodeModal = (props: IProps) => {
    const { YupNews } = useYup();
    const {
        showPopup2,
        handleClose,
        TempBarcode,
        setFieldValue,
        values,
        currentChangeItem,
        setTempBarcode,
    } = props;
    const { t } = useTranslation();
    const { success, error } = useNotify();
    const [switchButton, setSwitchButton] = useState<boolean>(false);
    const [Barcode, setBarcode] = useState<string>();

    useEffect(() => {
        if (currentChangeItem) {
            setBarcode(currentChangeItem?.variant_code || "");
        }
    }, [currentChangeItem]);

    const handleSave = () => {
        try {
            handleClose();
            const newState = values.variant_list.map((obj) => {
                if (
                    // obj._id === currentChangeItem._id 
                    // can not use id because new item do not have id
                    obj.dimension_list === currentChangeItem.dimension_list
                ) {
                    return {
                        ...obj,
                        variant_code: Barcode,
                    };
                }
                return obj;
            });
            setFieldValue("variant_list", newState);

        } catch (err) {
            console.error(err);
        } finally {
            setBarcode("");
            setSwitchButton(false);
        }
    };
    const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setBarcode(e.target.value);
        setTempBarcode(e.target.value);
    };
    const autoGenBarcode = Math.floor(1000000000000 + Math.random() * 9000000000000).toString();
    const onSwitchChange = () => {
        if (switchButton === true) {
            setBarcode("");
            setSwitchButton(false);
        } else {
            setBarcode(autoGenBarcode);
            setTempBarcode(autoGenBarcode);
            setSwitchButton(true);
        }
    };
    return (
        <SharedModal2
            showPopup2={showPopup2}
            handleClose={handleClose}
            title={t("page.enter_barcode")}
            width={600}
        >
            {/* modify popup content & css here */}
            <StyledModalContent>
                <div className="avatar">
                    <div
                        className="box_title"
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <p>{t("page.barcode")}</p>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <p style={{ fontSize: "14px" }}>{t("page.auto_generate")}</p>
                            <Switch
                                className="ant-switch"
                                onChange={(e) => {
                                    onSwitchChange();
                                }}
                                checked={
                                    TempBarcode === undefined || TempBarcode === ""
                                        ? false
                                        : switchButton
                                }
                            />
                        </div>
                    </div>
                </div>
                <Row className="barcodeRow">
                    <Col md={16} style={{ paddingRight: "10px" }}>
                        <SharedInput
                            onChange={handleChangeName}
                            value={
                                TempBarcode === undefined || TempBarcode === ""
                                    ? Barcode
                                    : TempBarcode
                            }
                            parentClassName="mb-6"
                        />
                    </Col>
                    <Col md={8}>
                        <Button type="primary" className="scanBtn">
                            <BarcodeIcon />
                            <p>{t("page.scan_barcode")}</p>
                        </Button>
                    </Col>
                </Row>
            </StyledModalContent>
            <Divider />
            <div
                className="btn-layout"
                style={{ display: "flex", justifyContent: "space-between" }}
            >
                <StyledCancelButton
                    type="sub"
                    text={t("page.cancel")}
                    htmlType="button"
                    onClick={handleClose}
                />
                <StyledSubmitButton
                    type="default"
                    text={t("page.save")}
                    htmlType="button"
                    // disable={isSubmitting}
                    onClick={handleSave}
                    style={{ marginRight: 0 }}
                />
            </div>
        </SharedModal2>
    );
};

const StyledModalContent = styled.div`
    .ant-drawer-body {
        .add-sub-btn {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: #0263e0;
            margin: 0;
            cursor: pointer;
        }
        .title {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            color: #000000;
            margin-bottom: 20px;
        }
        .btn-layout {
            position: fixed;
            bottom: 16px;
        }
    }
    .avatar {
        margin-bottom: 0;
        position: relative;
        .box_title {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            p {
                margin: 0 4px 0 0;
                color: #000;
            }
            // button {
            //     padding: 0 4px;
            //     border: 0;
            //     background: transparent;
            //     height: auto;
            // }
        }
    }
    .avatar .box_title {
        margin: 30px auto 10px auto;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 1.5em;
    }
    .avatar-layout {
        position: relative;
        width: 110px;
        &:hover {
            .remove-icon-layout {
                visibility: visible;
            }
        }
    }
    .remove-icon-layout {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
        visibility: hidden;
        display: flex;
        justify-content: end;
        padding: 8px;
    }
    .avatar .box_title span {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 1.5em;
        position: relative;
        top: -1px;
        display: inline-block;
        margin-left: 4px;
    }
    .avatar .camera {
        width: 33px;
        height: 33px;
        background-color: #000000;
        border-radius: 50%;
        opacity: 0.29;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 110px;
        bottom: 0;
        transform: translate(-50%, 50%);
        cursor: pointer;
    }
    .scanBtn {
        display: flex;
        place-content: center;
        gap: 5px;
        align-items: center;
        height: 48px;
        background: #0263e0;
        width: 100%;
        p {
            margin: 0;
        }
    }
    .barcodeRow {
        gap: 10px;
    }
`;
