import { useTranslation } from "react-i18next";
import {
    SharedComponentDrawer,
    SharedInput,
    SharedModal,
    StyledCancelButton,
    StyledSubmitButton,
} from "../shared";
import styled from "styled-components";
import { enumValidation, IMAGE_TYPE } from "@configs";
import { useNotify } from "@utils";
import axios from "axios";
import { useEffect, useState } from "react";
import { Avatar, Button, Checkbox, Tooltip } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { InfoIcon, IconCamera } from "@components";
import { useFormik } from "formik";
import { INews, INewsCategory, INewsData } from "@interfaces";
import * as Yup from "yup";
import { useYup } from "@validations";
import React from "react";
import { ColumnProps } from "antd/lib/table";
import { Table } from "antd";
import moment from "moment";

interface IProps {
    showPopup: boolean;
    handleClose: () => void;
    tableData?: any;
    setTableData?: any;
}

export const ProductQTYHistoryModal = (props: IProps) => {
    const { YupNews } = useYup();
    const { showPopup, handleClose, tableData, setTableData } = props;
    const { t } = useTranslation();
    const { success, error } = useNotify();

    // Edit Stock Table
    interface IMyTableData {
        name: string;
        used_qty: number;
        _id: string;
        unit_name: string;
    }
    interface IGetHistory {
        is_deduct_by_pos: boolean;
        before_qty: number;
        edited_qty: number;
        new_qty: number;
        created_date: Date;
        remark: string;
    }

    const columns: ColumnProps<IGetHistory>[] = [
        {
            dataIndex: "created_date",
            title: () => <span style={{ fontWeight: 700 }}>{t("page.date")}</span>,
            key: "created_date",
            // width: "50%",
            sorter: (a, b) => moment(b.created_date).valueOf() - moment(a.created_date).valueOf(),
            defaultSortOrder: "ascend",
            render: (text, record, index) => (
                <div className="tableCell">
                    {new Date(text).toLocaleDateString("fr-CA") +
                        " " +
                        new Date(text).toTimeString().slice(0, 5)}
                </div>
            ),
        },
        {
            dataIndex: "activity",
            title: () => <span style={{ fontWeight: 700 }}>{t("page.activity")}</span>,
            key: "activity",
            render: (text, record, index) => (
                <div className="tableCell">
                    {record.before_qty < record.new_qty ? t("page.add") : t("page.deduct")}
                </div>
            ),
        },
        {
            dataIndex: "before_qty",
            title: () => <span style={{ fontWeight: 700 }}>{t("page.current_quantity")}</span>,
            key: "before_qty",
            render: (text, record, index) => <div className="tableCell">{text}</div>,
        },
        {
            dataIndex: "edited_qty",
            title: () => <span style={{ fontWeight: 700 }}>{t("page.edited_quantity")}</span>,
            key: "edited_qty",
            render: (text, record, index) => <div className="tableCell">{text}</div>,
        },
        {
            dataIndex: "new_qty",
            title: () => <span style={{ fontWeight: 700 }}>{t("page.new_quantity")}</span>,
            key: "new_qty",
            render: (text, record, index) => <div className="tableCell">{text}</div>,
        },
        {
            dataIndex: "remark",
            title: () => <span style={{ fontWeight: 700 }}>{t("page.note")}</span>,
            key: "remark",
            render: (text, record, index) => (
                <div className="tableCell">{text === "" ? "-" : text}</div>
            ),
        },
    ];
    // Edit Stock Table
    const handleSubmit = () => {
        handleClose();
    };

    return (
        <SharedModal
            showPopup={showPopup}
            handleClose={handleClose}
            title={t("page.history_edit_quantity")}
            width={900}
        >
            <StyledModalContent>
                <Table
                    columns={columns}
                    dataSource={tableData}
                    bordered
                    size="middle"
                    rowKey={"_id"}
                />
            </StyledModalContent>
        </SharedModal>
    );
};

const StyledModalContent = styled.div`
    .ant-drawer-body {
        padding: 0 !important;
    }
`;
