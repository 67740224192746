import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Switch, Col, Row, Avatar, Menu, Dropdown, Button, Checkbox } from "antd";
import { MoreHorizontal } from "react-feather";

import { IDataexample, INewsCategory } from "@interfaces";
import { enumNewsType, PATH_PRODUCT_CREATE, PATH_SERVICES_CREATE } from "@configs";
import { selectAuth, store } from "@redux";
import { useSelector } from "react-redux";
import { IconDrag } from "@components";
import { useNotify } from "@utils";
import React, { useEffect, useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import styled from "styled-components";
import axios from "axios";
import { orgApi } from "@api";
interface IProps {
    data: any;
    itemJson: string;
    handleEdit: (status: boolean, data: IDataexample) => void;
    callbackGetList: () => void;
    index: number;
    newType?: enumNewsType;
    categories: INewsCategory[];
    stores: any[];
    setStores: any;
    editItem: (data: IDataexample) => void;
}

export const ComponentRowBrand = (props: IProps) => {
    //hooks
    const { t } = useTranslation();
    const { success, error } = useNotify();
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const org_id = useSelector(selectAuth).userInfo?.merchant?._id;

    //props
    const {
        data,
        handleEdit,
        callbackGetList,
        itemJson,
        newType,
        stores,
        index,
        setStores,
        editItem,
    } = props;
    const [show, setShowModal] = useState(false);

    const { attributes, listeners, setNodeRef, transform } = useSortable({
        id: itemJson,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
    };

    const handleDelete = async () => {
        try {
            const res = await orgApi.deleteBrand({
                organization_id: org_id,
                brand_id: data?._id,
            });
            if (res.data.error === "0") {
                await callbackGetList();
            }
            setShowModal(false);
            success(t("message.delete.success"));
        } catch (err: any) {
            if (err) {
                error(err.msg);
            } else error(t("message.delete.fail"));
        }
    };

    const menu = (
        <Menu>
            <Menu.Item key={1} onClick={handleDelete}>
                <div className="duplicate-btn">{t("page.delete")}</div>
            </Menu.Item>
        </Menu>
    );


    // const [plainOptions, setPlainOptions] = useState(stores);
    return (
        <Styless>
            {/* {stores.length !== 0 ? (
                stores.map((item, i) => ( */}
            <div className="table-element" ref={setNodeRef} style={style}>
                <div className="drag-icon" {...attributes} {...listeners}>
                    <IconDrag />
                </div>
                <div className="table-row">
                    <Row
                        style={{ height: "100%" }}
                        gutter={24}
                        type="flex"
                        align="middle"
                        justify="space-between"
                    >
                        <Col className="col-item" span={12}>
                            <p
                                className="news-title"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    editItem(stores[index]);
                                }}
                            >
                                {stores[index].name || t("page.empty_text")}
                            </p>
                        </Col>
                        <Col className="col-item visible-md" span={6}>
                            <p>{0 || t("page.empty_text")}</p>

                        </Col>
                        <Col className="col-item" span={6} style={{ placeContent: "end" }}>
                            <Dropdown
                                //@ts-ignore
                                getPopupContainer={(trigger) => trigger.parentNode}
                                overlay={menu}
                                trigger={["click"]}
                                placement="bottomRight"
                            >
                                <Button style={{ border: "unset" }}>
                                    <MoreHorizontal />
                                </Button>
                            </Dropdown>
                        </Col>
                    </Row>
                </div>
            </div>
        </Styless>
    );
};
const Styless = styled.div`
    .table-row {
        height: 60px;
    }
`;
