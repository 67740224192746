import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Col, Row } from "antd";
import styled from "styled-components";
import {
    ComponentContactSortIcon,
    ComponentEmptyData,
    StyledTable,
    ComponentRowCheckStockInside,
    StyledCancelButton,
} from "@components";
import { IGetCheckStockDocsExample } from "@interfaces";
import { theme, useNotify } from "@utils";
import ScrollContainer from "react-indiana-drag-scroll";
import { PATH_CHECK_STOCK, PATH_WASTE_RECORD, enumContactSortFields, enumSortBy } from "@configs";
import { selectAuth, selectContact } from "@redux";
import { checkStockDocAPI } from "@api";

export const ModuleInsideStock = () => {
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const { t } = useTranslation();
    const { error } = useNotify();
    const search_params = useSelector(selectContact).paramsContact;
    const [params, setParams] = useState(search_params);
    const org_id = useSelector(selectAuth).userInfo?.merchant?._id;
    const [checkStockDoc, setCheckStockDoc] = useState<IGetCheckStockDocsExample>();

    const pathName = window.location.pathname;
    const handleSort = () => {
        null;
    };

    useEffect(() => {
        fetchNews();
    }, [id]);

    const fetchNews = async () => {
        try {
            const get = await checkStockDocAPI.getCheckStockDoc({
                organization_id: org_id,
                check_stock_doc_id: id,
            });
            if (get.data.error === "0") {
                setCheckStockDoc(get.data.result);
            } else {
                history.push(PATH_CHECK_STOCK);
            }
        } catch (err: any) {
            error(err.message);
        }
    };

    return (
        <StyledTable>
            <div className="page-layout">
                <CustomStyles>
                    <div className="page-header-layout">
                        <div className="page-header">
                            <h3>
                                {checkStockDoc?.is_waste === false
                                    ? t("page.check_stock")
                                    : t("page.waste_record")}{" "}
                                {/* - {checkStockDoc?.check_stock_code} */}
                            </h3>
                            <p>{t("page.remark")} : {checkStockDoc?.remark}</p>
                        </div>
                    </div>

                    <div className="page-table-layout">
                        <div className="table">
                            <ScrollContainer vertical={false}>
                                <div className="table_ground">
                                    <div className="table-body">
                                        <div className="table-header">
                                            <Row
                                                gutter={24}
                                                type="flex"
                                                justify="space-between"
                                                align="middle"
                                                className="header-row"
                                            >
                                                <Col className="col-item" span={6}>
                                                    <p>{t("page.consumable_name")}</p>
                                                    <div className="filter-button">
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.ASC}
                                                            sortField={
                                                                enumContactSortFields.FULL_NAME
                                                            }
                                                            searchParams={params}
                                                        />
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.DESC}
                                                            sortField={
                                                                enumContactSortFields.FULL_NAME
                                                            }
                                                            searchParams={params}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col className="col-item" span={6}>
                                                    {pathName.includes(PATH_WASTE_RECORD) ?
                                                        <p>{t("page.waste")}</p>
                                                        :
                                                        <p>{t("page.chech_stock_amount")}</p>
                                                    }

                                                    <div className="filter-button">
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.ASC}
                                                            sortField={
                                                                enumContactSortFields.CREATED_AT
                                                            }
                                                            searchParams={params}
                                                        />
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.DESC}
                                                            sortField={
                                                                enumContactSortFields.CREATED_AT
                                                            }
                                                            searchParams={params}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col className="col-item" span={6}>
                                                    <p>{t("page.current_amount")}</p>
                                                    <div className="filter-button">
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.ASC}
                                                            sortField={
                                                                enumContactSortFields.CREATED_AT
                                                            }
                                                            searchParams={params}
                                                        />
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.DESC}
                                                            sortField={
                                                                enumContactSortFields.CREATED_AT
                                                            }
                                                            searchParams={params}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col className="col-item" span={6}>
                                                    <p>{t("page.different_amount")}</p>
                                                    <div className="filter-button">
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.ASC}
                                                            sortField={
                                                                enumContactSortFields.CREATED_AT
                                                            }
                                                            searchParams={params}
                                                        />
                                                        <ComponentContactSortIcon
                                                            handleSort={handleSort}
                                                            sortBy={enumSortBy.DESC}
                                                            sortField={
                                                                enumContactSortFields.CREATED_AT
                                                            }
                                                            searchParams={params}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        {checkStockDoc &&
                                            checkStockDoc.check_doc_type === "ingredient" ? (
                                            <div className="data-table">
                                                {checkStockDoc?.check_consumable_list.map(
                                                    (item, index) => (
                                                        <ComponentRowCheckStockInside
                                                            key={index}
                                                            consumable={item}
                                                        />
                                                    )
                                                )}
                                                <div style={{ width: "100%", height: 10 }}></div>
                                            </div>
                                        ) : (
                                            <div className="data-table">
                                                {checkStockDoc?.check_product_list.map(
                                                    (item, index) => (
                                                        <ComponentRowCheckStockInside
                                                            key={index}
                                                            product={item}
                                                        />
                                                    )
                                                )}
                                                <div style={{ width: "100%", height: 10 }}></div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </ScrollContainer>
                        </div>
                        <div className="btn-layout">
                            <StyledCancelButton
                                type="sub"
                                text={t("page.back")}
                                htmlType="button"
                                onClick={() => {
                                    if (checkStockDoc?.is_waste === false) {
                                        history.push(PATH_CHECK_STOCK);
                                    } else {
                                        history.push(PATH_WASTE_RECORD);
                                    }
                                }}
                            />
                        </div>
                    </div>
                </CustomStyles>
            </div>
        </StyledTable>
    );
};
const CustomStyles = styled.div`
    .table-header {
        padding: 0 34px 12px 34px !important;
    }
    .ant-tabs-bar {
        border: none;
        margin-top: 10px;
    }
    .ant-tabs-nav .ant-tabs-tab {
        padding: 8px 0;
        margin: 0 28px 0 0;
    }
    .ant-tabs-nav .ant-tabs-tab-active {
        color: #f4394f;
        font-weight: 600;
    }
    .ant-tabs-ink-bar {
        background-color: #f4394f;
        height: 3px !important;
    }
    .ant-tabs-nav .ant-tabs-tab:hover {
        color: #f4394f;
    }
    .page-table-layout {
        padding: 0 4.625rem 0 4.625rem !important;
    }
    .branchListTitle {
        font-size: 16px;
        font-weight: 700;
        color: #000;
    }
    .branchListSection {
        margin-top: 20px;
    }
    .branchListSection .antd-btn-custom {
        margin-left: 0;
    }
    .selectBranchBox {
        position: absolute;
        background: #fff;
        margin-top: 10px;
        z-index: 999;
    }
    .ant-checkbox-wrapper {
        width: 100%;
    }
    .input-box {
        margin-top: 30px;
        .input-title {
            font-weight: 700;
            font-size: 16px;
            margin-bottom: 5px;
            height: 25px;
        }
        input {
            border-color: #d9d9d9 !important;
            box-shadow: none !important;
            height: 48px;
        }
        .radio-item {
            margin-right: 30px;
        }
    }
    .page-header h3 svg {
        position: relative;
        top: -5px;
    }
    .ant-progress-text {
        display: none;
    }
    .topStatusRow {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .statusFrame {
            border-radius: 7px;
            border: 1px solid #6cd14e;
            padding: 20px 24px;
            width: 100%;
            display: flex;
            align-items: center;
            gap: 5px;
            .leftSide {
                display: flex;
                align-items: center;
                gap: 5px;
            }
            span {
                color: #202020;
                font-size: 20px;
                font-style: normal;
                font-weight: 800;
                line-height: normal;
                width: max-content;
            }
            small {
                color: #000;
                text-align: right;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
            .anticon-check-circle {
                display: none;
            }
            .ant-progress-show-info .ant-progress-outer {
                padding: 0;
            }
        }
        .btnCol {
            height: 100%;
            display: flex;
            justify-content: flex-end;
            .downloadBtn {
                height: 40px;
            }
            .downloadBtn:hover {
                color: ${theme.colors.main};
            }
        }
    }
`;
