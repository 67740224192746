import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { StyledNewDetail } from "@components";
import { INews } from "@interfaces";
import { PATH_CREATE_NEWS, PATH_NEWS, PATH_STORE, PATH_STORE_CREATE } from "@configs";
import { ModuleServiceForm, ModuleStoreForm } from "@modules";
import { useNotify, showErrorMessage } from "@utils";
import { setLoading, selectAuth, selectOrg, getCategories } from "@redux";
import axios from "axios";
import { ModulePaymentSettingForm } from "src/Modules/payment";
import { orgApi } from "@api";

export const PagePaymentSetting = () => {
    const { id } = useParams<{ id: string }>();
    const org_id = useSelector(selectAuth).userInfo?.merchant._id;
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const categories = useSelector(selectOrg).category;
    const dispatch = useDispatch();
    const history = useHistory();
    const { warning } = useNotify();
    const { t } = useTranslation();
    // page state
    const [store, setStore] = useState<any>();
    // variable
    const pathName = history.location.pathname;

    useEffect(() => {
        fetchNews();
    }, []);

    const fetchNews = async () => {
        dispatch(setLoading(true));
        // if (pathName !== PATH_STORE_CREATE) {
        try {
            const getStore = await orgApi.getPaymentGateway({
                organization_code: orgCode,
            });
            setStore(getStore.data.result);
            // const response = await newsAPI.detail({
            //     organization_id: org_id || "",
            //     news_id: id,
            // });
            // const data: INews = response.data.result;
            // setNews(data);
            dispatch(setLoading(false));
        } catch (err: any) {
            if (err.response) {
                warning(showErrorMessage(err.response));
                dispatch(setLoading(false));
            } else warning(t("message.not_found", { name: t("object._reward") }));
            history.push(PATH_NEWS);
        } finally {
            dispatch(setLoading(false));
        }
        // }
    };

    return (
        <StyledNewDetail>
            {<ModulePaymentSettingForm store={store} categories={categories} />}
        </StyledNewDetail>
    );
};
