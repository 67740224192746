export const SmallTickIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="25"
            viewBox="0 0 26 25"
            fill="none"
        >
            <path
                d="M24.1532 10.6765C23.7747 10.4565 23.4345 10.1762 23.1461 9.84663C23.1753 9.38786 23.2846 8.93774 23.469 8.51667C23.8082 7.5597 24.1917 6.47568 23.5763 5.63294C22.9608 4.7902 21.7987 4.81701 20.7788 4.84032C20.3281 4.88669 19.8728 4.85595 19.4325 4.7494C19.1979 4.36767 19.0303 3.94861 18.9371 3.51036C18.648 2.52541 18.3182 1.41225 17.3052 1.07889C16.3285 0.764169 15.4239 1.45654 14.6243 2.06499C14.2793 2.38008 13.8795 2.62936 13.4447 2.8005C13.0054 2.63075 12.6012 2.38141 12.2523 2.06499C11.455 1.46004 10.554 0.760673 9.57257 1.08005C8.56198 1.40875 8.23211 2.52541 7.94071 3.51036C7.84761 3.94719 7.68172 4.36525 7.44998 4.74707C7.00882 4.85333 6.55296 4.88485 6.10137 4.84032C5.07796 4.81235 3.92517 4.78204 3.30389 5.63294C2.68262 6.48384 3.07077 7.5597 3.41113 8.5155C3.59814 8.93595 3.70904 9.38626 3.73867 9.84547C3.45085 10.1754 3.11107 10.4561 2.73274 10.6765C1.87951 11.2594 0.910889 11.9226 0.910889 12.9996C0.910889 14.0766 1.87951 14.7375 2.73274 15.3227C3.11098 15.5427 3.45076 15.8231 3.73867 16.1526C3.71216 16.6116 3.60442 17.0623 3.42046 17.4837C3.08243 18.4395 2.70011 19.5236 3.31438 20.3663C3.92866 21.209 5.08728 21.1822 6.11186 21.1589C6.56291 21.1125 7.01858 21.1432 7.45931 21.2498C7.69287 21.632 7.85998 22.0509 7.95353 22.4889C8.2426 23.4738 8.57247 24.587 9.58539 24.9204C9.74778 24.9724 9.91723 24.9992 10.0878 24.9996C10.9074 24.882 11.6693 24.5092 12.2651 23.9342C12.6102 23.6192 13.01 23.3699 13.4447 23.1987C13.8841 23.3685 14.2883 23.6178 14.6372 23.9342C15.4356 24.5439 16.3401 25.2397 17.3181 24.9192C18.3287 24.5905 18.6585 23.4738 18.9499 22.49C19.0433 22.0524 19.2105 21.6338 19.4441 21.2522C19.8836 21.1452 20.3381 21.1136 20.7881 21.1589C21.8115 21.1834 22.9643 21.2172 23.5856 20.3663C24.2068 19.5154 23.8187 18.4395 23.4783 17.4826C23.2926 17.0625 23.1818 16.6132 23.1508 16.1549C23.4388 15.8247 23.779 15.5439 24.1579 15.3239C25.0111 14.741 25.9797 14.0766 25.9797 12.9996C25.9797 11.9226 25.0076 11.2605 24.1532 10.6765Z"
                fill="#6CD14E"
            />
            <path
                d="M12.2771 16.2052C12.1623 16.2054 12.0486 16.1829 11.9426 16.1389C11.8365 16.0949 11.7403 16.0303 11.6594 15.9488L9.32814 13.6176C9.17371 13.4518 9.08965 13.2327 9.09364 13.0062C9.09764 12.7797 9.18939 12.5636 9.34956 12.4034C9.50973 12.2433 9.72582 12.1515 9.9523 12.1475C10.1788 12.1435 10.398 12.2276 10.5637 12.382L12.3587 14.1771L16.4151 11.1348C16.6006 10.9957 16.8337 10.936 17.0632 10.9688C17.2928 11.0015 17.4999 11.1242 17.639 11.3096C17.7781 11.4951 17.8378 11.7283 17.805 11.9578C17.7722 12.1873 17.6496 12.3944 17.4641 12.5335L12.8017 16.0304C12.6503 16.1438 12.4663 16.2051 12.2771 16.2052Z"
                fill="white"
            />
        </svg>
    );
};
