import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Switch, Col, Row, Menu, Dropdown, Button } from "antd";
import { MoreHorizontal } from "react-feather";

import { IDivision } from "@interfaces";
import { newsAPI, orgApi } from "@api";
import { enumNewsType } from "@configs";
import { getDivisions, selectAuth, setLoading } from "@redux";
import { useDispatch, useSelector } from "react-redux";
import { IconDrag } from "@components";
import { showErrorMessage, useNotify } from "@utils";
import { useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
interface IProps {
    item: IDivision;
    itemJson: string;
    index: number;
    newType?: enumNewsType;
}

export const ComponentRowDivision = (props: IProps) => {
    //hooks
    const { t } = useTranslation();
    const { success, error } = useNotify();
    const history = useHistory();
    const organization_id = useSelector(selectAuth).auth?.organization_data?._id;
    //props
    const { item, itemJson, newType } = props;
    const [show, setShowModal] = useState(false);
    const dispatch = useDispatch();
    const { attributes, listeners, setNodeRef, transform } = useSortable({
        id: itemJson,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
    };

    const handleDelete = async () => {
        dispatch(setLoading(true));
        try {
            const { data } = await orgApi.deleteDeivision({
                organization_id: organization_id || "",
                division_id: item._id || "",
            });
            if (data.error === "0") {
                success(data.msg);
                dispatch(getDivisions({ organization_id: organization_id || "" }));
                dispatch(setLoading(false));
            } else {
                success(data.msg);
                dispatch(setLoading(false));
            }
        } catch (err: any) {
            dispatch(setLoading(false));
        }
    };

    const handleRemove = () => {
        try {
            // callbackGetList();
            success(t("message.duplicate.success"));
        } catch (err: any) {
            if (err.response) {
                error(showErrorMessage(err.response));
            } else error(t("message.duplicate.fail"));
        }
    };

    const showModal = () => {
        setShowModal(!show);
    };

    const menu = (
        <Menu>
            <Menu.Item key={1} onClick={handleDelete}>
                <div className="duplicate-btn">{t("page.delete")}</div>
            </Menu.Item>
        </Menu>
    );
    const handleUpdateStatus = async (e: boolean) => {
        dispatch(setLoading(true));
        try {
            const { data } = await orgApi.setEnableDeivision({
                organization_id: organization_id || "",
                division_id: item._id || "",
                is_enable: e,
            });
            if (data.error === "0") {
                success(data.msg);
                dispatch(getDivisions({ organization_id: organization_id || "" }));
                dispatch(setLoading(false));
            } else {
                success(data.msg);
                dispatch(setLoading(false));
            }
        } catch (err: any) {
            dispatch(setLoading(false));
        }
    };
    return (
        <div className="table-row" ref={setNodeRef} style={style}>
            <div className="drag-icon" {...attributes} {...listeners}>
                <IconDrag />
            </div>
            <Row
                style={{ height: "100%" }}
                gutter={24}
                type="flex"
                align="middle"
                justify="space-between"
            >
                <Col className="col-item" span={8}>
                    <p>{item.division_name || t("page.empty_text")}</p>
                </Col>
                <Col className="col-item" span={8}>
                    <Switch onChange={handleUpdateStatus} checked={item.is_enable} />
                </Col>
                <Col className="option-dropdown visible-md" lg={2} md={2}>
                    <Dropdown
                        //@ts-ignore
                        getPopupContainer={(trigger) => trigger.parentNode}
                        overlay={menu}
                        trigger={["click"]}
                        placement="bottomRight"
                    >
                        <Button style={{ border: "unset" }}>
                            <MoreHorizontal />
                        </Button>
                    </Dropdown>
                </Col>
            </Row>
        </div>
    );
};
