export const GRItemIcon = () => (
    <svg
        width="123"
        height="121"
        viewBox="0 0 123 121"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M112.907 60.5509C112.907 72.3876 108.917 83.51 102.267 92.2854C98.5845 97.0814 94.1855 101.265 89.0704 104.53C80.8863 109.938 70.963 113 60.4259 113C31.4745 113.102 7.84277 89.6324 7.84277 60.5509C7.84277 31.5714 31.3722 8 60.4259 8C70.963 8 80.784 11.0612 89.0704 16.4694C94.1855 19.7347 98.5845 23.9183 102.267 28.7142C108.917 37.5917 112.907 48.5101 112.907 60.5509Z"
            fill="#F7F7F7"
        />
        <path
            d="M76.6907 33.6122H73.2124C72.8032 33.6122 72.5986 33.4081 72.5986 32.9999C72.5986 32.5918 72.8032 32.3877 73.2124 32.3877H76.6907C77.0999 32.3877 77.3045 32.5918 77.3045 32.9999C77.3045 33.4081 77.0999 33.6122 76.6907 33.6122Z"
            fill="url(#paint0_linear_8408_550577)"
        />
        <path
            d="M1.5 100.652H121.5"
            stroke="#A5A5A5"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M106.769 31.0611V95.6527C106.769 96.469 106.564 97.1833 106.257 97.7956C106.155 97.9996 105.951 98.3058 105.746 98.5098C105.644 98.6119 105.541 98.7139 105.439 98.816C104.621 99.6323 103.495 100.143 102.268 100.143H82.5234L78.7383 26.7754H102.472C104.825 26.7754 106.769 28.6121 106.769 31.0611Z"
            fill="url(#paint1_linear_8408_550577)"
        />
        <path
            d="M106.769 31.0611V95.6527C106.769 96.469 106.564 97.1833 106.257 97.7956C106.155 97.9996 105.951 98.3058 105.746 98.5098C105.644 98.6119 105.541 98.7139 105.439 98.816C104.621 99.6323 103.495 100.143 102.268 100.143H82.5234L78.7383 26.7754H102.472C104.825 26.7754 106.769 28.6121 106.769 31.0611Z"
            fill="#C0C0C0"
        />
        <path
            d="M82.6249 30.6529V100.04H18.7886C16.3334 100.04 14.3896 98.1017 14.3896 95.6527V31.0611C14.3896 28.7142 16.3334 26.7754 18.6863 26.7754H78.7375C80.8858 26.7754 82.6249 28.5101 82.6249 30.6529Z"
            fill="#E1E1E1"
        />
        <path
            d="M42.0112 81.0608H21.5508C21.0393 81.0608 20.7324 80.5506 20.7324 79.9384C20.7324 79.3261 21.1416 78.8159 21.5508 78.8159H42.0112C42.5227 78.8159 42.8296 79.3261 42.8296 79.9384C42.8296 80.5506 42.5227 81.0608 42.0112 81.0608Z"
            fill="white"
        />
        <path
            d="M42.0112 86.5708H21.5508C21.0393 86.5708 20.7324 86.0606 20.7324 85.4484C20.7324 84.8361 21.1416 84.3259 21.5508 84.3259H42.0112C42.5227 84.3259 42.8296 84.8361 42.8296 85.4484C42.8296 86.0606 42.5227 86.5708 42.0112 86.5708Z"
            fill="white"
        />
        <path
            d="M30.9626 91.9793H21.5508C21.0393 91.9793 20.7324 91.4691 20.7324 90.8568C20.7324 90.2446 21.1416 89.7344 21.5508 89.7344H30.9626C31.4741 89.7344 31.781 90.2446 31.781 90.8568C31.8833 91.4691 31.4741 91.9793 30.9626 91.9793Z"
            fill="white"
        />
        <path
            d="M55.6178 26.7754V51.2651C55.6178 51.7753 55.3109 52.1835 54.9017 52.4896L52.2418 53.7141C51.9349 53.8161 51.5257 53.8161 51.2188 53.612L47.1267 50.4488C46.8198 50.2447 46.4106 50.1427 46.1037 50.3467L43.9554 51.4692C43.4439 51.7753 42.8301 51.3671 42.8301 50.8569V26.7754H55.6178Z"
            fill="#A5A5A5"
        />
        <path
            d="M120.989 27.8977C120.989 29.2242 120.784 30.6528 120.58 31.8773C120.375 32.6936 120.17 33.4079 119.864 34.1221C118.124 38.6119 114.544 42.2854 110.043 44.0201C109.633 44.1221 109.429 44.3262 109.02 44.4282C107.28 44.9384 105.541 45.2446 103.7 45.2446C94.0835 45.2446 86.3086 37.4895 86.3086 27.8977C86.3086 18.3059 94.0835 10.5508 103.7 10.5508C113.214 10.4487 120.989 18.3059 120.989 27.8977Z"
            fill="#A5A5A5"
        />
        <path
            d="M101.858 34.6325C101.347 34.6325 100.733 34.4284 100.426 34.0203L96.4361 30.0407C95.6177 29.2244 95.6177 27.9999 96.4361 27.1835C97.2545 26.3672 98.4821 26.3672 99.3005 27.1835L101.858 29.7346L109.121 22.4897C109.94 21.6734 111.168 21.6734 111.986 22.4897C112.804 23.306 112.804 24.5305 111.986 25.3468L103.29 34.0203C102.881 34.4284 102.37 34.6325 101.858 34.6325Z"
            fill="white"
        />
        <path
            d="M78.7383 26.7753C80.3751 26.4692 82.2165 27.4896 82.8304 29.1223C83.035 29.5304 83.1373 29.9386 83.1373 30.4488C83.1373 30.8569 83.1373 31.2651 83.1373 31.6733V34.0202V43.51L83.035 62.3875L82.8304 81.2649L82.5234 100.142L82.3188 81.1629L82.1142 62.2854L82.0119 43.4079V33.9182V31.5712C82.0119 30.7549 82.1142 30.0406 81.8073 29.4284C81.5004 28.2039 80.2728 27.0814 78.7383 26.7753Z"
            fill="#A5A5A5"
        />
        <path
            d="M58.5833 80.7546C58.3787 81.0608 58.2764 81.4689 58.2764 81.7751C58.2764 82.6934 58.9925 83.4077 59.9132 83.4077H62.3684V91.4689C62.3684 91.775 62.573 91.9791 62.8799 91.9791H68.8135H69.8365C70.1434 91.9791 70.348 91.775 70.348 91.4689V83.5097H72.5986C72.9055 83.5097 73.3147 83.3057 73.6216 83.2036C74.3377 82.6934 74.5424 81.571 73.9285 80.8567L67.4835 72.7955C67.2789 72.5914 67.2789 72.4894 67.1766 72.4894C66.4605 71.9792 65.4375 72.1832 64.8237 72.7955L58.5833 80.7546Z"
            fill="#A5A5A5"
        />
        <defs>
            <linearGradient
                id="paint0_linear_8408_550577"
                x1="72.6987"
                y1="32.9999"
                x2="77.325"
                y2="32.9999"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#83A6FF" />
                <stop offset="1" stopColor="#5A78FF" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_8408_550577"
                x1="60.1896"
                y1="64.6824"
                x2="93.4961"
                y2="63.3332"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#3F68D1" />
                <stop offset="1" stopColor="#4D6CFF" />
            </linearGradient>
        </defs>
    </svg>
);
