import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Col, Row, Menu, Dropdown, Button } from "antd";
import { MoreHorizontal } from "react-feather";
import { ICancelPr, IDataexample, IGetStoresBindingDropdownExample } from "@interfaces";
import { PATH_PURCHASE_REQUEST, PATH_TRANSFER_REQUEST } from "@configs";
import { IconDrag } from "@components";
import { theme, useNotify } from "@utils";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useHistory } from "react-router";
import { DoneIcon } from "../icon/DoneIcon";
import { POAPI } from "@api";
import { ArrowRightOutlined, CheckOutlined, PlusOutlined } from "@ant-design/icons";
import styled from "styled-components";

interface IProps {
    data: IDataexample | any;
    itemJson: string;
    index: number;

    checkInvList: any[];
    delInv: (value: number) => void;
    // storeList: IGetStoresBindingDropdownExample[];
    // requestType?: string;
    // Refresh: number
    // setRefresh: (value: number) => void;
}

export const ComponentRowTrInv = (props: IProps) => {
    //hooks
    const { t } = useTranslation();
    const { success, error } = useNotify();
    const history = useHistory();
    //props
    const { data,
        itemJson,
        checkInvList,
        delInv,
        index,
        // storeList,
        // requestType,
        // Refresh,
        // setRefresh
    } = props;
    const { attributes, listeners, setNodeRef, transform } = useSortable({
        id: itemJson,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
    };


    // const renderStore = () => {
    //     const findStore = storeList.find((obj) => obj._id === data?.store_id);
    //     return <>{findStore?.store_name || "-"}</>;
    // };
    const renderInv = () => {

        let check = checkInvList?.some((x) => x?.consumable_id === data?.consumable_id);
        return check;
    };
    return (
        <CustomStyles>
            <div className="table-element" ref={setNodeRef} style={style}>

                <Row
                    style={{ height: "100%" }}
                    gutter={24}
                    type="flex"
                    align="middle"
                    justify="space-between"
                >
                    <Col className="col-item" span={10}>
                        <b>
                            <p>{data?.name || t("page.empty_text")}</p>
                        </b>
                    </Col>
                    <Col className="col-item" span={6}>
                        <p>{data?.on_stork} {data?.unit}</p>
                    </Col>
                    <Col className="col-item" span={8}>
                        <div className="add-btn">
                            <Button onClick={() => delInv(index)} className={`${renderInv() ? "selected" : "not-selected"}`} >
                                {t("page.remove")}
                            </Button>
                        </div>

                    </Col>
                </Row>

            </div>
        </CustomStyles>
    );
};


const CustomStyles = styled.div`
    background-color: #F7F7F8;
    padding: 5px 10px;
    margin: 5px;
    .add-btn {
        text-align: right;
        .selected {
            color: #6CD14E;
            background-color: #6CD14E33;
        
        }
    }

`;