import { useEffect, memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Row, Col, Form, Select, Pagination } from "antd";
import styled from "styled-components";
import {
    IGetImportExcelLogsExample,
    IGetLastOneImportExcelLogExample,
    IPaginationNext,
} from "@interfaces";
import {
    StyledSubmitButton,
    StyledCard,
    ComponentContactSortIcon,
    ComponentEmptyData,
    ComponentRowExcelHistory,
} from "@components";
import { selectAuth, selectContact } from "@redux";
import { useNotify } from "@utils";
import {
    INITIAL_LIST_PARAMS,
    PAGE_START,
    PAGINATION,
    enumContactSortFields,
    enumSortBy,
} from "@configs";
import { importExcelAPI } from "@api";
import { FolderIcon } from "src/Components/icon/FolderIcon";
import { ModuleUploadExcel } from "../form";
import { ComponentImportTableModal } from "src/Components/ImportTable";
import { ComponentImportTableStatusModal } from "src/Components/ImportTable/StatusModal";

const INITAL_PAGE = {
    xpage: PAGE_START,
    xlimit: PAGINATION,
};

export const ModuleImportTableForm = memo(() => {
    const { t } = useTranslation();
    const { error, success } = useNotify();
    const organization_id = useSelector(selectAuth).userInfo?.merchant?._id;
    const [tab, setTab] = useState<string>("import");
    const [files, setFiles] = useState<string>("");
    const search_params = useSelector(selectContact).paramsContact;
    const [params, setParams] = useState<any>(search_params);
    const [excelHistory, setExcelHistory] = useState<IGetImportExcelLogsExample[]>([]);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showStatusModal, setShowStatusModal] = useState<boolean>(false);
    const [selectionBox, setSelectionBox] = useState<string>("");
    const [statusInfo, setStatusInfo] = useState<IGetLastOneImportExcelLogExample>();
    const [total, setTotal] = useState<number>(0);
    const [pagination, setPagination] = useState<IPaginationNext>(INITAL_PAGE);

    useEffect(() => {
        getHistory();
    }, [pagination]);

    const tabHandler = (value) => {
        setTab(value);
    };
    const { Option } = Select;
    const handleGetFile = (files: string) => {
        setFiles(files);
        setShowModal(true);
    };
    const handleSort = (sortField: enumContactSortFields, sortBy: enumSortBy) => {
        const newSortParams = { ...params, sortField, sortBy };
        setParams(newSortParams);
    };
    const getHistory = async () => {
        try {
            const get = await importExcelAPI.getImportExcelLogs({
                organization_id: organization_id,
                xlimit: 1000,
                xpage: pagination.xpage,
            });
            if (get.data.error === "0") {
                setExcelHistory(get.data.result);
                setTotal(get.data.total);
            }
        } catch (err: any) {
            error(err.message);
        }
    };
    const handleCloseStatusModal = () => {
        setShowStatusModal(false);
        setTab("history");
    };
    const handleStatus = () => {
        setShowModal(false);
        getLastOneImportExcelLog();
        getHistory();
        setTimeout(() => {
            setShowStatusModal(true);
        }, 400);
    };
    const getLastOneImportExcelLog = async () => {
        try {
            const get = await importExcelAPI.getLastOneImportExcelLog({
                organization_id: organization_id,
            });
            if (get.data.error === "0") {
                setStatusInfo(get.data.result);
            }
        } catch (err: any) {
            error(err.message);
        }
    };
    const importNow = () => {
        setShowModal(true);
        getLastOneImportExcelLog();
    };
    const handlePagination = (page) => {
        setPagination({ ...pagination, xpage: page });
    };

    return (
        <div className="form-wrap">
            <Form>
                <div className="page-body full-body">
                    <div className="card-wrap">
                        <StyledCard maxWidth="100%">
                            <StyledCustom>
                                <div className="topTitle">
                                    <p className="title1">{t("page.import_table")}</p>
                                    <p className="body4">
                                        Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry.
                                    </p>
                                </div>
                                <Row gutter={32} style={{ marginBottom: 20 }}>
                                    <Col md={8}>
                                        <div className="buttonTabsRow">
                                            <p
                                                className={`body1 ${tab === "import" && "active"}`}
                                                onClick={() => tabHandler("import")}
                                            >
                                                {t("page.import_table")}
                                            </p>
                                            <p
                                                className={`body1 ${tab === "history" && "active"}`}
                                                onClick={() => {
                                                    tabHandler("history");
                                                }}
                                            >
                                                {t("page.history")}
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                                {tab === "import" && (
                                    <>
                                        <Row gutter={32}>
                                            <Col span={12}>
                                                <div className="label">{t("page.import_to")}</div>
                                                <Select
                                                    showSearch
                                                    filterOption={(input, option) =>
                                                        //@ts-ignore
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    style={{ width: "100%", height: 48 }}
                                                    placeholder={t("page.category")}
                                                    onChange={(value: string) => {
                                                        setSelectionBox(value);
                                                    }}
                                                    defaultActiveFirstOption={true}
                                                    value={selectionBox}
                                                >
                                                    <Option key={""} value={""} disabled>
                                                        {t("page.select_import_option")}
                                                    </Option>
                                                    <Option
                                                        key={"product_variant_selling_inventory"}
                                                        value={"product_variant_selling_inventory"}
                                                    >
                                                        {t(
                                                            "page.product_variant_selling_inventory"
                                                        )}
                                                    </Option>
                                                    <Option
                                                        key={"non_selling_inventory"}
                                                        value={"non_selling_inventory"}
                                                    >
                                                        {t("page.non_selling_inventory")}
                                                    </Option>
                                                    <Option
                                                        key={
                                                            "assign_non_selling_inventory_to_variant"
                                                        }
                                                        value={
                                                            "assign_non_selling_inventory_to_variant"
                                                        }
                                                    >
                                                        {t(
                                                            "page.assign_non_selling_inventory_to_variant"
                                                        )}
                                                    </Option>
                                                    <Option
                                                        key={"assing_bom_inventory_to_inventory"}
                                                        value={"assing_bom_inventory_to_inventory"}
                                                    >
                                                        {t(
                                                            "page.assing_bom_inventory_to_inventory"
                                                        )}
                                                    </Option>
                                                    <Option
                                                        key={"assign_addon_option_to_product"}
                                                        value={"assign_addon_option_to_product"}
                                                    >
                                                        {t("page.assign_addon_option_to_product")}
                                                    </Option>
                                                </Select>
                                            </Col>
                                        </Row>
                                        <Row gutter={32}>
                                            <Col span={12}>
                                                <div className="grayTemplateBox">
                                                    <p className="body1 titleTemplate">
                                                        {t("page.template_table_file_csv")}
                                                    </p>
                                                    <p className="templateRow">
                                                        <div className="leftSide">
                                                            <FolderIcon />
                                                            <div className="templateInfo">
                                                                <p className="body4">
                                                                    Contact List 1
                                                                </p>
                                                                <p className="description">23KB.</p>
                                                            </div>
                                                        </div>
                                                        {selectionBox !== "" && (
                                                            <a
                                                                href={
                                                                    selectionBox ===
                                                                        "product_variant_selling_inventory"
                                                                        ? "https://uat-api-store-admin.rocket-tech.app/download/ImportProductVariant.xlsx"
                                                                        : selectionBox ===
                                                                            "non_selling_inventory"
                                                                            ? "https://uat-api-store-admin.rocket-tech.app/download/ImportNonSellingInventory.xlsx"
                                                                            : selectionBox ===
                                                                                "assign_non_selling_inventory_to_variant"
                                                                                ? "https://uat-api-store-admin.rocket-tech.app/download/ImportAssignNonSellingInventoryToVariant.xlsx"
                                                                                : selectionBox ===
                                                                                    "assing_bom_inventory_to_inventory"
                                                                                    ? "https://uat-api-store-admin.rocket-tech.app/download/ImportAssignBomInventoryToMainInventory.xlsx"
                                                                                    : "https://uat-api-store-admin.rocket-tech.app/download/ImportAssignAddonToProduct.xlsx"
                                                                }
                                                            >
                                                                <p className="body3 downloadBtn">
                                                                    Download .CSV
                                                                </p>
                                                            </a>
                                                        )}
                                                    </p>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row gutter={32} className="importSection">
                                            <Col span={12}>
                                                <p className="title2">
                                                    {t("page.import_to_create_product_here")}
                                                </p>
                                                <ModuleUploadExcel
                                                    files={files}
                                                    handleGetFile={handleGetFile}
                                                    multiple={false}
                                                    showTitle={false}
                                                    type={selectionBox}
                                                />
                                            </Col>
                                        </Row>
                                        <Row gutter={32} style={{ marginTop: 20 }}>
                                            <Col span={12}>
                                                <StyledSubmitButton
                                                    type="default"
                                                    text={t("page.import_your_table_now")}
                                                    htmlType="submit"
                                                    onClick={selectionBox !== "" ? importNow : null}
                                                />
                                            </Col>
                                        </Row>
                                    </>
                                )}
                                {tab === "history" && (
                                    <>
                                        <Row
                                            gutter={24}
                                            type="flex"
                                            justify="space-between"
                                            align="middle"
                                            className="header-row"
                                        >
                                            <Col className="col-item" span={3}>
                                                <p>{t("page.date_import")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.FULL_NAME}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.FULL_NAME}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={3}>
                                                <p>{t("page.table_name")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.POINTS}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.POINTS}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={3}>
                                                <p>{t("page.user_account")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.CREATED_AT}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.CREATED_AT}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={5}>
                                                <p>{t("page.file_name")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.CREATED_AT}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.CREATED_AT}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={5}>
                                                <p>{t("page.fail_reason")}</p>
                                            </Col>
                                            <Col
                                                className="col-item"
                                                span={2}
                                                style={{ placeContent: "center" }}
                                            >
                                                <p>{t("page.status")}</p>
                                            </Col>
                                        </Row>
                                        {excelHistory && excelHistory.length > 0 ? (
                                            <div className="data-table">
                                                {excelHistory.map((item, index) => (
                                                    <ComponentRowExcelHistory
                                                        key={index}
                                                        data={item}
                                                    />
                                                ))}
                                            </div>
                                        ) : (
                                            <ComponentEmptyData />
                                        )}
                                        <div className="page-bottom">
                                            {total > 0 && (
                                                <div className="pagination">
                                                    <Pagination
                                                        defaultCurrent={
                                                            INITIAL_LIST_PARAMS.page || 1
                                                        }
                                                        pageSize={PAGINATION}
                                                        total={total}
                                                        onChange={handlePagination}
                                                        current={pagination.xpage}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </>
                                )}
                                <ComponentImportTableModal
                                    showPopup={showModal}
                                    handleStatus={handleStatus}
                                />
                                {statusInfo && (
                                    <ComponentImportTableStatusModal
                                        showPopup={showStatusModal}
                                        handleClose={handleCloseStatusModal}
                                        status={statusInfo}
                                    />
                                )}
                            </StyledCustom>
                        </StyledCard>
                    </div>
                </div>
            </Form>
        </div>
    );
});

const StyledCustom = styled.div`
    p {
        margin: 0;
    }
    .secondTitle {
        font-size: 20px;
    }
    .topTitle {
        p {
            margin: 0;
        }
        color: #000 !important;
        .title1 {
            margin-bottom: 4px;
        }
        margin-bottom: 24px;
    }
    .buttonTabsRow {
        display: flex;
        flex-direction: row;
        border: 0.5px solid rgba(165, 165, 165, 0.64);
        background: #fff;
        border-radius: 8px;
        padding: 4px;
        place-items: center;
        p {
            margin: 0;
            width: 50%;
            text-align: center;
            cursor: pointer;
            font-weight: 700;
            color: #000;
        }
        .active {
            background: #000;
            border-radius: 8px;
            padding: 8px 16px;
            color: #fff;
        }
    }
    .grayTemplateBox {
        margin-top: 20px;
        background: #f7f7f8;
        border-radius: 8px;

        p {
            color: #000;
            margin: 0;
        }
        .titleTemplate {
            padding: 20px 14px 12px 14px;
            margin: 0;
            font-weight: 700;
            border-bottom: 1px solid #e1e1e1;
        }
        .templateRow {
            display: flex;
            justify-content: space-between;
            padding: 16px 14px;
        }
        .templateInfo .description {
            color: #646464;
        }
        .downloadBtn {
            border: 1px solid #a5a5a5;
            border-radius: 4px;
            color: #000;
            display: flex;
            place-items: center;
            padding: 8px 20px;
            background: #fff;
            cursor: pointer;
        }
        .leftSide {
            display: flex;
            gap: 8px;
        }
    }
    .importSection {
        margin-top: 20px;
        p {
            color: #000;
            margin: 0;
        }
        .title2 {
            margin-bottom: 20px;
        }
    }
    button[type="submit"] {
        width: 100%;
        background: #f22f46 !important;
    }
    button[type="submit"]:hover {
        box-shadow: 0px 4px 12px rgb(224 2 2 / 50%);
    }
    .header-row {
        .col-item {
            display: flex;
            place-items: center;
            gap: 8px;
            p {
                font-size: 12px;
                font-style: normal;
                font-weight: 800;
                line-height: normal;
                color: #a5a5a5;
            }
            .filter-button {
                display: flex;
                flex-direction: column;
            }
        }
    }
    .ant-row-flex {
        padding: 12px 24px;
        border-bottom: 0.5px solid #e1e1e1;
    }
    .ant-row-flex:hover {
        background: rgb(225 225 225 / 30%);
    }
    .status {
        border-radius: 40px;
        padding: 2px 12px;
        font-size: 12px;
        font-weight: 800;
        display: flex;
        place-content: center;
        width: fit-content;
        cursor: pointer;
    }
    .page-bottom {
        display: flex;
        place-content: center;
        margin-top: 20px;
    }
`;
