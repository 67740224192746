import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Switch, Col, Row, Avatar, Menu, Dropdown, Button } from "antd";
import { MoreHorizontal } from "react-feather";

import { IConsumableExample, IDataexample, INewsCategory } from "@interfaces";
import {
    enumNewsType,
    PATH_PRODUCT_CREATE,
    PATH_SERVICES_CREATE,
    PATH_STOCK_LIST,
    PATH_STORE,
    PATH_STORE_CREATE,
} from "@configs";
import { selectAuth, store } from "@redux";
import { useSelector } from "react-redux";
import { IconDrag, StyledTableButtonTop } from "@components";
import { useNotify } from "@utils";
import React, { useEffect, useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import styled from "styled-components";
import axios from "axios";
import { consumableAPI, storeAPI } from "@api";
import { useHistory } from "react-router";
import { ProductEditQTYDrawer } from "../product/ProductEditQTYDrawer";
import { ComponentEditStockDrawer } from "../stock";

interface IProps {
    data: any;
    itemJson: string;
    handleEdit?: (status: boolean, data: IDataexample) => void;
    callbackGetList: () => void;
    index: number;
    newType?: enumNewsType;
    categories: INewsCategory[];
    stores: IConsumableExample[];
    selectedStore?: string;
    Refresh?: boolean;
    setRefresh?: (change: boolean) => void;
}

export const ComponentRowEditInventoryQTY = (props: IProps) => {
    const history = useHistory();

    //hooks
    const { t } = useTranslation();
    const { success, error } = useNotify();
    const organization_id = useSelector(selectAuth).auth?.organization_data?._id;
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const userPermissionType = useSelector(selectAuth).userInfo?.storeIds;
    //props
    const {
        data,
        handleEdit,
        callbackGetList,
        itemJson,
        newType,
        stores,
        index,
        selectedStore,
        Refresh,
        setRefresh,
    } = props;
    const [showModal, setShowModal] = useState(false);
    // const history = useHistory();

    const { attributes, listeners, setNodeRef, transform } = useSortable({
        id: itemJson,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
    };
    const handleDelete = async (item: any) => {
        try {
            const res = await consumableAPI.deleteConsumable({
                organization_id: organization_id,
                consumable_id: item,
            });
            if (res.data.error === "0") {
                callbackGetList();
                success(t("message.delete.success"));
            } else {
                error(res.data.msg);
            }
        } catch (err: any) {
            if (err) {
                error(err.msg);
            } else error(t("message.delete.fail"));
        }
    };

    const menu = (
        <Menu>
            <Menu.Item key={1} onClick={() => handleDelete(data._id)}>
                <div className="duplicate-btn">{t("page.delete")}</div>
            </Menu.Item>
        </Menu>
    );

    return (
        <StylesEditInventoryQTY>
            <div className="table-element" ref={setNodeRef} style={style}>
                <div className="drag-icon hide" {...attributes} {...listeners}>
                    <IconDrag />
                </div>
                <div className="table-row">
                    <Row
                        style={{ height: "100%" }}
                        gutter={24}
                        type="flex"
                        align="middle"
                        justify="space-between"
                    >
                        <Col className="col-item" span={8}>
                            {userPermissionType?.length === 0 ? (
                                // <Link to={`${PATH_STOCK_LIST}/${data._id}`}>
                                <p className="news-title">
                                    {stores[index]?.name || t("page.empty_text")}
                                </p>
                            ) : (
                                // </Link>
                                <p className="news-title">
                                    {stores[index]?.name || t("page.empty_text")}
                                </p>
                            )}
                        </Col>
                        <Col
                            className="col-item"
                            span={selectedStore === "" ? 8 : 4}
                            style={{ placeContent: "center" }}
                        >
                            <p>
                                {stores[index]?.formula[0]?.restock_unit_name ||
                                    t("page.empty_text")}
                            </p>
                        </Col>
                        {userPermissionType?.length === 0 && selectedStore !== "" && (
                            <Col className="col-item" span={4} style={{ placeContent: "center" }}>
                                <StyledTableButtonTop
                                    type="danger"
                                    size="default"
                                    text={t("page.edit")}
                                    onClick={() => {
                                        setShowModal(true);
                                    }}
                                    className="add-btn"
                                />
                            </Col>
                        )}
                        {/* <Col
                            className="col-item"
                            span={selectedStore === "" ? 8 : 4}
                            style={{ placeContent: "end" }}
                        >
                            <Dropdown
                                //@ts-ignore
                                getPopupContainer={(trigger) => trigger.parentNode}
                                overlay={menu}
                                trigger={["click"]}
                                placement="bottomRight"
                            >
                                <Button style={{ border: "unset" }}>
                                    <MoreHorizontal />
                                </Button>
                            </Dropdown>
                        </Col> */}
                    </Row>
                </div>
            </div>
            <ComponentEditStockDrawer
                showModal={showModal}
                editItem={stores[index]?.consumable_data}
                stock={stores[index]}
                setRefresh={setRefresh}
                Refresh={Refresh}
                Unit={stores[index]?.unit_name}
                cost={stores[index]?.cost || 0}
                handleClose={() => setShowModal(!showModal)}
            />
        </StylesEditInventoryQTY>
    );
};
export const StylesEditInventoryQTY = styled.div`
    .table-row {
        height: 60px;
    }
`;
