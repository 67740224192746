import { useState, useEffect } from "react";
import {
    ComponentContactSortIcon,
    StyledTable,
    ComponentEmptyData,
    StyledTableButtonTop,
    ComponentTableSearch,
    ComponentRowService,
    ComponentQuickEditModal,
} from "@components";
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    TouchSensor,
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    rectSortingStrategy,
    //@ts-ignore
} from "@dnd-kit/sortable";
import { Row, Col, Pagination } from "antd";
import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";
import {
    enumSortBy,
    enumContactSortFields,
    PAGINATION,
    PAGE_START,
    enumNewsStatus,
    INITIAL_LIST_PARAMS,
    PATH_PRODUCT_CREATE,
} from "@configs";
import { IPaginationNext, IDataexample, IProduct } from "@interfaces";
import { useDispatch, useSelector } from "react-redux";
import { getCategories, selectAuth, selectContact, selectOrg, setLoading } from "@redux";
import { useNotify } from "@utils";
import { useHistory } from "react-router";
import { productAPI } from "@api";

const INITAL_PAGE = {
    xpage: PAGE_START,
    xlimit: PAGINATION,
};

export const PageProductList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const search_params = useSelector(selectContact).paramsContact;
    const [params, setParams] = useState(search_params);
    const org_id = useSelector(selectAuth).userInfo?.merchant._id;
    const orgCode = useSelector(selectAuth).userInfo?.merchant.subCode;
    const categories = useSelector(selectOrg).category;
    const [textSearch, setTextSearch] = useState<string>("");
    const [searchDate, setSearchDate] = useState<string[]>([]);
    const [catSearch, setCatSearch] = useState<string>("");
    const [showModal, setShowModal] = useState<boolean>(false);
    const [tempCatID, setTempCatID] = useState<string>("");
    const [pagination, setPagination] = useState<IPaginationNext>(INITAL_PAGE);
    const [services, setServices] = useState<IDataexample[]>([]);
    const [total, setTotal] = useState<number>(0);
    const { success, error } = useNotify();
    const history = useHistory();
    const [Refresh, setRefresh] = useState<any>(0);
    const [tempRefresh, setTempRefresh] = useState(0);
    const [selectedProduct, setSelectedProduct] = useState<IProduct>();

    let tempServices: any = [];
    useEffect(() => {
        fetchNews();
    }, [Refresh, textSearch, tempCatID, pagination]);

    useEffect(() => {
        handleSort;
    }, [tempRefresh]);

    const fetchNews = async () => {
        dispatch(setLoading(true));
        await dispatch(getCategories({ organization_code: orgCode }));
        try {
            const res = await productAPI.getList({
                xpage: pagination.xpage,
                xlimit: pagination.xlimit,
                text_search: textSearch,
                category_id: tempCatID || "",
                organization_code: orgCode,
            });
            setTotal(res.data.total);
            tempServices = res.data.result;
            for (let i = 0; i < tempServices.length; i++) {
                tempServices[i].variant = tempServices[i].variant_list.length;
                tempServices[i].price = tempServices[i].variant_list[0].price;
                tempServices[i].raw_price = tempServices[i].variant_list[0].raw_price;
                for (let j = 0; j < categories.length; j++) {
                    if (tempServices[i].category_id === categories[j]._id) {
                        tempServices[i].category = categories[j].category_name;
                    }
                    if (catSearch.toLowerCase() === categories[j].category_name.toLowerCase()) {
                        setTempCatID(categories[j]._id);
                    }
                    for (let k = 0; k < categories[j].sub_category.length; k++) {
                        if (tempServices[i].sub_category_id === categories[j].sub_category[k]._id) {
                            tempServices[i].sub_cat = categories[j].sub_category[k].name;
                        }
                    }
                }
            }
            setServices(tempServices);
        } catch (err: any) {
            error(err.msg);
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleSort = (sortField: enumContactSortFields, sortBy: enumSortBy) => {
        const newSortParams = { ...params, sortField, sortBy };
        setParams(newSortParams);
        setServices(services.reverse());
        setTempRefresh(2);
    };

    const callbackGetList = () => {
        fetchNews();
    };
    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            const swapItem = (items: IDataexample[]) => {
                const oldIndex = items.findIndex((item) => JSON.stringify(item) === active.id);
                const newIndex = items.findIndex((item) => JSON.stringify(item) === over.id);
                return arrayMove(items, oldIndex, newIndex);
            };
            setServices(swapItem);
        }
    };
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
        useSensor(TouchSensor)
    );
    const handleSearchCategory = (value) => {
        setCatSearch(value);
        let findCateId = categories.find((cItem) => cItem.category_name === value);
        setTempCatID(findCateId?._id || "");
        setRefresh(0);
    };
    const onChangeDateRange = (dates, dateStrings: [string, string]) => {
        setSearchDate(dateStrings);
    };
    const handlePagination = (page) => {
        setPagination({ ...pagination, xpage: page });
    };
    const updateService = async (data, item) => {
        dispatch(setLoading(true));
        try {
            item.is_enable = !item.is_enable;
            const body = {
                organization_id: item.organization_id,
                product_id: item._id,
                is_enable: item.is_enable,
            };
            await success("Product status has been updated");
        } catch (err: any) {
            error(err.msg);
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };
    const handleCloseModal = () => {
        setShowModal(false);
    };
    const handleQuickEdit = (item: IProduct) => {
        setShowModal(true);
        setSelectedProduct(item);
    };

    return (
        <StyledTable>
            <div className="page-layout">
                <div className="page-header-layout">
                    <div className="page-header">
                        <div>
                            <h3>{t("page.product_lists")}</h3>
                        </div>
                        <div className="flex-header">
                            <StyledTableButtonTop
                                type="danger"
                                size="default"
                                text={t("page.add_product")}
                                onClick={() =>
                                    history.push({
                                        pathname: PATH_PRODUCT_CREATE,
                                    })
                                }
                                className="add-btn"
                                icon="plus"
                            />
                        </div>
                    </div>
                    <div className="search-layout">
                        <ComponentTableSearch
                            setTextSearch={setTextSearch}
                            handleSearchCategory={handleSearchCategory}
                            categories={categories}
                            onChangeDateRange={onChangeDateRange}
                            total={total}
                            isRangePicker={true}
                        />
                    </div>
                </div>
                <div className="page-table-layout">
                    <div className="table">
                        <ScrollContainer vertical={false}>
                            <div className="table_ground">
                                <div className="table-body">
                                    <div className="table-header">
                                        <Row
                                            gutter={24}
                                            type="flex"
                                            justify="space-between"
                                            align="middle"
                                            className="header-row"
                                        >
                                            <Col className="col-item" span={8}>
                                                <p>{t("page.product_name")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.FULL_NAME}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.FULL_NAME}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={6}>
                                                <p>{t("page.category")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.POINTS}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.POINTS}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={6}>
                                                <p>{t("page.amount_variants")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.CREATED_AT}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.CREATED_AT}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={4}>
                                                <p>{t("page.manage")}</p>
                                            </Col>
                                        </Row>
                                    </div>
                                    <DndContext
                                        sensors={sensors}
                                        collisionDetection={closestCenter}
                                        onDragEnd={handleDragEnd}
                                    >
                                        <SortableContext
                                            items={services.map((item) => JSON.stringify(item))}
                                            strategy={rectSortingStrategy}
                                        >
                                            {services && services.length > 0 ? (
                                                <div className="data-table">
                                                    {services.map((item, index) => (
                                                        <ComponentRowService
                                                            key={index}
                                                            data={item}
                                                            itemJson={JSON.stringify(item)}
                                                            categories={categories}
                                                            handleEdit={updateService}
                                                            callbackGetList={callbackGetList}
                                                            index={index}
                                                            handleQuickEdit={handleQuickEdit}
                                                        />
                                                    ))}
                                                    <div
                                                        style={{ width: "100%", height: 10 }}
                                                    ></div>
                                                </div>
                                            ) : (
                                                <ComponentEmptyData />
                                            )}
                                        </SortableContext>
                                    </DndContext>
                                </div>
                            </div>
                        </ScrollContainer>
                    </div>
                </div>
                {selectedProduct && (
                    <ComponentQuickEditModal
                        showPopup={showModal}
                        handleClose={handleCloseModal}
                        categories={categories}
                        selectedProduct={selectedProduct}
                        setRefresh={setRefresh}
                        Refresh={Refresh}
                    />
                )}
                <div className="page-bottom">
                    {total > 0 && (
                        <div className="pagination">
                            <Pagination
                                defaultCurrent={INITIAL_LIST_PARAMS.page || 1}
                                pageSize={PAGINATION}
                                total={total}
                                onChange={handlePagination}
                                current={pagination.xpage}
                            />
                        </div>
                    )}
                </div>
            </div>
        </StyledTable>
    );
};
