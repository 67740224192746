import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from ".";
interface IVariant {
    store_code: string;
    qty: number;
}
interface IState {
    variant_list: IVariant[];
}

const initialState: IState = {
    variant_list: [],
};

const productSlice = createSlice({
    name: "product",
    initialState: initialState,
    reducers: {
        addVariant: (state, action: PayloadAction<IVariant[]>) => {
            state.variant_list = action.payload;
        },
    },
});

export const { addVariant } = productSlice.actions;

export const selectProduct = (state: RootState) => state.product;

export default productSlice.reducer;
