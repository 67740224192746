import { ArrowRightOutlined, ShopOutlined } from "@ant-design/icons";
import { POAPI, consumableAPI, storeAPI } from "@api";
import { IconMinusCircle, StyledSubmitButton } from "@components";
import { PATH_TRANSFER_REQUEST_CREATE } from "@configs";
import { ICancelPr, ICancelTr, IConsumableExample, IConsumableStores, IConsumeListPayload } from "@interfaces";
import { selectAuth } from "@redux";
import { theme, useNotify } from "@utils";
import { Button, Col, Icon, Input, Modal, Radio, Row, Select, Tabs } from "antd";
import Table, { ColumnProps } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { ArrowRight } from "react-feather";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";

const { Option } = Select;

interface IProps {
    data: any;
    showModal: boolean;
    handleClose: () => void;
    Refresh: number;
    setRefresh: (value: number) => void;
}

export const TRDetailModal = (props: IProps) => {
    const { data, showModal, handleClose, Refresh, setRefresh } = props;
    const { t } = useTranslation();
    const { TabPane } = Tabs;
    const { success, error } = useNotify();
    const org_id = useSelector(selectAuth).userInfo?.merchant?._id;
    const [stores, setStores] = useState<any[]>([]);


    const history = useHistory();
    const cancelTr = async () => {
        console.log('data', data);
        let payload: ICancelTr = {
            organization_id: data.organization_id,
            ttr_id: data._id,
            store_id: data?.store_id,
        };
        const res = await POAPI.cancelTr(payload);
        if (res.data.error === "0") {
            success(res.data.msg);
            setRefresh(Refresh + 1);
            handleClose();
        } else {
            error(res.data.msg);
        }

    };
    const getStores = async () => {
        try {
            const getStores = await storeAPI.getStoresBindingDropdown({
                organization_id: org_id,
            });
            if (getStores.data.error === "0") {
                setStores(getStores.data.result);
            } else {
                error(getStores.data.msg);
            }
        } catch (err: any) {
            error(err.message);
        }
    };

    useEffect(() => {
        getStores();
    }, []);

    const renderStore = () => {
        const findStore = stores.find((obj) => obj._id === data?.store_id);
        return <>{findStore?.store_name || "-"}</>;
    };

    let columns: any[] = [
        {
            dataIndex: "consumable_name",
            title: `${t("page.inventory")}`,
            width: "50%",
        },
        {
            dataIndex: "restock_qty",
            title: `${t("page.transfer_request.qty_request_transfer")}`,
            width: "50%",
        },
    ];

    return (
        //@ts-ignore
        <StyledModal
            visible={showModal}
            onCancel={handleClose}
            title={t("page.transfer_request.tr_req_detail")}
            footer={null}
            width={1000}
            closable={true}
        >
            <Row>
                <Col span={5}>
                    <div className="flex-section">
                        <span>
                            <ShopOutlined style={{ fontSize: "30px" }} />
                        </span>
                        <div className="two-line-section">
                            <p>{t("page.transfer_request.from_branch")}</p>
                            <strong>{data?.ttr_consumable_list?.[0]?.request_to_store_name || t("page.empty_text")}</strong>
                        </div>
                    </div>
                </Col>
                <Col span={1}>
                    <div className="flex-section">
                        <span><ArrowRightOutlined style={{ fontSize: "25px" }} /></span>
                    </div>
                </Col>
                <Col span={5}>
                    <div className="flex-section">
                        <span>
                            <ShopOutlined style={{ fontSize: "30px" }} />
                        </span>
                        <div className="two-line-section">
                            <p>{t("page.transfer_request.to_branch")}</p>
                            <strong>{renderStore()}</strong>
                        </div>
                    </div>
                </Col>
                <Col span={4}>
                    <div className="flex-section">
                        <div className="two-line-section">
                            <p>{t("page.transfer_request.order_request")}</p>
                            <strong>{data?.ttr_consumable_list?.length}</strong>
                        </div>
                    </div>
                </Col>
                <Col span={4}>
                    <div className="flex-section">
                        <div className="two-line-section">
                            <p>{t("page.transfer_request.tr_no")}</p>
                            <strong>{data?.ttr_no}</strong>
                        </div>
                    </div>
                </Col>
                <Col span={4}>
                    <div className="flex-section">
                        <div className="two-line-section">
                            <p>{t("page.transfer_request.order_request")}</p>
                            <strong>{data?.status}</strong>
                        </div>
                    </div>
                </Col>
            </Row>
            <Table
                columns={columns}
                dataSource={data?.ttr_consumable_list}
                pagination={false}

            />
            <div className="btn-section-right">
                <Button className="confirm-btn" onClick={cancelTr}>
                    {t('page.transfer_request.cancel_tr')}
                </Button>
            </div>

        </StyledModal >
    );
};
const StyledModal = styled(Modal)`
    .flex-section {
        display: flex;
        grid-gap: 5px;
        span {
            padding-top: 5px;
        }
        .two-line-section {
            font-size: 16px;
            p {
                margin: 0;
            }
        }
    }
    
    .ant-select {
        min-width: 100%;
    }
    .btn-section-right {
        text-align: right;
    }
    .confirm-btn {
        /* width: 100%; */
        margin-top: 20px;
        height: 50px;
        background-color: ${theme.colors.main};
        color: white;
        font-weight: bold;
        font-size: 18px;
    }
    .ant-modal-title {
        padding-bottom: 20px;
    }
    .ant-modal-body {
        padding: 36px 0 0 0 !important;
    }
    .ant-tabs-bar {
        border: none;
    }
    .ant-tabs-nav .ant-tabs-tab-active {
        color: ${theme.colors.main};
    }
    .ant-tabs-ink-bar {
        height: 3px !important;
        background: ${theme.colors.main};
    }
    .ant-tabs-nav .ant-tabs-tab {
        margin: 0 16px 0 0;
        padding: 8px 0;
        text-align: center;
    }
    .ant-tabs-nav .ant-tabs-tab:hover {
        color: ${theme.colors.main};
    }
    .ant-table-body {
        border-radius: 6px;
        border: 0.5px solid #a5a5a5;
        background: #fff;
    }
    .ant-table-thead > tr:first-child > th:last-child {
        border-top-right-radius: 6px;
    }
    .ant-table-thead > tr:first-child > th:first-child {
        border-top-left-radius: 6px;
    }
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }
    .ant-pagination {
        display: flex;
        width: 100%;
        place-content: center;
    }
    .btnRow {
        width: 100%;
        display: flex;
        flex-direction: row;
        place-content: center;
        margin-top: 20px;
        button {
            width: 200px;
            margin: 0;
        }
    }
`;
