import styled from "styled-components";

export const StyledNewDetail = styled.div`
    padding-right: 0;
    display: flex;
    flex-direction: column;
    height: 84vh;
    position: relative;

    .page-header {
        margin-bottom: ${(p) => p.theme.margins.pageHeader};
        h3 {
            margin-right: 44px;
            font-weight: 700;
            font-size: 35px;
            margin-bottom: 0;
            color: black;
            @media (max-width: ${(p) => p.theme.breakPoints.breakMobileMedium}) {
                font-size: 28px;
            }
        }
        .sub-title {
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            color: #a5a5a5;
        }
    }
    .form-wrap {
        flex: 1;
        position: relative;
        height: 10vh;
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }
    form,
    .info-card {
        padding: 3.5rem;
        flex: 1;
        overflow: auto;
        ::-webkit-scrollbar {
            width: 6px;
            height: 6px;
        }

        ::-webkit-scrollbar-track {
            display: none;
        }

        ::-webkit-scrollbar-thumb {
            background: ${(p) => p.theme.colors.fadedText};
            border-radius: 10px;
        }

        ::-webkit-scrollbar-thumb:hover {
            cursor: grab;
        }
        .page-body {
            .card-wrap {
                margin-bottom: 28px;
                .select-branch-section {
                    display: flex;
                    width: 300px;
                    margin-bottom: 30px;
                    p {
                        width: 100px;
                        margin-top: 12px;
                        font-weight: 400;
                        font-size: 16px;
                    }
                }
                .step-section {
                    /* max-width: 992px; */
                    background-color: #fff;
                    border-radius: 8px 8px 4px 4px;
                    .col-item {
                        height: 100px;
                        padding: 20px;
                        border-bottom: 6px solid #ff7f8e;
                        display: flex;
                        .step-text {
                            margin-left: 5px;
                            .step-number {
                                margin-top: 5px;
                                margin-bottom: 0px;
                                color: #ff7f8e;

                                /* font-size: 16px; */
                            }
                            .step-title {
                                color: #8e8e8e;
                                font-size: 18px;
                                font-weight: 700;
                            }
                        }
                    }
                    .start {
                        border-radius: 0 0 0 8px;
                    }
                    .end {
                        border-radius: 0 0 8px 0;
                    }
                    .col-item.active {
                        border-bottom: 6px solid #f4394f;
                        .step-number {
                            color: #f4394f;
                        }
                        .step-title {
                            color: black;
                        }
                    }
                    .border-right:after {
                        content: "";
                        position: absolute;
                        right: 0px;
                        top: 25%;
                        height: 50%;
                        border-right: 2px solid #e0e0e0;
                    }
                }
                .add-btn-section {
                    display: flex;
                    justify-content: flex-end;
                }
                .auto-gen-btn {
                    margin-right: 20px;
                }
                .product-addon-list {
                    .addon-item {
                        background-color: #f7f7f8;
                        border-radius: 6px;
                        margin-top: 30px;
                        padding: 20px;
                        .option {
                            margin-bottom: 10px;
                        }
                    }
                    .delete-icon {
                        margin-top: 55px;
                        margin-left: 30px;
                        cursor: pointer;
                    }
                }
                .quantity-columns {
                    display: flex;
                    justify-content: flex-end;
                    grid-gap: 5px;
                    input {
                        width: 100px;
                    }
                    svg {
                        margin-top: 5px;
                    }
                    p {
                        margin-top: 5px;
                        width: 80px;
                    }
                    span {
                        cursor: pointer;
                    }
                }
                .table-select-supplier {
                    grid-gap: 20px;
                    .row-sup {
                        margin-bottom: 30px;
                    }
                    .table-head-1 {
                    }
                    .table-head-2 {
                        text-align: right;
                    }
                    .item-name {
                        background: #f7f7f8;
                        border-radius: 6px;
                        padding: 10px;
                        font-weight: 700;
                        font-size: 16px;
                    }
                    .sup-card {
                        padding: 15px;
                        margin-bottom: 5px;
                        margin-left: 30px;
                        display: flex;
                        justify-content: space-between;
                        border: 1px solid #a5a5a5;
                        border-radius: 5px;
                        p {
                            margin: 0px;
                            margin-top: 4px;
                            font-weight: 400;
                            font-size: 16px;
                        }
                        .price-input {
                            width: 150px;
                            text-align: right;
                            height: 30px;
                        }
                    }
                }
                .spec-sup-card {
                    border: 1px solid #e1e1e1;
                    border-radius: 6px;
                    margin: 30px 0px;
                    padding: 30px;
                    .name-section {
                        background: #f7f7f8;
                        border-radius: 6px;
                        padding: 10px;
                        p {
                            margin: 5px;
                            font-weight: 800;
                            font-size: 18px;
                        }
                    }
                    .note-section {
                        margin-top: 30px;
                        p {
                            font-weight: 700;
                            font-size: 16px;
                        }
                    }
                }
                .PO-preview {
                    background-color: white;
                    border: 1px solid #a5a5a5;
                    width: 800px;
                    margin-top: 30px;
                }
                .title {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 24px;
                    color: #29304d;
                    margin-bottom: 20px;
                    .req-addon {
                        background-color: #ffd9de;
                        color: #6d1923;
                        padding: 5px 10px;
                        margin-left: 15px;
                        font-weight: 800;
                        font-size: 12px;
                        border-radius: 50px;
                    }
                }
                /*  variant-reward */
                .variants-field {
                    .variant-items {
                        display: flex;
                        flex-wrap: wrap;
                    }
                    .title {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 21px;
                        color: #000000;
                        margin-bottom: 8px;
                        display: inline-block;
                    }
                    .variant-wrap {
                        display: flex;
                        align-items: center;
                        width: fit-content;
                        position: relative;
                        margin-right: 2px;
                        .icon {
                            display: none;
                            cursor: pointer;
                            margin-right: 12px;
                        }
                        :hover .icon {
                            display: block;
                        }
                        .variant-item {
                            min-width: 92px;
                            max-width: 120px;
                            overflow: hidden;
                            background-color: #e1e1e1;
                            cursor: inherit;
                            /* margin: 7px 7px 7px 0; */
                            margin: 7px 5px 7px 0;
                            padding: 10px;
                            padding-right: 18px;
                            font-size: 16px;
                            border-radius: 6px;
                            text-align: center;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            font-weight: 600;
                            box-shadow: 0 0px 1px rgba(0, 0, 0, 0.1), 0 0px 1px rgba(0, 0, 0, 0.1);
                        }
                    }
                    margin-top: 30px;
                    .button-variant-add {
                        border: 1px solid #646464;
                        min-width: 92px;
                        font-size: 16px;
                        margin-top: 10px;
                        height: 40px;
                        color: #646464;
                        border-color: #646464 !important;
                    }
                }
                .location-input {
                    display: flex;
                    column-gap: 32px;
                    .input-layout {
                        flex: 3;
                        position: relative;
                        .loading-input {
                            position: absolute;
                            z-index: 1;
                            width: 100%;
                            height: 48px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }
            }
            .ck-editor__editable {
                min-height: 300px;
            }
            .err-input {
                input {
                    border-color: ${(p) => p.theme.colors.danger};
                }
                .ant-select-selection--multiple {
                    border-color: ${(p) => p.theme.colors.danger};
                }
                :hover,
                :focus input {
                    border-color: ${(p) => p.theme.colors.danger};
                }
            }
            .err-text {
                text-align: left;
                color: #f43f3f;
                visibility: visible;
                font-size: 11px;
                font-weight: 700;
            }
            .date-picker-field {
                p {
                    color: black;
                    font-size: 16px;
                    margin-bottom: 11px;
                }
                .col-item {
                    margin: 11px 0;
                }
                .ant-calendar-picker {
                    width: 100%;
                    input {
                        color: #6c7084;
                        font-size: 16px;
                    }
                    .ant-calendar-picker-input {
                        height: ${(p) => p.theme.heights.input};
                    }
                }
                .ant-time-picker {
                    width: 100%;
                    .ant-time-picker-input {
                        height: ${(p) => p.theme.heights.input};
                        :hover,
                        :focus-within,
                        :focus {
                            border-color: transparent;
                        }
                    }
                }
            }
            .button-field {
                display: flex;
                .button {
                    height: ${(p) => p.theme.heights.button};
                    min-width: 117px;
                }
                .button-save {
                    background-color: ${(p) => p.theme.colors.primary};
                    color: white;
                    margin-right: 10px;
                    :hover,
                    :focus-within {
                        border-color: ${(p) => p.theme.colors.primary}!important;
                    }
                }
                .button-cancel {
                    color: black;
                    border-color: black;
                    :hover,
                    :focus-within {
                        border-color: black !important;
                    }
                }
            }
            .switch-field {
                display: flex;
                align-items: center;
                margin-bottom: 32px;
                p {
                    color: black;
                    font-size: 16px;
                    font-weight: 600;
                    margin-right: 26px;
                }
            }
            .date {
                margin-top: 50px;
                margin-bottom: 16px;
                @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                    margin-top: 42px;
                }
                .label {
                    margin-bottom: 24px;
                }
                .date_ground {
                    margin-bottom: 16px;
                    display: flex;
                    align-items: center;

                    .date_from_to {
                        display: inline-block;
                        min-width: 100px;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 1.5em;
                        color: #000000;
                    }
                }
            }
            .label {
                /* margin-bottom: 1rem; */
            }
            .for-custom-margin {
                margin-bottom: 20px;
            }
            .btn-layout {
                margin-top: 42px;
                display: flex;
                column-gap: 20px;
            }
            .cancel-btn {
                background: #e95c60;
                border: none;
                color: white;
            }
            .pending-btn {
                /* background: #E95C60; */
            }
            .complete-btn {
                background: #34c759;
            }
        }

        .radio-section {
            .radio-title {
                font-size: 16px;
                font-weight: 700;
            }
            .radio-item {
                margin-right: 30px;
            }
        }
    }

    // other
    .group_link {
        display: flex;
        align-items: center;
        .copy {
            width: 22px;
            margin-left: 12px;
            position: relative;
            top: -7px;
            cursor: pointer;
        }
    }
    .ground_qrcode {
        display: flex;
        align-items: flex-start;
        margin-bottom: 16px;
        .image {
            width: 200px;
            height: 200px;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    .desc_link {
        font-weight: normal;
        font-size: 12px;
        line-height: 1.5em;
        color: #646464;
        margin-top: -10px;
    }
    .mb-22 {
        margin-bottom: 22px;
    }
    .mb-6 {
        margin-bottom: 6px;
    }
    // update
    .display-coupon {
        width: 100%;
        overflow-wrap: break-word;
        .title {
            margin-bottom: 1rem;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 21px;
            color: #000000;
        }
        .sub-title {
            margin: 0.75rem 0 1rem 0;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            color: #000000;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 0.4);
        }
    }
    .variantItems {
        display: flex;
        gap: 10px;
        place-items: center;
        margin-bottom: 20px;
    }
    .ant-tag {
        background: #e1e1e1;
        border-radius: 4px;
        padding: 9px 9px 9px 12px;
        display: flex;
        place-items: center;
        // height: 40px;
    }
    .ant-table-column-title {
        font-size: 16px;
        font-weight: 700;
        color: #000;
    }
    .ant-table-thead > tr:first-child > th:first-child {
        text-align: left;
    }
    .ant-table-thead > tr > th {
        text-align: center;
    }
    .antd-btn-custom {
        height: 40px;
        margin-left: 22px;
        font-size: 14px !important;
        font-weight: 700 !important;
        color: #000;
        display: flex;
        place-items: center;
    }
    .antd-btn-custom span {
        font-size: 14px !important;
        font-weight: 700 !important;
        color: #000;
        margin-left: 5px;
    }
    .both-edge-title {
        justify-content: space-between;
        display: flex;
        place-items: center;
    }
`;
