import {
    ICreateProductAddon,
    IDeleteProductAddon,
    IGetProductAddon,
    IGetProductAddonID,
    IGetProductAddons,
    IUpdateProductAddon,
} from "@interfaces";

import storeFrontAxiosClient from "./storeFrontAxiosClient";

export const productAddonAPI = {
    getProductAddons: (params: IGetProductAddons) => {
        const url = "product_addon/getProductAddons";
        return storeFrontAxiosClient.get(url, { params });
    },
    getProductAddon: (params: IGetProductAddon) => {
        const url = "product_addon/getProductAddon";
        return storeFrontAxiosClient.get(url, { params });
    },
    deleteProductAddon: (params: IDeleteProductAddon) => {
        const url = "product_addon/deleteProductAddon";
        return storeFrontAxiosClient.post(url, params);
    },
    createProductAddon: (params: ICreateProductAddon) => {
        const url = "product_addon/createProductAddon";
        return storeFrontAxiosClient.post(url, params);
    },
    updateProductAddon: (params: IUpdateProductAddon) => {
        const url = "product_addon/updateProductAddon";
        return storeFrontAxiosClient.patch(url, params);
    },
    getProductAddonID: (params: IGetProductAddonID) => {
        const url = "product_addon/getProductAddon";
        return storeFrontAxiosClient.get(url, { params });
    },
};
