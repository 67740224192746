import { useTranslation } from "react-i18next";
import { Col, Row, Input, DatePicker } from "antd";
import { IDataexample } from "@interfaces";
import { IconDrag } from "@components";
import { useEffect } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Checkbox } from "@mui/material";
import moment from "moment";
import styled from "styled-components";

interface IProps {
    data: IDataexample | any;
    itemJson: string;
    index: number;
    clickCheck: (isCheck: boolean, gId: string) => void;
    showCheck: boolean;
    changeDate: (dateString: string, index: number) => void;
    receiveAmountHandler?: (amount: number, index: number) => void;
    showExpiry: boolean;
    selectList: string[];
}

export const ComponentRowGoodReceiveItem = (props: IProps) => {
    const { t } = useTranslation();
    const { data, itemJson, index, clickCheck, showCheck, changeDate, receiveAmountHandler, showExpiry, selectList } =
        props;

    const { attributes, listeners, setNodeRef, transform } = useSortable({
        id: itemJson,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
    };

    const handleChange = (e) => {
        clickCheck(e.target.checked, data?._id);
    };

    const MONTHlIST = [
        t("month.jan"),
        t("month.feb"),
        t("month.mar"),
        t("month.apr"),
        t("month.may"),
        t("month.jun"),
        t("month.jul"),
        t("month.aug"),
        t("month.sep"),
        t("month.oct"),
        t("month.nov"),
        t("month.dec"),
    ];

    function formatDate(date) {
        let d = new Date(date),
            month = "" + d.getMonth(),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        return [day, MONTHlIST[parseInt(month)], year + 543].join(" ");
    }
    return (
        <StyledGR>
            <div className="table-element" ref={setNodeRef} style={style}>
                <div className="drag-icon hide" {...attributes} {...listeners}>
                    <IconDrag />
                </div>
                <div className="table-row" style={{ height: "70px" }}>
                    <Row
                        style={{ height: "100%" }}
                        gutter={24}
                        type="flex"
                        align="middle"
                        justify="space-between"
                    >
                        <Col className="col-item" span={7}>
                            {showCheck &&
                                <Checkbox
                                    onChange={handleChange}
                                    checked={selectList.some(
                                        (x) =>
                                            x === data?._id
                                    )}
                                />
                            }
                            <p>{data?.consumable_name || t("page.empty_text")}</p>
                        </Col>
                        <Col className="col-item" span={4}>
                            <p>
                                {showCheck
                                    ? data.restock_qty
                                    : data.total_restock_qty || t("page.empty_text")}{" "}
                                {data.restock_unit}
                            </p>
                        </Col>
                        <Col className="col-item" span={4}>
                            {showCheck ? (
                                <Input
                                    value={data.receive_amount}
                                    className="receiveAmount"
                                    type="number"
                                    addonAfter={data.restock_unit}
                                    onChange={(value) => {
                                        if (receiveAmountHandler) {
                                            if (
                                                value.target.value <= data.restock_qty &&
                                                parseInt(value.target.value) !== 0
                                            ) {
                                                receiveAmountHandler(
                                                    parseInt(value.target.value),
                                                    index
                                                );
                                            }
                                        }
                                    }}
                                />
                            ) : (
                                <p>
                                    {data.restock_qty || t("page.empty_text")} {data.restock_unit}
                                </p>
                            )}
                        </Col>
                        <Col className="col-item" span={4}>
                            <p>
                                {data?.unit_price || t("page.empty_text")} THB / {data.restock_unit}
                            </p>
                        </Col>
                        {showExpiry &&
                            <Col className="col-item" span={5}>
                                {showCheck ? (
                                    <DatePicker
                                        format={"YYYY-MM-DD"}
                                        value={
                                            data?.expire_date === null
                                                ? undefined
                                                : moment(data?.expire_date)
                                        }
                                        onChange={(date, dateString) => {
                                            changeDate(dateString, index);
                                        }}
                                        placeholder={t("page.good_receive.select_date")}
                                    />
                                ) : (
                                    <p>{data?.expire_date ? formatDate(data?.expire_date) : "-"}</p>
                                )}
                            </Col>
                        }

                    </Row>
                </div>
            </div>
        </StyledGR>
    );
};

export const StyledGR = styled.div`
    .receiveAmount {
        position: relative;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;
